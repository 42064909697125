import styled from 'styled-components'
import { TextField } from '@material-ui/core'

import { withStyles } from '@material-ui/styles'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const getThemeColorStructureBackground = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().structure) {
			if (getThemeColorFromLocalStorage().structure.backgroundColor) {
				return getThemeColorFromLocalStorage().structure.backgroundColor
			}
		}
	}
	return '#08993A'
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: getThemeColorStructureBackground(),
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: getThemeColorStructureBackground(),
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: getThemeColorStructureBackground(),
				},
				'&.Mui-focused fieldset': {
					borderColor: getThemeColorStructureBackground(),
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(0,0%,95%)',
			'& fieldset': {
				borderColor: 'hsl(0,0%,90%)',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense ': {
			paddingTop: '9.5px',
			paddingBottom: '9.5px',
		},
	},
})(TextField)

export const InputText = styled(CssTextField)`
	.MuiOutlinedInput-input {
		padding: 11px 14px;
	}
`
