import React, { useEffect, useState } from 'react'

// @ Translation
import { useTranslation } from 'react-i18next'

// @ Prop Types
import PropTypes from 'prop-types'

// @ Material
import Card from '@mui/material/Card'
import { CardContent, Icon } from '@mui/material'

// @ Styles
import * as S from './styles'

import { useHistory } from 'react-router-dom'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

export const CustomCard = ({ additionalModule }) => {
	const history = useHistory()
	const { t } = useTranslation()
	const [themeColor, setThemeColor] = useState(getThemeColorFromLocalStorage())

	const disableCard = additionalModule?.name !== 'GerminiPay'

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	return (
		<S.CustomCard themeColor={themeColor} disable={disableCard}>
			<Card onClick={() => !disableCard && history.push(`/additionalModules/ModuleDetails/${additionalModule?.id}`)}>
				<S.HeaderIcons>
					<S.CustomIcon themeColor={themeColor} disable={disableCard}>
						<Icon>
							<i className="icon-servicos_contratados_pagamentos" />
						</Icon>
					</S.CustomIcon>
					<S.BlackTooltip arrow title={t('FIELD_TOOLTIP_ADDITIONAL_MODULE')} placement="right">
						<S.IconInformation>
							<Icon>
								<i style={{ color: '#21409A' }} className="icon-informacao" />
							</Icon>
						</S.IconInformation>
					</S.BlackTooltip>
				</S.HeaderIcons>
				<CardContent style={{ padding: '0px', marginTop: '50px' }}>
					<S.CardTitle>{t(additionalModule?.name)}</S.CardTitle>
				</CardContent>
			</Card>
		</S.CustomCard>
	)
}

CustomCard.propTypes = {
	id: PropTypes.number,
	name: PropTypes.string,
	additionalModule: PropTypes.object,
}
