import React, { useEffect, useState } from 'react'
import {
	Close,
	Icon,
	TextStyle,
	Example,
	Model,
	Form,
	Container,
} from './style'
import { Modal, Box, Grid } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import hourglass from '../../../assets/img/ampulheta.png'
import CurrencyInput from '../../../pages/Award/CreateAward/components/NewCustomInput'
import { Controller, useForm } from 'react-hook-form'
import imageCSV from 'assets/img/modelo_csv.jpg'

import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 600,
	maxHeight: 600,
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: 24,
	p: 4,
	padding: '10px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}

const ModalInfo = ({
	open,
	icon,
	text,
	type,
	GetValue,
	GetValidationNewFile,
	SelectValue,
	setImportNewFile,
	valueReserve,
	msgError,
}) => {
	const [openState, setOpenState] = useState(false)
	const [valuePostAward, setValuePostAward] = useState('')
	const [errorValue, setErrorValue] = useState(false)
	// const handleOpen = () => setOpen(true)

	const handleClose = () => {
		setOpenState(false)
	}
	const handleValue = () => {
		setErrorValue(false)
		SelectValue()
		setOpenState(false)
	}

	const handleChange = (e, valuePostAward) => {
		e.preventDefault()

		if (valuePostAward > 0 && valueReserve > 0) {
			GetValue(valuePostAward)
		} else {
			setErrorValue(true)
		}
	}

	const handleChangeConditionImportFile = (e, valid) => {
		e.preventDefault()
		GetValidationNewFile(valid)
		setImportNewFile(false)
	}

	const handleValueFile = () => {
		setImportNewFile(false)
		setOpenState(false)
	}

	// highValue
	// invalidFile
	// disregardedValue
	// loading
	// moreThousand

	useEffect(() => {
		setOpenState(open)
	}, [open])

	const validateIndication = Yup.object().shape({
		giftValue: Yup.string().required('Campo de preenchimento obrigatório'),
	})

	const { control, reset, setValue } = useForm({
		defaultValues: {
			giftValue: 0,
		},
		resolver: yupResolver(validateIndication),
	})

	return (
		<Modal
			open={openState}
			// onClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				{type === 'loading' ? (
					<img
						src={hourglass}
						alt="hourglass"
						style={{ width: '40%', margin: '50px 0' }}
					/>
				) : (
					<>
						{type !== 'disregardedValue' &&
							type !== 'getConditionImportFile' && (
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
										width: '100%',
									}}
								>
									<Close>
										<button onClick={handleClose}>
											<i className="icon-ico_cancelar" />
											Fechar
										</button>
									</Close>
								</div>
							)}

						<Icon>
							<i className={icon} />
						</Icon>

						<TextStyle>
							<p>{text}</p>
							{type === 'highValue' && (
								<p
									style={{
										marginBottom: '20px',
										marginLeft: '25px',
										marginRight: '25px',
									}}
								>
									O valor distribuído é maior que o saldo de
									reserva destinado a premiação.
									<br /> Para continuar é necessário diminuir
									o valor ou remover um registro.
								</p>
							)}
							{type === 'balanceSubmit' && (
								<p
									style={{
										marginLeft: '40px',
										marginRight: '40px',
										marginBottom: '30px',
									}}
								>
									O saldo para importação é insuficiente.
									<br />
									Remova registros ou selecione outra reserva
									com saldo maior.
								</p>
							)}
							{type === 'invalidFile' && (
								<p
									style={{
										marginLeft: '40px',
										marginRight: '40px',
										marginBottom: '30px',
									}}
								>
									O formato do arquivo é inválido.
									<br /> Repita a operação utilizando uma das
									três opções: csv, txt
								</p>
							)}
							{type === 'fileInvalid' && (
								<>
									<Grid
										style={{
											fontSize: '17px/30px',
											textAlign: 'center',
											marginRight: '20px',
											marginLeft: '20px',
										}}
										className="grid_modal"
									>
										O arquivo arquivo importado não esta
										formatado da forma correta.
										<br />
										Duas colunas, indicando o CPF / Cód. do
										participante e outra o Valor, sendo
										considerado um registro por linha.
									</Grid>
									<Model>Modelo: </Model>
									<Example>
										<img src={imageCSV} alt="informação" />
									</Example>
								</>
							)}
							{type === 'disregardedValue' && (
								<p
									style={{
										marginBottom: '40px',
										marginLeft: '25px',
										marginRight: '25px',
									}}
								>
									Os valores informados no arquivo a ser
									importado serão desconsiderados.
								</p>
							)}
							{type === 'moreThousand' && (
								<p
									style={{
										marginBottom: '37px',
										marginLeft: '100px',
										marginRight: '100px',
									}}
								>
									O documento possui mais de 1000 registros.
									<br />
									importe em arquivos separados e lotes
									diferentes.
								</p>
							)}
							{type === 'getConditionImportFile' && (
								<p
									style={{
										marginBottom: '15px',
										marginLeft: '50px',
										marginRight: '50px',
									}}
								>
									Ao selecionar um novo arquivo a lista atual
									será substituída.
									<br />
									Deseja continuar?
								</p>
							)}
							{type === 'BankAccountReserve' && (
								<p
									style={{
										marginBottom: '37px',
										marginLeft: '100px',
										marginRight: '100px',
									}}
								>
									{msgError}
								</p>
							)}
							{type === 'disregardedValue' && (
								<Form
									style={{
										alignItems: 'center',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<Controller
										style={{ width: '344px' }}
										render={({ onChange, value }) => (
											<CurrencyInput
												max={100000}
												label={
													'Valor do crédito (R$) *'
												}
												reset={reset}
												value={value}
												setValue={setValue}
												name={'giftValue'}
												onValueChange={onChange}
												error={
													(errorValue &&
														value === 0) ||
													(errorValue &&
														valueReserve ===
															undefined)
												}
												helperText={
													errorValue && value === 0
														? 'Campo obrigatório'
														: errorValue &&
														  valueReserve ===
																undefined
														? 'Selecione uma reserva'
														: ''
												}
												onChange={setValuePostAward(
													value,
												)}
												style={{ width: '344px' }}
											/>
										)}
										control={control}
										name="giftValue"
										defaultValue=""
									/>

									<div
										style={{
											marginTop: '20px',
											marginBottom: '37px ',
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<Button
											style={{
												width: '157px',
												height: '47px',
												marginRight: '30px',
											}}
											size="sm"
											variant="contained"
											color="primary"
											onClick={handleValue}
										>
											CANCELAR
										</Button>
										<Button
											style={{
												width: '157px',
												height: '47px',
											}}
											//type='submit'
											size="sm"
											variant="contained"
											color="greenBorderButtonColor"
											type="submit"
											onClick={(e) =>
												handleChange(e, valuePostAward)
											}
										>
											CONFIRMAR
										</Button>
									</div>
								</Form>
							)}
							{type === 'getConditionImportFile' && (
								<Form
									style={{
										alignItems: 'center',
										display: 'flex',
										flexDirection: 'column',
									}}
								>
									<div
										style={{
											marginTop: '20px',
											marginBottom: '20px ',
											display: 'flex',
											justifyContent: 'space-between',
										}}
									>
										<Button
											style={{
												width: '107px',
												height: '47px',
												marginRight: '30px',
											}}
											size="sm"
											variant="contained"
											color="primary"
											onClick={handleValueFile}
										>
											NÃO
										</Button>
										<Button
											style={{
												width: '107px',
												height: '47px',
											}}
											//type='submit'
											size="sm"
											variant="contained"
											color="greenBorderButtonColor"
											type="submit"
											onClick={(e) =>
												handleChangeConditionImportFile(
													e,
													true,
												)
											}
										>
											SIM
										</Button>
									</div>
								</Form>
							)}
						</TextStyle>
					</>
				)}
			</Box>
		</Modal>
	)
}
export default ModalInfo
