import { PartnerService } from '../../Services'

export default async function getAddressCountryList() {
	const partnerService = new PartnerService()
	const response = await partnerService.getPartnerCountry()

	const result = []

	response.forEach(item => {
		result.push({
			label: item?.name,
			value: item?.id,
		})
	})

	return result ?? []
}
