import React from 'react'

import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'
import { withStyles } from '@material-ui/core/styles'

const style = (theme) => ({
	root: {
		float: 'right',
		marginRight: '-5px',
		padding: '2px',
		backgroundColor: germiniDefaultColors[0] + ' !important',
		color: '#fff',
	},
	selected: {
		backgroundColor: '#e1e1e1 !important',
	},
	paper: {
		backgroundColor: '#e1e1e1',
		right: '80px',
		left: 'initial !important',
		boxShadow: '0px 0px 2px #808080',
		overflow: 'visible',
		marginTop: '-17px',
		'&::after': {
			left: '100%',
			top: '12%',
			border: 'solid transparent',
			content: "' '",
			height: 0,
			width: 0,
			position: 'absolute',
			borderColor: 'rgba(136, 183, 213, 0)',
			borderLeftColor: '#e1e1e1',
			borderWidth: '10px',
			marginTop: '-10px',
		},
	},
})

class IconMenu extends React.Component {
	state = {
		open: false,
	}

	handleToggle = () => {
		this.setState((state) => ({ open: !state.open }))
	}

	handleClose = (event) => {
		if (this.anchorEl.contains(event.target)) {
			return
		}

		this.setState({ open: false })
	}

	render() {
		const { classes } = this.props
		const items = this.props.items
		const active = this.props.active
		const { open } = this.state

		return (
			<div>
				<IconButton
					aria-owns={open ? 'simple-menu' : undefined}
					aria-haspopup="true"
					buttonRef={(node) => {
						this.anchorEl = node
					}}
					onClick={this.handleToggle}
					classes={{
						root: classes.root,
					}}
				>
					<MoreHorizIcon />
				</IconButton>
				<Menu
					id="simple-menu"
					anchorEl={this.anchorEl}
					open={Boolean(open)}
					onClick={this.handleClose}
					classes={{ paper: classes.paper }}
					getContentAnchorEl={null}
				>
					{items &&
						items.map((prop, key) => (
							<MenuItem
								value={key}
								key={key}
								selected={key === active}
								classes={{ selected: classes.selected }}
								onClick={this.props.onChangeValue}
							>
								{prop.label}
							</MenuItem>
						))}
				</Menu>
			</div>
		)
	}
}

export default withStyles(style)(IconMenu)
