import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

//@components
import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable'
import SubtitleItem from './mocks/SubtitleItem'

//@Utils
import * as S from './styles'
import * as UT from './mocks'
import { GetStatusProductsByDivision, GetStatusProductsByDivisionTable } from '../../../../../../../redux/api/productPage'
import { buildQueryString } from './utils/utils'

const ListComponent = ({ statusName, partnerId, productId }) => {
	const tableRef = createRef()
	const { t } = useTranslation()
	const [pageSizeState, setPageSizeState] = useState(10)

	return (
		<S.Container>
			<CustomMaterialTable
				tableRef={tableRef}
				title={false}
				onChangeRowsPerPage={e => {
					setPageSizeState(e)
				}}
				options={{
					pageSize: pageSizeState,
					pageSizeOptions: [10, 20, 30, 50, 100],
					search: true,
					toolBar: false,
					emptyRowsWhenPaging: false,
				}}
				columns={UT?.Columns(t)}
				data={obj =>
					new Promise(resolve => {
						delete obj?.totalCount
						delete obj?.filters

						const pagination = {
							...obj,
							page: obj?.page + 1,
							error: '',
							orderBy: '',
						}

						const filterValues = {
							productId: productId,
							partnerId: partnerId,
							statusName: statusName,
						}

						const body = buildQueryString({ ...filterValues, ...pagination })

						GetStatusProductsByDivisionTable(body).then(result => {
							if (!_.isEmpty(result)) {
								resolve({
									data: result?.results,
									page: result?.currentPage - 1,
									totalCount: result?.recordCount,
									pageSize: result?.pageSize,
								})
							} else {
								resolve({
									data: [],
									page: 0,
									totalCount: 0,
								})
							}
						})
					})
				}
			/>
			<S.SubTitle>
				<SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#3EC450" text="Centro de custo" />
				<SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#1BBED3" text="Departamento" />
				<SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#7E6BB9" text="Filial" />
				<SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#FB9006" text="Unidade de negócio" />
			</S.SubTitle>
		</S.Container>
	)
}

export default ListComponent
