import { Tooltip } from '@material-ui/core'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
// import { ApiGet } from '../Api'
import { useAward } from '../../../Providers/AwardContext'
import {
	GetAwards,
	ScheduleJob,
	UpdateStatusAward,
} from '../../../redux/api/award.js'
import { ActionBtn } from '../style'
import { ModalPending } from './ModalPending'
import { Container, IconContainer, IconInfo } from './style'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

const PAUSAR = 1
const PLAY = 2
const CANCELAR = 3

const Pending = ({ watch }) => {
	const tableRef = React.useRef()
	const [pageSizeState, setPageSizeState] = useState(10)
	const [recordPageState, setRecordPageState] = useState()
	const [openModal, setOpenModal] = useState(false)
	const [typeModal, setTypeModal] = useState(false)
	const [valuesStatus, setValuesStatus] = useState({})
	const [hasData, setHasData] = useState(false)
	const [themeColor, setThemeColor] = useState()

	const { valueTypeFilter, setTypesFilterAwardRev } = useAward()

	const handleGetOriginData = useCallback(async () => {
		const origin = await GetAwards(null, null, null, null, 2)
		let typeFilter = []

		origin &&
			origin.data &&
			origin.data.results &&
			origin.data.results.map((item, index) => {
				typeFilter.push({
					value: item.partnerCreditOrigin.key,
					key: item.partnerCreditOrigin.key,
					label: item.partnerCreditOrigin.value,
				})
			})

		setTypesFilterAwardRev(
			[
				...new Set(
					typeFilter && typeFilter.map((obj) => JSON.stringify(obj)),
				),
			].map((str) => JSON.parse(str)),
		)
	}, [])

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		handleGetOriginData()
	}, [])

	useEffect(() => {
		tableRef.current.onChangePage({}, 0)
		tableRef.current.onQueryChange({ page: 0, search: '' })
	}, [valueTypeFilter])

	useEffect(() => {
		const timer = setInterval(() => {
			tableRef.current.onQueryChange()
		}, 5000)
		return () => clearInterval(timer)
	}, [])

	const handleGetAwardData = useCallback(
		async (pageSize, page) => {
			let startDate = null
			let endDate = null
			if (valueTypeFilter.dateFilter) {
				startDate = moment(
					valueTypeFilter.dateFilter,
					'DD/MM/YYYY',
				).format('YYYY-MM-DD')
			}
			if (valueTypeFilter.dateFilterTo) {
				endDate = moment(
					valueTypeFilter.dateFilterTo,
					'DD/MM/YYYY',
				).format('YYYY-MM-DD')
			}

			const res = GetAwards(
				valueTypeFilter.description,
				valueTypeFilter.typeFilter,
				startDate,
				endDate,
				2,
				pageSize,
				page,
			)
			return res
		},
		[valueTypeFilter],
	)

	const handleRequest = async (idRequest, statusRequest) => {
		const body = {
			awardId: idRequest,
			action: statusRequest,
		}

		await UpdateStatusAward(idRequest, statusRequest, body)
		tableRef.current.onQueryChange()
	}

	const handleStartJob = async (id) => {
		const today = moment().format('YYYY-MM-DD HH:mm')

		const res = await ScheduleJob(today, id)
	}

	const handleUpdateStatus = async (id, status) => {
		// 1 - PAUSAR
		// 2 - PLAY
		// 3 - CANCELAR
		if (status === PAUSAR) {
			setTypeModal(false)
			setOpenModal(true)
			handleRequest(id, status)
			setInterval(() => {
				setOpenModal(false)
			}, 5000)
		}
		if (status === PLAY) {
			handleRequest(id, status)
			await handleStartJob(id)
		}
		if (status === CANCELAR) {
			setTypeModal(true)
			setOpenModal(true)
			setValuesStatus({
				id: id,
				status: status,
			})
		}
	}

	return (
		<Container themeColor={themeColor}>
			<ModalPending
				openModal={openModal}
				setOpenModal={setOpenModal}
				typeModal={typeModal}
				handleRequest={handleRequest}
				valuesStatus={valuesStatus}
			/>
			<MaterialTable
				tableRef={tableRef}
				options={{
					loadingType: 'none',
					isLoading: false,
					emptyRowsWhenPaging: false,
					toolBar: false,
					pageSizeOptions: [10, 20, 30, 40, 50],
					search: false,
					pageSize:
						recordPageState < 10 ? recordPageState : pageSizeState,
					paging: hasData ? true : false,
					sorting: false,
					draggable: false,

					// se data for vazio não renderizar a tabela, renderizar um component, ou só tirar a paginação, usando o has data
				}}
				columns={[
					{
						title: 'Ordem de Processamento',
						searchable: true,
						cellStyle: {
							textAlign: 'center',
							height: '45px',
							backgroundColor: '#fff',
						},
						headerStyle: {
							width: '15%',
							maxWidth: '15%',
							minWidth: '15%',
						},
						render: (props) => props.order,
					},
					{
						title: 'Data',
						searchable: true,
						cellStyle: {
							paddingLeft: '10px',
							textAlign: 'left',
							width: '15%',
							maxWidth: '15%',
							minWidth: '15%',
							height: '45px',
							backgroundColor: '#fff',
						},
						headerStyle: {
							width: '10%',
							maxWidth: '10%',
							minWidth: '10%',
						},
						render: (props) => (
							<div
								style={{
									alignItems: 'flex-start',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									height: '34px',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									{moment(props.createdAt).format(
										'DD/MM/YYYY',
									)}
								</p>
								<span
									style={{
										fontStyle: 'italic',
										fontSize: '12px',
									}}
								>
									{moment(props.createdAt).format('HH:mm')}
								</span>
							</div>
						),
					},
					{
						title: 'Descrição/Tipo',
						field: 'description',
						searchable: true,
						cellStyle: {
							textAlign: 'left',
							width: '70%',
							maxWidth: '70%',
							minWidth: '70%',
							height: '45px',
							backgroundColor: '#fff',
						},

						render: (props) => (
							<IconContainer>
								<Tooltip
									title="Taxa de antecipação"
									color="#000000"
									arrow
									placement="top-start"
								>
									<IconInfo
										className="icon-antecipacao"
										style={{
											color: `${props.isAnticipated
												? ''
												: '#c4c4c4'
												}`,
										}}
									/>
								</Tooltip>
								<div
									style={{
										alignItems: 'flex-start',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '34px',
										marginLeft: '10px',
									}}
								>
									<p style={{ fontSize: '15px', margin: 0 }}>
										{props.description}
									</p>
									<span
										style={{
											fontStyle: 'italic',
											fontSize: '12px',
										}}
									>
										{props.partnerCreditOrigin.value}
									</span>
								</div>
							</IconContainer>
						),
					},
					{
						title: 'Status do Processo',
						field: 'description',
						searchable: true,
						cellStyle: {
							textAlign: 'left',
							width: '35%',
							maxWidth: '35%',
							minWidth: '35%',
							height: '45px',
							backgroundColor: '#fff',
						},

						render: (props) => (
							<div>
								{props.progress
									? `${props.progress}%`
									: `${props.progressText}`}
							</div>
						),
					},

					{
						title: 'Ação',
						field: 'actions',
						cellStyle: {
							backgroundColor: '#fff',
							width: '80px',
							maxWidth: '80px',
							minWidth: '80px',
						},
						sorting: false,
						render: (props) => (
							<div
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
								}}
							>
								<Tooltip
									arrow
									id="tooltip-top"
									title={'Cancelar'}
									placement="top-start"
								>
									<ActionBtn
										themeColor={themeColor}
										disabled={
											props.status.key !== '0'
												? true
												: false
										}
										style={{
											fontSize: '15px',
											backgroundColor: `${props.status.key !== '0' &&
												'#c4c4c4'
												}`,
											cursor: `${props.status.key !== '0'
												? 'default'
												: 'pointer'
												}`,
										}}
										onClick={() =>
											handleUpdateStatus(
												props.id,
												CANCELAR,
											)
										}
									>
										<i className="icon-acao_cancelar" />
									</ActionBtn>
								</Tooltip>
							</div>
						),
					},
				]}
				data={(query) =>
					new Promise((resolve, reject) => {
						handleGetAwardData(
							query.pageSize,
							query.page + 1,
							query.search,
							query.orderDirection,
						).then((result) => {
							if (result) {
								setPageSizeState(result.data.pageSize)
								setRecordPageState(result.data.recordCount)
								// criar um if se result.data.results length > 0
								// se tiver data trocar has para true, se não false
								if (result.data.results.length > 0) {
									setHasData(true)
								} else {
									setHasData(false)
								}
								if (result.data) {
									resolve({
										data: result.data.results,
										page: result.data.currentPage - 1,
										totalCount: result.data.recordCount,
										pageSize: result.data.pageSize,
									})
								}
							}
						})
					})
				}
			/>
		</Container>
	)
}
export default Pending
