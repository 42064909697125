import _ from 'lodash'
import { GetTypeOfPoints } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import { CalculateValue, UpdateProduct } from '../../../../../../../../../redux/api/product'
import {envs}  from '../../../../../../../../../redux/api/windowServerData'



export const GetTypePoints = async ({ setGenerationType, setCircularProgress }) => {
	setCircularProgress(true)

	try {
		const res = await GetTypeOfPoints()
		if (res && typeof res === 'object') {
			const newRes =
				res
					?.map(item => {
						if (item?.value === 'No') {
							return {
								...item,
								value: 'Não gerar pontos',
							}
						}
						return item
					})
					?.filter(item => item !== null) || []
			setGenerationType(newRes)
		}
	} catch (error) {
		setGenerationType([])
	} finally {
		setCircularProgress(false)
	}
}

export const FormatPoints = value => {
	// const currencySymbol = localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const currencySymbol = localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL

	const formattedValue = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	}).format(value)

	return formattedValue.replace(/,/g, '.').replace('R$', currencySymbol)
}

export const FormatMoney = value => {
	// const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const formattedValue = parseFloat(value).toLocaleString('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	})
	return formattedValue.replace('R$', symbol)
}

export const handleProductDataUpdate = (productData, setParameterization, setValue) => {
	if (!_.isEmpty(productData)) {
		const { typeOfPoints, parameterizationGenerationPoints, pointsValue } = productData

		setValue('typeOfPoints', typeOfPoints)
		setValue('pointsValue', pointsValue)
		setParameterization(parameterizationGenerationPoints)
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async (productId, price, type, value, typeOfRedeem, redeemValue) => {
	try {
		const res = await CalculateValue(productId, price, type, value, typeOfRedeem, redeemValue)
		if (res?.success && res?.data) {
			const { generationPoints } = res.data
			return generationPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationProduct = async (productData, typeOfPoints, pointsValue, setSnackStatus, typeOfBenefitPoints, setEditMode) => {
	const obj = {
		id: productData?.id,
		price: productData?.price,
		name: productData?.name,
		generatePoints: typeOfPoints?.key != '0' ? true : false,
		typeOfBenefitPoints: typeOfBenefitPoints?.key,
		typeOfPoints: typeOfPoints?.key,
		pointsValue: typeOfPoints?.key !== '0' && typeOfPoints?.key !== '1' ? pointsValue : '0',
		typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key,
		typeOfRedeem: productData?.typeOfRedeem?.key,
		redemptionPoints: productData?.redemptionPoints || 0,
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: productData?.typeOfCashback?.key,
		cashbackPercentual: productData?.cashbackPercentual,
	}

	try {
		const res = await UpdateProduct(obj?.id, obj)

		if (res?.success) {
			console.log('res', res)
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				type: 'success',
			})
			setEditMode(false)
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				message: res?.data?.errors[0]?.message,
				type: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}
