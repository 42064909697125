import styled from 'styled-components'
import { StandardColors } from './ParameterizeDivisionTB/Functions/utils'
import { Box } from '@mui/material'

export const Title = styled.div`
	text-align: left;
	font: normal normal normal 15px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`
export const SubTitle = styled.div`
	text-align: left;
	font: normal normal bold 17px Roboto;
	letter-spacing: 0px;
	color: ${({ typeColor }) => StandardColors[typeColor] || '#333333'};
	opacity: 1;
`

export const Text = styled.div`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`

export const Section = styled(Box)`
	display: flex;
	flex-direction: column;
`
