import Axios from 'axios'

export default class NegotiationService {
	async getNegotiations() {
		const response = await Axios.get(`/Negotiation/GetAll?pageSize=10&page=1`)
		return response?.data
	}

	async getPaginationNegotiations({ pageSize, page }) {
		const response = await Axios.get(`/Negotiation/GetAll?pageSize=${pageSize}&page=${page}`)
		return response?.data
	}

	async getFilteredNegotiations({ pageSize, page, ...filter }) {
		const queryParams = {
			page,
			pageSize,
			...filter,
		}

		console.log('filter', filter)

		const filteredParams = Object.fromEntries(
			Object.entries(queryParams).filter(([_, value]) => value !== undefined && value !== null && value !== ''),
		)

		const query = Object.entries(filteredParams)
			.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
			.join('&')

		const response = await Axios.get(`/Negotiation/GetAll?${query}`)
		return response?.data
	}

	async getNegotiationNotification(id) {
		const response = await Axios.get(`/Negotiation/SendNegotiationNotification/${id}`)
		return response?.data
	}

	async CancelNegotiation(id) {
		const response = await Axios.post(`/Negotiation/Cancel/${id}`)
		return response?.data
	}

	async getBusinessDivisions(partnedId) {
		const response = await Axios.get(`/Partner/GetPartnerBranchKeyValue?partnerId=${partnedId}`)
		return response?.data
	}

	async getSellersName(partnedId) {
		const response = await Axios.get(`/Negotiation/GetSellersByNegotiation`)
		return response?.data
	}
}
