import React, { useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import Loader from 'components/Loader/Loader.jsx'
import moment from 'moment'

// @ Components
import CustomDate from 'components/CustomDateNew'
import { FormField } from 'components/FormField/styles'
import CustomModal from '../exportedModal'
import Button from 'components/CustomButtons/Button.jsx'

// @ Material UI
import { Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'

// @ Utils
import { CssTextField, PropsSnackExportLog } from '../../../../../utils'
import GeneralContext from '../../../../../../../GeneralContext'

// @ Utils and Styles
import * as S from '../styles'
import {
	periodOptions,
	statusOptions,
	sleep,
	defaultValues,
	handleChangePeriod,
	Open,
	IsDisabled,
	getPurchaserObjects,
	getAllTransactionsStatus,
	getPurchaserStatus,
	getStatusbyPurchaser,
	ordinationOptions,
} from './utils'
import { formatURL } from '../../../../../../../utils/formatUrl'

// PaymentGateway
import {
	ExportAllTransactionLogs,
	ExportAllPartnerLogs,
} from '../../../../../../../redux/api/paymentGateway'

const SearchForm = ({ tableRef, tab, getLogs, setTab, values, totalFiles }) => {
	const { t } = useTranslation()

	const { setIsSucess, setOrdination } = useContext(GeneralContext)
	const [openModal, setOpenModal] = useState(false)
	const [isLoaded, setIsLoaded] = useState(false)
	const [openStatus, setOpenStatus] = useState(false)
	const [optionsStatus, setOptionsStatus] = useState([])
	const [openPurchasers, setOpenPurchasers] = useState(false)
	const [optionsPurchasers, setPurchaserObjects] = useState([])
	const loadingStatus = openStatus && optionsStatus.length === 0
	const loadingPurchasers = openPurchasers && optionsPurchasers.length === 0

	const { control, handleSubmit, reset, watch, setValue } = useForm({
		defaultValues: { ...defaultValues, ordination: ordinationOptions[0] },
	})

	const { period, autorizadora, status } = watch()

	const handleSearch = e => {
		tableRef.current && tableRef.current.onQueryChange(e)
	}

	useEffect(() => {
		period && handleChangePeriod(period, setValue)
	}, [period])

	useEffect(() => {
		let active = true

		if (!loadingStatus) {
			return undefined
		}

		;(async () => {
			await sleep(1e3)

			if (active) {
				autorizadora && autorizadora.label !== ''
					? getStatusbyPurchaser(autorizadora, setOptionsStatus)
					: getAllTransactionsStatus(setOptionsStatus)
			}
		})()

		return () => {
			active = false
		}
	}, [loadingStatus])

	useEffect(() => {
		let active = true

		if (!loadingPurchasers) {
			return undefined
		}

		;(async () => {
			await sleep(1e3)

			if (active) {
				status && status.length !== 0
					? getPurchaserStatus(status, setPurchaserObjects)
					: getPurchaserObjects(setPurchaserObjects)
			}
		})()

		return () => {
			active = false
		}
	}, [loadingPurchasers])

	useEffect(() => {
		if (!openStatus) {
			setOptionsStatus([])
		}
	}, [openStatus])

	useEffect(() => {
		if (!openPurchasers) {
			setPurchaserObjects([])
		}
	}, [openPurchasers])

	const handleClickExportModal = () => {
		setOpenModal(true)
	}

	const handleExportLog = async () => {
		const obj = getLogs(watch(), tab)
		setIsLoaded(true)
		try {
			await new Promise(resolve => setTimeout(resolve, 3000))
			const logs =
				tab === 0
					? await ExportAllTransactionLogs(obj)
					: await ExportAllPartnerLogs(obj)

			if (logs.status === 200) {
				const newData = logs?.data

				const url = window.URL.createObjectURL(new Blob([newData]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'arquivo.xlsx')

				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
			handleCloseExportModal()
			setIsSucess(PropsSnackExportLog)
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoaded(false)
		}
	}

	const handleCloseExportModal = () => setOpenModal(false)

	const isValid = current => {
		const startDate = watch('dateFrom')
		const today = moment()
		return current.isAfter(startDate) && current.isBefore(today)
	}

	const validation = currentDate => {
		const dateTo = watch('dateTo')
		const today = moment()
		if (moment(dateTo, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(dateTo)) {
				return false
			}
		}
		return currentDate.isBefore(today)
	}

	const focousOut = () => {
		const dateTo = watch('dateTo')
		if (!moment(dateTo, 'DD/MM/YYYY').isValid()) {
			setValue('dateTo', '')
		}
		if (!isValid(moment(dateTo), 'DD/MM/YYYY')) {
			setValue('dateTo', '')
		}
	}

	const multipleStatus =
		optionsStatus &&
		optionsStatus.flatMap(group =>
			group.options.map(option => ({
				title: option,
				group: group.label,
				firstLetter: group.label,
			})),
		)

	return (
		<S.CustomCard>
			{isLoaded ? (
				<Loader />
			) : (
				<CustomModal
					open={openModal}
					onClick={handleExportLog}
					handleClose={handleCloseExportModal}
					onClose={handleCloseExportModal}
					btnCloseTxt={t('BUTTON_NO')}
					btnConfirmTxt={t('BUTTON_YES')}
					text={t('MODAL_EXPORT_LOGS')}
					setTab={setTab}
					tab={tab}
					values={values}
					totalFiles={totalFiles}
				/>
			)}
			<S.TabPanel>
				<S.Filter>
					<Grid
						item
						xs={12}
						style={{
							fontSize: '16px',
							color: '#717171',
							marginBottom: '20px',
							fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
						}}
					>
						{t('SEARCH_OPTIONS')}
					</Grid>
				</S.Filter>
				<Grid container spacing={'20px'}>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<Controller
							name="period"
							control={control}
							defaultValue={[]}
							render={({ onChange, value }) => (
								<Autocomplete
									disablePortal
									id="combo-box-demo"
									size="small"
									getOptionLabel={option =>
										option.label ? option.label : ''
									}
									options={periodOptions}
									value={value}
									disableClearable
									onChange={(e, value) => onChange(value)}
									renderInput={params => (
										<CssTextField
											size="small"
											style={{
												backgroundColor: '#ffffff',
												width: '100%',
											}}
											{...params}
											label={t(
												'TITLE_PARTICIPANTS_PERIOD_FILTER',
											)}
										/>
									)}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Controller
							name="dateFrom"
							defaultValue=""
							control={control}
							as={
								<CustomDate
									size="small"
									variant="outlined"
									label={t('DATETIME_FROM')}
									maxDate={new Date().getTime()}
									timeFormat={false}
									open={Open(watch('period'))}
									disabled={IsDisabled(watch('period'))}
									isValidDate={e => validation(e)}
								/>
							}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Controller
							name="dateTo"
							defaultValue=""
							control={control}
							as={
								<CustomDate
									size="small"
									variant="outlined"
									label={t('DATETIME_TO')}
									timeFormat={false}
									onBlur={focousOut}
									isValidDate={isValid}
									open={Open(watch('period'))}
									disabled={IsDisabled(watch('period'))}
									maxDate={new Date().getTime()}
								/>
							}
						/>
					</Grid>
					{tab === 0 ? (
						<Grid item xs={12} sm={12} md={4} lg={4}>
							<Controller
								name="autorizadora"
								control={control}
								defaultValue={[]}
								render={({ onChange, value }) => (
									<Autocomplete
										disablePortal
										id="combo-box-demo"
										size="small"
										open={openPurchasers}
										onOpen={() => {
											setOpenPurchasers(true)
										}}
										onClose={() => {
											setOpenPurchasers(false)
										}}
										isOptionEqualToValue={(option, value) =>
											option.label === value.label
										}
										getOptionLabel={option => option.label}
										options={optionsPurchasers}
										loading={loadingPurchasers}
										value={value}
										disableClearable
										onChange={(e, value) => onChange(value)}
										renderInput={params => (
											<CssTextField
												{...params}
												size="small"
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												label={t(
													'MENU_PAYMENT_AUTHORIZING',
												)}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{loadingPurchasers ? (
																<CircularProgress
																	color="inherit"
																	size={20}
																/>
															) : null}
															{
																params
																	.InputProps
																	.endAdornment
															}
														</React.Fragment>
													),
												}}
											/>
										)}
									/>
								)}
							/>
						</Grid>
					) : (
						''
					)}
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<Controller
							name="status"
							control={control}
							defaultValue={[]}
							render={({ onChange, value }) =>
								tab === 0 ? (
									<Autocomplete
										multiple
										id="grouped-demo"
										options={multipleStatus.sort(
											(a, b) =>
												-b.firstLetter.localeCompare(
													a.firstLetter,
												),
										)}
										groupBy={option => option.firstLetter}
										onChange={(e, value) => onChange(value)}
										open={openStatus}
										onOpen={() => {
											setOpenStatus(true)
										}}
										onClose={() => {
											setOpenStatus(false)
										}}
										loading={loadingStatus}
										value={value}
										disableClearable
										getOptionLabel={option => option.title}
										renderInput={params => (
											<CssTextField
												{...params}
												size="small"
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												label={t('FIELD_STATUS')}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{loadingStatus ? (
																<CircularProgress
																	color="inherit"
																	size={20}
																/>
															) : null}
															{
																params
																	.InputProps
																	.endAdornment
															}
														</React.Fragment>
													),
												}}
											/>
										)}
									/>
								) : (
									<Autocomplete
										disablePortal
										id="combo-box-demo"
										size="small"
										options={statusOptions}
										getOptionLabel={option =>
											option.label ? option.label : ''
										}
										value={value}
										disableClearable
										onChange={(e, value) => onChange(value)}
										renderOption={(props, option) => (
											<div {...props}>
												<S.IconStatus
													style={{
														backgroundColor:
															option.value === 2
																? '#F05B4F'
																: '#5CB860',
													}}
												/>
												<p style={{ margin: '10px' }}>
													{option.label}
												</p>
											</div>
										)}
										renderInput={params => (
											<CssTextField
												{...params}
												size="small"
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												label={t('FIELD_STATUS')}
											/>
										)}
									/>
								)
							}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sm={12}
						md={tab === 0 ? 8 : 12}
						lg={tab === 0 ? 8 : 12}
					>
						<Controller
							name="history"
							control={control}
							defaultValue=""
							as={
								<FormField
									variant="outlined"
									style={{
										backgroundColor: '#ffffff',
										width: '100%',
									}}
									formControlProps={{
										fullWidth: true,
									}}
									size="small"
									label={t('FIELD_SEARCH_LOG')}
								/>
							}
						/>
					</Grid>
				</Grid>
				<S.Buttons>
					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							gap: '0.813rem',
						}}
					>
						<Button
							size="sm"
							variant="contained"
							color="greenButtonColor"
							className="button-wrapper"
							onClick={handleSubmit(handleSearch)}
						>
							{t('BUTTON_FIND')}
						</Button>
						<Button
							size="sm"
							onClick={() => {
								reset(defaultValues)
								formatURL(defaultValues)
								tableRef.current &&
									tableRef.current.onQueryChange(
										defaultValues,
									)
							}}
							className="button-wrapper"
							color="greenBorderButtonColor"
						>
							{t('BUTTON_CLEAN_FILTER')}
						</Button>
						<Button
							size="sm"
							variant="contained"
							color="greenButtonColor"
							className="button-wrapper"
							onClick={event => handleClickExportModal(event)}
						>
							<i
								className="icon-exportar_relatorios"
								style={{ marginRight: '5px' }}
							></i>
							{t('BUTTON_EXPORT_LOG')}
						</Button>
					</div>
				</S.Buttons>
			</S.TabPanel>
		</S.CustomCard>
	)
}

SearchForm.propTypes = {
	tableRef: PropTypes.func,
	getLogs: PropTypes.func,
	tab: PropTypes.number,
	setTab: PropTypes.func,
	values: PropTypes.object,
	totalFiles: PropTypes.number,
}

export default SearchForm
