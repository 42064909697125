import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'

import * as _ from 'lodash'

// UTILS
import compose from 'utils/compose'
import Loader from 'components/Loader/Loader.jsx'
import withStyles from '@material-ui/core/styles/withStyles'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { GetCurrentPartner, changePassword, SweetAlert, HideAlert, ShowLoader, HideLoader } from '../../../redux/actions'

import { Formik, withFormik } from 'formik'
import * as Yup from 'yup'
import Form from './Component/ChangePassword'
// MOMENT JS
import * as moment from 'moment'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { getProgramTitle } from '../../../utils/utils'

const validationSchema = Yup.object({
	oldPassword: Yup.string().required('Senha antiga é obrigatório'),
	newPassword: Yup.string()
		.min(8, 'mínimo de 8 caracteres')
		.max(20, 'máximo de 20 caracteres')
		.required('Senha nova é obrigatório'),
	confirmPassword: Yup.string()
		.min(8, 'mínimo de 8 caracteres')
		.max(20, 'máximo de 20 caracteres')
		.oneOf([Yup.ref('newPassword'), null], 'Campos senha nova não batem.')
		.required('Confirme a nova senha'),
	//confirmPassword: Yup.string().required().oneOf([Yup.ref('newPassword'), null], 'Campos senha nova não batem.')
})
// alteração para dev-qa
class ChangePassword extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			id: '',
			oldPassword: '',
			newPassword: '',
			confirmPassword: '',
		}
		if (localStorage.getItem('i18nextLng') !== null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		this.props.GetCurrentPartner()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.userData !== this.props.userData) {
			this.setState({ id: this.props.user.id })

			this.props.HideLoader()
		}

		if (this.props.changePasswordData && prevProps.changePasswordData !== this.props.changePasswordData) {
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={null}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
						this.props.history.push(`/auth/login`)
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_SUCCESS')}
				</Alert>,
			)
			this.formik.current && this.formik.current.resetForm()
		}

		if (this.props.changePasswordFailed && prevProps.changePasswordFailed !== this.props.changePasswordFailed) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Erro'}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{_.isArray(this.props.changePasswordFailed) ? (
						this.props.changePasswordFailed.map(error => [<div>{this.props.t(error.code)}</div>])
					) : (
						<div>{this.props.t(this.props.changePasswordFailed.code)}</div>
					)}
				</Alert>,
			)
		}
	}

	handleSave = values => {
		this.props.ShowLoader(<Loader />)
		this.props.changePassword(this.state.id, values)
	}

	render() {
		const { t, classes } = this.props
		const { oldPassword, newPassword, confirmPassword } = this.state
		const values = { oldPassword, newPassword, confirmPassword }
		return (
			<Fragment>
				<DocumentTitle title={getProgramTitle()} />
				<Formik
					ref={this.formik}
					render={props => <Form {...props} />}
					validationSchema={validationSchema}
					initialValues={values}
					onSubmit={this.handleSave.bind(this)}
				/>
			</Fragment>
		)
	}
}
const mapStateToProps = state => {
	return {
		user: state.authentication.user,
		userData: state.users.userData,
		changePasswordData: state.authentication.changePassword,
		changePasswordFailed: state.authentication.changePasswordFailed,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetCurrentPartner,
			changePassword,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(extendedFormsStyle),
	withTranslation(),
)(ChangePassword)
