import React, { Fragment } from 'react'
import * as moment from 'moment'
import MaskedInput from 'react-text-mask'
import { withTranslation } from 'react-i18next'

// @material-ui/core components
import { withStyles } from '@material-ui/core/styles'
import { FormLabel, FormControlLabel, Grid } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer.jsx'
import { ControlSwitch } from 'components/CustomSwitch/styles.js'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import AvatarUpload from 'components/CustomUpload/AvatarUpload.jsx'
import defaultAvatar from 'assets/img/fazendeiro.png'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import { CssTextField } from 'components/CssTextField/CssTextField'

// style
import styles from '../profile.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import userProfileStyles from 'assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx'
import { InputWrapper } from './styles.js'

// UTILS
import compose from 'utils/compose'

const ProfileInputStyle = {
	...extendedFormsStyle,
	...userProfileStyles,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	labelTexRF: {
		lineHeight: '0 !important',
		top: '-8px',
	},
}

function CPFMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'.',
				/\d/,
				/\d/,
				/\d/,
				'-',
				/\d/,
				/\d/,
			]}
			placeholderChar={'\u2000'}
		/>
	)
}

class Profile extends React.Component {
	constructor(props) {
		super(props)

		if (localStorage.getItem('i18nextLng') !== null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	render() {
		const {
			t,
			classes,
			values,
			errors,
			touched,
			handleSubmit,
			handleChange,
			handleEdit,
			handleCancel,
			disabledForm,
			setFieldTouched,
		} = this.props

		const change = (name, e) => {
			if (!name === 'image') {
				e.persist()
			}
			handleChange(e)
			setFieldTouched(name, true, false)
		}

		return (
			<Fragment>
				<GridContainer>
					<GridItem xs={12} sm={12}>
						<div
							style={{
								marginBottom: '15px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Section
								title={t('TITLE_PROFILE')}
								style={{ flexGrow: 1 }}
							/>
							<div style={{ marginLeft: '15px' }}>
								{disabledForm && (
									<Button
										size="sm"
										variant="contained"
										color="greenButtonColor"
										onClick={handleEdit}
									>
										{t('BUTTON_EDIT')}
									</Button>
								)}
								{!disabledForm && (
									<>
										<Button
											size="sm"
											variant="contained"
											style={{ marginRight: '8px' }}
											color="greenBorderButtonColor"
											onClick={handleCancel}
										>
											{t('BUTTON_CANCEL')}
										</Button>
										<Button
											size="sm"

											variant="contained"
											color="greenButtonColor"
											className={styles.Button}
											onClick={handleSubmit}
										>
											{t('BUTTON_SAVE')}
										</Button>
									</>
								)}
							</div>
						</div>
					</GridItem>
					<GridItem xs={6} md={4}>
						{disabledForm && (
							<div
								className="picture-container-disabled"
								style={{ marginBottom: '37px' }}
							>
								<div
									className="picture"
									style={{ margin: '5px 0 0 0' }}
								>
									<img
										style={{
											objectFit: 'cover',
											height: '100px',
											width: '100px',
										}}
										className="picture-src"
										src={
											values.image && values.image !== ''
												? values.image
												: defaultAvatar
										}
										alt="..."
									/>
								</div>
							</div>
						)}
						{!disabledForm && (
							<AvatarUpload
								avatar
								addButtonProps={{
									color: 'success',
									round: true,
								}}
								changeButtonProps={{
									color: 'success',
									round: true,
								}}
								removeButtonProps={{
									color: 'danger',
									round: true,
									disabled: disabledForm,
								}}
								onChange={change.bind(null, 'image')}
								value={values.image}
							/>
						)}
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} md={12} style={{ marginTop: '7px' }}>
						<CssTextField
							fullWidth
							label={
								<CustomRequiredField value="FIELD_REGISTER_NAME" />
							}
							id="name"
							name="name"
							error={touched.name && Boolean(errors.name)}
							value={values.name}
							onChange={change.bind(null, 'name')}
							variant="outlined"
							margin="dense"
							disabled={disabledForm}
						/>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} md={6} style={{ marginTop: '7px' }}>
						<CssTextField
							fullWidth
							label={
								<CustomRequiredField value="FIELD_REGISTER_EMAIL" />
							}
							id="email"
							name="email"
							error={touched.email && Boolean(errors.email)}
							value={values.email}
							onChange={change.bind(null, 'email')}
							variant="outlined"
							margin="dense"
							disabled={disabledForm}
						/>
					</GridItem>
					<GridItem xs={12} md={6} style={{ marginTop: '7px' }}>
						<CssTextField
							fullWidth
							label={
								<CustomRequiredField value="FIELD_REGISTER_DOCUMENT" />
							}
							id="username"
							name="username"
							error={touched.username && Boolean(errors.username)}
							value={values.username}
							onChange={change.bind(null, 'username')}
							variant="outlined"
							margin="dense"
							disabled={disabledForm}
							InputProps={{ inputComponent: CPFMaskCustom }}
						/>
					</GridItem>
				</GridContainer>
				<Grid style={{ paddingTop: '25px', paddingRight: '15px' }}>
					<Section title={t('ACCOUNT_PREFERENCES')} />
				</Grid>
				<GridContainer>
					<GridItem xs={12} style={{ marginTop: '7px' }}>
						<FormLabel
							className={
								classes.labelHorizontal +
								' ' +
								(disabledForm ? styles.DisabledCursor : '') +
								' ' +
								'switch-label'
							}
							style={{
								padding: '0 0 10px 0',
								width: '100%',
								color: 'rgba(0, 0, 0, 0.50)',
								fontSize: '0.7rem',
								fontWeight: 400,
							}}
						>
							{t('FIELD_ALLOW_LOGON_NOTIFICATION')}
						</FormLabel>
						<InputWrapper
							style={{ position: 'relative', bottom: '10px' }}
						>
							<FormControlLabel
								style={{ paddingLeft: '5px' }}
								control={
									<ControlSwitch
										name="viewImportantAlerts"
										id="viewImportantAlerts"
										disabled={disabledForm}
										checked={values.viewImportantAlerts}
										onChange={change.bind(
											null,
											'viewImportantAlerts',
										)}
										value={values.viewImportantAlerts}
										classes={{
											switchBase: classes.switchBase,
											checked: classes.switchChecked,
											thumb: classes.switchIcon,
											track: classes.switchBar,
											disabled: classes.disabled,
										}}
									/>
								}
								classes={{
									label:
										classes.label +
										' ' +
										(disabledForm
											? styles.DisabledCursor
											: ''),
								}}
								label={
									values.viewImportantAlerts ? 'Sim' : 'Não'
								}
							/>
						</InputWrapper>
					</GridItem>
				</GridContainer>
			</Fragment>
		)
	}
}

export default compose(
	withTranslation(),
	withStyles(ProfileInputStyle),
)(Profile)
