/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import * as Yup from 'yup'
import { Controller, useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'
import { Grid, Fade } from '@material-ui/core'

import { FormField } from 'components/FormField/styles'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { ModalContainer, PaperContainer } from './styles'
import * as S from './styles'
import Button from 'components/CustomButtons/Button.jsx'
import Loader from 'components/Loader/Loader'
import { PartnerValidatePartner } from '../../../../redux/api/partner'
import { yupResolver } from '@hookform/resolvers'

function ConfirmModal({ className, text, error, maxWidth, minWidth, onCancel, open, shadow, t, enablePaymentMethods }) {
	const [loading, setLoading] = useState(false)

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.required('Senha obrigatória.')
			.nullable(),
	})

	const { handleSubmit, control, errors, setError } = useForm({
		resolver: yupResolver(validationSchema),
	})

	async function onSubmit(value) {
		setLoading(true)

		try {
			const response = await PartnerValidatePartner(value)

			if (response === true) {
				enablePaymentMethods(response)
				onCancel()
				return
			}

			setError('password', {
				type: 'manual',
				message: 'Senha inválida.',
			})
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<ModalContainer
			open={open}
			shadow={shadow}
			onClose={onCancel}
			maxwidth={maxWidth}
			minwidth={minWidth}
			disableEnforceFocus
			className={className}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
		>
			{open && (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={'0px 60px'} maxwidth={maxWidth}>
						{loading && <Loader />}
						<GridContainer xs={12} sm={12} style={{ margin: '50px 20px 30px 2px' }}>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
							<S.Text_Focused>{text}</S.Text_Focused>
						</GridItem>
						<form style={{ width: '100%' }} onSubmit={handleSubmit(onSubmit)}>
							<GridContainer style={{ marginTop: '30px' }}>
								<Grid
									item
									xs={8}
									style={{
										margin: '0 auto',
										paddingLeft: '10px',
									}}
								>
									<Controller
										as={<FormField fullWidth label="Senha *" type="password" error={!!errors.password} helperText={t(errors?.password?.message)} />}
										defaultValue=""
										name="password"
										control={control}
									/>
								</Grid>
							</GridContainer>
							<S.Text_Error>{t(error ? error : '')}</S.Text_Error>
							<GridContainer
								style={{
									padding: '10px 0 0 20px',
									marginBottom: '50px',
									marginTop: '35px',
								}}
							>
								<GridItem xs={12} sm={12} style={{ display: 'flex', justifyContent: 'center', gap: '15px' }}>
									<Button size="sm" variant="contained" color={'greenBorderButtonColor'} onClick={onCancel}>
										Cancelar
									</Button>

									<Button size="sm" type="submit" variant="contained" color="greenButtonColor">
										Confirmar
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</PaperContainer>
				</Fade>
			)}
		</ModalContainer>
	)
}

export default withTranslation()(ConfirmModal)
