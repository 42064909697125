import React, { useEffect } from 'react'
import { Box, Divider, Grid } from '@mui/material'
import { ButtonClose } from 'components/NewButton/styles'
import { useTranslation } from 'react-i18next'
import {
	Breadcrumb,
	LabelText,
	MuiBox,
	MuiTypography,
	MuiStack,
	GridContainer,
	GridItem,
	AlertIcon,
	NegotiationHorsIcon,
	NegotiationCalendarIcon,
	AttentionIcon,
	Button,
	formatCellphone,
	formatCPF,
	MuiGrid,
} from '../../../components/storybook'
import { Container, CustomInput } from './styles'
import { useParams } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import useNegotiationDetails from './Hooks/useNegotiationDetails'
import {
	formatDate,
	formatISOFromDate,
	formatISOFromTime,
	validateCurrencyMask,
	validateCurrencyMaskLimited,
	validateCurrencyMaskOnDetails,
} from '../Negotiation/Functions'
import { getDefaultColor } from '../../Partner/PartnerProfile/Functions'
import { useHistory } from 'react-router-dom'
import { DivisionItems } from './Components/DivisionItems'

export function NegotiationDetails() {
	const { t } = useTranslation()
	const { id } = useParams()
	const defaultColor = getDefaultColor()
	const history = useHistory()

	const { getNegotiationDetailsById, negotiationDetails } = useNegotiationDetails()

	const negotiationStatus = {
		ACTIVE: 'Negociação ativa',
		Active: 'Negociação ativa',
		INACTIVE: 'Negociação inativa',
		Inactive: 'Negociação inativa',
		Expired: 'Negociação expirada',
		EXPIRED: 'Negociação expirada',
		Canceled: 'Negociação cancelada',
		CANCELED: 'Negociação cancelada',
		ActivePartial: 'Crédito parcial ativo',
		Partial: 'Crédito parcial ativo',
		SaleMade: 'Venda realizada',
		SALEMADE: 'Venda realizada',
	}

	useEffect(() => {
		if (id) getNegotiationDetailsById(id)
	}, [id])


	return (
		<>
			<MuiGrid container>
				<MuiGrid lg={11} sm={12}>
					<Breadcrumb hrf={[]} items={['Vendas', 'Negociação', 'Visualizar']} />
				</MuiGrid>
				<MuiGrid lg={1} sm={12}>
					<Button
						text="Fechar"
						typeVariation="CleanButton"
						onClick={() => history.push('/negotiation')}
						sx={{
							border: '1px solid #C8C8C8',
							width: '100%',
							height: '40px',
							color: '#C81010!important',
							textTransform: 'unset',
						}}
					/>
				</MuiGrid>
			</MuiGrid>

			{/******* Negotiator infos ********/}
			<MuiGrid container pt={3}>
				<MuiGrid lg={12} sm={12} mb={3}>
					<MuiTypography variant="body1" fontWeight={600} color={defaultColor}>
						{t('NEGOTIATION.PERSONAL_DATA')}
					</MuiTypography>
				</MuiGrid>
				<MuiGrid lg={4} sm={12}>
					<LabelText label={[t('NEGOTIATION.CPF')]} value={formatCPF(negotiationDetails?.consumer?.cpf) ?? '-'} />
				</MuiGrid>
				<MuiGrid lg={5} sm={12}>
					<LabelText label={[t('NEGOTIATION.NAME')]} value={negotiationDetails?.consumer?.name ?? '-'} />
				</MuiGrid>
				<MuiGrid lg={3} sm={12}>
					<MuiStack direction="row" justifyContent="space-between" width="100%">
						<LabelText label={[t('NEGOTIATION.MEMBER_SINCE')]} value={formatISOFromDate(negotiationDetails?.consumer?.fidelityDate)} />
					</MuiStack>
				</MuiGrid>

				<MuiGrid lg={4} sm={12} mt={3}>
					<LabelText label={[t('NEGOTIATION.CELLPHONE')]} value={formatCellphone(negotiationDetails?.consumer?.phoneNumber2)} />
				</MuiGrid>
				<MuiGrid lg={8} sm={12} mt={3}>
					<LabelText label={[t('NEGOTIATION.EMAIL')]} value={negotiationDetails?.consumer?.email ?? '-'} />
				</MuiGrid>
				<MuiGrid lg={12} sm={12} mt={4}>
					<Divider />
				</MuiGrid>
			</MuiGrid>

			{/******* Negotiation infos ********/}
			<MuiGrid container pt={4}>
				<MuiGrid lg={12} sm={12}>
					<MuiTypography variant="body1" fontWeight={600} color={defaultColor}>
						{t('NEGOTIATION.NEGOTIATION')}
					</MuiTypography>
				</MuiGrid>
				<MuiGrid lg={12} sm={12} mt={3}>
					<MuiStack
						direction="row"
						alignItems="center"
						sx={{
							background: '#F2F2F2',
							padding: '8px 15px',
							borderRadius: '5px',
						}}
					>
						<AttentionIcon size={30} color="#2BA2C8" />
						<MuiTypography variant="body1" ml={1} sx={{ color: '#2BA2C8' }}>
							{negotiationStatus[(negotiationDetails?.status?.value)] ?? 'Negociação ativa'}
						</MuiTypography>
					</MuiStack>
				</MuiGrid>
			</MuiGrid>
			<MuiGrid container mt={3}>
				<MuiGrid lg={3} sm={12}>
					<MuiStack direction="row" justifyContent="space-between">
						<MuiBox>
							<MuiTypography variant="body2" mb={1}>
								{t('NEGOTIATION.TRADING_DATE')}
							</MuiTypography>
							<MuiStack direction="row" spacing={10} alignItems="center">
								<LabelText
									label="De"
									value={
										negotiationDetails?.validFrom ? (
											<MuiStack direction="row" justifyContent="space-between">
												<MuiBox mr={1}>{formatISOFromDate(negotiationDetails?.validFrom) ?? '-'}</MuiBox>
												<NegotiationCalendarIcon size={20} color="#C8C8C8" />
											</MuiStack>
										) : (
											'-'
										)
									}
								/>
								<LabelText
									label="Até"
									value={
										negotiationDetails?.validUntil ? (
											<MuiStack direction="row" justifyContent="space-between">
												<MuiBox mr={1}>{formatISOFromDate(negotiationDetails?.validUntil) ?? '-'}</MuiBox>
												<NegotiationCalendarIcon size={20} color="#C8C8C8" />
											</MuiStack>
										) : (
											'-'
										)
									}
								/>
							</MuiStack>
						</MuiBox>
					</MuiStack>
				</MuiGrid>
			</MuiGrid>

			<MuiGrid container mt={3}>
				<MuiGrid lg={12}>
					<LabelText label={[t('NEGOTIATION.CONTACT_NUMBER')]} value={negotiationDetails?.contractNumber ?? '-'} />
				</MuiGrid>
			</MuiGrid>

			<MuiGrid container mt={3}>
				<MuiGrid item lg={12} mb={3}>
					<LabelText label={[t('NEGOTIATION.DIVISION_NEGOTIATION')]} value={negotiationDetails?.divisions.length < 1 ? '-' : ''} />
					{negotiationDetails?.divisions.length >= 1 && <DivisionItems divisions={negotiationDetails?.divisions ?? []} />}
				</MuiGrid>
				<MuiGrid lg={12} sm={12}>
					<LabelText label={[t('NEGOTIATION.GROUPER')]} value={negotiationDetails?.grouper?.value ?? '-'} />
				</MuiGrid>
			</MuiGrid>

			{/******* Product infos ********/}
			<MuiGrid container mt={3}>
				<MuiGrid lg={3} sm={12}>
					<LabelText label={[t('NEGOTIATION.CODE_SKU')]} value={negotiationDetails?.product?.key ?? '-'} />
				</MuiGrid>
				<MuiGrid lg={5} sm={12}>
					<LabelText label={[t('NEGOTIATION.PRODUCT')]} value={negotiationDetails?.product?.value ?? '-'} />
				</MuiGrid>
				<MuiGrid lg={2} sm={12}>
					<LabelText label={t('NEGOTIATION.ITEM_QUANTITY')} value={negotiationDetails?.sellQuantity?.totalAllowed || '-'} />
				</MuiGrid>
				<MuiGrid lg={2} sm={12}>
					<MuiStack alignItems="end">
						<LabelText
							label={t('NEGOTIATION.PARTIALLY_CONSUMED')}
							value={
								(negotiationDetails?.sellQuantity?.totalAllowed &&
									`${negotiationDetails?.sellQuantity?.totalSold} / ${negotiationDetails?.sellQuantity?.totalAllowed}`) ||
								'-'
							}
						/>
					</MuiStack>
				</MuiGrid>
			</MuiGrid>

			<MuiGrid container mt={3}>
				<MuiGrid lg={12} sm={12}>
					<LabelText label={t('NEGOTIATION.BALANCE_BLOCKED')} value={negotiationDetails?.displayBlockedValue === true ? 'Exibir' : '-'} />
				</MuiGrid>
				<MuiGrid lg={12} sm={12} mt={3}>
					<LabelText
						label={[t('NEGOTIATION.BENEFIT')]}
						value={negotiationDetails?.typeOfBenefit?.value ? t(`NEGOTIATION.${negotiationDetails?.typeOfBenefit?.value}`) : '-'}
					/>
				</MuiGrid>
				<MuiGrid lg={4} sm={12} mt={3}>
					<LabelText
						label={[t('NEGOTIATION.CONFIGURATION_BENEFIT')]}
						value={
							(negotiationDetails?.typeOfBenefit?.value === 'Cashback' && negotiationDetails?.benefitConfigType?.value === 'Fixed' && 'Fixo') ||
							(negotiationDetails?.typeOfBenefit?.value === 'Cashback' &&
								negotiationDetails?.benefitConfigType?.value === 'Manual' &&
								'Percentual') ||
							(negotiationDetails?.benefitConfigType?.value === 'Percentage' && 'Percentual') ||
							(negotiationDetails?.benefitConfigType?.value && t(`NEGOTIATION.${negotiationDetails?.benefitConfigType?.value}`)) ||
							'-'
						}
					/>
				</MuiGrid>
				<MuiGrid lg={4} sm={12} mt={3}>
					<LabelText label={[t('NEGOTIATION.VALUE')]} value={negotiationDetails ? validateCurrencyMaskOnDetails(negotiationDetails) : '-'} />
				</MuiGrid>
				<MuiGrid lg={4} sm={12} mt={3}>
					<LabelText
						label={'Limite do valor do benefício'}
						value={negotiationDetails?.maximumBenefitGenerationLimit ? validateCurrencyMaskLimited(negotiationDetails) : '-'}
					/>
				</MuiGrid>
				<MuiGrid lg={4} sm={12} mt={3}>
					<LabelText label={'Transferência parcial'} value={negotiationDetails?.allowPartialAvailabilityOfValue ? 'Permitido' : 'Não permitido'} />
				</MuiGrid>
				<MuiGrid lg={4} sm={12} mt={3}>
					<LabelText
						label={t('NEGOTIATION.TRANSFER')}
						value={negotiationDetails?.allowTransfer === true ? 'Disponibilizado' : 'Não disponibilizado'}
					/>
				</MuiGrid>
				<MuiGrid lg={4} sm={12} mt={3}>
					<LabelText label={'Data de transferência'} value={formatISOFromDate(negotiationDetails?.transferValidAfter) ?? '-'} />
				</MuiGrid>
			</MuiGrid>

			{/******* Seller infos ********/}
			<MuiGrid container mt={3}>
				<MuiGrid lg={12} sm={12}>
					<LabelText label={[t('NEGOTIATION.SELLER_NAME')]} value={negotiationDetails?.sellersName ?? '-'} />
				</MuiGrid>
				<MuiGrid lg={12} sm={12} mt={3}>
					<LabelText label={[t('NEGOTIATION.DESCRIPTION')]} value={negotiationDetails?.description ?? '-'} />
				</MuiGrid>
			</MuiGrid>
		</>
	)
}
