import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled.div`
	margin-bottom: 1.25rem;
	background-color: #f6f6f6;
	padding: 20px 15px;
	padding-bottom: 16px;

	.MuiGrid-spacing-xs-4 > .MuiGrid-item {
		padding: 15px !important;
		margin: 0px !important;
	}

	.MuiFormControl-root {
		background-color: #fff;
		color: rgba(0, 0, 0, 0.6);
	}

	.MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: #ffffff !important;
			background-color:  ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor}
		}
	}

	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}


	.MuiOutlinedInput-root:hover fieldset {
		border-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
	}
`

export const moreFilterBox = styled.div`
	display: flex;
	justify-content: space-between;
	margin: 5px 15px 0 15px;
	width: 100%;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
	}

	.iconButton {
		padding: 4px;
		color: ${(props) => props.getBtnElColor} !important;
		border: 1px solid;
		border-color: ${(props) => props.getBtnElColor} !important;
		margin: auto 0;
		&:hover {
			color: #fff !important;
			background-color: ${(props) => props.getBtnElColor} !important;
		}
	}
`
export const moreFilterBtnBox = styled.div`
	align-items: center;
	cursor: pointer;
	color: ${(props) => props.getBtnElColor};
	display: flex;
	width: 200px;
`
export const btnFilterBox = styled.div`
	display: flex;

	@media (max-width: 768px) {
		justify-content: flex-end;
	}
`

export const CustomGridDate = styled(Grid)`
	display: flex;
	justify-content: space-between;
	gap: 30px;
`
