import styled from 'styled-components/macro'

import Button from 'components/CustomButtons/Button.jsx'

export const BtnSubmit = styled(Button)`
	background-color: ${(props) => props.backgroundColor} !important;
	margin: 10px 0 !important;
	float: right;
	&:hover,
	&:focus {
		background-color: ${(props) => props.backgroundColorOver} !important;
	}
	color: ${(props) => props.color} !important;
`
