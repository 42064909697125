import React, { useEffect } from 'react'
import * as _ from 'lodash'

// @material-ui/icons
import { Edit } from '@material-ui/icons'

import {
	TablePagination,
} from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

// style
import * as S from '../../styles'
import styles from '../../statement.module.scss'
import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable'
import { CssTextField } from '../../styles'
import { ContainerTitle, GroupButtons, GroupContainer, Title } from './styles'
import { VerifyLth } from '../Utils'

export const Client = (
	{
		handleChange,
		classes,
		maximumNegotiation,
		searchName,
		searchCPF,
		themeColor,
		handleClearFilter,
		handleFilter,
		t,
		tableRef,
		pagination,
		formatCnpjCpf,
		handleEdit,
		results,
		setState,
		getTableData,
		loading,
		handleChangePage,
		handleSaveData,
		isDisabled,
		checkedChange,
	}
) => {

	useEffect(() => {
		checkedChange()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [maximumNegotiation])

	let errors = VerifyLth(maximumNegotiation)

	return (
		<>
			<ContainerTitle
				style={{
					marginTop: '10px',
				}}
			>
				<Title>
					Percentual padrão de cashback
				</Title>
				<Button
					size="sm"
					variant="contained"
					color="greenButtonColor"
					onClick={handleSaveData}
					disabled={isDisabled || Boolean(errors)}
					className="button-container"
					style={{
						marginRight: '0'
					}}
				>
					{t('BUTTON_SAVE')}
				</Button>
			</ContainerTitle>
			<GridContainer
				style={{
					marginTop: '16px',
					marginBottom: '16px',
				}}
			>
				<GridItem xs={12} sm={2} md={2}>
					<CssTextField
						fullWidth
						label="Máximo"
						id="maximumNegotiation"
						name="maximumNegotiation"
						onChange={handleChange}
						value={maximumNegotiation}
						variant="outlined"
						margin="dense"
						style={{
							backgroundColor: '#fff',
						}}
						InputProps={{
							endAdornment: (
								<span
									style={{
										marginRight:
											'5px',
									}}
								>
									%
								</span>
							),
							inputComponent:
								DoubleFormatCustom,
						}}
						maxLength={6}
						helperText={Boolean(errors) && errors}
						error={Boolean(errors)}
						inputProps={{
							max_val: 100,
							maxLength: 6,
						}}
						InputLabelProps={{
							classes: {
								disabled:
									classes.labelTexRF,
							},
						}}
					/>
				</GridItem>
			</GridContainer>

			<div
				className="greyInfo2"
				style={{
					marginBottom: '8px',
					color:
						themeColor &&
						themeColor.titles &&
						themeColor.titles
							.highlight &&
						themeColor.titles
							.highlight.textColor &&
						themeColor.titles
							.highlight.textColor +
						' !important',
				}}
			>
				<i
					className="icon-detalhe_campo"
					style={{
						fontSize: '30px',
						marginRight: '10px',
					}}
				/>
				Percentual padrão será definido para o
				cashback do cliente.
			</div>
			{/* FILTER */}
			<GroupContainer >
				<GridItem xs={12} sm={6} md={6}>
					<CssTextField
						className={styles.TextField}
						fullWidth
						label="Nome / Razão social"
						id="searchName"
						name="searchName"
						value={searchName}
						onChange={handleChange}
						variant="outlined"
						size='small'
						style={{
							backgroundColor: '#fff',
						}}
					/>
				</GridItem>

				<GridItem xs={12} sm={6} md={6}>
					<CssTextField
						className={styles.TextField}
						fullWidth
						label="CPF / CNPJ"
						id="searchCPF"
						name="searchCPF"
						value={searchCPF}
						onChange={handleChange}
						variant="outlined"
						size='small'
						style={{
							backgroundColor: '#fff',
						}}
					/>
				</GridItem>
				<GridContainer>
					<GroupButtons
						xs={12}
						sm={12}
						md={12}
						style={{ marginTop: 20 }}
					>
						<Button
							size="sm"
							variant="contained"
							style={{ margin: 0 }}
							color="greenBorderButtonColor"
							onClick={() =>
								handleClearFilter()
							}
						>
							{t('BUTTON_CLEAN_FILTER')}
						</Button>
						<Button
							size="sm"
							style={{ margin: 0 }}
							variant="contained"
							color="greenButtonColor"
							onClick={() =>
								handleFilter(false)
							}
						>
							{t('BUTTON_FIND')}
						</Button>
					</GroupButtons>
				</GridContainer>
			</GroupContainer>
			{/* TABLE */}
			<S.GridBox>
				<S.GridContent
					colorTitle={
						themeColor &&
						themeColor.titles &&
						themeColor.titles
							.highlight &&
						themeColor.titles
							.highlight.textColor &&
						themeColor.titles
							.highlight.textColor
					}
					style={{ width: '100%' }}
				>
					<CustomMaterialTable
						tableRef={tableRef}
						style={{
							width: '100%',
							marginBottom: '70px',
						}}
						noToolbar
						components={{
							Pagination: (props) => {
								return (
									<TablePagination
										{...props}
										rowsPerPageOptions={[
											10, 20, 30,
											50, 100,
										]}
										count={
											pagination.recordCount
										}
										page={
											pagination.currentPage -
											1
										}
										rowsPerPage={
											pagination.pageSize
										}
										onChangePage={
											handleChangePage
										}
									/>
								)
							},
						}}
						title=""
						options={{
							emptyRowsWhenPaging: false,
							search: false,
							toolBar: false,
							pageSize:
								pagination.pageSize,
						}}
						columns={[
							{
								title: 'Nome / Razão social',
								field: 'name',
								width: '50%',
								headerStyle: {
									textAlign: 'left',
									fontSize: '15px',
								},
							},
							{
								title: 'CPF / CNPJ',
								field: 'cpf',
								width: '35%',
								cellStyle: {
									textAlign: 'start',
								},
								headerStyle: {
									textAlign: 'start',
									fontSize: '15px',
								},
								render: (props) =>
									formatCnpjCpf(
										props.cpf,
									),
							},
							{
								title: 'Mínimo',
								width: '5%',
								cellStyle: {
									textAlign: 'start',
								},
								headerStyle: {
									textAlign: 'start',
									fontSize: '15px',
								},
								render: (props) =>
									props.minimumNegotiation !==
										'-'
										? props.minimumNegotiation +
										'%'
										: '-',
							},
							{
								title: 'Máximo',
								width: '5%',
								render: (props) =>
									props.maximumNegotiation !==
										'-'
										? props.maximumNegotiation +
										'%'
										: '-',
								headerStyle: {
									textAlign: 'left',
									fontSize: '15px',
								},
							},
							{
								title: 'Ações',
								field: 'actions',
								cellStyle: {
									textAlign: 'end',
								},
								headerStyle: {
									textAlign: 'end',
									fontSize: '15px',
								},
								sorting: false,
								render: (props) => (
									<S.GridButton
										justIcon
										size="sm"
										color="greenButtonColor"
										title={t(
											'BUTTON_EDIT',
										)}
										onClick={() =>
											handleEdit(
												props,
											)
										}
									>
										<Edit
											className={"op-icon"}
										/>
									</S.GridButton>
								),
							},
						]}
						data={results}
						onChangeRowsPerPage={(e) =>
							e !== pagination.pageSize
								? setState(
									(prevState) => {
										return {
											...prevState,
											pagination:
											{
												...this
													.state
													.pagination,
												currentPage: 0,
												pageSize:
													e,
											},
										}
									},
									() => {
										getTableData()
									},
								)
								: ''
						}
						localization={{
							header: {
								actions: 'Ações',
							},
							pagination: {
								labelRowsSelect:
									'linhas',
								labelDisplayedRows:
									'{from}-{to} de {count}',
								firstAriaLabel:
									'Primeira página',
								firstTooltip: '',
								previousAriaLabel:
									'Anterior',
								previousTooltip: '',
								nextAriaLabel:
									'Próxima',
								nextTooltip: '',
								lastAriaLabel:
									'Última página',
								lastTooltip: '',
							},
							body: {
								emptyDataSourceMessage:
									'Nenhum registro encontrado.',
							},
						}}
						isLoading={loading}
					/>
				</S.GridContent>
			</S.GridBox>

		</>
	)
}
