import styled from 'styled-components'

export const BodyCard = styled.div``

export const Title = styled.p`
	font: normal normal normal 12px/60px Roboto;
	color: #333333;
	margin: 0;
	line-height: 100%;
`

export const Switch = styled.div`
	margin: 17px 0 20px 0;
`
