import React from 'react';

import defaultImage from "assets/img/image_placeholder.jpg";
import { Fragment } from 'react';
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors';
import { Icon, PictureContainer } from './styles';

class Thumb extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			thumb: undefined,
			sWidth: props.sWidth,
			edit: false,
			themeColor: getThemeColorFromLocalStorage(),
		};
	}

	getStructBGCol() {
		return (
			this.state &&
			this.state.themeColor &&
			this.state.themeColor.structure &&
			this.state.themeColor.structure.backgroundColor
		)
	}

	componentWillReceiveProps(nextProps) {
		if (!nextProps.file) { return; }
		this.setState({ loading: true }, () => {
			if (nextProps.file instanceof File) {
				let reader = new FileReader();
				reader.onloadend = () => {
					this.setState({ loading: false, thumb: reader.result });
				};
				reader.readAsDataURL(nextProps.file);
			}
			else {
				this.setState({ loading: false, thumb: nextProps.file, edit: nextProps.edit });
			}
		});
	}

	render() {
		const { edit, thumb, sWidth } = this.state;


		// if (loading) { return <p>loading...</p>; }

		return (
			edit ?
				<Fragment>
					<PictureContainer
						className="picture-container"
						border={this.getStructBGCol()}
					>
						<div className="image" >
							<Icon
								className="material-icons"
								backgroundColor={this.getStructBGCol()}
							>
								camera_alt
							</Icon>
							<img
								src={thumb ? thumb : defaultImage}
								alt="..."
								className="pictureSrc"
							/>
						</div>
					</PictureContainer>
				</Fragment>
				:
				<div className={"thumbnail"} style={{ width: sWidth }}>
					<img src={thumb ? thumb : defaultImage} alt="..." />
				</div>
		);
	}
}

export default Thumb;
