import axios from 'axios'

const CancelToken = axios.CancelToken
let cancel

export const GetExpirationDays = () => {
	return axios.get(`/Partner/GetExpirationDays`).then(response => response.data)
}

// GET
export const GetPartnersTable = (name, cnpj, orderBy, OrderByDesc, pageSize, page) => {
	let url = '/Partner'

	if (name) {
		url += `&name=${name}`
	}
	if (cnpj) {
		url += `&cnpj=${cnpj}`
	}

	if (orderBy) {
		url += `&orderBy=${orderBy}`
	}

	if (OrderByDesc === true || OrderByDesc === false) {
		url += `&OrderByDesc=${OrderByDesc}`
	}
	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	return axios.get(url).then(response => response.data)
}

export const GetPartners = (name, cnpj, page) => {
	return axios.get(`/Partner?name=${name}&cnpj=${cnpj}&page=${page}`).then(response => response.data)
}

export const GetPartnerTypesData = () => axios.get(`/Partner/GetPartnerType`).then(response => response.data)

export const GetPartnerSimple = filter => axios.get(`/Partner/GetPartnerSimple?filter=${filter}`).then(response => response.data)

export const GetPartnerKeyValue = filter => axios.get(`/Partner/GetPartnerKeyValue?filter=${filter}`).then(response => response.data)

export const GetPartnerMaxRefundDays = async () => {
	const res = await axios.get(`/Partner/GetPartnerMaxRefundDays`)
	return res
}

export const GetRefundRules = async () => {
	const res = await axios.get(`/Partner/GetGeneralRulesRefund`)
	return res
}

export const UpdateRefundRules = async params => {
	const res = await axios.post(`/Partner/UpdateGeneralRulesRefund${params}`)
	return res
}

export const UpdateRefundType = async refundType => {
	const res = await axios.post(`/Partner/UpdateGeneralRulesRefund?refundType=${refundType}`)
	return res
}

export const GetCreditOrigins = async (pageSize, page, search, orderBy, orderDirection) => {
	let url = `/Partner/GetCreditOrigins`

	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}

	if (orderBy) {
		url += `&orderBy=${orderBy}`
	}

	if (orderDirection) {
		url += `&byDescending=${orderDirection}`
	}
	if (search) {
		url += `&description=${search}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	const res = await axios.get(url)

	return res
}

export const GetPaymentMethods = async () => {
	const res = await axios.get(`/Partner/GetPaymentMethods`)
	return res
}

export const GetCreditCardBrands = async () => {
	const res = await axios.get(`/Partner/GetCreditCardBrands`)
	return res
}

export const GetPaymentMethod = async (pageSize, page, search, OrderBy, orderDirection) => {
	const res = await axios.get(
		`/Partner/GetPaymentMethod?pageSize=${pageSize}&page=${page}&description=${search}&OrderBy=${OrderBy}&byDescending=${orderDirection}`,
	)
	return res
}

export const PostCreditOrigin = async (id, obj) => {
	let res = ''
	if (id) {
		res = await axios.post(`/Partner/PostCreditOrigin?id=${id}`, obj)
	} else {
		res = await axios.post(`/Partner/PostCreditOrigin`, obj)
	}
	return res
}

export const PostPaymentMethod = async (id, obj) => {
	let res = ''
	if (id) {
		res = await axios.post(`/Partner/PostPaymentMethod?id=${id}`, obj)
	} else {
		res = await axios.post(`/Partner/PostPaymentMethod`, obj)
	}
	return res
}

export const UpdateStatusCreditOrigin = async (id, status) => {
	const res = await axios.post(`/Partner/UpdateStatusCreditOrigin?id=${id}&active=${status}`)
	return res
}

export const UpdateStatusPaymentMethod = async (id, status) => {
	const res = await axios.post(`/Partner/UpdateStatusPaymentMethod?id=${id}&active=${status}`)
	return res
}

// GET BY ID
export const GetPartnersId = async id => {
	const res = await axios.get(`/Partner/${id}`)
	return res
}

// GET BY ID Admins
export const GetPartnerAdmins = async id => {
	const res = await axios.get(`/Partner/GetPartnerAdmins/${id}`)
	return res?.data
}

export const GetPartnersAdmins = async ({ id, name, pageSize, page }) => {
	const params = new URLSearchParams()

	if (name) {
		params.append('name', name)
	}

	params.append('pageSize', pageSize)

	params.append('page', page)

	const url = `/Partner/GetPartnerAdmins/${id}?${params.toString()}`

	try {
		const response = await axios.get(url)

		return response
	} catch (error) {
		return error?.response || []
	}
}

// PUT
export const PutPartners = async (id, obj) => {
	const res = await axios.put(`/Partner/${id}`, obj)
	return res?.data
}

export const PutPartnerBranch = (id, obj) => axios.put(`/Partner/PutPartnerBranch/${id}`, obj).then(response => response.data)

// POST
export const PostPartners = async obj => {
	const res = await axios.post(`/Partner/`, obj)
	return res?.data
}

// POST
export const CreatePartner = async obj => {
	const res = await axios.post(`/Partner`, obj)
	return res?.data
}

// POST
export const RegisterNewAdmin = (id, obj) => axios.post(`/Partner/RegisterNewAdmin/${id}`, obj).then(response => response.data)

// GET
export const SendResetPasswordRequest = login => axios.get(`/Partner/SendResetPasswordRequest/${login}`).then(response => response.data)
// GET
export const GetPartnerBranchById = id => axios.get(`/Partner/GetPartnerBranchById?id=${id}`).then(response => response)

// POST
export const ResetPassword = obj => axios.put(`/Partner/ResetPassword`, obj).then(response => response.data)

// POST
export const PostPartnersBranch = (partnerId, obj) =>
	axios.post(`/Partner/CreatePartnerBranch?partnerId=${partnerId}`, obj).then(response => response.data)

// UPDATE
export const UpdatePartnersBranch = (partnerId, obj) =>
	axios.put(`/Partner/UpdatePartnerBranch?partnerId=${partnerId}`, obj).then(response => response.data)

//Conversion Rate
export const verifyConversionRate = value => axios.get(`/Partner/ConversionRateValid/${value}`).then(response => response.data)

export const GetBranchSegment = async () => {
	return await axios.get(`/Partner/GetBranchSegment`).then(response => response.data)
}

export const ExpirationDaysData = () => {
	return axios.get(`/Partner/GetExpirationDays`).then(response => response)
}

export const GetAllPartners = async data => {
	const { pageSize = 10, page = 1, orderBy = '', orderDirection = true, status = '', name = '', contact = '' } = data

	let url = `/Partner/GetAllPartners`

	if (pageSize) url += `&pageSize=${pageSize}`

	if (page) url += `&page=${page}`

	if (orderBy) url += `&orderBy=${orderBy}`

	if (orderDirection) url += `&byDescending=${orderDirection}`

	if (status) url += `&status=${status}`

	if (name) url += `&name=${name}`

	if (contact) url += `&contactName=${contact}`

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	return await axios.get(url).then(response => response?.data)
}

export const UpdatePartnerStatus = async ({ id, status }) => {
	return await axios
		.post(`/Partner/UpdatePartnerStatus/${id}`, {
			status: status,
		})
		.then(response => response?.data)
		.catch(error => error.response)
}

export const GetPartner = (name, type, status) => {
	let url = `/Partner/GetAllPartners`

	if (name && type === 'Nome') {
		url += `&Name=${name}`
	}

	if (name && type === 'CNPJ') {
		url += `&CNPJ=${name}`
	}
	if (status) {
		url += `&status=${status}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')
	return axios.get(url).then(response => response.data)
}

export const GetAllSegmentations = async data => {
	const { pageSize, page = 1, orderDirection, search } = data

	let url = `/PartnerSegmentation/GetSegmentations`

	if (pageSize) url += `&pageSize=${pageSize}`

	if (page) url += `&page=${page + 1}`

	if (search) url += `&description=${search}`

	if (orderDirection) url += `&OrderByDesc=${orderDirection === 'desc' ? true : false}`

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	return await axios.get(url).then(response => response?.data)
}

export const PostSegmentation = async obj => {
	try {
		return await axios.post(`/PartnerSegmentation/PostSegmentation`, obj)
	} catch (error) {
		return error.response
	}
}

export const UpdateStatusSegmentation = async data => {
	let url = `/PartnerSegmentation/UpdateStatusSegmentation`
	const { status, id } = data

	if (id) url += `&id=${id}`

	if (id) url += `&active=${status.key === '1' ? false : true}`

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	try {
		return await axios.post(url)
	} catch (error) {
		return error.response
	}
}

export const GetPartnersCount = () => {
	return axios.get(`/Partner/GetPartnersCount`).then(response => response)
}

export const GetAllSketches = async data => {
	const { pageSize = 10, page = 1, cnpj = '' } = data

	let url = `/PartnerSketch/GetAllPartnersSketches`

	const body = {
		pageSize,
		page,
		cnpj,
		name: '',
	}

	return await axios.post(url, body).then(response => response?.data)
}

export const GetActiveSegmentations = () => {
	return axios.get(`/PartnerSegmentation/GetActiveSegmentations`).then(response => response)
}

export const CreateSketch = async obj => {
	return await axios.post(`/Partner/Sketch`, obj).then(response => response)
}

export const UpdateSketch = async (id, obj) => {
	return await axios.put(`/Partner/Sketch/${id}`, obj).then(response => response)
}

export const GetSketchById = async id => {
	return await axios(`/Partner/Sketch/${id}`).then(response => response)
}

export const GetSketchByCNPJ = async id => {
	return await axios(`/PartnerSketch/SketchByCNPJ/${id}`).then(response => response)
}

export const DeleteSketch = async id => {
	return await axios.delete(`/PartnerSketch/Sketch/${id}`).then(response => response)
}

export const GetAttachmentTypes = async () => {
	return await axios.get(`/PartnerAttachments/GetAttachmentTypes`).then(response => response)
}

export const GetActiveOrigins = async () => {
	return await axios.get(`/Partner/GetActiveOrigins`).then(response => response)
}

export const AttachmentFile = async data => {
	const fileData = new Blob([data?.File], {
		type: 'application/octet-stream',
	})

	const fileName = data?.File?.name

	const formData = new FormData()
	formData.append('File', fileData, fileName)
	formData.append('FileName', data?.FileName ? data?.FileName : '')

	return await axios
		.post(`/PartnerAttachments/PostAttachmentFile`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(response => response)
}

export const DeleteAttachmentFile = async name => {
	return await axios.delete(`/PartnerAttachments/DeleteAttachmentFile?fileName=${name}`).then(response => response)
}

export const GetSegmentationTypes = async () => {
	return await axios.get(`/Partner/GetSegmentationTypes`).then(response => response)
}

export const GetAllAdditionalModules = async () => {
	return await axios.get(`/AdditionalModules/GetAllAdditionalModules?activeToPartners=true`).then(response => response)
}

export const GeneratePartnerBranchId = async () => {
	return await axios.get(`/PartnerSketch/GeneratePartnerBranchId`).then(response => response)
}

export const CreatePartnerInfoSketch = async obj => {
	if (cancel) {
		// Cancela a requisição anterior se existir
		cancel('Canceling previous request')
	}

	return await axios
		.post(`/PartnerSketch/PartnerInfoSketch`, obj, {
			cancelToken: new CancelToken(function executor(c) {
				cancel = c
			}),
		})
		.then(response => response)
}

export const CreatePartnerAttachmentsSketch = async obj => {
	return await axios.post(`/PartnerSketch/PartnerAttachmentsSketch`, obj).then(response => response)
}

export const CreatePartnerBusinessDivisionSketch = async obj => {
	return await axios.post(`/PartnerSketch/PartnerBusinessDivisionSketch`, obj).then(response => response)
}

export const PartnerBusinessDivision = async obj => {
	return await axios.post(`PartnerBusinessDivision/Post`, obj).then(response => response)
}

export const PartnerBusinessDivisionPut = async obj => {
	return await axios.put(`PartnerBusinessDivision/Put`, obj).then(response => response)
}
export const ChangeModuleStatus = async id => {
	return await axios.put(`/AdditionalModules/Partner/ChangeModuleStatus/${id}`).then(response => response)
}

export const ValidatePartnerSketch = async cnpj => {
	return await axios.post(`/PartnerSketch/ValidatePartnerSketch/${cnpj}`).then(response => response)
}

export const CreateFromSketch = async cnpj => {
	return await axios.post(`/Partner/CreateFromSketch/${cnpj}`).then(response => response)
}

export const GetMarketingAreaType = async () => {
	return await axios.get(`/Enums/GetMarketingAreaType`).then(response => response)
}

export const GetAdditionalModule = async id => {
	return await axios.get(`/AdditionalModules/GetAdditionalModule/${id}`).then(response => response)
}

export const GetBusinessDivisionType = async () => {
	return await axios.get(`/Enums/GetBusinessDivisionType`).then(response => response)
}

export const GetBusinessDivision = async id => {
	return await axios.get(`/PartnerBusinessDivision/GetAll/${id}`).then(response => response)
}

export const UpdatePartnerInfo = async obj => {
	return await axios.put(`/Partner/ManagerUpdatePartnerInfo`, obj).then(response => response)
}
export const PutCompanyInformation = async (id, obj) => {
	try {
		return await axios.put(`/Partner/PutCompanyInformation/${id}`, obj).then(response => response)
	} catch (error) {
		return error.response
	}
}

export const PutConfigurations = async (id, obj) => {
	try {
		return await axios.put(`/Partner/PutConfigurations/${id}`, obj)
	} catch (error) {
		return error.response
	}
}

export const PutAttachments = async (id, obj) => {
	try {
		return await axios.put(`/PartnerAttachments/PutAttachments/${id}`, obj)
	} catch (error) {
		return error.response
	}
}

export const ValidateBusinessDivisionAllErrors = async id => {
	try {
		return await axios.get(`/PartnerBusinessDivision/ValidateBusinessDivisionAllErrors/${id}`)
	} catch (error) {
		return error.response
	}
}
