import axios from 'axios';

//GET api/ConsumerWallet/GetExpirationPoints
export const GetConsumerWalletExpirationPoints = () => 
    axios.get(`/ConsumerWallet/GetExpirationPoints/`).then(
        response => response.data
    );

    // GET api/extract
export const GetStatementData = (dateFrom, dateTo, consumerId, partnerId, pageIndex) => {
    let args = "";
    if (dateFrom && dateFrom !== "")
        args = args + `&startDate=${dateFrom}`;
    if (dateTo && dateTo !== "")
        args = args + `&endDate=${dateTo}`;
    if (partnerId && partnerId !== "")
        args = args + `&partnerId=${partnerId}`;
    return axios.get(`/ConsumerWallet/GetConsumerStatement?page=${pageIndex}&consumerId=${consumerId}${args}`)
        .then(response => response.data);
}

// // GET api/extract
// export const GetStatementDetails = (id) => 
//     axios.get(`/AccountStatement/${id}`)
//         .then(response => response.data)