import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import { useHistory } from 'react-router-dom'

//@ components
import Info from './info'
import ListComponent from './List'
import { GetParametrizationDetails } from '../../../../../../../redux/api/productPage'

import { FormatPoints, formatDateTime } from './utils/index'
import { Box } from '@material-ui/core'
import CalcMemory from '../../../Components/CalculationMemory'
import Loader from 'components/Loader/Loader.jsx'
import { Grid } from '@mui/material'
import { formatTypeOfBenefit } from './info/Functions'

const Details = () => {
	const { t } = useTranslation()
	const history = useHistory()

	const [data, setData] = useState({})
	const path = window.location.pathname.split('/')
	const productId = path[3]
	const [openCalcMemory, setOpenCalcMemory] = useState(false)
	const [isLoadingData, setIsLoadingData] = useState(false)

	useEffect(() => {
		setIsLoadingData(true)
		const fetchData = async () => {
			try {
				const response = await GetParametrizationDetails(productId)
				setData(response)
				setIsLoadingData(false)
			} catch (error) {
				console.error('Erro na requisição da API:', error)
			}
		}
		fetchData()
	}, [])

	const parameterizationGeneration = useMemo(() => {
		return data?.data?.parameterizationGenerationPoints
	}, [data?.data])

	const parameterizationRedemption = useMemo(() => {
		return data?.data?.parameterizationRedemptionPoints
	}, [data?.data])

	const parametrizationCashback = useMemo(() => {
		return data?.data?.parametrizationCashback
	}, [data?.data])

	const TypeOfBenefit = useMemo(() => {
		if (data?.data?.typeOfPoints && data?.data?.parameterizationGenerationPoints) {
			return data?.data?.typeOfPoints?.value
		}
		if (data?.data?.typeOfCashback && data?.data?.parametrizationCashback) {
			return data?.data?.typeOfCashback?.value
		}
		if (data?.data?.typeOfRedeem && data?.data?.parameterizationRedemptionPoints) {
			return data?.data?.typeOfRedeem?.value
		}

		return 'No'
	}, [data?.data])

	const TypeOfValue = useMemo(() => {
		if (data?.data?.typeOfPoints && data?.data?.parameterizationGenerationPoints) {
			if (data?.data?.typeOfPoints?.value === 'standard' || data?.data?.typeOfPoints?.value === 'Fixed') {
				return FormatPoints(data?.data?.pointsValue)
			}
			if (data?.data?.typeOfPoints?.value === 'Percentage') {
				return `${data?.data?.pointsValue}%`
			}
		}
		if (data?.data?.typeOfCashback && data?.data?.parametrizationCashback) {
			if (data?.data?.typeOfCashback?.value === 'Manual') {
				return `${data?.data?.cashbackPercentual}%`
			} else {
				return FormatPoints(data?.data?.cashbackPercentual)
			}
		}
		if (data?.data?.typeOfRedeem && data?.data?.parameterizationRedemptionPoints) {
			if (data?.data?.typeOfRedeem?.value === 'Default' || data?.data?.typeOfRedeem?.value === 'Custom') {
				return FormatPoints(data?.data?.redemptionPoints)
			}
		}
	}, [data?.data])

	const Name = useMemo(() => {
		if (data?.data?.typeOfPoints?.value) {
			return 'Geração'
		}
		if (data?.data?.typeOfRedeem?.value) {
			return 'Resgate'
		}
		if (data?.data?.typeOfCashback?.value) {
			return 'Cashback'
		}
	}, [data?.data])

	const ListData = useMemo(() => {
		if (data?.data?.divisions) {
			return data?.data?.divisions
		}
	}, [data?.data])

	useEffect(() => {
		if (!productId) handleGoBack()
	}, [productId])

	const handleGoBack = () => {
		history.goBack()
	}

	return (
		<S.Container>
			{isLoadingData && <Loader />}
			<S.ButtonContainer>
				<S.Titles>
					{Name} - {data?.data?.name || '-'}
				</S.Titles>
				<S.ButtonClose onClick={handleGoBack}>Fechar</S.ButtonClose>
			</S.ButtonContainer>
			<S.DataContainer>
				<Box sx={{ fontWeight: 600 }}>
					Usuario: <b>{data?.data?.username}</b>
				</Box>
				<Box>
					Data: <b>{formatDateTime(data?.data?.date || '')}</b>
				</Box>
			</S.DataContainer>
			<S.LineComponent />
			<Grid container spacing={2} my={1}>
				<Grid item xs={12} md={4}>
					<S.ConfigInfoTitle>Configuração do beneficio</S.ConfigInfoTitle>
					<S.ConfigInfo>{t(TypeOfBenefit)}</S.ConfigInfo>
				</Grid>
				{TypeOfBenefit !== 'No' && TypeOfBenefit !== 'Unknow' && (
					<Grid item xs={12} md={8}>
						<div style={{ opacity: '0.5', flex: 1 }}>
							<Info
								parameterizationGeneration={parameterizationGeneration}
								parameterizationRedemption={parameterizationRedemption}
								parametrizationCashback={parametrizationCashback}
								productData={data?.data}
								type="default"
								Name={Name}
							/>
						</div>
					</Grid>
				)}
			</Grid>
			{TypeOfBenefit !== 'Default' && TypeOfBenefit !== 'Standard' && TypeOfBenefit !== 'No' && TypeOfBenefit !== 'Auto' && (
				<Box style={{ marginBottom: '10px' }}>
					<Grid container spacing={2} mt={1}>
						<Grid item xs={12} md={4}>
							<S.ConfigCont>
								<S.ConfigInfoTitle>{t('FIELD_VALUE')}</S.ConfigInfoTitle>
								<S.ConfigInfo>{TypeOfValue}</S.ConfigInfo>
							</S.ConfigCont>
						</Grid>
						<Grid item xs={12} md={8}>
							<div style={{ flex: 1 }}>
								<Info
									parameterizationGeneration={parameterizationGeneration}
									parameterizationRedemption={parameterizationRedemption}
									parametrizationCashback={parametrizationCashback}
									openModal={() => setOpenCalcMemory(!openCalcMemory)}
									TypeOfBenefit={formatTypeOfBenefit(TypeOfBenefit, Name)}
									productData={data?.data}
									type="custom"
									Name={Name}
								/>
							</div>
						</Grid>
					</Grid>
					<S.ContainerDescrip>
						O valor informado será submetido a taxa de administração do programa e o valor liquido ira para a carteira do cliente.
					</S.ContainerDescrip>
				</Box>
			)}

			<S.LineComponent />
			<ListComponent listData={ListData} />
			<CalcMemory open={openCalcMemory} productData={data?.data ?? []} onClose={() => setOpenCalcMemory(!openCalcMemory)} />
		</S.Container>
	)
}

export default Details
