/* eslint-disable */
import React, { useEffect, useState, useCallback } from 'react'
import TextMaskCustom from '../../../components/TextMaskCustom'
import { HideLoader } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { useAward } from '../../../Providers/AwardContext'
import { useTranslation } from 'react-i18next'
import Section from 'components/Section'
import Button from 'components/CustomButtons/Button.jsx'
import { useHistory } from 'react-router-dom'
import { NewTab, NewTabList, NewTabs } from 'components/NewTab/styles'
import CurrencyInput from './components/NewCustomInput'
import PercentageInput from './components/PercentageInput/PercentageInput'
import { yupResolver } from '@hookform/resolvers'
import { Controller, useForm } from 'react-hook-form'
import CustomDateNew from 'components/CustomDateNew'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { germiniDefaultColors, grayColor } from 'assets/jss/material-dashboard-pro-react'

import {
	FormControl,
	TextField,
	InputLabel,
	MenuItem,
	Select,
	RadioGroup,
	FormControlLabel,
	Radio,
	FormHelperText,
	FormLabel,
	Switch,
	Tooltip,
	Grid,
} from '@material-ui/core'

import TableColumns from './TableColumns'
import { numberFormatText } from 'utils/utils'

import * as S from './style'

import ModalInfo from '../ModalInfo'
import { useSelector } from 'react-redux'

//-- EndPoints API
import { GetCreditOriginsByPartner } from '../../../redux/api/partner'
import { AwardImportFile, PostAward, BankAccountReserveGetAll } from '../../../redux/api/award'
import { ValidateCpf, ValidateCode } from '../../../redux/api/consumer'
//--
import moment from 'moment'
import { handleCheckInvalid, MaskedInputNunber, useStylesNew, ValidateExtension, validateIndication } from './utils'
import NewConfirmModal from '../../../components/NewConfirmModal'
import CustomRequiredField from '../../../components/CustomRequiredField/CustomRequiredField'
import ConfirmModal from './components/ConfirmModal'
import { envs } from '../../../redux/api/windowServerData'

const FileSize = 1000

const CreateAward = () => {
	const { t } = useTranslation()
	const [clear, setClear] = useState(false)
	const [themeColor, setThemeColor] = useState()

	const tableRef = React.useRef()
	const partnerId = useSelector(state => state.users.userData.id)

	const history = useHistory()
	const dispatch = useDispatch()
	const { setDisplayedTab, setOptionMenssage, setOpenMsgSucess, GetGeneral, GeneralRules } = useAward()
	const classes = useStylesNew()

	// Estados para o formulário
	const [optionsType, setOptionsType] = useState([])
	const [selectedType, setSelectedType] = useState('')
	const [selectedValue, setSelectedValue] = useState('batch')
	const [optionsReserve, setOptionsReserve] = useState([])
	const [selectedReserve, setSelectedReserve] = useState([])
	const [FilesMultipleError, setFilesMultipleError] = useState('')
	const [valueReserve, setValueReserve] = useState([])
	const [description, setDescription] = useState('')
	const [note, setNote] = useState('')
	const [standardValue, setStandardValue] = useState('')
	const [valueAward, setValueAward] = useState('')
	const [logs, setLogs] = useState([])
	const [switchValue, setSwitchValue] = useState(false)

	// Estados da tabela
	const [list, setList] = useState([])
	const [isList, setIsList] = useState(false)
	const [realList, setRealList] = useState([])
	const [inclusionImportTab, setInclusionImportTab] = useState(true)
	const [radioValue, setRadioValue] = useState(false)

	// Estados de para validar o formulário de incluir participantes manual
	const [consumer, setConsumer] = useState()
	const [cpf, setCpf] = useState('')

	// Estados de validação
	const [error, setError] = useState('')
	const [submit, setSubmit] = useState(false)
	const [fileName, setFileName] = useState('')

	const [dateValid, setDateValid] = useState(true)

	const [invalidUser, setInvalidUser] = useState(0)
	dispatch(HideLoader())

	// State de validação  modal box
	const [file, setFile] = useState('')
	const [validSize, setValidSize] = useState(false)
	const [balanceSubmit, setBalanceSubmit] = useState(false)
	const [manualShipping, setManualShipping] = useState(false)
	const [submitConsumer, setSubmitConsumer] = useState(false)
	const [modalOpen, setModalOpen] = useState('')
	const [changedValue2, setChangedValue2] = useState()
	const [fileValid, setFileValid] = useState(false)
	const [openModalValue, setOpenModalValue] = useState(false)
	const [bankReserveError, setBankReserveError] = useState([false, ''])
	const [bankMessageCode, setBankMessageCode] = useState('')

	const [cancelModal, setCancelModal] = useState(false)
	const [importNewFile, setImportNewFile] = useState(false)
	const [openModalWarning, setOpenModalWarning] = useState(false)
	const [openModalWMutipleError, setOpenModalWMutipleError] = useState(false)
	const [openModalReserve_insufficient_funds, setOpenReserve_insufficient_funds] = useState(false)
	const [openModalMinimum, setOpenModalMinimum] = useState(false)
	const [filesInvalid, setFilesInvalid] = useState(false)
	const [disableBtn, setDisableBtn] = useState(false)

	const [loading, setLoading] = useState(false)
	const [reservedFunds, setReservedFunds] = useState({
		valueReserve: '',
		valueAward: '',
	})

	const [validateDown, setValidateDown] = useState()



	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		GetAllForPartner()
		GetCreditOrigins(partnerId)
	}, [partnerId])

	useEffect(() => {
		if (cpf.length > 0) {
			setError('')
		}
	}, [cpf])

	useEffect(() => {
		if (valueReserve.reserveBalance > 0) {
			Balance(valueReserve.reserveBalance)
		}
	}, [list, realList])

	useEffect(() => {
		clear && setClear(false)
	}, [clear])

	// Valida dinamicamente o valor do campo valor de crédito
	useEffect(() => {
		if (valueAward > valueReserve.reserveBalance) {
			setFilesMultipleError(t('Reserve_insufficient'))
		} else if (valueAward <= valueReserve.reserveBalance) {
			setFilesMultipleError(t(''))
		}
	}, [valueAward])

	useEffect(() => {
		setInvalidUser(handleCheckInvalid(list)) // Verifica se o usuário é válido
	}, [list])

	// Autocomplete styles

	const CustomPopper = function (props) {
		const classesPoper = useStyles()
		return <Popper {...props} className={classesPoper.root} placement="bottom" />
	}

	const customSelectStyles = {
		control: (base, state) => ({
			...base,
			input: { height: '25px' },
			boxShadow: state.isFocused ? 0 : 0,
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
			'&:hover': {
				borderColor: state.isFocused ? grayColor[2] : base.borderColor,
			},
		}),
	}

	const useStyles = makeStyles(theme =>
		createStyles({
			root: {
				'& .MuiAutocomplete-listbox': {
					'& :hover': {
						color: '#ffffff',
						backgroundColor:
							themeColor && themeColor.structure && themeColor.structure.backgroundColor
								? themeColor.structure.backgroundColor + ' !important'
								: `#fff`,
					},
					'& li': {
						backgroundColor: '#ffffff',
					},
				},
			},
		}),
	)

	// Validar código ou CPF
	const CheckCPFCODE = e => {
		setCpf(e)
		let cpf = e

		if (cpf.length === 6) {
			ValidateConsumerByCode(cpf)
		}
		cpf = cpf.replace(/[^\d]+/g, '')

		if (cpf.length >= 11) {
			ValidateConsumerByCpf(cpf)
		}
	}

	const { control, errors, handleSubmit, setValue, watch } = useForm({
		defaultValues: {
			description: '',
			creditReason: '',
			reservation: '',
			anticipationFee: '0',
			periodDays: '',
			datePeriod: '',
			note: '',
			switcherValue: false,
			radioValue: '',
			giftValue: '',
		},
		resolver: yupResolver(validateIndication),
	})

	const watchAllFields = watch()

	useEffect(() => {
		if (watchAllFields.radioValue !== '1' && watchAllFields.radioValue !== '2' && GetGeneral.awardTaxType === 2) {
			setValue('radioValue', '1')

			return
		}
		if (watchAllFields.radioValue === '2') {
			setValue('periodDays', '')
		}
		if (watchAllFields.radioValue === '1') {
			setValue('datePeriod', '')
		}
		if (watchAllFields.datePeriod && watchAllFields.datePeriod.length === 10) {
			setDateValid(moment(watchAllFields.datePeriod, 'DD/MM/YYYY').isValid())
		} else {
			setDateValid(true)
		}
	}, [watchAllFields.radioValue, watchAllFields.datePeriod, GetGeneral.awardTaxType])

	useEffect(() => {
		if (switchValue && GetGeneral.awardTaxType === 1) {
			setValue('periodDays', GetGeneral.waitingPeriod)
		}
		if (switchValue) {
			setValue('anticipationFee', GetGeneral.antecipationTax.toString().replace(/\./g, ','))
		}
	}, [switchValue, GetGeneral.awardTaxType])

	useEffect(() => {
		isValidAnticipation(watchAllFields.anticipationFee)
	}, [watchAllFields.anticipationFee, watchAllFields.datePeriod])

	const Balance = e => {
		// Somar todos os valores de list e verificar se é maior que o valor da reserva não removidos
		let total = 0
		let fileInvalid = 0
		list && list.map(item => (item.isRemoved ? '' : (total += item.value)))

		// Verrificar a quantidade de arquivos inválidos com status 2 ou 3
		list && list.map(item => (item.isRemoved ? '' : item.status === 2 || item.status === 3 ? fileInvalid++ : ''))

		if (e >= total) {
			setFilesMultipleError('')
		}
		if (fileInvalid > 0) {
			setInvalidUser(fileInvalid)
		}
	}

	const handleChangeTab = tab => {
		setFilesMultipleError('')
		setSelectedValue('batch')
		setRadioValue(false)
		if (list.length > 0 || standardValue.length > 0 || cpf.length > 0) {
		} else {
			tab === 1 ? setInclusionImportTab(true) : setInclusionImportTab(false)
		}
	}

	const handleChangeReserve = (value, event) => {
		event(value.id)
		setSelectedReserve(value)
		setValueReserve(value)
		setFilesMultipleError('')

		if (bankReserveError[0] === 0 || bankReserveError[1]) {
			setBankReserveError([true, ''])
			setBankMessageCode('')
		}
	}

	const handleSelectedEvent = (value, event) => {
		event(value.id)
		setSelectedType(value)
	}

	// Valida o tipo de importação selecionada: Valor fixo no arquivo ou  valor padrão
	const handleChangeRadio = value => {
		if (value === 'individual') {
			setRadioValue(true)
			setSelectedValue('individual')
			setSubmit(false)
		}
		if (value === 'batch') {
			setSubmit(false)
			setRadioValue(false)
			setSelectedValue('batch')
		}
	}

	const handleOnConfirm = () => {
		setCancelModal(false)
		history.push('/financial/award')
	}

	// Voltar para  Award
	const handleOnCancel = () => {
		setModalOpen('sair')
		setOpenModalValue(false)
		setCancelModal(!cancelModal)
	}

	// Deleta linha coluna
	const handleOnConfirmDelete = () => {
		let newRealList = []
		let newList = []

		setCancelModal(false)
		setInvalidUser(invalidUser - changedValue2.length)

		for (let j = 0; j < changedValue2.length; j++) {
			for (let i = 0; i < list.length; i++) {
				if (list[i].code_CPF === changedValue2[j].code_CPF) {
					list[i].isRemoved = true
					list[i].data_exclusao = moment().format('YYYY-MM-DD HH:mm:ss')
				}
			}
		}

		for (let j = 0; j < list.length; j++) {
			if (!list[j].isRemoved) {
				newRealList.push(list[j])
			}
		}
		newList = list

		setList(newList)
		setRealList(newRealList)

		// se o usuário excluir todos os arquivos, o valor da reserva deve ser zerado
		if (newRealList.length === 0) {
			Clean()
		}
	}

	// Pega linha atual do arquivo a ser deletado
	const handleOnCancelDelete = selected => {
		setModalOpen('')
		setChangedValue2(selected)
		setCancelModal(!cancelModal) //
	}

	useEffect(() => {
		if (list.length > 1 && radioValue) {
			setOpenModalValue(true)
		}
	}, [radioValue])

	const handleOnCancelvalue = () => {
		setSelectedValue('batch')
		setOpenModalValue(false)
		setRadioValue(false)
		setDisableBtn(false)
	}

	const handleOnConfirmvalue = () => {
		setOpenModalValue(false)
		setSelectedValue('individual')
		setDisableBtn(true)
	}

	// Validação tamanho do e seu cabeçalho
	async function fileInputControlChangeEventHandlerAsync() {
		setFileValid(false)
		setValidSize(false)

		let files = document.getElementById('raised-file').files[0]

		let contentOfAllFiles = ''

		var myFileReader = new MyFileReader()

		let fileContents = await myFileReader.readAsTextAsync(files)
		contentOfAllFiles = fileContents[0]

		// let tableSize = fileContents[1].length

		// concatena cabecalho na primeira linha do arquivo
		const headOne = `${contentOfAllFiles[0] || ''}${contentOfAllFiles[1] || ''}` || contentOfAllFiles[0]

		const headTwo = headOne.replace(/\s/g, '').replace(/;/g, '')
		//.toLowerCase()

		const tableSizeTwo = fileContents[1].filter(item => item !== '')

		if (tableSizeTwo.length - 1 < 10) {
			setOpenModalMinimum(true)
			return
		}
		if (tableSizeTwo.length - 1 <= FileSize) {
			//-1 para desconsiderar o cabeçalho e validar
			setValidSize(false)
			setFilesMultipleError('')

			let headersValid = ''

			radioValue ? (headersValid = headTwo.includes('CPF_Cod')) : (headersValid = headTwo.includes('CPF_CodValor'))

			if (headersValid) {
				setFileValid(false)
				FileImport(files, standardValue)
			} else {
				setFileValid(true)
			}
		} else {
			setFilesMultipleError(t('Maximum_Number_Of_Files_Exceeded'))
		}
	}
	function MyFileReader() { }

	MyFileReader.prototype.readAsTextAsync = function (file) {
		return new Promise((resolve, reject) => {
			try {
				var reader = new FileReader()
				reader.onload = function (event) {
					const csv = event.target.result
					const lines = csv.split('\n')
					const fileContents = lines[0].split(',')

					let data = [fileContents, lines]
					resolve(data)
				}

				reader.readAsText(file, 'ISO-8859-1')
			} catch (error) {
				reject(error)
			}
		})
	}

	// Passa o arquivo para o servidor
	const handleChangeFile = () => {
		if (ValidateExtension(document.getElementById('raised-file'))) {
			fileInputControlChangeEventHandlerAsync()
			const input = document.getElementById('raised-file')
			input.value = ''
			setFilesMultipleError('')
		} else {
			setFilesInvalid(true)
		}
	}

	// Limpar campos
	const Clean = () => {
		setSelectedValue('batch')
		setList([])
		setRealList([])
		setFileName('')
		setStandardValue('')
		setValueAward('')
		setCpf('')
		setSubmitConsumer(false)
		setError('')
		setClear(true)
		setLogs([])
		setFilesMultipleError('')
		setRadioValue(false)

		// limpar input file
		if (document.getElementById('raised-file')) {
			const input = document.getElementById('raised-file')
			input.value = ''
		}
	}

	const GetValidationNewFile = async id => {
		await setImportNewFile(false)

		if (id) {
			const input = document.getElementById('raised-file')
			input.click()
		}
	}

	// Prepara arquivo csv ou txt para ser enviado para o servidor
	const FileImport = async (file, StandardValue) => {
		setFile(file)

		setFileValid(false)
		const formData = new FormData()
		formData.append('formFile', file)

		setLoading(true)
		let log = {
			logDate: new Date().toISOString(),
			logAction: 3,
			message: 'Lote criado com sucesso.',
		}
		try {
			const response = await AwardImportFile(formData, StandardValue)
			if (response.data.errors.length > 0) {
				if (
					response.data.errors[0].messageCode === 45 ||
					response.data.errors[0].message === "formFile - TOO_MANY_ERRORS_IN_FILE") {
					setOpenModalWMutipleError(!openModalWMutipleError)
					return
				}

				setFilesMultipleError(t(response.data.errors[0].message))
				return
			}

			if (response.data.data) {
				setFilesMultipleError('')
				const total = response.data.data.consumers.reduce((acc, cur) => {
					return acc + cur.value
				}, 0)

				if (total > valueReserve.reserveBalance) {
					setFilesMultipleError(t('Reserve_insufficient'))
					setOpenReserve_insufficient_funds(true)
					setReservedFunds({
						valueReserve: valueReserve.reserveBalance,
						valueAward: total,
					})
				}

				setFileName(file.name)
				setList(response.data.data.consumers)
				setRealList(response.data.data.consumers)

				//adiciona a resposta do servidor no array de logs e log
				setLogs([...logs, log, ...response.data.data.logs])
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	// Buscar dados de origem de crédito
	const GetCreditOrigins = async id => {
		try {
			const response = await GetCreditOriginsByPartner(id)
			if (response) {
				setOptionsType(response)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const isValidAnticipation = async (e = '') => {
		if (e) {
			let value = e
				.toString()
				.replace(/\,/g, '.')
				.replace('%', '')
			let antValue = parseFloat(value)
			if (antValue < GetGeneral.antecipationTax && switchValue) {
				setValidateDown(true)
			}
			if (antValue >= GetGeneral.antecipationTax && switchValue) {
				setValidateDown(false)
			}
		}
	}

	// Retonar reservas
	const GetAllForPartner = async () => {
		try {
			const response = await BankAccountReserveGetAll()

			if (response.data.data) {
				setOptionsReserve(response.data.data)
			}
		} catch (error) {
			console.log(error)
		}
	}

	// Valida se cpf é valido ou inválido
	const ValidateConsumerByCpf = async cpf => {
		let valid = false
		list.map(item => {
			if (item.code_CPF === cpf && item.isRemoved === false) valid = true
		})
		const response = await ValidateCpf(cpf)
		if (response.data.data) {
			if (valid === true) {
				setError('Participante já adicionado')
			}
			if (valid === false) {
				setError('false')
				setConsumer(response.data.data)
				setError('')
			}
		}
		if (response.data.success === false) {
			setError('CPF inválido')
		}
	}

	// Valida se o valor é maior que o valor padrão da reserva
	const validateValue = useCallback(
		value => {
			let valid = false
			setManualShipping(false)
			const total = list.reduce((acc, cur) => {
				return cur.isRemoved === false ? acc + cur.value : acc
			}, 0)

			valid = total + value > valueReserve.reserveBalance ? false : true
			valid === false ? setManualShipping(true) : setManualShipping(false)

			return valid
		},
		[list],
	)

	// Adiciona novo item na lista de reservas
	const AddCosumerList = async () => {
		setSubmitConsumer(true)
		setManualShipping(false)

		// VERIFICA SE LISTA ESTÝ VAZIA E ADICIONA UM VALOR AS LOGS
		if (list.length === 0) {
			setLogs([
				{
					logDate: new Date(),
					logAction: 6,
					message: `${t('BATCH_CREATED_SUCCESSFULLY')}`,
				},
			])
		}

		let valid = validateValue(parseFloat(valueAward))

		if (consumer && valid && valueReserve.reserveBalance > 0) {
			// valida se existe um consumidor
			if (valueAward > valueReserve.reserveBalance) {
				setSubmitConsumer(false)
				setValueAward('')
			} else if (
				// Valida se os campos estão preenchidos
				valueAward > 0 &&
				error.length === 0 &&
				balanceSubmit === false
			) {
				setSubmitConsumer(false)
				let user = {
					consumerId: consumer.id,
					code_CPF: consumer.cpf,
					rowNumber: list.length + 1,
					status: 1,
					dateRemoved: null,
					isRemoved: false,
					value: parseFloat(valueAward),
				}

				// passa para a lista
				setList([...list, user])
				setRealList([...realList, user])

				// limpa inputs
				setValueAward('')
				setCpf('')
				setClear(true)
			}
		}
	}

	// Valida código do participante
	const ValidateConsumerByCode = async indicationcode => {
		let valid = false
		try {
			const { data } = await ValidateCode(indicationcode)

			if (data.success) {
				list &&
					list.map(item => {
						if (item.code_CPF === data.data.cpf && item.isRemoved === false) valid = true
					})
				if (valid === true) {
					setError('Participante Já adicionado')
				} else if (valid === false) {
					setConsumer(data.data)
					setError('')
				}
			}
			if (data.success === false) {
				setError('Código do participante inválido')
			}
		} catch (error) {
			console.log(error)
		}
	}

	// processar dados da premiação e retornar para tela award
	const handlePostAward = async body => {
		await setBankReserveError(false)

		// Valida se os campos estão preenchidos
		if (invalidUser <= 0 && list.length > 0) {
			setLoading(true)
			try {
				const response = await PostAward(body)

				if (response.data.success) {
					setOpenMsgSucess(true)
					setOptionMenssage('success')
					history.push('/financial/award')
					setDisplayedTab(2)
				}

				if (response.data.success === false) {
					let err = response.data.errors[0].message
					err = t(
						err
							.replace(/ /g, '_')
							.replace(/\./g, '')
							.toUpperCase(),
					)

					setBankReserveError([response.data.errors[0].messageCode, err])

					if (response.data.errors[0].messageCode === 48) {
						setBankMessageCode(response.data.errors[0].message)
					}
					if (response.data.errors[0].messageCode === 46) {
						GeneralRules()
						setValidateDown(true)
					}
				}
			} catch (error) {
				console.log(error)
			} finally {
				setLoading(false)
			}
		}
	}
	const onSubmit = async (data, e) => {
		e.preventDefault()

		let log = logs

		let logManual = {
			logDate: new Date(),
			logAction: 6,
			message: 'Total de registros adicionados: ' + list.length,
		}

		// prettier-ignore
		logs.length > 1 ? (log = logs) : (log = [...logs, logManual]);

		const { anticipationFee, creditReason, datePeriod, description, periodDays, note, switcherValue, radioValue } = data

		let body = {
			id: '',
			description: description,
			partnerCreditOriginId: creditReason,
			bankAccountReserveId: valueReserve.id,
			partnerId: partnerId,
			observation: note,
			importValueType: 1,
			standartValue: valueReserve.reserveBalance,
			fileName: fileName || '',
			consumers: list,
			logs: log,
			isAnticipated: switcherValue,
			anticipationFee: anticipationFee ? parseFloat(anticipationFee.replace('%', '').replace(',', '.')) : 0,
			graceType: radioValue || false,
			graceDays: periodDays ? periodDays : GetGeneral.waitingPeriod || 0,
			graceDate: datePeriod ? new Date(datePeriod).toISOString() : null,
		}

		if (body.anticipationFee === 0 && watchAllFields.switcherValue) {
			return
		}
		if (watchAllFields.periodDays === '0') {
			return
		}
		if (moment(datePeriod).isBefore(new Date())) {
			setDateValid(false)
			return
		}
		if (validateDown && watchAllFields.anticipationFee !== '' && watchAllFields.switcherValue) {
			setValidateDown(true)
			return
		}

		await handlePostAward(body)
	}

	const onError = error => {
		console.log('Error:::::::', error)
	}

	return (
		<S.Container themeColor={themeColor}>
			<ConfirmModal
				type="openModalWMutipleError"
				open={openModalWMutipleError}
				onConfirm={() => setOpenModalWMutipleError(false)}
				onCancel={() => setOpenModalWMutipleError(false)}
			/>
			<ConfirmModal
				type="Reserve_insufficient_funds"
				open={openModalReserve_insufficient_funds}
				onConfirm={() => {
					setOpenReserve_insufficient_funds(false)
				}}
				onCancel={() => {
					Clean()
					setOpenReserve_insufficient_funds(false)
				}}
				data={reservedFunds}
			/>
			<ConfirmModal type="fileInvalid" open={filesInvalid} onConfirm={() => setFilesInvalid(false)} onCancel={() => setFilesInvalid(false)} />
			<ConfirmModal
				type="minimumRecords"
				open={openModalMinimum}
				onConfirm={() => setOpenModalMinimum(false)}
				onCancel={() => setOpenModalMinimum(false)}
			/>
			<ConfirmModal type="fileValid" open={fileValid} onConfirm={() => setFileValid(false)} onCancel={() => setFileValid(false)} />
			<ConfirmModal
				type="ModalWarning"
				open={openModalWarning}
				onConfirm={() => setOpenModalWarning(false)}
				onCancel={() => setOpenModalWarning(false)}
			/>
			<ModalInfo open={false} icon="icon-alerta_modal" type="highValue" />

			<ModalInfo open={manualShipping ? true : false} icon="icon-alerta_modal" type="highValue" />
			<ModalInfo
				open={importNewFile}
				icon="icon-alerta_modal"
				type="getConditionImportFile"
				setImportNewFile={setImportNewFile}
				GetValidationNewFile={GetValidationNewFile}
			/>
			<ModalInfo open={loading} icon="icon-alerta_modal" type="loading" />
			<NewConfirmModal
				type={modalOpen === 'sair' ? 'Close' : 'cancel'}
				open={cancelModal}
				text={modalOpen === 'sair' && t('FIELD_GENERAL_RULES_ALERT')}
				onConfirm={modalOpen === 'sair' ? handleOnConfirm : handleOnConfirmDelete}
				onCancel={handleOnCancel}
			/>
			<NewConfirmModal
				text="Todos os registros serão alterados. Deseja continuar?"
				type="cancel"
				open={openModalValue}
				onConfirm={handleOnConfirmvalue}
				onCancel={handleOnCancelvalue}
			/>
			<S.InputsContain onSubmit={handleSubmit(onSubmit, onError)}>
				<S.TitleContainer>
					<S.GroupButton>
						<Section
							title={t('AWARD')}
							style={{
								flexGrow: 1,
							}}
						/>

						<div className="btn-container">
							<Button className="btn-default-cancel" size="sm" variant="contained" color="greenBorderButtonColor" onClick={handleOnCancel}>
								{t('BUTTON_CANCEL')}
							</Button>
							<Button
								className="btn-default-process"
								size="sm"
								variant="contained"
								color="greenButtonColor"
								type="submit"
								disabled={(optionsReserve.length === 0 && optionsType.length === 0) || FilesMultipleError.length > 0}
							>
								<S.IconButton className="icon-processar" />
								{t('PROCESS')}
							</Button>
						</div>
					</S.GroupButton>
				</S.TitleContainer>
				<S.FormColumn>
					<div className="column-form-tool">
						<Controller
							as={
								<TextField
									className="input-form-tool-description"
									autoComplete="off"
									error={errors.description || bankReserveError[0] === 3 ? true : false}
									helperText={errors.description ? errors.description.message : bankReserveError[0] === 3 ? t(bankReserveError[1]) : ''}
									size="small"
									id="outlined-helperText"
									label={<CustomRequiredField value={t('DESCRIPTION_AWARD')} />}
									variant="outlined"
									inputProps={{
										maxLength: 80,
									}}
									value={description}
									onChange={e => setDescription(e.target.value)}
								/>
							}
							name="description"
							control={control}
						/>
						<S.IconInfo>
							<Tooltip
								title={t('tooltip_premiacao_descricao')}
								classes={{
									tooltip: classes.customWidth,
									arrow: classes.customArrow,
								}}
								arrow
								placement="top"
								interactive
							>
								<S.IconInfo className="icon-ico_mais_informacao" />
							</Tooltip>
						</S.IconInfo>
						{/* <ToolTip /> */}
					</div>

					<FormControl size="small" variant="outlined" className="input-form-type">
						<Controller
							onChange={([, data]) => data}
							name="creditReason"
							control={control}
							render={({ onChange }) => (
								<Autocomplete
									styles={customSelectStyles}
									id="combo-box-demo"
									options={optionsType}
									getOptionLabel={option => (option.description ? option.description : '')}
									PopperComponent={CustomPopper}
									disableClearable={true}
									value={selectedType}
									renderInput={params => (
										<div ref={params.InputProps.ref}>
											<TextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
												error={!params.inputProps.value ? errors.creditReason : ''}
												label={<CustomRequiredField value={t('EVENT')} />}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
									onChange={(e, data) => handleSelectedEvent(data, onChange)}
								/>
							)}
						/>
						{!selectedType ? errors.creditReason ? <FormHelperText error>{errors.creditReason.message}</FormHelperText> : null : ''}
					</FormControl>
				</S.FormColumn>
				<S.FormSection>
					<FormControl className="input-form-tool-reserv" size="small" variant="outlined">
						<Controller
							onChange={([, data]) => data}
							name="reservation"
							control={control}
							render={({ onChange }) => (
								<Autocomplete
									styles={customSelectStyles}
									id="combo-box-demo"
									options={optionsReserve}
									getOptionLabel={option => (option.name ? option.name : '')}
									PopperComponent={CustomPopper}
									disableClearable={true}
									value={selectedReserve}
									renderInput={params => (
										<div ref={params.InputProps.ref}>
											<TextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
												error={
													!params.inputProps.value
														? errors.reservation || bankMessageCode !== '' || (bankReserveError[0] === 0 && bankReserveError[1])
														: ''
												}
												label={<CustomRequiredField value={t('SELECT_RESERVATION')} />}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
									onChange={(e, data) => handleChangeReserve(data, onChange)}
								/>
							)}
						/>
						{!selectedReserve ? (
							errors.reservation || bankReserveError[0] === 0 || bankMessageCode !== '' ? (
								<FormHelperText error>
									{bankReserveError[0] === 0 || bankReserveError[1]
										? t(bankReserveError[1])
										: bankMessageCode !== ''
											? t(bankMessageCode)
											: errors.reservation.message}
								</FormHelperText>
							) : null
						) : (
							''
						)}
					</FormControl>

					{valueReserve.reserveBalance > 0 && (
						<S.ValueStandard className="valueStandard-icon" themeColor={themeColor}>
							<S.IcoConversao themeColor={themeColor} className="icon-ico_conversao" />
							<div className="valueStandard-text">
								<span style={{ fontSize: '12px' }}>{t('RESERVED_BALANCE_AMOUNT')}</span>
								<div className="valueStandard-value">
									{numberFormatText(
										valueReserve ? valueReserve.reserveBalance : '0',
										localStorage.getItem('currencySymbol')
											? `${localStorage.getItem('currencySymbol')}\n`
											: // : envs
											// 	.REACT_APP_CURRENCY_SYMBOL,
											envs.REACT_APP_CURRENCY_SYMBOL,

										2,
										',',
									)}
								</div>
							</div>
						</S.ValueStandard>
					)}
				</S.FormSection>
				<S.Partition themeColor={themeColor}>
					<div className="flex-row">
						<FormControl id="formControl" component="fieldset" error={error} className={classes.formControl}>
							<FormLabel
								style={{
									fontSize: 'normal normal normal 13px/60px Roboto;',
								}}
								component="legend"
							>
								{t('EARLY_DISTRIBUTION')}
							</FormLabel>
							<RadioGroup className="radio-group" aria-label="quiz" row value={switchValue}>
								{console.log(GetGeneral.allowAntecipationTax)}
								<FormControlLabel
									control={
										<Controller
											name="switcherValue"
											control={control}
											defaultValue={false}
											render={() => (
												<Switch
													value={switchValue}
													size="medium"
													name="checkedB"
													color="primary"
													// disabled={
													// 	!GetGeneral.allowAntecipationTax
													// }
													onChange={() => {
														setSwitchValue(!switchValue)
														setValue('switcherValue', !switchValue)
													}}
												/>
											)}
										/>
									}
									label={
										<div className="rateCss">
											<span>{switchValue ? 'Sim' : 'Não'}</span>
											{switchValue && (
												<Controller
													as={
														<TextField
															focused={true}
															autoComplete="off"
															className="txt-input"
															variant="outlined"
															size="small"
															error={errors.anticipationFee || validateDown}
															helperText={errors.anticipationFee ? 'Obrigatório' : ''}
															label={`${t('DEFAULT_RATE')} *`}
															name="anticipationFee"
															InputProps={{
																inputComponent: PercentageInput,
															}}
														/>
													}
													name="anticipationFee"
													control={control}
												/>
											)}
										</div>
									}
								/>
							</RadioGroup>
							{validateDown && watchAllFields.switcherValue && watchAllFields.anticipationFee !== '' && (
								<FormHelperText
									style={{
										fontSize: '14px',
										width: '300px',
									}}
									error
								>
									{'Você não pode definir uma taxa menor do que o valor padrão.'}
								</FormHelperText>
							)}
						</FormControl>

						{switchValue && (
							<FormControl component="fieldset" error={error} className={classes.formControl} id="formControl">
								<FormLabel component="legend" color="#717171">
									{t('GRACE_PERID')}
								</FormLabel>
								<Controller
									as={
										<RadioGroup className="radioGroup" aria-label="quiz" row name="radioValue">
											<div className="radioContainer">
												<div className="radioButton">
													<FormControlLabel value="1" control={<Radio disabled={GetGeneral.awardTaxType !== 2} name="1" color="primary" />} />

													<div className="daysCss">
														<span>Dias</span>
														<Controller
															as={
																<TextField
																	className="txtField"
																	defaultValue={switchValue ? GetGeneral.waitingPeriod : ''}
																	autoComplete="off"
																	variant={'outlined'}
																	size="small"
																	name="periodDays"
																	disabled={watchAllFields.radioValue === '2' || GetGeneral.awardTaxType !== 2}
																	style={{
																		height: '40px',
																		width: '55px',
																		marginLeft: '15px',
																		backgroundColor: watchAllFields.radioValue === '2' || GetGeneral.awardTaxType !== 2 ? '#f5f5f5' : '#fff',
																	}}
																	error={(errors.periodDays && watchAllFields.radioValue === '1') || watchAllFields.periodDays === '0'}
																	helperText={errors.periodDays && watchAllFields.radioValue === '1' ? errors.periodDays.message : ''}
																	InputProps={{
																		inputComponent: MaskedInputNunber,
																		maxLength: 3,
																	}}
																/>
															}
															name="periodDays"
															control={control}
														/>
													</div>
												</div>
												<FormHelperText error className="days_error">
													{watchAllFields.periodDays === '0' ? 'Informe um período maior que 0 dias.' : ''}
												</FormHelperText>
											</div>

											<div className="radioButtonDate">
												<FormControlLabel value="2" control={<Radio color="primary" name="2" disabled={GetGeneral.awardTaxType !== 2} />} />
												<Controller
													as={
														<CustomDateNew
															className="dateCss"
															size="small"
															name="datePeriod"
															disabled={watchAllFields.radioValue === '1' || GetGeneral.awardTaxType !== 2}
															isValidDate={currentDate => currentDate.isAfter(moment())}
															marginLeft={'15px'}
															error={!dateValid || (errors.datePeriod && watchAllFields.radioValue === '2') ? true : false}
															helperText={
																!dateValid
																	? t('Data inválida')
																	: errors.datePeriod && watchAllFields.radioValue === '2'
																		? errors.datePeriod.message
																		: ''
															}
														/>
													}
													name="datePeriod"
													control={control}
												/>
											</div>
										</RadioGroup>
									}
									name="radioValue"
									control={control}
								/>
							</FormControl>
						)}
					</div>
				</S.Partition>
				<Controller
					as={
						<TextField
							autoComplete="off"
							size="small"
							id="outlined-helperText"
							label="Observação"
							variant="outlined"
							style={{ width: '100%' }}
							name="note"
							value={note}
							inputProps={{
								maxLength: 80,
							}}
							onChange={e => setNote(e.target.value)}
						/>
					}
					name="note"
					control={control}
				/>
			</S.InputsContain>
			{/* CONTENT */}
			<div style={{ marginTop: '20px' }}>
				<S.SectionButton>
					<Button
						className="buttonDiscard"
						size="sm"
						variant="contained"
						color="greenBorderButtonColor"
						onClick={() => Clean()}
						disabled={list.length === 0 && cpf.length === 0 && standardValue.length === 0}
						style={
							list.length === 0 && cpf.length === 0 && standardValue.length <= 0
								? {
									opacity: '0.3',
									color: 'black',
									border: '0.3px solid black',
									backgroundColor: '#fff',
									cursor: 'default',
								}
								: {}
						}
					>
						Descartar
					</Button>
				</S.SectionButton>
				<NewTabs style={{ width: '100%' }}>
					<NewTabList style={{ minWidth: '800px', width: '100%' }}>
						<NewTab
							onClick={() => handleChangeTab(1)}
							disabled={(inclusionImportTab === false && list.length > 0) || standardValue.length > 0 ? true : false}
							style={
								(inclusionImportTab === false && list.length > 0) || standardValue.length > 0
									? {
										color: '#D0D0D0	',
										backgroundColor: '#F8F8F8',
										cursor: 'default',
									}
									: { padding: '10px 35px' }
							}
						>
							INCLUSÃO MANUAL
						</NewTab>
						<NewTab
							onClick={() => handleChangeTab(2)}
							disabled={(inclusionImportTab === true && list.length > 0) || cpf.length > 0 ? true : false}
							style={
								(inclusionImportTab === true && list.length > 0) || cpf.length > 0
									? {
										color: '#D0D0D0	',
										backgroundColor: '#F8F8F8',
										cursor: 'default',
									}
									: { padding: '10px 40px' }
							}
						>
							IMPORTAÇÃO
						</NewTab>
					</NewTabList>
				</NewTabs>
			</div>
			{inclusionImportTab ? (
				<div>
					{/* INCLUSÃO MANUAL */}
					<S.FormAddParticipant>
						<TextField
							fullWidth
							size="small"
							autoComplete="off"
							className="input-add-participant"
							error={error || (submitConsumer && cpf.length === 0) ? true : false}
							helperText={submitConsumer && cpf.length === 0 ? 'Campo Obrigatório' : error ? error : ''}
							label={<CustomRequiredField value={t('CPF_OR_CODE')} />}
							type="text"
							variant="outlined"
							InputProps={{
								inputComponent: TextMaskCustom,
							}}
							value={cpf}
							name="cpf"
							onChange={e => CheckCPFCODE(e)}
							style={{
								width: '33.5rem',
								height: '38px',
								marginRight: '18px',
							}}
						/>
						<div>
							<Controller
								render={({ name, onChange, value }) => (
									<CurrencyInput
										className="currency-input"
										max={1000000}
										error={FilesMultipleError || (value === '' && submitConsumer) ? true : false}
										helperText={FilesMultipleError ? FilesMultipleError : watchAllFields.giftValue === 0 && submitConsumer ? 'Obrigatório' : ''}
										onValueChange={onChange}
										value={value}
										setValue={setValue}
										onChange={setValueAward(value)}
										style={{ width: '15rem' }}
										label={<CustomRequiredField value={t('Valor do crédito (R$)')} />}
										clear={clear}
										name={name}
									/>
								)}
								control={control}
								name="giftValue"
								defaultValue=""
							/>
							{!(valueReserve.reserveBalance > 0) && submitConsumer && valueAward > 0 && (
								<FormHelperText error>{'Selecione uma reserva'}</FormHelperText>
							)}
						</div>

						<Button
							className="button-add-participant"
							style={{
								margin: '0',
								marginLeft: '18px',
							}}
							size="sm"
							variant="contained"
							color="greenButtonColor"
							type="submit"
							onClick={e => {
								setFilesMultipleError(true)
								AddCosumerList(e)
							}}
						>
							{t('BUTTON_ADD')}
						</Button>
					</S.FormAddParticipant>
					<TableColumns
						tableRef={tableRef}
						data={realList}
						handleOnCancelDelete={handleOnCancelDelete}
						selectedValue={inclusionImportTab}
						paging={realList.length > 0 ? true : false}
					/>
				</div>
			) : (
				<div>
					<Grid container spacing={3}>
						<Grid
							item
							xs={12}
							sm={12}
							md={12}
						//lg={8}
						>
							{selectedValue === 'batch' ? (
								<S.InfoImportTab themeColor={themeColor}>
									O arquivo de importação deve conter os dados de CPF ou Código do participante e o Valor a ser creditado na conta do destinatário.
									<br /> O sistema aceitará o mínimo de 10 e o máximo de 1.000 registros no mesmo arquivo.
									<br />
									Para mais informações{' '}
									<span
										style={{
											textAlign: 'left',
											font: 'italic normal bold 13px/20px Roboto',
											letterSpacing: '0px',
											color: '#74CCDA !important',
											cursor: 'pointer',
										}}
										onClick={() => {
											setOpenModalWarning(!openModalWarning)
										}}
									>
										clique aqui.
									</span>
								</S.InfoImportTab>
							) : (
								<S.InfoImportTab themeColor={themeColor}>
									O arquivo de importação deve conter os dados de CPF ou Código do participante e o Valor a ser creditado na conta do destinatário.
									<br /> O sistema aceitará o mínimo de 10 e o máximo de 1.000 registros no mesmo arquivo.
									<br /> Para mais informações{' '}
									<span
										onClick={() => {
											setOpenModalWarning(!openModalWarning)
										}}
										style={{
											cursor: 'pointer',
											textAlign: 'left',
											font: 'italic normal bold 13px/20px Roboto',
											letterSpacing: '0px',
											color: '#74CCDA !important',
										}}
									>
										clique aqui.
									</span>
								</S.InfoImportTab>
							)}
						</Grid>
					</Grid>

					<FormControl
						component="fieldset"
						style={{
							margin: '1.4em 0px 1.7em 4px',
							flexDirection: 'row',
						}}
						onChange={e => handleChangeRadio(e.target.value)}
					>
						<RadioGroup
							aria-label="reversal"
							defaultValue="batch"
							name="radio-buttons-group"
							style={{
								flexDirection: 'row',
								alignItems: 'flex-start',
							}}
						>
							<FormControlLabel
								checked={selectedValue === 'batch'}
								disabled={list.length > 0 && selectedValue === 'individual' ? true : false}
								value="batch"
								control={
									<Radio
										color="primary"
										style={
											selectedValue === 'batch'
												? {
													color: themeColor.structure.backgroundColor,
												}
												: { color: '#636363' }
										}
									/>
								}
								label="Valores importados"
								style={
									selectedValue === 'batch'
										? {
											color: themeColor.titles.highlight.textColor,
										}
										: { color: '#636363' }
								}
							/>
							<FormControlLabel
								checked={selectedValue === 'individual'}
								value="individual"
								// disabled={list.length > 0 ? true : false}
								control={
									<Radio
										color="primary"
										style={
											selectedValue === 'individual'
												? {
													color: themeColor.structure.backgroundColor,
												}
												: { color: '#636363' }
										}
									/>
								}
								label="Valor padrão"
								style={
									selectedValue === 'individual'
										? {
											color: themeColor.titles.highlight.textColor,
										}
										: { color: '#636363' }
								}
							/>
							{radioValue ? (
								<>
									<Controller
										render={({ name, onChange, value }) => (
											<CurrencyInput
												readOnly={isList}
												disabled={isList}
												className="currency-input"
												max={1000000}
												error={standardValue > valueReserve.reserveBalance || (submit && !value) ? true : false}
												helperText={submit && !value ? 'Campo obrigatório' : ''}
												onValueChange={onChange}
												value={value ? value : 0}
												setValue={setValue}
												onChange={setStandardValue(value ? value : 0)}
												style={{ width: '173px' }}
												label={<CustomRequiredField value={t('Definir valor (R$)')} />}
												clear={clear}
												name={name}
											/>
										)}
										control={control}
										name="standardValue"
									/>

									{radioValue && disableBtn && list.length > 0 ? (
										<Button
											className="button-upload"
											style={{
												backgroundColor: themeColor.navigation.buttons.backgroundColor,
												marginLeft: '10px',
												height: '40px',
												marginTop: '0',
											}}
											onClick={() => {
												FileImport(file, standardValue)
											}}
										>
											Atualizar valores
										</Button>
									) : (
										''
									)}
								</>
							) : null}
						</RadioGroup>
					</FormControl>
					<S.InputsContain
						style={{
							flexDirection: 'row',
							alignItems: 'flex-start',
						}}
					>
						<S.InputFileContainer
							style={{
								alignItems: 'flex-start',
							}}
						>
							<div className="grid-container">
								<TextField
									className="outlined-textFile"
									autoComplete="off"
									fullWidth
									size="small"
									id="outlined-textFile"
									label={<CustomRequiredField value={t('Extensões Excel e CSV/ TXT')} />}
									accept=".csv, .txt"
									variant="outlined"
									value={fileName}
									error={FilesMultipleError}
									helperText={FilesMultipleError}
								/>
								{standardValue > valueReserve.reserveBalance && !FilesMultipleError ? (
									<FormHelperText error>{t('Reserve_insufficient')}</FormHelperText>
								) : null}
							</div>

							<input
								autoComplete="off"
								className={classes.input}
								style={{ display: 'none' }}
								id="raised-file"
								name="raised-file"
								accept=".csv, .txt"
								type="file"
								disabled={
									valueReserve.reserveBalance > 0 && importNewFile === false && standardValue <= valueReserve.reserveBalance
										? standardValue <= 0 && radioValue
											? true
											: false
										: true
								}
								onChange={e => handleChangeFile(e)}
							/>

							<S.AddFileBtn
								style={{
									transform: 'translateY(-5.5px)',
								}}
							>
								<label htmlFor="raised-file">
									<Button
										className="button-upload"
										size="sm"
										color="greenButtonColor"
										variant="raised"
										component="span"
										onClick={() => {
											setSubmit(true)
											setDisableBtn(false)
											if (valueReserve.reserveBalance > 0) {
												if (list.length > 0) {
													setImportNewFile(true)
												}
											} else {
												setFilesMultipleError('Selecione uma reserva')
											}
										}}
									>
										<S.IconButton className="icon-import_CSV" />
										Importar arquivo
									</Button>
								</label>

								<S.InfoContain>
									{selectedValue === 'batch' ? (
										<div
											className="info-contain-img"
											style={{
												transform: 'translateY(2px) !important',
											}}
										>
											<S.IconInfo
												onClick={() => {
													setOpenModalWarning(!openModalWarning)
												}}
												className="icon-ico_mais_informacao"
											/>
										</div>
									) : (
										<div
											className="info-contain-img"
											style={{
												transform: 'translateY(2px) !important',
											}}
										>
											<S.IconInfo
												onClick={() => {
													setOpenModalWarning(!openModalWarning)
												}}
												className="icon-ico_mais_informacao"
											/>
										</div>
									)}
								</S.InfoContain>
							</S.AddFileBtn>
						</S.InputFileContainer>
					</S.InputsContain>
					{realList.length > 0 && (
						<TableColumns
							tableRef={tableRef}
							search={true}
							//title="Participantes adicionados"
							data={realList}
							handleOnCancelDelete={handleOnCancelDelete}
							selectedValue={inclusionImportTab}
							paging={realList.length > 0 ? true : false}
						/>
					)}
				</div>
			)}
		</S.Container>
	)
}
export default CreateAward
