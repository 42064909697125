import * as actionTypes from './actionsTypes';

export const showLoader = (data) => {
    return {
        type: actionTypes.SHOW_LOADER,
        loading: data
    };
};

export const hideLoader = () => {
    return {
        type: actionTypes.HIDE_LOADER,
        loading: null
    };
};

//----------------------------------------------------

export const ShowLoader = (data) => {
    return dispatch => (
        dispatch(showLoader(data))
    );
};

export const HideLoader = () => {
    return dispatch => (
        dispatch(hideLoader())
    );
}
