import cp_estrutura from '../../../../assets/img/Icones cadastro do parceiro/cp_estrutura.png'
import cp_simples from '../../../../assets/img/Icones cadastro do parceiro/cp_simples.png'
import cp_filial from '../../../../assets/img/Icones cadastro do parceiro/cp_filial.png'
import cp_departamento from '../../../../assets/img/Icones cadastro do parceiro/cp_departamento.png'
import cp_centro_custo from '../../../../assets/img/Icones cadastro do parceiro/cp_centro_custo.png'
import cp_unidade_negocio from '../../../../assets/img/Icones cadastro do parceiro/cp_unidade_negocio.png'

export const businessMock = [
	{
		id: 2,
		type: 'BusinessUnit',
		name: 'Unidade de negócio',
		title: 'Unidade de negócio',
		btnTxt: 'Criar unidade de negócio',
		description:
			'É uma entidade dentro de uma empresa que opera de forma independente e possui sua própria estratégia, objetivos, recursos e responsabilidades.',
		image: cp_unidade_negocio,
		bgColor: '#FBB919',
		subColor: '#FCE08B',
		isFinal: false,
	},
	{
		id: 3,
		btnTxt: 'Criar centro de custo',
		name: 'Centro de custo',
		title: 'Centro de custo',
		type: 'CostCenter',
		description:
			'É uma unidade organizacional ou departamento dentro de uma empresa, sendo responsável por gerir despesas ou custos do seu segmento. Ela é criada para acompanhar os gastos em áreas que não geram receitas direta.',
		image: cp_centro_custo,
		bgColor: '#7BD35F',
		subColor: '#B2EBA0',
		isFinal: false,
	},
	{
		id: 4,
		name: 'Departamento',
		title: 'Departamento',
		type: 'Department',
		btnTxt: 'Criar departamento',
		description:
			'É uma subdivisão funcional de uma empresa, que agrupa colaboradores em suas habilidades e responsabilidades similares. Os departamentos são organizados por pessoas para execução de tarefas especificas dentro de uma empresa.',
		image: cp_departamento,
		subColor: '#CEEBF5',
		bgColor: '#8ED9F1',
		isFinal: false,
	},
	{
		id: 5,
		btnTxt: 'Criar Filial',
		name: 'Filial',
		type: 'Branch',
		title: 'Filial',
		description:
			'Sendo ela uma organização secundária de uma empresa que está localizada em outro ambiente (Geográfico), geralmente uma filial opera pelas diretrizes da empresa mãe.',
		image: cp_filial,
		subColor: '#DDD7EF',
		bgColor: '#C3B6EA',
		isFinal: false,
	},
	{
		id: 6,
		btnTxt: 'Criar Franquia',
		name: 'Franquia',
		type: 'Franchise',
		title: 'Franquia',
		description:
			'Sendo ela uma organização secundária de uma empresa que está localizada em outro ambiente (Geográfico), geralmente uma filial opera pelas diretrizes da empresa mãe.',
		image: cp_filial,
		subColor: '#DFAE93',
		bgColor: '#DFAE93',
		isFinal: false,
	},
	{
		id: 7,
		btnTxt: '',
		type: 'Simple',
		title: 'Simples',
		name: 'Simples',
		description: 'Essa estrutura define que uma empresa criada não poderá ter divisões segmentadas a ela.',
		image: cp_simples,
		subColor: '#CBEBEE',
		bgColor: '#B4DFE3',
		isFinal: false,
	},
	{
		id: 8,
		btnTxt: '',
		type: 'Matriz',
		title: 'Matriz',
		description:
			'É uma entidade dentro de uma empresa que opera de forma independente e possui sua própria estratégia, objetivos, recursos e responsabilidades.',

		bgColor: '#7ACDD6',
		subColor: '#495DA2',
		isFinal: true,
	},
]

export const defaultBusinessMock = {
	id: 1,
	name: 'Divisão de negócio',
	subtitle: 'Selecione o tipo de',
	description: 'Selecione o tipo de divisão da empresa do parceiro*',
	image: cp_estrutura,
	bgColor: '#E2E2E2',
	subColor: '#EBEBEB',
	isFinal: false,
	btnTxt: 'Criar divisão de negócio',
	type: 'default',
}

export const defaultBusinessColors = {
	BusinessUnit: '#FBB919',
	CostCenter: '#7BD35F',
	Department: '#8ED9F1',
	Branch: '#C3B6EA',
	Franchise: '#DFAE93',
}

//
export const BusinessInitials = {
	BusinessUnit: 'Un',
	CostCenter: 'CC',
	Department: 'Departamento',
	Branch: 'Filial',
	Franchise: 'Franquia',
}
