import { Tooltip } from '@material-ui/core'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import moment from 'moment'
import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAward } from '../../../Providers/AwardContext'
import { GetAwards } from '../../../redux/api/award.js'
import { PartnerValidatePartner } from '../../../redux/api/partner'
import ConfirmRefundModal from '../ConfirmRefundModal'
import { ActionBtn } from '../style'
import { Container, IconContainer, IconCOP, IconInfo } from './style'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { Search } from '@material-ui/icons'
import Button from '../../../components/CustomButtons/Button'

const Processed = () => {
	const tableRef = React.useRef()
	const {
		setIdDetailProcessed,
		valueTypeFilter,
		setTypesFilterAwardProc,
		setValidatedReversal,
		allowDigitalWallet,
	} = useAward()
	const history = useHistory()

	const [openModal, setOpenModal] = useState(false)
	const [errorModal, setErrorModal] = useState(false)
	const [pageSizeState, setPageSizeState] = useState(10)
	const [recordPageState, setRecordPageState] = useState()
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const handleOpenModal = (id) => {
		localStorage.setItem('idDetailProcessed', id)
		setOpenModal(true)
	}
	const handleCloseModal = () => setOpenModal(false)

	const handleGetOriginData = useCallback(async () => {
		const origin = await GetAwards(null, null, null, null, 1)
		let typeFilter = []

		origin &&
			origin.data &&
			origin.data.results &&
			origin.data.results.map((item, index) => {
				typeFilter.push({
					value: item.partnerCreditOrigin.key,
					key: item.partnerCreditOrigin.key,
					label: item.partnerCreditOrigin.value,
				})
			})

		setTypesFilterAwardProc(
			[
				...new Set(
					typeFilter && typeFilter.map((obj) => JSON.stringify(obj)),
				),
			].map((str) => JSON.parse(str)),
		)
	}, [])

	useEffect(() => {
		handleGetOriginData()
	}, [])

	useEffect(() => {
		tableRef.current.onChangePage({}, 0)
		tableRef.current.onQueryChange({ page: 0, search: '' })
	}, [valueTypeFilter])

	const confirmInactive = async (value) => {
		let id = localStorage.getItem('idDetailProcessed')
		let response = await PartnerValidatePartner(value)
		response && handleCloseModal()

		if (response) {
			setValidatedReversal(true)
			history.push(`/financial/reversal/${id}`)
		} else {
			setErrorModal(true)
		}
	}

	const handleGetAwardData = useCallback(
		async (pageSize, page) => {
			let startDate = null
			let endDate = null
			if (valueTypeFilter.dateFilter) {
				startDate = moment(
					valueTypeFilter.dateFilter,
					'DD/MM/YYYY',
				).format('YYYY-MM-DD')
			}
			if (valueTypeFilter.dateFilterTo) {
				endDate = moment(
					valueTypeFilter.dateFilterTo,
					'DD/MM/YYYY',
				).format('YYYY-MM-DD')
			}

			const res = await GetAwards(
				valueTypeFilter.description,
				valueTypeFilter.typeFilter,
				startDate,
				endDate,
				1,
				pageSize,
				page,
			)
			return res
		},
		[valueTypeFilter],
	)

	const handleGetId = (id) => {
		localStorage.setItem('idDetailProcessed', id)
		setIdDetailProcessed(id)
		history.push(`/financial/dataAward/${id}`)
	}

	const handleGetIdLog = (id) => {
		localStorage.setItem('idDetailProcessed', id)
		setIdDetailProcessed(id)
		history.push(`/financial/LogsWard/${id}`)
	}

	return (
		<Container themeColor={themeColor}>
			<ConfirmRefundModal
				open={openModal}
				onCancel={handleCloseModal}
				onDelete={(value) => confirmInactive(value)}
				error={errorModal && 'Senha incorreta'}
			/>

			<MaterialTable
				tableRef={tableRef}
				style={{ width: '100%' }}
				options={{
					emptyRowsWhenPaging: false,
					toolBar: false,
					pageSizeOptions: [10, 20, 30, 40, 50],
					search: false,
					pageSize:
						recordPageState < 10 ? recordPageState : pageSizeState,
					sorting: false,
					draggable: false,

				}}
				columns={[
					{
						title: 'Evento',
						searchable: true,
						cellStyle: {
							width: '23%',
							maxWidth: '23%',
							minWidth: '23%',
							backgroundColor: '#fff',
						},

						render: (props) => (
							<div
								style={{
									alignItems: 'flex-start',
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									height: '34px',
									textAlign: props.processingDate
										? 'left'
										: 'center',
								}}
							>
								<p style={{ fontSize: '15px' }}>
									{props.processingDate
										? moment(props.processingDate).format(
											'DD/MM/YYYY',
										)
										: '-'}
								</p>
								<span
									style={{
										fontStyle: 'italic',
										fontSize: '12px',
										textAlign: props.processingDate
											? 'left'
											: 'center',
									}}
								>
									{props.processingDate
										? moment(props.processingDate).format(
											'HH:mm',
										)
										: ''}
								</span>
							</div>
						),
					},
					{
						title: 'Descrição/Tipo',
						field: 'description',
						searchable: true,
						cellStyle: {
							textAlign: 'left',
							backgroundColor: '#fff',
							width: '65%',
							maxWidth: '65%',
							minWidth: '65%',
						},

						render: (props) => (
							<IconContainer>
								<Tooltip
									title="Taxa de antecipação"
									color="#000000"
									arrow
									placement="top-start"
								>
									<IconInfo
										className="icon-antecipacao"
										style={{
											color: `${props.isAnticipated
												? ''
												: '#c4c4c4'
												}`,
										}}
									/>
								</Tooltip>
								<div
									style={{
										alignItems: 'flex-start',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '34px',
										marginLeft: '10px',
									}}
								>
									<p style={{ fontSize: '15px', margin: 0 }}>
										{props.description}
									</p>
									<span
										style={{
											fontSize: '12px',
											fontStyle: 'italic',
										}}
									>
										{props.partnerCreditOrigin.value}
									</span>
								</div>
							</IconContainer>
						),
					},

					{
						title: 'Ações',
						field: 'actions',
						cellStyle: {
							textAlign: 'left',
							width: '148px',
							maxWidth: '148px',
							minWidth: '148px',
							backgroundColor: '#fff',
						},
						options: { className: 'teste' },

						sorting: false,
						render: (props) => (
							<div
								style={{
									width: '133px',
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Button
									color="greenButtonColor"
									style={{
										height: '43px',
										width: '43px', padding: '20px'
									}}
									title="Detalhe"
									onClick={() => handleGetId(props.id)}
								>
									<IconCOP style={{ margin: '0px' }} />
								</Button>
								<ActionBtn
									themeColor={themeColor}
									title="Estorno"
									onClick={() => handleOpenModal(props.id)}
									disabled={
										allowDigitalWallet === false ||
											props.status.value === 'CANCELED' ||
											props.progressText === 'Cancelado' ||
											props.status.value === 'REFUNDED' ||
											props.allowRefund === false
											? true
											: false
									}
									style={{
										backgroundColor: `${allowDigitalWallet === false ||
											props.status.value === 'CANCELED' ||
											props.progressText ===
											'Cancelado' ||
											props.status.value === 'REFUNDED' ||
											props.allowRefund === false
											? '#c4c4c4'
											: `${themeColor.navigation.buttons.backgroundColor}`
											}`,

										cursor: `${props.status.value === 'CANCELED' ||
											props.progressText ===
											'Cancelado' ||
											props.status.value === 'REFUNDED' ||
											props.allowRefund === false
											? 'default'
											: 'pointer'
											}`,
									}}
								>
									<i className="icon-estorno" />
								</ActionBtn>
								<Button
									color="greenButtonColor"
									style={{
										height: '43px',
										width: '43px', padding: '20px'
									}}
									title="Log"
									onClick={() => handleGetIdLog(props.id)}
								>
									<i className="icon-log" style={{ fontSize: '20px' }} />
								</Button>
							</div>
						),
					},
				]}
				data={(query) =>
					new Promise((resolve, reject) => {
						handleGetAwardData(
							query.pageSize,
							query.page + 1,
							query.search,
							query.orderDirection,
						).then((result) => {
							if (result) {
								setPageSizeState(result.data.pageSize)
								setRecordPageState(result.data.recordCount)
								if (result.data) {
									resolve({
										data: result.data.results,
										page: result.data.currentPage - 1,
										totalCount: result.data.recordCount,
										pageSize: result.data.pageSize,
									})
								}
							}
						})
					})
				}
			/>
		</Container >
	)
}
export default Processed
