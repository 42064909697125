import styled from 'styled-components'

export const Container = styled.div`
	.cancel {
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		background-color: #eee;
		font-size: 24px;
		color: #eb6c61;
		:hover {
			background-color: #eb6c61;
			color: #ffffff;
		}
	}
	.save {
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		background-color: #eee;
		font-size: 24px;
		color: #5fb55f;
		:hover {
			background-color: #5fb55f;
			color: #ffffff;
		}
	}
	.blueAction {
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		background-color: #eee;
		font-size: 24px;
		color: #1a9cac;
		:hover {
			background-color: #1a9cac;
			color: #ffffff;
		}
	}
	.softBlueAction {
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		background-color: #eee;
		font-size: 24px;
		color: #2cc3d6;
		:hover {
			background-color: #2cc3d6;
			color: #ffffff;
		}
	}

	.softBlue {
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		background-color: #2cc3d6;
		font-size: 24px;
		color: #ffffff;
		:hover {
			background-color: #1a9cac;
			color: #ffffff;
		}
	}
`
