import { Stack } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Stack)`
	.MuiBreadcrumbs-ol {
		font-size: 13px !important;
	}

	.MuiBreadcrumbs-li {
		p {
			font-size: 13px !important;
			font-weight: 700;
		}
	}
`
