import React, { Fragment } from 'react'
import * as S from '../styles'
import { Tooltip } from '@material-ui/core'

const Color = {
    Costcenter: <S.Indicator className="indicator" Color='#3EC450' />,
    BusinessUnit: <S.Indicator className="indicator" Color='#FB9006' />,
    Branch: <S.Indicator className="indicator" Color='#7E6BB9' />,
    Department: <S.Indicator className="indicator" Color='#1BBED3' />,
    FI: <S.Indicator className="indicator" Color='#1BBED3' />,
}

const TooltipText = {
    Costcenter: 'Centro de Custo',
    BusinessUnit: 'Unidade de negócio',
    Branch: 'Filial',
    Department: 'Departamento',
    FI: 'Filial',
}

export const Columns = (t) => [
    {
        title: '',
        field: 'segmentationType',
        sorting: false,
        cellStyle: {
            width: '2px',
            marginLeft: '0px',
            paddingLeft: '0px',
        },
        headerStyle: {
            width: '2px',
            marginLeft: '0px',
            paddingLeft: '0px',
        },
        render: rowData => (
            <Fragment>
                <Tooltip
                    arrow
                    id="tooltip-top"
                    placement="top-start"
                    title={TooltipText[rowData.segmentationType.value]}
                >
                    <>
                        {Color[rowData.segmentationType.value]}
                    </>
                </Tooltip>
            </Fragment>
        ),
    },
    {
        title: t('Nome / Divisão de negócio'),
        field: 'name',
        width: '100%',
        cellStyle: {
            width: '100%',
        },
        headerStyle: {
            width: '100%',
            paddingLeft: '25px',
        },
        render: (rowData) => (

            <div style={{
                height: "100%",
            }}>
                <span>{rowData.name}</span>
            </div>
        ),
    },
]