import { yupResolver } from '@hookform/resolvers'
import * as _ from 'lodash'
// Material
import {
	FormControl,
	FormHelperText,
	Grid,
	IconButton as MuiIconButton,
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button.jsx'
import Loader from 'components/Loader/Loader.jsx'

// Components
import Section from 'components/Section'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ConfirmModal from '../../../../../../components/ConfirmModal'
import CurrencyInput from '../../../../../../components/NewCustomInput'
// redux
import { PostBalance, PostFile } from '../../../../../../redux/api/accounts'
import { GetCurrentPartner } from '../../../../../../redux/api/user'
// Context
import { useAccounts } from '../../../../hooks/useAccounts'
import { Layout } from '../../Layout'
import * as S from './style'

import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'
import { ValidateExtension, btnBGCol, initialValues, validation } from './utils'



export function InsertBalance({ title, isOpen, onClose, ...props }) {
	const {
		closeInsertBalance,
		openSnack,
		openErrorSnack,
		refreshTable,
		setRefreshTable,
	} = useAccounts()

	const { t } = useTranslation()
	const { id } = useParams()

	const [fileName, setFileName] = useState('')
	const [file, setFile] = useState([])
	const [invalidFile, setInvalidFile] = useState(false)
	const [currentFile, setCurrentFile] = useState(null)
	const [noVoucher, setNoVoucher] = useState(false)
	const [openCancelModal, setOpenCancelModal] = useState(false)
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const {
		control,
		handleSubmit,
		formState: { errors, isDirty, isSubmitting },
		setValue,
		watch,
		setError,
		clearErrors,
		reset,

	} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(validation),
		mode: 'onChange',
	})

	const watchAllFields = watch()

	useEffect(() => {
		if (watchAllFields.value > 1000000000) {
			setError('value', {
				type: 'manual',
				message: 'Valor acima do permitido',
			})
		} else {
			clearErrors('value')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [watchAllFields.value])



	const handleUploadFile = (event) => {
		if (ValidateExtension(document.getElementById('raised-file'), setInvalidFile)) {
			setFileName(event.target.files[0].name)
			setCurrentFile(event.target.files[0])
		} else {
			setFileName('')
			setCurrentFile(null)
		}
	}

	function handleNewFile() {
		const fileArray = [...file]

		if (currentFile) {
			fileArray.push(currentFile)
			setFile(fileArray)
			setCurrentFile(null)
			document.getElementById('raised-file').value = ''
			setFileName('')

			setNoVoucher(false)
		}
	}

	function handleDeleteItem(_, index) {
		const newArray = [...file]

		newArray.splice(index, 1)
		setFile(newArray)
	}

	const onSubmit = async (data) => {
		//await new Promise((resolve) => setTimeout(resolve, 10000))
		if (file.length <= 0) {
			setNoVoucher(true)
			return
		}
		setNoVoucher(false)

		const url = await PostFile(file[0])

		if (url && url.success === false) {
			if (!_.isEmpty(url.message)) {
				openErrorSnack(t(url.message[0].message))
				return
			}
		}

		try {
			const partnerId = await GetCurrentPartner().then((res) => {
				return res.id
			})

			const formattedData = {
				bankAccountId: id,
				partnerId: partnerId,
				value: parseFloat(data.value),
				description: data.description,
				attachments: [
					{
						attachmentName: file[0].name,
						attachmentLink: url.data,
					},
				],
			}

			const res = await PostBalance(formattedData)

			if (res.status === 200) {
				if (!_.isEmpty(res.data)) {
					if (!_.isEmpty(res.data.errors)) {
						const message = res.data.errors
						openErrorSnack(t(message[0].message))

						return
					}
					openSnack(t('BALANCE_INSERTED_SUCCESSFULLY'))
					setRefreshTable(!refreshTable)
					closeInsertBalance()
				}
			}


		} catch (error) {
			openErrorSnack('ERROR_INSERT_BALANCE')
			console.error(error)
		}
	}

	function handleCancel() {
		if (isDirty) {
			setOpenCancelModal(true)
		} else {
			closeInsertBalance()
			ClearForm()
		}
	}

	function handleConfirmModal() {
		closeInsertBalance()
		setOpenCancelModal(false)
		ClearForm()
	}

	const ClearForm = () => {
		reset(initialValues)
		setFile([])
		setFileName('')
		setCurrentFile(null)
		setInvalidFile(false)
		setNoVoucher(false)
	}


	return (
		<>
			<ConfirmModal
				type="cancel"
				open={openCancelModal}
				onConfirm={() => handleConfirmModal()}
				onCancel={() => setOpenCancelModal(false)}
			/>
			{isSubmitting && <Loader />}
			<Layout
				title={t('INSERT_BALANCE')}
				isOpen={isOpen}
				onClose={handleCancel}
			>
				<S.FormContain
					themeColor={
						themeColor &&
						themeColor.navigation &&
						themeColor.navigation.buttons &&
						themeColor.navigation.buttons.textColor
							? themeColor.navigation.buttons.textColor
							: `#444`
					}
					onSubmit={handleSubmit(onSubmit)}
				>
					<S.TitleSection>
						<Section
							title={t('INSERT_BALANCE')}
							style={{ flexGrow: 1 }}
						/>

						<S.BtnTitleSection>
							<S.BtnBack
								onClick={handleCancel}
								variant="contained"
								size="sm"
								color="greenBorderButtonColor"
								style={{ margin: 0 }}
							>
								{t('BUTTON_CANCEL')}
							</S.BtnBack>
							<S.BtnConfirm
								variant="contained"
								color="greenButtonColor"
								size="sm"
								isDisabled={isSubmitting}
								type="submit"
								style={{ margin: 0 }}
								disabled={isSubmitting || !isDirty}
							>
								{t('BUTTON_CONCLUDE')}
							</S.BtnConfirm>
						</S.BtnTitleSection>
					</S.TitleSection>

					<S.InputBox>
						<S.InputValue>
							<FormControl error={errors && errors.value} fullWidth>
								<Controller
									render={({ name, onChange, value, onBlur }) => (
										<CurrencyInput
											error={errors && errors.value}
											helperText={
												errors.value && errors.value.message
											}
											onValueChange={onChange}
											name={name}
											value={value}
											label={t('VALUE')}
											setValue={setValue}
										/>
									)}
									control={control}
									name="value"
									defaultValue=""
								/>

								{errors && errors.value && (
									<FormHelperText
										style={{ marginLeft: '14px' }}
									>
										Campo obrigatório
									</FormHelperText>
								)}
							</FormControl>
						</S.InputValue>

						<S.InputDescription>
							<Controller
								name="description"
								control={control}
								render={({ onChange, value }) => (
									<S.CssTextField
										label={t('FIELD_DESCRIPTION')}
										variant="outlined"
										margin="dense"
										fullWidth
										onChange={onChange}
										value={value}
									/>
								)}
							/>
						</S.InputDescription>

						<S.InputProof>
							<FormControl fullWidth>
								<S.CssTextField
									autoComplete="off"
									size="small"
									id="outlined-textFile"
									label={`${t('OPERATION_PROOF')}*`}
									accept=".pdf, .jpg, .jpeg, .png"
									variant="outlined"
									value={fileName}
									error={invalidFile || noVoucher}
									helperText={
										!invalidFile && !noVoucher
											? t('VALID_EXTENSIONS')
											: invalidFile
												? t('THE_IMPORTED_FILE_IS_INVALID')
												: noVoucher
													? t('INSERT_AT_LEAST_ONE_PROOF')
													: ''
									}
									className="inputProof"
								/>
								<input
									autoComplete="off"
									style={{ display: 'none' }}
									id="raised-file"
									name="raised-file"
									accept=".pdf, .jpg, .jpeg, .png"
									type="file"
									onChange={(e) => handleUploadFile(e)}
								/>
								{/* <FormHelperText>Disabled</FormHelperText> */}
							</FormControl>
							<S.BtnInputProof>
								<label htmlFor="raised-file">
									<Button
										style={{
											padding: '10px 0 10px 10px',
											margin: 0,
											alignSelf: 'start',
											backgroundColor: btnBGCol(),
											color:
												themeColor &&
												themeColor.navigation &&
												themeColor.navigation.buttons &&
												themeColor.navigation.buttons
													.textColor
													? themeColor.navigation
															.buttons.textColor
													: `#444`,
											height: '40px',
											width: '40px',
										}}
										variant="raised"
										component="span"
									>
										<S.IconButton className="icon-lupa" />
									</Button>
								</label>

								<Button
									style={{
										height: '40px',
										width: '100px',
										margin: 0,
									}}
									color={'greenButtonColor'}
									size="sm"
									type="button"
									onClick={handleNewFile}
									disabled={!currentFile || file.length > 0}
								>
									{t('BUTTON_ADD')}
								</Button>
							</S.BtnInputProof>
						</S.InputProof>
						<Grid item xs={12} md={12}>
							<S.FileContainer>
								{file &&
									file.map((item, key) => (
										<S.FileText>
											<S.CustomIcon
												className="icon-ico_saldo_incluido_visualizar"
											/>
											<S.MyButton>
												{item.name}
												<MuiIconButton
													style={{
														marginLeft: '7px',
														width: '1rem',
														height: '1rem',
													}}
													onClick={() =>
														handleDeleteItem(
															item,
															key,
														)
													}
												>
													<Close
														style={{
															width: '1rem',
															height: '1rem',
															color: '#fff',
														}}
													/>
												</MuiIconButton>
											</S.MyButton>
										</S.FileText>
									))}
							</S.FileContainer>
						</Grid>
					</S.InputBox>
				</S.FormContain>
			</Layout>
		</>
	)
}
