import styled from 'styled-components'
import TextField from '@mui/material/TextField';
import { getThemeColorFromLocalStorage, ColorLuminance } from '../../../../redux/api/themeColors';

export const Container = styled.div`
    margin-right: -15px !important;

    .title{
        margin-right:15px;
    }

    .GridContainerButtons{
		width: 100%;
		padding: 15px 0px 15px 15px;
		display: flex;
        margin-bottom: 5px;
		justify-content: end;
	}

    .GridContainer{
        
    }
    
    .gridDiv{
        margin-bottom: 20px
    }

    .newButtonsChange{
        height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		background-color: #f6f6f6;
		font-size: 24px;
		color: #989898;
    }

	.newButtons{
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
        background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
        font-size: 24px;
        display: flex;
        color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.textColor};;
        :hover{
            background-color:${ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5,)};
        }
	}

`

export const CssTextField = styled(TextField)({
    backgroundColor: '#ffffff',
    width: '100%',
    '& label.Mui-focused': {
        color: getThemeColorFromLocalStorage()?.headers?.backgroundColor,
    },
    '& label': {
        lineHeight: 1.2,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons
            ?.backgroundColor,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
                ?.backgroundColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
                ?.backgroundColor,
        },
    },
})
