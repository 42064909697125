import React from 'react'
import Home from 'pages/Home'
import _ from 'lodash'
import Profile from 'pages/User/Profile'
import PartnerProfileNew from 'pages/Partner/PartnerProfile'
import ChangePassword from 'pages/User/ChangePassword'
import Construction from 'pages/Construction'
import { Users, UsersForm } from 'pages/Settings'
import { Campaigns, CampaignForm, RedeemCampaignForm, CampaignPerformance } from 'pages/Campaign'
import CashbackParameterization from 'pages/Cashback/parameterization'
import CashbackTools from 'pages/Cashback/tools'
import ProductNew from '../ProductNew'
import ProductDetails from '../ProductNew/ProductDetails'
import ProductHistory from '../ProductNew/ProductDetails/History'
import HistoryDetails from '../ProductNew/ProductDetails/History/Details'
import GrouperComponent from '../ProductNew/Grouper'
import CategoryParametrization from '../ProductNew/Grouper/Details'

import { Groups, GroupForm } from '../Access/Groups/'
import Notification from '../Notification'
import Sponsor from '../Sponsor'
import SponsorRegister from '../Sponsor/SponsorRegister'

import Invoices from 'pages/Invoices'
import InvoiceDetails from 'pages/Invoices/Details/Details'

import NotFound from 'pages/404'
import Forbidden from 'pages/403'
import PDV from '../PDV'
import Award from '../Award'
import DataAward from '../Award/DataAward'
import Reversal from '../Award/Reversal'
import ReversalConfirm from '../Award/ReversalConfirm'
import CreateAward from '../Award/CreateAward'
import LogAward from '../Award/LogAward'

import { Accounts, AccountRegister, AccountDetails, AccountManage } from '../Accounts'
import AdditionalModules from '../AdditionalModules'
import NotificationCenter from '../NotificationCenter'
import PaymentOptions from '../PaymentOptions'
import { GetGrouper } from './Functions'
import ConfigureGrouper from '../ConfigureGrouper'
import { Negotiation } from '../Invoices/Negotiation'
import { NegotiationDetails } from '../Invoices/NegotiationDetails'
import { CreateNegotiation } from '../Invoices/CreateNegotiation'
import { IconNegotiation } from '../../components/storybook'
import ModuleDetails from '../AdditionalModules/ModuleDetails'

// import Build from "@material-ui/icons/Build";

export const dashRoutes = [
	{
		id: 'home',
		component: Home,
		icon: 'icon-ico_dashboard',
		layout: '/admin',
		name: 'MENU_HOME',
		path: '/home',
		role: 'home.get',
		sidebar: true,
	},
	{
		component: Notification,
		icon: 'icon-ico_aviso',
		layout: '/admin',
		name: 'MENU_NOTIFICATION',
		path: '/notifications',
		role: 'notifications.get',
		sidebar: false,
	},
	{
		id: 'sales',
		collapse: true,
		icon: 'icon-ico_vendas',
		layout: '/admin',
		name: 'MOVEMENT',
		path: '/sales',
		role: 'sales.get',
		sidebar: true,
		state: 'pageCollapseSettings',
		views: [
			{
				id: 'sales',
				component: InvoiceDetails,
				focus: '/sales',
				icon: '',
				layout: '/admin',
				name: 'MENU_SALES_ITEMS',
				path: '/sales/details/:id',
				role: 'sales.get',
				sidebar: false,
			},
			{
				id: 'sales',
				component: Invoices,
				icon: 'icon-ico_vendas',
				layout: '/admin',
				name: 'MENU_SALES',
				path: '/sales',
				role: 'sales.get',
				sidebar: true,
			},

			// {
			// 	id: 'sales',
			// 	component: Invoices,
			// 	icon: 'icon-ico_vendas',
			// 	layout: '/admin',
			// 	name: 'MENU_SALES',
			// 	path: '/sales',
			// 	role: 'sales.get',
			// 	sidebar: true,
			// },
		],
	},
	{
		id: 'report',
		component: Construction,
		icon: 'icon-ico_relatorios',
		layout: '/admin',
		name: 'MENU_REPORT',
		path: '/report',
		role: 'sales.get',
		sidebar: true,
	},
	// {
	// 	id: 'report',
	// 	component: Construction,
	// 	icon: 'icon-ico_relatorios',
	// 	layout: '/admin',
	// 	name: 'MENU_REPORT',
	// 	path: '/report',
	// 	role: 'sales.get',
	// 	sidebar: true,
	// },
	{
		id: 'financial',
		collapse: true,
		component: Construction,
		icon: 'icon-ico_financeiro',
		layout: '/admin',
		name: 'MENU_FINANCIAL',
		path: '/financial',
		role: 'financial.get',
		sidebar: true,
		state: 'pageCollapseSettings',
		views: [
			// {
			// 	id: 'financial',
			// 	component: Construction,
			// 	icon: 'icon-ico_transferencia_Pontos',
			// 	layout: '/admin',
			// 	name: 'MENU_TRANSACTIONS_POINTS',
			// 	path: '/financial/points',
			// 	role: 'financial.points.get',
			// 	sidebar: true,
			// },
			{
				id: 'financial',
				component: Award,
				icon: 'icon-menu_premiacao',
				layout: '/admin',
				name: 'MENU_AWARD',
				path: '/financial/award',
				role: 'financial.award.get',
				sidebar: true,
			},
			{
				id: 'financial',
				component: Negotiation,
				svgIcon: color => <IconNegotiation size={20} color={color ?? '#fff'} />,
				layout: '/admin',
				name: 'NEGOTIATION.NEGOTIATION',
				path: '/negotiation',
				pagePath: 'negotiation',
				role: 'financial.get',
				sidebar: true,
			},
			{
				id: 'financial',
				component: NegotiationDetails,
				icon: '',
				layout: '/admin',
				name: 'MENU_SALES',
				path: '/negotiationDetails/:id',
				role: 'sales.get',
				sidebar: false,
			},
			{
				id: 'financial',
				component: CreateNegotiation,
				icon: '',
				layout: '/admin',
				name: 'criar',
				path: '/createNegotiation',
				role: 'sales.get',
				sidebar: false,
			},
			{
				id: 'financial',
				component: PaymentOptions,
				icon: 'icon-ico_meios_pagamento',
				layout: '/admin',
				name: 'MENU_FINANCIAL_PAYMENT_OPTIONS',
				path: '/financial/paymentOptions',
				role: 'financial.paymentOptions.get',
				sidebar: true,
			},
			{
				id: 'financial',
				component: DataAward,
				icon: '',
				layout: '/admin',
				name: '',
				path: '/financial/dataAward/:id',
				role: 'financial.dataAward.get',
				sidebar: false,
			},
			{
				id: 'financial',
				component: Reversal,
				icon: '',
				layout: '/admin',
				name: '',
				path: '/financial/reversal/:id',
				role: 'financial.reversal.get',
				sidebar: false,
			},
			{
				id: 'financial',
				component: ReversalConfirm,
				icon: '',
				layout: '/admin',
				name: '',
				path: '/financial/reversalConfirm',
				role: 'financial.reversalConfirm.get',
				sidebar: false,
			},
			{
				id: 'financial',
				component: CreateAward,
				icon: '',
				layout: '/admin',
				name: '',
				path: '/financial/createAward',
				role: 'financial.createAward.get',
				sidebar: false,
			},
			{
				id: 'financial',
				component: LogAward,
				icon: '',
				layout: '/admin',
				name: '',
				path: '/financial/LogsWard/:id',
				role: 'financial.LogsWard.get',
				sidebar: false,
			},
			{
				id: 'financial',
				focus: '/financial',
				component: AccountManage,
				icon: '',
				layout: '/admin',
				name: 'MENU_ACCOUNTS',
				path: '/financial/accounts/manage/:id',
				role: 'accounts.create',
				sidebar: false,
			},
			{
				id: 'financial',
				focus: '/financial',
				component: AccountDetails,
				icon: '',
				layout: '/admin',
				name: 'MENU_ACCOUNTS',
				path: '/financial/accounts/details/:id',
				role: 'accounts.get',
				sidebar: false,
			},
			{
				id: 'financial',
				focus: '/financial',
				component: AccountRegister,
				icon: '',
				layout: '/admin',
				name: 'MENU_ACCOUNTS',
				path: '/financial/accounts/new',
				role: 'accounts.create',
				sidebar: false,
			},
			{
				id: 'financial',
				component: Accounts,
				icon: 'icon-conta_premiacao',
				layout: '/admin',
				name: 'MENU_ACCOUNTS',
				path: '/financial/accounts',
				role: 'accounts.get',
				sidebar: true,
			},
		],
	},
	// {
	// 	id: 'accounts',
	// 	focus: '/accounts',
	// 	component: AccountManage,
	// 	icon: '',
	// 	layout: '/admin',
	// 	name: 'MENU_ACCOUNTS',
	// 	path: '/accounts/manage/:id',
	// 	role: 'accounts.create',
	// 	sidebar: false,
	// },
	// {
	// 	id: 'accounts',
	// 	focus: '/accounts',
	// 	component: AccountDetails,
	// 	icon: '',
	// 	layout: '/admin',
	// 	name: 'MENU_ACCOUNTS',
	// 	path: '/accounts/details/:id',
	// 	role: 'accounts.get',
	// 	sidebar: false,
	// },
	// {
	// 	id: 'accounts',
	// 	focus: '/accounts',
	// 	component: AccountRegister,
	// 	icon: '',
	// 	layout: '/admin',
	// 	name: 'MENU_ACCOUNTS',
	// 	path: '/accounts/new',
	// 	role: 'accounts.create',
	// 	sidebar: false,
	// },
	// {
	// 	id: 'accounts',
	// 	component: Accounts,
	// 	icon: 'icon-conta_premiacao',
	// 	layout: '/admin',
	// 	name: 'MENU_ACCOUNTS',
	// 	path: '/accounts',
	// 	role: 'accounts.get',
	// 	sidebar: true,
	// },
	{
		component: CampaignPerformance,
		icon: '',
		layout: '/admin',
		name: 'MENU_CAMPAIGN',
		path: '/campaigns/performance/:id',
		role: 'campaigns.performance.get',
		sidebar: false,
	},
	{
		component: CampaignPerformance,
		icon: '',
		layout: '/admin',
		name: 'MENU_CAMPAIGN',
		path: '/campaign/performance/:id',
		role: 'campaigns.performance.get',
		sidebar: false,
	},

	{
		id: 'campaigns',
		collapse: true,
		component: Campaigns,
		icon: 'icon-ico_campanha',
		layout: '/admin',
		name: 'MENU_CAMPAIGN',
		path: '/campaigns',
		role: 'campaigns.get',
		sidebar: true,
		state: 'pageCollapseSettings',
		views: [
			{
				id: 'campaigns',
				component: Campaigns,
				icon: 'icon-gerar_pontos',
				layout: '/admin',
				name: 'MENU_STANDARD_CAMPAIGN',
				path: '/campaigns/generation',
				role: 'campaigns.get',
				sidebar: true,
			},
			{
				id: 'campaigns',
				component: Campaigns,
				icon: 'icon-resgatar_pontos',
				layout: '/admin',
				name: 'MENU_CAMPAIGN_REDEEM',
				path: '/campaign/redeem',
				role: 'campaigns.get',
				sidebar: true,
			},
			{
				id: 'campaigns',
				component: Sponsor,
				icon: 'icon-ico_crud_parceiros',
				layout: '/admin',
				name: 'MENU_SPONSOR',
				path: '/sponsors',
				role: 'campaigns.get',
				sidebar: true,
			},
			{
				id: 'campaigns',
				component: SponsorRegister,
				icon: 'icon-ico_crud_parceiros',
				layout: '/admin',
				name: 'MENU_SPONSOR',
				path: '/sponsors/new',
				role: 'campaigns.get',
				sidebar: false,
			},
			{
				id: 'campaigns',
				component: CampaignForm,
				focus: '/campaigns/generation',
				icon: '',
				layout: '/admin',
				name: 'MENU_CAMPAIGN',
				path: '/campaigns/view/:id',
				role: 'campaigns.view.get',
				sidebar: false,
			},
			{
				id: 'campaigns',
				component: CampaignForm,
				focus: '/campaigns/generation',
				icon: '',
				layout: '/admin',
				name: 'MENU_CAMPAIGN',
				path: '/campaigns/edit/:id',
				role: 'campaigns.edit',
				sidebar: false,
			},
			{
				id: 'campaigns',
				component: CampaignForm,
				focus: '/campaigns/generation',
				icon: '',
				layout: '/admin',
				name: 'MENU_CAMPAIGN',
				path: '/campaigns/new',
				role: 'campaigns.create',
				sidebar: false,
			},
			{
				id: 'campaigns',
				component: RedeemCampaignForm,
				focus: '/campaign/redeem',
				icon: '',
				layout: '/admin',
				name: 'MENU_CAMPAIGN',
				path: '/campaign/viewRedeemCampaign/:id',
				role: 'campaigns.edit',
				sidebar: false,
			},
			{
				id: 'campaigns',
				component: RedeemCampaignForm,
				focus: '/campaign/redeem',
				icon: '',
				layout: '/admin',
				name: 'MENU_CAMPAIGN',
				path: '/campaign/editRedeemCampaign/:id',
				role: 'campaigns.edit',
				sidebar: false,
			},
			{
				id: 'campaigns',
				component: RedeemCampaignForm,
				focus: '/campaign/redeem',
				icon: '',
				layout: '/admin',
				name: 'MENU_CAMPAIGN',
				path: '/campaign/newRedeemCampaign',
				role: 'campaigns.create',
				sidebar: false,
			},
		],
	},

	{
		id: 'cashback',
		collapse: true,
		component: CashbackParameterization,
		icon: 'icon-menu_cashback',
		layout: '/admin',
		name: 'MENU_CASHBACK',
		path: '/cashback',
		role: 'campaigns.get',
		sidebar: true,
		state: 'pageCollapseSettings',
		views: [
			{
				id: 'cashback',
				component: CashbackParameterization,
				icon: 'icon-menu_cashback_parametrizacao',
				layout: '/admin',
				name: 'MENU_PARAMETERIZATION',
				path: '/cashback/parameterization',
				role: 'campaigns.get',
				sidebar: true,
			},
			{
				id: 'cashback',
				component: CashbackTools,
				icon: 'icon-menu_cashback_ferramentas',
				layout: '/admin',
				name: 'MENU_TOOLS',
				path: '/cashback/tools',
				role: 'campaigns.get',
				sidebar: false,
			},
		],
	},
	{
		id: 'product',
		component: ProductDetails,
		focus: '/product',
		icon: '',
		layout: '/admin',
		name: 'MENU_PRODUCT_DETAIL',
		path: '/product/parametrization',
		role: 'product.get',
		sidebar: false,
	},
	{
		id: 'product',
		component: ProductHistory,
		focus: '/product',
		icon: '',
		layout: '/admin',
		name: 'MENU_PRODUCT_DETAIL',
		path: '/product/history',
		role: 'product.get',
		sidebar: false,
	},
	{
		id: 'HistoryDetails',
		component: HistoryDetails,
		focus: '/HistoryDetails',
		icon: '',
		layout: '/admin',
		name: 'MENU_PRODUCT_DETAIL',
		path: '/product/historyDetails',
		role: 'HistoryDetails.get',
		sidebar: false,
	},
	{
		id: 'product',
		component: CategoryParametrization,
		focus: '/category',
		icon: '',
		layout: '/admin',
		name: 'MENU_PRODUCT_DETAIL',
		path: '/category/parametrization/:id',
		role: 'product.get',
		sidebar: false,
	},
	{
		id: 'product',
		collapse: true,
		icon: 'icon-ico_produtos',
		layout: '/admin',
		name: 'MENU_CATALOG',
		path: '/product',
		role: 'product.get',
		sidebar: true,
		state: 'pageCollapseSettings',
		views: [
			{
				id: 'product',
				component: ProductNew,
				icon: 'icon-menu_produtos',
				layout: '/admin',
				name: 'MENU_PRODUCT',
				path: '/product',
				role: 'product.get',
				sidebar: true,
			},
			{
				id: 'product',
				component: ConfigureGrouper,
				icon: 'icon-menu_produtos',
				layout: '/admin',
				name: 'MENU_CONFIGURE_GROUPER',
				path: '/configureGrouper',
				role: 'product.get',
				sidebar: true,
			},
			{
				id: 'product',
				component: GrouperComponent,
				icon: 'icon-menu_produtos',
				layout: '/admin',
				name: '',
				path: '/category',
				role: 'Category.get',
				sidebar: false,
				identifier: 'category',
			},
		],
	},
	{
		id: 'moduleDetails',
		component: ModuleDetails,
		icon: 'icon-menu_modulos_adicionais',
		layout: '/admin',
		name: 'MENU_ADDITIONAL_MODULES',
		path: '/additionalModules/ModuleDetails/:id',
		role: 'additionalModules.get',
		sidebar: false,
	},
	{
		id: 'additional',
		component: AdditionalModules,
		icon: 'icon-menu_modulos_adicionais',
		layout: '/admin',
		name: 'MENU_ADDITIONAL_MODULES',
		path: '/additionalModules',
		role: 'additionalModules.get',
		sidebar: true,
	},
	{
		id: 'access',
		collapse: true,
		name: 'MENU_ACCESS_CONTROL',
		roles: [
			'partnerusers.edit',
			'partnerusers.create',
			'partnerusers.get',
			'partnerusers.get',
			'groups.create',
			'groups.edit',
			'groups.get',
			'groups.get',
		],
		sidebar: true,
		icon: 'icon-acesso',
		state: 'pageCollapseSettings',
		views: [
			{
				id: 'access',
				component: UsersForm,
				focus: '/partnerusers',
				icon: '',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_PARTNER_USERS',
				path: '/partnerusers/edit/:id',
				role: 'partnerusers.edit',
				sidebar: false,
			},
			{
				id: 'access',
				component: UsersForm,
				focus: '/partnerusers',
				icon: '',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_PARTNER_USERS',
				path: '/partnerusers/new',
				role: 'partnerusers.create',
				sidebar: false,
			},
			{
				id: 'access',
				component: UsersForm,
				focus: '/partnerusers',
				icon: '',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_PARTNER_USERS',
				path: '/partnerusers/:id',
				role: 'partnerusers.get',
				sidebar: false,
			},
			{
				id: 'access',
				component: PDV,
				icon: 'icon-menu_PDV',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_PDV',
				path: '/pdv',
				role: 'pdv.get',
				sidebar: true,
				exact: true,
				requiresDigitalWallet: true,
			},
			{
				id: 'access',
				component: Construction,
				icon: 'icon-menu_PDV',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_PDV',
				path: '/crud/pdv',
				role: 'pdv.get',
				sidebar: false,
				requiresDigitalWallet: true,
			},
			{
				id: 'access',
				component: Construction,
				icon: 'icon-menu_PDV',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_PDV',
				path: '/crud/pdv/:id',
				role: 'pdv.get',
				sidebar: false,
				requiresDigitalWallet: true,
			},
			{
				id: 'access',
				component: Users,
				icon: 'icon-sub_usuarios',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_PARTNER_USERS',
				path: '/partnerusers',
				role: 'partnerusers.get',
				sidebar: true,
			},
			// UsersForm
			{
				id: 'access',
				component: GroupForm,
				focus: '/groups',
				icon: '',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_GROUPS',
				path: '/groups/new',
				role: 'groups.create',
				sidebar: false,
			},
			{
				id: 'access',
				component: GroupForm,
				focus: '/groups',
				icon: '',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_GROUPS',
				path: '/groups/edit/:id',
				role: 'groups.edit',
				sidebar: false,
			},
			{
				id: 'access',
				component: GroupForm,
				focus: '/groups',
				icon: '',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_GROUPS',
				path: '/groups/:id',
				role: 'groups.get',
				sidebar: false,
			},
			{
				id: 'access',
				component: Groups,
				icon: 'icon-sub_grupos',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_GROUPS',
				path: '/groups',
				role: 'groups.get',
				sidebar: true,
			},
			{
				id: 'access',
				component: NotificationCenter,
				icon: 'icon-ico_notificacoes',
				iconSmall: true,
				layout: '/admin',
				name: 'MENU_MANAGE_NOTIFICATION_CENTER',
				path: '/notificationCenter',
				role: 'notificationCenter.get',
				sidebar: true,
			},
		],
	},
	{
		component: Profile,
		icon: '',
		layout: '/admin',
		name: 'MENU_PROFILE',
		path: '/profile',
		role: 'profile.get',
		sidebar: false,
	},
	{
		component: PartnerProfileNew,
		icon: '',
		layout: '/admin',
		name: 'MENU_PROFILE',
		path: '/partnerProfile',
		role: 'partnerProfile.edit',
		sidebar: false,
	},
	{
		component: ChangePassword,
		icon: '',
		layout: '/admin',
		name: 'MENU_USER_EDIT_PASSWORD',
		path: '/changepassword',
		role: 'changepassword.edit',
		sidebar: false,
	},
	{
		component: Forbidden,
		icon: 'portrait',
		layout: '/admin',
		name: 'MENU_FORBIDDEN',
		path: '/403',
		role: '403.get',
		sidebar: false,
	},
	{
		component: NotFound,
		icon: 'portrait',
		layout: '/admin',
		name: 'MENU_NOT_FOUND',
		path: '/404',
		role: '404.get',
		sidebar: false,
	},
]
