import React from "react";
import Slider from "react-slick";

import { Container, PrevArrow, NextArrow } from "./styles";

import style from "../../home.module.scss";

import { MetricsBannersCount } from "../../../../redux/api/banners";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function SimpleSlider({ bannerInfo, bannersDisplayTime }) {
	async function handleChangeSlide(currentSlide) {
		const view = true;
		const click = false;

		const response = await MetricsBannersCount(
			bannerInfo[currentSlide][2],
			view,
			click
		);
	}

	function handleLink(link, id) {
		const view = false;
		const click = true;

		if (link) {
			MetricsBannersCount(id, view, click);

			var url = link;
			if (url.indexOf("http://") == 0 || url.indexOf("https://") == 0) {
				window.open(link, "_blank");
			} else {
				window.open(`https://${link}`, "_blank");
			}
		}
	}

	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: bannersDisplayTime * 1000,
		fade: true,
		pauseOnHover: false,
		cssEase: "linear",
		prevArrow: <PrevArrow />,
		nextArrow: <NextArrow />,
		afterChange: (currentSlide) => handleChangeSlide(currentSlide),
	};

	return (
		<Container>
			<Slider {...settings}>
				{bannerInfo.map((item, index) => {
					return (
						<div key={index}>
							<div onClick={() => handleLink(item[1], item[2])}>
								<img
									src={item[0]}
									alt="img"
									className={style.maxImage}
								/>
							</div>
						</div>
					);
				})}
			</Slider>
		</Container>
	);
}
