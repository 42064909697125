import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import CustomModal from '../../../Modal'
import { UpdateProduct } from 'redux/api/product'
import SnackDefault from 'components/SnackDefault'
import Cookies from 'js-cookie'
import { UpdateGrouperParametrization } from '../../../../../../../../../redux/api/Grouper'

const NoDivisionTab = ({ setEditMode, editMode, productData, typeOfBenefitCashback, grouper }) => {
	const { t } = useTranslation()
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const ParameterizationProduct = async (productData, typeOfBenefitCashback) => {
		try {
			const partnerId = Cookies.get('partnerId') || ''
			const {
				generatePoints,
				typeOfPoints,
				pointsValue = 0,
				typeOfBenefitRedeem,
				typeOfRedeem,
				redemptionPoints,
				typeOfCashback,
				cashbackPercentual,
			} = productData || {}

			const obj = {
				partnerId,
				typeOfGrouper: grouper || '',
				generatePoints,
				typeOfPoints: typeOfPoints?.key ?? '',
				pointsValue,
				typeOfBenefitRedeem: typeOfBenefitRedeem?.key ?? '',
				typeOfRedeem: typeOfRedeem?.key ?? '',
				redemptionPoints: redemptionPoints?.key ?? '',
				typeOfBenefitCashback: typeOfBenefitCashback?.key ?? '',
				typeOfCashback: typeOfCashback?.key ?? '',
				cashbackPercentual,
			}

			const res = await UpdateGrouperParametrization({ id: productData?.id, obj })

			if (res?.data?.errors?.length > 0) {
				setSnackStatus({
					open: true,
					text: t(res?.data?.errors[0]?.message),
					severity: 'error',
				})
				return
			}

			if (res?.data?.success) {
				setSnackStatus({
					open: true,
					text: 'Operação realizada com sucesso!',
					severity: 'success',
				})
				setEditMode(false)
				return
			}
		} catch (error) {
			console.error(error)
			setSnackStatus({
				open: true,
				text: 'Erro ao realizar a operação!',
				severity: 'error',
			})
		}
	}

	return (
		<>
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					ParameterizationProduct(productData, typeOfBenefitCashback)
					setModalParameterization({ open: false })
				}}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<S.Container>
				{editMode === true ? (
					<S.Content>
						<Buttons className="cancel" Icon="icon-bot_fechar" onClick={() => setEditMode(false)} />
						<Buttons
							className="save"
							onClick={() =>
								setModalParameterization({
									open: true,
									title: 'Deseja realmente alterar a parametrização',
									subtitle: 'desse benefício?',
								})
							}
							Icon="icon-bot_salvar"
						/>
					</S.Content>
				) : (
					<S.Content>
						<Buttons className="softBlueAction" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
					</S.Content>
				)}
			</S.Container>
		</>
	)
}

export default NoDivisionTab
