import styled from 'styled-components'
import { TextField } from '@material-ui/core'

export const InputText = styled(TextField)`
	p {
		text-align: left !important;
	}

	.MuiFormHelperText-contained {
		padding-top: 0.2px;
		margin-top: 0.2px;
		font-size: 0.75rem;
	}
`
