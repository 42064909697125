import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

import { FormLabel, Grid, InputAdornment } from '@mui/material'
import { Controller } from 'react-hook-form'
//@ components
import { CssTextField } from '../../../../../../Filter/styles'
import GenerationPoints from '../../../../../../generalTab/components/Configuration/GenerationPoints'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'
import MonetaryInput from 'components/MonetaryInput'
import { FormatMoney, Symbol, newValue } from '../../Functions'

const Configuration = ({
	typeOfCashback,
	customCashbackValue,
	productData,
	parameterizationGenerationPoints,
	fetchCalculation,
	control,
	viewMode,
	errors,
	cashbackPercentual,
}) => {
	const { t } = useTranslation()

	const adornmentPosition = typeOfCashback === '4' ? 'start' : 'end'

	const formatError = error => {
		if (!_?.isEmpty(error)) {
			const message = error?.replace('0', FormatMoney(0))
			return message
		}
		return error
	}

	return (
		<S.CardsContent>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Valor*')}</span>
						<Controller
							name="cashbackPercentual"
							control={control}
							render={({ value, onChange, name }) =>
								typeOfCashback === '4' ? (
									<MonetaryInput
										fullWidth
										max={1000000}
										name={name}
										value={value || cashbackPercentual || 0}
										onChange={e => {
											fetchCalculation(e)
											onChange(e)
										}}
										disabled={viewMode}
										onValueChange={() => console.log('')}
										error={errors?.cashbackPercentual}
										helperText={formatError(errors?.cashbackPercentual?.message)}
									/>
								) : (
									<CssTextField
										variant="outlined"
										size="small"
										value={newValue(value, 100)}
										disabled={viewMode}
										onChange={e => {
											fetchCalculation(e.target.value)
											onChange(e.target.value)
										}}
										error={errors?.cashbackPercentual}
										helperText={errors?.cashbackPercentual?.message}
										inputProps={{
											max_val: 100,
											maxLength: 5,
										}}
										InputProps={{
											[adornmentPosition + 'Adornment']: (
												<InputAdornment position={adornmentPosition}>
													<S.CustomSymbol>{Symbol(typeOfCashback)}</S.CustomSymbol>
												</InputAdornment>
											),
											inputComponent: DoubleFormatCustom,
										}}
									/>
								)
							}
						/>
						<S.CashbackContent>
							<S.CashbackCardIcon className="icon-i_atencao" />
							<S.CashbackCardText>Valor informado não pode ser maior do que a parametrização automática.</S.CashbackCardText>
						</S.CashbackContent>
					</FormLabel>
				</Grid>
				<Grid item xs={12} sm={9}>
					<GenerationPoints
						customCashbackValue={customCashbackValue}
						parameterization={parameterizationGenerationPoints}
						typeOfCashback={typeOfCashback}
						productData={productData}
					/>
				</Grid>
			</Grid>
		</S.CardsContent>
	)
}

Configuration.propTypes = {
	typeOfCashback: PropTypes.string,
	productData: PropTypes.object,
	parameterizationGenerationPoints: PropTypes.array,
	fetchCalculation: PropTypes.func,
	customCashbackValue: PropTypes.number,
	cashbackValue: PropTypes.number,
	viewMode: PropTypes.bool,
	control: PropTypes.object,
	errors: PropTypes.object,
	setValue: PropTypes.func,
	cashbackPercentual: PropTypes.func,
}

Configuration.defaultProps = {
	typeOfCashback: '',
}

export default Configuration
