/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import Cards from './components/cards'
import Configuration from './components/Configuration'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, CircularProgress, FormLabel, Grid } from '@mui/material'
import { TypeOfCashback, ParameterizationProduct, handleCalculateValue, handleProductDataUpdate, Schema } from './Functions'
import { CssTextField } from '../Filter/styles'
import { DefaultModal, DefaultProductData } from './Functions/utils'
import CustomModal from '../../../Modal'
import SnackDefault from 'components/SnackDefault'
import { yupResolver } from '@hookform/resolvers'
import Loader from 'components/Loader/Loader.jsx'

const GeneralTab = ({ productData, setEditMode, editMode, typeOfBenefitCashback, rules, grouper, setModalCancel }) => {
	const { t } = useTranslation()
	const { control, watch, reset, errors, formState, trigger, setValue } = useForm({
		defaultValues: {
			typeOfCashback: {
				key: '0',
				value: 'Unknow',
			},
			cashbackPercentual: 0,
		},
		resolver: yupResolver(Schema),
		mode: 'all',
	})
	const { typeOfCashback, cashbackPercentual } = watch()

	const [loading, setLoading] = useState(false)
	const [GenerationType, setGenerationType] = useState([])
	const [customCashbackValue, setCustomCashbackValue] = useState('')
	const [modalParameterization, setModalParameterization] = useState(DefaultModal)
	const [circularProgress, setCircularProgress] = useState(false)
	const [modalTypeOfCashback, setModalTypeOfCashback] = useState({ ...DefaultModal, newValue: {} })
	const [defaultType, setDefaultType] = useState({
		typeOfCashback: typeOfCashback,
		cashbackPercentual: cashbackPercentual,
	})
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const fetchCalculation = async pointsValueWatch => {
		await handleCalculateValue(
			productData?.price || 0,
			typeOfCashback?.key,
			pointsValueWatch || cashbackPercentual || productData?.cashbackPercentual || 0,
			setCustomCashbackValue,
		)
	}

	const fetchGenerationType = () => {
		TypeOfCashback({ setGenerationType, setCircularProgress })
	}

	useEffect(() => fetchGenerationType(), [])

	useEffect(() => {
		handleProductDataUpdate(productData, reset, setDefaultType)
	}, [productData])

	useEffect(() => {
		typeOfCashback && fetchCalculation(cashbackPercentual)
	}, [typeOfCashback])

	const onConfirmTypeOfCashback = newValue => {
		setValue('typeOfCashback', newValue)
		setValue('cashbackPercentual', '')
		setModalTypeOfCashback(DefaultModal)
	}

	return (
		<Fragment>
			{loading && <Loader />}
			<CustomModal
				onClose={() => setModalParameterization(DefaultModal)}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					ParameterizationProduct({ grouper, productData, typeOfCashback, cashbackPercentual, setSnackStatus, typeOfBenefitCashback, setLoading })
					setEditMode(false)
					setModalParameterization(DefaultModal)
				}}
			/>

			<CustomModal
				onClose={() => {
					setValue('typeOfCashback', defaultType?.typeOfCashback)
					setModalTypeOfCashback(DefaultModal)
				}}
				open={modalTypeOfCashback?.open}
				title={modalTypeOfCashback?.title}
				subtitle={modalTypeOfCashback?.subtitle}
				onConfirm={() => onConfirmTypeOfCashback(modalTypeOfCashback?.newValue)}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<S.Container>
				{editMode === false ? (
					<S.Content>
						<Buttons className="softBlueAction" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
					</S.Content>
				) : (
					<S.Content>
						<Buttons
							className="cancel"
							Icon="icon-bot_fechar"
							onClick={() =>
								setModalCancel({
									open: true,
									title: 'As alterações realizadas serão perdidas.',
									subtitle: 'Deseja realmente continuar?',
								})
							}
						/>
						<Buttons
							className="save"
							Icon="icon-bot_salvar"
							disabled={!formState.isValid}
							onClick={() => {
								trigger().then(isValid => {
									if (isValid) {
										setModalParameterization({
											open: true,
											title: 'Deseja realmente alterar a parametrização',
											subtitle: 'desse benefício?',
										})
									}
								})
							}}
						/>
					</S.Content>
				)}
			</S.Container>
			{rules?.cashbackRules?.maximumPercentualProduct > 0 && (
				<S.CashbackCard>
					<S.CashbackCardIcon className="icon-i_atencao" />
					<S.CashbackCardText>Percentual padrão de cashback permitido:</S.CashbackCardText>
					<S.CashbackCardValue>{`${rules?.cashbackRules?.maximumPercentualProduct} %`}</S.CashbackCardValue>
				</S.CashbackCard>
			)}

			<S.CardsContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={3}>
						<FormLabel>
							<span className="FormLabel">{t('Configuração do benefício*')}</span>
							<Controller
								name="typeOfCashback"
								control={control}
								render={({ value, onChange }) => (
									<Autocomplete
										options={GenerationType}
										getOptionLabel={option => (option.value ? t(option.value) : '')}
										disabled={!editMode || circularProgress}
										value={value}
										fullWidth
										onChange={(_event, newValue) => {
											if (
												defaultType?.typeOfCashback?.key !== newValue?.key &&
												!_.isEmpty(defaultType?.typeOfCashback) &&
												defaultType?.typeOfCashback?.key === '2'
											) {
												setModalTypeOfCashback({
													open: true,
													title: `Sua parametrização de cashback está definida como automática.`,
													subtitle: `Ao editar este produto ele passará a ser ${t(newValue?.value)}.`,
													newValue: newValue,
												})
												return
											}
											setValue('cashbackPercentual', 0)
											onChange(newValue)
										}}
										renderInput={params => (
											<CssTextField
												{...params}
												variant="outlined"
												size="small"
												disabled={!editMode}
												error={errors?.typeOfCashback ? true : false}
												helperText={errors?.typeOfCashback?.message}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{circularProgress && <CircularProgress color="inherit" size={20} />}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
											/>
										)}
									/>
								)}
							/>
						</FormLabel>
					</Grid>
					{/* <Grid item xs={12} sm={9}>
						{typeOfCashback?.key > '1' && <Cards customCashbackValue={customCashbackValue} typeOfCashback={typeOfCashback?.key} />}
					</Grid> */}
				</Grid>
				<Grid item xs={12} sm={12}>
					{typeOfCashback?.key >= '3' && (
						<Configuration
							editMode={editMode}
							fetchCalculation={fetchCalculation}
							control={control}
							errors={errors}
							rules={rules}
							setValue={setValue}
							cashbackPercentual={cashbackPercentual}
							customCashbackValue={customCashbackValue}
							typeOfCashback={typeOfCashback?.key}
							productData={productData}
						/>
					)}
				</Grid>
			</S.CardsContent>
		</Fragment>
	)
}

GeneralTab.propTypes = {
	grouper: PropTypes.number,
	rules: PropTypes.object,
	productData: PropTypes.object,
	setEditMode: PropTypes.func,
	editMode: PropTypes.bool,
	setModalCancel: PropTypes.func,
	typeOfBenefitCashback: PropTypes.string,
}

GeneralTab.defaultProps = {
	productData: DefaultProductData,
}

export default GeneralTab
