import React from 'react'
import PropTypes from 'prop-types'

// @Components
import * as Tab from 'components/NewTab/styles'

function Tabs({ tabIndex, setTabIndex, tableRef, form, t }) {
	const { reset } = form

	const tabChange = index => {
		reset()
		setTabIndex(index)
		tableRef.current && tableRef.current.onQueryChange()
	}

	return (
		<Tab.NewTabs defaultIndex={1} selectedIndex={tabIndex} onSelect={index => tabChange(index)}>
			<Tab.NewTabList>
				<Tab.NewTab>{t('FIELD_CATEGORY')}</Tab.NewTab>
				<Tab.NewTab>{t('FAMILY')}</Tab.NewTab>
				<Tab.NewTab>{t('MANUFACTURE')}</Tab.NewTab>
				<Tab.NewTab>{t('FIELD_BRAND')}</Tab.NewTab>
			</Tab.NewTabList>
		</Tab.NewTabs>
	)
}

Tabs.propTypes = {
	t: PropTypes.func,
	tabIndex: PropTypes.number,
	setTabIndex: PropTypes.func,
	tableRef: PropTypes.object,
	form: PropTypes.object,
}

export default Tabs
