import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    consumerData: false,
    consumerPost: false,
    consumerUpdate: false,
    lsConsumerData: [],
    consumerDataFailed: false
};

const fetchConsumerData = (state, action) => {
    return updateObject(state, {
        lsConsumerData: action.lsConsumerData
    });
};

const postConsumerData = (state, action) => {
    return updateObject(state, {
        consumerPost: action.consumerPost
    });
};

const updateConsumereData = (state, action) => {
    return updateObject(state, {
        consumerUpdate: action.consumerUpdate
    });
};

const fetchConsumerById = (state, action) => {
    return updateObject(state, {
        consumerData: action.consumerData
    });
};

// DATA FAIL
const fetchConsumerFail = (state, action) => {
    return updateObject(state, {
        consumerDataFailed: true
    });
};

//
const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CONSUMER_DATA:
            return fetchConsumerData(state, action);
        case actionTypes.POST_CONSUMER_DATA:
            return postConsumerData(state, action);
        case actionTypes.UPDATE_CONSUMER_DATA:
            return updateConsumereData(state, action);
        case actionTypes.FETCH_CONSUMER_DATA_BY_ID:
            return fetchConsumerById(state, action);

        case actionTypes.FETCH_CONSUMER_FAILED:
            return fetchConsumerFail(state, action);

        default:
            return state;
    }
};

export default dashboardReducer;