import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import GriTablow from './GriTablow.jsx'

import { Grid, Divider, Tooltip } from '@material-ui/core'

import { Search, Settings } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'
import { useTranslation } from 'react-i18next'

import Button from 'components/CustomButtons/Button.jsx'

const ColorLuminance = (hex, lum) => {
	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '')
	if (hex.length < 6) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
	}
	lum = lum || 0
	// convert to decimal and change luminosity
	var rgb = '#',
		c,
		i
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i * 2, 2), 16)
		c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
		rgb += ('00' + c).substr(c.length)
	}
	return rgb
}

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#ececec',
		borderRadius: '4px',
		margin: '3px 0',
		'&:nth-child(even)': {
			backgroundColor: '#F4F4F4',
		},
	},
	button: {
		height: '100%',
		backgroundColor:
			getThemeColorFromLocalStorage() &&
			getThemeColorFromLocalStorage()?.navigation &&
			getThemeColorFromLocalStorage()?.navigation?.buttons &&
			getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		textAlign: 'center',
		display: 'flex',
		cursor: 'pointer',
		width: '49%',
		'&:hover': {
			backgroundColor: ColorLuminance(
				getThemeColorFromLocalStorage() &&
				getThemeColorFromLocalStorage()?.navigation &&
				getThemeColorFromLocalStorage()?.navigation?.buttons &&
				getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
				-0.5,
			),
		},
	},
	buttonRadius: {
		borderTopRightRadius: '4px',
		borderBottomRightRadius: '4px',
	},
}))

const GriTable = props => {
	const classes = useStyles()
	const { labels, values, history, id, noButton } = props
	const { t } = useTranslation()
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const getColor = () => {
		return (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor &&
			themeColor.navigation.buttons.textColor
		)
	}

	return (
		<Grid container spacing={0} className={classes.root}>
			<Grid container item xs={12} sm={12} md={11} spacing={0}>
				<Grid container spacing={0} style={{ padding: '5px 0' }}>
					<Grid container item xs={12} spacing={0}>
						<GriTablow labels={[labels[0], labels[1], labels[2]]} values={[values[0], values[1], values[2]]} />
					</Grid>
					{labels.length > 3 && (
						<>
							<Divider style={{ width: '96%', margin: '0 8px' }} />
							<Grid container item xs={12} spacing={0}>
								<GriTablow labels={[labels[3], labels[4], labels[5]]} values={[values[3], values[4], values[5]]} />
							</Grid>
						</>
					)}
					{labels.length > 6 && (
						<>
							<Divider style={{ width: '96%', margin: '0 8px' }} />
							<Grid container item xs={12} spacing={0}>
								<GriTablow labels={[labels[6], labels[7], labels[8]]} values={[values[6], values[7], values[8]]} />
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			{!noButton && (
				<Grid container item xs={12} sm={12} md={1} spacing={0}>
					<div style={{ display: 'flex', width: '100%' }}>
						<div style={{ width: '50%' }}>
							<Tooltip arrow title={t('BUTTON_MANAGE')} placement="left" style={{ display: 'flex' }}>
								<Button
									style={{
										margin: '0px',
										padding: 'revert',
										height: '100%',
										width: '100%',
									}}
									variant="contained"
									color="greenButtonColor"
									onClick={() => history.push(`/groups/edit/${id}`)}
								>
									<Settings style={{ margin: 'auto', color: `${getColor()}` }} />
								</Button>
							</Tooltip>
						</div>
						<div style={{ width: '50%' }}>
							<Tooltip arrow title={t('BUTTON_VIEW')} placement="left">
								<Button
									style={{
										margin: '0px 0 0 1px',
										padding: 'revert',
										height: '100%',
										width: '100%',
									}}
									variant="contained"
									color="greenButtonColor"
									onClick={() => history.push(`/groups/${id}`)}
								>
									<Search style={{ margin: 'auto', color: `${getColor()}` }} />
								</Button>
							</Tooltip>
						</div>
					</div>
				</Grid>
			)}
		</Grid>
	)
}

export default GriTable
