import React, { useState, useEffect, useContext } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import { HideLoader } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from 'components/CustomButtons/Button.jsx'
import { withStyles, FormControlLabel, Checkbox, TablePagination, MenuItem, Grid, Tooltip } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers'
import TextField from '@material-ui/core/TextField'
import MaterialTable from 'material-table'
import { Delete } from '@material-ui/icons'
import ConfirmModal from 'components/ConfirmModal'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader'
import Section from 'components/Section'

import PdvContext from '../context'
import { ShowLoader } from 'redux/actions'
import axios from 'axios'

import * as Styled from '../styles'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import NoResults from 'components/NoResults/NoResults.jsx'
import { GetActivePaymentMethod, GetReportFrequency, GetPaymentMethods, GetPdv } from '../../../redux/api/pdv'
import { GetCreditOriginsByPartner } from '../../../redux/api/partner'
import LinkGroup from './components/LinkGroup'
import SnackDefault from 'components/SnackDefault'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { getProgramTitle } from '../../../utils/utils'

export const validation = Yup.object().shape({
	name: Yup.string().required('Informe a identificação do PDV'),
	creditOrigins: Yup.array().required('Informe pelo menos uma definição de origens de crédito'),
	paymentMethodsControl: Yup.array().required('Informe a forma de recebimento dos valores'),
})

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			fontWeight: '400',
			color: 'hls(0, 0, 0, 0.55)',
			lineHeight: 1.2,
			zIndex: 1,
		},
		'& .MuiInputBase-root': {
			color: 'rgba(0, 0, 0, 0.6)',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)',
			//
			'&:not(.Mui-disabled)': {
				marginTop: '-7px',
				2: '-5px',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: '9.5px',
			paddingBottom: '10.5px',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			fontSize: '16px',
			top: '-9px',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			cursor: 'default',
		},
		'& fieldset': {
			color: 'rgba(0, 0, 0, 0.7)',
			borderColor: 'hsl(0,0%,80%)',
			'& .hover': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
			'& legend': {
				width: '0px',
			},
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'&.Mui-focused fieldset': {
					borderColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(3,3%,95%)',
			'& fieldset': {
				'& legend': {
					width: '0px',
				},
			},
		},
		'& .PrivateNotchedOutline-legendLabelled-583': {
			width: '0px !important',
		},
	},
})(TextField)

const initialPagination = {
	currentPage: 0,
	pageCount: 1,
	pageSize: 10,
	recordCount: 1,
}

const initialSelected = []

const periodNames = {
	DO_NOT_SHOW_REPORTS: 'Não apresentar relatórios',
	TWENTY_FOUR_HOURS: '24 horas',
	FORTY_EIGHT_HOURS: '48 horas',
}

function PdvRegister({ classes, partnerId }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const [editMode, setEditMode] = useState(false)
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])
	const { handleSubmit, control, reset, watch, errors, setValue, formState } = useForm({
		defaultValues: {
			name: '',
			paymentMethodsControl: [],
			reportFrequency: '1',
			users: [],
			creditOrigins: [],
		},
		resolver: yupResolver(validation),
		mode: 'onChange',
	})

	const watchAllFields = watch()

	const [confirmModalProps, setConfirmModalProps] = useState({
		open: false,
		type: '',
		text: '',
	})

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'error',
		text: '',
	})

	const { Pdv, setPdv, setEditing, editing, setSwitchComp } = useContext(PdvContext)
	const [newPdv, setNewPdv] = useState(false)
	const [selectedGroups, setSelectedGroups] = useState(initialSelected)
	const [pagination, setPagination] = useState(initialPagination)
	const [loading, setLoading] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState({})
	const [paymentMethods, setPaymentMethods] = useState([])

	const [filteredPaymentMethod, setFilteredPaymentMethod] = useState([])
	const [activePaymentMethod, setActivePaymentMethod] = useState([])
	const [selectedPaymentMethods, setSelectedPaymentMethods] = useState([])

	const [reportFrequency, setReportFrequency] = useState({})
	const [origin, setOrigin] = useState([])
	const [selectedOrigin, setSelectedOrigin] = useState([])
	const [list, setList] = useState({
		available: [],
		selected: [],
	})
	const [paymentMethodTypeUnique, setPaymentMethodTypeUnique] = useState([])

	const [pdvData, setPdvData] = useState()

	useEffect(() => {
		if (Pdv) {
			dispatch(ShowLoader(<Loader></Loader>))
			getPdvData(Pdv)
		}
	}, [])

	useEffect(() => {
		setValue('creditOrigins', selectedOrigin)
	}, [selectedOrigin])

	useEffect(() => {
		setValue('paymentMethodsControl', selectedPaymentMethods)
	}, [selectedPaymentMethods])

	useEffect(() => {
		if (pdvData) {
			let badFormatUsers = pdvData.users
			let formatUsers = []
			badFormatUsers.map(x => formatUsers.push({ id: x.key, name: x.value }))
			let { name, paymentMethods, reportFrequency, creditOrigins } = pdvData
			reset({
				name: name,
				paymentMethods: paymentMethods,
				reportFrequency: reportFrequency.key,
				users: formatUsers,
				creditOrigins: creditOrigins,
			})
		}
	}, [pdvData])

	useEffect(() => {
		//setSelectedPaymentMethods
		if (pdvData && watch('paymentMethods').length > 0) {
			setSelectedPaymentMethods(watch('paymentMethods'))
		}
	}, [pdvData])

	useEffect(() => {
		//setSelectedUsers
		if (pdvData && watch('users').length > 0) {
			setList({
				available: [],
				selected: watch('users'),
			})
		}
	}, [pdvData])

	useEffect(() => {
		//setSelectedUsers
		if (pdvData && watch('reportFrequency').length > 0) {
		}
	}, [pdvData])

	useEffect(() => {
		//setSelectedUsers
		if (pdvData && watch('creditOrigins').length > 0) {
			setSelectedOrigin(watch('creditOrigins'))
		}
	}, [pdvData])

	const getPdvData = async () => {
		const res = await GetPdv(Pdv)
		setPdvData(res)
		dispatch(HideLoader())
	}

	const handleGetPaymentMethods = async () => {
		const res = await GetPaymentMethods()
		setPaymentMethods(res)
	}

	const handleGetActivePaymentMethod = async () => {
		const res = await GetActivePaymentMethod()
		setActivePaymentMethod(res)
	}

	useEffect(() => {
		if (activePaymentMethod) {
			const filteredPay = activePaymentMethod.filter(p => p.paymentMethodType.value === watchAllFields.paymentMethod)

			setFilteredPaymentMethod(filteredPay)
		}
	}, [watchAllFields.paymentMethod, paymentMethod])

	useEffect(() => {
		if (activePaymentMethod.length > 0) {
			const paymentMethodType = []
			const key = 'key'

			activePaymentMethod.map(obj => paymentMethodType.push(obj.paymentMethodType))

			const arrayUniqueByKey = [...new Map(paymentMethodType.map(item => [item[key], item])).values()]
			setPaymentMethodTypeUnique(arrayUniqueByKey)
		}
	}, [activePaymentMethod])

	useEffect(() => {
		async function loadPage() {
			dispatch(ShowLoader(<Loader></Loader>))
			handleGetPaymentMethods()
			handleGetActivePaymentMethod()
			const frequency = await GetReportFrequency()
			const payment = await GetActivePaymentMethod()
			const origin = await GetCreditOriginsByPartner(partnerId)
			var payMethod = []
			payment.forEach((item, index) => {
				payMethod.push({
					id: item.id,
					key: index,
					value: (item.paymentMethodType ? t(item.paymentMethodType.value) : '') + ' ' + (item.creditCardBrand ? t(item.creditCardBrand.value) : ''),
				})
			})
			setOrigin(origin)
			setReportFrequency(frequency)
			setPaymentMethod(payMethod)
			dispatch(HideLoader())
		}
		loadPage()
		if (!Pdv) {
			setNewPdv(true)
			setEditMode(true)
		} else {
			getUsers()

			setEditMode(true)
			setNewPdv(true)
		}
	}, [])

	async function getUsers() {
		try {
			dispatch(ShowLoader(<Loader></Loader>))
		} catch {
		} finally {
			dispatch(HideLoader())
		}
	}

	function handleBack() {
		var currentUrl = window.location.pathname
		if (currentUrl === '/Pdvs/new') {
			return history.goBack()
		}
		setEditing(false)
		setSwitchComp('list')
		setPdv('')
	}

	const getColor = () => {
		return (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor &&
			themeColor.navigation.buttons.textColor
		)
	}

	function handleRemove(id) {
		var obj = selectedGroups
		obj.splice(id, 1)
		setSelectedGroups(obj)
	}

	function cancelConfirmed() {
		hideModal()
		setSwitchComp('list')
		setPdv('')
	}

	function handleCancel() {
		if (formState.isDirty)
			return setConfirmModalProps(prevState => ({
				...prevState,
				open: true,
				type: 'cancel',
			}))

		setSwitchComp('list')
		setPdv('')
	}

	function hideModal() {
		setConfirmModalProps({ open: false, type: '', text: '' })
	}

	const checkOrigin = item => {
		let newItem = ''
		if (item) {
			newItem = {
				key: item.id,
				value: item.description,
			}
		}

		if (selectedOrigin.length > 0) {
			let filter = selectedOrigin.filter(x => x.id === item.id)

			if (filter.length === 0) {
				if (selectedOrigin.filter(item => item.key === newItem.key).length > 0) {
					const newSelectedOrigin = selectedOrigin.filter(item => item.key !== newItem.key)
					setSelectedOrigin(newSelectedOrigin)
				} else {
					setSelectedOrigin(prevState => [...prevState, newItem])
				}
			} else {
				let newValue = selectedOrigin.filter(x => x.id !== item.id)
				setSelectedOrigin(newValue)
			}
		} else {
			setSelectedOrigin(prevState => [...prevState, newItem])
		}
	}

	async function sendForm(formData) {
		let selectedUserList = []
		let selectedOriginRaw = []
		let paymentMethodsRaw = []
		let status = ''

		list.selected.map(x => selectedUserList.push(x.id))

		selectedOrigin.map(x => selectedOriginRaw.push(x.key))

		const uniqueSelectedOriginRaw = [...new Set(selectedOriginRaw)]
		selectedPaymentMethods.map(x => paymentMethodsRaw.push(x.id))

		if (pdvData) {
			status = parseInt(pdvData.status.key)
		}

		const obj = {
			name: formData.name,
			reportFrequency: watch('reportFrequency') ? watch('reportFrequency') : 1,
			partnerId: partnerId,
			users: selectedUserList,
			status: 1,
			creditOrigins: uniqueSelectedOriginRaw,
			paymentMethods: paymentMethodsRaw,
		}

		try {
			if (Pdv) {
				dispatch(ShowLoader(<Loader></Loader>))
				await axios.post(`/PDV/PostPDV?id=${Pdv}`, obj).then(response => {
					if (response.data.errors.length === 0) {
						dispatch(HideLoader())
						history.push({
							pathname: '/pdv',
							state: {
								snackStatus: true,
								snackSeverity: 'success',
								snackText: 'PDV Atualizado com sucesso!',
							},
						})
						handleBack()
					} else {
						dispatch(HideLoader())
						history.push({
							pathname: '/pdv',
							state: {
								snackStatus: true,
								snackSeverity: 'danger',
								snackText: 'Erro em atualizar os dados do PDV!',
							},
						})
						handleBack()
					}
				})
			} else {
				dispatch(ShowLoader(<Loader></Loader>))
				await axios.post(`/PDV/PostPDV`, obj).then(response => {
					if (response.data.errors.length === 0) {
						dispatch(HideLoader())
						history.push({
							pathname: '/pdv',
							state: {
								snackStatus: true,
								snackSeverity: 'success',
								snackText: 'Sucesso em criar o PDV!',
							},
						})
						handleBack()
					} else {
						dispatch(HideLoader())
						history.push({
							pathname: '/pdv',
							state: {
								snackStatus: true,
								snackSeverity: 'danger',
								snackText: 'Erro em criar o PDV!',
							},
						})
						handleBack()
					}
				})
			}
		} catch (error) {
		} finally {
			dispatch(HideLoader())
		}
	}

	const handleAddPaymentMethod = () => {
		if (watchAllFields.paymentMethod == null) {
			setSnackStatus({
				...snackStatus,
				severity: 'error',
				open: true,
				text: 'Selecione o meio de pagamento',
			})
			return
		}

		let selectedPaymentMethod = null
		if (watchAllFields.paymentMethod === 'CREDIT_CARD' || watchAllFields.paymentMethod === 'DEBIT_CARD' || watchAllFields.paymentMethod === 'OTHER') {
			if (watchAllFields.filteredPaymentMethod == null) {
				setSnackStatus({
					...snackStatus,
					severity: 'error',
					open: true,
					text: 'Selecione o meio de pagamento',
				})
				return
			}

			const selectedId = watchAllFields.filteredPaymentMethod
			selectedPaymentMethod = activePaymentMethod.filter(p => p.id === selectedId)[0]
		} else {
			selectedPaymentMethod = filteredPaymentMethod[0]
		}

		// Verify if paymentMethod is already in the selectedPaymentMethods
		const exist = selectedPaymentMethods.filter(p => p.id === selectedPaymentMethod.id)
		if (exist.length > 0) {
			setSnackStatus({
				...snackStatus,
				severity: 'error',
				open: true,
				text: 'O meio de pagamento já está selecionado',
			})
			return
		}

		setSelectedPaymentMethods(previous => [...previous, selectedPaymentMethod])
	}

	const handleRemovePaymentMethod = id => {
		const newSelectedPaymentMethods = selectedPaymentMethods.filter(p => p.id !== id)
		setSelectedPaymentMethods(previous => newSelectedPaymentMethods)
	}

	return (
		<Styled.ContainerPdvRegister themeColor={themeColor}>
			<DocumentTitle title={getProgramTitle()} />
			<ConfirmModal
				open={confirmModalProps.open}
				type={confirmModalProps.type}
				text={confirmModalProps.text}
				onConfirm={() => cancelConfirmed()}
				onCancel={() => hideModal()}
			></ConfirmModal>
			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			></SnackDefault>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<div
						style={{
							marginBottom: '17px',
							display: 'flex',
							alignItems: 'center',
							height: '40px',
						}}
					>
						<Section title={t('MENU_PDV')} style={{ flexGrow: 1, margin: '15px 0px' }} />
						<div style={{ marginLeft: '15px' }}>
							<Button
								style={{ marginRight: '0.875rem' }}
								variant="contained"
								color="greenBorderButtonColor"
								size="sm"
								onClick={() => (formState.isDirty ? handleCancel() : handleBack())}
							>
								{formState.isDirty ? 'Cancelar' : 'Voltar'}
							</Button>
							{editing ? (
								<Button color="greenButtonColor" size="sm" onClick={handleSubmit(sendForm)}>
									{editing ? t('BUTTON_SAVE') : t('BUTTON_EDIT')}
								</Button>
							) : (
								<Button color="greenButtonColor" size="sm" onClick={() => setEditing(true)}>
									{editing ? t('BUTTON_SAVE') : t('BUTTON_EDIT')}
								</Button>
							)}
						</div>
					</div>
				</GridItem>
			</GridContainer>
			<form onSubmit={handleSubmit(sendForm)} style={{ width: '100%' }}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12} className={classes.rowMargin}>
						<Controller
							as={
								<CssTextField
									label={<CustomRequiredField disable={!editing} value={t('Identificação do PDV')} />}
									variant="outlined"
									margin="dense"
									fullWidth
									error={!!errors.name}
									helperText={errors.name ? errors.name.message : ''}
									disabled={!editing}
									required={editMode}
								/>
							}
							name="name"
							control={control}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Section title={t('Vincular usuários')} style={{ flexGrow: 1, margin: '25px 0px' }} />
					</GridItem>
					<GridItem
						xs={12}
						sm={12}
						md={12}
						style={{
							marginBottom: '15px',
						}}
					>
						<Controller as={<LinkGroup list={list} setList={setList} users={watch('users')} editing={editing} />} name="users" control={control} />
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Section title={t('SET_CREDIT_EVENTS')} style={{ flexGrow: 1, margin: '25px 0px' }} />
					</GridItem>
					<Controller as={<div></div>} name="creditOrigins" control={control}></Controller>
					{origin && origin.length > 0
						? origin.map((item, index) => (
							<>
								<Grid
									key={index}
									style={{
										marginBottom: '15px',
										paddingLeft: '17px',
									}}
									xs={6}
									sm={6}
									md={6}
								>
									<FormControlLabel
										style={{
											width: '40px',
										}}
										control={
											<Checkbox
												style={{
													transform: 'scale(1.3)',
												}}
												name="checkedB"
												color="primary"
												checked={selectedOrigin.filter(x => x.key === item.id).length > 0}
												disabled={!editing}
												onClick={items => checkOrigin(item)}
											/>
										}
									/>
									<span
										style={{
											position: 'relative',
											right: '20px',
										}}
									>
										{item.description}
									</span>
								</Grid>
							</>
						))
						: ''}
					<GridItem xs={12} sm={12} md={12}>
						<span
							style={{
								color: 'red',
							}}
						>
							{errors && errors.creditOrigins ? `${errors.creditOrigins.message}` : ''}
						</span>
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<Section title={t('Relatorios *')} style={{ flexGrow: 1, marginTop: '15px' }} />
					</GridItem>
					<GridItem
						xs={12}
						sm={12}
						md={6}
						className={classes.rowMargin}
						style={{
							marginTop: '25px',
							color: themeColor && themeColor.titles && themeColor.titles.secondary && themeColor.titles.secondary.textColor,
						}}
					>
						Mostrar as compras realizadas nas ultimas *
						<Controller
							as={
								<CssTextField
									select
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.reportFrequency}
									helperText={errors.reportFrequency ? errors.reportFrequency.reportFrequency : ''}
									disabled={!editMode}
									required={editMode}
								>
									{!reportFrequency.length > 0 ? (
										<MenuItem></MenuItem>
									) : (
										reportFrequency.map(item => (
											<MenuItem value={item.key} key={item.key}>
												{periodNames[item.value]}
											</MenuItem>
										))
									)}
								</CssTextField>
							}
							name="reportFrequency"
							disabled={!editing}
							control={control}
						/>
					</GridItem>

					<GridItem xs={12} sm={12} md={12}>
						<Section title={t('Formas de pagamentos *')} style={{ flexGrow: 1, marginTop: '15px' }} />
					</GridItem>
					<div
						style={{
							display: 'flex',
							width: '100%',
							flexWrap: 'wrap',
						}}
					>
						<div style={{ width: '50%' }}>
							{editing ? (
								<div style={{ width: '200%' }}>
									<GridItem
										xs={12}
										sm={12}
										md={6}
										className={classes.rowMargin}
										style={{
											marginTop: '17px',
											width: '100%',
										}}
									>
										<Controller
											as={
												<CssTextField
													label={<CustomRequiredField message={'Formas de pagamento'} disabled={false} />}
													select
													variant="outlined"
													margin="dense"
													fullWidth
													error={errors.paymentMethod}
													helperText={errors.paymentMethod ? errors.paymentMethod.message : ''}
													disabled={!editMode}
													required={editMode}
												>
													{!paymentMethodTypeUnique.length > 0 ? (
														<MenuItem></MenuItem>
													) : (
														paymentMethodTypeUnique.map(item => (
															<MenuItem value={item.value} key={item.key}>
																{t(item.value)}
															</MenuItem>
														))
													)}
												</CssTextField>
											}
											name="paymentMethod"
											control={control}
											disabled={!editing}
										></Controller>
									</GridItem>
								</div>
							) : (
								''
							)}

							<Controller as={<div></div>} name="paymentMethodsControl" control={control} />

							<GridItem xs={12} sm={12} md={12}>
								<span
									style={{
										color: 'red',
									}}
								>
									{errors && errors.paymentMethodsControl ? `${errors.paymentMethodsControl.message}` : ''}
								</span>
							</GridItem>
						</div>

						{filteredPaymentMethod != null &&
							(watchAllFields.paymentMethod === 'CREDIT_CARD' ||
								watchAllFields.paymentMethod === 'DEBIT_CARD' ||
								watchAllFields.paymentMethod === 'OTHER') && (
								<GridItem
									xs={12}
									sm={12}
									md={6}
									className={classes.rowMargin}
									style={{
										marginTop: '17px',
										width: '100%',
									}}
								>
									<Controller
										as={
											<CssTextField
												label={<CustomRequiredField message={watchAllFields.paymentMethod === 'OTHER' ? 'Descrição' : 'Bandeira'} disabled={false} />}
												select
												variant="outlined"
												margin="dense"
												fullWidth
												error={errors.filteredPaymentMethod}
												helperText={errors.filteredPaymentMethod ? errors.filteredPaymentMethod.message : ''}
												disabled={!editMode}
												required={editMode}
											>
												{!filteredPaymentMethod.length > 0 ? (
													<MenuItem></MenuItem>
												) : (
													filteredPaymentMethod.map(item => (
														<MenuItem value={item.id} key={item.id}>
															{watchAllFields.paymentMethod !== 'OTHER'
																? filteredPaymentMethod[0] && filteredPaymentMethod[0].creditCardBrand && t(item.creditCardBrand.value)
																: item.description}
														</MenuItem>
													))
												)}
											</CssTextField>
										}
										name="filteredPaymentMethod"
										control={control}
									/>
								</GridItem>
							)}
					</div>

					{editing && watchAllFields.paymentMethod && (
						<GridItem
							xs={12}
							style={{
								display: 'flex',
								marginTop: '10px',
								justifyContent: 'flex-end',
							}}
						>
							<Button style={{ marginRight: '0px' }} color="greenButtonColor" size="sm" onClick={handleAddPaymentMethod} disabled={!editing}>
								{t('BUTTON_ADD')}
							</Button>
						</GridItem>
					)}

					<GridItem xs={12} style={{ margin: '20px 0px 20px 0px' }}>
						{selectedPaymentMethods && selectedPaymentMethods.length > 0 ? (
							<Styled.RegisterGrid>
								<MaterialTable
									title={false}
									search={false}
									noToolbar={true}
									components={{
										Pagination: props => {
											return (
												<TablePagination
													{...props}
													rowsPerPageOptions={[]}
													count={pagination.recordCount}
													page={pagination.currentPage}
													rowsPerPage={pagination.pageSize}
													onChangePage={(e, page) =>
														setPagination(prevState => ({
															...prevState,
															currentPage: page,
														}))
													}
												/>
											)
										},
									}}
									options={{
										emptyRowsWhenPaging: false,
										doubleHorizontalScroll: false,
										search: false,
										toolBar: false,
										paging: false,
										pageSizeOptions: false,
										//pageSize: pagination.pageSize,
										loadingType: 'overlay',
									}}
									onChangeRowsPerPage={e =>
										e !== pagination.pageSize
											? setPagination(prevState => ({
												...prevState,
												currentPage: 0,
												pageSize: e,
											}))
											: ''
									}
									localization={{
										header: {
											actions: '',
										},
										pagination: {
											labelRowsSelect: 'linhas',
										},
										body: {
											emptyDataSourceMessage: 'Nenhum registro encontrado.',
										},
									}}
									data={selectedPaymentMethods}
									isLoading={loading}
									columns={[
										{
											title: 'Forma de pagamento',
											field: 'value',
											headerStyle: { fontSize: '15px' },
											render: props => <div>{props.paymentMethodType && t(props.paymentMethodType.value)}</div>,
										},
										{
											title: 'Bandeira',
											field: 'value',
											headerStyle: { fontSize: '15px' },
											render: props => <div>{props.creditCardBrand ? t(props.creditCardBrand.value) : '-'}</div>,
										},
										{
											title: 'Descrição',
											headerStyle: { fontSize: '15px' },
											field: 'value',
											render: props => <div>{props.description ? t(props.description) : '-'}</div>,
										},
										{
											title: '',
											field: 'value',
											sorting: false,
											cellStyle: {
												width: '50px !important',
											},
											headerStyle: {
												width: '50px !important',
												fontSize: '15px',
											},
											render: props => (
												<Button
													justIcon
													style={{ marginRight: '16px' }}
													size="sm"
													color="greenButtonColor"
													className="detail-button"
													onClick={() => handleRemove(props.id)}
												>
													<Tooltip arrow placement="left" title={t('BUTTON_DELETE')}>
														<Delete
															style={{
																color: `${getColor()}`,
															}}
															onClick={() => handleRemovePaymentMethod(props.id)}
														/>
													</Tooltip>
												</Button>
											),
										},
									]}
								/>
							</Styled.RegisterGrid>
						) : (
							<Grid xs={12} style={{ justifyContent: 'center' }}>
								<div style={{ margin: 'auto', width: '100%' }}>
									<NoResults value={t('NO_GROUPS_ASSOCIATED_TO_THIS_USER')} />
								</div>
							</Grid>
						)}
					</GridItem>
				</GridContainer>
			</form>
		</Styled.ContainerPdvRegister>
	)
}

export default withStyles()(withStyles()(PdvRegister))
