import styled from 'styled-components/macro'

export const SearchContain = styled.div`
	background-color: #f6f6f6;
	border-radius: 10px;
	display: flex;
	flex-wrap: wrap;
	padding: 20px 15px 20px 15px;
	margin: 25px 0;
	width: 100%;

	@media only screen and (max-width: 449px) {
		padding-bottom: 75px;
	}

	.css-1p9vdpl-control:hover,
	.css-1iz0oxs-control,
	.css-1iz0oxs-control:hover {
		border-color: ${(props) => props.themeColor} !important;
	}

	.rdtDay.rdtToday.rdtActive,
	.rdtDay.rdtActive,
	.rdtDay.rdtActive:hover {
		background-color: ${(props) => props.themeColor} !important;
	}

	 .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	 .MuiInput-underline:after {
	 	border-color: ${(props) => props.getBGCol};
	 	border-width: 2px;
	 }


	 .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => props.themeColor};
	 }

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => props.getBGCol};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.getBGCol};
	}
`
export const DateContain = styled.div`
	display: flex;
	gap: 30px;
	margin-bottom: 20px;
	width: 100%;

	@media only screen and (max-width: 449px) {
		flex-wrap: wrap;
		gap: 15px;
	}


	@media only screen and (min-width: 700px) {
		padding-right: 15px;
		width: 50%;
	}
	@media only screen and (min-width: 1200px) {
		width: 37%;
	}

	.MuiOutlinedInput-inputMarginDense {
        padding-top: 10px !important;
	}
`
export const DateBox = styled.div`
	width: 50%;

	@media only screen and (max-width: 449px) {
		width: 100%;
	}

	.MuiOutlinedInput-adornedEnd {
		padding-right: 7px;
	}

	i {
		transform: translateY(-2px);
	}
`
export const ConsumerBox = styled.div`
    margin-bottom: 20px;
	width: 100%;
	@media only screen and (min-width: 600px) {
		padding-right: 15px;
		width: 50%;
	}
	@media only screen and (min-width: 700px) {
		padding-left: 15px;
		padding-right: 0;
		width: 50%;
	}
	@media only screen and (min-width: 1200px) {
		padding-left: 15px;
		padding-right: 15px;
		width: 30%;
	}

  .css-g1d714-ValueContainer {
    padding: 0.15rem 0.8rem;
  }

	.css-1okebmr-indicatorSeparator {
        background-color: transparent;
	}
`

export const optionAutocomplete = styled.option`
    background-color: red;
	.CustomOption MuiAutocomplete-option[data-focus="true"] {
		background-color: red !important;
	}
`

export const TransectionCodeBox = styled.div`
	margin-bottom: 20px;
	width: 100%;
	.MuiFormControl-marginDense {
		margin: 0 !important;
	}

	@media only screen and (min-width: 600px) {
		padding-left: 15px;
		width: 50%;
	}
	@media only screen and (min-width: 700px) {
		padding-right: 15px;
		padding-left: 0;
		width: 50%;
	}
	@media only screen and (min-width: 1200px) {
		padding-left: 15px;
		padding-right: 0;
		width: 33%;
	}
`
export const BankBox = styled.div`
	width: 100%;
	margin-bottom: 15px;
	@media only screen and (min-width: 700px) {
		width: 50%;
	}
`
export const BtnBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-top: 5px;
	//margin-top: ${({ open }) => (open ? '5px' : '0')};
	height: 40px;
	width: 100%;
`
export const MoreFilter = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
	height: 40px;
	// padding-left: 7px;
	@media only screen and (min-width: 450px) {
		width: 50%;
	}
`
export const BtnMoreFilter = styled.div`
	align-items: center;
	display: flex;
	margin-top: 15px;
	width: 100%;
	height: 40px;
	gap: 0.813rem;
	justify-content: flex-end;
	@media only screen and (min-width: 450px) {
		justify-content: flex-end;
		margin-top: 0;
		width: 50%;
	}
`

export const TitleContainer = styled.div`
	align-items: center;
	display: flex;
	height: 40px;
	margin-bottom: 15px;
	margin-bottom: 25px;
`

