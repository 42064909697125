import * as Yup from 'yup'
import { getThemeColorFromLocalStorage } from "../../redux/api/themeColors"

export const getThemeColorStructureBackground = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().structure) {
			if (getThemeColorFromLocalStorage().structure.backgroundColor) {
				return getThemeColorFromLocalStorage().structure.backgroundColor
			}
		}
	}
	return '#08993A'
}

export const validationSchema = Yup.object({
	partnerId: Yup.string().required('obrigatório'),
	name: Yup.string().required('obrigatório'),
	sponsorId: Yup.string().required('obrigatório'),
	startTerm: Yup.date().nullable().required('obrigatório'),
	endTerm: Yup.date().nullable().required('obrigatório'),
	investment: Yup.number()
		.nullable()
		.typeError("Obrigatório")
		.max(1000000000, 'valor máximo: 1.000.000.000')
		.required('obrigatório'),
	campaignType: Yup.number().required('obrigatório'),
	productType: Yup.number(),
	volume: Yup.number().when('campaignType', {
		is: 4,
		then: Yup.number().required('obrigatório'),
		otherwise: Yup.number().nullable(),
	}),
	qtyLimit: Yup.boolean().required('obrigatório'),
	units: Yup.number().when('qtyLimit', {
		is: true,
		then: Yup.number().required('obrigatório'),
		otherwise: Yup.number().nullable(),
	}),
	scoringType: Yup.number().required('obrigatório'),
	scoringValue: Yup.number().required('obrigatório'),
	items: Yup.array(),
})


export function sleep(delay = 0) {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
}
