export function formatCellPhone(telefone) {
	return telefone.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
}

export function formatCEP(cep) {
	return cep.replace(/(\d{5})(\d{3})/, '$1-$2')
}

export function formatCPF(cpf) {
	cpf = cpf.replace(/\D/g, '')

	return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
}

export function formatCNPJ(cnpj) {
	cnpj = cnpj.replace(/\D/g, '')

	cnpj = cnpj.replace(/(\d{2})(\d)/, '$1.$2')
	cnpj = cnpj.replace(/(\d{3})(\d)/, '$1.$2')
	cnpj = cnpj.replace(/(\d{3})(\d{4})/, '$1/$2')
	cnpj = cnpj.replace(/(\d{4})(\d{2})$/, '$1-$2')

	return cnpj
}
