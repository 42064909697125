import * as Yup from 'yup'

export const businessUnitSchema = Yup.object({
	businessDivisionName: Yup.string().required(' obrigatório'),
	businessDivisionMarketingArea: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionCEP: Yup.string().required(' obrigatório'),
	businessDivisionAddress: Yup.string().required(' obrigatório'),
	businessDivisionAddressNumber: Yup.string().required(' obrigatório'),
	businessDivisionDistrict: Yup.string().required(' obrigatório'),
	businessDivisionUF: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionCity: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionCountry: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionResponsibleCPF: Yup.string().required(' obrigatório'),
	businessDivisionResponsibleName: Yup.string().required(' obrigatório'),
	businessDivisionResponsibleCellphone: Yup.string().required(' obrigatório'),
	businessDivisionResponsibleEmail: Yup.string().required(' obrigatório'),
	businessDivisionParent: Yup.object()
		.nullable()
		.required(' obrigatório'),
})
