import { Box } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 15px;
	padding: 18px 24px;
`

export const Title = styled(Box)`
	text-align: left;
	font: normal normal bold 15px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`

export const Description = styled(Box)`
	text-align: left;
	font: normal normal normal 15px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`
