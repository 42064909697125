import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
    walletExpirationPointsData: [],
    walletExpirationPointsFailed: false,
    statementData: [],
    statementDetails: false,
    statementDataFailed: false,
}

const fetchWalletExpirationPointsData = (state, action) => {
    return updateObject( state, {
        walletExpirationPointsData: action.walletExpirationPointsData
    })
}

const fetchWalletExpirationPointsFailed = (state, action) => {
    return updateObject( state, {
        walletExpirationPointsFailed: true
    })
}

const fetchStatementData = (state, action) => {
    return updateObject(state, {
        statementData: action.statementData
    });
};

const fetchStatementDetailsData = (state, action) => {
    return updateObject(state, {
        statementDetails: action.statementDetails
    });
};

// DATA FAIL
const fetchStatementFail = (state, action) => {
    return updateObject(state, {
        statementDataFailed: true
    });
};

export const walletReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_STATEMENT_DATA:
            return fetchStatementData(state, action);
        case actionTypes.FETCH_STATEMENT_DETAILS_DATA:
            return fetchStatementDetailsData(state, action);
        case actionTypes.FETCH_STATEMENT_FAILED:
            return fetchStatementFail(state, action);
        case actionTypes.FETCH_CONSUMER_WALLET_DATA:
            return fetchWalletExpirationPointsData(state,action);
        case actionTypes.FETCH_CONSUMER_WALLET_FAILED:
            return fetchWalletExpirationPointsFailed(state,action);
        default:
            return state;
    }
}

export default walletReducer;