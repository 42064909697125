import React from 'react'

// @Material-UI
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

// @Material-Utils
import { makeStyles, withStyles } from '@material-ui/core/styles'

// @ThemeColor
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

let themeColor =
	getThemeColorFromLocalStorage() &&
	getThemeColorFromLocalStorage().navigation &&
	getThemeColorFromLocalStorage().navigation.buttons &&
	getThemeColorFromLocalStorage().navigation.buttons.backgroundColor &&
	getThemeColorFromLocalStorage().navigation.buttons.backgroundColor


export const AntTab = withStyles(theme => ({
	root: {
		//minWidth: 72,
		fontWeight: theme.typography.fontWeightBold,
		marginRight: 2,
		// borderRadius na parte superior direita e esquerda
		borderTopRightRadius: 4,
		borderTopLeftRadius: 4,
		background: ' #E9E9E9 0% 0% no-repeat padding-box',
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:hover': {
			color: themeColor,
			opacity: 1,
		},
		'&$selected': {
			color: '#fff',
			backgroundColor: themeColor,
			//fontWeight: themeColor,
		},
		// '&:focus': {
		// 	color: '#fff',
		// 	fontWeight: themeColor,
		// },
	},
	selected: {},
}))(props => <Tab disableRipple {...props} />)

export const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	padding: {
		//padding: theme.spacing(3),
	},
	demo1: {
		backgroundColor: theme.palette.background.paper,
	},
}))

export const AntTabs = withStyles({
	root: {
		width: '100%',
		borderBottom: `4px solid ${themeColor}`,
	},
	indicator: {
		backgroundColor: themeColor,
	},
})(Tabs)
