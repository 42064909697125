import { TextField, withStyles } from '@material-ui/core'
import React, { forwardRef, useEffect, useState } from 'react'
import MaskedInput, { conformToMask } from 'react-text-mask'

const CPF_MASK_CUSTOM = [
	/[\d]/,
	/[\d]/,
	/[\d]/,
	'.',
	/[\d]/,
	/[\d]/,
	/[\d]/,
	'.',
	/[\d]/,
	/[\d]/,
	/[\d]/,
	'-',
	/[\d]/,
	/[\d]/,
]

const TextMaskCPFCustom = ({ value, onChange, ...props }) => {
	const [maskValue, setMaskValue] = useState(
		value && value.length < 7
			? conformToMask(value, CPF_MASK_CUSTOM).conformedValue
			: `${conformToMask(value, CPF_MASK_CUSTOM).conformedValue}`,
	)


	const handleMask = (valueWithoutMask) => {
		return valueWithoutMask.length < 7 ? CPF_MASK_CUSTOM : CPF_MASK_CUSTOM
	}

	useEffect(() => {
		setMaskValue(value)
	}, [value])

	return (
		<MaskedInput
			mask={handleMask}
			value={maskValue}
			onChange={(e) => {
				const val = onChange(e.target.value )

				if (val) {
					setMaskValue(val)
				} else {
					setMaskValue(e.target.value)
				}
			}}
			{...props}
			guide={false}
		/>
	)
}

export default TextMaskCPFCustom
