import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { Box, Stack } from '@mui/material'
import { FormatMoney } from '../../Functions'

const Info = ({ customCashbackValue }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<Stack gap="4rem" spacing={2} width="100%" display="flex" direction="row" padding="10px 13px" className="stack-info">
				<Box className="box-info">
					<S.Title>{t('Cashback a acumular')}</S.Title>
					<S.SubTitle>{t('Carteira do cliente')}</S.SubTitle>
					<S.Value>{FormatMoney(customCashbackValue?.standardCashbackValue || 0)}</S.Value>
				</Box>
			</Stack>
		</S.Container>
	)
}

Info.propTypes = {
	customCashbackValue: PropTypes.array,
}

Info.defaultProps = {
	calculationMemory: {
		standardAdministrationFeeApplied: '',
		standardGrossValue: '',
		standardAccumulatePoints: '',
	},
}

export default Info
