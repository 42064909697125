import * as actionTypes from './actionsTypes';
import * as API from '../api/campaign';

export const fetchCampaignListData = (data) => {
    return {
        type: actionTypes.FETCH_CAMPAIGN_LIST_DATA,
        lsCampaignData: data
    };
};

export const fetchCampaignData = (data) => {
    return {
        type: actionTypes.FETCH_CAMPAIGN_DATA,
        campaignData: data
    };
};

export const fetchCampaignPerformanceData = (data) => {
    return {
        type: actionTypes.FETCH_CAMPAIGN_PERFORMANCE_DATA,
        campaignPerformanceData: data
    };
};


export const postCampaignData = (data) => {
    return {
        type: actionTypes.POST_CAMPAIGN_DATA,
        postCampaign: data
    };
};
// Update for campaign status inactive
export const putCampaignDeactivate = (data) => {
    return {
        type: actionTypes.PUT_CAMPAIGN_DEACTIVATE,
        deactivateCampaign: data
    };
};

export const putCampaignData = (data) => {
    return {
        type: actionTypes.PUT_CAMPAIGN_DATA,
        putCampaign: data
    };
};

export const fetchProductTypeListData = (data) => {
    return {
        type: actionTypes.FETCH_PRODUCT_TYPE_DATA,
        productTypeList: data
    };
};

export const fetchCampaignTypeListData = (data) => {
    return {
        type: actionTypes.FETCH_CAMPAIGN_TYPE_DATA,
        campaignTypeList: data
    };
};

export const fetchScoringTypeListData = (data) => {
    return {
        type: actionTypes.FETCH_SCORING_TYPE_DATA,
        scoringTypeList: data
    };
};

export const fetchCampaignStatusListData = (data) => {
    return {
        type: actionTypes.FETCH_CAMPAIGN_STATUS_DATA,
        campaignStatusList: data
    };
};

// FAIL FETCH -------------------------------------
export const fetchCampaignError = (error) => {
    return {
        type: actionTypes.FETCH_CAMPAIGN_FAILED,
        campaignFailed: error
    };
};

//----------------------------------------------------

export const SearchCampaignData = (name, sponsor, status, partnerId, campaignMode, page) => {
    return dispatch => {
        // dispatch(fetchCampaignListData([]));
        return (
            API.SearchCampaigns(name, sponsor, status, partnerId, campaignMode, page).then(response => {
                dispatch(fetchCampaignListData(response));
            }).catch(error => {
                console.error(error);
                dispatch(fetchCampaignError(error));
            })
        )
    };
};

export const GetCampaignDataById = (id) => {
    return dispatch => (
        API.GetCampaignById(id).then(response => {
            dispatch(fetchCampaignData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};

export const GetCampaignPerformanceData = (id) => {
    return dispatch => (
        API.GetCampaignPerformance(id).then(response => {
            dispatch(fetchCampaignPerformanceData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};

export const PostCampaignData = (obj) => {
    return dispatch => (
        API.CreateCampaigns(obj).then(response => {
            dispatch(postCampaignData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};

export const PutCampaignData = (id, obj) => {
    return dispatch => (
        API.UpdateCampaigns(id, obj).then(response => {
            dispatch(putCampaignData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};


export const DeactivateCampaign = (id) => {
    return dispatch => (
        API.DeactivateCampaign(id).then(response => {
            dispatch(putCampaignDeactivate(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};

export const GetCampaignTypeListData = () => {
    return dispatch => (
        API.GetCampaignTypes().then(response => {
            dispatch(fetchCampaignTypeListData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};

export const GetProductTypeListData = () => {
    return dispatch => (
        API.GetProductTypes().then(response => {
            dispatch(fetchProductTypeListData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};

export const GetScoringTypeListData = () => {
    return dispatch => (
        API.GetScoringTypes().then(response => {
            dispatch(fetchScoringTypeListData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};

export const GetCampaignStatusListData = () => {
    return dispatch => (
        API.GetCampaignStatus().then(response => {
            dispatch(fetchCampaignStatusListData(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchCampaignError(error));
        })
    );
};