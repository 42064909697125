/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Grid } from "@mui/material";
import { MuiBox } from "../Box";
export function GridContainer(_a) {
    var _b;
    var children = _a.children, customTestId = _a.customTestId, props = __rest(_a, ["children", "customTestId"]);
    return (_jsx(Grid, __assign({ container: true, "data-testid": customTestId !== null && customTestId !== void 0 ? customTestId : "grid-container", width: "100%", spacing: (_b = props === null || props === void 0 ? void 0 : props.spacing) !== null && _b !== void 0 ? _b : 3, sx: {
            "& > .MuiGrid-item": {
                paddingTop: 0.5,
                paddingBottom: 0.5,
            },
        } }, props, { children: children })));
}
export function GridItem(_a) {
    var children = _a.children, customTestId = _a.customTestId, props = __rest(_a, ["children", "customTestId"]);
    return (_jsx(Grid, __assign({ item: true, "data-testid": customTestId !== null && customTestId !== void 0 ? customTestId : "grid-item" }, props, { children: _jsx(MuiBox, __assign({ width: "100%" }, { children: children })) })));
}
