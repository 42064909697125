import styled from 'styled-components'
import { getThemeColorFromLocalStorage, ColorLuminance } from '../../../../redux/api/themeColors'
import Button from '@material-ui/core/Button'

export const Container = styled.div`
`
export const ButtonContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: end;
    margin-top: 15px;
`

export const ButtonAction = styled(Button)`
	height: 40px !important;
    width: 40px !important;
    min-width: 40px !important;
    border: 2px solid #fff !important;
    background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor} !important;
    font-size: 24px !important;
    display: flex;
    color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.textColor} !important;
    align-items: center !important;
    justify-content: center !important;
	border-radius: 6px !important;

	:hover{
		background-color:${ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5,)} !important;
	}
`
