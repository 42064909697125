import React from 'react'
import { Icon, Tooltip } from '@material-ui/core'
import { Flag } from '@material-ui/icons'

export function StatusIcon({ slug, t }) {
	switch (slug) {
		case 'RELEASED':
			return (
				<Tooltip
					title={t ? t(slug) : ''}
					placement="top"
				>
					<Icon
						className="icon-ico_liberado"
						style={{
							fontSize: '20px',
							color: '#08993A',
						}}
					/>
				</Tooltip>

			)
		case 'FAILED':
			return (
				<Tooltip
					title={t ? t(slug) : ''}
					placement="top"
				>
					<Icon
						className="icon-ico_liberado"
						style={{
							fontSize: '20px',
							color: '#08993A',
						}}
					/>
				</Tooltip>

			)
		case 'RESIDUAL':
			return (
				<Tooltip
					title={t ? t(slug) : ''}
					placement="top"
				>
					<Icon
						className="icon-ico_residual_grid"
						style={{
							fontSize: '20px',
							color: '#E58B00',
						}}
					/>
				</Tooltip>

			)
		case 'DEBITED':
			return (
				<Tooltip
					title={t ? t(slug) : ''}
					placement="top"
				>
					<Icon
						className="icon-ico_debitado"
						style={{
							fontSize: '20px',
							color: '#DC3F3F',
						}}
					/>
				</Tooltip>

			)
		case 'RESERVED':
			return (
				<Tooltip
					title={t ? t(slug) : ''}
					placement="top"
				>
					<Icon
						className="icon-ico_reservado"
						style={{
							fontSize: '20px',
							color: '#74CCDA',
						}}
					/>
				</Tooltip>

			)
		case 'CREDITED':
			return (
				<Tooltip
					title={t ? t(slug) : ''}
					placement="top"
				>
					<Icon
						className="icon-ico_creditado"
						style={{
							fontSize: '20px',
							color: '#9FB029',
						}}
					/>
				</Tooltip>

			)
		default:
			return <span></span>
	}
}
