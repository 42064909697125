import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { Box, Stack, Tooltip } from '@mui/material'
import { FormatMoney, FormatPoints } from '../../Functions'
import { createData } from '../../../../../../CalculationMemory'

const Info = ({ calculationMemory, setOpen }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<Stack gap="4rem" spacing={2} width="100%" display="flex" direction="row" padding="10px 13px" className="stack-info">
				<Box className="box-info">
					<S.Title>{t('Taxa de resgate (R$)')}</S.Title>
					<S.Value>{FormatMoney(calculationMemory?.standardValueTax || 0)}</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('Valor em Seeds')}</S.Title>
					<S.Value>{FormatPoints(calculationMemory?.standardPointsValue || 0)}</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('A receber')}</S.Title>
					<S.Value>{FormatPoints(calculationMemory?.standardValueReceivable || 0)}</S.Value>
				</Box>
			</Stack>
			<Tooltip arrow placement="top" title={t('Memória de cálculo')}>
				<Box
					className="stack-info-icon"
					onClick={() => {
						/// /sssssss
						setOpen({
							active: true,
							row: [
								createData('Valor do produto em Seeds', FormatPoints(calculationMemory?.standardPointsValue || 0)),
								createData('Valor do produto em Reais', FormatMoney(calculationMemory?.calculationMemory?.productValue || 0)),
								createData('Taxa de resgate', FormatMoney(calculationMemory?.calculationMemory?.redemptionFee || 0)),
								createData('Taxa de resgate aplicada', FormatMoney(calculationMemory?.standardValueTax || 0)),
								createData('Valor a receber', FormatPoints(calculationMemory?.standardValueReceivable || 0)),
							],
						})
					}}
				>
					<i style={{ fontSize: '1.5rem', color: '#1A9CAC' }} className="icon-bot_memoria_calculo" />
				</Box>
			</Tooltip>
		</S.Container>
	)
}

Info.propTypes = {
	setOpen: PropTypes.func,
	productData: PropTypes.object,
	calculationMemory: PropTypes.array,
}

Info.defaultProps = {
	calculationMemory: {
		standardAdministrationFeeApplied: '',
		standardGrossValue: '',
		standardAccumulatePoints: '',
	},
}

export default Info
