import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, CircularProgress, FormLabel, Grid } from '@mui/material'
import { GetTypePoints, ParameterizationProduct, handleCalculateValue, handleProductDataUpdate } from './Functions'
import { CssTextField } from '../Filter/styles'

//import Cards from './components/cards'
import Configuration from './components/Configuration'

import { DefaultProductData } from './Functions/utils'
import CalcMemory from '../CalculationMemory'
import CustomModal from '../../../Modal'
import SnackDefault from 'components/SnackDefault'

const GeneralTab = ({ productData, setEditMode, editMode, typeOfBenefitPoints, grouper, setModalCancel }) => {
	const { t } = useTranslation()
	const [open, setOpen] = React.useState(false)
	const [GenerationType, setGenerationType] = useState([])
	const [parameterization, setParameterization] = useState([])
	const [circularProgress, setCircularProgress] = useState(false)
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const { control, watch, reset } = useForm({
		defaultValues: {
			typeOfPoints: {
				key: '',
				value: 'Não parametrizado',
			},
			pointsValue: '',
		},
	})

	const { typeOfPoints, pointsValue } = watch()

	const [parameterizationGenerationPoints, setParameterizationGenerationPoints] = useState(parameterization)

	const fetchCalculation = async pointsValueWatch => {
		const generationPoints = await handleCalculateValue(
			productData?.id,
			productData?.price,
			typeOfPoints?.key || 0,
			pointsValueWatch,
			productData?.typeOfRedeem?.key,
			productData?.redemptionPoints?.key,
		)
		if (generationPoints) {
			setParameterizationGenerationPoints(generationPoints)
		}
	}

	const fetchGenerationType = () => {
		GetTypePoints({ setGenerationType, setCircularProgress })
	}

	useEffect(() => fetchGenerationType(), [])

	useEffect(() => {
		handleProductDataUpdate({ productData, setParameterization, reset })
	}, [productData])

	useEffect(() => {
		fetchCalculation(pointsValue)
	}, [typeOfPoints])

	return (
		<Fragment>
			<CalcMemory open={open} onClose={() => setOpen(!open)} productData={productData} />
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					ParameterizationProduct({ grouper, productData, typeOfPoints, pointsValue, setSnackStatus, typeOfBenefitPoints, setEditMode })
					setModalParameterization({ open: false })
				}}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<S.Container>
				{editMode === false ? (
					<S.Content>
						<Buttons className="softBlueAction" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
					</S.Content>
				) : (
					<S.Content>
						<Buttons
							className="cancel"
							Icon="icon-bot_fechar"
							onClick={() =>
								setModalCancel({
									open: true,
									title: 'As alterações realizadas serão perdidas.',
									subtitle: 'Deseja realmente continuar?',
								})
							}
						/>

						<Buttons
							className="save"
							Icon="icon-bot_salvar"
							onClick={() =>
								setModalParameterization({
									open: true,
									title: 'Deseja realmente alterar a parametrização',
									subtitle: 'desse benefício?',
								})
							}
						/>
					</S.Content>
				)}
			</S.Container>
			<S.CardsContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={3}>
						<FormLabel>
							<span className="FormLabel">{t('Configuração do benefício*')}</span>
							<Controller
								name="typeOfPoints"
								control={control}
								render={({ value, onChange }) => (
									<Autocomplete
										id="combo-box-demo"
										options={GenerationType}
										getOptionLabel={option => (option.value ? t(option.value) : '')}
										disabled={!editMode || circularProgress}
										value={value}
										fullWidth
										onChange={(_event, newValue) => onChange(newValue)}
										renderInput={params => (
											<CssTextField
												{...params}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{circularProgress && <CircularProgress color="inherit" size={20} />}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
												variant="outlined"
												size="small"
												disabled={!editMode}
											/>
										)}
									/>
								)}
							/>
						</FormLabel>
					</Grid>
					{/* <Grid item xs={12} sm={9}>
						{typeOfPoints?.key !== '0' && (
							<Cards onOpen={() => setOpen(!open)} calculationMemory={parameterization} typeOfPoints={typeOfPoints?.key} />
						)}
					</Grid> */}
				</Grid>
				<Grid item xs={12} sm={12}>
					{typeOfPoints?.key >= '2' && (
						<Configuration
							editMode={editMode}
							parameterizationGenerationPoints={parameterizationGenerationPoints}
							onOpen={() => setOpen(!open)}
							fetchCalculation={fetchCalculation}
							control={control}
							pointsValue={pointsValue}
							typeOfPoints={typeOfPoints?.key}
							productData={productData}
						/>
					)}
				</Grid>
			</S.CardsContent>
		</Fragment>
	)
}

GeneralTab.propTypes = {
	setModalCancel: PropTypes.func,
	productData: PropTypes.object,
	setEditMode: PropTypes.func,
	editMode: PropTypes.bool,
	typeOfBenefitPoints: PropTypes.string,
	setOpenCalcMemory: PropTypes.func,
	openCalcMemory: PropTypes.bool,
	grouper: PropTypes.number,
}

GeneralTab.defaultProps = {
	productData: DefaultProductData,
}

export default GeneralTab
