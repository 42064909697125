export default function convertDefaultValueId(data) {
	const result = []

	data
		.filter(item => item !== null)
		.forEach(item => {
			result.push(typeof item === 'object' ? item.id : item)
		})

	return result ?? ['']
}
