import { FormatMoney, FormatPoints } from '../ParameterizeDivisionTB/Functions'
import _ from 'lodash'
export const GetDivisionName = item => item?.segmentationType?.value || ''

export function formatLabelsAndValues(item, t, localStorage) {
	const labels = [
		item?.segmentationType?.value || '',
		t('Forma que resgata pontos'),
		item?.typeOfRedeem?.key === '3' ? t('Preço em pontos') : null,
		t('Taxa de resgate (R$)'),
		t(`Valor em ${localStorage.getItem('programCurrencySymbol') || '-'}`),
		t('A receber'),
	].filter(item => item !== null)

	const values = [
		item?.name || '-',
		item?.typeOfRedeem ? t(formatLabelTypeOfRedeem(item?.typeOfRedeem)) : '-',
		item?.typeOfRedeem?.key === '3' ? FormatMoney(item?.parameterizationRedemptionPoints?.standardPointsValue) : null,
		FormatMoney(item?.parameterizationRedemptionPoints?.standardValueTax ?? '0'),
		FormatPoints(item?.parameterizationRedemptionPoints?.standardPointsValue ?? '0'),
		FormatPoints(
			item?.typeOfRedeem?.key === '3'
				? item?.parameterizationRedemptionPoints?.customValueReceivable
				: item?.parameterizationRedemptionPoints?.standardValueReceivable || '0',
		),
	].filter(item => item !== null)

	return { labels, values }
}

const formatLabelTypeOfRedeem = typeOfRedeem => {
	if (!_?.isEmpty(typeOfRedeem)) {
		if (typeOfRedeem?.key === '1') {
			return 'Não resgatável'
		}
		return typeOfRedeem?.value
	}
}
