import axios from "axios";
/*
 * api/Invoice/GetInvoices
 */
export const GetInvoices = (
	dateFrom,
	dateTo,
	ConsumerId,
	partnerId,
	pageIndex
) => {
	let args = "";
	if (dateFrom && dateFrom !== "") args = args + `StartDate=${dateFrom}`;
	if (dateTo && dateTo !== "") args = args + `&EndData=${dateTo}`;
	if (partnerId && partnerId !== "") args = args + `&PartnerId=${partnerId}`;
	if (ConsumerId && ConsumerId !== "")
		args = args + `&ConsumerId=${ConsumerId}`;
	return axios
		.get(`/Invoice/GetInvoices?${args}&Page=${pageIndex}`)
		.then((response) => response.data);
};

/*
 * api/Invoice/{id}
 * GetInvoiceById
 */
export const GetInvoiceById = (id) => {
	return axios
		.get(`/Invoice/GetInvoiceSaleDetails/${id}`)
		.then((response) => response.data);
};

export const GetSalesHistory = (
	startDate,
	endData,
	consumerId,
	channelType,
	code,
	documentId,
	page
) => {
	let args = "";
	if (startDate && startDate !== "") args = args + `StartDate=${startDate}`;

	if (endData && endData !== "") args = args + `&EndData=${endData}`;

	if (channelType && channelType !== "")
		args = args + `&ChannelType=${channelType}`;

	if (consumerId && consumerId !== "")
		args = args + `&ConsumerId=${consumerId}`;

	if (code && code !== "") args = args + `&Code=${code}`;

	if (documentId && documentId !== "")
		args = args + `&DocumentId=${documentId}`;

	return axios
		.get(`/Invoice/GetSalesHistory?${args}&Page=${page}`)
		.then((response) => response.data);
};

export const GetInvoicesDetailsFromPartner = () => {
	return axios
		.get(`/Invoice/GetInvoicesDetailsFromPartner`)
		.then((response) => response.data);
};
