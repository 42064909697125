import styled from 'styled-components'

export const Container = styled.div`
`

export const Components = styled.div`

`

export const Content = styled.div`
    display:flex;
    width: 100%;
    max-width: 100px;
    justify-content: flex-end;
    transform: translateY(-40px);
    
`