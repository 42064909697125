import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@Components
import CardBody from 'components/Card/CardBody.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import { useForm, Controller, useFormContext } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import ListComponent from './list'
import Cookie from 'js-cookie'

//@ API
import { GetGroupers, GetGrouperTypes } from '../../../redux/api/productPage'
import Loader from '../../../components/Loader/Loader'
import { Box } from '@mui/material'

const Productlist = () => {
	const { t } = useTranslation()
	const tableRef = useRef()
	const partnerId = Cookie.get('partnerId')
	const { control, watch, handleSubmit, getValues, setValue } = useForm({
		defaultValue: {
			Grouper: {
				value: '',
				key: '',
			},
		},
	})

	const watchFields = watch()
	const values = getValues()

	const [loading, setLoading] = useState(false)
	const [showGroupItems, setShowGroupItems] = useState(false)
	const [pageSizeState, setPageSizeState] = useState(10)

	const [groupers, setGroupers] = useState({
		value: '',
		key: '',
	})
	const [groupTypes, setGroupTypes] = useState({
		value: '',
		key: '',
	})
	const [filterValues, setFilterValues] = useState({
		SKU: '',
		Name: '',
		Grouper: '',
		GrouperType: '',
	})

	const handleFilterChange = () => {
		const adjustedFilterValues = {
			SKU: values?.SKU || '',
			Name: values?.Name || '',
			Grouper: values?.Grouper?.value || '',
			GrouperType: values?.GrouperType?.key || '',
		}
		setFilterValues(adjustedFilterValues)
		tableRef.current.onQueryChange()
	}

	const handleCleanFilter = () => {
		setFilterValues({
			SKU: '',
			Name: '',
			Grouper: null,
			GrouperType: null,
		})
		setValue('SKU', '')
		setValue('Name', '')
		setValue('Grouper', null)
		setValue('GrouperType', null)
		setShowGroupItems(false)
		setPageSizeState(10)
	}

	useEffect(() => {
		const fetchData = async () => {
			try {
				setLoading(true)
				const response = await GetGroupers()
				setGroupers(response)
			} catch (error) {
				console.error(error)
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [])

	function getFilterProducts() {
		handleSubmit(handleFilterChange)()
	}

	useMemo(() => {
		if (watchFields?.Grouper?.value) {
			getFilterProducts()
			setFilterValues({
				...filterValues,
				GrouperType: '',
			})
			setValue('GrouperType', null)
		}
	}, [watchFields?.Grouper?.value])

	useMemo(() => {
		if (watchFields?.GrouperType) {
			getFilterProducts()
		}
	}, [watchFields?.GrouperType])

	useMemo(async () => {
		const response = await GetGrouperTypes({
			grouperType: watchFields?.Grouper?.value,
			partnerId,
			groupName: '',
			page: '',
		})

		setGroupTypes(response)
	}, [watchFields?.Grouper?.value])

	useEffect(() => {
		if (filterValues.SKU === '' && filterValues.Name === '' && filterValues.Grouper === null && filterValues.GrouperType === null) {
			getFilterProducts()
		}
	}, [filterValues])

	function validateLabel() {
		if (filterValues.Grouper === 'Manufacturer') {
			return `${t('SELECT')} ${t('OF')} ${t(filterValues.Grouper)}`
		} else {
			return `${t('SELECT')} ${t('THE')} ${t(filterValues.Grouper)}`
		}
	}

	return (
		<S.Container>
			{loading && <Loader />}
			<div className="buttonsContainerOut">
				<Button className="newButtons" disabled>
					<i className="icon-item_selecionar" />
				</Button>
				<Button className="newButtons" disabled>
					<i className="icon-bot_parametrizar_massa" />
				</Button>
			</div>
			<CardBody className="CardBody">
				<GridContainer className="GridContainer">
					{/* #1 - Código (SKU) */}
					<GridItem xs={12} sm={12} md={4}>
						<Controller
							name="SKU"
							control={control}
							render={({ onChange, field, value }) => (
								<S.CssTextField
									style={{
										backgroundColor: '#ffffff',
										width: '100%',
									}}
									id="SKU"
									name="SKU"
									onChange={onChange}
									label={<CustomRequiredField disabled value={t('FIELD_CODE_SKU')} />}
									value={value ? value : filterValues.SKU}
									variant="outlined"
									size="small"
									margin="dense"
									fullWidth
								/>
							)}
						/>
					</GridItem>

					{/* #3 - Descrição */}
					<GridItem xs={12} sm={12} md={5}>
						<Controller
							name="Name"
							control={control}
							render={({ field, value, onChange }) => (
								<S.CssTextField
									style={{
										backgroundColor: '#ffffff',
										width: '100%',
									}}
									id="Name"
									name="Name"
									onChange={onChange}
									label={<CustomRequiredField disabled value="PRODUCT" />}
									value={value ? value : filterValues.Name}
									variant="outlined"
									margin="dense"
									size="small"
									fullWidth
								/>
							)}
						/>
					</GridItem>

					{/* #2 - Grouper */}
					<GridItem xs={12} sm={12} md={3}>
						<Controller
							name="Grouper"
							control={control}
							render={({ field, value, onChange }) => (
								<Autocomplete
									id="combo-box-demo"
									options={groupers}
									getOptionLabel={option => (option.value ? t(option.value) : '')}
									disableClearable={true}
									disablePortal
									value={value ?? null}
									onChange={(event, newValue) => {
										onChange(newValue)
										setShowGroupItems(true)
									}}
									renderInput={params => (
										<S.CssTextField
											style={{
												backgroundColor: '#ffffff',
												width: '100%',
												marginTop: '3px',
											}}
											{...params}
											label={t('FIELD_GROUPER')}
											variant="outlined"
											size="small"
										/>
									)}
								/>
							)}
						/>
					</GridItem>
					{/* #3 - Select Group */}
					{showGroupItems && (
						<GridItem xs={12} sm={12} md={4} lg={4}>
							<Box pt={3}>
								<Controller
									name="GrouperType"
									control={control}
									render={({ field, value, onChange }) => (
										<Autocomplete
											id="combo-box-demo"
											options={groupTypes}
											getOptionLabel={option => (option.value ? t(option.value) : '')}
											disableClearable={true}
											disablePortal
											value={value ?? null}
											onChange={(event, newValue) => onChange(newValue)}
											renderInput={params => (
												<S.CssTextField
													style={{
														backgroundColor: '#ffffff',
														width: '100%',
														marginTop: '3px',
													}}
													{...params}
													label={validateLabel()}
													variant="outlined"
													size="small"
												/>
											)}
										/>
									)}
								/>
							</Box>
						</GridItem>
					)}
				</GridContainer>
				<GridContainer className="buttonsContainer">
					<Button
						size="sm"
						variant="contained"
						color="greenBorderButtonColor"
						onClick={() => {
							handleCleanFilter()
						}}
					>
						{t('BUTTON_CLEAN_FILTER')}
					</Button>
					<Button
						size="sm"
						variant="contained"
						style={{ marginLeft: '5px' }}
						color="greenButtonColor"
						onClick={() => {
							setPageSizeState(10)
							getFilterProducts()
						}}
					>
						{t('BUTTON_FIND')}
					</Button>
				</GridContainer>
			</CardBody>
			<ListComponent
				tableRef={tableRef}
				setLoading={setLoading}
				filterValues={filterValues}
				pageSizeState={pageSizeState}
				setPageSizeState={setPageSizeState}
			/>
		</S.Container>
	)
}

export default Productlist
