import * as React from 'react'

// @ Mui
import { styled } from '@mui/material/styles'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'

// @ Styles
import * as S from './styles'

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(() => ({
	[`& .${tooltipClasses.popper}`]: {
		background: '#ffffff 0% 0% no-repeat padding-box',
	},

	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: '#E8E8E8',
		color: 'rgba(0, 0, 0, 0.87)',
		maxWidth: 1000,
		width: '100%',
		minWidth: 200,
		marginLeft: '33px' + ' !important',
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: '#E8E8E8',
		width: '0',
		height: '0',
		borderStyle: 'solid',
		marginLeft: '-1.71em' + ' !important',
		borderWidth: '20px 20px 20px 0',
		borderColor: 'transparent #E8E8E8 transparent transparent',

		'&::before': {
			dispatchEvent: 'none',
			display: 'none',
		},
	},
}))

export default function CustomizedTooltip({ children, title, description }) {
	return (
		<CustomTooltip
			title={
				<S.Container>
					<S.Title>{title}</S.Title>
					<S.Description>{description}</S.Description>
				</S.Container>
			}
			arrow
			placement="right-start"
		>
			{children}
		</CustomTooltip>
	)
}
