export const businessDivisionList = [
	{ id: 1, label: 'BusinessUnit', value: 'BusinessUnit' },
	{ id: 2, label: 'CostCenter', value: 'CostCenter' },
	{ id: 3, label: 'Department', value: 'Department' },
	{ id: 4, label: 'Branch', value: 'Branch' },
	{ id: 5, label: 'Simple', value: 'Simple' },
	{ id: 6, label: 'HeadOffice', value: 'HeadOffice' },
	{ id: 7, label: 'Franchise', value: 'Franchise' },
]

export const benefitList = [
	{ id: 1, label: 'Points', value: 'Points' },
	// { id: 2, label: 'Redeem', value: 'Redeem' },
	{ id: 3, label: 'Cashback', value: 'Cashback' },
]

export const status = [
	{ id: 1, label: 'SaleMade', value: 'SaleMade' },
	{ id: 2, label: 'Active', value: 'Active' },
	{ id: 3, label: 'Expired', value: 'Expired' },
	{ id: 3, label: 'Canceled', value: 'Canceled' },
]

export const createOption = (_value, _label) => ({ value: _value, label: _label })

export const periodOptions = [createOption(15, '15 dias'), createOption(30, '30 dias'), createOption(0, 'Personalizado')]

export function formatCPF(cpf) {
	if (!cpf) return '-'

	return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

export function CustomMask(value) {
	if (value.length === 11) {
		return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
	} else if (value.length === 14) {
		return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
	} else {
		return value
	}
}
