import { GetTypeOfCashback } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import _ from 'lodash'

import {
	UpdateProduct,
	GetProductByDivision,
	GetCalculateCashback,
	CreateProductByMultipleDivisions,
	UpdateProductByDivision,
} from '../../../../../../../../../../../../redux/api/product'

import {envs}  from '../../../../../../../../../../../../redux/api/windowServerData'



export const GetTypePoints = async setGenerationType => {
	try {
		const res = await GetTypeOfCashback()
		if (res && typeof res === 'object') {
			setGenerationType(res)
		}
	} catch (error) {
		setGenerationType([])
	}
}

export const ProductByDivision = async (division, Cashback, productId, setDivisionProductData, setSnackStatus, setLoading) => {
	setLoading(true)
	try {
		const res = await GetProductByDivision(division, Cashback, productId)

		if (res?.success && !_.isEmpty(res?.data)) {
			setDivisionProductData(res.data)
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: 'Ocorreu um erro ao buscar os dados do produto por divisão',
				severity: 'error',
			})
		}

		return res?.data
	} catch (error) {
		setDivisionProductData([])
		setSnackStatus({
			open: true,
			text: 'Ocorreu um erro ao buscar os dados do produto por divisão',
			severity: 'error',
		})
	} finally {
		setLoading(false)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const FormatMoney = value => {
	// const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', symbol)
}

export const handleProductDataUpdate = (productData, reset, setDefaultType) => {
	if (!_.isEmpty(productData)) {
		const { cashbackPercentual, typeOfCashback } = productData

		if (typeOfCashback?.key === '1') {
			typeOfCashback.value = 'Não gera cashback'
		}

		if (typeOfCashback?.key === '2') {
			typeOfCashback.value = 'Padrão'
		}

		if (typeOfCashback?.key === '3') {
			typeOfCashback.value = 'Percentual'
		}

		setDefaultType({
			typeOfCashback: typeOfCashback,
			cashbackPercentual: cashbackPercentual,
		})
		reset({
			typeOfCashback: typeOfCashback,
			cashbackPercentual: cashbackPercentual,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const ParameterizationProduct = async (productData, typeOfPoints, cashbackPercentual, setSnackStatus, typeOfBenefitPoints) => {
	const obj = {
		id: productData?.id,
		price: 0,
		name: productData?.name,
		generatePoints: false,
		typeOfBenefitPoints: typeOfBenefitPoints.key,
		typeOfPoints: '0',
		typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key,
		typeOfRedeem: '0',
		redemptionPoints: productData?.redemptionPoints?.key,
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: '0',
		cashbackPercentual: cashbackPercentual,
	}

	try {
		const res = await UpdateProduct(obj?.id, obj)

		if (res?.success) {
			console.log('res', res)
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				type: 'success',
			})
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				message: res?.data?.errors[0]?.message,
				type: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}

export const handleCalculateValue = async (price, type, value, setCustomCashbackValue) => {
	try {
		const res = await GetCalculateCashback(price, type, value)
		if (res?.success && res?.data) {
			setCustomCashbackValue(res.data)
		}
		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationByDivision = async ({ divisionId, goToBack, productData, typeOfRedeem, redemptionPoints, setSnackStatus }) => {
	const obj = {
		typeOfBenefit: '1',
		price: productData?.price,
		name: productData?.name,
		redemptionPoints: redemptionPoints || 0,
		typeOfBenefitRedeem: '2',
		typeOfRedeem: typeOfRedeem?.value,
		divisionId: divisionId,
	}

	try {
		const res = await UpdateProductByDivision(productData?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				goToBack()
			}, 900)

			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}

export const CreateProductByMultiple = async ({
	history,
	setLoading,
	divisionsId,
	productData,
	typeOfCashback,
	setSnackStatus,
	cashbackPercentual,
	pametrizationName,
}) => {
	setLoading(true)
	const obj = {
		typeOfBenefit: '2',
		price: productData?.price,
		name: productData?.name,
		cashbackPercentual: cashbackPercentual || 0,
		typeOfBenefitCashback: '2',
		typeOfCashback: typeOfCashback?.key,
		pametrizationName: pametrizationName,
		divisionsId: divisionsId,
	}

	try {
		const res = await CreateProductByMultipleDivisions(productData?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				history.push(`/product/history/ProductId=${productData?.id}`)
			}, 900)
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		setSnackStatus({
			open: true,
			text: 'Erro ao realizar a operação!',
			severity: 'error',
		})
	} finally {
		setLoading(false)
	}
}

export const newValue = (value, maxValue) => {
	if (value > maxValue) {
		return maxValue
	}
	return value
}
