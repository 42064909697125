import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    lsSponsorsData: [],
    sponsorData: {},
    postSponsorsData: [],
    selectSponsors: [],
    

    sponsorFailed: false,
    postSponsorsFailed: false
};

const fetchSelectSponsorsData = (state, action) => {
    return updateObject(state, {
        selectSponsors: action.selectSponsors
    });
};

const fetchSponsorsData = (state, action) => {
    return updateObject(state, {
        lsSponsorsData: action.lsSponsorsData
    });
};

const fetchSponsorIdData = (state, action) => {
    return updateObject(state, {
        sponsorData: action.sponsorData
    });
};

const postSponsorsData = (state, action) => {
    return updateObject(state, {
        postSponsorsData: action.postSponsorsData
    });
};

// DATA FAIL
const fetchSponsorFailed = (state, action) => {
    return updateObject(state, {
        sponsorFailed: true
    });
};

const postSponsorsFailed = (state, action) => {
    return updateObject(state, {
        postSponsorsFailed: true
    });
};

const sponsorsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_SPONSOR_ID_DATA:
            return fetchSponsorIdData(state, action);

        case actionTypes.FETCH_SPONSOR_LIST_DATA:
            return fetchSelectSponsorsData(state, action);

        case actionTypes.FETCH_SPONSORS_DATA:
            return fetchSponsorsData(state, action);

        case actionTypes.POST_SPONSORS_DATA:
            return postSponsorsData(state, action);

        case actionTypes.FETCH_SPONSOR_FAILED:
            return fetchSponsorFailed(state, action);

        case actionTypes.POST_SPONSORS_FAILED:
            return postSponsorsFailed(state, action);

        default:
            return state;
    }
};

export default sponsorsReducer;