import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { Box, Stack, Tooltip } from '@mui/material'
import { FormatMoney, FormatPoints } from '../../../Functions'

const GenerationPointsInfo = ({ parameterization, onOpen, calculationMemory }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<Stack
				gap="4rem"
				spacing={2}
				width="100%"
				display="flex"
				direction="row"
				padding="10px 13px"
				className="stack-info"
			>
				<Box className="box-info">
					<S.Title>{t('Taxa de administração')}</S.Title>
					<S.SubTitle>{t('Programa')}</S.SubTitle>
					<S.Value>
						{FormatMoney(
							parameterization?.customAdministrationFeeApplied ?
								parameterization?.customAdministrationFeeApplied :
								calculationMemory?.customAdministrationFeeApplied ||
								0,
						)}
					</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('Valor bruto')}</S.Title>
					<S.SubTitle>{t('Lastro')}</S.SubTitle>
					<S.Value>
						{FormatMoney(
							parameterization?.customGrossValue ?
								parameterization?.customGrossValue :
								calculationMemory?.customGrossValue ||
								0)}
					</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('Pontos a acumular')}</S.Title>
					<S.SubTitle>{t('Carteira do cliente')}</S.SubTitle>
					<S.Value>
						{FormatPoints(
							parameterization?.customAccumulatePoints ?
								parameterization?.customAccumulatePoints :
								calculationMemory?.customAccumulatePoints ||
								0)}
					</S.Value>
				</Box>
			</Stack>
			<Tooltip arrow placement="top" title={t('Memória de cálculo')}>
				<Box className="stack-info-icon" onClick={() => onOpen()}>
					<i
						style={{ fontSize: '1.5rem', color: '#1A9CAC' }}
						className="icon-bot_memoria_calculo"
					/>
				</Box>
			</Tooltip>
		</S.Container>
	)
}

GenerationPointsInfo.propTypes = {
	parameterization: PropTypes.array,
	onOpen: PropTypes.func,
}

GenerationPointsInfo.defaultProps = {
	parameterization: {
		standardAdministrationFeeApplied: '',
		standardGrossValue: '',
		customGrossValue: '',
	},
}

export default GenerationPointsInfo
