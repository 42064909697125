import React from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

// imgs

// UTILS
import compose from 'utils/compose'

import styles from './resetPassword.module.scss'

import { GetResetPasswordToken, PostResetPassword, SweetAlert, HideAlert, ShowLoader, HideLoader } from 'redux/actions'

import ResetPasswordForm from './Component/ResetPassword'
import RequestPasswordResetForm from './Component/RequestPasswordReset'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx'
import { connectRouterState } from 'utils/connectRouterState'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { getProgramTitle } from '../../../utils/utils'

const requestValidationSchema = Yup.object({
	login: Yup.string().required('CPF é obrigatório.'),
})

const resetValidationSchema = Yup.object({
	token: Yup.string(),
	newPassword: Yup.string()
		.min(8, 'mínimo de 8 caracteres')
		.max(20, 'máximo de 20 caracteres')
		.required('Senha nova é obrigatório'),
	// confirmPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Campos de senha devem ser identicos.').required()
	confirmPassword: Yup.string()
		.min(8, 'mínimo de 8 caracteres')
		.max(20, 'máximo de 20 caracteres')
		.oneOf([Yup.ref('newPassword'), null], 'As senhas não conferem')
		.required('Confirme a nova senha'),
})

class ResetPassword extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			id: '',
			token: '',
			login: '',
			newPassword: '',
			confirmPassword: '',
			alert: null,
		}
		if (localStorage.getItem('i18nextLng') != null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		this.setState({ ...this.props.urlState })
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.requestPasswordReset && prevProps.requestPasswordReset !== this.props.requestPasswordReset) {
			if (this.props.requestPasswordReset.success) {
				// this.setState({
				//     alert:
				//         <Alert
				//             success
				//             style={{ display: "block", marginTop: "-100px" }}
				//             title={null}
				//             onConfirm={() => this.hideAlert()}
				//             confirmBtnCssClass={
				//                 this.props.classes.button + " " + this.props.classes.greenButtonColor
				//             }
				//             confirmBtnText={"Ok"}
				//         >
				//             <label className={styles.font}>
				//                 {this.props.t("REQUEST_PASSWORD_RESET") + this.props.requestPasswordReset.data}
				//             </label>
				//         </Alert>
				// });
			} else {
				this.setState({
					alert: (
						<Alert
							warning
							style={{ display: 'block', marginTop: '-100px' }}
							title={'Erro'}
							onConfirm={() => this.hideAlert()}
							confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						>
							{this.props.requestPasswordReset.errors.map(error => [<div>{this.props.t(error.message)}</div>])}
						</Alert>
					),
				})
			}
			this.formik.current && this.formik.current.resetForm()
		}

		if (this.props.resetPassword && prevProps.resetPassword !== this.props.resetPassword) {
			this.setState({
				alert: (
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={null}
						onConfirm={() => this.props.history.push('/auth/login')}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					>
						<label className={styles.font}>{this.props.t('FIELD_ALERT_SUCCESS')}</label>
					</Alert>
				),
			})
			// this.formik.current && this.formik.current.resetForm();
		}
	}

	handleRequest = values => {
		this.props.GetResetPasswordToken(values.login.replace(/(\.|\/|\-)/g, ''))
	}

	handleCancel = () => {
		this.props.history.push(`/auth/login`)
	}

	handleReset = values => {
		this.props.PostResetPassword(values)
	}

	renderErrorMessage = ({ resetPasswordFailed, t }) => {
		try {
			return resetPasswordFailed.message
				? t(resetPasswordFailed.message)
				: resetPasswordFailed?.errors?.map((error, index) => {
					let jsonWithoutEscapes = error?.message?.replace(/\\/g, '')
					let jsonArray = JSON.parse(jsonWithoutEscapes)
					let errorMessage = jsonArray[0]
					return <div key={index}>{t(errorMessage?.error_description?.replace('.', ''))}</div>
				})
		} catch (error) {
			return t(resetPasswordFailed?.errors?.length > 0 ? resetPasswordFailed?.errors[0].message : 'FIELD_ALERT_ERROR')
		}
	}

	hideAlert = () => this.setState({ alert: null })

	render() {
		const { t, classes, resetPasswordFailed } = this.props
		const { login, token, newPassword, confirmPassword, alert } = this.state
		const values = { token, newPassword, confirmPassword }

		return (
			<div className={classes.container}>
				{alert}
				<DocumentTitle title={getProgramTitle()} />

				<GridContainer justifyContent="center">
					<GridItem xs={12} sm={6} md={4}>
						<form name="form" onSubmit={this.submit}>
							<div login className={styles.card}>
								<div className={styles.cardShadow}>
									<div className={`${classes.textCenter} ${styles.cardHeader}`} color="success">
										<img
											src={(() => {
												if (
													getThemeColorFromLocalStorage() &&
													getThemeColorFromLocalStorage().images &&
													getThemeColorFromLocalStorage().images.loginPage &&
													getThemeColorFromLocalStorage().images.loginPage.logo &&
													getThemeColorFromLocalStorage().images.loginPage.logo.url
												) {
													return getThemeColorFromLocalStorage().images.loginPage.logo.url
												}
												return ''
											})()}
											style={{ width: '200px' }}
											alt="logo"
										/>
										{/* <div className={styles.account}>
											Não tem uma conta? <a href="/register">Crie agora</a>, leva apenas alguns click.
                               			</div> */}
									</div>

									<div className={styles.cardHeaderTitle} style={{ backgroundColor: germiniDefaultColors[0] }}>
										{t('TITLE_PARTNER_PORTAL')}
									</div>
								</div>
								<div className={styles.cardBody}>
									{_.isEmpty(token) ? (
										<Formik
											ref={this.formik}
											render={props => (
												<RequestPasswordResetForm handleCancel={this.handleCancel} {...props} disabledForm={resetPasswordFailed === 'loading'} />
											)}
											validationSchema={requestValidationSchema}
											initialValues={{ login }}
											onSubmit={this.handleRequest.bind(this)}
											handleCancel={this.handleCancel.bind(this)}
										/>
									) : (
										<Formik
											ref={this.formik}
											render={props => <ResetPasswordForm {...props} disabledForm={resetPasswordFailed === 'loading'} />}
											validationSchema={resetValidationSchema}
											initialValues={values}
											onSubmit={this.handleReset.bind(this)}
											enableReinitialize={true}
											handleCancel={this.handleCancel.bind(this)}
										/>
									)}
								</div>
								{resetPasswordFailed && (
									<div style={{ textAlign: 'center' }}>
										{resetPasswordFailed === 'loading' ? (
											<CircularProgress style={{ color: '#08993a' }} color="inherit" />
										) : (
											<Typography color="error" style={{ fontWeight: 'bold' }}>
												{this.renderErrorMessage({ resetPasswordFailed, t })}
											</Typography>
										)}
									</div>
								)}
							</div>
						</form>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}
const mapStateToProps = state => {
	return {
		requestPasswordReset: state.users.requestPasswordReset,
		resetPassword: state.users.resetPassword,
		resetPasswordFailed: state.users.resetPasswordFailed,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators({ GetResetPasswordToken, PostResetPassword, SweetAlert, HideAlert, ShowLoader, HideLoader }, dispatch)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(loginPageStyle),
	withStyles(extendedFormsStyle),
	withTranslation(),
	connectRouterState({ token: '' }),
)(ResetPassword)
