import React, { Fragment } from 'react'
import BusinessInfos from '../../Components/BusinessInfos'
import Address from '../../Components/Address'
import Responsible from '../../Components/Responsible'

export default function FormBusinessUnit({ ...sharedProps }) {
	return (
		<Fragment>
			<BusinessInfos {...sharedProps} />

			<Address {...sharedProps} />

			<Responsible {...sharedProps} />
		</Fragment>
	)
}
