import { numberFormatText } from 'utils/utils'
import {envs}  from '../../../../../../../../../redux/api/windowServerData'



export const FormatMoney = (value = 0) => {
	const result = numberFormatText(
		value,
		// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		2,
	)

	return result ?? 0
}

export const FormatPoints = (value = 0) => {
	const result = numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		0,
	)

	return result ?? 0
}

export const formatTypeOfBenefit = (TypeOfBenefit, Name) => {
	const newName = 'Valores baseados'

	if (TypeOfBenefit === 'Percentage' && Name === 'Geração') {
		return newName + ' na taxa percentual'
	}
	if (TypeOfBenefit === 'Fixed' && Name === 'Geração') {
		return newName + ' na taxa por pontos fixos'
	}

	if (TypeOfBenefit === 'Custom' && Name === 'Resgate') {
		return newName + ' na conversão personalizada'
	}

	if (TypeOfBenefit === 'Fixed' && Name === 'Cashback') {
		return newName + ' na geração de valor fixo'
	}

	if (TypeOfBenefit === 'Manual' && Name === 'Cashback') {
		return newName + '  na geração percentual'
	}
}

export const changeValues = ({
	type,
	setTaxField,
	setValueField,
	setPointsField,
	parametrizationCashback,
	parameterizationGeneration,
	parameterizationRedemption,
}) => {
	if (parameterizationGeneration) {
		setTaxField(parameterizationGeneration?.calculationMemory?.administrationFee)
		setValueField(type === 'default' ? parameterizationGeneration?.standardGrossValue : parameterizationGeneration?.customGrossValue)
		setPointsField(type === 'default' ? parameterizationGeneration?.standardGrossValue : parameterizationGeneration?.customAccumulatePoints)
	}
	if (parameterizationRedemption) {
		setTaxField(type === 'default' ? parameterizationRedemption?.standardValueTax : parameterizationRedemption?.customValueTax)
		setValueField(type === 'default' ? parameterizationRedemption?.standardValueReceivable : parameterizationRedemption?.customValueReceivable)
		setPointsField(type === 'default' ? parameterizationRedemption?.standardPointsValue : parameterizationRedemption?.customPointsValue)
	}
	if (parametrizationCashback) {
		setTaxField('')
		setValueField(type === 'default' ? parametrizationCashback?.standardCashbackValue : parametrizationCashback?.customCashbackValue)
		setPointsField('')
	}
}
