import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

// @ Styles and request
import * as S from './styles'

// Images
import noImage from '../../../../../assets/img/no-image.png'

// @Material-ui
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import CustomizedTooltip from '../Tooltip'

export default function AdditionalModulesCard({ options, additionalModules, methods, isEditData }) {
	const { setValue, register } = methods
	const [optionsList, setOptionsList] = useState([])

	const disabledOptions = nextView => {
		setValue('additionalModules', nextView)

		setOptionsList(nextView)
	}

	useEffect(() => {
		if (additionalModules?.length > 0) {
			const additionalModulesIds = additionalModules?.map(i => i?.id)
			setValue('additionalModules', additionalModulesIds)
			setOptionsList(prev => [...prev, ...additionalModulesIds])
		}
	}, [additionalModules])

	return (
		<S.PaymentOptions {...register('additionalModules')}>
			<ToggleButtonGroup
				onChange={(_event, nextView) => {
					disabledOptions(nextView)
				}}
				disabled={!isEditData}
				value={optionsList}
			>
				{options.map(i => {
					const isSelected = optionsList.includes(i?.id)
					return (
						<ToggleButton key={i.name} value={i?.id}>
							{isSelected && <S.Icon className="icon-grid_ativo" />}
							<CustomizedTooltip title={i.name} description={i.description}>
								<img src={i.icon || noImage} />
							</CustomizedTooltip>
						</ToggleButton>
					)
				})}
			</ToggleButtonGroup>
		</S.PaymentOptions>
	)
}

AdditionalModulesCard.propTypes = {
	options: PropTypes.array.isRequired,
	methods: PropTypes.object.isRequired,
	isEditData: PropTypes.bool.isRequired,
	additionalModules: PropTypes.array.isRequired,
	customFieldsForm: PropTypes.object.isRequired,
	updateCustomFieldsForm: PropTypes.func.isRequired,
}
