import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import {
	germiniDefaultColors,
	grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import Button from 'components/CustomButtons/Button.jsx'

export const Status = styled.div`
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-left: 1rem;
	background-color: ${(props) => (props.active ? '#8BC53F' : '#E81F26')};
`

export const GridContent = styled(Grid)`
	padding: 0px !important;
	.MuiTableCell-body {
		padding: 0px 0px 0px 15px!important;
	}
	.gWuMPF .MuiTableBody-root tr td {
		padding: 0 0 0 15px !important;
	}

	.MuiTableBody-root {
      tr {
        td {
          :last-child {
			width: 1% !important;
            justify-content: flex-start;
            text-align: left !important;
		  }
        }
      }
    }
`

export const ListGrid = styled(Grid)`
	background-color: #fff;
	height: 100%;
	margin: 30px 0;
	width: 100%;

	.section {
		font-weight: 300;
		color: #5d5d5d;
		margin-top: 10px;
	}
	> div {
		&:first-child {
			padding: 0 !important;
		}
	}

	.MuiToolbar-regular {
		min-height: 0px;
	}

	.grid-container {
		margin: 0 30px;
		padding: 0 0 15px 0;
	}

	.button-container {
		max-height: 36px;
	}

	.filter-box {
		margin: 30px 0;
		background-color: #fafafa;
		padding: 16px;
		padding-left: 24px;
		.search-button {
			margin-right: 20px;
			color: #fff;
			&:hover {
				background-color: ${germiniDefaultColors[0]};
				opacity: 0.8;
			}
		}
	}

	.MuiPaper-elevation2 {
		box-shadow: unset;
	}

	.MuiTableCell-root {
		> div {
			.MuiIconButton-root {
				background: transparent !important;
				box-shadow: none !important;
			}
			button {
				.MuiTouchRipple-root {
					display: none !important;
				}
				cursor: pointer;
				box-shadow: none !important;
			}
		}
	}

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				color: ${germiniDefaultColors[0]};
				font-size: 15px;
				font-weight: bold;

				:first-child {
					border-top-left-radius: 6px;
					width: 20% !important;
				}

				:nth-child(1) {
					/* width: 50% !important; */
					width: 20% !important;

					justify-content: flex-start;
					text-align: left !important;
				}

				:nth-child(2) {
					/* width: 50% !important; */
					width: 50% !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:nth-child(3) {
					width: 20% !important;
					text-align: left !important;
				}

				:nth-child(4) {
					width: 10% !important;
					text-align: left !important;
				}

				:last-child {
					border-top-right-radius: 6px;
					padding: 0 !important;
					width: 15% !important;
					justify-content: center !important;
					text-align: center !important;
					> div {
						margin-right: 0px;
					}
					span {
						svg {
							display: none;
						}
					}
				}
			}
		}
	}
	.MuiTableBody-root {
		tr {
			color: #717171;
			background-color: #fff;
			td {
				font-size: 15px !important;
				/* background-color: black; */
				height: 45px !important;
				padding: 0 15px !important;

				&:not(:last-child) {
					padding: 10px 15px !important;
				}

				:first-child {
					width: 20% !important;

					width: min-content !important;
				}
				> div {
				}

				> div p {
					margin: 0px;
					font-size: 13px;
					font-style: italic;
				}

				:nth-child(1) {
					/* width: 50% !important; */
					width: 20% !important;

					justify-content: flex-start;
					text-align: left !important;
				}

				:nth-child(2) {
					width: 50% !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:nth-child(3) {
					width: 20% !important;
					text-align: left !important;
				}

				:nth-child(4) {
					width: 10% !important;
					text-align: left !important;
				}

				:last-child {
					padding: 0 !important;
					width: 15% !important;
					justify-content: flex-end;
					text-align: right !important;
					> div {
					}
				}
			}
		}
	}

	.MuiIconButton-root {
		padding: 0px !important;
	}

	.submit-container {
		margin: 20px 0;
		padding-right: 16px;
		> :first-child {
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 1200px) {
		.create-title {
			width: 100%;
			margin-top: 30px;
		}
	}
`

export const GridButton = styled(Button)`
	height: 100% !important;
	width: 50px !important;
	border-radius: 0px !important;
	margin: 0px 0px 0px 1px !important;

	& svg {
		width: 1.5rem !important;
		height: 1.5rem !important;
	}
`
