import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    cardsData: [],
    cardsDataFailed: false
};
// -----------------------------------------

const fetchCardsData = (state, action) => {
    return updateObject(state, {
        cardsData: action.cardsData,
    });
};

// DATA FAIL
const fetchCardsFailed = (state, action) => {
    return updateObject(state, {
        cardsDataFailed: action.cardsDataFailed
    });
};

const dashReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_DASHBOARD_FAILURE:
            return fetchCardsFailed(state, action);
        case actionTypes.FETCH_DASHBOARD_DATA:
            return fetchCardsData(state, action);
        default:
            return state;
    }
};

export default dashReducer;