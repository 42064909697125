import React, { useEffect, useState } from 'react'
import { Card } from './Card'
import styled from 'styled-components'
import { Button as MuiButton, Grid } from '@material-ui/core'
import { OpenInNew } from '@material-ui/icons'
import Button from 'components/CustomButtons/Button.jsx'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

// Redux
import { useDispatch } from 'react-redux'
import { HideLoader } from 'redux/actions'
import { GetBalance } from '../../../redux/api/accounts'

// Context
import { useAccounts } from '../hooks/useAccounts'

import { numberFormatText } from 'utils/utils'
import {envs}  from '../../../redux/api/windowServerData'



const StyledLine = styled.div`
	position: relative;
	width: 100%;
	height: 38px;
	color: '#333333';
	font-weight: bold;

	& > div {
		width: 100%;
		height: 38px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 10px;
	}

	& button {
		text-decoration: underline;
		text-decoration-style: dashed;
		padding: 0;
		text-underline-offset: 2px;
	}

	& h6 {
		font-size: 1rem;
	}

	.MuiButton-root {
		text-transform: none;
	}
`

const LineBg = styled.div`
	position: absolute;

	width: 100%;
	height: 38px;
	background-color: #21409a;
	opacity: ${props => (!!props.opacity ? props.opacity : '')};
`

function Line({ opacity, label, value, onClick, link = false }) {
	return (
		<StyledLine>
			<LineBg opacity={opacity}></LineBg>
			<div>
				{link ? (
					<MuiButton onClick={onClick}>
						<span>{label}</span>
						<OpenInNew
							style={{
								width: '1rem',
								height: '1rem',
								marginLeft: '7px',
							}}
						/>
					</MuiButton>
				) : (
					<span>{label}</span>
				)}

				<h6>
					{/* {value ?
						value.toLocaleString('pt-br', {
							style: 'currency',
							currency: 'BRL',
						}) : } */}

					{numberFormatText(
						value ? value : '0',
						localStorage.getItem('currencySymbol')
							? `${localStorage.getItem('currencySymbol')}\n`
							: // : envs.REACT_APP_CURRENCY_SYMBOL,
							  envs.REACT_APP_CURRENCY_SYMBOL,
						2,
					)}
				</h6>
			</div>
		</StyledLine>
	)
}

export function Balance() {
	const { valueTypeFilter } = useAccounts()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { openCreateReservation, openInsertBalance, openResidualReserved } = useAccounts()
	const { id } = useParams()

	const { refreshBalance } = useAccounts()

	const [bankAccountValues, setBankAccountValues] = useState({})

	useEffect(() => {
		dispatch(HideLoader())
		RequestApi()
	}, [valueTypeFilter, refreshBalance])

	async function RequestApi() {
		try {
			const res = await GetBalance(id)
			setBankAccountValues(res.data)
		} catch (err) {
		} finally {
		}
	}

	return (
		<Card border="1px solid #e1e1e1" title="saldos" iconBg="#FB9006" px={'0.5rem'} em={'0.4rem'} icon="icon-acao_extrato_bancario">
			<Grid container xs={12} sm={12}>
				<Line opacity="0.04" label="Total" value={bankAccountValues.total} />

				<Line opacity="0.1" label="Reservado" value={bankAccountValues.reserved} />
				<Line opacity="0.16" label="Disponível" value={bankAccountValues.available} />

				<Line opacity="0.24" label="Residual reservado" link onClick={openResidualReserved} value={bankAccountValues.reserveResidual} />

				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					style={{
						marginTop: '5px',
						width: '100%',
						display: 'flex',
						gap: '0.813rem',
						justifyContent: 'end',
					}}
				>
					<Button size="sm" style={{ fontSize: '1rem', boxShadow: 'none' }} variant="contained" color="greenButtonColor" onClick={openInsertBalance}>
						{t('INSERT_BALANCE')}
					</Button>
					<Button
						size="sm"
						style={{
							fontSize: '1rem',
							padding: '0.40625rem 0.938rem',
							boxShadow: 'none',
						}}
						variant="contained"
						color="warning"
						onClick={() =>
							openCreateReservation({
								bankAccountId: id,
								bankAccountValues,
							})
						}
					>
						{t('CREATE_RESERVATION')}
					</Button>
				</Grid>
			</Grid>
		</Card>
	)
}
