import React from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import Hidden from '@material-ui/core/Hidden'
import Fade from '@material-ui/core/Fade'
import Icon from '@material-ui/core/Icon'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import IconMenu from 'components/IconMenu/IconMenu.jsx'

import customNavPillsStyle from 'assets/jss/material-dashboard-pro-react/components/customNavPillsStyle.jsx'

class CustomNavPills extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			active: props.active,
			tabs: props.tabs,
			value: '',
		}
	}

	componentDidUpdate(nexProps, nextState) {
		if (nextState.active !== this.state.active) {
			this.props.handleChangeNavPill(this.state.active)
		}
	}

	handleChangeValue = (e) => this.setState({ active: e.target.value })

	handleChange = (event, active) => {
		this.setState({ active })
	}
	handleChangeIndex = (index) => {
		this.setState({ active: index })
	}

	render() {
		const { classes, tabs, color, horizontal, alignCenter } = this.props
		const flexContainerClasses = classNames({
			[classes.flexContainer]: true,
			[classes.horizontalDisplay]: horizontal !== undefined,
		})

		let tabTitle = ''

		const tabButtons = (
			<Tabs
				classes={{
					root: classes.root,
					fixed: classes.fixed,
					flexContainer: flexContainerClasses,
					indicator: classes.displayNone,
				}}
				value={this.state.active}
				onChange={this.handleChange}
				centered={alignCenter}
			>
				{tabs &&
					tabs.map((prop, key) => {
						var icon = {}
						if (prop.tabIcon !== undefined) {
							icon['icon'] = (
								<Icon
									className={prop.tabIcon}
									style={{ marginBottom: 0 }}
								/>
							)
						}
						if (prop.tabClick !== undefined) {
						}
						const pillsClasses = classNames({
							[classes.pills]: true,
							[classes.horizontalPills]: horizontal !== undefined,
							[classes.pillsWithIcons]:
								prop.tabIcon !== undefined,
						})
						return (
							<Tab
								label={prop.label}
								key={key}
								{...icon}
								classes={{
									root: pillsClasses,
									labelContainer: classes.labelContainer,
									label: classes.label,
									selected: classes[color],
									wrapper: classes.wrapper,
								}}
							/>
						)
					})}
			</Tabs>
		)
		const tabContent = (
			<div className={classes.contentWrapper}>
				<SwipeableViews
					axis="x"
					index={this.state.active}
					onChangeIndex={this.handleChangeIndex}
					style={{ overflow: 'hidden' }}
					animateTransitions={false}
				>
					{tabs &&
						tabs.map((prop, key) => {
							var fadeIn = false
							if (this.state.active === key) {
								fadeIn = true
							} else {
								fadeIn = false
							}
							return (
								<Fade in={fadeIn} key={key}>
									<div className={classes.tabContent}>
										{prop.tabContent}
									</div>
								</Fade>
							)
						})}
				</SwipeableViews>
			</div>
		)
		return horizontal !== undefined ? (
			<div>
				<Hidden mdUp implementation="css">
					<IconMenu
						items={this.state.tabs}
						value={this.state.value}
						onChangeValue={this.handleChangeValue}
					></IconMenu>
					{tabContent}
				</Hidden>
				<Hidden mdDown implementation="css">
					<GridContainer>
						<GridItem {...horizontal.tabsGrid}>
							{tabButtons}
						</GridItem>
						<GridItem {...horizontal.contentGrid}>
							{tabContent}
						</GridItem>
					</GridContainer>
				</Hidden>
			</div>
		) : (
			<div>
				<Hidden smUp implementation="css">
					{tabs &&
						tabs.map((prop, key) => {
							if (this.state.active === key) {
								tabTitle = prop.label
							}
						})}
					<div className={classes.mobileHeader}>
						<span className={classes.tabTitle}>{tabTitle}</span>
						<IconMenu
							items={this.state.tabs}
							value={this.state.value}
							onChangeValue={this.handleChangeValue}
							active={this.state.active}
						/>
					</div>
					{tabContent}
				</Hidden>
				<Hidden xsDown implementation="css">
					{tabButtons}
					{tabContent}
				</Hidden>
			</div>
		)
	}
}

CustomNavPills.defaultProps = {
	active: 0,
	color: 'primary',
}

CustomNavPills.propTypes = {
	classes: PropTypes.object.isRequired,
	// index of the default active pill
	active: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			tabIcon: PropTypes.func,
			tabContent: PropTypes.node,
			tabClick: PropTypes.func,
		}),
	).isRequired,
	color: PropTypes.oneOf([
		'primary',
		'warning',
		'danger',
		'success',
		'germiniMainColor',
		'info',
		'rose',
	]),
	horizontal: PropTypes.shape({
		tabsGrid: PropTypes.object,
		contentGrid: PropTypes.object,
	}),
	alignCenter: PropTypes.bool,
}

export default withStyles(customNavPillsStyle)(CustomNavPills)
