import axios from 'axios'

// GET
export const GetPartners = (name = '', cnpj = '', page = 1) =>
	axios.get(`/Partner?name=${name}&cpnj=${cnpj}&page=${page}`).then(response => response.data)

// UPDATE
export const UpdatePartnerData = obj => axios.put(`/Partner/UpdatePartnerData`, obj).then(response => response.data)

export const GetPartnerBranchByPartnerId = (page, pageSize, _name, _status) => {
	let name = _name ? `&name=${name}` : ''
	let status = _status ? `&status=${status}` : ''

	return axios.get(`/Partner/GetPartnerBranchByPartnerId?page=${page}&pageSize=${pageSize}` + name + status).then(response => response.data)
}

export const GetPartnersBranchById = id => axios.get(`/Partner/GetPartnerBranchById?id=${id}`).then(response => response.data)

// POST
export const PostPartnersBranch = obj => axios.post(`/Partner/CreatePartnerBranch`, obj).then(response => response.data)

// UPDATE
export const UpdatePartnersBranch = obj => axios.put(`/Partner/UpdatePartnerBranch`, obj).then(response => response.data)

// ------------
// Para os dados das regras
export const GetCashbackRules = () => axios.get(`/Partner/GetCashbackRules`).then(response => response.data)

export const UpsertPartnerCashbackRules = obj => axios.post(`/Partner/UpsertPartnerCashbackRules`, obj).then(response => response.data)

export const UpdetePartnerCashbackRules = obj => axios.post(`/Partner/UpsertPartnerCashbackRules`, obj).then(response => response.data)

// OK - Para carregar tabela consumidores:
export const GetPartnerConsumers = (name, cpf, pageSize, page) =>
	axios.get(`/Partner/GetPartnerConsumers?Name=${name}&CPF=${cpf}&pageSize=${pageSize}&page=${page}`).then(response => response.data)

// Para carregar regras do consumidor pelo parceiro:
export const GetConsumerCashbackRules = consumerId =>
	axios.get(`/Partner/GetConsumerCashbackRules?consumerId=${consumerId}`).then(response => response.data)

// post
export const UpsertPartnerConsumerCashbackRules = async obj =>
	await axios.post(`/Partner/UpsertPartnerConsumerCashbackRules`, obj).then(response => response.data)

export const PartnerValidatePartner = async ({ password }) => {
	const obj = {
		password,
	}
	return await axios.post(`/Partner/ValidatePartner`, obj).then(response => response.data)
}

export const GetUsersByPartner = async () => {
	return await axios.get(`/Partner/GetUsersByPartner?pageSize=100&page=1`).then(response => response.data)
}

export const GetCreditOriginsByPartner = async obj => {
	return await axios.get(`/Partner/GetCreditOriginsByPartner`, obj).then(response => response.data)
}

export const GetBranchSegment = async () => {
	return await axios.get(`/Partner/GetBranchSegment`).then(response => response.data)
}

export const GetTypeOfGrouper = async () => {
	return await axios.get(`/Enums/GetTypeOfGrouper`).then(response => response)
}

export const StateRegistrationValid = async (ie, stateId) => {
	try {
		return await axios.get(`/Consumer/IsStateRegistrationValid/${ie}/${stateId}`)
	} catch (e) {
		return e?.response
	}
}

export const DeleteAttachmentFromStorageFile = async fileName => {
	return await axios.delete(`/PartnerAttachments/DeleteAttachmentFromStorageFile/${fileName}`)
}
