import axios from 'axios'

export const GetOperationDetail = async bankAccountOperationId => {
	const url = `BankAccount/OperationDetails/${bankAccountOperationId}`

	let res
	try {
		res = await axios.get(url)
	} catch (error) {
		console.error(error)
	}

	return res
}

export const OperationDetails = bankAccountOperationId => {
	return axios.get(`BankAccount/OperationDetails/${bankAccountOperationId}`)
}

export const GetReservedValuesById = async id => {
	const url = `BankAccountReserve/GetByAccountId/${id}`

	let res
	try {
		res = await axios.get(url)
	} catch (error) {
		console.error(error)
	}

	return res
}

export const ReleaseResidualBalance = async body => {
	const url = `BankAccount/Partner/ReleaseResidualBalance`

	let res
	try {
		res = await axios.post(url, body)
	} catch (error) {
		console.error(error)
	}

	return res
}

export const CreateReserve = async body => {
	const url = `BankAccount/Partner/CreateReserve`

	return axios
		.post(url, body)
		.then(res => res)
		.catch(err => err.response)
}

export function GetBank() {
	return axios.get('/BankAccount/GetBanks').then(res => res.data)
}

export const GetBankAccountReserves = () => {
	const url = `BankAccountReserve/GetAllForPartner`

	const res = axios.get(url)

	return res
}

export const GetBankOperations = (bankAccountId, startDate, endDate, operation, status, orderBy, sortBy, pageSize, page) => {
	let url = `/BankAccount/GetOperations`

	if (bankAccountId) {
		url += `&bankAccountId=${bankAccountId}`
	}
	if (startDate) {
		url += `&startDate=${startDate}`
	}
	if (endDate) {
		url += `&endDate=${endDate}`
	}
	if (operation) {
		url += `&operation=${operation}`
	}
	if (status) {
		url += `&status=${status}`
	}
	if (orderBy) {
		url += `&orderBy=${orderBy}`
	}
	if (sortBy) {
		url += `&sortBy=${sortBy}`
	}
	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	const res = axios.get(url)

	return res
}

export const GetAccounts = (name, startDate, endDate, status, pageSize, page) => {
	var query = `?page=${page && page !== '' ? page : '1'}`

	if (name && name !== '') {
		query = query + `&name=${name}`
	}
	if (startDate && startDate !== '') {
		query = query + `&startDate=${startDate}`
	}
	if (endDate && endDate !== '') {
		query = query + `&endDate=${endDate}`
	}
	if (status != null && status !== '') {
		query = query + `&isActive=${status}`
	}
	if (pageSize && pageSize !== '') {
		query = query + `&pageSize=${pageSize}`
	}

	const res = axios
		.get(`/BankAccount${query}`)

		.then(response => response.data)

	return res
}

export function GetAccountById(id) {
	return axios.get(`/BankAccount/${id}`).then(res => res.data)
}

export async function PostBankAccount(body) {
	const res = await axios.post(`/BankAccount`, body)

	return res
}

export const UpdateBankAccount = async (id, body) => {
	axios
		.put(`/BankAccount/${id}`, body)
		.then(res => res)
		.catch(err => err)
}

export const PostFile = obj => {
	const formData = new FormData()
	formData.append('File', obj)
	return axios
		.post(`/Images/UploadFile`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(response => response.data)
}

export const PostAllFiles = async fileList => {
	const formData = new FormData()

	fileList.forEach(obj => {
		formData.append(obj.name, obj)
	})

	return axios
		.post(`/Images/UploadAllFiles`, formData, {
			// headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(response => response)
}

export async function GetBalance(id) {
	return axios.get(`/BankAccount/Balance/${id}`).then(res => {
		return res
	})
}

export async function PostBalance(body) {
	return await axios.post(`/BankAccount/Partner/InsertBalance`, body).then(res => res)
}

export async function GetResidualBalance(id, pageSize, page) {
	let query = `?bankAccountId=${id}&page=${page && page !== '' ? page : '1'}`

	if (!!pageSize && pageSize !== '') {
		query = query + `&pageSize=${pageSize}`
	} else {
		query = query + `&pageSize=5`
	}
	const res = await axios
		.get(`/BankAccount/ResidualBalance${query}`)

		.then(res => res.data)

	return res
}
