import { makeStyles } from '@material-ui/core'
import styled from 'styled-components'

export const IconContainer = styled.div`
	display: flex;
	justify-content: center;
	font-size: 5rem;
`

export const BtnContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 1.4rem;

	i {
		font-size: 14px !important;
	}
`
export const Icon = styled.i`
	color: #f8bb86;
`

export const Form = styled.div`
	display: flex;
	width: 70%;
	margin: 0 auto;
	margin-bottom: 2rem;
`

export const TextModal = styled.p`
	text-align: center;
	font: normal normal normal 16px/40px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
	margin-top: 1rem;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
`

export const getModalStyle = () => {
	const top = 50
	const left = 50

	return {
		top: `${50}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`,
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'column',
	}
}

export const useStyles = makeStyles(theme => ({
	paper: {
		position: 'absolute',
		width: 612,
		minHeight: 302,
		padding: '25px',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[1],
		borderRadius: 10,
	},
}))
