import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
	userData: {},
	userPartnerData: {},
	userPartnerError: false,
	userFormData: {},
	userPost: {},
	userPartnerCreateNewUser: {},
	userUpdate: {},
	userUpdateFailed: false,
	userPostFailed: {},
	userDataFailed: false,
	userListData: [],
	userListDataFailed: false,
	userClaimGroups: [],
	userClaimGroupsFailed: false,
	consumerRegulationRules: false,
	userCpf: {},
	requestPasswordReset: false,
	resetPassword: false,
	resetPasswordFailed: false,
	generalBasicData: [],
	generalBasicDataError: false,
	userPartnerByIdData: null,
	userPartnerByIdError: false,
}

const fetchPartnerUserByIdData = (state, action) => {
	return updateObject(state, {
		userPartnerByIdData: action.userPartnerByIdData,
	})
}

const fetchPartnerUserByIdError = (state, action) => {
	return updateObject(state, {
		userPartnerByIdError: action.userPartnerByIdError,
	})
}

const fetchPartnerUsersData = (state, action) => {
	return updateObject(state, {
		userListData: action.userListData,
	})
}

const fetchPartnerUsersFail = (state, action) => {
	return updateObject(state, {
		userListDataFailed: action.userListDataFailed,
	})
}

const fetchPartnerUserData = (state, action) => {
	return updateObject(state, {
		userData: action.userData,
	})
}

const fetchPartnerUserPartnerData = (state, action) => {
	return updateObject(state, {
		userPartnerData: action.userPartnerData,
	})
}

const fetchPartnerUserPartnerError = (state, action) => {
	return updateObject(state, {
		userPartnerError: action.userPartnerError,
	})
}

const fetchUserFormData = (state, action) => {
	return updateObject(state, {
		userFormData: action.userData,
	})
}

const validateConsumerCpf = (state, action) => {
	return updateObject(state, {
		userCpf: action.validCpf,
	})
}

const putPartnerUserData = (state, action) => {
	return updateObject(state, {
		userUpdate: action.userUpdate,
	})
}
const putPartnerUserDataFailed = (state, action) => {
	return updateObject(state, {
		userUpdateFailed: true,
	})
}
const postPartnerData = (state, action) => {
	return updateObject(state, {
		userPost: action.userPost,
	})
}

const fetchPartnerUserFail = (state, action) => {
	return updateObject(state, {
		userDataFailed: true,
	})
}

const postPartnerFail = (state, action) => {
	return updateObject(state, {
		userPostFailed: action.userPostFailed,
	})
}

const postConsumerRegulationData = (state, action) => {
	return updateObject(state, {
		consumerPost: action.consumerRegulationRules,
	})
}

const postPartnerCreateNewUserData = (state, action) => {
	return updateObject(state, {
		userPartnerCreateNewUser: action.userPartnerCreateNewUser,
	})
}

const RequestPasswordReset = (state, action) => {
	return updateObject(state, {
		requestPasswordReset: action.requestPasswordReset,
	})
}

const ResetPassword = (state, action) => {
	return updateObject(state, {
		resetPassword: action.resetPassword,
	})
}

const ResetPasswordFailed = (state, action) => {
	return updateObject(state, {
		resetPasswordFailed: action.resetPasswordFailed,
	})
}

const fetchGeneralBasicData = (state, action) => {
	return updateObject(state, {
		generalBasicData: action.generalBasicData,
	})
}

const fetchGeneralBasicDataFail = (state, action) => {
	return updateObject(state, {
		generalBasicDataError: action.generalBasicDataError,
	})
}

export function users(state = initialState, action) {
	switch (action.type) {
		case actionTypes.GETALL_REQUEST:
			return {
				loading: true,
			}
		case actionTypes.GETALL_SUCCESS:
			return fetchPartnerUsersData(state, action)
		case actionTypes.GETALL_FAILURE:
			return fetchPartnerUsersFail(state, action)

		case actionTypes.FETCH_PARTNER_USER_BY_ID:
			return fetchPartnerUserByIdData(state, action)
		case actionTypes.FETCH_PARTNER_USER_BY_ID_FAILED:
			return fetchPartnerUserByIdError(state, action)

		case actionTypes.POST_CONSUMER_REGULATION_DATA:
			return postConsumerRegulationData(state, action)
		case actionTypes.FETCH_PARTNER_USER_DATA:
			return fetchPartnerUserData(state, action)
		case actionTypes.FETCH_PARTNER_USER_PARTNER_DATA:
			return fetchPartnerUserPartnerData(state, action)
		case actionTypes.FETCH_PARTNER_USER_PARTNER_ERROR:
			return fetchPartnerUserPartnerError(state, action)
		case actionTypes.GET_SUCCESS:
			return fetchUserFormData(state, action)
		case actionTypes.POST_PARTNER_USER_DATA:
			return postPartnerData(state, action)
		case actionTypes.POST_PARTNER_NEW_USER_DATA:
			return postPartnerCreateNewUserData(state, action)
		case actionTypes.VERIFY_CPF:
			return validateConsumerCpf(state, action)

		case actionTypes.PUT_PARTNER_USER_DATA:
			return putPartnerUserData(state, action)
		case actionTypes.PUT_PARTNER_USER_DATA_FAILED:
			return putPartnerUserDataFailed(state, action)
		case actionTypes.POST_PARTNER_USER_FAILED:
			return postPartnerFail(state, action)
		case actionTypes.FETCH_PARTNER_USER_FAILED:
			return fetchPartnerUserFail(state, action)

		case actionTypes.GET_CLAIN_USERS_SUCCESS:
			return updateObject(state, {
				userClaimGroups: action.userClaimGroups,
			})
		case actionTypes.GET_CLAIN_USERS_FAILURE:
			return updateObject(state, {
				userClaimGroupsFailed: action.userClaimGroupsFailed,
			})
		case actionTypes.REQUEST_PASSWORD_RESET:
			return RequestPasswordReset(state, action)
		case actionTypes.RESET_PASSWORD:
			return ResetPassword(state, action)
		case actionTypes.RESET_PASSWORD_FAILED:
			return ResetPasswordFailed(state, action)

		case actionTypes.GENERAL_BASIC_DATA:
			return fetchGeneralBasicData(state, action)
		case actionTypes.GENERAL_BASIC_DATA_FAILED:
			return fetchGeneralBasicDataFail(state, action)

		default:
			return state
	}
}
