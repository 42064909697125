import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { Box, Stack, Tooltip } from '@mui/material'
import { FormatMoney, FormatPoints } from '../../../Functions'
import { createData } from '../../../../CalculationMemory'
import { FormarPointsValue } from '../../../../../../../../../../../utils/utils'

const GenerationPointsInfo = ({ parameterization, setOpen }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<Stack
				height="76px"
				alignItems="center"
				gap="4rem"
				spacing={2}
				width="100%"
				display="flex"
				direction="row"
				padding="10px 13px"
				className="stack-info"
			>
				<Box className="box-info">
					<S.Title>{t('Taxa de resgate (R$)')}</S.Title>

					<S.Value>{FormatMoney(parameterization?.customValueTax || 0)}</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('Valor em Seeds')}</S.Title>

					{/* <S.Value>{FormatPoints(parameterization?.customPointsValue || 0)}</S.Value> */}
					<S.Value>{FormarPointsValue(parameterization?.customPointsValue || 0)}</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('A receber')}</S.Title>

					<S.Value>{FormarPointsValue(parameterization?.customValueReceivable || 0)}</S.Value>
				</Box>
			</Stack>
			<Tooltip arrow placement="top" title={t('Memória de cálculo')}>
				<Box
					className="stack-info-icon"
					onClick={() =>
						setOpen({
							active: true,
							row: [
								createData('Valor do produto em Seeds', FormatPoints(parameterization?.customPointsValue || 0)),
								createData('Valor do produto em Reais', FormatMoney(parameterization?.calculationMemory?.productValue || 0)),
								createData('Taxa de resgate', FormatMoney(parameterization?.calculationMemory?.redemptionFee || 0)),
								createData('Taxa de resgate aplicada', FormatMoney(parameterization?.customValueTax || 0)),
								createData('Valor a receber', FormatPoints(parameterization?.customValueReceivable || 0)),
							],
						})
					}
				>
					<i style={{ fontSize: '1.5rem', color: '#1A9CAC' }} className="icon-bot_memoria_calculo" />
				</Box>
			</Tooltip>
		</S.Container>
	)
}

GenerationPointsInfo.propTypes = {
	setOpen: PropTypes.func,
	productData: PropTypes.object,
	parameterization: PropTypes.array,
	typeOfRedeem: PropTypes.string,
}

GenerationPointsInfo.defaultProps = {
	parameterization: {
		standardAdministrationFeeApplied: '',
		standardGrossValue: '',
		customGrossValue: '',
	},
}

export default GenerationPointsInfo
