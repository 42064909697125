import { Modal, Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const ModalContainer = styled(Modal)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100%;
`
export const ModalTypography = styled(Typography)`
	font-size: 0.875rem !important;
	white-space: nowrap;
	//transform: translate(-151px, 19px);
`

export const PaperContainer = styled(Paper)`
	position: relative;
	max-width: ${({ maxwidth }) => maxwidth};
	min-width: 900px;
	padding: ${({ padding }) => padding};

	&.MuiPaper-elevation1 {
		box-shadow: ${({ shadow }) => shadow};
	}
	.sub-info {
		color: #48484c;
		font-size: 0.9rem;
		font-weight: bold;
	}

	.info-geral {
		color: #31394d;
		font-weight: bold;
		font-size: 1.3rem;
		> div {
			margin-right: 5px;
		}
	}

	.blue {
		color: red;
	}

	.close-button {
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: none;
		color: #F05B4F;
		&:hover {
			opacity: 0.7;
		}
		svg {
			margin-right: 3px;
		}
	}

	.sub-total {
		color: #31394d;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
	}

	.sub-price {
		color: #49d489;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 18px;
	}
	.button-box {
		position: absolute;
		margin-top: -28px;
		top: 0;
		margin-right: -28px;
		right: 0;
		.MuiIconButton-label {
			background-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.backgroundColor};
			border-radius: 50%;
			.MuiIcon-colorPrimary {
				color: #fff;
				font-size: 2rem;
			}
		}
	}
`

export const Title = styled.div`
	align-items: center;
	font-size: 16px;
	display: flex;
	padding: 0;
	white-space: nowrap;
	font-weight: bold;
	overflow: hidden;
`

export const SubBox = styled.div`
	transform:${({ subTitle }) => subTitle ? 'translateY(13px)' : 'translateY(2px)'};
`
