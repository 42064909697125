export default function convertSegmentsToId(list = [], segmentsList = []) {
	const result = []

	list.forEach(item => {
		segmentsList.forEach(segment => {
			if (item === segment.description) result.push(segment.id)
		})
	})

	return result ?? []
}
