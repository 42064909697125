import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`
export const Content = styled.div`
	display: flex;
	width: 100%;
	max-width: 100px;
	justify-content: flex-end;
	transform: translateY(-40px);
`
