import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import _ from 'lodash'

import { GetCurrentPartnerBase } from '../redux/api/user'
import { GetCurrentPartner } from '../redux/api/productPage'
import Cookies from 'js-cookie'

const GeneralContext = createContext({})

export const GeneralProvider = ({ children }) => {
	const [edit, setEdit] = useState(false)
	const [img, setImg] = useState(false)
	const [purchase, setPurchase] = useState([])
	const [settings, setSettings] = useState({})
	const [selectOrdination, setOrdination] = useState({})
	const [activePay, setActivePay] = useState(false)
	const [activeModules, setActiveModules] = useState(null)
	const [isLoaded, setIsLoaded] = useState(false)
	const [grouper, setGrouper] = useState(null)
	const [isSucess, setIsSucess] = useState({
		open: false,
		color: '',
		icon: '',
		message: '',
	})

	const [modules, setModules] = useState([])

	useEffect(() => {
		if (window.location.pathname !== '/auth/login') {
			GetCurrentPartner()
				.then(response => {
					if (response.benefitGrouper) {
						setGrouper(response.benefitGrouper)
						setGrouperInStorage(response.benefitGrouper)
					}

					Cookies.set('IsBranch', response?.branches?.length > 0)
				})
				.catch(error => {
					console.error('Erro ao obter o parceiro atual:', error)
				})
		}
	}, [])

	const setGrouperInStorage = Grouper => {
		localStorage.setItem('Grouper', Grouper)
		Cookies.set('Grouper', Grouper || '')
	}

	const GerminiPayActive = modules => {
		if (modules && modules?.length > 0) {
			const germiniPay = modules.find(module => module?.additionalModule?.name === 'GerminiPay')
			if (germiniPay) {
				setActivePay(germiniPay.isActive)
				return
			}
			setActivePay(false)
		}
		setActivePay(false)
	}

	const getCurrentPartnerBase = async () => {
		try {
			const getCurrentPartner = await GetCurrentPartnerBase()
			if (!_.isEmpty(getCurrentPartner)) {
				GerminiPayActive(getCurrentPartner?.additionalModules || [])
				setModules(getCurrentPartner?.additionalModules || [])

				setActiveModules(getCurrentPartner?.isAdditionalModulesActive)
				Cookies.set('partnerId', getCurrentPartner.id)
			}

			return getCurrentPartner
		} catch (error) {
			console.log(error)
		}
	}

	useEffect(() => {
		const timer = setInterval(async () => {
			if (window.location.pathname !== '/auth/login') {
				getCurrentPartnerBase()
			}
		}, 20000)
		return () => clearInterval(timer)
	}, [])

	return (
		<GeneralContext.Provider
			value={{
				isSucess,
				setIsSucess,
				img,
				setImg,
				edit,
				setEdit,
				purchase,
				setPurchase,
				settings,
				setSettings,
				selectOrdination,
				setOrdination,
				activePay,
				setActivePay,
				activeModules,
				setActiveModules,
				isLoaded,
				setIsLoaded,
				grouper,
				setGrouper,
				modules,
				getCurrentPartnerBase,
			}}
		>
			{children}
		</GeneralContext.Provider>
	)
}

GeneralProvider.propTypes = {
	children: PropTypes.node.isRequired,
}

export default GeneralContext
