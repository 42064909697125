import styled from 'styled-components'
import Box from '@mui/material/Box'
import Card from 'components/Card/Card.jsx'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

export const CustomCard = styled(Card)`
	margin: 0 0 0 15px;
	.MuiIcon-root {
		margin-left: 4px;
		font-size: 30px;
		transform: rotateY(180deg);
	}
`

export const BodyCard = styled.div`
	margin-top: 1rem;
`

export const CustomBoxModal = styled(Box)`
	background-color: #fff;
	box-shadow: 24;
	border-radius: 7px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400;
	padding: 25px;

	img {
		width: 65px;
	}

	.MuiFormControlLabel-root {
		margin-right: 35px;
	}
`

export const BodyModal = styled.div`
	display: flex;
	flex-wrap: wrap;

	.MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: #ffffff !important;
			background-color: ${getThemeColorFromLocalStorage()?.navigation
				?.buttons?.backgroundColor};
		}
	}
	.MuiAutocomplete-option {
		background-color: #ffffff;
	}
`
export const ModalButtons = styled.div`
	border-top: 1px solid #bbbbbb;
	display: flex;
	justify-content: end;
	margin-top: 18px;
`

export const BoxIcon = styled.div`
	display: flex;
	.MuiIcon-root {
		color: ${getThemeColorFromLocalStorage()?.visual?.icons?.color};
		font-size: 35px;
		width: auto;
		height: auto;
		transform: rotateY(180deg);
	}
`
export const Titles = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 0 0 15px;

	span {
		color: ${getThemeColorFromLocalStorage()?.titles?.main?.textColor};
		font: normal normal bold 18px/18px Roboto;
	}

	p {
		font: normal normal normal 13px/20px Roboto;
	}
`

export const Authorizer = styled.div`
	align-items: center;
	border: 1px solid
		${getThemeColorFromLocalStorage()?.titles?.main?.textColor};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	margin-top: 6px;
	padding: 15px;
	width: 128px;

	span {
		color: #333333;
		font: normal normal normal 15px/15px Roboto;
	}

	img {
		margin-top: 5px;
	}
`

export const AuthorizerConnects = styled.div`
	display: flex;
	flex-direction: column;

	p {
		font: normal normal normal 14px/20px Roboto;
	}

	img {
		width: 85px;
	}
`

export const TitlesPaymentOptions = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 30px;

	span {
		font: normal normal bold 16px/16px Roboto;
		color: ${getThemeColorFromLocalStorage()?.titles?.main?.textColor};
	}
`

export const PaymentOptions = styled.div`
	margin-bottom: 25px;
	img {
		border-radius: 9px;
	}

	.MuiToggleButtonGroup-root {
		display: flex;
		flex-wrap: wrap;
	}

	.MuiToggleButton-root {
		cursor: pointer;
		border: none;
		background-color: #efefef;
		border-radius: 9px;
		box-shadow: 6px 6px 1px rgba(214, 214, 214);
		margin: 0 15px 15px 0;
		opacity: 1;
		padding: 0px;
		outline: none;
		height: 65px;
		transition: all 0.3s ease 0s;
		width: 90px;
		&:hover {
			box-shadow: none;
		}
	}

	.MuiToggleButton-root.Mui-selected {
		background-color: #bdbfc1;
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0);
		&:hover {
			background-color: #ccced1;
		}
	}

	.MuiToggleButtonGroup-root
		.MuiToggleButtonGroup-grouped:not(:last-of-type) {
		border-radius: 9px;
		&:hover {
			box-shadow: none;
		}
	}

	.MuiToggleButtonGroup-root
		.MuiToggleButtonGroup-grouped:not(:first-of-type) {
		border-radius: 9px;
	}

	p {
		font: normal normal bold 18px/60px Roboto;
		letter-spacing: 0px;
		color: #505050;
	}
`
