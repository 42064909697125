
export const extractProductIdFromUrl = () => {
    const pathname = window.location.pathname;
    const parts = pathname.split('/');
    return parts[parts.length - 1];
};

function getUserIdFromLocalStorage() {
    if (localStorage.getItem('user')) {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user && user.id) {
            return user.id;
        }
    }
    return null;
}

export const userId = getUserIdFromLocalStorage();