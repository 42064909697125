import React, { useMemo } from 'react'

// @Helpers
import _ from 'lodash'
import Cookies from 'js-cookie'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// @Components
import * as S from './styles'

// @ HookForm
import { Controller } from 'react-hook-form'

// @MUI
import { Grid, Tooltip } from '@mui/material'

// @ Functions
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'

// @ Styles
import { CustomButton } from '../ListGrouper/styles'
import { LabelOptions, getLabel } from '../../utils'
import { Create } from '../ListGrouper/Functions'

function RegisterGrouper({ form, setLoading, setSnackStatus, tabIndex, tableRef }) {
	const { t } = useTranslation()
	const partnerId = String(Cookies.get('partnerId'))

	const themeColor = useMemo(() => getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, [])

	const { handleSubmit, control, watch, reset } = form

	const onSubmitFilter = async obj => {
		setLoading(true)
		try {
			const data = {
				name: obj.description,
				partnerId: partnerId,
			}
			const response = await Create({ data, tabIndex })

			if (response?.status === 200) {
				const { data } = response
				if (data?.errors?.length > 0) {
					setSnackStatus({
						open: true,
						text: t(data?.errors[0].message),
						severity: 'error',
					})
					return
				}
				if (data?.success) {
					reset()
					setSnackStatus({
						open: true,
						text: `${LabelOptions({ tabIndex, t })} adicionada com sucesso!`,
						severity: 'success',
					})
					tableRef.current && tableRef.current.onQueryChange()
					return
				}
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Grid container style={{ marginTop: '22px' }}>
			<Grid item xs={12} sm={12} md={6} lg={6} style={{ display: 'flex', alignItems: 'center' }}>
				<S.AddProduct onSubmit={handleSubmit(onSubmitFilter)} autoComplete="off">
					<Controller
						render={({ onChange, value }) => (
							<S.CssTextField
								style={S.FieldsStyled}
								onChange={onChange}
								value={value}
								size="small"
								name="description"
								fullWidth
								label={getLabel({ tabIndex, t })}
								variant="outlined"
								margin="dense"
								InputProps={{
									inputProps: {
										maxLength: 150,
									},
								}}
							/>
						)}
						name="description"
						control={control}
						defaultValue=""
					/>
					<Tooltip arrow placement="top" title={t('BUTTON_ADD')} disableHoverListener={_.isEmpty(watch('description'))}>
						<CustomButton
							bg="#F2F2F2"
							type="submit"
							width={'44px'}
							border="#F2F2F2"
							color={themeColor}
							hoverBg={themeColor}
							disabled={_.isEmpty(watch('description'))}
						>
							<i className="icon-item_selecionar" />
						</CustomButton>
					</Tooltip>
				</S.AddProduct>
			</Grid>
		</Grid>
	)
}

RegisterGrouper.propTypes = {
	form: PropTypes.func,
	setLoading: PropTypes.func,
	setSnackStatus: PropTypes.func,
	tabIndex: PropTypes.number,
	tableRef: PropTypes.object,
}

export default RegisterGrouper
