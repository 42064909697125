import * as Yup from 'yup'
import _ from 'lodash'
import { GetTypeOfCashback } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import { GetCalculateCashback, UpdateProduct } from '../../../../../../../../../redux/api/product'
import {envs}  from '../../../../../../../../../redux/api/windowServerData'



export const TypeOfCashback = async ({ setGenerationType, setCircularProgress }) => {
	setCircularProgress(true)
	try {
		const res = await GetTypeOfCashback()
		if (res && typeof res === 'object') {
			const newRes =
				res
					?.map(item => {
						if (item?.key === '0') {
							return null
						}
						if (item?.key === '1') {
							return {
								...item,
								value: 'Não gerar cashback',
							}
						}

						if (item?.key === '2') {
							return {
								...item,
								value: 'Padrão',
							}
						}

						if (item?.key === '3') {
							return {
								...item,
								value: 'Percentual',
							}
						}

						if (item?.key === '4') {
							return {
								...item,
								value: 'Fixo',
							}
						}

						return item
					})
					?.filter(item => item !== null) || []

			setGenerationType(newRes.filter(item => item) || [])
		}
	} catch (error) {
		setGenerationType([])
	} finally {
		setCircularProgress(false)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const FormatMoney = value => {
	// const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', symbol)
}

export const handleProductDataUpdate = (productData, reset, setDefaultType) => {
	if (!_.isEmpty(productData)) {
		const { cashbackPercentual, typeOfCashback } = productData

		if (typeOfCashback?.key === '1') {
			typeOfCashback.value = 'Não gera cashback'
		}

		if (typeOfCashback?.key === '2') {
			typeOfCashback.value = 'Padrão'
		}

		if (typeOfCashback?.key === '3') {
			typeOfCashback.value = 'Percentual'
		}

		setDefaultType({
			typeOfCashback: typeOfCashback,
			cashbackPercentual: cashbackPercentual,
		})
		reset({
			typeOfCashback: typeOfCashback,
			cashbackPercentual: cashbackPercentual,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '4':
				// return localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				// return envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
		}
	} catch (error) {
		return ''
	}
}

export const handleCalculateValue = async (price, type, value, setCustomCashbackValue) => {
	try {
		const res = await GetCalculateCashback(price, type, value)
		if (res?.success && res?.data) {
			setCustomCashbackValue(res.data)
		}
		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationProduct = async (productData, typeOfCashback, cashbackPercentual, setSnackStatus, typeOfBenefitCashback) => {
	const obj = {
		id: productData?.id,
		price: productData?.price,
		name: productData?.name,
		generatePoints: productData?.typeOfPoints?.key != '0' ? true : false,
		typeOfBenefitPoints: productData?.typeOfBenefitPoints?.key,
		typeOfPoints: productData?.typeOfPoints?.key,
		cashbackPercentual: cashbackPercentual,
		pointsValue: productData?.pointsValue || 0,
		typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key,
		typeOfRedeem: productData?.typeOfRedeem?.key,
		redemptionPoints: productData?.redemptionPoints || 0,
		typeOfBenefitCashback: typeOfBenefitCashback?.key,
		typeOfCashback: typeOfCashback?.key,
	}

	try {
		const res = await UpdateProduct(obj?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				type: 'success',
			})
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				message: res?.data?.errors[0]?.message,
				type: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}

export const Schema = Yup.object().shape({
	typeOfCashback: Yup.object()
		.nullable()
		.required('Campo obrigatório'),

	cashbackPercentual: Yup.string()
		.nullable()
		.when('typeOfCashback', {
			is: value => value?.key >= '3',
			then: Yup.string()
				.nullable()
				.test('cashbackPercentual', 'O valor deve ser maior que 0', value => {
					return value > 0
				})
				.required('Campo obrigatório'),
		}),
})
