import axios from 'axios'
import {envs}  from '../../api/windowServerData'


export const getAllPurchasers = async () => {
	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllPurchasers`)
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetAllPurchasers`)
		return response
	} catch (error) {
		console.log(error)
	}
}

export const getPurchaser = async id => {
	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/api/PartnerSettings/GetPurchaser/${id}`)
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/api/PartnerSettings/GetPurchaser/${id}`)

		return response
	} catch (error) {
		console.log(error)
	}
}

export const getPurchaserByStatus = async status => {
	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaserByStatus/${status}`)
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaserByStatus/${status}`)

		return response
	} catch (error) {
		console.log(error)
	}
}

export const getStatusByPurchaser = async purchaser => {
	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetTransactionStatusByPurchaser/${purchaser}`)
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetTransactionStatusByPurchaser/${purchaser}`)

		return response
	} catch (error) {
		console.log(error)
	}
}

export const createSelectedPurchaser = async obj => {
	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreateSelectedPurchaser`, {
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreateSelectedPurchaser`, {
			method: 'POST',
			data: {
				partnerId: obj?.partnerId,
				purchaser: {
					id: obj?.id,
					name: obj?.name,
					type: obj?.type,
					connection: obj?.connection,
					paymentMethods: obj?.paymentMethods,
				},
				priority: obj?.priority,
			},
		})

		return response
	} catch (error) {
		console.log(error)
	}
}
export const createPartnerSettings = async obj => {
	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreatePartnerSettings`, {
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/CreatePartnerSettings`, {
			method: 'POST',
			data: obj,
		})
		return response
	} catch (error) {
		console.log(error)
	}
}

export const GetPurchaser = async id => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaser/${id}`)
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPurchaser/${id}`)
	} catch (error) {
		console.log(error)
	}
}

export const GetPartnerSettings = async id => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPartnerSettings`)
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/GetPartnerSettings`)
	} catch (error) {
		console.log(error)
	}
}

export const GetAllPartnerLogs = async obj => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetCurrentPartnerLogs`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetCurrentPartnerLogs`, {
			method: 'POST',
			data: obj,
		})
	} catch (error) {
		console.log(error)
	}
}

export const ExportLog = async rowData => {
	const { name, extension, id } = rowData
	const ref = window.location.href
	console.log('url', url)

	const url = `/Log/ExportLog/${id}`

	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}${url}`, {
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}${url}`, {
			method: 'POST',
			responseType: 'blob',
		})

		if (response.status === 200) {
			const newData = response.data

			const downloadExtension = extension === 'excel' ? 'xls' : extension
			const downloadName = `${name}.${downloadExtension}`

			const url = window.URL.createObjectURL(new Blob([newData]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', downloadName)

			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
		}
	} catch (error) {
		console.log(error)
	}
}

export const GetExportingLogsHistory = async obj => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetExportingLogsHistory`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetExportingLogsHistory`, {
			method: 'POST',
			data: obj,
		})
	} catch (error) {
		console.log(error)
	}
}

export const DeleteExportLog = async id => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/DeleteExportLog/${id}`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/DeleteExportLog/${id}`, {
			method: 'DELETE',
		})
	} catch (error) {
		console.log(error)
	}
}

export const GetAllTransactionLogs = async obj => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetCurrentTransactionLogs`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetCurrentTransactionLogs`, {
			method: 'POST',
			data: obj,
		})
	} catch (error) {
		console.log(error)
	}
}

export const GetAllTransactionStatus = async status => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetAllTransactionStatus`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/GetAllTransactionStatus`, {
			method: 'GET',
			data: status,
		})
	} catch (error) {
		console.log(error)
	}
}

export const ExportAllPartnerLogs = async obj => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/ExportAllPartnerLogs`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/ExportAllPartnerLogs`, {
			method: 'POST',
			data: obj,
			responseType: 'blob',
		})
	} catch (error) {
		console.log(error)
	}
}

export const ExportAllTransactionLogs = async obj => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/ExportAllTransactionLogs`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/Log/ExportAllTransactionLogs`, {
			method: 'POST',
			data: obj,
			responseType: 'blob',
		})
	} catch (error) {
		console.log(error)
	}
}

export const ValidatePurchaserCredentials = async (id, partnerId, obj) => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ValidatePurchaserCredentials/${id}`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ValidatePurchaserCredentials/${id}`, {
			method: 'POST',
			data: obj,
		})
	} catch (error) {
		console.log(error)
	}
}

export const DisablePartnerSettings = async obj => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/DisablePartnerSettings`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/DisablePartnerSettings`, {
			method: 'PUT',
			data: obj,
		})
	} catch (error) {
		console.log(error)
	}
}

export const UpdatePaymentSettings = async (id, obj) => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/UpdatePaymentSettings`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/UpdatePaymentSettings`, {
			method: 'PUT',
			data: obj,
		})
	} catch (error) {
		console.log(error)
	}
}

export const ChangeSelectedPurchaser = async obj => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ChangeSelectedPurchaser`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/ChangeSelectedPurchaser`, {
			method: 'PUT',
			data: obj,
		})
	} catch (error) {
		console.log(error)
	}
}

export const SaveToExportTransactionLogs = async (FileName, FileType, SendEmail, tab, values) => {
	console.log(values)
	const url = tab === 0 ? '/Log/SaveToExportCurrentTransactionLogs' : '/Log/SaveToExportCurrentPartnerLogs'
	try {
		// const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}${url}?Format=${FileType}&Name=${FileName}`, {
		const response = await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}${url}?Format=${FileType}&Name=${FileName}`, {
			method: 'POST',
			data: {
				id: values?.id || '',
				partnerId: values?.partnerId || '',
				userId: values?.userId || '',
				userName: values?.userName || '',
				action: values?.action || '',
				success: values?.success || true,
				amount: values?.amount || 0,
				status: values?.status || [''],
				transactionId: values?.transactionId || '',
				paymentMethod: values?.paymentMethod || '',
				purchaserName: values?.purchaserName || '',
				description: values?.description || '',
				initialCreated: values?.initialCreated ? values?.initialCreated : new Date(),
				finalCreated: values?.finalCreated ? values?.finalCreated : new Date(),
				orderByDescending: true,
				pageSize: values?.pageSize || 0,
				page: values?.page || 0,
			},
		})
		return response
	} catch (error) {
		console.log(error)
	}
}

export const RemoveSelectedPurchaser = async () => {
	try {
		// return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/RemoveSelectedPurchaser`, {
		return await axios(`${envs.REACT_APP_PAYMENT_GATEWAY_URL}/PartnerSettings/RemoveSelectedPurchaser`, {
			method: 'DELETE',
		})
	} catch (error) {
		console.log(error)
	}
}
