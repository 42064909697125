import React, { useContext, useState, createContext, useEffect } from 'react'
import {
	GetDigitalWalletRules,
	GetGeneralRulesData,
} from '../../redux/api/generalRules'
const AwardContext = createContext()
export const AwardProvider = ({ children }) => {
	const [optionMenssage, setOptionMenssage] = useState('')
	const [openMsgSucess, setOpenMsgSucess] = useState(false)
	const [radioValue, setRadioValue] = useState('batch')

	const [validatedReversal, setValidatedReversal] = useState(false)
	const [displayedTab, setDisplayedTab] = useState(1)
	const [token, setToken] = useState(localStorage.getItem('token'))
	const [typesFilterAwardProc, setTypesFilterAwardProc] = useState([])
	const [typesFilterAwardRev, setTypesFilterAwardRev] = useState([])
	const [idDetailProcessed, setIdDetailProcessed] = useState(
		localStorage.getItem('idDetailProcessed'),
	)
	const [GetGeneral, setGetGeneral] = useState({})
	const [idReversalProcessald, setIdReverseProcessed] = useState(
		localStorage.getItem('idDetailProcessed'),
	)

	const [allowDigitalWallet, setAllowDigitalWallet] = useState(false)
	const [allowAwards, setAllowAwards] = useState(false)

	useEffect(() => {
		GeneralRules()
	}, [])

	const GeneralRules = async () => {
		try {
			const date = await GetGeneralRulesData()

			setGetGeneral(date)
			setAllowDigitalWallet(date.allowDigitalWallet)
			setAllowAwards(date.allowAwards)
		} catch (error) {
			console.log(error)
		}
	}
	const [valueTypeFilter, setValueTypeFilter] = useState({})
	return (
		<AwardContext.Provider
			value={{
				radioValue,
				setRadioValue,
				validatedReversal,
				setValidatedReversal,
				displayedTab,
				setDisplayedTab,
				token,
				setToken,
				idDetailProcessed,
				setIdDetailProcessed,
				valueTypeFilter,
				setValueTypeFilter,
				typesFilterAwardProc,
				setTypesFilterAwardProc,
				typesFilterAwardRev,
				setTypesFilterAwardRev,
				optionMenssage,
				setOptionMenssage,
				openMsgSucess,
				setOpenMsgSucess,
				idReversalProcessald,
				setIdReverseProcessed,
				allowDigitalWallet,
				allowAwards,
				GetGeneral,
				GeneralRules,
			}}
		>
			{children}
		</AwardContext.Provider>
	)
}
export const useAward = () => useContext(AwardContext)
