import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'

import { Grid, Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import TextInput from 'components/TextInput'
import { ButtonBack } from 'components/NewButton/styles'
import { ButtonConfirm } from 'components/NewButton/styles'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import { ModalContainer, PaperContainer } from './styles'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const textType = [
	{
		text: 'Deseja realmete excluir este item?',
		type: 'delete',
	},
	{
		text: 'Ao remover os registros selecionados, o sistema irá ignorar a distribuição. Deseja continuar?',
		type: 'cancel',
	},
	{
		text: 'Para prosseguir com a exclusão é necessário informar a sua senha.',
		type: 'confirmDelete',
	},
]

export const validationSchema = Yup.object().shape({
	password: Yup.string().required('Senha obrigatória.'),
})

function NewConfirmModal({
	children,
	className,
	closeByClick,
	error,
	maxWidth,
	minWidth,
	onCancel,
	onConfirm,
	onDelete,
	open,
	padding,
	parameter,
	password,
	shadow,
	standardFocus,
	t,
	text,
	title = 'Confirmar',
	type,
}) {
	const { handleSubmit, control } = useForm({ validationSchema })
	const [displayText, setDisplayText] = useState({})
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		ModalType(type)
	}, [type, text, parameter, error, ModalType])

	function ModalType(type) {
		if (type) {
			if (!text) {
				return textType.forEach((item) => {
					if (item.type === type) {
						setDisplayText(item.text)
					}
				})
			}
			return setDisplayText(text)
		}
		if (text) {
			return setDisplayText(text)
		}
		setDisplayText('Deseja Continuar?')
	}

	function confirmAction() {
		if (type == 'delete') return (type = 'confirmDelete')
		onConfirm()
	}

	async function removeForm(value) {
		onDelete(value)
	}

	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			minwidth={minWidth}
			disableEnforceFocus
		>
			{open && type !== 'confirmDelete' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '30px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className={
										type === 'cancel' || type === 'Close'
											? 'icon-alerta_modal'
											: 'icon-grid_reprovado'
									}
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '13px',
									fontWeight: 'bold',
									color: '#333333',
									opacity: '1',
								}}
							>
								{text ? (
									text
								) : (
									<p>
										Ao remover os registros selecionados, o
										sistema irá ignorar a distribuição de
										prêmios para estes registros.
										<br />
										Deseja continuar?
									</p>
								)}
							</div>
						</GridItem>
						<GridContainer
							style={{
								padding: '10px 0 0 20px',
								marginBottom: '30px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{ textAlign: 'center' }}
							>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'yes'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginRight: '15px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onCancel}
								>
									{t('BUTTON_NO')}
								</Button>
								<Button
									size="sm"
									variant="contained"
									style={{
										marginLeft: '15px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									color={
										standardFocus.toString() === 'no'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									onClick={onConfirm}
								>
									{t('BUTTON_YES')}
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : (
				//PASSOWRD MODAL
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={'0px 60px'} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '50px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{ fontSize: '15px', fontWeight: 'bold' }}
							>
								{displayText}
							</div>
						</GridItem>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(removeForm)}
						>
							<GridContainer style={{ marginTop: '30px' }}>
								<Grid
									item
									xs={10}
									style={{
										position: 'relative',
										left: '60px',
									}}
								>
									<Controller
										as={
											<TextInput
												style={{ marginRight: '50px' }}
												label="Senha *"
												type="password"
											/>
										}
										defaultValue=""
										name="password"
										control={control}
									></Controller>
								</Grid>
							</GridContainer>
							<div
								style={{
									position: 'relative',
									left: '45px',
									color: '#F05B4F',
									fontSize: '0.80rem',
									fontWeight: '400',
									fontFamily:
										'Roboto, Helvetica, Arial, sans-serif',
								}}
							>
								{t(error ? error : '')}
							</div>
							<GridContainer
								style={{
									padding: '10px 0 0 20px',
									marginBottom: '50px',
									marginTop: '35px',
								}}
							>
								<GridItem
									xs={12}
									sm={12}
									style={{ textAlign: 'center' }}
								>
									<ButtonConfirm
										themeColor={themeColor}
										color="primary"
										style={{ marginRight: 10 }}
										startIcon={
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													fontSize: '14px',
												}}
											>
												<i
													className={
														'icon-ico_cancelar'
													}
													style={{ fontSize: '18px' }}
												/>
											</div>
										}
										onClick={() => {
											onCancel()
										}}
									>
										Cancelar
									</ButtonConfirm>

									<ButtonBack
										type="submit"
									// onClick={() => {
									//     onConfirm()
									//     // setDisplayError("")
									// }}
									>
										{title}
									</ButtonBack>
								</GridItem>
							</GridContainer>
						</form>
					</PaperContainer>
				</Fade>
			)}
		</ModalContainer>
	)
}

NewConfirmModal.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	standardFocus: PropTypes.string,
	text: PropTypes.string,
	password: PropTypes.string,
	parameter: PropTypes.string,
	type: PropTypes.string,
	error: PropTypes.string,
	onDelete: PropTypes.func,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
}

NewConfirmModal.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	standardFocus: 'no',
	children: null,
	open: false,
	closeByClick: null,
	onDelete: null,
	maxWidth: '',
	minWidth: '',
	text: '',
	parameter: '',
	password: '',
	error: '',
	type: '',
	onConfirm: null,
	onCancel: null,
}

export default withTranslation()(NewConfirmModal)
