import axios from 'axios'
import Cookies from 'js-cookie'

export const GetAllBrands = async (pageSize, page, orderBy, OrderByDesc, name) => {
	const partnerId = String(Cookies.get('partnerId'))
	let url = '/Brand/GetAllBrands'

	if (partnerId) {
		url += `&id=${partnerId}`
	}

	if (name) {
		url += `&name=${name}`
	}

	if (orderBy) {
		url += `&orderBy=${orderBy}`
	}

	if (OrderByDesc === true || OrderByDesc === false) {
		url += `&OrderByDesc=${OrderByDesc}`
	}
	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	return axios.get(url).then(response => response.data)
}

export const DeleteBrand = async id => {
	const url = `/Brand/DeleteBrand/${id}`
	return axios.delete(url).then(response => response)
}

export const CreateBrand = async obj => {
	const url = `/Brand/CreateBrand`
	return axios.post(url, obj).then(response => response)
}
