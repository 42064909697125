import React, { Fragment } from 'react'
import { Tooltip } from '@material-ui/core'
import { Indicator } from './SubtitleItem'

const Color = {
	CostCenter: <Indicator className="indicator" Color="#3EC450" />,
	BusinessUnit: <Indicator className="indicator" Color="#FB9006" />,
	Branch: <Indicator className="indicator" Color="#7E6BB9" />,
	Department: <Indicator className="indicator" Color="#1BBED3" />,
}

export const Columns = t => [
	{
		title: '',
		field: 'value',
		sorting: false,
		cellStyle: {
			width: '2px',
			margin: '0px',
			padding: '0px',
		},
		headerStyle: {
			width: '2px',
			marginLeft: '0px',
			paddingLeft: '0px',
		},
		// eslint-disable-next-line react/display-name
		render: rowData => (
			<Fragment>
				<Tooltip arrow id="tooltip-top" placement="top-center" title={t(rowData?.segmentationType?.value || '')}>
					{Color[(rowData?.segmentationType?.value)]}
				</Tooltip>
			</Fragment>
		),
	},
	{
		title: t('Nome'),
		field: 'name',
		width: '40%',
		cellStyle: {
			width: '60%',
			textAlign: 'left',
		},
		headerStyle: {
			width: '60%',
			textAlign: 'left',
		},
	},
	{
		title: t('Divisão de negócio'),
		field: 'name',
		width: '40%',
		cellStyle: {
			width: '40%',
			textAlign: 'left',
		},
		headerStyle: {
			width: '40%',
			textAlign: 'left',
		},
		render: rowData => t(rowData.segmentationType?.value || ''),
	},
]
