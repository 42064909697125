import React, { Fragment, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import * as S from './styles'

// MATERIAL
import Card from '@mui/material/Card'
import { CardContent, Icon } from '@mui/material'

import GeneralContext from '../../../../GeneralContext'
import { GetPartnerSettings } from '../../../../redux/api/paymentGateway'
import Cookies from 'js-cookie'

export const CardServicesLogs = ({ childToParent }) => {
	const { t } = useTranslation()
	const { activeModules, activePay, setActivePay } = useContext(GeneralContext)

	const getPartnerSettings = async () => {
		const partnerId = String(Cookies.get('partnerId'))
		if (partnerId !== null) {
			const getPartnerSettings = await GetPartnerSettings(partnerId)
			if (
				getPartnerSettings?.data !== undefined &&
				getPartnerSettings?.data?.isGerminiPayActive
			) {
				setActivePay(getPartnerSettings?.data?.isGerminiPayActive)
			}
		}
	}

	useEffect(() => {
		getPartnerSettings()
	}, [])

	const clickMe = boolean => {
		childToParent(boolean)
	}


	return (
		<Fragment>
			{
				activeModules && activePay ?
					<S.CustomCard>
						<Card
							onClick={() => {
								clickMe(true)
							}}
						>
							<S.HeaderIcons>
								<S.CustomIcon>
									<Icon>
										<i className="icon-servicos_contratados_pagamentos" />
									</Icon>
								</S.CustomIcon>
							</S.HeaderIcons>
							<CardContent style={{ padding: '0px', marginTop: '50px' }}>
								<S.CardTitle>{t('CARD_SERVICES_LOGS')}</S.CardTitle>
							</CardContent>
						</Card>
					</S.CustomCard>
					:
					<S.NoModules>
						<i
							className="icon-ico_mais_informacao"
						/>
						<span> Não há logs a serem exibidos. </span>
					</S.NoModules>
			}
		</Fragment>
	)
}

CardServicesLogs.propTypes = {
	childToParent: PropTypes.func.isRequired,
}
