import styled from 'styled-components'

export const Container = styled.div`
	background: #ececec 0% 0% no-repeat padding-box;
	opacity: 1;
	flex: 1;
`

export const IconTable = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	width: 42px;
	background-color: ${({ bgColor }) => bgColor || ''};
	cursor: pointer;
	margin-left: 8px;
	border-radius: 0px 8px 8px 0px;

	i {
		margin: auto;
		font-size: 20px;
		color: ${({ themeColor, disabled }) => (!disabled ? themeColor?.visual?.icons?.color : '#989898')};
	}

	:disabled {
		cursor: not-allowed;
	}
`
