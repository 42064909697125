import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

// @ Components
import Snackbar from 'components/Snackbar'
import Section from 'components/Section'

// @ Context and Styles
import * as S from './styles'
import GeneralContext from '../../GeneralContext'
import { CardServicesLogs } from './PaymentServicesLog/CardServicesLog'
import { CardServices } from './PaymentServicesLog/CardServices'


function NotificationCenter() {
	const { t } = useTranslation()
	const { isSucess, setIsSucess } = useContext(GeneralContext)

	const [data, setData] = useState(false)

	return (
		<S.Container>
			<Snackbar
				close={true}
				marginTop="130px"
				open={isSucess.open}
				color={isSucess.color}
				handleClose={() => setIsSucess({ open: false })}
				message={t(isSucess.message)}
				closeNotification={() => setIsSucess({ open: false })}
				icon={() => <i className={isSucess.icon} />}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			/>
				<Section
					title={t('MENU_MANAGE_NOTIFICATION_CENTER')}
					style={{ marginBottom: '25px' }}
				/>
				{!data ? (
						<CardServicesLogs
							childToParent={childdata => setData(childdata)}
						/>
				) : (
					<CardServices />
				)}
			</S.Container>
	)
}

export default NotificationCenter
