import React from 'react'
import { AttentionIcon, MuiStack, MuiTypography } from '../../../../../components/storybook'

export function LimitInformation() {
	return (
		<MuiStack width="100%" direction="row" spacing={2} alignItems="center" backgroundColor="#FFF5DA" padding="3px 15px" borderRadius={1}>
			<AttentionIcon size={30} color="#D9A207" />
			<MuiTypography variant="body2" color="#333333" pt="3px">
				Para exibir o saldo bloqueado é necessário informar a quantidade de itens.
			</MuiTypography>
		</MuiStack>
	)
}
