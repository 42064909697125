import styled from 'styled-components'
import { Modal, Paper } from '@material-ui/core'
import {
	germiniDefaultColors,
	grayColor,
	primaryColor,
} from 'assets/jss/material-dashboard-pro-react'

export const ModalContainer = styled(Modal)`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

export const PaperContainer = styled(Paper)`
	margin-bottom: 40px;
	position: relative;
	max-width: ${({ maxwidth }) => maxwidth};
	min-width: 40vw;
	padding: ${({ padding }) => padding};

	@media (max-width: 600px) {
		min-width: 100%;
		flex-direction: column;
	}

	&.MuiPaper-elevation1 {
		box-shadow: ${({ shadow }) => shadow};
	}

	.row {
		width: 100%;
		display: table;
		margin-top: 16px;
		/* color: ${germiniDefaultColors[14]}; */
	}
	.name {
		display: table-cell;
		white-space: nowrap;
		line-height: 1.4;
	}
	.dotted {
		border-bottom: 1px dashed lightgray;
		display: table-cell;
		width: 100%;
		margin-bottom: 2px;
	}
	.value {
		text-align: right;
		white-space: nowrap;
	}

	@media (max-width: 600px) {

		.GridContainer-grid-68 {
			flex-direction: column;
			text-align: center;
			padding: 0 !important;
		}

		.MuiGrid-grid-xs-3 {
			max-width: 100%;
		}

	}

	@media (max-width: 330px) {
		padding: 0;
		margin-bottom: 0;
		margin: 0;

	}
`

export const TitleText = styled.p`
	color: ${primaryColor[0]};
	font-weight: bold;
`
