import styled from 'styled-components'

export const Container = styled.div`
	.fullscreen {
		position: fixed;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		overflow: auto;
		z-index: 999999999999999999999999;
		background: #ffffff 0% 0% no-repeat padding-box;
	}
`
export const Section = styled.div`
	border: 1px solid #9b9b9b;
	border-radius: 6px;
	opacity: 1;
`

export const ContainerDiagram = styled.div`
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 20px;

	.title {
		text-align: left;
		font: normal normal bold 18px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
	}
`
