import { PartnerService } from '../../Services'
import Cookies from 'js-cookie'

export default async function getAllBusinessDivision(updateBusinessDivisionData) {
	const partnerId = String(Cookies.get('partnerId'))

	const partnerService = new PartnerService()
	const response = await partnerService.getBusinessDivisions(partnerId)

	return response
}
