import axios from 'axios'

export const GetProductsCatalog = queryParams => {
	return axios.get(`/Product/GetProductsCatalog${'?' + queryParams}`).then(response => response.data)
}

export const GetGroupers = () => {
	return axios.get(`/Product/GetGroupers`).then(response => response.data)
}

export const GetGrouperTypes = ({ grouperType, partnerId, groupName, page }) => {
	const groups = {
		Category: 'GetAllCategoriesByNameAndPartner',
		Family: 'GetAllFamiliesByNameAndPartner',
		Manufacturer: 'GetAllManufacturersByNameAndPartner',
		Brand: 'GetAllBrandsByNameAndPartner',
	}

	const query = `${String(groups[grouperType])}?partnerId=${partnerId}`

	return axios.get(`/Product/${query}`).then(response => response.data)
}

export const GetProductsById = id => {
	return axios.get(`/Product/${id}`).then(response => response.data)
}

export const GetStatusProductsByDivisionTable = queryParams => {
	return axios.get(`/Product/GetStatusProductsByDivision${'?' + queryParams}`).then(response => response.data)
}

export const GetStatusProductsByDivision = (productId, statusName, partnerId) => {
	let queryParams = `ProductId=${productId}&PartnerId=${partnerId}&PageSize=10&Page=1&OrderBy=desc`
	if (statusName) {
		queryParams += `&Name=${statusName}`
	}

	return axios.get(`/Product/GetStatusProductsByDivision?${queryParams}`).then(response => response.data)
}

export const GetProductTypeOfBenefit = () => {
	return axios.get(`/Product/GetProductTypeOfBenefit`).then(response => response.data)
}

export const GetListProductParametrizationByMultipleDivisions = (productId, typeOfBenefit) => {
	let queryParams = `ProductId=${productId}&PageSize=10&Page=1`

	if (typeOfBenefit) {
		queryParams += `&TypeOfBenefit=${typeOfBenefit}`
	}

	return axios.get(`/Product/GetListProductParametrizationByMultipleDivisions?${queryParams}`).then(response => response.data)
}

export const GetParametrizationHistory = productId => {
	let queryParams = `Id=${productId}&PageSize=10&Page=1`
	return axios.get(`/Grouper/GetListGrouperParametrizationByMultipleDivisions?${queryParams}`).then(response => response.data)
}

export const GetTypeOfPoints = async () => {
	return await axios.get(`/Product/GetTypeOfPoints`).then(response => response.data)
}

export const GetTypeOfCashback = async () => {
	return await axios.get(`/Product/GetTypeOfCashback`).then(response => response.data)
}

export const GetCategoriesByNameAndPartner = async (PartnerId, category) => {
	let queryParams = `id=${PartnerId}`
	if (category) {
		queryParams += `&name=${category}`
	}
	return axios.get(`/Product/GetCategoriesByNameAndPartner?${queryParams}`).then(response => response.data)
}

export const GetParametrizationDetails = async id => {
	return await axios.get(`/Product/GetProductParametrizationByMultipleDivisions/${id}`).then(response => response.data)
}

export const GetCurrentPartner = async () => {
	return await axios.get(`/Partner/GetCurrentPartner`).then(response => response.data)
}

export const GetGrouperByPartner = async (PartnerId, params) => {
	// let queryParams = `PartnerId=${PartnerId}`
	// if (category) {
	// 	queryParams += `&Name=${category}`
	// }
	return axios.get(`/Grouper/GetGrouperByPartner?PartnerId=${PartnerId}&${params}`).then(response => response.data)
}

export const GetGrouperByDetails = async categoryId => {
	return axios.get(`/Grouper/GetGrouperParametrization/${categoryId}`).then(response => response.data)
}

export const GetGrouperParametrization = async partnerId => {
	return axios.get(`/Product/CalculateValueBase?partnerId=${partnerId}&price=10&typeOfPoints=Fixed&pointsValue=2`).then(response => response.data)
}
