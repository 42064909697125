/* eslint-disable no-undef */
import axios from 'axios'
import { envs } from '../redux/api/windowServerData'

// const apiGateWayURLKernel = envs.REACT_APP_GERMINI_API_URL
const apiGateWayURLKernel = envs.REACT_APP_GERMINI_API_URL

const apiKernel = axios.create({
	baseURL: `${apiGateWayURLKernel}`,
	headers: {
		appId: 'PDV1805',
		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// companyInternalName: 'eprodutor',
	},
})

const apiKernelNoAuth = axios.create({
	baseURL: `${apiGateWayURLKernel}`,
	headers: {
		appId: 'PDV1805',
		Authorization: 'Bearer ',
		// companyInternalName: 'eprodutor',
	},
})

const apiKernelPartner = axios.create({
	baseURL: `${apiGateWayURLKernel}`,
	headers: {
		appId: 'PRN1802',
		Authorization: 'Bearer ' + localStorage.getItem('token'),
		// companyInternalName: 'eprodutor',
	},
})

export { apiKernel, apiKernelPartner, apiKernelNoAuth }
