import styled from 'styled-components';

export const ContainerTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

`

export const Title = styled.div`
	font-weight: bold;
`


export const Info = styled.div`
	font-weight: bold;
	color: ${({ themeColor }) => themeColor &&
		themeColor.titles &&
		themeColor.titles
			.highlight &&
		themeColor.titles
			.highlight.textColor &&
		themeColor.titles
			.highlight.textColor
	};

`




