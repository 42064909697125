import styled from 'styled-components/macro'
import Button from 'components/CustomButtons/Button.jsx'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

import { Box, Icon, TextField, withStyles } from '@material-ui/core'
import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'

const getIconColor = () => {
	let themeColor = getThemeColorFromLocalStorage() || ''
	return themeColor &&
		themeColor.visual &&
		themeColor.visual.icons &&
		themeColor.visual.icons.color &&
		themeColor.visual.icons.color
		? themeColor.visual.icons.color
		: '#fff'

}

const getButtonColor = () => {
	let themeColor =
		getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().navigation &&
		getThemeColorFromLocalStorage().navigation.buttons &&
		getThemeColorFromLocalStorage().navigation.buttons.backgroundColor &&
		getThemeColorFromLocalStorage().navigation.buttons.backgroundColor

	return themeColor
}

export const IconButton = styled.i`
	margin-right: .625rem;
`

export const MyButton = styled.span`
	color: white;
	padding: 4px 8px;
	border-radius: 10px;
	background-color: ${getButtonColor()};
	border-style: none;
	display: inline-flex;
	align-items: center;
	gap: 4px;
`

export const ErrorSpan = styled.span`
	color: #f05b4f;
`
export const FormContain = styled.form`
	width: 100%;

`

export const TitleSection = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 25px;
	width: 100%;
`
export const BtnTitleSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 0.813rem;
	margin-left: 0.875rem;
`
export const BtnConfirm = styled(Button)`
	position: relative;
	padding: 0 1.2rem;
	color: ${(props) => props.themeColor};

	box-shadow: none !important;
	${({ isDisabled }) => isDisabled &&
		`
		border: none !important;
		background: #c8c8c8 !important;
		pointer-events: none !important;
  `}
`
export const BtnBack = styled(Button)`
	position: relative;
	padding: 0 1.5rem;
`
export const InputBox = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export const InputValue = styled.div`
	width: 100%;
	margin-bottom: 30px;
	@media only screen and (min-width: 800px) {
		width: 50%;
	}
`
export const InputDescription = styled.div`
	width: 100%;
	margin-bottom: 30px;
`
export const InputProof = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.inputProof {
		width: 100%;
		height: 40px;
	}
	@media only screen and (min-width: 600px) {
		flex-direction: row;
	}
`
export const BtnInputProof = styled.div`
	display: flex;
	justify-content: flex-end;
	gap: 10px;
	padding-left: 10px !important;
	margin-top: 20px !important;
	width: 100%;
	@media only screen and (min-width: 600px) {
		margin-top: 0 !important;
		width: 150px;
	}
`

export const CustomIcon = styled(Icon)`
	display: inline-flex;
	align-items: center;
	width: 2rem !important;
	height: 2rem !important;
	color: ${getIconColor()} !important;
`

export const FileContainer = styled(Box)`
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: 0.8rem;
	margin-top: 30px;
`

export const FileText = styled.span`
	width: max-content;
	display: inline-flex;
	align-items: center;
	gap: 0.25rem;
`

export const CssTextField = withStyles({
	root: {
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& label.Mui-focused': {
			color: getThemeColorFromLocalStorage() &&
				getThemeColorFromLocalStorage().structure &&
				getThemeColorFromLocalStorage().structure.backgroundColor
				? getThemeColorFromLocalStorage().structure.backgroundColor
				: "#fff !important"
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: "#fff !important",
			},
			'&.Mui-focused fieldset': {
				borderColor: getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: "#fff !important"
			},
		},
	},
})(TextField)

