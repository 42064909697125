import * as actionTypes from '../actions/actionsTypes';
import {updateObject} from '../utility';

const initialState = {
    tierData: [],
    tierDataFailed: false
}

const fetchData = (state, action) => {
    return updateObject( state, {
        tierData: action.tierData
    });
}

const fetchDataFailed = (state, action) => {
    return updateObject( state, {
        tierDataFailed: true
    });
}

const tierReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.FETCH_TIER_DATA:
            return fetchData(state, action);
        case actionTypes.FETCH_TIER_FAILED:
            return fetchDataFailed(state, action);
        default:
            return state;
    }
}

export default tierReducer;