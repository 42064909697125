import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'
import styled from 'styled-components'
import { Button } from '@material-ui/core'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const ColorLuminance = (hex, lum) => {
	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '')
	if (hex.length < 6) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
	}
	lum = lum || 0
	// convert to decimal and change luminosity
	var rgb = '#',
		c,
		i
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i * 2, 2), 16)
		c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
		rgb += ('00' + c).substr(c.length)
	}
	return rgb
}

const getThemeColorButtonsBackgroundColor = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().navigation) {
			if (getThemeColorFromLocalStorage().navigation.buttons) {
				return getThemeColorFromLocalStorage().navigation.buttons
					.backgroundColor
			}
		}
	}
	return
}
const getThemeColorButtonsTextColor = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().navigation) {
			if (getThemeColorFromLocalStorage().navigation.buttons) {
				return getThemeColorFromLocalStorage().navigation.buttons
					.TextColor
			}
		}
	}
	return
}

export const ButtonConfirm = styled(Button)`
	color: ${getThemeColorButtonsTextColor()};
	&.MuiButton-root.Mui-disabled {
		background-color: #d3d3d3;
	}

	height: 40px;
	font-size: 15px !important;
	min-width: 75px !important;

	&.MuiButton-root {
		border-radius: 6px;
		text-transform: initial;

		background-color: ${getThemeColorButtonsBackgroundColor()};
		color: ${getThemeColorButtonsTextColor()};
	}

	&.MuiButton-textPrimary {
		color: ${getThemeColorButtonsTextColor()};

		&:hover {
			background-color: ${ColorLuminance(
				getThemeColorButtonsBackgroundColor(),
				-0.5,
			)};
			box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
		}
	}

	&.MuiButton-text {
		padding: 0 15px;
		font-weight: initial;
	}

	&.search-button {
		border-radius: 6px;

		&:hover {
			opacity: 1 !important;
			box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
		}
	}
`

export const ButtonBack = styled(Button)`
	height: 40px;
	font-size: 15px !important;
	min-width: 75px !important;

	&.MuiButton-root {
		border-radius: 6px;
		text-transform: initial;

		background-color: #fff;
		border: 1px solid ${getThemeColorButtonsBackgroundColor()};
	}

	&.MuiButton-text {
		color: ${getThemeColorButtonsBackgroundColor()};

		&:hover {
			background-color: ${getThemeColorButtonsBackgroundColor()};
			color: ${getThemeColorButtonsTextColor()};
			box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
		}
	}

	&.MuiButton-text {
		padding: 0px 15px;
		font-weight: initial;
	}
`
export const ButtonClose = styled(Button)`
	height: 40px;
	font-size: 14px !important;
	font-weight: normal !important;
	min-width: 75px !important;
	padding: 0 15px !important;

	&.MuiButton-root {
		border-radius: 6px;
		text-transform: initial;

		color: #fff;
		background-color: ${germiniDefaultColors[12]};

		&:hover {
			background-color: #7a000e;
		}
	}
`
