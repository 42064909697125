import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

//@ components
import Filters from './components/Filter'
import NoDivisionTab from './components/NoDivisionTab'
import GeneralTab from './components/generalTab'
import DivisionTab from './components/DivisionTab'

const Cashback = props => {
	const { editMode, setReloadProduct, setModalCancel } = props
	const [generalTab, setGeneralTab] = useState(false)
	const [byDivisionTab, setByDivisionTab] = useState(false)
	const [noDivisionTab, setNoDivisionTab] = useState(false)

	const [listDivision, setListDivision] = useState([])

	useEffect(() => {
		if (!editMode) {
			setReloadProduct(true)
		}
	}, [editMode])

	const sharedProps = {
		...props,
		generalTab,
		setGeneralTab,
		byDivisionTab,
		setByDivisionTab,
		noDivisionTab,
		setNoDivisionTab,
		listDivision,
		setListDivision,
		setModalCancel,
	}

	return (
		<S.Container>
			<Filters {...sharedProps} />
			<S.Components>
				{noDivisionTab && <NoDivisionTab {...sharedProps} />}
				{generalTab && <GeneralTab {...sharedProps} />}
				{byDivisionTab && <DivisionTab {...sharedProps} />}
			</S.Components>
		</S.Container>
	)
}

Cashback.propTypes = {
	setModalCancel: PropTypes.func,
	editMode: PropTypes.bool,
	setReloadProduct: PropTypes.func,
}

export default Cashback
