import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    platformRulesData: [],
    platformRulesDataFailed: false
};

const fetchPlatformRulesData = (state, action) => {
    return updateObject(state, {
        platformRulesData: action.platformRulesData,
    });
};

//
// DATA FAIL
const fetchPlatformRulesFail = (state, action) => {
    return updateObject(state, {
        platformRulesDataFailed: true
    });
};

const platformRules = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_PLATFORM_RULES_DATA: return fetchPlatformRulesData(state, action);
        case actionTypes.FETCH_PLATFORM_RULES_FAILED: return fetchPlatformRulesFail(state, action);

        default: return state;
    }
};

export default platformRules;