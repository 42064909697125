import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    generalRulesData: {},
    generalRulesDataFailed: false,
};

const fetchGeneralRulesData = (state, action) => {
    return updateObject(state, {
        generalRulesData: action.generalRulesData
    });
};

// DATA FAIL
const fetchGeneralRulesFail = (state, action) => {
    return updateObject(state, {
        generalRulesdataFailed: true
    });
};

//
const generalRulesReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_GENERAL_RULES_DATA:
            return fetchGeneralRulesData(state, action);
        case actionTypes.FETCH_GENERAL_RULES_FAILED:
            return fetchGeneralRulesFail(state, action);
        default:
            return state;
    }
};

export default generalRulesReducer;