import axios from "axios";

export const GetCity = (stateId) =>
  axios.get(`/City?stateId=${stateId}`).then((response) => response.data);

export const GetState = (countryId) =>
  axios.get(`/State?countryId=${countryId}`).then((response) => response.data);

export const GetCountries = () =>
  axios.get(`/Country`).then((response) => response.data);

export const FindCNPJ = () =>
  axios.get(`/Country`).then((response) => response.data);

export const FindZipCode = (zipCode) =>
  axios
    .get(`/Address/SearchZipCode/${zipCode}`)
    .then((response) => response.data);

let cancel

const setupCancelToken = () => {
  if (cancel) {
    cancel('Canceling previous request')
  }

  return new axios.CancelToken(function executor(c) {
    cancel = c
  })
}

export const GetCities = async stateId => {
  return await axios
    .get(`/City?stateId=${stateId}`, {
      cancelToken: setupCancelToken(),
    })
    .then(response => response.data)
}

export const GetStates = async countryId => {
  return await axios
    .get(`/State?countryId=${countryId}`, {
      cancelToken: setupCancelToken(),
    })
    .then(response => response.data)
}