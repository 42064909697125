import { Grid } from '@material-ui/core'
import React, { forwardRef } from 'react'
import * as Style from './styles'
import './index.css'

import VerificationInput from 'react-verification-input'

const TokenInput = forwardRef(
    (
        {
            amount,
            onChange,
            regExp = /^[0-9]$/,
            error,
            hasBackground = true,
            validChars,
        },
        ref,
    ) => {
        return (
            <>
                <Style.Container
                    item
                    container
                    title={hasBackground}
                    error={error ? 1 : 0}
                    xs={12}
					style={{
						marginLeft: "15px"
					}}
                >
					<VerificationInput
						validChars={validChars}
						removeDefaultStyles
						classNames={{
							container: 'container',
							character: 'character',
							characterInactive: 'character--inactive',
							characterSelected: 'character--selected',
						}}
						ref={ref}
						length={amount}
						onChange={onChange}
					/>
                </Style.Container>
                <Grid
                    item
                    xs={12}
                    style={{ textAlign: hasBackground ? 'end' : 'center' }}
                >
                    {error && <span style={{ color: '#DE2E2E' }}>{error}</span>}
                </Grid>
            </>
        )
    },
)

export default TokenInput
