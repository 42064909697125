import { TextField } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import Section from 'components/Section'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { HideLoader } from 'redux/actions'
import { numberFormatText } from 'utils/utils'
import { useAward } from '../../../Providers/AwardContext'
import { GetAwardId, PostAwardRefund } from '../../../redux/api/award.js'
import ConfirmRefundModal from '../ConfirmRefundModal'
import ModalReversal from '../ModalReversal'
import { ActionBtn } from '../style'
import * as S from './style'

import TextMaskCPFCustom from '../../../components/TextMaskCPFCustom'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import {envs}  from '../../../redux/api/windowServerData.js'



const DataAward = () => {
	const { id } = useParams()
	const tableRef = React.useRef()
	const { idDetailProcessed, allowDigitalWallet } = useAward()
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const { handleSubmit, watch, control } = useForm()

	dispatch(HideLoader())

	const [pageSizeState, setPageSizeState] = useState(10)
	const [recordPageState, setRecordPageState] = useState()
	const history = useHistory()
	const [dataResponse, setDataResponse] = useState({})
	const [openModal, setOpenModal] = useState(false)
	const [errorModal, setErrorModal] = useState(false)
	const [openPwdModal, setOpenPwdModal] = useState(false)
	const [selectedCpfList, setSelectedCpfList] = useState([])
	const [selectList, setSelectList] = useState(false)
	const [refundCanceled, setRefundCanceled] = useState(false)
	const [valueSearch, setValueSearch] = useState(0)
	const [dataLogAward, setDataLogAward] = useState([])
	const watchAllFields = watch()
	const [allowRefund, setAllowRefund] = useState(false)
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		if (dataResponse) {
			setAllowRefund(dataResponse.allowRefund)
		}
	}, [dataResponse])

	useEffect(() => {
		if (watchAllFields.search === '') {
			tableRef.current.onQueryChange({ page: 0, search: '' })
		}
	}, [watchAllFields.search])

	const handleGetAwardData = async (pageSize, page, search) => {
		const response = await GetAwardId(idDetailProcessed, pageSize, page, search)

		setDataResponse({
			description: response.data.description,
			id: response.data.id,
			importValueType: response.data.importValueType,
			jobId: response.data.jobId,
			observation: response.data.observation,
			partner: response.data.partner,
			partnerCreditOrigin: response.data.partnerCreditOrigin,
			type: response.data.partnerCreditOrigin.value,
			partnerCreditOriginId: response.data.partnerCreditOriginId,
			date: response.data.processingDate ? moment(response.data.processingDate).format('DD/MM/YYYY') : null,
			hour: response.data.processingDate ? moment(response.data.processingDate).format('HH:mm') : null,
			standartValue: response.data.standartValue,
			status: response.data.status,
			reserveBalance: response.data.bankAccountReserve ? response.data.bankAccountReserve.reserveBalance : 0,
			total: response && response.data && response.data.total,
			allowRefund: response && response.data && response.data.allowRefund,
		})

		const refundState = response.data.status.value === 'CANCELED' ? true : false
		setRefundCanceled(refundState)

		return response
	}

	const handleOpenModal = () => setOpenModal(true)

	const handleSelectCpf = cpf => {
		localStorage.setItem('idDataAward', cpf.id)
		handleOpenModal()
		setSelectedCpfList(cpf)
	}

	const onSubmitFilter = data => {
		let value = data.search.replace(/\D/g, '')

		tableRef.current.onQueryChange({ page: 0, search: value })
	}

	const handleConfirmPasswordModal = async data => {
		let consumerIds = []
		consumerIds.push(selectedCpfList.id)

		const obj = {
			awardId: id,
			password: data.password,
			refundType: 2,
			consumerIds: consumerIds,
		}

		const res = await PostAwardRefund(obj)

		setOpenModal(false)

		history.push({
			pathname: '/financial/reversalConfirm',
			state: {
				data: [selectedCpfList],
				reservedValue: dataResponse.total,
			},
		})
	}

	const handleConfirmModal = () => {
		setOpenPwdModal(true)
	}

	return (
		<S.Container themeColor={themeColor && themeColor}>
			<ConfirmRefundModal
				open={openPwdModal}
				onCancel={() => setOpenPwdModal(false)}
				onDelete={value => handleConfirmPasswordModal(value)}
				error={errorModal && 'Senha incorreta'}
			/>
			<ModalReversal
				setOpenModal={setOpenModal}
				openModal={openModal}
				handleConfirm={handleConfirmModal}
				cpfList={[selectedCpfList]}
				reversalType={selectList}
			/>

			<S.TitleContainer>
				<Section title={t('AWARD_DATA')} style={{ flexGrow: 1 }} />
				<div style={{ marginLeft: '15px' }}>
					<Button color="greenBorderButtonColor" size="sm" variant="contained" onClick={() => history.push('/financial/award')}>
						Voltar
					</Button>
				</div>
			</S.TitleContainer>
			<S.GridContain>
				<S.FieldContain themeColor={themeColor && themeColor}>
					<S.InputBox style={{ width: '220px', marginRight: '15px' }}>
						<label>Data do evento</label>
						<S.Inputs value={dataResponse.date ? `${dataResponse.date} - ${dataResponse.hour}` : '-'} />
					</S.InputBox>
					<S.InputBox style={{ width: '690px', marginRight: '0' }}>
						<label>Descrição da premiação</label>
						<S.Inputs value={dataResponse.description ? dataResponse.description : '-'} />
					</S.InputBox>
				</S.FieldContain>
				<S.FieldContain themeColor={themeColor && themeColor}>
					<S.InputBox
						style={{
							width: '220px',
							marginRight: '15px',
						}}
					>
						<label>Evento</label>
						<S.Inputs value={dataResponse.type ? dataResponse.type : '-'} />
					</S.InputBox>
					<S.InputBox
						style={{
							width: '440px',
							marginRight: '15px',
						}}
					>
						<label>Reserva da conta</label>
						<S.ShowValue>
							{numberFormatText(
								dataResponse.reserveBalance ? dataResponse.reserveBalance : '0',
								localStorage.getItem('currencySymbol')
									? `${localStorage.getItem('currencySymbol')}\n`
									: // : envs.REACT_APP_CURRENCY_SYMBOL,
									  envs.REACT_APP_CURRENCY_SYMBOL,
								2,
							)}
						</S.ShowValue>
					</S.InputBox>
					<S.InputBox style={{ width: '235px', marginRight: '0' }}>
						<label>Valor total utilizado na distribuição</label>

						<S.ShowValue>
							{numberFormatText(
								dataResponse.total ? dataResponse.total : '0',
								// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
								localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
								2,
							)}
						</S.ShowValue>
					</S.InputBox>
				</S.FieldContain>
				{dataLogAward.isAnticipated && (
					<S.ContainFields style={{ marginRight: '0', marginTop: '18px' }}>
						<S.InputBox style={{ width: '220px' }}>
							<label style={{ marginLeft: '15px' }}>Taxa de antecipação</label>
							<S.Inputs value={dataLogAward.anticipationFee ? dataLogAward.anticipationFee + '%' : '-'} />
						</S.InputBox>
						<S.InputBox style={{ marginRight: '0', width: '200px' }}>
							<label style={{ marginLeft: '15px' }}>Carência</label>
							<S.Inputs value={dataLogAward.graceDescription ? dataLogAward.graceDescription : '-'} />
						</S.InputBox>
					</S.ContainFields>
				)}
				<S.InputBox style={{ marginRight: '0', marginTop: '18px' }}>
					<label>Observação</label>
					<S.Inputs value={dataResponse.observation} />
				</S.InputBox>
			</S.GridContain>

			<S.FieldContain
				themeColor={themeColor && themeColor}
				onSubmit={handleSubmit(onSubmitFilter)}
				autoComplete="off"
				style={{
					position: 'relative',
					justifyContent: 'flex-end',
				}}
			>
				<Controller
					as={
						<TextField
							name="search"
							label="Buscar"
							variant="standard"
							InputProps={{
								inputComponent: TextMaskCPFCustom,
							}}
						/>
					}
					control={control}
					name="search"
					defaultValue=""
				/>

				<button type="submit">
					<i className="icon-lupa" />
				</button>
			</S.FieldContain>

			<MaterialTable
				search={true}
				tableRef={tableRef}
				style={{ width: '100%' }}
				options={{
					emptyRowsWhenPaging: false,
					toolBar: false,
					pageSizeOptions: [10, 20, 30, 40, 50],
					search: false,
					pageSize: recordPageState < 10 ? recordPageState : pageSizeState,
					sorting: false,
					paging: true,
				}}
				columns={[
					{
						title: 'CPF/Cód. participante',
						searchable: true,
						cellStyle: {
							width: '50%',
							maxWidth: '50%',
							minWidth: '50%',
							backgroundColor: '#fff',
						},
						headerStyle: {
							fontSize: '15px',
							paddingLeft: '15px !important',
						},
						render: props => (
							<div
								style={{
									height: '34px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}
							>
								{props.cpF_Code > 5 ? props.cpF_Code.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : props.cpF_Code}
							</div>
						),
					},
					{
						title: 'Valor',
						field: 'value',
						width: '50%',
						searchable: true,
						cellStyle: {
							textAlign: 'left',
							backgroundColor: '#fff',
						},
						headerStyle: {
							textAlign: 'left',
							fontSize: '15px',
						},
						render: props => (
							<div
								style={{
									height: '34px',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'flex-start',
								}}
							>
								{numberFormatText(
									props.value ? props.value : '0',
									// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
									localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
									2,
								)}
							</div>
						),
					},

					{
						title: 'Ação',
						field: 'actions',

						cellStyle: {
							width: '43px',
							// maxWidth: '43px',
							// minWidth: '43px',
							backgroundColor: '#fff',
							padding: '0',
						},
						headerStyle: {
							padding: '0',
						},

						sorting: false,
						render: props => (
							<ActionBtn
								themeColor={themeColor && themeColor}
								disabled={
									allowDigitalWallet === false || props.status.key === '4' || dataResponse.allowRefund === false
										? true
										: refundCanceled === true
										? true
										: false
								}
								style={{
									backgroundColor: `${(allowDigitalWallet === false ||
										dataResponse.allowRefund === false ||
										props.status.key === '4' ||
										refundCanceled === true) &&
										'#c4c4c4'}`,
									cursor: `${props.status.key === '4' && 'default'}`,
								}}
								onClick={() => handleSelectCpf(props)}
							>
								<i
									className="icon-estorno"
									style={{
										color:
											themeColor &&
											themeColor.navigation &&
											themeColor.navigation.buttons &&
											themeColor.navigation.buttons.textColor &&
											themeColor.navigation.buttons.textColor,
									}}
								/>
							</ActionBtn>
						),
					},
				]}
				data={query =>
					new Promise((resolve, reject) => {
						handleGetAwardData(query.pageSize, query.page + 1, query.search, query.orderDirection).then(result => {
							if (result) {
								setDataLogAward(result.data)
								setPageSizeState(result.data.consumers.pageSize)
								setRecordPageState(result.data.consumers.recordCount)
								if (result.data) {
									resolve({
										pageSize: result.data.consumers.pageSize,
										data: result.data.consumers.results,
										page: result.data.consumers.currentPage - 1,
										totalCount: result.data.consumers.recordCount,
									})
								}
							}
						})
					})
				}
			/>
		</S.Container>
	)
}
export default DataAward
