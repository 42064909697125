/* eslint-disable no-unused-vars */
import { history } from 'utils'
import queryString from 'query-string'

function removeEmptyProperties(obj) {
	return Object.fromEntries(
		Object.entries(obj).filter(([_, v]) => v != null && v != ''),
	)
}

export const formatURL = props => {
	const location = history.location.search
	const finalState = {
		...queryString.parse(location),
		...props,
	}

	return history.push({
		search: `?${queryString.stringify(removeEmptyProperties(finalState))}`,
	})
}
export const getURL = () => {
	const location = history.location.search
	return { ...queryString.parse(location) }
}
