/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core'
import React from 'react'

export default function TabItem({ value, index, children, minHeight, ...rest }) {
	return (
		<Box role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...rest}>
			{value === index && (
				<Box sx={{ padding: '10px 0px', minHeight: minHeight }}>
					<Box>{children}</Box>
				</Box>
			)}
		</Box>
	)
}
