import styled from 'styled-components'
import { makeStyles } from '@material-ui/core/styles'

export const Image = styled.img`
	border-radius: 10px;
	height: 100%;
	width: 100%;
`

export const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		maxWidth: '90%',
		maxHeight: '80%',
		margin: 'auto',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '1px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		borderRadius: '10px',
	},
}))
