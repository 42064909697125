import axios from 'axios'

export const GetPdvs = (
	partnerId,
	name,
	startDate,
	endDate,
	status,
	pageSize,
	page,
) => {
	var query = `?page=${page && page !== '' ? page : '1'}`
	if (partnerId && partnerId !== '') {
		query = query + `&partnerId=${partnerId}`
	}
	if (name && name !== '') {
		query = query + `&description=${name}`
	}
	if (startDate && startDate !== '') {
		query = query + `&startDate=${startDate}`
	}
	if (endDate && endDate !== '') {
		query = query + `&endDate=${endDate}`
	}
	if (status !== '') {
		query = query + `&status=${status}`
	}
	if (pageSize && pageSize !== '') {
		query = query + `&pageSize=${pageSize}`
	}
	return axios.get(`/PDV/GetPDVs${query}`).then((response) => response.data)
}

export const GetActivePDVUser = (partnerPDVId, partnerId) => {
	return axios
		.get(
			`/PDV/GetActivePDVUser?partnerPDVId=${partnerPDVId}&partnerId=${partnerId}`,
		)
		.then((response) => response.data)
}

export const GetPdv = (id) => {
	return axios.get(`/PDV/GetPDV?id=${id}`).then((response) => response.data)
}

export const GetReportFrequency = () =>
	axios.get(`/PDV/GetReportFrequency`).then((response) => response.data)

export const GetActivePaymentMethod = () =>
	axios
		.get(`/Partner/GetActivePaymentMethod`)
		.then((response) => response.data)

export const GetPaymentMethods = () =>
	axios.get(`/Partner/GetPaymentMethods`).then((response) => response.data)

export const GetActiveOrigins = () =>
	axios.get(`/Partner/GetActiveOrigins`).then((response) => response.data)

export const PostPDVCreate = (obj) =>
	axios.post(`/PDV/PostPDV`, obj).then((response) => response.data)

export const PostPDVUpdate = (obj, id) => {
	if (id) {
		axios
			.post(`/PDV/PostPDV?id=${id}`, obj)
			.then((response) => console.log(response))
	} else {
		axios.post(`/PDV/PostPDV`, obj).then((response) => response.data)
	}
}

export const DisconnectUser = (partnerPDVId, partnerId) =>
	axios.get(`/PDV/DisconnectUser?partnerPDVId=${partnerPDVId}&partnerId=${partnerId}`).then((response) => response.data)
