import styled from 'styled-components'

export const Container = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	padding: 20px 25px;

	table tbody {
		border: none;
	}

	th {
		border: none;
	}

	td:nth-child(2),
	th:nth-child(2) {
		border: none;
	}

	table tr:nth-child(even) {
		background-color: #fafafc;
	}

	.MuiTableContainer-root {
		box-shadow: none;
	}
`

export const Title = styled.div`
	text-align: left;
	font: normal normal bold 15px/60px Roboto;
	letter-spacing: 0px;
	color: ${({ color }) => color || '#000000'};
	opacity: 1;
`

export const Button = styled.button`
	border: 1px solid #f2f2f2;
	opacity: 1;
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	width: 65px;
	height: 37px;
	color: #4e4e4e;

	&:hover {
		background: #f05b4f 0% 0% no-repeat padding-box;
		color: #ffffff;
		cursor: pointer;
	}
`
