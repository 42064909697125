import React from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'
import MaskedInput from 'react-text-mask'
import Select from 'react-select'
import axios from 'axios'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
// @material-ui/core components
import Check from '@material-ui/icons/Check'

import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import TextField from '@material-ui/core/TextField'
import { withStyles } from '@material-ui/core/styles'

// core components
import Button from 'components/CustomButtons/Button.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section'

//Utils
import compose from 'utils/compose'

import styles from '../settings.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

import * as S from './styles'

import * as APILocation from 'redux/api/location'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

import {
	PostPartnerCreateNewUser,
	GetAccessGroupsData,
	GetPartnerUserById,
	UpdatePartnerUserData,
	CreateNewUser,
	UpdateUser,
	GetCurrentPartner,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from '../../../redux/actions'
import GroupsList from './groups'

import { useAuth } from '../../../utils/authconfig'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { useState } from 'react'
import { useEffect } from 'react'
import { getProgramTitle } from '../../../utils/utils'

function CPFMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}

function PhoneMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={['(', /\d/, /\d/, ')', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}

const userStyle = {
	...extendedFormsStyle,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	// REQUIRED FIELD
	labelTexRF: {
		lineHeight: '0 !important',
		top: '-5px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	labelTexRFForLogin: {
		top: '-5px',
	},
	// sem required fielvd
	inputHeight: {
		height: '9px',
	},
}

const CssTooltip = withStyles({
	arrow: {
		left: '10px !important',
	},
	tooltip: {
		backgroundColor: '#171717',
		borderRadius: '6px',
		fontSize: '0.72rem',
		padding: '2px 8px',
		fontWeight: 'initial',
		'& span': {
			color: '#171717',
		},

		// marginRight: "-160px",
		//VminWidth: "200px!important",
	},
})(Tooltip)

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: '1.2 !important',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: grayColor[2],
				},
				'&.Mui-focused fieldset': {
					borderColor: grayColor[2],
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(0,0%,95%)',
			'& fieldset': {
				borderColor: 'hsl(0,0%,90%)',
			},
		},
	},
})(TextField)

const validationSchema = Yup.object({
	cpf: Yup.string()
		.required('CPF obrigatório')
		.test('string', 'CPF inválido', (x = '') => {
			let rawCPF = x
				.replaceAll('-', '')
				.replaceAll('.', '')
				.replace(/\s/g, '')
			if (rawCPF.length === 11) return true
			return false
		}),
	name: Yup.string().required('Nome obrigatório'),
	email: Yup.string()
		.email('E-mail inválido')
		.required('E-mail obrigatório'),
	phoneNumber: Yup.string()
		.required('Celular obrigatório')
		.test('string', 'Celular inválido', (x = '') => {
			let rawPhone = x
				.replaceAll('(', '')
				.replaceAll(')', '')
				.replaceAll('.', '')
				.replace(/\s/g, '')
			if (rawPhone.length === 11) {
				return true
			} else {
				return false
			}
		})
		.typeError('Celular obrigatório'),
	isAdminUser: Yup.bool(),
	isPDVUser: Yup.bool(),
	UserGroups: Yup.array(),
})

const Form = props => {
	const {
		t,
		classes,
		values,
		errors,
		touched,
		handleSubmit,
		handleChange,
		handleCancel,
		handleEdit,
		handleBack,
		disabledForm,
		setFieldTouched,
		setFieldValue,
		editMode,
		selectItemsGroups,
		getGroupsByType,
		userGroupsArr,
		handleAdd,
		handleRemove,
	} = props

	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const auth = useAuth()

	const change = (name, e) => {
		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
		if (name === 'cpf') {
			setFieldTouched('userName', true, false)
			setFieldValue('userName', e.target.value, true)
		}
	}

	const changeSelectedGroup = (name, e) => {
		let check = _.includes(values.UserGroups, e.value)
		if (!check) {
			setFieldValue(name, e, true)
			setFieldTouched(name, true, false)
		}
	}

	const handleAddItem = () => {
		var array = userGroupsArr
		array.push({
			key: values.selectedGroup.value,
			label: values.selectedGroup.label,
		})
		if (!_.isEmpty(array)) {
			handleAdd(array)
			setFieldValue('UserGroups', array)
			setFieldValue('selectedGroup', '')

			// setFieldTouched('userGroups', true, false);
		}
	}

	const customSelectStyles = {
		control: (base, state) => ({
			...base,
			boxShadow: 0,
			borderWidth: state.isFocused ? '2px' : base.borderWidth,
			borderColor: touched.isActive && Boolean(errors.isActive) ? '#f05b4f !important' : state.isFocused ? grayColor[2] : base.borderColor,
		}),
		placeholder: (base, state) => ({
			...base,
			color: touched.isActive && Boolean(errors.isActive) ? '#f05b4f !important' : base.color,
		}),
	}

	const handleChangeSwitch = (name, e) => {
		if (name === 'admin') {
			setFieldValue('isAdminUser', e.target.checked, true)
			getGroupsByType(e.target.checked, values.isPDVUser)
		}
		if (name === 'pdv') {
			setFieldValue('isPDVUser', e.target.checked, true)
			getGroupsByType(values.isAdminUser, e.target.checked)
		}
	}

	const removeGroup = id => {
		var array = userGroupsArr.filter(item => item !== id)
		handleRemove(array)
		setFieldValue('UserGroups', array)
		setFieldTouched('UserGroups', true, false)
	}

	return (
		<S.Container themeColor={themeColor}>
			<DocumentTitle title={getProgramTitle()} />
			<GridContainer>
				<GridContainer style={{ marginLeft: 0, display: 'flex' }}>
					<GridItem xs={12} sm={12}>
						<div
							style={{
								marginBottom: '17px',
								display: 'flex',
								alignItems: 'center',
								height: 40,
							}}
						>
							<Section title={editMode ? t('TITLE_NEW_USER') : t('TITLE_USERS_INFO')} style={{ flexGrow: 1 }} />
							<div style={{ margin: '0 15px 0 15px' }}>
								{editMode && (
									<Button size="sm" variant="contained" color="greenBorderButtonColor" style={{ marginRight: '0.875rem' }} onClick={handleCancel}>
										{t('BUTTON_CANCEL')}
									</Button>
								)}
								{editMode && (
									<>
										<Button size="sm" style={{ marginRight: '0.875rem' }} variant="contained" color="greenButtonColor" onClick={handleSubmit}>
											{t('BUTTON_SAVE_CREATE')}
										</Button>
										<Button size="sm" variant="contained" color="greenButtonColor" onClick={handleSubmit}>
											{t('BUTTON_SAVE')}
										</Button>
									</>
								)}

								{disabledForm && (
									<>
										<Button size="sm" variant="contained" style={{ marginRight: '0.875rem' }} color="greenBorderButtonColor" onClick={handleBack}>
											{t('BUTTON_BACK')}
										</Button>
										<Button size="sm" variant="contained" color="greenButtonColor" onClick={handleEdit}>
											{t('BUTTON_EDIT')}
										</Button>
									</>
								)}
							</div>
						</div>

						<GridContainer>
							{/* #1 - NOME */}
							<GridItem xs={12} sm={12} md={4} style={{ marginBottom: '10px' }}>
								<CssTextField
									error={touched.name && Boolean(errors.name)}
									disabled={disabledForm}
									className={styles.TextField}
									fullWidth
									label={<CustomRequiredField value="FIELD_REGISTER_NAME" message="" disabled={false} />}
									helperText={touched.name ? errors.name : ''}
									id="name"
									name="name"
									value={values.name}
									onChange={change.bind(null, 'name')}
									variant="outlined"
									margin="dense"
									InputLabelProps={{
										classes: {
											disabled: values.id !== '' ? classes.labelTexRF : '',
										},
									}}
									style={{ backgroundColor: '#fff' }}
								/>
							</GridItem>

							{/* #2 CPF  */}
							<GridItem xs={12} sm={12} md={3} style={{ marginBottom: '10px' }}>
								<CssTextField
									error={touched.cpf && Boolean(errors.cpf)}
									disabled={disabledForm}
									className={styles.TextField}
									fullWidth
									label={<CustomRequiredField value="FIELD_REGISTER_DOCUMENT" message="" disabled={disabledForm} />}
									helperText={touched.cpf ? errors.cpf : ''}
									id="cpf"
									name="cpf"
									value={values.cpf}
									onChange={change.bind(null, 'cpf')}
									variant="outlined"
									margin="dense"
									InputLabelProps={{
										classes: {
											disabled: values.id !== '' ? classes.labelTexRF : '',
										},
									}}
									style={{ backgroundColor: '#fff' }}
									InputProps={{
										inputComponent: CPFMaskCustom,
									}}
								/>
							</GridItem>

							{/* #3 E-MAIL */}
							<GridItem xs={12} sm={12} md={5}>
								<CssTextField
									error={touched.email && Boolean(errors.email)}
									disabled={disabledForm}
									className={styles.TextField}
									fullWidth
									label={<CustomRequiredField value="FIELD_REGISTER_EMAIL" />}
									helperText={touched.email ? errors.email : ''}
									id="email"
									name="email"
									value={values.email}
									onChange={change.bind(null, 'email')}
									variant="outlined"
									margin="dense"
									InputLabelProps={{
										classes: {
											disabled: values.id !== '' ? classes.labelTexRF : '',
										},
									}}
									style={{ backgroundColor: '#fff' }}
								/>
							</GridItem>

							{/* #4 CELULAR */}
							<GridItem xs={12} sm={12} md={4}>
								<CssTextField
									error={touched.phoneNumber && Boolean(errors.phoneNumber)}
									disabled={disabledForm}
									className={styles.TextField}
									fullWidth
									label={<CustomRequiredField value="FIELD_CELLPHONE" />}
									helperText={touched.phoneNumber ? errors.phoneNumber : ''}
									id="phoneNumber"
									name="phoneNumber"
									value={values.phoneNumber}
									onChange={change.bind(null, 'phoneNumber')}
									variant="outlined"
									margin="dense"
									InputLabelProps={{
										classes: {
											disabled: values.id !== '' ? classes.labelTexRF : '',
										},
									}}
									style={{ backgroundColor: '#fff' }}
									InputProps={{
										inputComponent: PhoneMaskCustom,
									}}
								/>
							</GridItem>
						</GridContainer>

						<GridContainer style={{ marginBottom: '23px', marginTop: '30px' }}>
							<GridItem xs={12}>
								<Section title={t('TITLE_USERS_ACCESS_GROUP')} />
							</GridItem>
						</GridContainer>

						{<span>Informe o tipo de acesso que este usuário será associado</span>}
						<GridContainer style={{ marginBottom: '30px' }}>
							<GridItem
								xs={12}
								sm={6}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								{
									<>
										<CssTooltip
											arrow
											id={`tooltip-1linked`}
											placement="top-start"
											title="Ao habilitar esta permissão, o usuário terá acesso aos grupos que administram a aplicação do parceiro"
										>
											<FormControlLabel
												control={
													<Checkbox
														onClick={handleChangeSwitch.bind(this, 'admin')}
														checkedIcon={<Check className={classes.checkedIcon} />}
														icon={<Check className={classes.uncheckedIcon} />}
														classes={{
															checked: classes.checked,
															root: classes.checkRoot,
														}}
														inputProps={{
															id: 'checkRegulation',
														}}
														id="isAdminUser"
														name="isAdminUser"
														checked={values.isAdminUser}
														disabled={disabledForm}
													/>
												}
												classes={{
													label: classes.label,
												}}
												label={'Administrador'}
											/>
										</CssTooltip>

										{auth && (
											<CssTooltip
												arrow
												id={`tooltip-1linked`}
												placement="top-start"
												title="Ao habilitar esta permissão, o usuário poderá ser associado aos PDV's vinculados a este parceiro."
											>
												<FormControlLabel
													control={
														<Checkbox
															onClick={handleChangeSwitch.bind(this, 'pdv')}
															checkedIcon={<Check className={classes.checkedIcon} />}
															icon={<Check className={classes.uncheckedIcon} />}
															classes={{
																checked: classes.checked,
																root: classes.checkRoot,
															}}
															inputProps={{
																id: 'checkRegulation',
															}}
															id="isPDVUser"
															name="isPDVUser"
															checked={values.isPDVUser}
															disabled={disabledForm}
														/>
													}
													classes={{
														label: classes.label,
													}}
													label={'PDV Virtual'}
												/>
											</CssTooltip>
										)}
									</>
								}
							</GridItem>
						</GridContainer>

						<GridContainer style={{ marginBottom: '30px' }}>
							<GridItem xs={12} sm={12} lg={4}>
								<Select
									value={values.selectedGroup}
									placeholder={t('TITLE_GROUP_NAME')}
									noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
									onChange={changeSelectedGroup.bind(null, 'selectedGroup')}
									styles={customSelectStyles}
									options={selectItemsGroups}
									disabled={disabledForm}
								/>
							</GridItem>
							<GridItem sx={12} sm={12} lg={2}>
								<Button
									color="primary"
									size="sm"
									variant="contained"
									className={classes.marginRight}
									disabled={values.selectedGroup.length === 0 ? true : false}
									onClick={handleAddItem}
								>
									{t('BUTTON_ADD')}
								</Button>
							</GridItem>
						</GridContainer>
						{/* - DATATABLE */}

						{auth && (
							<GridContainer justifyContent="center">
								<GridItem xs={12} sm={12} md={12}>
									<GroupsList valuesCache={userGroupsArr} remove={id => removeGroup(id)} disabledForm={disabledForm} />
								</GridItem>
							</GridContainer>
						)}
					</GridItem>
				</GridContainer>
			</GridContainer>
		</S.Container>
	)
}

class UsersForm extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			selectItemsGroups: [],
			selectItemsGroupsAll: [],
			selectItemsGroupsOptions: [],
			selectedGroup: '',
			disabledForm: true,
			editMode: false,
			id: '',
			UserGroups: [],
			name: '',
			email: '',
			userName: '',
			cpf: '',
			phoneNumber: '',
			isAdminUser: false,
			isPDVUser: false,
			isActive: '',
			identityId: '',
			userGroupsArr: [],
			breadcrumbItems: [
				{ name: 'Home' },
				{ name: 'Configurações' },
				{ name: 'Usuários' },
				{
					name: _.includes(this.props.match.path, 'new') ? 'Novo' : _.includes(this.props.match.path, 'edit') ? 'Editar' : 'Visualizar',
					last: true,
				},
			],
			BreadCrumbComp: '',
		}
	}

	componentDidMount() {
		let _new = _.includes(this.props.match.path, 'new')
		let _edit = _.includes(this.props.match.path, 'edit')
		if (_new) {
			this.setState({ editMode: _new, disabledForm: !_new })
		} else if (_edit) {
			this.setState({ editMode: _edit, disabledForm: !_edit })
		} else {
			this.setState({ editMode: false, disabledForm: true })
		}
		let _id = this.props.match.params.id
		if (_id) {
			this.setState({ identityId: _id })
			this.props.ShowLoader(<Loader />)
			this.props.GetPartnerUserById(_id)
		}

		this.getGroupsByType(false, false)

		if (!this.props.userData) {
			this.props.GetCurrentPartner()
		} else {
			this.props.GetAccessGroupsData('', '', this.props.userData.id)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.accessGroups !== this.props.accessGroups) {
			// this.setState({
			// 	selectItemsGroups: this.props.accessGroups.results
			// }, () => {
			// 	const id = this.props.match && this.props.match.params.id;
			// 	id && this.props.GetPartnerUserById(id);
			// });
		}

		if (prevState.isAdminUser !== this.state.isAdminUser || prevState.isPDVUser !== this.state.isPDVUser) {
			this.getGroupsByType(this.state.isAdminUser, this.state.isPDVUser)
		}

		if (prevProps.userPartnerData !== this.props.userPartnerData) {
			if (this.props.userPartnerData) {
				this.props.GetAccessGroupsData('', '', this.props.userPartnerData.id)
			}
		}

		if (prevProps.userPartnerByIdData !== this.props.userPartnerByIdData) {
			//{key: values.selectedGroup.value, label:values.selectedGroup.label}
			this.setState({
				...this.props.userPartnerByIdData,
				userName: this.props.userPartnerByIdData.cpf,
				userGroupsArr: this.props.userPartnerByIdData.groups.map(item => {
					return { key: item.key, label: item.value }
				}),
				UserGroups: this.getUserGroupIdList(this.props.userPartnerByIdData.groups),
			})
			this.props.HideLoader()
		}

		if (prevProps.userPartnerByIdError !== this.props.userPartnerByIdError) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.history.goBack()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{_.isArray(this.props.userPartnerByIdError.errors)
						? this.props.userPartnerByIdError.errors.map(error => [this.props.t(error.description ? error.description : error.message)])
						: this.props.t(
							this.props.userPartnerByIdError.description ? this.props.userPartnerByIdError.description : this.props.userPartnerByIdError.message,
						)}
				</Alert>,
			)
			this.props.HideLoader()
		}

		if (prevProps.userUpdate !== this.props.userUpdate) {
			this.props.HideLoader()
			if (this.props.userUpdate) {
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={''}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.history.goBack()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.t('FIELD_ALERT_SUCCESS')}
					</Alert>,
				)
			} else {
				this.props.HideLoader()
				this.setState({ editMode: true, disabledForm: false })
				this.props.SweetAlert(
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={'Erro'}
						onConfirm={() => {
							this.props.HideAlert()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{_.isArray(this.props.userUpdate.errors)
							? this.props.userUpdate.errors.map(error => [this.props.t(error.description ? error.description : error.message)])
							: this.props.t(this.props.userUpdate.description ? this.props.userUpdate.description : this.props.userUpdate.message)}
					</Alert>,
				)
			}
		}
		if (prevProps.userPartnerCreateNewUser !== this.props.userPartnerCreateNewUser) {
			if (this.props.userPartnerCreateNewUser.success) {
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={''}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.history.goBack()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.t('FIELD_ALERT_SUCCESS')}
					</Alert>,
				)
			} else {
				this.setState({ editMode: true, disabledForm: false })
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={'Erro'}
						onConfirm={() => {
							this.props.HideAlert()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{_.isArray(this.props.userPartnerCreateNewUser.errors)
							? this.props.userPartnerCreateNewUser.errors.map(error => [this.props.t(error.description ? error.description : error.message)])
							: this.props.t(
								this.props.userPartnerCreateNewUser.description
									? this.props.userPartnerCreateNewUser.description
									: this.props.userPartnerCreateNewUser.message,
							)}
					</Alert>,
				)
			}
		}

		if (prevProps.userDataFailed !== this.props.userDataFailed) {
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Erro'}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}

		if (prevProps.userPostFailed !== this.props.userPostFailed) {
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Erro'}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}

		if (prevProps.userPartnerError !== this.props.userPartnerError) {
			this.setState({ editMode: true, disabledForm: false })
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Erro'}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}
	}

	getGroupsByType = (admin, pdv) => {
		// Endpoint:/api/Partner/GetPermissionPartnerUser?isAdmin=true&isPDVUser=true
		// Parâmetros: -isAdmin -isPDVUser
		axios.get(`/Partner/GetPermissionPartnerUser?isAdminUser=${admin}&isPDVUser=${pdv}`).then(response => {
			//value: group.key, label: group.value
			let selectItemsGroups = []
			response.data.map(group =>
				selectItemsGroups.push({
					value: group.key,
					label: group.value,
				}),
			)
			this.setState({ selectItemsGroups: selectItemsGroups })
			this.setState({ selectItemsGroupsAll: response.data })
		})
	}

	getGroupsOptions = value => {
		const inputValue = value.trim().toLowerCase()

		return this.state.selectItemsGroupsAll
			.filter(x => x.value.toLowerCase().includes(inputValue.toLowerCase()))
			.map(group => {
				return { value: group.key, label: group.value }
			})
	}

	getUserGroupIdList = userGroup => {
		const groups = userGroup.map(item => item.key)
		return groups
	}

	handleSave = values => {
		this.props.ShowLoader(<Loader />)
		this.setState({ editMode: false, disabledForm: true })
		let userGroups = []
		values.UserGroups.map(obj => userGroups.push(obj.key || obj))
		values.userName = values.userName
			.replace('.', '')
			.replace('.', '')
			.replace('.', '')
			.replace('-', '')
		values.phoneNumber = values.phoneNumber
			.replaceAll(' ', '')
			.replaceAll('(', '')
			.replaceAll(')', '')
			.replaceAll('.', '')
		values.UserGroups = userGroups
		this.props.PostPartnerCreateNewUser(this.props.userData.id, values)
	}

	CancelForm() {
		this.setState({ editMode: false, disabledForm: true })
		this.props.HideLoader()
		this.props.HideAlert()
		this.props.history.goBack()
	}

	handleBack = () => {
		this.props.history.goBack()
	}

	handleCancel = () => {
		if (this.formik.current && _.isEmpty(this.formik.current.state.touched)) {
			this.CancelForm()
		} else {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => this.CancelForm()}
					onCancel={() => this.props.HideAlert()}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
					confirmBtnText={this.props.t('BUTTON_YES')}
					cancelBtnText={this.props.t('BUTTON_NO')}
					showCancel
					reverseButtons={true}
				>
					{this.props.t('FIELD_GENERAL_RULES_ALERT')}
				</Alert>,
			)
		}
	}

	handleEdit = () => {
		this.props.history.push(`/partnerusers/edit/${this.state.identityId}`)
	}

	findZipCode = data => {
		APILocation.FindZipCode(data).then(rest => {
			//  //console.log("FindZipCode: ", rest);
		})
	}
	//
	findCNPJ = data => {
		APILocation.FindCNPJ(data).then(rest => {
			// //console.log("FindCNPJ: ", rest);
		})
	}

	handleAdd = arr => {
		this.setState({ userGroupsArr: arr })
	}

	handleRemove = arr => {
		this.setState({ userGroupsArr: arr })
	}

	render() {
		const { classes, t } = this.props
		const {
			editMode,
			disabledForm,
			name,
			cpf,
			phoneNumber,
			selectItemsGroupsAll,
			isAdminUser,
			isPDVUser,
			email,
			userName,
			isActive,
			UserGroups,
			selectItemsGroups,
			selectedGroup,
			identityId,
		} = this.state
		const values = {
			name,
			cpf,
			isAdminUser,
			isPDVUser,
			email,
			phoneNumber,
			userName,
			isActive,
			UserGroups,
			selectedGroup,
			identityId,
		}

		return (
			<div>
				<DocumentTitle title={getProgramTitle()} />
				<Formik
					ref={this.formik}
					render={props => (
						<Form
							{...props}
							t={t}
							classes={classes}
							selectItemsGroups={selectItemsGroups}
							selectItemsGroupsAll={selectItemsGroupsAll}
							disabledForm={disabledForm}
							handleCancel={this.handleCancel}
							handleBack={this.handleBack}
							handleEdit={this.handleEdit}
							getGroupsOptions={this.getGroupsOptions}
							editMode={editMode}
							idValid={this.state.id !== 0}
							getGroupsByType={this.getGroupsByType}
							userGroupsArr={this.state.userGroupsArr}
							handleAdd={this.handleAdd}
							handleRemove={this.handleRemove}
						/>
					)}
					validationSchema={validationSchema}
					onSubmit={this.handleSave}
					initialValues={values}
					enableReinitialize={true}
				/>
			</div>
		)
	}
}

const mapStateToProps = state => {
	return {
		accessGroups: state.access.accessGroups,
		userData: state.users.userData,
		userDataFailed: state.users.userDataFailed,

		userPartnerByIdData: state.users.userPartnerByIdData,
		userPartnerByIdError: state.users.userPartnerByIdError,

		userPartnerCreateNewUser: state.users.userPartnerCreateNewUser,

		userPartnerData: state.users.userPartnerData,
		userPartnerError: state.users.userPartnerError,

		userPostFailed: state.users.userPostFailed,

		userUpdate: state.users.userUpdate,
		userUpdateFailed: state.users.userUpdateFailed,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetAccessGroupsData,
			GetPartnerUserById,
			UpdateUser,
			UpdatePartnerUserData,
			CreateNewUser,
			PostPartnerCreateNewUser,
			GetCurrentPartner,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(userStyle),
	withTranslation(),
)(UsersForm)
