import * as Yup from 'yup'
import _ from 'lodash'
import { VerifyCPF, VerifyTelefone } from '../../../../utils/utils'

export const validationSchema = Yup.object({
	businessName: Yup.string()
		.nullable()
		.required(' obrigatório'),
	businessCellPhone: Yup.string()
		.required('Celular obrigatório')
		.nullable()
		.test('validar-celular', 'cellphone - INVALID_VALUE', function (x) {
			if (!_.isEmpty(x)) {
				let rawValue = x.replace(/[^\d]+/g, '')

				const regex = /^[0-9]{2}9[0-9]{8}$/
				return regex.test(rawValue)
			}
		}),
	businessPhone: Yup.string()
		.nullable()
		.test('valid', 'Número de telefone inválido', x => {
			if (!_.isEmpty(x)) {
				return VerifyTelefone(x)
			}
			return true
		}),
	businessEmail: Yup.string()
		.nullable()
		.email('Email inválido')
		.required(' obrigatório'),
	businessMarketingArea: Yup.object({
		value: Yup.string()
			.nullable()
			.required(' obrigatório'),
	}),
	addresses: Yup.array().of(
		Yup.object({
			zipCode: Yup.string()
				.nullable()
				.matches(/^([0-9]{5}-[0-9]{3})|([0-9]{8})/, {
					message: 'inválido',
					excludeEmptyString: true,
				})
				.required('CEP obrigatório'),
			location: Yup.string()
				.nullable()
				.required('Endereço obrigatório'),
			number: Yup.string()
				.max(7, 'máximo de 7 caracteres')
				.nullable()
				.required('Número obrigatório')
				.min(1, 'mínimo de 1 caracter'),
			complement: Yup.string().nullable(),
			district: Yup.string()
				.required('Bairro obrigatório')
				.nullable(),
			cityId: Yup.object()
				.required('Cidade obrigatória')
				.nullable(),
			stateId: Yup.object()
				.nullable()
				.required('Estado obrigatório'),
			countryId: Yup.object()
				.nullable()
				.required('País obrigatório'),
		}),
	),
	// businessCep: Yup.string().required(' obrigatório'),
	// businessAddress: Yup.string().required(' obrigatório'),
	// businessAddressNeighborhood: Yup.string().required(' obrigatório'),
	// businessAddressUF: Yup.string().required(' obrigatório'),
	// businessCity: Yup.string().required(' obrigatório'),
	// businessCountry: Yup.string().required(' obrigatório'),
	responsibleDocument: Yup.string()
		.nullable()
		.required('Required')
		.test('cpf', 'CPF inválido', function (x) {
			if (VerifyCPF(x)) {
				return true
			}

			return false
		}),
	responsibleName: Yup.string()
		.nullable()
		.required(' obrigatório'),
	responsibleCellphone: Yup.string()
		.required('Celular obrigatório')
		.nullable()
		.test('validar-celular', 'cellphone - INVALID_VALUE', function (x) {
			if (!_.isEmpty(x)) {
				let rawValue = x.replace(/[^\d]+/g, '')

				const regex = /^[0-9]{2}9[0-9]{8}$/
				return regex.test(rawValue)
			}
		}),
	responsibleEmail: Yup.string()
		.nullable()
		.email('Email inválido')
		.required(' obrigatório'),
	benefitGroup: Yup.object()
		.nullable()
		.required('Agrupador obrigatório'),
})
