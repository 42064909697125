import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

import CustomizedIconButton from '../Buttons/CustomizedIconButton'

const DivisionOptions = ({ multiple, setMultiple, setSelectedDivision, setParameterization, editMode, setEditMode, selectedDivisionIdRed }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<S.Content>
				{!editMode && <CustomizedIconButton title={t('Editar')} className="softBlue" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />}

				{editMode && (
					<Fragment>
						<CustomizedIconButton
							className="softBlue"
							title={t('Parametrização Múltipla')}
							Icon="icon-bot_parametrizar_multiplo"
							onClick={() => setMultiple(!multiple)}
						/>

						<CustomizedIconButton
							title={t('Configurar parametrização múltipla')}
							className="blueAction"
							Icon="icon-bot_parametrizar"
							disabled={!multiple || selectedDivisionIdRed.length === 0}
							onClick={() =>
								setParameterization(prev => ({
									...prev,
									show: true,
									isMultiple: true,
								}))
							}
						/>

						<CustomizedIconButton
							className="cancel"
							title={t('Cancelar')}
							Icon="icon-bot_fechar"
							onClick={() => {
								setParameterization(prev => ({
									...prev,
									show: false,
									isMultiple: false,
								}))
								{
									multiple && setMultiple(!multiple)
								}
								setSelectedDivision([])
								setEditMode(false)
							}}
						/>
					</Fragment>
				)}
			</S.Content>
		</S.Container>
	)
}

DivisionOptions.propTypes = {
	multiple: PropTypes.bool,
	editMode: PropTypes.bool,
	setMultiple: PropTypes.func,
	setEditMode: PropTypes.func,
	setIsMultiple: PropTypes.func,
	setParameterization: PropTypes.func,
	setSelectedDivision: PropTypes.func,
	selectedDivisionIdRed: PropTypes.array,
}

export default DivisionOptions
