import { Grid } from '@material-ui/core'
import styled, { css } from 'styled-components'

export const Container = styled(Grid)`
    border-radius: 0.25rem;
    padding: 1rem;

    span {
        font-size: 15px;
        color: #333333;
    }

    input[type='text'] {
        width: 32px;
        height: 40px;
        border-radius: 0.25rem;
        border: 1px solid #c4c4c4;
        font-size: 20px;
        font-weight: bold;
    }

    .input-box {
        text-align: center;
    }

    ${({ error }) =>
        !!error &&
        css`
            input[type='text'] {
                border-color: #de2e2e;
                color: #de2e2e;
            }
        `}

    @media screen and (min-width: 960px) {
        .input-box {
            padding-left: ${({ title }) => title && '1rem'};
            text-align: ${({ title }) => (title ? 'start' : 'center')};
        }
    }
`
