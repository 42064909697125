/* eslint-disable */
export const useBrasilCoin = value => {
	if (!value) return `R$ 0,00`

	const newValue =
		value &&
		value.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		})

	return newValue
}

export const GetTextColor = themeColor => {
	return (
		themeColor &&
		themeColor.titles &&
		themeColor.titles.secondary &&
		themeColor.titles.secondary.textColor
	)
}

export const ConvertToNumber = (originalValueArray, value, max) => {
	if (originalValueArray.length === 0) return 0
	const originalValue = Number.parseInt(originalValueArray.join(''))

	if (originalValue / 100 <= max) {
		return originalValue / 100
	}

	return value
}

export const handleChangeSplit = value => {
	const newValue = parseFloat(`${value}`).toFixed(2)
	return newValue && newValue.replace('.', '')
}

export default useBrasilCoin
