import { Grid, Tooltip } from '@material-ui/core'
import { Delete } from '@material-ui/icons'

import React, { useEffect, useState } from 'react'
import { useTranslation, withTranslation } from 'react-i18next'
import * as Styled from './styles'
import MaterialTable from 'material-table'
import { TablePagination } from '@material-ui/core'

import NoResults from 'components/NoResults/NoResults.jsx'
import Button from 'components/CustomButtons/Button.jsx'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

const initialPagination = {
	currentPage: 0,
	pageCount: 1,
	pageSize: 10,
	recordCount: 1,
}

function GroupsPermission({ valuesCache, remove, disabledForm }) {
	const { t } = useTranslation()
	const [pagination, setPagination] = useState(initialPagination)
	const [tableData, setTableData] = useState([])
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		setTableData(valuesCache)
	})

	const handleRemove = (props) => {
		remove(props)
	}

	const getColor = () => {
		return (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor &&
			themeColor.navigation.buttons.textColor
		)
	}

	return (
		<>
			{tableData ? (
				<Styled.TableGrid>
					<MaterialTable
						title={false}
						search={false}
						noToolbar={true}
						components={{
							Pagination: (props) => {
								return (
									<TablePagination
										{...props}
										rowsPerPageOptions={[]}
										count={pagination.recordCount}
										page={pagination.currentPage}
										rowsPerPage={pagination.pageSize}
										onChangePage={(e, page) =>
											setPagination((prevState) => ({
												...prevState,
												currentPage: page,
											}))
										}
									/>
								)
							},
						}}
						options={{
							emptyRowsWhenPaging: false,
							doubleHorizontalScroll: false,
							search: false,
							toolBar: false,
							paging: false,
							pageSizeOptions: false,
							loadingType: 'overlay',
							headerStyle: {
								color:
									themeColor &&
									themeColor.titles &&
									themeColor.titles.highlight &&
									themeColor.titles.highlight.textColor,
							},
						}}
						onChangeRowsPerPage={(e) =>
							e !== pagination.pageSize
								? setPagination((prevState) => ({
									...prevState,
									currentPage: 0,
									pageSize: e,
								}))
								: ''
						}
						localization={{
							header: {
								actions: 'Ações',
							},
							pagination: {
								labelRowsSelect: 'linhas',
							},
							body: {
								emptyDataSourceMessage:
									'Nenhum registro encontrado.',
							},
						}}
						data={tableData}
						isLoading={!tableData}
						columns={[
							{
								title: 'Nome do grupo',
								field: 'value',
								headerStyle: {
									textAlign: 'left',
									fontSize: '15px',
								},
								render: (props) => <div>{props.label}</div>,
							},
							{
								title: 'Ações',
								field: 'value',
								sorting: false,
								cellStyle: {
									width: '50px !important',
								},
								headerStyle: {
									width: '50px !important',
									fontSize: '15px',
								},
								render: (props) => (
									<Button
										justIcon
										size="sm"
										color="greenButtonColor"
										className="detail-button"
										onClick={() => handleRemove(props)}
										style={{
											marginRight: '30px',
										}}
										disabled={disabledForm}
									>
										{
											<i
												style={{
													fontSize: 14,
												}}
												className="icon-ico_excluir"
											/>
										}<Tooltip
											arrow
											title={t("BUTTON_DELETE")}
											placement='left'
										>
											<Delete
												style={{ color: `${getColor()}` }}
											/>
										</Tooltip>
									</Button>


								),
							},
						]}
					/>
				</Styled.TableGrid>
			) : (
				<Grid xs={12} style={{ justifyContent: 'center' }}>
					<div style={{ margin: 'auto', width: '100%' }}>
						<NoResults
							value={t('NO_GROUPS_ASSOCIATED_TO_THIS_USER')}
						/>
					</div>
				</Grid>
			)}
		</>
	)
}

export default withTranslation()(GroupsPermission)
