/* eslint-disable */
import React, { useState, useEffect, useContext, Fragment } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import InputMask from 'react-input-mask'
import { HideLoader } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@material-ui/icons/Close'
import Edit from '@material-ui/icons/Edit'
import MaterialTable from 'material-table'
import TextField from '@material-ui/core/TextField'
import { Search } from '@material-ui/icons'
import { Grid, TablePagination, withStyles } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import ConfirmModal from 'components/ConfirmModal'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Section from 'components/Section'

import * as Style from '../styles'
import GeneralContext from '../../../GeneralContext'
import SponsorContext from '../context'
import { GetSponsors, DeleteSponsor } from 'redux/api/sponsor'

import { grayColor } from 'assets/jss/material-dashboard-pro-react'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { getProgramTitle } from '../../../utils/utils'

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: getThemeColorFromLocalStorage() &&
				getThemeColorFromLocalStorage().structure &&
				getThemeColorFromLocalStorage().structure.backgroundColor
				? getThemeColorFromLocalStorage().structure.backgroundColor
				: "#fff !important",
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: "#fff !important",
			},
			'&.Mui-focused fieldset': {
				borderColor: getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: "#fff !important",
			},
		},
	},
})(TextField)

const initialPagination = {
	currentPage: 0,
	pageCount: 1,
	pageSize: 10,
	recordCount: 1,
	filter: {
		cnpj: '',
		companyName: '',
		name: '',
	},
}

export const validation = Yup.object().shape({
	cnpj: Yup.string().nullable(),
})

function SponsorList({ classes }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [pagination, setPagination] = useState(initialPagination)
	const { setSponsor, sponsor, setEditing, editing, setSwitchComp } =
		useContext(SponsorContext)
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [confirmModalProps, setConfirmModalProps] = useState({
		open: false,
		type: '',
		text: '',
	})
	const [themeColor, setThemeColor] = useState()

	const { setSnackStatus } = useContext(GeneralContext)

	const { handleSubmit, control, reset } = useForm({
		validationSchema: validation,
		mode: 'onBlur',
	})

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		dispatch(HideLoader())
		requestApi()
	}, [pagination.currentPage + 1, pagination.pageSize, pagination.filter])

	useEffect(() => {
		var currentUrl = window.location.pathname

		if (currentUrl.toString() === '/sponsors/new') {
			setSwitchComp('form')
		}
	}, [])

	async function requestApi() {
		try {
			let filter = pagination.filter
			setLoading(true)
			const response = await GetSponsors(
				filter.name,
				filter.cnpj,
				filter.companyName,
				pagination.pageSize,
				pagination.currentPage + 1,
			)
			setPagination((prevState) => ({
				...prevState,
				recordCount: response.recordCount,
				pageSize: response.pageSize,
			}))
			setList(response.results)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function hideModal() {
		setConfirmModalProps({ open: false, type: '', text: '' })
	}

	function handleDetail(sponsorId) {
		setSponsor(sponsorId)
		setSwitchComp('form')
	}

	function handleEdit(sponsorId) {
		setEditing(true)
		setSponsor(sponsorId)
		setSwitchComp('form')
	}

	function handleDelete(sponsorId) {
		setConfirmModalProps((prevState) => ({
			...prevState,
			open: true,
			type: 'delete',
			text: 'Deseja realmente excluir este patrocinador ?',
		}))
		setSponsor(sponsorId)
	}

	async function confirmDelete() {
		setLoading(true)
		try {
			const response = await DeleteSponsor(sponsor)
			await requestApi(pagination)
		} catch (error) {
		} finally {
			setLoading(false)
			hideModal()
			setSponsor('')
			setSnackStatus((prevState) => ({ ...prevState, open: true }))
		}
	}

	function formatCnpjCpf(value) {
		const cnpjCpf = value.replace(/\D/g, '')

		if (cnpjCpf.length === 11) {
			return cnpjCpf.replace(
				/(\d{3})(\d{3})(\d{3})(\d{2})/g,
				'$1.$2.$3-$4',
			)
		}

		return cnpjCpf.replace(
			/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
			'$1.$2.$3/$4-$5',
		)
	}

	function handleCleanFilter() {
		reset({
			cnpj: '',
			companyName: '',
			name: '',
		})
		setPagination((prevState) => ({
			...prevState,
			filter: {
				...[],
			},
		}))
	}

	function sendForm(formData) {
		setPagination((prevState) => ({
			...prevState,
			filter: {
				...formData,
			},
		}))
		//  requestApi(formData)
	}

	return (
		<SponsorContext.Provider
			value={{
				setSwitchComp,
				setSponsor,
				setEditing,
				editing,
			}}
		>
			<ConfirmModal
				open={confirmModalProps.open}
				type={confirmModalProps.type}
				text={confirmModalProps.text}
				onConfirm={() => confirmDelete()}
				onCancel={() => hideModal()}
			/>
			<DocumentTitle title={getProgramTitle()} />

			<Style.TitlesContainer
				xs={12}
				sm={12}
				md={12}
				style={{
					height: '40px',
					marginBottom: '15px',
					alignItems: 'center'
				}}
			>
				<Section title={t('MENU_SPONSOR')} style={{ flexGrow: '1' }} />
				<Button
					style={{ marginLeft: '15px' }}
					color="greenButtonColor"
					size="sm"
					onClick={() => setSwitchComp('form')}
				>
					{t('Cadastrar patrocinador')}
				</Button>
			</Style.TitlesContainer>
			<Style.InnerContainer container>
				<form
					onSubmit={handleSubmit(sendForm)}
					style={{ width: '100%' }}
					autoComplete="off"
				>
					<Style.SearchBox>
						<GridItem
							xs={12}
							sm={12}
							md={3}
						>
							<Controller
								as={
									<InputMask
										label={
											<CustomRequiredField
												disabled
												value="CNPJ"
											/>
										}
										variant="outlined"
										size="small"
										mask="99.999.999/9999-99"
										fullWidth
										maskChar={null}
									>
										{(props) =>
											<CssTextField
												style={{ backgroundColor: 'white ' }}
												{...props}
											/>
										}
									</InputMask>
								}
								name="cnpj"
								defaultValue=""
								control={control}
							/>
						</GridItem>
						<GridItem
							xs={12}
							sm={12}
							md={5}
						>
							<Controller
								as={
									<CssTextField
										id="name"
										name="name"
										label={
											<CustomRequiredField
												disabled
												value="FIELD_REGISTER_NAME"
											/>
										}
										value={''}
										variant="outlined"
										size="small"
										style={{ backgroundColor: 'white ' }}
										fullWidth
									/>
								}
								defaultValue=""
								name="name"
								control={control}
							/>
						</GridItem>
						<GridItem
							xs={12}
							sm={12}
							md={4}
						>
							<Controller
								as={
									<CssTextField
										id="campanyName"
										name="campanyName"
										label={
											<CustomRequiredField
												disabled
												value="FIELD_COMPANY_NAME"
											/>
										}
										value={''}
										variant="outlined"
										size="small"
										style={{ backgroundColor: 'white ' }}
										fullWidth
									/>
								}
								defaultValue=""
								name="companyName"
								control={control}
							/>
						</GridItem>
						<Style.GroupButtons
							xs={12}
							sm={12}
							md={12}
							style={{ marginTop: '20px' }}
						>
							<Button
								style={{ margin: 0 }}
								color="greenBorderButtonColor"
								size="sm"
								startIcon={
									<div>
										<i
											style={{
												fontSize: 14,
												padding: '2px 0',
											}}
										/>
									</div>
								}
								onClick={() => handleCleanFilter()}
							>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>
							<Button
								style={{ margin: 0 }}
								color="greenButtonColor"
								size="sm"
								onClick={handleSubmit(sendForm)}
							>
								{t('BUTTON_FIND')}
							</Button>
						</Style.GroupButtons>
					</Style.SearchBox>
				</form>
				<Style.GridBox>
					<Style.GridContent style={{ width: '100%' }}>
						<MaterialTable
							style={{ width: '100%' }}
							noToolbar
							components={{
								Pagination: (props) => {
									return (
										<TablePagination
											{...props}
											rowsPerPageOptions={[
												10, 20, 30, 50, 100,
											]}
											count={pagination.recordCount}
											page={pagination.currentPage}
											rowsPerPage={pagination.pageSize}
											onChangePage={(e, page) =>
												setPagination((prevState) => ({
													...prevState,
													currentPage: page,
												}))
											}
										/>
									)
								},
							}}
							title=""
							options={{
								emptyRowsWhenPaging: false,
								search: false,
								toolBar: false,
								sorting: false,
								pageSize: pagination.pageSize,
								headerStyle: {
									color:
										themeColor &&
										themeColor.titles &&
										themeColor.titles.highlight &&
										themeColor.titles.highlight.textColor,
									fontWeight: '700',
									fontSize: '15px !important',
									lineHeight: '1.2',
									background: '#E9E9E9 ',
									textAlign: 'left !important',
								},
							}}
							columns={[
								{
									title: 'CNPJ',
									field: 'cnpj',
									cellStyle: {
										minWidth: '30%',
										width: '30%',
										maxWidth: '30%',
									},
									headerStyle: {
										minWidth: '30%',
										width: '30%',
										maxWidth: '30%',
									},
									render: (props) =>
										formatCnpjCpf(props.cnpj),

								},
								{
									title: 'Nome',
									field: 'name',
									cellStyle: {
										minWidth: '20%',
										width: '20%',
										maxWidth: '20%',
									},
									headerStyle: {
										textAlign: 'left',
										minWidth: '20%',
										width: '20%',
										maxWidth: '20%',
									},

								},
								{
									title: 'Ações',
									field: 'actions',
									cellStyle: {
										minWidth: '126px',
										width: '126px',
										maxWidth: '126px',
									},
									headerStyle: {
										textAlign: 'left',
										paddingLeft: '20px',
										minWidth: '126px',
										width: '126px',
										maxWidth: '126px',
									},
									render: (props) => (
										<div style={{
											display: 'flex',
											paddingLeft: '15px',
											justifyContent: 'flex-end'
										}}>
											<Style.GridButton
												justIcon
												size="sm"
												color="greenButtonColor"
												title={t('BUTTON_VIEW')}
												onClick={() =>
													handleDetail(props.id)
												}
											>
												<Search className={"op-icon"} />
											</Style.GridButton>
											<Style.GridButton
												justIcon
												size="sm"
												color="greenButtonColor"
												title={t('BUTTON_EDIT')}
												// disabled={true}
												onClick={() =>
													handleEdit(props.id)
												}
											>
												<Edit className={"op-icon"} />
											</Style.GridButton>
											<Style.GridButton
												justIcon
												size="sm"
												color="greenButtonColor"
												title={t('BUTTON_REMOVE')}
												onClick={() =>
													handleDelete(props.id)
												}
											>
												<CloseIcon className={"op-icon"} />
											</Style.GridButton>
										</div>
									),
								},
							]}
							data={list}
							onChangeRowsPerPage={(e) =>
								e !== pagination.pageSize
									? setPagination((prevState) => ({
										...prevState,
										pageSize: e,
									}))
									: ''
							}
							localization={{
								header: {
									actions: 'Ações',
								},
								pagination: {
									labelRowsSelect: 'linhas',
									firstTooltip: '',
									lastTooltip: '',
									nextTooltip: '',
									previousTooltip: '',
								},
								body: {
									emptyDataSourceMessage:
										'Nenhum registro encontrado.',
								},
							}}
							isLoading={loading}
						/>
					</Style.GridContent>
				</Style.GridBox>
			</Style.InnerContainer>
		</SponsorContext.Provider>
	)
}

export default withStyles()(withStyles()(SponsorList))
