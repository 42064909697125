import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

export const InputText = styled(TextField)({
	backgroundColor: '#ffffff',
	width: '100%',
	marginTop: '3px',
	'& label.Mui-focused': {
		color: getThemeColorFromLocalStorage()?.headers?.backgroundColor,
	},
	'& label': {
		lineHeight: 1.2,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		},
		'&.Mui-focused fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		},
	},
})
