import styled from 'styled-components'
import Button from 'components/CustomButtons/Button.jsx'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	background: #f2f2f2 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	padding: 14px;

	.indicator {
		height: 47px;
		border-radius: 5px 0px 0px 5px;
	}
`

export const GroupButtons = styled.div`
	display: flex;
	gap: 20px;
`

export const CustomButton = styled(Button)`
	box-shadow: none !important;
	background-color: ${({ bg }) => bg || 'inherit'} !important;
	border: 1px solid ${({ bc }) => bc || 'inherit'} !important;
	color: ${({ color }) => color || 'inherit'} !important;
	min-width: 100px !important;

	&:hover {
		background-color: ${({ hoverBg }) => hoverBg} !important;
		color: #ffffff !important;
		border: none !important;
	}
`
