import styled from 'styled-components'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

export const NewTab = styled(Tab)`
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 1em;
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	border-radius: 6px 6px 0 0;
	list-style: none;
	padding: 10px 30px;
	cursor: pointer;

	color: ${({ selected }) =>
		selected
			? `${getThemeColorFromLocalStorage() &&
			getThemeColorFromLocalStorage().navigation &&
			getThemeColorFromLocalStorage().navigation.buttons &&
			getThemeColorFromLocalStorage().navigation.buttons.textColor
			}`
			: '#636363'};
	background: ${({ selected }) =>
		selected
			? `${getThemeColorFromLocalStorage() &&
			getThemeColorFromLocalStorage().navigation &&
			getThemeColorFromLocalStorage().navigation.buttons &&
			getThemeColorFromLocalStorage().navigation.buttons
				.backgroundColor
			}`
			: '#eee'};
`

export const NewTabWhite = styled(Tab)`
	margin-top: ${({ selected }) => (selected ? '24px' : '0px')};
	text-transform: uppercase;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: bold;
	font-size: 0.9em;
	display: inline-block;
	border: 1px solid transparent;
	border-bottom: none;
	border-radius: 6px 6px 0 0;
	list-style: none;
	padding: 10px 30px;
	cursor: pointer;

	color: ${({ selected }) =>
		selected
			? `${getThemeColorFromLocalStorage() &&
			getThemeColorFromLocalStorage().navigation &&
			getThemeColorFromLocalStorage().navigation.buttons &&
			getThemeColorFromLocalStorage().navigation.buttons.backgroundColor
			}` : '#636363'};
	border: 1px solid #eee;
	background: #ffffff;
`

export const NewTabs = styled(Tabs)`
	-webkit-tap-highlight-color: transparent;
`

export const NewTabList = styled(TabList)`
	border-bottom: 4px solid
		${getThemeColorFromLocalStorage() &&
	getThemeColorFromLocalStorage().navigation &&
	getThemeColorFromLocalStorage().navigation.buttons &&
	getThemeColorFromLocalStorage().navigation.buttons.backgroundColor};
	margin: 0 0 10px;
	padding: 0;
`

export const NewTabPanel = styled(TabPanel)`
	display: ${({ selected }) => (selected ? 'block' : 'none')};
`
