import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import Datetime from 'react-datetime'
import DocumentTitle from 'react-document-title'
import MaskedInput from 'react-text-mask'
import Slide from '@material-ui/core/Slide'
import Switch from '@material-ui/core/Switch'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import styled from 'styled-components/macro'

// @material-ui/core component
import { Grid, Icon, Dialog, DialogContent, FormControlLabel, FormLabel, Hidden, InputAdornment, TextField } from '@material-ui/core'

import { Autocomplete } from '@material-ui/lab'
import Popper from '@material-ui/core/Popper'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles'

import AprovadaCinza from 'assets/img/03_aprovada_campanha_desabilitado.png'
import AprovadaVerde from 'assets/img/03_aprovada_campanha_habilitado.png'
import AvaliacaoCinza from 'assets/img/02_Avaliacao_campanha_desabilitado.png'
import AvaliacaoVerde from 'assets/img/02_Avaliacao_campanha_habilitado.png'
import AvaliacaoVermelha from 'assets/img/03_Pendente_campanha.png' //Avaliacao Pendente
import CriacaoCinza from 'assets/img/01_Criador_campanha_desabilitado.png'
import CriacaoVerde from 'assets/img/01_Criador_campanha_habilitado.png'
import ReprovadaVermelha from 'assets/img/04_reprovada_campanha_habilitado.png'

// core components
import Button from 'components/CustomButtons/Button.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom.jsx'
import Section from 'components/Section'
import SelectFilials from 'components/SelectFilials'
import CurrencyInput from '../../../components/NewCustomInput'

//Utils
import compose from 'utils/compose'
import { numberFormatText } from 'utils/utils'

import styles from '../campaign.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { germiniDefaultColors, grayColor, dangerColor } from 'assets/jss/material-dashboard-pro-react'

import {
	DeactivateCampaign,
	GetAllProduct,
	GetCampaignDataById,
	GetCampaignTypeListData,
	GetCurrentPartner,
	GetProductByBranches,
	GetProductById,
	GetProductTypeListData,
	GetSponsorsData,
	HideAlert,
	HideLoader,
	ListSponsorByCompanyName,
	PostCampaignData,
	PutCampaignData,
	ShowLoader,
	SweetAlert,
} from 'redux/actions'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { envs } from '../../../redux/api/windowServerData'
import { getProgramTitle } from '../../../utils/utils'

const ContainerMain = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${props =>
		props.themeColor && props.themeColor.titles && props.themeColor.titles.highlight && props.themeColor.titles.highlight.textColor};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}
	.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
		background-color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor} !important;
	}

	.MuiInputLabel-outlined.MuiInputLabel-marginDense {
		white-space: nowrap;
	}
`

const CustomPopper = function (props) {
	const classesPoper = useStyles()
	return <Popper {...props} className={classesPoper.root} placement="bottom" />
}

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				'& :hover': {
					color: '#ffffff',
					backgroundColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'& li': {
					backgroundColor: '#ffffff',
				},
			},
		},
	}),
)

const CampaignStyle = {
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	// REQUIRED FIELD
	labelTexRF: {
		lineHeight: '0 !important',
		top: '-2px',
	},
	// sem required fielvd
	labelTexF: {
		lineHeight: '0 !important',
	},
	inputHeight: {
		height: '9px',
	},
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: grayColor[2],
				},
				'&.Mui-focused fieldset': {
					borderColor: grayColor[2],
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(0,0%,95%)',
			'& fieldset': {
				borderColor: 'hsl(0,0%,90%)',
			},
		},
	},
})(TextField)

function DateMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			// mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/] }
			placeholderChar={'\u2000'}
			readOnly
		/>
	)
}

const theme = createTheme({
	overrides: {
		MuiInputLabel: {
			outlined: {
				zIndex: 0,
				transform: 'translate(14px, 20px) scale(1)',
				pointerEvents: 'none',
			},
		},
	},
	palette: {
		primary: {
			main: grayColor[2],
		},
	},
	typography: {
		useNextVariants: true,
	},
})

const validationSchema = Yup.object({
	partnerId: Yup.string().required(),
	name: Yup.string().required(),
	investment: Yup.number()
		.min(0.01)
		.required(),
	sponsorId: Yup.string().required(),
	startTerm: Yup.date().required(),
	endTerm: Yup.date().required(),
	items: Yup.array().min(1),
	partnerBranchs: Yup.array(),
	qtyLimit: Yup.boolean().required(),
	units: Yup.number().when('qtyLimit', {
		is: true,
		then: Yup.number().required(),
		otherwise: Yup.number().nullable(),
	}),
	subsidyValue: Yup.string()
		// .min(1)
		.required(),
})

function Transition(props) {
	return <Slide direction="down" {...props} />
}

const Form = props => {
	const {
		t,
		classes,
		status,
		values,
		errors,
		touched,
		handleSubmit,
		handleEdit,
		handleNewSponsor,
		handleChange,
		handleCancel,
		handleDeactivate,
		loadSponsorOptions,
		selectItemsBranches,
		listProductsByBranches,
		getProductByBranchesModal,
		getProductById,
		handleBack,
		disabledForm,
		setFieldTouched,
		setFieldValue,
		editMode,
		sponsorKey,
		selectItemsProducts,
		selectItemsSponsors,
		listSponsor,
		generalRulesData,
	} = props
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	moment.locale(localStorage.getItem('i18nextLng'))

	const customSelectStyles = name => ({
		menu: (base, state) => ({
			...base,
			zIndex: 100,
		}),
		control: (base, state) => ({
			...base,
			boxShadow: state.isFocused ? 0 : 0,
			borderColor: _.get(errors, name, false) && _.get(touched, name, false) ? dangerColor[0] : state.isFocused ? grayColor[2] : base.borderColor,
			'&:hover': {
				borderColor: state.isFocused ? grayColor[2] : base.borderColor,
			},
		}),
		multiValue: (styles, state) => {
			return {
				...styles,
				backgroundColor: state.isDisabled
					? '#9e9e9e'
					: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
				color: 'white',
				borderRadius: '5px',
				paddingRight: state.isDisabled ? '4px' : 0,
			}
		},
		placeholder: base => ({
			...base,
			fontWeight: 400,
			marginLeft: '5px',
			color: _.get(errors, name, false) && _.get(touched, name, false) ? dangerColor[0] : base.color,
		}),
		multiValueLabel: styles => ({
			...styles,
			color: 'white',
		}),
		multiValueRemove: (styles, state) => ({
			...styles,
			color: 'white',
			borderRadius: '5px',
			':hover': {
				backgroundColor: state.isDisabled ? '#9e9e9e' : germiniDefaultColors[9],
				color: 'white',
				cursor: 'pointer',
			},
			display: state.isDisabled ? 'none' : 'inherit',
		}),
	})

	useEffect(() => {
		if (values.investment) {
			var points = _.toNumber(values.investment) * generalRulesData.pointMultiplierFactor
			!disabledForm && setFieldValue('investmentPoints', points, true)
			calculateQuantity(points, values.subsidyValue)
		}
	}, [values.investment])

	const change = (name, e) => {
		if (name.includes('startTerm')) {
			if (moment(e).isValid() && (!e.length || e.length === 10)) {
				setFieldValue(name, e, true)

				if (moment(values.endTerm).isValid() && e.isAfter(values.endTerm)) {
					setFieldValue('endTerm', e, true)
					setFieldTouched('endTerm', true, false)
				}
			} else {
				setFieldValue(name, e, true)
			}
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('endTerm')) {
			if (moment(e).isValid() && (!e.length || e.length === 10)) {
				setFieldValue(name, e, true)
				setFieldTouched(name, true, false)

				if (moment(values.startTerm).isValid() && e.isBefore(values.startTerm)) {
					setFieldValue('startTerm', e, true)
					setFieldTouched('startTerm', true, false)
				}
			} else {
				setFieldValue(name, e, true)
			}
			return
		}

		if (name.includes('qtyLimit')) {
			if (e.target.value === false) {
				setFieldValue('units', null, true)
			}
		}

		if (name.includes('sponsorId')) {
			setFieldValue(name, e.value, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('selectedProduct')) {
			setFieldValue(name, e, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('number')) {
			if (name.includes('investment')) {
				var points = _.toNumber(e.target.value) * generalRulesData.pointMultiplierFactor
				!disabledForm && setFieldValue('investmentPoints', points, true)
				calculateQuantity(points, values.subsidyValue)
			}

			if (name.includes('subsidyValue')) {
				if (_.toNumber(e.target.value) > _.toNumber(values.redeemValue)) {
					e.target.value = values.redeemValue
				}
				calculateQuantity(values.investmentPoints, e.target.value)
				calculateNewRedeemValue(values.redeemValue, e.target.value)
			}

			setFieldValue(name.split('|')[1], e.target.value, true)
			setFieldTouched(name, true, false)

			return
		}

		if (name.includes('units')) {
			setFieldValue(name, e.target.value, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('items')) {
			var array = []
			array.push(e.value)
			setFieldValue(name, array, true)
			setFieldTouched(name, true, false)
			setProductValues(e.value)
			return
		}

		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	const calculateQuantity = (investment, subsidy) => {
		if (subsidy && subsidy > 0 && investment && investment > 0) {
			var qtd = Math.floor(_.toNumber(investment).toFixed(2) / _.toNumber(subsidy).toFixed(2))
			setFieldValue('quantityProduct', qtd, true)
		} else {
			setFieldValue('quantityProduct', 0, true)
		}
	}

	function handleFilialModal() {
		setFieldValue('openFilialModal', true)
	}

	const calculateNewRedeemValue = (redeem, subsidy) => {
		if (redeem && subsidy) {
			var newValue = redeem - subsidy
			setFieldValue('newRedeemValue', newValue > 0 ? newValue : 0, false)
		} else {
			setFieldValue('newRedeemValue', 0, false)
		}
	}

	const setProductValues = productId => {
		getProductById(productId).then(rest => {
			if (rest && rest.typeOfRedeem.key !== '1' && rest.parameterizationRedemptionPoints) {
				if (rest.typeOfRedeem.key === '2') {
					setFieldValue('redeemValue', rest.parameterizationRedemptionPoints.standardPointsValue, true)
					setFieldValue('receivableValue', rest.parameterizationRedemptionPoints.standardValueReceivable, true)
					calculateNewRedeemValue(rest.parameterizationRedemptionPoints.standardPointsValue, values.subsidyValue)
				} else {
					setFieldValue('redeemValue', rest.redemptionPoints, true)
					setFieldValue('receivableValue', rest.parameterizationRedemptionPoints.customValueReceivable, true)
					calculateNewRedeemValue(rest.redemptionPoints, values.subsidyValue)
				}
			} else {
				setFieldValue('redeemValue', 0, true)
				setFieldValue('receivableValue', 0, true)
			}
		})
	}

	const selectedBranches = modalBranches => {
		let checkedBranches = []
		modalBranches.forEach(item => {
			if (item.check === true) {
				checkedBranches.push({ partnerBranchId: item.id })
			}
		})
		values.partnerBranchs = checkedBranches

		let productId = _.flatMap(checkedBranches, 'partnerBranchId')
		function configureArray(id) {
			return 'branches=' + id
		}
		let branches = productId.map(configureArray).join('&')

		test(branches)
	}

	const test = async branches => {
		setFieldValue('productsByBranches', await getProductByBranchesModal(branches))
	}

	const getSelectItemValue = (list, i) => {
		if (i) {
			let value = _.find(list, x => x.value === i.toString())
			return value
		}
	}

	const getProductsByBranches = value => {
		getSelectItemValue(selectItemsProducts, (values.items = [value.value]))
	}

	const getSponsorId = value => {
		getSelectItemValue(selectItemsSponsors, (values.sponsorId = value.value))
	}

	const onChange = value => {
		change.bind(value, 'sponsorId')
	}

	const isValidDate = current => {
		var today = new Date()
		today.setDate(today.getDate() - 1)
		return current.isAfter(today)
	}

	const isValidDateEnd = current => {
		var today = moment(values.startTerm).isValid() ? new Date(values.startTerm) : new Date()
		today.setDate(today.getDate() - 1)
		return current.isAfter(today)
	}

	const getStatus = () => {
		switch (status) {
			case 1:
				return 'Avaliação para aprovação'
			case 2:
				return 'Campanha aprovada'
			default:
				return 'Criador da campanha'
		}
	}

	return (
		<ContainerMain themeColor={themeColor}>
			<form>
				<Hidden mdDown implementation="css">
					<GridContainer style={{ marginBottom: '20px' }}>
						<GridItem xs={false} md={1}></GridItem>
						<GridItem xs={false} md={3} style={{ marginRight: '28px' }}>
							<img src={status >= 0 ? CriacaoVerde : CriacaoCinza} alt="imagem" />
						</GridItem>
						<GridItem xs={false} md={3} style={{ marginRight: '65px' }}>
							<img src={status >= 1 ? AvaliacaoVerde : status === 0 ? AvaliacaoVermelha : AvaliacaoCinza} alt="imagem" />
						</GridItem>
						<GridItem xs={false} md={3}>
							<img src={status === 2 ? AprovadaVerde : status === 0 ? ReprovadaVermelha : AprovadaCinza} alt="imagem" />
						</GridItem>
					</GridContainer>
				</Hidden>
				<Hidden lgUp implementation="css">
					<GridContainer>
						<GridItem xs={12}>
							<CustomInput
								id="Status"
								name="Status"
								labelText="status"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									disabled: true,
									value: getStatus(),
								}}
							/>
						</GridItem>
					</GridContainer>
				</Hidden>
				<Grid>
					<SelectFilials
						open={values.openFilialModal}
						campaignId={values.modalId}
						closeByClick={() => setFieldValue('openFilialModal', false)}
						selectedBranchs={modalBranches => selectedBranches(modalBranches)}
					/>
				</Grid>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginBottom: '0.75rem',
							}}
						>
							<Section title={t('FIELD_RESCUE_POINTS')} style={{ flexGrow: 1 }} />
							<div
								style={{
									marginLeft: '15px',
								}}
							>
								{editMode && (
									<Button
										size="sm"
										variant="contained"
										color="greenBorderButtonColor"
										onClick={handleCancel}
										disableElevation
										style={{ marginRight: '0.688rem' }}
									>
										{t('BUTTON_CANCEL')}
									</Button>
								)}
								{editMode && (
									<Button size="sm" variant="contained" color="greenButtonColor" disableElevation onClick={handleSubmit}>
										{t('BUTTON_SAVE')}
									</Button>
								)}
								{disabledForm && (
									<>
										<Button size="sm" variant="contained" color="greenBorderButtonColor" disableElevation onClick={handleBack}>
											{t('BUTTON_BACK')}
										</Button>{' '}
										{values.status.toString() === '0' && (
											<Button size="sm" variant="contained" color="greenButtonColor" onClick={handleEdit} disableElevation>
												{t('BUTTON_EDIT')}
											</Button>
										)}
										{values.status.toString() === '2' && (
											<Button size="sm" variant="contained" color="greenButtonColor" disableElevation onClick={handleDeactivate}>
												{t('BUTTON_DEACTIVATE')}
											</Button>
										)}
									</>
								)}
							</div>
						</div>
					</GridItem>
				</GridContainer>
				<GridContainer>
					{/* #2 - NOME */}
					<GridItem xs={12} sm={12} md={4} style={{ marginBottom: '10px' }}>
						<CssTextField
							error={touched.name && Boolean(errors.name)}
							helperText={touched.name && Boolean(errors.name) ? 'Nome da campanha obrigatório' : ''}
							disabled={disabledForm}
							className={styles.TextField}
							fullWidth
							label={<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_NAME" />}
							id="name"
							name="name"
							value={values.name}
							onChange={change.bind(null, 'name')}
							variant="outlined"
							margin="dense"
							InputLabelProps={{
								classes: {
									disabled: values.partnerId !== '' ? classes.labelTexRF : '',
								},
							}}
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
					{/* #3 - LOGIN */}
					{/* teste */}
					<GridItem xs={12} sm={12} md={3} style={{ display: 'flex' }}>
						<CurrencyInput
							max={999999999}
							error={touched.investment && Boolean(errors.investment)}
							helperText={touched.investment && Boolean(errors.investment) ? errors.investment : ''}
							disabled={disabledForm}
							className={styles.TextField}
							fullWidth
							label={<CustomRequiredField style={{ whiteSpace: 'nowrap' }} disabled={disabledForm} value="FIELD_CAMPAIGN_INVESTMENT" />}
							onValueChange={e => null}
							id="investment"
							name="investment"
							value={disabledForm ? _.toNumber(values.investment).toFixed(2) : values.investment}
							onChange={change.bind(null, 'number|investment')}
							variant="outlined"
							margin="dense"
							setValue={setFieldValue}
							InputLabelProps={{
								classes: {
									disabled: values.partnerId !== '' ? classes.labelTexRF : '',
								},
							}}
							style={{
								transform: 'translateY(8px)',
								backgroundColor: 'rgb(255, 255, 255)',
							}}
						/>

						{/* <CssTextField
							error={
								touched.investment && Boolean(errors.investment)
							}
							helperText={
								touched.investment && Boolean(errors.investment)
									? 'Valor obrigatório'
									: ''
							}
							disabled={disabledForm}
							className={styles.TextField}
							fullWidth
							label={
								<CustomRequiredField
									disabled={disabledForm}
									value="FIELD_CAMPAIGN_INVESTMENT"
								/>
							}
							id="investment"
							name="investment"
							value={
								disabledForm
									? _.toNumber(values.investment).toFixed(2)
									: values.investment
							}
							onChange={change.bind(null, 'number|investment')}
							variant="outlined"
							margin="dense"
							InputLabelProps={{
								classes: {
									disabled:
										values.partnerId !== ''
											? classes.labelTexRF
											: '',
								},
							}}
							InputProps={{
								startAdornment: (
									<span
										style={{
											marginRight: '5px',
											color:
												touched.subsidyValue &&
												Boolean(errors.subsidyValue)
													? dangerColor[0]
													: 'inherit',
										}}
									>
										{localStorage.getItem('currencySymbol')
											? localStorage.getItem(
													'currencySymbol',
											  ) + ' '
											: envs
													.REACT_APP_CURRENCY_SYMBOL +
											  ' '}

										{/* {numberFormatText(
											values.investment,
											localStorage.getItem(
												'currencySymbol',
											)
												? localStorage.getItem(
														'currencySymbol',
												  ) + ' '
												: envs
														.REACT_APP_CURRENCY_SYMBOL +
														' ',
											2,
										)} */}
						{/* </span>
								),
								 maximo de 10 caracter
								 inputComponent: DoubleFormatCustom,
							}}
							style={{ backgroundColor: '#fff' }}
						/> */}
					</GridItem>
					<GridItem xs={11} sm={11} md={1}>
						<div
							style={{
								color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
							}}
						>
							<Icon
								className="icon-ico_conversao"
								style={{
									fontSize: '28px',
									width: '45px',
									margin: '10px 0',
									cursor: 'pointer',
									overflow: 'visible',
									position: 'relative',
									top: '3px',
								}}
							/>
						</div>
					</GridItem>
					{values.investment && values.investment > 0 ? (
						<GridItem xs={12} sm={12} md={4}>
							<div
								style={{
									display: 'flex',
									backgroundColor: '#eee',
									padding: '3px 15px',
									marginTop: '8px',
									borderRadius: '5px',
									lineHeight: 1.2,
								}}
							>
								<div style={{ flexGrow: '1' }}>
									<span style={{ fontSize: '12px' }}>Conversão em pontos</span>
									<br />
									<span
										style={{
											color:
												themeColor &&
												themeColor.titles &&
												themeColor.titles.highlight &&
												themeColor.titles.highlight.textColor &&
												themeColor.titles.highlight.textColor,
											fontWeight: 'bold',
										}}
									>
										{numberFormatText(
											values.investmentPoints,
											localStorage.getItem('programCurrencySymbol')
												? localStorage.getItem('programCurrencySymbol') + ' '
												: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
												envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
											0,
										)}
									</span>
								</div>
							</div>
						</GridItem>
					) : (
						''
					)}
				</GridContainer>
				<GridContainer>
					{/* #2 - CPF */}
					{editMode ? (
						<GridItem xs={11} sm={11} md={7} style={{ marginTop: '9px' }}>
							<Autocomplete
								key={sponsorKey}
								styles={customSelectStyles}
								id="combo-box-demo"
								size="small"
								options={listSponsor}
								getOptionLabel={option => (option.label ? option.label : '')}
								getOptionSelected={(option, value) => value === undefined || value === '' || option.value === value.value}
								disableClearable={true}
								onChange={(event, value) => getSponsorId(value)}
								isDisabled={disabledForm}
								value={getSelectItemValue(selectItemsSponsors, values.sponsorId)}
								PopperComponent={CustomPopper}
								renderInput={params => (
									<div ref={params.InputProps.ref}>
										<CssTextField
											style={{
												backgroundColor: '#ffffff',
												width: '100%',
											}}
											{...params}
											noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
											error={touched.sponsorId && Boolean(errors.sponsorId)}
											helperText={touched.sponsorId && Boolean(errors.sponsorId) ? 'Patrocinador obrigatório' : ''}
											label={t('FIELD_CAMPAIGN_SPONSOR')}
											variant="outlined"
											size="small"
										/>
									</div>
								)}
							/>
						</GridItem>
					) : (
						<GridItem xs={11} sm={11} md={7}>
							<CssTextField
								error={touched.sponsorId && Boolean(errors.sponsorId)}
								helperText={touched.sponsorId && Boolean(errors.sponsorId) ? 'Patrocinador obrigatório' : ''}
								disabled={disabledForm}
								className={styles.TextField}
								fullWidth
								label={<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_SPONSOR" />}
								id="sponsor"
								name="sponsor"
								value={values.sponsor}
								onChange={change.bind(null, 'sponsor')}
								variant="outlined"
								margin="dense"
								InputLabelProps={{
									classes: {
										disabled: values.partnerId !== '' ? classes.labelTexRF : '',
									},
								}}
								style={{ backgroundColor: '#fff' }}
							/>
						</GridItem>
					)}
					{editMode && (
						<GridItem xs={1}>
							<div
								onClick={handleNewSponsor}
								style={{
									color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
								}}
							>
								<Icon
									className="icon-ico_crud_parceiros"
									style={{
										fontSize: '28px',
										width: '45px',
										margin: '10px 0',
										cursor: 'pointer',
										overflow: 'visible',
										position: 'relative',
										top: '3px',
									}}
								/>
							</div>
						</GridItem>
					)}
				</GridContainer>
				<GridContainer style={{ marginTop: '10px' }}>
					{/* #4 - E-MAIL */}
					<GridItem xs={12} sm={12} md={4}>
						{disabledForm ? (
							<CssTextField
								disabled={disabledForm}
								className={styles.TextField}
								fullWidth
								label={<CustomRequiredField disabled={disabledForm} value="FIELD_START" />}
								value={moment(values.startTerm).isValid() ? moment(values.startTerm).format('L') : values.startTerm}
								variant="outlined"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<i
												style={{
													fontSize: '22px',
													color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
													marginRight: '-8px',
												}}
												className="icon-calendario"
											/>
										</InputAdornment>
									),
									inputComponent: DateMaskCustom,
								}}
								InputLabelProps={{
									classes: {
										disabled: values.partnerId !== '' ? classes.labelTexRF : '',
									},
								}}
								style={{ backgroundColor: '#fff' }}
							/>
						) : (
							<Datetime
								className={styles.dateInputBlock}
								id="startTerm"
								timeFormat={false}
								isValidDate={isValidDate}
								value={moment(values.startTerm).isValid() ? moment(values.startTerm).format('L') : values.startTerm}
								onChange={!disabledForm && change.bind(null, 'startTerm')}
								renderInput={props => {
									return (
										<CssTextField
											error={touched.startTerm && Boolean(errors.startTerm)}
											helperText={touched.startTerm && Boolean(errors.startTerm) ? 'Início obrigatório' : ''}
											disabled={disabledForm}
											label={<CustomRequiredField disabled={disabledForm} value="FIELD_START" />}
											variant="outlined"
											margin="dense"
											fullWidth
											InputLabelProps={{
												classes: {
													disabled: values.partnerId !== '' ? classes.labelTexRF : '',
												},
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="start">
														<i
															style={{
																fontSize: '22px',
																color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
																marginRight: '-8px',
															}}
															className="icon-calendario"
														/>
													</InputAdornment>
												),
												inputComponent: DateMaskCustom,
											}}
											style={{ backgroundColor: '#fff' }}
											{...props}
										/>
									)
								}}
							/>
						)}
					</GridItem>
					{/* #2 - STATUS */}
					<GridItem xs={12} sm={12} md={4}>
						{disabledForm ? (
							<CssTextField
								disabled={disabledForm}
								className={styles.TextField}
								fullWidth
								label={<CustomRequiredField disabled={disabledForm} value="FIELD_END" />}
								value={moment(values.endTerm).isValid() ? moment(values.endTerm).format('L') : values.endTerm}
								variant="outlined"
								margin="dense"
								InputLabelProps={{
									classes: {
										disabled: values.endTerm !== '' && values.endTerm !== null ? classes.labelTexRF : '',
									},
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<i
												style={{
													fontSize: '22px',
													color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
													marginRight: '-8px',
												}}
												className="icon-calendario"
											/>
										</InputAdornment>
									),
								}}
								style={{ backgroundColor: '#fff' }}
							/>
						) : (
							<Datetime
								className={styles.dateInputBlock}
								id="endTerm"
								timeFormat={false}
								isValidDate={isValidDateEnd}
								value={moment(values.endTerm).isValid() ? moment(values.endTerm).format('L') : values.endTerm}
								onChange={!disabledForm && change.bind(null, 'endTerm')}
								renderInput={props => {
									return (
										<CssTextField
											error={touched.endTerm && Boolean(errors.endTerm)}
											helperText={touched.endTerm && Boolean(errors.endTerm) ? 'Fim obrigatório' : ''}
											disabled={disabledForm}
											label={<CustomRequiredField disabled={disabledForm} value="FIELD_END" />}
											variant="outlined"
											margin="dense"
											fullWidth
											InputLabelProps={{
												classes: {
													disabled: values.partnerId !== '' ? classes.labelTexRF : '',
												},
											}}
											InputProps={{
												endAdornment: (
													<InputAdornment position="start">
														<i
															style={{
																fontSize: '22px',
																color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
																marginRight: '-8px',
															}}
															className="icon-calendario"
														/>
													</InputAdornment>
												),
												inputComponent: DateMaskCustom,
											}}
											style={{ backgroundColor: '#fff' }}
											{...props}
										/>
									)
								}}
							/>
						)}
					</GridItem>

					{/* Produto alvo da campanha */}
					<Grid xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
						<GridItem xs={12} style={{ marginTop: '10px' }}>
							<FormLabel
								disabled={disabledForm}
								style={{
									color:
										themeColor &&
										themeColor.titles &&
										themeColor.titles.secondary &&
										themeColor.titles.secondary.textColor &&
										themeColor.titles.secondary.textColor,
								}}
								className={styles.LabelHorizontal + ' ' + (touched.campaignType && Boolean(errors.campaignType) ? styles.Error : '')}
							>
								<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_UNITS" />
							</FormLabel>
						</GridItem>
						<GridItem
							xs={5}
							style={{
								marginTop: '10px',
								marginBottom: '20px',
							}}
						>
							<div onClick={handleFilialModal} className={styles.filialsDiv}>
								<i
									className="icon-filiais"
									style={{
										fontSize: '20px',
										color: themeColor && themeColor.navigation && themeColor.navigation.buttons && themeColor.navigation.buttons.elementsColor,
										marginRight: '10px',
									}}
								/>
								<span
									className={styles.filials}
									style={{
										color: themeColor && themeColor.navigation && themeColor.navigation.buttons && themeColor.navigation.buttons.elementsColor,
									}}
								>
									{values.partnerBranchs.length !== 0 ? 'Unidades selecionadas: ' + values.partnerBranchs.length : t('TITLE_UNITS') + ' *'}
								</span>
							</div>
						</GridItem>
					</Grid>
					<GridItem xs={12} sm={6}>
						<GridContainer style={{ padding: '0' }}>
							<GridItem xs={12}>
								<FormLabel
									style={{
										color:
											themeColor &&
											themeColor.titles &&
											themeColor.titles.secondary &&
											themeColor.titles.secondary.textColor &&
											themeColor.titles.secondary.textColor,
									}}
									disabled={disabledForm}
									className={styles.LabelHorizontal + ' ' + (touched.campaignType && Boolean(errors.campaignType) ? styles.Error : '')}
								>
									{t('TITLE_ACTIVE_PRODUCT_CAMPAIGN')}
								</FormLabel>
							</GridItem>
							{editMode ? (
								<Grid
									Item
									xs={12}
									style={{
										marginTop: '10px',
										paddingLeft: '15px',
									}}
								>
									<MuiThemeProvider theme={theme}>
										<Autocomplete
											styles={customSelectStyles}
											id="combo-box-demo"
											size="small"
											options={listProductsByBranches}
											getOptionLabel={option => (option.label ? option.label : '')}
											getOptionSelected={(option, value) => value === undefined || value === '' || option.value === value.value}
											disableClearable={true}
											onChange={(_e, value) => getProductsByBranches(value)}
											isDisabled={disabledForm}
											value={getSelectItemValue(selectItemsProducts, values?.items?.length > 0 && values.items[0])}
											PopperComponent={CustomPopper}
											renderInput={params => (
												<div ref={params.InputProps.ref}>
													<CssTextField
														style={{
															backgroundColor: '#ffffff',
															width: '100%',
														}}
														{...params}
														noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
														error={touched.items && Boolean(errors.items)}
														helperText={touched.items && Boolean(errors.items) ? 'Produto alvo obrigatório' : ''}
														label={t('FIELD_SELECT') + ' *'}
														variant="outlined"
														size="small"
													/>
												</div>
											)}
										/>
									</MuiThemeProvider>
									<p
										className={styles.productMessage}
										style={{
											color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
										}}
									>
										{t('REDEEM_TYPES_MESSAGE')}
									</p>
								</Grid>
							) : (
								<Grid Item xs={12} style={{ paddingLeft: '15px' }}>
									<CssTextField
										className={styles.TextField}
										disabled={disabledForm}
										fullWidth
										id="items"
										name="items"
										value={_.isEmpty(values.items) ? '' : values.items[0].label}
										variant="outlined"
										margin="dense"
										InputLabelProps={{
											classes: {
												disabled: values.partnerId !== '' ? classes.labelTexRF : '',
											},
										}}
										style={{ backgroundColor: '#fff' }}
									/>
								</Grid>
							)}
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<div
							style={{
								display: 'flex',
								backgroundColor: '#eee',
								marginTop: '23px',
								padding: '20px 30px',
								borderRadius: '5px',
							}}
						>
							<div style={{ flexGrow: '1' }}>
								Valor em pontos
								<br />
								<span
									style={{
										color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
										fontWeight: 'bold',
									}}
								>
									{numberFormatText(
										values.redeemValue ? values.redeemValue : 0,
										localStorage.getItem('programCurrencySymbol')
											? localStorage.getItem('programCurrencySymbol') + ' '
											: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
											envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
										0,
									)}
								</span>
							</div>
							<div style={{ flexGrow: '1' }}>
								{`Valor a receber (${localStorage.getItem('currencySymbol')})`}
								<br />
								<span
									style={{
										color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
										fontWeight: 'bold',
									}}
								>
									{numberFormatText(
										values.receivableValue ? values.receivableValue : 0,
										localStorage.getItem('currencySymbol')
											? localStorage.getItem('currencySymbol') + ' '
											: // : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
											envs.REACT_APP_CURRENCY_SYMBOL + ' ',
										2,
									)}
								</span>
							</div>
						</div>
					</GridItem>
				</GridContainer>

				<GridContainer style={{ marginBottom: '80px' }}>
					<GridItem xs={12}>
						<FormLabel
							style={{
								color:
									themeColor &&
									themeColor.titles &&
									themeColor.titles.secondary &&
									themeColor.titles.secondary.textColor &&
									themeColor.titles.secondary.textColor,
							}}
							disabled={disabledForm}
							className={styles.LabelHorizontal + ' ' + (touched.statusOptionsQtyLimit && Boolean(errors.statusOptionsQtyLimit) ? styles.Error : '')}
						>
							{t('FIELD_CAMPAIGN_QUANTITY_LIMIT_CONSUMER')}
						</FormLabel>
					</GridItem>
					<GridItem xs={12} sm={12} md={1} style={{ marginTop: '10px' }}>
						<FormControlLabel
							style={{ paddingLeft: '5px' }}
							control={
								<Switch
									name="qtyLimit"
									id="qtyLimit"
									disabled={disabledForm}
									checked={values.qtyLimit}
									onChange={change.bind(null, 'qtyLimit')}
									value={values.qtyLimit}
									classes={{
										switchBase: classes.switchBase,
										checked: classes.switchChecked,
										thumb: classes.switchIcon,
										// thumbChecked: classes.switchIconThumb,
										track: classes.switchBar,
										disabled: classes.disabled,
									}}
								/>
							}
							classes={{
								label: classes.label + ' ' + (disabledForm ? styles.DisabledCursor : ''),
							}}
							label={values.qtyLimit ? 'Sim' : 'Não'}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={3}>
						{values.qtyLimit && (
							<CssTextField
								error={touched.units && Boolean(errors.units)}
								helperText={touched.units && Boolean(errors.units) ? 'Limite obrigatório' : ''}
								disabled={disabledForm}
								className={styles.TextField}
								label={<CustomRequiredField disabled={disabledForm} value="FIELD_LIMIT_CONSUMER" />}
								id="units"
								name="units"
								value={values.units}
								onChange={change.bind(null, 'units')}
								variant="outlined"
								margin="dense"
								InputProps={{
									inputComponent: NumberFormatCustom,
								}}
								InputLabelProps={{
									classes: {
										disabled: values.partnerId !== '' ? classes.labelTexRF : '',
									},
								}}
								style={{
									backgroundColor: '#fff',
									width: '90%',
									float: 'right',
								}}
							/>
						)}
					</GridItem>
					<GridItem xs={12}>
						<FormLabel
							style={{
								color:
									themeColor &&
									themeColor.titles &&
									themeColor.titles.secondary &&
									themeColor.titles.secondary.textColor &&
									themeColor.titles.secondary.textColor,
							}}
							disabled={disabledForm}
							className={styles.LabelHorizontal}
						>
							{t('FIELD_CAMPAIGN_SCORING_TYPE')}
						</FormLabel>
					</GridItem>
					<GridItem xs={12} sm={12} md={6}>
						<CssTextField
							error={touched.subsidyValue && Boolean(errors.subsidyValue)}
							helperText={touched.subsidyValue && Boolean(errors.subsidyValue) ? 'Valor obrigatório' : ''}
							disabled={disabledForm}
							className={styles.TextField}
							fullWidth
							label={<CustomRequiredField disabled={disabledForm} value="FIELD_VALUE_SUBSIDY" />}
							id="subsidyValue"
							name="subsidyValue"
							value={values.subsidyValue}
							onChange={change.bind(null, 'number|subsidyValue')}
							variant="outlined"
							margin="dense"
							InputProps={{
								inputComponent: NumberFormatCustom,
								startAdornment: (
									<span
										style={{
											marginRight: '5px',
											color: touched.subsidyValue && Boolean(errors.subsidyValue) ? dangerColor[0] : 'inherit',
										}}
									>
										{localStorage.getItem('programCurrencySymbol')
											? localStorage.getItem('programCurrencySymbol') + ' '
											: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
											envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
									</span>
								),
							}}
							InputLabelProps={{
								classes: {
									disabled: values.partnerId !== '' ? classes.labelTexRF : '',
								},
							}}
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
					<GridItem xs={12} sm={12} md={6} style={{ marginTop: '-25px' }}>
						<div
							style={{
								backgroundColor: '#eee',
								borderRadius: '5px',
								padding: '1px',
							}}
						>
							<div
								style={{
									display: 'flex',
									backgroundColor: '#eee',
									padding: '20px 20px',
									lineHeight: 1.2,
									borderRadius: '5px',
								}}
							>
								<div style={{ flexGrow: '1' }}>
									Qtd de produtos patrocinados
									<br />
									<span
										style={{
											color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
											fontWeight: 'bold',
										}}
									>
										{values.quantityProduct}
									</span>
								</div>
								<div style={{ flexGrow: '1' }}>
									Valor para resgate (consumidor)
									<br />
									<span
										style={{
											color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
											fontWeight: 'bold',
										}}
									>
										{numberFormatText(
											values.newRedeemValue ? values.newRedeemValue : 0,
											localStorage.getItem('programCurrencySymbol')
												? localStorage.getItem('programCurrencySymbol') + ' '
												: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
												envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
											2,
										)}
									</span>
								</div>
							</div>
						</div>
					</GridItem>
					{values.subsidyValue && values.subsidyValue > 0 ? (
						<GridItem xs={12} style={{ marginTop: '10px', display: 'flex' }}>
							<Icon style={{ marginTop: '10px' }}>info</Icon>
							<div
								style={{
									backgroundColor: '#fff',
									padding: '5px 10px',
									margin: '1px',
									borderRadius: '5px',
									color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
									fontWeight: 400,
								}}
							>
								{`A campanha está subisidiando parte do valor em pontos para resgate do produto alvo`}{' '}
								<b>{`(${localStorage.getItem('programCurrencySymbol')} ${values.subsidyValue}).`}</b> <br />
								{`O parceiro receberá o valor original`} <b>{`(${localStorage.getItem('programCurrencySymbol')} ${values.redeemValue})`}</b>
							</div>
						</GridItem>
					) : (
						''
					)}
				</GridContainer>
			</form>
			{values.open && (
				<Dialog
					fullWidth={true}
					maxWidth="md"
					classes={{
						root: classes.center + ' ' + classes.modalRoot,
						paper: classes.modal,
					}}
					open={values.open}
					TransitionComponent={Transition}
					keepMounted
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogContent>{/* <Sponsors onClose={ToggleDialog} /> */}</DialogContent>
				</Dialog>
			)}
		</ContainerMain>
	)
}

class RedeemCampaignForm extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			modalId: '',
			loading: false,
			open: false,
			productsByBranches: [],
			selectItemsProducts: [],
			selectItemsSponsors: [],
			recordCount: 0,
			investmentPoints: 0,
			redeemValue: 0,
			quantityProduct: 0,
			receivableValue: 0,
			newRedeemValue: 0,
			subsidyValue: '',
			campaignMode: 2,
			quantityUnits: '',
			selectedProduct: '',
			disabledForm: true,
			editMode: false,
			id: '',
			partnerId: '',
			userGroups: [],
			name: '',
			investment: '0,00',
			startTerm: '',
			endTerm: '',
			items: [],
			partnerBranchs: [],
			volume: '',
			campaignType: '2',
			productType: '2',
			sponsorId: '',
			sponsor: '',
			status: '',
			scoringType: '1',
			scoringValue: '',
			qtyLimit: true,
			sponsorKey: Math.random(),
			units: '',
			listProductsByBranches: [],
			listSponsor: [],
		}
		moment.locale(localStorage.getItem('i18nextLng'))

		this.getProductByBranches = _.debounce(this.getProductByBranches, 1000)
		this.getCategoriesOptions = _.debounce(this.getCategoriesOptions, 1000)
		this.getBrandsOptions = _.debounce(this.getBrandsOptions, 1000)
		this.getFamilyOptions = _.debounce(this.getFamilyOptions, 1000)
		this.getManufacturerOptions = _.debounce(this.getManufacturerOptions, 1000)
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		const id = this.props.match && this.props.match.params.id

		//
		let _new = _.includes(this.props.match.path, 'new')
		let _edit = _.includes(this.props.match.path, 'edit')
		let _view = _.includes(this.props.match.path, 'view')
		if (_new) {
			this.setState({ editMode: _new, disabledForm: !_new }, () => this.props.HideLoader())
		} else if (_edit) {
			this.setState({ editMode: _edit, disabledForm: !_edit })
		} else if (_view) {
			this.setState({ editMode: !_view, disabledForm: _view })
		}
		//

		this.setState({ modalId: id })
		id && this.props.GetCampaignDataById(id)

		if (this.props.userData.id) {
			var array = []
			array.push({ value: '*', label: '>> Selecionar Todos' })
			this.props.userData.branches.filter(b => b.active).map(x => array.push({ value: x.id, label: x.name }))
			this.setState({
				partnerId: this.props.userData.id,
				selectItemsBranches: array,
			})
		}

		this.listProductsByBranches()
		this.listSponsorOptions()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.campaignData !== this.props.campaignData) {
			this.setState(
				{
					...this.props.campaignData,
					items: this.props.campaignData
						? this.props.campaignData.items.map(x => ({
							id: x.key,
							label: x.value,
						}))
						: [],
					sponsor: this.props.campaignData && this.props.campaignData.sponsor ? this.props.campaignData.sponsor.name : '',
					partnerBranchs: this.props.campaignData.partnerBranchs.map(x => ({ value: x.id, label: x.name })),
				},
				() => this.props.HideLoader(),
			)
		}

		if (prevProps.userData !== this.props.userData) {
			var array = []
			array.push({ value: '*', label: '>> Selecionar Todos' })
			this.props.userData.branches.filter(b => b.active).map(x => array.push({ value: x.id, label: x.name }))
			this.setState({
				partnerId: this.props.userData.id,
				selectItemsBranches: array,
			})
		}

		if (prevProps.lsProducts !== this.props.lsProducts) {
			if (this.props.lsProducts) {
				let selectItemsProducts = this.props.lsProducts.results.map(product => {
					return { id: product.id, label: product.name }
				})
				this.setState({ selectItemsProducts, ...this.props.lsProducts }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsProducts: [], ...this.props.lsProducts }, () => this.setState({ loading: false }))
			}
		}

		if (prevProps.lsSponsorsData !== this.props.lsSponsorsData) {
			if (this.props.lsSponsorsData) {
				let selectItemsSponsors = this.props.lsSponsorsData.results.map(sponsor => {
					return { id: sponsor.id, label: sponsor.name }
				})
				this.setState({ selectItemsSponsors, ...this.props.lsSponsorsData }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsSponsors: [], ...this.props.lsSponsorsData }, () => this.setState({ loading: false }))
			}
		}

		if (prevProps.postSponsorsData !== this.props.postSponsorsData) {
			this.setState({ sponsorKey: Math.random() })
		}

		if (prevProps.campaignTypeList !== this.props.campaignTypeList) {
			this.setState({
				selectItemsCampaignType: this.setOptionsState(this.props.campaignTypeList),
			})
		}

		if (prevProps.productTypeList !== this.props.productTypeList) {
			var selectItems = [{ value: '', label: 'Nenhum' }]
			this.setOptionsState(this.props.productTypeList).map(x => selectItems.push(x))
			this.setState({ selectItemsProductType: selectItems })
		}

		if (prevProps.scoringTypeList !== this.props.scoringTypeList) {
			this.setState({
				selectItemsScoringType: this.setOptionsState(this.props.scoringTypeList),
			})
		}

		if (prevProps.postCampaign !== this.props.postCampaign) {
			if (this.props.postCampaign.success) {
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={''}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.history.push('/campaign/redeem')
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.t('FIELD_ALERT_SUCCESS')}
					</Alert>,
				)
				setTimeout(() => {
					this.props.HideAlert()
					this.props.history.push(`/campaign/redeem`)
				}, 3000)
			} else {
				this.setState({ editMode: true, disabledForm: false })
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={'Erro'}
						onConfirm={() => {
							this.props.HideAlert()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.postCampaign.errors && this.props.postCampaign.errors.map(error => [this.props.t(error.message)])}
					</Alert>,
				)
			}
		}
		if (prevProps.putCampaign !== this.props.putCampaign) {
			if (this.props.putCampaign.success) {
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={''}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.history.goBack()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.t('FIELD_ALERT_SUCCESS')}
					</Alert>,
				)
				setTimeout(() => {
					this.props.HideAlert()
					this.props.history.goBack()
				}, 3000)
			} else {
				this.setState({ editMode: true, disabledForm: false })
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={'Erro'}
						onConfirm={() => {
							this.props.HideAlert()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.putCampaign.errors && this.props.putCampaign.errors.map(error => [this.props.t(error.message)])}
					</Alert>,
				)
			}
		}

		if (prevProps.deactivateCampaign !== this.props.deactivateCampaign) {
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.history.goBack()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_SUCCESS')}
				</Alert>,
			)
			setTimeout(() => {
				this.props.HideAlert()
				this.props.history.goBack()
			}, 3000)
		}

		if (prevProps.campaignFailed !== this.props.campaignFailed) {
			this.setState({ editMode: true, disabledForm: false })
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Erro'}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}
	}

	handleCheckboxClick = i => {
		const { selectItemsProducts } = this.state
		var item = selectItemsProducts.find(x => x.id === i.id)
		// selectItemsProducts[index].checked = !selectItemsProducts[index].checked;
		item.checked = !item.checked
		this.setState({ selectItemsProducts })
	}

	handleSelectedItems = () => {
		const { selectItemsProducts } = this.state
		var item = selectItemsProducts.forEach(x => (x.checked = false))
		this.setState({ selectItemsProducts })
	}

	setOptionsState = list => {
		let options = []
		list.map(item => {
			options.push({ value: item.key, label: this.props.t(item.value) })
		})
		return options
	}

	listProductsByBranches = () => {
		let result = []
		this.props.GetProductByBranches(null, '', 10, 1).then(resp => {
			if (resp && resp.results) {
				resp.results.map(product => {
					result.push({ value: product.id, label: product.name })
				})
				this.setState({
					listProductsByBranches: result,
				})
			}
		})
	}

	getProductByBranches = (branches, callback) => {
		let _name = ''

		if (!_.isEmpty(branches)) {
			_name = 'name=' + branches
		}

		return this.props.GetProductByBranches(null, _name, 10, 1).then(resp => {
			if (resp && resp.results) {
				return callback(
					resp.results.map(product => {
						return { value: product.id, label: product.name }
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	getProductByBranchesModal = async branches => {
		return this.props.GetProductByBranches(branches, '', 10, 1).then(resp => {
			if (resp && resp.results) {
				return resp.results.map(product => {
					return { value: product.id, label: product.name }
				})
			} else {
				return []
			}
		})
	}

	getSponsorsOptions = (inputValue, callback) => {
		return this.props.GetSponsorsData(inputValue, this.state.partnerId, 30).then(resp => {
			if (resp && resp.results) {
				return callback(
					resp.results.map(sponsor => {
						return { value: sponsor.id, label: sponsor.name }
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	getCategoriesOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetCategory(' ', searchField.length > 0 ? searchField : ' ', 1)
	}

	getBrandsOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetBrand(' ', searchField.length > 0 ? searchField : ' ', 1)
	}

	getFamilyOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetFamily(' ', searchField.length > 0 ? searchField : ' ', 1)
	}

	getManufacturerOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetManufacturer(' ', searchField.length > 0 ? searchField : ' ', 1)
	}

	clearsOptions = () => this.setState({ selectItemsProducts: [] })

	getUserGroupIdList = userGroup => {
		return userGroup.map(item => _.toInteger(item.groupId))
	}

	handleDeactivate = () => {
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title={''}
				onConfirm={() => this.deactivateCampaign()}
				onCancel={() => this.props.HideAlert()}
				confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
				cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
				confirmBtnText={this.props.t('BUTTON_YES')}
				cancelBtnText={this.props.t('BUTTON_NO')}
				showCancel
			>
				{this.props.t('ALERT_CAMPAIGN_DEACTIVATE')}
			</Alert>,
		)
	}

	deactivateCampaign = () => {
		const id = this.props.match && this.props.match.params.id
		if (id) {
			this.props.DeactivateCampaign(id)
		}
	}

	handleSave = values => {
		this.props.ShowLoader(<Loader />)
		// this.setState({ editMode: false, disabledForm: true });
		values.status = 1
		// values.partnerBranchs = values.partnerBranchs.map(b => ({ partnerBranchId: b.value }));
		if (!this.state.id) {
			values.partnerId = this.props.userData.id
			this.props.PostCampaignData(values)
		} else {
			this.props.PutCampaignData(this.state.id, values)
		}
	}

	CancelForm() {
		this.setState({ editMode: false, disabledForm: true })
		this.props.HideLoader()
		this.props.HideAlert()
		this.props.history.goBack()
	}

	handleBack = () => {
		this.props.history.goBack()
	}

	handleCancel = () => {
		if (this.formik.current && _.isEmpty(this.formik.current.state.touched)) {
			this.CancelForm()
		} else {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => this.CancelForm()}
					onCancel={() => this.props.HideAlert()}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
					confirmBtnText={this.props.t('BUTTON_YES')}
					cancelBtnText={this.props.t('BUTTON_NO')}
					showCancel
				>
					{this.props.t('FIELD_GENERAL_RULES_ALERT')}
				</Alert>,
			)
		}
	}

	loadSponsorOptions = (inputValue, callback) => {
		this.getSponsorOptions(inputValue, callback)
	}

	getSponsorOptions = (value, callback) => {
		const inputValue = value.trim().toLowerCase()
		// const inputLength = inputValue.length
		return this.props.ListSponsorByCompanyName(inputValue, inputValue ? '' : 30).then(() => {
			return callback(
				this.props.selectSponsors.results.map(partner => {
					return {
						value: partner.key,
						label: partner.value,
					}
				}),
			)
		})
	}

	listSponsorOptions() {
		let result = []
		this.props.ListSponsorByCompanyName('').then(() => {
			if (this.props.selectSponsors && this.props.selectSponsors.results) {
				this.props.selectSponsors.results.map(partner => {
					result.push({
						value: partner.key,
						label: partner.value,
					})
				})
				this.setState({
					listSponsor: result,
				})
			}
		})
	}

	handleNewSponsor = () => {
		this.props.history.push(`/sponsors/new`)
	}

	handleEdit = () => {
		this.props.history.push(`/campaigns/edit/${this.state.id}`)
	}

	render() {
		const { classes, t } = this.props
		const {
			modalId,
			toggleTip,
			open,
			editMode,
			investmentPoints,
			redeemValue,
			quantityProduct,
			receivableValue,
			newRedeemValue,
			disabledForm,
			recordCount,
			partnerBranchs,
			quantityUnits,
			campaignMode,
			partnerId,
			searchField,
			name,
			sponsorId,
			investment,
			campaignType,
			status,
			volume,
			qtyLimit,
			units,
			startTerm,
			endTerm,
			items,
			sponsor,
			scoringType,
			subsidyValue,
			selectedProduct,
			productType,
			selectItemsScoringType,
			selectItemsCampaignType,
			selectItemsProductType,
			selectItemsProducts,
			selectItemsSponsors,
			selectItemsBranches,
			productsByBranches,
			listProductsByBranches,
			listSponsor,
		} = this.state
		const values = {
			modalId,
			campaignMode,
			investmentPoints,
			quantityProduct,
			receivableValue,
			newRedeemValue,
			open,
			quantityUnits,
			toggleTip,
			partnerBranchs,
			redeemValue,
			partnerId,
			name,
			sponsorId,
			searchField,
			investment,
			status,
			volume,
			campaignType,
			qtyLimit,
			units,
			startTerm,
			endTerm,
			items,
			sponsor,
			scoringType,
			selectedProduct,
			productType,
			subsidyValue,
			productsByBranches,
		}

		return (
			<>
				<DocumentTitle title={getProgramTitle()} />
				<Grid
					style={{
						marginBottom: '1.563rem',
					}}
				>
					<Section title={t('TITLE_CAMPAIGN_STATUS')} />
				</Grid>

				<Formik
					ref={this.formik}
					render={props => (
						<Form
							{...props}
							t={t}
							classes={classes}
							recordCount={recordCount}
							status={status}
							selectItemsScoringType={selectItemsScoringType}
							selectItemsCampaignType={selectItemsCampaignType}
							selectItemsProductType={selectItemsProductType}
							selectItemsProducts={selectItemsProducts}
							selectItemsSponsors={selectItemsSponsors}
							selectItemsBranches={selectItemsBranches}
							listSponsor={listSponsor}
							listProductsByBranches={listProductsByBranches}
							handleCheckboxClick={this.handleCheckboxClick}
							loadSponsorOptions={this.loadSponsorOptions}
							handleSelectedItems={this.handleSelectedItems}
							generalRulesData={this.props.generalRulesData}
							handleDeactivate={this.handleDeactivate}
							sponsorKey={this.state.sponsorKey}
							clearsOptions={this.clearsOptions}
							disabledForm={disabledForm}
							handleCancel={this.handleCancel}
							handleBack={this.handleBack}
							handleEdit={this.handleEdit}
							handleNewSponsor={this.handleNewSponsor}
							// getProductsOptions={this.getProductsOptions}
							getProductByBranches={this.getProductByBranches}
							getProductByBranchesModal={this.getProductByBranchesModal}
							productsByBranches={this.productsByBranches}
							getSponsorsOptions={this.getSponsorsOptions}
							getProductById={this.props.GetProductById}
							editMode={editMode}
							idValid={this.state.id !== 0}
							loading={this.state.loading}
						/>
					)}
					validationSchema={validationSchema}
					onSubmit={this.handleSave.bind(this)}
					initialValues={values}
					enableReinitialize={true}
				/>
			</>
		)
	}
}

const mapStateToProps = state => {
	return {
		campaignData: state.campaign.campaignData,
		campaignFailed: state.campaign.campaignFailed,
		postCampaign: state.campaign.postCampaign,
		putCampaign: state.campaign.putCampaign,
		deactivateCampaign: state.campaign.deactivateCampaign,
		campaignTypeList: state.campaign.campaignTypeList,
		productTypeList: state.campaign.productTypeList,
		scoringTypeList: state.campaign.scoringTypeList,
		campaignStatusList: state.campaign.campaignStatusList,
		lsProducts: state.product.lsProducts,
		selectSponsors: state.sponsor.selectSponsors,
		lsCategories: state.product.lsCategories,
		lsFamily: state.product.lsFamily,
		lsManufacturers: state.product.lsManufacturers,
		lsBrands: state.product.lsBrands,
		productFailed: state.product.productFailed,
		categoriesFailed: state.product.categoriesFailed,
		familyFailed: state.product.familyFailed,
		manufactureFailed: state.product.manufacturereFailed,
		generalRulesData: state.generalRules.generalRulesData,
		generalRulesDataFailed: state.generalRules.generalRulesDataFailed,
		lsSponsorsData: state.sponsor.lsSponsorsData,
		postSponsorsData: state.sponsor.postSponsorsData,
		userData: state.users.userData,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetCurrentPartner,
			GetCampaignDataById,
			GetCampaignTypeListData,
			DeactivateCampaign,
			ListSponsorByCompanyName,
			GetProductTypeListData,
			GetSponsorsData,
			PostCampaignData,
			PutCampaignData,
			GetAllProduct,
			GetProductByBranches,
			GetProductById,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(CampaignStyle),
	withStyles(extendedFormsStyle),
	withTranslation(),
)(RedeemCampaignForm)
