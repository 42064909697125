import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel } from '@mui/material'
import Info from '../Info'

const Cards = ({ customCashbackValue, typeOfCashback, onOpen }) => {
	const { t } = useTranslation()

	return (
		<S.Container typeOfPoints={typeOfCashback}>
			<FormLabel sx={{ width: '100%' }}>
				<span className="FormLabel">{t('Valores baseados na taxa padrão')} GGGGG</span>
				<Info customCashbackValue={customCashbackValue} onOpen={onOpen} />
			</FormLabel>
		</S.Container>
	)
}

Cards.propTypes = {
	customCashbackValue: PropTypes.array,
	typeOfCashback: PropTypes.string,
	onOpen: PropTypes.func,
}

export default Cards
