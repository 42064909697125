import styled from 'styled-components'

const getBGCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons.backgroundColor
	) {
		return props.themeColor.navigation.buttons.backgroundColor
	}
	return `#fff`
}
const getHLCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor
	) {
		return props.themeColor.titles.highlight.textColor
	}
	return `#fff`
}
const getColor = (props) => {
	if (
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.textColor
	) {
		return props.themeColor.navigation.buttons.textColor
	}
	return `#fff`
}

const getColorIcon = (props) => {
	if (
		props.themeColor &&
		props.themeColor.visual &&
		props.themeColor.visual.icons &&
		props.themeColor.visual.icons.color
	) {
		return props.themeColor.visual.icons.color
	}
	return `#fff`
}

export const Title = styled.div`
	color: ${(props) => getHLCol(props)};
	font-weight: bold;
`

export const MyButton = styled.a`
	color: white;
	border-radius: 4px;
	color: ${(props) => getColor(props)};
	background-color: ${(props) => getBGCol(props)};
	border-style: none;
	height: 25px;
	text-align: center;
	width: '106px';
	&:hover {
		color: white;
	}
`

export const Close = styled.button`
	align-items: center;
	display: flex;
	justify-content: center;
	border: none;
	border-radius: 5px;
	background-color:  #F05B4F;
	cursor: pointer;
	color: #fff;
	margin-left: 0.938rem;
	width: 90px;
	height: 35px;
	i {
		margin-right: 5px;
		margin-top: 3px;
	}
`
export const Icon = styled.div`
	color: #f9bb86;
	display: flex;
	font-size: 79px;
	justify-content: center;
	margin: 18px 0;
	top: -10px;
`
export const TextStyle = styled.div`
	text-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	font-size: 80px;
	justify-content: center;
	min-height: 211px;
	padding: 30px;
	width: 600px;
	i {
		color: #f8bb86;
	}
	p {
		font-size: 22px;
		font-weight: bold;
		margin-top: 33px;
	}
`

export const Example = styled.div`
	text-align: left !important;
	background-color: #fff;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	img {
		width: 100%;
	}
`

export const Paragraph = styled.p`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 38px;
	margin-right: 38px;
	opacity: 0.9;
	color: #333333;
`

export const Model = styled.div`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 50px;
	padding-left: 32px;
	margin-right: 38px;
	opacity: 0.8;
	color: #333333;
`
export const Form = styled.form`
	.gfEDZo {
		width: 344px;
		height: 40px;
		background: #fff 0% 0% no-repeat padding-box;
		border: 1px solid #c4c4c4;
		opacity: 1;
	}
	.foVCqc {
		width: 344px;
	}
`
