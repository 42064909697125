import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel } from '@mui/material'
import GenerationPointsInfo from '../GenerationPointsInfo'

const GenerationPoints = ({ customCashbackValue, typeOfCashback }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<FormLabel sx={{ width: '100%' }}>
				<span className="FormLabel">
					{typeOfCashback === '2' ? t('Valores baseados na taxa por pontos fixos') : t('Valores baseados na taxa percentual')}
				</span>
				<GenerationPointsInfo customCashbackValue={customCashbackValue} />
			</FormLabel>
		</S.Container>
	)
}

GenerationPoints.propTypes = {
	customCashbackValue: PropTypes.array,
	typeOfCashback: PropTypes.string,
}

export default GenerationPoints
