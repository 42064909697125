import { GetTypeOfPoints } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import {
	CalculateValue,
	CreateProductByMultipleDivisions,
	GetDivisionByProduct,
	UpdateProductByDivision,
} from '../../../../../../../../../redux/api/product'
import {envs}  from '../../../../../../../../../redux/api/windowServerData'



export const GetTypePoints = async setGenerationType => {
	try {
		const res = await GetTypeOfPoints()
		if (res && typeof res === 'object') {
			setGenerationType(res)
		}
	} catch (error) {
		setGenerationType([])
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		0,
	)
}

export const FormatMoney = value => {
	return numberFormatText(
		value,
		// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const handleProductDataUpdate = (productData, setProductDataState, setValue) => {
	if (productData) {
		const { typeOfPoints, parameterizationGenerationPoints, pointsValue } = productData

		setValue('typeOfPoints', typeOfPoints)
		setProductDataState({
			parameterization: parameterizationGenerationPoints,
			pointsValue: pointsValue,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}
//
export const handleCalculateValue = async (productId, price, type, value, typeOfRedeem, redeemValue) => {
	try {
		const res = await CalculateValue(productId, price, type, value, typeOfRedeem, redeemValue)
		if (res?.success && res?.data) {
			const { generationPoints } = res.data
			return generationPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const GetBranchesPlatforms = async ({ Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, setDefaultValues, setLoading }) => {
	setLoading(true)
	try {
		const branchesPlatforms = await GetDivisionByProduct(Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page)
		if (branchesPlatforms?.status === 200) {
			const { data } = branchesPlatforms
			setDefaultValues({
				...data,
				page: data?.currentPage,
			})

			return data?.results ?? []
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

export const ParameterizationByDivision = async ({ divisionId, goToBack, productData, typeOfRedeem, redemptionPoints, setSnackStatus }) => {
	const obj = {
		typeOfBenefit: '1',
		price: productData?.price,
		name: productData?.name,
		redemptionPoints: redemptionPoints || 0,
		typeOfBenefitRedeem: '2',
		typeOfRedeem: typeOfRedeem?.key,
		divisionId: divisionId,
	}
	try {
		const res = await UpdateProductByDivision(productData?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				goToBack()
			}, 900)

			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}

export const CreateProductByMultiple = async ({
	history,
	divisionsId,
	productData,
	typeOfRedeem,
	setSnackStatus,
	redemptionPoints,
	pametrizationName,
}) => {
	const obj = {
		typeOfBenefit: '1',
		price: productData?.price,
		name: productData?.name,
		redemptionPoints: redemptionPoints || 0,
		typeOfBenefitRedeem: '2',
		typeOfRedeem: typeOfRedeem?.key,
		pametrizationName: pametrizationName,
		divisionsId: divisionsId,
	}

	try {
		const res = await CreateProductByMultipleDivisions(productData?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				history.push(`/product/history/ProductId=${productData?.id}`)
			}, 900)
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}
