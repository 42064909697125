import { UpdateProduct } from 'redux/api/product'

export const UpdateProductByID = async (id, productData) => {
	const obj = {
		price: productData?.price,
		generatePoints: productData?.generatePoints,
		typeOfPoints: productData?.typeOfPoints,
		pointsValue: productData?.pointsValue,
		typeOfRedeem: productData?.typeOfRedeem,
		redemptionPoints: productData.redemptionPoints,
		typeOfCashback: productData.typeOfCashback,
		cashbackPercentual: productData.cashbackPercentual,
	}
	try {
		const response = await UpdateProduct(id, obj)

		return response
	} catch (error) {
		console.log(error)
	}
}

export function getTitle(t) {
	const grouper = localStorage.getItem('Grouper')

	switch (grouper) {
		case '1':
			return t('MENU_CATEGORY')
		case '2':
			return t('FAMILY')
		case '3':
			return t('MANUFACTURE')
		case '4':
			return t('FIELD_BRAND')
		default:
			return t('teste')
	}
}
