/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core'
import React from 'react'

export default function TagItem({ label, onCLick }) {
	return (
		<Box
			onCLick={onCLick}
			sx={{
				minWidth: '50px',
				backgroundColor: '#F1F1F1',
				padding: '5px 10px',
				borderRadius: '5px',
				color: '#333333',
				fontWeight: 500,
				textAlign: 'center',
				fontSize: '13px',

				'&:hover': {
					cursor: 'pointer',
				},
			}}
		>
			{label}
		</Box>
	)
}
