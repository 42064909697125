import React from 'react';
import cx from 'classnames';

import GriTablow from "./GriTablow.jsx";

import { Grid, Divider } from "@material-ui/core";

import { Search, Edit } from "@material-ui/icons";

import { makeStyles } from '@material-ui/core/styles';

import { germiniDefaultColors, grayColor } from 'assets/jss/material-dashboard-pro-react';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#ececec',
        borderRadius: '4px',
        margin: '3px 0',
        "&:nth-child(even)": {
            backgroundColor: '#F4F4F4',
        }
    },
    button: {
        height: '100%',
        backgroundColor: germiniDefaultColors[0],
        textAlign: 'center',
        display: 'flex',
        cursor: 'pointer',
        width: '49%',
        "&:hover": {
            backgroundColor: germiniDefaultColors[9]
        }
    },
    buttonRadius: {
        borderTopRightRadius: '4px',
        borderBottomRightRadius: '4px',
    }
}));

const GriTable = props => {

    const classes = useStyles();
    const { labels, values, history, id, } = props;

    return (
        <Grid container spacing={0} className={classes.root}>
            <Grid container item xs={12} sm={12} md={11} spacing={0}>
                <Grid container spacing={0} style={{ padding: '5px 0' }}>
                    <Grid container item xs={12} spacing={0}>
                        <GriTablow
                            labels={[labels[0], labels[1], labels[2]]}
                            values={[values[0], values[1], values[2]]}
                        />
                    </Grid>
                    {labels.length > 3 &&
                        <>
                            <Divider style={{ width: '96%', margin: '0 8px' }} />
                            <Grid container item xs={12} spacing={0}>
                                <GriTablow
                                    labels={[labels[3], labels[4], labels[5]]}
                                    values={[values[3], values[4], values[5]]}
                                />
                            </Grid>
                        </>
                    }
                    {labels.length > 6 &&
                        <>

                            <Divider style={{ width: '96%', margin: '0 8px' }} />
                            <Grid container item xs={12} spacing={0}>
                                <GriTablow
                                    labels={[labels[6], labels[7], labels[8]]}
                                    values={[values[6], values[7], values[8]]}
                                />
                            </Grid>
                        </>

                    }
                </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={1} spacing={0} style={{ display: 'block' }}>
                <div
                    className={classes.button}
                    onClick={() => history.push(`/product/productDetail/${id}`)}
                    style={{ float: 'left' }}
                >
                    <Search style={{ margin: 'auto', color: 'white' }} />
                </div>
                <div
                    className={cx(classes.button, classes.buttonRadius)}
                    onClick={() => history.push(`/product/productDetail/edit/${id}`)}
                    style={{ float: 'right' }}
                >
                    <Edit style={{ margin: 'auto', color: 'white' }} />
                </div>
                <div />
            </Grid>
        </Grid>
    )

};

export default GriTable;
