import React, { Component, Fragment } from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { TextField, Grid } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx'
import GriTable from './Components/GriTable'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import NoResults from 'components/NoResults/NoResults.jsx'
import Section from 'components/Section'

import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import { grayColor } from 'assets/jss/material-dashboard-pro-react'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

import { GetCurrentPartner, GetAccessGroupsData, ShowLoader, HideLoader, SweetAlert, HideAlert } from 'redux/actions'
import { connectRouterState } from 'utils/connectRouterState'

//UTILS
import compose from 'utils/compose'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { Container, GroupButtons, SearchBox } from './Components/styles'
import { getProgramTitle } from '../../../utils/utils'

const CssTextFieldOld = withStyles({
	root: {
		'& label.Mui-focused': {
			color:
				getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: '#fff !important',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
			'&.Mui-focused fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
		},
	},
})(TextField)

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		input: { height: '25px' },
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}

const CustomPopper = function (props) {
	const classesPoper = useStyles()
	return <Popper {...props} className={classesPoper.root} placement="bottom" />
}

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				'& :hover': {
					color: '#ffffff',
					backgroundColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'& li': {
					backgroundColor: '#ffffff',
				},
			},
		},
	}),
)

class Groups extends Component {
	constructor(props) {
		super(props)
		this.state = {
			results: false,
			accessDataFailed: false,
			accessGroups: [],
			activeRoleClaims: [],
			roleClaims: [],
			usersOptions: [],

			currentPage: 1,
			pageSize: 1,
			recordCount: 0,
			groupName: '',
			userLogin: '',
			selectLogin: '',
			filtered: false,
			themeColor: getThemeColorFromLocalStorage(),
		}
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		this.props.ShowLoader(<Loader />)
		if (!this.props.userData) {
			this.props.GetCurrentPartner()
		} else {
			this.props.GetAccessGroupsData('', '', this.props.userData.id)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.accessGroups !== this.props.accessGroups) {
			this.props.HideLoader()
			this.setState({ ...this.props.accessGroups })
		}

		if (prevProps.activeRoleClaims !== this.props.activeRoleClaims) {
			this.setState({
				activeRoleClaims: this.handleArrayForm(this.props.activeRoleClaims),
			})
			this.props.HideLoader()
		}
		if (prevProps.accessGroups !== this.props.accessGroups) {
			this.props.HideLoader()
			this.setState({
				accessGroups: this.props.accessGroups,
			})
		}

		if (prevProps.userData !== this.props.userData) {
			this.props.GetAccessGroupsData('', '', this.props.userData.id)
		}

		if (prevProps.userData.users !== this.props.userData.users) {
			this.setState({
				usersOptions: this.getPartnerUserOptions(),
			})
			if (!_.isEmpty(this.state.searchUser)) {
				let result = this.props.userData.users
				if (result) {
					const obj = {
						value: result.id,
						label: result.name,
					}
					this.setState({ selectLogin: obj })
				}
			}

			this.props.HideLoader()
		}
	}

	handlePartnerUserChange = (event, value) => {
		this.setState({ selectLogin: value, userLogin: value.value })
	}

	getPartnerUserOptions = () => {
		const usersList = this.props.userData.users
		let usersSelectOptions = []

		if (usersList !== undefined) {
			usersList.map(item => {
				usersSelectOptions.push({
					value: item.id,
					label: item.cpf + ' - ' + item.name,
				})
			})
		}
		return usersSelectOptions
	}

	handleNew = () => {
		this.props.history.push(`/groups/new`)
	}

	handleEdit = i => {
		this.props.history.push(`/groups/edit/${i.id}`)
	}

	handleView = i => {
		this.props.history.push(`/groups/${i.id}`)
	}

	handleChange = (e, item) => {
		const id = item.target.id || item.target.name
		const value = item.target.value
		this.setState({ [id]: value })
	}

	handleFilter = clear => {
		this.props.GetAccessGroupsData(this.state.groupName, this.state.userLogin, this.props.userData.id)
		//
		if (clear) {
			this.setState({ filtered: false })
		} else {
			this.setState({ filtered: true })
		}
	}

	handleClearFilter = () => {
		this.setState({ groupName: '', userLogin: '', selectLogin: '' }, () => this.handleFilter(true))
	}

	render() {
		const { t, classes } = this.props
		const { accessDataFailed, filtered, results, currentPage, pageSize, recordCount, groupName, selectLogin, usersOptions } = this.state

		return (
			<Container>
				<DocumentTitle title={getProgramTitle()} />
				<Grid xs={12}>
					<div
						style={{
							alignItems: 'center',
							display: 'flex',
							height: '40px',
							marginBottom: '25px',
						}}
					>
						<Section title={t('MENU_GROUPS')} style={{ flexGrow: 1 }} />
						<Button style={{ marginLeft: '15px' }} size="sm" variant="contained" color="greenButtonColor" onClick={() => this.handleNew()}>
							{t('BUTTON_CREATE')}
						</Button>
					</div>
				</Grid>
				<SearchBox>
					<GridItem xs={12} sm={6} md={6}>
						<CssTextFieldOld
							fullWidth
							label={t('FIELD_ACCESS_GROUP_NAME')}
							id="groupName"
							name="groupName"
							value={groupName}
							onChange={this.handleChange.bind(null, 'groupName')}
							variant="outlined"
							size="small"
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<Autocomplete
							styles={customSelectStyles}
							id="combo-box-demo"
							size="small"
							options={usersOptions}
							getOptionLabel={option => (option.label ? option.label : '')}
							getOptionSelected={(option, value) => value === undefined || value === '' || option.id === value.id}
							disableClearable={true}
							onChange={this.handlePartnerUserChange}
							value={selectLogin}
							PopperComponent={CustomPopper}
							renderInput={params => (
								<div ref={params.InputProps.ref}>
									<CssTextFieldOld
										style={{
											backgroundColor: '#ffffff',
											width: '100%',
										}}
										{...params}
										noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
										label={t('FIELD_ACCESS_USERS_LOGIN')}
										variant="outlined"
										size="small"
									/>
								</div>
							)}
						/>
					</GridItem>

					<GridContainer
						style={{
							margin: '20px 0px 0px 0px',
						}}
					>
						<GroupButtons xs={12} sm={12} md={12}>
							<Button size="sm" variant="contained" style={{ margin: 0 }} color="greenBorderButtonColor" onClick={() => this.handleClearFilter()}>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>{' '}
							<Button size="sm" variant="contained" style={{ margin: 0 }} color="greenButtonColor" onClick={() => this.handleFilter(false)}>
								{t('BUTTON_FIND')}
							</Button>
						</GroupButtons>
					</GridContainer>
				</SearchBox>

				{/*  */}
				{filtered ? <Section title={t('TITLE_SEARCH_RESULTS')} /> : ''}
				<GridContainer justifyContent="center" className="search-container-form" style={{ paddingLeft: '15px' }}>
					{results && results.length > 0 && (
						<Fragment>
							{results &&
								results.map((item, index) => [
									<GriTable
										key={index}
										id={item.id}
										history={this.props.history}
										labels={[t('FIELD_GROUP'), t('FIELD_DESCRIPTION'), t('FIELD_STATUS')]}
										values={[
											item.name,
											item.description,
											<div
												style={{
													display: 'flex',
													lineHeight: 0.8,
													marginTop: '4px',
												}}
											>
												{item.status ? (
													<Icon
														className="icon-grid_ativo"
														style={{
															fontSize: '15px',
															color: '#9FB029',
														}}
													/>
												) : (
													<Icon
														className="icon-grid_inativo"
														style={{
															fontSize: '15px',
															color: '#38B9E9',
														}}
													/>
												)}
												<div
													style={{
														marginTop: '3px',
														marginLeft: '7px',
													}}
												>
													{item.status ? t('ACTIVE') : t('INACTIVE')}
												</div>
											</div>,
										]}
									/>,
								])}
						</Fragment>
					)}

					{(results && results.length === 0) || accessDataFailed ? <NoResults value={t('RESULTS_NOT_FOUND')} /> : ''}
					{results && results.length > 0 && (
						<div
							style={{
								textAlign: 'center',
								marginTop: '20px',
								width: '100%',
							}}
						>
							<CustomPagination
								pageSize={pageSize}
								recordCount={recordCount}
								offset={pageSize * (currentPage - 1)}
								clickEvent={(e, offset) => this.handleSearch(Math.round(offset / pageSize) + 1)}
							/>
						</div>
					)}
				</GridContainer>
			</Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		accessData: state.access.accessData,
		accessGroups: state.access.accessGroups,
		activeRoleClaims: state.access.activeRoleClaims,
		userData: state.users.userData,
		accessDataFailed: state.users.accessDataFailed,
	}
}
// GetAccessGroupsData, GetActiveRoleClaimsData, PostAccessData,
const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetCurrentPartner,
			GetAccessGroupsData,
			ShowLoader,
			HideLoader,
			SweetAlert,
			HideAlert,
		},
		dispatch,
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(extendedFormsStyle),
	connectRouterState({ searchName: '', searchUser: '', currentPage: 1 }),
	withTranslation(),
)(Groups)
