import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components

import Cards from './components/cards'
import { Controller } from 'react-hook-form'

// @ Functions
import { CreateProductByMultiple, handleCalculateValue } from './Functions'

// @ Styles and Utils
import { CssTextField } from '../../../../Filter/styles'
import { DefaultProductData } from './Functions/utils'
import Loader from 'components/Loader/Loader'

// @ Mui
import { Autocomplete, CircularProgress, FormLabel, Grid } from '@mui/material'

// @ Components
import Configuration from './components/Configuration'
import CustomModal from '../../../../../../Modal'
import { ParameterizationByDivision } from '../../../Functions'
import { ProductByDivision } from './Functions'
import SnackDefault from '../../../../../../../../../../../components/SnackDefault'
import { TypeOfCashback } from '../../../../generalTab/Functions'
import { useHistory } from 'react-router-dom'

const DefaultModal = { open: false, title: '', subtitle: '' }

const ParameterizeDivisionTB = ({
	useData,
	productData,
	rules,
	isMultiple,
	modalParameterization,
	setModalParameterization,
	division,
	viewMode,
	setViewMode,
	selectedDivisionIdRed,
	loading,
	setLoading,
	onReset,
}) => {
	const { t } = useTranslation()
	const history = useHistory()

	const [cashbackValue, setcashbackValue] = useState()
	const [GenerationType, setGenerationType] = useState([])
	const [divisionProductData, setDivisionProductData] = useState({})
	const [modalTypeOfCashback, setModalTypeOfCashback] = useState(DefaultModal)
	const [customCashbackValue, setCustomCashbackValue] = useState('')
	const [circularProgress, setCircularProgress] = useState(false)

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const { control, watch, reset, setValue, errors } = useData

	const { typeOfCashback, cashbackPercentual, pametrizationName } = watch()

	const [defaultType, setDefaultType] = useState({
		typeOfCashback: typeOfCashback || {
			key: '0',
			value: 'Unknow',
		},
		cashbackPercentual: cashbackPercentual,
	})

	const fetchCalculation = async pointsValueWatch => {
		await handleCalculateValue(
			productData?.price || divisionProductData?.price,
			typeOfCashback?.key,
			pointsValueWatch || cashbackPercentual || divisionProductData?.cashbackPercentual || 0,
			setCustomCashbackValue,
		)
	}

	const fetchGenerationType = () => {
		TypeOfCashback({ setGenerationType, setCircularProgress })
	}

	const goToBack = () => {
		onReset()
		setViewMode(true)
	}

	const handleParameterization = () => {
		const params = {
			goToBack,
			productData,
			typeOfCashback,
			cashbackPercentual,
			setSnackStatus,
			typeOfBenefitCashback: '2',
			setLoading,
			history,
		}

		if (selectedDivisionIdRed?.length > 0) {
			CreateProductByMultiple({
				...params,
				divisionsId: selectedDivisionIdRed,
				pametrizationName,
			})
		} else {
			ParameterizationByDivision({
				...params,
				divisionId: division?.id,
			})
		}

		setModalParameterization({ open: false })
		setViewMode(true)
	}

	useEffect(() => fetchGenerationType(), [])

	const onConfirmTypeOfCashback = newValue => {
		setValue('typeOfCashback', newValue)
		setValue('cashbackPercentual', 0)
		setModalTypeOfCashback({})
	}

	useEffect(() => {
		selectedDivisionIdRed?.length === 0 &&
			ProductByDivision(division?.id, 'Cashback', productData?.id, setDivisionProductData, setSnackStatus, setLoading).then(response => {
				if (!_.isEmpty(response)) {
					const { typeOfCashback, cashbackPercentual } = response
					if (typeOfCashback?.key === '1') {
						typeOfCashback.value = 'Não gera cashback'
					}

					if (typeOfCashback?.key === '2') {
						typeOfCashback.value = 'Padrão'
					}

					if (typeOfCashback?.key === '3') {
						typeOfCashback.value = 'Percentual'
					}

					setDefaultType({
						typeOfCashback: typeOfCashback,
						cashbackPercentual: cashbackPercentual,
					})
					reset({
						typeOfCashback: typeOfCashback,
						cashbackPercentual: cashbackPercentual || 0,
					})
				}
			})
	}, [division])

	return (
		<S.CardsContent>
			{loading && <Loader />}
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => handleParameterization()}
			/>

			<CustomModal
				onClose={() => {
					setValue('typeOfCashback', defaultType?.typeOfCashback)
					setModalTypeOfCashback(DefaultModal)
				}}
				open={modalTypeOfCashback?.open}
				title={modalTypeOfCashback?.title}
				subtitle={modalTypeOfCashback?.subtitle}
				onConfirm={() => onConfirmTypeOfCashback(modalTypeOfCashback?.newValue)}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			{isMultiple && <S.Line />}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Configuração do benefício*')}</span>
						<Controller
							name="typeOfCashback"
							control={control}
							render={({ value, onChange }) => (
								<Autocomplete
									options={GenerationType}
									getOptionLabel={option => (option.value ? t(option.value) : '')}
									disableClearable={true}
									disablePortal
									disabled={viewMode || circularProgress}
									value={value}
									fullWidth
									onChange={(_event, newValue) => {
										if (
											defaultType?.typeOfCashback?.key !== newValue?.key &&
											!_.isEmpty(defaultType?.typeOfCashback) &&
											rules?.cashbackRules?.automaticPercentualProduct
										) {
											setModalTypeOfCashback({
												open: true,
												title: `Sua parametrização de cashback está definida como automática.`,
												subtitle: `Ao editar este produto ele passará a ser ${t(newValue?.value)}.`,
												newValue: newValue,
											})
											return
										}
										setValue('cashbackPercentual', 0)
										onChange(newValue)
									}}
									renderInput={params => (
										<CssTextField
											{...params}
											variant="outlined"
											size="small"
											error={errors?.typeOfCashback ? true : false}
											helperText={errors?.typeOfCashback?.message}
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{circularProgress && <CircularProgress color="inherit" size={20} />}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							)}
						/>
					</FormLabel>
				</Grid>

				{/* <Grid item xs={12}>
					<S.CashbackCard>
						<S.CashbackCardIcon className="icon-i_atencao" />
						<S.CashbackCardText>Percentual padrão de cashback permitido:</S.CashbackCardText>
						<S.CashbackCardValue>3%</S.CashbackCardValue>
					</S.CashbackCard>
				</Grid> */}

				<Grid item xs={12} sm={9}>
					{typeOfCashback?.key > '1' && <Cards customCashbackValue={customCashbackValue} typeOfCashback={typeOfCashback?.key} />}
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12}>
				{typeOfCashback?.key >= '3' && (
					<Configuration
						viewMode={viewMode}
						errors={errors}
						cashbackValue={cashbackValue}
						setcashbackValue={setcashbackValue}
						customCashbackValue={customCashbackValue}
						fetchCalculation={fetchCalculation}
						control={control}
						cashbackPercentual={cashbackPercentual}
						typeOfCashback={typeOfCashback?.key}
					/>
				)}
			</Grid>
		</S.CardsContent>
	)
}

ParameterizeDivisionTB.propTypes = {
	rules: PropTypes.object,
	productData: PropTypes.object,
	isMultiple: PropTypes.bool,
	modalParameterization: PropTypes.object,
	setModalParameterization: PropTypes.func,
	division: PropTypes.object,
	viewMode: PropTypes.bool,
	setViewMode: PropTypes.func,
	selectedDivisionIdRed: PropTypes.array,
	useData: PropTypes.object,
	loading: PropTypes.bool,
	onReset: PropTypes.func,
	setLoading: PropTypes.func,
}

ParameterizeDivisionTB.defaultProps = {
	divisionProductData: DefaultProductData,
	isMultiple: false,
}

export default ParameterizeDivisionTB
