import styled from 'styled-components/macro'

import theme from '../../../assets/theme/CustomTheme'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	background-color: ${theme.white};
	justify-content: center;
	align-items: center;

	overflow-x: hidden;
	overflow-y: hidden;

	width: 100%;
	height: 100vh;
	background: url(${({ themeColor }) => themeColor?.images?.loginPage?.background?.url || ''});
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat, repeat;

	.logoVXP {
		position: absolute;
		right: 0;
		bottom: 0;
		padding: 25px;
	}

	.cardShadow {
		/* box-shadow: 0px 5px 15px 3px rgba(117, 117, 117, 1); */
		-webkit-box-shadow: 0px 3px 6px #00000029;
		-moz-box-shadow: 0px 3px 6px #00000029;
		box-shadow: 0px 3px 6px #00000029;
	}

	.cardHeader {
		margin-top: -90px;
		padding: 20px 0;
		border-radius: 6px;
		background-color: ${theme.white};
	}

	.cardCenter {
		text-align: center;
	}

	.cardHeaderTitle {
		border-radius: 0 0 6px 6px;
		padding: 10px;
		text-align: center;
		font-weight: 500;
		background-color: ${theme.darkBlue};
		color: ${theme.white};
	}

	form {
		width: 370px;
		max-width: 420px;

		background: ${theme.white};
		color: ${theme.textColor};
		font-size: 16px;
		padding: 30px;
		border-radius: 6px;
	}

	.formLabels {
		display: flex;
		flex-direction: column;
		height: auto;
		justify-content: space-between;
		margin: 30px 0;
	}

	.formLabels2 {
		display: flex;
		flex-direction: column;
		height: auto;
		justify-content: space-between;
		margin: 30px 0;
		text-align: center;
	}

	.formLabels3 {
		margin: 30px 0;
	}

	.textClick {
		color: ${({ themeColor }) => themeColor?.structure?.backgroundColor || ''};
		cursor: pointer;
	}

	.text {
		color: #333333;
	}

	.textSelected {
		display: block;
		background-color: ${({ themeColor }) => themeColor?.structure?.backgroundColor || ''};
		border: 2px solid ${({ themeColor }) => themeColor?.structure?.backgroundColor || ''};
		color: white;
		padding: 12px 5px;
		margin-top: 5px;
		border-radius: 5px;
		cursor: pointer;
	}

	.textUnselected {
		display: block;
		background-color: white;
		border: 2px solid ${({ themeColor }) => themeColor?.structure?.backgroundColor || ''};
		color: ${({ themeColor }) => themeColor?.structure?.backgroundColor || ''};
		padding: 12px 5px;
		margin-top: 5px;
		border-radius: 5px;
		cursor: pointer;
	}

	.buttonForget {
		color: ${theme.white};
		cursor: pointer;
		float: right;
		font-size: 14px;
		position: relative;
		right: -14px;
		top: 30px;
	}

	.buttonForget:hover {
		color: ${theme.white};
	}
	.buttonForget:visited {
		color: ${theme.white};
	}
	.buttonForget:active {
		color: ${theme.white};
	}
`
