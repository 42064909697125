import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

//@ components
import Filters from './components/Filter'
import NoDivisionTab from './components/NoDivisionTab'
import GeneralTab from './components/generalTab'
import DivisionTab from './components/DivisionTab'

const Cashback = ({
	rules,
	typeOfBenefit,
	setOnTab,
	setTabOn,
	productData,
	multiple,
	editMode,
	setEditMode,
	setMultiple,
	setDivision,
	setParameterization,
	setMultipleParameterization,
	typeOfBenefitCashback,
	setTypeOfBenefitCashback,
	setReloadProduct,
	division,
	circularProgress,
	selectedDivisionIdRed,
	setSelectedDivisionIdRed,
}) => {
	const [generalTab, setGeneralTab] = useState(false)
	const [byDivisionTab, setByDivisionTab] = useState(false)
	const [noDivisionTab, setNoDivisionTab] = useState(false)
	const [listDivision, setListDivision] = useState([])

	useEffect(() => {
		setOnTab({
			onTabGeneration: false,
			onTabRecue: false,
			onTabCashback: true,
		})
		setTabOn({
			cashback: true,
			redeem: false,
			points: false,
		})
	}, [])

	useEffect(() => {
		if (!editMode) {
			setReloadProduct(true)
		}
	}, [editMode])

	const sharedProps = {
		rules,
		multiple,
		setMultiple,
		division,
		circularProgress,
		editMode: editMode,
		selectedDivisionIdRed,
		productData: productData,
		setSelectedDivisionIdRed,
		setEditMode: setEditMode,
		setDivision: setDivision,
		listDivision: listDivision,
		typeOfBenefit: typeOfBenefit,
		setGeneralTab: setGeneralTab,
		setListDivision: setListDivision,
		setByDivisionTab: setByDivisionTab,
		setNoDivisionTab: setNoDivisionTab,
		setParameterization: setParameterization,
		typeOfBenefitCashback: typeOfBenefitCashback,
		setTypeOfBenefitCashback: setTypeOfBenefitCashback,
		setMultipleParameterization: setMultipleParameterization,
	}

	return (
		<S.Container>
			<Filters {...sharedProps} />
			<S.Components>
				{noDivisionTab && <NoDivisionTab {...sharedProps} />}
				{generalTab && <GeneralTab {...sharedProps} />}
				{byDivisionTab && <DivisionTab {...sharedProps} />}
			</S.Components>
		</S.Container>
	)
}

Cashback.propTypes = {
	rules: PropTypes.object,
	typeOfBenefit: PropTypes.string,
	setOnTab: PropTypes.func,
	setTabOn: PropTypes.func,
	division: PropTypes.array,
	circularProgress: PropTypes.bool,
	multiple: PropTypes.bool,
	setMultiple: PropTypes.func,
	productData: PropTypes.object,
	setDivision: PropTypes.func,
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func,
	setParameterization: PropTypes.func,
	setMultipleParameterization: PropTypes.func,
	typeOfBenefitCashback: PropTypes.string,
	setTypeOfBenefitCashback: PropTypes.func,
	setReloadProduct: PropTypes.func,
	selectedDivisionIdRed: PropTypes.array,
	setSelectedDivisionIdRed: PropTypes.func,
}

export default Cashback
