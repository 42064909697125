import {
	roseColor,
	primaryColor,
	infoColor,
	successColor,
	warningColor,
	dangerColor,
	whiteColor,
	blackColor,
	grayColor,
	hexToRgb,
} from 'assets/jss/material-dashboard-pro-react.jsx'

import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

const navPillsStyle = (theme) => ({
	root: {
		marginTop: '0px',
		paddingLeft: '0',
		marginBottom: '0',
		overflow: 'visible !important',
	},
	flexContainer: {
		[theme.breakpoints.down('md')]: {
			display: 'flex',
			flexWrap: 'wrap',
		},
	},
	displayNone: {
		display: 'none !important',
	},
	fixed: {
		overflowX: 'visible',
	},
	horizontalDisplay: {
		display: 'block',
	},
	pills: {
		float: 'left',
		position: 'relative',
		display: 'block',
		borderRadius: '5px',
		minWidth: '100px',
		textAlign: 'center',
		transition: 'all .3s',
		padding: '10px 15px',
		color: grayColor[6],
		height: 'auto',
		opacity: '1',
		maxWidth: '100%',
		margin: '1px',
		backgroundColor: grayColor[8],
		[theme.breakpoints.down('sm')]: {
			minWidth: '240px',
		},
		[theme.breakpoints.down('md')]: {
			minWidth: '49%',
			maxWidth: '228px',
		},
	},
	pillsWithIcons: {
		borderRadius: '4px',
	},
	tabIcon: {
		width: '30px',
		height: '30px',
		display: 'block',
		margin: '15px 0',
	},
	tabTitle: {
		fontWeight: '600',
	},
	wrapper: {
		display: 'block',
	},
	mobileHeader: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	horizontalPills: {
		width: '100%',
		float: 'none !important',
		'& + button': {
			margin: '10px 0',
		},
	},
	labelContainer: {
		padding: '0!important',
		color: 'inherit',
	},
	label: {
		lineHeight: '24px',
		textTransform: 'none',
		fontSize: '16px',
		fontWeight: '400',
		padding: '5px 20px',
		position: 'relative',
		display: 'block',
		color: 'inherit',
		[theme.breakpoints.down('lg')]: {
			padding: '5px 15px',
		},
	},
	contentWrapper: {
		marginTop: '20px',
	},
	primary: {
		'&,&:hover': {
			color: whiteColor,
			backgroundColor: primaryColor[0],
			boxShadow:
				'0 4px 20px 0px rgba(' +
				hexToRgb(blackColor) +
				', 0.14), 0 7px 10px -5px rgba(' +
				hexToRgb(primaryColor[0]) +
				', 0.4)',
		},
	},
	info: {
		'&,&:hover': {
			color: whiteColor,
			backgroundColor: infoColor[0],
			boxShadow:
				'0 4px 20px 0px rgba(' +
				hexToRgb(blackColor) +
				', 0.14), 0 7px 10px -5px rgba(' +
				hexToRgb(successColor[0]) +
				', 0.4)',
		},
	},
	germiniMainColor: {
		'&,&:hover': {
			color:
				getThemeColorFromLocalStorage() &&
				getThemeColorFromLocalStorage().navigation &&
				getThemeColorFromLocalStorage().navigation.buttons &&
				getThemeColorFromLocalStorage().navigation.buttons.textColor,
			backgroundColor:
				getThemeColorFromLocalStorage() &&
				getThemeColorFromLocalStorage().navigation &&
				getThemeColorFromLocalStorage().navigation.buttons &&
				getThemeColorFromLocalStorage().navigation.buttons
					.backgroundColor,
			boxShadow:
				'0 2px 2px 0 rgba(' +
				hexToRgb(
					getThemeColorFromLocalStorage() &&
						getThemeColorFromLocalStorage().navigation &&
						getThemeColorFromLocalStorage().navigation.buttons &&
						getThemeColorFromLocalStorage().navigation.buttons
							.backgroundColor,
				) +
				', 0.14), 0 3px 1px -2px rgba(' +
				hexToRgb(
					getThemeColorFromLocalStorage() &&
						getThemeColorFromLocalStorage().navigation &&
						getThemeColorFromLocalStorage().navigation.buttons &&
						getThemeColorFromLocalStorage().navigation.buttons
							.backgroundColor,
				) +
				', 0.2), 0 1px 5px 0 rgba(' +
				hexToRgb(
					getThemeColorFromLocalStorage() &&
						getThemeColorFromLocalStorage().navigation &&
						getThemeColorFromLocalStorage().navigation.buttons &&
						getThemeColorFromLocalStorage().navigation.buttons
							.backgroundColor,
				) +
				', 0.12)',
		},
	},
	success: {
		'&,&:hover': {
			color: whiteColor,
			backgroundColor: successColor[0],
			boxShadow:
				'0 2px 2px 0 rgba(' +
				hexToRgb(successColor[0]) +
				', 0.14), 0 3px 1px -2px rgba(' +
				hexToRgb(successColor[0]) +
				', 0.2), 0 1px 5px 0 rgba(' +
				hexToRgb(successColor[0]) +
				', 0.12)',
		},
	},
	warning: {
		'&,&:hover': {
			color: whiteColor,
			backgroundColor: warningColor[0],
			boxShadow:
				'0 4px 20px 0px rgba(' +
				hexToRgb(blackColor) +
				', 0.14), 0 7px 10px -5px rgba(' +
				hexToRgb(warningColor[0]) +
				', 0.4)',
		},
	},
	danger: {
		'&,&:hover': {
			color: whiteColor,
			backgroundColor: dangerColor[0],
			boxShadow:
				'0 4px 20px 0px rgba(' +
				hexToRgb(blackColor) +
				', 0.14), 0 7px 10px -5px rgba(' +
				hexToRgb(warningColor[0]) +
				', 0.4)',
		},
	},
	rose: {
		'&,&:hover': {
			color: whiteColor,
			backgroundColor: roseColor[0],
			boxShadow:
				'0 4px 20px 0px rgba(' +
				hexToRgb(blackColor) +
				', 0.14), 0 7px 10px -5px rgba(' +
				hexToRgb(roseColor[0]) +
				', 0.4)',
		},
	},
	alignCenter: {
		alignItems: 'center',
		justifyContent: 'center',
	},
})

export default navPillsStyle
