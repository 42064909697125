import React from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'perfect-scrollbar'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'

import withStyles from '@material-ui/core/styles/withStyles'

import {
	Collapse,
	Hidden,
	ListItemText,
	ListItem,
	List,
	Drawer,
} from '@material-ui/core'

// core components
import AdminNavbarLinks from 'components/Navbars/AdminNavbarLinks.jsx'
import NewMenu from 'components/NewMenu/index.js'
import sidebarStyle from 'assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx'
import BorderImg from 'assets/img/u183.png'
import { withTranslation } from 'react-i18next'

import { FormatCNPJ } from 'utils/utils'
import compose from 'utils/compose'

import logoF from 'assets/img/logo_Germini.png'
import { createTheme, MuiThemeProvider } from '@material-ui/core'

import FileUpload from 'components/CustomUpload/ImgUpload.jsx'

import Loader from 'components/Loader/Loader.jsx'

import {
	GetCurrentPartner,
	UpdatePartnerData,
	PostPartnersBranchData,
	UpdatePartnersBranchData,
	SetFileData,
	ImageUpload,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
	GetCountryData,
	GetStateData,
	GetCityData,
} from 'redux/actions'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import GeneralContext from '../../GeneralContext'
import { TransitionsModal } from 'components/TransitionsModal'

var ps

const theme = createTheme({
	overrides: {
		MuiListItem: {
			divider: {
				borderBottom: '1px solid',
				backgroundClip: 'padding-box',
				borderImageSource: `url(${BorderImg})`,
				borderImageSlice: '3',
			},
		},
	},
})

class SidebarWrapper extends React.Component {
	componentDidMount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps = new PerfectScrollbar(this.refs.sidebarWrapper, {
				suppressScrollX: true,
				suppressScrollY: false,
			})
		}
	}
	componentDidUpdate(prevProps, prevState) {
		if (prevProps.imageUrl !== this.props.imageUrl) {
			console.log('-> ', this.props.imageUrl)
		}
		if (prevProps.imageFile !== this.props.imageFile) {
			console.log('-> ', this.props.imageFile)
		}
	}
	componentWillUnmount() {
		if (navigator.platform.indexOf('Win') > -1) {
			ps.destroy()
		}
	}
	render() {
		const { className, user } = this.props
		return (
			<div className={className} ref="sidebarWrapper">
				{user}
				<br />
				<NewMenu />
			</div>
		)
	}
}

class Sidebar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			openAvatar: false,
			miniActive: true,
			open: false,
			edit: false,
			...this.getCollapseStates(props.routes),
		}
	}

	handleOpen = () => {
		this.setState({ open: true })
	}

	handleClose = () => {
		this.setState({ open: false })
	}

	getCollapseStates = routes => {
		let initialState = {}
		routes &&
			routes.map((prop, key) => {
				if (prop.collapse) {
					initialState = {
						[prop.state]: this.getCollapseInitialState(prop.views),
						...this.getCollapseStates(prop.views),
						...initialState,
					}
				}
				return null
			})
		return initialState
	}

	getCollapseInitialState(routes) {
		for (let i = 0; i < routes.length; i++) {
			if (
				routes[i].collapse &&
				this.getCollapseInitialState(routes[i].views)
			) {
				return true
			} else if (window.location.href.indexOf(routes[i].path) !== -1) {
				return true
			}
		}
		return false
	}
	// verifies if routeName is the one active (in browser input)
	activeRoute = routeName => {
		return this.props.location.pathname.indexOf(routeName) > -1
			? 'active'
			: ''
	}
	openCollapse(collapse) {
		var st = {}
		st[collapse] = !this.state[collapse]
		this.setState(st)
	}

	gotoPartnerProfile = () => {
		this.props.history.push(`/partnerProfile`)
	}
	// this function creates the links and collapses that appear in the sidebar (left menu)
	createLinks = routes => {
		const { t, classes, color, rtlActive } = this.props
		//
		return (
			routes &&
			routes
				.filter(n => n.sidebar)
				.map((prop, key) => {
					if (prop.redirect) {
						return null
					}
					if (prop.collapse) {
						var st = {}
						st[prop['state']] = !this.state[prop.state]
						const navLinkClasses =
							classes.itemLink +
							' ' +
							cx({
								[' ' +
								classes.collapseActive]: this.getCollapseInitialState(
									prop.views,
								),
							})
						const itemText =
							classes.itemText +
							' ' +
							cx({
								[classes.itemTextMini]:
									this.props.miniActive &&
									this.state.miniActive,
								[classes.itemTextMiniRTL]:
									rtlActive &&
									this.props.miniActive &&
									this.state.miniActive,
								[classes.itemTextRTL]: rtlActive,
							})
						const collapseItemText =
							classes.collapseItemText +
							' ' +
							cx({
								[classes.collapseItemTextMini]:
									this.props.miniActive &&
									this.state.miniActive,
								[classes.collapseItemTextMiniRTL]:
									rtlActive &&
									this.props.miniActive &&
									this.state.miniActive,
								[classes.collapseItemTextRTL]: rtlActive,
							})
						const caret =
							classes.caret +
							' ' +
							cx({
								[classes.caretRTL]: rtlActive,
							})
						return (
							<ListItem
								divider
								key={key}
								className={cx({
									[classes.item]: prop.icon !== undefined,
								})}
							>
								<NavLink
									to={'#'}
									className={navLinkClasses}
									onClick={e => {
										e.preventDefault()
										this.setState(st)
									}}
								>
									<ListItemText
										primary={
											rtlActive
												? t(prop.rtlName)
												: t(prop.name)
										}
										secondary={
											<b
												className={
													caret +
													' ' +
													(this.state[prop.state]
														? classes.caretActive
														: '')
												}
											/>
										}
										disableTypography={true}
										className={cx(
											{
												[itemText]:
													prop.icon !== undefined,
											},
											{
												[collapseItemText]:
													prop.icon === undefined,
											},
										)}
									/>
								</NavLink>
								<Collapse
									in={this.state[prop.state]}
									unmountOnExit
								>
									<List
										className={
											classes.list +
											' ' +
											classes.collapseList
										}
									>
										{this.createLinks(prop.views)}
									</List>
								</Collapse>
							</ListItem>
						)
					}
					const innerNavLinkClasses =
						classes.collapseItemLink +
						' ' +
						cx({
							[' ' + classes[color]]: this.activeRoute(prop.path),
						})
					const navLinkClasses =
						classes.itemLink +
						' ' +
						cx({
							[classes[color]]: this.activeRoute(prop.path),
						})
					const itemText =
						classes.itemText +
						' ' +
						cx({
							[classes.itemTextMini]:
								this.props.miniActive && this.state.miniActive,
							[classes.itemTextMiniRTL]:
								rtlActive &&
								this.props.miniActive &&
								this.state.miniActive,
							[classes.itemTextRTL]: rtlActive,
							[classes.bold]: this.activeRoute(prop.path),
						})
					const collapseItemText =
						classes.collapseItemText +
						' ' +
						cx({
							[classes.collapseItemTextMini]:
								this.props.miniActive && this.state.miniActive,
							[classes.collapseItemTextMiniRTL]:
								rtlActive &&
								this.props.miniActive &&
								this.state.miniActive,
							[classes.collapseItemTextRTL]: rtlActive,
						})

					return (
						<MuiThemeProvider theme={theme} key={Math.random()}>
							<ListItem
								divider
								key={key}
								className={cx(
									{ [classes.item]: prop.icon !== undefined },
									{
										[classes.collapseItem]:
											prop.icon === undefined,
									},
								)}
							>
								<NavLink
									to={prop.path}
									className={cx(
										{
											[navLinkClasses]:
												prop.icon !== undefined,
										},
										{
											[innerNavLinkClasses]:
												prop.icon === undefined,
										},
									)}
								>
									<ListItemText
										primary={t(prop.name)}
										disableTypography={true}
										className={cx(
											{
												[itemText]:
													prop.icon !== undefined,
											},
											{
												[collapseItemText]:
													prop.icon === undefined,
											},
										)}
									/>
								</NavLink>
							</ListItem>
						</MuiThemeProvider>
					)
				})
		)
	}

	handleSave = e => {
		let pictureUrl = e.currentTarget.files[0]
		this.props.ShowLoader(<Loader />)
		if (pictureUrl instanceof File) {
			this.props.ImageUpload(pictureUrl).then(async rest => {
				if (rest) {
					if (rest.success) {
						let imagem = rest.data
						this.context.setImg(imagem)
						await this.props
							.GetCurrentPartner()
							.then(() => this.setState({ disabledForm: true }))
					} else {
						alert('Erro ao atualizar a imagem, tente novamente')
					}
				}
				this.props.SetFileData(null)
			})
		}
	}

	changeImage(e) {}

	render() {
		const {
			classes,
			logo,
			image,
			routes,
			bgColor,
			rtlActive,
			t,
			userData,
		} = this.props

		//const { userData, generalRulesData } = this.state;
		//const { edit, setEdit } = useContext(GeneralContext)
		const { open } = this.state

		const userWrapperClass =
			classes.user +
			' ' +
			cx({
				[classes.whiteAfter]: bgColor === 'white',
			})

		let User = (
			<>
				<TransitionsModal
					open={open}
					handleClose={this.handleClose}
					image={
						this.context.img
							? this.context.img
							: userData.pictureUrl
					}
				/>
				<div
					className={userWrapperClass}
					style={{
						marginBottom: '10px',
						padding: '10px 15px',
						border: '1px solid #ddd',
						borderRadius: '10px',
					}}
				>
					<div
						style={{ padding: '0px 15px', cursor: 'pointer' }}
						onClick={
							this.context.edit
								? this.gotoPartnerProfile
								: this.handleOpen
						}
					>
						{userData && (
							<div className="fileinput text-center ">
								<>
									<label style={{ background: 'none' }}>
										<FileUpload
											file={
												this.context.img
													? this.context.img
													: userData.pictureUrl
											}
											sWidth="200px"
											edit={this.context.edit}
										/>
										<input
											id="file"
											name="file"
											type="file"
											onChange={this.handleSave}
											disabled={!this.context.edit}
										/>
									</label>
								</>
							</div>
						)}
					</div>
					<hr style={{ marginBottom: '10px' }}></hr>
					<span>
						<p style={{ margin: '-2px' }}>
							{userData && userData.name}
						</p>
					</span>
					<span>
						<p style={{ margin: '-2px' }}>
							{Boolean(userData && userData.cnpj) &&
								FormatCNPJ(userData.cnpj)}
						</p>
					</span>
					<NavLink to="/partnerProfile">
						<span
							className={classes.itemLink}
							style={{
								color: '#3c4858',
								maxHeight: '30px',
								fontSize: '15px',
								padding: '0',
								margin: '-2px',
							}}
						>
							<b>{t('SECTION_PARTNER_PROFILE')}</b>
						</span>
					</NavLink>
				</div>
			</>
		)
		var links = (
			<List className={classes.list}>{this.createLinks(routes)}</List>
		)

		const logoClasses =
			classes.logo +
			' ' +
			cx({
				[classes.whiteAfter]: bgColor === 'white',
			})
		var brand = (
			<div className={logoClasses}>
				<img
					src={this.state.miniActive ? logo : logoF}
					alt="logo"
					className={classes.img}
				/>
			</div>
		)
		const drawerPaper =
			classes.drawerPaper +
			' ' +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.drawerPaperRTL]: rtlActive,
			})
		const sidebarWrapper =
			classes.sidebarWrapper +
			' ' +
			cx({
				[classes.drawerPaperMini]:
					this.props.miniActive && this.state.miniActive,
				[classes.sidebarWrapperWithPerfectScrollbar]:
					navigator.platform.indexOf('Win') > -1,
			})
		return (
			<div ref="mainPanel">
				{/* mobile */}
				<Hidden mdUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={'right'}
						open={this.props.open}
						classes={{
							paper:
								drawerPaper +
								' ' +
								classes[bgColor + 'Background'],
						}}
						onClose={this.props.handleDrawerToggle}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{/* {brand} */}
						<SidebarWrapper
							className={sidebarWrapper}
							user={this.props.userData !== undefined ? User : ''}
							headerLinks={
								<AdminNavbarLinks rtlActive={rtlActive} />
							}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{
									backgroundImage: 'url(' + image + ')',
								}}
							/>
						) : null}
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						onMouseEnter={() =>
							this.setState({ miniActive: false })
						}
						onMouseLeave={() => this.setState({ miniActive: true })}
						variant="permanent"
						elevation={0}
						open
						classes={{
							paper:
								drawerPaper +
								' ' +
								classes[bgColor + 'Background'],
						}}
					>
						{/* {brand} */}
						<SidebarWrapper
							className={sidebarWrapper}
							user={User}
							links={links}
						/>
						{image !== undefined ? (
							<div
								className={classes.background}
								style={{
									backgroundImage: 'url(' + image + ')',
								}}
							/>
						) : null}
					</Drawer>
				</Hidden>
			</div>
		)
	}
}

Sidebar.defaultProps = {
	bgColor: 'blue',
}

Sidebar.propTypes = {
	classes: PropTypes.object.isRequired,
	bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
	rtlActive: PropTypes.bool,
	color: PropTypes.oneOf([
		'white',
		'red',
		'orange',
		'green',
		'blue',
		'purple',
		'rose',
	]),
	logo: PropTypes.string,
	image: PropTypes.string,
	routes: PropTypes.arrayOf(PropTypes.object),
	user: PropTypes.object,
}

const mapStateToProps = state => {
	return {
		userData: state.users.userData,
		userDataFailed: state.users.userDataFailed,

		updatePartnerData: state.partners.updatePartnerData,
		updatePartnerDataFailed: state.partners.updatePartnerDataFailed,

		postPartnersBranchData: state.partners.postPartnersBranchData,
		postPartnersBranchDataFailed:
			state.partners.postPartnersBranchDataFailed,

		updatePartnersBranchData: state.partners.updatePartnersBranchData,
		updatePartnersBranchDataFailed:
			state.partners.updatePartnersBranchDataFailed,

		imageUrl: state.images.imageUrl,
		imageFile: state.images.imageFile,

		countryData: state.location.countryData,
		stateData: state.location.stateData,
		cityData: state.location.cityData,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetCurrentPartner,
			UpdatePartnerData,
			PostPartnersBranchData,
			UpdatePartnersBranchData,
			SetFileData,
			ImageUpload,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
			GetCountryData,
			GetStateData,
			GetCityData,
		},
		dispatch,
	)

Sidebar.contextType = GeneralContext

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withTranslation(),
	withStyles(sidebarStyle),
)(Sidebar)
