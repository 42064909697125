import React, { useState, useEffect } from 'react'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { Card as MuiCard, CardContent, Box, Icon } from '@material-ui/core'
import styled from 'styled-components'

const Header = styled(Box)`
	display: flex;
	position: absolute;
	top: 0;
	left: 32px;
`

const IconBox = styled(Box)`
	width: 4rem;
	height: 4rem;
	border-radius: 10px;
`

const Title = styled.h5`
	text-transform: uppercase;
	font-weight: bold;
	align-self: end;
	padding-left: 1rem;
	line-height: 100%;
	margin: 0;
	font-size: 0.85rem;
`

export function Card({ children, border, bg, icon, iconBg, title, px, em }) {
	const [theme, setTheme] = useState({})

	useEffect(() => {
		setTheme(getThemeColorFromLocalStorage())
	}, [])

	return (
		<div
			style={{ width: '100%', position: 'relative', paddingTop: '2rem' }}
		>
			<Header>
				<IconBox variant="rounded" style={{ background: iconBg }}>
					<i
						class={icon}
						style={{
							color: 'white',
							display: 'flex',
							fontSize: '31px',
							justifyContent: 'center',
							height: '100%',
							alignItems: 'center',
						}}
					/>
				</IconBox>
				<Title
					variant="body1"
					style={{
						color:
							theme &&
							theme.titles &&
							theme.titles.secondary &&
							theme.titles.secondary.textColor,
					}}
				>
					{title}
				</Title>
			</Header>
			<MuiCard
				elevation={0}
				style={{
					borderRadius: '10px',
					border,
					background: bg,
				}}
			>
				<CardContent
					style={{
						marginTop: '2rem',
						paddingRight: !!px ? px : '',
						paddingLeft: !!px ? px : '',
						paddingBottom: !!em ? em : '',
					}}
				>
					{children}
				</CardContent>
			</MuiCard>
		</div>
	)
}
