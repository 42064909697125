/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import InputMask from "react-input-mask";
import { MuiInput } from "../Input";
import { NumericFormat } from "react-number-format";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import { FaUser, IconKey } from "../Icons";
export function MuiInputMask(_a) {
    var mask = _a.mask, value = _a.value, onChange = _a.onChange, defaultValue = _a.defaultValue, props = __rest(_a, ["mask", "value", "onChange", "defaultValue"]);
    return (_jsx(InputMask, __assign({ mask: mask, value: value, onChange: onChange, defaultValue: defaultValue }, { children: function (inputProps) { return _jsx(MuiInput, __assign({}, inputProps, { onChange: onChange }, props)); } })));
}
export var InputCNPJ = function (props) {
    return _jsx(MuiInputMask, __assign({ mask: "99.999.999/9999-99" }, props));
};
export var InputPHONE = function (props) {
    return _jsx(MuiInputMask, __assign({ mask: "(99) 9999-9999" }, props));
};
export var InputCPF = function (props) {
    var handleChange = function (e) {
        if (props.onChange) {
            props.onChange(e);
        }
    };
    return (_jsx(InputMask, __assign({ mask: "999.999.999-99", onChange: handleChange }, props, { children: function (inputProps) { return _jsx(MuiInput, __assign({}, inputProps, props)); } })));
};
export var InputCPForCNPJ = function (_a) {
    var _b;
    var onClear = _a.onClear, showIcon = _a.showIcon, props = __rest(_a, ["onClear", "showIcon"]);
    var _c = useState((_b = props === null || props === void 0 ? void 0 : props.value) !== null && _b !== void 0 ? _b : ""), inputValue = _c[0], setInputValue = _c[1];
    var mask = function (v) {
        v = v.replace(/\D/g, "");
        if (v.length <= 11) {
            v = v.replace(/(\d{3})(\d)/, "$1.$2");
            v = v.replace(/(\d{3})(\d)/, "$1.$2");
            v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
        }
        else {
            v = v.substring(0, 14);
            v = v.replace(/^(\d{2})(\d)/, "$1.$2");
            v = v.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
            v = v.replace(/\.(\d{3})(\d)/, ".$1/$2");
            v = v.replace(/(\d{4})(\d)/, "$1-$2");
        }
        return v;
    };
    useEffect(function () {
        if (onClear) {
            setInputValue("");
        }
    }, [onClear]);
    useEffect(function () {
        setInputValue(mask(props.value || ""));
    }, [props.value]);
    var handleChange = function (e) {
        var maskedValue = mask(e.target.value);
        setInputValue(maskedValue);
        if (props.onChange) {
            props.onChange(__assign(__assign({}, e), { target: __assign(__assign({}, e.target), { value: maskedValue }) }));
        }
    };
    return (_jsx(MuiInput, __assign({}, props, { value: inputValue, onChange: handleChange, InputProps: {
            startAdornment: (_jsx(_Fragment, { children: _jsx(IconButton, __assign({ size: "small", style: {
                        marginRight: showIcon ? 10 : 2,
                    } }, { children: showIcon && _jsx(FaUser, { size: 20, color: "#BEC1CC" }) })) })),
        } })));
};
export var InputPtBRCurrency = function (props) {
    var _a;
    var _b = useState((_a = props === null || props === void 0 ? void 0 : props.value) !== null && _a !== void 0 ? _a : ""), value = _b[0], setValue = _b[1];
    var handleOnChange = function (e) {
        var cleanedValue = e.target.value.replace(/\D/g, "");
        var numberValue = parseFloat(cleanedValue) / 100;
        var formattedValue = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
        }).format(numberValue);
        formattedValue = formattedValue.replace("R$", "R$ ").trim();
        setValue(formattedValue);
        if (props.onChange) {
            props.onChange(formattedValue);
        }
    };
    return _jsx(MuiInput, __assign({}, props, { value: value, onChange: handleOnChange }));
};
export var InputUSDCurrency = function (props) {
    var _a;
    var _b = useState((_a = props === null || props === void 0 ? void 0 : props.value) !== null && _a !== void 0 ? _a : ""), value = _b[0], setValue = _b[1];
    var handleOnChange = function (e) {
        var _a;
        var cleanedValue = e.target.value.replace(/\D/g, "");
        var numberValue = parseFloat(cleanedValue) || 0;
        var formattedValue;
        if (numberValue < 1000) {
            formattedValue = new Intl.NumberFormat("en-US", {
                maximumFractionDigits: 0,
            }).format(numberValue);
        }
        else {
            formattedValue = new Intl.NumberFormat("en-US", {
                useGrouping: true,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            }).format(numberValue);
            formattedValue = formattedValue.replace(/,/g, ".");
        }
        formattedValue = "".concat((_a = props === null || props === void 0 ? void 0 : props.maskedValue) !== null && _a !== void 0 ? _a : "$", " ").concat(formattedValue);
        setValue(formattedValue);
        if (props.onChange) {
            props.onChange(formattedValue);
        }
    };
    return _jsx(MuiInput, __assign({}, props, { value: value, onChange: handleOnChange }));
};
export var InputPercentageDOT = function (props) {
    return _jsx(NumericFormat, __assign({}, props, { customInput: MuiInput, thousandSeparator: true, suffix: "%", isNumericString: true }));
};
export var InputPercentage = function (props) {
    var handleOnChange = function (values) {
        var floatValue = values.floatValue;
        if (props.onChange) {
            props.onChange(floatValue !== undefined
                ? "".concat((floatValue * 100).toLocaleString("pt-BR", {
                    maximumFractionDigits: 2,
                }), ",")
                : "");
        }
    };
    return (_jsx(NumericFormat, __assign({}, props, { customInput: MuiInput, suffix: "%", decimalSeparator: ",", allowNegative: false, onValueChange: handleOnChange, isNumericString: true })));
};
export var InputDate = function (props) {
    return _jsx(MuiInputMask, __assign({}, props, { mask: "99/99/9999", placeholderChar: "\u2000" }));
};
