/* eslint-disable react/display-name */
import React from 'react'
import * as moment from 'moment'
import { Icon, Tooltip } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import { CustomButton } from './styles'
import { ExportLog } from '../../../../../../../../redux/api/paymentGateway'
import Cookies from 'js-cookie'

export const columns = (t, url, setModal) => [
	{
		title: 'Data de exportação',
		field: 'exportedAt',
		cellStyle: {
			width: '20% !important',
		},
		headerStyle: {
			width: '20% !important',
		},
		render: rowData => (
			<div>{moment(rowData?.exportedAt).format('DD/MM/YYYY HH:mm')}</div>
		),
	},
	{
		title: 'Período de consulta',
		field: 'initialPeriod',
		cellStyle: {
			width: '20% !important',
		},
		headerStyle: {
			width: '20% !important',
		},
		render: rowData => (
			<div>{moment(rowData?.exportedAt).format('DD/MM/YYYY HH:mm')}</div>
		),
	},
	{
		title: 'Nome do arquivo',
		field: 'name',
		cellStyle: {
			width: '20% !important',
		},
		headerStyle: {
			width: '20% !important',
		},
	},
	{
		title: 'Progresso',
		field: 'progress',
		cellStyle: {
			width: '20% !important',
		},
		headerStyle: {
			width: '20% !important',
		},
		sorting: false,
		render: rowData => (
			<div>
				<div style={{ fontSize: '14px' }}>
					{rowData?.progress + '%'}
				</div>
				<i
					style={{
						color:
							rowData?.progress === 100 ? '#5CB860' : '#E1BB00',
					}}
					className={
						rowData?.progress >= 50 && rowData?.progress < 100
							? 'icon-ico_progresso_50'
							: rowData?.progress === 100
								? 'icon-ico_progresso'
								: 'icon-ico_progresso_0'
					}
				/>
			</div>
		),
	},
	{
		title: 'Ações',
		field: 'actions',
		width: '10.5%',
		cellStyle: {
			width: '10.5%',
			maxWidth: '10.5%',
			minWidth: '10.5%',
			paddingTop: '3px',
			paddingBottom: '3px',
		},
		headerStyle: {
			width: '10.5%',
			maxWidth: '10.5%',
			minWidth: '10.5%',
		},

		sorting: false,
		render: rowData => (
			<div
				style={{
					display: 'flex',
					justifyContent: 'right',
				}}
			>
				<Tooltip arrow placement="top" title={t('Fazer download')}>
					<Button
						justIcon
						size="sm"
						color="greenButtonColor"
						// disabled={rowData?.progress !== 100}
						onClick={() => {
							ExportLog(rowData)
						}}
					>
						<Icon className=" icon-download" />
					</Button>
				</Tooltip>
				<Tooltip arrow placement="top" title={t('BUTTON_DELETE')}>
					<CustomButton
						justIcon
						size="sm"
						color="greenButtonColor"
						onClick={() => setModal({ open: true, id: rowData?.id })}
					>
						<Icon className="icon-ico_excluir" />
					</CustomButton>
				</Tooltip>
			</div>
		),
	},
]

export const reqDataLog = [
	{
		id: '',
		name: '',
		type: '',
		partnerId: String(Cookies.get('partnerId')) || '',
		exportedAt: null,
		initialPeriod: null,
		finalPeriod: null,
		orderByDescending: true,
		pageSize: 0,
		page: 0,
	},
];
