import Cookies from 'js-cookie'
import * as Yup from 'yup'
import { ValidateByDivisions } from '../../../../../../../../../../../redux/api/product'

export const Schema = Yup.object().shape({
	typeOfCashback: Yup.object()
		.nullable()
		.required('Campo obrigatório'),

	cashbackPercentual: Yup.string()
		.nullable()
		.when('typeOfCashback', {
			is: value => value?.key >= '3',
			then: Yup.string()
				.nullable()
				.test('cashbackPercentual', 'Valor de ser maior que 0', value => {
					return value > 0
				})
				.required('Campo obrigatório'),
		}),
	selectedDivisionIdRed: Yup.array().nullable(),
	pametrizationName: Yup.string()
		.nullable()
		.when('selectedDivisionIdRed', {
			is: value => value?.length > 0,
			then: Yup.string().required('Campo obrigatório'),
		}),
})

export const GetDivisionName = item => item?.segmentationType?.value || ''

export const ValidateStatusProductByDivisions = async ({ productId, partnerId, divisions, typeOfBenefit, page, pageSize }) => {
	try {
		const body = {
			partnerId: partnerId || '',
			productId: productId || '',
			divisions: divisions || [],
			typeOfBenefit: typeOfBenefit,
			pageSize: pageSize || 10,
			page: page || 1,
		}
		const res = await ValidateByDivisions(body)
		return res
	} catch (error) {
		return error
	}
}

export const HasParameterization = async ({ productData, selectedDivisionIdGen, setHasParameterization, setLoading, setSnackStatus }) => {
	setLoading(true)

	try {
		const PartnerId = String(Cookies.get('partnerId'))
		const res = await ValidateStatusProductByDivisions({
			productId: productData?.id,
			partnerId: PartnerId,
			divisions: selectedDivisionIdGen,
			typeOfBenefit: 'Cashback',
			pageSize: selectedDivisionIdGen?.length || 10,
		})
		if (res.status == 200) {
			setHasParameterization(res?.data)
		}
	} catch (error) {
		setSnackStatus({
			oopen: false,
			severity: 'error',
			text: 'Ocorreu um erro ao validar a parametrização',
		})
	} finally {
		setLoading(false)
	}
}
