import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

// @Components
import Loader from 'components/Loader/Loader.jsx'

// @ Context and Utils
import { cardsStyle } from '../../utils'
import CardLog from './CardLog'

export const CardServices = () => {
    const { t } = useTranslation()
    const [isLoaded, setIsLoaded] = useState(false)

    return (
        <Fragment>
            {isLoaded ? (
                <Loader />
            ) : (
                <div>
                    <CardLog />
                </div>
            )}
        </Fragment>
    )
}
