import styled from 'styled-components'
import { getThemeColorFromLocalStorage, ColorLuminance } from '../../../../redux/api/themeColors'

export const Container = styled.div`
	.css-1jxvthj-MuiFormLabel-root-MuiInputLabel-root {
		color: #333333 !important;

		:focus {
			color: #333333;
		}
		:after {
			color: #333333;
		}
	}
	.css-v4u5dn-MuiInputBase-root-MuiInput-root {
		:focus {
			color: #333333;
			border-bottom: 1px solid #333;
		}
		:after {
			color: #333333;
			border-bottom: 1px solid #333;
		}
	}
	.MuiInputBase-input {
		:focus {
			color: #333333;
			border-bottom: 1px solid #333;
		}
		:after {
			color: #333333;
			border-bottom: 1px solid #333;
		}
	}
	.TextField {
		margin-top: 25px;
	}

	td:nth-child(2),
	th:nth-child(2) {
		text-align: left;
	}
	.MuiToolbar-regular {
		min-height: 25px;
	}
	.hlQWrt .MuiTableBody-root tr td {
		padding: 5px 15px;
	}
`
export const Titles = styled.div`
	margin-bottom: 1.563rem;
`

export const ButtonAction = styled.div`
	height: 40px;
	width: 40px;
	min-width: 40px;
	border: 2px solid #fff;
	background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
	font-size: 20px;
	display: flex;
	color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.textColor};
	align-items: center;
	justify-content: center;
	border-radius: 6px;

	:hover {
		background-color: ${ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5)};
	}
`

export const IconsDiv = styled.div`
	font-size: 18px;
	padding-left: 15px;
	cursor: pointer;

	:disabled {
		cursor: not-allowed;
		opacity: 0.5;
	}
`
