import { TextField } from '@material-ui/core'
import styled from 'styled-components'

export const Text = styled(TextField)`
	/* min-height: 84px; */
	.MuiFormLabel-root.Mui-disabled {
		color: #5d5d5d;
	}
	min-height: ${props => props.minheight};
	.MuiInput-underline.Mui-disabled:before {
		border-bottom-style: unset;
	}
	.MuiInputLabel-formControl {
		white-space: nowrap;
		text-overflow: ellipsis;
		max-width: 100%;
	}
	.MuiInputBase-input {
		color: #5d5d5d;
	}

	.MuiInput-underline:after {
		border-bottom: 2px solid ${({ themeColor }) => themeColor?.navigation?.buttons?.backgroundColor};
	}
`
