/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { Box, Stack, Tooltip } from '@mui/material'
import { FormatMoney, FormatPoints, changeValues } from './Functions'

const Info = ({ parameterizationGeneration, parameterizationRedemption, productData, parametrizationCashback, openModal, type, Name, TypeOfBenefit }) => {
	const { t } = useTranslation()

	const [taxField, setTaxField] = useState()
	const [valueField, setValueField] = useState()
	const [pointsField, setPointsField] = useState()

	const sharedValues = {
		parameterizationGeneration,
		parameterizationRedemption,
		parametrizationCashback,
		type,
		taxField,
		valueField,
		pointsField,
		setTaxField,
		setValueField,
		setPointsField,
	}

	useEffect(() => {
		changeValues({ ...sharedValues })
	}, [parameterizationGeneration, parameterizationRedemption, parametrizationCashback])

	return (
		<S.ContainerT>
			<S.Description>{t(TypeOfBenefit || 'VALUE_BASED_ON_STANDARD_RATE')}</S.Description>
			<S.Container>
				<Stack gap="4rem" spacing={2} width="100%" display="flex" direction="row" padding="10px 13px" className="stack-info">
					{Name !== 'Cashback' ? (
						<>
							<Box className="box-info">
								<S.Title>{t('Taxa de administração')}</S.Title>
								{Name !== 'Resgate' && <S.SubTitle>{t('Programa')}</S.SubTitle>}
								<S.Value>{FormatMoney(taxField)}</S.Value>
							</Box>
							<Box className="box-info">
								<S.Title>{t('Valor bruto')}</S.Title>
								{Name !== 'Resgate' && <S.SubTitle>{t('Lastro')}</S.SubTitle>}
								<S.Value>{FormatMoney(valueField)}</S.Value>
							</Box>
							<Box className="box-info">
								<S.Title>{t('Pontos a acumular')}</S.Title>
								{Name !== 'Resgate' && <S.SubTitle>{t('Carteira do cliente')}</S.SubTitle>}
								<S.Value>{FormatPoints(pointsField)}</S.Value>
							</Box>
						</>
					) : (
						<Box className="box-info">
							<S.Title>{t('Cashback a acumular')}</S.Title>
							<S.SubTitle>{t('Carteira do cliente')}</S.SubTitle>
							<S.Value>{productData?.typeOfCashback?.value === "Manual" ? valueField + '%' : FormatMoney(valueField)}</S.Value>
						</Box>
					)}
				</Stack>
				<Tooltip arrow placement="top" title={t('Memória de cálculo')}>
					<Box className="stack-info-icon" onClick={openModal}>
						<i style={{ fontSize: '1.5rem', color: '#0c0c0c' }} className="icon-bot_memoria_calculo" />
					</Box>
				</Tooltip>
			</S.Container>
		</S.ContainerT>
	)
}

export default Info
