import React, { useState, useContext, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import _ from 'lodash'
import { useParams } from 'react-router-dom'

// @Components
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import GeneralContext from '../../../GeneralContext'

import ConfirmModal from './Confirm'
import { ModalProps, PropsSnack } from './mocks'

import SnackDefault from 'components/SnackDefault'
import { ChangeModuleStatus } from '../../../redux/api/partners/partners'

export default function ModuleDetails() {
	const { t } = useTranslation()
	const { id } = useParams()
	const [enablePayment, setEnablePayment] = useState('')
	const { setActivePay, getCurrentPartnerBase, setIsLoaded, activePay, isLoaded } = useContext(GeneralContext)
	const [confirmModalProps, setConfirmModalProps] = useState(ModalProps)

	const [snackStatus, setSnackStatus] = useState(PropsSnack)

	const enablePaymentMethods = async () => {
		setIsLoaded(true)
		try {
			const response = await ChangeModuleStatus(id)
			if (response?.status === 200) {
				setEnablePayment(!enablePayment)
				setActivePay(!enablePayment)

				setSnackStatus({ open: true, severity: 'success', text: t('FIELD_ALERT_SUCCESS') })
				return
			}
			setSnackStatus({ open: true, severity: 'error', text: t('MESSAGE_ERROR_OPERATION') })
		} catch (error) {
			console.log(error)
			setSnackStatus({ open: true, severity: 'error', text: t('MESSAGE_ERROR_OPERATION') })
		} finally {
			setIsLoaded(false)
		}
	}

	const onConfirmModal = () => {
		setConfirmModalProps({
			open: true,
			type: 'confirmSave',
			text: t('PARTICIPANTS_CONFIRM_ACTION_MESSAGE'),
			warningMessage: 'teste',
		})
	}

	const getAdditionalModule = async () => {
		setIsLoaded(true)
		try {
			const response = await getCurrentPartnerBase(id)
			console.log(response)
			if (response?.status === 200 && !_.isEmpty(response.data)) {
				setEnablePayment(response?.data?.isGerminiPayActive || false)
			}
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoaded(false)
		}
	}

	const hideModal = () => {
		setConfirmModalProps({ open: false, type: '', text: '' })
	}

	useEffect(() => {
		getAdditionalModule()
	}, [])

	useEffect(() => {
		setEnablePayment(activePay)
	}, [activePay])

	return (
		<Fragment>
			<ConfirmModal
				open={confirmModalProps.open}
				type={confirmModalProps.type}
				text={confirmModalProps.text}
				parameter={confirmModalProps.parameter}
				warningMessage={''}
				onConfirm={() => confirmModalProps.onConfirm}
				onCancel={() => hideModal()}
				enablePaymentMethods={enablePaymentMethods}
			/>
			<SnackDefault snackStatus={snackStatus} handleCloseSnack={() => setSnackStatus(PropsSnack)} />
			<Section title={t('MENU_ADDITIONAL_MODULES')} style={{ marginBottom: '25px' }} />
			{isLoaded && <Loader />}
			<S.BodyCard>
				<S.Title>Habilitar os serviços de pagamento?</S.Title>
				<S.Switch>
					<FormGroup>
						<FormControlLabel
							checked={enablePayment}
							control={<Switch onChange={event => onConfirmModal(event)} />}
							label={enablePayment ? t('BUTTON_YES') : t('BUTTON_NO')}
						/>
					</FormGroup>
				</S.Switch>
			</S.BodyCard>
		</Fragment>
	)
}
