import * as actionTypes from '../actionsTypes';
import * as API from '../../api/access/access';
import * as Loader from '../loader';

export const fetcAccess = (data) => {
	return {
		type: actionTypes.FETCH_ACCESS_DATA,
		accessData: data
	};
};

export const fetcAccessGroups = (data) => {
	return {
		type: actionTypes.FETCH_ACCESS_GROUPS,
		accessGroups: data
	};
};

export const fetcActiveRoleClaimsData = (data) => {
	return {
		type: actionTypes.FETCH_ACTIVE_ROLE_CLAIMS_DATA,
		activeRoleClaims: data
	};
};

export const fetcGroupPermissionsData = (data) => {
	return {
		type: actionTypes.FETCH_GROUP_PERMISSIONS_DATA,
		groupPermissionsData: data
	};
};
export const fetcGroupData = (data) => {
	return {
		type: actionTypes.FETCH_GROUP_DATA,
		groupData: data
	};
};

export const FetcUpdateGroupData = (data) => {
	return {
		type: actionTypes.FETCH_UPDATE_GROUP_DATA,
		groupData: data
	};
};

export const postAccess = (data) => {
	return {
		type: actionTypes.POST_ACCESS_DATA,
		postAccess: data
	};
};
export const PostClaims = (data) => {
	return {
		type: actionTypes.POST_CLAIMS_DATA,
		groupPermissionsData: data
	};
};

export const postAssignToRole = (data) => {
	return {
		type: actionTypes.POST_ACCESS_TO_ROLE_DATA,
		postAssignToRole: data
	};
};

export const fetcUpdateAccess = (data) => {
	return {
		type: actionTypes.UPDATE_ACCESS_DATA,
		updaeAccess: data
	};
};

// FAIL FETCH -------------------------------------
export const fetcAccessFailed = (error) => {
	return {
		type: actionTypes.FETCH_ACCESS_FAILED,
		accessDataFailed: error
	};
};
export const fetcPostClaimsFailed = (error) => {
	return {
		type: actionTypes.FETCH_GROUP_PERMISSIONS_FAILED,
		groupPermissionsDataFailed: error
	};
};

export const fetcGroupPermissionsFailed = (error) => {
	return {
		type: actionTypes.FETCH_GROUP_PERMISSIONS_FAILED,
		groupPermissionsDataFailed: error
	};
};
export const fetcGroupFailed = (error) => {
	return {
		type: actionTypes.FETCH_GROUP_FAILED,
		groupDataFailed: error
	};
};

//----------------------------------------------------
export const GetAccessGroupsData = (group, login, partnerId) => {
	return dispatch => (
		API.GetAccessGroups(group, login, partnerId).then(response => {
			dispatch(fetcAccessGroups(response));
			dispatch(Loader.hideLoader());
			return response;
		}).catch(error => {
			console.error(error);
			dispatch(fetcAccessFailed());
			dispatch(Loader.hideLoader());
		})
	);
};

export const GetActiveRoleClaimsData = () => {
	return dispatch => (
		API.GetActiveRoleClaims().then(response => {
			dispatch(fetcActiveRoleClaimsData(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetcAccessFailed());
			dispatch(Loader.hideLoader());
		})
	);
};

export const GetClaimsByGroupIdData = (id) => {
	return dispatch => (
		API.GetClaimsByGroupId(id).then(response => {
			dispatch(fetcGroupPermissionsData(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetcGroupPermissionsFailed());
			dispatch(Loader.hideLoader());
		})
	);
};


export const UpdateGroupData = (obj) => {
	return dispatch => (
		API.UpdateGroupData(obj).then(response => {
			dispatch(FetcUpdateGroupData(response));
			return response;
		}).catch(error => {
			console.error(error);
			dispatch(fetcAccessFailed());
			dispatch(Loader.hideLoader());
		})
	);
}

export const GetGroupData = (id) => {
	return dispatch => (
		API.GetGroupData(id).then(response => {
			dispatch(fetcGroupData(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetcGroupFailed());
			dispatch(Loader.hideLoader());
		})
	);
};

export const PostAccessData = (obj) => {
	return dispatch => (
		API.PostAccess(obj).then(response => {
			dispatch(postAccess(response));
			return response;
		}).catch(error => {
			console.error(error);
			dispatch(fetcAccessFailed());
			dispatch(Loader.hideLoader());
		})
	);
}

export const PostClaimsData = (obj) => {
	return dispatch => (
		API.PostClaims(obj).then(response => {
			dispatch(PostClaims(response));
			return response;
		}).catch(error => {
			console.error(error);
			dispatch(fetcPostClaimsFailed());
			dispatch(Loader.hideLoader());
		})
	);
}

export const PostAssignToRoleData = (obj) => {
	return dispatch => (
		API.PostAssignToRole(obj).then(response => {
			dispatch(postAssignToRole(response));
			return response;
		}).catch(error => {
			console.error(error);
			dispatch(fetcAccessFailed());
			dispatch(Loader.hideLoader());
		})
	);
}

export const PutAccessData = (id, obj) => {
	return dispatch => {
		API.UpdateAccess(id, obj).then(response => {
			dispatch(fetcUpdateAccess(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetcAccessFailed());
			dispatch(Loader.hideLoader());
		})
	};
}
