import styled from 'styled-components'

export const Container = styled.div`
	gap: 20px;
	display: flex;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: #ffffff 0% 0% no-repeat padding-box;
	border-radius: 6px;
	opacity: 1;
	padding: 20px 25px;
	width: 100%;
	max-width: 517px;
	flex-direction: column;

	table tbody {
		border: none;
	}

	th {
		border: none;
	}

	td:nth-child(2),
	th:nth-child(2) {
		border: none;
	}

	table tr:nth-child(even) {
		background-color: #fafafc;
	}

	.MuiTableContainer-root {
		box-shadow: none;
	}

	.muitxt {
		text-align: center;
		font: normal normal normal 16px/30px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
	}
`

export const Title = styled.div`
	text-align: left;
	font: normal normal bold 15px/60px Roboto;
	letter-spacing: 0px;
	color: ${({ color }) => color || '#000000'};
	opacity: 1;
`

export const CustomButton = styled.button`
	background-color: ${({ bg }) => bg || 'transparent'} !important;
	color: ${({ color }) => color || '#717171'} !important;
	border: 1px solid ${({ border }) => border || '#b2b2b2'} !important;
	box-shadow: none !important;
	height: 40px;
	cursor: pointer;
	&:hover {
		background-color: ${({ hoverBg }) => hoverBg} !important;
		color: #ffffff !important;
		border-color: ${({ hoverBg }) => hoverBg} !important;
	}

	i {
		font-size: 20px;
	}

	font: normal normal normal 16px Roboto;
	letter-spacing: 0px;
	opacity: 1;

	${({ disabled }) =>
		disabled && {
			backgroundColor: ' transparent !important',
			color: '#B2B2B2 !important',
			border: '1px solid #B2B2B2 !important',
			boxShadow: 'none !important',
			opacity: '0.7',
			cursor: 'not-allowed',
		}}
`

export const Icon = styled.i`
	font-size: 70px;

	opacity: 1;
	color: #c8c8c8;
`
