import styled from 'styled-components'

export const Container = styled.div`
	margin-right: -25px;

	.MuiTableCell-body > div {
		align-items: flex-start !important;
	}

	#grid {
		padding-left: 11px;
		padding-right: 15px;
	}

	#gridFor {
		margin-bottom: 30px;
		margin-top: 20px;
		padding-left: 8px;
		padding-right: 15px;
	}
`

export const SkeletonContainer = styled.div`
	width: 100%;
	max-width: 455px;
	margin-top: 20px;
	transform: translateX(14px);
`
