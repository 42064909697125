export default function convertAddressToId(addresses) {
	const result = []
	const businessAddress = 2
	const otherAddress = 3

	addresses.forEach((item, index) => {
		const currenttype = (index === 0 && businessAddress) || (index > 0 && otherAddress)
		result.push({
			...item,
			stateId: item?.stateId?.id,
			cityId: item?.cityId?.id,
			countryId: item?.countryId?.id,
			addressType: currenttype,
		})
	})
	return result ?? []
}
