import React, { useEffect } from 'react'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

// @material-ui/core components
import { Icon } from '@material-ui/core'

// core components
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

import * as S from './styles'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

function CardHeaderIcon({ color, icon, name, arround = false, t }) {
	const [theme, setTheme] = React.useState(getThemeColorFromLocalStorage())

	return (
		<S.Container theme={theme}>
			<CardHeader
				stats
				icon
				className="headerTitle"
				style={{ padding: '0px 10px 0px 25px', margin: '0px' }}
			>
				<GridContainer>
					<GridItem
						xs={12}
						style={{
							display: 'flex',
							alignItems: 'flex-end',
							justifyContent:
								arround === true
									? 'space-between'
									: 'flex-start',
						}}
					>
						<CardIcon
							color={
								theme?.visual?.cards?.variableColors
									? color
									: ''
							}
							className="CardHeader"
						>
							<Icon>
								<i className={icon}></i>
							</Icon>
						</CardIcon>
						<div className="cardTitle">{t(name)}</div>
					</GridItem>
				</GridContainer>
			</CardHeader>
		</S.Container>
	)
}

CardHeaderIcon.propTypes = {
	color: PropTypes.string,
	icon: PropTypes.string,
	name: PropTypes.string,
	subTitle: PropTypes.string,
	arround: PropTypes.bool,
}

CardHeaderIcon.defaultProps = {
	color: '',
	icon: '',
	name: '',
	subTitle: '',
	arround: false,
}

export default withTranslation()(CardHeaderIcon)
