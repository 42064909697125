import React, { createContext, useState } from 'react'
import {
	InsertBalance,
	CreateReservation,
	ResidualReserved,
} from '../AccountManage/Modal/BalanceModal'
import { ModalCredit } from '../AccountManage/Modal/BalanceModal/ModalCredit'
import { ModalDebit } from '../AccountManage/Modal/BalanceModal/ModalDebit'
import { ModalFail } from '../AccountManage/Modal/BalanceModal/ModalFail'
import { ModalReserved } from '../AccountManage/Modal/BalanceModal/ModalReserved'
import { ModalResidual } from '../AccountManage/Modal/BalanceModal/ModalResidual'
import SnackDefault from '../../../components/SnackDefault'
import { useTranslation } from 'react-i18next'

export const AccountsContext = createContext({})

export function AccountsProvider({ children, ...rest }) {
	const [switchComp, setSwitchComp] = useState('list')
	const [isCreteReservationOpen, setIsCreateReservationOpen] = useState(false)
	const [isInsertBalanceOpen, setIsInsertBalanceOpen] = useState(false)
	const [isResidualReservedOpen, setIsResidualReservedOpen] = useState(false)
	const [isFail, setIsFail] = useState(false)
	const [isDebitOpen, setIsDebitOpen] = useState(false)
	const [isCreditOpen, setIsCreditOpen] = useState(false)
	const [isResidualOpen, setIsResidualOpen] = useState(false)
	const [isReservedOpen, setIsReservedOpen] = useState(false)
	const [refreshTable, setRefreshTable] = useState(false)
	const [refreshBalance, setRefreshTBalance] = useState(false)
	const [modalData, setModalData] = useState({})

	const [valueTypeFilter, setValueTypeFilter] = useState({})

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const { t } = useTranslation()

	function openSnack(msg = 'Operação realizada com sucesso!') {
		setSnackStatus((prevState) => ({
			...prevState,
			text: msg,
			open: true,
		}))
	}

	function openErrorSnack(errorMsg = 'Falha ao realizar operação!') {
		setSnackStatus((prevState) => ({
			...prevState,
			open: true,
			severity: 'error',
			text: t(errorMsg),
		}))
	}

	function openReserved(data) {
		setModalData(data)
		setIsReservedOpen(true)
	}
	function closeReserved() {
		// setModalData(data)
		setIsReservedOpen(false)
	}

	function openResidual(data) {
		setModalData(data)
		setIsResidualOpen(true)
	}
	function closeResidual() {
		setIsResidualOpen(false)
	}

	function openCredit(data) {
		setModalData(data)
		setIsCreditOpen(true)
	}

	function closeCredit() {
		setIsCreditOpen(false)
	}
	function openDebit(data) {
		setModalData(data)
		setIsDebitOpen(true)
	}

	function closeDebit() {
		setIsDebitOpen(false)
	}

	function openFailModal() {
		setIsFail(true)
	}
	function closeFailModal() {
		setIsFail(false)
	}

	function openCreateReservation(obj) {
		setModalData(obj)
		setIsCreateReservationOpen(true)
	}

	function closeCreateReservation() {
		setIsCreateReservationOpen(false)
	}

	function openInsertBalance() {
		setIsInsertBalanceOpen(true)
	}

	function closeInsertBalance() {
		setIsInsertBalanceOpen(false)
	}

	function openResidualReserved() {
		setIsResidualReservedOpen(true)
	}

	function closeResidualReserved() {
		setIsResidualReservedOpen(false)
	}

	return (
		<AccountsContext.Provider
			value={{
				switchComp,
				setSwitchComp,
				openCreateReservation,
				closeCreateReservation,
				openInsertBalance,
				closeInsertBalance,
				openResidualReserved,
				closeResidualReserved,
				openFailModal,
				closeFailModal,
				openDebit,
				closeDebit,
				openCredit,
				closeCredit,
				openResidual,
				closeResidual,
				valueTypeFilter,
				setValueTypeFilter,
				openReserved,
				closeReserved,
				openSnack,
				openErrorSnack,
				refreshTable,
				setRefreshTable,
				refreshBalance,
				setRefreshTBalance
			}}
		>
			<ModalReserved isOpen={isReservedOpen} reservedData={modalData} />
			<ModalResidual isOpen={isResidualOpen} residualData={modalData} />
			<InsertBalance isOpen={isInsertBalanceOpen} data={modalData} />
			<CreateReservation
				isOpen={isCreteReservationOpen}
				reservationData={modalData}
			/>
			<ResidualReserved
				isOpen={isResidualReservedOpen}
				data={modalData}
			/>
			<ModalDebit isOpen={isDebitOpen} debitData={modalData} />
			<ModalCredit isOpen={isCreditOpen} creditData={modalData} />
			<ModalFail open={isFail} />

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus((prevState) => ({
						...prevState,
						open: false,
					}))
				}
			></SnackDefault>

			{children}
		</AccountsContext.Provider>
	)
}
