import styled from 'styled-components/macro'

const getTextCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor
	) {
		return props.themeColor.titles.highlight.textColor
	}
	return `#fff`
}
export const Container = styled.div`
	border-bottom: 1px solid #c8c8c8;

	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) => getTextCol(props)};
	}

	
	.MuiTableBody-root tr td {
		padding: 0 !important;
	}
	.MuiTablePagination-caption {
		display: none;
	}

	
`
export const BoxPaused = styled.div`
	align-items: center;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	font-size: 80px;
	justify-content: center;
	min-height: 211px;
	padding: 30px;
	width: 600px;
	i {
		color: #f8bb86;
	}
	p {
		font-size: 22px;
		font-weight: bold;
		margin-top: 33px;
	}
`
export const bnts = styled.div`
	margin: 28px 0 0;
	display: flex;
	justify-content: center;
	gap: 1.875rem;
`
export const bntsNo = styled.button`
	background-color: #21409a;
	border: 1px solid #21409a;
	border-radius: 8px;
	cursor: pointer;
	color: #fff;
	height: 47px;
	outline: none;
	width: 107px;
`

export const bntsYes = styled(bntsNo)`
	background-color: #fff;
	color: #21409a;
	margin-left: 30px;
`

export const IconContainer = styled.i`
	display: flex;
	align-items: center;
`

export const IconInfo = styled.i`
	font-size: 21px;
	cursor: pointer;
	//margin-left: 14px;
	color: #ff9800;
	opacity: 1;
`