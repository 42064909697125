import axios from "axios";

// GET
export const GetTransactionsData = (
  startDate,
  endDate,
  consumerId,
  partnerId,
  transactionCode,
  transactionType,
  page
) => {
  let strStart = startDate ? `&startDate=${startDate}` : "";
  let strEnd = endDate ? `&endDate=${endDate}` : "";
  return axios
    .get(
      `/Transaction?consumerId=${consumerId}&partnerId=${partnerId}&code=${transactionCode}&transactionType=${transactionType}&page=${page}` +
        strStart +
        strEnd
    )
    .then((response) => response.data);
};

// POST
export const PostTransaction = (obj) =>
  axios.post(`/Transaction/`, obj).then((response) => response.data);

// GET BY ID
export const GetTransactionById = (id) =>
  axios.get(`/Transaction/${id}`).then((response) => response.data);

export const GetTransactionDetails = (id) =>
  axios
    .get(`/Transaction/GetTransactionDetails/${id}`)
    .then((response) => response.data);

export const GetTransactionType = () =>
  axios
    .get(`/Transaction/GetTransactionType`)
    .then((response) => response.data);

export const GetTransactionChannelTypes = () =>
  axios.get(`/Invoice/GetChannelType`).then((response) => response.data);
