import React from 'react'
import PropTypes from 'prop-types'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

// Create input mask to handle percentage input
const percentageMask = createNumberMask({
	  prefix: '',
	  suffix: '%',
	  includeThousandsSeparator: false,
	  thousandsSeparatorSymbol: ',',
	  allowDecimal: true,
	  decimalSymbol: ',',
	  decimalLimit: 3,
	  integerLimit: 2,
	  allowNegative: false,
	  allowLeadingZeroes: false
	  })

const PercentageInput = (props) => {
	return (
		<MaskedInput
			{...props}
			mask={percentageMask}
			placeholder={'0,000%'}
			guide={false}
			showMask
		/>
	)
}

PercentageInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	value: PropTypes.number.isRequired,
}

export default PercentageInput

