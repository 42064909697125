import { Grid } from '@material-ui/core'

import styled from 'styled-components/macro'

export const Container = styled.div`
	border-bottom: 1px solid #c8c8c8;
	label {
		color: #717171;
		margin-left: 15px;
	}
	.wivQC .MuiTableBody-root tr td {
		padding: 0 !important;
	}
	.gWuMPF .MuiTableBody-root tr td {
		padding: 0 !important;
		height: 45px;
	}
		.MuiTablePagination-caption {
		display: none;
	}
	.cTDMaR button {
		//padding-top: 0;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after {
		border-color: ${(props) =>
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.backgroundColor &&
		props.themeColor.navigation.buttons.backgroundColor};
		border-width: 2px;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.backgroundColor &&
		props.themeColor.navigation.buttons.backgroundColor};
	}

	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.backgroundColor &&
		props.themeColor.navigation.buttons.backgroundColor};
	}
`
export const GridContain = styled.div``

export const TitleContainer = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 40px;
`

export const FieldContain = styled.form`
	display: flex;
	flex-wrap: wrap;
	margin: 25px 0;
	button {
		border: none;
		background-color: transparent;
		cursor: pointer;
		color: ${(props) =>
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.backgroundColor &&
		props.themeColor.navigation.buttons.backgroundColor};
		font-size: 100%;
		height: 100%;
		position: absolute;
		padding-top: 18px;
		width: 35px;
	}
`
export const ContainFields = styled(Grid)`
	display: flex;
`

export const InputBox = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 18px;
`
export const Inputs = styled.input`
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	color: #717171;
	height: 35px;
	padding: 0 15px;
	overflow: hidden;
	text-overflow: ellipsis;
`
export const ShowValue = styled.div`
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	color: #717171;
	height: 35px;
	padding: 0 15px;
	display: flex;
	align-items: center;
`
