import styled, { css } from 'styled-components'

export const FieldContain = styled.form`
	display: flex;
	margin: 15px 0 20px;
	position: relative;
	justify-content: flex-end;
`

export const CustomContainer = styled.div`
	margin-top: 30px;
	.MuiToolbar-regular {
		min-height: 0px;
	}
`

export const CustomButton = styled.button`
	background-color: ${({ bg }) => bg || 'transparent'};
	color: ${({ color }) => color || '#717171'};
	border: 1px solid ${({ border }) => border || '#b2b2b2'};
	box-shadow: none;
	border-radius: 5px;
	width: ${({ width }) => width || '40px'};
	height: 40px;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	font: normal normal normal 16px Roboto;
	letter-spacing: 0px;
	opacity: ${({ disabled }) => (disabled ? '0.7' : '1')};
	outline: none;

	i {
		font-size: 18px;
	}

	&:hover {
		background-color: ${({ hoverBg }) => hoverBg || 'transparent'};
		color: #ffffff;
		border-color: ${({ hoverBg }) => hoverBg || 'transparent'};
	}

	${({ disabled }) =>
		disabled &&
		css`
			background-color: transparent !important;
			color: #b2b2b2 !important;
			border: 1px solid #e7e7e7 !important;
			box-shadow: none !important;
		`}
`

export const IconSearch = styled.i`
	color: #787474;
	cursor: pointer;
	font-size: 16px;
	opacity: 1;
`

export const IconClear = styled.i`
	color: #b2b2b2;
	cursor: pointer;
	opacity: 1;
	font-size: 12px;
`

export const EmptyTable = styled.div`
	display: flex;
	background-color: #fff;
	flex-direction: column;
	padding: 1em;
	color: #b4b4b4;
	gap: 20px;

	i {
		font-size: 2em;
		cursor: pointer;
		margin: auto 18px;
		color: #b4b4b4;
	}

	.MuiTablePagination-toolbar {
		padding-right: 42px;
	}
`
