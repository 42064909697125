import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'

import { withStyles } from '@material-ui/core/styles'

import GridItem from 'components/Grid/GridItem.jsx'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import FormLabel from '@material-ui/core/FormLabel'
import TableRow from '@material-ui/core/TableRow'
import Section from 'components/Section'
import NoResults from 'components/NoResults/NoResults.jsx'

import GriTable from './GriTable'

//UTILS
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'
import compose from 'utils/compose'

import styles from './permission.module.scss'
import { Divider } from '@material-ui/core'

class ViewPermissions extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			t,
			classes,
			values,
			errors,
			touched,
			editMode,
			handleSubmit,
			handleChange,
			handleBack,
			setFieldValue,
			setFieldTouched,
		} = this.props

		return (
			<Fragment>
				{values && values.groupClaims && values.groupClaims.length > 0 && (
					<Fragment>
						<div>
							<Section title={t('TITLE_ACCESS_PERMISSIONS')} />
						</div>
						{values &&
							values.groupClaims &&
							values.groupClaims.map((claim) => [
								claim.claim.filter(
									(i) => i.removeOrAdd === true,
								).length > 0 && (
									<>
										<FormLabel
											className={styles.LabelHorizontal}
										>
											{t(
												'CLAIM_CARD_' +
													_.toUpper(
														claim.name.replace(
															/\./g,
															'_',
														),
													),
											)}
										</FormLabel>
										<div className={styles.detailsContent}>
											{claim &&
												claim.claim &&
												claim.claim.map((values) => [
													values.removeOrAdd ===
														true && (
														<>
															<span
																style={{
																	paddingRight:
																		'110px',
																	color: '#636363',
																}}
															>
																{t(
																	'ROLE_CLAIMS_' +
																		_.toUpper(
																			values.value,
																		),
																)}
															</span>
															<br />
														</>
													),
												])}
										</div>
										<Divider />
									</>
								),
							])}
					</Fragment>
				)}
				{values.userGroup.length > 0 && (
					<Fragment>
						<div style={{ marginTop: '30px' }}>
							<Section title={t('TAB_ACCESS_ASSOCIATED_USERS')} />
						</div>
						{this.props.userList &&
						values &&
						values.userGroup &&
						values.userGroup.length > 0 ? (
							<Fragment>
								{values &&
									values.userGroup &&
									values.userGroup.map((item, index) => {
										let user = _.find(
											this.props.userList,
											(x) => x.id === item,
										)
										if (!user) {
											return false
										}
										return (
											<GriTable
												key={index}
												labels={[
													t('LOGIN_LOGIN'),
													t('FIELD_REGISTER_NAME'),
												]}
												values={[
													user.userName
														? user.userName
														: user.username,
													user.name,
												]}
												noButton
											/>
										)
									})}
							</Fragment>
						) : (
							<div style={{ margin: 'auto', width: '30%' }}>
								<NoResults value={t('RESULTS_NOT_FOUND')} />
							</div>
						)}
					</Fragment>
				)}
			</Fragment>
		)
	}
}

export default compose(
	withStyles(sweetAlertStyle),
	withTranslation(),
)(ViewPermissions)
