import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

export const Text = styled.div`
	text-align: left;
	font: normal normal normal 13px Roboto;
	letter-spacing: 0px;
	color: #636363;
	opacity: 1;
`
