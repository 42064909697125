import React, { useState, useEffect } from 'react'

import { Grid, withStyles } from '@material-ui/core'

import PdvRegister from './PdvRegister'
import PdvList from './PdvList'

import { BoxNotification } from './styles'
import PdvContext from './context'

import { useDispatch, useSelector } from 'react-redux'
import SnackDefault from 'components/SnackDefault'
import { useHistory } from 'react-router-dom'

function Pdv() {
	const [switchComp, setSwitchComp] = useState('list')
	const [Pdv, setPdv] = useState()
	const [editing, setEditing] = useState(false)
	const history = useHistory()
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: '',
		text: '',
	})

	const partnerId = useSelector((state) => state.users.userData.id)

	const comp = {
		list: <PdvList partnerId={partnerId}></PdvList>,
		form: <PdvRegister partnerId={partnerId}></PdvRegister>,
	}

	useEffect(() => {
		if (history.action === 'PUSH') {
			if (history.location.state) {
				setSnackStatus({
					open: history.location.state.snackStatus,
					severity: history.location.state.snackSeverity,
					text: history.location.state.snackText,
				})
			}
		}
	}, [history.location.state])

	return (
		<PdvContext.Provider
			value={{
				setSwitchComp,
				Pdv,
				setPdv,
				editing,
				setEditing,
				partnerId,
			}}
		>
			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus((prevState) => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<Grid container style={{ display: 'contents' }}>
				{comp[switchComp]}
			</Grid>
		</PdvContext.Provider>
	)
}

export default withStyles()(withStyles()(Pdv))
