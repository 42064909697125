import _ from 'lodash'
import { numberFormatText } from 'utils/utils'
import { CalculateValue } from 'redux/api/product'
import { UpdateProduct, GetProductByDivision, GetTypeORedeem, ValidateByDivisions } from '../../../../../../../../../../../../redux/api/product'
import Cookies from 'js-cookie'
import {envs}  from '../../../../../../../../../../../../redux/api/windowServerData'



export const GetORedeem = async ({ setGenerationType, setCircularProgress }) => {
	setCircularProgress(true)
	try {
		const res = await GetTypeORedeem()

		if (res?.status === 200 && res?.data?.length > 0) {
			const newRes =
				res?.data
					?.map(item => {
						if (item?.value === 'No_') {
							return null
						}
						if (item?.value === 'No') {
							return {
								...item,
								value: 'Não resgatavel',
							}
						}
						return item
					})
					?.filter(item => item !== null) || []

			setGenerationType(newRes)
		}
	} catch (error) {
		console.log(error)
	} finally {
		setCircularProgress(false)
	}
}

export const ProductByDivision = async (division, Cashback, productId, setDivisionProductData, setSnackStatus, setLoading) => {
	setLoading(true)
	try {
		const res = await GetProductByDivision(division, Cashback, productId)

		if (res?.success && !_.isEmpty(res?.data)) {
			setDivisionProductData(res.data)
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: 'Ocorreu um erro ao buscar os dados do produto por divisão',
				severity: 'error',
			})
		}

		return res?.data
	} catch (error) {
		setDivisionProductData([])
		setSnackStatus({
			open: true,
			text: 'Ocorreu um erro ao buscar os dados do produto por divisão',
			severity: 'error',
		})
	} finally {
		setLoading(false)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const FormatMoney = value => {
	// const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', symbol)
}

export const handleProductDataUpdate = (productData, reset) => {
	if (productData) {
		const { typeOfRedeem, redemptionPoints } = productData

		reset({
			typeOfRedeem: typeOfRedeem,
			redemptionPoints: redemptionPoints,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '3':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async ({ productId, price, typeOfRedeem, redemptionPoints }) => {
	try {
		const res = await CalculateValue(productId, price, '0', '0', typeOfRedeem, redemptionPoints)
		if (res?.success && res?.data) {
			const { redemptionPoints } = res.data
			return redemptionPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationProduct = async (productData, typeOfPoints, pointsValue, setSnackStatus, typeOfBenefitPoints) => {
	const obj = {
		id: productData?.id,
		price: 0,
		name: productData?.name,
		generatePoints: false,
		typeOfBenefitPoints: typeOfBenefitPoints.key,
		typeOfPoints: '0',
		pointsValue: typeOfPoints?.key !== '0' && typeOfPoints?.key !== '1' ? pointsValue : '0',
		typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key,
		typeOfRedeem: '0',
		redemptionPoints: productData?.redemptionPoints?.key,
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: '0',
		cashbackPercentual: productData?.cashbackPercentual,
	}

	try {
		const res = await UpdateProduct(obj?.id, obj)

		if (res?.success) {
			console.log('res', res)
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				type: 'success',
			})
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				message: res?.data?.errors[0]?.message,
				type: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}

export const ValidateStatusProductByDivisions = async ({ productId, partnerId, divisions, typeOfBenefit, page, pageSize }) => {
	try {
		const body = {
			partnerId: partnerId || '',
			productId: productId || '',
			divisions: divisions || [],
			typeOfBenefit: typeOfBenefit,
			pageSize: pageSize || 10,
			page: page || 1,
		}
		const res = await ValidateByDivisions(body)
		return res
	} catch (error) {
		return error
	}
}

export const HasParameterization = async ({ productData, selectedDivisionIdRed, setHasParameterization, setLoading, setSnackStatus }) => {
	setLoading(true)

	try {
		const PartnerId = String(Cookies.get('partnerId'))
		const res = await ValidateStatusProductByDivisions({
			productId: productData?.id,
			partnerId: PartnerId,
			divisions: selectedDivisionIdRed,
			typeOfBenefit: 'Redeem',
		})
		if (res.status == 200) {
			setHasParameterization(res?.data)
		}
	} catch (error) {
		setSnackStatus({
			oopen: false,
			severity: 'error',
			text: 'Ocorreu um erro ao validar a parametrização',
		})
	} finally {
		setLoading(false)
	}
}
