import React from 'react'
// import * as _ from 'lodash'
import PropTypes from 'prop-types'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import * as S from './styles'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { useTranslation } from 'react-i18next'

function ConfirmModalPartners({ open, onCancel, text, subText, hasActive, onConfirm }) {
	const { t } = useTranslation()
	return (
		<S.ModalContainer open={open} aria-labelledby="alert-dialog-slide-title" aria-describedby="alert-dialog-slide-description" disableEnforceFocus>
			<S.ModalFade in={open} unmountOnExit>
				<S.PaperContainer>
					<GridContainer xs={12} sm={12}>
						<GridItem
							xs={12}
							sm={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<i
								className="icon-alerta_modal"
								style={{
									color: germiniDefaultColors[11],
									fontSize: '80px',
								}}
							/>
						</GridItem>
					</GridContainer>
					<GridItem xs={12} sm={12}>
						<S.TextContainer>
							<S.Text>{text}</S.Text>
							<S.Text>{subText}</S.Text>
						</S.TextContainer>
					</GridItem>

					<GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
						<S.GroupButtons>
							<S.CustomButton size="sm" onClick={onCancel} bc="#B2B2B2" color="#F05B4F" bg="#ffffff" hoverBg="#F05B4F">
								{hasActive ? t('BUTTON_CLOSE') : t('BUTTON_NO')}
							</S.CustomButton>{' '}
							{!hasActive && (
								<S.CustomButton size="sm" variant="contained" color="greenButtonColor" bg="#4caf50" hoverBg="#00802C" onClick={onConfirm}>
									{t('BUTTON_YES')}
								</S.CustomButton>
							)}
						</S.GroupButtons>
					</GridItem>
				</S.PaperContainer>
			</S.ModalFade>
		</S.ModalContainer>
	)
}

ConfirmModalPartners.propTypes = {
	open: PropTypes.bool,
	onCancel: PropTypes.func,
	text: PropTypes.string,
	subText: PropTypes.string,
	hasActive: PropTypes.bool,
	onConfirm: PropTypes.func,
}

export default ConfirmModalPartners
