import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { Box } from '@mui/material'
import { getDefaultColor } from './Functions'

export const Icon = styled.i``

export const CustomIconButton = styled(IconButton)`
	background: #f2f2f2 0% 0% no-repeat padding-box !important;

	border-radius: 4px !important;
	padding: 5px;
	height: 40px;
	width: 40px;
	opacity: 1;

	i {
		color: ${({ color }) => color};
	}

	&:hover {
		background-color: ${({ bgColor }) => bgColor} !important;
		color: #fff;

		i {
			color: #fff;
		}
	}
`

export const CustomTabItem = styled(Box)`
	background-color: ${({ selectedItem, index }) => (selectedItem === index ? getDefaultColor() : '#EEEEEE')};
	color: ${({ selectedItem, index }) => (selectedItem === index ? '#fff' : '#636363')};
	border-radius: 5px 5px 0px 0px;
	padding: 10px 25px;
	font-weight: 600;
	text-transform: uppercase;

	&:hover {
		cursor: pointer;
	}
`
