import styled from 'styled-components'
import { color } from '@mui/system'

export const Container = styled.div`
    .indicator{
        height: 47px;
        border-radius: 5px 0px 0px 5px;
    }
    .MuiTableBody-root tr td {
        font-size: 14px !important;
        padding-bottom: 0px;
        padding-top: 0px;
    }

	td:nth-child(2), th:nth-child(2){
		text-align: left;
	}
    .MuiToolbar-regular{
        min-height: 25px;
    }
    .makeStyles-tableTitle-218 span, .makeStyles-tableTitle-238 span{
        color: #585858 ;
    }
`
export const Titles = styled.div`
	margin-bottom: 1.563rem;
`

export const Status = styled.div`
    height: 100%;
    background-color: #333333;
    width: 3px;
`

export const IconsDiv = styled.div`
    font-size: 18px;
    padding-left: 15px;
`

export const SubTitle = styled.div`
    transform: translatey(-30px);
    padding-left: 15px;
    display: flex;
`

export const Indicator = styled.div`
    border: ${({ Color }) => `3px solid ${Color}`};
`