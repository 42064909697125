import React, { useEffect } from 'react'
import { Box, InputAdornment } from '@mui/material'
import PropTypes from 'prop-types'

import { Container, CssTextField, LabelCheckbox } from './styles'
import Checkbox from '../../../../../Checkbox'

function Search({ GetDivisionTable, name, setName, multiple, selectAllItemsByName, editMode }) {
	const [ckecked, setCkecked] = React.useState(false)

	useEffect(() => {
		if (multiple === false) {
			setCkecked(false)
		}
	}, [multiple])

	return (
		<Box display="flex" justifyContent="space-between">
			<CssTextField
				label="Buscar"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<i className="icon-lupa" />
						</InputAdornment>
					),
					endAdornment: (
						<InputAdornment position="end">
							<i
								className="icon-menu_fechar"
								onClick={() => {
									setName('')
									GetDivisionTable()
								}}
								style={{
									cursor: 'pointer',
									fontSize: '12px',
									opacity: '0.5',
								}}
							/>
						</InputAdornment>
					),
				}}
				value={name}
				onChange={e => {
					setName(e.target.value)
					GetDivisionTable(e.target.value)
				}}
				variant="standard"
				sx={{ maxWidth: '300px' }}
			/>

			<Container>
				<LabelCheckbox>Selecionar todas</LabelCheckbox>
				<Checkbox
					disabled={!multiple && !editMode}
					checked={ckecked}
					onChange={() => {
						setCkecked(!ckecked)
						selectAllItemsByName()
					}}
				/>
			</Container>
		</Box>
	)
}

Search.propTypes = {
	name: PropTypes.string,
	setName: PropTypes.func,
	multiple: PropTypes.bool,
	editMode: PropTypes.bool,
	GetDivisionTable: PropTypes.func,
	selectAllItemsByName: PropTypes.func,
}

export default Search
