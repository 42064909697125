/* eslint-disable */
import React, { Fragment } from 'react'

import * as _ from 'lodash'
import * as S from './styles'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'

import Chart from 'react-apexcharts'
import cx from 'classnames'
// react plugin for skeleton
import Skeleton from 'react-loading-skeleton'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { AddCircleOutline, Search } from '@material-ui/icons'
import { Grid, Icon, Tooltip } from '@material-ui/core'

import NoResults from 'components/NoResults/NoResults.jsx'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'

import SimpleSlider from './comp/Slider'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'

// Utils
import compose from 'utils/compose'
import { MonthsTranslate } from 'utils/charts'

import dashboardStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

// imgs
// import bannerFixo from 'assets/img/sem_imagem_parceiro_fixo.png'
// import bannerRotatorio from 'assets/img/sem_imagem_parceiro_rotatorio.png'

import styles from './home.module.scss'
// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { GetDashboardData, ShowLoader, HideLoader } from 'redux/actions'

import Axios from 'axios'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

import defaultGerminiPartnerBg from 'assets/img/GERMINI_Banners_Intitucional/GERMINI_banner_institucional_parceiro_maior.jpg'
import defaultGerminiPartner from 'assets/img/GERMINI_Banners_Intitucional/GERMINI_banner_institucional_parceiro.jpg'

import defaultCvalePartnerBg from 'assets/img/CVALE_Banners_Intitucional/GERMINI_banner_institucional_parceiro_maior.jpg'
import defaultCvalePartner from 'assets/img/CVALE_Banners_Intitucional/GERMINI_banner_institucional_parceiro.jpg'
import { envs } from '../../redux/api/windowServerData'
import { getProgramTitle } from '../../utils/utils'

const CssTooltip = withStyles({
	tooltip: {
		backgroundColor: '#171717',
		borderRadius: '6px',
		fontSize: '0.72rem',
		padding: '2px 8px',
		fontWeight: 'initial',
		'& span': {
			color: '#171717',
		},
	},
})(Tooltip)

class Home extends React.Component {
	state = {
		value: 0,
		invoiceDashBoardViewModel: [],
		productsDashBoardViewModel: [],
		campaignDashBoardViewModel: [],
		cardsLoader: true,
		chatsLoader: true,
		announcementsLoader: true,
		cardSelesIndicators: null,
		cardConsumersIndicators: null,
		cardMovimentationsIndicators: null,
		cardConsumersProfileIndicators: null,
		dashInvoicesDetailsData: {},
		loading: true,
		showNothingCardInvoice: false,
		showNothingCardProduct: false,
		showNothingCardCampaign: false,
		loadingCard: true,
		bannersDisplayTime: 5,

		themeColor: getThemeColorFromLocalStorage(),
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		if (!_.isEmpty(this.props.userData)) {
			this.props.GetDashboardData()
		}

		Axios.get(`/Dashboard/GetBannersByPartner`).then(response =>
			this.setState({
				bannerInfo: response.data,
			}),
		).finally(() => this.setState({ loadingCard: false }))
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState.bannerInfo !== this.state.bannerInfo) {
			this.state.bannerInfo.map(item => {
				if (item.bannerType.value === 'CAROUSEL') {
					this.setState({ bannersDisplayTime: item.displayTime })
				}
			})
		}

		if (prevProps.generalRulesData !== this.props.generalRulesData) {
			this.props.HideLoader()
			this.setState({ loading: false })
		}
		if (prevProps.userData !== this.props.userData && this.state.tiersOrdered) {
			this.setState({ loading: false })
			this.changeCategoryImg(this.props.userData.points, this.state.tiersOrdered)
		}
		if (prevProps.tierData !== this.props.tierData) {
			let tiers = _.orderBy(this.props.tierData, ['scoreStart'], ['cresc'])
			this.setState({ loading: false, tiersOrdered: tiers })
		}
		if (prevProps.walletExpirationPointsData !== this.props.walletExpirationPointsData) {
			this.setState({
				loading: false,
				expirationPoints: this.props.walletExpirationPointsData,
			})
		}

		if (prevProps.lsInvoiceData !== this.props.lsInvoiceData) {
			this.setState({
				invoicesResults: this.props.lsInvoiceData.results,
			})
		}

		if (prevProps.lsProducts !== this.props.lsProducts) {
			this.setState({
				loading: false,
				productCount: this.props.lsProducts.recordCount,
			})
		}

		if (prevProps.cardsData !== this.props.cardsData) {
			this.setState(
				{
					loading: false,
					...this.props.cardsData,
				},
				() => this.handleChartData(this.props.cardsData.invoiceDashBoardViewModel),
			)
		}

		if (prevProps.lsCampaignData !== this.props.lsCampaignData) {
			this.setState({
				loading: false,
				campaignResults: _.take(this.props.lsCampaignData.results, 5),
			})
		}

		if (prevProps.lsBrands !== this.props.lsBrands) {
			this.setState({ brandCount: this.props.lsBrands.recordCount })
		}

		if (prevProps.lsManufacturers !== this.props.lsManufacturers) {
			this.setState({
				manufacturerCount: this.props.lsManufacturers.recordCount,
			})
		}

		if (prevProps.lsInvoicesDetailPartner !== this.props.lsInvoicesDetailPartner) {
			this.handleChartData(this.props.lsInvoicesDetailPartner)
		}

		if (prevProps.invoicesDetailPartnerFailed !== this.props.invoicesDetailPartnerFailed) {
		}

		if (prevProps.userData !== this.props.userData) {
			this.componentDidMount()
		}

		if (prevProps.lsCategories !== this.props.lsCategories) {
			this.setState({
				categoryCount: this.props.lsCategories.recordCount,
			})
		}

		if (prevProps.categoriesFailed !== this.props.categoriesFailed) {
		}

		// ERRORs
		if (prevProps.manufacturerFailed !== this.props.manufacturerFailed) {
		}
		if (prevProps.brandsFailed !== this.props.brandsFailed) {
		}
		if (prevProps.tierDataFailed !== this.props.tierDataFailed) {
		}
		if (prevProps.walletExpirationPointsFailed !== this.props.walletExpirationPointsFailed) {
		}
		if (prevProps.productFailed !== this.props.productFailed) {
		}
		if (prevProps.campaignFailed !== this.props.campaignFailed) {
		}
		if (prevProps.generalRulesDataFailed !== this.props.generalRulesDataFailed) {
		}
		if (prevProps.invoiceDataFailed !== this.props.invoiceDataFailed) {
		}
		if (prevState.campaignDashBoardViewModel !== this.state.campaignDashBoardViewModel) {
			this.handleShowNothingCampaing(this.state.campaignDashBoardViewModel)
		}
		if (prevState.productsDashBoardViewModel !== this.state.productsDashBoardViewModel) {
			this.handleShowNothingProduct(this.state.productsDashBoardViewModel.productCount)
		}
	}

	getColor = () => {
		return (
			this.state.themeColor &&
			this.state.themeColor.titles &&
			this.state.themeColor.titles.secondary &&
			this.state.themeColor.titles.secondary.textColor &&
			this.state.themeColor.titles.secondary.textColor
		)
	}

	iconColor = () => {
		return (
			this.state.themeColor &&
			this.state.themeColor.visual &&
			this.state.themeColor.visual.icons &&
			this.state.themeColor.visual.icons.highlightColor &&
			this.state.themeColor.visual.icons.highlightColor
		)
	}

	handleLink = item => {
		const link = item[1]
		const id = item[2]
		const click = true

		if (link) {
			Axios.put(`/Banner/MetricsBannersCount/${id}?click=${click}`)
			var url = link
			if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
				window.open(link, '_blank')
			} else {
				window.open(`https://${link}`, '_blank')
			}
		}
	}

	handleShowNothingProduct = data => {
		if (data === 0) {
			this.setState({
				showNothingCardProduct: true,
			})
		}
	}

	handleShowNothingCampaing = data => {
		if (data.length === 0) {
			this.setState({
				showNothingCardCampaign: true,
			})
		}
	}

	//aqui é montado o objeto que vai conter os dados para o grafico
	handleChartData = data => {
		const { t } = this.props
		let chart1 = data
		const options = {
			chart: {
				zoom: {
					enabled: false,
				},
				toolbar: {
					show: false,
				},
			},
			colors: ['#e58b00', '#8D918B'],
			dataLabels: {
				enabled: false,
			},
			stroke: {
				width: [4, 4],
				curve: 'straight',
				dashArray: [0, 4],
			},
			markers: {
				size: 5,
				hover: {
					sizeOffset: 5,
				},
			},
			yaxis: {
				labels: {
					offsetX: -12,
					formatter: function (value, opts) {
						return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
					},
				},
			},
			xaxis: {
				categories: _.flatten(chart1.data)[0].labels,
				labels: {
					formatter: function (value, opts) {
						return MonthsTranslate(value)
					},
				},
			},

			tooltip: {
				enabled: true,
				y: {
					formatter: function (value, opts) {
						value = value.toString().replace('.', ';')
						value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
						value = value.toString().replace(';', ',')
						return localStorage.getItem('currencySymbol') + ` ${value}`
					},
				},
			},
		}

		if (data.data.length === 0 || data.data[0].labels === 0 || data.data[0].values === 0) {
			this.setState({ showNothingCardInvoice: true })
		}

		let _seriesD1 = []
		_.forEach(_.flatten(chart1.data), item => {
			_seriesD1.push({
				name: t(item.name),
				type: 'line',
				data: item.values,
			})
		})

		this.setState({
			dashInvoicesDetailsData: { options, series: _seriesD1 },
		})
	}

	handleChange = (event, value) => {
		this.setState({ value })
	}

	handleChangeIndex = index => {
		this.setState({ value: index })
	}

	handleViewCampaign(id) {
		var campaigns = this.state.campaignDashBoardViewModel
		var type
		campaigns.forEach(item => {
			if (item.id === id) {
				type = item.campaignMode
			}
		})

		if (type) {
			switch (type) {
				case 1:
					return this.props.history.push(`/campaigns/view/${id}`)
				case 2:
					return this.props.history.push(`/campaign/viewRedeemCampaign/${id}`)
				default:
					return
			}
		}
	}

	CampaignStatus = props => {
		const { status } = props
		switch (status.toString()) {
			case 'Reproved':
				return (
					<CssTooltip id={`tooltip-linked`} title={this.props.t(status.toString())} placement="top-start" arrow>
						<Icon
							className="icon-grid_reprovado"
							style={{
								fontSize: '20px',
								color: '#E81F26',
							}}
						/>
					</CssTooltip>
				)
			case 'Finished':
				return (
					<CssTooltip id={`tooltip-linked`} title={this.props.t(status.toString())} placement="top-start" arrow>
						<Icon
							className="icon-grid_finalizado"
							style={{
								fontSize: '20px',
								color: '#CAA22C',
							}}
						/>
					</CssTooltip>
				)
			case 'Expired':
				return (
					<CssTooltip id={`tooltip-linked`} title={this.props.t(status.toString())} placement="top-start" arrow>
						<Icon
							className="icon-grid_expirado"
							style={{
								fontSize: '20px',
								color: '#8F48D6 ',
							}}
						/>
					</CssTooltip>
				)
			case 'Pending':
				return (
					<CssTooltip id={`tooltip-linked`} title={this.props.t(status.toString())} placement="top-start" arrow>
						<Icon
							className="icon-ico_grid_pendente"
							style={{
								fontSize: '20px',
								color: '#F9931F',
							}}
						/>
					</CssTooltip>
				)
			case 'Active':
				return (
					<CssTooltip id={`tooltip-linked`} title={this.props.t(status.toString())} placement="top-start" arrow>
						<Icon
							className="icon-grid_ativo"
							style={{
								fontSize: '20px',
								color: '#8BC53F',
							}}
						/>
					</CssTooltip>
				)
			case 'Inactive':
				return (
					<CssTooltip id={`tooltip-linked`} title={this.props.t(status.toString())} placement="top-start" arrow>
						<Icon
							className="icon-grid_inativo"
							style={{
								fontSize: '20px',
								color: '#38b9e9',
							}}
						/>
					</CssTooltip>
				)
			default:
				return <div></div>
		}
	}

	render() {
		const { t, classes } = this.props
		const { bannerInfo, bannersDisplayTime, dashInvoicesDetailsData, campaignDashBoardViewModel, loadingCard } = this.state

		let bannerPositionOneInfo = []
		if (!_.isEmpty(bannerInfo)) {
			bannerPositionOneInfo = bannerInfo.map(item => {
				if (item.positionOnScreen.value === 'PARTNER_FIXED_BANNER_POSITION_1') {
					return [item.imageUrl, item.link, item.id]
				}
				return null
			})
		}

		let bannerPositionTwoInfo = []
		if (!_.isEmpty(bannerInfo)) {
			bannerPositionTwoInfo = bannerInfo.map(item => {
				if (item.positionOnScreen.value === 'PARTNER_FIXED_BANNER_POSITION_2') {
					return [item.imageUrl, item.link, item.id]
				}
				return null
			})

		}

		let bannerPositionThreeInfo = []
		if (!_.isEmpty(bannerInfo)) {
			bannerPositionThreeInfo = bannerInfo.map(item => {
				if (item.positionOnScreen.value === 'PARTNER_CAROUSEL_BANNER_POSITION_3') {
					return [item.imageUrl, item.link, item.id]
				}
				return null
			})
		}

		var boxSkeleton = (
			<div>
				<Skeleton height={200} count={1} />
			</div>
		)



		return (
			<S.Container>
				<DocumentTitle title={getProgramTitle()} />
				<GridContainer>
					{/* banner 1 */}
					<GridItem xs={12} sm={6} md={6} lg={6}>
						{!_.isEmpty(_.compact(bannerPositionOneInfo)) ? (
							<>
								{bannerPositionOneInfo.map((item, index) => {
									if (item !== null) {
										return (
											<div key={index} onClick={() => this.handleLink(item)}>
												<img
													src={item[0]}
													alt="Banner 1"
													width="98%"
													style={{
														marginBottom: '30px',
														borderRadius: '10px',
														height: '150px',
														objectFit: 'cover',
														cursor: 'pointer',
													}}
												/>
											</div>
										)
									}
									return null
								})}
							</>
						) : loadingCard ? <Skeleton height={150} width="100%" /> :
							(
								<img
									alt="Banner 1"
									src={envs.REACT_APP_CVALE_IDENTITY === 'true' ? defaultCvalePartner : defaultGerminiPartner}
									style={{
										marginBottom: '30px',
										borderRadius: '10px',
										height: '150px',
										objectFit: 'cover',
									}}
									width="98%"
								/>
							)}
					</GridItem>
					{/* banner 2 */}
					<Grid xs={12} sm={6} md={6} lg={6} id="grid">
						{!_.isEmpty(_.compact(bannerPositionTwoInfo)) ? (
							<>
								{bannerPositionTwoInfo.map((item, index) => {
									if (item !== null) {
										return (
											<div key={index} onClick={() => this.handleLink(item)}>
												<img
													src={item[0]}
													alt="Banner 2"
													width="98%"
													style={{
														marginBottom: '30px',
														borderRadius: '10px',
														height: '150px',
														objectFit: 'cover',
														cursor: 'pointer',
													}}
												/>
											</div>
										)
									}
									return null
								})}
							</>
						) : loadingCard ? <Skeleton height={150} width="100%" /> :
							(
								<img
									alt="Banner 2"
									src={envs.REACT_APP_CVALE_IDENTITY === 'true' ? defaultCvalePartner : defaultGerminiPartner}
									style={{
										marginBottom: '30px',
										borderRadius: '10px',
										height: '150px',
										objectFit: 'cover',
									}}
									width="98%"
								/>
							)}
					</Grid>
				</GridContainer>

				<GridContainer>
					<GridContainer>
						<GridItem xs={12} sm={6} md={6}>
							{!_.isEmpty(_.compact(bannerPositionThreeInfo)) ? (
								<SimpleSlider bannerInfo={_.compact(bannerPositionThreeInfo)} bannersDisplayTime={bannersDisplayTime} />
							) : loadingCard ?
								<S.SkeletonContainer>
									{boxSkeleton}
								</S.SkeletonContainer>
								: (
									<img
										alt="Banner 3"
										src={envs.REACT_APP_CVALE_IDENTITY === 'true' ? defaultCvalePartnerBg : defaultGerminiPartnerBg}
										className={styles.noImage}
									/>
								)}
						</GridItem>
						{/* LINE 1.2 */}
						<GridItem xs={12} sm={6} md={6}>
							<Section
								title={t('MENU_SALES')}
								style={{
									maxWidth: '453px',
									marginLeft: '0.1rem',
								}}
							/>
							{this.state.loading ? (
								boxSkeleton
							) : dashInvoicesDetailsData.series && dashInvoicesDetailsData.series[0].data.length ? (
								<Chart options={dashInvoicesDetailsData.options} series={dashInvoicesDetailsData.series} type="line" width={'100%'} height={220} />
							) : !this.state.showNothingCardInvoice ? (
								boxSkeleton
							) : (
								<NoResults value="NO_REGISTERED_SALES" />
							)}
						</GridItem>
					</GridContainer>
					<GridItem
						xs={12}
						sm={6}
						md={6}
						style={{
							marginBottom: '30px',
							marginTop: '20px',
						}}
					>
						<Section title={t('MENU_PRODUCT')} />
						{this.state.loading ? (
							boxSkeleton
						) : this.state.productsDashBoardViewModel.productCount !== 0 && !_.isArray(this.state.productCount) ? (
							<Fragment>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
									}}
								>
									<GridItem md={3} sm={3} xs={12} className={styles.seesBox}>
										<div className={styles.iconStyle}>
											<Icon
												className="icon-produtos"
												style={{
													fontSize: '40px',
													color: this.iconColor(),
													width: '40px',
													height: '40px',
												}}
											/>
										</div>
										<div
											className={styles.seesBoxTitle}
											style={{
												color: this.getColor(),
											}}
										>
											{t('MENU_PRODUCT')}
										</div>
										<div
											className={styles.seedsBoxValue}
											style={{
												color: this.state?.themeColor?.titles?.highlight?.textColor,
											}}
										>
											<span>{this.state.productsDashBoardViewModel.productCount}</span>
										</div>
									</GridItem>

									<GridItem md={3} sm={3} xs={12} className={styles.seesBox}>
										<div className={styles.iconStyle}>
											<Icon
												className="icon-categorias"
												style={{
													fontSize: '40px',
													color: this.iconColor(),
													width: '40px',
													height: '40px',
												}}
											/>
										</div>
										<div className={styles.seesBoxTitle} style={{ color: this.getColor() }}>
											{t('MENU_CATEGORY')}
										</div>
										<div
											className={styles.seedsBoxValue}
											style={{
												color: this.state.themeColor?.titles?.highlight?.textColor,
											}}
										>
											<span>{this.state.productsDashBoardViewModel.categoryCount}</span>
										</div>
									</GridItem>

									<GridItem md={3} sm={3} xs={12} className={styles.seesBox}>
										<div className={styles.iconStyle}>
											<Icon
												className="icon-fabricantes"
												style={{
													fontSize: '40px',
													color: this.iconColor(),
													width: '40px',
													height: '40px',
												}}
											/>
										</div>
										<div className={styles.seesBoxTitle} style={{ color: this.getColor() }}>
											{t('MANUFACTURER')}
										</div>
										<div
											className={styles.seedsBoxValue}
											style={{
												color: this.state.themeColor?.titles?.highlight?.textColor,
											}}
										>
											<span>{this.state.productsDashBoardViewModel.manufacturerCount}</span>
										</div>
									</GridItem>

									<GridItem md={3} sm={3} xs={12} className={styles.seesBox}>
										<div className={styles.iconStyle}>
											<Icon
												className="icon-marcas"
												style={{
													fontSize: '40px',
													color: this.iconColor(),
													width: '40px',
													height: '40px',
												}}
											/>
										</div>
										<div className={styles.seesBoxTitle} style={{ color: this.getColor() }}>
											{t('Brand')}
										</div>
										<div
											className={styles.seedsBoxValue}
											style={{
												color: this.state.themeColor?.titles?.highlight?.textColor,
											}}
										>
											<span>{this.state.productsDashBoardViewModel.brandCount}</span>
										</div>
									</GridItem>
								</div>
								<div className={styles.seedsBoxButton} style={{ marginTop: '20px' }}>
									<Button
										variant="contained"
										color="greenButtonColor"
										className={styles.submit}
										size="sm"
										onClick={() => this.props.history.push(`/product`)}
										style={{
											color: this.state.themeColor?.navigation?.buttons?.textColor,
											backgroundColor: this.state.themeColor?.navigation?.buttons?.backgroundColor,
										}}
									>
										<Icon className={cx(classes.icons, 'icon-informacoes')} />
										{t('BUTTON_MORE_INFORMATION')}
									</Button>
								</div>
							</Fragment>
						) : !this.state.showNothingCardProduct ? (
							boxSkeleton
						) : (
							<NoResults value="NO_PRODUCTS_REGISTERED" />
						)}
					</GridItem>

					{/* LINE 2 */}
					<Grid xs={12} sm={6} md={6} id="gridFor">
						<Section title={t('MENU_CAMPAIGN')} style={{ marginBottom: '10px', maxWidth: '445px' }} />
						{this.state.loading ? (
							boxSkeleton
						) : campaignDashBoardViewModel.length !== 0 ? (
							<MaterialTable
								style={{ maxWidth: '445px' }}
								options={{
									sorting: false,
									search: false,
									paging: false,
								}}
								title={false}
								columns={[
									{
										title: '',
										field: 'campaignMode',
										cellStyle: {
											paddingTop: 0,
											paddingBottom: 0,
											textAlign: 'center',
											maxWidth: '25px',
											fontWeight: 'bold',
										},
										headerStyle: {
											fontSize: '15px',
										},
									},

									{
										title: 'Descrição / Patrocinador',
										field: 'description',
										headerStyle: { textAlign: 'left', fontSize: '15px' },
										cellStyle: {
											paddingTop: 0,
											paddingBottom: 0,
											minWidth: '250px',
											font: 'Roboto Regular',
											color: '#717171',
										},
									},
									{
										title: 'Status',
										field: 'status',
										cellStyle: {
											paddingTop: 0,
											paddingBottom: 0,
											textAlign: 'center',
											maxWidth: '70px',
										},
										headerStyle: {
											textAlign: 'center',
											maxWidth: '70px',
										},
									},

									{
										title: '',
										field: 'actions',
										headerStyle: { maxWidth: '60px' },
										cellStyle: {
											padding: '0px !important',
											textAlign: 'right',
											maxWidth: '70px',
										},
										render: rowData => (
											<Fragment>
												<Button
													justIcon
													style={{
														borderTopLeftRadius: 0,
														borderBottomLeftRadius: 0,
														borderTopRightRadius: '10%',
														borderBottomRightRadius: '10%',
														margin: 0,
														marginRight: '-15px',
														height: '50px',
														color: this.state.themeColor?.navigation?.buttons?.textColor,
														backgroundColor: this.state.themeColor?.navigation?.buttons?.backgroundColor,
														borderColor: this.state.themeColor?.navigation?.buttons?.backgroundColor,
													}}
													color="greenButtonColor"
													size="sm"
													title={t('BUTTON_VIEW')}
													onClick={() => this.handleViewCampaign(rowData.id)}
												>
													<Search />
												</Button>
											</Fragment>
										),
									},
								]}
								data={campaignDashBoardViewModel.map((item, index) => {
									return {
										id: item.id,
										campaignMode: (
											<CssTooltip
												id={`tooltip-linked`}
												title={this.props.t(item.campaignMode === 1 ? 'FIELD_GENERATION_POINTS' : 'FIELD_RESCUE_POINTS')}
												placement="top-start"
												arrow
											>
												<i
													className={item.campaignMode === 1 ? 'icon-ico_gerar_pontos' : 'icon-ico_resgatar_pontos'}
													style={{
														color: item.campaignMode === 1 ? '#009fe5' : '#e58b00',
														fontSize: '20px',
													}}
												/>
											</CssTooltip>
										),
										description: (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													textAlign: 'left',
												}}
											>
												<div>{item.description}</div>
												<div
													style={{
														fontSize: '12px',
													}}
												>
													{item.sponsor}
												</div>
											</div>
										),
										status: <this.CampaignStatus status={item.status} />,
									}
								})}
								search={false}
								noPaging
							/>
						) : !this.state.showNothingCardCampaign ? (
							boxSkeleton
						) : (
							<Fragment>
								<NoResults value="NO_CAMPAIGNS_REGISTERED" />
								<div className={styles.seedsBoxButton} style={{ marginTop: '20px' }}>
									<Button
										variant="contained"
										color="greenButtonColor"
										className={styles.submit}
										size="sm"
										onClick={() => this.props.history.push(`/campaigns`)}
									>
										<AddCircleOutline className={classes.icons} />
										{_.toUpper(t('BUTTON_CREATE_CAMPAIGN'))}
									</Button>
								</div>
							</Fragment>
						)}
					</Grid>
				</GridContainer>
			</S.Container>
		)
	}

	changeCategoryImg = (points, tiers) => {
		tiers.map(t => {
			if (points >= t.scoreStart) {
				this.setState({
					categoryImg: {
						url: t.imgUrl,
						categoryName: t.name,
					},
				})
			}
		})
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
	return {
		walletExpirationPointsData: state.wallet.walletExpirationPointsData,
		walletExpirationPointsFailed: state.wallet.walletExpirationPointsFailed,

		generalRulesData: state.generalRules.generalRulesData,
		generalRulesDataFailed: state.generalRules.generalRulesDataFailed,

		cardsData: state.home.cardsData,
		cardsDataFailed: state.home.cardsDataFailed,

		userData: state.users.userData,
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({ GetDashboardData, ShowLoader, HideLoader }, dispatch)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(dashboardStyle),
	withTranslation(),
)(Home)
