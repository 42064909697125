import React from 'react'
import PropTypes from 'prop-types'
import Modal from '@material-ui/core/Modal'
import TecnicalDetails from '../TecnicalDetails'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import Button from 'components/CustomButtons/Button.jsx'
import { Container, CustomGridItem } from './styles'
import { useTranslation } from 'react-i18next'
import { DeleteExportLog } from '../../../../../../../redux/api/paymentGateway'

export function DetailsModal({
	open,
	handleClose,
	id,
	type,
	tableRef,
	setOpenSnack,
}) {
	const { t } = useTranslation()

	const handleRemove = () => {
		DeleteExportLog(id)
			.then(e => {
				if (e) {
					setOpenSnack({
						open: true,
						color: 'success',
						message: 'Registro apagado com sucesso.',
					})
					return
				}
				setOpenSnack({
					open: true,
					color: 'danger',
					message: 'Erro ao tentar apagar o registro.',
				})
			})
			.finally(() => tableRef.current && tableRef.current.onQueryChange())
		handleClose()
	}
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Container width={type === 'technical' ? '1257px' : '500px'}>
				{type === 'technical' ? (
					<TecnicalDetails id={id} handleClose={handleClose} />
				) : (
					<GridContainer
						style={{
							display: 'flex',
							gap: '42px',
						}}
					>
						<GridItem xs={12}>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridItem>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '14px',
									fontWeight: 'bold',
									marginBottom: '5px',
								}}
							>
								{t(
									'Ao excluir o registro não será possível restaurar.',
								)}
							</div>
							<div
								style={{
									hidden: true,
									fontSize: '14px',
									fontWeight: 'bold',
								}}
							>
								{t('Deseja realmente continuar?')}
							</div>
						</GridItem>
						<CustomGridItem xs={12}>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								onClick={handleClose}
							>
								{t('BUTTON_CANCEL')}
							</Button>
							<Button
								size="sm"
								color="greenButtonColor"
								onClick={() => handleRemove()}
							>
								{t('BUTTON_CONFIRM')}
							</Button>
						</CustomGridItem>
					</GridContainer>
				)}
			</Container>
		</Modal>
	)
}

DetailsModal.propTypes = {
	open: PropTypes.bool,
	handleClose: PropTypes.func,
	id: PropTypes.string,
	type: PropTypes.string,
	tableRef: PropTypes.func,
	setOpenSnack: PropTypes.func,
}
