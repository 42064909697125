/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'
import * as S from './styles'

// @ components
import Section from 'components/Section'
import * as Tab from 'components/NewTab/styles'
import StatusByDivision from './components/StatusByDivision'
import Generation from './components/Generation'
import Cashback from './components/Cashback'
import RescueTab from './components/Rescue'

// @ API
import { GetStatusProductsByDivision, GetProductTypeOfBenefit } from '../../../../redux/api/productPage'

//@Utils
import { extractProductIdFromUrl, userId } from './mocks'
import CalcMemory from './components/CalculationMemory'
import CustomModal from './components/Modal'
import Cookies from 'js-cookie'

const Parametrization = ({
	rules,
	setTabOn,
	multiple,
	division,
	productData,
	setDivision,
	setMultiple,
	editMode,
	setEditMode,
	parameterization,
	selectedDivision,
	setReloadProduct,
	setSelectedDivision,
	setParameterization,
	selectedDivisionIdGen,
	selectedDivisionIdRed,
	selectedDivisionIdCash,
	setSelectedDivisionIdGen,
	setSelectedDivisionIdRed,
	setSelectedDivisionIdCash,
	setMultipleParameterization,
}) => {
	//
	const { t } = useTranslation()
	const IsBranch = Cookies.get('IsBranch') || true
	const [circularProgress, setCircularProgress] = useState(false)
	const [statusData, setStatusData] = useState({})
	const [statusName, setStatusName] = useState('')
	const [hasNoDivision, setHasNoDivision] = useState(false)
	const [typeOfBenefit, setTypeOfBenefit] = useState({})
	const [openCalcMemory, setOpenCalcMemory] = useState(false)
	const [tabIndex, setTabIndex] = useState(0)
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
		tabIndex: null,
	})

	const [typeOfBenefitRedeem, setTypeOfBenefitRedeem] = useState([
		{
			key: '',
			value: '',
		},
	])

	const [typeOfBenefitPoints, setTypeOfBenefitPoints] = useState([
		{
			key: '',
			value: '',
		},
	])

	const [typeOfBenefitCashback, setTypeOfBenefitCashback] = useState([
		{
			key: '',
			value: '',
		},
	])

	const [typeOfBenefitHistory, setTypeOfBenefitHistory] = useState({})
	const [onTab, setOnTab] = useState({
		onTabGeneration: false,
		onTabRecue: false,
		onTabCashback: false,
	})

	const [onTabTrue, setOnTabTrue] = useState(false)

	const productId = extractProductIdFromUrl()
	const partnerId = userId

	useEffect(() => {
		let newTypeOfBenefitHistory = ''
		if (onTab.onTabGeneration) {
			newTypeOfBenefitHistory = 'Points'
		} else if (onTab.onTabRecue) {
			newTypeOfBenefitHistory = 'Redeem'
		} else if (onTab.onTabCashback) {
			newTypeOfBenefitHistory = 'Cashback'
		}
		setTypeOfBenefitHistory(newTypeOfBenefitHistory)
		setOnTabTrue(onTab.onTabGeneration || onTab.onTabRecue || onTab.onTabCashback)
	}, [onTab])

	useEffect(() => {
		const fetchData = async () => {
			setCircularProgress(true)

			try {
				const data = await GetStatusProductsByDivision(productId, statusName, partnerId)
				if (data.success === false && data.errors && data.errors.length > 0) {
					const hasNoDivisionError = data.errors.find(error => error.field === 'partner' && error.message === 'partner - HAS_NO_DIVISION')
					if (hasNoDivisionError) {
						setHasNoDivision(true)
					}
				}

				setStatusData(data)
			} catch (error) {
				console.error('Erro ao buscar dados de status:', error)
			} finally {
				setCircularProgress(false)
			}
		}

		fetchData()

		const fetchTypeOfBenefit = async () => {
			setCircularProgress(true)

			try {
				const typeOfBenefitData = await GetProductTypeOfBenefit()
				if (!_.isEmpty(typeOfBenefitData)) {
					setTypeOfBenefit(IsBranch ? typeOfBenefitData : typeOfBenefitData.filter(item => item.value !== 'ByDivision'))
				}
			} catch (error) {
				console.error('Erro ao buscar o tipo de benefício:', error)
			} finally {
				setCircularProgress(false)
			}
		}

		fetchTypeOfBenefit()
	}, [productId, statusName, partnerId])

	const sharedProps = {
		rules,
		multiple,
		setMultiple,
		setTabOn,
		division,
		editMode,
		setEditMode,
		setSelectedDivision,
		selectedDivision,
		typeOfBenefitPoints,
		setTypeOfBenefitPoints,
		setTypeOfBenefitRedeem,
		typeOfBenefitCashback,
		setTypeOfBenefitCashback,
		typeOfBenefitRedeem,
		typeOfBenefit,
		setOnTab,
		parameterization,
		circularProgress,
		productData,
		setMultipleParameterization,
		setParameterization,
		setDivision,
		setReloadProduct,
		selectedDivisionIdGen,
		setSelectedDivisionIdGen,
		selectedDivisionIdRed,
		setSelectedDivisionIdRed,
		selectedDivisionIdCash,
		setSelectedDivisionIdCash,
		openCalcMemory,
		setOpenCalcMemory,
	}

	const onSetTab = index => {
		if (editMode) {
			setModalParameterization({
				open: true,
				title: 'As alterações realizadas serão perdidas.',
				subtitle: 'Deseja continuar?',
				tabIndex: index,
			})
		} else {
			setTabIndex(index)
		}
	}

	return (
		<S.Container>
			<Section title={t('SECTION_PARAMETERIZATION_SALE')} />
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					setTabIndex(modalParameterization.tabIndex)
					setEditMode(false)
					setModalParameterization({ open: false })
				}}
			/>
			<S.SnackContainer>
				<S.CustomCard>
					<S.History>
						<Tooltip arrow id="tooltip-top" title={'Histórico de alterações'} placement="top">
							<Link to={`/product/history/ProductId=${productId}&TypeOfBenefit=${typeOfBenefitHistory}`}>
								<i className="icon-menu_relatorios" />
							</Link>
						</Tooltip>
					</S.History>
					<Tab.NewTabs defaultIndex={1} selectedIndex={tabIndex} onSelect={index => onSetTab(index)}>
						<Tab.NewTabList>
							<Tab.NewTabWhite style={{ marginTop: 0 }}>{t('Status por Divisão de negócio')}</Tab.NewTabWhite>
							<Tab.NewTab>{t('Geração')}</Tab.NewTab>
							<Tab.NewTab>{t('Resgate')}</Tab.NewTab>
							{rules?.cashbackRules?.allowCashback && rules?.digitalWalletRules?.allowDigitalWallet && <Tab.NewTab>{t('Cashback')}</Tab.NewTab>}
						</Tab.NewTabList>
						<Tab.NewTabPanel>
							<StatusByDivision
								partnerId={partnerId}
								productId={productId}
								statusName={statusName}
								setStatusName={setStatusName}
								hasNoDivision={hasNoDivision}
								setOnTab={setOnTab}
								setReloadProduct={setReloadProduct}
							/>
						</Tab.NewTabPanel>
						<Tab.NewTabPanel>
							<Generation {...sharedProps} />
						</Tab.NewTabPanel>
						<Tab.NewTabPanel>
							<RescueTab {...sharedProps} />
						</Tab.NewTabPanel>
						{rules?.cashbackRules?.allowCashback && rules?.digitalWalletRules?.allowDigitalWallet && (
							<Tab.NewTabPanel>
								<Cashback {...sharedProps} />
							</Tab.NewTabPanel>
						)}
					</Tab.NewTabs>
				</S.CustomCard>
				<CalcMemory open={openCalcMemory} productData={productData ?? []} onClose={() => setOpenCalcMemory(!openCalcMemory)} />
			</S.SnackContainer>
		</S.Container>
	)
}

export default Parametrization
