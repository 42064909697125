import TextField from '@mui/material/TextField';
import { styled } from '@mui/system'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

export const CustomFormField = styled(TextField)(() => ({
	width: '100%',
	'& .Mui-focused fieldset': {
		borderColor: `${
			getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor
		} !important`,
	},
	'& label.Mui-focused': {
		color: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
	},
	'& .MuiOutlinedInput-root': {
		height: '40px',
		'&:hover fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
		'&.Mui-focused fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
	},
	'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
		borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
			?.backgroundColor,
	},
	'& .MuiInput-underline:after': {
		borderBottom: `2px solid  ${
			getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor
		}`,
	},
}))
