import * as actionTypes from './actionsTypes'
import * as API from '../api/partner'

// GET
export const GetPartnersData = (name, cnpj, page) => {
	return (dispatch) =>
		API.GetPartners(name, cnpj, page)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.FETCH_PARTNERS_DATA,
			partnersData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.FETCH_PARTNERS_FAILED,
			partnersDataFailed: error,
		}
	}
}

// UPDATE Profile
export const UpdatePartnerData = (obj) => {
	return (dispatch) =>
		API.UpdatePartnerData(obj)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.UPDATE_PARTNER_DATA,
			updatePartnerData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.UPDATE_PARTNER_FAILED,
			updatePartnerDataFailed: error,
		}
	}
}
export const GetPartnerBranchByPartnerId = (
	id,
	name,
	cnpj,
	status,
	page,
	pageSize,
) => {
	return (dispatch) =>
		API.GetPartnerBranchByPartnerId(id, name, cnpj, status, page, pageSize)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.UPDATE_PARTNER_DATA,
			partnersBranchesData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.UPDATE_PARTNER_FAILED,
			partnersBranchesDataFailed: error,
		}
	}
}

// POST Branch
export const PostPartnersBranchData = (obj) => {
	return (dispatch) =>
		API.PostPartnersBranch(obj)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.POST_PARTNERS_BRANCH_DATA,
			postPartnersBranchData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.POST_PARTNERS_BRANCH_FAILED,
			postPartnersBranchDataFailed: error,
		}
	}
}

// UPDATE Branch
export const UpdatePartnersBranchData = (obj) => {
	return (dispatch) =>
		API.UpdatePartnersBranch(obj)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.UPDATE_PARTNERS_BRANCH_DATA,
			updatePartnersBranchData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.UPDATE_PARTNERS_BRANCH_FAILED,
			updatePartnersBranchDataFailed: error,
		}
	}
}

// ----------------------------

export const GetCashbackRules = () => {
	return (dispatch) =>
		API.GetCashbackRules()
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.FETCH_CASHBACK_RULES_DATA,
			cashbackRulesData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.FETCH_CASHBACK_RULES_FAILED,
			cashbackRulesFailed: error,
		}
	}
}

export const UpsertPartnerCashbackRules = (obj) => {
	return (dispatch) =>
		API.UpsertPartnerCashbackRules(obj)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.POST_PARTNER_CASHBACK_RULES_DATA,
			postPartnerCashbackRulesData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.POST_PARTNER_CASHBACK_RULES_FAILED,
			postPartnerCashbackRulesDataFailed: error,
		}
	}
}

export const GetPartnerConsumers = (name, cnpj, pageSize, page) => {
	return (dispatch) =>
		API.GetPartnerConsumers(name, cnpj, pageSize, page)
			.then((response) => {
				dispatch(success(response))
				return response
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.FETCH_PARTNER_CONSUMERS_DATA,
			partnerConsumersData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.FETCH_PARTNER_CONSUMERS_FAILED,
			partnerDataFailed: error,
		}
	}
}

export const GetConsumerCashbackRules = (consumerId) => {
	return (dispatch) =>
		API.GetConsumerCashbackRules(consumerId)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.FETCH_CONSUMER_CASHBACK_RULES_DATA,
			consumerCashbackRulesData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.FETCH_CONSUMER_CASHBACK_RULES_FAILED,
			consumerCashbackRulesDataFailed: error,
		}
	}
}

export const UpsertPartnerConsumerCashbackRules = (obj) => {
	return (dispatch) =>
		API.UpsertPartnerConsumerCashbackRules(obj)
			.then((response) => {
				dispatch(success(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(failed(error))
			})

	function success(data) {
		return {
			type: actionTypes.POST_PARTNER_CONSUMER_CASHBACK_RULES_DATA,
			postPartnerConsumerCashbackRulesData: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.POST_PARTNER_CONSUMER_CASHBACK_RULES_FAILED,
			postPartnerConsumerCashbackRulesDataFailed: error,
		}
	}
}
