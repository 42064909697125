import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const TabPanel = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: ${getThemeColorFromLocalStorage()?.headers
				?.backgroundColor} !important;
			background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor};
		}
	}
`

export const CustomCard = styled.form`
	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons
			?.backgroundColor};
	}
`

export const Filter = styled.div`
	margin-top: 25px;
`

export const Buttons = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 10px;
`

export const Table = styled.div`
	margin-top: 25px;
`
