import * as yup from 'yup'
import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'

export const initialValues = {
	value: '',
	description: '',
}

export const validation = yup.object().shape({
	value: yup.string().required(''),
	description: yup.string(),
})

export const ValidateExtension = (file, setInvalidFile) => {
	var caminhoArquivo = file.value
	var extensoesPermitidas = /(\.jpg|\.png|\.jpeg|\.pdf)$/i
	if (!extensoesPermitidas.exec(caminhoArquivo)) {
		setInvalidFile(true)
		return false
	} else {
		setInvalidFile(false)
		return true
	}
}

export const btnBGCol = () => {
	if (
		getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage()?.navigation &&
		getThemeColorFromLocalStorage()?.navigation?.buttons &&
		getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor
	) {
		return getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor
	}
	return `#fff`
}
