import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { LabelForm } from './styles'
import { Checkbox } from '@mui/material'

export default function CustomCheckMui({ label, ...props }) {
	const [getThemeFromStorage] = useState(getThemeColorFromLocalStorage())
	return (
		<LabelForm
			themeColor={getThemeFromStorage}
			{...props}
			control={
				<Checkbox
					{...props}
					defaultChecked
					//sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
				/>
			}
			label={label}
		/>
	)
}

CustomCheckMui.propTypes = {
	label: PropTypes.string,
}

export const CustomCheckMuiButton = ({ ...props }) => {
	const [getThemeFromStorage] = useState(getThemeColorFromLocalStorage())
	return (
		<Checkbox
			{...props}
			defaultChecked
			sx={{
				//'& .MuiSvgIcon-root': { fontSize: 28 },
				color:
					getThemeFromStorage?.navigation?.buttons?.backgroundColor,
				'&.Mui-checked': {
					color:
						getThemeFromStorage?.navigation?.buttons
							?.backgroundColor,
				},
			}}
		/>
	)
}
