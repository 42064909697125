import styled from 'styled-components/macro'

export const Container = styled.div`
	width: 100%;
`

export const Header = styled.div`
	align-items: center;
	display: flex;
	margin-bottom: 25px;
	height: 40px;
`

export const FilterForm = styled.form`
	background-color: #f6f6f6;
	border-radius: 5px;
	min-width: 800px;
	margin: auto;
	margin-bottom: 20px;
	padding: 20px;
	width: 100%;
`

export const DateTimeContain = styled.div`
	display: flex;
	flex-wrap: wrap;
	min-width: 800px;
	width: 100%;
	.text-field {
		background-color: #fff;
		width: 62.5%;
	}
`
export const DateIconContain = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-right: 18px;
	position: relative;
	width: 150px !important;
	> img {
		margin-right: 10px;
		position: absolute;
	}
`
export const BntFilterContain = styled.div`
	display: flex;
	justify-content: flex-end;
`

export const ActionBtn = styled.button`
	background-color: ${(props) =>
		props.themeColor.navigation.buttons.backgroundColor};
	border: none;
	cursor: pointer;
	color: ${(props) => props.themeColor.navigation.buttons.textColor};
	font-size: 80%;
	height: 43px;
	width: 43px;
	font-size: 20px;
`
