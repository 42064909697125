import React, { useEffect, useRef } from 'react'

import PropTypes from 'prop-types'
import { Box, Tooltip } from '@mui/material'

import * as S from './styles'
import { defaultBusinessMock } from '../../../../../Mocks'
export default function Selection({
	item,
	open,
	handleClick,
	handleItemClick,
	Business,
	setOModalCancel,
	handleClose,
	disable,
	setItem,
	handleConfirm,
	onOpen,

	setOnOpen,
}) {
	const containerRef = useRef(null)

	// Use useEffect para adicionar um event listener quando o componente é montado
	useEffect(() => {
		function handleClickOutside(event) {
			if (containerRef.current && !containerRef.current.contains(event.target)) {
				handleClose() // Chame a função para fechar a seleção
			}
		}

		// Adicione um event listener para cliques fora do componente
		document.addEventListener('mousedown', handleClickOutside)

		// Lembre-se de remover o event listener quando o componente for desmontado
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [containerRef, handleClose])

	// useEffect(() => {
	// 	if (disable) SimpleDivision(handleItemClick, cnpj)
	// }, [disable])

	return (
		<Box component="div" ref={containerRef}>
			<S.Header>
				<S.HeaderText>Selecione o tipo de divisão da empresa do parceiro*</S.HeaderText>
				<Tooltip
					arrow
					title="O cadastro da empresa pode ser estruturado em divisões, conforme a estrutura de negócio. Essa seleção irá definir como serão essas divisões dentro da plataforma. Isso impacta em como as taxas e parâmetros referentes ao catálogo de produtos (geração de pontos, resgate e cashback) irão funcionar de forma isolada em cada uma dessas divisões."
					placement="top"
				>
					<S.HeaderIcon className="icon-ico_info" />
				</Tooltip>
			</S.Header>
			<S.Body mt="30px">
				<div>
					<S.OptionsContainer open={open} bg={item?.bgColor} border={item?.isFinal} onClick={() => handleClick(disable)} disable={disable}>
						<S.Image>
							<img src={item?.image} alt="logo" />
						</S.Image>
						<S.CustomSelect disable={disable}>
							{item?.subtitle && <S.Text>{item?.subtitle}</S.Text>}
							<S.TextDescription open={open}>{item?.name}</S.TextDescription>
						</S.CustomSelect>

						<S.IconContainer open={open} disable={disable}>
							<i className="icon-bot_play" />
						</S.IconContainer>
					</S.OptionsContainer>

					{open && (
						<S.CustomBox>
							{Business.map(item =>
								!item?.isFinal ? (
									<S.OptionsContainer
										key={item?.id}
										bg={item?.bgColor}
										onClick={() => {
											handleItemClick(item, disable)
											handleClose()
										}}
										border={item?.isFinal}
										open={open}
									>
										<S.Image>
											<img src={item?.image} alt="logo" />
										</S.Image>
										<S.CustomSelect>
											<S.TextDescription open={open}>{item?.name}</S.TextDescription>
										</S.CustomSelect>
									</S.OptionsContainer>
								) : null,
							).filter(Boolean)}
						</S.CustomBox>
					)}
				</div>
				<S.Description bg={'#EBEBEB'} type={item?.type || ''}>
					<S.TextContainer>
						<span>{item?.description}</span>
						{item?.id === 1 && (
							<Tooltip
								arrow
								title="O cadastro da empresa pode ser estruturado em divisões, conforme a estrutura de negócio. Essa seleção irá definir como serão essas divisões dentro da plataforma. Isso impacta em como as taxas e parâmetros referentes ao catálogo de produtos (geração de pontos, resgate e cashback) irão funcionar de forma isolada em cada uma dessas divisões."
								placement="top"
							>
								<S.HeaderIcon className="icon-ico_info" />
							</Tooltip>
						)}
					</S.TextContainer>
					{!onOpen && (
						<S.GroupButton>
							<Tooltip arrow title="Selecione o tipo de divisão para criá-la" placement="top" disableHoverListener={item?.id !== 1}>
								<S.CustomButton
									bgColor={item?.bgColor}
									onClick={() => {
										if (item?.id === 1) return
										handleConfirm()
										setOnOpen(true)
									}}
								>
									<span>{item?.btnTxt || ''}</span>
								</S.CustomButton>
							</Tooltip>
							{item?.id !== 1 && (
								<Tooltip arrow title="Reiniciar" placement="top" disableHoverListener={item?.id !== 1}>
									<S.IconButton
										onClick={() => {
											setItem(defaultBusinessMock)
											setOnOpen(false)
										}}
									>
										<S.Icon className="icon-bot_parametrizar_multiplo" />
									</S.IconButton>
								</Tooltip>
							)}
						</S.GroupButton>
					)}
					{onOpen && (
						<S.CustomButton
							size="sm"
							color="#F05B4F"
							bgHover="#F05B4F"
							borderColor="#F05B4F"
							onClick={e => {
								e.preventDefault()
								setOModalCancel({
									open: true,
									text: 'Deseja realmente cancelar a operação?',
									subText: 'Todas as modificações serão perdidas.',
								})
							}}
						>
							Cancelar
						</S.CustomButton>
					)}
				</S.Description>
			</S.Body>
		</Box>
	)
}

Selection.propTypes = {
	item: PropTypes.shape({
		id: PropTypes.number,
		bgColor: PropTypes.string,
		subColor: PropTypes.string,
		isFinal: PropTypes.bool,
		description: PropTypes.string,
		name: PropTypes.string,
		image: PropTypes.string,
		subtitle: PropTypes.string,
		btnTxt: PropTypes.string,
		type: PropTypes.string,
	}),
	disable: PropTypes.bool,
	open: PropTypes.bool,
	handleClick: PropTypes.func,
	handleConfirm: PropTypes.func,
	handleItemClick: PropTypes.func,
	Business: PropTypes.array,
	handleClose: PropTypes.func,
	cnpj: PropTypes.string,
	onOpen: PropTypes.bool,
	setItem: PropTypes.func,
	setOnOpen: PropTypes.func,
	setOModalCancel: PropTypes.func,
}
