export default function getLabelByElementId(element = '', list = []) {
	const result = list?.filter(item => item?.value == String(element))

	return result[0]?.label
}

export function getByElementId(element = '', list = []) {
	const result = list?.filter(item => item?.value == String(element))

	return result[0]
}

export function getByElementKey(element = '', list = []) {
	const result = list?.filter(item => item?.key == String(element))

	return result[0] || {}
}
