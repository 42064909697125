import * as actionTypes from './actionsTypes';
import * as API from '../api/generalRules';

export const fetchData = (data) => {
    return {
        type: actionTypes.FETCH_GENERAL_RULES_DATA,
        generalRulesData: data
    };
};

// FAIL FETCH -------------------------------------
export const fetchDataError = (error) => {
    return {
        type: actionTypes.FETCH_GENERAL_RULES_FAILED,
        generalRulesDataFailed: error
    };
};

//----------------------------------------------------

export const GetData = () => {
    return dispatch => (
        API.GetGeneralRulesData().then(response => {
            dispatch(fetchData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchDataError(error));
        })
    );
};
