/* eslint-disable */
import React, { useEffect, useState } from 'react'

import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import CustomDateNew from 'components/CustomDateNew'
import DocumentTitle from 'react-document-title'
import MaskedInput from 'react-text-mask'
import Select from 'react-select'
import Skeleton from 'react-loading-skeleton'
import Slide from '@material-ui/core/Slide'
import TagsInput from 'react-tagsinput'
import { Formik } from 'formik'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
// @material-ui/core components
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import Hidden from '@material-ui/core/Hidden'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Icon, Tooltip } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { Autocomplete } from '@material-ui/lab'
import Popper from '@material-ui/core/Popper'
import { makeStyles, createStyles } from '@material-ui/core/styles'

import CriacaoVerde from 'assets/img/01_Criador_campanha_habilitado.png'
import CriacaoCinza from 'assets/img/01_Criador_campanha_desabilitado.png'

import AvaliacaoVerde from 'assets/img/02_Avaliacao_campanha_habilitado.png'
import AvaliacaoCinza from 'assets/img/02_Avaliacao_campanha_desabilitado.png'
import AvaliacaoVermelha from 'assets/img/03_Pendente_campanha.png' //Avaliacao Pendente

import AprovadaVerde from 'assets/img/03_aprovada_campanha_habilitado.png'
import AprovadaCinza from 'assets/img/03_aprovada_campanha_desabilitado.png'
import ReprovadaVermelha from 'assets/img/04_reprovada_campanha_habilitado.png'

// core components
import Loader from 'components/Loader/Loader.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'
import NumberFormatCustom from 'components/NumberFormatCustom/NumberFormatCustom.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import Switch from '@material-ui/core/Switch'

//Utils
import compose from 'utils/compose'

import styles from '../campaign.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { germiniDefaultColors, dangerColor } from 'assets/jss/material-dashboard-pro-react'

import { withStyles, MuiThemeProvider } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

import {
	GetCurrentPartner,
	GetCampaignDataById,
	GetCampaignTypeListData,
	ListSponsorByCompanyName,
	GetSponsorsData,
	GetAllProduct,
	GetProductByBranches,
	GetProductTypeListData,
	GetScoringTypeListData,
	PostCampaignData,
	PutCampaignData,
	DeactivateCampaign,
	GetCategory,
	GetBrand,
	GetManufacturer,
	GetFamily,
	SweetAlert,
	HideAlert,
	ShowLoader,
	HideLoader,
} from 'redux/actions'

import SelectFilials from 'components/SelectFilials'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import ModalInfo from '../ModalInfo'
import CurrencyInput from '../../../components/NewCustomInput'
import { CampaignStyle, Container, CssTextField, ProductTypeMessage, theme, SelectQuantity, titleStyles, CustomGrid, CustomI } from './styles'
import { validationSchema } from '../utils'
import { getProgramTitle } from '../../../utils/utils'

const CustomPopper = function(props) {
	const classesPoper = useStyles()
	return <Popper {...props} className={classesPoper.root} placement="bottom" />
}

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				'& :hover': {
					color: '#ffffff',
					backgroundColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'& li': {
					backgroundColor: '#ffffff',
				},
			},
		},
	}),
)

function DateMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			readOnly
		/>
	)
}

function Transition(props) {
	return <Slide direction="down" {...props} />
}

const Form = props => {
	const {
		t,
		classes,
		values,
		errors,
		touched,
		loading,
		recordCount,
		handleSubmit,
		handleEdit,
		handleNewSponsor,
		handleChange,
		handleCancel,
		handleDeactivate,
		handleCheckboxClick,
		handleSelectedItems,
		loadSponsorOptions,
		selectItemsBranches,
		getProductByBranches,
		getBrandsOptions,
		getFamilyOptions,
		getManufacturerOptions,
		getCategoriesOptions,
		clearsOptions,
		handleBack,
		disabledForm,
		sponsorKey,
		listSponsors,
		selectItemsSponsors,
		setFieldTouched,
		setFieldValue,
		editMode,
		selectItemsCampaignType,
		selectItemsScoringType,
		selectItemsProductType,
		selectItemsProducts,
	} = props
	const [themeColor, setThemeColor] = useState()
	const [scoringType, setScoringType] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const getBGCol = () => themeColor && themeColor.structure && themeColor.structure.backgroundColor

	const customSelectStyles = name => ({
		menu: (base, state) => ({
			...base,
			zIndex: 100,
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = themeColor && themeColor.navigation && themeColor.navigation.buttons && themeColor.navigation.buttons.elementsColor
			return {
				...styles,
				backgroundColor: isDisabled ? undefined : isSelected ? color : isFocused ? color : undefined,
				color: isDisabled ? undefined : isSelected ? '#FFFFFF' : isFocused ? '#FFFFFF' : undefined,
			}
		},
		control: (base, state) => ({
			...base,
			boxShadow: state.isFocused ? 0 : 0,
			borderColor:
				_.get(errors, name, false) && _.get(touched, name, false)
					? dangerColor[0]
					: state.isFocused
					? themeColor.structure.backgroundColor
					: base.borderColor,
			'&:hover': {
				borderColor: state.isFocused ? themeColor.structure.backgroundColor : base.borderColor,
			},
		}),
		multiValue: (styles, state) => {
			return {
				...styles,
				backgroundColor: state.isDisabled ? '#9e9e9e' : germiniDefaultColors[0],
				color: 'white',
				borderRadius: '5px',
				paddingRight: state.isDisabled ? '4px' : 0,
			}
		},
		placeholder: base => ({
			...base,
			fontWeight: 400,
			marginLeft: '5px',
			color: _.get(errors, name, false) && _.get(touched, name, false) ? dangerColor[0] : base.color,
		}),
		multiValueLabel: styles => ({
			...styles,
			color: 'white',
		}),
		multiValueRemove: (styles, state) => ({
			...styles,
			color: 'white',
			borderRadius: '5px',
			':hover': {
				backgroundColor: state.isDisabled ? '#9e9e9e' : germiniDefaultColors[9],
				color: 'white',
				cursor: 'pointer',
			},
			display: state.isDisabled ? 'none' : 'inherit',
		}),
	})

	moment.locale(localStorage.getItem('i18nextLng'))

	const change = (name, e) => {
		if (name.includes('startTerm')) {
			if (moment(e).isValid() && (!e.length || e.length === 10)) {
				setFieldValue(name, e, true)

				if (moment(values.endTerm).isValid() && e.isAfter(values.endTerm)) {
					setFieldValue('endTerm', e, true)
					setFieldTouched('endTerm', true, false)
				}
			} else {
				setFieldValue(name, e, true)
			}
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('endTerm')) {
			if (moment(e).isValid() && (!e.length || e.length === 10)) {
				setFieldValue(name, e, true)
				setFieldTouched(name, true, false)

				if (moment(values.startTerm).isValid() && e.isBefore(values.startTerm)) {
					setFieldValue('startTerm', e, true)
					setFieldTouched('startTerm', true, false)
				}
			} else {
				setFieldValue(name, e, true)
			}
			return
		}

		if (name.includes('campaignType')) {
			if (e.value.toString() !== '4') {
				setFieldValue('volume', '', true)
				setFieldValue('productType', e.value, true)
				loadProductsOptions(e.value, '')
			} else {
				setFieldValue('productType', '', true)
				setFieldValue('selectedProduct', '', true)
				loadProductsOptions('', '')
			}
			setFieldValue('items', [], true)

			setFieldValue(name, e.value, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('qtyLimit')) {
			if (e.target.value === false) {
				setFieldValue('units', null, true)
			}
		}

		if (name.includes('scoringType')) {
			setFieldValue('scoringValue', '', true)
			setFieldValue(name, e.value, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('sponsorId')) {
			setFieldValue(name, e.value, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('productType')) {
			setFieldValue(name, e.value, true)
			setFieldValue('items', [], true)
			loadProductsOptions(e.value, '')
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('selectedProduct')) {
			setFieldValue(name, e, true)
			setFieldTouched(name, true, false)
			return
		}
		if (name.includes('number')) {
			setFieldValue(name.split('|')[1], e.target.value, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('units')) {
			setFieldValue(name, e.target.value, true)
			setFieldTouched(name, true, false)
			return
		}

		if (name.includes('searchField')) {
			loadProductsOptions(values.productType, e.target.value)
		}

		e.persist()
		handleChange(e)
		setFieldTouched(name, true, false)
	}

	function handleFilialModal() {
		setFieldValue('openFilialModal', true)
	}

	const statusOptionsQtyLimit = [
		{ value: 'false', label: t('LABEL_CAMPAIGN_QUANTITY_UNLIMITED') },
		{ value: 'true', label: t('LABEL_CAMPAIGN_QUANTITY_LIMITED') },
	]

	const handleSelectBranch = values => {
		if (_.find(values, x => x.value === '*')) {
			setFieldValue('partnerBranchs', selectItemsBranches.filter(x => x.value !== '*'), true)
		} else {
			setFieldValue('partnerBranchs', values, true)
		}
		setFieldTouched('partnerBranchs', true, false)
	}

	const handleAdd = async item => {
		await handleCheckboxClick(item)
		var array = values.items
		selectItemsProducts &&
			selectItemsProducts
				.filter(item => item.checked)
				.map(item =>
					array.push({
						id: item.id,
						label: item.label,
						checked: false,
					}),
				)
		setFieldValue('items', array)
		setFieldTouched('items', true, false)
		handleSelectedItems()
	}

	const getSelectItemValue = (list, i) => {
		console.log(list, i)
		if (i) {
			let value = _.find(list, x => x.value === i.toString())
			return value
		}
	}

	const handleAddAll = async () => {
		if (values.searchField.length > 0) {
			var array = selectItemsProducts.filter(item => !_.find(values.items, x => x.label === item.label))
			values && values.items && values.items.map(item => array.push(item))
			setFieldValue('items', array)
		} else {
			switch (values.productType.toString()) {
				case '1':
					setFieldValue('items', [{ id: '*', label: 'Todas as categorias' }])
					break
				case '2':
					setFieldValue('items', [{ id: '*', label: 'Todos os produtos' }])
					break
				case '3':
					setFieldValue('items', [{ id: '*', label: 'Todas as marcas' }])
					break
				case '5':
					setFieldValue('items', [{ id: '*', label: 'Todas as familias' }])
					break
				case '6':
					setFieldValue('items', [{ id: '*', label: 'Todas os fabricantes' }])
					break
				default:
					break
			}
		}
		setFieldValue('searchField', '')
		loadProductsOptions(values.productType, '')
		setFieldTouched('items', true, false)
		handleSelectedItems()
	}

	const handleRemove = items => {
		var array = values.items.filter(x => _.find(items, y => y === x.label))
		setFieldValue('items', array)
		setFieldTouched('items', true, false)
	}

	const handleRemoveAll = items => {
		setFieldValue('items', [])
		setFieldTouched('items', true, false)
	}
	const loadProductsOptions = (productType, searchField) => {
		let branchesSelected = values.partnerBranchs
		let productId = _.flatMap(branchesSelected, 'partnerBranchId')

		function configureArray(id) {
			return 'branches=' + id
		}
		var branches = productId && productId.map(configureArray).join('&')
		switch (productType.toString()) {
			case '1':
				return getCategoriesOptions(searchField)
			case '2':
				return getProductByBranches(searchField, branches)
			case '3':
				return getBrandsOptions(searchField)
			case '5':
				return getFamilyOptions(searchField)
			case '6':
				return getManufacturerOptions(searchField)
			default:
				clearsOptions()
		}
	}

	const selectedBranches = modalBranches => {
		let checkedBranches = []
		modalBranches.forEach(item => {
			if (item.check === true) {
				checkedBranches.push({ partnerBranchId: item.id })
			}
		})
		values.partnerBranchs = checkedBranches
	}

	const isValidDate = current => {
		var today = new Date()
		today.setDate(today.getDate() - 1)
		return current.isAfter(today)
	}

	const isValidDateEnd = current => {
		var today = moment(values.startTerm).isValid() ? new Date(values.startTerm) : new Date()
		today.setDate(today.getDate() - 1)
		return current.isAfter(today)
	}

	const getSponsorId = value => {
		getSelectItemValue(selectItemsSponsors, (values.sponsorId = value.value))
	}

	const getProductType = value => {
		getSelectItemValue(selectItemsProductType, (values.productType = value.value))
	}

	const getCampaignType = value => {
		getSelectItemValue(selectItemsCampaignType, (values.campaignType = value.value))
	}

	const getScoringType = value => {
		getSelectItemValue(selectItemsScoringType, (values.scoringType = value.value))
		setScoringType(values.scoringType)
	}

	return (
		<Container themeColor={themeColor}>
			{loading && <Loader />}
			<form>
				<SelectFilials
					open={values.openFilialModal}
					campaignId={values.modalId}
					closeByClick={() => setFieldValue('openFilialModal', false)}
					selectedBranchs={modalBranches => selectedBranches(modalBranches)}
					subTitle={'INFO_CAMPAIGN'}
				/>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<CustomGrid>
							<Section title={t('TITLE_CAMPAIGN_STATUS')} style={{ flexGrow: 1 }} />
							<div style={{ marginLeft: '15px' }}>
								{editMode && (
									<Button
										size="sm"
										variant="contained"
										color="greenBorderButtonColor"
										style={{ marginRight: '0.875rem' }}
										onClick={handleCancel}
										disableElevation
									>
										{t('BUTTON_CANCEL')}
									</Button>
								)}
								{editMode && (
									<Button
										size="sm"
										disabled={values.partnerBranchs.length === 0}
										variant="contained"
										color="greenButtonColor"
										disableElevation
										onClick={handleSubmit}
									>
										{t('BUTTON_SAVE')}
									</Button>
								)}
							</div>
						</CustomGrid>
						<div
							className={`${styles.section} ${editMode && styles.sectionEdit}`}
							style={{
								height: 40,
								marginBottom: 25,
							}}
						>
							<Section title={t('FIELD_GENERATION_POINTS')} style={{ flexGrow: 1 }} />
							<div className={`${!editMode && styles.backButtonEdit}`}>
								{disabledForm && (
									<>
										<Button
											size="sm"
											variant="contained"
											color="greenBorderButtonColor"
											disableElevation
											style={{ marginRight: '0.875rem' }}
											onClick={handleBack}
										>
											{t('BUTTON_BACK')}
										</Button>
										{values.status.toString() === '0' && (
											<Button size="sm" variant="contained" onClick={handleEdit} color="greenButtonColor" disableElevation>
												{t('BUTTON_EDIT')}
											</Button>
										)}
										{values.status.toString() === '2' && (
											<Button size="sm" variant="contained" color="greenButtonColor" disableElevation onClick={handleDeactivate}>
												{t('BUTTON_DEACTIVATE')}
											</Button>
										)}
									</>
								)}
							</div>
						</div>
					</GridItem>
				</GridContainer>
				<GridContainer>
					{/* #2 - NOME */}
					<GridItem xs={12} sm={12} md={4}>
						<CssTextField
							error={touched.name && Boolean(errors.name)}
							helperText={touched.name && Boolean(errors.name) ? 'Nome da campanha obrigatório' : ''}
							disabled={disabledForm}
							className={styles.TextField}
							fullWidth
							label={<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_NAME" />}
							id="name"
							name="name"
							value={values.name}
							onChange={change.bind(null, 'name')}
							variant="outlined"
							margin="dense"
							InputLabelProps={{
								classes: {
									disabled: values.partnerId !== '' ? classes.labelTexRF : '',
								},
							}}
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
					{/* #3 - LOGIN */}
					<GridItem xs={12} sm={12} md={4} style={{ display: 'flex', marginBottom: '15px' }}>
						<CurrencyInput
							error={touched.investment && Boolean(errors.investment)}
							helperText={touched.investment && Boolean(errors.investment) ? errors.investment : ''}
							disabled={disabledForm}
							className={styles.TextField}
							fullWidth
							label={<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_INVESTMENT" />}
							onValueChange={e => console.log(e)}
							id="investment"
							name="investment"
							value={values.investment}
							variant="outlined"
							margin="dense"
							setValue={setFieldValue}
							InputLabelProps={{
								classes: {
									disabled: values.partnerId !== '' ? classes.labelTexRF : '',
								},
							}}
							style={{
								transform: 'translateY(8px)',
								backgroundColor: 'rgb(255, 255, 255)',
							}}
						/>
					</GridItem>
					{/* #2 - SPONSOR */}
					{!editMode ? (
						<>
							<GridItem xs={11} sm={11} md={3}>
								<CssTextField
									error={touched.sponsorId && Boolean(errors.sponsorId)}
									helperText={touched.sponsorId && Boolean(errors.sponsorId) ? 'Patrocinador obrigatório' : ''}
									className={styles.TextField}
									disabled={disabledForm}
									fullWidth
									label={<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_SPONSOR" />}
									id="sponsor"
									name="sponsor"
									value={values.sponsor ? values.sponsor : ''}
									onChange={change.bind(null, 'sponsor')}
									variant="outlined"
									margin="dense"
									InputLabelProps={{
										classes: {
											disabled: values.partnerId !== '' ? classes.labelTexRF : '',
										},
									}}
									style={{ backgroundColor: '#fff' }}
								/>
							</GridItem>
						</>
					) : (
						<>
							<GridItem
								xs={12}
								sm={12}
								md={3}
								style={{
									marginTop: '7.5px',
								}}
							>
								<Autocomplete
									key={sponsorKey}
									styles={customSelectStyles}
									id="combo-box-demo"
									size="small"
									options={listSponsors}
									getOptionLabel={option => (option.label ? option.label : '')}
									getOptionSelected={(option, value) => value === undefined || value === '' || option.value === value.value}
									disableClearable={true}
									onChange={(event, value) => getSponsorId(value)}
									isDisabled={disabledForm}
									value={getSelectItemValue(selectItemsSponsors, values.sponsorId)}
									PopperComponent={CustomPopper}
									renderInput={params => (
										<div ref={params.InputProps.ref}>
											<CssTextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
												error={touched.sponsorId && Boolean(errors.sponsorId)}
												helperText={touched.sponsorId && Boolean(errors.sponsorId) ? 'Patrocinador obrigatório' : ''}
												label={t('FIELD_CAMPAIGN_SPONSOR')}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
								/>
							</GridItem>
						</>
					)}
					<GridItem xs={11} sm={11} md={1}>
						<div
							onClick={editMode ? handleNewSponsor : ''}
							style={{
								color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
								cursor: 'pointer',
							}}
						>
							<Tooltip title="Cadastrar patrocinador" placement="top" arrow>
								<Icon
									className="icon-ico_crud_parceiros"
									style={{
										fontSize: '28px',
										width: '45px',
										margin: '10px 0',
										overflow: 'visible',
										position: 'relative',
										top: '3px',
									}}
								/>
							</Tooltip>
						</div>
					</GridItem>
				</GridContainer>
				<GridContainer>
					{/* #4 - E-MAIL */}
					<GridItem xs={12} sm={12} md={4} style={{ marginTop: '5px', marginBottom: '15px' }}>
						{disabledForm ? (
							<CssTextField
								disabled={disabledForm}
								className={styles.TextField}
								fullWidth
								label={<CustomRequiredField disabled={disabledForm} value="FIELD_START" />}
								value={moment(values.startTerm).isValid() ? moment(values.startTerm).format('L') : values.startTerm}
								variant="outlined"
								margin="dense"
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<i
												style={{
													fontSize: '22px',
													color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
												}}
												className="icon-calendario"
											/>
										</InputAdornment>
									),
									inputComponent: DateMaskCustom,
								}}
								InputLabelProps={{
									classes: {
										disabled: values.partnerId !== '' ? classes.labelTexRF : '',
									},
								}}
								style={{ backgroundColor: '#fff' }}
							/>
						) : (
							<CustomDateNew
								id="startTerm"
								timeFormat={false}
								isValidDate={isValidDate}
								locale={t('LANGUAGE')}
								value={moment(values.startTerm).isValid() ? moment(values.startTerm).format('L') : values.startTerm}
								onChange={!disabledForm && change.bind(null, 'startTerm')}
								disabled={disabledForm}
								label={<CustomRequiredField disabled={disabledForm} value="FIELD_START" />}
								InputLabelProps={{
									classes: {
										disabled: values.partnerId !== '' ? classes.labelTexRF : '',
									},
								}}
							/>
						)}
					</GridItem>
					{/* #2 - STATUS */}
					<GridItem xs={12} sm={12} md={4} style={{ marginTop: '5px' }}>
						{disabledForm ? (
							<CssTextField
								disabled={disabledForm}
								className={styles.TextField}
								fullWidth
								label={<CustomRequiredField disabled={disabledForm} value="FIELD_END" />}
								value={moment(values.endTerm).isValid() ? moment(values.endTerm).format('L') : values.endTerm}
								variant="outlined"
								margin="dense"
								InputLabelProps={{
									classes: {
										disabled: values.endTerm !== '' && values.endTerm !== null ? classes.labelTexRF : '',
									},
								}}
								InputProps={{
									endAdornment: (
										<InputAdornment position="start">
											<i
												style={{
													fontSize: '22px',
													color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
												}}
												className="icon-calendario"
											/>
										</InputAdornment>
									),
								}}
								style={{ backgroundColor: '#fff' }}
							/>
						) : (
							<CustomDateNew
								id="endTerm"
								timeFormat={false}
								isValidDate={isValidDateEnd}
								locale={t('LANGUAGE')}
								value={moment(values.endTerm).isValid() ? moment(values.endTerm).format('L') : values.endTerm}
								onChange={!disabledForm && change.bind(null, 'endTerm')}
								label={t('FIELD_END')}
								disabled={disabledForm}
								InputLabelProps={{
									classes: {
										disabled: values.partnerId !== '' ? classes.labelTexRF : '',
									},
								}}
							/>
						)}
					</GridItem>
					<Grid xs={12} sm={12} md={12}>
						<GridItem xs={12} style={{ marginTop: '10px' }}>
							<FormLabel
								disabled={disabledForm}
								className={styles.LabelHorizontal}
								style={{
									color:
										themeColor &&
										themeColor.titles &&
										themeColor.titles.secondary &&
										themeColor.titles.secondary.textColor &&
										themeColor.titles.secondary.textColor,
								}}
							>
								<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_UNITS" />
							</FormLabel>
						</GridItem>
						<GridItem xs={3} style={{ marginTop: '15px', marginBottom: '15px' }}>
							<div onClick={handleFilialModal} className={styles.filialsDiv}>
								<i
									className="icon-filiais"
									style={{
										fontSize: '20px',
										color:
											themeColor &&
											themeColor.navigation &&
											themeColor.navigation.buttons &&
											themeColor.navigation.buttons.elementsColor &&
											themeColor.navigation.buttons.elementsColor,
										marginRight: '10px',
									}}
								/>
								<span
									className={styles.filials}
									style={{
										color: themeColor && themeColor.navigation && themeColor.navigation.buttons && themeColor.navigation.buttons.elementsColor,
									}}
								>
									{values.partnerBranchs.length !== 0 ? (
										'Unidades selecionadas: ' + values.partnerBranchs.length
									) : (
										<CustomRequiredField disabled={disabledForm} value="TITLE_UNITS" />
									)}
								</span>
							</div>
						</GridItem>
					</Grid>
					{/* {modelodecampanha} */}
					<GridItem xs={12} sm={4}>
						<GridContainer style={{ padding: '0' }}>
							<GridItem xs={12}>
								<FormLabel
									disabled={disabledForm}
									className={styles.LabelHorizontal}
									style={{
										color:
											themeColor &&
											themeColor.titles &&
											themeColor.titles.secondary &&
											themeColor.titles.secondary.textColor &&
											themeColor.titles.secondary.textColor,
									}}
								>
									<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_TYPE" />
								</FormLabel>
							</GridItem>
							<GridItem xs={12} style={{ marginTop: '10px', padding: '0' }}>
								<MuiThemeProvider theme={theme}>
									<Autocomplete
										styles={customSelectStyles('campaignType')}
										id="combo-box-demo"
										size="small"
										options={selectItemsCampaignType}
										getOptionLabel={option => (option.label ? option.label : '')}
										getOptionSelected={(option, value) => value === undefined || value === '' || option.value === value.value}
										disableClearable={true}
										onChange={(event, value) => getCampaignType(value)}
										isDisabled={disabledForm}
										value={getSelectItemValue(selectItemsCampaignType, values.campaignType)}
										PopperComponent={CustomPopper}
										renderInput={params => (
											<div ref={params.InputProps.ref}>
												<CssTextField
													style={{
														backgroundColor: '#ffffff',
														width: '100%',
													}}
													{...params}
													noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
													error={touched.campaignType && Boolean(errors.campaignType)}
													helperText={touched.campaignType && Boolean(errors.campaignType) ? 'Modelo da campanha obrigatório' : ''}
													label={t('FIELD_SELECT')}
													variant="outlined"
													size="small"
												/>
											</div>
										)}
									/>
								</MuiThemeProvider>
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={3}>
						<GridContainer style={{ padding: '0' }}>
							<GridItem xs={12}>
								{values.campaignType.toString() === '4' && (
									<FormLabel
										disabled={disabledForm}
										className={styles.LabelHorizontal}
										style={{
											color:
												themeColor &&
												themeColor.titles &&
												themeColor.titles.secondary &&
												themeColor.titles.secondary.textColor &&
												themeColor.titles.secondary.textColor,
										}}
									>
										<CustomRequiredField
											disabled={disabledForm}
											value="FIELD_CAMPAIGN_VOLUME"
											//disabled={true}
										/>
										<CustomRequiredField disabled={disabledForm} value="LABEL_CAMPAIGN_VOLUME_2" />
									</FormLabel>
								)}
							</GridItem>

							<GridItem xs={12} style={{ marginTop: '2px' }}>
								{values.campaignType.toString() === '4' && (
									<CssTextField
										error={touched.volume && Boolean(errors.volume)}
										helperText={touched.volume && Boolean(errors.volume) ? 'Valor obrigatório' : ''}
										disabled={disabledForm}
										className={styles.TextField}
										fullWidth
										label={t('LABEL_CAMPAIGN_VOLUME_1')}
										id="volume"
										name="volume"
										value={disabledForm ? _.toNumber(values.volume).toFixed(2) : values.volume}
										onChange={change.bind(null, 'number|volume')}
										variant="outlined"
										margin="dense"
										InputProps={{
											startAdornment: (
												<span
													style={{
														color: touched.subsidyValue && Boolean(errors.subsidyValue) ? dangerColor[0] : 'inherit',
													}}
												>
													{localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : ''}
												</span>
											),
											inputComponent: DoubleFormatCustom,
										}}
										InputLabelProps={{
											classes: {
												disabled: values.partnerId !== '' ? classes.labelTexRF : '',
											},
										}}
										style={{ backgroundColor: '#fff' }}
									/>
								)}
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={3}>
						<GridContainer style={{ padding: '0' }}>
							<GridItem xs={12}>
								{values.campaignType.toString() === '4' && (
									<FormLabel
										disabled={disabledForm}
										className={styles.LabelHorizontal}
										style={{
											color:
												themeColor &&
												themeColor.titles &&
												themeColor.titles.secondary &&
												themeColor.titles.secondary.textColor &&
												themeColor.titles.secondary.textColor,
										}}
									>
										<CustomRequiredField
											disabled={disabledForm}
											value="FIELD_TYPE"
											//disabled={true}
										/>
									</FormLabel>
								)}
							</GridItem>
							<GridItem xs={12} style={{ marginTop: '10px' }}>
								{values.campaignType.toString() === '4' && (
									<MuiThemeProvider theme={theme}>
										<Autocomplete
											styles={customSelectStyles}
											id="combo-box-demo"
											size="small"
											options={selectItemsProductType}
											getOptionLabel={option => (option.label ? option.label : '')}
											getOptionSelected={(option, value) => value === undefined || value === '' || option.value === value.value}
											disableClearable={true}
											onChange={(event, value) => getProductType(value)}
											isDisabled={disabledForm}
											value={getSelectItemValue(selectItemsProductType, values.productType)}
											PopperComponent={CustomPopper}
											renderInput={params => (
												<div ref={params.InputProps.ref}>
													<CssTextField
														style={{
															backgroundColor: '#ffffff',
															width: '100%',
														}}
														{...params}
														noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
														error={touched.productType && Boolean(errors.productType)}
														helperText={touched.productType && Boolean(errors.productType) ? 'Patrocinador obrigatório' : ''}
														label={t('FIELD_CAMPAIGN_PRODUCT_TYPE')}
														variant="outlined"
														size="small"
													/>
												</div>
											)}
										/>
									</MuiThemeProvider>
								)}
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
				{values.productType !== '' && selectItemsProducts.length > 0 && (
					<GridContainer style={{ padding: '15px 0 0 15px' }}>
						<GridItem xs={12}>
							<GridContainer
								style={{
									padding: '15px 0',
									backgroundColor: 'rgb(247, 247, 247)',
									borderRadius: '4px',
									border: '1px solid #DFDFDF',
								}}
							>
								{editMode && (
									<GridItem xs={12} sm={7}>
										<GridContainer
											style={{
												padding: '0',
												marginBottom: '10px',
												height: '40px',
											}}
										>
											<GridItem xs={12} sm={12} md={6} style={{ marginTop: '10px' }}>
												<div className={styles.TitleSession}>{t('FIELD_CAMPAIGN_SELECT_ITEMS')}:</div>
											</GridItem>
											<GridItem xs={12} sm={12} md={6}>
												<CssTextField
													className={styles.TextField}
													fullWidth
													placeholder={t('PLACEHHOLDER_QUERY')}
													id="searchField"
													name="searchField"
													value={values.searchField}
													onChange={change.bind(null, 'searchField')}
													variant="outlined"
													margin="dense"
													InputLabelProps={{
														classes: {
															disabled: values.partnerId !== '' ? classes.labelTexRF : '',
														},
													}}
													style={{
														margin: '0',
														background: '#fff',
													}}
												/>
											</GridItem>
										</GridContainer>
										{loading ? (
											<Skeleton height={205} />
										) : (
											<>
												<GridContainer
													style={{
														height: '205px',
														overflow: 'auto',
														display: 'flex',
													}}
												>
													<GridItem xs={12} className={styles.options}>
														{selectItemsProducts.filter(item => !_.find(values.items, x => x.id === item.id)).length > 0 &&
															Boolean(!_.find(values.items, x => x.id === '*')) && (
																<FormControlLabel
																	style={{
																		width: '100%',
																		backgroundColor: '#EDEDED',
																		padding: '5px',
																		borderRadius: '5px',
																	}}
																	onClick={e => handleAddAll()}
																	control={
																		<span
																			style={{
																				width: '20px',
																			}}
																		>
																			{'>>'}
																		</span>
																	}
																	classes={{
																		label: classes.label,
																	}}
																	label={<strong>Selecionar todos</strong>}
																/>
															)}
														{selectItemsProducts.filter(item => !_.find(values.items, x => x.id === item.id)).length > 0 &&
														Boolean(!_.find(values.items, x => x.id === '*')) ? (
															<>
																{selectItemsProducts
																	.filter(item => !_.find(values.items, x => x.label === item.label))
																	.map((item, index) => [
																		<FormControlLabel
																			key={index}
																			style={{
																				width: '100%',
																				padding: '5px',
																				lineHeight: 1.7,
																				borderRadius: '5px',
																			}}
																			onClick={e => handleAdd(item)}
																			control={
																				<span
																					style={{
																						width: '18px',
																						padding: '0px 4.5px',
																						border: `1px solid ${themeColor &&
																							themeColor.visual &&
																							themeColor.visual.icons &&
																							themeColor.visual.icons.color}`,
																						borderRadius: '50%',
																						color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
																						height: '18px',
																						marginRight: '6px',
																						lineHeight: 1.09,
																					}}
																				>
																					{'+'}
																				</span>
																			}
																			classes={{
																				root: classes.zebra,
																				label: classes.label,
																			}}
																			label={item.label}
																		/>,
																	])}
															</>
														) : selectItemsProducts.length > 0 ? (
															<>
																<div
																	style={{
																		marginTop: '20%',
																		textAlign: 'center',
																	}}
																>
																	{t('Nenhum item restante')}
																</div>
															</>
														) : (
															<>
																<div
																	style={{
																		marginTop: '20%',
																		textAlign: 'center',
																	}}
																>
																	{t('RESULTS_NOT_FOUND')}
																</div>
															</>
														)}
													</GridItem>
												</GridContainer>
											</>
										)}
									</GridItem>
								)}
								<GridItem xs={12} sm={disabledForm ? 12 : 5} styles={{ padding: '5px 0' }}>
									<SelectQuantity className={styles.TitleSession}>
										<div>
											{t('FIELD_CAMPAIGN_SELECTED_ITEMS')}: {values.items && values.items.length}
										</div>
									</SelectQuantity>

									<GridContainer
										style={{
											height: '205px',
											overflow: 'auto',
											display: 'flex',
										}}
									>
										<GridItem xs={12}>
											{!disabledForm && values.items.length > 0 && (
												<FormControlLabel
													style={{
														width: '100%',
														backgroundColor: '#EDEDED',
														padding: '5px',
														borderRadius: '5px',
														marginTop: '20px',
													}}
													onClick={e => handleRemoveAll()}
													control={
														<i
															className="icon-ico_cancelar"
															style={{
																color: 'red',
																marginRight: '6px',
																lineHeight: 1.0,
																marginTop: '1px',
															}}
														/>
													}
													classes={{
														label: classes.label,
													}}
													label={<strong>Remover todos</strong>}
												/>
											)}
											<TagsInput
												value={values && values.items && values.items.map(x => x.label)}
												onChange={handleRemove}
												tagProps={{
													className: disabledForm ? 'react-tagsinput-tag' : 'react-tagsinput-tag primary',
												}}
												disabled={disabledForm}
												renderLayout={tagComponents => [
													<Grid
														item
														xs={12}
														style={{
															padding: '0 !important',
														}}
													>
														<>{tagComponents}</>
													</Grid>,
												]}
											/>
										</GridItem>
									</GridContainer>
								</GridItem>
								{selectItemsProducts.length < recordCount && (
									<GridItem
										xs={6}
										style={{
											backgroundColor: themeColor && themeColor.structure && themeColor.structure.backgroundColor,
											color: '#fff',
											margin: '20px 10px -10px 10px',
											borderRadius: '8px',
											zIndex: '1',
											lineHeight: '1.1',
										}}
									>
										<div
											style={{
												width: '15px',
												height: '15px',

												backgroundColor: themeColor && themeColor.structure && themeColor.structure.backgroundColor,
												position: 'relative',
												transform: 'rotate(45deg)',
												top: '-7px',
												marginLeft: '50px',
												zIndex: '-1',
											}}
										></div>
										<div style={{ margin: '-7px 0 5px 0 ' }}>
											Exibindo
											{selectItemsProducts.length} itens de {recordCount}. <br />
											Caso não encontre o item desejado, refaça sua pesquisa.
										</div>
									</GridItem>
								)}
							</GridContainer>
							{values.productType === '2' && <ProductTypeMessage themeColor={themeColor}>{t('PRODUCTS_TYPES_MESSAGE')}</ProductTypeMessage>}
							{values.productType === '1' && <ProductTypeMessage themeColor={themeColor}>{t('CATEGORY_TYPES_MESSAGE')}</ProductTypeMessage>}
							{values.productType === '3' && <ProductTypeMessage themeColor={themeColor}>{t('BRAND_TYPES_MESSAGE')}</ProductTypeMessage>}
							{values.productType === '5' && <ProductTypeMessage themeColor={themeColor}>{t('FAMILY_TYPES_MESSAGE')}</ProductTypeMessage>}
							{values.productType === '6' && <ProductTypeMessage themeColor={themeColor}>{t('MANUFACTURER_TYPES_MESSAGE')}</ProductTypeMessage>}
						</GridItem>
					</GridContainer>
				)}
				{values.campaignType === '4' && values.productType === '' && (
					<ProductTypeMessage themeColor={themeColor}>{t('PRODUCTS_TYPES_MESSAGE')}</ProductTypeMessage>
				)}

				<GridContainer style={{ marginBottom: '80px' }}>
					<GridItem xs={12} sm={12}>
						<GridContainer style={{ padding: '0' }}>
							<GridItem xs={12}>
								<FormLabel
									disabled={disabledForm}
									style={{
										color:
											themeColor &&
											themeColor.titles &&
											themeColor.titles.secondary &&
											themeColor.titles.secondary.textColor &&
											themeColor.titles.secondary.textColor,
									}}
									className={
										styles.LabelHorizontal + ' ' + (touched.statusOptionsQtyLimit && Boolean(errors.statusOptionsQtyLimit) ? styles.Error : '')
									}
								>
									{t('FIELD_CAMPAIGN_QUANTITY_LIMIT')}
								</FormLabel>
							</GridItem>
							<GridItem xs={12} sm={12} md={1} style={{ marginTop: '10px' }}>
								<FormControlLabel
									style={{ paddingLeft: '5px' }}
									control={
										<Switch
											name="qtyLimit"
											id="qtyLimit"
											disabled={disabledForm}
											checked={values.qtyLimit}
											onChange={change.bind(null, 'qtyLimit')}
											value={values.qtyLimit}
											classes={{
												switchBase: classes.switchBase,
												checked: classes.switchChecked,
												thumb: classes.switchIcon,
												// thumbChecked: classes.switchIconThumb,
												track: classes.switchBar,
												disabled: classes.disabled,
											}}
										/>
									}
									classes={{
										label: classes.label + ' ' + (disabledForm ? styles.DisabledCursor : ''),
									}}
									label={values.qtyLimit ? 'Sim' : 'Não'}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={3} style={{ marginLeft: '10px' }}>
								{values.qtyLimit.toString() === 'true' && (
									<CssTextField
										error={touched.units && Boolean(errors.units)}
										helperText={touched.units && Boolean(errors.units) ? 'Unidades obrigatório' : ''}
										disabled={disabledForm}
										className={styles.TextField}
										fullWidth
										label={<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_UNITS" />}
										id="units"
										name="units"
										value={values.units}
										onChange={change.bind(null, 'units')}
										variant="outlined"
										margin="dense"
										autoComplete="off"
										InputProps={{
											maxLength: 9,
											inputComponent: NumberFormatCustom,
										}}
										InputLabelProps={{
											classes: {
												disabled: values.partnerId !== '' ? classes.labelTexRF : '',
											},
										}}
										style={{
											backgroundColor: '#fff',
											maxWidth: '182px',
										}}
									/>
								)}
							</GridItem>
						</GridContainer>
					</GridItem>
					<GridItem xs={12} sm={12}>
						<GridContainer style={{ padding: '0' }}>
							<GridItem xs={12}>
								<FormLabel
									disabled={disabledForm}
									className={styles.LabelHorizontal}
									style={{
										color:
											themeColor &&
											themeColor.titles &&
											themeColor.titles.secondary &&
											themeColor.titles.secondary.textColor &&
											themeColor.titles.secondary.textColor,
									}}
								>
									<CustomRequiredField disabled={disabledForm} value="FIELD_CAMPAIGN_SCORING_TYPE" />
								</FormLabel>
							</GridItem>
							<GridItem xs={12} sm={12} md={4} style={{ marginBottom: '15px' }}>
								<MuiThemeProvider theme={theme}>
									<Autocomplete
										styles={customSelectStyles}
										id="combo-box-demo"
										size="small"
										options={selectItemsScoringType}
										getOptionLabel={option => (option.label ? option.label : '')}
										getOptionSelected={(option, value) => value === undefined || value === '' || option.value === value.value}
										disableClearable={true}
										onChange={(event, value) => getScoringType(value)}
										isDisabled={disabledForm}
										value={getSelectItemValue(selectItemsScoringType, values.scoringType)}
										PopperComponent={CustomPopper}
										renderInput={params => (
											<div ref={params.InputProps.ref}>
												<CssTextField
													style={{
														backgroundColor: '#ffffff',
														width: '100%',
													}}
													{...params}
													noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
													error={touched.scoringType && Boolean(errors.scoringType)}
													helperText={touched.scoringType && Boolean(errors.scoringType) ? '	Comportamento da bonificação obrigatório' : ''}
													label={t('FIELD_SELECT')}
													variant="outlined"
													size="small"
												/>
											</div>
										)}
									/>
								</MuiThemeProvider>
							</GridItem>
							<GridItem xs={12} sm={12} md={3} style={{ marginTop: '-9px' }}>
								<CssTextField
									error={touched.scoringValue && Boolean(errors.scoringValue)}
									helperText={touched.scoringValue && Boolean(errors.scoringValue) ? 'Valor obrigatório' : ''}
									disabled={disabledForm || !scoringType}
									className={styles.TextField}
									fullWidth
									autoComplete="off"
									label={<CustomRequiredField disabled={disabledForm} value="FIELD_VALUE" />}
									id="scoringValue"
									name="scoringValue"
									value={values.scoringValue}
									onChange={change.bind(null, 'number|scoringValue')}
									variant="outlined"
									margin="dense"
									rows={scoringType === '1' ? 999999 : 100}
									InputProps={{
										inputComponent: scoringType === '1' ? NumberFormatCustom : DoubleFormatCustom,
										startAdornment:
											scoringType === '1' && localStorage.getItem('programCurrencySymbol') ? (
												<div style={{ marginRight: '4px' }}>{localStorage.getItem('programCurrencySymbol')}</div>
											) : (
												''
											),
										endAdornment: scoringType === '2' && '%',
									}}
									InputLabelProps={{
										classes: {
											disabled: values.partnerId !== '' ? classes.labelTexRF : '',
										},
									}}
									style={{ backgroundColor: '#fff' }}
								/>
							</GridItem>
						</GridContainer>
					</GridItem>
				</GridContainer>
			</form>
			<Dialog
				fullWidth={true}
				maxWidth="md"
				classes={{
					root: classes.center + ' ' + classes.modalRoot,
					paper: classes.modal,
				}}
				open={values.open}
				TransitionComponent={Transition}
				keepMounted
				// onClose={ToggleDialog}
				aria-labelledby="classic-modal-slide-title"
				aria-describedby="classic-modal-slide-description"
			>
				<DialogContent>{/* <Sponsors onClose={ToggleDialog} /> */}</DialogContent>
			</Dialog>
		</Container>
	)
}

class CampaignForm extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			modalId: '',
			loading: false,
			open: false,
			selectItemsProducts: [],
			recordCount: 0,
			selectItemsScoringType: [],
			selectItemsCampaignType: [],
			selectItemsProductType: [],
			selectedProduct: '',
			disabledForm: true,
			editMode: false,
			id: '',
			campaignMode: 1,
			quantityUnits: '',
			partnerId: '',
			userGroups: [],
			name: '',
			investment: '0,00',
			startTerm: '',
			endTerm: '',
			items: [],
			openModal: false,
			selectItemsSponsors: [],
			partnerBranchs: [],
			volume: '',
			campaignType: '',
			sponsorId: '',
			productType: '',
			sponsor: '',
			status: '',
			scoringType: '',
			scoringValue: '',
			qtyLimit: false,
			selectedSponsor: '',
			searchField: '',
			units: '',
			listSponsors: [],
			sponsorKey: Math.random(),
			themeColor: getThemeColorFromLocalStorage(),
		}
		moment.locale(localStorage.getItem('i18nextLng'))

		this.getProductByBranches = _.debounce(this.getProductByBranches, 1000)
		this.getCategoriesOptions = _.debounce(this.getCategoriesOptions, 1000)
		this.getBrandsOptions = _.debounce(this.getBrandsOptions, 1000)
		this.getFamilyOptions = _.debounce(this.getFamilyOptions, 1000)
		this.getManufacturerOptions = _.debounce(this.getManufacturerOptions, 1000)
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		this.props.ShowLoader(<Loader />)
		const id = this.props.match && this.props.match.params.id

		//
		let _new = _.includes(this.props.match.path, 'new')
		let _edit = _.includes(this.props.match.path, 'edit')
		let _view = _.includes(this.props.match.path, 'view')
		if (_new) {
			this.setState({ editMode: _new, disabledForm: !_new }, () => this.props.HideLoader())
		} else if (_edit) {
			this.setState({ editMode: _edit, disabledForm: !_edit })
		} else if (_view) {
			this.setState({ editMode: !_view, disabledForm: _view })
		}
		//

		this.setState({ modalId: id })

		id && this.props.GetCampaignDataById(id)

		if (this.props.userData.id) {
			var array = []
			array.push({ value: '*', label: '>> Selecionar Todos' })
			this.props && this.props.userData && this.props.userData.branches.filter(b => b.active).map(x => array.push({ value: x.id, label: x.name }))

			this.setState({
				partnerId: this.props.userData.id,
				selectItemsBranches: array,
			})
		}
		this.props.GetCampaignTypeListData()
		this.props.GetProductTypeListData()
		this.props.GetScoringTypeListData()

		this.listSponsorOptions()
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.campaignData !== this.props.campaignData) {
			this.setState(
				{
					...this.props.campaignData,
					items: this.props.campaignData
						? this.props.campaignData.items.map(x => ({
								id: x.key,
								label: x.value,
						  }))
						: [],
					sponsor: this.props.campaignData && this.props.campaignData.sponsor ? this.props.campaignData.sponsor.name : '',
					partnerBranchs: this.props.campaignData.partnerBranchs.map(x => ({ value: x.id, label: x.name })),
				},
				() => this.props.HideLoader(),
			)
		}

		if (prevProps.userData !== this.props.userData) {
			var array = []
			array.push({ value: '*', label: '>> Selecionar Todos' })
			this.props.userData.branches.filter(b => b.active).map(x => array.push({ value: x.id, label: x.name }))
			this.setState({
				partnerId: this.props.userData.id,
				selectItemsBranches: array,
			})
		}

		if (prevProps.lsProducts !== this.props.lsProducts) {
			if (this.props.lsProducts) {
				let selectItemsProducts = this.props.lsProducts.results.map(product => {
					return { id: product.id, label: product.name }
				})
				this.setState({ selectItemsProducts, ...this.props.lsProducts }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsProducts: [], ...this.props.lsProducts }, () => this.setState({ loading: false }))
			}
		}

		if (prevProps.lsCategories !== this.props.lsCategories) {
			if (this.props.lsCategories.results) {
				let selectItemsProducts = this.props.lsCategories.results.map(product => {
					return { id: product.key, label: product.value }
				})
				this.setState({ selectItemsProducts, ...this.props.lsCategories }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsProducts: [], ...this.props.lsCategories }, () => this.setState({ loading: false }))
			}
		}

		if (prevProps.lsFamily !== this.props.lsFamily) {
			if (this.props.lsFamily.results) {
				let selectItemsProducts = this.props.lsFamily.results.map(product => {
					return { id: product.key, label: product.value }
				})
				this.setState({ selectItemsProducts, ...this.props.lsFamily }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsProducts: [], ...this.props.lsFamily }, () => this.setState({ loading: false }))
			}
		}
		if (prevProps.lsManufacturers !== this.props.lsManufacturers) {
			if (this.props.lsManufacturers.results) {
				let selectItemsProducts = this.props.lsManufacturers.results.map(product => {
					return { id: product.key, label: product.value }
				})
				this.setState({ selectItemsProducts, ...this.props.lsManufacturers }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsProducts: [], ...this.props.lsManufacturers }, () => this.setState({ loading: false }))
			}
		}

		if (prevProps.lsBrands !== this.props.lsBrands) {
			if (this.props.lsBrands.results) {
				let selectItemsProducts = this.props.lsBrands.results.map(product => {
					return {
						id: product.key,
						label: product.value,
						checked: false,
					}
				})
				this.setState({ selectItemsProducts, ...this.props.lsBrands }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsProducts: [], ...this.props.lsBrands }, () => this.setState({ loading: false }))
			}
		}

		if (prevProps.selectSponsors !== this.props.selectSponsors) {
			if (this.props.selectSponsors) {
				let selectedSponsors = this.props.selectSponsors.results.map(sponsor => {
					return { id: sponsor.key, label: sponsor.value }
				})
				this.setState({ selectItemsSponsors: selectedSponsors }, () => this.setState({ loading: false }))
			} else {
				this.setState({ selectItemsSponsors: [], ...this.props.selectSponsors }, () => this.setState({ loading: false }))
			}
		}

		if (prevProps.postSponsorsData !== this.props.postSponsorsData) {
			this.setState({ sponsorKey: Math.random() })
		}

		if (prevProps.campaignTypeList !== this.props.campaignTypeList) {
			this.setState({
				selectItemsCampaignType: this.setOptionsState(this.props.campaignTypeList),
			})
		}

		if (prevProps.productTypeList !== this.props.productTypeList) {
			var selectItems = [{ value: '', label: 'Nenhum' }]
			this.setOptionsState(this.props.productTypeList).map(x => selectItems.push(x))
			this.setState({ selectItemsProductType: selectItems })
		}

		if (prevProps.scoringTypeList !== this.props.scoringTypeList) {
			this.setState({
				selectItemsScoringType: this.setOptionsState(this.props.scoringTypeList),
			})
		}

		if (prevState.productType !== this.state.productType) {
			switch (this.state.productType.toString()) {
				case '1':
					this.getCategoriesOptions('')
					break
				case '2':
					this.getProductByBranches('')
					break
				case '3':
					this.getBrandsOptions('')
					break
				case '5':
					this.getFamilyOptions('')
					break
				case '6':
					this.getManufacturerOptions('')
					break
				default:
					break
			}
		}

		if (prevProps.postCampaign !== this.props.postCampaign) {
			if (this.props.postCampaign.success) {
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={''}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.history.push('/campaigns/generation')
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.t('FIELD_ALERT_SUCCESS')}
					</Alert>,
				)
				setTimeout(() => {
					this.props.HideAlert()
					this.props.history.push(`/campaigns/generation`)
				}, 3000)
			} else {
				this.setState({ editMode: true, disabledForm: false })
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={'Erro'}
						onConfirm={() => {
							this.props.HideAlert()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.postCampaign.errors && this.props.postCampaign.errors.map(error => [this.props.t(error.message)])}
					</Alert>,
				)
			}
		}
		if (prevProps.putCampaign !== this.props.putCampaign) {
			if (this.props.putCampaign.success) {
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={''}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.history.goBack()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.t('FIELD_ALERT_SUCCESS')}
					</Alert>,
				)
				setTimeout(() => {
					this.props.HideAlert()
					this.props.history.goBack()
				}, 3000)
			} else {
				this.setState({ editMode: true, disabledForm: false })
				this.props.HideLoader()
				this.props.SweetAlert(
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={'Erro'}
						onConfirm={() => {
							this.props.HideAlert()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.putCampaign.errors && this.props.putCampaign.errors.map(error => [this.props.t(error.message)])}
					</Alert>,
				)
			}
		}

		if (prevProps.deactivateCampaign !== this.props.deactivateCampaign) {
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.history.goBack()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_SUCCESS')}
				</Alert>,
			)
			setTimeout(() => {
				this.props.HideAlert()
				this.props.history.goBack()
			}, 3000)
		}

		if (prevProps.campaignFailed !== this.props.campaignFailed) {
			this.setState({ editMode: true, disabledForm: false })
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Erro'}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}
	}

	handleCheckboxClick = i => {
		const { selectItemsProducts } = this.state
		var item = selectItemsProducts.find(x => x.id === i.id)
		item.checked = !item.checked
		this.setState({ selectItemsProducts })
	}

	handleSelectedItems = () => {
		const { selectItemsProducts } = this.state
		var item = selectItemsProducts.forEach(x => (x.checked = false))
		this.setState({ selectItemsProducts })
	}

	setOptionsState = list => {
		let options = []
		list.map(item => {
			options.push({ value: item.key, label: this.props.t(item.value) })
		})
		return options
	}

	getSponsorsOptions = (inputValue, callback) => {
		return this.props.GetSponsorsData(inputValue, this.state.partnerId, 30).then(resp => {
			if (resp && resp.results) {
				return callback(
					resp.results.map(sponsor => {
						return { value: sponsor.id, label: sponsor.name }
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	HandleSponsorChange = newValue => {
		this.setState({
			searchSponsor: newValue.value,
			selectedSponsor: newValue,
		})
	}

	getProductByBranches = (searchField, branches) => {
		let _name = ''
		if (!_.isEmpty(searchField)) {
			_name = 'name=' + searchField
		}
		this.setState({ loading: true })
		this.props.GetProductByBranches(branches && branches.length === 0 ? null : branches, _name, 10, 1)
	}
	getCategoriesOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetCategory(' ', searchField.length > 0 ? searchField : ' ', 1)
	}
	getBrandsOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetBrand(' ', searchField.length > 0 ? searchField : ' ', 1)
	}
	getFamilyOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetFamily(' ', searchField.length > 0 ? searchField : ' ', 1)
	}
	getManufacturerOptions = searchField => {
		this.setState({ loading: true })
		this.props.GetManufacturer(' ', searchField.length > 0 ? searchField : ' ', 1)
	}
	clearsOptions = () => this.setState({ selectItemsProducts: [] })

	getUserGroupIdList = userGroup => {
		return userGroup.map(item => _.toInteger(item.groupId))
	}

	handleDeactivate = () => {
		this.props.SweetAlert(
			<Alert
				warning
				style={{ display: 'block', marginTop: '-100px' }}
				title={''}
				onConfirm={() => this.deactivateCampaign()}
				onCancel={() => this.props.HideAlert()}
				confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
				cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
				confirmBtnText={this.props.t('BUTTON_YES')}
				cancelBtnText={this.props.t('BUTTON_NO')}
				showCancel
			>
				{this.props.t('ALERT_CAMPAIGN_DEACTIVATE')}
			</Alert>,
		)
	}

	deactivateCampaign = () => {
		const id = this.props.match && this.props.match.params.id
		if (id) {
			this.props.DeactivateCampaign(id)
		}
	}
	handleSave = values => {
		this.props.ShowLoader(<Loader />)
		values.status = 1
		values.items = values.items.map(item => item.id)
		if (values.investment.length > 10) {
			return false
		}
		if (!this.state.id) {
			values.partnerId = this.props.userData.id
			this.props.PostCampaignData(values)
		} else {
			this.props.PutCampaignData(this.state.id, values)
		}
	}

	CancelForm() {
		this.setState({ openModal: false, editMode: false, disabledForm: true })
		this.props.HideLoader()
		this.props.HideAlert()
		this.props.history.goBack()
	}

	handleBack = () => {
		this.props.history.goBack()
	}

	handleCancel = () => {
		if (this.formik.current && _.isEmpty(this.formik.current.state.touched)) {
			this.CancelForm()
		} else {
			this.setState({ openModal: true })
		}
	}

	getSponsorOptions = (value, callback) => {
		const inputValue = value.trim().toLowerCase()
		return this.props.ListSponsorByCompanyName(inputValue, inputValue ? '' : 30).then(() => {
			if (this.props.selectSponsors && this.props.selectSponsors.results) {
				return callback(
					this.props.selectSponsors.results.map(partner => {
						return {
							value: partner.key,
							label: partner.value,
						}
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	listSponsorOptions() {
		let result = []
		this.props.ListSponsorByCompanyName('').then(() => {
			if (this.props.selectSponsors && this.props.selectSponsors.results) {
				this.props.selectSponsors.results.map(partner => {
					result.push({
						value: partner.key,
						label: partner.value,
					})
				})
				this.setState({
					listSponsors: result,
				})
			}
		})
	}

	handleNewSponsor = () => {
		this.props.history.push(`/sponsors/new`)
	}

	handleEdit = () => {
		this.props.history.push(`/campaigns/edit/${this.state.id}`)
	}

	loadSponsorOptions = (inputValue, callback) => {
		this.getSponsorOptions(inputValue, callback)
	}

	render() {
		const { classes, t } = this.props
		const {
			open,
			editMode,
			disabledForm,
			recordCount,
			partnerBranchs,
			partnerId,
			searchField,
			name,
			sponsor,
			investment,
			campaignType,
			status,
			sponsorId,
			volume,
			qtyLimit,
			units,
			startTerm,
			endTerm,
			items,
			scoringType,
			scoringValue,
			selectedProduct,
			productType,
			selectItemsScoringType,
			selectItemsCampaignType,
			selectItemsProductType,
			selectedSponsor,
			searchSponsor,
			selectItemsProducts,
			selectItemsBranches,
			selectItemsSponsors,
			campaignMode,
			quantityUnits,
			modalId,
			listSponsors,
			openModal,
		} = this.state
		const values = {
			modalId,
			campaignMode,
			quantityUnits,
			open,
			partnerBranchs,
			partnerId,
			name,
			sponsor,
			searchField,
			investment,
			status,
			volume,
			campaignType,
			qtyLimit,
			units,
			sponsorId,
			startTerm,
			endTerm,
			items,
			selectedSponsor,
			searchSponsor,
			scoringType,
			selectedProduct,
			productType,
			listSponsors,
			scoringValue,
		}

		const getStatus = () => {
			switch (status) {
				case 1:
					return 'Avaliação para aprovação'
				case 2:
					return 'Campanha aprovada'
				default:
					return 'Criador da campanha'
			}
		}

		return (
			<>
				<ModalInfo open={openModal} onConfirm={() => this.CancelForm()} onCancel={() => this.setState({ openModal: false })} />
				<DocumentTitle title={getProgramTitle()} />
				<div style={{ height: '15px' }}></div>
				<Hidden mdDown implementation="css">
					<GridContainer
						style={{
							marginBottom: '20px',
							marginTop: '20px',
						}}
					>
						<GridItem xs={false} md={1}></GridItem>
						<GridItem xs={false} md={3} style={{ marginRight: '28px' }}>
							<img src={status >= 0 ? CriacaoVerde : CriacaoCinza} alt="imagem" />
						</GridItem>
						<GridItem xs={false} md={3} style={{ marginRight: '65px' }}>
							<img src={status >= 1 ? AvaliacaoVerde : status === 0 ? AvaliacaoVermelha : AvaliacaoCinza} alt="AvaliacaoVerde" />
						</GridItem>
						<GridItem xs={false} md={3}>
							<img src={status === 2 ? AprovadaVerde : status === 0 ? ReprovadaVermelha : AprovadaCinza} alt="AvaliacaoVerde" />
						</GridItem>
					</GridContainer>
				</Hidden>
				<Hidden lgUp implementation="css">
					<GridContainer>
						<GridItem xs={12}>
							<CustomInput
								id="Status"
								name="Status"
								labelText="status"
								formControlProps={{
									fullWidth: true,
								}}
								inputProps={{
									disabled: true,
									value: getStatus(),
								}}
							/>
						</GridItem>
					</GridContainer>
				</Hidden>
				<Formik
					ref={this.formik}
					render={props => (
						<Form
							{...props}
							t={t}
							classes={classes}
							recordCount={recordCount}
							selectItemsScoringType={selectItemsScoringType}
							selectItemsCampaignType={selectItemsCampaignType}
							selectItemsProductType={selectItemsProductType}
							selectItemsProducts={selectItemsProducts}
							selectItemsBranches={selectItemsBranches}
							selectItemsSponsors={selectItemsSponsors}
							listSponsors={listSponsors}
							handleCheckboxClick={this.handleCheckboxClick}
							handleSelectedItems={this.handleSelectedItems}
							handleDeactivate={this.handleDeactivate}
							loadSponsorOptions={this.loadSponsorOptions}
							sponsorKey={this.state.sponsorKey}
							getBrandsOptions={this.getBrandsOptions}
							getFamilyOptions={this.getFamilyOptions}
							getManufacturerOptions={this.getManufacturerOptions}
							getSponsorsOptions={this.getSponsorsOptions}
							getCategoriesOptions={this.getCategoriesOptions}
							clearsOptions={this.clearsOptions}
							disabledForm={disabledForm}
							handleCancel={this.handleCancel}
							handleBack={this.handleBack}
							handleNewSponsor={this.handleNewSponsor}
							handleEdit={this.handleEdit}
							// getProductsOptions={this.getProductsOptions}
							getProductByBranches={this.getProductByBranches}
							editMode={editMode}
							idValid={this.state.id !== 0}
							loading={this.state.loading}
						/>
					)}
					validationSchema={validationSchema}
					onSubmit={this.handleSave.bind(this)}
					initialValues={values}
					enableReinitialize={true}
				/>
			</>
		)
	}
}

const mapStateToProps = state => {
	return {
		campaignData: state.campaign.campaignData,
		campaignFailed: state.campaign.campaignFailed,
		postCampaign: state.campaign.postCampaign,
		putCampaign: state.campaign.putCampaign,
		deactivateCampaign: state.campaign.deactivateCampaign,
		campaignTypeList: state.campaign.campaignTypeList,
		productTypeList: state.campaign.productTypeList,
		selectSponsors: state.sponsor.selectSponsors,
		scoringTypeList: state.campaign.scoringTypeList,
		campaignStatusList: state.campaign.campaignStatusList,
		lsProducts: state.product.lsProducts,
		lsSponsorsData: state.sponsor.lsSponsorsData,
		lsCategories: state.product.lsCategories,
		lsFamily: state.product.lsFamily,
		lsManufacturers: state.product.lsManufacturers,
		lsBrands: state.product.lsBrands,
		productFailed: state.product.productFailed,
		categoriesFailed: state.product.categoriesFailed,
		familyFailed: state.product.familyFailed,
		manufactureFailed: state.product.manufacturereFailed,
		userData: state.users.userData,
		postSponsorsData: state.sponsor.postSponsorsData,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetCurrentPartner,
			GetCampaignDataById,
			GetCampaignTypeListData,
			GetProductTypeListData,
			GetScoringTypeListData,
			PostCampaignData,
			GetAllProduct,
			GetProductByBranches,
			PutCampaignData,
			DeactivateCampaign,
			GetSponsorsData,
			ListSponsorByCompanyName,
			GetCategory,
			GetBrand,
			GetFamily,
			GetManufacturer,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(CampaignStyle),
	withStyles(extendedFormsStyle),
	withTranslation(),
)(CampaignForm)
