import axios from 'axios'

export const GetAwardLogs = (
	awardId,
	startDate,
	endDate,
	awardOperator,
	logAction,
	pageSize,
	page,
) => {
	let url = `/Award/GetLogs/${awardId}`

	if (startDate) {
		url += `&startDate=${startDate}`
	}
	if (endDate) {
		url += `&endDate=${endDate}`
	}
	if (awardOperator) {
		url += `&awardOperator=${awardOperator}`
	}
	if (logAction) {
		url += `&logAction=${logAction}`
	}
	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	const res = axios.get(url)

	return res
}

export const PostAwardRefund = async (obj) => {
	const url = `/Award/PostAwardRefund`
	const res = await axios.post(url, obj)

	return res
}

export const GetExcludedEntriesFile = async (id) => {
	const url = `/Award/GetExcludedEntriesFile/${id}`
	const res = await axios.get(url)

	return res
}

export const GetAwardId = async (
	idDetailProcessed,
	pageSize,
	page,
	search,
	showRefunds = true,
) => {
	let url = `/Award/${idDetailProcessed}`

	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}
	if (search) {
		url += `&search=${search}`
	}
	url += `&showRefunds=${showRefunds}`

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	const res = await axios.get(url)

	return res
}

export const ScheduleJob = async (date, id) => {
	const url = `/Jobs/ScheduleJob?Date=${date}&option=CreateAwardProcess&id=${id}`
	const res = await axios.get(url)

	return res
}

export const UpdateStatusAward = async (awardId, statusRequest, body) => {
	var url = `/Award/UpdateStatusAward?awardId=${awardId}&action=${statusRequest}`
	const res = await axios.post(url, body)

	return res
}

export const GetAwards = async (
	description,
	partnerCreditOriginId,
	startDate,
	endDate,
	type,
	pageSize,
	page,
) => {
	var url = '/Award/GetAwards'

	if (description) {
		url += `&description=${description}`
	}
	if (partnerCreditOriginId) {
		url += `&partnerCreditOriginId=${partnerCreditOriginId}`
	}
	if (startDate) {
		url += `&startDate=${startDate}`
	}
	if (endDate) {
		url += `&endDate=${endDate}`
	}
	if (type) {
		url += `&type=${type}`
	}
	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	const res = await axios.get(url)

	return res
}

export const AwardImportFile = async (formFile, StandardValue) => {
	let params = ''
	if (StandardValue) {
		params = '/ImportFile?standardValue=' + StandardValue
	} else {
		params = '/ImportFile'
	}
	const res = await axios.post(`/Award` + params, formFile)

	return res
}

export const PostAward = async (body) => {
	const res = await axios.post(`/Award/PostAward`, body)

	return res
}

export const BankAccountReserveGetAllForPartner = async (partnerId) => {
	const res = await axios.get(
		`/BankAccountReserve/GetAllForPartner?partnerId=${partnerId}`,
	)

	return res
}

export const BankAccountReserveGetAll = async () => {
	const res = await axios.get(
		`/BankAccountReserve/GetAllForPartner`,
	)

	return res
}
