import Axios from 'axios'

export default class PartnerService {
	async getPartnerData(partnerId) {
		const response = await Axios.get(`/Partner/${partnerId}`)
		return response?.data
	}

	async getPartnerMarketingArea() {
		const response = await Axios.get(`/Enums/GetMarketingAreaType`)
		return response?.data
	}

	async getPartnerCountry() {
		const response = await Axios.get(`/Country`)
		return response?.data
	}

	async getPartnerParents(partnerId) {
		const response = await Axios.get(`/Partner/${partnerId}/GetPartnerBranchParents`)

		return response?.data
	}

	async getPartnerUF(countryList) {
		const countryId = countryList.find(country => country.label === 'Brazil')

		const response = await Axios.get(`/State?countryId=${countryId?.value}`)
		return response?.data
	}

	async getPartnerCity(stateID) {
		const response = await Axios.get(`/City?stateId=${stateID}`)
		return response?.data
	}

	async getAdditionalModules() {
		const response = await Axios.get(`/AdditionalModules/GetAllAdditionalModules?activeToPartners=true`)
		return response?.data
	}

	async getPartnerEvents() {
		const response = await Axios.get(`/Partner/GetActiveOrigins`)
		return response?.data
	}

	async getPartnerSegmentations() {
		const response = await Axios.get(`/PartnerSegmentation/GetActiveSegmentations`)
		return response?.data
	}

	async getAddressByZipCode(zipCode) {
		const response = await Axios.get(`/Address/SearchZipCode/${zipCode}`)
		return response?.data
	}

	async getBusinessDivisions(partnerId) {
		const response = await Axios.get(`/PartnerBusinessDivision/GetAll/${partnerId}`)
		return response?.data
	}

	async updateBusinessDivisions({ data }) {
		const response = await Axios.put(`/PartnerBusinessDivision/Put/`, data)
		return response?.data
	}
	async createBusinessDivisions({ data }) {
		const response = await Axios.post(`/PartnerBusinessDivision/Post`, data)
		return response?.data
	}

	async updatePartnerData({ data }) {
		const response = await Axios.put(`/Partner/UpdatePartnerInfo`, data)
		return response?.data
	}

	async deletePartnerData(id) {
		return await Axios.delete(`/Partner/DeletePartnerBranch/${id}`)
	}
}
