/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { ActiveButton } from "./ActiveButton";
import { ConfirmButton } from "./ConfirmButton";
import { CustomizableIconButton } from "./CustomIconButton";
import { InactiveButton } from "./InactiveButton";
import { TextButton } from "./TextButton";
import { ViewButton } from "./ViewButton";
import { CancelButton, CleanButton, CloseButton } from "../Buttons/CancelButton";
import { CustomTextButton } from "./CustomTextButton";
import { DefaultButton } from "./DefaultButton";
import { CustomIcon } from "./CustomIcon";
import { ButtonNotification } from "./ButtonNotification";
export function Button(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var typeVariation = _a.typeVariation, props = __rest(_a, ["typeVariation"]);
    var buttonVariations = {
        ConfirmButton: _jsx(ConfirmButton, __assign({ "data-testid": (_b = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _b !== void 0 ? _b : "confirm-button" }, props)),
        ActiveButton: _jsx(ActiveButton, __assign({ "data-testid": (_c = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _c !== void 0 ? _c : "active-button" }, props)),
        CloseButton: _jsx(CloseButton, __assign({ "data-testid": (_d = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _d !== void 0 ? _d : "close-button" }, props)),
        CancelButton: _jsx(CancelButton, __assign({ "data-testid": (_e = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _e !== void 0 ? _e : "cancel-button" }, props)),
        CleanButton: _jsx(CleanButton, __assign({ "data-testid": (_f = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _f !== void 0 ? _f : "clean-button" }, props)),
        CustomIconButton: _jsx(CustomizableIconButton, __assign({ "data-testid": (_g = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _g !== void 0 ? _g : "custom-icon-button" }, props)),
        InactiveButton: _jsx(InactiveButton, __assign({ "data-testid": (_h = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _h !== void 0 ? _h : "inactive-button" }, props)),
        TextButton: _jsx(TextButton, __assign({ "data-testid": (_j = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _j !== void 0 ? _j : "text-button", themeColor: "", themeHoverColor: "" }, props)),
        ViewButton: _jsx(ViewButton, __assign({ "data-testid": (_k = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _k !== void 0 ? _k : "view-button", themeColor: "" }, props)),
        CustomTextButton: _jsx(CustomTextButton, __assign({ "data-testid": (_l = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _l !== void 0 ? _l : "custom-text-button" }, props)),
        DefaultButton: _jsx(DefaultButton, __assign({ "data-testid": (_m = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _m !== void 0 ? _m : "default-button" }, props)),
        CustomIcon: _jsx(CustomIcon, __assign({ "data-testid": (_o = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _o !== void 0 ? _o : "custom-icon-button" }, props)),
        Notification: _jsx(ButtonNotification, __assign({ "data-testid": (_p = props === null || props === void 0 ? void 0 : props.customTestId) !== null && _p !== void 0 ? _p : "custom-icon-button" }, props)),
    };
    if (typeVariation && buttonVariations[typeVariation]) {
        return buttonVariations[typeVariation];
    }
    return null;
}
