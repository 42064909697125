// Material
import { Grid, Tooltip } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import React, { createRef, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import CustomMaterialTable from '../../../../components/CustomMaterialTable/CustomMaterialTable'
import { useAccounts } from '../../hooks/useAccounts'
import SearchForm from './SearchForm'
import { StatusIcon } from './StatusIcon'
import * as Style from './styles'
import moment from 'moment'
import { GetBankOperations } from '../../../../redux/api/accounts.js'
import { numberFormatText, convertUTCToLocal } from 'utils/utils'

import {envs}  from '../../../../redux/api/windowServerData.js'



const TitleStyled = styled.div`
	h6 {
		line-height: 100%;
		margin: 0;
		text-transform: unset;
	}

	h6:last-of-type {
		font-style: italic;
		font-size: 0.75rem;
		margin-top: 0.25rem;
	}
`

export function Filter({ bankAccountId }) {
	const { t } = useTranslation()
	const tableRef = createRef()

	const { openFailModal, openDebit, openCredit, openResidual, openReserved, valueTypeFilter, refreshTable } = useAccounts()

	const [pageSizeState, setPageSizeState] = useState(10)
	const [recordPageState, setRecordPageState] = useState()

	useEffect(() => {
		tableRef.current.onChangePage({}, 0)
		tableRef.current.onQueryChange({ page: 0, search: '' })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [valueTypeFilter])

	useEffect(() => {
		tableRef.current.onChangePage({}, 0)
		tableRef.current.onQueryChange({ page: 0, search: '' })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refreshTable])

	// Open account details modal
	function handleDetails(data) {
		switch (data.status.value) {
			case 'CREDITED':
				openCredit(data)
				break
			case 'RESERVED':
				openReserved(data)
				break
			case 'DEBITED':
				openDebit(data)
				break
			case 'RESIDUAL':
				openResidual(data)
				break
			case 'FAILED':
				openFailModal()
				break
			default:
				break
		}
	}

	const handleGetBankOperations = useCallback(
		async (pageSize, page) => {
			let startDate = null

			let endDate = null

			if (valueTypeFilter.dateFilterFrom) {
				startDate = moment(valueTypeFilter.dateFilterFrom, 'YYYY-MM-DD').format('YYYY-MM-DD')
			}
			if (valueTypeFilter.dateFilterTo) {
				endDate = moment(valueTypeFilter.dateFilterTo, 'YYYY-MM-DD')
					.add(1, 'days')
					.format('YYYY-MM-DD')
			}

			const res = await GetBankOperations(
				bankAccountId,
				startDate,
				endDate,
				valueTypeFilter.operation && valueTypeFilter.operation.value,
				valueTypeFilter.status && valueTypeFilter.status.value,
				null,
				null,
				pageSize,
				page,
			)
			return res
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[valueTypeFilter],
	)

	return (
		<>
			<Grid container xs={12} sm={12} md={12}>
				<Grid item xs={12} style={{ backgroundColor: '#F6F6F6' }}>
					<SearchForm />
				</Grid>
				<Style.GridContent style={{ width: '100%' }}>
					<CustomMaterialTable
						tableRef={tableRef}
						style={{
							width: '100%',
							borderBottom: '2px solid #C8C8C8',
							borderRadius: '10px 10px 0 0',
							marginBottom: '20px',
						}}
						noToolbar
						options={{
							emptyRowsWhenPaging: false,
							search: false,
							toolBar: false,
							pageSize: recordPageState < 10 ? recordPageState : pageSizeState,
							rowStyle: { backgroundColor: '#fff' },
						}}
						columns={[
							{
								title: 'Data',
								field: 'date',
								width: '37.5%',
								headerStyle: {
									textAlign: 'left',
									fontSize: '15px',
								},
								render: props => (
									<div>
										{moment(convertUTCToLocal(props.createdAt), 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}
										<br />
										{moment(convertUTCToLocal(props.createdAt), 'YYYY-MM-DD hh:mm:ss').format('HH:mm')}
									</div>
								),
							},
							{
								title: 'Operação',
								field: 'operation',
								width: '37.5%',
								cellStyle: {
									textAlign: 'left',
								},
								headerStyle: {
									textAlign: 'left',
									fontSize: '15px',
								},
								render: props => (
									<TitleStyled>
										<h6>{t(props.operation.value)}</h6>
										<h6>{props.description}</h6>
									</TitleStyled>
								),
							},
							{
								title: 'Valor',
								field: 'value',
								width: '5%',
								headerStyle: {
									textAlign: 'left',
									fontSize: '15px',
								},
								render: props => (
									<span
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '7px',
										}}
									>
										<StatusIcon slug={props.status.value} t={t} />
										{numberFormatText(
											props.value ? props.value : '0',
											localStorage.getItem('currencySymbol')
												? `${localStorage.getItem('currencySymbol')}\n`
												: // : envs
												  // 	.REACT_APP_CURRENCY_SYMBOL,
												  envs.REACT_APP_CURRENCY_SYMBOL,
											2,
										)}
									</span>
								),
							},
							{
								title: 'Status',
								field: 'status',
								width: '15%',
								headerStyle: {
									fontSize: '15px',
								},
								render: props => (
									<span
										style={{
											color: props.status.value === 'FAILED' ? '#F05B4F' : 'inherit',
										}}
									>
										{t(props.status.value)}
									</span>
								),
							},
							{
								title: 'Ações',
								field: 'actions',
								width: '2%',
								cellStyle: {
									textAlign: 'end',
									paddingRight: '0px',
								},
								headerStyle: {
									textAlign: 'end',
									fontSize: '15px',
									paddingRight: '4px',
								},
								sorting: false,
								render: props => (
									<>
										<Tooltip title={t('BUTTON_SHOW_DETAILS')}>
											<Style.GridButton
												justIcon
												size="sm"
												title={t('BUTTON_SHOW_DETAILS')}
												color="greenButtonColor"
												disabled={props.status.value === 'RELEASED' ? true : false}
												onClick={() => handleDetails(props)}
											>
												<Search
													style={{
														width: 19,
														height: 19,
														fontSize: '20px',
													}}
												/>
											</Style.GridButton>
										</Tooltip>
									</>
								),
							},
						]}
						data={query =>
							new Promise(resolve => {
								handleGetBankOperations(query.pageSize, query.page + 1, query.search, query.orderBy, query.orderDirection).then(result => {
									if (result) {
										setPageSizeState(result.data.pageSize)
										setRecordPageState(result.data.recordCount)
										if (result.data) {
											resolve({
												data: result.data.results,
												page: result.data.currentPage - 1,
												totalCount: result.data.recordCount,
												pageSize: result.data.pageSize,
											})
										}
									}
								})
							})
						}
					/>
				</Style.GridContent>
			</Grid>
		</>
	)
}
