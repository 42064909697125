import { Box, Modal } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Close, TextStyle } from './styles'
import { useAccounts } from '../../../../hooks/useAccounts'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 460,
	height: 294,
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: 24,
	p: 4,
	padding: '20px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}

export const ModalFail = ({ open }) => {
	const { closeFailModal } = useAccounts()

	return (
		<Modal
			open={open}
			onClose={() => closeFailModal()}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<>
					<div
						style={{
							display: 'flex',
							justifyContent: 'flex-end',
							width: '100%',
						}}
					>
						<Close onClick={() => closeFailModal()}>
							<i className="icon-ico_cancelar"
								style={{ marginRight: '.688rem' }}
							/>
							Fechar
						</Close>
					</div>

					<TextStyle>
						<i className="icon-alerta_modal" />
						<p>FALHOU</p>
						<span>O saldo não foi liberado</span>
					</TextStyle>
				</>
			</Box>
		</Modal>
	)
}
export default ModalFail
