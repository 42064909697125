/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { NegotiationCalendarIcon } from "../Icons";
import { MuiStack } from "../Stack";
import { MuiBox } from "../Box";
import { MuiTypography } from "../Typography";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
dayjs.locale("pt-br");
export function MuiDatePicker(_a) {
    var defaultColor = _a.defaultColor, showLabel = _a.showLabel, disabled = _a.disabled, textLabel = _a.textLabel, formatText = _a.formatText, defaultValue = _a.defaultValue, height = _a.height, width = _a.width, onChange = _a.onChange, background = _a.background, minDate = _a.minDate, maxDate = _a.maxDate, rest = __rest(_a, ["defaultColor", "showLabel", "disabled", "textLabel", "formatText", "defaultValue", "height", "width", "onChange", "background", "minDate", "maxDate"]);
    var color = defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C";
    function CustomIcon() {
        return _jsx(NegotiationCalendarIcon, { size: 20, color: disabled ? "#989898" : color });
    }
    return (_jsxs(MuiStack, __assign({ sx: {
            ".MuiTypography-body2": {
                height: "25px!important",
            },
            ".MuiPickersDay-root.Mui-selected.MuiPickersDay-dayWithMargin": {
                backgroundColor: "".concat(color, "!important"),
            },
            ".MuiInputBase-formControl.MuiInputBase-adornedEnd input": {
                padding: "8px 15px",
            },
            "& label.Mui-focused": {
                color: color !== null && color !== void 0 ? color : null,
            },
            "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                    borderColor: color,
                },
            },
            "& .MuiInputBase-root.Mui-focused, & .MuiOutlinedInput-root.Mui-focused .MuiInputAdornment-root": {
                borderColor: color,
            },
            "& .MuiInput-underline.Mui-focused:after": {
                borderBottomColor: color,
            },
            "& .MuiInputBase-root": {
                maxWidth: width !== null && width !== void 0 ? width : "100%",
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #BEC1CC!important",
                },
            },
            height: "85px",
            padding: "0px!important",
            backgroundColor: background,
        } }, { children: [showLabel && (_jsx(MuiBox, __assign({ height: "25px" }, { children: _jsx(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: "#989898", style: {
                        height: "25px",
                    } }, { children: textLabel !== null && textLabel !== void 0 ? textLabel : " " })) }))), _jsx(LocalizationProvider, __assign({ dateAdapter: AdapterDayjs, adapterLocale: "pt" }, { children: _jsx(DemoContainer, __assign({ components: ["DatePicker"] }, { children: _jsx(DatePicker, __assign({ slots: { openPickerIcon: CustomIcon }, defaultValue: defaultValue ? dayjs(defaultValue) : null, disabled: disabled, format: "DD/MM/YYYY", onChange: onChange, minDate: minDate ? dayjs(minDate) : null, maxDate: maxDate ? dayjs(maxDate) : null, value: defaultValue ? dayjs(defaultValue) : null, autoComplete: "off", slotProps: {
                            textField: {
                                fullWidth: true,
                                autoComplete: "bday",
                            },
                            field: { autoComplete: "bday" },
                            day: {
                                sx: {
                                    "&.MuiPickersDay-root.Mui-selected": {
                                        backgroundColor: defaultColor,
                                    },
                                },
                            },
                        }, sx: __assign({ backgroundColor: disabled ? "#F2F2F2" : "#FFFFFF", overflow: "hidden", maxHeight: height, width: width }, rest === null || rest === void 0 ? void 0 : rest.sx) }, rest)) })) }))] })));
}
