import React from 'react'
import { useTranslation } from 'react-i18next'
import { MuiModal, MuiTypography } from '../../../../../components/storybook'

export function NotificateNegotiationModal({ open, handleClose, onConfirm }) {
	const { t } = useTranslation()

	return (
		<MuiModal
			open={open}
			alertIcon
			description={t('Notificar negociação')}
			customDescription={
				<>
					<MuiTypography>
						{t('Gostaria de enviar uma notificação no e-mail cadastrado do participante, com os detalhes da negociação?')}
					</MuiTypography>
				</>
			}
			onConfirm={onConfirm}
			onClose={handleClose}
			color="#F18932"
			sx={{ lineHeight: 2.5 }}
		/>
	)
}
