import React, { useState, useEffect, createRef } from 'react'
import GridItem from 'components/Grid/GridItem'
import ModalMaterialTable from 'components/ModalMaterialTable/ModalMaterialTable'
import { useDispatch } from 'react-redux'
import { useTranslation, withTranslation } from 'react-i18next'
import ViewModal from '../ViewModal'

import { Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { HideLoader } from '../../redux/actions'
import axios from 'axios'
import PropTypes from 'prop-types'
import * as _ from 'lodash'
import { Container, FilialCount, UnderLineTable } from './styles'

import styled from 'styled-components/macro'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const initialPagination = {
	currentPage: 0,
	pageCount: 1,
	pageSize: 10,
	recordCount: 0,
	filter: {
		filter: '',
	},
}

function SelectFilials({ campaignId, open, closeByClick, selectedBranchs, keepBranchs, subTitle }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [loading, setLoading] = useState(false)
	const [pagination, setPagination] = useState(initialPagination)
	const [branches, setBranches] = useState([])
	const tableRef = createRef()
	const [filialCount, setFilialCount] = useState(0)
	const [checkAll, setCheckAll] = useState(false)

	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		dispatch(HideLoader())
		if (open === true) {
			requestApi(pagination)
		}
	}, [pagination, campaignId, open])

	useEffect(() => {
		let count = 0
		branches.forEach(item => {
			if (item.check === true) {
				count = count + 1
			}
		})
		setFilialCount(count)
	}, [branches])

	useEffect(() => {}, [filialCount])

	async function requestApi(pagination) {
		try {
			setLoading(true)
			if (campaignId) {
				let filter = pagination.filter
				const response = await axios.get(
					`/Campaign/GetBranchesByCampaignId/${campaignId}?${filter ? new URLSearchParams(filter).toString() : ''}&pageSize=${
						pagination.pageSize
					}&page=${pagination.currentPage + 1}`,
				)
				let list = response.data.results
				list.forEach(item => {
					item.check = true
				})
				setBranches(list)
			}
			if (_.isEmpty(branches) && !campaignId) {
				let filter = pagination.filter
				const response = await axios.get(
					`/Campaign/GetBranchesByPartner?${new URLSearchParams(filter).toString()}&pageSize=${pagination.pageSize}&page=${pagination.currentPage +
						1}`,
				)
				let list = response.data.results
				list.forEach(item => {
					item.check = false
				})
				setBranches(list)
			}
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function formatCnpjCpf(value) {
		const cnpjCpf = value?.replace(/\D/g, '')

		if (cnpjCpf?.length === 11) {
			return cnpjCpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
		}

		return cnpjCpf?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
	}

	function selectAllBranches() {
		if (checkAll === false) {
			setCheckAll(!checkAll)
			let list = branches
			let count = 0
			list.forEach(item => {
				if (item.check === checkAll) {
					item.check = true
				}
				count = count + 1
			})
			setFilialCount(count)
			setBranches(list)
		}

		if (checkAll === true) {
			setCheckAll(!checkAll)
			let list = branches
			list.forEach(item => {
				if (item.check === checkAll) {
					item.check = false
				}
			})
			setFilialCount(0)
			setBranches(list)
		}
	}

	function checkboxChange(props) {
		let list = branches
		list.forEach(item => {
			if (item.cnpj === props.cnpj) {
				item.check = !item.check
				if (item.check === true) {
					setFilialCount(filialCount + 1)
				} else {
					setFilialCount(filialCount - 1)
				}
			}
		})
		setBranches(list)
	}

	function returnBranches(branches) {
		closeByClick()
		selectedBranchs(branches)
	}

	return (
		<ViewModal
			open={open}
			closeByClick={() => returnBranches(branches)}
			maxWidth="900px"
			headerTitle={campaignId ? 'MODAL_TITLE_SELECTED_UNITS' : 'MODAL_TITLE_SELECT_UNITS'}
			headerIcon="icon-filiais"
			subTitle={subTitle}
		>
			<Container themeColor={themeColor}>
				<div style={{ marginLeft: '44px' }}>
					<p
						style={{
							padding: 0,
							position: 'relative',
							top: '-20px',
						}}
					></p>
				</div>
				<GridItem xs={12} sm={12} md={12}>
					{campaignId ? (
						<Grid>
							<ModalMaterialTable
								tableRef={tableRef}
								options={{
									pageSize: 10,
									pageSizeOptions: [10, 20, 30, 50, 100],
									search: true,
									searchFieldStyle: {
										marginBottom: '30px',
									},
									overflowY: 'visible',
									emptyRowsWhenPaging: false,
								}}
								localization={{
									pagination: {
										firstTooltip: '',
										lastTooltip: '',
										nextTooltip: '',
										previousTooltip: '',
									},
									toolbar: {
										searchTooltip: '',
										searchPlaceholder: 'Buscar',
									},
								}}
								columns={[
									{
										title: 'Unidade',
										field: 'name',
										cellStyle: {
											minWidth: '500px',
											height: '50px',
											textAlign: 'left',
										},
										headerStyle: {
											minWidth: '500px',
											textAlign: 'left',
											height: '50px',
											color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
										},
									},
									{
										title: 'CNPJ',
										field: 'cnpj',
										cellStyle: {
											minWidth: '250px',
											textAlign: 'left',
											height: '50px',
										},
										headerStyle: {
											minWidth: '250px',
											textAlign: 'left',
											height: '50px',
											color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
										},
										render: props => formatCnpjCpf(props.cnpj),
									},
								]}
								data={branches}
								isLoading={loading}
							/>
						</Grid>
					) : (
						<ModalMaterialTable
							tableRef={tableRef}
							options={{
								pageSize: 10,
								pageSizeOptions: [10, 20, 30, 50, 100],
								search: true,
								searchFieldStyle: {
									marginBottom: '30px',
								},
								maxBodyHeight: 387,
								overflowY: 'visible',
								emptyRowsWhenPaging: false,
							}}
							localization={{
								pagination: {
									firstTooltip: '',
									lastTooltip: '',
									nextTooltip: '',
									previousTooltip: '',
								},
								toolbar: {
									searchTooltip: '',
									searchPlaceholder: 'Buscar',
								},
							}}
							columns={[
								{
									title: (
										<FormControlLabel
											style={{ width: '30px' }}
											control={<Checkbox checked={checkAll} onClick={() => selectAllBranches()} name="checkedB" color="primary" />}
										/>
									),
									field: 'status',
									cellStyle: {
										textAlign: 'left',
										maxWidth: '30px',
										minWidth: '30px',
										width: '30px !important',
									},
									headerStyle: {
										textAlign: 'left',
										maxWidth: '30px',
										minWidth: '30px',
										width: '30px !important',
										color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
									},
									sorting: false,
									render: props => (
										<FormControlLabel
											style={{ width: '30px' }}
											control={<Checkbox checked={props.check} onClick={() => checkboxChange(props)} name="checkedB" color="primary" />}
										/>
									),
								},
								{
									title: 'Unidade',
									field: 'name',
									cellStyle: {
										minWidth: '500px',
										textAlign: 'left',
									},
									headerStyle: {
										minWidth: '500px',
										textAlign: 'left',
										color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
									},
								},
								{
									title: 'CNPJ',
									field: 'cnpj',
									cellStyle: {
										minWidth: '250px',
										textAlign: 'left',
									},
									headerStyle: {
										minWidth: '250px',
										textAlign: 'left',
										color: themeColor && themeColor.titles && themeColor.titles.highlight && themeColor.titles.highlight.textColor,
									},
									render: props => formatCnpjCpf(props.cnpj),
								},
							]}
							data={branches}
							isLoading={loading}
						/>
					)}
					<FilialCount>
						Filiais selecionadas : <b>{filialCount}</b>
					</FilialCount>
					<UnderLineTable />
				</GridItem>
			</Container>
		</ViewModal>
	)
}

SelectFilials.propTypes = {
	selectedBranchs: PropTypes.func,
}

SelectFilials.defaultProps = {
	selectedBranchs: null,
}

export default withTranslation()(SelectFilials)
