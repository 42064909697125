import React from 'react'
import * as S from '../styles'
import { Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'

export const Columns = t => [
	{
		title: t('Produto / Cód.'),
		field: 'name',
		width: '60%',
		cellStyle: {
			width: '60%',
		},
		headerStyle: {
			width: '25%',
			paddingLeft: '25px',
		},
		render: rowData => (
			<div
				style={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '34px',
				}}
			>
				<span style={{ fontSize: '14px' }}>{rowData.name}</span>
				<span style={{ fontSize: '12px' }}>{rowData.sku}</span>
			</div>
		),
	},
	{
		title: t('Agrupador'),
		field: 'grouper',
		width: '40%',
		cellStyle: {
			width: '40%',
			textAlign: 'left !important',
		},
		headerStyle: {
			width: '100%',
			paddingLeft: '25px',
		},
		render: rowData => (
			<div
				style={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '34px',
				}}
			>
				<span style={{ fontSize: '14px' }}>{t(rowData.grouper)}</span>
				<span style={{ fontSize: '12px' }}>{t(rowData.grouperName)}</span>
			</div>
		),
	},
	{
		title: 'Ação',
		field: 'actions',
		cellStyle: {
			width: '80px',
			maxWidth: '80px',
			minWidth: '80px',
		},
		sorting: false,
		render: rowData => (
			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
				}}
			>
				<Tooltip arrow id="tooltip-top" title={'Parametrizar'} placement="top-start">
					<Link to={`/product/parametrization/${rowData.id}`}>
						<S.ButtonAction>
							<i className="icon-i_configuracao" />
						</S.ButtonAction>
					</Link>
				</Tooltip>
			</div>
		),
	},
]

export const buildQueryString = filterValues => {
	const queryParams = Object.entries(filterValues)
		.filter(([key, value]) => value !== '')
		.map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
		.join('&')
	return queryParams
}

export const buildProductQueryString = params => {
	const queryParts = []
	const grouperParamMap = {
		Manufacturer: 'Manufacturer',
		Family: 'Family',
		Brand: 'Brand',
		Category: 'Category',
	}

	if (params.SKU) queryParts.push(`SKU=${encodeURIComponent(params.SKU)}`)
	if (params.Name) queryParts.push(`Name=${encodeURIComponent(params.Name)}`)
	if (params.Grouper) queryParts.push(`Grouper=${encodeURIComponent(params.Grouper)}`)
	if (params.page) queryParts.push(`page=${params.page}`)
	if (params.pageSize) queryParts.push(`pageSize=${params.pageSize}`)

	if (params.Grouper && params.GrouperType) {
		const paramName = grouperParamMap[params.Grouper]
		if (paramName) {
			queryParts.push(`${paramName}=${encodeURIComponent(params.GrouperType)}`)
		}
	}

	return queryParts.join('&')
}
