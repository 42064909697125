import styled from 'styled-components'
import { MenuItem, Paper } from '@material-ui/core'

export const CustomMenu = styled(MenuItem)`
	:hover {
		background-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.navigation &&
		themeColor.navigation.menu &&
		themeColor.navigation.menu.activeColor &&
		themeColor.navigation.menu.activeColor} !important;
		box-shadow: none !important;
	}
`

export const CustomPaper = styled(Paper)`
	width: 14.375rem;
	box-shadow: none !important;
	border: 1px solid #ecebeb !important;

`
