import React, { useEffect, useState } from 'react'
import _ from 'lodash'
import { useLocation, NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { dashRoutes } from '../../pages/Admin/routes.js'

import { GetGeneralRulesData } from 'redux/api/generalRules'
import { ListItemMenu, ListSubItem, ListItemIconMenu, ListItemTextMenu, CustomList } from './styles'
import { useAuth } from '../../utils/authconfig.js'
import { GetAwards } from '../../redux/api/award.js'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { Box, Stack } from '@mui/material'

const useStyles = makeStyles(theme => ({
	root: {
		width: 270,
		maxWidth: 270,
		backgroundColor: theme.palette.background.paper,
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}))

function SideMenu({ t }) {
	const classes = useStyles()
	const [open, setOpen] = useState(false)
	const [id, setId] = useState()
	const location = useLocation()
	const [focuses, setFocuses] = useState('')
	const [newRoutes, setNewRoutes] = useState([])
	const [lengthAward, setLengthAward] = useState(0)
	const [themeColor, setThemeColor] = useState()

	const [iconColor, setIconColor] = useState('#878787')

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])
	const auth = useAuth()
	//
	const handleClick = routeId => {
		if (id) {
			if (id && id === routeId) {
				setId('')
				return setOpen(false)
			}
			setOpen(routeId)
			setId(routeId)
		} else {
			setOpen(routeId)
			setId(routeId)
		}
	}

	const getAllExtracted = async () => {
		try {
			const { data } = await GetAwards(null, null, null, null, 1)
			if (data) {
				setLengthAward(data.pageSize)
				return data.pageSize
			}
		} catch (error) {
			console.log(error)
		}

		return 0
	}

	useEffect(() => {
		getAllExtracted()
	}, [])

	function focus(path) {
		var currentUrl = window.location.pathname

		if (path === currentUrl) {
			return true
		}
	}

	function verifyFocus() {
		var firstPath = window.location.pathname.split('/')[1]
		var currentUrl = window.location.pathname
		newRoutes.forEach(item => {
			if (item.collapse) {
				item.views &&
					item.views.map(view => {
						if (view.path === currentUrl) {
							if (view.focus) {
								setFocuses(view.focus)
								return setOpen(view.id)
							} else {
								focus(view.path)
								return setOpen(view.id)
							}
						} else {
							if (currentUrl.includes(firstPath)) {
								if (firstPath === view.path.split('/')[1]) {
									if (view.focus) {
										setFocuses(view.focus)
										return setOpen(view.id)
									}
								}
							}
						}
					})
			} else {
				if (currentUrl.includes(firstPath)) {
					if (firstPath === item.path.split('/')[1]) {
						if (item.focus) {
							setFocuses(item.id)
							return setOpen(item.id)
						}
					}
				}
			}
		})
	}

	useEffect(() => {
		// const getGeralBasaicDat = async () => {
		;(async () => {
			const response = await GetGeneralRulesData()

			if ((!response.allowDigitalWallet || !response.allowCashback) && dashRoutes) {
				let _routes = dashRoutes.map(item => {
					//retorna todos que nao sao cashback
					if (item.id !== 'cashback') {
						return item
					} else {
						//se for cashback, remove
						return { remove: true }
					}
				})
				_.remove(_routes, ['remove', true])
				setNewRoutes(_routes)
				return
			}

			const allExtracted = await getAllExtracted()
			if (!response.allowAwards && allExtracted <= 0) {
				dashRoutes[5].views.splice(1, 1)
			}
			setNewRoutes(dashRoutes)
		})()
		// getGeralBasicData()
	}, [])

	useEffect(() => {
		setFocuses('')
		verifyFocus()
	}, [location])

	return (
		<CustomList component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
			{newRoutes.map((routes, key) => {
				return routes.sidebar && !routes.collapse ? (
					<div key={key}>
						<NavLink to={routes.path}>
							<ListItemMenu
								themeMenu={themeColor && themeColor.navigation && themeColor.navigation.menu && themeColor.navigation.menu.activeColor}
								theme={focuses === routes.id ? 'true' : focus(routes.path)}
								first={key === 0 ? 'true' : 'false'}
								className={key === 0 && 'fistMenu'}
							>
								<ListItemIconMenu theme={focuses === routes.id ? 'true' : focus(routes.path)}>
									<div id="listMenu">
										<i className={routes.icon} />
									</div>
								</ListItemIconMenu>
								<ListItemTextMenu primary={t(routes.name)} />
							</ListItemMenu>
						</NavLink>
					</div>
				) : routes.sidebar && routes.collapse ? (
					<div key={key}>
						<ListItemMenu
							themeMenu={themeColor && themeColor.navigation && themeColor.navigation.menu && themeColor.navigation.menu.activeColor}
							button
							onClick={() => handleClick(routes.id)}
						>
							<ListItemIconMenu theme={focuses === routes.path ? 'true' : focus(routes.path)}>
								<i className={routes.icon} />
							</ListItemIconMenu>
							<ListItemTextMenu primary={t(routes.name)} />
							{open === routes.id ? <ExpandLess /> : <ExpandMore />}
						</ListItemMenu>
						{routes &&
							routes.views &&
							routes.views.map((subItem, index) => {
								if (subItem.requiresDigitalWallet && !auth) {
									return
								}

								return subItem.sidebar ? (
									<Collapse in={open === subItem.id} timeout="auto" unmountOnExit key={index}>
										<List component="div" disablePadding>
											<NavLink to={subItem.path}>
												<ListSubItem
													themeMenu={themeColor && themeColor.navigation && themeColor.navigation.menu && themeColor.navigation.menu.activeColor}
													theme={focuses === subItem.path ? 'true' : focus(subItem.path)}
													button
													className={classes.nested}
													// component="a"
													// href={subItem.path}
												>
													{/* <ListItemIconMenu theme={focuses === subItem.path ? 'true' : focus(subItem.path)}>
														<i className={subItem.icon} />
													</ListItemIconMenu> */}
													<Box
														sx={{
															width: '100%',
															height: '100%',
															'.iconMenu:hover svg, .iconMenu.active svg': {
																fill: '#fff',
															},
														}}
														onMouseOver={() => {
															if (subItem.svgIcon) {
																setIconColor('#fff')
															}
														}}
														onMouseOut={() => {
															if (subItem.svgIcon) {
																setIconColor('#878787')
															}
														}}
													>
														<Stack direction="row" spacing={0.5}>
															<ListItemIconMenu className={`iconMenu ${focuses === subItem.path ? 'active' : ''}`}>
																{(subItem.svgIcon && subItem.svgIcon(focus(subItem.path) ? '#fff' : iconColor)) ?? <i className={subItem.icon} />}
															</ListItemIconMenu>
															<ListItemTextMenu primary={t(subItem.name)} />
														</Stack>
													</Box>
												</ListSubItem>
											</NavLink>
										</List>
									</Collapse>
								) : (
									''
								)
							})}
					</div>
				) : (
					''
				)
			})}
			<div
				style={{
					borderBottomStyle: 'solid',
					borderWidth: '15px',
					color: '#eee',
					borderRadius: '0 0 10px 10px',

					// -webkit-border-bottom-right-radius: 10px,
				}}
			></div>
		</CustomList>
	)
}

export default withTranslation()(SideMenu)
