import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'

import * as _ from 'lodash'

//material UI
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormLabel from '@material-ui/core/FormLabel'
import { withStyles } from '@material-ui/core/styles'

//components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Section from 'components/Section'

//UTILS
import sweetAlertStyle from 'assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx'
import compose from 'utils/compose'
import styles from './permission.module.scss'

import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

const getThemeColorStructureBackground = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().structure) {
			if (getThemeColorFromLocalStorage().structure.backgroundColor) {
				return getThemeColorFromLocalStorage().structure.backgroundColor
			}
		}
	}
	return '#08993A'
}

const GreenCheckbox = withStyles({
	root: {
		color: getThemeColorStructureBackground(),
		'&$checked': {
			color: getThemeColorStructureBackground(),
		},
	},
	checked: {},
})((props) => <Checkbox color="default" {...props} />)

class Permission extends React.Component {
	render() {
		const { t, values, setFieldValue, setFieldTouched } = this.props

		const handleChangeAdd = (claim, roles) => {
			var group = this.props.values.groupClaims
			group.forEach((values) => {
				if (values.id === claim.id) {
					let exists = values.claim.filter(
						(x) => x.value === roles.value,
					)
					let index = values.claim.findIndex(
						(x) => x.value === roles.value,
					)
					exists[0].removeOrAdd = !roles.removeOrAdd
					values.claim[index] = exists[0]
				}
			})
			setFieldValue('groupClaims', group, true)
			setFieldTouched('groupClaims', true, false)
		}

		return (
			<div style={{ display: 'flex-start' }}>
				<Section title={t('TITLE_ACCESS_PERMISSIONS')} />
				<GridContainer style={{ margin: '0', width: '100%' }}>
					{values.groupClaims.length > 0 && (
						<Fragment>
							{values &&
								values.groupClaims &&
								values.groupClaims.map((claim) => [
									<Fragment key={claim.name}>
										<FormLabel
											style={{ marginLeft: '8px' }}
											className={styles.LabelHorizontal}
										>
											{t(
												'CLAIM_CARD_' +
													_.toUpper(
														claim.name.replace(
															/\./g,
															'_',
														),
													),
											)}
										</FormLabel>

										<GridItem
											xs={12}
											sm={6}
											md={6}
											style={{ marginTop: '2.5%' }}
										>
											<label>
												{' '}
												{t(
													'CLAIM_PATH_' +
														_.toUpper(
															claim.name.replace(
																/\./g,
																'_',
															),
														),
												)}{' '}
											</label>
										</GridItem>
										<GridItem xs={12} key={values.value}>
											<GridContainer>
												{claim &&
													claim.claim &&
													claim.claim.map(
														(values) => [
															<GridItem
																xs={2}
																sm={2}
																md={2}
																key={
																	values.value
																}
															>
																<FormControlLabel
																	style={{
																		marginLeft:
																			'0px',
																	}}
																	control={
																		<GreenCheckbox
																			checked={
																				values.removeOrAdd
																			}
																			onClick={() => {
																				handleChangeAdd(
																					claim,
																					values,
																				)
																			}}
																			value={
																				values.value
																			}
																		/>
																	}
																	label={t(
																		'ROLE_CLAIMS_' +
																			_.toUpper(
																				values.value,
																			),
																	)}
																/>
															</GridItem>,
														],
													)}
											</GridContainer>
										</GridItem>
									</Fragment>,
								])}
						</Fragment>
					)}
					<GridContainer>
						<label
							style={{
								fontSize: '28px',
								textAlign: 'left',
								marginLeft: '15px',
								marginTop: '3.5%',
							}}
						></label>
					</GridContainer>
				</GridContainer>
			</div>
		)
	}
}

export default compose(
	withStyles(sweetAlertStyle),
	withTranslation(),
)(Permission)
