import styled from 'styled-components'
import ListItem from '@material-ui/core/ListItem'
import { ListItemIcon, ListItemText } from '@material-ui/core'

export const ListItemMenu = styled(ListItem)`
	&:hover {
		i {
			color: white;
		}
		span {
			color: white;
		}
	}

	&.MuiListItem-button:hover {
		text-decoration: none;
		background-color: ${props => props.themeMenu};
		color: #fff;
	}

	&.MuiListItem-root {
		background-color: ${props => (props.theme === 'true' || props.theme === true ? props => props.themeMenu : '#eee')};
		margin-bottom: 3px;
		border-top-left-radius: ${props => (props.first === true ? '10px' : '0px')};
		border-top-right-radius: ${props => (props.first === true ? '10px' : '0px')};
		color: ${props => (props.theme === 'true' || props.theme === true ? '#fff' : '#878787')};

		&.MuiListItemText-root {
			color: ${props => (props.theme === 'true' || props.theme === true ? '#fff' : '#878787')};
		}
	}
	&.MuiListItem-root:hover {
		background-color: ${props => props.themeMenu};
	}

	.ListItemTextMenu {
		> span {
			font-size: '15px';
			color: ${props => (props.theme === 'true' || props.theme === true ? '#fff' : '#878787')};
			:hover {
				color: #fff;
			}
		}
	}

	.MuiListItemIcon-root {
		font-size: 20px;
		color: ${props => (props.theme === 'true' || props.theme === true ? '#fff !important' : '#878787')};
		display: inline-flex;
		min-width: 42px;
		flex-shrink: 0;
	}
`

export const ListSubItem = styled(ListItem)`
    &.MuiListItem-root {
        background-color: ${props => (props.theme === 'true' || props.theme === true ? props => props.themeMenu : '#F3F3F3')} ;
        margin-bottom: 3px;
        color: ${props => (props.theme === 'true' || props.theme === true ? '#fff' : '#878787')};
        border-left-style: solid;
        border-width: 5px;
        border-color: ${props => (props.theme === 'true' || props.theme === true ? '#fff' : '#878787')};

		&.MuiListItemText-root {
			color: ${props => (props.theme === 'true' || props.theme === true ? '#fff' : '#878787')};
		}
	}
	&.MuiListItem-root:hover {
		background-color: ${props => props.themeMenu};
	}
	&.MuiListItem-root:focus {
		background-color: ${props => props.themeMenu};
	}

    &.MuiListItem-button:hover {
        text-decoration: none;
        background-color: ${props => props.themeMenu};
        color: #fff;
        border-color: #fff;
    }

    &.makeStyles-nested-213 {
        padding-left: 11px;
    }
    :hover {
        i{
            color: #fff;
        }
    }

	.MuiListItemIcon-root {
		font-size: 20px;
		color: ${props => (props.theme === 'true' || props.theme === true ? '#fff !important' : '#878787')};
		display: inline-flex;
		min-width: 42px;
		flex-shrink: 0;
	}
}
`

export const ListItemIconMenu = styled(ListItemIcon)`
	&.MuiListItemIcon-root {
		font-size: 20px;
		color: #878787;
		display: inline-flex;
		min-width: 42px;
		flex-shrink: 0;
	}
	.iconMenu:hover,
	.iconMenu.active {
		color: #fff;
	}
`

export const ListItemTextMenu = styled(ListItemText)`
	> span {
		:hover {
			color: #fff;
		}
	}
`

export const CustomList = styled.div`
	border-radius: 10px !important;
	box-sizing: border-box !important;
	width: 100% !important;
	.fistMenu {
		border-radius: 10px 10px 0 0 !important;
	}
`
