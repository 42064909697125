import React from 'react';
import moment from 'moment';

import { grayColor } from 'assets/jss/material-dashboard-pro-react'

import { ActionBtn } from './style';

export const OptionsTypeFilter = Object.freeze([
	{
		name: 'Cancelado',
		value: 1,
	},
	{
		name: 'Removido',
		value: 2,
	},
	{
		name: 'Importado',
		value: 3,
	},
	{
		name: 'Lote Processado',
		value: 4,
	},
	{
		name: 'Lote pausado',
		value: 5,
	},
	{
		name: 'Inclusão manual',
		value: 6,
	},
	{
		name: 'Lote interrompido',
		value: 7,
	},
	{
		name: 'Lote reiniciado',
		value: 4,
	},
	{
		name: 'Falha na importação',
		value: 8,
	},
	{
		name: 'Sucesso na importação',
		value: 9,
	},
]);

export const TypeFilter = {
	dateFilter: null,
	dateFilterTo: null,
	awardOperator: '',
	logAction: '',
}

export const defaultValues = {
	dateFilter: null,
	dateFilterTo: null,
	awardOperator: '',
	logAction: '',
}

export const Columns = (GetExcludedEntriesFile, themeColor) => [
	{
		title: 'Data/Hora',
		searchable: true,
		cellStyle: {
			width: '20%',
			maxWidth: '20%',
			minWidth: '20%',
			backgroundColor: '#fff',
		},
		headerStyle: {
			lineHeight: '1.2',
			height: '50px',
			fontSize: '15px',
		},
		render: (props) => (
			<div
				style={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '34px',
				}}
			>
				<p style={{ fontSize: '15px' }}>
					{moment(props.createdAt).format(
						'DD/MM/YYYY',
					)}
				</p>
				<span
					style={{
						fontStyle: 'italic',
						fontSize: '12px',
					}}
				>
					{moment(props.createdAt).format('HH:mm')}
				</span>
			</div>
		),
	},
	{
		title: 'Operador',
		searchable: true,
		cellStyle: {
			textAlign: 'left',
			backgroundColor: '#fff',
			width: '30%',
			maxWidth: '30%',
			minWidth: '30%',
		},
		headerStyle: {
			textAlign: 'left',
			fontSize: '15px',
		},
		render: (props) => props.awardOperator,
	},
	{
		title: 'Log',
		searchable: true,
		cellStyle: {
			textAlign: 'left',
			backgroundColor: '#fff',
			width: '50%',
			maxWidth: '50%',
			minWidth: '50%',
		},
		headerStyle: {
			textAlign: 'left',
			fontSize: '15px',
		},
		render: (props) => {
			return props.message
		},
	},
	{
		title: 'Ação',
		field: 'actions',

		cellStyle: {
			width: '30%',
			maxWidth: '30%',
			minWidth: '30%',
			padding: '0',
			backgroundColor: '#fff',
		},
		headerStyle: {
			padding: '0',
			textAlign: 'center',
		},

		sorting: false,
		render: (props) => (
			<ActionBtn
				themeColor={themeColor}
				disabled={
					props.logType.key !== '3' ? true : false
				}
				style={{
					backgroundColor: `${props.logType.key !== '3' && '#c4c4c4'
						}`,
				}}
				onClick={() => GetExcludedEntriesFile()}
			>
				<i className="icon-downloads" />
			</ActionBtn>
		),
	},
]

export const customSelectStyles = {
	control: (base, state) => ({
		...base,
		input: { height: '25px' },
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}


