import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

export const Container = styled.div`
	.cardTitle {
		color: ${getThemeColorFromLocalStorage()?.titles?.secondary?.textColor};
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		font-size: 1.2em;
		font-weight: 900;
		min-height: auto;
		text-align: left;
		text-decoration: none;
	}

	@media screen and (max-width: 960px) {
		.headerTitle {
			padding: 0px 0px 25px !important;
		}
	}

	@media screen and (min-width: 961px) {
		.headerTitle {
			padding-bottom: 25px !important;
		}
	}

	.CardHeader {
		background-color: ${props =>
			props?.theme?.visual?.cards?.singleColor} !important;
		float: left;
		padding: 3px;
		margin-top: -20px;
		margin-right: 20px;
		border-radius: 8px;
	}
`
