import * as actionTypes from './actionsTypes'
import * as API from '../api/product'

export const GetProduct = (
	sku,
	barcode,
	name,
	category,
	family,
	manufacturer,
	brand,
	typeOfPoints,
	typeOfRedeem,
	active,
	page,
) => {
	return (dispatch) =>
		API.GetProduct(
			sku,
			barcode,
			name,
			category,
			family,
			manufacturer,
			brand,
			typeOfPoints,
			typeOfRedeem,
			active,
			page,
		)
			.then((rest) => {
				dispatch(fetchProduct(rest))
			})
			.catch((err) => {
				//console.log(err);
				dispatch(fetchProductFailed(err))
			})

	function fetchProduct(data) {
		return {
			type: actionTypes.GET_PRODUCT,
			lsProducts: data,
		}
	}

	function fetchProductFailed(error) {
		return {
			type: actionTypes.GET_PRODUCT_FAILED,
			productFailed: error,
		}
	}
}

// GET PRODUCT BY ID
export const GetProductById = (id) => {
	return (dispatch) =>
		API.GetProductById(id)
			.then((rest) => {
				dispatch(success(rest))
				return rest
			})
			.catch((err) => {
				console.error(err)
				dispatch(failed(err))
			})

	function success(data) {
		return {
			type: actionTypes.GET_PRODUCT_BY_ID,
			productItem: data,
		}
	}

	function failed(error) {
		console.log('Error -> ', error)
		return {
			type: actionTypes.GET_PRODUCT_BY_ID_FAILED,
			productItemFailed: error,
		}
	}
}

export const GetAllProduct = (name, hasGenerationValue, hasRedeemValue) => {
	return (dispatch) =>
		API.GetAllProduct(name, hasGenerationValue, hasRedeemValue)
			.then((rest) => {
				dispatch(fetchProduct(rest))
				return rest
			})
			.catch((err) => {
				//console.log(err);
				dispatch(fetchProductFailed(err))
			})

	function fetchProduct(data) {
		return {
			type: actionTypes.GET_PRODUCT,
			lsProducts: data,
		}
	}

	function fetchProductFailed(error) {
		return {
			type: actionTypes.GET_PRODUCT_FAILED,
			productFailed: error,
		}
	}
}

export const GetProductByBranches = (branches, name, pageSize, page) => {
	return (dispatch) =>
		API.GetProductByBranches(branches, name, pageSize, page)
			.then((rest) => {
				dispatch(fetchProduct(rest))
				return rest
			})
			.catch((err) => {
				//console.log(err);
				dispatch(fetchProductFailed(err))
			})

	function fetchProduct(data) {
		return {
			type: actionTypes.GET_PRODUCT,
			lsProducts: data,
		}
	}

	function fetchProductFailed(error) {
		return {
			type: actionTypes.GET_PRODUCT_FAILED,
			productFailed: error,
		}
	}
}

export const UpdateProduct = (id, obj) => {
	return (dispatch) =>
		API.UpdateProduct(id, obj)
			.then((rest) => {
				dispatch(success(rest))
				return rest
			})
			.catch((err) => {
				//console.log(err);
				dispatch(failed(err))
				return err
			})

	function success(data) {
		return {
			type: actionTypes.UPDATE_PRODUCT,
			updateProduct: data,
		}
	}

	function failed(error) {
		return {
			type: actionTypes.UPDATE_PRODUCT_FAILED,
			updateProductFailed: error,
		}
	}
}

export const CalculateValue = (
	productId,
	price,
	type,
	value,
	typeOfRedeem,
	redeemValue,
) => {
	return (dispatch) =>
		API.CalculateValue(
			productId,
			price,
			type,
			value,
			typeOfRedeem,
			redeemValue,
		)
			.then((rest) => {
				dispatch(fetchCalculateValue(rest))
				return rest
			})
			.catch((err) => {
				//console.log(err);
				dispatch(fetchCalculateValueFailed(err))
			})

	function fetchCalculateValue(data) {
		return {
			type: actionTypes.CALCULATED_PRODUCT_VALUE,
			calculatedValue: data,
		}
	}

	function fetchCalculateValueFailed(error) {
		return {
			type: actionTypes.CALCULATED_PRODUCT_VALUE_FAILED,
			calculatedValueFailed: error,
		}
	}
}

// GET CATEGORY OF PRODUCTS
export const GetCategory = (partnerId, name, page) => {
	return (dispatch) =>
		API.GetCategory(partnerId, name, page)
			.then((rest) => {
				dispatch(fetchGetCategory(rest))
			})
			.catch((err) => {
				//console.log(err);
				dispatch(fetchGetCategoryFailed(err))
			})

	function fetchGetCategory(data) {
		return {
			type: actionTypes.GET_CATEGORY,
			lsCategories: data,
		}
	}

	function fetchGetCategoryFailed(error) {
		return {
			type: actionTypes.GET_CATEGORY_FAILED,
			categoriesFailed: error,
		}
	}
}

// GET BRAND OF PRODUCTS
export const GetBrand = (partnerId, name, page) => {
	return (dispatch) =>
		API.GetBrand(partnerId, name, page)
			.then((rest) => {
				dispatch(fetchGetBrand(rest))
			})
			.catch((err) => {
				//console.log(err);
				dispatch(fetchGetBrandFailed(err))
			})

	function fetchGetBrand(data) {
		return {
			type: actionTypes.GET_BRAND,
			lsBrands: data,
		}
	}

	function fetchGetBrandFailed(error) {
		return {
			type: actionTypes.GET_BRAND_FAILED,
			brandsFailed: error,
		}
	}
}

// GET manufacturers
export const GetManufacturer = (partnerId, name, page) => {
	return (dispatch) =>
		API.GetManufacturer(partnerId, name, page)
			.then((rest) => {
				dispatch(fetchGetManufacturer(rest))
			})
			.catch((err) => {
				//console.log(err);
				dispatch(fetchGetManufacturerFailed(err))
			})

	function fetchGetManufacturer(data) {
		return {
			type: actionTypes.GET_MANUFACTURER,
			lsManufacturers: data,
		}
	}

	function fetchGetManufacturerFailed(error) {
		return {
			type: actionTypes.GET_MANUFACTURER_FAILED,
			manufacturerFailed: error,
		}
	}
}
//GET FAMILY
export const GetFamily = (partnerId, name, page) => {
	return (dispatch) =>
		API.GetFamily(partnerId, name, page)
			.then((rest) => {
				dispatch(fetchGetFamily(rest))
			})
			.catch((err) => {
				dispatch(fetchGetFamilyFailed(err))
			})

	function fetchGetFamily(data) {
		return {
			type: actionTypes.GET_FAMILY,
			lsFamily: data,
		}
	}

	function fetchGetFamilyFailed(error) {
		return {
			type: actionTypes.GET_FAMILY_FAILED,
			familyFailed: error,
		}
	}
}
