import axios from "axios";

export const ReadAlert = async (id, readAllImportantAlerts) => {
	const response = await axios.get(
		`/Alert/ReadAlert/${id}?${readAllImportantAlerts
			? `readAllImportantAlerts=${readAllImportantAlerts}`
			: ""
		}`
	);
	return response.data;
};

// GET
export const GetPartnerAlerts = async (page = 1) => {
	const response = await axios.get(
		`Alert/GetAlertsByDestination/2?page=${page}&pageSize=6`
	);
	return response.data;
};

// GET
export const GetPartnerImportantAlertsId = async () => {
	const response = await axios.get(`/Alert/GetImportantAlertsId/2`);
	return response.data;
};

// GET
export const GetConnectionId = async () => {
	const response = await axios.get(`/Notification/GetNotifications`);
	return response.data;
};

// UPDATE
export const userPreference = async (viewImportantAlerts) => {
	const response = await axios.put(
		`/UserPreference?viewImportantAlerts=${viewImportantAlerts}`
	);
	return response.data;
};

export const GetUserPreference = async (viewImportantAlerts) => {
	const response = await axios.get(`/UserPreference`);
	return response.data;
};
