import * as actionTypes from '../actionsTypes';
import * as API from '../../api/access/groups';
import * as Loader from '../loader';

export const fetcUserByGroup = (data) => {
    return {
        type: actionTypes.FETCH_USER_GROUP_DATA,
        userByGroup: data
    };
};
export const fetcUsersOptions = (data) => {
    return {
        type: actionTypes.FETCH_USER_OPTIONS_DATA,
        userOptions: data
    };
};


export const fetcRemoveUserFromgroup = (data) => {
    return {
        type: actionTypes.FETCH_REMOVE_USER_FROM_GROUP,
        userByGroup: data
    };
};
export const fetcClaimsGroupNewGroup = (data) => {
    return {
        type: actionTypes.FETCH_CLAIMS_GROUP_NEW_GROUP_DATA,
        claimsGroupNewGroup: data
    };
};

// POST Action -------------------------------------

export const postNewGroup = (data) => {
    return {
        type: actionTypes.POST_NEW_GROUP_DATA,
        postNewGroup: data
    };
};
export const editNewGroup = (data) => {
    return {
        type: actionTypes.EDIT_NEW_GROUP_DATA,
        editNewGroup: data
    };
};
// FAIL FETCH -------------------------------------

export const fetcFailed = (error) => {
    return {
        type: actionTypes.FETCH_FAILED,
        reglationDataFailed: error
    };
};

// FAIL POST -------------------------------------

export const PostNewGroupFailed = (error) => {
    return {
        type: actionTypes.POST_NEW_GROUP_DATA_FAILED,
        newGroupPostFailed: error
    };
};

//----------------------------------------------------

export const GetUserByGroupData = (id) => {
    return dispatch => {
        API.GetUserByGroup(id).then(response => {
            dispatch(fetcUserByGroup(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetcFailed());
            dispatch(Loader.hideLoader());
        })
    };
};

export const GetUsersOptions = (id) => {
    return dispatch => (
        API.GetUsersOptions(id).then(response => {
            dispatch(fetcUsersOptions(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetcFailed());
            dispatch(Loader.hideLoader());
        })
    );
};
export const AssignUserToGroup = (obj) => {
    return dispatch => (
        API.AssignUserToGroup(obj).then(response => {
            dispatch(fetcUserByGroup(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetcFailed());
            dispatch(Loader.hideLoader());
        })
    );
};

export const GetClaimsGroupNewGroupData = () => {
    return dispatch => {
        API.GetClaimsGroupNewGroup().then(response => {
            dispatch(fetcClaimsGroupNewGroup(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetcFailed());
            dispatch(Loader.hideLoader());
        })
    };
};

export const RemoveUserFromgroup = (obj) => {
    return dispatch => {
        API.RemoveUserFromgroup(obj).then(response => {
            dispatch(fetcRemoveUserFromgroup(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetcFailed());
            dispatch(Loader.hideLoader());
        })
    };
};

// POST ACTIONS -----------------------------------------
export const PostNewGroupData = (obj) => {
    return dispatch => (
        API.PostNewGroup(obj).then(response => {
            dispatch(postNewGroup(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(PostNewGroupFailed());
            dispatch(Loader.hideLoader());
        })
    );
}
export const EditNewGroupData = (obj) => {
    return dispatch => (
        API.EditNewGroup(obj).then(response => {
            dispatch(editNewGroup(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(PostNewGroupFailed());
            dispatch(Loader.hideLoader());
        })
    );
}
