import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    lsProducts: [],
    productFailed: false,

    productItem: [],
    productItemFailed: false,

    updateProduct: [],
    updateProductFailed: false,

    calculatedValue: [],
    calculatedValueFailed: false,

    lsCategories: [],
    categoriesFailed: false,

    lsBrands: [],
    brandsFailed: false,

    lsManufacturers: [],
    manufacturerFailed: false,

    lsFamily: [],
    familyFailed: false,

};

//GET
const fetchProductData = (state, action) => {
    return updateObject(state, {
        lsProducts: action.lsProducts
    });
};

// GET PRODUCT BY ID
const fetchProductByIdData = (state, action) => {
    return updateObject(state, {
        productItem: action.productItem
    });
};

// UPDATE
const fetchUpdateProductData = (state, action) => {
    return updateObject(state, {
        updateProduct: action.updateProduct
    });
};

// CALCULATE
const fetchCalculateProductValue = (state, action) => {
    return updateObject(state, {
        calculatedValue: action.calculatedValue
    });
};

// GET CATEGORY
const fetchCategoryValue = (state, action) => {
    return updateObject(state, {
        lsCategories: action.lsCategories
    });
};

// GET BRAND
const fetchBrandValue = (state, action) => {
    return updateObject(state, {
        lsBrands: action.lsBrands
    });
};

// GET MANUFACTURER
const fetchManufacturerValue = (state, action) => {
    return updateObject(state, {
        lsManufacturers: action.lsManufacturers
    });
};

// GET Family
const fetchFamilyValue = (state, action) => {
    return updateObject(state, {
        lsFamily: action.lsFamily
    });
};

//
// -------------------------------------
// ------------- DATA FAIL -------------
// -------------------------------------
// get
const fetchProductDataFailed = (state, action) => {
    return updateObject(state, {
        productFailed: true
    });
};

const fetchProductByIdDataFailed = (state, action) => {
    return updateObject(state, {
        productItemFailed: action.productItemFailed
    });
};

// update
const fetchUpdateProductDataFailed = (state, action) => {
    return updateObject(state, {
        updateProductFailed: true
    });
};
// calculate
const fetchCalculateProductValueFailed = (state, action) => {
    return updateObject(state, {
        calculatedValueFailed: true
    });
};
// category
const fetchCategoriesFailed = (state, action) => {
    return updateObject(state, {
        categoriesFailed: true
    });
};

// brand
const fetchBrandsFailed = (state, action) => {
    return updateObject(state, {
        brandsFailed: true
    });
};

const fetchManufacturerFailed = (state, action) => {
    return updateObject(state, {
        manufacturerFailed: true
    });
};

const fetchFamilyFailed = (state, action) => {
    return updateObject(state, {
        familyFailed: true
    });
};
const partnersReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PRODUCT:
            return fetchProductData(state, action);
        case actionTypes.GET_PRODUCT_FAILED:
            return fetchProductDataFailed(state, action);

        case actionTypes.GET_PRODUCT_BY_ID:
            return fetchProductByIdData(state, action);
        case actionTypes.GET_PRODUCT_BY_ID_FAILED:
            return fetchProductByIdDataFailed(state, action);

        case actionTypes.UPDATE_PRODUCT:
            return fetchUpdateProductData(state, action);
        case actionTypes.UPDATE_PRODUCT_FAILED:
            return fetchUpdateProductDataFailed(state, action);

        case actionTypes.CALCULATED_PRODUCT_VALUE:
            return fetchCalculateProductValue(state, action);
        case actionTypes.CALCULATED_PRODUCT_VALUE_FAILED:
            return fetchCalculateProductValueFailed(state, action);

        case actionTypes.GET_CATEGORY:
            return fetchCategoryValue(state, action);
        case actionTypes.GET_PRODUCT_FAILED:
            return fetchCategoriesFailed(state, action);

        case actionTypes.GET_BRAND:
            return fetchBrandValue(state, action);
        case actionTypes.GET_BRAND_FAILED:
            return fetchBrandsFailed(state, action);

        case actionTypes.GET_MANUFACTURER:
            return fetchManufacturerValue(state, action);
        case actionTypes.GET_MANUFACTURER_FAILED:
            return fetchManufacturerFailed(state, action);

        case actionTypes.GET_FAMILY:
            return fetchFamilyValue(state, action);
        case actionTypes.GET_FAMILY_FAILED:
            return fetchFamilyFailed(state, action);


        default:
            return state;
    }
};

export default partnersReducer;