import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

export const CustomBoxModal = styled.form`
	background-color: #fff;
	box-shadow: 24;
	border-radius: 7px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 400;
	padding: 25px;

	img {
		width: 65px;
	}

	.MuiFormControlLabel-root {
		margin-right: 35px;
	}

	.MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
		color: #ffffff !important;
		background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor} !important;
	}

	.MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'].Mui-focused {
		background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor} !important;
	}
`

export const BodyModal = styled.div`
	display: flex;
	flex-wrap: wrap;
`
export const ModalButtons = styled.div`
	border-top: 1px solid #bbbbbb;
	display: flex;
	justify-content: end;
	margin-top: 18px;
`

export const BoxIcon = styled.div`
	display: flex;
	.MuiIcon-root {
		color: ${getThemeColorFromLocalStorage()?.visual?.icons?.color};
		margin-left: 5px;
		font-size: 35px;
		width: auto;
		height: auto;
		transform: rotateY(180deg);
	}
`
export const Icon = styled.div`
	.MuiIcon-root {
		width: auto;
		height: auto;
	}
`

export const Titles = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 0 0 15px;

	span {
		color: ${getThemeColorFromLocalStorage()?.titles?.main?.textColor};
		font: normal normal bold 18px/18px Roboto;
	}

	p {
		font: normal normal normal 13px/20px Roboto;
	}
`

export const Authorizer = styled.div`
	align-items: center;
	border: 1px solid ${getThemeColorFromLocalStorage()?.titles?.main?.textColor};
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	margin: 6px 15px 0 0;
	padding: 15px;
	width: 128px;

	span {
		color: #333333;
		font: normal normal normal 15px/15px Roboto;
	}

	img {
		margin-top: 5px;
	}
`

export const ValidatedConnection = styled.div`
	align-items: center;
	display: flex;
`
