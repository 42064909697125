import styled from 'styled-components'

export const Container = styled.div`
    height: 300px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const Icon = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 70px;
    color: #c8c8c8;
    margin-bottom: 15px;
`

export const Text = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
`