import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { Box, Stack, Tooltip } from '@mui/material'
import { createData } from '../../../CalculationMemory'
import { FormatMoney, FormatPoints } from '../../Functions'
import { FormarPointsValue } from '../../../../../../../../../../../utils/utils'

const Info = ({ parameterization, setOpen }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<Stack gap="4rem" spacing={2} width="100%" display="flex" direction="row" padding="10px 13px" className="stack-info">
				<Box className="box-info">
					<S.Title>{t('Taxa de resgate (R$)')}</S.Title>
					<S.SubTitle>{t('Programa')}</S.SubTitle>
					<S.Value>{FormatMoney(parameterization?.standardValueTax || 0)}</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('Valor em seeds')}</S.Title>
					<S.SubTitle>{t('Lastro')}</S.SubTitle>
					<S.Value>{FormarPointsValue(parameterization?.standardPointsValue || 0)}</S.Value>
				</Box>
				<Box className="box-info">
					<S.Title>{t('A receber')}</S.Title>
					<S.SubTitle>{t('Carteira do cliente')}</S.SubTitle>
					{/* <S.Value>{FormatPoints(parameterization?.standardValueReceivable || 0)}</S.Value> */}
					<S.Value>{FormarPointsValue(parameterization?.standardValueReceivable || 0)}</S.Value>
				</Box>
			</Stack>
			<Tooltip arrow placement="top" title={t('Memória de cálculo')}>
				<Box
					className="stack-info-icon"
					onClick={() =>
						setOpen({
							active: true,
							row: [
								createData('Valor do produto em Seeds', FormatPoints(parameterization?.standardPointsValue || 0)),
								createData('Valor do produto em Reais', FormatMoney(parameterization?.calculationMemory?.productValue || 0)),
								createData('Taxa de resgate', FormatMoney(parameterization?.calculationMemory?.redemptionFee || 0)),
								createData('Taxa de resgate aplicada', FormatMoney(parameterization?.standardValueTax || 0)),
								createData('Valor a receber', FormatPoints(parameterization?.customValueReceivable || 0)),
							],
						})
					}
				>
					<i style={{ fontSize: '1.5rem', color: '#1A9CAC' }} className="icon-bot_memoria_calculo" />
				</Box>
			</Tooltip>
		</S.Container>
	)
}

Info.propTypes = {
	setOpen: PropTypes.func,
	productData: PropTypes.object,
	parameterization: PropTypes.array,
	onOpen: PropTypes.func,
}

Info.defaultProps = {
	parameterization: {
		standardAdministrationFeeApplied: '',
		standardGrossValue: '',
		standardAccumulatePoints: '',
	},
}

export default Info
