// images authorizer
import Getnet from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_getnet.png'
import Iugu from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_iugu.png'
import Cielo from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_cielo.png'
import Paypal from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_paypal.png'
import Pagarme from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_pagarme.png'
import Yapay from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_yapay.png'
import Juno from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_juno.png'
import Rede from '../../../assets/img/pagamentos/Autorizadora PNG/autorizadora_rede.png'

// @Mui
import { styled } from '@mui/system'
import TextField from '@mui/material/TextField'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

export const purchaseOptions = [
	{
		icon: Getnet,
		name: 'GetNet',
		labelId: 'ClientId*',
		labelKey: 'ClientSecret*',
	},
	{
		icon: Cielo,
		name: 'Cielo',
		labelId: 'MerchantId*',
		labelKey: 'MerchantKey*',
	},
	{
		icon: Pagarme,
		name: 'PagarMe',
		labelId: 'PublicKey*',
		labelKey: 'SecretKey*',
	},
	{
		icon: Juno,
		name: 'Juno',
		labelId: 'ClientId*',
		labelKey: 'ClientSecret*',
	},
	{
		icon: Iugu,
		name: 'Iugu',
		labelId: 'LiveToken*',
		labelKey: '',
	},
	{
		icon: Paypal,
		name: 'Paypal',
		labelId: 'ClientId*',
		labelKey: 'ClientSecret*',
	},
	{
		icon: Yapay,
		name: 'Yapay',
		labelId: 'IntegrationToken*',
		labelKey: '',
	},
	{
		icon: Rede,
		name: 'Rede',
		labelId: 'PV*',
		labelKey: 'Token*',
	},
]

export const authorizerIcons = purchaserObjects => {
	if (purchaserObjects.length === 0) return purchaseOptions
	let newPurchaseOptions = []

	for (const opt of purchaseOptions) {
		for (const obj of purchaserObjects) {
			if (opt.name === obj.name) {
				newPurchaseOptions.push({
					...opt,
					brands: obj.brands,
					connection: obj.connection,
					id: obj?.id,
					isActive: obj?.isActive,
					paymentMethods: obj?.paymentMethods,
				})
			}
		}
	}

	return newPurchaseOptions
}

export const brandsIcons = (purchaserObjects = {}) => {
	const paymentOptions = purchaserObjects.paymentMethods.filter(p => {
		return p.enabledIcon && p.disabledIcon
	})
	const newPaymentOptions = [
		{
			brands: [...purchaserObjects.brands],
			paymentMethods: [...paymentOptions],
		},
	]
	return newPaymentOptions
}

export const PropsSnack = {
	open: true,
	color: 'success',
	icon: 'icon-ico_aprovar_botao',
	message: 'Autorizadora conectada com sucesso',
}

export const PropsSnackExportLog = {
	open: true,
	color: 'success',
	icon: 'icon-ico_aprovar_botao',
	message: 'Log exportado com sucesso',
}

export const SnackProps = {
	open: false,
	color: '',
	icon: '',
	message: '',
}

export const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: getThemeColorFromLocalStorage()?.headers?.backgroundColor,
	},
	'& label': {
		lineHeight: 1.2,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons
			?.backgroundColor,
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
		'&.Mui-focused fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
	},
})

export const cardsStyle = [
	{
		name: 'MENU_FINANCIAL_PAYMENT_OPTIONS',
		icon: 'icon-ico_resumo_conta',
		color: 'success',
	},
]
