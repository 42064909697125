import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// @ Components
//import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Snackbar from 'components/Snackbar'

// @ Context and Styles
import * as S from './styles'
import GeneralContext from '../../GeneralContext'
import { PaymentMethodsCard } from './PaymentMethodsCard'

const breadcrumbItems = t => {
	return [
		{ name: t('MENU_FINANCIAL') },
		{
			name: t('MENU_FINANCIAL_PAYMENT_OPTIONS'),
			last: true,
		},
	]
}

function PaymentOptions() {
	const { t } = useTranslation()
	const { isSucess, setIsSucess } = useContext(GeneralContext)

	return (
		<GridContainer>
			<Snackbar
				close={true}
				marginTop="130px"
				open={isSucess.open}
				color={isSucess.color}
				handleClose={() => setIsSucess({ open: false })}
				message={t(isSucess.message)}
				closeNotification={() => setIsSucess({ open: false })}
				icon={() => <i className={isSucess.icon} />}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			/>
			<S.Container>
				{/* <Breadcrumbs items={breadcrumbItems(t)} /> */}
				<PaymentMethodsCard />
			</S.Container>
		</GridContainer>
	)
}

export default PaymentOptions
