/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

import { FormLabel, Grid } from '@mui/material'
import { Controller } from 'react-hook-form'
//@ components
import { CssTextField } from '../../../../../../Filter/styles'
import GenerationPoints from '../../../../../../generalTab/components/Configuration/GenerationPoints'
import { PercentageValue, USDValue } from '../../../../../../../../../../../../utils/useMask'

const Configuration = ({
	typeOfPoints,
	productData,
	parameterizationGenerationPoints,
	fetchCalculation,
	control,
	viewMode,
	pointsValue,
	calculationMemory,
	onOpen,
}) => {
	const { t } = useTranslation()

	const typeMask = typeOfPoints === '2' ? USDValue : PercentageValue

	useEffect(() => {
		fetchCalculation()
	}, [pointsValue])

	return (
		<S.CardsContent>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Valor*')}</span>
						<Controller
							name="pointsValue"
							control={control}
							render={({ onChange, value }) => (
								<CssTextField
									variant="outlined"
									size="small"
									onChange={e => {
										fetchCalculation(e.target.value)
										onChange(e.target.value)
									}}
									disabled={viewMode}
									value={value}
									InputProps={{
										inputComponent: typeMask,
									}}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				{/* <Grid item xs={12} sm={9}>
					<GenerationPoints
						parameterization={parameterizationGenerationPoints}
						calculationMemory={calculationMemory}
						typeOfPoints={typeOfPoints}
						productData={productData}
						onOpen={onOpen}
					/>
				</Grid> */}
			</Grid>
		</S.CardsContent>
	)
}

Configuration.propTypes = {
	typeOfPoints: PropTypes.string,
	divisionProductData: PropTypes.object,
	productData: PropTypes.object,
	parameterizationGenerationPoints: PropTypes.object,
	fetchCalculation: PropTypes.func,
	control: PropTypes.any,
	setcashbackValue: PropTypes.func,
	cashbackValue: PropTypes.object,
	viewMode: PropTypes.bool,
	calculationMemory: PropTypes.object,
	onOpen: PropTypes.func,
	pointsValue: PropTypes.string,
}

Configuration.defaultProps = {
	typeOfPoints: '1',
}

export default Configuration
