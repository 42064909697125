import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import CustomModal from '../../../Modal'
import SnackDefault from 'components/SnackDefault'
import Cookies from 'js-cookie'
import { UpdateGrouperParametrization } from '../../../../../../../../../redux/api/Grouper'

const NoDivisionTab = ({ setEditMode, editMode, productData, typeOfBenefitPoints, grouper }) => {
	const { t } = useTranslation()
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const ParameterizationProduct = async (productData, typeOfBenefitPoints) => {
		const partnerId = Cookies.get('partnerId')
		const obj = {
			partnerId: partnerId || '',
			typeOfGrouper: grouper || '',
			generatePoints: productData?.generatePoints,
			typeOfBenefitPoints: typeOfBenefitPoints?.key,
			typeOfPoints: productData?.typeOfPoints?.key,
			pointsValue: productData?.pointsValue || 0,
			typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key,
			typeOfRedeem: productData?.typeOfRedeem?.key,
			redemptionPoints: productData?.redemptionPoints?.key,
			typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
			typeOfCashback: productData?.typeOfCashback?.key,
			cashbackPercentual: productData?.cashbackPercentual,
		}

		try {
			const res = await UpdateGrouperParametrization({ id: productData?.id, obj })

			if (res?.errors?.length > 0) {
				setSnackStatus({
					open: true,
					text: t(res?.errors[0]?.message) || 'Erro ao realizar operação',
					severity: 'error',
				})
				return
			}

			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})
			setEditMode(false)
		} catch (error) {
			console.log(error)
		}
	}

	return (
		<>
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					ParameterizationProduct(productData, typeOfBenefitPoints)
					setModalParameterization({ open: false })
				}}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<S.Container>
				{editMode === true ? (
					<S.Content>
						<Buttons className="cancel" Icon="icon-bot_fechar" onClick={() => setEditMode(false)} />
						<Buttons
							className="save"
							onClick={() =>
								setModalParameterization({
									open: true,
									title: 'Deseja realmente alterar a parametrização',
									subtitle: 'desse benefício?',
								})
							}
							Icon="icon-bot_salvar"
						/>
					</S.Content>
				) : (
					<S.Content>
						<Buttons className="softBlueAction" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
					</S.Content>
				)}
			</S.Container>
		</>
	)
}

export default NoDivisionTab
