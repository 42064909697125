import { makeStyles } from '@material-ui/core'
import styled from 'styled-components/macro'

export const FilterForm = styled.form`
	background-color: #f6f6f6;
	border-radius: 5px;
	margin: auto;
	margin-bottom: 30px;
	padding: 20px 15px;
	width: 100%;

	@media only screen and (max-width: 449px) {
		padding-bottom: 75px;
	}

	.MuiOutlinedInput-adornedEnd {
		padding-right: 7px;
	}

	.iconButton {
		margin-right: 5px;
		padding: 4px;
		color: ${(props) => props.themeColor} !important;
		border: 1px solid;
		border-color: ${(props) => props.themeColor} !important;
		&:hover {
			color: #fff !important;
			background-color: ${(props) => props.themeColor} !important;
		}
	}

	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) => props.getTextCol};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after {
		border-color: ${(props) => props.getBGCol};
		border-width: 2px;
	}

	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => props.themeColor};
	 }

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => props.getBGCol};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.getBGCol};
	}
	.MuiFormControl-marginDense {
		margin: 0 !important;
		width: 100%;
	}
	.makeStyles-formControl-215 {
		margin: 0;
		width: 100%;
	}
`
export const InpusContain = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
`

export const DateTimeContain = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 20px;
	width: 100%;
	@media only screen and (min-width: 700px) {
		padding-right: 7.5px;
		width: 50%;
	}
	@media only screen and (min-width: 1000px) {
		width: 40%;
	}
`
export const DateInput = styled.div`
	width: 100%;

	&:nth-child(1) {
		margin-bottom: 15px;
	}
	@media only screen and (min-width: 400px) {
		width: 50%;
		&:nth-child(1) {
			margin-bottom: 0;
			padding-right: 15px;
		}
		&:nth-child(2) {
			padding-left: 15px;
		}
	}
`

export const InputDescription = styled.div`
	margin-bottom: 20px;
	width: 100%;
	@media only screen and (min-width: 700px) {
		padding-left: 22px;
		width: 50%;
	}
	@media only screen and (min-width: 1000px) {
		width: 60%;
	}
`
export const InputEvent = styled.div`
	margin-bottom: 20px;
	width: 100%;
	@media only screen and (min-width: 700px) {
		padding-right: 7.5px;
		width: 50%;
	}
	@media only screen and (min-width: 1000px) {
		width: 35%;
	}
`
export const BntFilterContain = styled.div`
	flex-direction: column;
	display: flex;
	height: 40px;
	@media only screen and (min-width: 450px) {
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}
`
export const BntMoreFilter = styled.div`
	align-items: center;
	color: ${(props) => props.getBtnElColor};
	cursor: pointer;
	display: flex;
	margin-bottom: 15px;
	@media only screen and (min-width: 450px) {
		margin-bottom: 0;
	}
`

export const BntFilter = styled.div`
	gap: 13px;
	display: flex;
`

export const IconCalendario = styled.img`
	height: 20px;
	width: 20px;
`

export const useStyles = makeStyles((theme) => ({
	formControl: {
		marginTop: 18,
		width: 317,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
}))
