import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    cityData: [],
    stateData: [],
    countryData: [],

    cityDataFailed: false,
    stateDataFailed: false,
    countryDataFailed: false,
};

const fetchCityData = (state, action) => {
    return updateObject(state, {
        cityData: action.cityData
    });
};

const fetchStateData = (state, action) => {
    return updateObject(state, {
        stateData: action.stateData
    });
};

const fetchCountryData = (state, action) => {
    return updateObject(state, {
        countryData: action.countryData
    });
};

// DATA FAIL
const fetchCityFail = (state, action) => {
    return updateObject(state, {
        cityDataFailed: true
    });
};

const fetchStateFail = (state, action) => {
    return updateObject(state, {
        stateDataFailed: true
    });
};

const fetchCountryFail = (state, action) => {
    return updateObject(state, {
        countryDataFailed: true
    });
};

const locationReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CITY_DATA:
            return fetchCityData(state, action);
        case actionTypes.FETCH_STATE_DATA:
            return fetchStateData(state, action);
        case actionTypes.FETCH_COUNTRIES_DATA:
            return fetchCountryData(state, action);

        case actionTypes.FETCH_CITY_FAILED:
            return fetchCityFail(state, action);
        case actionTypes.FETCH_STATE_FAILED:
            return fetchStateFail(state, action);
        case actionTypes.FETCH_COUNTRIES_FAILED:
            return fetchCountryFail(state, action);
        default:
            return state;
    }
};

export default locationReducer;