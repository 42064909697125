import axios from "axios";

// GET api/ProgramRules/General
export const GetGeneralRulesData = () =>
	axios.get(`/ProgramRules/General`).then((response) => response.data);

export const GetDigitalWalletRules = () =>
	axios
		.get(`/ProgramRules/General/DigitalWalletRules`)
		.then((response) => response.data);
