export const initialPagination = {
	currentPage: 0,
	pageCount: 1,
	pageSize: 10,
	recordCount: 1,
	filter: {
		name: '',
	},
}

export const defaultSnackbar = {
	open: false,
	severity: 'success',
	text: 'Operação realizada com sucesso!',
}

export const defaultModal = {
	open: false,
	title: '',
	subtitle: '',
}

export const LabelOptions = ({ tabIndex, t, multiple }) => {
	switch (tabIndex) {
		case 0:
			return multiple ? t('MENU_CATEGORY') : t('FIELD_CATEGORY')
		case 1:
			return multiple ? t('MENU_FAMILY') : t('FAMILY')
		case 2:
			return multiple ? t('MENU_MANUFACTURER') : t('MANUFACTURER')
		case 3:
			return multiple ? t('MENU_BRAND') : t('FIELD_BRAND')
		default:
			return ''
	}
}

export const getLabel = ({ tabIndex, t }) => {
	switch (tabIndex) {
		case 0:
			return t('FIELD_ADD_CATEGORY')
		case 1:
			return t('FIELD_ADD_FAMILY')
		case 2:
			return t('FIELD_ADD_MANUFACTURER')
		case 3:
			return t('FIELD_ADD_BRAND')
		default:
			return ''
	}
}
