/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

// @ Mui
import { FormLabel, Grid, InputAdornment } from '@mui/material'

// @ Components
import GenerationPoints from './GenerationPoints'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'

// @ Functions
import { Symbol } from '../../Functions'
import { Controller } from 'react-hook-form'

// @ Styles
import * as S from './styles'
import { CssTextField } from '../../../Filter/styles'
import MonetaryInput from 'components/MonetaryInput'
import { newValue } from '../../../../../../../../../ProductDetails/Parametrization/components/Cashback/components/DivisionTab/components/ParameterizeDivision/ParameterizeDivisionTB/Functions'

const Configuration = ({
	typeOfCashback,
	fetchCalculation,
	control,
	editMode,
	errors,
	rules,
}) => {
	const { t } = useTranslation()

	const adornmentPosition = typeOfCashback === '4' ? 'start' : 'end'

	const handleNewValue = value => {
		if (rules?.cashbackRules?.automaticPercentualProduct) {
			return newValue(value, rules?.cashbackRules?.maximumPercentualProduct)
		}
		return newValue(value, 100)
	}

	return (
		<S.CardsContent>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Valor*')}</span>
						<Controller
							name="cashbackPercentual"
							control={control}
							defaultValue={0}
							render={({ value, onChange, name }) =>
								typeOfCashback === '4' ? (
									<MonetaryInput
										fullWidth
										max={1000000}
										name={name}
										value={value}
										onChange={e => {
											fetchCalculation(e)
											onChange(e)
										}}
										disabled={!editMode}
										onValueChange={() => console.log('')}
										error={errors?.cashbackPercentual}
										helperText={errors?.cashbackPercentual?.message}
									/>
								) : (
									<CssTextField
										variant="outlined"
										size="small"
										value={handleNewValue(value)}
										disabled={!editMode}
										onChange={e => {
											fetchCalculation(e.target.value)
											onChange(e.target.value)
										}}
										error={errors?.cashbackPercentual}
										helperText={errors?.cashbackPercentual?.message}
										inputProps={{
											max_val: 100,
											maxLength: 4,
										}}
										InputProps={{
											[adornmentPosition + 'Adornment']: (
												<InputAdornment position={adornmentPosition}>
													<S.CustomSymbol>{Symbol(typeOfCashback)}</S.CustomSymbol>
												</InputAdornment>
											),
											inputComponent: DoubleFormatCustom,
										}}
									/>
								)
							}
						/>
						{typeOfCashback === '3' && rules?.cashbackRules?.automaticPercentualProduct && <S.CashbackContent>
							<S.CashbackCardIcon className="icon-i_atencao" />
							<S.CashbackCardText>Valor informado não pode ser maior do que a parametrização automática.</S.CashbackCardText>
						</S.CashbackContent>}
					</FormLabel>
				</Grid>
				{/* <Grid item xs={12} sm={9}>
					<GenerationPoints
						customCashbackValue={customCashbackValue}
						parameterization={parameterizationGenerationPoints}
						typeOfCashback={typeOfCashback}
						productData={productData}
					/>
				</Grid> */}
			</Grid>
		</S.CardsContent>
	)
}

Configuration.propTypes = {
	rules: PropTypes.object,
	typeOfCashback: PropTypes.string,
	productData: PropTypes.object,
	parameterizationGenerationPoints: PropTypes.array,
	fetchCalculation: PropTypes.func,
	customCashbackValue: PropTypes.number,
	cashbackValue: PropTypes.number,
	editMode: PropTypes.bool,
	control: PropTypes.object,
	errors: PropTypes.object,
	setValue: PropTypes.func,
	cashbackPercentual: PropTypes.func,
}

Configuration.defaultProps = {
	typeOfCashback: '',
}

export default Configuration
