import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    campaignData: {},
    campaignPerformanceData: {},
    lsCampaignData: [],
    postCampaign: {},
    putCampaign: {},
    deactivateCampaign: {},//update status to inactive
    campaignTypeList: [],
    productTypeList: [],
    scoringTypeList: [],
    campaignStatusList: [],
    campaignFailed: false,
};

const fetchCampaignListData = (state, action) => {
    return updateObject(state, {
        lsCampaignData: action.lsCampaignData
    });
};

const postCampaignData = (state, action) => {
    return updateObject(state, {
        postCampaign: action.postCampaign
    });
};

const fetchCampaignData = (state, action) => {
    return updateObject(state, {
        campaignData: action.campaignData
    });
};

const fetchCampaignPerformanceData = (state, action) => {
    return updateObject(state, {
        campaignPerformanceData: action.campaignPerformanceData
    });
};

const putCampaignData = (state, action) => {
    return updateObject(state, {
        putCampaign: action.putCampaign
    });
};

const putCampaignDeactivate = (state, action) => {
    return updateObject(state, {
        deactivateCampaign: action.deactivateCampaign
    });
};

const fetchCampaignTypeListData = (state, action) => {
    return updateObject(state, {
        campaignTypeList: action.campaignTypeList
    });
};

const fetchProductTypeListData = (state, action) => {
    return updateObject(state, {
        productTypeList: action.productTypeList
    });
};

const fetchScoringTypeListData = (state, action) => {
    return updateObject(state, {
        scoringTypeList: action.scoringTypeList
    });
};

const fetchCampaignStatusListData = (state, action) => {
    return updateObject(state, {
        campaignStatusList: action.campaignStatusList
    });
};

// DATA FAIL
const fetchcampaignFail = (state, action) => {
    return updateObject(state, {
        campaignFailed: action.campaignFailed
    });
};

//
const CampaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_CAMPAIGN_LIST_DATA:
            return fetchCampaignListData(state, action);
        case actionTypes.FETCH_CAMPAIGN_DATA:
            return fetchCampaignData(state, action);
        case actionTypes.FETCH_CAMPAIGN_PERFORMANCE_DATA:
            return fetchCampaignPerformanceData(state, action);
        case actionTypes.POST_CAMPAIGN_DATA:
            return postCampaignData(state, action);
        case actionTypes.PUT_CAMPAIGN_DATA:
            return putCampaignData(state, action);
        case actionTypes.PUT_CAMPAIGN_DEACTIVATE:
            return putCampaignDeactivate(state, action);
        case actionTypes.FETCH_CAMPAIGN_TYPE_DATA:
            return fetchCampaignTypeListData(state, action);
        case actionTypes.FETCH_PRODUCT_TYPE_DATA:
            return fetchProductTypeListData(state, action);
        case actionTypes.FETCH_SCORING_TYPE_DATA:
            return fetchScoringTypeListData(state, action);
        case actionTypes.FETCH_CAMPAIGN_STATUS_DATA:
            return fetchCampaignStatusListData(state, action);
        case actionTypes.FETCH_CAMPAIGN_FAILED:
            return fetchcampaignFail(state, action);
        default:
            return state;
    }
};

export default CampaignReducer;