import * as yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
// Styles
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export const CreateReservationErrors = (t, errors, setError) => {
	errors.forEach((error) => {
		if (error.field === 'Name') {
			setError('name', {
				type: 'manual',
				message: t(error.message),
			})
		}

		if (error.field === 'Value') {
			setError('reservationValue', {
				type: 'manual',
				message: t(error.message),
			})
		}

		if (error.field === 'Description') {
			setError('description', {
				type: 'manual',
				message: t(error.message),
			})
		}
	})
}

export const validation = yup.object().shape({
	name: yup.string().required('Campo obrigatório'),
	reservationValue: yup.string().required('Campo obrigatório'),
	description: yup.string(),
})

export const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			fontWeight: '400',
			color: 'hls(0, 0, 0, 0.55)',
			lineHeight: 1.2,
			zIndex: 0,
		},
		'& .MuiInputBase-root': {
			color: 'rgba(0, 0, 0, 0.6)',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)',
			//
			'&:not(.Mui-disabled)': {
				marginTop: '-7px',
				marginLeft: '-5px',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: '9.5px',
			paddingBottom: '10.5px',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			fontSize: '16px',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			cursor: 'default',
		},
		'& fieldset': {
			color: 'rgba(0, 0, 0, 0.7)',
			borderColor: 'hsl(0,0%,80%)',
			'& .hover': {
				borderColor: 'hsl(0,0%,80%)',
			},
			'& legend': {
				width: '0px',
			},
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: grayColor[2],
				},
				'&.Mui-focused fieldset': {
					borderColor: grayColor[2],
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(3,3%,95%)',
			zIndex: -1,
			'& fieldset': {
				borderColor: 'transparent',
				'& legend': {
					width: '0px',
				},
			},
		},
		'& .PrivateNotchedOutline-legendLabelled-583': {
			width: '0px !important',
		},
	},
})(TextField)

export const initialData = {
	name: '',
	reservationValue: '',
	description: '',
}
