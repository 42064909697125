import React, { useEffect } from 'react'

import { useForm } from 'react-hook-form'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HideLoader } from 'redux/actions'
import { useTranslation } from 'react-i18next'

import { useAward } from '../../Providers/AwardContext'
import Button from 'components/CustomButtons/Button.jsx'
import { NewTab, NewTabList, NewTabs } from 'components/NewTab/styles'
import Section from 'components/Section'
import ModalReversal from './ModalReversal'
import Pending from './Pending'
import Processed from './Processed'
import SearchForm from './SearchForm'
import CustomizedSnackbars from './SnackBar'

import * as S from './style'

const Award = () => {
	const {
		displayedTab,
		setDisplayedTab,
		optionMenssage,
		openMsgSucess,
		setOpenMsgSucess,
		allowAwards,
	} = useAward()
	const dispatch = useDispatch()
	const { watch } = useForm({
		defaultValues: {
			dateFilter: null,
			dateFilterTo: null,
			description: '',
			typeFilter: '',
		},
	})
	const { t } = useTranslation()
	const history = useHistory()

	useEffect(() => {
		dispatch(HideLoader())
	}, [])

	const handleChangeTab = (tab) => {
		tab === 1 ? setDisplayedTab(1) : setDisplayedTab(2)
	}

	return (
		<S.Container>
			<CustomizedSnackbars
				openMsgSucess={openMsgSucess}
				setOpenMsgSucess={setOpenMsgSucess}
				optionMenssage={optionMenssage}
			/>
			<ModalReversal />

			<S.Header>
				<Section title={t('MENU_AWARD')} style={{ flexGrow: 1 }} />
				<div style={{ marginLeft: '15px' }}>
					<Button
						size="sm"
						variant="contained"
						disabled={allowAwards === false}
						color="greenButtonColor"
						onClick={() => history.push('/financial/createAward')}
					>
						Criar Premiação
					</Button>
				</div>
			</S.Header>
			<SearchForm />
			<NewTabs selectedIndex={displayedTab - 1}>
				<NewTabList
					style={{
						minWidth: '800px',
						width: '100%',
						marginBottom: '20px',
					}}
				>
					<NewTab onClick={() => handleChangeTab(1)}>
						{t('PROCESSED')}
					</NewTab>
					<NewTab onClick={() => handleChangeTab(2)}>
						{t('PROCESSING(PROCESSED)')}
					</NewTab>
				</NewTabList>
			</NewTabs>
			{displayedTab === 1 ? <Processed watch={watch} /> : <Pending />}
			{/* </div> */}
		</S.Container>
	)
}
export default Award
