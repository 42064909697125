import axios from 'axios'
import * as _ from 'lodash'
// GET api/ProgramRules/Platform
export const GetProduct = (sku, barcode, name, category, family, manufacturer, brand, typeOfPoints, typeOfRedeem, active, page) => {
	return axios
		.get(
			`/Product/GetProductsByPartner?sku=${sku}&barcode=${barcode}&name=${name}&category=${category}&family=${family}&manufacturer=${manufacturer}&brand=${brand}&typeOfPoints=${typeOfPoints}&typeOfRedeem=${typeOfRedeem}&active=${active}&page=${page}`,
		)
		.then(response => response.data)
}

export const GetProductById = id => {
	return axios.get(`/Product/${id}`).then(response => response.data)
}

export const GetAllProduct = (name, hasGenerationValue, hasRedeemValue) => {
	return axios
		.get(`/Product/ListProductsByPartner?queryStr=${name}&hasGenerationValue=${hasGenerationValue}&hasRedeemValue=${hasRedeemValue}`)
		.then(response => response.data)
}

export const GetProductByBranches = (branches, name, pageSize, page) => {
	let url = `/Product/GetProductByBranches?`

	if (branches) url += `&"${branches}"`
	if (name) url += `&"${name}"`
	if (pageSize) url += `&pageSize="${pageSize}"`
	if (page) url += `&page="${page}"`

	url = url.replace(/['"]+/g, '')

	return axios.get(url).then(response => response.data)
}

export const UpdateProduct = (id, obj) => {
	return axios.put(`/Product/UpdateProduct/${id}`, obj).then(response => response.data)
}

export const GetProductByDivision = (division, Cashback, productId) => {
	return axios.get(`/Product/GetProductByDivision/${productId}/${division}/${Cashback}`).then(response => response.data)
}

export const UpdateProductByDivision = (id, obj) => {
	return axios.post(`/Product/UpdateProductByDivision/${id}`, obj).then(response => response.data)
}

export const CreateProductByMultipleDivisions = (id, obj) => {
	return axios.post(`/Product/CreateProductByMultipleDivisions/${id}`, obj).then(response => response.data)
}

export const UpdateProductByDivisionCashback = (id, obj) => {
	return axios.post(`/Product/UpdateProductByDivision/${id}`, obj).then(response => response.data)
}

export const CalculateValue = (productId, price, type, value, typeOfRedeem, redeemValue) => {
	value = !_.isEmpty(value) || _.isNumber(value) ? value : 0
	redeemValue = !_.isEmpty(redeemValue) || _.isNumber(redeemValue) ? redeemValue : 0
	price = !_.isEmpty(price) || _.isNumber(price) ? price : 0
	return axios
		.get(
			`/Product/CalculateValue?productId=${productId}&price=${price}&typeOfPoints=${type}&pointsValue=${value}&typeOfRedeem=${typeOfRedeem}&redemptionPoints=${redeemValue}`,
		)
		.then(response => response.data)
}

export const CalculateParametrizationValue = (partnerId, price, type, value, typeOfRedeem, redeemValue) => {
	value = !_.isEmpty(value) || _.isNumber(value) ? value : 0
	redeemValue = !_.isEmpty(redeemValue) || _.isNumber(redeemValue) ? redeemValue : 0
	price = !_.isEmpty(price) || _.isNumber(price) ? price : 0

	let url = `/Product/CalculateValueBase?partnerId=${partnerId}&price=${price}&pointsValue=${value}&typeOfRedeem=${typeOfRedeem}&redemptionPoints=${redeemValue}`

	if (type) url += `&typeOfPoints=${type}`

	return axios.get(url).then(response => response.data)
}
export const GetCategory = (partnerId, name, page) => {
	return axios.get(`/Product/Category/${partnerId}/${name}/${page}`).then(response => response.data)
}

export const GetBrand = (partnerId, name, page) => {
	return axios.get(`/Product/Brand/${partnerId}/${name}/${page}`).then(response => response.data)
}

export const GetManufacturer = (partnerId, name, page) => {
	return axios.get(`/Product/Manufacturer/${partnerId}/${name}/${page}`).then(response => response.data)
}

export const GetFamily = (partnerId, name, page) => {
	return axios.get(`/Product/Family/${partnerId}/${name}/${page}`).then(response => response.data)
}

export const CalculateMemoryValue = (id, typeOfPoints, value) => {
	return axios.get(`/Product/CalculateMemoryValue?productId=${id}&typeOfPoints=${typeOfPoints}&value=${value}`).then(response => response.data)
}

export const GetFilials = (id, name, pageSize, page) => {
	return axios.get(`/Product/${id}/GetBranches?name=${name}&pageSize=${pageSize}&page=${page}`).then(response => response.data)
}

export const GetTypeOfCashback = () => {
	return axios.get(`/Product/GetTypeOfCashback`).then(response => response.data)
}

export const GetCalculateCashback = (price, typeOfCashback, cashbackPercentual) => {
	return axios
		.get(`/Product/CalculateCashback?price=${price}&typeOfCashback=${typeOfCashback}&cashbackPercentual=${cashbackPercentual}`)
		.then(response => response.data)
}

export const GetBranchesPlatform = async ({ id, name, pageSize, page }) => {
	const params = new URLSearchParams()

	if (name) {
		params.append('name', name)
	}
	params.append('pageSize', pageSize)
	params.append('page', page)

	const url = `/Product/${id}/GetBranchesPlatform?${params.toString()}`

	try {
		const response = await axios.get(url)
		return response
	} catch (error) {
		return error?.response || []
	}
}

export const GetDivisionByProduct = async (Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page) => {
	let queryParams = `ProductId=${ProductId}&PartnerId=${PartnerId}&TypeOfBenefit=${TypeOfBenefit}&PageSize=${PageSize}&Page=${Page}`
	if (Name) {
		queryParams += `&Name=${Name}`
	}
	const url = `/Product/GetDivisionByProduct?${queryParams}`

	try {
		const response = await axios.get(url)
		return response
	} catch (error) {
		return error?.response || []
	}
}

export const UpdateProductByDivisionParams = async (id, obj) => {
	try {
		const response = await axios.put(`/Product/UpdateProductByDivision/${id}`, obj)
		return response
	} catch (error) {
		return error?.response || []
	}
}

export const GetTypeORedeem = async () => {
	try {
		const response = await axios.get(`/Product/GetTypeORedeem`)

		return response
	} catch (error) {
		return error?.response || []
	}
}

export const ValidateByDivisions = async obj => {
	try {
		const response = await axios.post(`/Product/ValidateStatusProductByDivisions`, obj)

		return response
	} catch (error) {
		return error?.response || []
	}
}

export const GetDivisionByGrouper = async ({ id, name, pageSize, page }) => {
	const params = new URLSearchParams()

	if (name) {
		params.append('name', name)
	}

	params.append('Id', id)
	params.append('pageSize', pageSize)
	params.append('page', page)

	const url = `/Grouper/GetDivisionByGrouper?${params.toString()}`

	try {
		const response = await axios.post(url)
		return response
	} catch (error) {
		return error?.response || []
	}
}
