/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal } from "@mui/material";
import { MuiStack } from "../Stack";
import { MuiTypography } from "../Typography";
import { MuiBox } from "../Box";
import { Button } from "../Buttons";
import { AttentionIcon } from "../Icons";
var style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
};
export function MuiModal(_a) {
    var actionButton = _a.actionButton, titleIcon = _a.titleIcon, open = _a.open, onClose = _a.onClose, defaultColor = _a.defaultColor, description = _a.description, customDescription = _a.customDescription, typeButtonSubmit = _a.typeButtonSubmit, alertIcon = _a.alertIcon, onConfirm = _a.onConfirm, color = _a.color, form = _a.form, titleHelp = _a.titleHelp, children = _a.children, customTestId = _a.customTestId, props = __rest(_a, ["actionButton", "titleIcon", "open", "onClose", "defaultColor", "description", "customDescription", "typeButtonSubmit", "alertIcon", "onConfirm", "color", "form", "titleHelp", "children", "customTestId"]);
    return (_jsxs(MuiBox, __assign({ "data-testid": customTestId !== null && customTestId !== void 0 ? customTestId : "modal-group", sx: {
            ".makeStyles-paper-1": {
                borderRadius: " 5px !important",
                backgroundColor: "#fff",
            },
        } }, { children: [_jsx(MuiBox, { children: actionButton }), _jsx(Modal, __assign({ open: open, onClose: onClose }, { children: _jsxs(MuiBox, __assign({ sx: __assign(__assign(__assign({}, style), { background: "#fff", borderRadius: "5px!important" }), props === null || props === void 0 ? void 0 : props.sx) }, { children: [alertIcon ? (_jsx(MuiStack, __assign({ justifyContent: "center", alignItems: "center", mb: "25px" }, { children: _jsx(AttentionIcon, { size: 60, color: "#C8C8C8" }) }))) : null, _jsxs(MuiStack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", mb: 3 }, { children: [_jsx(MuiTypography, __assign({ variant: "body1", sx: { color: defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F18932" } }, { children: props === null || props === void 0 ? void 0 : props.title })), !props.title && (_jsxs(MuiStack, __assign({ width: "100%" }, { children: [_jsx(MuiTypography, __assign({ id: "modal-modal-description", sx: {
                                                whiteSpace: "pre-line",
                                                textAlign: "center",
                                                fontWeight: 300,
                                                color: color !== null && color !== void 0 ? color : "#F18932",
                                            } }, { children: description })), customDescription && (_jsx(MuiTypography, __assign({ id: "modal-modal-description", sx: {
                                                whiteSpace: "pre-line",
                                                textAlign: "center",
                                                fontWeight: 300,
                                            } }, { children: customDescription })))] }))), (props === null || props === void 0 ? void 0 : props.title) && _jsx(Button, { typeVariation: "CloseButton", text: "Fechar", onClick: onClose })] })), children, !props.title && !titleHelp && (_jsxs(MuiStack, __assign({ direction: "row", alignItems: "center", justifyContent: "center", spacing: 2 }, { children: [_jsx(Button, { "data-testid": "modal-button-cancell", typeVariation: "CancelButton", onClick: onClose, text: "N\u00E3o", sx: {
                                        borderRadius: "8px",
                                        border: "1px solid #C8C8C8",
                                        color: "#EB6C61",
                                        padding: "9px 30px",
                                    } }), _jsx(Button, { "data-testid": "modal-button-confirm", typeVariation: "ConfirmButton", text: "Sim", form: form, type: typeButtonSubmit !== null && typeButtonSubmit !== void 0 ? typeButtonSubmit : "button", onClick: onConfirm, sx: {
                                        borderRadius: "8px!important",
                                        padding: "9px 30px!important",
                                    } })] }))), titleHelp && (_jsx(MuiBox, __assign({ sx: {
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            } }, { children: _jsx(Button, { "data-testid": "modal-button-close", typeVariation: "CloseButton", text: "Fechar", onClick: onClose, sx: {
                                    width: "30%",
                                    borderRadius: "20px !important",
                                    textTransform: "capitalize !important",
                                    color: "#EB6C61 !important",
                                } }) })))] })) }))] })));
}
