/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import { HideLoader } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import moment from 'moment'


import Edit from '@material-ui/icons/Edit'
import MaterialTable from 'material-table'
import TextField from '@material-ui/core/TextField'
import { Search } from '@material-ui/icons'
import {
	Grid,
	TablePagination,
	withStyles,
	MenuItem,
	Icon,
	Tooltip,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import ColoredCircle from 'components/ColoredCircle/ColoredCircle.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Section from 'components/Section'
import { CustomFormField } from 'components/CustomTextField'

import * as Style from '../styles'
import PdvContext from '../context'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import { GetPdvs } from '../../../redux/api/pdv'
import { formatDate, getProgramTitle } from '../../../utils/utils'
import OperatorModal from './components/OperatorModal'
import { yupResolver } from '@hookform/resolvers'
import CustomDateNew from 'components/CustomDateNew'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			fontWeight: '400',
			color: 'hls(0, 0, 0, 0.55)',
			lineHeight: 1.2,
			zIndex: 1,
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)',
			'&:not(.Mui-disabled)': {
				marginTop: '-7px',
				marginLeft: '-5px',
			},
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			fontSize: '16px',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			cursor: 'default',
		},
		'& fieldset': {
			color: 'rgba(0, 0, 0, 0.7)',
			borderColor: 'hsl(0,0%,80%)',
			'& .hover': {
				borderColor: 'hsl(0,0%,80%)',
			},
			'& legend': {
				width: '0px',
			},
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: getThemeColorFromLocalStorage() &&
						getThemeColorFromLocalStorage().structure &&
						getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: "#fff !important",
				},
				'&.Mui-focused fieldset': {
					borderColor: getThemeColorFromLocalStorage() &&
						getThemeColorFromLocalStorage().structure &&
						getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: "#fff !important",
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(3,3%,95%)',
			'& fieldset': {
				'& legend': {
					width: '0px',
				},
			},
		},
		'& .PrivateNotchedOutline-legendLabelled-583': {
			width: '0px !important',
		},
	},
})(TextField)


const customSelectStyles = {
	control: (base, state) => ({
		...base,
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			"& .MuiAutocomplete-listbox": {
				"& :hover": {
					color: "#ffffff",
					backgroundColor: getThemeColorFromLocalStorage() &&
						getThemeColorFromLocalStorage().structure &&
						getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: "#fff !important"
				},
				"& li": {
					backgroundColor: "#ffffff",
				}
			},
		},
	})
);

const CustomPopper = function (props) {
	const classesPoper = useStyles();
	return <Popper {...props} className={classesPoper.root} placement="bottom" />;
};


const status = [
	{ key: 0, label: 'Inativos' },
	{ key: 1, label: 'Ativos' },
]
const initialPagination = {
	currentPage: 0,
	pageCount: 1,
	pageSize: 10,
	recordCount: 1,
	filter: {
		partnerId: '',
		name: '',
		status: '',
		endDate: null,
		startDate: null,
	},
}

export const validation = Yup.object().shape({})

function PdvList({ classes }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [pagination, setPagination] = useState(initialPagination)
	const { setPdv, setEditing, editing, setSwitchComp } =
		useContext(PdvContext)
	const [loading, setLoading] = useState(false)
	const [list, setList] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const [itemId, setItemId] = useState('')
	const [themeColor, setThemeColor] = useState()
	const [selectedStatus, setSelectedStatus] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const { handleSubmit, control, reset, watch, setValue } = useForm({
		defaultValue: {
			currentPage: 0,
			pageCount: 1,
			pageSize: 10,
			recordCount: 1,
			filter: {
				partnerId: '',
				name: '',
				status: '',
				endDate: null,
				startDate: null,
			},
		},
		resolver: yupResolver(validation),
		mode: 'onChange',
	})

	useEffect(() => {
		dispatch(HideLoader())
		requestApi()
	}, [pagination.currentPage + 1, pagination.pageSize, pagination.filter])

	useEffect(() => {
		var currentUrl = window.location.pathname

		if (currentUrl.toString() === '/Pdvs/new') {
			setSwitchComp('form')
		}
	}, [])

	useEffect(() => {
		let startDate = watch('filter.startDate')
		let endDate = watch('filter.endDate')
		console.log(startDate, endDate)
	}, [watch('filter.startDate'), watch('filter.endDate')])

	useEffect(() => { }, [])

	function handleModal(id) {
		setItemId(id)
		setOpenModal(true)
	}

	const valid = (current) => {
		const today = moment()
		return (
			current.isAfter(watch('filter.startDate')) &&
			current.isBefore(today)
		)
	}

	const focousOut = () => {
		if (!moment(watch('filter.endDate'), 'DD/MM/YYYY').isValid()) {
			setValue('filter.startDate', '')
		}
		if (!valid(moment(watch('filter.endDate')), 'DD/MM/YYYY')) {
			setValue('filter.endDate', '')
		}
	}

	const validationDate = (currentDate) => {
		const today = moment()

		if (moment(watch('filter.endDate'), 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(watch('filter.endDate'))) {
				return false
			}
		}

		return currentDate.isBefore(today)
	}

	async function requestApi() {
		try {
			const filter = {
				...pagination.filter,
				startDate: formatDate(pagination.filter.startDate)
					? formatDate(pagination.filter.startDate)
					: '',
				endDate: formatDate(pagination.filter.endDate)
					? formatDate(pagination.filter.endDate)
					: '',
			}
			setLoading(true)
			let startDate = filter.startDate
				? moment(filter.startDate).format('YYYY-MM-DD')
				: ''
			let endDate = filter.endDate
				? moment(filter.endDate).format('YYYY-MM-DD')
				: ''
			let status = ''

			if (filter.status !== '') {
				status = filter.status
			}

			const response = await GetPdvs(
				'',
				filter.name,
				startDate,
				endDate,
				status,
				pagination.pageSize,
				pagination.currentPage + 1,
			)
			setPagination((prevState) => ({
				...prevState,
				recordCount: response.recordCount,
				pageSize: response.pageSize,
			}))
			setList(response.results)
		} catch (error) {
		} finally {
			setLoading(false)
		}
	}

	function handleNew() {
		setPdv()
		setEditing(true)
		setSwitchComp('form')
	}

	function handleDetail(PdvId) {
		setPdv(PdvId)
		setEditing(false)
		setSwitchComp('form')
	}

	function handleEdit(PdvId) {
		setPdv(PdvId)
		setEditing(true)
		setSwitchComp('form')
	}

	function handleCleanFilter() {
		setValue('filter.startDate', '')
		setValue('filter.endDate', '')
		setSelectedStatus(null)
		reset({
			defaultValue: {
				currentPage: 0,
				pageCount: 1,
				pageSize: 10,
				recordCount: 1,
				filter: {
					partnerId: '',
					name: '',
					status: '',
					endDate: null,
					startDate: null,
				},
			},
		})
		setValue('defaultValue.filter.startDate', null)
		setValue('defaultValue.filter.endDate', null)
		setValue('defaultValue.filter.status', '')
		setValue('defaultValue.filter.partnerId', '')
		setValue('defaultValue.filter.name', '')
		setPagination({
			currentPage: 0,
			pageCount: 1,
			pageSize: 10,
			recordCount: 1,
			filter: {
				partnerId: '',
				name: '',
				status: '',
				endDate: null,
				startDate: null,
			},
		})
	}

	const handleSelectedStatus = (value, event) => {
		event(value.key)
		setSelectedStatus(value)
	}

	function sendForm(formData) {
		let data = formData.filter
		setPagination((prevState) => ({
			...prevState,
			filter: {
				...data,
			},
		}))
	}

	return (
		<PdvContext.Provider
			value={{
				setSwitchComp,
				setPdv,
				setEditing,
				editing,
			}}
		>
			<DocumentTitle title={getProgramTitle()} />
			<OperatorModal
				open={openModal}
				itemId={itemId}
				closeByClick={() => setOpenModal(false)}
			/>
			<Grid
				style={{
					alignItems: 'center',
					display: 'flex',
					height: '40px',
					marginBottom: '15px'
				}}
			>
				<Section title={'PDV Virtual'} style={{ flexGrow: 1 }} />
				<div style={{ marginLeft: '0.875rem' }}>
					<Button
						size="sm"
						variant="contained"
						color="greenButtonColor"
						onClick={() => handleNew()}
					>
						Criar
					</Button>
				</div>
			</Grid>
			<Style.InnerContainer container themeColor={themeColor}>
				<form
					onSubmit={handleSubmit(sendForm)}
					style={{ width: '100%' }}
					autoComplete="off"
				>
					<Style.SearchBox>
						<Style.GroupDate
							xs={12}
							sm={12}
							md={5}
						>
							<Controller
								as={
									<CustomDateNew
										enableDates={true}
										label={t('DATETIME_FROM')}
										value={pagination.filter.startDate}
										minDate={false}
										locale="pt-br"
										maxDate={new Date().getTime()}
										isValidDate={validationDate}
										style={{ width: '100%' }}
										fullWidth
									/>
								}
								name={'filter.startDate'}
								control={control}
								onChange={(event) => {
									return event[0]
								}}
							/>

							<Controller
								as={
									<CustomDateNew
										enableDates={true}
										label={t('DATETIME_TO')}
										value={pagination.filter.endDate}
										locale={'pt-br'}
										maxDate={new Date().getTime()}
										isValidDate={valid}
										onBlur={focousOut}
										fullWidth
										style={{ width: '100%' }}
									/>
								}
								name={'filter.endDate'}
								control={control}
								onChange={(event) => {
									return event[0]
								}}
							/>
						</Style.GroupDate>
						<GridItem
							xs={12}
							sm={12}
							md={5}
							className="search-input"
						>
							<Controller
								as={
									<CustomFormField
										id="name"
										name="name"
										label={
											<CustomRequiredField
												disabled
												value="Identificação do PDV"
											/>
										}
										style={{
											backgroundColor: '#ffffff',
										}}
										variant="outlined"
										size='small'
										fullWidth
									/>
								}
								defaultValue=""
								name="filter.name"
								control={control}
							/>
						</GridItem>
						<GridItem
							xs={12}
							sm={12}
							md={2}
							className="search-input"
						>
							<Controller
								onChange={([, data]) => data}
								name="filter.status"
								control={control}
								render={({ onChange }) => (
									<Autocomplete
										id="combo-box-demo"
										options={status}
										getOptionLabel={(option) => option.label}
										PopperComponent={CustomPopper}
										disableClearable={true}
										value={selectedStatus}
										renderInput={(params) => (
											<div ref={params.InputProps.ref}>
												<CustomFormField
													style={{
														backgroundColor: '#ffffff',
														width: '100%',
													}}
													{...params}
													noOptionsMessage={() =>
														t('RESULTS_NOT_FOUND')
													}
													label={t('FIELD_STATUS')}
													variant="outlined"
													size="small"
												/>
											</div>
										)}
										onChange={(e, data) => handleSelectedStatus(data, onChange)}
									/>
								)}
							/>
						</GridItem>
						<Style.GroupButtons
							xs={12}
							sm={12}
							style={{ marginTop: 20 }}
						>
							<Button
								variant="contained"
								color="greenBorderButtonColor"
								size="sm"
								style={{ margin: 0 }}
								startIcon={
									<i
										style={{
											fontSize: 14,
											padding: '2px 0',
										}}
									/>
								}
								onClick={() => handleCleanFilter()}
							>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>
							<Button
								color="greenButtonColor"
								size="sm"
								type="submit"
								style={{ margin: 0 }}
							>
								{t('BUTTON_FIND')}
							</Button>
						</Style.GroupButtons>
					</Style.SearchBox>
				</form>
				<Style.ListGrid>
					<Style.GridContent>
						<MaterialTable
							noToolbar
							components={{
								Pagination: (props) => {
									return (
										<TablePagination
											{...props}
											rowsPerPageOptions={[
												10, 20, 30, 50, 100,
											]}
											count={pagination.recordCount}
											page={pagination.currentPage}
											rowsPerPage={pagination.pageSize}
											onChangePage={(e, page) =>
												setPagination((prevState) => ({
													...prevState,
													currentPage: page,
												}))
											}
										/>
									)
								},
							}}
							title=""
							options={{
								emptyRowsWhenPaging: false,
								search: false,
								toolBar: false,
								sorting: false,
								draggable: false,
								pageSize: pagination.pageSize,
								headerStyle: {
									color:
										themeColor &&
										themeColor.titles &&
										themeColor.titles.highlight &&
										themeColor.titles.highlight.textColor,
									fontWeight: '700',
									fontSize: '15px !important',
									lineHeight: '1.2',
									background: '#E9E9E9 ',
									textAlign: 'left !important',
								},
							}}
							columns={[
								{
									title: 'Data de criação',
									field: 'createdAt',
									headerStyle: {
										textAlign: 'left',
										fontSize: '15px',
									},
									render: (props) => (
										<div>
											{moment(props.createdAt).format(
												'L',
											)}
										</div>
									),
								},
								{
									title: 'Identificação do PDV',
									field: 'name',
									headerStyle: {
										textAlign: 'left',
										fontSize: '15px',
									},
								},
								{
									title: 'Status',
									field: 'warning',
									headerStyle: {
										textAlign: 'left',
										fontSize: '15px',
									},
									render: (props) => (
										<div>
											{props &&
												props.status.key == '1' ? (
												<div
													style={{
														display: 'flex',
														lineHeight: 0.9,
														marginTop: '5px',
													}}
												>
													<ColoredCircle
														color="success"
														size={12}
													/>
													{t(props.status.value)}
												</div>
											) : (
												<div
													style={{
														display: 'flex',
														lineHeight: 0.9,
														marginTop: '5px',
													}}
												>
													<ColoredCircle
														color="warning"
														size={12}
													/>
													{t(props.status.value)}
												</div>
											)}
										</div>
									),
								},
								{
									title: 'Ações',
									field: 'actions',
									headerStyle: {
										textAlign: 'left',
										fontSize: '15px',
									},
									render: (props) => (
										<>
											<Tooltip
												arrow
												placement="top"
												title={t('Visualizar o operador logado no momento')}
											>
												<Style.GridButton
													justIcon
													size="sm"
													color="greenButtonColor"
													disabled={!props.hasActiveLogin}
													onClick={() =>
														handleModal(props.id)
													}
												>
													<Icon
														className="icon-online"
														style={{
															fontSize: '20px',
															position: 'relative',
															top: '-8px',
														}}
													/>
												</Style.GridButton>
											</Tooltip>
											<Style.GridButton
												justIcon
												size="sm"
												color="greenButtonColor"
												onClick={() =>
													handleDetail(props.id)
												}
											>
												<Tooltip
													arrow
													placement="left"
													title={t('BUTTON_VIEW')}
												>
													<Search className={"op-icon"} />
												</Tooltip>
											</Style.GridButton>

											<Style.GridButton
												justIcon
												size="sm"
												color="greenButtonColor"
												onClick={() =>
													handleEdit(props.id)
												}
											>
												<Tooltip
													arrow
													placement="left"
													title={t('BUTTON_EDIT',)}
												>
													<Edit
														className={"op-icon"}
													/>
												</Tooltip>
											</Style.GridButton>

										</>
									),
								},
							]}
							data={list}
							onChangeRowsPerPage={(e) =>
								e !== pagination.pageSize
									? setPagination((prevState) => ({
										...prevState,
										pageSize: e,
									}))
									: ''
							}
							localization={{
								header: {
									actions: 'Ações',
								},
								pagination: {
									labelRowsSelect: 'linhas',
									firstTooltip: '',
									lastTooltip: '',
									nextTooltip: '',
									previousTooltip: '',
								},
								body: {
									emptyDataSourceMessage:
										'Nenhum registro encontrado.',
								},
							}}
							isLoading={loading}
						/>
					</Style.GridContent>
				</Style.ListGrid>
			</Style.InnerContainer>
		</PdvContext.Provider>
	)
}

export default withStyles()(withStyles()(PdvList))
