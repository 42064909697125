import { TextField } from '@material-ui/core'
import React from 'react'
import MaskedInput from 'react-text-mask'
import * as yup from 'yup'
import { makeStyles } from '@material-ui/core/styles'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
	customWidth: {
		maxWidth: 800,
		background: '#000000 0% 0% no-repeat padding-box',
		opacity: '0.85',
		justifyContent: 'center',
		alignItems: 'center',
	},
}))

export const useStylesNew = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	customWidth: {
		maxWidth: 900,
		background: '#282828 0% 0% no-repeat padding-box',
		textAlign: 'left',
		font: '0.75rem Roboto',
		letterSpacing: '0px',
		color: '#fff',
		opacity: '1',
	},
	customTooWidth: {
		background: '#f0efed 0% 0% no-repeat padding-box',
		textAlign: 'left',
		font: '0.75rem Roboto',
		color: '#636363',
		opacity: 1,
	},
	customArrow: {
		color: '#282828',
		opacity: '1',
	},
}))

export const TooltipStyles = makeStyles((theme) => ({
	button: {
		margin: theme.spacing(1),
	},

	noMaxWidth: {
		maxWidth: 'none',
	},
}))
export const DateMaskCustom = (props) => {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}

export const MaskedInputPercentage = (props) => {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '%']}
			placeholderChar={'\u2000'}
		/>
	)
}
export const MaskedInputNunber = (props) => {
	const { inputRef, ...other } = props
	//somente numeros
	const mask = createNumberMask({
		prefix: '',
		suffix: '',
		includeThousandsSeparator: false,
		thousandsSeparatorSymbol: '',
		allowDecimal: false,
		decimalSymbol: '',
		decimalLimit: 0,
		integerLimit: 3,
		allowNegative: false,
		allowLeadingZeroes: false,
	})

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={mask}
			style={{ border: 'none' }}
			placeholderChar={'\u2000'}
		/>
	)
}

export const validateIndication = yup.object().shape({
	description: yup.string().required('Campo obrigatório'),
	creditReason: yup.string().required('Campo obrigatório'),
	reservation: yup.string().required('Campo obrigatório'),
	switcherValue: yup.boolean(),
	giftValue: yup.string(),
	anticipationFee: yup.string().when('switcherValue', {
		is: true,
		then: yup.string().required('Campo obrigatório'),
	}),
	radioValue: yup.string(),

	periodDays: yup.string().when('radioValue', {
		is: '1',
		then: yup.string().required('Obrigatório'),
	}),

	datePeriod: yup.string().when('radioValue', {
		is: '2',
		then: yup.string().required('Campo obrigatório'),
	}),

	formMethod: yup.boolean(),

	definedValue: yup.string(),
})

export const DateCustom = (props) => {
	const { inputRef, ...other } = props
	const classes = useStyles()

	return (
		<TextField
			{...other}
			label="Birthday"
			type="date"
			variant="outlined"
			className={classes.textField}
		/>
	)
}

export const ValidateExtension = (file) => {
	var caminhoArquivo = file.value
	var extensoesPermitidas = /(.CSV|.txt)$/i
	if (!extensoesPermitidas.exec(caminhoArquivo)) {
		return false
	} else {
		return true
	}
}

export const handleCheckInvalid = (list) => {
	let cont = 0
	list.forEach((element) => {
		if (element.status === 2 && element.isRemoved === false) {
			cont = cont + 1
		}
	})

	return cont
}
