import styled from 'styled-components';
import GridItem from 'components/Grid/GridItem.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
export const ContainerTitle = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

`

export const Title = styled.div`
	font-weight: bold;
`


export const Info = styled.div`
	font-weight: bold;
	color: ${({ themeColor }) => themeColor &&
		themeColor.titles &&
		themeColor.titles
			.highlight &&
		themeColor.titles
			.highlight.textColor &&
		themeColor.titles
			.highlight.textColor
	};

`

export const GroupButtons = styled(GridItem)`
	display: flex;
	gap: 15px;
	justify-content: flex-end;
`

export const GroupContainer = styled(GridContainer)`
	padding: 20px 0px;
    background: rgb(246, 246, 246);
    margin: auto auto 0px !important;
    border-radius: 5px;
    width: 100% !important;

	@media only screen and (max-width: 600px) {
		padding: 20px 15px;
		gap: 20px;
	}

`


