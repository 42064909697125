import _ from 'lodash'

export const handleResetAddress = ({ setValue, address }) => {
	if (!_.isEmpty(address)) {
		setValue('businessDivisionCountry', {
			label: address?.country?.name,
			value: address?.country?.id,
		})
		setValue('businessDivisionCEP', address?.zipCode || '')

		setValue('businessDivisionUF', {
			label: address?.state?.name,
			value: address?.state?.id,
		})
		setValue('businessDivisionCity', {
			label: address?.city?.name,
			value: address?.city?.id,
		})

		setValue('businessDivisionAddress', address?.location || '')
		setValue('businessDivisionDistrict', address?.district || '')
		setValue('businessDivisionAddressNumber', address?.number || '')
		setValue('businessDivisionAditionalInfo', address?.aditionalInfo || '')
	}
}

export const handleReset = ({ setValue }) => {
	setValue('businessDivisionCountry', {
		label: '',
		value: '',
	})
	setValue('businessDivisionCEP', '')

	setValue('businessDivisionUF', {
		label: '',
		value: '',
	})
	setValue('businessDivisionCity', {
		label: '',
		value: '',
	})

	setValue('businessDivisionAddress', '')
	setValue('businessDivisionDistrict', '')
	setValue('businessDivisionAddressNumber', '')
	setValue('businessDivisionAditionalInfo', '')
}
