import React, { Fragment, useState } from 'react'

//@ components
import { DefaultProductData } from './Functions/utils'
import DivisionTable from './components/DivisionTable'
import DivisionOptions from '../DivisionOptions'

const DivisionTab = props => {
	const [selectedDivision, setSelectedDivision] = useState([])
	const [selectedDivisionId, setSelectedDivisionId] = useState([])

	const sharedProps = {
		...props,
		selectedDivision,
		selectedDivisionId,
		setSelectedDivision,
		setSelectedDivisionId,
	}
	return (
		<Fragment>
			<DivisionOptions {...sharedProps} />
			<DivisionTable {...sharedProps} />
		</Fragment>
	)
}

DivisionTab.defaultProps = {
	productData: DefaultProductData,
}

export default DivisionTab
