import React, { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router-dom'

// components
import UserBox from './comp/UserBox'

import compose from 'utils/compose'

import styles from './styles.module.scss'
import NotificationBox from './comp/NotificationBox'
import { GetConnectionId } from 'redux/api/signalr'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const Banner = (props) => {
	const [alerts, setAlerts] = useState('')
	const [themeColor, setThemeColor] = useState({})

	useEffect(() => {
		;(async () => {
			setThemeColor(getThemeColorFromLocalStorage())
			const connectId = await GetConnectionId()
			connectId &&
				connectId.forEach((item) => {
					item.type.value === 'ALERT' && setAlerts({ alerts: item })
				})
		})()
	}, [])

	const getBgUrl = () =>
		(themeColor &&
			themeColor.images &&
			themeColor.images.internalPages &&
			themeColor.images.internalPages.headerBackground &&
			themeColor.images.internalPages.headerBackground.url) ||
		''

	const getHeaderUrl = () =>
		(themeColor &&
			themeColor.images &&
			themeColor.images.internalPages &&
			themeColor.images.internalPages.headerLogo &&
			themeColor.images.internalPages.headerLogo.url) ||
		''

	const getHighLight = () =>
		(themeColor && themeColor.titles && themeColor.titles.highlight) ||
		`#fff`

	return (
		<div
			className={styles.parent}
			style={{
				backgroundImage: `url(${getBgUrl()})`,
			}}
		>
			<div className={styles.container}>
				<div className={styles.logoBox}>
					<div
						style={{
							width: '200px',
							height: '100px',
							zIndex: '10',
							marginLeft: '40px',
						}}
					>
						<NavLink to="/home">
							<img
								className={styles.logo}
								src={getHeaderUrl()}
								alt="Logo"
							/>
						</NavLink>
					</div>
				</div>

				{/* <SearchBox /> */}
				<div
					className={styles.userBox}
					style={{
						borderRadius: '4px',
						color: getHighLight().textColor,
					}}
				>
					<UserBox userData={props.userData} />
				</div>
				<div
					className={styles.userBox}
					style={{
						borderRadius: '4px',
						color: getHighLight(),
						marginLeft: '20px',
					}}
				>
					<NotificationBox
						userData={props.userData}
						alerts={alerts}
					/>
				</div>
			</div>
		</div>
	)
}
// class Banner extends React.Component {
// 	constructor(props) {
// 		super(props)
// 		this.state = {
// 			alerts: '',
// 			themeColor: (() => {
// 				if (getThemeColorFromLocalStorage()) {
// 					return getThemeColorFromLocalStorage()
// 				}
// 				return {}
// 			})(),
// 		}
// 	}

// 	async componentDidMount() {
// 		const connectId = await GetConnectionId()

// 		this.setState({ themeColor: getThemeColorFromLocalStorage() })

// 		if (connectId) {
// 			connectId.forEach((item) => {
// 				if (item.type.value === 'ALERT') {
// 					this.setState({ alerts: item })
// 				}
// 			})
// 		}
// 	}

// 	getTheme() {
// 		return this.state && this.state.themeColor
// 	}

// 	getBgUrl() {
// 		return (
// 			(themeColor &&
// 				themeColor.images &&
// 				themeColor.images.internalPages &&
// 				themeColor.images.internalPages.headerBackground &&
// 				themeColor.images.internalPages.headerBackground.url) ||
// 			''
// 		)
// 	}

// 	getHeaderUrl() {
// 		return (
// 			(themeColor &&
// 				themeColor.images &&
// 				themeColor.images.internalPages &&
// 				themeColor.images.internalPages.headerLogo &&
// 				themeColor.images.internalPages.headerLogo.url) ||
// 			''
// 		)
// 	}

// 	getHighLight() {
// 		return (
// 			(themeColor &&
// 				themeColor.titles &&
// 				themeColor.titles.highlight) ||
// 			`#fff`
// 		)
// 	}

// 	render() {
// 		return (
// 			<div
// 				className={styles.parent}
// 				style={{
// 					backgroundImage: `url(${this.getBgUrl()})`,
// 				}}
// 			>
// 				<div className={styles.container}>
// 					<div className={styles.logoBox}>
// 						<div
// 							style={{
// 								width: '150px',
// 								zIndex: '10',
// 								marginLeft: '40px',
// 							}}
// 						>
// 							<NavLink to="/home">
// 								<img
// 									className={styles.logo}
// 									src={this.getHeaderUrl()}
// 									alt="Logo"
// 								/>
// 							</NavLink>
// 						</div>
// 					</div>

// 					{/* <SearchBox /> */}
// 					<div
// 						className={styles.userBox}
// 						style={{
// 							borderRadius: '4px',
// 							color: this.getHighLight().textColor,
// 						}}
// 					>
// 						<UserBox userData={this.props.userData} />
// 					</div>
// 					<div
// 						className={styles.userBox}
// 						style={{
// 							borderRadius: '4px',
// 							color: this.getHighLight(),
// 							marginLeft: '20px',
// 						}}
// 					>
// 						<NotificationBox
// 							userData={this.props.userData}
// 							alerts={this.state.alerts}
// 						/>
// 					</div>
// 				</div>
// 			</div>
// 		)
// 	}
// }

export default compose(withRouter)(Banner)
