/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal, Paper } from "@mui/material";
import OrganogramaTemplate from "./components/OrganogramaTemplate";
import { MuiBox } from "../Box";
export default function Organograma(_a) {
    var title = _a.title, divisionsData = _a.divisionsData, handleClick = _a.handleClick, defaultColor = _a.defaultColor, divisionsErrors = _a.divisionsErrors, titleTooltipEdit = _a.titleTooltipEdit, titleFullScreenExit = _a.titleFullScreenExit, titleFullScreen = _a.titleFullScreen, titleZoomIn = _a.titleZoomIn, titleZoomOut = _a.titleZoomOut, hasBorder = _a.hasBorder, rest = __rest(_a, ["title", "divisionsData", "handleClick", "defaultColor", "divisionsErrors", "titleTooltipEdit", "titleFullScreenExit", "titleFullScreen", "titleZoomIn", "titleZoomOut", "hasBorder"]);
    var _b = useState(1), zoomLevel = _b[0], setZoomLevel = _b[1];
    var _c = useState(0), panX = _c[0], setPanX = _c[1];
    var _d = useState(-60), panY = _d[0], setPanY = _d[1];
    var _e = useState(false), isDragging = _e[0], setIsDragging = _e[1];
    var _f = useState(false), handleFullScreen = _f[0], setHandleFullScreen = _f[1];
    var getErrorsByDivision = function (division) {
        if ((divisionsErrors === null || divisionsErrors === void 0 ? void 0 : divisionsErrors.length) < 1)
            return [];
        var warnings = [];
        var criticalErrors = [];
        if (divisionsErrors) {
            divisionsErrors.forEach(function (item) {
                if ((item === null || item === void 0 ? void 0 : item.name) === (division === null || division === void 0 ? void 0 : division.name) || (item === null || item === void 0 ? void 0 : item.id) === (division === null || division === void 0 ? void 0 : division.id)) {
                    if (item.warnings.length > 0) {
                        warnings = item.warnings.map(function (warning) { return ({
                            message: warning === null || warning === void 0 ? void 0 : warning.message,
                        }); });
                    }
                    if (item.criticalErrors.length > 0) {
                        criticalErrors = item.criticalErrors.map(function (criticalError) { return ({
                            message: criticalError === null || criticalError === void 0 ? void 0 : criticalError.message,
                        }); });
                    }
                }
            });
            return { warnings: warnings, criticalErrors: criticalErrors };
        }
    };
    function validateIfRemoveIndicator(value) {
        var _a;
        if (((_a = value === null || value === void 0 ? void 0 : value.branches) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return true;
        }
        return false;
    }
    function zoomIn() {
        setZoomLevel(Math.min(zoomLevel + 0.1, 2));
    }
    function zoomOut() {
        setZoomLevel(Math.max(zoomLevel - 0.1, 0.5));
    }
    function handleDrag(e) {
        if (e.buttons === 1) {
            setIsDragging(true);
            setPanX(panX + e.movementX);
            setPanY(panY + e.movementY);
        }
        else {
            setIsDragging(false);
        }
    }
    var containerRef = useRef(null);
    useEffect(function () {
        var totalWidth = 0;
        var totalHeight = 0;
        if (containerRef.current) {
            containerRef.current.style.width = "".concat(totalWidth, "px");
            containerRef.current.style.height = "".concat(totalHeight, "px");
        }
    }, [zoomLevel]);
    function toggleFullScreen() {
        setHandleFullScreen(function (prev) {
            return !prev;
        });
    }
    useEffect(function () {
        setPanX(0);
        setPanY(-60);
    }, [zoomLevel]);
    var sharedProps = __assign({ title: title, divisionsData: divisionsData, handleClick: handleClick, defaultColor: defaultColor, divisionsErrors: divisionsErrors, titleTooltipEdit: titleTooltipEdit, titleFullScreenExit: titleFullScreenExit, titleFullScreen: titleFullScreen, titleZoomIn: titleZoomIn, titleZoomOut: titleZoomOut, hasBorder: hasBorder, zoomLevel: zoomLevel, setZoomLevel: setZoomLevel, panX: panX, setPanX: setPanX, panY: panY, setPanY: setPanY, isDragging: isDragging, setIsDragging: setIsDragging, zoomIn: zoomIn, zoomOut: zoomOut, handleDrag: handleDrag, handleFullScreen: handleFullScreen, validateIfRemoveIndicator: validateIfRemoveIndicator, getErrorsByDivision: getErrorsByDivision, toggleFullScreen: toggleFullScreen }, rest);
    return (_jsxs(MuiBox, __assign({ width: "100%", sx: {
            padding: handleFullScreen && "40px 0px",
        } }, { children: [_jsx(Modal, __assign({ open: handleFullScreen, style: {
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "300vh",
                    zIndex: 10000,
                    overflow: "auto",
                } }, { children: _jsx(Paper, __assign({ style: { padding: 40, height: "100%" } }, { children: _jsx(OrganogramaTemplate, __assign({ "data-testid": "organograma-template" }, sharedProps)) })) })), !handleFullScreen && _jsx(OrganogramaTemplate, __assign({ "data-testid": "organograma-template" }, sharedProps))] }), handleFullScreen));
}
