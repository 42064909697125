/* eslint-disable react/prop-types */
import React, { Fragment, useContext, useState } from 'react'
import _ from 'lodash'
import Address from './Components/Address'
import BusinessInfos from './Components/BusinessInfos'
import BusinessType from './Components/BusinessType'
import WebsiteAndOthers from './Components/WebsiteAndOthers'
import Responsible from './Components/Responsible'
import Configuration from './Components/Configuration'
import AdditionalModules from './Components/AdditionalModules'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { validationSchema } from '../../../Functions/yupValidations'
import { PartnerService } from '../../../../Services'
import { partnerDataUpdate } from '../../../Mocks'
import SnackDefault from 'components/SnackDefault'
import Cookies from 'js-cookie'
import GeneralContext from '../../../../../../GeneralContext'
import { profiileErrors } from '../../../Mocks/profileErrors'

export default function BusinessData({
	isEditData,
	partnerId,
	originsList,
	partnerData,
	partnerInfos,
	segmentsList,
	reloadFormsData,
	setIsLoadingData,
	customFieldsForm,
	updateCustomFieldsForm,
}) {
	const { setGrouper } = useContext(GeneralContext)
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: '',
		text: '',
	})
	const partnerService = new PartnerService()

	const methods = useForm({
		resolver: yupResolver(validationSchema),
	})

	const setGrouperInStorage = Grouper => {
		if (!_.isEmpty(Grouper)) {
			localStorage.setItem('Grouper', Grouper)
			Cookies.set('Grouper', Grouper || '')
			setGrouper(Grouper || '')
		}
	}

	const removeGrouper = () => {
		localStorage.removeItem('Grouper')
		Cookies.remove('Grouper')
		setGrouper('')
	}

	async function onSubmit(data) {
		setIsLoadingData(true)
		try {
			const formatedData = partnerDataUpdate({
				data,
				partnerId,
				partnerData,
				customFieldsForm,
				segmentsList,
				originsList,
			})

			await partnerService.updatePartnerData({ data: formatedData }).then(response => {
				if (response?.success) {
					if (!_.isEmpty(formatedData?.benefitGrouper) && formatedData?.benefitGrouper !== partnerData?.benefitGrouper) {
						setGrouperInStorage(formatedData?.benefitGrouper)
					} else {
						removeGrouper('')
					}
					setSnackStatus({
						open: true,
						severity: 'success',
						text: 'Dados atualizados com sucesso!',
					})

					reloadFormsData()
				}
				if (response?.errors?.length > 0) {
					setSnackStatus({
						open: true,
						severity: 'error',
						text: profiileErrors[(response?.errors[0]?.message)] ?? response?.errors[0]?.message,
					})
				}
			})
		} catch (error) {
			console.log(error)
			setSnackStatus({
				open: true,
				severity: 'error',
				text: 'Erro ao atualizar dados do parceiro!',
			})
		} finally {
			setIsLoadingData(false)
		}
	}

	const sharedProps = {
		methods,
		isEditData,
		partnerData,
		partnerInfos,
		segmentsList,
		customFieldsForm,
		updateCustomFieldsForm,
	}

	const onErrors = errors => console.log(errors)

	return (
		<Fragment>
			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<FormProvider {...methods}>
				<form id="business-data-form" onSubmit={methods.handleSubmit(onSubmit, onErrors)}>
					{/* Business Infos */}
					<BusinessInfos {...sharedProps} />

					{/* Business Type */}
					<BusinessType {...sharedProps} />

					{/* Website and others */}
					<WebsiteAndOthers {...sharedProps} />

					{/* Address */}
					<Address {...sharedProps} />

					{/* Responsible */}
					<Responsible {...sharedProps} />

					{/* Configuration */}
					<Configuration {...sharedProps} />

					{/* Additional Modules */}
					{!_.isEmpty(partnerInfos?.additionalModules) && <AdditionalModules {...sharedProps} />}
				</form>
			</FormProvider>
		</Fragment>
	)
}
