import styled from 'styled-components'
import { TextField, withStyles } from '@material-ui/core'
import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

export const Container = styled.div`
	padding: 35px 0 0 0;
`

export const ButtonContainer = styled.div`
	display: flex;
`

export const FormField = styled.div`
	display: flex;
	margin-bottom: 25px;
	.GridItem {
		padding: 0px !important;
		margin-right: 15px;
	}
	.GridItemFirst {
		padding: 0px !important;
		margin-right: 15px;
	}
	.GridItemLast {
		padding: 0px !important;
	}
`

export const CssTextField = withStyles({
	root: {
		margin: '0px',
		'& label.Mui-focused': {
			color:
				getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: '#fff !important',
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},

		'& .MuiInput-underline:after': {
			borderBottomColor: '#eeeeee',
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
			'&.Mui-focused fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
		},
		'& .Mui-disabled': {
			backgroundColor: '#F1F1F1 !important',
			borderColor: 'none!important',

			'&:hover': {
				borderColor: 'none!important',
			},
		},
	},
})(TextField)
