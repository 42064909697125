import { GetTypeOfPoints } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import { CalculateValue } from 'redux/api/product'
import { CreateGrouperByMultipleDivisions, GetDivisionByGrouper, UpdateGrouperByDivision } from '../../../../../../../../../../redux/api/Grouper'
import Cookies from 'js-cookie'
import {envs}  from '../../../../../../../../../../redux/api/windowServerData'



export const GetTypePoints = async setGenerationType => {
	try {
		const res = await GetTypeOfPoints()
		if (res && typeof res === 'object') {
			setGenerationType(res)
		}
	} catch (error) {
		setGenerationType([])
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		0,
	)
}

export const FormatMoney = value => {
	return numberFormatText(
		value,
		// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const handleProductDataUpdate = (productData, setProductDataState, setValue) => {
	if (productData) {
		const { typeOfPoints, parameterizationGenerationPoints, pointsValue } = productData

		setValue('typeOfPoints', typeOfPoints)
		setProductDataState({
			parameterization: parameterizationGenerationPoints,
			pointsValue: pointsValue,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}
//
export const handleCalculateValue = async (productId, price, type, value, typeOfRedeem, redeemValue) => {
	try {
		const res = await CalculateValue(productId, price, type, value, typeOfRedeem, redeemValue)
		if (res?.success && res?.data) {
			const { generationPoints } = res.data
			return generationPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const GetBranchesPlatforms = async ({ id, Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, setDefaultValues, setLoading }) => {
	setLoading(true)
	try {
		const branchesPlatforms = await GetDivisionByGrouper(Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, id)
		if (branchesPlatforms?.status === 200) {
			const { data } = branchesPlatforms
			setDefaultValues({
				...data,
				page: data?.currentPage,
			})

			return data?.results ?? []
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

export const ParameterizationByDivision = async ({
	grouper,
	divisionId,
	goToBack,
	productData,
	typeOfRedeem,
	redemptionPoints,
	setSnackStatus,
	setLoading,
}) => {
	setLoading(true)
	const partnerId = Cookies.get('partnerId')

	const obj = {
		partnerId: partnerId,
		typeOfBenefit: '1',
		typeOfGrouper: grouper || '',
		redemptionPoints: redemptionPoints || 0,
		typeOfBenefitRedeem: 'ByDivision',
		typeOfRedeem: typeOfRedeem?.key,
		divisionId: divisionId,
	}
	try {
		const res = await UpdateGrouperByDivision(productData?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				goToBack()
			}, 900)

			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		setSnackStatus({
			open: true,
			text: 'Erro ao realizar operação!',
			severity: 'error',
		})
	} finally {
		setLoading(false)
	}
}

export const CreateProductByMultiple = async ({
	grouper,
	goToBack,
	divisionsId,
	productData,
	typeOfRedeem,
	setSnackStatus,
	redemptionPoints,
	pametrizationName,
	setLoading,
}) => {
	setLoading(true)
	const partnerId = Cookies.get('partnerId')
	const obj = {
		partnerId: partnerId,
		typeOfGrouper: grouper || '',
		typeOfBenefit: '1',
		redemptionPoints: redemptionPoints || 0,
		typeOfBenefitRedeem: 'ByDivision',
		typeOfRedeem: typeOfRedeem?.key,
		pametrizationName: pametrizationName,
		divisionsId: divisionsId,
	}

	try {
		const res = await CreateGrouperByMultipleDivisions(productData?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				goToBack()
			}, 900)
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		console.log(error)
		setSnackStatus({
			open: true,
			text: 'Erro ao realizar operação!',
			severity: 'error',
		})
	} finally {
		setLoading(false)
	}
}
