import React from 'react'
import { Icon } from '@material-ui/core'

export function StatusIcon({ type }) {
	switch (type) {
		case 'residual':
			return (
				<Icon
					className="icon-ico_liberado"
					style={{
						fontSize: '20px',
						color: '#E58B00',
					}}
				/>
			)

		case 'reserve':
			return (
				<Icon
					className="icon-ico_reservado"
					style={{
						fontSize: '20px',
						color: '#74CCDA',
					}}
				/>
			)

		default:
			return <span></span>
	}
}
