import * as React from 'react'
import PropTypes from 'prop-types'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import { Container } from './styles'
import Section from 'components/Section'
import { useTranslation } from 'react-i18next'

function handleClick(event) {
	event.preventDefault()
	console.info('You clicked a breadcrumb.')
}

export default function CustomSeparator({ item }) {
	const { t } = useTranslation()


	return (
		<Container spacing={2}>
			<Section title={item} />
		</Container>
	)
}

CustomSeparator.propTypes = {
	item: PropTypes.string,
}
