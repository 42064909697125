import React from 'react'
import PropTypes from 'prop-types'
import { Button, Tooltip } from '@mui/material'

import * as S from '../styles'

const CustomizedIconButton = ({ onClick, className, Icon, disabled, title }) => {
	return (
		<Tooltip title={title} placement="top" arrow>
			<S.Container>
				<Button onClick={onClick} className={className} disabled={disabled}>
					<i className={Icon} />
				</Button>
			</S.Container>
		</Tooltip>
	)
}

CustomizedIconButton.propTypes = {
	Icon: PropTypes.string,
	title: PropTypes.string,
	onClick: PropTypes.func,
	disabled: PropTypes.bool,
	className: PropTypes.string,
}

export default CustomizedIconButton
