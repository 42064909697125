import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

export const Container = styled.div`
	border-bottom: 1px solid #c8c8c8;

	.MuiAutocomplete-popperDisablePortal {
		margin: 38px auto
	}

	.MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: #ffffff !important;
			background-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
		}
	}

	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${({ themeColor }) =>
		themeColor &&
		themeColor.titles &&
		themeColor.titles.highlight &&
		themeColor.titles.highlight.textColor &&
		themeColor.titles.highlight.textColor};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after {
		border-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
		border-width: 2px;
	}

	.MuiOutlinedInput-root:hover fieldset {
		border-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
	}

	label {
		color: #717171;
	}
	.MuiTablePagination-caption {
		display: none;
	}
`
export const GridContain = styled.div``

export const FieldContain = styled.div`
	display: flex;
	margin: 20px 0;

	@media only screen and (max-width: 649px) {
		flex-wrap: wrap;
		gap: 20px;
	}

	button {
		border: none;
		background-color: transparent;
		cursor: pointer;
		color: ${({ themeColor }) =>
		themeColor &&
		themeColor.navigation &&
		themeColor.navigation.buttons &&
		themeColor.navigation.buttons.backgroundColor &&
		themeColor.navigation.buttons.backgroundColor};
		font-size: 100%;
		height: 100%;
		position: absolute;
		padding-top: 18px;
		width: 35px;
	}
`
export const ContainFields = styled(Grid)`
	display: flex;
	justify-content: space-between;
`

export const InputBox = styled.div`

	@media only screen and (max-width: 649px) {
		width: 100% !important;
		margin-right: 0px !important;
	}

	display: flex;
	flex-direction: column;
	margin-right: 18px;
`
export const Inputs = styled.input`
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	color: #717171;
	height: 35px;
	padding: 0 15px;
	overflow: hidden;
	text-overflow: ellipsis;
`
export const FilterForm = styled.form`
	background-color: #f6f6f6;
	border-radius: 5px;
	margin-top: 20px;
	padding: 20px 15px;
	padding-top: 21px;
	width: 100%;

	.MuiGrid-spacing-xs-4 > .MuiGrid-item {
    	padding: 15px !important;
	}

	.filter__gridDATE {
		@media only screen and (max-width: 285px) {
			flex-direction: column;
		}
	}
`
export const DateTimeContain = styled.div`
	display: flex;
	flex-wrap: wrap;
	min-width: 800px;
	margin-bottom: 20px;
	width: 100%;
	height: 40px;
    align-content: center;
`
export const DateIconContain = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin-right: 18px;
	position: relative;
	width: 150px;
	> img {
		margin-right: 10px;
		position: absolute;
	}
`
export const BntFilterContain = styled.div`
	align-items: flex-end;
	display: flex;
	justify-content: flex-end;
    height: 40px;
    margin: 5px 15px 20px 15px;
    flex-direction: row;
    align-content: center;
	gap: 15px;
	width: 100%;
`
export const ActionBtn = styled.button`
	background-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.navigation &&
		themeColor.navigation.buttons &&
		themeColor.navigation.buttons.backgroundColor &&
		themeColor.navigation.buttons.backgroundColor};
	border: none;
	cursor: pointer;
	color: ${({ themeColor }) =>
		themeColor &&
		themeColor.navigation &&
		themeColor.navigation.buttons &&
		themeColor.navigation.buttons.textColor &&
		themeColor.navigation.buttons.textColor};
	font-size: 80%;
	height: 43px;
	width: 43px;
	font-size: 20px;
`
export const ShowValue = styled.div`
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	color: #717171;
	height: 35px;
	padding: 0 15px;
	display: flex;
	align-items: center;
`

export const Section = styled.div`
	display: flex;
	align-items: center;
	height: 40px;
	margin-bottom: 25px;
`
