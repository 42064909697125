import React, { Fragment } from 'react'

import Address from '../../Components/Address'
import Responsible from '../../Components/Responsible'
import BranchInfos from '../../Components/BranchInfos'

export default function FormBranch({ ...sharedProps }) {
	return (
		<Fragment>
			<BranchInfos {...sharedProps} />

			<Address {...sharedProps} />

			<Responsible {...sharedProps} />
		</Fragment>
	)
}
