/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from "react";
import { CustomButton } from "./styles";
import { BackIcon, CancelIcon, DumpIcon, EditIcon, RefreshIcon, PiCalculatorFill, SaveIcon, NotificationNegotiationIcon, FullScreenIcon, FullScreenExitIcon, OpenAccordionIcon, CloseAccordionIcon, IconAdd, SaveBotAddIcon, FollowIcon, } from "../../Icons";
export function CustomizableIconButton(_a) {
    var children = _a.children, defaultColor = _a.defaultColor, isGridButton = _a.isGridButton, _b = _a.customType, customType = _b === void 0 ? "edit" : _b, hasBorder = _a.hasBorder, props = __rest(_a, ["children", "defaultColor", "isGridButton", "customType", "hasBorder"]);
    var _c = useState("#F2F2F2"), hoverColor = _c[0], setHoverColor = _c[1];
    var customIcons = {
        edit: _jsx(EditIcon, { size: "25", color: "#1BBED3" }),
        backPage: _jsx(BackIcon, { size: "25", color: "#1BBED3" }),
        followPage: _jsx(FollowIcon, { size: "25", color: "#1BBED3" }),
        save: _jsx(SaveIcon, { size: "25", color: "#1BBED3" }),
        cancel: _jsx(CancelIcon, { size: "25", color: "#EB6C61" }),
        delete: _jsx(DumpIcon, { size: "18", color: "#EB6C61" }),
        saveSuccess: _jsx(SaveIcon, { size: "25", color: "#5FB55F" }),
        calculationMemory: _jsx(PiCalculatorFill, { size: 30, color: "#1BBED3" }),
        parametrization: _jsx(RefreshIcon, { size: "20", color: "#F58B3C" }),
        notification: _jsx(NotificationNegotiationIcon, { size: "20", color: "#1BBED3" }),
        fullScreen: _jsx(FullScreenIcon, { size: "25", color: "#1BBED3" }),
        fullScreenExit: _jsx(FullScreenExitIcon, { size: "25", color: "#1BBED3" }),
        openAccordion: _jsx(OpenAccordionIcon, { size: "15", color: "#1BBED3" }),
        closeAccordion: _jsx(CloseAccordionIcon, { size: "15", color: "#1BBED3" }),
        add: _jsx(IconAdd, { size: "25", color: "#1BBED3" }),
        saveBotAdd: _jsx(SaveBotAddIcon, { size: "25", color: "#5FB55F" }),
    };
    var actionsColor = [
        "edit",
        "backPage",
        "followPage",
        "save",
        "calculationMemory",
        "notification",
        "fullScreen",
        "fullScreenExit",
        "openAccordion",
        "closeAccordion",
        "add",
    ];
    var cancelColor = ["cancel", "delete"];
    return (_jsx(CustomButton, __assign({ style: {
            backgroundColor: hoverColor,
            height: isGridButton ? "30px" : "40px",
            width: isGridButton ? "30px" : "40px",
            border: hasBorder ? "2px solid #fff" : "none",
        }, onMouseOver: function () {
            return setHoverColor(defaultColor ||
                (defaultColor && customType === "parametrization" && defaultColor) ||
                (!defaultColor && customType === "parametrization" && "#F58B3C") ||
                ((actionsColor === null || actionsColor === void 0 ? void 0 : actionsColor.includes(customType)) && "#1BBED3") ||
                ((cancelColor === null || cancelColor === void 0 ? void 0 : cancelColor.includes(customType)) && "#EB6C61") ||
                "#5FB55F");
        }, onMouseOut: function () { return setHoverColor("#F2F2F2"); } }, props, { children: customIcons[customType] })));
}
