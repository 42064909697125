import React, { Component, Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import AsyncSelect from 'react-select/async'

import * as _ from 'lodash'

//material UI
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'

//components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import styles from '../groups.module.scss'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'

//UTILS
import compose from 'utils/compose'
import { Grid } from '@material-ui/core'

import styled from 'styled-components/macro'

import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

const Container = styled.div`
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${props => props.themeColor && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${props => props.themeColor && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}
	.Title {
		color: ${props =>
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor &&
		props.themeColor.titles.highlight.textColor} !important;
	}
`

class UsersAssign extends Component {
	constructor(props) {
		super(props)
		this.state = {
			themeColor: getThemeColorFromLocalStorage(),
		}
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
	}

	render() {
		const { t, classes, values, setFieldValue, setFieldTouched } = this.props

		const changeSelectedUser = newValue => {
			var newUser = { value: newValue.value, label: newValue.label }
			setFieldValue('selectedUser', newUser, true)
		}

		const handleAdd = () => {
			var array = values && values.userGroup && values.userGroup
			array.push(values.selectedUser.value)
			setFieldValue('userGroup', array)
			setFieldValue('selectedUser', [])
			setFieldTouched('userGroup', true, false)
		}

		const handleRemove = i => {
			var array = values && values.userGroup && values.userGroup.filter(item => item !== i)
			setFieldValue('userGroup', array)
			setFieldTouched('userGroup', true, false)
		}

		const loadUserOptions = (inputValue, callback) => {
			getUsersOptions(inputValue, callback)
		}
		const getUsersOptions = (value, callback) => {
			const inputValue = value
				.replace(/\./g, '')
				.replace(/\-/g, '')
				.trim()
				.toLowerCase()
			const inputLength = inputValue.length

			if (inputLength === 0) {
				return callback([])
			} else {
				var notSelectedUser = this.props.userList.filter(i => !_.includes(values && values.userGroup && values.userGroup, i.id))
				return callback(
					notSelectedUser &&
					notSelectedUser
						.filter(
							user =>
								user.name.toLowerCase().includes(inputValue) ||
								user.userName
									.replace(/\./g, '')
									.replace(/\-/g, '')
									.includes(inputValue),
						)
						.map(user => {
							return {
								value: user.id,
								label: user.name + ' | ' + user.userName,
							}
						}),
				)
			}
		}

		return (
			<Container>
				<Section title={t('NAVPILLS_SETTINGS_USERS')} />

				<GridContainer justifyContent="flex-start" style={{ paddingTop: '25px', paddingLeft: '1px' }}>
					<GridItem xs={8} sm={8} md={4}>
						<AsyncSelect
							defaultOptions
							placeholder={t('FIELD_ACCESS_ENTER_USER_LOGIN')}
							loadingMessage={() => t('PLACEHOLDER_SEARCHING')}
							noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
							loadOptions={_.debounce(loadUserOptions, 1000)}
							value={values.selectedUser}
							onChange={changeSelectedUser}
							theme={theme => ({
								...theme,
								colors: {
									...theme.colors,
									primary: this.state && this.state.themeColor && this.state.themeColor.structure && this.state.themeColor.structure.backgroundColor,
								},
							})}
						/>
					</GridItem>
					<GridItem
						xs={4}
						sm={4}
						md={1}
						style={{
							textAlign: 'right',
							transform: 'translateY(-5px)',
						}}
					>
						<Button
							size="sm"
							color="primary"
							className={classes.marginRight}
							onClick={handleAdd}
							disabled={values.selectedUser.length === 0 ? true : false}
						>
							{t('BUTTON_ADD')}
						</Button>
					</GridItem>
				</GridContainer>
				<Fragment>
					{values && values.userGroup && values.userGroup.length > 0 && (
						<Grid style={{ paddingTop: '50px' }}>
							<Section title={t('TAB_ACCESS_ASSOCIATED_USERS')} />
						</Grid>
					)}
				</Fragment>
				<GridContainer justifyContent="center" style={{ paddingTop: '50px' }}>
					<GridItem xs={12} sm={12} md={12}>
						<Table className={styles.statementTable}>
							{values && values.userGroup && values.userGroup.length > 0 ? (
								<Fragment>
									{this.props.userList &&
										values &&
										values.userGroup &&
										values.userGroup.map((item, index) => {
											let user = _.find(this.props.userList, x => x.id === item)
											if (!user) {
												return false
											}
											return (
												<Fragment key={index}>
													<TableBody className={index % 2 === 0 ? styles.bodyDark : styles.bodyLight}>
														<TableRow
															className={styles.rowItems}
															style={{
																borderBottom: '5px solid #fff',
															}}
														>
															<TableCell colSpan={2}>
																<div
																	className={styles.title}
																	style={{
																		color:
																			this.state.themeColor &&
																			this.state.themeColor?.titles &&
																			this.state.themeColor?.titles?.highlight &&
																			this.state.themeColor?.titles?.highlight?.textColor &&
																			this.state.themeColor?.titles?.highlight?.textColor,
																	}}
																>
																	{t('LOGIN_LOGIN')}
																</div>
																{/* Nome do usuário está vindo diferente em diferentes APIs */}
																<div className={styles.detailsContent}>{user.userName ? user.userName : user.username}</div>
															</TableCell>
															<TableCell className={styles.Left} colSpan={2}>
																<div
																	className={styles.title}
																	style={{
																		color:
																			this.state.themeColor &&
																			this.state.themeColor.titles &&
																			this.state.themeColor.titles.highlight &&
																			this.state.themeColor?.titles?.highlight?.textColor &&
																			this.state.themeColor?.titles?.highlight?.textColor,
																	}}
																>
																	{t('FIELD_REGISTER_NAME')}
																</div>
																<div className={styles.detailsContent}>{user.name}</div>
															</TableCell>
															<TableCell
																style={{
																	textAlign: 'right',
																	paddingRight: '0.5rem',
																}}
															>
																<div
																	style={{
																		display: 'inline-flex',
																	}}
																>
																	<Button
																		onClick={() => {
																			handleRemove(item)
																		}}
																		color="greenButtonClose"
																		size="sm"
																	>
																		{t('BUTTON_REMOVE')}
																	</Button>
																</div>
															</TableCell>
														</TableRow>
													</TableBody>
												</Fragment>
											)
										})}
								</Fragment>
							) : (
								<TableBody>
									<TableRow>
										<TableCell colSpan={10} align={'center'}>
											{t('NO_USERS_ASSOCIATED_TO_THIS_GROUP')}.
										</TableCell>
									</TableRow>
								</TableBody>
							)}
						</Table>
					</GridItem>
				</GridContainer>
			</Container>
		)
	}
}

export default compose(
	//withStyles(),
	withTranslation(),
)(UsersAssign)
