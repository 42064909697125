import { UpdateProduct } from '../../../../../../../../../redux/api/product'

export const onConfirm = async ({ productData, setSnackStatus, setLoading }) => {
	const obj = {
		id: productData?.id,
		price: productData?.price,
		name: productData?.name,
		generatePoints: productData?.generatePoints,
		typeOfBenefitPoints: productData?.typeOfBenefitPoints?.key,
		typeOfPoints: productData?.typeOfPoints?.key,
		redemptionPoints: productData?.redemptionPoints || 0,
		typeOfBenefitRedeem: '0',
		typeOfRedeem: '0',

		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: productData?.typeOfCashback?.key,
		cashbackPercentual: productData?.cashbackPercentual || 0,
	}
	setLoading(true)

	try {
		const res = await UpdateProduct(obj?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				type: 'success',
			})
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				message: res?.data?.errors[0]?.message,
				type: 'error',
			})
		}
	} catch (error) {
		setSnackStatus({
			open: true,
			message: 'Ocorreu um erro ao realizar a operação!',
			type: 'error',
		})
	} finally {
		setLoading(false)
	}
}
