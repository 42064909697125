import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import Select from '../../../../Select'
import { useTranslation } from 'react-i18next'
import { validateValue } from '../../../../../Functions'
import Section from 'components/Section'
import { Stack } from '@mui/material'
import { getElementByID } from '../../../../../Functions'
import usePartnerProfile from '../../../../../Hooks/usePartnerProfile'
import { ZipCodeNoMask } from '../../../BusinessData/Components/Address/Functions'
import AddressInherit from './AddressInherit'
import { handleReset, handleResetAddress } from './Functions'
import { MuiInput } from '../../../../../../../../components/storybook'

export default function Address({ isEditData, UFList, defaultInfos, sharedInfos, parentDivision }) {
	const { t } = useTranslation()
	const { control, errors, watch, setValue } = useFormContext()
	const watchFields = watch()
	const { getAddressComponent } = usePartnerProfile()
	const [addressInfos, setAddressInfos] = useState(null)
	const { Inherit, InheritOption } = watch()

	const defaultCountry = sharedInfos?.countryList?.find(item => item?.label === 'Brazil') ?? []

	async function getAddress(zipCode) {
		if (ZipCodeNoMask(zipCode)?.length >= 8) {
			const address = await getAddressComponent(zipCode)

			setValue('businessDivisionCountry', {
				label: address?.country,
				value: address?.countryId,
			})
			setValue('businessDivisionCEP', zipCode)

			const UF = getElementByID(address?.stateId, UFList)

			setValue('businessDivisionUF', {
				label: UF?.label,
				value: UF?.value,
			})
			setValue('businessDivisionCity', {
				label: address?.city,
				value: addressInfos?.cityId ?? address?.cityId,
			})

			setValue('businessDivisionAddress', address?.location)
			setValue('businessDivisionDistrict', address?.district)

			setAddressInfos({
				zipCode: zipCode,
				location: address?.location,
				number: address?.number ?? defaultInfos?.address?.number,
				additionalInfo: defaultInfos?.address?.additionalInfo,
				district: address?.district,
				country: {
					label: address?.country,
					value: address?.countryId,
				},
				state: {
					label: UF?.label,
					value: UF?.value,
				},
				city: {
					label: address?.city,
					value: addressInfos?.cityId ?? address?.cityId,
				},
			})
		}
	}

	useEffect(() => {
		if (defaultInfos?.address) {
			getAddress(defaultInfos?.address?.zipCode)
		}
	}, [defaultInfos?.address?.zipCode])

	useEffect(() => {
		if (InheritOption?.address) {
			const { address } = InheritOption
			handleReset({ setValue })
			handleResetAddress({ setValue, address })
		}
	}, [InheritOption])

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={12} sm={12}>
					<Stack mt={3} sx={{ width: '100%' }}>
						<Section title="Endereço" />
					</Stack>
				</Grid>
				<AddressInherit
					t={t}
					disabled={false}
					Inherit={Inherit}
					control={control}
					setValue={setValue}
					errorAddress={errors}
					parentDivision={parentDivision}
				/>
				<Grid item lg={2} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="CEP" value={validateValue(addressInfos?.zipCode)} />
					) : (
						<Fragment>
							<Labelitem required label="CEP" />
							<Controller
								control={control}
								name="businessDivisionCEP"
								render={({ onChange, value }) => (
									<MuiInput
										error={errors?.businessDivisionCEP}
										helperText={t(errors?.businessDivisionCEP?.message)}
										fullWidth
										id="businessDivisionCEP"
										name="businessDivisionCEP"
										value={value}
										disabled={false}
										onChange={e => {
											getAddress(e.target.value)
											onChange(e)
										}}
										// InputProps={{
										// 	inputComponent: CepMask,
										// }}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={addressInfos?.zipCode}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={6} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Endereço" value={validateValue(addressInfos?.location)} />
					) : (
						<Fragment>
							<Labelitem required label="Endereço" />
							<Controller
								control={control}
								name="businessDivisionAddress"
								render={({ onChange, value }) => (
									<MuiInput
										error={errors?.businessDivisionAddress}
										helperText={t(errors?.businessDivisionAddress?.message)}
										fullWidth
										id="businessDivisionAddress"
										name="businessDivisionAddress"
										value={value}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={addressInfos?.location}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={1} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Nº" value={validateValue(addressInfos?.number)} />
					) : (
						<Fragment>
							<Labelitem required label="Nº" />
							<Controller
								control={control}
								name="businessDivisionAddressNumber"
								render={({ onChange, value }) => (
									<MuiInput
										error={errors?.businessDivisionAddressNumber}
										helperText={t(errors?.businessDivisionAddressNumber?.message)}
										fullWidth
										id="businessDivisionAddressNumber"
										name="businessDivisionAddressNumber"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={addressInfos?.number}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem disabled label="Complemento" value={validateValue(addressInfos?.additionalInfo)} />
					) : (
						<Fragment>
							<Labelitem label="Complemento" />
							<Controller
								control={control}
								name="businessDivisionAditionalInfo"
								render={({ onChange, value }) => (
									<MuiInput
										error={errors?.businessDivisionAditionalInfo}
										helperText={t(errors?.businessDivisionAditionalInfo?.message)}
										fullWidth
										id="businessDivisionAditionalInfo"
										name="businessDivisionAditionalInfo"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={addressInfos?.additionalInfo}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Bairro" value={validateValue(addressInfos?.district)} />
					) : (
						<Fragment>
							<Labelitem required label="Bairro" />
							<Controller
								control={control}
								name="businessDivisionDistrict"
								render={({ onChange, value }) => (
									<MuiInput
										error={errors?.businessDivisionDistrict}
										helperText={t(errors?.businessDivisionDistrict?.message)}
										fullWidth
										id="businessDivisionDistrict"
										name="businessDivisionDistrict"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={addressInfos?.district}
							/>
						</Fragment>
					)}
				</Grid>

				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="País" disabled required value={validateValue(addressInfos?.country?.label)} />
					) : (
						<Fragment>
							<Labelitem required label="País" />
							<Controller
								control={control}
								name="businessDivisionCountry"
								render={({ onChange, value }) => (
									<Select
										name="businessDivisionCountry"
										error={errors?.businessDivisionCountry}
										helperText={t(errors?.businessDivisionCountry?.message)}
										value={value}
										options={sharedInfos?.countryList ?? []}
										onChange={onChange}
									/>
								)}
								defaultValue={{
									label: addressInfos?.country?.label ?? defaultCountry.label,
									value: addressInfos?.country?.value ?? defaultCountry.value,
								}}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="UF" disabled required value={validateValue(addressInfos?.state?.label)} />
					) : (
						<Fragment>
							<Labelitem required label="UF" />
							<Controller
								control={control}
								name="businessDivisionUF"
								render={({ onChange, value }) => (
									<Select
										disabled={!watchFields?.businessDivisionCountry}
										name="businessDivisionUF"
										isOptionEqualToValue={(option, value) => option.value === value}
										error={errors?.businessDivisionUF}
										helperText={t(errors?.businessDivisionUF?.message)}
										value={value}
										options={sharedInfos?.addressUFList ?? []}
										onChange={onChange}
									/>
								)}
								defaultValue={{
									label: addressInfos?.state?.label,
									value: addressInfos?.state?.value,
								}}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="Cidade" disabled required value={validateValue(addressInfos?.city?.label)} />
					) : (
						<Fragment>
							<Labelitem required label="Cidade" />
							<Controller
								control={control}
								name="businessDivisionCity"
								render={({ onChange, value }) => (
									<Select
										disabled={!watchFields?.businessDivisionUF}
										name="businessDivisionCity"
										error={errors?.businessDivisionCity}
										helperText={t(errors?.businessDivisionCity?.message)}
										value={value}
										options={sharedInfos?.addressCityList ?? []}
										onChange={onChange}
									/>
								)}
								defaultValue={{
									label: addressInfos?.city?.label,
									value: addressInfos?.city?.value,
								}}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}

Address.propTypes = {
	isEditData: PropTypes.bool,
	UFList: PropTypes.array,
	defaultInfos: PropTypes.object,
	sharedInfos: PropTypes.object,
	parentDivision: PropTypes.object,
}
