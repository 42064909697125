import { push } from 'connected-react-router'
import * as actionTypes from './actionsTypes'
import { userService } from '../services'

import * as API from '../api/user'

// import { alertActions } from './';

// export const userActions = {
//     login,
//     logout
// };

export const getClaimsGroups = () => {
	return (dispatch) =>
		userService.getClaimsGroups().then(
			(result) => {
				dispatch(success(result))
				return result
			},
			(error) => {
				dispatch(failure(error))
			},
		)

	function success(data) {
		return {
			type: actionTypes.GET_CLAIN_USERS_SUCCESS,
			userClaimGroups: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.GET_CLAIN_USERS_FAILURE,
			userClaimGroupsFailed: error,
		}
	}
}

export const login = (username, password) => {
	return (dispatch) => {
		dispatch(
			request({
				username,
			}),
		)

		userService.login(username, password).then(
			(user) => {
				// dispatch(getClaimsGroups());
				dispatch(success(user))
				dispatch(push('/home'))
			},
			(error) => {
				dispatch(failure(error))
				// dispatch(alertActions.error(error));
			},
		)
	}

	function request(user) {
		return {
			type: actionTypes.LOGIN_REQUEST,
			user,
		}
	}

	function success(user) {
		return {
			type: actionTypes.LOGIN_SUCCESS,
			user,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.LOGIN_FAILURE,
			error,
		}
	}
}

export const logout = () => {
	userService.logout()
	return {
		type: actionTypes.LOGOUT,
	}
}

export const changePassword = (id, obj) => {
	return (dispatch) => {
		userService.changePassword(id, obj).then(
			(result) => {
				dispatch(success(result))
			},
			(error) => {
				dispatch(failure(error))
			},
		)
	}

	function success(data) {
		return {
			type: actionTypes.CHANGE_PASSWORD,
			changePassword: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.CHANGE_PASSWORD_FAILURE,
			error,
		}
	}
}

export const fetchPartnerDataError = (error) => {
	return {
		type: actionTypes.FETCH_PARTNER_USER_FAILED,
		userDataFailed: error,
	}
}

export const PutPartnerUserData = (data) => {
	return {
		type: actionTypes.PUT_PARTNER_USER_DATA,
		userUpdate: data,
	}
}

export const verifyConsumerCpf = (data) => {
	return {
		type: actionTypes.VERIFY_CPF,
		validCpf: data,
	}
}

export const verifyConsumerCpfFailed = (error) => {
	return {
		type: actionTypes.VERIFY_CPF,
		validCpf: false,
	}
}

// POST
export const postPartnerRegulationData = (data) => {
	return {
		type: actionTypes.POST_CONSUMER_REGULATION_DATA,
		consumerRegulationPost: data,
	}
}

export const postPartnerUserData = (data) => {
	return {
		type: actionTypes.POST_PARTNER_USER_DATA,
		userPost: data,
	}
}

export const postPartnerCreateNewUser = (data) => {
	return {
		type: actionTypes.POST_PARTNER_NEW_USER_DATA,
		userPartnerCreateNewUser: data,
	}
}

export const GetUsersByPartner = () => {
	return (dispatch) =>
		API.GetUsersByPartner()
			.then((response) => {
				dispatch(fetchPartnerUserPartnerData(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchPartnerUserPartnerError(error))
			})

	function fetchPartnerUserPartnerData(data) {
		return {
			type: actionTypes.FETCH_PARTNER_USER_PARTNER_DATA,
			userPartnerData: data,
		}
	}
	function fetchPartnerUserPartnerError(error) {
		return {
			type: actionTypes.FETCH_PARTNER_USER_PARTNER_ERROR,
			userPartnerError: error,
		}
	}
}

export const GetPartnerUserById = (id) => {
	return (dispatch) =>
		API.GetPartnerUserById(id)
			.then((response) => {
				dispatch(fetchPartnerUserByIdData(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchPartnerUserByIdError(error))
			})

	function fetchPartnerUserByIdData(data) {
		return {
			type: actionTypes.FETCH_PARTNER_USER_BY_ID,
			userPartnerByIdData: data,
		}
	}
	function fetchPartnerUserByIdError(error) {
		return {
			type: actionTypes.FETCH_PARTNER_USER_BY_ID_FAILED,
			userPartnerByIdError: error,
		}
	}
}

// GET
export const GetCurrentPartner = () => {
	return (dispatch) =>
		API.GetCurrentPartner()
			.then((response) => {
				dispatch(fetchPartnerUserData(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchPartnerDataError(error))
			})

	function fetchPartnerUserData(data) {
		return {
			type: actionTypes.FETCH_PARTNER_USER_DATA,
			userData: data,
		}
	}
}

export const VerifyDocument = (cpf) => {
	return (dispatch) =>
		API.VerifyDocument(cpf)
			.then((response) => {
				dispatch(verifyConsumerCpf(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(verifyConsumerCpfFailed(error))
			})
}

// PUT
export const UpdatePartnerUserData = (id, obj) => {
	return (dispatch) =>
		API.UpdatePartner(id, obj)
			.then((response) => {
				dispatch(PutPartnerUserData(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchPartnerDataError(error))
			})
}

//POST
export const PostPartnerRegulation = (data) => {
	return (dispatch) =>
		API.PostPartnerRegulation(data)
			.then((response) => {
				dispatch(postPartnerRegulationData(response))
				return response
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchPartnerDataError(error))
			})
}

export const PostPartnerCreateNewUser = (id, obj) => {
	return (dispatch) =>
		API.CreateNewUser(id, obj)
			.then((response) => {
				dispatch(postPartnerCreateNewUser(response))
				return response
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchPartnerDataError(error))
			})
}

export const RegisterPartner = (data) => {
	return (dispatch) =>
		API.PostUser(data)
			.then((response) => {
				dispatch(postPartnerUserData(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchPartnerDataError(error))
			})
}
// function getAll() {
//     return dispatch => {
//         dispatch(request());

//         userService.getAll()
//             .then(
//                 users => dispatch(success(users)),
//                 error => {
//                     dispatch(failure(error));
//                     // dispatch(alertActions.error(error))
//                 }
//             );
//     };

//     function request() { return { type: actionTypes.GETALL_REQUEST } }
//     function success(users) { return { type: actionTypes.GETALL_SUCCESS, users } }
//     function failure(error) { return { type: actionTypes.GETALL_FAILURE, error } }
// }

export const ListUsers = (name, email, isActive, cpf, page) => {
	return (dispatch) =>
		userService.ListUsers(name, email, isActive, cpf, page).then(
			(result) => {
				dispatch(success(result))
				return result
			},
			(error) => {
				dispatch(failure(error))
			},
		)

	function success(data) {
		return {
			type: actionTypes.GETALL_SUCCESS,
			userListData: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.GETALL_FAILURE,
			userListDataFailed: error,
		}
	}
}

export const GetUserById = (id) => {
	return (dispatch) =>
		userService.GetUserById(id).then(
			(result) => {
				dispatch(success(result))
				return result
			},
			(error) => {
				dispatch(failure(error))
			},
		)

	function success(data) {
		return {
			type: actionTypes.GET_SUCCESS,
			userData: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.GET_FAILURE,
			userDataFailed: error,
		}
	}
}

export const UpdateUserProfile = (id, obj) => {
	return (dispatch) =>
		userService.UpdateUserProfile(id, obj).then(
			(result) => {
				dispatch(success(result))
			},
			(error) => {
				dispatch(failure(error))
			},
		)

	function success(data) {
		return {
			type: actionTypes.PUT_PARTNER_USER_DATA,
			userUpdate: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.FETCH_PARTNER_USER_FAILED,
			userUpdateFailed: error,
		}
	}
}

export const CreateNewUser = (id, obj) => {
	return (dispatch) =>
		userService.CreateNewUser(id, obj).then(
			(result) => {
				dispatch(success(result))
			},
			(error) => {
				dispatch(failure(error))
			},
		)

	function success(data) {
		return {
			type: actionTypes.POST_PARTNER_USER_DATA,
			userPost: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.POST_PARTNER_USER_FAILED,
			userPostFailed: error,
		}
	}
}

export const UpdateUser = (id, obj) => {
	return (dispatch) =>
		userService.UpdateUser(id, obj).then(
			(result) => {
				dispatch(success(result))
			},
			(error) => {
				dispatch(failure(error))
			},
		)

	function success(data) {
		return {
			type: actionTypes.PUT_PARTNER_USER_DATA,
			userUpdate: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.POST_PARTNER_USER_FAILED,
			userPostFailed: error,
		}
	}
}

export const GetResetPasswordToken = (login) => {
	return (dispatch) => {
		dispatch(failure('loading'))
		return API.SendResetPasswordRequest(login)
			.then((response) => {
				if (response.success) {
					dispatch(failure(false))
					dispatch(success(response))
				} else {
					dispatch(failure(response))
				}
			})
			.catch((error) => {
				console.error(error)
				dispatch(failure(error))
			})
	}

	function success(data) {
		return {
			type: actionTypes.REQUEST_PASSWORD_RESET,
			requestPasswordReset: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.RESET_PASSWORD_FAILED,
			resetPasswordFailed: error,
		}
	}
}

export const PostResetPassword = (obj) => {
	return (dispatch) => {
		dispatch(failure('loading'))
		API.ResetPassword(obj)
			.then((response) => {
				if (response.success) {
					dispatch(failure(false))
					dispatch(success(response))
				} else {
					dispatch(failure(response))
				}
			})
			.catch((error) => {
				console.error(error)
				dispatch(failure(error))
			})
	}

	function success(data) {
		return {
			type: actionTypes.RESET_PASSWORD,
			resetPassword: data,
		}
	}

	function failure(error) {
		return {
			type: actionTypes.RESET_PASSWORD_FAILED,
			resetPasswordFailed: error,
		}
	}
}

export const GetGeneralBasicData = () => {
	return (dispatch) =>
		API.GetGeneralBasicData()
			.then((response) => {
				dispatch(fetchGeneralBasicData(response))
			})
			.catch((error) => {
				console.error(error)
				dispatch(fetchGeneralBasicError(error))
			})

	function fetchGeneralBasicData(data) {
		return {
			type: actionTypes.GENERAL_BASIC_DATA,
			generalBasicData: data,
		}
	}

	function fetchGeneralBasicError(error) {
		return {
			type: actionTypes.GENERAL_BASIC_DATA_FAILED,
			generalBasicDataError: error,
		}
	}
}
