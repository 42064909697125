import * as React from 'react'
import PropTypes from 'prop-types'
import Modal from '@mui/material/Modal'
import { CustomButton, Container, Icon } from './styles'
import { Box } from '@mui/material'

const CustomModal = ({ open, onClose, title, subtitle, onConfirm }) => {
	return (
		<div>
			<Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Container>
					<Box textAlign="center">
						<Icon className="icon-i_atencao" />
					</Box>
					<Box textAlign={'center'} className="muitxt">
						{title}
						<br />
						{subtitle}
					</Box>
					<Box display="flex" gap={2} justifyContent="center" alignItems="center">
						<CustomButton
							color="#fff"
							hoverBg="#eb6c61"
							border="#eb6c61"
							bg="#eb6c61"
							onClick={() => onClose()}
							style={{
								width: '107px',
							}}
						>
							Não
						</CustomButton>
						<CustomButton
							style={{
								width: '107px',
							}}
							color="#5FB55F"
							hoverBg="#069136"
							border="#5FB55F"
							onClick={() => onConfirm()}
						>
							Sim
						</CustomButton>
					</Box>
				</Container>
			</Modal>
		</div>
	)
}

CustomModal.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onConfirm: PropTypes.func,
	title: PropTypes.string,
	subtitle: PropTypes.string,
}

export default CustomModal
