/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import { Stack } from '@mui/material'
import { Box } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
	root: {
		margin: 'auto',
	},
	cardHeader: {
		padding: theme.spacing(1, 2),
	},
	list: {
		width: 300,
		height: 230,
		backgroundColor: theme.palette.background.paper,
		overflow: 'auto',
	},
	button: {
		margin: theme.spacing(0.5, 0),
	},
}))

function not(a, b) {
	return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
	return a.filter(value => b.indexOf(value) !== -1)
}

export default function TransferList({ options, fieldName, label, selectorLabel, handleClick, defaultOptions, updateCustomFieldsForm }) {
	const classes = useStyles()
	const [checked, setChecked] = useState([])
	const [left, setLeft] = useState([])
	const [right, setRight] = useState([])

	const leftChecked = intersection(checked, left)
	const rightChecked = intersection(checked, right)

	const handleToggle = value => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const handleCheckedRight = () => {
		setRight(right.concat(leftChecked))
		setLeft(not(left, leftChecked))
		setChecked(not(checked, leftChecked))
	}

	const handleCheckedLeft = () => {
		setLeft(left.concat(rightChecked))
		setRight(not(right, rightChecked))
		setChecked(not(checked, rightChecked))
	}

	React.useEffect(() => {
		handleClick(right)
	}, [right])

	React.useEffect(() => {
		if (defaultOptions?.length > 0 && options?.length > 0) {
			const newLeft = options.filter(item => !defaultOptions.some(origin => origin?.description === item))?.filter(item => item)

			const newRight = options.filter(item => defaultOptions.some(origin => origin?.description === item))?.filter(item => item)
			fieldName && updateCustomFieldsForm(fieldName, newRight)
			setLeft(newLeft)
			setRight(newRight)
		} else if (options?.length > 0) {
			setLeft(options)
		}
	}, [defaultOptions])

	const customList = (title, items) => (
		<Card
			style={{
				border: '1px solid #c4c4c4',
				borderRadius: '5px!important',
				boxShadow: 'none',
			}}
		>
			<List className={classes.list} dense component="div" role="list">
				{items.map((value, index) => {
					const labelId = `transfer-list-all-item-${value}-label`

					return (
						<Stack
							key={index}
							sx={{
								'.MuiListItem-root': {
									padding: 0,
									width: '100%',
								},
								'.MuiCheckbox-colorSecondary.Mui-checked': {
									color: '#f58b3c!important',
								},
							}}
						>
							<ListItem role="listitem" onClick={handleToggle(value)}>
								<ListItemIcon>
									<Checkbox checked={checked.indexOf(value) !== -1} tabIndex={-1} disableRipple inputProps={{ 'aria-labelledby': labelId }} />
								</ListItemIcon>
								<ListItemText id={labelId} primary={value} />
							</ListItem>
						</Stack>
					)
				})}
				<ListItem />
			</List>
		</Card>
	)

	return (
		<Stack mt={1} direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
			<Grid
				container
				spacing={2}
				justifyContent="flex-start"
				alignItems="center"
				className={classes.root}
				style={{
					padding: 0,
					'.MuiButton-outlined': {
						borderRadius: '5px',
						padding: '5px',
					},
					'.MuiList-root': {
						border: '1px solid',
						minWidth: '350px',
					},
				}}
			>
				<Grid item>
					<Box pb={1}>{label}</Box>
					{customList('Choices', left)}
				</Grid>
				<Grid item>
					<Grid container direction="column" alignItems="center">
						<Stack
							sx={{
								fontSize: '25px',
								color: '#c4c4c4',
							}}
						>
							<i
								className="icon-i_voltar"
								onClick={() => {
									handleCheckedRight(right)
								}}
								style={{
									transform: 'rotate(180deg)',
									marginBottom: '20px',
								}}
							/>
							<i
								className="icon-i_voltar"
								onClick={() => {
									handleCheckedLeft()
								}}
							/>
						</Stack>
					</Grid>
				</Grid>
				<Grid item>
					<Box pb={1}>{selectorLabel}</Box>
					{customList('Chosen', right)}
				</Grid>
			</Grid>
		</Stack>
	)
}
