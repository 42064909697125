/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { InputAdornment, TextField } from "@mui/material";
import { MuiStack } from "../Stack";
import { MuiTypography } from "../Typography";
import { MuiBox } from "../Box";
export function MuiInput(_a) {
    var _b, _c, _d, _e, _f, _g;
    var defaultColor = _a.defaultColor, standard = _a.standard, variant = _a.variant, maxSize = _a.maxSize, minSize = _a.minSize, textLabel = _a.textLabel, showLabel = _a.showLabel, showError = _a.showError, errorText = _a.errorText, disabled = _a.disabled, backgroundColor = _a.backgroundColor, labelColor = _a.labelColor, type = _a.type, sx = _a.sx, iconEnd = _a.iconEnd, iconStart = _a.iconStart, background = _a.background, error = _a.error, helperText = _a.helperText, onInput = _a.onInput, customTestId = _a.customTestId, props = __rest(_a, ["defaultColor", "standard", "variant", "maxSize", "minSize", "textLabel", "showLabel", "showError", "errorText", "disabled", "backgroundColor", "labelColor", "type", "sx", "iconEnd", "iconStart", "background", "error", "helperText", "onInput", "customTestId"]);
    var color = defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C";
    return (_jsxs(MuiStack
    // {...props}
    , __assign({ 
        // {...props}
        sx: {
            ".MuiTypography-body2": {
                height: "25px!important",
            },
            width: "100%",
            height: "85px",
            backgroundColor: background,
        } }, { children: [showLabel && textLabel && (_jsx(MuiBox, __assign({ height: "25px" }, { children: _jsx(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: labelColor !== null && labelColor !== void 0 ? labelColor : "#989898", style: {
                        height: "25px",
                        fontSize: "13px",
                    } }, { children: textLabel !== null && textLabel !== void 0 ? textLabel : " " })) }))), _jsx(TextField, __assign({ "data-testid": customTestId || standard ? "mui-input-standard" : "mui-input", type: type !== null && type !== void 0 ? type : "text", size: "small", variant: standard ? "standard" : "outlined", disabled: disabled, error: error, helperText: helperText, InputLabelProps: __assign({ shrink: true }, props === null || props === void 0 ? void 0 : props.InputProps), inputProps: {
                    maxLength: maxSize !== null && maxSize !== void 0 ? maxSize : null,
                    minLength: minSize !== null && minSize !== void 0 ? minSize : null,
                    endAdornment: iconEnd && _jsx(InputAdornment, __assign({ position: "end" }, { children: iconEnd })),
                    startAdornment: iconStart && _jsx(InputAdornment, __assign({ position: "start" }, { children: iconStart })),
                }, sx: __assign(__assign({}, sx), { backgroundColor: (_b = ((disabled && "#F2F2F2") || backgroundColor)) !== null && _b !== void 0 ? _b : "#FFFFFF", "& label.Mui-focused": {
                        color: color,
                    }, "& .MuiOutlinedInput-root": {
                        padding: "0px 5px",
                        "&.Mui-focused fieldset": {
                            borderColor: color,
                        },
                    }, "& .MuiInputBase-root.Mui-focused, & .MuiOutlinedInput-root.Mui-focused .MuiInputAdornment-root": {
                        borderColor: color,
                    }, "& .MuiInput-underline.Mui-focused:after": {
                        borderBottomColor: color,
                    } }), onInput: onInput, fullWidth: true }, props)), showError && (_jsx(MuiBox, __assign({ height: "25px" }, { children: _jsx(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: "#BE2C2C" }, { children: errorText !== null && errorText !== void 0 ? errorText : " " })) }))), minSize && (props === null || props === void 0 ? void 0 : props.value) && ((_c = props === null || props === void 0 ? void 0 : props.value) === null || _c === void 0 ? void 0 : _c.length) < minSize && (_jsx(MuiBox, __assign({ sx: {
                    textAlign: "right",
                } }, { children: _jsxs(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: "#BE2C2C" }, { children: ["Deve conter no m\u00EDnimo ", ((_d = props.value) === null || _d === void 0 ? void 0 : _d.length) < 0 ? minSize - ((_e = props.value) === null || _e === void 0 ? void 0 : _e.length) : minSize, " caracteres"] })) }))), maxSize && props.value && (_jsx(MuiBox, __assign({ sx: {
                    textAlign: "right",
                } }, { children: _jsxs(MuiTypography, __assign({ variant: "body2", pb: 0.6, color: "#2EAE2E" }, { children: [((_f = props.value) === null || _f === void 0 ? void 0 : _f.length) > 0 ? maxSize - ((_g = props.value) === null || _g === void 0 ? void 0 : _g.length) : maxSize, " caracteres"] })) })))] })));
}
