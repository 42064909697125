import React, { useState } from 'react'

// @ HookForm
import { Controller, useForm } from 'react-hook-form'

// @ Helpers
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// @ Components
import CustomModal from './Modal'
import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable'

// @ MUI
import { InputAdornment } from '@mui/material'

// @ Styles and Utils
import * as S from './styles'
import { defaultModal } from '../../utils'
import { ColumnsGroupers, defaultOptions } from './utils'

// @ Functions
import { Delete, fetchData } from './Functions'
import { CustomFormField } from '../../../../components/CustomTextField'

const ListGroupers = ({ tabIndex, tableRef, setLoading, setSnackStatus }) => {
	const { t } = useTranslation()
	const { control, watch } = useForm()

	const [modalParameterization, setModalParameterization] = useState(defaultModal)

	const fetchDataTable = name => {
		setTimeout(() => {
			tableRef.current.onQueryChange({
				name: name,
				page: 0,
				pageSize: 10,
			})
		}, 500)
	}

	const onDelete = async data => {
		setLoading(true)
		try {
			const id = data?.id
			const response = await Delete({ id, tabIndex })

			if (response?.status === 200) {
				const { data } = response
				if (data?.errors?.length > 0) {
					setSnackStatus({
						open: true,
						text: t(data?.errors[0].message),
						severity: 'error',
					})
					return
				}
				if (data?.success) {
					setSnackStatus({
						open: true,
						text: 'Operação realizada com sucesso!',
						severity: 'success',
					})
					return tableRef.current && tableRef.current.onQueryChange()
				}
			}
		} catch (error) {
			console.error(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<S.CustomContainer>
			<CustomModal
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				onConfirm={() => {
					onDelete(modalParameterization?.rowData)
					setModalParameterization({ open: false })
				}}
				onClose={() => setModalParameterization({ open: false })}
			/>
			<S.FieldContain>
				<Controller
					render={({ onChange, value }) => (
						<CustomFormField
							value={value}
							variant="standard"
							placeholder={'Buscar'}
							onChange={e => {
								onChange(e.target.value)
								fetchDataTable(e.target.value)
							}}
							style={{ maxWidth: '230px' }}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<S.IconSearch className="icon-lupa" />
									</InputAdornment>
								),
								endAdornment: (
									<InputAdornment position="end">
										<S.IconClear
											className="icon-bot_cancelar"
											onClick={() => {
												onChange('')
												fetchDataTable('')
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
					)}
					name="name"
					defaultValue=""
					control={control}
				/>
			</S.FieldContain>

			<CustomMaterialTable
				search={true}
				tableRef={tableRef}
				options={defaultOptions}
				localization={{
					body: {
						emptyDataSourceMessage: (
							<S.EmptyTable>
								<i className={watch('name') ? 'icon-grid_expirado' : 'icon-informacao'} />
								<span>{watch('name') ? `Não existem resultados para "${watch('name')}"` : 'Nenhum resultado encontrado'}</span>
							</S.EmptyTable>
						),
					},
				}}
				data={query => fetchData({ query, tabIndex })}
				columns={ColumnsGroupers(t, setModalParameterization, tabIndex)}
			/>
		</S.CustomContainer>
	)
}

ListGroupers.propTypes = {
	tableRef: PropTypes.object,
	tabIndex: PropTypes.number,
	setLoading: PropTypes.func,
	setSnackStatus: PropTypes.func,
}
export default ListGroupers
