import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { InputAdornment } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

//@components
import GridItem from 'components/Grid/GridItem'
import Button from 'components/CustomButtons/Button.jsx'
import ListComponent from './List'

//@API
import { getThemeColorFromLocalStorage } from '../../../../../../../redux/api/themeColors'

import * as S from './styles'
import { deleteAttachment, handleFormSubmission, isPDFFile, schema } from './functions'
import { defaultValue, propsOpenModal } from './List/utils'
import ConfirmModalPartners from './ConfirmModal'

const ListDocument = ({ isEditData, setForm, setIsLoadingData, setClosing, formData, setFormData, attachmentTypes, partnerId }) => {
	const { t } = useTranslation()
	const fileInputRef = useRef(null)
	const IconColor = getThemeColorFromLocalStorage()?.visual?.icons?.color
	const [openModal, setOpenModal] = React.useState(propsOpenModal)

	const {
		control,
		handleSubmit,
		setValue,
		setError,
		clearErrors,
		formState: { errors },
		watch,
		reset,
	} = useForm({
		resolver: yupResolver(schema),
		defaultValue: defaultValue,
	})
	const sharedProps = {
		setForm,
		setIsLoadingData,
		setClosing,
		formData,
		setFormData,
		partnerId,
		isEditData,
		attachmentTypes,
		setOpenModal,
		fileInputRef,
		reset,
		t,
	}
	const handleCustomFormFieldClick = () => {
		fileInputRef.current.click()
	}

	const handleFileInputChange = event => {
		const selectedFile = event.target.files[0]
		if (selectedFile) {
			if (isPDFFile(selectedFile)) {
				setValue('file', selectedFile)
				clearErrors('file')
			} else {
				setError('file', {
					message: 'Arquivo inválido',
				})
			}
		}
	}

	const onSubmit = async obj => handleFormSubmission(obj, sharedProps)

	const DeleteFile = async item => deleteAttachment(item, sharedProps)

	return (
		<S.Container>
			<ConfirmModalPartners
				open={openModal.open}
				text={openModal.text}
				hasActive={openModal.hasActive}
				subText={openModal.subText}
				onConfirm={() => DeleteFile(openModal.object)}
				onCancel={() => setOpenModal(propsOpenModal)}
			/>
			{isEditData && (
				<S.FormField>
					<Controller
						name="file"
						control={control}
						render={({ value }) => (
							<GridItem xs={12} sm={12} md={4} className="GridItemFirst">
								<input
									accept="application/pdf"
									className="input-file"
									id="contained-button-file"
									type="file"
									onChange={handleFileInputChange}
									ref={fileInputRef}
									name="file"
									style={{ display: 'none' }}
								/>
								<S.CssTextField
									disabled
									fullWidth
									label="Buscar documento*"
									id="file"
									name="file"
									value={value?.name || ''}
									variant="outlined"
									margin="dense"
									InputProps={{
										endAdornment: (
											<InputAdornment>
												<S.ButtonImport onClick={handleCustomFormFieldClick}>
													<i
														className="icon-i_procurar"
														style={{
															fontSize: '24px',
															color: IconColor,
														}}
													/>
												</S.ButtonImport>
											</InputAdornment>
										),
									}}
								/>
							</GridItem>
						)}
					/>
					<Controller
						name="fileName"
						control={control}
						render={({ field, value, onChange }) => (
							<GridItem xs={12} sm={12} md={4} className="GridItem">
								<S.CssTextField
									{...field}
									fullWidth
									label="Nome do documento*"
									value={value}
									onChange={onChange}
									id="fileName"
									name="fileName"
									variant="outlined"
									margin="dense"
									error={!!errors.fileName}
									helperText={errors.fileName?.message}
								/>
							</GridItem>
						)}
					/>
					<Controller
						name="attachmentTypes"
						control={control}
						render={({ field, value, onChange }) => (
							<GridItem xs={12} sm={12} md={4} className="GridItemFirst">
								<Autocomplete
									{...field}
									options={attachmentTypes}
									value={value}
									onChange={(e, value) => onChange(value)}
									getOptionLabel={option => t(option.value)}
									renderInput={params => (
										<S.CssTextField
											{...params}
											label="Tipo de documento*"
											variant="outlined"
											id="attachmentTypes"
											name="attachmentTypes"
											margin="dense"
											error={!!errors.attachmentTypes}
											helperText={errors.attachmentTypes?.value?.message}
										/>
									)}
								/>
							</GridItem>
						)}
					/>
					<Controller
						name="attachmentType"
						control={control}
						render={({ field, value, onChange }) => (
							<GridItem xs={12} sm={12} md={4} className="GridItemLast">
								<S.CssTextField
									{...field}
									fullWidth
									label="Nomear tipo de documento"
									value={value}
									onChange={onChange}
									id="attachmentType"
									name="attachmentType"
									variant="outlined"
									margin="dense"
									error={!!errors.attachmentType}
									helperText={errors.attachmentType?.message}
									disabled={watch('attachmentTypes')?.key !== '0'}
								/>
							</GridItem>
						)}
					/>
				</S.FormField>
			)}
			{isEditData && (
				<S.ButtonContainer>
					<Button size="sm" color="greenButtonColor" onClick={handleSubmit(onSubmit)}>
						Anexar documento
					</Button>
				</S.ButtonContainer>
			)}

			<ListComponent formData={formData} isEditData={isEditData} setOpenModal={setOpenModal} />
		</S.Container>
	)
}

ListDocument.propTypes = {
	setForm: PropTypes.func,
	isEditData: PropTypes.bool,
	setClosing: PropTypes.func,
	formData: PropTypes.object,
	setFormData: PropTypes.func,
	partnerId: PropTypes.string,
	partnerData: PropTypes.object,
	attachmentTypes: PropTypes.array,
	setIsLoadingData: PropTypes.func,
}

export default ListDocument
