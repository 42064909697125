import React, { Fragment } from 'react'
import PasswordStrengthBars from 'react-password-strength-bar'
import { withTranslation } from 'react-i18next'

import { Grid, Tooltip, TextField } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'

import styles from '../changePassword.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import * as S from './style'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

import compose from 'utils/compose'

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: getThemeColorFromLocalStorage() &&
				getThemeColorFromLocalStorage().structure &&
				getThemeColorFromLocalStorage().structure.backgroundColor
				? getThemeColorFromLocalStorage().structure.backgroundColor
				: "#fff !important",
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: "#fff !important",
			},
			'&.Mui-focused fieldset': {
				borderColor: getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: "#fff !important",
			},
		},
	},
})(TextField)

const ProfileInputStyle = {
	...extendedFormsStyle,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	labelTexRF: {
		lineHeight: '0 !important',
		top: '-8px',
	},
}

const PwdTooltip = withStyles({
	tooltip: {
		textAlign: 'left!important',
		maxWidth: '500px!important',
		padding: '8px 15px',
		borderRadius: '10px!important',
		'@media (max-width: 780px)': {
			maxWidth: '300px!important',
			marginRight: '10px',
		},
	},
})(Tooltip)

class ChangePassword extends React.Component {
	render() {
		const {
			errors,
			handleChange,
			handleSubmit,
			setFieldTouched,
			t,
			touched,
			values,
		} = this.props

		const change = (name, e) => {
			e.persist()
			handleChange(e)
			setFieldTouched(name, true, false)
		}

		return (
			<Fragment>
				<S.HeaderBox>
					<S.SectionBox>
						<Section title={t('MENU_USER_EDIT_PASSWORD')} />
					</S.SectionBox>
					<S.BtnHeaderBox>
						<Button
							size="sm"
							variant="contained"
							color={'greenButtonColor'}
							onClick={handleSubmit}
						>
							{t('BUTTON_SAVE')}
						</Button>
					</S.BtnHeaderBox>
				</S.HeaderBox>

				<GridContainer>
					<GridItem xs={12} md={6} style={{ marginTop: '20px' }}>
						<CssTextField
							fullWidth
							label={
								<CustomRequiredField value="LOGIN_OLD_PASSWORD" />
							}
							id="oldPassword"
							name="oldPassword"
							error={
								touched.oldPassword &&
								Boolean(errors.oldPassword)
							}
							value={values.oldPassword}
							onChange={change.bind(null, 'oldPassword')}
							variant="outlined"
							margin="dense"
							type="password"
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} md={6} style={{ marginTop: '7px' }}>
						<CssTextField
							fullWidth
							label={
								<CustomRequiredField value="LOGIN_NEW_PASSWORD" />
							}
							id="newPassword"
							name="newPassword"
							error={
								touched.newPassword &&
								Boolean(errors.newPassword)
							}
							helperText={
								touched.newPassword ? errors.newPassword : ''
							}
							value={values.newPassword}
							onChange={change.bind(null, 'newPassword')}
							variant="outlined"
							margin="dense"
							type="password"
							style={{ backgroundColor: '#fff' }}
						/>
						<PasswordStrengthBars
							password={values.newPassword}
							minLength={2}
							shortScoreWord={'muito curto'}
							// onChangeScore={handleChangeScore}
							barColors={[
								'#dddddd',
								'#ef4836',
								'#f6b44d',
								'#2b90ef',
								'#25c281',
							]}
							scoreWords={[
								'muito fraca',
								'fraca',
								'regular',
								'boa',
								'forte',
							]}
						/>
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} md={6} style={{ marginTop: '7px' }}>
						<CssTextField
							fullWidth
							id="confirmPassword"
							label={
								<CustomRequiredField value="LOGIN_VERIFY_PASSWORD" />
							}
							margin="dense"
							name="confirmPassword"
							onChange={change.bind(null, 'confirmPassword')}
							style={{ backgroundColor: '#fff' }}
							type="password"
							value={values.confirmPassword}
							variant="outlined"
							error={
								touched.confirmPassword &&
								Boolean(errors.confirmPassword)
							}
							helperText={
								touched.confirmPassword
									? errors.confirmPassword
									: ''
							}
						/>
						<div style={{ textAlign: 'right' }}>
							<PwdTooltip
								arrow
								id="tooltip-top"
								title={
									<div>
										<b>Regras:</b>
										<ul
											style={{
												marginLeft: '-18px',
											}}
										>
											<li>No mínimo 8 caracteres</li>
											<li>No máximo 20 caracteres</li>
											<li>
												Conter letras, números e
												caracteres especiais
											</li>
											<li>
												Não pode ter mais de 3 números
												sequenciais
											</li>
											<li>
												Não pode ter números repetidos
												em ordem
											</li>
											<li>
												Não ser igual ao CPF, CNPJ, data
												de nascimento, telefone ou
												celular
											</li>
										</ul>
									</div>
								}
								placement="right-end"
							>
								<small
									style={{
										cursor: 'pointer',
										color: '#21409a',
										'text-decoration': 'underline',
										'text-decoration-style': 'dotted',
									}}
								>
									Política de senha
								</small>
							</PwdTooltip>
						</div>
					</GridItem>
				</GridContainer>
			</Fragment>
		)
	}
}

export default compose(
	withTranslation(),
	withStyles(ProfileInputStyle),
)(ChangePassword)
