import { FindZipCode, GetCity, GetCountries, GetState } from '../../../../../../../../../redux/api/location'

import React from 'react'
import MaskedInput from 'react-text-mask'

export const fetchCountriesAndStates = async setList => {
	const countries = await GetCountries()
	const country = countries.find(country => country.name === 'Brazil')
	const states = await GetCountries(country.id)

	const result = []

	const ResultCountries = []

	states.forEach(item => {
		result.push({
			id: item?.id,
			label: item?.name,
		})
	})

	countries.forEach(item => {
		ResultCountries.push({
			id: item?.id,
			label: item?.name,
		})
	})

	setList(prevState => {
		return { ...prevState, countries: ResultCountries, states: result }
	})
}

export const handleAddress = async (values, setValue, setList, value) => {
	if (ZipCodeNoMask(value).length < 8) return
	const res = await FindZipCode(ZipCodeNoMask(value))

	if (!res) return

	setValue(`address`, res?.location || values?.address || '')
	setValue(`district`, res?.district || values?.district || '')
	setValue(`stateId`, res.stateId || '')
	setValue(`cityId`, res?.cityId || '')
	setValue(`location`, res.location || values?.location || '')
	setValue(`cityId`, res.cityId || '')
	setValue(`stateId`, res.stateId || '')
	setValue(`country`, res.countryId || '')

	const responseCity = await GetCity(res.stateId)
	if (responseCity?.length > 0) {
		setList(prevState => ({ ...prevState, cities: responseCity }))
		setValue(`cityId`, responseCity.find(item => item.id === res.cityId))
	}

	const responseState = await GetState(res.countryId)
	if (responseState?.length > 0) {
		setList(prevState => ({ ...prevState, states: responseState }))
		setValue(`stateId`, responseState.find(item => item.id === res.stateId))
	}
}

export const ZipCodeNoMask = value => {
	if (value === '') return ''

	const newValue = value?.replace(/[^0-9]+/g, '')
	return newValue || ''
}

/* eslint-disable react/prop-types */

export function CNPJMaskCustom(props) {
	const { inputRef, ...other } = props

	const handleInputRef = ref => {
		if (inputRef && typeof inputRef === 'function') {
			inputRef(ref ? ref.inputElement : null)
		}
	}

	return (
		<MaskedInput
			{...other}
			ref={handleInputRef}
			mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
			placeholderChar={'\u2000'}
			showMask={false}
		/>
	)
}

export function CPFMaskCustom(props) {
	const { inputRef, ...other } = props

	const handleInputRef = ref => {
		if (inputRef && typeof inputRef === 'function') {
			inputRef(ref ? ref.inputElement : null)
		}
	}

	return (
		<MaskedInput
			{...other}
			ref={handleInputRef}
			mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
			placeholderChar={'\u2000'}
			showMask={false}
		/>
	)
}

export function CellMaskCustom(props) {
	const { inputRef, ...other } = props

	const handleInputRef = ref => {
		if (inputRef && typeof inputRef === 'function') {
			inputRef(ref ? ref.inputElement : null)
		}
	}

	return (
		<MaskedInput
			{...other}
			ref={handleInputRef}
			mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			showMask={false}
		/>
	)
}

export function TelephoneMaskCustom(props) {
	const { inputRef, ...other } = props

	const handleInputRef = ref => {
		if (inputRef && typeof inputRef === 'function') {
			inputRef(ref ? ref.inputElement : null)
		}
	}

	return (
		<MaskedInput
			{...other}
			ref={handleInputRef}
			mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			showMask={false}
		/>
	)
}

export const CepMask = props => {
	const { inputRef, ...other } = props

	const handleInputRef = ref => {
		if (inputRef && typeof inputRef === 'function') {
			inputRef(ref ? ref.inputElement : null)
		}
	}

	return (
		<MaskedInput
			{...other}
			ref={handleInputRef}
			mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
			showMask={false}
		/>
	)
}

export const expirationDaysLabels = [
	{
		key: '30',
		label: '30 dias',
		value: 'THIRTY',
	},
	{
		key: '60',
		label: '60 dias',
		value: 'SIXTY',
	},
	{
		key: '90',
		label: '90 dias',
		value: 'NINETY',
	},
]
