/* eslint-disable react/prop-types */
import { Box, Typography } from '@material-ui/core'
import { Stack } from '@mui/material'
import React from 'react'

export default function Labelitem({ label, disabled, value, margin, isEditData, required }) {
	return (
		<Stack sx={{ marginTop: margin ?? 3 }}>
			<Typography style={{ color: isEditData ? '#333333' : '#8D8D8D', fontSize: '13px', paddingLeft: '15px' }}>{`${label}${
				required ? '*' : ''
			}`}</Typography>
			<Box
				sx={{
					backgroundColor: disabled && '#F3F2F2',
					borderRadius: disabled && '5px',
					width: '100%',
					padding: disabled && '8px 10px',
					minHeight: disabled && '40px',
				}}
			>
				{value && <Typography style={{ color: '#333333', marginTop: disabled ? 0 : 10 }}>{value}</Typography>}
			</Box>
		</Stack>
	)
}
