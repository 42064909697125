import { Box } from '@mui/material'
import styled from 'styled-components'

export const Wrapper = styled(Box)`
	display: flex;
	height: 97px;
`

export const Container = styled(Box)`
	display: flex;
	align-items: center;
	gap: 18px;
	border-radius: 39px;
	margin: 7px 0;
	min-width: 100px;
	width: 410px;
	height: 78px;
	padding-right: 20px;
	position: absolute;
	left: 24px;
	transform: translateY(2px);

	background: linear-gradient(302deg, #ffffff 0%, #f5f5f5 100%) 0 0 no-repeat
		padding-box;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
	border: 2px solid #dfdfdf;
	opacity: 1;
`

export const Title = styled.div`
	text-align: left;
	font: normal 15px Roboto;
	letter-spacing: 0;
	color: #4e4e4e;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const Subtitle = styled.div`
	text-align: left;
	font: normal bold 18px Roboto;
	letter-spacing: 0;
	color: #4e4e4e;
`

export const Section = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;

	// adicionar 3 pontinhos no final do texto
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`

export const Circle = styled(Box)`
	height: unset;
	border-radius: 50%;
	width: 100px;
	background: ${({ bgColor }) =>
		bgColor ||
		'transparent linear-gradient(144deg, #7acdd6 0%, #495da2 100%) 0% 0% no-repeat padding-box'};
`

export const Icon = styled.div`
	display: flex;
	background: transparent;
	border-radius: 0px 41px 41px 0px;
	opacity: 1;
	justify-content: center;
	align-items: center;
	min-width: 78px;
	height: 66px;

	img {
		width: 41px;
		height: 41px;
		opacity: 1;
	}
`

export const IconBox = styled(Box)`
	align-items: center;
	background: #f2f2f2 0% 0% no-repeat padding-box;
	border-radius: 5px;
	color: #f8a62a;
	cursor: pointer;
	display: flex;
	justify-content: center;
	width: 30px;
	height: 30px;
	opacity: 1;

	transform: translateY(2px);

	:hover {
		background: #fb9006 0% 0% no-repeat padding-box;
		color: #ffffff;
	}
`
