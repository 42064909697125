import styled from 'styled-components'
import Box from '@mui/material/Box'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

export const ModalBox = styled(Box)`
	position: absolute;
	top: 20%;
	right: 5%;
	left: 25%;
	width: 70%;
	transform: translate(-50% -50%);
	background-color: #fff;
	border-radius: 8px;
	box-shadow: 24;

	@media screen and (max-width: 959px) {
		left: 19%;
	}
`
export const HeaderModal = styled.div`
	display: flex;
	flex-direction: column;
	padding: 25px 25px 0px 25px;
	justify-content: space-between;
`

export const BodyModal = styled.div`
	padding: 0px 25px 25px 25px;
`

export const Title = styled.p`
	color: #333333;
	display: flex;
	align-items: center;
	font: normal normal bold 14px Roboto;
	letter-spacing: 0px;
	text-transform: math-auto;
	opacity: 1;
	margin: 0 0 0 5px;
`

export const PartnersIcon = styled.i`
	align-items: center;
	color: ${getThemeColorFromLocalStorage() &&
	getThemeColorFromLocalStorage().visual &&
	getThemeColorFromLocalStorage().visual.icons &&
	getThemeColorFromLocalStorage().visual.icons.color
		? getThemeColorFromLocalStorage().visual.icons.color
		: '#fff !important'};
	display: flex;
`

export const ButtonCloseIcon = styled.i`
	color: #fff;
	font-size: 16px !important;
`
