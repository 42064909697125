import styled from 'styled-components'

export const PaymentOptions = styled.div`
	margin-bottom: 25px;
	img {
		width: 100%;
	}

	.MuiToggleButtonGroup-root {
		display: flex;
		flex-wrap: wrap;
	}

	.MuiToggleButton-root {
		cursor: pointer;
		border: none;
		background-color: #ffffff;
		border-radius: 5px;
		box-shadow: 6px 6px 1px rgba(214, 214, 214);
		margin: 0 15px 15px 0;
		opacity: 1;
		padding: 13px;
		outline: none;
		height: 65px;
		transition: all 0.3s ease 0s;
		width: 118px;
		&:hover {
			box-shadow: none;
		}
	}

	.MuiToggleButton-root.Mui-selected {
		background-color: #bdbfc1;
		box-shadow: 5px 5px 5px rgba(0, 0, 0, 0);
		&:hover {
			background-color: #ccced1;
		}
	}

	.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:last-of-type) {
		border-radius: 9px;
		&:hover {
			box-shadow: none;
		}
	}

	.MuiToggleButtonGroup-root .MuiToggleButtonGroup-grouped:not(:first-of-type) {
		border-radius: 9px;
	}

	p {
		font: normal normal bold 18px/60px Roboto;
		letter-spacing: 0px;
		color: #505050;
	}
`

export const Icon = styled.i`
	color: #08993a;
	position: absolute;
	top: 8px;
	left: 8px;
`
