import axios from 'axios'
import { FETCH_UNAUTH_USER } from '../redux/actions/actionsTypes'
import { userService } from '../redux/services'

export default {
	setupInterceptors: (store) => {
		// Add a response interceptor
		axios.interceptors.response.use(
			function (response) {
				return response
			},
			function (error) {
				//catches if the session ended!
				// if (error && error.response && error.response.status === 401) {
				// 	//console.log('Network-service: ', error.response.statusText);
				// 	userService.logout()
				// 	window.location.reload()
				// 	store.dispatch({ type: FETCH_UNAUTH_USER })
				// }
				// else if (error.response.data.token.KEY == 'ERR_EXPIRED_TOKEN') {
				//     //console.log("EXPIRED TOKEN!");
				//     localStorage.clear();
				//     store.dispatch({ type: FETCH_UNAUTH_USER });
				// };
				if (error.response) {
					// Request made and server responded
					const { data } = error.response
				} else if (error.request) {
					// The request was made but no response was received
				} else {
					// Something happened in setting up the request that triggered an Error
				}
				return Promise.reject(error)
			},
		)
	},
}
