/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

// @ Styles and request
import * as S from './styles'

// @Material-ui
import Modal from '@mui/material/Modal'
import { Icon } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

// @Components
import ModalsAuthorizer from './ModalsAuthorizer'
import SimpleModal from 'components/CustomModal/Confirm'
import Button from 'components/CustomButtons/Button.jsx'
import {
	NewTab,
	NewTabs,
	NewTabList,
	NewTabPanel,
} from 'components/NewTab/styles'
import CustomHeaderIcon from 'components/CustomHeaderIcon'
import Section from 'components/Section'

// @ Context and Utils
import * as Utils from './utils'
import GeneralContext from '../../../../GeneralContext'
import { authorizerIcons, brandsIcons, purchaseOptions } from '../../utils'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'
import CustomizedTabs from '../../../../components/CustomNavTabs'
import { RemoveSelectedPurchaser } from '../../../../redux/api/paymentGateway'

const CardParameterization = () => {
	const { t } = useTranslation()
	const { purchase, setPurchase, settings } = useContext(GeneralContext)
	const [disableTab, setDisableTab] = useState(true)
	const [data, setData] = useState({ value: false, icon: '', finish: false })
	const [purchaserOrSettings, setPurchaserOrSettings] = useState('')

	const [activeTab, setActiveTab] = useState(0)
	const [selectedAuthorizer, setSelectedAuthorizer] = useState('h')

	// informations modal
	const [icon, setIcon] = useState('')
	const [name, setName] = useState('')
	const [labelId, setLabelId] = useState('')
	const [labelKey, setLabelKey] = useState('')

	// open modal
	const [openCancelModal, setOpenCancelModal] = useState(false)
	const [open, setOpen] = useState(false)

	const [paymentOptionsList, setPaymentOptions] = useState('')

	const [active, setActive] = useState(0)

	//PurchaserObjects
	const [purchaserObjects, setPurchaserObjects] = useState([])

	const handleChangeTab = (event, newValue) => setActive(newValue)

	const handleOpen = () => setOpen(true)

	const handleOpenCancelModal = () => setOpenCancelModal(true)

	const handleCloseCancelModal = () => setOpenCancelModal(false)

	const handleClickCancelModal = async () => {
		RemoveSelectedPurchaser()
		setOpenCancelModal(false)
		setData({ finish: false })
	}

	const handleClose = () => {
		setOpen(false)
		setData({ value: false, icon: '' })
	}

	const handleChange = (event, i, name, labelId, labelKey) => {
		setSelectedAuthorizer(event.target.value)
		setIcon(i)
		setName(name)
		setLabelId(labelId)
		setLabelKey(labelKey)
	}

	const handleNext = event => {
		if (event) {
			setOpen(false)
			setData({
				value: true,
				icon: icon,
				name: name,
				labelId: labelId,
				labelKey: labelKey,
			})
		}
	}

	// communication between components
	const childToParent = childData => setData(childData)

	useEffect(() => {
		Utils.GetPurchaserObjects(setPurchaserObjects)

		if (_.isEmpty(settings)) {
			setPurchaserOrSettings(purchase)
		} else {
			Utils.verifyIcons(purchaseOptions, setData, settings)
			setPurchaserOrSettings(
				settings?.selectedPurchasers[0]?.length !== 0
					? settings?.selectedPurchasers[0]
					: '',
			)
		}
	}, [settings])

	useEffect(() => {
		if (data.click === false) setOpen(false)
	}, [data])

	const disabledPaymentOptions = (event, nextView) =>
		setPaymentOptions(nextView)

	const handleTabChange = (event, newValue) => setActiveTab(newValue)

	return (
		<>
			<Section
				title={t('NAVPILLS_PARAMETERIZATION')}
				style={{ flexGrow: 1 }}
			/>
			<S.BodyCard>
				<CustomizedTabs
					handleTabChange={handleTabChange}
					active={activeTab}
					tabs={[
						{
							label: t('TITLE_DEFAULT_OPTION'),
							disabled: false,
							tabContent: (
								<>
									{data.finish ? (
										<>
											<Button
												size="sm"
												variant="outlined"
												color="greenButtonColor"
												style={{
													margin: '15px 0 25px 0',
												}}
												onClick={handleOpenCancelModal}
											>
												{t(
													'BUTTON_DISCONNECT_WITH_AUTHORIZER',
												)}
											</Button>
											<S.AuthorizerConnects>
												<p>
													{t(
														'FIELD_AUTHORIZER_CONNECTED',
													)}
												</p>
												<img src={data.icon} />
											</S.AuthorizerConnects>
											<S.TitlesPaymentOptions>
												<span>
													{t('PAYMENT_OPTIONS')}
												</span>
												<p>
													{t(
														'FIELD_DISABLE_PAYMENT_OPTION',
													)}
												</p>
											</S.TitlesPaymentOptions>
											<S.PaymentOptions>
												<p>
													Cartão de crédito e débito
												</p>
												<ToggleButtonGroup
													onChange={
														disabledPaymentOptions
													}
													value={paymentOptionsList}
												>
													{brandsIcons(
														purchaserOrSettings,
													)[0].brands.map(i => {
														return (
															<ToggleButton
																value={i.name}
																selected={
																	!i.enabled
																}
																key={i.name}
																onClick={() => {
																	Utils.disableOrEnablePaymentMethods(
																		i.name,
																		settings,
																	)
																}}
															>
																<img
																	src={
																		i?.enabled
																			? i?.enabledIcon
																			: i?.disabledIcon
																	}
																/>
															</ToggleButton>
														)
													})}
												</ToggleButtonGroup>
												<p>Outras opções</p>
												<ToggleButtonGroup
													onChange={
														disabledPaymentOptions
													}
													value={paymentOptionsList}
												>
													{brandsIcons(
														purchaserOrSettings,
													)[0].paymentMethods.map(
														i => {
															return (
																<ToggleButton
																	value={
																		i.name
																	}
																	selected={
																		!i.enabled
																	}
																	key={i.name}
																	onClick={() => {
																		Utils.disableOrEnablePaymentMethods(
																			i.name,
																			settings,
																		)
																	}}
																>
																	<img
																		src={
																			i?.enabled
																				? i?.enabledIcon
																				: i?.disabledIcon
																		}
																	/>
																</ToggleButton>
															)
														},
													)}
												</ToggleButtonGroup>
											</S.PaymentOptions>
										</>
									) : (
										<Button
											size="sm"
											color="greenButtonColor"
											style={{
												margin: '15px 0 25px 0',
											}}
											onClick={handleOpen}
										>
											{t(
												'BUTTON_CONNECT_WITH_AUTHORIZER',
											)}
										</Button>
									)}

									<Modal open={open}>
										<S.CustomBoxModal>
											<S.BoxIcon>
												<Icon>
													<i className="icon-servicos_contratados_pagamentos" />
												</Icon>
												<S.Titles>
													<span>
														{' '}
														{t('Autorizadora')}{' '}
													</span>
													<p>
														{' '}
														{t(
															'Selecione a autorizadora desejada',
														)}{' '}
													</p>
												</S.Titles>
											</S.BoxIcon>
											<S.BodyModal>
												{purchaserObjects &&
													authorizerIcons(
														purchaserObjects,
													).map(i => (
														<RadioGroup
															value={
																selectedAuthorizer
															}
															key={i.name}
															onChange={event => {
																setPurchase(i)
																handleChange(
																	event,
																	i.icon,
																	i.name,
																	i.labelId,
																	i.labelKey,
																)
															}}
														>
															<FormControlLabel
																control={
																	<Radio
																		size="small"
																		value={
																			i.name
																		}
																	/>
																}
																label={
																	<React.Fragment>
																		<img
																			src={
																				i.icon
																			}
																			className="profile-img"
																			width="40px"
																			height="auto"
																			style={{
																				marginRight:
																					'5px',
																			}}
																		/>
																	</React.Fragment>
																}
															/>
														</RadioGroup>
													))}
											</S.BodyModal>
											<S.ModalButtons>
												<Button
													size="sm"
													variant="contained"
													color="greenBorderButtonColor"
													style={{
														margin: '10px 15px 0 0',
													}}
													onClick={handleClose}
												>
													{t('BUTTON_CANCEL')}
												</Button>
												<Button
													size="sm"
													disabled={
														selectedAuthorizer !==
														'h'
															? false
															: true
													}
													style={{
														backgroundColor:
															selectedAuthorizer ===
															'h'
																? '#BBBBBB'
																: getThemeColorFromLocalStorage()
																		?.navigation
																		?.buttons
																		?.backgroundColor,
														margin: '10px 0 0 0',
														color: '#fff',
													}}
													onClick={event => {
														handleNext(event)
													}}
												>
													{t('BUTTON_NEXT')}
												</Button>
											</S.ModalButtons>
										</S.CustomBoxModal>
									</Modal>
								</>
							),
						},
						{
							label: t('TITLE_SECOND_OPTION'),
							content: <></>,
							disabled: disableTab,
						},
					]}
				/>

				<ModalsAuthorizer
					parentToChild={data}
					childToParent={childToParent}
					setOpen={setOpen}
				/>
			</S.BodyCard>
			<SimpleModal
				open={openCancelModal}
				onClick={handleClickCancelModal}
				handleClose={handleCloseCancelModal}
				btnCloseTxt={t('BUTTON_NO')}
				btnConfirmTxt={t('BUTTON_YES')}
				text={t('FIELD_RESET_CONNECT_AUTHORIZER')}
			/>
		</>
	)
}

export default CardParameterization
