import styled from 'styled-components'

export const FormContain = styled.form`
	width: 100%;
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) => props.themeColorHighlight};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) => props.themeColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => props.themeColor} !important;
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.themeColor};
	}
`

export const TitleSection = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 25px;
	width: 100%;
	@media only screen and (min-width: 450px) {
		flex-direction: row;
	}
`
export const BtnTitleSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: end;
	gap: 0.938rem;
	//padding-left: 10px;
	//margin-left: 0.75rem;
	width: 100%;
	button {
		margin: 0 !important;
	}
	@media only screen and (min-width: 450px) {
		width: 202px;
	}
`
export const InputContain = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	.MuiFormControl-marginDense {
		margin: 0 !important;
	}
	.iLnFEI .MuiOutlinedInput-input {
		height: 17px;
	}
`
export const ReservationName = styled.div`
	width: 100%;
	@media only screen and (min-width: 800px) {
		width: 70%;
	}
`
export const InputValue = styled.div`
	margin-top: 30px;
	width: 100%;
	@media only screen and (min-width: 800px) {
		margin-top: 0;
		padding-left: 10px;
		width: 30%;
	}
`
export const InputDescription = styled.div`
	margin-top: 30px;
	width: 100%;
`
