import React from 'react'
import PropTypes from 'prop-types'

import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import * as S from './styles'
import { columns } from './utils'

import { GetExportingLogsHistoryForManagement } from 'redux/api/paymentGateway'
import { useTranslation } from 'react-i18next'
import { GetExportingLogsHistory } from '../../../../../../../../redux/api/paymentGateway'
import Cookies from 'js-cookie'

const TableComponent = ({ tableRef, watch, setModal }) => {
	const { t } = useTranslation()

	const url = window.location.href

	const { dateFrom, dateTo, history } = watch()

	return (
		<S.Table>
			<MaterialTable
				tableRef={tableRef}
				title={false}
				options={{
					sorting: true,
					emptyRowsWhenPaging: false,
					toolbar: false,
					actionsColumnIndex: -1,
					search: false,
					draggable: false,
					pageSize: 10,
					pageSizeOptions: [10, 20, 30, 40, 50, 100],
				}}
				localization={{
					body: {
						emptyDataSourceMessage: (
							<S.EmptyTable>
								<i className="icon-ico_information" style={{ color: '#B4B4B4' }} />
								<span>Nenhum registro para exibir</span>
							</S.EmptyTable>
						),
					},
					pagination: {
						labelRowsSelect: 'linhas',
						labelDisplayedRows: '{from}-{to} de {count}',
						firstTooltip: 'Primeira página',
						previousTooltip: 'Página anterior',
						nextTooltip: 'Próxima página',
						lastTooltip: 'Última página',
					},
				}}
				columns={columns(t, url, setModal)}
				data={query =>
					new Promise(resolve => {
						const { page, pageSize } = query

						const reqDataLog = {
							id: '',
							name: history ? history : null,
							type: '',
							partnerId: String(Cookies.get('partnerId')),
							exportedAt: null,
							initialPeriod: dateFrom ? dateFrom : null,
							finalPeriod: dateTo ? dateTo : null,
							orderByDescending: true,
							pageSize: pageSize,
							page: page + 1,
						}

						GetExportingLogsHistory(reqDataLog).then(result => {
							result && result?.data
								? resolve({
									data: result?.data?.results,
									page: result?.data?.currentPage - 1,
									totalCount: result?.data?.recordCount,
								})
								: resolve({
									data: [],
									page: 0,
									totalCount: 0,
								})
						})
					})
				}
			/>
		</S.Table>
	)
}

TableComponent.propTypes = {
	tableRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]),
	watch: PropTypes.func,
	setModal: PropTypes.func,
}

export default TableComponent
