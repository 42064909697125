import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../../../../../../../redux/api/themeColors'

import Button from 'components/CustomButtons/Button.jsx'

export const CustomButton = styled(Button)`
	background-color: ${({ isDisabled }) => isDisabled && '#ccc'} !important;
	pointer-events: ${({ isDisabled }) => isDisabled && 'none'} !important;
`

export const Table = styled.div`
	margin-top: 25px;

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				font-size: 15px;
				font-weight: bold;
				z-index: 0 !important;

				:first-child {
					border-top-left-radius: 6px;
					width: 20% !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:last-child {
					border-top-right-radius: 6px;
					width: 20% !important;
					justify-content: flex-end;
					padding-left: 75px;
					span {
						svg {
							display: none;
						}
					}
				}
				:nth-child(2) {
					text-align: left !important;
                    width: 20% !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 45% !important;
				}
				:nth-child(4) {
					text-align: center !important;
					width: 20% !important;
				}
				:nth-child(5) {
					text-align: center !important;
					width: 20% !important;
				}
			}
		}
	}

	.MuiTableBody-root {
		.MuiTableCell-alignLeft {
			text-align: left;
			:last-child {
				text-align: right;
			}
		}
		tr {
			color: #717171;
			font-size: 15px;
			height: 45px;

			p {
				font-size: 13px;
				font-style: italic;
				margin: 0px;
			}

			td {
				padding: 0px 15px !important;
				:first-child {
					width: 20% !important;
					justify-content: flex-start;
					text-align: left !important;
				}
				:last-child {
					justify-content: flex-end;
				}
				:nth-child(2) {
					text-align: left !important;
					width: 20% !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 20% !important;
				}
				:nth-child(4) {
					text-align: center !important;
					width: 20% !important;
				}
				:nth-child(5) {
					text-align: right !important;
					width: 20% !important;
				}
				:last-child {
					border-top-right-radius: 6px;
					width: 20% !important;
				}
			}
		}
	}
`

export const DateRow = styled.div`
	text-align: left;
	font: normal normal normal Segoe UI;
	font-size: 15px;
	line-height: 10px;
	letter-spacing: 0px;
	color: #717171;
	opacity: 1;
`

export const HourRow = styled.div`
	text-align: left;
	font: italic normal normal 12px Segoe UI;
	font-size: 12px;
	line-height: 8px;
	letter-spacing: 0px;
	color: #717171;
	opacity: 1;
`

export const CreatedAt = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`

export const EmptyTable = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: 3rem;
	color: #b4b4b4;
	text-align: center;

	i {
		font-size: 3em;
		cursor: pointer;
	}

	.icon-informacao:before {
		font-size: 1.5em;
	}

	span {
		font-weight: 900;
		margin: 25px 0 12px;
	}

	.MuiTablePagination-toolbar {
		padding-right: 42px;
	}
`

export const IconButtonCustom = styled.div`
	.MuiIconButton-root {
		color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().headers &&
		getThemeColorFromLocalStorage().headers.backgroundColor
			? getThemeColorFromLocalStorage().headers.backgroundColor
			: '#fff !important'};
		border: 1px solid;
		padding: 4px;
		border-color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().headers &&
		getThemeColorFromLocalStorage().headers.backgroundColor
			? getThemeColorFromLocalStorage().headers.backgroundColor
			: '#fff !important'};
		margin-right: 5px;
		&:hover {
			background-color: ${getThemeColorFromLocalStorage() &&
			getThemeColorFromLocalStorage().headers &&
			getThemeColorFromLocalStorage().headers.backgroundColor
				? getThemeColorFromLocalStorage().headers.backgroundColor
				: '#fff !important'};
		}
	}

	.MuiSvgIcon-root {
		&:hover {
			color: #ffff;
		}
	}

	span {
		color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().headers &&
		getThemeColorFromLocalStorage().headers.backgroundColor
			? getThemeColorFromLocalStorage().headers.backgroundColor
			: '#fff !important'};
	}
`

