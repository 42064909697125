import React, { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _, { filter } from 'lodash'

import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable'

import * as S from './styles'
import * as UT from './utils/utils'
import { GetProductsCatalog } from '../../../../redux/api/productPage'
import { buildQueryString, buildProductQueryString } from './utils/utils'

const ListComponent = ({ filterValues, setLoading, tableRef, pageSizeState, setPageSizeState }) => {
	const { t } = useTranslation()
	const [recordPageState, setRecordPageState] = useState()

	return (
		<S.Container>
			<CustomMaterialTable
				tableRef={tableRef}
				title={false}
				onChangeRowsPerPage={e => {
					setPageSizeState(e)
				}}
				options={{
					pageSize: pageSizeState,
					pageSizeOptions: [10, 20, 30, 50, 100],
					search: false,
					toolBar: false,
				}}
				localization={{
					body: {
						emptyDataSourceMessage: filterValues.Name ? 'Não há produtos a serem listados' : 'Nenhum resultado encontrado',
					},
				}}
				columns={UT.Columns(t)}
				data={obj =>
					new Promise(resolve => {
						delete obj.totalCount
						delete obj.filters

						const pagination = {
							...obj,
							page: obj.page + 1,
							error: '',
							orderBy: '',
						}

						// const body = buildQueryString({...filterValues, ...pagination})
						const body = buildProductQueryString({ ...filterValues, ...pagination })

						GetProductsCatalog(body).then(result => {
							if (!_.isEmpty(result)) {
								resolve({
									data: result.results,
									page: result.currentPage - 1,
									totalCount: result.recordCount,
									pageSize: result.pageSize,
								})
							} else {
								resolve({
									data: [],
									page: 0,
									totalCount: 0,
								})
							}
						})
					})
				}
			/>
		</S.Container>
	)
}

export default ListComponent
