import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Grid, Fade, Typography } from '@material-ui/core'
import imageCSV from 'assets/img/modelo_excel.jpg'
import imageTXT from 'assets/img/modelo_txt.jpg'
import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import {
	ModalContainer,
	PaperContainer,
	Close,
	Icon,
	TypographyTXT,
	CloseButton,
	CloseFile,
	MinimumRecords,
	IconFile,
} from './styles'
import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

const textType = [
	{
		text: 'Deseja realmete excluir este item?',
		type: 'delete',
	},
	{
		text: 'As alterações realizadas serão perdidas. Deseja realmente continuar?',
		type: 'cancel',
	},
	{
		text: 'Para prosseguir com a exclusão é necessário informar a sua senha.',
		type: 'confirmDelete',
	},
	{
		type: 'cancelError',
		text: 'As alterações realizadas serão perdidas. Deseja realmente continuar?',
	},
	{
		type: 'ModalWarning',
		text: 'Deseja realmente excluir este item?',
	},
	{
		type: 'openModalWMutipleError',
		text: '',
	},
	{
		type: 'fileInvalid',
		text: '',
	},
	{
		type: 'fileValid',
		text: '',
	},
	{
		type: 'Reserve_insufficient_funds',
		text: '',
	},
	{
		type: 'minimumRecords',
		text: 'Seu arquivo deve conter pelo menos 10 registros, abaixo dessa quantidade a distribuição deverá ser realizada de forma manual.',
	},
]

export const validationSchema = Yup.object().shape({
	password: Yup.string().required('Senha obrigatória.'),
})

function ConfirmModal({
	className,
	closeByClick,
	error,
	maxWidth,
	minWidth,
	onCancel,
	onConfirm,
	onDelete,
	data,
	open,
	padding,
	parameter,
	shadow,
	standardFocus,
	t,
	text,
	type,
}) {
	const [displayText, setDisplayText] = useState({})

	const [themeColor, setThemeColor] = useState({})

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		ModalType(type)
	}, [type, text, parameter, error])

	const getColor = () => {
		const color =
			themeColor &&
			themeColor.titles &&
			themeColor.titles.main &&
			themeColor.titles.main.textColor
		return color
	}

	const downloadLocalFile = (type) => {
		const link = document.createElement('a')
		// eslint-disable-next-line default-case
		switch (type) {
			case 'csv':
				link.href = '/files/Modelo.csv'
				link.setAttribute('download', 'Modelo.csv')
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				break
			case 'txt':
				link.href = '/files/Modelo.txt'
				link.setAttribute('download', 'Modelo.txt')
				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
				break
		}
	}

	function ModalType(type) {
		if (type) {
			if (!text) {
				return textType.forEach((item) => {
					if (item.type === type) {
						setDisplayText(item.text)
					}
				})
			}
			return setDisplayText(text)
		}
		if (text) {
			return setDisplayText(text)
		}
		setDisplayText('Deseja Continuar?')
	}

	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			minwidth={minWidth}
			disableEnforceFocus
		>
			{open && type === 'Reserve_insufficient_funds' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								maxWidth: '860px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '40px',
								paddingTop: '25px',
							}}
						>
							<Grid item xs={12} sm={12}>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									textAlign: 'center',
								}}
							>
								<Typography
									className="infoMinimumRecords"
									variant="caption"
								>
									Saldo insuficiente
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									margin: '30px',
								}}
							>
								<Typography
									className="infoTextReserv"
									style={{ textAlign: 'center' }}
								>
									<strong>
										O saldo disponível na reserva
										selecionada de {''}
										{data &&
											data.valueReserve.toLocaleString(
												'pt-BR',
												{
													style: 'currency',
													currency: 'BRL',
												},
											)}
										, não é suficiente
									</strong>{' '}
									para realizar a<br /> distribuição do valor
									total dos registros importados que é de{' '}
									{data &&
										data.valueAward.toLocaleString(
											'pt-BR',
											{
												style: 'currency',
												currency: 'BRL',
											},
										)}
									.
									<br /> Você pode descartar a operação ou,
									para prosseguir, é necessário remover
									registros ou alterar para um valor padrão.
									<br />
									<br />
									Como deseja prosseguir?
								</Typography>
							</Grid>
							<GridItem
								xs={12}
								sm={12}
								className="buttonContainerModal"
							>
								<Button
									size="sm"
									variant="contained"
									color="greenButtonColor"
									style={{
										borderRadius: '5px',
										fontSize: '16px',
										width: '134px',
									}}
									onClick={onCancel}
								>
									{t('Descartar')}
								</Button>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'yes'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										borderRadius: '5px',
										fontSize: '16px',
										width: '134px',
									}}
									onClick={onConfirm}
								>
									{t('Ajustar valores')}
								</Button>
							</GridItem>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'minimumRecords' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<CloseButton>
							<button onClick={onCancel}>
								<i className="icon-ico_cancelar" /> Fechar
							</button>
						</CloseButton>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								maxWidth: '860px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
								paddingTop: '13px',
							}}
						>
							<Grid item xs={12} sm={12}>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									textAlign: 'center',
								}}
							>
								<Typography
									className="infoMinimumRecords"
									variant="caption"
								>
									A quantidade de registros está abaixo do
									permitido
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									margin: '30px',
								}}
							>
								<MinimumRecords>{displayText}</MinimumRecords>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'fileValid' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer
						padding={padding}
						maxwidth={maxWidth}
						style={{
							height: '44.875rem',
							overflowY: 'auto',
							overflowX: 'hidden',
							maxWidth: '56.25rem',
							marginBottom: '7px',
						}}
					>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								maxWidth: '56.25',
								height: 'fit-content',
								paddingLeft: '28px',
								paddingRight: '25px',
							}}
						>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									transform: 'translate(10px, 10px)',
								}}
							>
								<CloseFile
									onClick={onCancel}
									startIcon={
										<i
											className="icon-ico_cancelar"
											style={{
												paddingLeft: '5px',
												fontSize: '15px',
												fontWeight: '400',
											}}
										/>
									}
								>
									Fechar
								</CloseFile>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
							//className="paddPagText"
							>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
								<Grid
									item
									xs={12}
									sm={12}
									style={{
										textAlign: 'center',
										display: 'flex',
									}}
								>
									<Typography
										className="infoTextFileTitle"
										variant="caption"
										style={{
											fontWeight: 'bold',
										}}
									>
										O formato do arquivo é incompatível
									</Typography>
								</Grid>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									marginTop: '15px',
								}}
							>
								<Typography className="infoTextFile">
									O arquivo de importação deve conter os dados
									de CPF ou Código do participante e o Valor a
									ser creditado na conta do destinatário.
									<br /> O sistema aceitará o mínimo de 10 e o
									máximo de 1.000 registros no mesmo arquivo.
									<br /> O padrão do arquivo a ser utilizado
									deverá seguir a formatação conforme indicado
									nas imagens abaixo.
									<br /> Uma coluna, indicando o CPF ou o
									Código do participante (CPF_Cod) e outra
									indicando o valor a ser creditado na conta
									do
									<br /> participante (Valor), sendo
									considerado um registro por linha.
								</Typography>
								<span
									style={{
										fontWeight: 'normal !important',
									}}
								>
									IMPORTANTE
								</span>
								<Typography
									className="infoTextFile"
									style={{
										background:
											'0% 0% no-repeat padding-box padding-box rgb(241, 241, 241)',
										opacity: '1',
										padding: '9px',
										borderRadius: '10px',
									}}
								>
									* O arquivo deve conter no mínimo 10
									registros. Abaixo dessa quantidade a
									distribuição deverá ser realizada de forma
									manual. <br />* O arquivo excel deverá ser
									salvo no formato CSV ou TXT. A ferramenta
									não aceita as formatações .XLS e XLSX.{' '}
									<br />* A coluna "CPF_Cod" deve ser
									formatada como texto para que os CPFs com
									zero a esquerda não sejam truncados.
									<br /> * Caso o documento importado
									ultrapasse o limite, será necessário separar
									em lotes.
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							sm={12}
							spacing={2}
							className="paddPag"
							style={{
								paddingLeft: '25px',
								paddingRight: '25px',
								paddingBottom: '25px',
							}}
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								spacing={2}
							>
								<Typography
									onClick={() => {
										downloadLocalFile('csv')
									}}
									style={{
										color: getColor(),
										fontSize: '18px',
										textAlign: 'left',
										fontWeight: '400',
										letterSpacing: '0px',
										opacity: '1',
										textTransform: 'none',
										cursor: 'pointer',
										marginBottom: '10px',
									}}
									variant="h6"
								>
									<IconFile
										color={themeColor}
										className="icon-ico_modelo_Excel"
									/>
									Baixar modelo Excel
								</Typography>
								<img
									src={imageCSV}
									alt="Arquivo Excel exemplo"
									style={{
										width: '100%',
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								spacing={2}
							>
								<Typography
									onClick={() => {
										downloadLocalFile('txt')
									}}
									style={{
										color: getColor(),
										fontSize: '18px',
										textAlign: 'left',
										fontWeight: '400',
										letterSpacing: '0px',
										opacity: '1',
										textTransform: 'none',
										cursor: 'pointer',
										marginBottom: '10px',
									}}
									variant="h6"
								>
									<IconFile
										color={themeColor}
										className="icon-ico_modelo_txt"
									/>
									Baixar modelo CSV/TXT
								</Typography>
								<img
									src={imageTXT}
									alt="Arquivo CSV/TXT exemplo"
									style={{
										width: '100%',
									}}
								/>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'fileInvalid' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<CloseButton>
							<button onClick={onCancel}>
								<i className="icon-ico_cancelar" /> Fechar
							</button>
						</CloseButton>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								maxWidth: '600px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
							}}
						>
							<Grid item xs={12} sm={12}>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									margin: '30px',
								}}
							>
								<TypographyTXT>
									O formato do arquivo é inválido.
									<br /> Repita a operação utilizando uma das
									duas opções: csv, txt.
								</TypographyTXT>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'openModalWMutipleError' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<CloseButton>
							<button onClick={onCancel}>
								<i className="icon-ico_cancelar" /> Fechar
							</button>
						</CloseButton>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								width: '612px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
							}}
						>
							<Grid item xs={12} sm={12}>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
								<Typography
									style={{
										textAlign: 'center',
										fontSize: '18px',
										fontWeight: '700',
									}}
								>
									Erro
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									margin: '30px',
								}}
							>
								<Typography
									style={{
										borderBottom: '1px solid #ccc',
										paddingBottom: '10px',
										marginBottom: '10px',
										textAlign: 'center',
										letterSpacing: '0px',
										color: '#333333',
										opacity: 1,
									}}
								>
									O arquivo contém mais de 10% de registros
									com erros.
									<br /> Confira os registro que estão sendo
									importados e tente novamente.
								</Typography>
								<Typography
									style={{
										marginTop: '10px',
										textAlign: 'left',
										letterSpacing: '0px',
										color: '#333333',
										opacity: 1,
										font: 'normal normal normal 16px/25px Roboto',
									}}
								>
									Possíveis erros que podem estar ocorrendo:{' '}
									<span
										style={{
											font: 'italic normal normal 15px Roboto',
										}}
									>
										<br />- CPFs inexistentes
										<br />- CPFs não cadastrados
										<br />- CPFs com formatação errada
									</span>
								</Typography>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'ModalWarning' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer
						padding={padding}
						maxwidth={maxWidth}
						style={{
							height: '41.600rem',
							overflowY: 'auto',
							overflowX: 'hidden',
							maxWidth: '55.25rem',
							marginBottom: '7px',
						}}
					>
						<GridContainer
							xs={12}
							sm={12}
							style={{
								//margin: '30px 20px 30px 2px',
								maxWidth: '860px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									paddingRight: '0px !important',
								}}
							>
								<Typography
									className="infoText"
									style={{
										color: getColor(),
									}}
									variant="h6"
								>
									Informações para importação nos formatos{' '}
									<span>Excel</span> e padrão{' '}
									<span>CSV/TXT</span>
								</Typography>
								<Close>
									<button onClick={onCancel}>
										<i className="icon-ico_cancelar" />{' '}
										Fechar
									</button>
								</Close>
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								style={{
									marginTop: '20px',
								}}
							>
								<Typography className="infoTextFile">
									O arquivo de importação deve conter os dados
									de CPF ou Código do participante e o Valor a
									ser creditado na conta do destinatário.
									<br /> O sistema aceitará o mínimo de 10 e o
									máximo de 1.000 registros no mesmo arquivo.
									<br />O padrão do arquivo a ser utilizado
									deverá seguir a formatação conforme indicado
									nas imagens abaixo.
									<br /> Uma coluna, indicando o CPF ou o
									Código do participante (CPF_Cod) e outra
									indicando o valor a ser creditado na conta
									do
									<br /> participante (Valor), sendo
									considerado um registro por linha.
								</Typography>
								<span
									style={{
										fontWeight: 'normal !important',
									}}
								>
									IMPORTANTE
								</span>
								<Typography
									className="infoTextFile"
									style={{
										background:
											'0% 0% no-repeat padding-box padding-box rgb(241, 241, 241)',
										opacity: '1',
										padding: '9px',
										borderRadius: '10px',
									}}
								>
									* O arquivo deve conter no mínimo 10
									registros. Abaixo dessa quantidade a
									distribuição deverá ser realizada de forma
									manual. <br />* O arquivo excel deverá ser
									salvo no formato CSV ou TXT. A ferramenta
									não aceita as formatações .XLS e XLSX.
									<br /> * A coluna "CPF_Cod" deve ser
									formatada como texto para que os CPFs com
									zero a esquerda não sejam truncados.
									<br /> * Caso o documento importado
									ultrapasse o limite, será necessário separar
									em lotes.
									<br />
								</Typography>
							</GridItem>
						</GridContainer>
						<GridContainer
							xs={12}
							sm={12}
							style={{
								margin: 0,
								padding: '10px',
								marginBottom: '20px',
							}}
						>
							<GridItem xs={12} sm={12} md={6} lg={6} spacing={2}>
								<Typography
									onClick={() => {
										downloadLocalFile('csv')
									}}
									style={{
										color: getColor(),
										fontSize: '18px',
										textAlign: 'left',
										fontWeight: '400',
										letterSpacing: '0px',
										opacity: '1',
										textTransform: 'none',
										cursor: 'pointer',
										marginBottom: '10px',
									}}
									variant="h6"
								>
									<IconFile
										color={themeColor}
										className="icon-ico_modelo_Excel"
									/>
									Baixar modelo Excel
								</Typography>
								<img
									src={imageCSV}
									alt="Arquivo Excel exemplo"
									style={{
										width: '100%',
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={6} lg={6} spacing={2}>
								<Typography
									onClick={() => {
										downloadLocalFile('txt')
									}}
									style={{
										color: getColor(),
										fontSize: '18px',
										textAlign: 'left',
										fontWeight: '400',
										letterSpacing: '0px',
										opacity: '1',
										textTransform: 'none',
										cursor: 'pointer',
										marginBottom: '10px',
									}}
									variant="h6"
								>
									<IconFile
										color={themeColor}
										className="icon-ico_modelo_txt"
									/>
									Baixar modelo CSV/TXT
								</Typography>
								<img
									src={imageTXT}
									alt="Arquivo CSV/TXT exemplo"
									style={{
										width: '100%',
									}}
								/>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : open && type === 'saveError' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '30px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '13px',
									fontWeight: 'bold',
								}}
							>
								{displayText}
							</div>
						</GridItem>
						<GridContainer
							style={{
								padding: '10px 0 0 20px',
								marginBottom: '30px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{ textAlign: 'center' }}
							>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'no'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginRight: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={() => onCancel()}
								>
									{t('BUTTON_OK')}
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : open && type === 'cancelError' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '30px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '13px',
									fontWeight: 'bold',
								}}
							>
								{displayText}
							</div>
						</GridItem>
						<GridContainer
							style={{
								padding: '10px 0 0 20px',
								marginBottom: '30px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{ textAlign: 'center' }}
							>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'no'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginRight: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onCancel}
								>
									{t('BUTTON_NO')}
								</Button>{' '}
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'yes'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginLeft: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onConfirm}
								>
									{t('BUTTON_YES')}
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : open && type !== 'confirmDelete' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '30px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className={
										type === 'cancel'
											? 'icon-alerta_modal'
											: 'icon-grid_reprovado'
									}
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '13px',
									fontWeight: 'bold',
								}}
							>
								{displayText}
							</div>
						</GridItem>
						<GridContainer
							style={{
								padding: '10px 0 0 20px',
								marginBottom: '30px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{ textAlign: 'center' }}
							>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'no'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginRight: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onCancel}
								>
									{t('BUTTON_NO')}
								</Button>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'yes'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginLeft: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onConfirm}
								>
									{t('BUTTON_YES')}
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : null}
		</ModalContainer>
	)
}

ConfirmModal.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	standardFocus: PropTypes.string,
	text: PropTypes.string,
	password: PropTypes.string,
	parameter: PropTypes.string,
	type: PropTypes.string,
	error: PropTypes.string,
	onDelete: PropTypes.func,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
}

ConfirmModal.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	standardFocus: 'no',
	children: null,
	open: false,
	closeByClick: null,
	onDelete: null,
	maxWidth: '',
	minWidth: '',
	text: '',
	parameter: '',
	password: '',
	error: '',
	type: '',
	onConfirm: null,
	onCancel: null,
}

export default withTranslation()(ConfirmModal)
