import React, { Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import * as _ from 'lodash'
import Alert from 'react-bootstrap-sweetalert'

import Loader from 'components/Loader/Loader.jsx'

//material UI
import { withStyles } from '@material-ui/core/styles'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import navPillsStyle from 'assets/jss/material-dashboard-pro-react/components/navPillsStyle.jsx'

// FORMIK
import { Formik } from 'formik'
import * as Yup from 'yup'
import Form from './Components/Form'

// REDUX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {
	GetCurrentPartner,
	GetClaimsByGroupIdData,
	GetUserByGroupData,
	GetGroupData,
	PostNewGroupData,
	EditNewGroupData,
	ShowLoader,
	HideLoader,
	SweetAlert,
	HideAlert,
} from 'redux/actions'

//UTILS
import compose from 'utils/compose'

const validationSchema = Yup.object({
	partnerId: Yup.string().required(),
	name: Yup.string().required(),
	description: Yup.string().required(),
	status: Yup.bool(),
	ListOfClaims: Yup.array().min(1),
})

class GroupsForm extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			groupClaims: [],
			selectedUser: '',
			editMode: false,
			id: '',
			partnerId: '',
			ListOfUsers: [],
			ListOfClaims: [],
			userGroup: [],
			isAdmin: false,
			name: '',
			description: '',
			status: true,
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		const id = this.props.match && this.props.match.params.id
		//
		let _new = _.includes(this.props.match.path, 'new')
		let _edit = _.includes(this.props.match.path, 'edit')
		if (_new) {
			this.setState({ editMode: _new }, () => this.props.HideLoader())
		} else if (_edit) {
			this.setState({ editMode: _edit })
		} else {
			this.setState({ editMode: false })
		}

		if (!this.props.userData) {
			this.props.GetCurrentPartner()
		}

		this.props.GetGroupData(id).then(() => {
			this.props.GetClaimsByGroupIdData(id)
			this.props.GetUserByGroupData(id)
		})
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.groupData !== this.props.groupData) {
			this.setState(
				{
					...this.props.groupData,
					id: this.props.groupData.groupId,
					partnerId: this.props.userData.id,
				},
				() => this.props.HideLoader(),
			)
		}

		if (
			prevProps.groupPermissionsData !== this.props.groupPermissionsData
		) {
			this.setState({
				groupClaims: this.props && this.props.groupPermissionsData,
				ListOfClaims:
					this.props &&
					this.props.groupPermissionsData &&
					this.props.groupPermissionsData.map((x) =>
						x.claim.filter((cl) => cl.removeOrAdd).map((y) => y.id),
					),
			})
		}
		if (prevProps.userByGroup !== this.props.userByGroup) {
			this.setState({
				userGroup:
					this.props &&
					this.props.userByGroup &&
					this.props.userByGroup.map((x) => x.id),
				ListOfUsers:
					this.props &&
					this.props.userByGroup &&
					this.props.userByGroup.map((x) => x.id),
			})
		}
		if (prevProps.userData !== this.props.userData) {
			this.setState({ partnerId: this.props.userData.id })
		}
		if (
			prevProps.postNewGroup !== this.props.postNewGroup ||
			prevProps.editNewGroup !== this.props.editNewGroup
		) {
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.history.push('/Groups')
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_SUCCESS')}
				</Alert>,
			)
		}

		if (
			this.props.newGroupPostFailed &&
			prevProps.newGroupPostFailed !== this.props.newGroupPostFailed
		) {
			this.props.HideLoader()
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={'Erro'}
					onConfirm={() => {
						this.props.HideAlert()
					}}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}
	}

	getUserGroupIdList = (userGroup) => {
		return userGroup && userGroup.map((item) => _.toInteger(item.groupId))
	}

	handleSave = (values) => {
		this.props.ShowLoader(<Loader />)
		const id = this.props.match && this.props.match.params.id

		var group = {
			name: values.name,
			description: values.description,
			status: values.status,
			partnerId: values.partnerId,
			listOfClaims: [],
			listOfUsers: [],
		}
		group.listOfUsers = values.userGroup
		values &&
			values.groupClaims &&
			values.groupClaims.map((x) => {
				x.claim
					.filter((cl) => cl.removeOrAdd)
					.map((y) => {
						group.listOfClaims.push(y.id)
					})
			})

		if (id) {
			group.id = values.id
			this.props.EditNewGroupData(group)
		} else {
			this.props.PostNewGroupData(group)
		}
	}

	handleBack = () => {
		this.props.HideAlert()
		this.props.history.goBack()
	}

	handleCancel = () => {
		if (
			this.formik.current &&
			_.isEmpty(this.formik.current.state.touched)
		) {
			this.handleBack()
		} else {
			this.props.SweetAlert(
				<Alert
					warning
					reverseButtons={true}
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => this.handleBack()}
					onCancel={() => this.props.HideAlert()}
					confirmBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.greenButtonColor
					}
					cancelBtnCssClass={
						this.props.classes.button +
						' ' +
						this.props.classes.danger
					}
					confirmBtnText={this.props.t('BUTTON_YES')}
					cancelBtnText={this.props.t('BUTTON_NO')}
					showCancel
				>
					{this.props.t('FIELD_GENERAL_RULES_ALERT')}
				</Alert>,
			)
		}
	}

	handleEdit = () => {
		this.props.history.push(`/groups/edit/${this.state.id}`)
	}

	render() {
		const { classes, t } = this.props
		const {
			editMode,
			partnerId,
			id,
			groupClaims,
			userGroup,
			name,
			description,
			selectedUser,
			status,
			isAdmin,
			ListOfUsers,
			ListOfClaims,
		} = this.state
		const values = {
			partnerId,
			id,
			groupClaims,
			userGroup,
			name,
			description,
			selectedUser,
			status,
			isAdmin,
			ListOfUsers,
			ListOfClaims,
		}

		return (
			<Fragment>
				{this.state.BreadCrumbComp}

				<Formik
					ref={this.formik}
					render={(props) => (
						<Form
							{...props}
							t={t}
							classes={classes}
							editMode={editMode}
							handleEdit={this.handleEdit}
							userList={this.props.userData.users}
							handleCancel={this.handleCancel}
							handleBack={this.handleBack}
						/>
					)}
					onSubmit={this.handleSave}
					validationSchema={validationSchema}
					initialValues={values}
					enableReinitialize={true}
				/>
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => {
	const { access, users, groups } = state
	return {
		postNewGroup: groups.postNewGroup,
		editNewGroup: groups.editNewGroup,
		groupData: access.groupData,
		groupPut: access.groupPut,
		newGroupPostFailed: groups.newGroupPostFailed,
		groupPermissionsData: access.groupPermissionsData,
		userByGroup: groups.userByGroup,
		userData: users.userData,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators(
		{
			GetCurrentPartner,
			GetClaimsByGroupIdData,
			GetUserByGroupData,
			GetGroupData,
			PostNewGroupData,
			EditNewGroupData,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	connect(mapStateToProps, mapDispatchToProps),
	withStyles(navPillsStyle),
	withStyles(extendedFormsStyle),
	withTranslation(),
)(GroupsForm)
