import React from 'react'

// @ Helpers
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

// @ Components
import { Box } from '@mui/material'
import ShowSector from '../ShowSector'
import ConfirmModalPartners from '../../../../ConfirmModal'
import { Breadcrumb, Button } from '../../../../../../../../components/storybook'

// @ Utils and Mocks
import { propsOpenModal } from '../../../Documents/components/List/utils'
import { BusinessInitials } from '../../../../../Mocks/businessDivisionMock'

const Buttons = ({
	onTab,
	reset,
	formState,
	isEditData,
	selectedItem,
	onCancelEdit,
	divisionData,
	setIsEditData,
	removeDivision,
	setEditDivision,
	businessDivisionData,
}) => {
	const { t } = useTranslation()
	const [openModal, setOpenModal] = React.useState(propsOpenModal)

	const onCancel = () => {
		reset()
		onCancelEdit()
		setOpenModal(propsOpenModal)
	}

	return (
		<Box>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Breadcrumb
					items={[
						'Perfil da empresa',
						BusinessInitials[businessDivisionData?.typeOfDivision || ''],
						businessDivisionData?.divisionDetails?.name || '',
						isEditData ? 'Editar' : 'Visualizar',
					]}
				/>
				<Box display="flex" gap="10px">
					<ConfirmModalPartners
						open={openModal.open}
						text={openModal.text}
						subText={openModal?.subText || ''}
						hasActive={openModal?.hasActive || false}
						onCancel={() => setOpenModal(propsOpenModal)}
						onConfirm={() => {
							openModal.onConfirm()
							setOpenModal(propsOpenModal)
						}}
					/>
					{!isEditData ? (
						<Button
							typeVariation="CustomIconButton"
							onClick={() => {
								setIsEditData(true)
								setEditDivision(true)
							}}
						/>
					) : (
						<Box display="flex" gap="10px">
							<Button
								customType="backPage"
								disabled={formState.isDirty}
								typeVariation="CustomIconButton"
								onClick={() => {
									setIsEditData(false)
								}}
							/>
							<Button
								customType="cancel"
								typeVariation="CustomIconButton"
								disabled={!formState.isDirty}
								onClick={() => {
									setOpenModal({
										open: true,
										text: t('WHEN_CANCELING_WILL_BE_LOST'),
										subText: t('DO_YOU_WANT_TO_CONTINUE'),
										onConfirm: () => {
											setIsEditData(false)
										},
									})
								}}
							/>
							<Button
								customType="saveSuccess"
								typeVariation="CustomIconButton"
								disabled={!formState.isDirty}
								form={onTab[selectedItem]}
								type="submit"
							/>
							<Button
								customType="delete"
								typeVariation="CustomIconButton"
								onClick={() => {
									setOpenModal({
										open: true,
										text: `Deseja realmente excluir esta divisão de negócio?`,
										onConfirm: () => removeDivision(divisionData?.id),
									})
								}}
							/>
						</Box>
					)}
					<Button
						text="Fechar"
						typeVariation="CloseButton"
						onClick={() => {
							if (formState?.isDirty && isEditData) {
								setOpenModal({
									open: true,
									text: t('BUTTON_CLOSE_MESSAGE'),
									subText: t('DO_YOU_WANT_TO_CONTINUE'),
									onConfirm: () => {
										onCancel()
									},
								})
								return
							}
							onCancel()
						}}
					/>
				</Box>
			</Box>
			<ShowSector name={businessDivisionData?.divisionDetails?.name || ''} type={businessDivisionData?.typeOfDivision || ''} />
		</Box>
	)
}

Buttons.propTypes = {
	reset: PropTypes.func,
	onTab: PropTypes.object,
	isEditData: PropTypes.bool,
	formState: PropTypes.object,
	onCancelEdit: PropTypes.func,
	setIsEditData: PropTypes.func,
	selectedItem: PropTypes.string,
	removeDivision: PropTypes.func,
	divisionData: PropTypes.object,
	setEditDivision: PropTypes.func,
	businessDivisionData: PropTypes.object,
}

export default Buttons
