/* eslint-disable react/prop-types */
import { Autocomplete } from '@mui/material'
import { withStyles, TextField } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getDefaultColor } from '../../Functions'

export default function Select({ value, label, options, onChange, errors, helperText, ...rest }) {
	const { t } = useTranslation()

	const FormField = withStyles({
		root: {
			minHeight: '40px',
			width: '100%',
			margin: 0,
			'& .Mui-focused fieldset': {
				borderColor: `${getDefaultColor()} !important`,
			},
			'& label.Mui-focused': {
				color: getDefaultColor(),
			},
			'& .MuiOutlinedInput-root': {
				'&:hover fieldset': {
					borderColor: getDefaultColor(),
				},
				'&.Mui-focused fieldset': {
					borderColor: getDefaultColor(),
				},
			},
			'& .MuiInput-underline:hover:not:(.Mui-disabled):before': {
				borderColor: getDefaultColor(),
			},
			'& .MuiInput-underline:after': {
				borderBottom: `2px solid  ${getDefaultColor()}`,
			},
			'& .MuiInputBase-root': {
				height: '40px',
				padding: '0px 10px !important',
				marginTop: 0,
			},
		},
	})(TextField)

	return (
		<Autocomplete
			value={value}
			onChange={(_, newValue) => {
				onChange(newValue)
			}}
			options={options}
			getOptionLabel={option => t(option.label || option?.value)}
			renderInput={params => (
				<FormField {...params} label={label} variant="outlined" margin="dense" fullWidth error={errors} helperText={helperText} {...rest} />
			)}
			{...rest}
		/>
	)
}
