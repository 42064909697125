import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 100%;
`

export const Sector = styled.div`
	text-align: left;
	font: normal normal normal 15px Roboto;
	letter-spacing: 0px;
	color: #4e4e4e;
	opacity: 1;
`

export const SectorName = styled.div`
	text-align: left;
	font: normal normal bold 20px Roboto;
	letter-spacing: 0px;
	color: ${({ color }) => (color ? color : '#4e4e4e')};
	opacity: 1;
`
