import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

// @ Mui
import { Box, CircularProgress } from '@mui/material'
import Pagination from '@mui/material/Pagination'

// @ Components
import Search from '../Search'
import Table from '../Table/GriTable'

// @ Functions
import { GetBranchesPlatforms, formatLabelsAndValuesCashback } from '../../Functions'

// @ Hooks
import { useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'

// @ Styles
import * as S from './styles'
import MenuButton from './MenuButton'

function DivisionTable({
	multiple,
	editMode,
	productData,
	setDivision,
	parameterization,
	selectedDivision,
	setSelectedDivision,
	setParameterization,
	selectedDivisionIdRed,
	setSelectedDivisionIdRed,
}) {
	const { t } = useTranslation()
	const history = useHistory()
	const [name, setName] = useState('')
	const [loading, setLoading] = useState(false)

	const [defaultValues, setDefaultValues] = useState({
		currentPage: 1,
		pageCount: 0,
		page: 1,
		pageSize: 10,
		recordCount: 0,
		results: [],
	})

	const { results, pageCount, page, pageSize } = defaultValues

	const GetDivisionTable = async (name, pageSize, page) => {
		const PartnerId = String(Cookies.get('partnerId'))
		const Name = name ? name : ''
		const TypeOfBenefit = 'Cashback'
		const ProductId = productData?.id
		const PageSize = pageSize || defaultValues.pageSize
		const Page = page || defaultValues.page

		const response = await GetBranchesPlatforms({
			Name: Name,
			TypeOfBenefit: TypeOfBenefit,
			ProductId: ProductId,
			PartnerId: PartnerId,
			PageSize: PageSize,
			Page: Page,
			setDefaultValues,
			setLoading,
			id: productData?.id,
		})

		return response
	}

	const selectAllItemsByName = async () => {
		const currentPage = 1
		const customSize = defaultValues.pageSize * defaultValues.pageCount ?? 100

		try {
			const response = await GetDivisionTable(name, customSize, currentPage)

			const allItemsSelected = selectedDivision.length === response.length

			if (allItemsSelected) {
				setSelectedDivision([])
				setSelectedDivisionIdRed([])
			} else {
				const allDivisions = response.map(item => item.name)
				const allIds = response.map(item => item.id)

				setSelectedDivision(allDivisions)
				setSelectedDivisionIdRed(allIds)
			}
		} catch (error) {
			console.error('Erro na obtenção dos dados:', error)
		}
	}

	useEffect(() => {
		GetDivisionTable()
	}, [])

	useEffect(() => {
		GetDivisionTable()
	}, [parameterization])
	return (
		<Box display="flex" flexDirection="column" gap="25px" mt="25px">
			<Search
				name={name}
				setName={setName}
				multiple={multiple}
				selectAllItemsByName={selectAllItemsByName}
				GetDivisionTable={GetDivisionTable}
				selectedDivision={selectedDivision}
			/>
			<Box
				display="flex"
				flexDirection="column"
				alignItems="center"
				justifyContent="center"
				gap="10px"
				sx={{
					opacity: loading ? 0.5 : 1,
				}}
			>
				{results.length > 0 &&
					results?.map((item, index) => {
						const { labels, values } = formatLabelsAndValuesCashback(item, t, localStorage)
						return (
							<Table
								Item={item}
								key={index}
								id={item.id}
								labels={labels}
								values={values}
								history={history}
								editMode={editMode}
								multiple={multiple}
								setDivision={setDivision}
								selectedDivision={selectedDivision}
								setSelectedDivision={setSelectedDivision}
								setParameterization={setParameterization}
								selectedDivisionIdRed={selectedDivisionIdRed}
								setSelectedDivisionIdRed={setSelectedDivisionIdRed}
							/>
						)
					})}
				{loading && <CircularProgress sx={{ position: 'absolute' }} />}
			</Box>
			<S.NavContainer>
				<S.SubTitle>
					<S.SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#3EC450" text="Centro de custo" />
					<S.SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#1BBED3" text="Departamento" />
					<S.SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#7E6BB9" text="Filial" />
					<S.SubtitleItem iconClassName="icon-acao_cancelar" iconColor="#FB9006" text="Unidade de negócio" />
				</S.SubTitle>

				<S.NavItem pageCount={pageCount}>
					<MenuButton setDefaultValues={setDefaultValues} pageSize={pageSize} onChange={value => GetDivisionTable(name, value, 1)} />
					<Pagination
						page={page}
						count={pageCount}
						showFirstButton
						showLastButton
						onChange={(_e, value) => {
							GetDivisionTable(name, pageSize, value)
						}}
					/>
				</S.NavItem>
			</S.NavContainer>
		</Box>
	)
}

DivisionTable.propTypes = {
	multiple: PropTypes.bool,
	editMode: PropTypes.bool,
	division: PropTypes.string,
	setDivision: PropTypes.func,
	productData: PropTypes.string,
	parameterization: PropTypes.array,
	selectedDivision: PropTypes.array,
	setSelectedDivision: PropTypes.func,
	selectedDivisionIdRed: PropTypes.array,
	setSelectedDivisionIdRed: PropTypes.func,
	setParameterization: PropTypes.func,
	setMultipleParameterization: PropTypes.func,
}

export default DivisionTable
