import { authentication } from "./authenticate";

import home from "./home";

// GERAL
import images from "./images";
import sweetAlert from "./sweetAlert";
import loader from "./loader";
import location from "./location";
import generalRules from "./generalRules";

import access from "./access/access";
import groups from "./access/groups";
import tier from "./tier";
import wallet from "./wallet";
import regulation from "./regulation";
import sponsor from './sponsor';
import platformRules from "./platformRules";
import partners from "./partners";
import statement from "./statement";
import product from "./product";
import campaign from "./campaign";
import consumer from "./consumer";
import transaction from "./transaction";
import invoice from "./invoice";
import { users } from "./user";
import { connectRouter } from 'connected-react-router'
import { combineReducers } from 'redux'

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  users,
  authentication,
  sweetAlert,
  loader,
  location,
  home,
  statement,
  consumer,
  transaction,
  product,
  invoice,
  campaign,
  images,
  generalRules,
  access,
  groups,
  regulation,
  platformRules,
  tier,
  partners,
  wallet,
  sponsor
});

export default createRootReducer;