import React, { Fragment } from 'react'

import Responsible from '../../Components/Responsible'
import CoastCenterInfos from '../../Components/CoastCenterInfos'

export default function FormCoastCenter({ ...sharedProps }) {
	return (
		<Fragment>
			<CoastCenterInfos {...sharedProps} />

			<Responsible {...sharedProps} />
		</Fragment>
	)
}
