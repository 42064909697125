/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'

import { Grid, IconButton, TextField } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'

import Button from 'components/CustomButtons/Button.jsx'
import * as S from './styles'

import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAccounts } from '../../../hooks/useAccounts'
import CustomDateNew from 'components/CustomDateNew'

import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

const periodOptions = [
	{ value: 7, label: 'Últimos 7 dias' },
	{ value: 14, label: 'Últimos 14 dias' },
	{ value: 0, label: 'Personalizado' },
]

const SearchForm = () => {
	const { setValueTypeFilter } = useAccounts()
	const { t } = useTranslation()

	const { control, handleSubmit, watch, reset, setValue } = useForm()
	const [dateFrom, setDateFrom] = useState('')
	const [selectedPeriod, setSelectedPeriod] = useState('')
	const [selectedOperation, setSelectedOperation] = useState('')
	const [selectedStatus, setSelectedStatus] = useState('')
	const [open, setOpen] = useState(false)
	const [fromEnabled, setFromEnabled] = useState(false)
	const [toEnabled, setToEnabled] = useState(false)
	const [themeColor, setThemeColor] = useState()

	const operationOptions = [
		{
			value: 1,
			label: t('BALANCE_INCLUSION'),
		},
		{
			value: 2,
			label: t('BALANCE_RESERVE'),
		},
		{
			value: 3,
			label: t('AWARD'),
		},
		{
			value: 4,
			label: t('RESIDUAL_BALANCE'),
		},
		{
			value: 5,
			label: t('BALANCE_RELEASE'),
		},
		{
			value: 6,
			label: t('AWARD_REFUND'),
		},
		{
			value: 7,
			label: t('ANTICIPATION_FEE'),
		},
	]

	const statusOptions = [
		{
			value: 1,
			label: t('CREDITED'),
		},
		{
			value: 2,
			label: t('RESERVED'),
		},
		{
			value: 3,
			label: t('DEBITED'),
		},
		{
			value: 4,
			label: t('RESIDUAL'),
		},
		{
			value: 5,
			label: t('RELEASED'),
		},
		{
			value: 6,
			label: t('FAILED'),
		},
		// {
		// 	value: 7,
		// 	label: t('REFUNDED'),
		// },
	]

	const watchAllFields = watch()

	const beforeValidation = currentDate => {
		const today = moment()

		const beforeDate = moment(watchAllFields.dateFilterFrom).format(
			'DD/MM/YYYY',
		)
		const endDate = moment(watchAllFields.dateFilterTo).format('DD/MM/YYYY')

		if (moment(beforeDate, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(moment(endDate, 'DD/MM/YYYY'))) {
				return false
			}
		}

		return currentDate.isBefore(today)
	}

	const afterValidation = current => {
		const today = moment()

		const beforeDate = moment(watchAllFields.dateFilterFrom).format(
			'DD/MM/YYYY',
		)

		return (
			current.isAfter(moment(beforeDate, 'DD/MM/YYYY')) &&
			current.isBefore(today)
		)
	}

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		let timeAgo = null
		let today = null

		today = moment()
		if (watchAllFields.period) {
			switch (watchAllFields.period.value) {
				case 7:
					timeAgo = moment().subtract(7, 'd')
					break
				case 14:
					timeAgo = moment().subtract(14, 'd')
					break
				default:
					break
			}
			console.log(watchAllFields)
			if (watchAllFields.period && watchAllFields.period.value !== 0) {
				setValue('dateFilterFrom', timeAgo.format('DD/MM/YYYY'))
				setValue('dateFilterTo', today)
				setFromEnabled(false)
				setToEnabled(false)
			} else {
				setValue('dateFilterFrom', '')
				setValue('dateFilterTo', '')
				setFromEnabled(true)
				setToEnabled(true)
			}
		}
	}, [watchAllFields.period])

	useEffect(() => {
		setDateFrom(watchAllFields.dateFilterFrom)
	}, [watchAllFields.dateFilterFrom])

	useEffect(() => {
		if (watchAllFields.dateFilterFrom > watchAllFields.dateFilterTo) {
			handleClearFilter()
		}
	}, [watchAllFields.dateFilterTo])

	function handleToggle() {
		setOpen(prev => !prev)
	}

	const handleClearFilter = () => {
		setValueTypeFilter({
			period: { value: 0, label: 'Personalizado' },
			dateFilterFrom: null,
			dateFilterTo: null,
			operation: '',
			status: '',
		})

		setSelectedStatus(null)
		setSelectedOperation(null)
		setSelectedPeriod(null)
		reset({
			period: { value: 0, label: 'Personalizado' },
			dateFilterFrom: null,
			dateFilterTo: null,
			operation: '',
			status: '',
		})
	}

	const handleSelectedPeriod = (value, event) => {
		event(value)
		setSelectedPeriod(value)
	}

	const handleSelectedOperation = (value, event) => {
		event(value)
		setSelectedOperation(value)
	}

	const handleSelectedStatus = (value, event) => {
		event(value)
		setSelectedStatus(value)
	}

	const onSubmitFilter = data => {
		setValueTypeFilter({
			dateFilterFrom:
				data.dateFilterFrom && data.dateFilterFrom
					? moment(data.dateFilterFrom, 'DD/MM/YYYY').format(
							'YYYY-MM-DD',
					  )
					: null,
			dateFilterTo:
				data.dateFilterTo && data.dateFilterTo
					? moment(data.dateFilterTo, 'DD/MM/YYYY').format(
							'YYYY-MM-DD',
					  )
					: null,
			operation: data.operation ? data.operation : null,
			status: data.status ? data.status : null,
		})
	}

	const getBtnElColor = () => {
		if (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.elementsColor
		) {
			return themeColor.navigation.buttons.elementsColor
		}
		return `#fff`
	}

	const colourStyles = {
		option: (styles, { isDisabled, isFocused, isSelected }) => {
			const color = getBtnElColor()
			return {
				...styles,
				backgroundColor: isDisabled
					? undefined
					: isSelected
					? color
					: isFocused
					? color
					: undefined,
			}
		},
		control: (base, state) => ({
			...base,
			height: '40px',
		}),
	}

	return (
		<S.Container themeColor={themeColor}>
			<form onSubmit={handleSubmit(onSubmitFilter)} autoComplete="off">
				<Grid container xs={12} sm={12} spacing={1}>
					<Grid item xs={12} md={3} sm={3} lg={3}>
						<Controller
							onChange={([, data]) => data}
							name="period"
							control={control}
							render={({ onChange }) => (
								<Autocomplete
									styles={colourStyles}
									id="combo-box-demo"
									options={periodOptions}
									getOptionLabel={option =>
										option.label ? option.label : ''
									}
									disableClearable={true}
									disablePortal
									value={selectedPeriod}
									renderInput={params => (
										<div ref={params.InputProps.ref}>
											<TextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() =>
													t('RESULTS_NOT_FOUND')
												}
												label="Período"
												variant="outlined"
												size="small"
											/>
										</div>
									)}
									onChange={(e, data) =>
										handleSelectedPeriod(data, onChange)
									}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={6} md={3} sm={3} lg={3}>
						<Controller
							as={
								<CustomDateNew
									minDate={null}
									inputProps={{ readOnly: !fromEnabled }}
									enableDates={fromEnabled}
									isValidDate={beforeValidation}
									label={t('DATETIME_FROM')}
									disabled={!fromEnabled}
									open={
										fromEnabled === true ? undefined : false
									}
								/>
							}
							name="dateFilterFrom"
							control={control}
						/>
					</Grid>

					<Grid item xs={6} md={3} sm={3} lg={3}>
						<Controller
							as={
								<CustomDateNew
									minDate={dateFrom}
									enableDates={toEnabled}
									label={t('DATETIME_TO')}
									isValidDate={afterValidation}
									marginLeft={'15px'}
									open={toEnabled ? undefined : false}
									disabled={!toEnabled}
									inputProps={{ readOnly: !toEnabled }}
								/>
							}
							name="dateFilterTo"
							control={control}
							defaultValue={null}
						/>
					</Grid>

					<Grid
						item
						xs={12}
						md={3}
						sm={3}
						lg={3}
						style={{ zIndex: '50' }}
					>
						<Controller
							onChange={([, data]) => data}
							name="operation"
							control={control}
							render={({ onChange }) => (
								<Autocomplete
									styles={colourStyles}
									id="combo-box-demo"
									options={operationOptions}
									getOptionLabel={option =>
										option.label ? option.label : ''
									}
									disableClearable={true}
									disablePortal
									value={selectedOperation}
									renderInput={params => (
										<div ref={params.InputProps.ref}>
											<TextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() =>
													t('RESULTS_NOT_FOUND')
												}
												label={t('FIELD_OPERATION')}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
									onChange={(e, data) =>
										handleSelectedOperation(data, onChange)
									}
								/>
							)}
						/>
					</Grid>

					{open && (
						<Grid
							item
							xs={12}
							sm={5}
							style={{
								margin: '14px 0',
								zIndex: '50',
								marginTop: '8px',
							}}
						>
							<Controller
								onChange={([, data]) => data}
								name="status"
								control={control}
								render={({ onChange }) => (
									<Autocomplete
										styles={colourStyles}
										id="combo-box-demo"
										options={statusOptions}
										getOptionLabel={option =>
											option.label ? option.label : ''
										}
										disablePortal
										disableClearable={true}
										value={selectedStatus}
										renderInput={params => (
											<div ref={params.InputProps.ref}>
												<TextField
													style={{
														backgroundColor:
															'#ffffff',
														width: '100%',
													}}
													{...params}
													noOptionsMessage={() =>
														t('RESULTS_NOT_FOUND')
													}
													label={t('FIELD_STATUS')}
													variant="outlined"
													size="small"
												/>
											</div>
										)}
										onChange={(e, data) =>
											handleSelectedStatus(data, onChange)
										}
									/>
								)}
							/>
						</Grid>
					)}

					<S.moreFilterBox getBtnElColor={getBtnElColor}>
						<S.moreFilterBtnBox
							onClick={handleToggle}
							getBtnElColor={getBtnElColor}
						>
							<IconButton
								className="iconButton"
								title="Pesquisa avançada"
							>
								{!open ? <Add /> : <Remove />}
							</IconButton>
							<span
								style={{
									fontSize: '15px',
									marginLeft: '10px',
								}}
							>
								{!open
									? t('SHOW_MORE_FILTERS')
									: t('HIDE_MORE_FILTERS')}
							</span>
						</S.moreFilterBtnBox>
						<S.btnFilterBox>
							<Button
								size="sm"
								variant="contained"
								color="greenBorderButtonColor"
								onClick={() => handleClearFilter()}
								style={{ marginRight: '0.890rem' }}
							>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>
							<Button
								size="sm"
								variant="contained"
								color="greenButtonColor"
								type="submit"
							>
								{t('BUTTON_FIND')}
							</Button>
						</S.btnFilterBox>
					</S.moreFilterBox>
				</Grid>
			</form>
		</S.Container>
	)
}

export default SearchForm
