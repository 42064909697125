import * as React from 'react'
import PropTypes from 'prop-types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { IconButton } from '@mui/material'
import { Container, Text } from './styles'

const options = [10, 25, 50, 100]

export default function MenuButton({ pageSize, setDefaultValues, onChange }) {
	const [anchorEl, setAnchorEl] = React.useState(null)
	const open = Boolean(anchorEl)
	const handleClick = event => {
		setAnchorEl(event.currentTarget)
	}
	const handleClose = e => {
		setAnchorEl(null)
		if (e.target.value) {
			onChange(e.target.value)
			setDefaultValues(prev => ({ ...prev, pageSize: e.target.value }))
		}
	}

	return (
		<div>
			<Container>
				<Text>{pageSize} linhas</Text>
				<IconButton
					aria-label="delete"
					id="basic-button"
					aria-controls={open ? 'basic-menu' : undefined}
					aria-haspopup="true"
					aria-expanded={open ? 'true' : undefined}
					onClick={handleClick}
					sx={{
						height: '32px',
						width: '32px',
						ml: '5px',
						bgcolor: 'rgba(0,0,0,0.08)',
					}}
				>
					<ExpandMoreIcon />
				</IconButton>
			</Container>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{options.map(option => (
					<MenuItem key={option} onClick={handleClose} value={option}>
						{option}
					</MenuItem>
				))}
			</Menu>
		</div>
	)
}

MenuButton.propTypes = {
	onChange: PropTypes.func,
	pageSize: PropTypes.number,
	setDefaultValues: PropTypes.func,
}

MenuButton.defaultProps = {
	pageSize: 10,
}
