import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

export const NoModules = styled.div`
	display: flex;
	flex-direction: column;

	i {
		color: #b4b4b4;
		display: flex;
		font-size: 30px;
		justify-content: center;
	}

	span {
		color: #b4b4b4;
		display: flex;
		font-weight: 900;
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		justify-content: center;
		margin: 25px 0 12px;
	}
`
