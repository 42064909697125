import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

const getColor = (themeColor) => {
	const color =
		themeColor &&
		themeColor.titles &&
		themeColor.titles.main &&
		themeColor.titles.main.textColor
	return color
}

const getBGCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor
	) {
		return props.themeColor.structure.backgroundColor + ' !important'
	}
	return `#fff`
}
const getHLCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor
	) {
		return props.themeColor.titles.highlight.textColor + ' !important'
	}
	return `#fff`
}
const getSgColor = (props) => {
	if (
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.secondary &&
		props.themeColor.titles.secondary.textColor
	) {
		return props.themeColor.titles.secondary.textColor + ' !important'
	}
	return `#fff`
}

const getColorIcon = (props) => {
	if (
		props.themeColor &&
		props.themeColor.visual &&
		props.themeColor.visual.icons &&
		props.themeColor.visual.icons.color
	) {
		return props.themeColor.visual.icons.color + ' !important'
	}
	return `#fff`
}

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.import_tab_button {
		display: flex;
		justify-content: flex-end;
		margin-top: 10px;
	}


	@media (max-width: 1280px) {
		.import_tab_button {
			justify-content: flex-start !important;
			padding: 0px;
			margin-top: 0px;
		}
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after {
		border-color: ${(props) => getBGCol(props)};
		border-width: 2px;
	}

	.MuiOutlinedInput-root:hover fieldset {
		border-color: ${(props) => getBGCol(props)};
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => getBGCol(props)};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => getBGCol(props)};
	}

	.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
		color: ${(props) => getBGCol(props)};
		background-color: ${(props) => getBGCol(props)};
	}

	.MuiFormHelperText-contained {
		padding-top: 0.2px;
		margin-top: 0.2px;
		font-size: 0.75rem;
	}

	.icon-data {
		font-size: 20px;
		margin-right: -8px;
		color: ${(props) => getColorIcon(props)};
	}
	.rdtPicker {
		.dow,
		th.rdtSwitch,
		th.rdtNext,
		th.rdtPrev,
		.rdtTimeToggle {
			color: ${(props) => getSgColor(props)};
		}
	}
	.rdtDay {
		&.rdtToday.rdtActive,
		&.rdtActive,
		&.rdtActive:hover {
			background-color: ${(props) => getSgColor(props)};
		}
	}
	.MuiTableCell-root {

		padding: 0;
	}

	.ActionButton {
		overflow: hidden;

		height: 44px;

		font-size: 20px;
		background-color: #F05B4F;
		border-radius: 0 8px 8px 0;
		//margin-right: -10px;
	}

	.infoTypeErro {
		color: #F05B4F;
		padding-top: 0.2px;
		margin-top: 0.2px;
		font-size: 0.75rem;
		margin-left: 14px;
		margin-right: 14px;
		font-weight: 350;
	}

	.file-upload-wrapper {
		width: 300px;
		position: relative;

		&:after {
			content: attr(data-text);
			font-size: 18px;
			position: absolute;
			top: 0;
			left: 0;
			background: #fff;
			padding: 10px 15px;
			display: block;
			width: calc(100% - 40px);
			pointer-events: none;
			z-index: 20;
			color: #999;
			border-radius: 5px 10px 10px 5px;
			font-weight: 300;
		}

		&:before {
			content: 'Upload';
			position: absolute;
			top: 0;
			right: 0;
			display: inline-block;
			height: 60px;
			background: #000452;
			color: #fff;
			font-weight: 700;
			z-index: 25;
			font-size: 16px;
			line-height: $height;
			padding: 0 15px;
			text-transform: uppercase;
			pointer-events: none;
			border-radius: 0 5px 5px 0;
		}

		&:hover {
			&:before {
				background: darken($defaultColor, 10%);
			}
		}
		input {
			opacity: 0;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 99;
			height: $height - 20px;
			margin: 0;
			padding: 0;
			display: block;
			cursor: pointer;
			width: 100%;
		}
	}
`

export const InputsContain = styled.form`
	display: flex;
	flex-direction: column;
	padding-bottom: 35px;
	width: 100%;

	.button-upload {
		margin-left: 10px;
		width: 173px;
	}

	@media (max-width: 768px) {
		padding-bottom: 0px;
	}
`
export const ParticipantP = styled.p`
	color: #000;
	font-weight: 900;
	margin: 25px 0 12px;
`
export const ErrorP = styled.p`
	color: #b22222;
	font-weight: 900;
	margin: 1em 0 0.7em;
`

export const InfoList = styled.div`
	align-items: center;
	color: #929292;
	display: flex;
	flex-direction: column;
	font-size: 12px;
	font-weight: 500;
	margin: 50px auto 0;
	i {
		font-size: 50px;
	}
	p {
		margin: 18px 0 0;
	}
`

export const InfoImportTab = styled.p`
	font: normal normal normal 14px/20px Roboto;
	letter-spacing: 0px;
	color: ${(props) => getHLCol(props)};
	opacity: 0.8;
	margin-top: 10px;
`
export const InfoContain = styled.div`
	align-items: center;
	display: flex;
	margin-top: 3px;
`
export const IconInfo = styled.i`
	font-size: 25px;
	cursor: pointer;
	margin: auto 14.5px;
	margin-left: 7px;
	color: #5d5d5d;

	&:hover {
		opacity: 0.8;
	}

	.icon-ico_mais_informacao {
		color: #5d5d5d;
		font-size: 24px;
	}

	p {
		color: #878787;
		font-size: 12px;
	}

	:hover + .information {
		display: flex;
		flex-direction: column;
	}
	.information {
		color: #878787;
		font-size: 12px;
	}
`
export const Info = styled.div`
	background-color: #f4f4f4;
	border-radius: 10px;
	display: none;
	font-weight: bolder;
	margin: 0;
	padding: 10px;
	margin-left: 50px;
	margin-top: -150%;
	position: absolute;
	width: 342px;
	height: 232px;

	p {
		width: 294px;
		height: 89px;
		text-align: left;
		font: normal normal normal 12px/15px Roboto;
		letter-spacing: 0px;
		color: #636363;
		opacity: 1;
	}
`
export const Example = styled.div`
	width: 100%;
	border-radius: 10px;
	//padding: 10px;

	img {
		width: 100%;
		height: 113px;
	}
`
export const IcoConversao = styled.i`
	font-size: 40px;
	margin-right: 18px;
	color: ${(props) => getColorIcon(props)};
`

export const DefaultValue = styled.div`
	position: relative;
	label {
		position: absolute;
		padding-left: 10px;
		font-size: 10px;
		padding-top: 5px;
		color: #929292;
	}

	input {
		height: 40px;
		padding: 12px 0 0 10px;
		width: 200px;
		color: #21409a;
		font-weight: bold;
		border: none;
		background-color: #f4f4f4;
		border-radius: 5px;
	}
`

export const EmptyTable = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding-top: 1em;
	color: #b4b4b4;

	i {
		padding-top: 1em;
		font-size: 2em;
		cursor: pointer;
		margin: auto 18px;
	}

	span {
		font-weight: 900;
		margin: 25px 0 12px;
	}
`
export const CellMaterialTable = styled.div`
	text-align: left;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	color: #878787;

	padding: 1.2em;
`

export const SectionButton = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-end;
	margin-bottom: -45px;

	@media (max-width: 490px) {
		margin-bottom: 0px;
	}
`

export const ValueStandard = styled.div`
	width: 48%;
	height: 40px;
	display: flex;

	label {
		position: absolute;
		padding-left: 10px;
		font-size: 10px;
		padding-top: 5px;
		color: #929292;
	}

	/* span {
		font-size: 12px;
	} */

	input {
		height: 40px;
		padding: 12px 0 0 10px;
		width: 100%;
		color: #21409a;
		font-weight: bold;
		border: none;
		background-color: #f4f4f4;
		border-radius: 5px;
	}

	.valueStandard-text {
		position: relative;
		background: #e9e9e9;
		padding: 0px 10px;
		border-radius: 7px;
		width: auto;
		height: fit-content;
	}

	.valueStandard-value {
		color: ${(props) => getHLCol(props)};

		font-weight: bold;
	}
`
export const FormSection = styled(Grid)`
	width: 100%;
	display: flex;

	justify-content: space-between;
	margin-bottom: 10px;
	//align-items: center;
	flex-flow: row wrap;

	.input-form-tool-reserv {
		max-width: 100%;
		width: 48%;
		margin: 0;
		//margin-right: 10px;
	}

	@media only screen and (max-width: 480px) {
		flex-flow: column wrap;

		.input-form-tool-reserv {
			width: 100%;
		}

		.valueStandard-icon {
			margin-top: 20px;
			width: 100%;
		}
	}
`
export const IconButton = styled.i`
	margin-right: .625rem;
	font-size: 19px;
`

export const IconcreateAwards = styled.div`
	margin-bottom: 2em;
	display: flex;
	align-items: center;
	justify-content: center;
`

export const FormColumn = styled.div`
	display: flex;
	justify-content: space-between;
	margin-bottom: 30px;
	width: 100%;

	.column-form-tool {
		width: 48%;
		//min-width: 350px;
		display: grid;
		grid-template-columns: auto 40px;
	}

	.input-form-tool-description {
		min-width: 150px;
		flex: 1;
		height: 40px;
	}

	.input-form-type {
		width: 48%;
		//min-width: 415px;
		margin: 0;
	}

	@media only screen and (min-width: 320px) and (max-width: 1160px) {
		margin-bottom: 20px;
		.column-form-tool {
			width: 100%;
		}
		.input-form-type {
			margin-top: 20px;
			width: 100%;
		}
	}

	flex-wrap: wrap;
`

export const FormAddParticipant = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 28px;
	margin-bottom: 46px;

	// criar media query
	@media only screen and (max-width: 625px) {
		flex-direction: column;
		.currency-input {
			width: 100% !important;
			margin-top: 10px;
			margin-bottom: 10px;
		}
		.input-add-participant {
			width: 100% !important;
		}
		.button-add-participant {
			margin: 0px !important;
		}
	}
`

export const FormAddParticipantGuide = styled.form`
	display: flex;
	flex-direction: row;
	margin-top: 28px;
	margin-bottom: 46px;
`

export const LabelFloat = styled.div`
	position: relative;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	//margin-top: 15px;
	//margin-bottom: 25px;

	.form-control {
		height: 40px;
		width: 300px;
		border: 1px solid #c1c1c1;
		padding-left: 10px;
		//border-color: #dedede;
	}
	.floating-label {
		pointer-events: none;
		top: 2px;
		left: 12px;
		z-index: 1;
	}
`
export const InfoContainDesc = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
`

export const IconInfoDesc = styled.i`
	font-size: 25px;
	cursor: pointer;
	margin: auto 18px;
	p {
		color: #fff;
		font-size: 12px;
	}

	:hover + .information {
		display: flex;
		flex-direction: column;
	}
	.information {
		color: #fff;
		font-size: 12px;
	}
`

export const InfoDesc = styled.div`
	background-color: #333333;
	border-radius: 10px;
	display: none;
	font-weight: bolder;
	margin: 0;
	padding: 10px;
	//margin-left: 50px;
	margin-top: 150%;
	position: absolute;
	width: 300px;
`
export const GroupButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;

	.btn-container {
		display: flex;
		gap: 0.813rem;
		margin-left: 0.938rem;
	}

	@media only screen and (max-width: 400px) {
		padding-bottom: 30px;
		flex-direction: column;
		.btn-container {
			margin-left: 0px;
			margin-top: 10px;
			width: 100%;
		}
		.btn-default-process {
			width: 100%;
		}
		.btn-default-cancel {
			width: 100%;
		}
	}
`

export const TitleContainer = styled.div`
	padding-bottom: 25px;
`

export const Partition = styled(Grid)`
	display: flex;
	flex-direction: column;
	margin-top: 20px;
	margin-bottom: 30px;

	// Switch styles
	.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
		background: ${(props) => getBGCol(props)} 0% 0% no-repeat padding-box;
		opacity: 1;
	}

	.MuiSwitch-thumb {
		background: #fff 0% 0% no-repeat padding-box;
		border: 1px solid #707070;
		opacity: 1;
	}

	.MuiSwitch-switchBase.Mui-checked {
		transform: translateX(28px);
	}

	.MuiSwitch-root {
		width: 65px;
	}
	.MuiRadio-colorPrimary.Mui-checked {
		color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
	}

	span {
		display: flex;
		align-items: center;
	}

	.flex-row {
		display: flex;
		width: 100%;
	}

	.radioButton {
		display: flex;
		align-items: center;
		width: 100%;

		.MuiFormHelperText-contained {
			margin-left: 2px;
			margin-right: 14px;
		}
	}
	.radioButtonDate {
		display: flex;
		align-self: flex-start;

		.MuiOutlinedInput-inputMarginDense {
			padding-top: 9.5px;
			padding-bottom: 10.5px;
			font-size: 16px;
		}

		.MuiFormHelperText-contained {
			margin-left: 2px;
			margin-right: 14px;
		}

		/* .MuiInputBase-inputMarginDense {
			padding: 0px;
		} */
	}
	.radioContainer {
		display: flex;
		align-items: center;
		flex-direction: column;

		.days_error {
			width: 180px;
			text-align: justify;
		}
	}

	#formControl {
		margin-right: 40px;
	}

	.textFieldInput {
		margin-left: 20px;
		padding: 0;
	}
	.txt-input {
		width: 105px;
		height: 40px;
		margin-left: 20px;
	}

	.daysCss {
		display: flex;
		margin-right: 25px;
	}

	.rateCss {
		display: flex;
		margin-right: 25px;
		align-items: center;
	}

	label {
		font-size: 13px;
	}

	.radio-group {
		width: 100%;
	}

	.dateCss {
		width: 140px;
	}

	@media only screen and (max-width: 750px) {
		flex-direction: column;

		.dateCss {
			width: 100%;
		}
		#formControl {
			width: 100%;
			margin: 0px;
		}
		.textFieldInput {
			width: 100%;
			margin-left: 0px;
		}

		.daysCss {
			width: 100%;
			margin-right: 0px;
		}
		.txtField {
			width: 100% !important;
		}

		.txt-input {
			width: 100%;
		}

		.radioButton {
			width: 100%;
			margin: 0px 0px 10px 0px;
		}

		.rateCss {
			width: 100%;
			margin: 0px;
		}

		.radio-group {
			.MuiFormControlLabel-root {
				width: 100%;
				margin-right: 0px;
			}
			.MuiTypography-root
				.MuiFormControlLabel-label
				.MuiTypography-body1 {
				width: 100%;
			}
		}
	}

	@media only screen and (max-width: 500px) {
		.flex-row {
			flex-direction: column;
		}

		#formControl {
			margin-top: 20px;
		}
		.MuiTypography-body1 {
			width: 100%;
		}
	}
`

export const InputFileContainer = styled.div`
	display: flex;
	align-items: center;

	.outlined-textFile {
		width: 364px;
		height: 38px;
	}

	@media only screen and (max-width: 620px) {
		flex-direction: column;
		width: 100%;
		.grid-container {
			width: 100%;
			margin: 0px 0px 10px 0px;
		}

		.outlined-textFile {
			width: 100%;
		}
	}
`

export const AddFileBtn = styled.div`
	display: flex;

	@media only screen and (max-width: 620px) {
		width: 100%;
		label {
			width: 100%;
		}

		.button-upload {
			width: 99%;
			margin: 0;
			padding: 0;
		}
	}
`

export const ButtonDownload = styled.div`
	color: ${({ color }) => getColor(color)};
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	padding-left: 10px;
	height: 38px;
	white-space: nowrap;

	@media (max-width: 1280px) {
		padding: 0px;
	}
`

export const IconFile = styled.i`
	font-size: 25px;
	cursor: pointer;
	margin: auto 5.34px;
	margin-left: 7px;
	color: ${({ color }) => getColor(color)};
`
