import React, { Fragment, useEffect, useMemo, useState } from 'react'

// @ Helpers
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'
import { FormProvider, useForm } from 'react-hook-form'

// @ Components
import { Container, ContainerDiagram, Section } from './styles'
import { Box } from '@mui/material'
import Buttons from './Components/Buttons'
import Selection from './Components/Selection'
import FormBranch from './Templates/FormBranch'
import Loader from 'components/Loader/Loader.jsx'
import SnackDefault from 'components/SnackDefault'
import ConfirmModalPartners from '../../ConfirmModal'
import { FormBusinessUnit, FormCoastCenter, FormDepartment } from './Templates'
import { Button, Organograma } from '../../../../../../components/storybook'

// @ Functions
import { getParentListForSelect } from '../../../Functions/getParentList'
import { branchSchema } from '../../../Functions/yupValidationsBranchDivision'
import { businessUnitSchema } from '../../../Functions/yupValidationsBusinessDivision'
import { costCenterSchema } from '../../../Functions/yupValidationsCostCenterDivision'
import { departmentSchema } from '../../../Functions/yupValidationsDepartmentDivision'
import {
	FilterChart,
	filterBusinessDivision,
	incluirBranches,
	onCancelProps,
	onSubmitProps,
	removeDivisionById,
	validateBusinessDivisionAllErrors,
} from './Components/Functions'

// @ Redux and Utils
import { PartnerService } from '../../../../Services'

// @ Mocks
import { defaultDivisionsErrors, defaultSnack } from '../../../Mocks/divisionsErrors'
import { businessMock, defaultBusinessMock } from '../../../Mocks'

export default function BusinessDivision({
	isEditData,
	partnerData,
	partnerInfos,
	businessDivisionData,
	updateBusinessDivisionData,
	setSelectedState,
	partnerId,
	setIsEditData,
	setBusinessDivisionData,
	segmentsList,
	reloadFormsData,
	parentsList,
	updateCustomFieldsForm,
	customFieldsForm,
	selectDivisionDetails,
	editDivision,
	setEditDivision,
	setIsLoadingData,
	selectedType,
	onCancelEdit,
	setSelectedType,
	onOpen,
	setOnOpen,
	selectedItem,
	onTab,
}) {
	const { t } = useTranslation()

	const [snackStatus, setSnackStatus] = useState(defaultSnack)
	const partnerService = new PartnerService()
	const selectedDivision = businessDivisionData?.typeOfDivision ?? null
	const [parentDivision, setParentDivision] = useState([])
	const [isSelectFilterOpen, setIsSelectFilterOpen] = useState(false)
	const [openModalConfirm, setOpenModalConfirm] = useState(false)

	const [divisionData, setDivisionData] = useState(null)
	const [divisionsErrors, setDivisionsErrors] = useState(defaultDivisionsErrors)
	const [modalCancel, setOModalCancel] = useState()
	const [loading, setLoading] = useState(false)
	const validations = {
		BusinessUnit: businessUnitSchema,
		CostCenter: costCenterSchema,
		Branch: branchSchema,
		Franchise: branchSchema,
		Department: departmentSchema,
	}

	const methods = useForm({
		resolver: yupResolver(validations[selectedDivision]),
	})
	const watchFields = methods.watch()

	const { formState, reset } = methods

	const getSelectedState = watchFields?.businessDivisionUF ?? null

	const handleConfirm = () => {
		updateBusinessDivisionData('typeOfDivision', selectedType.type)
		updateBusinessDivisionData('isNewBusinessDivision', true)
	}

	const onCancel = () => {
		onCancelProps({
			setOnOpen,
			setSelectedType,
			setOModalCancel,
			setBusinessDivisionData,
			setIsEditData,
			setEditDivision,
			reloadFormsData,
			defaultBusinessMock,
			reset,
			setDivisionData,
		})
	}

	const removeDivision = async divisionId => {
		await removeDivisionById({ divisionId, partnerService, setLoading, setSnackStatus, setEditDivision, reloadFormsData })
	}

	async function onSubmit(data) {
		await onSubmitProps({
			data,
			businessDivisionData,
			selectedDivision,
			segmentsList,
			setSnackStatus,
			onCancel,
			updateBusinessDivisionData,
			partnerId,
			customFieldsForm,
			divisionData,
			setIsLoadingData,
			selectDivisionDetails,
			partnerService,
			reset,
		})
	}

	function updateOpenModalConfirm() {
		setOpenModalConfirm(!openModalConfirm)
	}

	useMemo(() => {
		if (businessDivisionData.typeOfDivision === 'Simple') {
			setOpenModalConfirm(true)
		}
	}, [businessDivisionData.typeOfDivision])

	useEffect(() => {
		if (businessDivisionData?.divisionDetails) {
			setDivisionData(businessDivisionData?.divisionDetails)
		}
	}, [businessDivisionData?.divisionDetails])

	useEffect(() => {
		if (watchFields?.businessDivisionUF?.value) setSelectedState(String(watchFields?.businessDivisionUF.value))
	}, [watchFields?.businessDivisionUF])

	useMemo(() => {
		setSelectedState(getSelectedState?.value)
	}, [getSelectedState])

	const organogramaData = [
		{
			...partnerData,
			segmentationType: { key: '1', value: 'Matriz' },
			branches: [...businessDivisionData?.divisionsList],
		},
	]
	const sharedProps = {
		onTab,
		reset,
		formState,
		isEditData,
		parentsList,
		partnerData,
		partnerInfos,
		divisionData,
		selectedType,
		selectedItem,
		onCancelEdit,
		setIsEditData,
		removeDivision,
		parentDivision,
		organogramaData,
		setEditDivision,
		businessDivisionData,
		updateCustomFieldsForm,
		setBusinessDivisionData,
		sharedInfos: partnerInfos,
		updateBusinessDivisionData,
		UFList: partnerInfos?.addressUFList,
		defaultInfos: businessDivisionData?.divisionDetails,
		typeDivision: businessDivisionData.typeOfDivision,
	}

	useEffect(() => {
		let novoArray = []

		sharedProps.organogramaData.forEach(item => {
			incluirBranches(item, novoArray)
		})

		if (novoArray.length > 0) {
			const divisions = getParentListForSelect(novoArray)

			setParentDivision(filterBusinessDivision(divisions, sharedProps.typeDivision))
		}
	}, [businessDivisionData.typeOfDivision])

	useEffect(() => {
		validateBusinessDivisionAllErrors({ partnerId, setLoading, setDivisionsErrors, t })
	}, [partnerId])

	const typeOfDivision = {
		BusinessUnit: <FormBusinessUnit {...sharedProps} />,
		CostCenter: <FormCoastCenter {...sharedProps} />,
		Department: <FormDepartment {...sharedProps} />,
		Branch: <FormBranch {...sharedProps} />,
		Franchise: <FormBranch {...sharedProps} />,
	}

	return (
		<Container>
			{loading && <Loader />}
			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<ConfirmModalPartners
				open={modalCancel?.open}
				text={modalCancel?.text}
				subText={modalCancel?.subText}
				onConfirm={() => onCancel()}
				onCancel={() => setOModalCancel(prevState => ({ ...prevState, open: false }))}
			/>

			<FormProvider {...methods}>
				{editDivision ? (
					<form id="business-division-form" onSubmit={methods.handleSubmit(onSubmit)}>
						<Buttons {...sharedProps} />
						{businessDivisionData.typeOfDivision && typeOfDivision[businessDivisionData.typeOfDivision]}
					</form>
				) : (
					<Fragment>
						{isEditData && !businessDivisionData?.isEditBusinessDivision && (
							<Selection
								onOpen={onOpen}
								item={selectedType}
								setItem={setSelectedType}
								setOnOpen={setOnOpen}
								disable={businessDivisionData?.isNewBusinessDivision}
								open={isSelectFilterOpen}
								setOModalCancel={setOModalCancel}
								handleClick={() => {
									!businessDivisionData?.isNewBusinessDivision && setIsSelectFilterOpen(!isSelectFilterOpen)
								}}
								handleConfirm={handleConfirm}
								handleItemClick={selectedItem => setSelectedType(selectedItem)}
								Business={FilterChart(organogramaData, businessMock)}
								handleClose={() => {
									setIsSelectFilterOpen(false)
								}}
							/>
						)}

						{businessDivisionData.typeOfDivision || businessDivisionData?.isEditBusinessDivision ? (
							<form id="business-division-form" onSubmit={methods.handleSubmit(onSubmit)}>
								{businessDivisionData.typeOfDivision && typeOfDivision[businessDivisionData.typeOfDivision]}
								<Box mt="30px" sx={{ transform: 'translateX(-2px)' }}>
									<Button
										type="submit"
										textColor="#fff"
										form={onTab[selectedItem]}
										text={selectedType?.btnTxt}
										typeVariation="CustomTextButton"
										defaultColor={selectedType?.bgColor}
									/>
								</Box>
							</form>
						) : null}
						{!businessDivisionData?.isEditBusinessDivision && (
							<ContainerDiagram>
								<span className="title">Organograma da empresa</span>
								<Section>
									<Organograma
										titleTooltipEdit="Editar"
										divisionsData={organogramaData}
										divisionsErrors={divisionsErrors}
										handleClick={function noRefCheck(item) {
											selectDivisionDetails(item)
											setEditDivision(true)
										}}
									/>
								</Section>
							</ContainerDiagram>
						)}
						{isEditData && openModalConfirm && (
							<ConfirmModalPartners
								open={openModalConfirm}
								text={'Deseja realmente alterar a estrutura de negócio para simples?'}
								hasActive={false}
								subText={'Todas as informações da divisão de negócio cadastradas serão eliminadas.'}
								onConfirm={() => {
									updateOpenModalConfirm()
								}}
								onCancel={() => {
									updateOpenModalConfirm()
								}}
							/>
						)}
					</Fragment>
				)}
			</FormProvider>
		</Container>
	)
}

BusinessDivision.propTypes = {
	onTab: PropTypes.object,
	onOpen: PropTypes.bool,
	setOnOpen: PropTypes.func,
	isEditData: PropTypes.bool,
	partnerId: PropTypes.string,
	onCancelEdit: PropTypes.func,
	parentsList: PropTypes.array,
	editDivision: PropTypes.bool,
	partnerData: PropTypes.object,
	segmentsList: PropTypes.array,
	setIsEditData: PropTypes.func,
	selectedItem: PropTypes.string,
	partnerInfos: PropTypes.object,
	selectedType: PropTypes.object,
	setSelectedType: PropTypes.func,
	setEditDivision: PropTypes.func,
	reloadFormsData: PropTypes.func,
	setSelectedState: PropTypes.func,
	setIsLoadingData: PropTypes.func,
	customFieldsForm: PropTypes.object,
	selectDivisionDetails: PropTypes.func,
	businessDivisionData: PropTypes.object,
	updateCustomFieldsForm: PropTypes.func,
	setBusinessDivisionData: PropTypes.func,
	updateBusinessDivisionData: PropTypes.func,
}
