import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

// Validation
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'

// Components
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section'
import ConfirmModal from '../../../../../../components/ConfirmModal'
import CurrencyInput from '../../../../../../components/NewCustomInput'
import Button from 'components/CustomButtons/Button.jsx'

// Redux
import { CreateReserve } from '../../../../../../redux/api/accounts'
import { GetCurrentPartner } from '../../../../../../redux/api/user'
import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'

// Context
import { useAccounts } from '../../../../hooks/useAccounts'
import { Layout } from '../../Layout'


// Styles and Utils
import * as S from './styles'
import { CreateReservationErrors, initialData, validation, CssTextField, } from './utils'



export function CreateReservation({
	title,
	isOpen,
	onClose,
	reservationData }) {
	const {
		closeCreateReservation,
		setValueTypeFilter,
		openSnack,
		openErrorSnack,
	} = useAccounts()
	const [openCancelModal, setOpenCancelModal] = useState(false)
	const [isValueExceeded, setIsValueExceeded] = useState(false)
	const [themeColor, setThemeColor] = useState()

	const { t } = useTranslation()
	const { refreshTable, setRefreshTable } = useAccounts()

	const {
		control,
		handleSubmit,
		setValue,
		formState: { errors, isDirty, isSubmitting },
		setError,
		watch,
		clearErrors,
	} = useForm({
		defaultValues: initialData,
		resolver: yupResolver(validation),
		mode: 'onChange',
	})

	const watchAllFields = watch()
	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])
	useEffect(() => {
		if (reservationData && reservationData.bankAccountValues)
			if (watchAllFields.reservationValue) {
				if (
					watchAllFields.reservationValue >
					reservationData.bankAccountValues.available
				) {
					setError('reservationValue', {
						type: 'manual',
						message: 'O valor informado está acima do disponível',
					})
					setIsValueExceeded(true)
				} else {
					clearErrors('reservationValue')
					setIsValueExceeded(false)
				}
			}
	}, [watchAllFields.reservationValue])

	const onSubmit = async (data) => {
		if (
			data.reservationValue > reservationData.bankAccountValues.available
		) {
			setError('reservationValue', {
				type: 'manual',
				message: 'O valor informado está acima do disponível',
			})
			return
		}

		const partnerId = await GetCurrentPartner().then((res) => {
			return res.id
		})

		const bankAccountId = reservationData.bankAccountId



		const obj = {
			bankAccountId: bankAccountId,
			partnerId: partnerId,
			name: data.name,
			description: data.description,
			value: data.reservationValue,
		}

		try {
			const res = await CreateReserve(obj)
			let errors = res.data && res.data.errors
			if (Object.keys(errors).length > 0) {
				CreateReservationErrors(t, errors, setError)
				return
			}

			openSnack('Reserva criada com sucesso!')
			setRefreshTable(!refreshTable)
		} catch (error) {
			console.error(error)
			openErrorSnack()
		} finally {

		}

		closeCreateReservation()

		setValueTypeFilter({
			dateFilterFrom: null,
			dateFilterTo: null,
			accountName: '',
			status: '',
		})
	}


	function handleCancel() {
		if (isDirty) {
			setOpenCancelModal(true)
		} else {
			closeCreateReservation()
		}
	}

	function handleConfirmModal() {
		closeCreateReservation()
		setOpenCancelModal(false)
	}

	return (
		<>
			<ConfirmModal
				type="cancel"
				open={openCancelModal}
				onConfirm={() => handleConfirmModal()}
				onCancel={() => setOpenCancelModal(false)}
			/>
			{isSubmitting && <Loader />}
			<Layout isOpen={isOpen}>
				<S.FormContain
					themeColorHighlight={
						themeColor &&
						themeColor.titles &&
						themeColor.titles.highlight &&
						themeColor.titles.highlight.textColor
					}
					themeColor={
						themeColor &&
						themeColor.structure &&
						themeColor.structure.backgroundColor
					}
					onSubmit={handleSubmit(onSubmit)}
				>
					<S.TitleSection>
						<Section
							title="Criar reserva"
							style={{ flexGrow: 1 }}
						/>

						<S.BtnTitleSection>
							<Button
								size="sm"
								variant="contained"
								color="greenBorderButtonColor"
								startIcon={
									<i
										style={{
											fontSize: 14,
											padding: '2px 0',
										}}
									/>
								}
								onClick={handleCancel}
							>
								{t('BUTTON_CANCEL')}
							</Button>
							<Button
								color="greenButtonColor"
								size="sm"
								type="submit"
								disabled={isValueExceeded}
							>
								{t('BUTTON_CONCLUDE')}
							</Button>
						</S.BtnTitleSection>
					</S.TitleSection>

					<S.InputContain>
						<S.ReservationName>
							<Controller
								name="name"
								control={control}
								render={({ onChange, value }) => (
									<CssTextField
										id="name"
										name="name"
										label={`${t(
											'FIELD_RESERVATION_NAME',
										)}*`}
										variant="outlined"
										margin="dense"
										fullWidth
										// disabled={isDisabled}
										onChange={onChange}
										value={value}
										error={errors.name}
										helperText={
											errors.name &&
											errors.name.message
										}
									/>
								)}
							/>
						</S.ReservationName>

						<S.InputValue>
							<Controller
								render={({ name, onChange, value }) => (
									<CurrencyInput
										max={
											reservationData.bankAccountValues
												.available
										}
										error={errors.reservationValue}
										helperText={
											errors.reservationValue &&
											errors.reservationValue.message
										}
										onValueChange={onChange}
										name={name}
										value={value}
										label={'Valor*'}
										setValue={setValue}
									/>
								)}
								control={control}
								name="reservationValue"
								defaultValue=""
							/>
						</S.InputValue>

						<S.InputDescription>
							<Controller
								name="description"
								control={control}
								render={({ onChange, value }) => (
									<CssTextField
										label={t('FIELD_DESCRIPTION')}
										variant="outlined"
										margin="dense"
										fullWidth
										onChange={onChange}
										value={value}
										error={errors.description}
										helperText={
											errors.description &&
											errors.description.message
										}
									/>
								)}
							/>
						</S.InputDescription>
					</S.InputContain>
				</S.FormContain>
			</Layout>
		</>
	)
}
