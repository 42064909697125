import styled from 'styled-components'
import GridItem from 'components/Grid/GridItem'

export const Container = styled.div`
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9999999999999999;

	position: absolute;
	width: ${props => props.width};
	background-color: #fff;
	border-radius: 8px;
	box-shadow: none;
	padding: 15px;
	display: flex;
	justify-content: center;
`

export const CustomGridItem = styled(GridItem)`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
`
