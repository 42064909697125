import * as actionTypes from './actionsTypes';
import * as API from '../api/consumer';
import * as Loader from './loader';

// FAIL FETCH -------------------------------------

export const fetchConsumerFailed = (error) => {
    return {
        type: actionTypes.FETCH_CONSUMER_FAILED,
        consumerDataFailed: error
    };
};
//----------------------------------------------------

export const GetConsumer = (name, cpf, startDate, endDate, pageIndex) => {
    return dispatch => (
        API.GetConsumerData(name, cpf, startDate, endDate, pageIndex).then(response => {
            dispatch(fetchConsumerData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchConsumerFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function fetchConsumerData(data) {
        return {
            type: actionTypes.FETCH_CONSUMER_DATA,
            lsConsumerData: data
        };
    };
};

export const PostConsumer = (data) => {
    return dispatch => (
        API.PostConsumer(data).then(response => {
            dispatch(postConsumerData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchConsumerFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function postConsumerData(data) {
        return {
            type: actionTypes.POST_CONSUMER_DATA,
            consumerPost: data
        };
    }
}

export const UpdateConsumer = (id, data) => {
    return dispatch => (
        API.UpdateConsumer(id, data).then(response => {
            dispatch(updateConsumerData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchConsumerFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function updateConsumerData(data) {
        return {
            type: actionTypes.UPDATE_CONSUMER_DATA,
            consumerUpdate: data
        };
    };
}

export const GetConsumerById = (id) => {
    return dispatch => (
        API.GetConsumerById(id).then(response => {
            dispatch(fetchConsumerById(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchConsumerFailed(error));
            dispatch(Loader.hideLoader());
        })
    );

    function fetchConsumerById(data) {
        return {
            type: actionTypes.FETCH_CONSUMER_DATA_BY_ID,
            consumerData: data
        };
    };
}