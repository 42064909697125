import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility';

const initialState = {
    regulationData: [],
    regulationDataFailed: false
}

const fetchData = (state, action) => {
    return updateObject(state, {
        regulationData: action.regulationData 
    })
}

const fetchDataFailed = ( state, action) => {
    return updateObject(state, {
        regulationDataFailed: true
    })
}

const regulationReducer = (state = initialState, action) => {
    switch(action.types) {
        case action.FETCH_REGULATION_DATA:
            return fetchData(state,action)
        case action.FETCH_REGULATION_FAILED:
            return fetchDataFailed(state,action);
        default:
            return state;
    }
}

export default regulationReducer;