import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    thumbnails: [],
    imageFile: null,
    imageUrl: "",
    imageFailed: false
};

const setFileData = (state, action) => {
    return updateObject(state, {
        imageFile: action.imageFile
    });
};

const postImageData = (state, action) => {
    return updateObject(state, {
        imageUrl: action.imageUrl
    });
};

const fetchThumbnails = (state, action) => {
    return updateObject(state, {
        thumbnails: action.thumbnails
    });
};

// DATA FAIL
const fetchImageFailed = (state, action) => {
    return updateObject(state, {
        imageFailed: true
    });
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_FILE_DATA:
            return setFileData(state, action);
        case actionTypes.POST_IMAGE_DATA:
            return postImageData(state, action);
        case actionTypes.FETCH_THUMBNAILS:
            return fetchThumbnails(state, action);
        case actionTypes.FETCH_IMAGE_FAILED:
            return fetchImageFailed(state, action);
        default:
            return state;
    }
};

export default dashboardReducer;