/* eslint-disable no-undef */
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

import { NumericFormat } from 'react-number-format';

class NumberFormatCustom extends React.Component {
	render() {
		const { inputRef, onChange, ...other } = this.props;

		const MAX_VAL = other.rows || 9999999999;

		const withValueLimit = ({ value }) => {
			if (_.isEmpty(value)) {
				return true;
			}
			return value <= MAX_VAL
		};

		return (
			<NumericFormat
				{...other}
				decimalScale={0}
				thousandSeparator={'.'}
				decimalSeparator={','}
				isAllowed={withValueLimit}
				getInputRef={inputRef}
				onValueChange={values => {
					onChange({
						target: {
							id: other.id,
							value: values.value,
						},
					});
				}}
			/>
		);
	}
}
NumberFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
};

export default NumberFormatCustom
