import styled from 'styled-components'

export const Title = styled.div`
	text-align: left;
	font: normal normal bold 16px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	padding-right: 1px;
`

export const InheritContainer = styled.div`
	display: flex;
	gap: 10px;
	max-width: 600px;

	.MuiFormControlLabel-label {
		white-space: nowrap !important;
		text-align: left;
		font: normal normal normal 15px Roboto;
		letter-spacing: 0px;
		color: #717171;
		opacity: 1;
	}
`
