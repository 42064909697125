import React from 'react'
import PropTypes from 'prop-types'
import { Container, Sector, SectorName } from './styles'
import { defaultBusinessColors } from '../../../../../Mocks/businessDivisionMock'

const ShowSector = ({ name, type }) => {
	return (
		<Container>
			<Sector>Unidade de negócio</Sector>
			<SectorName color={defaultBusinessColors[type]}>{name}</SectorName>
		</Container>
	)
}

ShowSector.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
}

export default ShowSector
