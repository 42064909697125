import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

import { Autocomplete, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import Labelitem from '../../../../../Labelitem'
import { CircularProgress } from '@mui/material'

import { CepMask } from '../Functions'
import { ZipCodeNoMask, defaultCountry, handleAddress, handleCity } from './Validations'

import { validateValue } from '../../../../../../Functions'
import Section from 'components/Section'
import { getValuesObject } from '../../../../../../Functions/validateValue'

function TemplateAddress({ title, index, removeTemplate, userform, isEditData, list, setList, setLoading, partnerData }) {
	const [circularProgress, setCircularProgress] = useState(false)
	const { control, watch, setValue, errors, setError } = userform
	const currentAddress = errors?.addresses?.length > 0 && errors.addresses[index]

	const { t } = useTranslation()

	const values = watch(`addresses[${index}]`)

	const handleZipCode = async value => {
		if (ZipCodeNoMask(value)?.length >= 8) {
			setLoading(true)
			setCircularProgress(true)
			handleAddress(values, setValue, setList, index, value, setError, t).finally(() => {
				setCircularProgress(false)
				setLoading(false)
			})
		}
	}

	return (
		<Fragment>
			<S.Container>
				<Grid container spacing={2} pb={2}>
					{index === 1 && (
						<Grid item lg={12} sm={12} style={{ margin: '10px 0px' }}>
							<S.Line />
						</Grid>
					)}
					<Grid item lg={12} sm={12} justifyContent="space-between">
						<S.Header>
							<Grid item lg={12} sm={12}>
								<Stack mt={10} sx={{ width: '100%' }}>
									<Section title={title} />
								</Stack>
							</Grid>
							{index !== 0 && (
								<S.CustomButton onClick={() => removeTemplate(index)}>
									<i className="icon-ico_excluir" />
								</S.CustomButton>
							)}
						</S.Header>
					</Grid>
					<Grid item lg={2} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="CEP" disabled required value={validateValue(partnerData?.addresses[index || 0]?.zipCode)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="CEP" required isEditData />
								<Controller
									render={({ onChange, value }) => (
										<S.CssTextField
											size="small"
											variant="outlined"
											value={value || ''}
											onChange={e => {
												console.log('e.target.value', e.target.value)
												handleZipCode(e.target.value)
												onChange(e.target.value)
											}}
											error={currentAddress?.zipCode}
											InputProps={{
												inputComponent: CepMask,
												endAdornment: <React.Fragment>{circularProgress && <CircularProgress color="inherit" size={20} />}</React.Fragment>,
											}}
											inputProps={{
												autoComplete: 'new-password',
											}}
											helperText={currentAddress?.zipCode && t(currentAddress?.zipCode?.message)}
										/>
									)}
									defaultValue={partnerData?.addresses[index]?.zipCode}
									name={`addresses[${index}].zipCode`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
					<Grid item lg={5} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="Endereço" disabled required value={validateValue(partnerData?.addresses[index || 0]?.location)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="Endereço" required isEditData />
								<Controller
									render={({ onChange, value }) => (
										<S.CssTextField
											size="small"
											fullWidth
											variant="outlined"
											onChange={e => {
												onChange(e.target.value)
											}}
											value={value || ''}
											error={currentAddress?.location}
											disabled={!isEditData}
											helperText={currentAddress?.location && currentAddress?.location?.message}
										/>
									)}
									defaultValue={partnerData?.addresses[index]?.location}
									name={`addresses[${index}].location`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
					<Grid item lg={1} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="Nº" disabled required value={validateValue(partnerData?.addresses[index || 0]?.number)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="Nº" required isEditData />
								<Controller
									render={({ onChange, value }) => (
										<S.CssTextField
											size="small"
											fullWidth
											variant="outlined"
											error={currentAddress?.number}
											value={value || ''}
											onChange={e => onChange(e.target.value)}
											disabled={!isEditData}
											helperText={currentAddress?.number && currentAddress?.number?.message}
										/>
									)}
									defaultValue={partnerData?.addresses[index]?.number}
									name={`addresses[${index}].number`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
					<Grid item lg={4} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="Complemento" disabled value={validateValue(partnerData?.addresses[index || 0]?.aditionalInfo)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="Complemento" isEditData />
								<Controller
									render={({ onChange, value }) => (
										<S.CssTextField
											size="small"
											fullWidth
											variant="outlined"
											value={value || ''}
											disabled={!isEditData}
											onChange={e => onChange(e.target.value)}
										/>
									)}
									defaultValue={partnerData?.addresses[index]?.aditionalInfo}
									name={`addresses[${index}].aditionalInfo`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
					<Grid item lg={3} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="Bairro" disabled required value={validateValue(partnerData?.addresses[index || 0]?.district)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="Bairro" required isEditData />
								<Controller
									render={({ onChange, value }) => (
										<S.CssTextField
											size="small"
											variant="outlined"
											value={value || ''}
											onChange={e => onChange(e.target.value)}
											error={currentAddress?.district}
											disabled={!isEditData}
											helperText={currentAddress?.district && currentAddress?.district?.message}
										/>
									)}
									defaultValue={partnerData?.addresses[index]?.district}
									name={`addresses[${index}].district`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
					<Grid item lg={3} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="País" disabled required value={validateValue(partnerData?.addresses[index || 0]?.country?.name)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="País" required isEditData />
								<Controller
									render={({ onChange, value }) => (
										<Autocomplete
											options={list?.countries}
											isOptionEqualToValue={(option, value) => option.id === value?.id}
											getOptionLabel={option => t(option?.name)}
											size="small"
											onChange={(_e, value) => {
												onChange(value)
											}}
											value={value}
											disableClearable
											disabled={!isEditData}
											renderInput={params => (
												<S.CssTextField
													{...params}
													size="small"
													variant="outlined"
													error={currentAddress?.countryId}
													helperText={currentAddress?.countryId && t(currentAddress?.countryId?.message)}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{circularProgress && <CircularProgress color="inherit" size={20} />}
																{params.InputProps.endAdornment}
															</React.Fragment>
														),
													}}
												/>
											)}
										/>
									)}
									defaultValue={defaultCountry}
									name={`addresses[${index}].countryId`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
					<Grid item lg={3} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="UF" required disabled value={validateValue(partnerData?.addresses[index || 0]?.state?.name)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="UF" required isEditData />
								<Controller
									render={({ onChange, value }) => (
										<Autocomplete
											options={list?.states}
											isOptionEqualToValue={(option, value) => option.stateId === value?.stateId}
											getOptionLabel={option => t(option?.name)}
											size="small"
											onChange={(_e, value) => {
												onChange(value)
												handleCity(value?.id, setValue, setList, index, setCircularProgress)
											}}
											value={value}
											disableClearable
											inputProps={{
												autoComplete: 'new-password',
											}}
											disabled={!isEditData}
											renderInput={params => (
												<S.CssTextField
													{...params}
													size="small"
													variant="outlined"
													error={currentAddress?.stateId}
													helperText={currentAddress?.stateId && currentAddress?.stateId?.message}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{circularProgress && <CircularProgress color="inherit" size={20} />}
																{params.InputProps.endAdornment}
															</React.Fragment>
														),
													}}
												/>
											)}
										/>
									)}
									defaultValue={getValuesObject(partnerData?.addresses[index]?.state)}
									name={`addresses[${index}].stateId`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
					<Grid item lg={3} sm={12}>
						{!isEditData ? (
							<div>
								<Labelitem label="Cidade" disabled value={validateValue(partnerData?.addresses[index || 0]?.city?.name)} />
							</div>
						) : (
							<Fragment>
								<Labelitem label="Cidade" required isEditData />
								<Controller
									render={({ onChange, value }) => (
										<Autocomplete
											options={list?.cities || []}
											isOptionEqualToValue={(option, value) => option.cityId === value?.cityId}
											getOptionLabel={option => t(option?.name)}
											size="small"
											onChange={(_e, value) => onChange(value)}
											value={value}
											disableClearable
											disabled={!values?.stateId || !isEditData}
											renderInput={params => (
												<S.CssTextField
													{...params}
													size="small"
													variant="outlined"
													error={currentAddress?.cityId}
													helperText={currentAddress?.cityId && currentAddress?.cityId?.message}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<React.Fragment>
																{circularProgress && <CircularProgress color="inherit" size={20} />}
																{params.InputProps.endAdornment}
															</React.Fragment>
														),
													}}
												/>
											)}
										/>
									)}
									defaultValue={getValuesObject(partnerData?.addresses[index]?.city)}
									name={`addresses[${index}].cityId`}
									control={control}
								/>
							</Fragment>
						)}
					</Grid>
				</Grid>
			</S.Container>
		</Fragment>
	)
}

TemplateAddress.propTypes = {
	watch: PropTypes.func,
	isEdit: PropTypes.bool,
	list: PropTypes.object,
	title: PropTypes.string,
	index: PropTypes.number,
	onKeyUp: PropTypes.func,
	setList: PropTypes.func,
	setValue: PropTypes.func,
	errors: PropTypes.object,
	setError: PropTypes.func,
	control: PropTypes.object,
	setLoading: PropTypes.func,
	isEditData: PropTypes.bool,
	userform: PropTypes.object,
	partnerData: PropTypes.object,
	removeTemplate: PropTypes.func,
}

TemplateAddress.defaultProps = {
	list: {},
	title: '',
	isEditData: false,
	index: 0,
	removeTemplate: () => { },
	control: {},
	errors: {},
	onKeyUp: () => { },
	watch: () => { },
	userform: {},
	setError: () => { },
	isEdit: false,
	setValue: () => { },
	setLoading: () => { },
	setList: () => { },
}

export default TemplateAddress
