import { PartnerService } from '../../Services'

export default async function getMarketingAreaList() {
	const partnerService = new PartnerService()
	const response = await partnerService.getPartnerMarketingArea()

	const result = []

	response.forEach(item => {
		result.push({
			key: item.key,
			label: item?.value,
			value: item?.value,
		})
	})

	return result ?? []
}
