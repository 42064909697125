import React, { createRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable'

import * as S from './styles'
import * as UT from './utils'

const ListComponent = ({ formData, isEditData, setOpenModal }) => {
	const tableRef = createRef()
	const { t } = useTranslation()

	return (
		<S.Container>
			<CustomMaterialTable
				tableRef={tableRef}
				title={false}
				options={{
					pageSize: 10,
					pageSizeOptions: [10, 20, 30, 50, 100],
					search: false,
					toolBar: false,
					emptyRowsWhenPaging: false,
				}}
				columns={UT.Columns(t, isEditData, setOpenModal)}
				data={formData}
			/>
		</S.Container>
	)
}

ListComponent.propTypes = {
	formData: PropTypes.array,
	isEditData: PropTypes.bool,
	setOpenModal: PropTypes.func,
}

export default ListComponent
