import React from 'react'
import * as _ from 'lodash'
import classNames from 'classnames'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import defaultAvatar from 'assets/img/icone_foto_perfil_02.png'

import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import { Avatar, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import Button from 'components/CustomButtons/Button.jsx'

import styles from '../styles.module.scss'
import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx'

import compose from 'utils/compose'
import { history } from 'utils'
import { CustomMenu, CustomPaper } from './styles'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import Cookies from 'js-cookie'

class UserBox extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null,
			setAnchorEl: null,
			selectedIndex: 1,
			setSelectedIndex: 1,
			MenuOptions: [this.props.t('MENU_USER_MY_PROFILE'), this.props.t('MENU_USER_EDIT_PASSWORD'), this.props.t('BUTTON_LOGOUT')],
			themeColor: getThemeColorFromLocalStorage(),
			open: false,
		}
	}

	handleClick = () => {
		this.setState({ open: !this.state.open })
	}
	handleClose = () => {
		this.setState({ open: false })
	}

	handleProfile = i => {
		history.push(`/${i}`)
		this.handleClose()
	}

	clearCookies = () => {
		Cookies.remove('IsBranch')
		Cookies.remove('Grouper')
		Cookies.remove('partnerId')
	}

	render() {
		const { classes, userData } = this.props
		const { open } = this.state

		const dropdownItem = classNames(classes.dropdownItem, classes.primaryHover)

		return (
			<div style={{ display: 'flex' }}>
				<Button
					color="transparent"
					aria-label="Notifications"
					aria-owns={open ? 'menu-list' : null}
					title={userData.name}
					aria-haspopup="true"
					onClick={this.handleClick}
					style={{ padding: '0', width: 230 }}
					buttonRef={node => {
						this.anchorEl = node
					}}
					disableElevation
				>
					<Avatar alt="..." src={userData.image !== '' && userData.image != null ? userData.image : defaultAvatar} className={styles.avatar} />
					<div style={{ margin: '0 10px', textAlign: 'left' }}>
						<div className={styles.userName}>
							{_.truncate(userData.name, {
								length: 18,
							})}
						</div>
						<div className={styles.userSeeds}>Perfil Pessoal</div>
					</div>
					<span style={{ color: '#fff' }}>
						<ArrowDropDown fontSize="large" />
					</span>
				</Button>

				<Popper
					open={open}
					anchorEl={this.anchorEl}
					transition
					disablePortal
					placement="bottom"
					className={classNames({
						[classes.popperClose]: !open,
						[classes.pooperResponsive]: true,
						[classes.pooperNav]: true,
					})}
				>
					{({ TransitionProps, placement }) => (
						<Grow {...TransitionProps} id="menu-list" style={{ transformOrigin: '0 0 0' }}>
							<CustomPaper className={classes.dropdown}>
								<ClickAwayListener onClickAway={this.handleClose}>
									<MenuList role="menu">
										<CustomMenu onClick={() => this.handleProfile('profile')} className={dropdownItem} themeColor={this.state.themeColor}>
											{this.props.t('MENU_USER_MY_PROFILE')}
										</CustomMenu>
										<CustomMenu themeColor={this.state.themeColor} onClick={() => this.handleProfile('changepassword')} className={dropdownItem}>
											{this.props.t('MENU_USER_EDIT_PASSWORD')}
										</CustomMenu>
										<NavLink to="/auth/login">
											<CustomMenu
												themeColor={this.state.themeColor}
												onClick={() => {
													this.clearCookies()
													this.handleClose()
												}}
												className={dropdownItem}
											>
												{this.props.t('BUTTON_LOGOUT')}
											</CustomMenu>
										</NavLink>
									</MenuList>
								</ClickAwayListener>
							</CustomPaper>
						</Grow>
					)}
				</Popper>
			</div>
		)
	}
}

export default compose(
	withTranslation(),
	withStyles(adminNavbarLinksStyle),
)(UserBox)
