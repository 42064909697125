import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

//@ components
import Filters from './components/Filter'
import NoDivisionTab from './components/NoDivisionTab'
import GeneralTab from './components/generalTab'
import DivisionTab from './components/DivisionTab'

const Generation = ({
	typeOfBenefit,
	setOnTab,
	setTabOn,
	circularProgress,
	productData,
	setDivision,
	multiple,
	setMultiple,
	setParameterization,
	setMultipleParameterization,
	typeOfBenefitPoints,
	setTypeOfBenefitPoints,
	setReloadProduct,
	selectedDivisionIdGen,
	setSelectedDivisionIdGen,
	openCalcMemory,
	setOpenCalcMemory,
	editMode,
	parameterization,
	setEditMode,
}) => {
	const [generalTab, setGeneralTab] = useState(false)
	const [byDivisionTab, setByDivisionTab] = useState(false)
	const [noDivisionTab, setNoDivisionTab] = useState(false)

	useEffect(() => {
		setOnTab({
			onTabGeneration: true,
			onTabRecue: false,
			onTabCashback: false,
		})
		setTabOn({
			cashback: false,
			redeem: false,
			points: true,
		})
	}, [])

	useEffect(() => {
		if (!editMode) {
			setReloadProduct(true)
		}
	}, [editMode])

	const sharedProps = {
		multiple,
		setMultiple,
		editMode: editMode,
		setEditMode: setEditMode,
		setDivision: setDivision,
		productData: productData,
		setGeneralTab: setGeneralTab,
		typeOfBenefit: typeOfBenefit,
		openCalcMemory: openCalcMemory,
		parameterization: parameterization,
		circularProgress: circularProgress,
		setByDivisionTab: setByDivisionTab,
		setNoDivisionTab: setNoDivisionTab,
		setOpenCalcMemory: setOpenCalcMemory,
		setParameterization: setParameterization,
		typeOfBenefitPoints: typeOfBenefitPoints,
		selectedDivisionIdGen: selectedDivisionIdGen,
		setTypeOfBenefitPoints: setTypeOfBenefitPoints,
		setSelectedDivisionIdGen: setSelectedDivisionIdGen,
		setMultipleParameterization: setMultipleParameterization,
	}

	return (
		<S.Container>
			<Filters {...sharedProps} />
			<S.Components>
				{noDivisionTab && <NoDivisionTab {...sharedProps} />}
				{generalTab && <GeneralTab {...sharedProps} />}
				{byDivisionTab && <DivisionTab {...sharedProps} />}
			</S.Components>
		</S.Container>
	)
}

Generation.propTypes = {
	multiple: PropTypes.bool,
	setMultiple: PropTypes.func,
	typeOfBenefit: PropTypes.array,
	setOnTab: PropTypes.func,
	setTabOn: PropTypes.func,
	circularProgress: PropTypes.bool,
	productData: PropTypes.object,
	setDivision: PropTypes.func,
	parameterization: PropTypes.object,
	setParameterization: PropTypes.func,
	setMultipleParameterization: PropTypes.func,
	typeOfBenefitPoints: PropTypes.array,
	setTypeOfBenefitPoints: PropTypes.func,
	setReloadProduct: PropTypes.func,
	selectedDivisionIdGen: PropTypes.array,
	setSelectedDivisionIdGen: PropTypes.func,
	openCalcMemory: PropTypes.bool,
	setOpenCalcMemory: PropTypes.func,
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func,
}

export default Generation
