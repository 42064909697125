/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'

// @ React-i18next and moment
import moment from 'moment'
import { useTranslation } from 'react-i18next'

// @ Components
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'

// @ Utils and Styles
import { getRightLocale } from '../../../../../../index'
import * as S from './styles'
import SearchForm from './SearchForm'
import ExportedLogs from './exportedLogs'
import { DetailsModal } from './DetailsModal'
import _ from 'lodash'

//@Material
import { Tooltip } from '@mui/material'

// @ Request
import {
	GetAllPartnerLogs,
	GetAllTransactionLogs,
} from '../../../../../../redux/api/paymentGateway'
import { GetCurrentPartner } from '../../../../../../redux/api/user'

const TabPanel = ({ tab, setTab }) => {
	const { t } = useTranslation()
	const tableRef = useRef()

	const [page, setPage] = useState(10)
	const [logs, setLogs] = useState([])
	const [modal, setModal] = useState({
		open: false,
		id: '',
	})

	const [totalFiles, setTotalFiles] = useState(0)
	const [values, setValues] = useState()

	const spliceMessage = (message, realizedAt, name, id, partnerName) => {
		const locale = getRightLocale()
		moment.locale(locale)

		const logDate = moment(realizedAt).format('DD [de] MMMM [de] YYYY')

		let remainingWords = message
			.replace(name, `<strong>${name}</strong>`)
			.replace(partnerName, `<strong>${partnerName}</strong>`)
			.replace(
				'sucesso',
				`<a id='${id}' class='status-cd' style='cursor: pointer; border-bottom: 1px dotted #8FC992;' title='Detalhar'><span style='color: #8FC992; font-weight: bold;'>sucesso</span></a>`,
			)
			.replace(
				'pendente',
				`<a id='${id}' class='status-cd' style='cursor: pointer; border-bottom: 1px dotted #E6CC75;' title='Detalhar'><span style='color: #E6CC75; font-weight: bold;'>pendente</span></a>`,
			)
			.replace(
				'negado',
				`<a id='${id}'class='status-cd'  style='cursor: pointer; border-bottom: 1px dotted #F18D85;' title='Detalhar'><span style='color: #F18D85; font-weight: bold;'>negado</span></a>`,
			)
			.replace(
				'negada',
				`<a id='${id}' class='status-cd' style='cursor: pointer; border-bottom: 1px dotted #F18D85;' title='Detalhar'><span style='color: #F18D85; font-weight: bold;'>negada</span></a>`,
			)
			.replace(
				'falha',
				`<a id='${id}' class='status-cd' style='cursor: pointer; border-bottom: 1px dotted #F18D85;' title='Detalhar'><span style='color: #F18D85; font-weight: bold;'>negada</span></a>`,
			)
			.replace(logDate, `<strong>${logDate}</strong>`)

		return (
			<p
				style={{
					font: 'normal normal normal 15px/46px Segoe UI',
					display: 'flex',
				}}
			>
				<div
					onClick={() => {
						if (tab === 0) {
							document
								.getElementById(`${id}`)
								.addEventListener('click', () => {
									console.log('click')
									setModal({
										open: true,
										id,
									})
								})
						}
					}}
					dangerouslySetInnerHTML={{
						__html: remainingWords,
					}}
				/>
			</p>
		)
	}

	const partnerId = async () => {
		const partner = await GetCurrentPartner()

		return partner.id
	}

	const getLogs = async (query, tab) => {
		const Id = await partnerId()

		const dateFrom = query?.dateFrom
			? moment(query?.dateFrom?._d).startOf('day')
			: null

		const dateTo = query?.dateTo
			? moment(query?.dateTo?._d).endOf('day')
			: null

		const description = query?.history !== undefined ? query?.history : ''
		const userName = []
		const statusList = []

		if (logs) {
			logs.map(log => {
				if (query?.history?.includes(log.userName)) {
					userName.push(log.userName)
				}
			})
		}

		if (query.status && tab === 0) {
			if (query.status !== { label: '' }) {
				query.status.map(status => {
					statusList.push(status.title)
				})
			}
		}

		if (tab === 0) {
			return {
				userId: '',
				userName: '',
				action: '',
				sucess: null,
				amount: 0,
				status: statusList.length === 0 ? [] : statusList,
				transactionId: '',
				paymentMethod: '',
				purchaserName: query?.autorizadora?.label,
				initialCreated: dateFrom,
				finalCreated: dateTo,
				orderByDescending:
					query?.orderDirection === 'desc' ? false : true,
				pageSize: query?.pageSize ?? 10,
				page: query?.page + 1 || 1,
			}
		} else {
			return {
				userId: '',
				userName: userName.length !== 0 ? userName[0] : '',
				action: '',
				description: description,
				purchaserName: '',
				success: query?.status?.label
					? query?.status?.label === 'Sucesso'
						? true
						: false
					: null,
				initialCreated: dateFrom,
				finalCreated: dateTo,
				orderByDescending:
					query?.orderDirection === 'desc' ? false : true,
				pageSize: query?.pageSize ?? 10,
				page: query?.page + 1 || 1,
			}
		}
	}

	return tab !== 2 ? (
		<>
			<DetailsModal
				open={modal.open}
				handleClose={() =>
					setModal({
						open: false,
						id: '',
					})
				}
				id={modal.id}
				type="technical"
				setTab={setTab}
			/>
			<SearchForm
				tableRef={tableRef}
				tab={tab}
				getLogs={getLogs}
				setTab={setTab}
				values={values}
				totalFiles={totalFiles}
			/>
			<S.Table>
				<MaterialTable
					tableRef={tableRef}
					title={false}
					onChangeRowsPerPage={e => {
						setPage(e)
					}}
					options={{
						sorting: true,
						emptyRowsWhenPaging: false,
						toolbar: false,
						actionsColumnIndex: -1,
						search: false,
						draggable: false,
						pageSize: page,
						pageSizeOptions: [10, 20, 30, 40, 50, 100],
					}}
					localization={{
						body: {
							emptyDataSourceMessage: (
								<S.EmptyTable>
									<i
										className="icon-informacao1"
										style={{ color: '#B4B4B4' }}
									/>
									<span>Nenhum registro para exibir</span>
								</S.EmptyTable>
							),
						},

						pagination: {
							labelRowsSelect: 'linhas',
							labelDisplayedRows: '{from}-{to} de {count}',
							firstTooltip: 'Primeira página',
							previousTooltip: 'Página anterior',
							nextTooltip: 'Próxima página',
							lastTooltip: 'Última página',
						},
					}}
					columns={[
						{
							title: 'Log',
							cellStyle: {
								minWidth: '100px',
								maxWidth: '100px',
							},
							headerStyle: {
								minWidth: '100px',
								maxWidth: '100px',
							},
							render: rowData => (
								<S.Description>
									<S.AlignIconStatus>
										<Tooltip
											title={
												rowData && rowData.success
													? 'Sucesso'
													: rowData.status === "PENDING" && rowData.success === false ?
														"Pendente" : 'Negado'
											}
											placement="top"
										>
											<S.IconStatus
												style={{
													backgroundColor: !rowData.success && rowData.status === "PENDING"
														? '#E6CC75'
														: (tab !== 0 && rowData.success) || (rowData && rowData.success)
															? '#8FC992'
															: '#F05B4F'
												}}
											/>
										</Tooltip>
									</S.AlignIconStatus>
									<div style={{
										lineHeight: "22px",
									}}>
										{spliceMessage(
											rowData.description,
											rowData.realizedAt,
											rowData.consumerName,
											rowData.id,
											rowData.partnerName,
										)}
									</div>
								</S.Description>
							),
						},
					]}
					data={query =>
						new Promise((resolve, reject) => {
							getLogs(query, tab).then(res => {
								res && setValues(res)
								tab === 0
									? GetAllTransactionLogs(res).then(res => {
										setTotalFiles(
											res?.data?.recordCount,
										)
										setLogs(res?.data?.results)
										res && res?.data?.results
											? resolve({
												data: res.data.results,
												page:
													res?.data
														.currentPage -
													1,
												totalCount:
													res?.data
														.recordCount,
											})
											: resolve({
												data: [],
												page: 0,
												totalCount: 0,
											})
									})
									: GetAllPartnerLogs(res).then(res => {
										setTotalFiles(
											res?.data?.recordCount,
										)
										setLogs(res?.data?.results)
										res && res?.data?.results
											? resolve({
												data: res.data.results,
												page:
													res?.data
														.currentPage -
													1,
												totalCount:
													res?.data
														.recordCount,
											})
											: resolve({
												data: [],
												page: 0,
												totalCount: 0,
											})
									})
							})
						})
					}
				/>
			</S.Table>
		</>
	) : (
		<ExportedLogs />
	)
}

TabPanel.propTypes = {
	tab: PropTypes.number,
	setTab: PropTypes.func,
}

export default TabPanel
