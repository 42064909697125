import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import * as S from './styles'
import ListComponent from './list'
import Section from 'components/Section'
import { GetListProductParametrizationByMultipleDivisions } from '../../../../redux/api/productPage'
import Loader from 'components/Loader/Loader.jsx'

const ProductHistory = () => {
	const { t } = useTranslation()
	const history = useHistory()

	const [productParametrizationData, setProductParametrizationData] = useState([])
	const [productId, setProductId] = useState('')
	const [typeOfBenefit, setTypeOfBenefit] = useState('')
	const [isLoadingData, setIsLoadingData] = useState(false)

	useEffect(() => {
		setIsLoadingData(true)
		setTimeout(() => {
			setIsLoadingData(false)
		}, 2000)
	}, [])

	useEffect(() => {
		const extractParamsFromUrl = () => {
			const pathSegments = window.location.pathname.split('/')
			const lastSegment = pathSegments[pathSegments.length - 1]
			const paramSegments = lastSegment.split('&')
			paramSegments.forEach(segment => {
				if (segment.startsWith('ProductId=')) {
					setProductId(segment.replace('ProductId=', ''))
				}
				if (segment.startsWith('TypeOfBenefit=')) {
					setTypeOfBenefit(segment.replace('TypeOfBenefit=', ''))
				}
			})
		}
		extractParamsFromUrl()
		const fetchData = async () => {
			try {
				if (productId) {
					const data = await GetListProductParametrizationByMultipleDivisions(productId, typeOfBenefit)
					if (!data.results || data.results.length === 0 || data.results.progress === 100) {
						clearInterval(intervalId)
					} else {
						setProductParametrizationData(data?.results)
					}
				}
			} catch (error) {
				console.error('Erro ao buscar dados:', error)
			}
		}

		fetchData()

		const intervalId = setInterval(fetchData, 3000)
		return () => {
			clearInterval(intervalId)
		}
	}, [productId])

	const handleGoBack = () => {
		history.goBack()
	}

	return (
		<S.Container>
			{isLoadingData && <Loader />}
			<Section title={t('MENU_PRODUCT_HISTORY')} />
			<S.ButtonContainer>
				<Tooltip arrow id="tooltip-top" title={'Voltar'} placement="top-start">
					<S.ButtonAction onClick={handleGoBack}>
						<i className="icon-bot_voltar" />
					</S.ButtonAction>
				</Tooltip>
			</S.ButtonContainer>
			<ListComponent data={productParametrizationData} typeOfBenefit={typeOfBenefit} />
		</S.Container>
	)
}

export default ProductHistory
