import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

//@ components
import Filters from './components/Filter'
import GeneralTab from './components/generalTab'
import DivisionTab from './components/DivisionTab'
import NoDivisionTab from './components/NoDivisionTab'
import ModalInfo from './components/Modal/ModalInfo'

const RescueTab = props => {
	const { setTabOn, setOnTab, editMode, setEditMode, setReloadProduct } = props
	const [generalTab, setGeneralTab] = useState(false)
	const [openModalInfo, setOpenModalInfo] = useState({
		open: false,
		tabs: null,
	})
	const [byDivisionTab, setByDivisionTab] = useState(false)
	const [noDivisionTab, setNoDivisionTab] = useState(false)

	useEffect(() => {
		setOnTab({
			onTabRecue: true,
			onTabCashback: false,
			onTabGeneration: false,
		})
		setTabOn({
			redeem: true,
			points: false,
			cashback: false,
		})
	}, [])

	useEffect(() => {
		if (!editMode) setReloadProduct(true)
	}, [editMode])

	const sharedProps = {
		...props,
		generalTab,
		setGeneralTab,
		byDivisionTab,
		setByDivisionTab,
		noDivisionTab,
		setNoDivisionTab,
		openModalInfo,
		setOpenModalInfo,
	}

	return (
		<S.Container>
			<ModalInfo
				open={openModalInfo.open}
				onConfirm={() => {
					setOpenModalInfo({ open: false, tabs: null })
					setEditMode(false)
				}}
				onCancel={() => {
					setOpenModalInfo({ open: false, tabs: null })
				}}
			/>
			<Filters {...sharedProps} />
			<S.Components>
				{noDivisionTab && <NoDivisionTab {...sharedProps} />}
				{generalTab && <GeneralTab {...sharedProps} />}
				{byDivisionTab && <DivisionTab {...sharedProps} />}
			</S.Components>
		</S.Container>
	)
}

RescueTab.propTypes = {
	setTabOn: PropTypes.func,
	setOnTab: PropTypes.func,
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func,
	setReloadProduct: PropTypes.func,
}

export default RescueTab
