import React, { useEffect, useState } from 'react'

import GriTablow from './GriTablow.jsx'

import { Grid, Divider } from '@material-ui/core'

import { Add } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import Button from '../../../components/CustomButtons/Button.jsx'

const ColorLuminance = (hex, lum) => {
	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '')
	if (hex.length < 6) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
	}
	lum = lum || 0
	// convert to decimal and change luminosity
	var rgb = '#',
		c,
		i
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i * 2, 2), 16)
		c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
		rgb += ('00' + c).substr(c.length)
	}
	return rgb
}

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: '#ececec',
		borderRadius: '4px',
		margin: '3px 0',
		'&:nth-child(even)': {
			backgroundColor: '#F4F4F4',
		},
	},
	button: {
		height: '100%',
		backgroundColor: `${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor}`,
		textAlign: 'center',
		display: 'flex',
		borderTopRightRadius: '4px',
		borderBottomRightRadius: '4px',
		float: 'right',
		cursor: 'pointer',
		width: '50%',
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
		'&:hover': {
			backgroundColor: ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5),
		},
	},
}))

const GriTable = props => {
	const classes = useStyles()
	const { labels, values, history, id } = props

	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const getColor = () => {
		return (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor &&
			themeColor.navigation.buttons.textColor
		)
	}

	return (
		<Grid container spacing={0} className={classes.root}>
			<Grid container item xs={12} sm={12} md={11} spacing={0}>
				<Grid container spacing={0} style={{ padding: '5px 0' }}>
					<Grid container item xs={12} spacing={0}>
						<GriTablow labels={[labels[0], labels[1], labels[2]]} values={[values[0], values[1], values[2]]} />
					</Grid>
					<Divider style={{ width: '96%', margin: '0 8px' }} />
					<Grid container item xs={12} spacing={0}>
						<GriTablow labels={[labels[3], labels[4], labels[5]]} values={[values[3], values[4], values[5]]} />
					</Grid>
					<Divider style={{ width: '96%', margin: '0 8px' }} />
					<Grid container item xs={12} spacing={0}>
						<GriTablow labels={[labels[6], labels[7], labels[8]]} values={[values[6], values[7], values[8]]} />
					</Grid>
				</Grid>
			</Grid>
			<Grid container item xs={12} sm={12} md={1} spacing={0} style={{ display: 'flex', justifyContent: 'end' }}>
				<Button
					style={{ padding: '7px', margin: '0px' }}
					variant="contained"
					color="greenButtonColor"
					onClick={() => history.push(`/sales/details/${id}`)}
				>
					<Add style={{ margin: 'auto', color: `${getColor()}` }} />
				</Button>
			</Grid>
		</Grid>
	)
}

export default GriTable
