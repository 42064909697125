import React, { createRef } from 'react'
import { useTranslation } from 'react-i18next'

//@components
import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable'

//@Utils
import * as S from './styles'
import * as UT from './mocks'

const ListComponent = ({ listData }) => {
    const tableRef = createRef()
    const { t } = useTranslation()

    return (
        <S.Container>
            <CustomMaterialTable
                tableRef={tableRef}
                title={false}
                options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30, 50, 100],
                    search: false,
                    toolBar: false,
                    emptyRowsWhenPaging: false,
                }}
                columns={UT.Columns(t)}
                data={listData}
            />
        </S.Container>
    )
}

export default ListComponent

