import React, { useEffect } from 'react'
import * as S from './styles'

//@ components
import ListComponent from "./list"
import Atention from './Atention'

const StatusByDivision = ({ partnerId, productId, statusName, setStatusName, hasNoDivision, setOnTab }) => {

    useEffect(() => {
        setOnTab({
            onTabGeneration: false,
            onTabRecue: false,
            onTabCashback: false,
        })
    }, [])

    return (
        <S.Container>
            {hasNoDivision ?
                <Atention /> :
                <ListComponent partnerId={partnerId}
                    productId={productId}
                    statusName={statusName} setStatusName={setStatusName} />
            }
        </S.Container>
    )
}

export default StatusByDivision
