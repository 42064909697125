import styled from "styled-components";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

export const GeneralRulesBox = styled(GridContainer)`
  .switch-label {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }

  .bottom-box {
    padding-left: 15px;
    position: relative !important;
    top: -35px;
    /* margin-top: -30px; */
  }

  @media (max-width: 1279px) {
    .recorrencionRules {
      padding-right: 0px !important;
    }
  }

  @media (max-width: 959.95px) {
    .bottom-box {
      padding-left: 0;
    }
  }
`;

export const CardBox = styled(Card)`
  margin-bottom: 50px !important;
`;

export const GridItemRules = styled(GridItem)`
  padding-left: 0 !important;
`;

export const InputWrapper = styled.div`
  /* background-color: lightgray; */
  display: inline-flex;
  align-items: baseline;

  > .MuiFormControl-root {
    padding: 0;
    top: -3px;
  }

  &.expirte-date {
    padding: 0 !important;
  }
`;

export const FormSelect = styled(FormControl)`
  .MuiInput-formControl {
    margin-top: 21px !important;
  }
`;
