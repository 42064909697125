import React from 'react'
import styled from 'styled-components/macro'
import * as _ from 'lodash'
import classNames from 'classnames'
import compose from 'utils/compose'
import { HubConnectionBuilder, LogLevel } from '@aspnet/signalr'
import { NavLink } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

// components
import Button from 'components/CustomButtons/Button.jsx'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Paper from '@material-ui/core/Paper'
import Grow from '@material-ui/core/Grow'
import Popper from '@material-ui/core/Popper'
import { Badge } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

// css
import adminNavbarLinksStyle from 'assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.jsx'

import { history } from 'utils'

import { ReadNotificationHub } from 'redux/api/signalr'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

const Container = styled.div`
	display: flex;
	.MuiBadge-colorPrimary {
		color: #0F2872;
		background-color:#FBBF46;
	}
`

class UserBox extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			anchorEl: null,
			setAnchorEl: null,
			selectedIndex: 1,
			setSelectedIndex: 1,
			MenuOptions: [
				this.props.t('MENU_USER_MY_PROFILE'),
				this.props.t('MENU_USER_EDIT_PASSWORD'),
				this.props.t('BUTTON_LOGOUT'),
			],
			open: false,
			alertCount: '',
			themeColor: getThemeColorFromLocalStorage(),
		}
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.alerts !== this.props.alerts) {
			if (this.props.alerts && this.props.alerts.alerts.type) {
				if (this.props.alerts.alerts.type.value === 'ALERT') {
					this.setState({
						alertCount: this.props.alerts.alerts.value,
					})
				}
			}
			if (this.props.alerts && this.props.alerts.identityId) {
			}
		}
	}

	handleClick = () => {
		this.setState({ open: !this.state.open })
	}
	handleClose = () => {
		this.setState({ open: false })
	}

	handleProfile = (i) => {
		history.push(`/${i}`)
		this.handleClose()
	}

	goToAlerts = () => {
		const response = ReadNotificationHub()
		if (response) {
			this.setState({ alertCount: 0 })
		}
		history.push('/notifications')
	}

	// getColor = () => {
	// 	return this.state.themeColor &&
	// 		this.state.themeColor.navigation &&
	// 		this.state.themeColor.navigation.menu &&
	// 		this.state.themeColor.navigation.menu.textColor &&
	// 		this.state.themeColor.navigation.menu.textColor
	// }

	render() {
		const { classes } = this.props
		const { open } = this.state

		const dropdownItem = classNames(
			classes.dropdownItem,
			classes.primaryHover,
		)

		return (
			<Container themeColor={this.state.themeColor}>
				{/* <NavLink to="/notifications"> */}
				<div onClick={() => this.goToAlerts()}>
					<Badge
						badgeContent={this.state.alertCount}
						color="primary"
						style={{
							margin: '0 10px',
						}}
					>
						<i
							className="icon-ico_aviso"
							style={{ paddingLeft: '10px', color: 'white' }}
						/>
					</Badge>
				</div>
				{/* </NavLink> */}

				<Button
					style={{
						margin: '0 10px',
						textAlign: 'left',
						backgroundColor: 'transparent',
						color: "transparent",
						padding: '0px',
					}}
				>
					<i
						className="icon-ico_notificacoes"
						style={{
							paddingRight: '10px',
							color: 'white',
							cursor: "pointer",
						 }}
					/>
				</Button>

				<Popper
					open={open}
					anchorEl={this.anchorEl}
					transition
					disablePortal
					placement="bottom"
					className={classNames({
						[classes.popperClose]: !open,
						[classes.pooperResponsive]: true,
						[classes.pooperNav]: true,
					})}
				>
					{({ TransitionProps, placement }) => (
						<Grow
							{...TransitionProps}
							id="menu-list"
							style={{
								transformOrigin: '0 0 0',
							}}
						>
							<Paper className={classes.dropdown}>
								<ClickAwayListener
									onClickAway={this.handleClose}
								>
									<MenuList role="menu">
										<MenuItem
											onClick={() =>
												this.handleProfile('profile')
											}
											className={dropdownItem}
										>
											{this.props.t(
												'MENU_USER_MY_PROFILE',
											)}
										</MenuItem>
										<MenuItem
											onClick={() =>
												this.handleProfile(
													'changepassword',
												)
											}
											className={dropdownItem}
										>
											{this.props.t(
												'MENU_USER_EDIT_PASSWORD',
											)}
										</MenuItem>
										<NavLink to="/auth/login">
											<MenuItem
												onClick={this.handleClose}
												className={dropdownItem}
											>
												{this.props.t('BUTTON_LOGOUT')}
											</MenuItem>
										</NavLink>
									</MenuList>
								</ClickAwayListener>
							</Paper>
						</Grow>
					)}
				</Popper>
			</Container>
		)
	}
}

export default compose(
	withTranslation(),
	withStyles(adminNavbarLinksStyle),
)(UserBox)
