import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'

import * as S from './styles'

const Buttons = ({ onClick, className, Icon, disabled }) => {
	return (
		<S.Container>
			<Button onClick={onClick} className={className} disabled={disabled}>
				<i className={Icon} />
			</Button>
		</S.Container>
	)
}

Buttons.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
	Icon: PropTypes.string,
	disabled: PropTypes.bool,
}

export default Buttons
