import React from 'react'

// @Material-UI
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'

// @Material-Utils
import { makeStyles, withStyles } from '@material-ui/core/styles'

// @ThemeColor
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

export const AntTab = withStyles(theme => ({
	root: {
		minWidth: 72,
		fontWeight: theme.typography.fontWeightBold,
		marginRight: theme.spacing(4),
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
		'&:hover': {
			color: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
			opacity: 1,
		},
		'&$selected': {
			color: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
			fontWeight: theme.typography.fontWeightBold,
		},
		'&:focus': {
			color: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
	},
	selected: {},
}))(props => <Tab disableRipple {...props} />)

export const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
	},
	padding: {
		padding: theme.spacing(3),
	},
	demo1: {
		backgroundColor: theme.palette.background.paper,
	},
}))

export const AntTabs = withStyles({
	root: {
		width: '100%',
	},
	indicator: {
		backgroundColor: getThemeColorFromLocalStorage()?.navigation?.buttons
			?.backgroundColor,
	},
})(Tabs)
