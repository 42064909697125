/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core'
import React, { Fragment } from 'react'
import AdditionalModulesCard from '../../../../AdditionalModulesCard'
import Section from 'components/Section'
import { Stack } from '@mui/material'

export default function AdditionalModules({ partnerData, partnerInfos, customFieldsForm, updateCustomFieldsForm, methods, isEditData }) {
	return (
		<Fragment>
			<Grid container>
				<Grid item lg={12} sm={12}>
					<Stack mt={10} mb={5} sx={{ width: '100%' }}>
						<Section title="Módulos adicionais" />
					</Stack>
				</Grid>
				<Grid item lg={12} sm={12}>
					<AdditionalModulesCard
						methods={methods}
						isEditData={isEditData}
						customFieldsForm={customFieldsForm}
						options={partnerInfos?.additionalModules}
						additionalModules={partnerData?.additionalModules}
						updateCustomFieldsForm={updateCustomFieldsForm}
					/>
				</Grid>
			</Grid>
		</Fragment>
	)
}
