import React from 'react'
import { GridContainer, GridItem, MuiBox, MuiStack, MuiTypography } from '../../../../components/storybook'

export function DivisionItems({ divisions }) {
	const divisionColors = {
		Branch: '#7E6BB9',
		BusinessUnit: '#FB9006',
		CostCenter: '#FB9006',
		Department: '#1BBED3',
	}

	const divisionLegends = {
		Branch: 'DP',
		BusinessUnit: 'UN',
		CostCenter: 'CC',
		Department: 'DP',
	}
	return (
		<GridContainer mt={1} spacing={2}>
			{divisions?.map((item, index) => (
				<GridItem key={index} lg={4} md={4} sm={12}>
					<MuiStack
						direction="row"
						sx={{
							background: '#FAFAFC',
							padding: '10px',
							borderRadius: '5px',
							minHeight: '35px',
							width: '100%',
						}}
					>
						{item?.segmentationType?.value ? (
							<MuiStack direction="row" alignItems="center" spacing={1}>
								<MuiBox
									sx={{
										height: '25px',
										width: '5px',
										background: divisionColors[(item?.segmentationType?.value)],
										borderRadius: '5px 0px 0px 5px',
									}}
								></MuiBox>
								<MuiTypography fontSize="13px">
									{divisionLegends[(item?.segmentationType?.value)]} - {item?.name}
								</MuiTypography>
							</MuiStack>
						) : (
							<MuiTypography>{item?.name}</MuiTypography>
						)}
					</MuiStack>
				</GridItem>
			))}
		</GridContainer>
	)
}
