import styled from 'styled-components'
import Card from 'components/Card/Card.jsx'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

export const CustomCard = styled(Card)`
	margin: 0 0 0 15px;
`

export const BodyCard = styled.div`
	padding: 0 25px;
`

export const Title = styled.p`
	font: normal normal normal 12px/60px Roboto;
	color: #333333;
	margin: 0;
	line-height: 100%;
`

export const Switch = styled.div`
	margin: 17px 0 20px 0;
`

export const ButtonTitle = styled.p`
	font: normal normal bold 14px Roboto;
	letter-spacing: 0px;
	color: ${getThemeColorFromLocalStorage() &&
	getThemeColorFromLocalStorage().titles &&
	getThemeColorFromLocalStorage().titles.main &&
	getThemeColorFromLocalStorage().titles.main.textColor
		? getThemeColorFromLocalStorage().titles.main.textColor
		: '#fff !important'};
	text-transform: math-auto;
	opacity: 1;
	margin: 0 0 0 5px;
`

export const ButtonPartnersIcon = styled.i`
	color: ${getThemeColorFromLocalStorage() &&
	getThemeColorFromLocalStorage().visual &&
	getThemeColorFromLocalStorage().visual.icons &&
	getThemeColorFromLocalStorage().visual.icons.color
		? getThemeColorFromLocalStorage().visual.icons.color
		: '#fff !important'};
`
