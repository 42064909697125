import styled from 'styled-components/macro'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

export const text = styled.span`
	color: ${germiniDefaultColors[0]};
	font-weight: bold;
	font-size: 15px;
`

export const number = styled.span`
	color: white;
	background-color: ${germiniDefaultColors[0]};
	font-weight: bold;
	font-size: 15px;
	padding: 2px 3px;
	border-radius: 4px;
`
