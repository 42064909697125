/* eslint-disable */
import React from 'react'
import * as S from '../styles'
import { Tooltip } from '@material-ui/core'
import moment from 'moment'
import { DeleteAttachmentFile } from '../../../../../../../../../redux/api/PartnerDocuments'

import Cookies from 'js-cookie'


export const Columns = (t, isEditData, setOpenModal) => [
	{
		title: t('Data do anexo'),
		field: 'date',
		width: '20%',
		cellStyle: {
			width: '20%',
		},
		headerStyle: {
			width: '20%',
			paddingLeft: '25px',
		},
		render: rowData => (
			<div
				style={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '34px',
				}}
			>
				<span style={{ fontSize: '14px' }}>{moment(rowData.date).format('DD/MM/YYYY')}</span>
			</div>
		),
	},
	{
		title: t('Nome do documento'),
		field: 'fileName',
		width: '25%',
		cellStyle: {
			width: '25%',
			textAlign: 'left !important',
		},
		headerStyle: {
			width: '25%',
			paddingLeft: '25px',
		},
	},
	{
		title: t('Documento'),
		field: 'file',
		width: '25%',
		cellStyle: {
			width: '25%',
			textAlign: 'left !important',
		},
		headerStyle: {
			width: '25%',
			paddingLeft: '25px',
		},
		render: () => (
			<div
				style={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '34px',
				}}
			>
				<span style={{ fontSize: '14px' }}>-</span>
			</div>
		),
	},
	{
		title: t('Tipo do documento'),
		field: 'typeOfDocument',
		width: '30%',
		cellStyle: {
			width: '30%',
			textAlign: 'left !important',
		},
		headerStyle: {
			width: '30%',
			paddingLeft: '25px',
		},
		render: rowData => (
			<div
				style={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '34px',
				}}
			>
				<span style={{ fontSize: '14px' }}>{rowData.attachmentTypes.key === 0 ? rowData.attachmentType : t(rowData.attachmentTypes.value)}</span>
			</div>
		),
	},
	isEditData
		? {
			title: 'Ação',
			field: 'actions',
			cellStyle: {
				width: '60px !important',
				maxWidth: '60px !important',
				minWidth: '60px !important',
			},
			sorting: false,
			render: rowData => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'start',
					}}
				>
					<Tooltip arrow id="tooltip-top" title={'Excluir'} placement="top-start">
						<S.ButtonAction onClick={() => {
							setOpenModal({
								open: true,
								text: t('Deseja realmente excluir o documento e suas informações?'),
								subText: '',
								object: rowData,
							})
						}}>
							<i className="icon-ico_excluir" />
						</S.ButtonAction>
					</Tooltip>
				</div >
			),
		}
		: {},
]


export const FormatDocument = (response, document) => {
	const obj = response?.data ? response?.data : ''
	const data = {
		date: new Date().toLocaleDateString(),
		file: obj,
		fileName: document?.fileName ? document.fileName : '',
		attachmentTypes: document?.attachmentTypes,
		attachmentType: document?.attachmentType || '',
		status: 'not-saved',
	}

	return data
}

export const defaultFormatDocument = obj => {
	const data = {
		date: obj?.date || '',
		fileName: obj?.fileName || '',
		file: obj?.file || '',
		attachmentTypes: obj?.attachmentTypes,
		attachmentType: obj?.attachmentType || '',
	}

	return data
}



export const defaultValue = {
	attachmentTypes: {
		value: '',
		key: '',
	},
	file: '',
	fileName: '',
	attachmentType: '',
}


export const propsOpenModal = {
	open: false,
	type: '',
	subText: '',
	hasActive: false,
	object: {},
}
