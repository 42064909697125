import React, { useEffect, useState } from 'react'
import SnackDefault from 'components/SnackDefault'
import { Container } from './styles'
import Button from 'components/CustomButtons/Button.jsx'
import { history } from '../../../utils'
import logoVXP from '../../../assets/img/logo_EAWARE.png'
import { useForm, Controller } from 'react-hook-form'
import { apiKernel } from 'services/api'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import Loader from 'components/Loader/Loader'

//import CustomTextField from 'components/CustomTextField'
import TextInput from 'components/TextInput'
import { IconButton, InputAdornment } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { getThemeColorFromLocalStorage, getThemeColorFromRequest, setThemeColorToLocalStorage } from '../../../redux/api/themeColors'

export default function NewPassword() {
	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const token = history.location.state ? history.location.state.data.token : null
	const email = history.location.state ? history.location.state.data.email : null

	const [loading, setLoading] = useState(false)
	const [themeColor, setThemeColor] = useState(getThemeColorFromLocalStorage())
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: '',
		text: '',
	})

	const getTheme = async () => {
		setLoading(true)

		try {
			const response = await getThemeColorFromRequest()

			if (response?.data) {
				const { data } = response
				setThemeColor(data)
				setThemeColorToLocalStorage(data)
			}
		} catch (error) {
			console.error('Erro ao fazer parse do tema:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!token || !email) {
			history.push('/')
		}
		getTheme()
	}, [])

	const schema = Yup.object().shape({
		newPassword: Yup.string()
			.min(8, 'mínimo de 8 caracteres')
			.max(20, 'máximo de 20 caracteres')
			.required('Senha nova é obrigatória')
			.test('string compare', 'Senha não deve conter mais de 3 números repetidos em ordem', (password = '') => {
				for (let i = 0; i <= 9; i++) {
					if (password.includes(`${i}${i}${i}`)) {
						return false
					}
				}
				return true
			})
			.test('string compare', 'Senha deve conter letras, números e caracteres especiais', (password = '') => {
				if (password) {
					let numberMatches = password.match(/\d+/g)
					let specialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
					let specialCharactersMatches = specialCharacters.test(password)

					if (!numberMatches) {
						return false
					}
					if (!specialCharactersMatches) {
						return false
					}
				}
				return true
			})
			.test('string compare', 'Senha não pode ter mais de 3 números sequenciais', (password = '') => {
				if (password) {
					for (let i = 0; i <= 9; i++) {
						if (password.includes(`${i}${i + 1 <= 9 ? i + 1 : 0}${i + 2 <= 9 ? i + 2 : i - 8}${i + 3 <= 9 ? i + 3 : i - 7}`)) {
							return false
						}
					}
				}
				return true
			}),
		confirmPassword: Yup.string()
			.oneOf(
				[Yup.ref('newPassword'), null],
				'Senhas não conferem',
				//'Campos senha nova não batem',
			)
			.required('Confirme a nova senha'),
	})

	const {
		handleSubmit,
		formState: { errors },
		control,
	} = useForm({
		defaultValues: {
			newPassword: '',
			confirmPassword: '',
		},
		resolver: yupResolver(schema),
	})

	const onSubmit = data => {
		const { newPassword } = data
		CreatePassword(newPassword)
	}

	const CreatePassword = async newPassword => {
		const { data } = await apiKernel.put(`/Partner/ResetPassword`, {
			newPassword: newPassword,
			token: token,
			email: email,
		})

		if (data.success) {
			setSnackStatus({
				open: true,
				severity: 'success',
				text: 'Senha cadastrada com sucesso.',
			})
			setTimeout(() => {
				history.push(`/`)
			}, 1000)
		}

		if (!data.success) {
			setSnackStatus({
				open: true,
				severity: 'error',
				text: 'Occoreu um erro no cadastro de senha.',
			})
		}
	}

	return loading ? (
		<Loader />
	) : (
		<>
			<Container themeColor={themeColor}>
				<SnackDefault
					snackStatus={snackStatus}
					handleCloseSnack={() =>
						setSnackStatus(prevState => ({
							...prevState,
							open: false,
						}))
					}
				/>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className={'card cardShadow'}>
						<div className={`${'cardCenter'} ${'cardHeader'}`}>
							<img src={themeColor?.images?.loginPage?.logo?.url} alt="logo" width={200} />
						</div>
						<div
							className={'cardHeaderTitle'}
							style={{
								backgroundColor: themeColor?.structure?.backgroundColor,
							}}
						>
							{'APLICAÇÃO DO PARCEIRO'}
						</div>
					</div>
					<div>
						<div className="formLabels2">Crie a sua senha de acesso a APLICAÇÃO DO PARCEIRO.</div>

						<div
							style={{
								margin: '40px 0',
							}}
						>
							<Controller
								as={
									<TextInput
										type={showPassword ? 'text' : 'password'}
										placeholder="Senha"
										error={errors.newPassword}
										helperText={errors.newPassword && errors.newPassword.message}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton disableRipple aria-label="toggle password visibility" onClick={() => setShowPassword(!showPassword)}>
														{showPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								}
								name="newPassword"
								control={control}
							/>
						</div>

						<div style={{ margin: '40px 0' }}>
							<Controller
								as={
									<TextInput
										type={showConfirmPassword ? 'text' : 'password'}
										placeholder="Confirmação de senha"
										error={errors.newPassword}
										helperText={errors.confirmPassword && errors.confirmPassword.message}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														disableRipple
														aria-label="toggle password visibility"
														onClick={() => setShowConfirmPassword(!showConfirmPassword)}
													>
														{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								}
								name="confirmPassword"
								control={control}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Button style={{ margin: '0 50px' }} fullWidth color="greenButtonColor" type="submit">
								Cadastrar senha
							</Button>
						</div>
					</div>
				</form>
				<div className="logoVXP">
					<img src={logoVXP} alt="logoVXP" />
				</div>
			</Container>
		</>
	)
}
