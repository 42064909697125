import styled from 'styled-components'

export const LinkContainer = styled.div`
	display: flex;
	border: 1px solid #e3e3e3;
	border-radius: 3px;
	padding: 10px;
	background-color: #f4f4f4;

	position: relative;

	.icon-editar {
		margin-left: 10px;
	}
`

export const SectionPainel = styled.section`
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: ${({ padding }) => padding};

	header {
		width: 100%;
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		label {
			b {
				color: #717171;
			}
		}

		input[type='text'] {
			margin-left: 10px;
			border-radius: 5px;
			border: 1px solid #e3e3e3;
			padding: 4px;
		}
	}
`

export const BodySelect = styled.section`
	display: flex;
	flex-direction: column;

	header {
		background-color: #e4e4e4;
		padding: 0 10px;
		border-radius: 5px;
		margin-bottom: 0;
	}

	label {
		span {
			padding: 3px;
		}
	}

	.MuiTypography-root {
		color: #717171;
		font-weight: bold;
		font-size: 14px;
	}

	section {
		display: flex;
		flex-direction: column;
		padding: 0 10px;
		padding-right: 0;
		max-height: 250px;
		overflow-y: auto;
		margin-bottom: 40px;

		.MuiTypography-root {
			font-weight: initial;
		}

		> div {
			:nth-child(even) {
				background-color: #fbfbfb;
			}
		}

		label {
			border-radius: 5px;
			:nth-child(even) {
				background-color: #fbfbfb;
			}
		}

		span {
			div {
				display: flex;
				align-items: center;
			}
		}
	}
`

export const DividerLineHeight = styled.div`
	position: absolute;
	height: 90%;
	width: 1px;
	background-color: #cfcfcf;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`

export const IsUserBall = styled.div`
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: ${({ isUser }) => (isUser ? '#69c226' : '#0069c0')};
	margin-right: 10px;
`
