import { FindZipCode, GetCities, GetCity, GetCountries, GetStates } from '../../../../../../../../../../redux/api/location'

export const handleAddress = async (values, setValue, setList, index, zipCode = '', setError, t) => {
	const res = await FindZipCode(zipCode || values?.zipCode || '')

	if (!res.zipCode) {
		setError(`addresses[${index}].zipCode`, {
			type: 'manual',
			message: t('ZipCode - NOT_FOUND'),
		})
	}

	if (!res) return

	setValue(`addresses[${index}].address`, res?.location || values?.address || '')
	setValue(`addresses[${index}].district`, res?.district || values?.district || '')
	setValue(`addresses[${index}].stateId`, res.stateId || '')
	setValue(`addresses[${index}].cityId`, res?.cityId || '')
	setValue(`addresses[${index}].location`, res.location || values?.location || '')
	setValue(`addresses[${index}].cityId`, res.cityId || '')
	setValue(`addresses[${index}].stateId`, res.stateId || '')
	setValue(`addresses[${index}].country`, res.country || '')

	const countries = await GetCountries()
	if (countries?.length > 0) {
		setList(prevState => ({ ...prevState, countries }))
		setValue(`addresses[${index}].countryId`, countries.find(item => item.name === res.country))
	}

	const responseCity = await GetCities(res.stateId)
	if (responseCity?.length > 0) {
		setList(prevState => ({ ...prevState, cities: responseCity }))
		setValue(`addresses[${index}].cityId`, responseCity.find(item => item.id === res.cityId))
	}

	const responseState = await GetStates(res.countryId)
	if (responseState?.length > 0) {
		setList(prevState => ({ ...prevState, states: responseState }))

		setValue(`addresses[${index}].stateId`, responseState.find(item => item.id === res.stateId))
	}
}

export const handleCity = async (value, setValue, setList, index, setCircularProgress) => {
	setCircularProgress(true)
	try {
		const responseCity = await GetCity(value)
		if (responseCity?.length > 0) {
			setList(prevState => ({ ...prevState, cities: responseCity }))
			setValue(`addresses[${index}].cityId`, '')
			return responseCity
		}
	} catch (error) {
		console.log(error)
	} finally {
		setCircularProgress(false)
	}
}

export const ZipCodeNoMask = value => {
	if (value === '') return ''

	const newValue = value?.replace(/[^0-9]+/g, '')
	return newValue || ''
}

export const defaultCountry = {
	isActive: true,
	code: 'BR',
	currencies: [
		{
			name: 'Brazilian real',
			symbol: 'R$',
			iso: 'BRL',
			id: '1076e0d4-2c74-4f13-8cd5-d7bf3de51b1e',
		},
	],
	id: 'd7f63e7b-4ea8-46c3-86a6-9a3f2a1ebc4b',
	name: 'Brazil',
}

export const defaultState = {
	countryId: '',
	name: '',
	id: '',
}

export const defaultCity = {
	stateId: '',
	name: '',
	id: '',
}
