import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

import { Autocomplete, Grid } from '@mui/material'
import { CustomFormField } from 'components/CustomTextField'
import { Controller } from 'react-hook-form'
import CustomCheckMui from 'components/CustomCheckBoxMui'
import { handleReset } from '../Functions'

function AddressInherit({ control, parentDivision, Inherit, errorAddress, t, disabled, setValue }) {
	return (
		<Grid item xs={12} sm={12} md={12}>
			<S.InheritContainer>
				<Controller
					render={({ onChange, value }) => (
						<CustomCheckMui
							checked={value}
							disabled={disabled}
							onChange={e => {
								onChange(e.target.checked)
								handleReset({ setValue })
							}}
							label="Herdar endereço"
						/>
					)}
					name="Inherit"
					defaultValue={false}
					control={control}
				/>

				{Inherit && (
					<Controller
						render={({ onChange, value }) => (
							<Autocomplete
								size="small"
								fullWidth
								value={value}
								disableClearable
								options={parentDivision || []}
								onChange={(_e, value) => {
									handleReset({ setValue })
									onChange(value)
								}}
								getOptionLabel={option => t(option?.type) + ' - ' + t(option?.label)}
								renderInput={params => (
									<CustomFormField
										{...params}
										size="small"
										label="Selecione a herança*"
										variant="outlined"
										error={errorAddress?.InheritOption}
										helperText={errorAddress?.InheritOption && errorAddress?.cityId.message}
									/>
								)}
							/>
						)}
						rules={{ required: true }}
						name="InheritOption"
						control={control}
					/>
				)}
			</S.InheritContainer>
		</Grid>
	)
}

AddressInherit.propTypes = {
	t: PropTypes.func,
	control: PropTypes.any,
	Inherit: PropTypes.bool,
	setValue: PropTypes.func,
	disabled: PropTypes.bool,
	errorAddress: PropTypes.object,
	parentDivision: PropTypes.array,
}

export default AddressInherit
