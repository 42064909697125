import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Grid } from '@material-ui/core'
import { Stack } from '@mui/material'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import Select from '../../../../Select'
import { useTranslation } from 'react-i18next'
import { compareMarketingArea, formatDate, getCheckListSelectedOptions, getLabelByElementId, validateValue } from '../../../../../Functions'
import TagItem from '../../../../TagItem'
import { Checkbox } from '../../../..'
import TextInput from '../../../../TextInput'
import { formatCNPJ } from '../../../../../Functions'
import { getByElementId } from '../../../../../Functions/getLabelByElementId'
import moment from 'moment'
import { StateRegistrationValid } from '../../../../../../../../redux/api/partner'
import CustomDate from '../CustomDate'
import { CheckList } from '../../../../../../../../components/storybook'

export default function BranchInfos({
	isEditData,
	partnerInfos,
	divisionData,
	partnerData,
	customFieldsForm,
	updateBusinessDivisionData,
	updateCustomFieldsForm,
	parentDivision,
	typeDivision,
}) {
	const { t } = useTranslation()
	const { control, errors, watch, clearErrors, setError, setValue } = useFormContext()
	const [timerStarted, setTimerStarted] = useState(false)
	const [marketingArea, setMarketingArea] = useState(null)

	useEffect(() => {
		if (partnerInfos?.marketingArea && divisionData?.marketingArea) {
			setMarketingArea(compareMarketingArea(partnerInfos?.marketingArea, divisionData?.marketingArea))
		}
	}, [partnerInfos, divisionData])

	const validation = currentDate => {
		const today = moment()

		if (currentDate.isAfter(today)) {
			return false
		}

		return currentDate.isBefore(today)
	}

	const { ie, ieStateId } = watch()
	const RegistrationValid = () => {
		StateRegistrationValid(ie, ieStateId?.value).then(response => {
			if (response?.data?.errors?.length > 0) {
				setError('ie', {
					type: 'manual',
					message: 'Inscrição estadual inválida',
				})
				return
			}
			clearErrors('ie')
		})
	}

	useEffect(() => {
		let typingTimer

		if (ie) {
			if (!timerStarted) setTimerStarted(true)

			clearTimeout(typingTimer)
			typingTimer = setTimeout(() => {
				RegistrationValid()
			}, 1000)
		}

		return () => clearTimeout(typingTimer)
	}, [ie, ieStateId])

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem
							disabled
							required
							label="Divisão de negócio Pai"
							value={validateValue(getLabelByElementId(divisionData?.partnerId, parentDivision))}
						/>
					) : (
						<Fragment>
							<Labelitem label="Divisão de negócio Pai" />
							<Controller
								control={control}
								name="businessDivisionParent"
								defaultValue={getByElementId(divisionData?.partnerId, parentDivision)}
								render={({ onChange, value }) => (
									<Select
										name="businessDivisionParent"
										error={errors?.businessDivisionParent}
										helperText={t(errors?.businessDivisionParent?.message)}
										value={value}
										options={parentDivision ?? []}
										onChange={e => {
											onChange(e)
											updateCustomFieldsForm('businessDivisionParent', e)
										}}
									/>
								)}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="CNPJ" value={validateValue(divisionData?.cnpj)} />
					) : (
						<Fragment>
							<Labelitem label="CNPJ" />
							<Controller
								control={control}
								name="businessDivisionCNPJ"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessDivisionCNPJ}
										helperText={t(errors?.businessDivisionCNPJ?.message)}
										fullWidth
										id="businessDivisionCNPJ"
										name="businessDivisionCNPJ"
										value={value}
										disabled={false}
										onChange={e => {
											e.target.value = formatCNPJ(e.target.value)
											onChange(e)
										}}
										inputProps={{
											maxLength: 18,
										}}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={divisionData?.cnpj}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Identificador" value={validateValue(divisionData?.identifier)} />
					) : (
						<Fragment>
							<Labelitem label="Identificador" />
							<Controller
								control={control}
								name="businessDivisionID"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessDivisionID}
										helperText={t(errors?.businessDivisionID?.message)}
										fullWidth
										id="businessDivisionID"
										name="businessDivisionID"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={12}
										inputProps={{
											maxLength: 12,
										}}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={divisionData?.identifier}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem
							disabled
							required
							label={typeDivision === 'Franchise' ? 'Nome da franquia' : 'Nome da filial'}
							value={validateValue(divisionData?.name)}
						/>
					) : (
						<Fragment>
							<Labelitem label={typeDivision === 'Franchise' ? 'Nome da franquia' : 'Nome da filial'} />
							<Controller
								control={control}
								name="businessDivisionName"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessDivisionName}
										helperText={t(errors?.businessDivisionName?.message)}
										fullWidth
										id="businessDivisionName"
										name="businessDivisionName"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={55}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={divisionData?.name}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={6} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Descrição" value={validateValue(divisionData?.description)} />
					) : (
						<Fragment>
							<Labelitem label="Descrição" />
							<Controller
								control={control}
								name="businessDivisionDescription"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessDivisionDescription}
										helperText={t(errors?.businessDivisionDescription?.message)}
										fullWidth
										id="businessDivisionDescription"
										name="businessDivisionDescription"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={60}
										inputProps={{
											maxLength: 60,
										}}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={divisionData?.description}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={2} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Dt. de fundação" value={validateValue(formatDate(divisionData?.foundingDate))} />
					) : (
						<Fragment>
							<Labelitem label="Dt. de fundação" />
							<Controller
								control={control}
								name="businessDivisionFundationDate"
								render={({ onChange, value }) => (
									<CustomDate
										variant="outlined"
										size="small"
										value={value}
										onChange={e => onChange(e)}
										timeFormat={false}
										isValidDate={validation}
										disabled={false}
										maxDate={new Date().getTime()}
										error={errors.foundingDate}
										helperText={errors.foundingDate && errors.foundingDate.message}
									/>
								)}
								defaultValue={formatDate(divisionData?.foundingDate)}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={6} sm={12}>
					<Stack direction="row" justifyContent="flex-start" alignItems="center" alignContent="center" spacing={2}>
						<Box>
							<Fragment>
								<Controller
									control={control}
									name="isMEI"
									defaultValue={divisionData?.isMEI || customFieldsForm?.isMEI}
									render={({ onChange, value }) => (
										<Checkbox
											label="Empresa se enquadra como MEI"
											disabled={!isEditData}
											checked={customFieldsForm?.isMEI || divisionData?.isMEI}
											onChange={e => onChange(e.target.checked)}
											value={value}
										/>
									)}
								/>
							</Fragment>
						</Box>
						{isEditData && (
							<Box>
								<Controller
									control={control}
									name="isIEFree"
									defaultValue={divisionData?.isIEFree || customFieldsForm?.isIEFree}
									render={({ onChange, value }) => (
										<Checkbox
											label="Isento IE"
											disabled={!isEditData || watch('ie')}
											checked={customFieldsForm?.isIEFree || divisionData?.isIEFree}
											onChange={e => {
												if (e.target.checked) {
													setValue('ie', '')
													setValue('ieStateId', null)
													clearErrors()
												}
												setValue('isIEFree', e.target.checked)
												onChange(e.target.checked)
											}}
											value={value}
										/>
									)}
								/>
							</Box>
						)}
					</Stack>
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem
							label="UF da inscrição Estadual"
							disabled
							// value={validateValue(getLabelByElementId(partnerData?.ieStateId))}
							value={validateValue(getLabelByElementId(divisionData?.ieStateId, partnerInfos.addressUFList))}
							isEditData={isEditData}
						/>
					) : (
						<Fragment>
							<Labelitem label="UF da Inscrição Estadual" />
							<Controller
								control={control}
								name="ieStateId"
								defaultValue={getByElementId(divisionData?.ieStateId, partnerInfos.addressUFList) ?? ''}
								render={({ onChange, value }) => (
									<Select
										name="ieStateId"
										error={errors?.ieStateId}
										helperText={t(errors?.ieStateId?.message)}
										value={value}
										disabled={!isEditData || watch('isIEFree')}
										options={partnerInfos.addressUFList ?? []}
										onChange={e => {
											onChange(e)
											updateCustomFieldsForm('ieStateId', e)
										}}
									/>
								)}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="Inscrição Estadual" disabled value={validateValue(divisionData?.ie)} isEditData={isEditData} />
					) : (
						<Fragment>
							<Labelitem label="Inscrição Estadual" />
							<Controller
								control={control}
								name="ie"
								defaultValue={divisionData?.ie}
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.ie}
										helperText={t(errors?.ie?.message)}
										fullWidth
										id="ie"
										name="ie"
										value={value}
										onChange={e => {
											onChange(e)
											updateCustomFieldsForm('ie', e.target.value)
										}}
										disabled={!isEditData || watch('isIEFree')}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={12} sm={12}>
					{!isEditData ? (
						<Fragment>
							<Box mt={3} sx={{ color: isEditData ? '#333333' : '#8D8D8D', fontSize: '13px' }}>
								Segmento(s)
							</Box>
							<Grid container spacing={2}>
								{partnerData?.segmentations?.map((item, index) => (
									<Grid key={index} item lg={2} sm={3}>
										<TagItem key={index} label={item?.description} />
									</Grid>
								))}
							</Grid>
						</Fragment>
					) : (
						<Fragment>
							<CheckList
								addTextLabel="Adicionar todas"
								fieldName="divisionSegment"
								getSelectedItems={function noRefCheck(value) {
									updateBusinessDivisionData('divisionSegment', value)
								}}
								label="Selecione e adicione o(s) segmento(s)*"
								options={partnerInfos?.partnerSegmentations}
								removeTextLabel="Deletar todas"
								selectorLabel="Segmento(s)"
								defaultOptions={getCheckListSelectedOptions(divisionData?.segmentations)}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Área de marketing" value={validateValue(t(marketingArea?.label))} />
					) : (
						<Fragment>
							<Labelitem label="Área de marketing" />
							<Controller
								control={control}
								name="businessDivisionMarketingArea"
								render={({ onChange, value }) => (
									<Select
										name="businessDivisionMarketingArea"
										error={errors?.businessDivisionMarketingArea}
										helperText={t(errors?.businessDivisionMarketingArea?.message)}
										value={value}
										options={partnerInfos?.marketingArea ?? []}
										onChange={onChange}
									/>
								)}
								defaultValue={{
									label: marketingArea?.label,
									value: marketingArea?.label,
								}}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}

BranchInfos.propTypes = {
	isEditData: PropTypes.bool,
	partnerInfos: PropTypes.object,
	divisionData: PropTypes.object,
	partnerData: PropTypes.object,
	customFieldsForm: PropTypes.object,
	updateBusinessDivisionData: PropTypes.func,
	updateCustomFieldsForm: PropTypes.func,
	typeDivision: PropTypes.string,
	parentsList: PropTypes.array,
	parentDivision: PropTypes.array,
	selectedType: PropTypes.object,
}
