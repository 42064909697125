import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

//@ components
import { DefaultProductData } from './Functions/utils'
import DivisionTable from './components/DivisionTable'
import DivisionOptions from '../DivisionOptions'

const DivisionTab = props => {
	return (
		<Fragment>
			<DivisionOptions {...props} />
			<DivisionTable {...props} />
		</Fragment>
	)
}

DivisionTab.propTypes = {
	productData: PropTypes.object,
	setDivision: PropTypes.func,
	setModalCancel: PropTypes.func,
	parameterization: PropTypes.object,
	setMultipleParameterization: PropTypes.func,
	setParameterization: PropTypes.func,
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func,
	selectedDivisionIdGen: PropTypes.array,
	setSelectedDivisionIdGen: PropTypes.func,
	selectedDivision: PropTypes.array,
	setSelectedDivision: PropTypes.func,
	setMultiple: PropTypes.func,
	multiple: PropTypes.bool,
}

DivisionTab.defaultProps = {
	productData: DefaultProductData,
}

export default DivisionTab
