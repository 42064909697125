export default function getCheckListSelectedOptions(data) {
	const result = []

	if (data && data.length > 0) {
		data.forEach(item => {
			result.push(item?.description ?? item?.value)
		})
		return result ?? []
	}

	return []
}
