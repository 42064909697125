import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'
export const Status = styled.div`
	height: 100%;
	background-color: #333333;
	width: 3px;
`

export const IconsDiv = styled.div`
	font-size: 18px;
	padding-left: 15px;
`

export const SubTitle = styled.div`
	display: flex;
`

export const Indicator = styled.div`
	border: ${({ Color }) => `3px solid ${Color}`};
`

export const NavContainer = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	.Mui-selected {
		color: #fff !important;
		background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor} !important;
	}
`

export const NavItem = styled.div`
	gap: 21px;
	width: ${({ pageCount }) => (pageCount > 6 ? '100%' : 'auto')};
	display: flex;
	justify-content: flex-end;
`
export const SubtitleItem = ({ iconClassName, iconColor, text }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginRight: '10px',
			}}
		>
			<i className={iconClassName} style={{ color: iconColor, fontSize: '12px', marginRight: '10px' }} />
			<div
				style={{
					fontSize: '12px',
					marginTop: '3px',
				}}
			>
				{text}
			</div>
		</div>
	)
}

SubtitleItem.propTypes = {
	text: PropTypes.string,
	iconColor: PropTypes.string,
	iconClassName: PropTypes.string,
}
