import { TextField } from '@material-ui/core'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import { createTheme, withStyles, } from '@material-ui/core/styles'
import { getThemeColorStructureBackground } from '../utils'

export const Container = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor};
	}

	.react-tagsinput-tag a::before {
		font-family: "Font Awesome 5 Free";
		content: "";
		font-size: 0.8rem;
		font-weight: 700;
		padding: 0px !important;
	}

	.react-tagsinput .react-tagsinput-tag:hover a {
		padding: 0px;
		color: red;
	}

	.react-tagsinput-tag a {
		display: flex;
    	align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		border-radius: 60%;
		top: 5px;
		color: red;
		background:  #fff !important;
		height: 0.70rem;
    	width: 0.70rem;
		margin-right: 5px;
	}

	.react-tagsinput .react-tagsinput-tag.primary {
		background-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor} !important;
		//padding: 3px 22px !important;
	}

	.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
		background-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}
`
export const ProductTypeMessage = styled.p`
	color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor};
	font-weight: 400;
	font-size: 12px;
	margin-top: 5px;
`

export const theme = createTheme({
	overrides: {
		MuiInputLabel: {
			outlined: {
				zIndex: 0,
				transform: 'translate(14px, 20px) scale(1)',
				pointerEvents: 'none',
			},
		},
	},
	palette: {
		primary: {
			main: getThemeColorStructureBackground(),
		},
	},
	typography: {
		useNextVariants: true,
	},
})


export const CampaignStyle = {
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	// REQUIRED FIELD
	labelTexRF: {
		lineHeight: '0 !important',
		top: '-2px',
	},
	// sem required fielvd
	labelTexF: {
		lineHeight: '0 !important',
	},
	inputHeight: {
		height: '9px',
	},
}



export const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: getThemeColorStructureBackground(),
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: getThemeColorStructureBackground(),
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: getThemeColorStructureBackground(),
				},
				'&.Mui-focused fieldset': {
					borderColor: getThemeColorStructureBackground(),
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(0,0%,95%)',
			'& fieldset': {
				borderColor: 'hsl(0,0%,90%)',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense ': {
			paddingTop: '9.5px',
			paddingBottom: '9.5px',
		},
	},
})(TextField)


export const SelectQuantity = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 0.625rem;
`
export const CustomGrid = styled(Grid)`
    display: flex;
    margin-bottom: 25px;
    height: 40; 
    margin-top: -12.25rem;

	@media only screen and (max-width: 1279px) {
		margin-top: -6rem;
	}
`