import axios from "axios";

// GET
export const GetManagementDashboardAlerts = async () => {
  const response = await axios.get(`/Alert/GetDashboardAlerts/1`);
  return response.data;
};

// GET
export const GetManagementImportantAlertsId = async () => {
  const response = await axios.get(`/Alert/GetImportantAlertsId/1`);
  return response.data;
};

export const ReadAlert = async (id, readAllImportantAlerts) => {
  const response = await axios.get(`/Alert/ReadAlert/${id}?${readAllImportantAlerts ? `readAllImportantAlerts=${readAllImportantAlerts}` : ''}`);
  return response.data;
};

// GET
export const GetManagementAlerts = async (page = 1) => {
  const response = await axios.get(`Alert/GetAlertsByDestination/1?page=${page}`);
  return response.data;
};

// GET
export const GetAlerts = async (filter) => {
  const response = await axios.get(`/Alert?${filter}`);
  return response.data;
};

// POST
export const PostAlert = async (obj) => {
  const response = await axios.post(`/Alert`, { ...obj });
  return response.data;
};

// DELETE
export const DeleteAlert = async (id) => {
  const response = await axios.delete(`/Alert/${id}`);
  return response.data;
};

// SHOW
export const ShowAlert = async (id) => {
  const response = await axios.get(`/Alert/${id}`);
  return response.data;
};

// UPDATE
export const UpdateAlert = async (id, obj) => {
  const response = await axios.put(`/Alert/${id}`, {
    ...obj,
  });
  return response.data;
};

export const GetStatusAlert = async () => {
  const response = await axios.get(`/Alert/GetAlertStatusOptions`);
  return response.data;
};

export const GetTypesDestination = async () => {
  const response = await axios.get(`/Alert/GetDestinationTypes`);
  return response.data;
};

export const GetConnectionId = async () => {
  const response = await axios.get(`/Notification/GetNotifications`);
  return response.data;
};

export const ReadNotificationHub = async () => {
  const response = await axios.put(`/Notification/ReadNotifications?type=1`);
  return response.data;
};

export const userPreference = async (viewImportantAlerts) => {
  const response = await axios.put(`/UserPreference?viewImportantAlerts=${viewImportantAlerts}`);
  return response.data;
};