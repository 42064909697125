import React from 'react'
import * as _ from 'lodash'
import { withTranslation } from 'react-i18next'
import DocumentTitle from 'react-document-title'

//MTERIAL UI
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import Select from 'react-select'

//Components
import Section from 'components/Section'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import CustomNavPills from 'components/CustomNavPills/CustomNavPills'
import TextField from '@material-ui/core/TextField'

import Permission from './Permission'
import UserAssign from './UserAssign'
import ViewPermissions from './ViewPermissions'
import styles from '../groups.module.scss'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

//UTILS
import compose from 'utils/compose'

import styled from 'styled-components/macro'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'
import { getProgramTitle } from '../../../../utils/utils'

const Container = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${props =>
		props && props.themeColor && props.themeColor.titles && props.themeColor.titles.highlight && props.themeColor.titles.highlight.textColor};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}
`

const GroupsStyle = {
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	labelTexRF: {
		lineHeight: '0 !important',
		top: '-2px',
	},
	labelTexRFForView: {
		top: '-6px',
	},
	inputHeight: {
		height: '9px',
	},
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'&.Mui-focused fieldset': {
					borderColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(0,0%,95%)',
			'& fieldset': {
				borderColor: 'hsl(0,0%,90%)',
			},
		},
	},
})(TextField)

class Form extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			themeColor: getThemeColorFromLocalStorage(),
		}
	}
	render() {
		const {
			t,
			classes,
			values,
			errors,
			touched,
			editMode,
			handleSubmit,
			handleChange,
			handleCancel,
			handleEdit,
			setFieldValue,
			AlertErrorClaims,
			setFieldTouched,
		} = this.props

		const customSelectStyles = {
			control: (base, state) => ({
				...base,
				boxShadow: 0,
				minHeight: '40px !important',
				borderWidth: state.isFocused ? '2px' : base.borderWidth,
				borderColor:
					touched.status && Boolean(errors.status)
						? '#F05B4F !important'
						: state.isFocused
							? getThemeColorFromLocalStorage() &&
								getThemeColorFromLocalStorage().structure &&
								getThemeColorFromLocalStorage().structure.backgroundColor
								? getThemeColorFromLocalStorage().structure.backgroundColor
								: '#fff !important'
							: base.borderColor,
				'&:hover': {
					borderColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
			}),
			placeholder: (base, state) => ({
				...base,
				color: touched.status && Boolean(errors.status) ? '#F05B4F !important' : base.color,
			}),
		}

		const StatusOptions = [
			{ value: '', label: t('Status'), isDisabled: true },
			{ value: 'true', label: t('ACTIVE') },
			{ value: 'false', label: t('INACTIVE') },
		]

		const submit = () => {
			handleSubmit(values)
			if (touched.selectedClaims && Boolean(errors.selectedClaims)) {
				AlertErrorClaims()
			}
		}
		const change = (name, e) => {
			e.persist()
			handleChange(e)
			setFieldTouched(name, true, false)
		}

		const changeSelect = (name, e) => {
			setFieldValue(name, e.value, true)
			setFieldTouched(name, true, false)
		}

		const getSelectItemValue = (list, i) => {
			let value = _.find(list, x => x.value === i.toString())
			return value
		}

		return (
			<Container themeColor={this.state.themeColor}>
				<DocumentTitle title={getProgramTitle()} />
				<GridContainer>
					<GridContainer style={{ marginLeft: 0, display: 'flex' }}>
						<GridItem xs={12} sm={12}>
							<div
								style={{
									alignItems: 'center',
									display: 'flex',
									height: '40px',
									marginBottom: '17px',
								}}
							>
								<Section title={t('TITLE_GROUP_DATA')} style={{ flexGrow: 1 }} />
								<div style={{ marginLeft: '15px' }}>
									<Button size="sm" style={{ marginRight: '0.688rem' }} variant="contained" color="greenBorderButtonColor" onClick={handleCancel}>
										{t('BUTTON_BACK')}
									</Button>{' '}
									{editMode ? (
										<Button size="sm" variant="contained" color="greenButtonColor" onClick={submit}>
											{t('BUTTON_SAVE')}
										</Button>
									) : (
										<Button size="sm" variant="contained" color="greenButtonColor" onClick={handleEdit}>
											{t('BUTTON_EDIT')}
										</Button>
									)}
								</div>
							</div>
						</GridItem>
						<GridItem xs={12} sm={6} md={6}>
							<CssTextField
								error={touched.name && Boolean(errors.name)}
								disabled={!editMode}
								className={styles.TextField}
								fullWidth
								label={<CustomRequiredField value="FIELD_ACCESS_GROUP_NAME" disabled={!editMode} />}
								id="name"
								name="name"
								value={values.name}
								onChange={change.bind(null, 'name')}
								variant="outlined"
								margin="dense"
								InputLabelProps={{
									classes: {
										disabled: values.id !== '' ? classes.labelTexRF : '',
									},
								}}
								style={{ backgroundColor: '#fff' }}
							/>
						</GridItem>
						<GridItem xs={12} sm={6} md={6} style={{ marginTop: '8px' }}>
							<Select
								isDisabled={!editMode}
								placeholder="Status"
								styles={customSelectStyles}
								loadingMessage={() => t('PLACEHOLDER_SEARCHING') + '...'}
								noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
								value={getSelectItemValue(StatusOptions, values.status)}
								options={StatusOptions}
								onChange={changeSelect.bind(null, 'status')}
								theme={theme => ({
									...theme,
									colors: {
										...theme.colors,
										primary:
											this.state && this.state.themeColor && this.state.themeColor.structure && this.state.themeColor.structure.backgroundColor,
									},
								})}
							/>
						</GridItem>
						<GridItem xs={12} sm={12} md={12} style={{ marginTop: '20px' }}>
							<CssTextField
								error={touched.description && Boolean(errors.description)}
								disabled={!editMode}
								fullWidth
								label={<CustomRequiredField value="FIELD_DESCRIPTION" disabled={!editMode} />}
								id="description"
								name="description"
								value={values.description}
								onChange={change.bind(null, 'description')}
								variant="outlined"
								margin="dense"
								InputLabelProps={{
									classes: {
										disabled: values.id !== '' ? classes.labelTexRF : '',
									},
								}}
								style={{ backgroundColor: '#fff' }}
							/>
						</GridItem>
					</GridContainer>
					<GridContainer
						style={{
							marginTop: '20px',
							marginLeft: '0',
							paddingLeft: '0',
						}}
					>
						<GridItem xs={12}>
							{editMode ? (
								<CustomNavPills
									color="germiniMainColor"
									handleChangeNavPill={() => { }}
									tabs={[
										{
											label: t('TAB_ACCESS_ASSOCIATE_PERMISSIONS'),
											tabIcon: 'icon-permissoes',
											tabContent: <Permission {...this.props} />,
										},
										{
											label: t('TAB_ACCESS_ASSOCIATE_USERS'),
											tabIcon: 'icon-usuarios',
											tabContent: <UserAssign {...this.props} />,
										},
									]}
								/>
							) : (
								<ViewPermissions {...this.props} />
							)}
						</GridItem>
					</GridContainer>
				</GridContainer>
			</Container>
		)
	}
}
export default compose(
	withRouter,
	withStyles(GroupsStyle),
	withTranslation(),
)(Form)
