import styled from 'styled-components'
import { getThemeColorFromLocalStorage, ColorLuminance } from 'redux/api/themeColors'

export const Container = styled.div``

export const SnackContainer = styled.div``

export const CustomCard = styled.form`
	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
	}
`
export const History = styled.div`
	display: flex;
	width: 100%;
	justify-content: end;
	.icon-menu_relatorios {
	}
	a {
		color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
		transform: translateY(30px);
		font-size: 24px;
		:hover {
			color: ${ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5)};
		}
	}
`
