export const defaultDivisionsErrors = [
	{
		criticalErrors: [],
		id: 3,
		name: '',
		parentErrors: [],
		warnings: [],
	},
]

export const defaultError = {
	open: true,
	text: 'Erro ao realizar a operação',
	severity: 'error',
}

export const defaultSnack = {
	open: false,
	severity: '',
	text: '',
}
