import Cookies from 'js-cookie'
import { PartnerService } from '../../Services'

export default async function getParentList() {
	const partnerId = String(Cookies.get('partnerId'))
	const partnerService = new PartnerService()
	const response = await partnerService.getPartnerParents(partnerId)
	const result = []

	response.forEach(item => {
		result.push({
			label: item?.value,
			value: item?.key,
		})
	})

	return result ?? []
}

export const getParentListForSelect = list => {
	let result = []

	list.forEach(item => {
		result.push({
			value: item?.id,
			label: item?.name,
			type: item?.segmentationType?.value,
			address: item?.addresses?.length > 0 ? item?.addresses[0] : item?.address || {},
		})
	})

	return result ?? []
}
