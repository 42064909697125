import _ from 'lodash'
import { convertAddressToId, convertDefaultValueId, convertSegmentsToId } from '../Functions'

export default function partnerDataUpdate({ data, partnerId, partnerData, customFieldsForm, segmentsList, originsList }) {
	return {
		// ...partnerData,
		id: partnerId,
		cnpj: data?.businessCNPJ ?? partnerData?.cnpj,
		corporateName: data?.businessName ?? partnerData?.corporateName,
		foundationDate: data?.businessFoundationDate ?? partnerData?.foundationDate,
		identifier: data?.businessID ?? partnerData?.identifier,
		isMEI: customFieldsForm?.isMEI ?? partnerData?.isMEI ?? false,
		ieStateId: data?.businessUF?.value ?? partnerData?.ieStateId,
		ie: data?.ie ?? partnerData?.ie,
		isIEFree: customFieldsForm?.isIEFree ?? partnerData?.isIEFree ?? false,
		pictureUrl: partnerData?.pictureUrl,
		site: data?.businessWebsite || partnerData?.site || 'https://',
		email: data?.businessEmail ?? partnerData?.email,
		phoneNumber: data?.businessPhone ?? partnerData?.phonenumber,
		cellphoneNumber: data?.businessCellPhone ?? partnerData?.cellphoneNumber,
		segments: convertSegmentsToId(customFieldsForm?.partnerSegments ?? partnerData?.segmentations, segmentsList),
		origins: convertSegmentsToId(customFieldsForm?.partnerEvents ?? partnerData?.origins, originsList),
		addresses: convertAddressToId(data?.addresses) ?? partnerData?.addresses,
		marketingArea: data?.businessMarketingArea?.value ?? partnerData?.marketingArea,
		partnerAdmin: {
			name: data?.responsibleName ?? partnerData?.partnerAdmin?.name,
			email: data?.responsibleEmail ?? partnerData?.partnerAdmin?.email,
			cpf: data?.responsibleDocument ?? partnerData?.partnerAdmin?.cpf,
			phoneNumber: data?.responsibleCellphone ?? partnerData?.partnerAdmin?.phoneNumber,
		},
		useDefaultConvertionRate: customFieldsForm?.useDefaultConvertion ?? partnerData?.useDefaultConvertionRate,
		conversionRate: data?.businessConversionRate?.value ?? partnerData?.conversionRate,
		expirationDays: data?.catalogBehavior?.value || partnerData?.expirationDays || 'THIRTY',
		activeAdditionalModules: data?.additionalModules?.length > 0 ? true : false,
		additionalModules: !_.isEmpty(data?.additionalModules) ? convertDefaultValueId(data?.additionalModules) : partnerData?.additionalModules,
		benefitGrouper: data?.benefitGroup?.value ?? partnerData?.benefitGrouper,
	}
}
