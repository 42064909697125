import React, { useEffect, useState } from 'react'
import _ from 'lodash'

// @ Styles
import * as S from './styles'

//@ components
import Section from 'components/Section'
import Parametrization from './Parametrization'
import ParameterizeDivisionGen from './Parametrization/components/Generation/components/DivisionTab/components/ParameterizeDivision'
import ParameterizeDivisionCash from './Parametrization/components/Cashback/components/DivisionTab/components/ParameterizeDivision'
import ParameterizeDivisionRes from './Parametrization/components/Rescue/components/DivisionTab/components/ParameterizeDivision'

import { Box } from '@mui/material'
import { GetDigitalWalletRules, GetGeneralRulesData } from 'redux/api/generalRules'
import { GetCashbackRules } from 'redux/api/partner'
import { useTranslation } from 'react-i18next'
import { GetGrouperByDetails } from '../../../../redux/api/productPage'
import { useHistory, useParams } from 'react-router-dom'
import Cookies from 'js-cookie'
import { getTitle } from './Functions'
import Loader from 'components/Loader/Loader.jsx'
import { GetORedeem } from './Parametrization/components/Rescue/components/DivisionTab/components/ParameterizeDivision/ParameterizeDivisionTB/Functions'

const ProductDetails = () => {
	const [division, setDivision] = useState(null)
	const [multiple, setMultiple] = useState(false)
	const [productData, setProductData] = useState(null)
	const [programRules, setProgramRules] = useState(null)
	const [GenerationType, setGenerationType] = useState([])
	const Grouper = Cookies.get('Grouper')
	// eslint-disable-next-line no-unused-vars
	const [reloadProduct, setReloadProduct] = useState(false)
	const [selectedDivision, setSelectedDivision] = useState([])
	const [selectedDivisionIdGen, setSelectedDivisionIdGen] = useState([])
	const [selectedDivisionIdRed, setSelectedDivisionIdRed] = useState([])
	const [selectedDivisionIdCash, setSelectedDivisionIdCash] = useState([])
	const [multipleParameterization, setMultipleParameterization] = useState([])
	const [rules, setRules] = useState({})
	const [typeOfBenefit, setTypeOfBenefit] = useState({})
	const partnerId = Cookies.get('partnerId')
	const [loading, setLoading] = useState(false)
	const [circularProgress, setCircularProgress] = useState(false)
	const history = useHistory()
	const categoryId = useParams()
	const [grouper, setGrouper] = useState('')
	const [editMode, setEditMode] = useState(false)
	const { t } = useTranslation()

	const [parameterization, setParameterization] = useState({
		show: false,
		isMultiple: false,
		type: '',
	})

	const getDigitalWalletRules = async () => {
		const [digitalWalletRules, cashbackRules] = await Promise.all([GetDigitalWalletRules(), GetCashbackRules()])

		if (!_.isEmpty(digitalWalletRules)) {
			setRules(prev => {
				return { ...prev, digitalWalletRules }
			})
		}

		if (!_.isEmpty(cashbackRules)) {
			setRules(prev => {
				return { ...prev, cashbackRules }
			})
		}
	}

	const fetchData = async () => {
		setLoading(true)
		try {
			const data = await GetGrouperByDetails(categoryId?.id || '')

			setProductData(data?.data)
		} catch (error) {
			console.error('Erro ao buscar dados de status:', error)
		} finally {
			setLoading(false)
		}
	}

	// pegar agrupador
	const GetGroupers = () => {
		setLoading(true)
		try {
			const data = localStorage.getItem('Grouper')

			!_.isEmpty(data) && setGrouper(data)
		} catch (error) {
			console.error('Erro ao buscar dados do agrupador:', error)
		}
	}

	useEffect(() => {
		if (partnerId) {
			fetchData()
		}
	}, [partnerId])

	const handleGoBack = () => {
		history.goBack()
	}

	useEffect(() => {
		GetGeneralRulesData().then(res => {
			!_.isEmpty(res) && setProgramRules(res)
		})
		GetORedeem({ setGenerationType, setCircularProgress })
		getDigitalWalletRules()
		GetGroupers()
	}, [])

	useEffect(() => {
		if (_.isEmpty(Grouper)) {
			history.push(`/home`)
		}
	}, [Grouper])

	const sharedProps = {
		rules,
		grouper,
		multiple,
		division,
		editMode,
		setEditMode,
		productData,
		fetchData,
		setMultiple,
		loading,
		setLoading,
		programRules,
		GenerationType,
		setProductData,
		setProgramRules,
		typeOfBenefit,
		setTypeOfBenefit,
		parameterization,
		circularProgress,
		setSelectedDivisionIdRed,
		setDivision: setDivision,
		selectedDivision: selectedDivision,
		setReloadProduct: setReloadProduct,
		setSelectedDivision: setSelectedDivision,
		setParameterization: setParameterization,
		selectedDivisionIdGen: selectedDivisionIdGen,
		selectedDivisionIdRed: selectedDivisionIdRed,
		selectedDivisionIdCash: selectedDivisionIdCash,
		setSelectedDivisionIdGen: setSelectedDivisionIdGen,
		multipleParameterization: multipleParameterization,
		setSelectedDivisionIdCash: setSelectedDivisionIdCash,
		setMultipleParameterization: setMultipleParameterization,
	}

	return (
		<S.Container>
			{loading && <Loader />}
			<Box display={!parameterization.show ? 'block' : 'none'}>
				<Section title={getTitle(t)} />
				<S.History>
					<S.Titles>
						<b>{productData?.name}</b>
					</S.Titles>
					<S.Buttons onClick={handleGoBack}>
						<i className="icon-bot_voltar" />
					</S.Buttons>
				</S.History>
				<Parametrization {...sharedProps} />
			</Box>

			{parameterization?.type === 'cashback' && parameterization.show && <ParameterizeDivisionCash {...sharedProps} />}

			{parameterization?.type === 'redeem' && parameterization.show && <ParameterizeDivisionRes {...sharedProps} />}

			{parameterization?.type === 'points' && parameterization.show && <ParameterizeDivisionGen {...sharedProps} />}
		</S.Container>
	)
}

export default ProductDetails
