/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import Cards from './components/cards'
import Configuration from './components/Configuration'
import { Controller, useForm } from 'react-hook-form'
import { Autocomplete, CircularProgress, FormLabel, Grid, Tooltip } from '@mui/material'
import { GetORedeem, ParameterizationProduct, handleCalculateValue, handleProductDataUpdate } from './Functions'
import { CssTextField } from '../Filter/styles'
import { DefaultProductData } from './Functions/utils'
import CalcMemory from '../CalculationMemory'
import CustomModal from '../../../Modal'
import SnackDefault from 'components/SnackDefault'
import Loader from 'components/Loader/Loader.jsx'

const GeneralTab = ({ productData, setEditMode, editMode, typeOfBenefitRedeem, setOpenModalInfo, grouper, setModalCancel }) => {
	const { t } = useTranslation()
	const [open, setOpen] = React.useState({
		active: false,
		row: [],
	})
	const [loading, setLoading] = useState(false)
	const [GenerationType, setGenerationType] = useState([])
	const [circularProgress, setCircularProgress] = useState(false)
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const { control, watch, reset } = useForm({
		defaultValues: {
			rescue: {
				key: '0',
				value: 'Não parametrizado',
			},
			redemptionPoints: 0,
		},
	})

	const { rescue, redemptionPoints } = watch()

	const [parameterizationRedemption, setParameterizationRedemptionPoints] = useState({})

	const fetchCalculation = async (pointsValueWatch, type) => {
		const parameterizationRedemptionPoints = await handleCalculateValue({
			price: productData?.price || 0,
			typeOfRedeem: rescue?.key || type?.key,
			productId: productData?.id,
			type: productData?.typeOfPoints?.key,
			pointsValue: pointsValueWatch || productData?.redemptionPoints,
		})
		if (!_.isEmpty(parameterizationRedemptionPoints)) {
			setParameterizationRedemptionPoints(parameterizationRedemptionPoints)
		}
	}

	const fetchGenerationType = () => {
		GetORedeem({ setGenerationType, setCircularProgress })
	}

	useEffect(() => fetchGenerationType(), [])

	useEffect(() => {
		handleProductDataUpdate({ productData, setParameterizationRedemptionPoints, reset })
	}, [productData])

	useEffect(() => {
		if (rescue?.key) {
			fetchCalculation('', rescue)
		}
	}, [rescue])

	return (
		<Fragment>
			{loading && <Loader />}
			<CalcMemory
				open={open.active}
				onClose={() =>
					setOpen({
						active: false,
						row: [],
					})
				}
				rows={open.row}
				parameterization={productData}
			/>

			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					ParameterizationProduct(productData, rescue, redemptionPoints, setSnackStatus, typeOfBenefitRedeem, setLoading, grouper)
					setEditMode(false)
					setModalParameterization({ open: false })
				}}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<S.Container>
				{editMode === false ? (
					<S.Content>
						<Tooltip title={t('Editar')}>
							<Buttons className="softBlueAction" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
						</Tooltip>
					</S.Content>
				) : (
					<S.Content>
						<Buttons
							className="cancel"
							Icon="icon-bot_fechar"
							onClick={() => {
								setModalCancel({
									open: true,
									title: 'As alterações realizadas serão perdidas.',
									subtitle: 'Deseja realmente continuar?',
								})
							}}
						/>
						<Buttons
							className="save"
							Icon="icon-bot_salvar"
							onClick={() =>
								setModalParameterization({
									open: true,
									title: 'Deseja realmente alterar a parametrização',
									subtitle: 'desse benefício?',
								})
							}
						/>
					</S.Content>
				)}
			</S.Container>
			<S.CardsContent>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={3}>
						<FormLabel>
							<span className="FormLabel">{t('Configuração do benefício*')}</span>
							<Controller
								name="rescue"
								control={control}
								render={({ value, onChange }) => (
									<Autocomplete
										id="combo-box-demo"
										options={GenerationType}
										getOptionLabel={option => (option.value ? t(option.value) : '')}
										disabled={!editMode || circularProgress}
										value={value}
										fullWidth
										onChange={(_event, newValue) => onChange(newValue)}
										renderInput={params => (
											<CssTextField
												{...params}
												InputProps={{
													...params.InputProps,
													endAdornment: (
														<React.Fragment>
															{circularProgress && <CircularProgress color="inherit" size={20} />}
															{params.InputProps.endAdornment}
														</React.Fragment>
													),
												}}
												variant="outlined"
												size="small"
												disabled={!editMode}
											/>
										)}
									/>
								)}
							/>
						</FormLabel>
					</Grid>
					{/* <Grid item xs={12} sm={9}>
						{rescue?.key !== '0' && rescue?.key !== '1' && (
							<Cards
								setOpen={setOpen}
								productData={productData}
								typeOfRedeem={rescue?.key}
								fetchCalculation={fetchCalculation}
								parameterizationRedemption={parameterizationRedemption}
							/>
						)}
					</Grid> */}
				</Grid>

				<Grid item xs={12} sm={12}>
					{rescue?.key === '3' && (
						<Configuration
							control={control}
							setOpen={setOpen}
							editMode={editMode}
							typeOfRedeem={rescue?.key}
							fetchCalculation={fetchCalculation}
							parameterizationRedemption={parameterizationRedemption}
						/>
					)}
				</Grid>
			</S.CardsContent>
		</Fragment>
	)
}

GeneralTab.propTypes = {
	grouper: PropTypes.object,
	editMode: PropTypes.bool,
	setModalCancel: PropTypes.func,
	setEditMode: PropTypes.func,
	productData: PropTypes.object,
	setOpenModalInfo: PropTypes.func,
	typeOfBenefitRedeem: PropTypes.string,
}

GeneralTab.defaultProps = {
	productData: DefaultProductData,
}

export default GeneralTab
