import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	width: 100%;
	opacity: ${({ typeOfCashback }) => (typeOfCashback !== '1' ? 0.5 : 1)};
	justify-content: flex-end;

	.CardBody {
		background: #f2f2f2 0% 0% no-repeat padding-box;
		border-radius: 4px;
		opacity: 1;
	}

	.FormLabel {
		text-align: left;
		font: normal normal normal 13px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
	}
`
