import React from 'react'

import { LabelOptions } from '../../../utils'
import { CustomButton } from '../styles'
import { Tooltip } from '@mui/material'

export const ColumnsGroupers = (t, setModalParameterization, tabIndex) => [
	{
		title: LabelOptions({ tabIndex, t, multiple: true }),
		field: 'name',
		width: '100%',
	},
	{
		title: 'Ação',
		field: 'actions',
		width: '2%',
		cellStyle: {
			width: '2%',
			maxWidth: '2%',
			minWidth: '2%',
			paddingTop: '3px',
			paddingBottom: '3px',
		},
		headerStyle: {
			width: '2%',
			maxWidth: '2%',
			minWidth: '2%',
		},

		sorting: false,
		// eslint-disable-next-line react/display-name
		render: rowData => (
			<Tooltip arrow placement="top" title={t('BUTTON_EXCLUDE')} disableHoverListener={rowData.hasRelatedProduct}>
				<CustomButton
					bg="#F2F2F2"
					color="#F05B4F"
					border="#F2F2F2"
					hoverBg="#F05B4F"
					disabled={rowData.hasRelatedProduct}
					onClick={() =>
						setModalParameterization({
							open: true,
							title: `Deseja realmente excluir essa ${labelOptions({ tabIndex, t })}?`,
							rowData,
						})
					}
				>
					<i className="icon-ico_excluir" />
				</CustomButton>
			</Tooltip>
		),
	},
]

function labelOptions({ tabIndex, t }) {
	const Option = LabelOptions({ tabIndex, t }) || 'excluir'
	return Option.charAt(0).toLowerCase() + Option.slice(1)
}

export const defaultOptions = {
	pageSize: 10,
	pageSizeOptions: [10, 20, 30, 50, 100],
	search: false,
	toolBar: false,
	emptyRowsWhenPaging: false,
	pagination: true,
}

export const defaultFetchData = {
	data: [],
	page: 0,
	totalCount: 0,
	pageSize: 10,
}
