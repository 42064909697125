import { convertSegmentsToId } from '../Functions'

import _ from 'lodash'

export default function businessDivisionDataUdateFormat({
	data,
	divisionData,
	segments,
	segmentsList,
	partnerId,
	selectedDivisionType,
	customFieldsForm,
}) {
	console.log('data', data)
	const formData = {
		//...divisionData,
		id: divisionData.id,
		name: data?.businessDivisionName || null,
		cnpj: data?.businessDivisionCNPJ ?? divisionData.cnpj,
		parentId: data?.businessDivisionParent?.value ?? divisionData.parentId ?? partnerId,
		defaultSegmentationType: selectedDivisionType ?? 'Simple',

		identifier: data?.businessDivisionID || data.businessDivisionId || divisionData.identifier || null,
		description: data?.businessDivisionDescription ?? divisionData.description,

		segments: segments && selectedDivisionType !== 'CostCenter' ? convertSegmentsToId(segments, segmentsList) : null,

		marketingArea: data?.businessDivisionMarketingArea?.value ?? divisionData?.marketingArea,
		address: !_.isEmpty(data?.businessDivisionCEP)
			? {
				location: data?.businessDivisionAddress || null,
				district: data?.businessDivisionDistrict || null,
				number: data?.businessDivisionAddressNumber || null,
				aditionalInfo: data?.businessDivisionAditionalInfo || null,
				zipCode: data?.businessDivisionCEP || null,
				stateId: data?.businessDivisionUF?.value ?? divisionData?.stateId,
				cityId: data?.businessDivisionCity?.value ?? divisionData?.cityId,
				countryId: data?.businessDivisionCountry?.value ?? divisionData?.countryId,
			}
			: null,
		contact: !_.isEmpty(data?.businessDivisionResponsibleCPF)
			? {
				name: data?.businessDivisionResponsibleName ?? divisionData?.name,
				cpf: data?.businessDivisionResponsibleCPF ?? divisionData?.name,
				cellphone: data?.businessDivisionResponsibleCellphone ?? divisionData?.cellphone,
				skype: divisionData?.skype || null,
				email: data?.businessDivisionResponsibleEmail ?? divisionData?.email,
			}
			: null,
	}

	if (selectedDivisionType === 'Branch' || selectedDivisionType === 'Franchise') {
		return {
			...formData,
			parentId: partnerId,
			cnpj: data?.businessDivisionCNPJ ?? null,
			foundingDate: data?.businessDivisionFundationDate ? FormaterData(data?.businessDivisionFundationDate) : null,
			isMEI: data?.isMEI || customFieldsForm?.isMEI || null,
			isIEFree: data?.isIEFree || customFieldsForm?.isIEFree || null,
			ieStateId: data?.ieStateId?.value || data?.ieStateId || '',
			ie: data?.ie ?? null,
		}
	}

	return formData
}

export const FormaterData = data => {
	const regexDate = /^\d{2}\/\d{2}\/\d{4}$/ // Expressão regular para o formato 'dd/mm/yyyy'

	if (!regexDate.test(data)) {
		return data // Se não estiver no formato esperado, retorna a data original
	}

	const [dia, mes, ano] = data.split('/').map(Number)
	const dataFormatada = `${ano}-${mes.toString().padStart(2, '0')}-${dia.toString().padStart(2, '0')}T00:00:00`

	return dataFormatada
}
