import styled from "styled-components";
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'


export const Container = styled.div`
	.search-container-form {
		@media only screen and (max-width: 600px) {
			padding-left: 0px !important;
		}
	}

`


export const SearchBox = styled(GridContainer)`
	padding: 20px 0px;
    background: rgb(246, 246, 246);
    margin: auto auto 27px !important;
    border-radius: 5px;
    width: 100% !important;

	@media only screen and (max-width: 600px) {
		padding: 20px 15px;
		gap: 20px;
	}
`


export const GroupButtons = styled(GridItem)`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	gap: 15px;
`
