import * as API from '../api/sponsor';
import * as actionTypes from './actionsTypes';

export const fetchSponsorFailed = (error) => {
    return {
        type: actionTypes.FETCH_SPONSOR_FAILED,
        sponsorFailed: error
    }
}

export const postSponsorsFailed = (error) => {
    return {
        type: actionTypes.POST_SPONSORS_FAILED,
        postSponsorsFailed: error
    }
}

// LIST
export const GetSponsorsData = (name, partnerId, pageSize, page) => {
    return dispatch => (
        API.GetSponsors(name, partnerId, pageSize, page).then(response => {
            dispatch(success(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchSponsorFailed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_SPONSORS_DATA,
            lsSponsorsData: data
        };
    }; 
};

export const ListSponsorByCompanyName = (companyName, pageSize) => {
    return dispatch => (
        API.GetSponsorList(companyName, pageSize).then(response => {
            dispatch(success(response));
            return response;
        }).catch(error => {
            console.error(error);
            dispatch(fetchSponsorFailed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_SPONSOR_LIST_DATA,
            selectSponsors: data
        };
    }; 
};

// GET
export const GetSponsorIdData = (sponsorId) => {
    return dispatch => (
        API.GetSponsorById(sponsorId).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchSponsorFailed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.FETCH_SPONSOR_ID_DATA,
            sponsorData: data
        };
    }; 
};

// GET
export const PostSponsorsData = (obj) => {
    return dispatch => (
        API.UpSertSponsors(obj).then(response => {
            dispatch(success(response));
        }).catch(error => {
            console.error(error);
            dispatch(postSponsorsFailed(error));
        })
    );

    function success(data) {
        return {
            type: actionTypes.POST_SPONSORS_DATA,
            postSponsorsData: data
        };
    }; 
};