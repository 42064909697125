import { PartnerService } from '../../Services'

export default async function getAddressUFList(countryList) {
	const partnerService = new PartnerService()
	const response = await partnerService.getPartnerUF(countryList)
	const result = []

	response.forEach(item => {
		result.push({
			label: item?.name,
			value: item?.id,
		})
	})

	return result ?? []
}
