import React from 'react'
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'
import Login from './components/Login'
import ResetPassword from 'pages/User/ResetPassword/ResetPassword.jsx'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

// core components
import authStyle from 'assets/jss/material-dashboard-pro-react/layouts/authStyle.jsx'

// Utils
import compose from 'utils/compose'
import { CVALE, getThemeColorFromRequest } from '../../redux/api/themeColors'
import { envs } from '../../redux/api/windowServerData'

const getImageTheme = themeColor => {
	if (themeColor) {
		if (themeColor.images) {
			if (themeColor.images.loginPage) {
				if (themeColor.images.loginPage.background) {
					return themeColor.images.loginPage.background.url
				}
			}
		}
	}
	return ''
}

class Auth extends React.Component {
	state = {
		themeColor: {},
	}

	async getTheme() {
		const theme = await getThemeColorFromRequest()
		this.setState({
			themeColor: envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : theme?.data,
		})
	}

	componentDidMount() {
		this.getTheme()
	}

	render() {
		const { classes } = this.props
		return (
			<div className={classes.wrapper} ref="wrapper">
				<div
					className={classes.fullPage}
					style={{
						backgroundImage: 'url(' + getImageTheme(this.state.themeColor) + ')',
						height: '100vh',
					}}
				>
					<Route path="/auth/resetPassword" component={ResetPassword} />
					<Route path="/auth/login" component={Login} />
				</div>
			</div>
		)
	}
}

Auth.propTypes = {
	classes: PropTypes.object.isRequired,
}

export default compose(withStyles(authStyle))(Auth)
