import { CreateBrand, DeleteBrand, GetAllBrands } from 'redux/api/brand'
import { CreateFamily, DeleteFamily, GetFamilies } from 'redux/api/family'
import { CreateCategory, DeleteCategory, GetCategories } from 'redux/api/category'
import { CreateManufacturer, DeleteManufacturer, GetManufacturers } from 'redux/api/manufacturer'
import { defaultFetchData } from '../utils'

export const Delete = async ({ id, tabIndex }) => {
	if (!id) return null
	switch (tabIndex) {
		case 0:
			return await DeleteCategory(id)
		case 1:
			return await DeleteFamily(id)
		case 2:
			return await DeleteManufacturer(id)
		case 3:
			return await DeleteBrand(id)
		default:
			return null
	}
}

export const Create = async ({ data, tabIndex }) => {
	switch (tabIndex) {
		case 0:
			return await CreateCategory(data)
		case 1:
			return await CreateFamily(data)
		case 2:
			return await CreateManufacturer(data)
		case 3:
			return await CreateBrand(data)
		default:
			return null
	}
}

export const fetDataGrouper = async ({ query, tabIndex }) => {
	switch (tabIndex) {
		case 0:
			return await GetCategories(query.pageSize, query.page + 1, query.search, query.orderDirection, query?.name)
		case 1:
			return await GetFamilies(query.pageSize, query.page + 1, query.search, query.orderDirection, query?.name)
		case 2:
			return await GetManufacturers(query.pageSize, query.page + 1, query.search, query.orderDirection, query?.name)
		case 3:
			return await GetAllBrands(query.pageSize, query.page + 1, query.search, query.orderDirection, query?.name)
		default:
			return null
	}
}

export async function fetchData({ query, watch, tabIndex }) {
	try {
		const result = await fetDataGrouper({ query, watch, tabIndex })
		if (result && result.results) {
			return {
				data: result.results,
				page: result.currentPage - 1,
				totalCount: result.recordCount,
				pageSize: result.pageSize,
			}
		}
		return defaultFetchData
	} catch (error) {
		console.error('Erro ao buscar os dados:', error)
		return defaultFetchData
	}
}
