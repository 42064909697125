import React from 'react'
import * as _ from 'lodash'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

// @material-ui/icons

import { InputAdornment, withStyles, Slide, Dialog, DialogTitle, DialogActions } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import ConfirmModal from 'components/ConfirmModal'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section'
import SnackDefault from 'components/SnackDefault'
import { ControlSwitch } from 'components/CustomSwitch/styles.js'
import SimpleTabs from 'components/CustomNavTabsPills'

// import CustomPagination from 'components/CustomPagination/CustomPagination.jsx'
import compose from 'utils/compose'

// REDUX INIT
import { connectRouterState } from 'utils/connectRouterState'
import {
	GetCashbackRules,
	GetPartnerConsumers,
	HideAlert,
	HideLoader,
	ShowLoader,
	SweetAlert,
	UpsertPartnerCashbackRules,
	UpsertPartnerConsumerCashbackRules,
} from 'redux/actions'

import { PartnerValidatePartner, UpdatePartnersBranch } from 'redux/api/partner'

// import { GetDigitalWalletRules } from 'redux/api/generalRules.js'

import { GetGeneralBasicData } from 'redux/api/user.js'

// style
import * as S from './styles'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import modalStyle from 'assets/jss/material-dashboard-pro-react/modalStyle.jsx'
import styles from './statement.module.scss'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { UpdetePartnerCashbackRules } from '../../../redux/api/partner'
import { Product } from './Components/Product'
import { Client } from './Components/Client'
import Modal from './Components/Modal'
import { getProgramTitle } from '../../../utils/utils'

function Transition(props) {
	return <Slide direction="down" {...props} />
}

class Parameterization extends React.Component {
	constructor(props) {
		super(props)
		this.tableRef = React.createRef()
		this.state = {
			active: 0,
			results: [],
			isClient: false,
			allowCashback: false,
			maximumPercentualProduct: null,
			automaticPercentualProduct: false,
			manualPercentualProduct: false,
			maximumNegotiation: 0,
			searchName: '',
			searchCPF: '',
			filials: '',
			filtered: false,
			maximun: '',
			pattern: '',

			open: false,
			productFailed: false,
			isHidden: true,
			userData: {},
			loading: false,
			isDisabled: true,

			list: [],
			pagination: {
				currentPage: 0,
				pageCount: 1,
				pageSize: 10,
				recordCount: 1,
				filter: {
					cnpj: '',
					companyName: '',
					name: '',
				},
			},

			formStatusChanged: {},
			isDataSaved: '',
			confirmModalProps: {
				open: false,
				type: 'confirmDelete',
				text: 'Para habilitar o cashback é necessário informar a sua senha.',
				button: 'Habilitar cashback',
				mode: 'allow',
			},
			ModalProps: {
				open: false,
				type: 'cancel',
				text: 'As alterações não foram salvas, deseja descartá-las?',
				button: '',
				mode: '',
			},

			snackStatus: {
				open: false,
				severity: 'success',
				text: 'Parametrização do produto salvo com sucesso',
			},

			editData: [],
			modalName: '',
			modalCPF: '',
			modalMinimum: false,
			modalMinimumError: false,
			modalMaximum: false,
			modalMaximumError: false,
			allowNegotiation: false,
			allowNegotiationError: false,

			// administrationFee: '',

			radioCashback: '',

			saveDataObj: [],
			currentTab: 1,
			themeColor: getThemeColorFromLocalStorage(),
			isUnsavedData: false,
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		this.props.GetCashbackRules()
		this.getTableData()
		this.setState({ ...this.props.urlState }, () => {
			if (!_.isEmpty(this.state.searchName) || !_.isEmpty(this.state.searchCPF)) {
				this.setState({ filtered: true })
			}
		})

		this.setState({
			themeColor: getThemeColorFromLocalStorage(),
		})

		// checkedChange
		this.checkedChange()
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState(
				{
					...urlState,
					pagination: {
						...this.state.pagination,
						currentPage: urlState.currentPage,
					},
				},
				() => this.getTableData(),
			)
		}

		// this.checkedChange
		//
		if (prevProps.cashbackRulesData !== this.props.cashbackRulesData) {
			if (this.props.cashbackRulesData) {
				if (this.props.cashbackRulesData.automaticPercentualProduct) {
					this.setState({
						radioCashback: 'automaticPercentualProduct',
					})
				} else {
					this.setState({ radioCashback: 'manualPercentualProduct' })
				}
				//
				this.setState({
					allowCashback: this.props.cashbackRulesData.allowCashback,
					automaticPercentualProduct: this.props.cashbackRulesData.automaticPercentualProduct,
					manualPercentualProduct: this.props.cashbackRulesData.manualPercentualProduct,
					maximumNegotiation: this.props.cashbackRulesData.maximumNegotiation,
					maximumPercentualProduct: this.props.cashbackRulesData.maximumPercentualProduct,
				})
			}
			this.props.HideLoader()
		}
		if (prevProps.postPartnerCashbackRulesData !== this.props.postPartnerCashbackRulesData) {
			if (this.props.postPartnerCashbackRulesData.success) {
				this.props.GetCashbackRules()
				this.setState({
					allowCashback: this.props.postPartnerCashbackRulesData.allowCashback,
					snackStatus: {
						open: true,
						severity: 'success',
						text: 'Operação realizada com sucesso!',
					},
				})
			}
		}
		if (prevProps.partnerConsumersData !== this.props.partnerConsumersData) {
			if (!_.isEmpty(this.props.partnerConsumersData)) {
				this.props.HideLoader()
			}
		}

		if (prevProps.postPartnerConsumerCashbackRulesData !== this.props.postPartnerConsumerCashbackRulesData) {
			if (!_.isEmpty(this.props.postPartnerConsumerCashbackRulesData)) {
				this.handleClose()
				this.props.SweetAlert(
					<Alert
						success
						style={{ display: 'block', marginTop: '-100px' }}
						title={null}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.HideLoader()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.t('FIELD_ALERT_SUCCESS')}
					</Alert>,
				)
				//
				this.setState(
					{
						pagination: {
							...this.state.pagination,
							currentPage: 0,
						},
					},
					() => {
						this.getTableData()
					},
				)
			}
		}

		if (prevProps.cashbackRulesFailed !== this.props.cashbackRulesFailed) {
		}
		if (prevProps.postPartnerCashbackRulesDataFailed !== this.props.postPartnerCashbackRulesDataFailed) {
		}
		if (prevProps.partnerDataFailed !== this.props.partnerDataFailed) {
			if (this.props.partnerDataFailed) {
			}
		}

		if (prevProps.postPartnerConsumerCashbackRulesDataFailed !== this.props.postPartnerConsumerCashbackRulesDataFailed) {
			if (this.props.postPartnerConsumerCashbackRulesDataFailed) {
				this.props.SweetAlert(
					<Alert
						warning
						style={{ display: 'block', marginTop: '-100px' }}
						title={''}
						onConfirm={() => {
							this.props.HideAlert()
							this.props.HideLoader()
						}}
						confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
						confirmBtnText={'OK'}
					>
						{this.props.postPartnerConsumerCashbackRulesDataFailed.errors[0].message == 'CNPJ - DUPLICATED'
							? 'Atenção! O CNPJ cadastrado já existe!'
							: ''}
					</Alert>,
				)
			}
		}
	}

	handleFilter = clear => {
		const { searchName, searchCPF } = this.state
		this.props.setURLState({
			searchName,
			searchCPF,
			currentPage: 0,
		})
		//
		if (clear) {
			this.setState({ filtered: false })
		} else {
			this.setState({ filtered: true })
		}
	}

	handleSearch = currentPage => {
		this.props.ShowLoader(<Loader />)
		this.props.setURLState({ currentPage })
	}

	handleClearFilter = () => {
		this.setState(
			{
				filtered: false,
				searchName: '',
				searchCPF: '',
			},
			() => {
				this.handleFilter(true)
			},
		)
	}

	handleChangeTab = tab => {
		if (this.state.isDisabled === false) {
			this.handleCancel()
		}

		this.handleClearFilter()
	}

	checkedChange = () => {
		var checked
		if (this.state.active === 1) {
			if (Number(this.props.cashbackRulesData.maximumNegotiation) !== Number(this.state.maximumNegotiation)) {
				checked = true
			} else {
				checked = false
			}
		}

		if (this.state.active === 0) {
			if (this.props.cashbackRulesData.automaticPercentualProduct) {
				if (
					Number(this.props.cashbackRulesData.maximumPercentualProduct) !== Number(this.state.maximumPercentualProduct) ||
					this.state.radioCashback !== 'automaticPercentualProduct'
				) {
					checked = true
				}
			} else {
				if (
					Number(this.props.cashbackRulesData.maximumPercentualProduct) !== Number(this.state.maximumPercentualProduct) ||
					this.state.radioCashback !== 'manualPercentualProduct'
				) {
					checked = true
				}
			}
		}

		if (checked) {
			this.setState({ isDisabled: false })
		} else {
			this.setState({ isDisabled: true })
		}
	}

	handleSaveData = () => {
		this.verifyCashbackPermission()
		const data = {
			allowCashback: this.state.allowCashback,
			maximumPercentualProduct: this.state.maximumPercentualProduct || '',
			automaticPercentualProduct: this.state.automaticPercentualProduct,
			manualPercentualProduct: this.state.manualPercentualProduct,
			maximumNegotiation: this.state.maximumNegotiation,
		}
		//

		this.setState({
			saveDataObj: data,
			confirmModalProps: {
				open: true,
				type: 'confirmDelete',
				text: 'Para confirmar a nova parametrização do cashback é necessário informar a sua senha.',
				button: 'Enviar',
				mode: 'save',
			},
		})
	}

	formatCnpjCpf = value => {
		const cnpjCpf = value.replace(/\D/g, '')

		if (cnpjCpf.length === 11) {
			return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
		}

		return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5')
	}

	handleChange = event => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ isUnsavedData: true })

		//
		if (id === 'maximumNegotiation') {
			if (this.state.maximumNegotiation !== value) {
				this.setState({ isUnsavedData: true })
			}
		}

		if (id === 'searchCPF') {
			const cnpjSize = 18
			if (value.length <= cnpjSize) {
				this.setState({ [id]: value })
			}
		} else {
			this.setState({ [id]: value })
		}
		//
		if (id === 'radioCashback') {
			this.setState({ radioCashback: event.target.value })
			if (event.target.value === 'automaticPercentualProduct') {
				this.setState({
					automaticPercentualProduct: true,
					manualPercentualProduct: false,
					maximumPercentualProduct:
						this.props &&
							this.props.cashbackRulesData &&
							this.props.cashbackRulesData.maximumPercentualProduct &&
							this.props.cashbackRulesData.maximumPercentualProduct
							? this.props.cashbackRulesData.maximumPercentualProduct
							: null,
				})
			}
			if (event.target.value === 'manualPercentualProduct') {
				this.setState({
					automaticPercentualProduct: false,
					manualPercentualProduct: true,
				})
			}
			//
			this.setState({ [event.target.value]: true })
		}
	}

	handleEdit = data => {
		if (this.state.isUnsavedData) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					Você ainda não salvou o valor de <strong>cashback padrão</strong>. Para realizar a edição do cliente, é necessário salvar essa alteração
				</Alert>,
			)
			return false
		}
		//
		this.setState({ editData: data })
		this.setState(
			{
				allowNegotiation: data.allowNegotiation,
				modalCPF: this.formatCnpjCpf(data.cpf),
				modalMaximum: data.maximumNegotiation === '-' ? '' : data.maximumNegotiation,
				modalMinimum: data.minimumNegotiation === '-' ? '' : data.minimumNegotiation,
				modalName: data.name,
			},
			() => {
				this.setState({ open: true })
			},
		)
	}

	handleSaveModal = () => {
		this.verifyCashbackPermission()
		let error = false
		if (this.state.modalMaximum > this.props.cashbackRulesData.maximumNegotiation) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					Valor máximo não pode ser maior do que o valor padrão de cashback
				</Alert>,
			)
			return false
		}
		//
		const obj = {
			consumerId: this.state.editData.id,
			allowNegotiation: this.state.allowNegotiation,
			maximumNegotiation: this.state.modalMaximum,
			minimumNegotiation: this.state.modalMinimum,
		}
		//
		if (_.isEmpty(obj.minimumNegotiation.toString())) {
			this.setState({ modalMinimumError: 'obrigatório' })
			error = true
		}
		if (_.isEmpty(obj.maximumNegotiation.toString())) {
			this.setState({ modalMaximumError: 'obrigatório' })
			error = true
		}
		//
		if (!_.isEmpty(obj.minimumNegotiation.toString()) && !_.isEmpty(obj.maximumNegotiation.toString())) {
			if (Number(obj.minimumNegotiation) > Number(obj.maximumNegotiation)) {
				this.setState({
					modalMinimumError: 'O valor mínimo não pode ser maior que o valor máximo',
				})
				error = true
			}
		}
		//
		if (error) {
			return false
		}
		//
		if (_.isEmpty(obj.minimumNegotiation.toString()) || _.isEmpty(obj.maximumNegotiation.toString())) {
			this.setState({ modalMinimumError: 'obrigatório' })
			this.setState({ modalMaximumError: 'obrigatório' })
			error = true
		}
		//
		this.setState({ modalMinimumError: false })
		this.setState({ modalMaximumError: false })
		this.props.ShowLoader(<Loader />)
		this.props.UpsertPartnerConsumerCashbackRules(obj)
	}

	sendForm = async formData => {
		try {
			if (!formData.active && !formData.password && formData.active !== this.state.formStatusChanged.active) {
			} else {
				this.props.ShowLoader(<Loader />)
				let response
				if (this.state.filials) {
					response = await UpdatePartnersBranch({
						id: this.state.filials,
						name: formData.name,
						corporateName: formData.corporateName,
						cnpj: formData.cnpj,
						active: formData.active,
						phone: formData.phone,
						cellphone: formData.cellphone,
						address: {
							location: formData.location,
							district: formData.district,
							number: formData.number,
							aditionalInfo: formData.aditionalInfo,
							zipCode: formData.zipCode,
							stateId: formData.state,
							cityId: formData.city,
							countryId: formData.country,
						},
						password: formData.password ? formData.password : null,
					})
				}
				//
				if (!response.success) {
					response.errors.forEach(error => {
						if (error.field.includes('Password')) {
							return this.setState({
								confirmModalProps: {
									open: true,
									type: 'confirmDelete',
									text: 'Para habilitar o cashback é necessário informar a sua senha.',
									button: 'Habilitar cashback',
									mode: 'allow',
									error: error.message,
								},
							})
						}
					})
					return this.setState({
						snackStatus: {
							open: true,
							severity: 'error',
							text: 'Falha ao realizar operação!',
						},
					})
				} else {
					this.hideModal()
					return (
						this.setState({ formStatusChanged: {} }),
						this.setState({
							snackStatus: {
								open: true,
								severity: 'success',
								text: 'Operação realizada com sucesso!',
							},
						})
					)
				}
			}
		} catch (error) {
			console.log('ERROR: ', error)
		} finally {
			this.props.HideLoader()
		}
	}

	hideModal = () => {
		this.setState({
			confirmModalProps: {
				...this.state.confirmModalProps,
				open: false,
			},
		})
	}
	//
	handleConfirmModal = () => {
		this.setState({
			isDisabled: true,
			maximumNegotiation: this.props.cashbackRulesData.maximumNegotiation,
			maximumPercentualProduct: this.props.cashbackRulesData.maximumPercentualProduct,
			isUnsavedData: false,
			active: parseInt(this.state.active) === 1 ? 0 : 1,
		})

		if (this.props.cashbackRulesData.automaticPercentualProduct) {
			this.setState({
				radioCashback: 'automaticPercentualProduct',
			})
		} else {
			this.setState({ radioCashback: 'manualPercentualProduct' })
		}
		this.hideModal()
		this.handleCloseModal()
	}
	//
	handleClose = () => {
		this.setState({ open: false })
	}

	partnerValidatePartner = async password => {
		this.props.ShowLoader(<Loader />)
		const response = await PartnerValidatePartner(password)
		if (response) {
			const data = {
				allowCashback: !this.state.allowCashback,
				maximumPercentualProduct: this.state.maximumPercentualProduct ? this.state.maximumPercentualProduct : 0,
				automaticPercentualProduct: this.state.automaticPercentualProduct,
				manualPercentualProduct: this.state.manualPercentualProduct,
				maximumNegotiation: this.state.maximumNegotiation,
			}
			UpdetePartnerCashbackRules(data).then(res => {
				if (res.success) {
					this.setState({
						allowCashback: !this.state.allowCashback,
						confirmModalProps: {
							...this.state.confirmModalProps,
							open: false,
						},
					})
				} else {
					this.setState({
						snackStatus: {
							open: true,
							severity: 'error',
							text: 'Falha ao realizar operação!',
						},
					})
				}
			})
		} else {
			this.setState({
				snackStatus: {
					open: true,
					severity: 'error',
					text: 'Senha errada, tente novamente.',
				},
			})
		}
		this.props.HideLoader()
	}

	handleChangePage = (event, newPage) => {
		return this.setState(
			{
				pagination: {
					...this.state.pagination,
					currentPage: newPage,
				},
			},
			() => {
				this.getTableData()
			},
		)
	}

	verifyCashbackPermission = async () => {
		const response = await GetGeneralBasicData()
		if (!response.allowCashback) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
						this.props.history.push('/home')
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					Você não tem permissão pra acessar a tela de parametrização de cashback.
				</Alert>,
			)
		}
	}

	getTableData = async () => {
		this.props.ShowLoader(<Loader />)
		this.verifyCashbackPermission()
		//
		this.props
			.GetPartnerConsumers(this.state.searchName, this.state.searchCPF, this.state.pagination.pageSize, this.state.pagination.currentPage + 1)
			.then(result => {
				if (result && result.results.length > 0) {
					let newResults = result.results
					newResults.forEach(item => {
						if (item.minimumNegotiation === 0) {
							item.minimumNegotiation = '-'
						}
						if (item.maximumNegotiation === 0) {
							item.maximumNegotiation = '-'
						}
					})
					this.setState({
						results: newResults,
						pagination: {
							currentPage: result.currentPage,
							pageCount: result.pageCount,
							pageSize: result.pageSize,
							recordCount: result.recordCount,
						},
					})
				} else {
					this.setState({
						results: [],
						pagination: {
							currentPage: result.currentPage,
							pageCount: result.pageCount,
							pageSize: result.pageSize,
							recordCount: result.recordCount,
						},
					})
				}
			})
	}
	//
	handleSavePartnerData = async password => {
		this.props.ShowLoader(<Loader />)
		this.verifyCashbackPermission()
		const response = await PartnerValidatePartner(password)
		if (response) {
			UpdetePartnerCashbackRules(this.state.saveDataObj).then(res => {
				if (res.success) {
					this.props.GetCashbackRules()
					this.setState({
						isUnsavedData: false,
						confirmModalProps: {
							...this.state.confirmModalProps,
							open: false,
						},
						isDisabled: true,
						snackStatus: {
							open: true,
							severity: 'success',
							text: `Parametrização do ${this.state.active === 1 ? 'cliente' : 'produto'} salvo com sucesso`,
						},
					})
				} else {
					this.setState({
						snackStatus: {
							open: true,
							severity: 'error',
							text: 'Falha ao realizar operação!',
						},
					})
				}
			})
		} else {
			this.setState({
				snackStatus: {
					open: true,
					severity: 'error',
					text: 'Senha inválida. Tente novamente!',
				},
			})
		}
		this.props.HideLoader()
	}
	//
	handleCancel = () => {
		this.setState({
			ModalProps: {
				...this.state.ModalProps,
				open: true,
			},
		})
	}

	handleCloseModal = () => {
		this.setState({
			ModalProps: {
				...this.state.ModalProps,
				open: false,
			},
		})
	}
	//
	render() {
		const { t, classes } = this.props
		const {
			maximumPercentualProduct,
			isDisabled,
			maximumNegotiation,
			modalMinimumError,
			modalMaximumError,
			loading,
			pagination,
			searchName,
			searchCPF,
			confirmModalProps,
			ModalProps,
			allowCashback,
			open,
			modalName,
			modalCPF,
			modalMinimum,
			modalMaximum,
			allowNegotiation,
			radioCashback,
			active,
		} = this.state

		const change = (name, e) => {
			this.verifyCashbackPermission()
			if (name === 'allowCashback') {
				this.setState({
					confirmModalProps: {
						open: true,
						type: 'confirmDelete',
						text: `${e.target.checked ? 'Para habilitar' : 'Para desabilitar'} o cashback é necessário informar a sua senha.`,
						button: `${e.target.checked ? 'Habilitar cashback' : 'Desabilitar cashback'}`,
						mode: 'allow',
					},
				})
			}

			if (name === 'allowNegotiation') {
				this.setState({ [name]: e.target.checked })
			}
		}

		return (
			<S.Container themeColors={this.state.themeColor} disabledSaveButton={this.state.isDisabled}>
				<DocumentTitle title={getProgramTitle()} />
				<SnackDefault snackStatus={this.state.snackStatus} handleCloseSnack={() => this.setState({ snackStatus: { open: false } })} />
				{/* debugger; */}
				<ConfirmModal
					open={confirmModalProps.open}
					type={confirmModalProps.type}
					text={confirmModalProps.text}
					error={confirmModalProps.error}
					onConfirm={() => this.handleConfirmModal()}
					onDelete={value => (confirmModalProps.mode === 'allow' ? this.partnerValidatePartner(value) : this.handleSavePartnerData(value))}
					onCancel={() => this.hideModal()}
					title={confirmModalProps.button}
				/>
				<Modal
					open={ModalProps.open}
					type={ModalProps.type}
					text={ModalProps.text}
					error={ModalProps.error}
					onConfirm={() => this.handleConfirmModal()}
					onCancel={() => this.handleCloseModal()}
					title={ModalProps.button}
				/>

				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<div
							style={{
								marginBottom: '12px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Section title={t('MENU_CASHBACK')} style={{ flexGrow: 1 }} />
						</div>
						<div className="cashBackInfo">
							Ao habilitar a função de cashback, você está concordando que uma porcentagem do valor da compra retorna para o consumidor.
						</div>
					</GridItem>
					<GridItem xs={12} sm={12} md={12} style={{ marginTop: '30px' }}>
						{t('ENABLE_CASHBACK')}
						<br />
						<ControlSwitch
							name="allowCashback"
							id="allowCashback"
							checked={allowCashback}
							onChange={change.bind(null, 'allowCashback')}
							classes={{
								switchBase: classes.switchBase,
								checked: classes.switchChecked,
								thumb: classes.switchIcon,
								track: classes.switchBar,
								disabled: classes.disabled,
							}}
						/>
					</GridItem>

					{allowCashback && (
						<GridItem xs={12} style={{ marginTop: '16px' }}>
							<SimpleTabs
								handleTabChange={(event, newValue) => {
									if (newValue === 0) {
										this.handleChangeTab(2)
									}

									if (newValue === 1) {
										this.handleChangeTab(1)
									}

									if (!this.state.isDisabled) return
									this.props.setURLState({ tab: newValue })
									this.setState({
										active: newValue,
									})
								}}
								active={active}
								tabs={[
									{
										label: t('TAB_PRODUCT'),
										tabContent: (
											<Product
												classes={classes}
												maximumPercentualProduct={maximumPercentualProduct}
												checkedChange={this.checkedChange}
												isDisabled={isDisabled}
												radioCashback={radioCashback}
												themeColor={this.state.themeColor}
												handleChange={this.handleChange}
												handleSaveData={this.handleSaveData}
												t={t}
												cashbackRulesData={this.props.cashbackRulesData}
												isUnsavedData={this.state.isUnsavedData}
											/>
										),
									},
									{
										label: t('TAB_CLIENT'),
										tabContent: (
											<Client
												handleChange={this.handleChange}
												classes={classes}
												checkedChange={this.checkedChange}
												isDisabled={isDisabled}
												maximumNegotiation={maximumNegotiation}
												searchName={searchName}
												searchCPF={searchCPF}
												themeColor={this.state.themeColor}
												handleClearFilter={this.handleClearFilter}
												handleFilter={this.handleFilter}
												t={t}
												isDataSaved={this.state.isDataSaved}
												tableRef={this.tableRef}
												pagination={pagination}
												formatCnpjCpf={this.formatCnpjCpf}
												handleEdit={this.handleEdit}
												results={this.state.results}
												setState={this.setState.bind(this)}
												getTableData={this.getTableData.bind(this)}
												loading={loading}
												handleChangePage={this.handleChangePage.bind(this)}
												handleSaveData={this.handleSaveData}
												cashbackRulesData={this.props.cashbackRulesData}
												isUnsavedData={this.state.isUnsavedData}
											/>
										),
									},
								]}
							/>
						</GridItem>
					)}
				</GridContainer>
				<Dialog
					className={{
						root: classes.center + ' ' + classes.modalRoot,
						paper: classes.modal,
					}}
					fullWidth={true}
					maxWidth="sm"
					open={open}
					TransitionComponent={Transition}
					keepMounted
					onClose={this.handleClose.bind(this)}
					aria-labelledby="classic-modal-slide-title"
					aria-describedby="classic-modal-slide-description"
				>
					<DialogTitle
						id="classic-modal-communication"
						disableTypography
						className={classes.modalHeader}
						style={{
							alignItems: 'center',
							display: 'flex',
							justifyContent: 'space-between',
							padding: '19px 24px 35px 25px',
						}}
					>
						<div>
							<h4 className={classes.modalTitle}>
								<b>Percentual do cliente</b>
							</h4>
						</div>
						<div>
							<Button
								size="sm"
								variant="contained"
								color="greenBorderButtonColor"
								style={{
									marginRight: '0.875rem',
								}}
								onClick={() =>
									this.setState({
										open: false,
										modalMinimumError: false,
										modalMaximumError: false,
									})
								}
							>
								{t('BUTTON_CANCEL')}
							</Button>
							<Button size="sm" variant="contained" color="greenButtonColor" onClick={() => this.handleSaveModal()}>
								{t('BUTTON_SAVE')}
							</Button>
						</div>
					</DialogTitle>
					<S.InputBox id="classic-modal-slide-description">
						<S.InputModalName>
							<S.CssTextField
								className={styles.TextField}
								fullWidth
								label="Nome / Razão social"
								id="modalName"
								name="modalName"
								value={modalName}
								disabled={true}
								onChange={this.handleChange}
								variant="outlined"
								margin="dense"
								style={{
									backgroundColor: '#fff',
								}}
							/>
						</S.InputModalName>
						<S.InputModalCpf>
							<S.CssTextField
								className={styles.TextField}
								fullWidth
								label="CPF / CNPJ"
								id="modalCPF"
								name="modalCPF"
								value={modalCPF}
								disabled={true}
								onChange={this.handleChange}
								variant="outlined"
								margin="dense"
								style={{
									backgroundColor: '#fff',
								}}
							/>
						</S.InputModalCpf>

						<S.InputModalMinimum>
							<S.CssTextField
								className={styles.TextField}
								fullWidth
								label="Mínimo"
								id="modalMinimum"
								name="modalMinimum"
								value={modalMaximum || ''}
								onChange={this.handleChange}
								variant="outlined"
								margin="dense"
								style={{
									backgroundColor: '#fff',
								}}
								InputProps={{
									endAdornment: <InputAdornment position="start">%</InputAdornment>,
									inputComponent: DoubleFormatCustom,
								}}
								inputProps={{ max_val: 100 }}
								error={modalMinimumError}
								helperText={modalMinimumError}
							/>
						</S.InputModalMinimum>
						<S.InputModalMaximum>
							<S.CssTextField
								className={styles.TextField}
								fullWidth
								label="Máximo"
								id="modalMaximum"
								name="modalMaximum"
								value={modalMaximum || ''}
								onChange={this.handleChange}
								variant="outlined"
								margin="dense"
								style={{
									bacroundColor: '#fff',
								}}
								InputProps={{
									endAdornment: <InputAdornment position="start">%</InputAdornment>,
									inputComponent: DoubleFormatCustom,
								}}
								inputProps={{ max_val: 100 }}
								error={modalMaximumError}
								helperText={modalMaximumError && 'Obrigatório'}
							/>
						</S.InputModalMaximum>
						<div
							style={{
								width: '100%',
							}}
						>
							Permitir negociação
							<br />
							<ControlSwitch
								name="allowNegotiation"
								id="allowNegotiation"
								checked={allowNegotiation}
								onChange={change.bind(null, 'allowNegotiation')}
								value={allowNegotiation}
								classes={{
									switchBase: classes.switchBase,
									checked: classes.switchChecked,
									thumb: classes.switchIcon,
									track: classes.switchBar,
									disabled: classes.disabled,
								}}
							/>
							{allowNegotiation ? 'Sim' : 'Não'}
						</div>
					</S.InputBox>

					<DialogActions></DialogActions>
				</Dialog>
			</S.Container>
		)
	}
}

const mapStateToProps = state => {
	return {
		userData: state.users.userData,

		cashbackRulesData: state.partners.cashbackRulesData,
		cashbackRulesFailed: state.partners.cashbackRulesFailed,

		postPartnerCashbackRulesData: state.partners.postPartnerCashbackRulesData,
		postPartnerCashbackRulesDataFailed: state.partners.postPartnerCashbackRulesDataFailed,

		partnerConsumersData: state.partners.partnerConsumersData,
		partnerDataFailed: state.partners.partnerDataFailed,

		postPartnerConsumerCashbackRulesData: state.partners.postPartnerConsumerCashbackRulesData,
		postPartnerConsumerCashbackRulesDataFailed: state.partners.postPartnerConsumerCashbackRulesDataFailed,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetCashbackRules,
			GetPartnerConsumers,
			HideAlert,
			HideLoader,
			ShowLoader,
			SweetAlert,
			UpsertPartnerCashbackRules,
			UpsertPartnerConsumerCashbackRules,
		},
		dispatch,
	)

export default compose(
	withRouter,
	withTranslation(),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	connectRouterState({ searchName: '', searchCPF: '', currentPage: 0 }),
	withStyles(extendedFormsStyle),
	withStyles(modalStyle),
)(Parameterization)
