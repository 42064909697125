import React from 'react';

import { Grid } from "@material-ui/core";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	label: {
		padding: '5px 18px 0 18px',
		fontWeight: '600',
		color: '#333333',
	},
	textValue: {
		padding: '0 14px 5px 18px',
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap'
	}
}));

const GriTablow = props => {

	const classes = useStyles();
	const { labels, values } = props;

	return (
		<React.Fragment>
			<Grid item xs={5}>
				<div className={classes.label}>{labels[0]}</div>
				<div className={classes.textValue} title={values[0]}>{values[0]}</div>
			</Grid>
			<Grid item xs={4}>
				<div className={classes.label}>{labels[1]}</div>
				<div className={classes.textValue} title={values[1]}>{values[1]}</div>
			</Grid>
			<Grid item xs={3}>
				<div className={classes.label}>{labels[2]}</div>
				<div className={classes.textValue}>{values[2]}</div>
			</Grid>
		</React.Fragment>
	);
}

export default GriTablow;
