import { TextField, withStyles } from "@material-ui/core";
import { grayColor } from 'assets/jss/material-dashboard-pro-react';

export const CssTextField = withStyles({
	root: {
		"& label.Mui-focused": {
			color: grayColor[2],
		},
		"& label": {
			fontSize: "14px",
			fontWeight: "400",
			color: "hls(0, 0, 0, 0.55)",
			lineHeight: 1.2,
			zIndex: 0,
		},
		"& .MuiInputBase-root": {
			color: "rgba(0, 0, 0, 0.6)",
		},
		"& .MuiInputBase-root.Mui-disabled": {
			color: "rgba(0, 0, 0, 0.50)",
			cursor: "default",
		},
		"& .MuiInput-underline:after": {
			borderBottomColor: grayColor[2],
		},
		"& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
			transform: "translate(14px, -14px) scale(0.75)",
			"&:not(.Mui-disabled)": {
				// marginTop: "-7px",
				// marginLeft: "-5px",
			},
		},
		"& .MuiOutlinedInput-root": {
			"&:not(.Mui-disabled)": {
				"&:hover fieldset": {
					borderColor: grayColor[2],
				},
				"&.Mui-focused fieldset": {
					borderColor: grayColor[2],
				},
			},
		},
		"& .MuiOutlinedInput-root.Mui-disabled": {
			backgroundColor: "hsl(3,3%,95%)",
			zIndex: -1,
			"& fieldset": {
				borderColor: "transparent",
				borderRadius: "3px",
				"& legend": {
					width: "0px",
				},
			},
		},
		"& .MuiOutlinedInput-inputMarginDense": {
			paddingTop: "9.5px",
			paddingBottom: "10.5px",
		},
		"& .MuiFormLabel-root.Mui-disabled": {
			color: "rgba(0, 0, 0, 0.50)",
			// fontSize: "16px",
		},
		"& fieldset": {
			color: "rgba(0, 0, 0, 0.7)",
			borderColor: "hsl(0,0%,80%)",
			"& .hover": {
				borderColor: "hsl(0,0%,80%)",
			},
			"& legend": {
				width: "0px",
			},
		},
		"& .PrivateNotchedOutline-legendLabelled-583": {
			width: "0px !important",
		},
		"& .MuiFormHelperText-contained": {
			marginRight: "0px",
		},
	},
})(TextField);
