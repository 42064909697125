import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import 'moment/locale/pt-br'

import * as S from './styles'
//import { useLocation } from 'react-router-dom'
import { GetAllTransactionLogs } from '../../../../../../../redux/api/paymentGateway'

const TecnicalDetails = ({ id, handleClose }) => {
	const { t } = useTranslation()
	const [array, setArray] = useState('')

	moment.locale('pt-br')

	const getLogs = {
		id: id,
		amount: 0,
		pageSize: 0,
		page: 0,
	}

	useEffect(() => {
		GetAllTransactionLogs(getLogs).then(res => {
			setArray(res?.data?.results[0])
		})
	}, [])

	const formattedDate = moment(array.realizedAt).format(
		'D [de] MMMM [de] YYYY [às] HH:mm',
	)

	const formattedAmount = isNaN(array.amount)
		? ''
		: array.amount.toLocaleString('pt-BR', {
			style: 'currency',
			currency: 'BRL',
		})

	const formattedStatus =
		{
			FAILURE: t('FIELD_DENIED'),
			APPROVED: t('Approved'),
			GENERATED: t('Pendente'),
			PENDING: t('Pendente'),
		}[array.status] || t('FIELD_DENIED')

	const paymentMethod = {
		DEBIT: `${t('DEBIT')} / ${array.brand}`,
		CREDIT: `${t('CREDIT')} / ${array.brand}`,
		BILLET: `${t('Boleto')}`,
		PIX: `${t('PIX')}`,
	}[array.paymentMethod]

	return (
		<S.Container>
			<S.TitleDiv>
				<S.Title>Detalhes Técnicos</S.Title>
				<S.ButtonClose onClick={() => handleClose()}>
					<i style={{ color: "#F05B4F", fontSize: "20px" }} className="icon-Modal_erro" />
				</S.ButtonClose>
			</S.TitleDiv>
			<S.DetailContainer>
				<S.DetailDiv>
					<S.DetailTitle>{t('FIELD_PARTNER')}</S.DetailTitle>
					<S.DataDetail>{array.userName}</S.DataDetail>
				</S.DetailDiv>
				<S.DetailDiv>
					<S.DetailTitle>{t('FIELD_ClIENT')}</S.DetailTitle>
					<S.DataDetail>{array.consumerName}</S.DataDetail>
				</S.DetailDiv>
				<S.DetailDiv>
					<S.DetailTitle>{t('PAYMENT_METHOD')}</S.DetailTitle>
					<S.DataDetail>{paymentMethod}</S.DataDetail>
				</S.DetailDiv>
				<S.DetailDiv>
					<S.DetailTitle>{t('FIELD_PURCHASE_NAME')}</S.DetailTitle>
					<S.DataDetail>{array.purchaserName}</S.DataDetail>
				</S.DetailDiv>
				<S.DetailDiv>
					<S.DetailTitle>{t('FIELD_VALUE')}</S.DetailTitle>
					<S.DataDetail>{formattedAmount}</S.DataDetail>
				</S.DetailDiv>
				<S.DetailDiv>
					<S.DetailTitle>{t('FIELD_TRANSACTION_CODE')}</S.DetailTitle>
					<S.DataDetail>{array.transactionId}</S.DataDetail>
				</S.DetailDiv>
				<S.DetailDiv>
					<S.DetailTitle>
						{t('FIELD_TRANSACTION_STATE')}
					</S.DetailTitle>
					<S.DataDetail>{formattedStatus}</S.DataDetail>
				</S.DetailDiv>
				<S.DetailDiv>
					<S.DetailTitle>{t('FIELD_TRANSACTION_DATE')}</S.DetailTitle>
					<S.DataDetail>{formattedDate}</S.DataDetail>
				</S.DetailDiv>
			</S.DetailContainer>
		</S.Container>
	)
}

TecnicalDetails.propTypes = {
	id: PropTypes.string,
	handleClose: PropTypes.func,
}

export default TecnicalDetails
