import React from 'react';
import MaterialTable, { MTableCell, MTableToolbar, MTableHeader } from 'material-table';
import { Paper, TablePagination } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { primaryColor } from "assets/jss/material-dashboard-pro-react.jsx";

const useStyles = makeStyles(theme => ({
	tableTitle: {
		padding: 0,
		"& h6": {
			color: '#3C4858',
			fontSize: '18px !important',
			fontWeight: 800 + ' !important',
		},
		"& span": {
			color: primaryColor[0]
		},
		minHeight: '0px'
	},
	headerStyle: {
		color: primaryColor[0],
		fontWeight: "700",
		fontSize: '1em',
		background: "#E9E9E9 ",
		padding: '0px 16px',
		"&:first-child": {
			borderRadius: '10px 0 0 0',
		},
		"&:last-child": {
			borderRadius: '0 10px 0 0',
		},
	},
	tableCell: {
		padding: '7px 16px',
	},
	tableBody: {
		"& tbody": {
			border: 'none',
		},
		"& tfoot": {
			"& tr": {
				"& td": {
					borderBottom: 'none'
				}
			}
		}
	},
	acttions: {
		color: 'blue'
	}
}));

const ModalMaterialTable = ({ data, search, columns, title, noPaging, ...props }) => {

	const classes = useStyles();

	return (
		<MaterialTable
			components={{
				Container: props => <Paper {...props} elevation={0} classes={{ root: classes.tableBody }} />,
				Toolbar: props => <MTableToolbar {...props} classes={{ root: classes.tableTitle }} />,
				Cell: props => <MTableCell {...props} classes={{ root: classes.tableCell }} />,
				Header: props => <MTableHeader {...props} classes={{ header: classes.headerStyle }} />,
				//Pagination: props => <TablePagination {...props} classes={{ root: classes.pagination, actions: classes.acttions }} />,
			}}
			localization={{
				toolbar: {
					searchPlaceholder: "Buscar"
				},
				pagination: {
					labelDisplayedRows: '{from}-{to} de {count}',
					labelRowsSelect: 'linhas'
				},
				body: {
					emptyDataSourceMessage: 'Não foram encontrados resultados'
				}
			}}
			title={title ? title : null}
			columns={columns}
			data={data}
			options={{
				search: search ? search : false,
				paging: noPaging ? false : true
			}}
			{...props}
		/>
	)
};

export default (ModalMaterialTable);
