
import React, { Fragment, useEffect } from 'react'
import * as _ from 'lodash'

// @ Material-ui
import {
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core'

// @ Components
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'

// @ Styles
import { ContainerTitle, Title } from './styles'
import { CssTextField, CssTooltip } from '../../styles'

// @ Utils
import { VerifyLth } from '../Utils'


export const Product = (
	{
		classes,
		maximumPercentualProduct,
		radioCashback,
		themeColor,
		handleChange,
		handleSaveData,
		t,
		isDisabled,
		checkedChange,
		cashbackRulesData,
	}
) => {
	let isManualPercentualProduct = radioCashback !== 'manualPercentualProduct'
	let errors = VerifyLth(maximumPercentualProduct)

	useEffect(() => {
		checkedChange(radioCashback)
	}, [radioCashback, maximumPercentualProduct, cashbackRulesData, checkedChange])

	return (
		<Fragment>
			<ContainerTitle yle={{ marginTop: '10px' }}>
				<Title>
					Comportamento do cashback
				</Title>

				<Button
					size="sm"
					variant="contained"
					color="greenButtonColor"
					onClick={handleSaveData}
					disabled={
						isDisabled ||
						Boolean(errors && !isManualPercentualProduct) ||
						Boolean(_.isNull(maximumPercentualProduct) && !isManualPercentualProduct)
					}
					className="button-container"
					style={{ marginRight: '0' }}
				>
					{t('BUTTON_SAVE')}
				</Button>
			</ContainerTitle>


			<RadioGroup
				aria-label="radioCashback"
				id="radioCashback"
				name="radioCashback"
				value={radioCashback}
				onChange={handleChange}
			>
				<CssTooltip
					id={`tooltip-linked`}
					title="Ao habilitar esse recurso, a parametrização deverá ser feita nas configurações do produto"
					placement="top-start"
					arrow
				>
					<FormControlLabel
						value="manualPercentualProduct"
						style={{
							color: themeColor &&
								themeColor
									.titles &&
								themeColor
									.titles.highlight &&
								themeColor
									.titles.highlight
									.textColor &&
								themeColor
									.titles.highlight
									.textColor,
							textDecoration: 'underline dotted',
							textUnderlineOffset: '5px',
						}}
						control={<Radio color="primary" />}
						label="Parametrização manual por produto" />
				</CssTooltip>
				<CssTooltip
					arrow
					id={`tooltip-1linked`}
					placement="top-start"
					title="Ao habilitar esse recurso, todo produto comercializado do seu portfólio irá gerar um cashback, com o percentual mínimo dos produtos."
				>
					<FormControlLabel
						value="automaticPercentualProduct"
						style={{
							color: themeColor &&
								themeColor
									.titles &&
								themeColor
									.titles.highlight &&
								themeColor
									.titles.highlight
									.textColor &&
								themeColor
									.titles.highlight
									.textColor,
							textDecoration: 'underline dotted',
							textUnderlineOffset: '5px',
						}}
						control={<Radio color="primary" />}
						label="Parametrização automática para todo portfólio" />
				</CssTooltip>
			</RadioGroup>
			{
				radioCashback === 'automaticPercentualProduct' && (
					<Fragment>
						<Title
							style={{
								marginTop: 20,
							}}
						>
							Percentual padrão de cashback
						</Title>
						<GridContainer
							style={{
								marginTop: '16px',
								marginBottom: '16px',
							}}
						>
							<GridItem xs={12} sm={2} md={2}>
								<CssTextField
									fullWidth
									label="Padrão"
									id="maximumPercentualProduct"
									name="maximumPercentualProduct"
									value={maximumPercentualProduct}
									onChange={handleChange}
									variant="outlined"
									margin="dense"
									style={{
										backgroundColor: '#fff',
									}}
									helperText={Boolean(errors) && errors}
									error={Boolean(errors)}
									InputProps={{
										endAdornment: (
											<span style={{ marginRight: '5px' }}> % </span>
										),
										inputComponent: DoubleFormatCustom,
									}}
									maxLength={6}
									inputProps={{
										max_val: 100,
										maxLength: 6,
									}}
									InputLabelProps={{
										classes: {
											disabled: classes.labelTexRF,
										},
									}} />
							</GridItem>
						</GridContainer>
						<div
							className="greyInfo2"
							style={{
								marginBottom: '8px',
								color: themeColor &&
									themeColor.titles &&
									themeColor.titles
										.highlight &&
									themeColor.titles
										.highlight.textColor &&
									themeColor.titles
										.highlight.textColor,
							}}
						>
							<i
								className="icon-detalhe_campo"
								style={{
									fontSize: '30px',
									marginRight: '10px',
								}} />
							Percentual padrão será definido para o
							cashback do produto.
						</div>
					</Fragment>
				)
			}
		</Fragment>
	)
}
