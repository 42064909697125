import React, { Fragment, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

// @Components
import Tabs from './Components/Tabs'
import Section from 'components/Section'
import Loader from 'components/Loader/Loader'
import SnackDefault from 'components/SnackDefault'
import RegisterGrouper from './Components/RegisterGrouper'

// Styles
import * as S from './styles'

// @ Helpers
import { useForm } from 'react-hook-form'
import { defaultSnackbar } from './utils'
import ListGroupers from './Components/ListGrouper'

function ConfigureGrouper() {
	const { t } = useTranslation()
	const tableRef = useRef()

	const [tabIndex, setTabIndex] = useState(0)
	const [loading, setLoading] = useState(false)
	const [snackStatus, setSnackStatus] = useState(defaultSnackbar)

	const form = useForm()

	const sharedProps = {
		setLoading,
		setSnackStatus,
		tabIndex,
		tableRef,
		form,
		setTabIndex,
		t,
	}

	const handleCloseSnack = () => {
		setSnackStatus(prevState => ({
			...prevState,
			open: false,
		}))
	}

	return (
		<Fragment>
			{loading && <Loader />}
			<SnackDefault snackStatus={snackStatus} handleCloseSnack={handleCloseSnack} />
			<Section title={t('MENU_CONFIGURE_GROUPER')} />
			<S.Title>{t('TITLE_REGISTERS')}</S.Title>

			<Tabs {...sharedProps} />
			<RegisterGrouper {...sharedProps} />
			<ListGroupers {...sharedProps} />
		</Fragment>
	)
}

export default ConfigureGrouper
