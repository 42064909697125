import React, { useState, useRef } from 'react'

// @ Components
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import { Table, EmptyTable, SearchIcon, RowData, Search } from './styles'

// @Material
import InputAdornment from '@mui/material/InputAdornment'

//@Requests
//import { getAllPartners } from '../../../../../redux/api/paymentGateway'
import { CssTextField } from '../../../utils'

const TableModal = () => {
	const [page, setPage] = useState(10)
	const [search, setSearch] = useState('')
	const tableRef = useRef()

	const handleSearch = e => {
		tableRef.current && tableRef.current.onQueryChange(e)
	}

	return (
		<>
			<Search>
				<CssTextField
					id="standard-basic"
					label="Buscar"
					variant="standard"
					onChange={event => setSearch(event.target.value)}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon
									className="icon-ico_lupa"
									onClick={handleSearch()}
								/>
							</InputAdornment>
						),
					}}
				/>
			</Search>
			<Table>
				<MaterialTable
					tableRef={tableRef}
					title={false}
					options={{
						sorting: true,
						emptyRowsWhenPaging: false,
						toolbar: false,
						actionsColumnIndex: -1,
						search: true,
						draggable: false,
						pageSize: page,
						pageSizeOptions: [10, 20, 30, 40, 50, 100],
					}}
					localization={{
						body: {
							emptyDataSourceMessage: (
								<EmptyTable>
									<i
										className="icon-ico_information"
										style={{ color: '#B4B4B4' }}
									/>
									<span>Nenhum registro para exibir</span>
								</EmptyTable>
							),
						},

						pagination: {
							labelRowsSelect: 'linhas',
							labelDisplayedRows: '{from}-{to} de {count}',
							firstTooltip: 'Primeira página',
							previousTooltip: 'Página anterior',
							nextTooltip: 'Próxima página',
							lastTooltip: 'Última página',
						},
					}}
					columns={[
						{
							title: 'Parceiro',
							cellStyle: {
								minWidth: '100px',
								maxWidth: '100px',
							},
							headerStyle: {
								minWidth: '100px',
								maxWidth: '100px',
							},
							// eslint-disable-next-line react/display-name
							render: rowData => (
								<RowData>
									{rowData && rowData.partnerName} Azul
									Marinho
								</RowData>
							),
						},
					]}
					data={
						[]
						// query =>
						// new Promise((resolve, reject) => {
						// 	getAllPartners().then(res => {
						// 		res && res?.data
						// 			? resolve({
						// 					data: res.data,
						// 					page: 0,
						// 					totalCount: 0,
						// 			  })
						// 			: resolve({
						// 					data: [],
						// 					page: 0,
						// 					totalCount: 0,
						// 			  })
						// 	})
						// })
					}
				/>
			</Table>
		</>
	)
}

export default TableModal
