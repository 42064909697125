import styled from 'styled-components'

export const Close = styled.div`
	display: flex;
	justify-content: flex-end;
	button {
		border: none;
		border-radius: 5px;
		background-color: #F05B4F;
		cursor: pointer;
		color: #fff;
		padding: 8px;
		width: 90px;
		height: 35px;
	}
`
export const Icon = styled.div`
	color: #f9bb86;
	display: flex;
	font-size: 79px;
	justify-content: center;
	margin: 18px 0;
	top: -10px;
`
export const TextStyle = styled.div`
	.grid_modal {
		opacity: 0.8;
		color: #333333;
	}
	p {
		text-align: center;
		font: normal normal normal 16px/30px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 0.9;
	}
`

export const Example = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;

	img {
		width: 70%;
		height: 90%;
	}
`

export const Paragraph = styled.p`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 38px;
	margin-right: 38px;
	opacity: 0.9;
	color: #333333;
`

export const Model = styled.div`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 50px;
	padding-left: 32px;
	margin-right: 38px;
	opacity: 0.8;
	color: #333333;

	p {
		color: #F05B4F;
		text-align: left;
	}
`
export const Form = styled.form`
	.jgIWDX {
		width: 344px;
		height: 40px;
	}

	.gfEDZo {
		width: 344px;
		height: 40px;
		background: #fff 0% 0% no-repeat padding-box;
		border: 1px solid #c4c4c4;
		opacity: 1;
	}
	.foVCqc {
		width: 344px;
	}
`
