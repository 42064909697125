import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

import ErrorImage from 'assets/img/ErrorPages/Erro_404.png'

import { HideLoader } from 'redux/actions'

function NotFound() {
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(HideLoader())
	}, [])

	return (
		<Card>
			<CardBody>
				<GridContainer justifyContent="center">
					<img src={ErrorImage} alt="ErrorImage" />
				</GridContainer>
			</CardBody>
		</Card>
	)
}

export default NotFound
