import styled from "styled-components";
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

export const SearchBox = styled(GridContainer)`
	padding: 20px 0px;
    background: rgb(246, 246, 246);
    margin: auto auto 30px !important;
    border-radius: 5px;
    width: 100% !important;

	@media only screen and (max-width: 600px) {
		padding: 20px 15px;
		gap: 20px;
	}

	.search-input {
		@media only screen and (min-width: 600px) {
			margin-top: 20px !important;
		}
	}

	.search-container-buttons {
		margin-bottom: 0px !important;

		@media only screen and (max-width: 600px) {
			margin: 0px !important;
		}
	}
`


export const GroupButtons = styled(GridItem)`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	gap: 15px;
	height: 40px;
`

export const MoreFilters = styled(GridItem)`
	cursor: pointer;
	color: ${({ color }) => color && color};
	line-height: 36px;
	width: 50%;
	height: 40px;
	display: flex;
	align-items: center;
	padding-left: 15px;

	@media only screen and (max-width: 600px) {
		margin-bottom: 20px !important;
		padding-left: 0px;
	}
`
