import styled from 'styled-components'
import { Grid, Button } from '@material-ui/core'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'

export const GroupText = styled(Grid)`
	border-bottom: 1px dashed;
	position: relative;
	bottom: 10px;
	right: 10px;
	> span {
		color: ${germiniDefaultColors[0]};
		position: relative;
		top: 20px;
		font-weight: 400;
	}
`

export const ButtonAdd = styled(Button)`
	height: 37px;
	font-size: 15px !important;
	min-width: 75px !important;
	position: relative;
	top: 9px;
	left: 20px;

	&.MuiButton-root {
		border-radius: 6px;
		text-transform: initial;

		background-color: ${germiniDefaultColors[0]};
	}

	&.MuiButton-textPrimary {
		color: #fff;

		&:hover {
			background-color: ${germiniDefaultColors[0]};
			box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
		}
	}

	&.MuiButton-text {
		padding: 0 15px;
		font-weight: 100;
	}

	&.search-button {
		border-radius: 6px;

		&:hover {
			opacity: 1 !important;
			box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-webkit-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
			-moz-box-shadow: 3px 3px 5px 0px rgba(190, 190, 190, 0.75);
		}
	}
`

export const TableGrid = styled(Grid)`
	width: 100%;
	margin-top: 30px;

	.detail-button {
		margin: 1px;
		padding: 0px;
		height: 45px;
		width: 35px;
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
	}

	tbody {
		border-left: none;
		border-right: none;
	}

	.MuiPaper-elevation2 {
		box-shadow: unset;
	}

	.MuiToolbar-regular {
		min-height: 0px;
	}

	table tr:nth-child(even) {
    	background-color: #ffffff;
	}


	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				color: ${germiniDefaultColors[0]};
				font-size: 15px;
				font-weight: bold;
				z-index: 0 !important;

				:first-child {
					border-top-left-radius: 6px;
					width: 90% !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
					justify-content: flex-end;
					text-align: right !important;
					span {
						svg {
							display: none;
						}
					}
				}
				:nth-child(2) {
					text-align: left !important;
					width: 10% !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 45% !important;
				}
				:nth-child(4) {
					text-align: left !important;
					width: 15% !important;
				}
				:nth-child(5) {
					text-align: left !important;
					width: 20% !important;
				}
			}
		}
	}

	.MuiTableBody-root {
		.MuiTableCell-alignLeft {
			text-align: left;
			:last-child {
				text-align: right;
			}
		}
		tr {
			color: #717171;
			font-size: 15px;
			height: 45px;

			p {
				font-size: 13px;
				font-style: italic;
				margin: 0px;
			}

			td {
				padding: 0px 15px !important;
				:first-child {
					width: 1% !important;
					justify-content: flex-start;
					text-align: left !important;
				}
				:last-child {
					padding: 0px !important;
					justify-content: flex-end;
					text-align: right !important;
				}
				:nth-child(2) {
					text-align: left !important;
					width: 10% !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 45% !important;
				}
				:nth-child(4) {
					text-align: left !important;
					width: 15% !important;
				}
				:nth-child(5) {
					text-align: left !important;
					width: 20% !important;
				}
				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
					justify-content: flex-end;
					text-align: right !important;
				}
			}
		}
	}
`

export const Container = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor};
	}

	.MuiCheckbox-colorSecondary.Mui-checked {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}

	.css-770kpv-control:hover,
	.css-4ar5ih-control:hover {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}
`
