/* eslint-disable react/prop-types */
import React from 'react'
import { Stack } from '@mui/material'
import * as S from './styles'
import { Box } from '@material-ui/core'
export default function TextInput({ value, onChange, disabled, error, maxSize, ...rest }) {
	const formattedQuantity = (value, maxSize) => {
		if (typeof value !== 'string' || typeof maxSize !== 'number') {
			return 0
		}

		const remainingCharacters = Math.max(0, maxSize - value.length)
		return remainingCharacters
	}

	return (
		<Stack>
			<S.CssTextField
				value={value}
				onChange={onChange}
				variant="outlined"
				margin="dense"
				fullWidth
				disabled={disabled}
				error={error}
				// limitar o tamanho do input
				inputProps={{
					maxLength: maxSize,
				}}
				{...rest}
			/>

			{maxSize && (
				<Box
					sx={{
						textAlign: 'right',
						fontSize: '12px',
						color: '#13A413',
					}}
				>
					{formattedQuantity(value, maxSize)} caracteres
				</Box>
			)}
		</Stack>
	)
}
