import axios from 'axios'

export const UpdateGrouperParametrization = async ({ id, obj }) => {
	return await axios.post(`/Grouper/UpdateGrouperParametrization/${id}`, obj)
}

export const GetDivisionByGrouper = async (Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, id) => {
	let queryParams = `Id=${id}&PartnerId=${PartnerId}&TypeOfBenefit=${TypeOfBenefit}&PageSize=${PageSize}&Page=${Page}`
	if (Name) {
		queryParams += `&Name=${Name}`
	}
	const url = `/Grouper/GetDivisionByGrouper?${queryParams}`

	try {
		const response = await axios.post(url)
		return response
	} catch (error) {
		return error?.response || []
	}
}

export const UpdateGrouperByDivision = async (id, obj) => {
	return await axios.post(`/Grouper/UpdateGrouperByDivision/${id}`, obj).then(response => response.data)
}

export const GetGrouperByDivision = async ({ id, divisionId, type }) => {
	return await axios.get(`/Grouper/GetGrouperByDivision/${id}/${divisionId}/${type}`)
}

export const CreateGrouperByMultipleDivisions = async (id, obj) => {
	return await axios.post(`/Grouper/CreateGrouperByMultipleDivisions/${id}`, obj).then(response => response.data)
}

export const ValidateUpdateByMultipleDivision = async obj => {
	try {
		const response = await axios.post(`/Grouper/ValidateUpdateByMultipleDivision`, obj)

		return response
	} catch (error) {
		return error?.response || []
	}
}
