import { useState } from 'react'
import NegotiationDetailsService from '../Services/NegotiationDetailsService'

const initialNegotiationDetails = {
	consumer: {
		id: '',
		name: '',
		cpf: '',
		email: '',
		status: '',
		phoneNumber2: '',
		fidelityDate: '',
	},
	status: {
		key: '',
		value: '',
	},
	divisions: [
		{
			divisionId: '',
			name: '',
			cnpj: '',
			segmentationType: {
				key: '',
				value: '',
			},
		},
	],
	product: {
		key: '',
		value: '',
	},
	grouper: {
		key: '',
		value: '',
	},
	typeOfBenefit: {
		key: '',
		value: '',
	},
	benefitConfigType: {
		key: '',
		value: '',
	},
	value: 0,
	validUntil: '',
	sellersName: '',
	description: '',
}

export default function useNegotiationDetails() {
	const [isLoadingDetails, setIsLoadingDetails] = useState(false)
	const [negotiationDetails, setNegotiationDetails] = useState(initialNegotiationDetails)

	async function getNegotiationDetailsById(id) {
		setIsLoadingDetails(true)
		try {
			const response = await NegotiationDetailsService.getDetailsById(id)
			setNegotiationDetails(response?.data)
		} catch (error) {
			console.error('Erro ao obter detalhes da negociação', error)
		} finally {
			setIsLoadingDetails(false)
		}
	}

	return {
		getNegotiationDetailsById,
		isLoadingDetails,
		negotiationDetails,
		setNegotiationDetails,
	}
}
