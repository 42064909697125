import styled from 'styled-components'
import Snackbar from '@material-ui/core/Snackbar'
import { makeStyles } from '@material-ui/core'

export const MessageContainer = styled.div`
	display: flex;

	i {
		text-align: left;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
		box-shadow: 0px 3px 6px #00000029;
	}
	span {
		text-align: left;
		font: normal normal normal 18px/11px Segoe UI;
		letter-spacing: 0px;
		color: #ffffff;
		opacity: 1;
	}
`
export const CustomizedSnack = styled(Snackbar)`
	top: ${({ top }) => top} !important;
`

export const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: '100px',
		},
	},
}))
