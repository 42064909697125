import styled from 'styled-components/macro'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

export const Container = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) => props.themeColor}!important;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) => props.themeColor}!important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => props.themeColor};
	 }


	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => props.themeColor}!important;
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => props.themeColor}!important;
	}

	@media only screen and (max-width: 960px) {
		.gridContainerTable {
			padding-left: 0px !important;
		}
	}
`

export const titleStyles = {
	alignItems: 'center',
	display: 'flex',
	marginBottom: '25px',
	height: 40,
}

export const GroupStylesButton = styled(GridItem)`
	display: flex;
	gap: 15px;
	justify-content: flex-end;

	@media only screen and (max-width: 600px) {
		margin-top: 0px !important;
	}
`

export const GroupContainer = styled(GridContainer)`
	padding: 20px 0px;
    background: rgb(246, 246, 246);
    margin: auto auto 30px !important;
    border-radius: 5px;
    width: 100% !important;

	@media only screen and (max-width: 600px) {
		padding: 20px 15px;
		gap: 20px;
	}
`
