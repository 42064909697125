import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel, Grid, InputAdornment } from '@mui/material'
import { CssTextField } from '../../../Filter/styles'
import { Symbol } from '../../Functions'
import GenerationPoints from './GenerationPoints'
import { Controller } from 'react-hook-form'
import NumberFormattedPoints from '../../../../../../../../../../components/NumberFormatCustom/NumberFormattedPoints'

const Configuration = ({
	typeOfPoints,
	setOpen,
	productData,
	parameterizationGenerationPoints,
	control,
	editMode,
	fetchCalculation,
	typeOfRedeem,
	redemptionPoints,
}) => {
	const { t } = useTranslation()

	useEffect(() => {
		fetchCalculation('', typeOfRedeem)
	}, [typeOfRedeem])

	return (
		<S.CardsContent>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Preço em pontos*')}</span>
						<Controller
							name="redemptionPoints"
							control={control}
							defaultValue={redemptionPoints}
							render={({ value, onChange }) => (
								<CssTextField
									variant="outlined"
									size="small"
									value={value}
									disabled={!editMode}
									onChange={e => {
										fetchCalculation(e.target.value)
										onChange(e.target.value)
									}}
									InputProps={{
										['start' + 'Adornment']: (
											<InputAdornment position={'start'}>
												<S.CustomSymbol disabled={!editMode}>{Symbol('2')}</S.CustomSymbol>
											</InputAdornment>
										),
										max_val: 999999,
										maxLength: 8,
										inputComponent: NumberFormattedPoints,
									}}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				<Grid item xs={12} sm={9}>
					<GenerationPoints
						setOpen={setOpen}
						productData={productData}
						parameterization={parameterizationGenerationPoints}
						typeOfPoints={typeOfPoints}
					/>
				</Grid>
			</Grid>
		</S.CardsContent>
	)
}

Configuration.propTypes = {
	setOpen: PropTypes.func,
	editMode: PropTypes.bool,
	control: PropTypes.object,
	productData: PropTypes.object,
	typeOfPoints: PropTypes.string,
	typeOfRedeem: PropTypes.string,
	fetchCalculation: PropTypes.func,
	redemptionPoints: PropTypes.string,
	parameterization: PropTypes.object,
	parameterizationGenerationPoints: PropTypes.array,
}

Configuration.defaultProps = {
	typeOfPoints: '3',
}

export default Configuration
