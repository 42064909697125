import React from 'react'
import PropTypes from 'prop-types'
import { TextField, withStyles } from '@material-ui/core'
import styled from 'styled-components/macro'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import MaskedInput from 'react-text-mask'

export const Container = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${props => props.themeColor} !important;
	}

	@media (min-width: 1280px) {
		.rdtPicker {
			transform: translateX(-120px) !important;
		}
	}

	.rdtDay.rdtToday.rdtActive,
	.rdtDay.rdtActive,
	.rdtDay.rdtActive:hover {
		background-color: ${props => props.themeColor} !important;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after {
		border-color: ${props => props.getBGCol};
		border-width: 2px;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${props => props.getBGCol};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${props => props.getBGCol};
	}

	.MuiOutlinedInput-adornedEnd {
		padding-right: 7px;
	}

	.MuiOutlinedInput-inputMarginDense {
		padding-top: 10px !important;
	}

	.MuiInputBase-root {
		height: 40px !important;
	}
`

export const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			fontWeight: '400',
			color: 'hls(0, 0, 0, 0.55)',
			lineHeight: 1.2,
			zIndex: 1,
		},
		'& .MuiInputBase-root': {
			color: 'rgba(0, 0, 0, 0.6)',
			backgroundColor: '#fff',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)',
			'&:not(.Mui-disabled)': {
				marginTop: '-7px',
				marginLeft: '-5px',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: '9.5px',
			paddingBottom: '10.5px',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			fontSize: '16px',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			cursor: 'default',
		},
		'& fieldset': {
			color: 'rgba(0, 0, 0, 0.7)',
			borderColor: 'hsl(0,0%,80%)',
			'& .hover': {
				borderColor: 'hsl(0,0%,80%)',
			},
			'& legend': {
				width: '0px',
			},
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'&.Mui-focused fieldset': {
					borderColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(3,3%,95%)',
			'& fieldset': {
				'& legend': {
					width: '0px',
				},
			},
		},
		'& .PrivateNotchedOutline-legendLabelled-583': {
			width: '0px !important',
		},
	},
})(TextField)

export const DateMaskCustom = props => {
	const { inputRef, disabled, ...other } = props

	return (
		<MaskedInput
			{...other}
			disabled={disabled}
			readOnly={disabled}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}

DateMaskCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
}
