import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/CustomButtons/Button.jsx'
import * as S from './styles'

import { GetCountries, GetStates } from '../../../../../../../../redux/api/location'
import TemplateAddress from './Template'
function Address({ onKeyUp, isEdit, defaultEditValues, isEditData, methods, partnerData }) {
	const addressTypes = ['Endereço principal', 'Endereço secundário', 'Outro endereço']

	const [templates, setTemplates] = useState([{ addressType: addressTypes[0] }])

	const [list, setList] = useState({
		countries: [],
		states: [],
		cities: [],
	})

	const fetchCountriesAndStates = useCallback(async () => {
		try {
			const countries = await GetCountries()
			const country = countries.find(country => country.name === 'Brazil')
			const states = await GetStates(country.id)

			setList({ states, countries })
		} catch (error) {
			console.log(error)
		}
	}, [])

	useEffect(() => {
		fetchCountriesAndStates()
	}, [fetchCountriesAndStates])

	useEffect(() => {
		if (templates.length > 1 && templates[1].addressType !== addressTypes[1]) {
			const updatedTemplates = [...templates]
			updatedTemplates[1] = {
				...updatedTemplates[1],
				addressType: addressTypes[1],
			}
			setTemplates(updatedTemplates)
		}
	}, [templates])

	const addTemplate = () => {
		const newTemplate = { addressType: addressTypes[templates.length] }
		setTemplates([...templates, newTemplate])
	}

	const removeTemplate = index => {
		const newTemplates = templates.filter((_, i) => i !== index)
		setTemplates(newTemplates)

		if (newTemplates[index] && addressTypes.includes(newTemplates[index].addressType)) {
			newTemplates[index].addressType = addressTypes[2]
			setTemplates(newTemplates)
		}
	}

	useEffect(() => {
		if (partnerData?.addresses?.length > 0) {
			const updatedTemplates = [...templates]
			partnerData.addresses.forEach((address, index) => {
				if (address) {
					updatedTemplates[index] = {
						...updatedTemplates[index],
						...address,
					}
				}
			})
			setTemplates(updatedTemplates)
		}
	}, [partnerData?.addresses])

	return (
		<S.Container>
			{templates?.map((template, index) => (
				<TemplateAddress
					key={index}
					list={list}
					setList={setList}
					isEdit={isEdit}
					{...template}
					title={addressTypes[index]}
					index={index}
					removeTemplate={removeTemplate}
					onKeyUp={onKeyUp}
					userform={methods}
					isEditData={isEditData}
					partnerData={partnerData}
					defaultEditValues={defaultEditValues}
				/>
			))}
			{isEditData && templates.length < 3 && (
				<S.ButtonContainer>
					<Button size="sm" color="greenButtonColor" style={{ margin: 0 }} onClick={addTemplate}>
						Adicionar outro endereço
					</Button>
				</S.ButtonContainer>
			)}
		</S.Container>
	)
}

Address.propTypes = {
	isEdit: PropTypes.bool,
	onKeyUp: PropTypes.func,
	userform: PropTypes.func,
	methods: PropTypes.object,
	addresses: PropTypes.array,
	isEditData: PropTypes.bool,
	partnerData: PropTypes.object,
	defaultEditValues: PropTypes.object,
}

export default Address
