import axios from 'axios'

// GET
export const GetConsumerData = (name, _cpf, startDate, endDate, pageIndex) => {
	let cpf = _cpf ? `&cpf=${_cpf}` : ''
	let strStart = startDate ? `&startDate=${startDate}` : ''
	let strEnd = endDate ? `&endDate=${endDate}` : ''
	return axios
		.get(
			`/Consumer?name=${name}&page=${pageIndex}` +
				cpf +
				strStart +
				strEnd,
		)
		.then((response) => response.data)
}

export const GetAllConsumers = (
	name,
	_cpf,
	startDate,
	endDate,
	_pageSize,
	_orderBy,
	_sortBy,
	pageIndex,
) => {
	let cpf = _cpf ? `&cpf=${_cpf}` : ''
	let strStart = startDate ? `&startDate=${startDate}` : ''
	let strEnd = endDate ? `&endDate=${endDate}` : ''
	let orderBy = _orderBy ? `&orderBy=${_orderBy}` : ''
	let sortBy = _sortBy ? `&sortBy=${_sortBy}` : ''
	let pageSize = _pageSize ? `&pageSize=${_pageSize}` : ''
	return axios
		.get(
			`/Consumer?name=${name}&page=${pageIndex ? pageIndex : 1}` +
				cpf +
				strStart +
				strEnd +
				pageSize +
				orderBy +
				sortBy,
		)
		.then((response) => response.data)
}

// POST
export const PostConsumer = (obj) =>
	axios.post(`/Consumer`, obj).then((response) => response.data)

// UPDATE
export const UpdateConsumer = (id, obj) =>
	axios.put(`/Consumer/${id}`, obj).then((response) => response.data)

// GET BY ID
export const GetConsumerById = (id) =>
	axios.get(`/Consumer/${id}`).then((response) => response.data)

//


export const ValidateCpf = async (cpf) => {
	const url = `/Consumer/ValidateConsumerByCpf?cpf=${cpf}`
	const res = await axios.get(url)
	return res
}

export const ValidateCode = async (indicationcode) => {
	const url = `/Consumer/ValidateConsumerByCode?indicationcode=${indicationcode}`
	const res = await axios.get(url)
	return res
}



