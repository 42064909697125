import { Tooltip } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import Section from 'components/Section'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { HideLoader } from 'redux/actions'
import { numberFormatText } from 'utils/utils'
import { useAward } from '../../../Providers/AwardContext'
import { GetAwardId } from '../../../redux/api/award'
import * as S from './style'
import {envs}  from '../../../redux/api/windowServerData'


const ReversalConfirm = () => {
	const dispatch = useDispatch()
	const tableRef = React.useRef()
	const history = useHistory()
	const { idDetailProcessed, radioValue } = useAward()
	const [dataResponse, setDataResponse] = useState({})
	const [refundCanceled, setRefundCanceled] = useState(false)
	const { t } = useTranslation()
	const [tableSize, setTableSize] = useState(0)

	useEffect(() => {
		handleGetAwardData()
	}, [])

	const handleGetRefundData = () => {
		return new Promise(resolve => {
			// const data = history.location.state.data || {}
			const data = history && history.location && history.location.state ? history.location.state.data : {}
			setTimeout(() => {
				resolve(data)
			}, 10)
		})
	}

	const handleGetAwardData = async (pageSize, page, search) => {
		const response = await GetAwardId(idDetailProcessed, pageSize, page, search)

		setDataResponse({
			description: response.data.description,
			id: response.data.id,
			importValueType: response.data.importValueType,
			jobId: response.data.jobId,
			observation: response.data.observation,
			partner: response.data.partner,
			partnerCreditOrigin: response.data.partnerCreditOrigin,
			type: response.data.partnerCreditOrigin.value,
			partnerCreditOriginId: response.data.partnerCreditOriginId,
			date: response.data.processingDate ? moment(response.data.processingDate).format('DD/MM/YYYY') : null,
			hour: response.data.processingDate ? moment(response.data.processingDate).format('HH:MM') : null,
			standartValue: response.data.standartValue,
			status: response.data.status,
			reserveBalance: response.data.bankAccountReserve ? response.data.bankAccountReserve.reserveBalance : 0,
			total: response && response.data && response.data.total,
			isAnticipated: response.data.isAnticipated,
			anticipationFee: response.data.anticipationFee ? response.data.anticipationFee : '-',
			graceDescription: response.data.graceDescription ? response.data.graceDescription : '-',
		})

		const refundState = response.data.status.value === 'CANCELED' ? true : false
		setRefundCanceled(refundState)

		return response
	}

	dispatch(HideLoader())

	return (
		<>
			<S.Container>
				<div>
					<div
						style={{
							marginBottom: '15px',
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Section title={t('REVERSAL_CONFIRM')} style={{ flexGrow: 1 }} />
						<div style={{ marginLeft: '15px' }}>
							<Button
								style={{
									marginRight: '8px',
									backgroundColor: 'transparent',
									border: '1px solid #23429a',
									color: '#23429a',
									borderRadius: '5px',
								}}
								size="sm"
								variant="contained"
								onClick={() => history.push('/financial/award')}
							>
								Voltar
							</Button>
						</div>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<div
						class="icon-ico_sucesso"
						style={{
							color: '#67B106',
							fontSize: 70,
							marginBottom: 10,
						}}
					></div>
					<S.MyMainTitle style={{ marginBottom: 50 }}>
						{radioValue === 'batch'
							? 'Lote estornado com sucesso'
							: tableSize > 1 && radioValue === 'individual'
							? 'Registros estornados com sucesso'
							: 'Registro estornado com sucesso'}
					</S.MyMainTitle>
				</div>

				<S.MyTitle style={{ marginBottom: 30 }}>Dados do lote</S.MyTitle>
				<S.GridContain>
					<S.FieldContain style={{ marginBottom: '15px', marginTop: '20px' }}>
						<S.InputBox style={{ width: '220px' }}>
							<label>Data/Hora do processamento</label>
							<S.Inputs value={dataResponse.date ? `${dataResponse.date} - ${dataResponse.hour}` : '-'} />
						</S.InputBox>
						<S.InputBox style={{ width: '700px', marginRight: '0' }}>
							<label>Descrição da premiação</label>
							<S.Inputs value={dataResponse.description ? dataResponse.description : '-'} />
						</S.InputBox>
					</S.FieldContain>
					<S.FieldContain style={{ marginBottom: '15px', marginTop: '18px' }}>
						<S.InputBox style={{ width: '220px' }}>
							<label>Evento</label>
							<S.Inputs value={dataResponse.type ? dataResponse.type : '-'} />
						</S.InputBox>
						<S.InputBox style={{ width: '440px' }}>
							<label>Reserva da conta</label>
							{/* <S.Inputs
								value={
									dataResponse.bankAccountReserve
										? dataResponse.bankAccountReserve.name
										: '-'
								}
							/> */}
							<S.ShowValue>
								{numberFormatText(
									dataResponse.reserveBalance ? dataResponse.reserveBalance : '0',
									localStorage.getItem('currencySymbol')
										? `${localStorage.getItem('currencySymbol')}\n`
										: // : envs.REACT_APP_CURRENCY_SYMBOL,
										  envs.REACT_APP_CURRENCY_SYMBOL,
									2,
								)}
							</S.ShowValue>
						</S.InputBox>
						<S.InputBox style={{ marginRight: '0', width: '235px' }}>
							<Tooltip arrow id="tooltip-top" title={'Valor referente a conta reservada para utilizar na premiação'} placement="top-start">
								<label>
									<S.MyBoldText>Valor do saldo reservado</S.MyBoldText>
								</label>
							</Tooltip>

							<S.ShowValue>
								{numberFormatText(
									dataResponse.total ? dataResponse.total : '0',
									// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
									localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
									2,
								)}
							</S.ShowValue>
						</S.InputBox>
					</S.FieldContain>
					{dataResponse.isAnticipated ? (
						<S.ContainFields>
							<S.InputBox style={{ width: '220px' }}>
								<label style={{ marginLeft: '15px' }}>Taxa de antecipação</label>
								<S.Inputs value={dataResponse.anticipationFee ? dataResponse.anticipationFee + '%' : '-'} />
							</S.InputBox>
							<S.InputBox style={{ marginRight: '0', width: '200px' }}>
								<label style={{ marginLeft: '15px' }}>Carência</label>
								<S.Inputs value={dataResponse.graceDescription ? dataResponse.graceDescription : '-'} />
							</S.InputBox>
						</S.ContainFields>
					) : null}
					<S.InputBox style={{ marginRight: '0', marginTop: '18px' }}>
						<label>Observação</label>
						<S.Inputs value={dataResponse.observation ? dataResponse.observation : '-'} />
					</S.InputBox>
				</S.GridContain>

				<S.MyTitle style={{ marginBottom: 30, marginTop: 30 }}>Dados do estorno</S.MyTitle>

				<MaterialTable
					tableRef={tableRef}
					style={{ width: '100%' }}
					options={{
						selectionProps: () => ({
							color: 'primary',
						}),
						showTextRowsSelected: false,
						showTitle: false,
						showSelectAllCheckbox: false,
						emptyRowsWhenPaging: false,
						toolBar: false,
						paging: false,
						search: false,
					}}
					columns={[
						{
							title: 'CPF / Cód. participante',
							searchable: true,
							cellStyle: {
								width: '30%',
								maxWidth: '30%',
								minWidth: '30%',
								backgroundColor: '#fff',
							},

							headerStyle: {
								lineHeight: '1.2',
								height: '50px',
								fontSize: '15px',
								textAlign: 'left',
							},
							render: props => (
								<div
									style={{
										alignItems: 'flex-start',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '34px',
									}}
								>
									{props.cpF_Code > 5 ? props.cpF_Code.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : props.cpF_Code}
								</div>
							),
						},
						{
							title: 'valor',
							searchable: true,

							cellStyle: {
								textAlign: 'left',
								backgroundColor: '#fff',
								width: '50%',
								maxWidth: '50%',
								minWidth: '50%',
							},
							headerStyle: {
								textAlign: 'left',
								fontSize: '15px',
							},
							render: props => (
								<div
									style={{
										alignItems: 'flex-start',
										display: 'flex',
										flexDirection: 'column',
										justifyContent: 'center',
										height: '34px',
									}}
								>
									{numberFormatText(
										props.value ? props.value : '0',
										// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
										localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
										2,
									)}
								</div>
							),
						},
					]}
					data={() =>
						new Promise(resolve => {
							handleGetRefundData().then(result => {
								setTableSize(result.length)
								if (result) {
									resolve({
										data: result,
									})
								}
							})
						})
					}
				/>
			</S.Container>
		</>
	)
}
export default ReversalConfirm
