import { Modal, Paper, Fade } from '@material-ui/core'
import styled from 'styled-components'
import Button from 'components/CustomButtons/Button.jsx'

export const ModalContainer = styled(Modal)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
`

export const ModalFade = styled(Fade)`
	border-radius: 20px !important;
`

export const PaperContainer = styled(Paper)`
	padding: 30px;
	position: relative;
	min-width: 32vw;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 30px;
`

export const CustomButton = styled(Button)`
	box-shadow: none !important;
	background-color: ${({ bg }) => bg || 'inherit'} !important;
	border: 1px solid ${({ bc }) => bc || 'inherit'} !important;
	color: ${({ color }) => color || 'inherit'} !important;
	min-width: 100px !important;

	&:hover {
		background-color: ${({ hoverBg }) => hoverBg} !important;
		color: #ffffff !important;
		border: none !important;
	}
`

export const Text = styled.div`
	text-align: center;
	font: normal normal normal 16px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`

export const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
`

export const GroupButtons = styled.div`
	display: flex;
	gap: 20px;
`
