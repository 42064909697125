import React from 'react'
import { useTranslation } from 'react-i18next'
import { MuiModal } from '../../../../../components/storybook'

export function CancelNegotiationModal({ open, handleClose, onConfirm }) {
	const { t } = useTranslation()

	return (
		<MuiModal
			open={open}
			alertIcon
			description={t('Ao cancelar, essa negociação será perdida.')}
			customDescription={t('Deseja realmente cancelar?')}
			onConfirm={onConfirm}
			onClose={handleClose}
			color="default"
		/>
	)
}
