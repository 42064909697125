import * as actionTypes from '../actions/actionsTypes';
import {
    updateObject
} from '../utility';

const initialState = {
    statementData: {},
    statementDetails: false,
    statementDataFailed: false,
};

const fetchStatementData = (state, action) => {
    return updateObject(state, {
        statementData: action.statementData
    });
};

const fetchStatementDetailsData = (state, action) => {
    return updateObject(state, {
        statementDetails: action.statementDetails
    });
};

// DATA FAIL
const fetchStatementFail = (state, action) => {
    return updateObject(state, {
        statementDataFailed: true
    });
};

//
const statementReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_STATEMENT_DATA:
            return fetchStatementData(state, action);
        case actionTypes.FETCH_STATEMENT_DETAILS_DATA:
            return fetchStatementDetailsData(state, action);
        case actionTypes.FETCH_STATEMENT_FAILED:
            return fetchStatementFail(state, action);
        default:
            return state;
    }
};

export default statementReducer;