/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core'
import React, { Fragment } from 'react'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as S from '../styles'
import { validateValue } from '../../../../../Functions'
import Section from 'components/Section'
import { Stack } from '@mui/material'
import { CPFMaskCustom, CellMaskCustom } from '../Address/Functions'

export default function Responsible({ isEditData, partnerData }) {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={12} sm={12}>
					<Stack mt={10} sx={{ width: '100%' }}>
						<Section title="Dados do responsável" />
					</Stack>
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="CPF" disabled required value={validateValue(partnerData?.partnerAdmin?.cpf)} />
					) : (
						<Fragment>
							<Labelitem label="CPF" required isEditData />
							<Controller
								control={control}
								name="responsibleDocument"
								render={({ onChange, value }) => (
									<S.CssTextField
										error={errors?.responsibleDocument}
										helperText={t(errors?.responsibleDocument?.message)}
										fullWidth
										id="modalName"
										name="responsibleDocument"
										value={value}
										disabled={false}
										onChange={onChange}
										InputProps={{
											inputComponent: CPFMaskCustom,
										}}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={partnerData?.partnerAdmin?.cpf}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={6} sm={12}>
					{!isEditData ? (
						<Labelitem label="Nome" disabled required value={validateValue(partnerData?.partnerAdmin?.name)} />
					) : (
						<Fragment>
							<Labelitem label="Nome" required isEditData />
							<Controller
								control={control}
								name="responsibleName"
								render={({ onChange, value }) => (
									<S.CssTextField
										error={errors?.responsibleName}
										helperText={t(errors?.responsibleName?.message)}
										fullWidth
										id="modalName"
										name="responsibleName"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={partnerData?.partnerAdmin?.name}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="Celular" disabled required value={validateValue(partnerData?.partnerAdmin?.phoneNumber)} />
					) : (
						<Fragment>
							<Labelitem label="Celular" required isEditData />
							<Controller
								control={control}
								name="responsibleCellphone"
								render={({ onChange, value }) => (
									<S.CssTextField
										error={errors?.responsibleCellphone}
										helperText={t(errors?.responsibleCellphone?.message)}
										fullWidth
										id="modalName"
										name="responsibleCellphone"
										value={value}
										disabled={false}
										onChange={e => {
											onChange(e)
										}}
										InputProps={{
											inputComponent: CellMaskCustom,
										}}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={partnerData?.partnerAdmin?.phoneNumber}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={10} sm={12}>
					{!isEditData ? (
						<Labelitem label="Email" disabled required value={validateValue(partnerData?.partnerAdmin?.email)} />
					) : (
						<Fragment>
							<Labelitem label="Email" required isEditData />
							<Controller
								control={control}
								name="responsibleEmail"
								render={({ onChange, value }) => (
									<S.CssTextField
										error={errors?.responsibleEmail}
										helperText={t(errors?.responsibleEmail?.message)}
										fullWidth
										id="modalName"
										name="responsibleEmail"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={partnerData?.partnerAdmin?.email}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}
