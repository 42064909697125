import React, { Fragment } from 'react'
import * as S from '../styles'
import { Tooltip } from '@material-ui/core'

const Color = {
    Costcenter: <S.Indicator className="indicator" Color='#3EC450' />,
    BusinessUnit: <S.Indicator className="indicator" Color='#FB9006' />,
    Branch: <S.Indicator className="indicator" Color='#7E6BB9' />,
    Department: <S.Indicator className="indicator" Color='#1BBED3' />,
    HeadOffice: <S.Indicator className="indicator" Color='#73BDCE' />,
}

const TooltipText = {
    Costcenter: 'Centro de Custo',
    BusinessUnit: 'Unidade de negócio',
    Branch: 'Filial',
    Department: 'Departamento',
    HeadOffice: 'Matriz',
}

export const Columns = (t) => [
    {
        title: '',
        field: 'value',
        sorting: false,
        cellStyle: {
            width: '2px',
            marginLeft: '0px',
            paddingLeft: '0px',
        },
        headerStyle: {
            width: '2px',
            marginLeft: '0px',
            paddingLeft: '0px',
        },
        render: rowData => (
            <Fragment>
                { <Tooltip
                    arrow
                    id="tooltip-top"
                    placement="top-center"
                    title={TooltipText[rowData.segmentationType.value]}
                    >
                    {Color[rowData.segmentationType.value]}
                 </Tooltip>}
            </Fragment>
        ),
    },
    {
        title: t('Nome'),
        field: 'name',
        width: '40%',
        cellStyle: {
            width: '40%',
        },
        headerStyle: {
            width: '40%',
            paddingLeft: '25px',
        },
        render: (rowData) => (

            <div style={{
                height: "100%",
            }}>
                <span>{rowData.name}</span>
            </div>
        ),
    },
    {
        title: t('Geração'),
        field: 'isConfigPoints',
        width: '20%',
        cellStyle: {
            width: '20%',
            textAlign: 'left !important',
        },
        headerStyle: {
            width: '20%',
            paddingLeft: '25px',
        },
        render: (rowData) => (
            <S.IconsDiv>
                {rowData.isConfigPoints ?
                    <i className="icon-gerar_pontos" /> :
                    <b>-</b>}
            </S.IconsDiv>
        ),
    },
    {
        title: t('Resgate'),
        field: 'isConfigRedeem',
        width: '20%',
        cellStyle: {
            width: '20%',
            textAlign: 'left !important',
        },
        headerStyle: {
            width: '20%',
            paddingLeft: '25px',
        },
        render: (rowData) => (
            <S.IconsDiv>
                {rowData.isConfigRedeem ?
                    <i className="icon-resgatar_pontos" /> :
                    <b>-</b>}
            </S.IconsDiv>
        ),
    },
    {
        title: t('Cashback'),
        field: 'isConfigCashback',
        width: '20%',
        cellStyle: {
            width: '20%',
            textAlign: 'left !important',
        },
        headerStyle: {
            width: '20%',
            paddingLeft: '25px',
        },
        render: (rowData) => (
            <S.IconsDiv>
                {rowData.isConfigCashback ?
                    <i className="icon-menu_cashback1" /> :
                    <b>-</b>}
            </S.IconsDiv>
        ),
    },
]
