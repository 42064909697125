import React, { useState } from 'react'
import { Card, ContainInfo } from './style'
import { Modal, Box, TextField } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import hourglass from '../../../assets/img/ampulheta.png'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import TotalItemsDisplay from '../TotalItemsDisplay'
import { numberFormatText } from 'utils/utils'
import {envs}  from '../../../redux/api/windowServerData'



const ModalInfo = ({ setOpenModal, openModal, handleConfirm, cpfList, reversalType, pageSize, recordPage }) => {
	const handleClose = () => setOpenModal(false)

	return (
		<Modal
			open={openModal}
			handleConfirm={handleConfirm}
			handleClose={handleClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Card>
				<ContainInfo>
					<p style={{ margin: 0 }}>Confirmação de estorno {reversalType === true ? 'individual' : 'em lote'}</p>
					<div>
						<Button type="submit" size="sm" variant="contained" color="greenBorderButtonColor" onClick={handleClose}>
							Cancelar
						</Button>
						<Button
							size="sm"
							style={{
								marginLeft: '0.5rem',
							}}
							variant="contained"
							color="greenButtonColor"
							onClick={handleConfirm}
						>
							Confirmar
						</Button>
					</div>
				</ContainInfo>

				<MaterialTable
					search={true}
					options={{
						showTitle: false,
						showSelectAllCheckbox: false,
						debounceInterval: 500,
						emptyRowsWhenPaging: false,
						pageSizeOptions: [10, 20, 30, 40, 50],
						pageSize: pageSize < 10 ? recordPage : pageSize,
						toolBar: false,
						search: false,
						searchFieldStyle: {
							marginBottom: 20,
						},
						sorting: false,
					}}
					columns={[
						{
							title: 'CPF/Cód. participante',
							searchable: true,
							cellStyle: {
								width: '50%',
								maxWidth: '50%',
								minWidth: '50%',
							},
							headerStyle: { fontSize: '15px' },
							render: props => (
								<div
									style={{
										paddingLeft: '0px',
										height: '34px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									{props.cpF_Code > 5
										? props.cpF_Code.replace(
												/(\d{3})(\d{3})(\d{3})(\d{2})/,

												'$1.$2.$3-$4',
										  )
										: props.cpF_Code}
								</div>
							),
						},
						{
							title: 'Valor',
							field: 'value',
							width: '50%',
							searchable: true,
							cellStyle: {
								textAlign: 'left',
							},
							headerStyle: {
								textAlign: 'left',
								fontSize: '15px',
							},
							render: props => (
								<div
									style={{
										paddingLeft: '0px',
										height: '34px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'flex-start',
									}}
								>
									{numberFormatText(
										props.value ? props.value : '0',
										localStorage.getItem('currencySymbol')
											? `${localStorage.getItem('currencySymbol')}\n`
											: // : envs
											  // 	.REACT_APP_CURRENCY_SYMBOL,

											  envs.REACT_APP_CURRENCY_SYMBOL,
										2,
									)}
								</div>
							),
						},
					]}
					data={cpfList}
				/>

				<div
					style={{
						display: 'flex',
						justifyContent: 'end',
						marginTop: 15,
					}}
				>
					<TotalItemsDisplay numItems={cpfList ? cpfList.length : 0} />
				</div>
			</Card>
		</Modal>
	)
}
export default ModalInfo
