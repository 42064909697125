import { format, parseISO } from 'date-fns'
import { id } from 'date-fns/locale'
import { formatBRCurrency } from '../../../../components/storybook'

export function formatDate(date) {
	return format(new Date(date), 'dd/MM/yyyy') ?? '-'
}
export function formatISOFromDate(date) {
	const ISODate = parseISO(date)
	return date ? format(ISODate, 'dd/MM/yyyy') : '-'
}
export function formatISOFromTime(date) {
	const ISODate = parseISO(date)
	return date ? format(ISODate, 'HH:mm') : '-'
}

export function formatHour(time) {
	return format(new Date(time), 'HH:mm') ?? '-'
}

export function validateCurrencyMask(data) {
	const pointsMask = data?.simpleRules?.programCurrencySymbol

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Percentage') {
		return `${data?.value}%`
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Manual') {
		return formatBRCurrency(data?.value)
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Fixed') {
		return formatBRCurrency(data?.value)
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Percentage') {
		return `${data?.value}%`
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Fixed') {
		return `${pointsMask} ${data?.value}`
	}
	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Standard') {
		return `${pointsMask} ${data?.value}`
	} else {
		return '-'
	}
}

function replaceDot(value) {
	if (typeof value === 'number') {
		return value.toString().replace('.', ',')
	}
	return value
}

export function validateCurrencyMaskOnDetails(data) {
	const pointsMask = data?.simpleRules?.programCurrencySymbol

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Fixed') {
		return formatBRCurrency(data?.value)
	}

	if (data?.benefitConfigType?.value === 'Percentage') {
		return `${replaceDot(data?.value)}%`
	}
	if (data?.benefitConfigType?.value === 'Manual') {
		return `${replaceDot(data?.value)}%`
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Fixed') {
		return `${pointsMask} ${data?.value}`
	} else {
		return '-'
	}
}
export function validateCurrencyMaskLimited(data) {
	const pointsMask = data?.simpleRules?.programCurrencySymbol

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Fixed') {
		return formatBRCurrency(data?.maximumBenefitGenerationLimit)
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Percentage') {
		return formatBRCurrency(data?.maximumBenefitGenerationLimit)
	}
	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Percentage') {
		return `${pointsMask} ${data?.maximumBenefitGenerationLimit}`
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Manual') {
		return formatBRCurrency(data?.maximumBenefitGenerationLimit)
	}
	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Manual') {
		return `${pointsMask} ${data?.maximumBenefitGenerationLimit}`
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Fixed') {
		return `${pointsMask} ${data?.maximumBenefitGenerationLimit}`
	} else {
		return '-'
	}
}

export function validateBenefitMask(data) {
	const pointsMask = data?.simpleRules?.programCurrencySymbol

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Percentage') {
		return `100%`
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Manual') {
		return formatBRCurrency(data?.value * data?.sellQuantity?.totalAllowed)
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Fixed') {
		return formatBRCurrency(data?.value * data?.sellQuantity?.totalAllowed)
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Percentage') {
		return `100%`
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Fixed') {
		return `${pointsMask} ${data?.value * data?.sellQuantity?.totalAllowed}`
	}
	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Standard') {
		return `${pointsMask} ${data?.value * data?.sellQuantity?.totalAllowed}`
	} else {
		return '-'
	}
}

export function validateLimitedValues(data) {
	const pointsMask = data?.simpleRules?.programCurrencySymbol

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Percentage') {
		return `${data?.sellQuantity?.totalSold * data?.value}%`
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Manual') {
		return formatBRCurrency(data?.sellQuantity?.totalSold * data?.value)
	}

	if (data?.typeOfBenefit?.value === 'Cashback' && data?.benefitConfigType?.value === 'Fixed') {
		return formatBRCurrency(data?.sellQuantity?.totalSold * data?.value)
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Percentage') {
		return `${data?.sellQuantity?.totalSold * data?.value}%`
	}

	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Fixed') {
		return `${pointsMask} ${data?.sellQuantity?.totalSold * data?.value}`
	}
	if (data?.typeOfBenefit?.value === 'Points' && data?.benefitConfigType?.value === 'Standard') {
		return `${pointsMask} ${data?.sellQuantity?.totalSold * data?.value}`
	} else {
		return '-'
	}
}

export const grouperOptions = [
	{
		key: '1',
		value: 'Product',
		label: 'Produto',
	},
	{
		key: '2',
		value: 'Category',
		label: 'Categoria',
	},
	{
		key: '3',
		value: 'Family',
		label: 'Família',
	},
	{
		key: '4',
		value: 'Manufacturer',
		label: 'Fabricante',
	},
	{
		key: '5',
		value: 'Brand',
		label: 'Marca',
	},
]
