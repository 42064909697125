import React, { useState, useEffect } from 'react'
import MaterialTable, { MTableCell, MTableToolbar, MTableHeader } from 'material-table'
import { Paper, TablePagination } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx'

import { TableContainer } from './styles'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const useStyles = makeStyles(theme => ({
	tableTitle: {
		'& h6': {
			color: '#3C4858',
			fontSize: '18px !important',
			fontWeight: 800 + ' !important',
		},
		'& span': {
			color: primaryColor[0],
		},
	},
	headerStyle: {
		color: `${getThemeColorFromLocalStorage()?.titles?.highlight?.textColor} !important`,
		fontWeight: '700',
		fontSize: '15px !important',
		lineHeight: '1.2',
		background: '#E9E9E9 ',
		textAlign: 'left !important',
		// height: '32px !important',
		padding: '7px 0 6px 15px !important',

		'&:first-child': {
			borderRadius: '6px 0 0 0',
		},
		'&:last-child': {
			borderRadius: '0 6px 0 0',
		},
	},
	tableCell: {
		padding: '10px 15px',
	},
	tableBody: {
		'& tbody': {
			border: 'none',
		},
		'& tfoot': {
			'& tr': {
				'& td': {
					borderBottom: 'none',
				},
			},
		},
	},
	acttions: {
		color: 'blue',
	},
}))

const CustomMaterialTable = ({ data, search, columns, title, noPaging, ...props }) => {
	const [themeColor, setThemeColor] = useState()
	const classes = useStyles()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	return (
		<TableContainer themeColor={themeColor}>
			<MaterialTable
				style={{
					overflowY: 'hidden',
				}}
				components={{
					Container: props => <Paper {...props} elevation={0} classes={{ root: classes.tableBody }} />,
					Toolbar: props => <MTableToolbar {...props} classes={{ root: classes.tableTitle }} />,
					Cell: props => <MTableCell {...props} classes={{ root: classes.tableCell }} />,
					Header: props => <MTableHeader {...props} classes={{ header: classes.headerStyle }} />,
					// Pagination: (props) => (
					// 	<TablePagination
					// 		{...props}
					// 		labelRowsPerPage=""
					// 		classes={{
					// 			root: classes.pagination,
					// 			actions: classes.acttions,
					// 		}}
					// 	/>
					// ),
				}}
				localization={{
					toolbar: {
						searchPlaceholder: 'Buscar',
					},
					header: {
						actions: 'Ações',
					},
					pagination: {
						labelRowsSelect: 'linhas',
						labelDisplayedRows: '{from}-{to} de {count}',
						firstAriaLabel: 'Primeira página',
						firstTooltip: '',
						previousAriaLabel: 'Anterior',
						previousTooltip: '',
						nextAriaLabel: 'Próxima',
						nextTooltip: '',
						lastAriaLabel: 'Última página',
						lastTooltip: '',
					},
					body: {
						emptyDataSourceMessage: 'Nenhum registro encontrado',
					},
				}}
				title={title ? title : null}
				columns={columns ? columns : null}
				data={data}
				options={{
					search: search ? search : false,
					paging: noPaging ? false : true,
					emptyRowsWhenPaging: false,
					toolBar: false,
					draggable: false,
				}}
				{...props}
			/>
		</TableContainer>
	)
}

export default CustomMaterialTable
