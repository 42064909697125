import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import {
	germiniDefaultColors,
} from 'assets/jss/material-dashboard-pro-react.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'

export const ButtonItem = styled(Grid)`
	text-align: right;
	margin-top: 0px;
	margin-bottom: 15px;
	padding: 0px !important;
`

export const SearchBox = styled(GridContainer)`
	padding: 20px 0px;
    background: rgb(246, 246, 246);
    margin: auto auto 0px !important;
    border-radius: 5px;
    width: 100% !important;

	#date-picker-inline-container {
		width: 100%;
	}

	.search-input {
		@media only screen and (max-width: 959px) {
			margin-top: 20px !important;
		}
	}

	@media only screen and (max-width: 600px) {
		padding: 20px 15px;
		gap: 20px;
	}
`

export const GroupButtons = styled(GridItem)`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	gap: 15px;
	height: 40px;
`

export const GroupDate = styled(GridItem)`
	display: flex !important;
	gap: 30px;

	@media only screen and (max-width: 370px) {
		flex-direction: column;
	}
`

export const InnerContainer = styled(Grid)`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) =>
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor};
	}

	.header {
		display: flex;
		width: 100% !important;
		background-color: #fafafa;
		padding: 20px 0px;
		flex-wrap: wrap;
		align-items: center;
	}

	.filter-box {
		display: flex;
		max-height: 310px;

		.box-height {
			min-height: 180px;
		}

		background-color: #fafafa;
		margin: 0;
		padding: 16px;
		padding-left: 24px;
		button {
			max-height: 38px;
		}
		.search-button {
			margin-right: 20px;
			color: #fff;
			&:hover {
				background-color: ${germiniDefaultColors[0]};
				opacity: 0.8;
			}
		}
	}

	@media screen and (max-width: 960px) {
		max-height: initial;
		.box-height {
			min-height: initial;
		}
		.grid-input {
			margin-top: 16px;
		}
	}

	@media screen and (max-width: 1280px) {
		.cards-box {
			justify-content: center !important;
		}
	}
`

export const CustomFormLabel = styled.div`
	cursor: default !important;
	display: inline-flex !important;
	font-size: 15px !important;
	line-height: 1.428571429 !important;
	font-weight: 600 !important;
	padding-top: 20px !important;
	color: #333333 !important;
	margin-right: 0 !important;
	margin-bottom: 15px;
`

export const GridContent = styled(Grid)`
	padding: 0px !important;
`

export const GridButton = styled(Button)`
	height: 45px !important;
	width: 45px !important;
	border-radius: 0px !important;
	margin: 0px 0px 0px 1px !important;

	.op-icon {
		font-size: 20px !important;
		width: 24px !important;
		height: 24px !important;
	}
`

export const ListGrid = styled(Grid)`
	background-color: #fff;
	height: 100%;
	margin: 30px 0;
	width: 100%;

	.section {
		font-weight: 300;
		color: #5d5d5d;
		margin-top: 10px;
	}
	 > div {
		&:first-child {
			padding: 0 !important;
		}
	}


	.MuiToolbar-regular {
		min-height: 0px;
	}

	.grid-container {
		margin: 0 30px;
		padding: 0 0 15px 0;
	}

	.button-container {
		max-height: 36px;
	}

	.filter-box {
		margin: 30px 0;
		background-color: #fafafa;
		padding: 16px;
		padding-left: 24px;
		.search-button {
			margin-right: 20px;
			color: #fff;
			&:hover {
				background-color: ${germiniDefaultColors[0]};
				opacity: 0.8;
			}
		}
	}

	.MuiPaper-elevation2 {
		box-shadow: unset;
	}

	.MuiTableCell-root {
		padding: 0;
		padding: 10px 0 10px 15px !important;
		> div {
			.MuiIconButton-root {
				background: transparent !important;
				box-shadow: none !important;
			}
			button {
				.MuiTouchRipple-root {
					display: none !important;
				}
				cursor: pointer;
				box-shadow: none !important;
			}
		}
	}

	.MuiTableHead-root {
		tr {
			th {
				:first-child {
					border-radius: 6px 0 0 0;
				}
				:last-child {
					border-radius: 0 6px 0 0;
					> div {
					}
					span {
						svg {
						}
					}
				}
			}
		}
	}

	.MuiTableBody-root {
		tr {
			color: #717171;
			background-color: #fff;
			td {
				font-size: 15px !important;
				height: 45px !important;
				padding: 0px 15px !important;
				:first-child {
					width: 20% !important;
				}
				> div {
				}

				> div p {
					margin: 0px;
					font-size: 13px;
					font-style: italic;
				}

				:nth-child(2) {
					width: 50% !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:last-child {
					padding: 0 !important;
					width: 20% !important;
					justify-content: flex-end;
					text-align: right !important;
					> div {
					}
				}
			}
		}
	}

	table tbody {
    	border: none;
	}

	.submit-container {
		margin: 20px 0;
		padding-right: 16px;
		> :first-child {
			margin-right: 10px;
		}
	}

	.jss232 {
		overflow-x: hidden !important;
		position: relative !important;
	}

	.jss232 > div > div {
		overflow-y: visible !important;
	}

	@media screen and (max-width: 1200px) {
		.create-title {
			width: 100%;
			margin-top: 30px;
		}
	}
`

export const RegisterGrid = styled(Grid)`
	.Component-paginationToolbar-237 {
		width: 100%;
		padding: 5px;
	}
	background-color: #fff;
	height: 100%;
	//padding: 2px 2px 0;
	margin: 30px 0;
	width: 100%;

	.section {
		font-weight: 300;
		color: #5d5d5d;
		margin-top: 10px;
	}
	> div {
		&:first-child {
			padding: 0 !important;
		}
	}

	.MuiToolbar-regular {
		min-height: 0px;
	}

	.grid-container {
		margin: 0 30px;
		padding: 0 0 15px 0;
	}

	.button-container {
		max-height: 36px;
	}

	.filter-box {
		margin: 30px 0;
		background-color: #fafafa;
		padding: 16px;
		padding-left: 24px;
		.search-button {
			margin-right: 20px;
			color: #fff;
			&:hover {
				background-color: ${germiniDefaultColors[0]};
				opacity: 0.8;
			}
		}
	}

	.MuiPaper-elevation2 {
		box-shadow: unset;
	}

	.MuiTableCell-root {

		> div {
			.MuiIconButton-root {
				background: transparent !important;
				box-shadow: none !important;
			}
			button {
				.MuiTouchRipple-root {
					display: none !important;
				}
				cursor: pointer;
				box-shadow: none !important;
			}
		}
	}

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				color: ${germiniDefaultColors[0]};
				font-size: 15px;
				font-weight: bold;

				:first-child {
					border-top-left-radius: 6px;
					width: 20% !important;
				}

				:nth-child(2) {
					width: 50% !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:last-child {
					border-top-right-radius: 6px;
					padding: 0 !important;
					width: 20% !important;
					justify-content: center !important;
					text-align: center !important;
					> div {
						margin-right: 0px;
					}
					span {
						svg {
							display: none;
						}
					}
				}
			}
		}
	}

	.MuiTableBody-root {
		tr {
			color: #717171;
			background-color: #fff;
			td {
				font-size: 15px !important;
				/* background-color: black; */
				height: 45px !important;
				padding: 0px 15px !important;
				:first-child {
					width: 20% !important;
				}
				> div {
				}

				> div p {
					margin: 0px;
					font-size: 13px;
					font-style: italic;
				}

				:nth-child(2) {
					width: 50% !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:last-child {
					padding: 0 !important;
					width: 20% !important;
					justify-content: flex-end;
					text-align: right !important;
					> div {
					}
				}
			}
		}
	}

	.MuiIconButton-root {
		padding: 0px !important;
	}

	.submit-container {
		margin: 20px 0;
		padding-right: 16px;
		> :first-child {
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 1200px) {
		.create-title {
			width: 100%;
			margin-top: 30px;
		}
	}
`
export const ContainerPdvRegister = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor};
	}

	table tbody {
   	 border: none;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) =>
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor};
	}
	.MuiCheckbox-colorPrimary.Mui-checked {
		color: ${(props) =>
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor};
	}
	.MuiTableHead-root tr th {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor} !important;
	}
`
