import * as actionTypes from './actionsTypes'
import * as API from '../api/wallet'

export const fetchExpirationPointsData = (data) => {
    return {
        type: actionTypes.FETCH_CONSUMER_WALLET_DATA,
        walletExpirationPointsData: data
    }
}

export const fetchExpirationPointsFailed = (error) => {
    return {
        type: actionTypes.FETCH_CONSUMER_WALLET_FAILED,
        walletExpirationPointsFailed: error
    }
}

export const fetchStatementData = (data) => {
    return {
        type: actionTypes.FETCH_STATEMENT_DATA,
        statementData: data
    };
};

// export const fetchStatementDetailsData = (data) => {
//     return {
//         type: actionTypes.FETCH_STATEMENT_DETAILS_DATA,
//         statementDetails: data
//     };
// };


// FAIL FETCH -------------------------------------
export const fetchDataError = (error) => {
    return {
        type: actionTypes.FETCH_STATEMENT_FAILED,
        statementDataFailed: error
    };
};

export const GetConsumerExpirationPoints = () => {
    return dispatch => (
        API.GetConsumerWalletExpirationPoints().then(rest => {
            dispatch(fetchExpirationPointsData(rest));
        }).catch(err => {
            //console.log(err);
            dispatch(fetchExpirationPointsFailed(err));
        })

    )
}

//----------------------------------------------------

export const GetStatementData = (dateFrom, dateTo, consumerId, partnerId, pageIndex) => {
    return dispatch => (
        API.GetStatementData(dateFrom, dateTo, consumerId, partnerId, pageIndex).then(response => {
            dispatch(fetchStatementData(response));
        }).catch(error => {
            console.error(error);
            dispatch(fetchDataError(error));
        })
    );
};

// export const GetStatementDetailsData = (id) => {
//     return dispatch => (
//         API.GetStatementDetails(id).then(response => {
//             dispatch(fetchStatementDetailsData(response));
//         }).catch(error => {
//             console.error(error);
//             dispatch(fetchDataError(error));
//         })
//     );
// };