import React from 'react'
import Skeleton from 'react-loading-skeleton'
import { Grid, Box } from '@material-ui/core'

export function FormSkeleton() {
	return (
		<Grid container xs={12} md={12} spacing={2}>
			<Grid item xs={6} sm={9}>
				<Box
					style={{
						width: '100%',
						height: '40px',
						paddingTop: '12px',
					}}
				>
					<Skeleton />
				</Box>
			</Grid>
			<Grid item xs={6} sm={3}>
				<Box style={{ width: '100%', height: '40px' }}>
					<Skeleton height={40} />
				</Box>
			</Grid>

			<Grid item xs={12} sm={6}>
				<Skeleton height={40} />
			</Grid>
			<Grid item xs={12} sm={6}>
				<Skeleton height={40} />
			</Grid>

			<Grid item xs={12} sm={12} md={6}>
				<Skeleton height={40} />
			</Grid>
			<Grid item xs={6} sm={6} md={2}>
				<Skeleton height={40} />
			</Grid>
			<Grid item xs={6} sm={6} md={4}>
				<Skeleton height={40} />
			</Grid>

			<Grid item xs={12} sm={6} md={2}>
				<Skeleton height={40} />
			</Grid>
		</Grid>
	)
}
