import styled from 'styled-components'

export const Card = styled.div`
	/* align-items: center; */
	border-radius: 12px;
	background-color: #fff;
	/* display: flex;
	flex-direction: column;
	justify-content: center; */
	padding: 20px;
	width: 550px;
`
export const ContainInfo = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 20px;
	width: 100%;
	p {
		font-weight: 900;
	}
`
