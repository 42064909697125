import React, { useEffect, useState } from 'react'
import cx from 'classnames'

import GriTablow from './GriTablow.jsx'

import { Grid, Divider, Tooltip } from '@material-ui/core'

import { Search, Assessment } from '@material-ui/icons'

import { makeStyles } from '@material-ui/core/styles'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { useTranslation } from 'react-i18next'
import Button from '../../../components/CustomButtons/Button.jsx'

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#ececec',
		borderRadius: '4px',
		margin: '3px 0',
		'&:nth-child(even)': {
			backgroundColor: '#F4F4F4',
		},
	},
	button: {
		height: '100%',
		backgroundColor: germiniDefaultColors[0],
		textAlign: 'center',
		display: 'flex',
		cursor: 'pointer',
		width: '49%',
		'&:hover': {
			backgroundColor: germiniDefaultColors[9],
		},
	},
	buttonRadius: {
		borderTopRightRadius: '4px',
		borderBottomRightRadius: '4px',
	},
}))

const GriTable = (props) => {
	const classes = useStyles()
	const { t } = useTranslation()
	const { labels, values, history, id, campaignMode } = props
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const handleTableView = (id, campaignMode) => {
		if (campaignMode === 2) {
			history.push(`/campaign/viewRedeemCampaign/${id}`)
		} else {
			history.push(`/campaigns/view/${id}`)
		}
	}

	const handlePerformanceView = (id, campaignMode) => {
		if (campaignMode === 2) {
			history.push(`/campaign/performance/${id}`)
		} else {
			history.push(`/campaigns/performance/${id}`)
		}
	}

	const getColor = () => {
		return themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor &&
			themeColor.navigation.buttons.textColor
	}

	return (
		<Grid container spacing={0} className={classes.root}>
			<Grid container item xs={12} sm={12} md={11} spacing={0}>
				<Grid container spacing={0} style={{ padding: '5px 0' }}>
					<Grid container item xs={12} spacing={0}>
						<GriTablow
							labels={[labels[0], labels[1], labels[2]]}
							values={[values[0], values[1], values[2]]}
						/>
					</Grid>
					{labels.length > 3 && (
						<>
							<Divider
								style={{ width: '96%', margin: '0 8px' }}
							/>
							<Grid container item xs={12} spacing={0}>
								<GriTablow
									labels={[labels[3], labels[4], labels[5]]}
									values={[values[3], values[4], values[5]]}
								/>
							</Grid>
						</>
					)}
					{labels.length > 6 && (
						<>
							<Divider
								style={{ width: '96%', margin: '0 8px' }}
							/>
							<Grid container item xs={12} spacing={0}>
								<GriTablow
									labels={[labels[6], labels[7], labels[8]]}
									values={[values[6], values[7], values[8]]}
								/>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>
			<Grid
				container
				item
				xs={12}
				sm={12}
				md={1}
				spacing={0}
			>
				<div style={{ display: 'flex', width: '100%' }}>
					<div style={{ width: '50%' }}>
						<Tooltip
							arrow
							placement="left"
							title={t('BUTTON_VIEW')}
							style={{ display: 'flex' }}
						>
							<Button
								onClick={() => {
									handleTableView(id, campaignMode)
								}}
								style={{
									margin: '0px',
									padding: 'revert',
									height: '100%',
									width: '100%'
								}}
								variant="contained"
								color="greenButtonColor"
							>
								<Search style={{ margin: 'auto', color: `${getColor()}` }} />
							</Button>
						</Tooltip>
					</div>
					<div style={{ width: '50%' }}>
						<Tooltip
							arrow
							placement="left"
							title={t('BUTTON_MANAGE',)}
						>
							<Button
								style={{
									margin: '0px 0 0 1px',
									padding: 'revert',
									height: '100%',
									width: '100%'
								}}
								variant="contained"
								color="greenButtonColor"
								onClick={() => {
									handlePerformanceView(id, campaignMode)
								}}
							>
								<Assessment style={{ margin: 'auto', color: `${getColor()}` }} />
							</Button>
						</Tooltip>
					</div>
				</div>
			</Grid>
		</Grid>
	)
}

export default GriTable
