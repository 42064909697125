import React from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

import { Fade } from '@material-ui/core'

import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import Loader from 'components/Loader/Loader.jsx'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { ModalContainer, PaperContainer } from './styles'

import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

function ModalResendEmail({ className, closeByClick, handleSendEmail, maxWidth, loading, minWidth, open, shadow, t }) {
	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			minwidth={minWidth}
			disableEnforceFocus
		>
			<Fade in={open} unmountOnExit>
				<PaperContainer padding={'0px 30px'} maxwidth={maxWidth}>
					{loading && <Loader />}
					<GridContainer xs={12} sm={12} style={{ margin: '10px 30px 30px 2px' }}>
						<GridItem
							xs={12}
							sm={12}
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<i
								className="icon-alerta_modal"
								style={{
									color: germiniDefaultColors[11],
									fontSize: '80px',
								}}
							/>
						</GridItem>
					</GridContainer>
					<GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
						<div
							style={{
								fontSize: '15px',
								fontWeight: 'bold',
							}}
						>
							{t('We have identified that your registration')}
							<br />
							{t('Resend the email to and perform the validation')}
						</div>
						<div
							style={{
								fontSize: '13px',
								color: '#9B9B9B',
								marginTop: '20px',
							}}
						>
							<p>{t('If you have not received it, please check your junk')}</p>
							<p style={{ margin: '10px 0', color: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor }}>
								E-mail: faleconosco@cvalefidelidade.com.br
							</p>
							<p style={{ marginTop: '0px', color: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor }}>
								{t('FIELD_REGISTER_PHONE')}: (44) 3689-7000
							</p>
						</div>
					</GridItem>
					<GridContainer
						style={{
							padding: '10px 0 0 10px',
							marginBottom: '30px',
						}}
					>
						<GridItem xs={12} sm={12} style={{ textAlign: 'center' }}>
							<Button
								size="sm"
								variant="contained"
								color={'greenButtonColor'}
								style={{
									marginLeft: '5px',
									padding: '8px 35px 8px 35px',
									borderRadius: '5px',
									fontSize: '16px',
								}}
								onClick={handleSendEmail}
							>
								{t('Resend email')}
							</Button>
						</GridItem>
					</GridContainer>
				</PaperContainer>
			</Fade>
		</ModalContainer>
	)
}

ModalResendEmail.propTypes = {
	children: PropTypes.element,
	className: PropTypes.string,
	closeByClick: PropTypes.func,
	handleClose: PropTypes.func,
	handleSendEmail: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	open: PropTypes.bool,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	email: PropTypes.string,
	t: PropTypes.func,
	loading: PropTypes.bool,
}

ModalResendEmail.defaultProps = {
	children: null,
	className: '',
	closeByClick: null,
	handleClose: null,
	handleSendEmail: null,
	maxWidth: '',
	minWidth: '',
	open: false,
	padding: '',
	shadow: '',
	loading: false,
}

export default withTranslation()(ModalResendEmail)
