import { Box } from '@mui/material'
import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../../../../../../../redux/api/themeColors'

export const Header = styled(Box)`
	display: flex;
	align-items: center;
	gap: 10px;
`

export const HeaderIcon = styled.i`
	width: 21px;
	height: 21px;
	font-size: 21px;
	color: ${({ color }) => color};
`

export const HeaderText = styled.div`
	text-align: left;
	font: normal normal bold 20px Roboto;
	letter-spacing: 0px;
	color: #4e4e4e;
	opacity: 1;
`

export const Body = styled(Box)`
	display: flex;
	width: 100%;

	@media (max-width: 768px) {
		flex-direction: column;
	}

	@media (max-width: 1195px) {
		flex-direction: column;
	}
`

export const Image = styled(Box)`
	display: flex;
	background: #fff;
	border-radius: 0px 41px 41px 0px;
	opacity: 1;
	justify-content: center;
	align-items: center;
	min-width: 70px;
	height: 66px;

	img {
		width: 35px;
		height: 35px;
		opacity: 1;

		transform: translateX(-3px);
	}
`

export const OptionsContainer = styled(Box)`
	display: flex;
	width: fit-content;
	height: 85px;
	align-items: center;
	gap: 18px;
	border-radius: ${({ open, border }) => (!open ? '10px 0px 0px 10px' : border ? '0px 0px 0px 10px' : '0px 0px 0px 0px')};

	background: ${({ bg }) => bg || '#E2E2E2'} 0% 0% no-repeat padding-box;

	min-width: 100px;
	width: ${({ open }) => (open ? '333px' : '100%')};
	padding-right: 10px;

	:hover {
		opacity: 1;
		cursor: pointer;
	}

	@media (max-width: 1195px) {
		width: 100%;
		border-radius: 0px;
	}

	cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`

export const CustomSelect = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;

	cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
`

export const Text = styled.div`
	text-align: left;
	font: normal normal normal 15px Roboto;
	letter-spacing: 0px;
	color: #4e4e4e;
	opacity: 1;
`

export const TextDescription = styled.div`
	text-align: left;
	white-space: nowrap;
	font: normal normal bold 20px Roboto;
	letter-spacing: 0px;
	color: #4e4e4e;
	opacity: 1;

	font-size: ${({ open }) => (open ? '22px' : '25px')};
`

export const CustomBox = styled(Box)`
	display: flex;
	flex-direction: column;
	position: absolute;
	min-width: 100px;
	width: 333px;
	z-index: 9999;
`

export const Description = styled.div`
	background-color: ${({ bg }) => bg};
	width: 100%;
	padding: 0px 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	gap: 10px;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: flex-start;
		padding: 15px;
	}

	span {
		text-align: left;
		font: normal normal normal 15px Roboto;
		letter-spacing: 0px;
		color: #4e4e4e;
		opacity: 1;

		font-size: ${({ type }) => (type !== 'default' ? '14px' : '16px')};

		@media (max-width: 768px) {
			padding: 14px 0px;
			align-items: flex-start;
		}

		@media (max-width: 1178px) {
			font-size: 19px;
			padding: 15px 0px;
		}
	}
`

export const CustomButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ bgColor }) => bgColor || '#ffffff'};
	border: 2px solid #ffffff;
	padding: 10px 10px;
	border-radius: 6px;
	cursor: pointer;
	height: 40px;
	opacity: 1;

	span {
		text-align: left;
		font: normal normal bold 15px Segoe UI;
		color: #4e4e4e;
		opacity: 1;
		white-space: nowrap;
	}
`

export const TextContainer = styled.div`
	display: flex;
	gap: 10px;

	@media (max-width: 768px) {
		align-items: flex-start;
	}
`

export const GroupButton = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;

	@media (max-width: 768px) {
		width: 100%;
		align-items: flex-start;
	}
`

export const Icon = styled.i`
	font-size: 22px;
`

export const IconButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	background: #f2f2f2 0% 0% no-repeat padding-box;
	border: 2px solid #ffffff;
	border-radius: 5px;
	opacity: 1;
	height: 40px;
	padding: 10px;
	cursor: pointer;
	color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
	&:hover {
		background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
		color: #ffffff;
	}
`

export const IconContainer = styled.div`
	transform: ${({ open }) => (open ? 'rotate(270deg)' : 'rotate(209deg)')};
	cursor: ${({ disable }) => (disable ? 'not-allowed' : 'pointer')};
	i {
		width: 2em;
		height: 2em;
	}
`
