import React, { Fragment } from 'react'

// @ components
import DivisionOptions from '../Options'
import DivisionTable from './components/DivisionTable'

const DivisionTab = props => {
	return (
		<Fragment>
			<DivisionOptions {...props} />
			<DivisionTable {...props} />
		</Fragment>
	)
}

DivisionTab.defaultProps = {
	selectedDivision: [],
}

export default DivisionTab
