import React, { Fragment } from 'react'

import Responsible from '../../Components/Responsible'
import DepartmentInfos from '../../Components/DepartmentInfos'

export default function FormDepartment({ ...sharedProps }) {
	return (
		<Fragment>
			<DepartmentInfos {...sharedProps} />

			<Responsible {...sharedProps} />
		</Fragment>
	)
}
