/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core'
import React, { Fragment } from 'react'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import * as S from '../styles'
import { useTranslation } from 'react-i18next'
import { formatCNPJ, formatDate, validateValue } from '../../../../../Functions'
import TextInput from '../../../../TextInput'
import CustomDateLeft from '../../../BusinessDivision/Components/CustomDateLeft'
import moment from 'moment'

export default function BusinessInfos({ isEditData, partnerData }) {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()

	const validation = currentDate => {
		const today = moment()

		if (currentDate.isAfter(today)) {
			return false
		}

		return currentDate.isBefore(today)
	}

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={3} sm={12}>
					{!partnerData?.cnpj && isEditData ? (
						<Fragment>
							<Labelitem label="CNPJ" required isEditData />
							<Controller
								control={control}
								name="businessCNPJ"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessCNPJ}
										helperText={t(errors?.businessCNPJ?.message)}
										fullWidth
										id="businessCNPJ"
										name="businessCNPJ"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										onChange={e => {
											e.target.value = formatCNPJ(e.target.value)
											onChange(e)
										}}
										inputProps={{
											maxLength: 18,
										}}
										maxSize={18}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={partnerData?.cnpj}
							/>
						</Fragment>
					) : (
						<Labelitem label="CNPJ" disabled value={validateValue(partnerData?.cnpj)} isEditData={isEditData} />
					)}
				</Grid>
				<Grid item lg={5} sm={12}>
					{!isEditData ? (
						<Labelitem label="Nome fantasia" disabled required value={validateValue(partnerData?.corporateName)} />
					) : (
						<Fragment>
							<Labelitem label="Nome fantasia" required isEditData />
							<Controller
								control={control}
								name="businessName"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessName}
										helperText={t(errors?.businessName?.message)}
										fullWidth
										id="businessName"
										name="businessName"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={55}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={partnerData?.corporateName}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={4} sm={12}>
					<Labelitem label="Razão social" disabled value={validateValue(partnerData?.name)} isEditData={isEditData} />
				</Grid>
				<Grid item lg={3} sm={12}>
					{!partnerData?.foundationDate && isEditData ? (
						<Fragment>
							<Labelitem label="Dt. de fundação" />
							<Controller
								control={control}
								name="businessFoundationDate"
								render={({ onChange, value }) => (
									<CustomDateLeft
										variant="outlined"
										size="small"
										value={value}
										onChange={e => onChange(e)}
										timeFormat={false}
										isValidDate={validation}
										disabled={false}
										maxDate={new Date().getTime()}
										error={errors.businessFoundationDate}
										helperText={errors.businessFoundationDate && errors.businessFoundationDate.message}
									/>
								)}
								defaultValue={formatDate(partnerData?.foundationDate)}
							/>
						</Fragment>
					) : (
						<Labelitem disabled required label="Dt. de fundação" value={validateValue(formatDate(partnerData?.foundationDate))} />
					)}
				</Grid>
				<Grid item lg={9} sm={12}>
					{!partnerData?.identifier && isEditData ? (
						<Fragment>
							<Labelitem label="Identificador" isEditData={isEditData} />
							<Controller
								control={control}
								name="businessID"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessID}
										helperText={t(errors?.businessID?.message)}
										fullWidth
										id="businessID"
										name="businessID"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={18}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={partnerData?.identifier}
							/>
						</Fragment>
					) : (
						<Labelitem label="Identificador" disabled value={validateValue(partnerData?.identifier)} isEditData={isEditData} />
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}
