import React, { useCallback, useEffect, useState } from 'react'
import { InputText } from './styles'

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

const CurrencyInput = ({
	className,
	max,
	onValueChange,
	style = {},
	name,
	value,
	setValue,
	helperText,
	error,
	clear,
	label,
	disabled,
}) => {
	const [originalValueArray, setOriginalValueArray] = useState([])
	const [valueDisplay, setValueDisplay] = useState()


	useEffect(() => {}, [onValueChange])


	useEffect(() => {
		const originalValue =
			originalValueArray.length === 0
				? 0
				: Number.parseInt(originalValueArray.join(''))
		setValue(name, originalValue / 100)
	}, [originalValueArray])

	useEffect(() => {
		if (clear === true) {
			setOriginalValueArray([])
			setValueDisplay()
		}
	}, [clear])

	const handleKeyDown = useCallback(
		(e) => {
			const { key, keyCode } = e

			if (keyCode === 8) {
				let newOriginalValueArray = [...originalValueArray]
				newOriginalValueArray.pop()
				setOriginalValueArray(newOriginalValueArray)
			} else {
				let newKey = key.replace(/[^0-9]/g, '')
				if (newKey != '')
					setOriginalValueArray([...originalValueArray, key])
			}

			if (
				(value === 0 && !VALID_FIRST.test(key)) ||
				(value !== 0 &&
					!VALID_NEXT.test(key) &&
					keyCode !== DELETE_KEY_CODE)
			) {
				return
			}

			const valueString = value.toString()

			let nextValue
			if (keyCode !== DELETE_KEY_CODE) {
				const nextValueString =
					value === 0 ? key : `${valueString}${key}`
				nextValue = Number.parseInt(nextValueString, 10)
			} else {
				const nextValueString = valueString.slice(0, -1)
				nextValue =
					nextValueString === ''
						? 0
						: Number.parseInt(nextValueString, 10)
			}
			if (nextValue > max) {
				return
			}
			onValueChange(nextValue)
		},
		[max, onValueChange, value],
	)

	const handleChange = useCallback(() => {
		setValueDisplay(
			value.toLocaleString('pt-BR', {
				style: 'currency',
				currency: 'BRL',
			}),
		)
	}, [value])

	return (
		<InputText
			focused={valueDisplay}
			label={label}
			select={clear}
			fullWidth
			size="small"
			autoComplete="off"
			variant="outlined"
			className={className}
			inputMode="numeric"
			onChange={handleChange}
			onKeyDown={handleKeyDown}
			style={style}
			name={name}
			value={valueDisplay}
			error={error}
			helperText={error ? helperText : ''}
		/>
	)
}

export default CurrencyInput

// error={
// 	(submitConsumer && value === 0) ||
// 	(submit && value === 0 && listSize === 0)
// }
// helperText={
// 	(submitConsumer && value === 0) ||
// 	(submit && value === 0 && listSize === 0) ?
// 	 helperText : ''
// }
