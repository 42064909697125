import styled from 'styled-components'
import { getThemeColorFromLocalStorage, ColorLuminance } from '../../../../redux/api/themeColors'

export const Container = styled.div`

	td:nth-child(2), th:nth-child(2){
		text-align: left;
	}
    .MuiToolbar-regular{
        min-height: 25px;
    }
`
export const Titles = styled.div`
	margin-bottom: 1.563rem;
`

export const ButtonAction = styled.div`
	height: 40px;
    width: 40px;
    min-width: 40px;
    border: 2px solid #fff;
    background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
    font-size: 20px;
    display: flex;
    color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.textColor};;
    align-items: center;
    justify-content: center;
	border-radius: 6px;

	:hover{
		background-color:${ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5,)};
	}
`
