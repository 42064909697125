import React, { useEffect } from 'react'
import DocumentTitle from 'react-document-title'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Breadcrumb, MuiBox, Button, MuiSnack, MuiStack } from '../../../components/storybook'
import { FilterNegotiation } from './components/FilterNegotiation'
import { TableNegotiations } from './components/TableNegotiations'
import useNegotiation from './Hooks/useNegotiation'
import { getDefaultColor } from '../../Partner/PartnerProfile/Functions'
import { Link } from 'react-router-dom'
import { CancelNegotiationModal } from './components/CancelNegotiationModal'
import { NotificateNegotiationModal } from './components/NotificateNegotiationModal'
import { grouperOptions } from './Functions'
import { getProgramTitle } from '../../../utils/utils'

export function Negotiation() {
	const { t } = useTranslation()

	const {
		negotiationData,
		isLoadingData,
		getNegotiationData,
		getNegotiationFilter,
		tablePagination,
		getPaginatedNegotiations,
		negotiationModalOpen,
		setNegotiationModalOpen,
		cancelNegotiationModalOpen,
		setCancelNegotiationModalOpen,
		isFilterData,
		convertOptions,
		getNegotiationNotification,
		selectedItem,
		setSelectedItem,
		notificationMessage,
		changeNotificationMessage,
		handleCloseStack,
		cancelNegotiation,
		cancelItem,
		setCancelItem,
		getPartnerBranches,
		partnerBranches,
		getSelersList,
		selersList,
	} = useNegotiation()

	useEffect(() => {
		Promise.all([getPartnerBranches(), getNegotiationData(), getSelersList()])
	}, [])

	const defaultColor = getDefaultColor()

	useEffect(() => {
		const styleTag = document.createElement('style')
		styleTag.innerHTML = `
		  .custom-background {
			background-color: ${defaultColor} !important;
		  }
		`
		document.head.appendChild(styleTag)
		return () => {
			document.head.removeChild(styleTag)
		}
	}, [defaultColor])

	return (
		<>
			<DocumentTitle title={getProgramTitle()} />
			<MuiStack direction="row" justifyContent="space-between" alignItems="center">
				<Breadcrumb hrf={[]} items={[t('MENU_MOVEMENT'), t('NEGOTIATION.NEGOTIATION')]} />

				<Link to={`/createNegotiation`}>
					<Button
						text={t('NEGOTIATION.NEW_NEGOTIATION')}
						typeVariation="TextButton"
						className="custom-background"
						sx={{ color: '#FFFFFF!important', textTransform: 'unset', fontWeight: 400 }}
					/>
				</Link>
			</MuiStack>

			{notificationMessage && (
				<MuiSnack
					anchorOrigin={{
						horizontal: 'right',
						vertical: 'top',
					}}
					open={notificationMessage?.open}
					autoHideDuration={6000}
					closeNotification={handleCloseStack}
					message={notificationMessage?.message}
					onClose={handleCloseStack}
					type={notificationMessage?.type ?? 'success'}
				/>
			)}

			<NotificateNegotiationModal
				open={negotiationModalOpen}
				handleClose={() => setNegotiationModalOpen(false)}
				onConfirm={() => getNegotiationNotification(selectedItem)}
			/>
			<CancelNegotiationModal
				open={cancelNegotiationModalOpen}
				handleClose={() => setCancelNegotiationModalOpen(false)}
				onConfirm={() => cancelNegotiation(cancelItem)}
			/>

			<FilterNegotiation
				selersList={selersList}
				partnerBranches={partnerBranches}
				getNegotiationFilter={getNegotiationFilter}
				negotiationData={negotiationData}
				convertOptions={convertOptions}
				grouperOptions={grouperOptions}
			/>
			<TableNegotiations
				negotiationData={negotiationData}
				tablePagination={tablePagination}
				getPaginatedNegotiations={getPaginatedNegotiations}
				isLoadingData={isLoadingData}
				onNotification={rowData => {
					setSelectedItem(rowData?.id)
					setNegotiationModalOpen(true)
				}}
				onCancel={rowData => {
					setCancelNegotiationModalOpen(true)
					setSelectedItem(rowData.id)
					setCancelItem(rowData.id)
				}}
				handleDetails={() => { }}
				isFilterData={isFilterData}
			/>
		</>
	)
}
