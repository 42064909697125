import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import { Tooltip } from '@mui/material'

const DivisionOptions = ({ multiple, setMultiple, setSelectedDivision, setParameterization, editMode, setEditMode, selectedDivisionIdGen }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<S.Content>
				{!editMode && (
					<Tooltip title={t('Editar')} placement="top" arrow>
						<Buttons className="softBlue" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
					</Tooltip>
				)}

				{editMode && (
					<>
						<Tooltip title={t('Parametrização Múltipla')} placement="top" arrow>
							<Buttons className="softBlue" Icon="icon-bot_parametrizar_multiplo" onClick={() => setMultiple(!multiple)} />
						</Tooltip>
						<Tooltip title={t('Configurar parametrização múltipla')} placement="top" arrow>
							<Buttons
								className="blueAction"
								Icon="icon-bot_parametrizar"
								disabled={!multiple || selectedDivisionIdGen?.length === 0}
								onClick={() =>
									setParameterization({
										show: true,
										isMultiple: true,
										type: 'points',
									})
								}
							/>
						</Tooltip>

						<Tooltip Tooltip title={t('Cancelar')} placement="top" arrow>
							<Buttons
								className="cancel"
								Icon="icon-bot_fechar"
								// disabled={!multiple}
								onClick={() => {
									setParameterization({
										show: false,
										isMultiple: false,
										type: '',
									})
									{
										multiple && setMultiple(!multiple)
									}
									setSelectedDivision([])
									setEditMode(false)
								}}
							/>
						</Tooltip>
					</>
				)}
			</S.Content>
		</S.Container>
	)
}

DivisionOptions.propTypes = {
	multiple: PropTypes.bool,
	setMultiple: PropTypes.func,
	setParameterization: PropTypes.func,
	setSelectedDivision: PropTypes.func,
	editMode: PropTypes.bool,
	selectedDivisionIdGen: PropTypes.array,
	setEditMode: PropTypes.func,
}

export default DivisionOptions
