import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'

//@components
import Section from 'components/Section'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader'

//@Utils
import { GetProductsById } from '../../../../redux/api/productPage'
import * as S from './styles'
import MonetaryInput from '../../../../components/MonetaryInput'

const Details = ({ /*productData,*/ setProductData, reloadProduct, setReloadProduct }) => {
	const { t } = useTranslation()
	const { control, setValue } = useForm({})

	const [loading, setLoading] = useState(false)

	useEffect(() => {
		const url = new URL(window.location.href)
		const id = url.pathname.split('/').pop()

		if (id) {
			setLoading(true)
			GetProductsById(id)
				.then(data => {
					setProductData(data)
					setValue('sku', data.sku)
					setValue('name', data.name)
					setValue('barcode', data.barcode[0])
					setValue('price', data.price)
					setValue('category', data.category.value)
					setValue('family', data.family.value)
					setValue('manufacturer', data.manufacturer.value)
					setValue('brand', data.brand.value)
					setValue('unit', data.unit)
				})
				.catch(error => {
					console.error('Erro ao obter dados do produto:', error)
				})
				.finally(() => {
					setLoading(false)
					setReloadProduct(false)
				})
		} else {
			console.error('ID inválido na URL')
		}
	}, [reloadProduct])

	return (
		<S.Container>
			{loading && <Loader />}
			<div className="title">
				<Section title={t('SECTION_PRODUCT_DETAIL')} />
			</div>
			<GridContainer className="GridContainerButtons">
				<Button className="newButtonsChange" disabled>
					<i className="icon-bot_editar" />
				</Button>
				<Link to="/product">
					<Button className="newButtons">
						<i className="icon-bot_voltar" />
					</Button>
				</Link>
			</GridContainer>
			<GridContainer className="GridContainer">
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="sku"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="sku"
								name="sku"
								onChange={onChange}
								label={t('FIELD_CODE_SKU')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="name"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="name"
								name="name"
								onChange={onChange}
								label={t('CLAIM_CARD_PRODUCT')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="barcode"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="barcode"
								name="barcode"
								onChange={onChange}
								label={t('FIELD_BARCODE')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="price"
						control={control}
						defaultValue={''}
						render={({ value, onChange, name }) => (
							<MonetaryInput
								fullWidth
								max={1000000}
								name={name}
								value={value}
								onChange={e => {
									onChange(e)
								}}
								disabled
								onValueChange={() => console.log('')}
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="category"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="category"
								name="category"
								onChange={onChange}
								label={t('FIELD_CATEGORY')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="family"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="family"
								name="family"
								onChange={onChange}
								label={t('FAMILY')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="manufacturer"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="manufacturer"
								name="manufacturer"
								onChange={onChange}
								label={t('MANUFACTURER')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="brand"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="brand"
								name="brand"
								onChange={onChange}
								label={t('FIELD_BRAND')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4} className="gridDiv">
					<Controller
						name="unit"
						control={control}
						defaultValue={''}
						render={({ value, onChange }) => (
							<S.CssTextField
								id="unit"
								name="unit"
								onChange={onChange}
								label={t('FIELD_UNITY')}
								value={value}
								variant="outlined"
								size="small"
								margin="dense"
								fullWidth
								disabled
							/>
						)}
					/>
				</GridItem>
			</GridContainer>
		</S.Container>
	)
}

Details.propTypes = {
	productData: PropTypes.object,
	setProductData: PropTypes.func,
	reloadProduct: PropTypes.bool,
	setReloadProduct: PropTypes.func,
}

export default Details
