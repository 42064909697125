import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

// Redux
import { GetAccountById } from '../../../redux/api/accounts'

// Material
import { Grid, Box } from '@material-ui/core'

// Components
import { Card } from './Card'

const ItemText = styled.h6`
	margin: 0;
	text-transform: none;
	font-size: 0.75rem;
	text-transform: lowercase;
	:first-letter {
		text-transform: uppercase;
	}
`

// Data item
function Item({ title, value }) {
	return (
		<div>
			<ItemText style={{ color: '#B4B4B4' }}>{title}</ItemText>
			<ItemText style={{ color: '#717171' }}>{value}</ItemText>
		</div>
	)
}

function Loading() {
	return (
		<Grid item xs={12} sm={12} spacing={2}>
			<Grid item xs={9} sm={9}>
				<Skeleton height={20} />
			</Grid>
			<Grid item xs={3} sm={3}>
				<Skeleton height={20} />
			</Grid>

			<Grid item xs={12} sm={12}>
				<Skeleton height={20} />
			</Grid>

			<Grid item xs={9} sm={9}>
				<Skeleton height={20} />
			</Grid>
			<Grid item xs={3} sm={3}>
				<Skeleton height={20} />
			</Grid>

			<Grid item xs={12} sm={12}>
				<Skeleton height={20} />
			</Grid>
		</Grid>
	)
}

export function AccountData() {
	const { t } = useTranslation()
	const { id } = useParams()

	const [data, setData] = useState(null)

	useEffect(() => {
		if (!!id) {
			RequestApi()
		}
	}, [])

	async function RequestApi() {
		try {
			const response = await GetAccountById(id)

			setData({
				name: response.name,
				description: response.description,
				bank: response.bank
					? `${response.bank.code}-${response.bank.name}`
					: '-',
				agency: response.branch || '-',
				status: response.isActive ? 'Ativa' : 'Inativa',
				number:
					!!response.number && !!response.numberDigit
						? `${response.number}-${response.numberDigit}`
						: '-',
			})
		} catch (error) { }
	}

	return (
		<Card
			bg="#f6f6f6"
			title="Dados da conta"
			iconBg="#74CCDA"
			icon="icon-menu_minha_conta"
		>
			{data ? (
				<Grid container xs={12} sm={12} spacing={2}>
					<Grid item xs={9} sm={9}>
						<Item title={t('ACCOUNT_NAME')} value={data.name} />
					</Grid>
					<Grid item xs={3} sm={3}>
						<Item title={t('FIELD_STATUS')} value={data.status} />
					</Grid>

					<Grid item xs={12} sm={12}>
						<Item
							title={t('ACCOUNT_DESCRIPTION')}
							value={data.description}
						/>
					</Grid>

					<Grid item xs={9} sm={9}>
						<Item title={t('FIELD_BANK')} value={data.bank} />
					</Grid>
					<Grid item xs={3} sm={3}>
						<Item title={t('FIELD_AGENCY')} value={data.agency} />
					</Grid>

					<Grid item xs={12} sm={12}>
						<Item
							title={t('FIELD_ACCOUNT_NUMBER')}
							value={data.number}
						/>
					</Grid>
				</Grid>
			) : (
				<Loading />
			)}
		</Card>
	)
}
