import styled from 'styled-components'
import { Button } from '@material-ui/core';
import { getThemeColorFromLocalStorage, ColorLuminance } from '../../../../../../../redux/api/themeColors'

export const Container = styled.div`
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
`

export const Titles = styled.div`
    color: ${getThemeColorFromLocalStorage()?.titles?.main?.textColor};
    font-weight: 500;
    font-size: 15px;
    display: flex;
    align-items: center;	
`

export const DataContainer = styled.div`
    width: 50%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 25px;
`

export const InfoContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
    margin-bottom: 25px;
`

export const ContainerInfo = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

export const ContainerDescrip = styled.div`
    width: 25%;
    display: flex;
    justify-content: start;
    font-size: 12px;
    transform: translateY(-30px);
`

export const ConfigCont = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 45%;
`
export const ConfigInfo = styled.div`
    width: 100%;
    font-weight: 400;
    font-size: 15px;
`
export const ConfigInfoTitle= styled.div`
    width: 100%;
    font-size: 14px;
`

export const LineComponent = styled.div`
    border-bottom: 1px solid #c3c3c3;
`

export const ButtonClose = styled(Button)`
	height: 40px !important;
    width: 100px !important;
    min-width: 40px !important;
    border: 1px solid #c3c3c3 !important;
    background-color: #fff !important;
    font-size: 14px !important;
    display: flex !important;
    color: #333 !important;
    align-items: center !important;
    justify-content: center !important;
	border-radius: 6px !important;

	:hover{
		background-color: #F05B4F !important;
        color: #fff !important;
        border: 1px solid #F05B4F !important;
	}
`