import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel } from '@mui/material'
import GenerationPointsInfo from '../GenerationPointsInfo'

const GenerationPoints = ({ parameterization, setOpen, productData, viewMode, typeOfRedeem }) => {
	const { t } = useTranslation()

	return (
		<S.Container viewMode={viewMode}>
			<FormLabel sx={{ width: '100%' }}>
				<span className="FormLabel">{t('Valores baseados na conversão personalizada')}</span>
				<GenerationPointsInfo typeOfRedeem={typeOfRedeem} parameterization={parameterization} setOpen={setOpen} productData={productData} />
			</FormLabel>
		</S.Container>
	)
}

GenerationPoints.propTypes = {
	setOpen: PropTypes.func,
	viewMode: PropTypes.bool,
	typeOfRedeem: PropTypes.string,
	productData: PropTypes.object,
	typeOfPoints: PropTypes.string,
	parameterization: PropTypes.array,
}

export default GenerationPoints
