import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { AntTab, AntTabs, useStyles } from './styles'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: '30x 0px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

export default function CustomizedTabs({ tabs, active, handleTabChange }) {
	const classes = useStyles()

	return (
		<div>
			<div className={classes.root}>
				<div className={classes.demo1}>
					<AntTabs
						value={active}
						onChange={handleTabChange}
						aria-label="ant example"
					>
						{tabs.map((tab, index) => (
							<AntTab
								key={index}
								label={tab.label}
								disabled={tab.disabled}
							/>
						))}
					</AntTabs>
				</div>

				{tabs.map((tab, index) => (
					<TabPanel key={index} value={active} index={index}>
						{tab.tabContent}
					</TabPanel>
				))}
			</div>
		</div>
	)
}

CustomizedTabs.defaultProps = {
	active: 0,
	color: 'primary',
}

CustomizedTabs.propTypes = {
	classes: PropTypes.object,
	active: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			tabContent: PropTypes.node,
			disabled: PropTypes.bool,
		}),
	),
	handleTabChange: PropTypes.func,
}
