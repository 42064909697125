/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Container } from "../../styles";
import ComponentShape from "../../components/ComponentShape";
import { Button } from "../../../Buttons";
import { MuiStack } from "../../../Stack";
import { MuiTooltip } from "../../../Tooltip";
import { GridContainer, GridItem } from "../../../Grid";
import { MuiTypography } from "../../../Typography";
export default function OrganogramaTemplate(_a) {
    var sharedProps = __rest(_a, []);
    var title = sharedProps.title, divisionsData = sharedProps.divisionsData, handleClick = sharedProps.handleClick, defaultColor = sharedProps.defaultColor, titleTooltipEdit = sharedProps.titleTooltipEdit, titleFullScreenExit = sharedProps.titleFullScreenExit, titleFullScreen = sharedProps.titleFullScreen, titleZoomIn = sharedProps.titleZoomIn, titleZoomOut = sharedProps.titleZoomOut, hasBorder = sharedProps.hasBorder, zoomLevel = sharedProps.zoomLevel, panX = sharedProps.panX, panY = sharedProps.panY, isDragging = sharedProps.isDragging, zoomIn = sharedProps.zoomIn, zoomOut = sharedProps.zoomOut, handleDrag = sharedProps.handleDrag, handleFullScreen = sharedProps.handleFullScreen, validateIfRemoveIndicator = sharedProps.validateIfRemoveIndicator, getErrorsByDivision = sharedProps.getErrorsByDivision, toggleFullScreen = sharedProps.toggleFullScreen;
    return (_jsx(Container, __assign({ defaultColor: defaultColor }, { children: _jsxs("div", __assign({ style: { fontFamily: "\"Roboto\", \"Helvetica\", \"Arial\", sans-serif" } }, { children: [title && (_jsx(MuiTypography, __assign({ variant: "h1", "data-testid": "title", fontSize: 20, fontWeight: 600 }, { children: title }))), _jsx("div", __assign({ style: {
                        width: "100%",
                        height: handleFullScreen ? "300vh" : "100%",
                        marginTop: "20px",
                        overflow: "hidden",
                        border: !handleFullScreen && hasBorder ? "1px solid #D9D9D9" : 0,
                        borderRadius: "10px",
                        padding: "10px",
                    } }, { children: _jsxs(GridContainer, __assign({ sx: { padding: 3 } }, { children: [_jsx(GridItem, __assign({ lg: 11.5, sm: 11.5, md: 11.5 }, { children: _jsx("div", __assign({ onMouseMove: handleDrag, style: {
                                        transform: "scale(".concat(zoomLevel, ") translate(").concat(panX, "px, ").concat(panY, "px)"),
                                        width: "100%",
                                        height: "100%",
                                        minHeight: "600px",
                                        minWidth: "1200px",
                                        overflow: "visible",
                                        padding: "20px",
                                        cursor: isDragging ? "grabbing" : "default",
                                    } }, { children: divisionsData === null || divisionsData === void 0 ? void 0 : divisionsData.map(function (item, index) {
                                        var _a, _b;
                                        return (_jsxs("div", __assign({ className: "levelOne" }, { children: [_jsx(ComponentShape, { item: item, name: item === null || item === void 0 ? void 0 : item.name, level: "levelOne", category: (_a = item === null || item === void 0 ? void 0 : item.segmentationType) === null || _a === void 0 ? void 0 : _a.value, handleClick: function (value) { return handleClick(value); }, divisionsData: divisionsData, defaultColor: defaultColor, handleFullScreen: handleFullScreen, "data-testid": "matriz-item" }), (_b = item === null || item === void 0 ? void 0 : item.branches) === null || _b === void 0 ? void 0 : _b.map(function (levelTwo, index) {
                                                    var _a, _b;
                                                    return (_jsxs("div", __assign({ className: "levelTwo ".concat(validateIfRemoveIndicator(levelTwo) && "removelastindicator"), style: {
                                                            marginLeft: "200px",
                                                            minHeight: (item === null || item === void 0 ? void 0 : item.branches.length) > 0 ? (item === null || item === void 0 ? void 0 : item.branches.length) + 1 * "250px" : "350px",
                                                        }, "data-testid": "branches-level-one" }, { children: [_jsx(ComponentShape, { item: levelTwo, name: levelTwo === null || levelTwo === void 0 ? void 0 : levelTwo.name, category: (_a = levelTwo === null || levelTwo === void 0 ? void 0 : levelTwo.segmentationType) === null || _a === void 0 ? void 0 : _a.value, handleClick: function (value) { return handleClick(value); }, divisionsData: divisionsData, defaultColor: defaultColor, handleFullScreen: handleFullScreen, titleTooltipEdit: titleTooltipEdit, divisionsErrors: getErrorsByDivision(levelTwo) }), (_b = levelTwo === null || levelTwo === void 0 ? void 0 : levelTwo.branches) === null || _b === void 0 ? void 0 : _b.map(function (levelThree, index) {
                                                                var _a, _b;
                                                                return (_jsxs("div", __assign({ className: "levelThree multiple-items ".concat(validateIfRemoveIndicator(levelTwo) && "removelastindicator"), style: {
                                                                        marginLeft: "200px",
                                                                        minHeight: (levelTwo === null || levelTwo === void 0 ? void 0 : levelTwo.branches.length) > 0 ? (levelTwo === null || levelTwo === void 0 ? void 0 : levelTwo.branches.length) + 1 * "250px" : "350px",
                                                                    } }, { children: [_jsx(ComponentShape, { item: levelThree, name: levelThree === null || levelThree === void 0 ? void 0 : levelThree.name, category: (_a = levelThree === null || levelThree === void 0 ? void 0 : levelThree.segmentationType) === null || _a === void 0 ? void 0 : _a.value, handleClick: function (value) { return handleClick(value); }, divisionsData: divisionsData, defaultColor: defaultColor, handleFullScreen: handleFullScreen, titleTooltipEdit: titleTooltipEdit, divisionsErrors: getErrorsByDivision(levelThree) }), (_b = levelThree === null || levelThree === void 0 ? void 0 : levelThree.branches) === null || _b === void 0 ? void 0 : _b.map(function (levelFour, index) {
                                                                            var _a, _b;
                                                                            return (_jsxs("div", __assign({ className: "levelFour ".concat(validateIfRemoveIndicator(levelThree) && "removelastindicator"), style: {
                                                                                    marginLeft: "200px",
                                                                                    minHeight: (levelThree === null || levelThree === void 0 ? void 0 : levelThree.branches.length) > 0 ? (levelThree === null || levelThree === void 0 ? void 0 : levelThree.branches.length) + 1 * "250px" : "350px",
                                                                                } }, { children: [_jsx(ComponentShape, { item: levelFour, name: levelFour === null || levelFour === void 0 ? void 0 : levelFour.name, category: (_a = levelFour === null || levelFour === void 0 ? void 0 : levelFour.segmentationType) === null || _a === void 0 ? void 0 : _a.value, handleClick: function (value) { return handleClick(value); }, divisionsData: divisionsData, defaultColor: defaultColor, handleFullScreen: handleFullScreen, titleTooltipEdit: titleTooltipEdit === null || titleTooltipEdit === void 0 ? void 0 : titleTooltipEdit.levelFour, divisionsErrors: getErrorsByDivision(levelFour) }), (_b = levelFour === null || levelFour === void 0 ? void 0 : levelFour.branches) === null || _b === void 0 ? void 0 : _b.map(function (levelFive, index) {
                                                                                        var _a;
                                                                                        return (_jsx("div", __assign({ className: "levelFive ".concat(validateIfRemoveIndicator(levelFour) && "removelastindicator"), style: {
                                                                                                marginLeft: "200px",
                                                                                                minHeight: (levelFour === null || levelFour === void 0 ? void 0 : levelFour.branches.length) > 0 ? (levelFour === null || levelFour === void 0 ? void 0 : levelFour.branches.length) + 1 * "250px" : "350px",
                                                                                            } }, { children: _jsx(ComponentShape, { item: levelFive, name: levelFive === null || levelFive === void 0 ? void 0 : levelFive.name, category: (_a = levelFive === null || levelFive === void 0 ? void 0 : levelFive.segmentationType) === null || _a === void 0 ? void 0 : _a.value, handleClick: function (value) { return handleClick(value); }, divisionsData: divisionsData, defaultColor: defaultColor, handleFullScreen: handleFullScreen, titleTooltipEdit: titleTooltipEdit === null || titleTooltipEdit === void 0 ? void 0 : titleTooltipEdit.levelFive, divisionsErrors: getErrorsByDivision(levelFive) }) }), index));
                                                                                    })] }), index));
                                                                        })] }), index));
                                                            })] }), index));
                                                })] }), index));
                                    }) })) })), _jsx(GridItem, __assign({ lg: 0.5, sm: 0.5, md: 0.5 }, { children: _jsxs(MuiStack, __assign({ direction: "column", alignItems: "center", spacing: 1 }, { children: [handleFullScreen ? (_jsx(MuiTooltip, __assign({ title: titleFullScreenExit !== null && titleFullScreenExit !== void 0 ? titleFullScreenExit : "Sair da tela cheia", arrow: true, sx: {
                                                zIndex: handleFullScreen && 11000,
                                            } }, { children: _jsx(Button, { onClick: function () { return toggleFullScreen(); }, customType: "fullScreenExit", typeVariation: "CustomIconButton" }) }))) : (_jsx(MuiTooltip, __assign({ title: titleFullScreen !== null && titleFullScreen !== void 0 ? titleFullScreen : "Tela cheia", sx: {
                                                zIndex: handleFullScreen && 11000,
                                            } }, { children: _jsx(Button, { onClick: function () { return toggleFullScreen(); }, customType: "fullScreen", typeVariation: "CustomIconButton" }) }))), _jsx(MuiTooltip, __assign({ title: titleZoomIn !== null && titleZoomIn !== void 0 ? titleZoomIn : "Aumentar zoom", sx: {
                                                zIndex: handleFullScreen && 11000,
                                            } }, { children: _jsx(Button, { customType: "openAccordion", typeVariation: "CustomIconButton", onClick: zoomIn }) })), _jsx(MuiTooltip, __assign({ title: titleZoomOut !== null && titleZoomOut !== void 0 ? titleZoomOut : "Diminuir zoom", sx: {
                                                zIndex: handleFullScreen && 11000,
                                            } }, { children: _jsx(Button, { customType: "closeAccordion", typeVariation: "CustomIconButton", onClick: zoomOut }) }))] })) }))] })) }))] })) })));
}
