import { cardTitle } from 'assets/jss/material-dashboard-pro-react.jsx'
import customSelectStyle from 'assets/jss/material-dashboard-pro-react/customSelectStyle.jsx'
import customCheckboxRadioSwitch from 'assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx'
import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle'
import { germiniDefaultColors } from '../../material-dashboard-pro-react'

import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

const getThemeColor = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().navigation) {
			if (getThemeColorFromLocalStorage().navigation.buttons) {
				return getThemeColorFromLocalStorage().navigation.buttons
					.elementsColor
			}
		}
	}
	return germiniDefaultColors[0]
}

const extendedFormsStyle = {
	...customCheckboxRadioSwitch,
	...customSelectStyle,
	cardTitle,
	cardIconTitle: {
		...cardTitle,
		marginTop: '15px',
		marginBottom: '0px',
	},
	label: {
		cursor: 'pointer',
		paddingLeft: '0',
		color: germiniDefaultColors[5],
		fontSize: '14px',
		lineHeight: '1.428571429',
		fontWeight: '400',
		display: 'inline-flex',
	},
	zebra: {
		'&:nth-child(even)': {
			backgroundColor: '#f2f2f2',
		},
	},
	noPadding: {
		padding: '0 !important',
	},
	mrAuto: {
		marginRight: 'auto',
	},
	mlAuto: {
		marginLeft: 'auto',
	},
	...buttonStyle,
	filterPlusButton: {
		marginRight: '5px',
		padding: '4px',
		color: `${getThemeColor()}`,
		border: '1px solid',
		borderColor: `${getThemeColor()}`,
		'&:hover': {
			color: '#fff',
			background: `${getThemeColor()}`,
		},
	},
	filterForm: {
		margin: 'auto',
		background: '#F6F6F6',
		borderRadius: '5px',
		padding: '5px',
		paddingTop: '20px',
		width: '100%',
		marginBottom: '15px',
	},
}

export default extendedFormsStyle
