// Material
import { Icon, Tooltip } from '@material-ui/core'
// Components
import Section from 'components/Section'
// Utils
import moment from 'moment'
import React, { createRef, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { numberFormatText } from 'utils/utils'
import CustomMaterialTable from '../../../../../../components/CustomMaterialTable/CustomMaterialTable'
// Redux
import { GetResidualBalance, ReleaseResidualBalance } from '../../../../../../redux/api/accounts'
import { GetCurrentPartner } from '../../../../../../redux/api/user'
// Context
import { useAccounts } from '../../../../hooks/useAccounts'
import { Layout } from '../../Layout'
import { ModalConfirmPassword } from '../ModalConfirmPassword'
// Style & Icons
import { StatusIcon } from './StatusIcon'
import { Form, GridButton, TableContainer, TitleStyled } from './styles'
import Button from 'components/CustomButtons/Button.jsx'

import Loader from 'components/Loader/Loader.jsx'
import { ShowLoader, HideLoader } from 'redux/actions'
import { useDispatch } from 'react-redux'
import {envs}  from '../../../../../../redux/api/windowServerData'



export function ResidualReserved({ isOpen }) {
	const { closeResidualReserved, setValueTypeFilter, openSnack, openErrorSnack } = useAccounts()
	const { t } = useTranslation()
	const tableRef = createRef()
	const { id } = useParams()
	const dispatch = useDispatch()

	const [isPwdModalOpen, setIsPwdModalOpen] = useState(false)
	const [bankAccountReserveId, setBankAccountReserveId] = useState('')
	const [currentBalanceValue, setCurrentBalanceValue] = useState(0)

	const handleGetResidualReserved = useCallback(async (pageSize, page) => {
		const res = await GetResidualBalance(id, pageSize, page)

		return res
	}, [])

	async function handleConfirmPasswordModal(data) {
		setIsPwdModalOpen(false)

		const partnerId = await GetCurrentPartner().then(res => {
			return res.id
		})

		const body = {
			bankAccountReserveId: bankAccountReserveId,
			partnerId: partnerId,
		}

		let res
		try {
			dispatch(ShowLoader(<Loader />))
			res = await ReleaseResidualBalance(body)
		} catch (error) {
			console.error(error)
		} finally {
			dispatch(HideLoader)
		}

		if (res.data.success === true) {
			openSnack()
		} else {
			openErrorSnack()
		}

		setValueTypeFilter({
			dateFilterFrom: null,
			dateFilterTo: null,
			accountName: '',
			status: '',
		})

		closeResidualReserved()
	}

	return (
		<Layout isOpen={isOpen}>
			<ModalConfirmPassword
				text={`Tem certeza que deseja liberar o saldo de ${currentBalanceValue.toLocaleString('pt-BR', {
					style: 'currency',
					currency: 'BRL',
				})} ?`}
				subText={`O saldo liberado será disponibilizado para novas reservas.`}
				open={isPwdModalOpen}
				onCancel={() => setIsPwdModalOpen(false)}
				onConfirm={value => handleConfirmPasswordModal(value)}
			/>

			<Form>
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'space-between',
						width: '100%',
					}}
				>
					<Section title="Saldo residual reservado" style={{ flexGrow: 1 }} />

					<Button
						color="greenButtonClose"
						size="sm"
						style={{
							marginLeft: '0.938rem',
						}}
						startIcon={<i className="icon-ico_cancelar" style={{ fontSize: 15 }} />}
						onClick={closeResidualReserved}
					>
						{t('BUTTON_CLOSE')}
					</Button>
				</div>

				<TableContainer container xs={12} sm={12}>
					<CustomMaterialTable
						fullWidth
						tableRef={tableRef}
						style={{
							width: '100%',
							borderBottom: '1px solid #C8C8C8',
							borderRadius: '10px 10px 0 0',
						}}
						noToolbar
						options={{
							emptyRowsWhenPaging: false,
							search: false,
							toolBar: false,
							pageSize: 5,
							rowStyle: { backgroundColor: '#fff' },
						}}
						columns={[
							{
								title: 'Data',
								field: 'date',
								width: '37.5%',
								render: props => moment(props.createdAt, 'YYYY-MM-DD').format('DD/MM/YYYY'),
							},
							{
								title: 'Operação',
								field: 'operation',
								width: '37.5%',
								cellStyle: {
									textAlign: 'left',
								},
								headerStyle: {
									textAlign: 'left',
								},
								render: props => (
									<TitleStyled>
										<h6>{t(props.operation.value)}</h6>
										<h6>{props.description}</h6>
									</TitleStyled>
								),
							},
							{
								title: 'Reserva inicial',
								field: 'reserve',
								width: '5%',
								render: props => (
									<span
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '7px',
										}}
									>
										<StatusIcon type="reserve" />

										{numberFormatText(
											props.initialReserve,
											localStorage.getItem('currencySymbol')
												? localStorage.getItem('currencySymbol')
												: // : envs
												  // 	.REACT_APP_CURRENCY_SYMBOL,
												  envs.REACT_APP_CURRENCY_SYMBOL,
											2,
										)}
									</span>
								),
							},
							{
								title: 'Saldo residual',
								field: 'residual',
								width: '5%',
								render: props => (
									<span
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '7px',
										}}
									>
										<StatusIcon type="residual" />

										{numberFormatText(
											props.residualBalance,
											localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
											2,
										)}
									</span>
								),
							},
							{
								title: 'Ação',
								field: 'actions',
								width: '20%',
								cellStyle: {
									textAlign: 'end',
								},
								headerStyle: {
									textAlign: 'end',
								},
								sorting: false,
								render: props => (
									<>
										<Tooltip title={t(props.operation.value)}>
											<GridButton
												justIcon
												size="sm"
												title={t(props.operation.value)}
												color="greenButtonColor"
												style={{
													margin: 0,
												}}
												onClick={() => {
													setBankAccountReserveId(props.bankAccountReserveId)
													setCurrentBalanceValue(props.residualBalance)
													setIsPwdModalOpen(true)
												}}
											>
												<Icon
													className="icon-acao_liberar_saldo"
													style={{
														display: 'inline-flex',
														width: '100%',
														height: '100%',
														fontSize: '24px',
														alignItems: 'center',
														justifyContent: 'center',
													}}
												/>
											</GridButton>
										</Tooltip>
									</>
								),
							},
						]}
						data={query =>
							new Promise(resolve => {
								handleGetResidualReserved(query.pageSize, query.page + 1).then(result => {
									resolve({
										data: result.results,
										page: result.currentPage - 1,
										totalCount: result.recordCount,
										pageSize: result.pageSize,
									})
								})
							})
						}
					/>
				</TableContainer>
			</Form>
		</Layout>
	)
}
