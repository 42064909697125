import React from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Productlist from './ProductList'
import Section from 'components/Section'

const ProductNew = () => {
    const { t } = useTranslation()

    return (
        <S.Container>
            <Section title={t('MENU_PRODUCT')} />
            <Productlist />
        </S.Container>
    )
}

export default ProductNew
