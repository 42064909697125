import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`
export const Content = styled.div`
	display: flex;
	width: 100px;
	justify-content: flex-end;
	transform: translateY(-40px);
`

export const CardsContent = styled.div`
	margin-top: 25px;
	width: calc(100% + 7px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.FormLabel {
		text-align: left;
		font: normal normal normal 13px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
	}
`

export const CashbackCard = styled.div`
	background: #f2f2f2 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;

	display: flex;
	align-items: center;
	gap: 10px;

	padding: 10px 15px;
`

export const CashbackCardText = styled.div`
	text-align: left;
	font: normal normal normal 15px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`

export const CashbackCardValue = styled.div`
	text-align: left;
	font: normal normal bold 15px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`

export const CashbackCardIcon = styled.div`
	color: #989898;
	font-size: 25px;
	opacity: 1;
`
