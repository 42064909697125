import styled from 'styled-components'

export const ActionContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 1rem;
`

export const IconInfo = styled.i`
	color: ${({ color }) => color || '#fff'};
	font-size: 20px;
	cursor: pointer;
`

export const VerticalLine = styled.div`
	width: 1px;
	height: 40px;
	background-color: #e2e2e2;
`

export const TooltipContainer = styled.div`
	padding: 0.5rem;
`

export const IconBox = styled.div`
	background-color: #f2f2f2;

	display: flex;

	justify-content: center;
	padding: 5px;
	border-radius: 5px;
	display: flex !important;
	align-items: center;
	color: ${({ color }) => color || '#fff'};

	:hover {
		cursor: pointer;
		background-color: ${({ bgColor }) => bgColor || '#fff'};
		cursor: pointer;
		color: #fff;
	}
`

export const CustomIcon = styled.i`
	font-size: 20px;
`
