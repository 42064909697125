import styled from 'styled-components'

export const Container = styled.div`
	gap: 10px;
	display: flex;
	align-items: center;
	margin-right: 9px;
`

export const LabelCheckbox = styled.div`
	opacity: 1;
	color: #717171;
	text-align: left;
	letter-spacing: 0px;
	font: normal normal normal 15px Roboto;
`
