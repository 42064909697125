import moment from 'moment'
import {
	getAllPurchasers,
	GetAllTransactionStatus,
	getPurchaserByStatus,
	getStatusByPurchaser,
} from '../../../../../../../redux/api/paymentGateway'

export const getPurchaserObjects = async setPurchaserObjects => {
	const response = await getAllPurchasers()

	if (response) {
		const { data } = response

		const res = []

		for (const purchasers of data) {
			res.push({ label: purchasers.name })
		}

		setPurchaserObjects(res)
	}
}

export const getAllTransactionsStatus = async setOptionsStatus => {
	const getAllTransactionsStatus = await GetAllTransactionStatus()

	const res = []

	for (const purchasers in getAllTransactionsStatus.data) {
		res.push({
			label: purchasers,

			options: getAllTransactionsStatus.data[purchasers],
		})
	}

	setOptionsStatus(res)
}

export const getPurchaserStatus = async (status, setPurchaserObjects) => {
	console.log(status)
	const response = await getPurchaserByStatus(status[0].title)

	if (response) {
		const { data } = response

		setPurchaserObjects([{ label: data[0] }])
	}
}

export const getStatusbyPurchaser = async (purchaser, setOptionsStatus) => {
	const response = await getStatusByPurchaser(purchaser.label)

	const options = []

	for (const status of response.data) {
		options.push(status)
	}

	setOptionsStatus([{ label: purchaser.label, options: options }])
}

export const periodOptions = [
	{ value: 1, label: 'Últimos 7 dias' },
	{ value: 2, label: 'Últimos 14 dias' },
	{ value: 3, label: 'Personalizado' },
]

export const statusOptions = [
	{ value: 1, label: 'Sucesso' },
	{ value: 2, label: 'Falha' },
]

export const ordinationOptions = [
	{ value: 1, label: 'Mais recente' },
	{ value: 2, label: 'Mais antiga' },
]

export const handleChangePeriod = (event, setValue) => {
	let end = new Date()
	let start = moment()

	switch (event?.value) {
		case 1:
			start = moment(start).subtract(7, 'days')
			end = moment(end)

			break
		case 2:
			start = moment(start).subtract(14, 'days')
			end = moment(end)
			break
		case 3:
			start = ''
			end = ''
			break
		default:
			start = ''
			end = ''
			break
	}

	setValue('dateFrom', start)
	setValue('dateTo', end)
}

export const defaultValues = {
	period: [],

	dateFrom: '',

	dateTo: '',

	history: '',

	ordination: ordinationOptions[0],

	status: [],

	autorizadora: { label: '' },
}

export const Open = e => {
	const period = e?.value
	return period !== 3 ? false : undefined
}

export const IsDisabled = e => {
	const period = e?.value
	return Boolean(period !== 3)
}

export function sleep(delay = 0) {
	return new Promise(resolve => {
		setTimeout(resolve, delay)
	})
}
