import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel, Grid, InputAdornment } from '@mui/material'
import { CssTextField } from '../../../Filter/styles'
import { Symbol } from '../../Functions'
import GenerationPoints from './GenerationPoints'
import { Controller } from 'react-hook-form'
import { PercentageValue, USDValue } from '../../../../../../../../utils/useMask'

const Configuration = ({ typeOfPoints, productData, parameterizationGenerationPoints, fetchCalculation, control, editMode, points, onOpen }) => {
	const { t } = useTranslation()

	const typeMask = typeOfPoints === '2' ? USDValue : PercentageValue

	return (
		<S.CardsContent>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Valor*')}</span>
						<Controller
							name="pointsValue"
							control={control}
							defaultValue={points}
							render={({ value, onChange }) => (
								<CssTextField
									variant="outlined"
									size="small"
									value={value}
									disabled={!editMode}
									onChange={e => {
										fetchCalculation(e.target.value)
										onChange(e.target.value)
									}}
									InputProps={{
										inputComponent: typeMask,
									}}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				<Grid item xs={12} sm={9}>
					<GenerationPoints
						onOpen={onOpen}
						parameterization={parameterizationGenerationPoints}
						typeOfPoints={typeOfPoints}
						productData={productData}
					/>
				</Grid>
			</Grid>
		</S.CardsContent>
	)
}

Configuration.propTypes = {
	control: PropTypes.object,
	productData: PropTypes.object,
	parameterization: PropTypes.object,
	pointsValue: PropTypes.string,
	typeOfPoints: PropTypes.string,
	parameterizationGenerationPoints: PropTypes.array,
	fetchCalculation: PropTypes.func,
	onOpen: PropTypes.func,
}

Configuration.defaultProps = {
	typeOfPoints: '1',
}

export default Configuration
