import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

// @ Components
import Section from 'components/Section'
import Snackbar from 'components/Snackbar'
import Loader from 'components/Loader/Loader'

// @ Context and Styles
import * as S from './styles'
import GeneralContext from '../../GeneralContext'
import { Box } from '@mui/material'
import { CustomCard } from './CustomCard'

function AdditionalModules() {
	const { t } = useTranslation()
	const { isSucess, isLoaded, setIsSucess, modules, getCurrentPartnerBase, setIsLoaded } = useContext(GeneralContext)

	const getModules = async () => {
		setIsLoaded(true)
		await getCurrentPartnerBase().finally(() => setIsLoaded(false))
	}

	useEffect(() => {
		getModules()
	}, [])

	return (
		<S.Container>
			<Snackbar
				close={true}
				marginTop="130px"
				open={isSucess.open}
				color={isSucess.color}
				handleClose={() => setIsSucess({ open: false })}
				message={t(isSucess.message)}
				closeNotification={() => setIsSucess({ open: false })}
				icon={() => <i className={isSucess.icon} />}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			/>
			{isLoaded && <Loader />}
			<Section title={t('MENU_ADDITIONAL_MODULES')} style={{ marginBottom: '25px' }} />
			{modules?.length > 0 ? (
				<Box display="flex" flexWrap="wrap" gap="20px">
					{modules?.map((module, index) => <CustomCard key={index} {...module} />)}
				</Box>
			) : (
				<S.NoModules>
					<i className="icon-ico_mais_informacao" />
					<span> Não há módulos adicionais a serem exibidos. </span>
				</S.NoModules>
			)}
		</S.Container>
	)
}

export default AdditionalModules
