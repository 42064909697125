import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'
import _ from 'lodash'

//@ components
import Section from 'components/Section'
import ListComponent from './List'

//@ API
import { GetGrouperByPartner } from '../../../redux/api/productPage'
import Loader from '../../../components/Loader/Loader'
import Cookies from 'js-cookie'

import { useHistory } from 'react-router-dom'

const GrouperComponent = () => {
	const { t } = useTranslation()
	const history = useHistory()
	const tableRef = useRef()
	const Grouper = Cookies.get('Grouper')

	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (_.isEmpty(Grouper)) {
			history.push(`/home`)
		}
	}, [Grouper])

	return (
		<S.Container>
			{loading && <Loader />}
			<Section
				title={t(
					localStorage.getItem('Grouper') === '1'
						? 'MENU_CATEGORY'
						: localStorage.getItem('Grouper') === '2'
						? 'FAMILY'
						: localStorage.getItem('Grouper') === '3'
						? 'MANUFACTURE'
						: localStorage.getItem('Grouper') === '4'
						? 'FIELD_BRAND'
						: 'teste',
				)}
			/>
			<ListComponent tableRef={tableRef} setLoading={setLoading} />
		</S.Container>
	)
}

export default GrouperComponent
