import React from 'react'
import * as S from '../styles'
import { Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'

import Loading from './carregando.gif'

export const Columns = (t, typeOfBenefit) => [
	{
		title: t('Nome da parametrização.'),
		field: 'name',
		width: '70%',
		cellStyle: {
			width: '70%',
		},
		headerStyle: {
			width: '25%',
			paddingLeft: '25px',
		},
		render: rowData => (
			<div
				style={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
					height: '34px',
				}}
			>
				<span style={{ fontSize: '14px' }}>{rowData.name}</span>
				<span style={{ fontSize: '12px', fontStyle: 'italic' }}>
					{rowData.typeOfBenefit.value === 'Cashback'
						? 'Parametrização de cashback'
						: rowData.typeOfBenefit.value === 'Redeem'
							? 'Parametrização de resgate'
							: rowData.typeOfBenefit.value === 'Points'
								? 'Parametrização de geração'
								: ''}
				</span>
			</div>
		),
	},
	{
		title: t('Andamento'),
		field: 'progress',
		width: '30%',
		cellStyle: {
			width: '30%',
			textAlign: 'left !important',
		},
		headerStyle: {
			width: '100%',
			paddingLeft: '25px',
		},
		render: rowData => (
			<div
				style={{
					display: 'flex',
				}}
			>
				<div style={{ marginRight: '10px' }}>
					{rowData.progress === 100 ? (
						<i
							style={{
								fontSize: '25px',
								color: '#5FB55F',
							}}
							className="icon-andamento_completo"
						/>
					) : (
						<img style={{ height: '30px' }} src={Loading} />
					)}
				</div>
				<div style={{ fontSize: '14px' }}>{(rowData.progress || 0) + '%'}</div>
			</div>
		),
	},
	{
		title: 'Ação',
		field: 'actions',
		cellStyle: {
			width: '80px',
			maxWidth: '80px',
			minWidth: '80px',
		},
		sorting: false,
		render: rowData => (
			<div
				style={{
					display: 'flex',
					justifyContent: 'start',
				}}
			>
				{rowData.progress !== 100 ? (
					<S.ButtonActionDisabled>
						<i className="icon-lupa" />
					</S.ButtonActionDisabled>
				) : (
					<Tooltip arrow id="tooltip-top" title={'Vizualizar'} placement="top-start">
						<Link to={`/product/historyDetails/${rowData.id}?typeOfBenefit=${typeOfBenefit}`}>
							<S.ButtonAction>
								<i className="icon-lupa" />
							</S.ButtonAction>
						</Link>
					</Tooltip>
				)}
			</div>
		),
	},
]

export const Data = {
	results: [
		{
			id: 'DD694168-2FF8-4CAF-AF3A-81C95747693A',
			name: 'Parametrização cashback',
			typeOfBenefit: {
				key: '2',
				value: 'Cashback',
			},
			progress: null,
		},
		{
			id: '0C3EB99A-DD7B-40F4-B8B7-E5432EAABC6B',
			name: 'Parametrização resgate',
			typeOfBenefit: {
				key: '1',
				value: 'Redeem',
			},
			progress: null,
		},
		{
			id: '33367240-A0F3-4D57-977F-6D40DF013AF0',
			name: 'Parametrização pontos',
			typeOfBenefit: {
				key: '0',
				value: 'Points',
			},
			progress: 100,
		},
	],
	currentPage: 1,
	pageCount: 1,
	pageSize: 10,
	recordCount: 3,
	success: true,
	errors: [],
}
