import React, { useEffect, useState } from 'react'

import * as Styled from './styles'
import { useParams } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { HideLoader, ShowLoader } from 'redux/actions'
import Loader from 'components/Loader/Loader'
import { GetUsersByPartner } from '../../../../../redux/api/partner'

import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

const PainelSelect = ({
	left,
	list,
	name,
	addRemove,
	selectItem,
	removeItem,
	selectAll,
	clonedList,
	setList,
	id,
	disabled,
}) => {
	const [searchValue, setSearchValue] = useState('')
	const [themeColor, setThemeColor] = useState()
	const [listLocal, setListLocal] = useState([])

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		setListLocal(list)
	}, [list])

	function searchName(event) {
		setSearchValue(event.target.value)

		const key = Object.keys(list)
		const all = key.map((chave) => list[chave])
		const filtered = all.filter((item) =>
			item.name.toLowerCase().includes(event.target.value.toLowerCase()),
		)

		setListLocal(filtered)
	}

	return (
		<Styled.SectionPainel padding={left ? '0 20px 0 0' : '0 0 0 20px'}>
			<header>
				<div>
					<Grid xs={12} style={{ display: 'flex' }}>
						<Grid xs={4}>
							<label htmlFor="search">
								<b style={{ position: 'relative', top: '5px' }}>
									{name}
								</b>
							</label>
						</Grid>
						{left && (
							<Grid xs={8}>
								<input
									style={{
										width: '96%',
										padding: '4px',
										borderRadius: '4px',
										border: '1px solid',
										borderColor: '#D3D3D3',
										marginLeft: '10px',
										paddingLeft: '10px',
										fontFamily:
											'Roboto, Open Sans, sans-serif',
										color: '#696969',
									}}
									value={searchValue}
									id="search"
									placeholder="Buscar"
									onChange={searchName}
									disabled={disabled}
									autoComplete="off"
								/>
							</Grid>
						)}
					</Grid>
				</div>
			</header>
			<Styled.BodySelect>
				<header style={{ marginTop: left ? 0 : '10px' }}>
					<Grid xs={12}>
						<div
							style={{ padding: '5px 0px', cursor: 'pointer' }}
							onClick={() => selectAll(left)}
						>
							<i
								style={{
									fontSize: 14,
									padding: '2px 0',
									position: 'relative',
									top: '2px',
									color: left
										? themeColor &&
										  themeColor.visual &&
										  themeColor.visual.icons &&
										  themeColor.visual.icons.color
										: 'red',
								}}
								className={
									left
										? 'icon-ico_selecionar'
										: 'icon-ico_cancelar'
								}
								disabled={disabled}
							/>
							<span
								style={{
									marginLeft: '15px',
									fontWeight: 'bold',
								}}
							>
								{left ? 'Selecionar todos' : 'Deletar todos'}
							</span>
						</div>
					</Grid>
				</header>
				<section>
					{listLocal
						? listLocal.map((item, index) => (
								<>
									<div
										style={{
											padding: '5px 0px',
											cursor: 'pointer',
										}}
									>
										<i
											onClick={() =>
												left
													? selectItem(
															item,
															index,
															searchValue,
															setSearchValue,
													  )
													: removeItem(item, index)
											}
											style={{
												fontSize: 14,
												padding: '2px 0',
												position: 'relative',
												top: '2px',
												color: left
													? themeColor &&
													  themeColor.visual &&
													  themeColor.visual.icons &&
													  themeColor.visual.icons
															.color
													: 'red',
											}}
											className={
												left
													? 'icon-ico_adicionar'
													: 'icon-ico_cancelar'
											}
											disabled={disabled}
										/>
										<span style={{ marginLeft: '15px' }}>
											{item.name}
										</span>
									</div>
								</>
						  ))
						: ''}
				</section>
			</Styled.BodySelect>
		</Styled.SectionPainel>
	)
}

function LinkGroup({ list, setList, users, editing }) {
	const { id } = useParams()
	const [data, setData] = useState([])
	const [newList, setNewList] = useState()

	const [clonedList, setClonedList] = useState({
		available: [],
		selected: [],
	})

	const dispatch = useDispatch()

	async function requestApi() {
		try {
			const response = await GetUsersByPartner()
			setData(response)
		} catch (error) {
		} finally {
			dispatch(HideLoader(<Loader />))
		}
	}

	useEffect(() => {
		requestApi()
	}, [])

	useEffect(() => {
		dispatch(ShowLoader(<Loader />))
		if (data && data.results) {
			setList((prevState) => ({
				...prevState,
				available: data.results.map((item) => ({
					...item,
					selected: false,
				})),
			}))

			dispatch(HideLoader(<Loader />))
		}
	}, [data, dispatch, id])

	async function selectItem(value, index, searchValue, setSearchValue) {
		var arr = []
		arr = list.available
		arr.splice(index, 1)
		setList((prevState) => ({
			...prevState,
			available: arr,
		}))
		var selectedArr
		selectedArr = list.selected
		selectedArr.push(value)
		setList((prevState) => ({
			...prevState,
			selected: selectedArr,
		}))

		searchValue && setSearchValue('')
	}

	async function removeItem(value, index) {
		var selectedArr = []
		selectedArr = list.selected
		selectedArr.splice(index, 1)
		setList((prevState) => ({
			...prevState,
			selected: selectedArr,
		}))
		var arr
		arr = list.available
		arr.push(value)
		setList((prevState) => ({
			...prevState,
			available: arr,
		}))
	}

	function selectAll(left) {
		if (left) {
			var availableItens = []
			availableItens = list.available
			var selectedItens = []
			selectedItens = list.selected
			availableItens.forEach((item) => {
				selectedItens.push(item)
			})
			setList((prevState) => ({
				available: [],
				selected: selectedItens,
			}))
		} else {
			availableItens = list.available
			selectedItens = list.selected
			selectedItens.forEach((item) => {
				availableItens.push(item)
			})
			setList((prevState) => ({
				available: availableItens,
				selected: [],
			}))
		}
	}

	async function addSelected(selected) {
		dispatch(ShowLoader(<Loader />))

		dispatch(HideLoader(<Loader />))
	}

	async function removeSelected(selected) {
		dispatch(ShowLoader(<Loader />))

		dispatch(HideLoader(<Loader />))
	}

	useEffect(() => {
		let newAvailable = list.available.map((a) => {
			let exists = false
			list.selected.forEach((s) => {
				if (a.id === s.id) {
					exists = true
				}
			})
			if (!exists) {
				return a
			}
		})
		newAvailable = newAvailable.filter((n) => n)

		newAvailable = newAvailable.filter(
			(v, i, a) => a.findIndex((t) => t.id === v.id) === i,
		)

		const newList = {
			available: newAvailable,
			selected: list.selected,
		}

		setNewList(newList)
	}, [list])

	return (
		<Styled.LinkContainer>
			<PainelSelect
				name={'Selecione os usuários'}
				left={true}
				list={(newList && newList.available) || []}
				setList={setList}
				addRemove={addSelected}
				selectItem={selectItem}
				selectAll={selectAll}
				removeItem={removeItem}
				id={id}
				clonedList={clonedList.available}
				disabled={false}
				// style={{ border: '2px solid green' }}
			/>
			<Styled.DividerLineHeight />
			<PainelSelect
				name={'Itens selecionados:'}
				left={false}
				list={(newList && newList.selected) || []}
				setList={setList}
				selectItem={selectItem}
				selectAll={selectAll}
				removeItem={removeItem}
				clonedList={clonedList.selected}
				addRemove={removeSelected}
				id={id}
				disabled={true}
				// style={{ border: '2px solid blue' }}
			/>
		</Styled.LinkContainer>
	)
}

export default LinkGroup
