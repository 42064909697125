/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable react/prop-types */
import { useState } from "react";
import { MuiBox } from "../Box";
import { CircularProgress, InputAdornment, Table as MuiTable, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { MuiTypography } from "../Typography";
import { Pagination } from "../Pagination";
import { MuiStack } from "../Stack";
import { useDebouncedCallback } from "use-debounce";
import { AiOutlineClose } from "react-icons/ai";
import { SearchIcon } from "../Icons";
import { MuiInput } from "../Input";
export function Table(_a) {
    var columns = _a.columns, totalItems = _a.totalItems, pageSize = _a.pageSize, defaultRows = _a.defaultRows, onChangePage = _a.onChangePage, changeRowsPerPage = _a.changeRowsPerPage, data = _a.data, loading = _a.loading, defaultColor = _a.defaultColor, handleOrderBy = _a.handleOrderBy, _b = _a.showPagination, showPagination = _b === void 0 ? true : _b, notFoundMessage = _a.notFoundMessage, padding = _a.padding, firstItemPadding = _a.firstItemPadding, align = _a.align, overflow = _a.overflow, getInputValue = _a.getInputValue, searchValues = _a.searchValues, labelSearch = _a.labelSearch, resetInputValue = _a.resetInputValue, onInput = _a.onInput;
    var _c = useState(false), orderBy = _c[0], setOrderBy = _c[1];
    var _d = useState(""), searchValue = _d[0], setSearchValue = _d[1];
    var getDebounceValue = useDebouncedCallback(function (value) {
        if (getInputValue) {
            getInputValue(value);
        }
    }, 700);
    function resetFilter() {
        setSearchValue("");
        if (resetInputValue) {
            resetInputValue(null);
        }
    }
    return (_jsxs(_Fragment, { children: [_jsx(MuiStack, __assign({ direction: "row", justifyContent: "flex-end", alignItems: "center" }, { children: searchValues && (_jsx(MuiBox, __assign({ display: "flex", alignItems: "center", justifyContent: "flex-end", maxWidth: "200px", sx: { float: "right" }, mb: -3 }, { children: _jsx(MuiInput, { showLabel: true, InputProps: {
                            endAdornment: (_jsx(InputAdornment, __assign({ position: "end", onClick: function () {
                                    resetFilter();
                                }, sx: {
                                    cursor: "pointer",
                                    "&:hover": {
                                        color: defaultColor,
                                    },
                                } }, { children: _jsx(AiOutlineClose, { size: 20 }) }))),
                            startAdornment: (_jsx(InputAdornment, __assign({ position: "start", onClick: function () { return getInputValue && getInputValue(searchValue !== null && searchValue !== void 0 ? searchValue : ""); }, sx: {
                                    cursor: "pointer",
                                } }, { children: _jsx(SearchIcon, { size: 20, hoverColor: defaultColor }) }))),
                        }, onChange: function (_a) {
                            var value = _a.target.value;
                            setSearchValue(value);
                            // if (getInputValue) {
                            //   getInputValue(value);
                            // }
                        }, onInput: function (_a) {
                            var value = _a.target.value;
                            if (getInputValue) {
                                getDebounceValue(value);
                            }
                        }, placeholder: labelSearch !== null && labelSearch !== void 0 ? labelSearch : "Buscar", value: searchValue, standard: true, textLabel: "" }) }))) })), _jsxs(MuiBox, __assign({ "data-testid": "table-group", sx: {
                    width: "100%",
                    borderRadius: "5px!important",
                    overflow: overflow !== null && overflow !== void 0 ? overflow : "hidden",
                    "& table": {
                        backgroundColor: "#fff",
                    },
                    "& .MuiTableHead-root": {
                        backgroundColor: "#fff",
                        borderRadius: "20px",
                    },
                    "& .MuiTableCell-head": {
                        borderRadius: "20px",
                    },
                    "& .MuiInput-underline::before": {
                        borderBottom: "none",
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
                        borderBottom: "none!important",
                    },
                    // "& td:nth-child(2), th:nth-child(2)": {
                    //   textAlign: align ?? "center",
                    // },
                    "& .MuiTableCell-root": {
                        padding: padding !== null && padding !== void 0 ? padding : "3px 16px!important",
                        // padding: "0px!important",
                        fontSize: "13px",
                        "&:first-child": {
                            padding: firstItemPadding !== null && firstItemPadding !== void 0 ? firstItemPadding : "3px 16px!important",
                        },
                        "&:nth-child(2)": {
                            padding: firstItemPadding !== null && firstItemPadding !== void 0 ? firstItemPadding : "0px!important",
                            // paddingLeft: "0px!important",
                        },
                    },
                } }, { children: [_jsxs(MuiTable, __assign({ "data-testid": "table", sx: {
                            width: "100%",
                        } }, { children: [_jsx(TableHead, __assign({ style: {
                                    backgroundColor: "#EFEFEF",
                                    height: "30px",
                                    overflow: "hidden",
                                } }, { children: _jsx(TableRow, __assign({ "data-testid": "thead" }, { children: columns === null || columns === void 0 ? void 0 : columns.map(function (column, index) {
                                        var _a, _b;
                                        return (_jsx(TableCell, __assign({ "data-testid": "table-header-".concat(index), align: (_a = column === null || column === void 0 ? void 0 : column.align) !== null && _a !== void 0 ? _a : "left", style: {
                                                minWidth: column === null || column === void 0 ? void 0 : column.minWidth,
                                                maxWidth: (_b = column === null || column === void 0 ? void 0 : column.maxWidth) !== null && _b !== void 0 ? _b : "100%",
                                                fontWeight: 600,
                                                border: "none",
                                                borderRadius: index === 0 ? "5px 0 0 5px" : index === (columns === null || columns === void 0 ? void 0 : columns.length) - 1 ? "0 5px 5px 0" : "0",
                                                padding: column === null || column === void 0 ? void 0 : column.padding,
                                            } }, { children: (column === null || column === void 0 ? void 0 : column.sort) ? (_jsx(TableSortLabel, __assign({ direction: orderBy ? "asc" : "desc", onClick: function () {
                                                    setOrderBy(!orderBy);
                                                    if (handleOrderBy) {
                                                        handleOrderBy(orderBy ? "asc" : "desc", column === null || column === void 0 ? void 0 : column.id);
                                                    }
                                                } }, { children: _jsx(MuiTypography, __assign({ variant: "body2", fontSize: 16, color: "#717171!important", fontWeight: 600 }, { children: column === null || column === void 0 ? void 0 : column.label })) }))) : (_jsx(MuiTypography, __assign({ variant: "body2", fontSize: 16, color: "#717171!important", fontWeight: 600 }, { children: column === null || column === void 0 ? void 0 : column.label }))) }), column === null || column === void 0 ? void 0 : column.id));
                                    }) })) })), _jsxs(TableBody, __assign({ sx: { border: "none" } }, { children: [loading && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ colSpan: 12, align: align }, { children: _jsx(MuiBox, __assign({ sx: {
                                                    textAlign: "center",
                                                    width: "100%",
                                                    height: "200px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                } }, { children: _jsx(CircularProgress, { sx: { color: defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C" } }) })) })) })), !loading &&
                                        (data === null || data === void 0 ? void 0 : data.length) > 0 &&
                                        (data === null || data === void 0 ? void 0 : data.map(function (row, rowIndex) { return (_jsx(TableRow, __assign({ "data-testid": "trow-".concat(rowIndex) }, { children: columns === null || columns === void 0 ? void 0 : columns.map(function (column) {
                                                var _a, _b;
                                                return (_jsx(TableCell, __assign({ "data-testid": "table-cell-".concat(rowIndex), align: (_a = column === null || column === void 0 ? void 0 : column.align) !== null && _a !== void 0 ? _a : "left", sx: {
                                                        background: rowIndex % 2 === 0 ? "#fff" : "#FAFAFC",
                                                        border: "none",
                                                        padding: column === null || column === void 0 ? void 0 : column.padding,
                                                        maxWidth: (_b = column === null || column === void 0 ? void 0 : column.maxWidth) !== null && _b !== void 0 ? _b : "100%",
                                                    } }, { children: (column === null || column === void 0 ? void 0 : column.render) ? column.render(row) : "-" }), column === null || column === void 0 ? void 0 : column.id));
                                            }) }), rowIndex)); })), loading ||
                                        ((data === null || data === void 0 ? void 0 : data.length) < 1 && (_jsx(TableRow, { children: _jsx(TableCell, __assign({ colSpan: 12 }, { children: _jsx(MuiBox, __assign({ "data-testid": "not-found-message", sx: {
                                                        textAlign: "center",
                                                        width: "100%",
                                                        height: "100px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    } }, { children: notFoundMessage })) })) })))] }))] })), _jsx(MuiStack, __assign({ "data-testid": "table-pagination-area", mt: 3 }, { children: showPagination && (_jsx(Pagination, { defaultColor: defaultColor !== null && defaultColor !== void 0 ? defaultColor : "#F58B3C", totalItems: totalItems !== null && totalItems !== void 0 ? totalItems : 1, pageSize: pageSize !== null && pageSize !== void 0 ? pageSize : 1, defaultRows: defaultRows !== null && defaultRows !== void 0 ? defaultRows : 10, onChangePage: function (page) { return onChangePage && onChangePage(page); }, changeRowsPerPage: function (page) { return changeRowsPerPage && changeRowsPerPage(page); } })) }))] }))] }));
}
