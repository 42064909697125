import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { ButtonClose } from 'components/NewButton/styles'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { withTranslation } from 'react-i18next'
import { Box, Fade } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'

import {
	Title,
	ModalContainer,
	ModalTypography,
	PaperContainer,
	SubBox,
} from './styles'

function ViewModal({
	children,
	open,
	closeByClick,
	maxWidth,
	className,
	padding,
	shadow,
	subTitle,
	t,
	headerIcon = 'icon-ico_regra_plataforma',
	headerTitle = 'TITLE_REGULATION_REGULATION_MODEL',
	buttonCloseIcon = 'icon-ico_cancelar',
}) {
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])
	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			disableEnforceFocus
		>
			<Fade in={open} unmountOnExit>
				<PaperContainer
					padding={padding}
					maxwidth={maxWidth}
					themeColor={themeColor}
				>
					<GridContainer style={{ padding: '15px 0 0 20px' }}>
						<GridItem
							xs={8}
							sm={8}
							md={7}
							style={{
								display: 'flex',
								alignItems: 'center',
								minHeight: '44px',
							}}
						>
							<i
								className={headerIcon}
								style={{
									color:
										themeColor &&
										themeColor.visual &&
										themeColor.visual.icons &&
										themeColor.visual.icons.color,
									fontSize: '18px',
									marginRight: '10px',
								}}
							/>
							<SubBox subTitle={subTitle} >
								<Title> {t(headerTitle)} </Title>
								{subTitle && (
									<ModalTypography>
										{t('INFO_CAMPAIGN')}
									</ModalTypography>
								)}
							</SubBox>

						</GridItem>
						<GridItem
							xs={4}
							sm={4}
							md={5}
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
						>
							<Button
								color="greenButtonClose"
								style={{ margin: 0 }}
								size="sm"
								onClick={closeByClick}
							>
								<i
									className={buttonCloseIcon}
									style={{
										fontSize: '15px',
										marginRight: '5px',
										//transform: 'translateY(-0.6px)',
									}}
								/>
								{t('BUTTON_CLOSE')}
							</Button>
						</GridItem>
					</GridContainer>
					<GridContainer style={{ padding: '10px 0px 0 20px' }}>
						{children}
					</GridContainer>
				</PaperContainer>
			</Fade>
		</ModalContainer>
	)
}

ViewModal.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	headTitle: PropTypes.string,
}

ViewModal.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	headTitle: '',
	children: null,
	open: false,
	closeByClick: null,
	maxWidth: '630px',
}

export default withTranslation()(ViewModal)
