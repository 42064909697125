import React, { Fragment } from 'react'
import * as _ from 'lodash'
import AsyncSelect from 'react-select/async'
import DocumentTitle from 'react-document-title'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { Add, Remove } from '@material-ui/icons'
import { TextField, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import GridContainer from 'components/Grid/GridContainer.jsx'

import GridItem from 'components/Grid/GridItem.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import Loader from 'components/Loader/Loader.jsx'
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx'
import NoResults from 'components/NoResults/NoResults.jsx'

import GriTable from './components/GriTable'

import { numberFormatText } from 'utils/utils'
import compose from 'utils/compose'

// style
import styles from './statement.module.scss'
import { germiniDefaultColors, grayColor } from 'assets/jss/material-dashboard-pro-react'

// REDUX INIT
import { connectRouterState } from 'utils/connectRouterState'
import {
	GetPartnersData,
	GetCampaignStatusListData,
	GetProduct,
	GetCategory,
	GetFamily,
	GetManufacturer,
	GetBrand,
	ShowLoader,
	HideLoader,
} from 'redux/actions'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { envs } from '../../../redux/api/windowServerData'
import { getProgramTitle } from '../../../utils/utils'

const statusOptions = [{ value: 'true', label: 'Ativos' }, { value: 'false', label: 'Inativos' }]

const CssTextField = withStyles({
	root: {
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: grayColor[2],
			},
			'&.Mui-focused fieldset': {
				borderColor: grayColor[2],
			},
		},
		'& .MuiOutlinedInput-inputMarginDense ': {
			paddingTop: '10px',
			paddingBottom: '10px',
		},
	},
})(TextField)

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}

const typeOfPointsOpts = [
	{ value: '0', label: 'Não' },
	{ value: '1', label: 'Padrão' },
	{ value: '2', label: 'Fixo' },
	{ value: '3', label: 'Percentual' },
]

const TypeOfRedeemOpts = [{ value: '1', label: 'Não' }, { value: '2', label: 'Padrão' }, { value: '3', label: 'Personalizado' }]

class Tools extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			currentPage: 1,
			disabledCategory: true,
			disabledFamily: true,
			filtered: false,
			isHidden: true,
			open: false,
			pageSize: 0,
			partnerId: '',
			productFailed: false,
			recordCount: 0,
			results: false,
			searchBarCode: '',
			searchBrand: '',
			searchCategory: '',
			searchCodeSKU: '',
			searchDescription: '',
			searchFamily: '',
			searchManufacturer: '',
			searchPartner: '',
			searchStatus: '',
			searchTypeOfPoints: '',
			searchTypeOfRedeem: '',
			selectedBrand: '',
			selectedCategory: '',
			selectedFamily: '',
			selectedManufacturer: '',
			selectedStatus: '',
			selectedTypeOfPoints: '',
			selectedTypeOfRedeem: '',
			userData: {},
			themeColor: getThemeColorFromLocalStorage(),
		}
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		this.props.ShowLoader(<Loader />)
		if (!_.isEmpty(this.props.userData)) {
			this.loadData()
		}
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState({ ...urlState })
			this.props.GetProduct(
				urlState.searchCodeSKU,
				urlState.searchBarCode,
				urlState.searchDescription,
				urlState.searchCategory,
				urlState.searchFamily,
				urlState.searchManufacturer,
				urlState.searchBrand,
				urlState.searchTypeOfPoints,
				urlState.searchTypeOfRedeem,
				urlState.searchStatus,
				urlState.currentPage,
			)
		}

		if (prevProps.lsProducts !== this.props.lsProducts) {
			this.props.HideLoader()
			this.setState({ ...this.props.lsProducts })
		}

		if (prevProps.productFailed !== this.props.productFailed) {
			this.setState({ productFailed: true })
		}

		if (prevProps.lsCategories !== this.props.lsCategories) {
			if (!_.isEmpty(this.state.searchCategory)) {
				let result = _.find(this.props.lsCategories.results, ['key', this.state.searchCategory])
				if (result) {
					const obj = {
						value: result.key,
						label: result.value,
					}
					this.setState({ selectedCategory: obj })
				}
			}
		}

		if (prevProps.lsFamily !== this.props.lsFamily) {
			if (!_.isEmpty(this.state.searchFamily)) {
				let result = _.find(this.props.lsFamily.results, ['key', this.state.searchFamily])
				if (result) {
					const obj = {
						value: result.key,
						label: result.value,
					}
					this.setState({ selectedFamily: obj })
				}
			}
		}

		if (prevProps.lsManufacturers !== this.props.lsManufacturers) {
			if (!_.isEmpty(this.state.searchManufacturer)) {
				let result = _.find(this.props.lsManufacturers.results, ['key', this.state.lsManufacturers])
				if (result) {
					const obj = {
						value: result.key,
						label: result.value,
					}
					this.setState({ selectedManufacturer: obj })
				}
			}
		}

		if (prevProps.lsBrands !== this.props.lsBrands) {
			if (!_.isEmpty(this.state.searchBrand)) {
				let result = _.find(this.props.lsBrands.results, ['key', this.state.lsBrands])
				if (result) {
					const obj = {
						value: result.key,
						label: result.value,
					}
					this.setState({ selectedBrand: obj })
				}
			}
		}

		if (prevProps.categoriesFailed !== this.props.categoriesFailed) {
			alert('Categories Failed')
		}
		//
		if (prevProps.userData !== this.props.userData) {
			this.loadData()
		}
	}

	handleToggle = () => {
		this.setState({ open: !this.state.open })
	}

	loadData = () => {
		this.props.ShowLoader(<Loader />)
		let urlState = _.get(this.props, 'urlState')
		this.setState(
			{
				searchCodeSKU: urlState.searchCodeSKU,
				searchBarCode: urlState.searchBarCode,
				searchDescription: urlState.searchDescription,
				searchCategory: urlState.searchCategory,
				searcgFamily: urlState.searchFamily,
				searchManufacturer: urlState.searchManufacturer,
				searchBrand: urlState.searchManufacturer,
				searchTypeOfPoints: urlState.searchTypeOfPoints,
				searchTypeOfRedeem: urlState.searchTypeOfRedeem,
				searchStatus: urlState.searchStatus,
			},
			() => {
				this.setState({
					selectedStatus: _.find(statusOptions, ['value', urlState.searchStatus]),
				})
				this.props.GetProduct(
					urlState.searchCodeSKU,
					urlState.searchBarCode,
					urlState.searchDescription,
					urlState.searchCategory,
					urlState.searchFamily,
					urlState.searchManufacturer,
					urlState.searchBrand,
					urlState.searchTypeOfPoints,
					urlState.searchTypeOfRedeem,
					urlState.searchStatus,
					this.state.currentPage,
				)
				this.props.GetCampaignStatusListData()
				//
				if (
					!_.isEmpty(this.state.searchCodeSKU) ||
					!_.isEmpty(this.state.searchBarCode) ||
					!_.isEmpty(this.state.searchDescription) ||
					!_.isEmpty(this.state.searchCategory) ||
					!_.isEmpty(this.state.searchFamily) ||
					!_.isEmpty(this.state.searchManufacturer) ||
					!_.isEmpty(this.state.searchBrand) ||
					!_.isEmpty(this.state.searchTypeOfPoints) ||
					!_.isEmpty(this.state.searchTypeOfRedeem) ||
					!_.isEmpty(this.state.searchStatus)
				) {
					this.setState({ filtered: true })
				}
			},
		)
		this.setState({
			userData: this.props.userData,
			disabledCategory: false,
			disabledFamily: false,
		})
	}

	handleEdit = i => {
		this.props.history.push(`/product/productDetail/edit/${i}`)
	}

	handleViewer = i => {
		this.props.history.push(`/product/productDetail/${i}`)
	}

	handleSelectedChange = newValue => {
		this.setState({
			searchStatus: newValue.value,
			selectedStatus: newValue,
		})
	}

	handleCategoryChange = newValue => {
		this.setState({
			searchCategory: newValue.value,
			selectedCategory: newValue,
		})
	}

	handleFamilyChange = newValue => {
		this.setState({
			searchFamily: newValue.value,
			selectedFamily: newValue,
		})
	}

	handleManufacturerChange = newValue => {
		this.setState({
			searchManufacturer: newValue.value,
			selectedManufacturer: newValue,
		})
	}

	handleBrandChange = newValue => {
		this.setState({ searchBrand: newValue.value, selectedBrand: newValue })
	}

	handleTypeOfPoints = newValue => {
		this.setState({
			searchTypeOfPoints: newValue.value,
			selectedTypeOfPoints: newValue,
		})
	}

	handleTypeOfRedeem = newValue => {
		this.setState({
			searchTypeOfRedeem: newValue.value,
			selectedTypeOfRedeem: newValue,
		})
	}

	handleChange = event => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ [id]: value })
	}

	loadCategoryOptions = (value, callback) => {
		let inputValue = value.trim().toLowerCase()
		let inputLength = inputValue.length
		if (inputLength === 0) {
			inputValue = ' '
		}

		return this.props.GetCategory(this.props.userData.id, inputValue, 1).then(rest => {
			if (this.props.lsCategories.recordCount > 0) {
				return callback(
					this.props.lsCategories.results.map(partner => {
						return { value: partner.key, label: partner.value }
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	loadFamilyOptions = (value, callback) => {
		let inputValue = value.trim().toLowerCase()
		let inputLength = inputValue.length
		if (inputLength === 0) {
			inputValue = ' '
		}

		return this.props.GetFamily(this.props.userData.id, inputValue, 1).then(rest => {
			if (this.props.lsFamily.recordCount > 0) {
				return callback(
					this.props.lsFamily.results.map(family => {
						return { value: family.key, label: family.value }
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	loadManufacturerOptions = (value, callback) => {
		let inputValue = value.trim().toLowerCase()
		let inputLength = inputValue.length
		if (inputLength === 0) {
			inputValue = ' '
		}

		return this.props.GetManufacturer(this.props.userData.id, inputValue, 1).then(rest => {
			if (this.props.lsManufacturers.recordCount > 0) {
				return callback(
					this.props.lsManufacturers.results.map(manufacturer => {
						return {
							value: manufacturer.key,
							label: manufacturer.value,
						}
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	loadBrandOptions = (value, callback) => {
		let inputValue = value.trim().toLowerCase()
		let inputLength = inputValue.length
		if (inputLength === 0) {
			inputValue = ' '
		}

		return this.props.GetBrand(this.props.userData.id, inputValue, 1).then(rest => {
			if (this.props.lsBrands.recordCount > 0) {
				return callback(
					this.props.lsBrands.results.map(brand => {
						return { value: brand.key, label: brand.value }
					}),
				)
			} else {
				return callback([])
			}
		})
	}

	handleSearch = currentPage => {
		this.props.ShowLoader(<Loader />)
		this.props.setURLState({ currentPage })
	}

	handleFilter = clear => {
		const {
			selectedStatus,
			selectedCategory,
			selectedFamily,
			selectedManufacturer,
			selectedBrand,
			selectedTypeOfPoints,
			selectedTypeOfRedeem,
			searchCodeSKU,
			searchBarCode,
			searchDescription,
		} = this.state
		this.props.ShowLoader()
		this.props.setURLState({
			searchCodeSKU,
			searchBarCode,
			searchDescription,
			searchCategory: selectedCategory ? selectedCategory.value : '',
			searchFamily: selectedFamily ? selectedFamily.value : '',
			searchManufacturer: selectedManufacturer ? selectedManufacturer.value : '',
			searchBrand: selectedBrand ? selectedBrand.value : '',
			searchTypeOfPoints: selectedTypeOfPoints ? selectedTypeOfPoints.value : '',
			searchTypeOfRedeem: selectedTypeOfRedeem ? selectedTypeOfRedeem.value : '',
			currentPage: 1,
		})
		//
		if (clear) {
			this.setState({ filtered: false })
		} else {
			this.setState({ filtered: true })
		}
	}

	handleClearFilter = () => {
		this.setState(
			{
				filtered: false,
				searchStatus: '',
				selectedStatus: '',
				selectedCategory: '',
				selectedFamily: '',
				selectedManufacturer: '',
				selectedBrand: '',
				selectedTypeOfPoints: '',
				selectedTypeOfRedeem: '',
				searchCodeSKU: '',
				searchBarCode: '',
				searchDescription: '',
				searchCategory: '',
				searchFamily: '',
				searchManufacturer: '',
				searchBrand: '',
				searchTypeOfPoints: '',
				searchTypeOfRedeem: '',
				partnerId: '',
			},
			() => this.handleFilter(true),
		)
	}

	getPartnerOptions = (value, callback) => {
		const inputValue = value.trim().toLowerCase()
		const inputLength = inputValue.length
		if (inputLength === 0) {
			return callback([])
		} else {
			return this.props.GetPartnersData(inputValue, '', 1).then(() => {
				return callback(
					this.props.partnersData.results.map(partner => {
						return {
							value: partner.id,
							label: partner.name + ' - ' + partner.cnpj,
						}
					}),
				)
			})
		}
	}

	render() {
		const { t, classes } = this.props
		const {
			productFailed,
			open,
			selectedCategory,
			selectedFamily,
			selectedManufacturer,
			selectedBrand,
			selectedTypeOfPoints,
			selectedTypeOfRedeem,
			filtered,
			disabledCategory,
			disabledFamily,
			searchCodeSKU,
			searchBarCode,
			searchDescription,
			results,
			pageSize,
			recordCount,
			currentPage,
		} = this.state

		return (
			<Fragment>
				<DocumentTitle title={getProgramTitle()} />
				<Section title={t('MENU_PRODUCT')} />
				<GridContainer className={classes.filterForm}>
					{/* FILTERS - LINE 1 */}
					<GridItem xs={12} sm={4} md={4}>
						<CssTextField
							className={styles.TextField}
							fullWidth
							label={t('FIELD_CODE_SKU')}
							id="searchCodeSKU"
							name="searchCodeSKU"
							value={searchCodeSKU}
							onChange={this.handleChange}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>

					<GridItem xs={12} sm={8} md={8} style={{ marginTop: '0px' }}>
						<CssTextField
							className={styles.TextField}
							fullWidth
							label={t('PRODUCT')}
							id="searchDescription"
							name="searchDescription"
							value={searchDescription}
							onChange={this.handleChange}
							margin="dense"
							variant="outlined"
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>

					{open && (
						<>
							{/* FILTERS LINE 2 */}

							<GridItem xs={12} sm={4} md={4} style={{ marginTop: '3px' }}>
								<CssTextField
									className={styles.TextField}
									fullWidth
									label={t('FIELD_BARCODE')}
									id="searchBarCode"
									name="searchBarCode"
									value={searchBarCode}
									onChange={this.handleChange}
									variant="outlined"
									margin="dense"
									style={{ backgroundColor: '#fff' }}
								/>
							</GridItem>
							<GridItem xs={12} sm={4} md={4} style={{ marginTop: '10px' }}>
								{this.state.userData.id && (
									<AsyncSelect
										cacheOptions
										defaultOptions
										styles={customSelectStyles}
										placeholder={t('FIELD_CATEGORY')}
										loadingMessage={() => t('FIELD_SEARCHING') + '...'}
										noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
										value={selectedCategory}
										isDisabled={disabledCategory}
										loadOptions={_.debounce(this.loadCategoryOptions.bind(this), 500)}
										onChange={this.handleCategoryChange}
										theme={theme => ({
											...theme,
											colors: {
												...theme.colors,
												primary: this.state.themeColor?.structure?.backgroundColor && this.state.themeColor?.structure?.backgroundColor,
											},
										})}
									/>
								)}
							</GridItem>

							<GridItem xs={12} sm={4} md={4} style={{ marginTop: '10px' }}>
								{this.state.userData.id && (
									<AsyncSelect
										cacheOptions
										defaultOptions
										styles={customSelectStyles}
										placeholder={t('FAMILY')}
										loadingMessage={() => t('FIELD_SEARCHING') + '...'}
										noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
										value={selectedFamily}
										isDisabled={disabledFamily}
										loadOptions={_.debounce(this.loadFamilyOptions.bind(this), 500)}
										onChange={this.handleFamilyChange}
										theme={theme => ({
											...theme,
											colors: {
												...theme.colors,
												primary: this.state.themeColor?.structure?.backgroundColor && this.state.themeColor?.structure?.backgroundColor,
											},
										})}
									/>
								)}
							</GridItem>

							<GridItem xs={12} sm={4} md={4} style={{ marginTop: '10px' }}>
								{this.state.userData.id && (
									<AsyncSelect
										cacheOptions
										defaultOptions
										styles={customSelectStyles}
										placeholder={t('MANUFACTURER')}
										loadingMessage={() => t('FIELD_SEARCHING') + '...'}
										noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
										value={selectedManufacturer}
										isDisabled={disabledFamily}
										loadOptions={_.debounce(this.loadManufacturerOptions.bind(this), 500)}
										onChange={this.handleManufacturerChange}
										theme={theme => ({
											...theme,
											colors: {
												...theme.colors,
												primary: this.state.themeColor?.structure?.backgroundColor && this.state.themeColor?.structure?.backgroundColor,
											},
										})}
									/>
								)}
							</GridItem>
							<GridItem xs={12} sm={4} md={4} style={{ marginTop: '10px' }}>
								{this.state.userData.id && (
									<AsyncSelect
										cacheOptions
										defaultOptions
										styles={customSelectStyles}
										placeholder={t('Brand')}
										loadingMessage={() => t('FIELD_SEARCHING') + '...'}
										noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
										value={selectedBrand}
										isDisabled={disabledFamily}
										loadOptions={_.debounce(this.loadBrandOptions.bind(this), 500)}
										onChange={this.handleBrandChange}
										theme={theme => ({
											...theme,
											colors: {
												...theme.colors,
												primary: this.state.themeColor?.structure.backgroundColor && this.state.themeColor?.structure.backgroundColor,
											},
										})}
									/>
								)}
							</GridItem>

							<GridItem xs={12} sm={4} md={4} style={{ marginTop: '10px' }}>
								<Select
									value={selectedTypeOfPoints}
									placeholder={t('TITLE_REDEEM_VALUE')}
									noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
									onChange={this.handleTypeOfPoints}
									styles={customSelectStyles}
									options={typeOfPointsOpts}
									theme={theme => ({
										...theme,
										colors: {
											...theme.colors,
											primary: germiniDefaultColors[0],
										},
									})}
								/>
							</GridItem>

							<GridItem xs={12} sm={4} md={4} style={{ marginTop: '15px' }}>
								<Select
									value={selectedTypeOfRedeem}
									placeholder={t('TITLE_REDEEM_SALES')}
									noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
									onChange={this.handleTypeOfRedeem}
									styles={customSelectStyles}
									options={TypeOfRedeemOpts}
									theme={theme => ({
										...theme,
										colors: {
											...theme.colors,
											primary: germiniDefaultColors[0],
										},
									})}
								/>
							</GridItem>
						</>
					)}

					<GridContainer style={{ margin: '20px 0' }}>
						<GridItem xs={12} sm={12} md={6} style={{ textAlign: 'left' }}>
							<div
								onClick={this.handleToggle}
								title="Pesquisa avançada"
								style={{
									cursor: 'pointer',
									color: this.state.themeColor?.navigation?.buttons?.elementsColor,
									lineHeight: '36px',
								}}
							>
								<IconButton classes={{ root: classes.filterPlusButton }} title="Pesquisa avançada">
									{!open ? <Add /> : <Remove />}
								</IconButton>
								<span> {!open ? t('SHOW_MORE_FILTERS') : t('HIDE_MORE_FILTERS')}</span>
							</div>
						</GridItem>
						<GridItem xs={12} sm={12} md={6} style={{ textAlign: 'right' }}>
							<Button size="sm" style={{ marginRight: '8px' }} variant="contained" color="greenButtonColor" onClick={() => this.handleFilter(false)}>
								{t('BUTTON_FIND')}
							</Button>{' '}
							<Button size="sm" variant="contained" color="greenBorderButtonColor" onClick={() => this.handleClearFilter()}>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>
						</GridItem>
					</GridContainer>
				</GridContainer>
				{/*  */}
				{filtered ? <Section title={t('TITLE_SEARCH_RESULTS')} /> : ''}
				<GridContainer justifyContent="center" style={{ paddingLeft: '15px' }}>
					{results && results.length > 0 && (
						<Fragment>
							{results.map((item, index) => [
								<GriTable
									key={index}
									id={item.id}
									history={this.props.history}
									labels={[
										t('FIELD_CODE'),
										t('PRODUCT'),
										t('FIELD_PRICE'),
										t('FIELD_CATEGORY'),
										t('FIELD_EQUIVALENT_BALLAST'),
										t('TITLE_ACCUMULATE_POINTS'),
									]}
									values={[
										item.sku ? item.sku : '-',
										item.name ? item.name : '-',
										item.price
											? numberFormatText(
												item.price,
												localStorage.getItem('currencySymbol')
													? localStorage.getItem('currencySymbol') + ' '
													: // : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
													envs.REACT_APP_CURRENCY_SYMBOL + ' ',
												2,
											)
											: numberFormatText(
												'0',
												localStorage.getItem('currencySymbol')
													? localStorage.getItem('currencySymbol') + ' '
													: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
													envs.REACT_APP_CURRENCY_SYMBOL + ' ',
												2,
											),
										item.category.value ? item.category.value : '-',

										item.typeOfPoints.key === '1'
											? item.parameterizationGenerationPoints && item.parameterizationGenerationPoints.standardAdministrationFeeApplied
												? numberFormatText(
													item.parameterizationGenerationPoints.standardAdministrationFeeApplied,
													localStorage.getItem('currencySymbol')
														? localStorage.getItem('currencySymbol') + ' '
														: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_CURRENCY_SYMBOL + ' ',
													2,
												)
												: numberFormatText(
													'0',
													localStorage.getItem('currencySymbol')
														? localStorage.getItem('currencySymbol') + ' '
														: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_CURRENCY_SYMBOL + ' ',
													2,
												)
											: item.parameterizationGenerationPoints && item.parameterizationGenerationPoints.customAdministrationFeeApplied
												? numberFormatText(
													item.parameterizationGenerationPoints.customAdministrationFeeApplied,
													localStorage.getItem('currencySymbol')
														? localStorage.getItem('currencySymbol') + ' '
														: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_CURRENCY_SYMBOL + ' ',
													2,
												)
												: numberFormatText(
													'0',
													localStorage.getItem('currencySymbol')
														? localStorage.getItem('currencySymbol') + ' '
														: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_CURRENCY_SYMBOL + ' ',
													2,
												),

										item.typeOfPoints.key === '1'
											? item.parameterizationGenerationPoints && item.parameterizationGenerationPoints.standardAccumulatePoints
												? numberFormatText(
													item.parameterizationGenerationPoints.standardAccumulatePoints,
													localStorage.getItem('programCurrencySymbol')
														? localStorage.getItem('programCurrencySymbol') + ' '
														: //: envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
													0,
												)
												: numberFormatText(
													'0',
													localStorage.getItem('programCurrencySymbol')
														? localStorage.getItem('programCurrencySymbol') + ' '
														: //: envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
													0,
												)
											: item.parameterizationGenerationPoints && item.parameterizationGenerationPoints.customAccumulatePoints
												? numberFormatText(
													item.parameterizationGenerationPoints.customAccumulatePoints,
													localStorage.getItem('programCurrencySymbol')
														? localStorage.getItem('programCurrencySymbol') + ' '
														: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
													0,
												)
												: numberFormatText(
													'0',
													localStorage.getItem('programCurrencySymbol')
														? localStorage.getItem('programCurrencySymbol') + ' '
														: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
														envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
													0,
												),
									]}
								/>,
							])}
						</Fragment>
					)}
					{(results && results.length == 0) || productFailed ? <NoResults value={t('RESULTS_NOT_FOUND')} /> : ''}
					{results && results.length > 0 && (
						<div
							style={{
								textAlign: 'center',
								marginTop: '20px',
								width: '100%',
							}}
						>
							<CustomPagination
								pageSize={pageSize}
								recordCount={recordCount}
								offset={pageSize * (currentPage - 1)}
								clickEvent={(e, offset) => this.handleSearch(Math.round(offset / pageSize) + 1)}
							/>
						</div>
					)}
				</GridContainer>
			</Fragment>
		)
	}
}

const mapStateToProps = state => {
	return {
		userData: state.users.userData,
		partnersData: state.partners.partnersData,
		partnersDataFailed: state.partners.partnersDataFailed,

		lsProducts: state.product.lsProducts,
		productFailed: state.product.productFailed,

		lsFamily: state.product.lsFamily,
		familyFailed: state.product.familyFailed,

		lsManufacturers: state.product.lsManufacturers,
		manufacturerFailed: state.product.manufacturerFailed,

		lsBrands: state.product.lsBrands,
		brandsFailed: state.product.brandsFailed,

		lsCategories: state.product.lsCategories,
		categoriesFailed: state.product.categoriesFailed,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetPartnersData,
			GetCampaignStatusListData,
			GetProduct,
			GetCategory,
			GetFamily,
			GetManufacturer,
			GetBrand,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	withTranslation(),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	connectRouterState({
		searchCodeSKU: '',
		searchBarCode: '',
		searchDescription: '',
		searchCategory: '',
		searchFamily: '',
		searchManufacturer: '',
		searchBrand: '',
		searchTypeOfPoints: '',
		searchTypeOfRedeem: '',
		searchStatus: '',
		currentPage: 1,
	}),
	withStyles(extendedFormsStyle),
)(Tools)
