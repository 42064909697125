export const DefaultProductData = {
	standardAdministrationFeeApplied: '',
	standardGrossValue: '',
	standardAccumulatePoints: '',
	customAdministrationFeeApplied: '',
	customGrossValue: '',
	customAccumulatePoints: '',
	calculationMemory: {
		conversionRate: '',
		administrationFee: '',
		valueToConvert: '',
		multiplierFactor: '',
	},
}
