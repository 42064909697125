import * as Yup from 'yup'
import { AttachmentFile, CreatePartnerAttachments, DeleteAttachmentFile } from '../../../../../../../../redux/api/PartnerDocuments'
import { FormatDocument, defaultValue } from '../List/utils'
import { DeleteAttachmentFromStorageFile } from '../../../../../../../../redux/api/partner'

export const isPDFFile = file => {
	const allowedExtensions = ['pdf']
	const fileExtension = file.name
		.split('.')
		.pop()
		.toLowerCase()
	if (!allowedExtensions.includes(fileExtension)) {
		return false
	}
	if (file.type !== 'application/pdf') {
		return false
	}

	return true
}

// Schema principal
export const schema = Yup.object().shape({
	file: Yup.mixed().required('Campo obrigatório'),
	fileName: Yup.string().required('Campo obrigatório'),
	attachmentTypes: Yup.object().shape({
		value: Yup.string().required('Campo obrigatório'),
		key: Yup.string().required('Campo obrigatório'),
	}),
	attachmentType: Yup.string().when('attachmentTypes', {
		is: { key: '0' },
		then: Yup.string().required('Campo obrigatório'),
		otherwise: Yup.string(),
	}),
})

export const updateData = async (form, partnerId) => {
	try {
		return await CreatePartnerAttachments(form, partnerId)
	} catch (error) {
		return error.response
	}
}

export const handleFormSubmission = async (obj, sharedProps) => {
	const { setFormData, formData, setForm, fileInputRef, reset, setError, setClosing, setIsLoadingData } = sharedProps
	setIsLoadingData(true)

	const isDuplicate = formData.length > 0 && formData.some(item => item.fileName === obj.fileName)

	if (isDuplicate) {
		setError('name', {
			message: 'Documento com nome duplicado',
		})
		return
	}
	try {
		const response = await AttachmentFile(obj)

		if (response.status === 200 && response.data?.errors?.length === 0) {
			const newData = FormatDocument(response.data, obj)

			setFormData(prevState => [...prevState, newData])
			setForm(prevState => [...prevState, newData])
			setClosing({
				severity: 'success',
				text: 'Documento anexado com sucesso',
				open: true,
			})
			fileInputRef.current.value = ''
			reset(defaultValue)
		}

		if (response.status === 200 && response.data?.errors?.length > 0) {
			setClosing({
				severity: 'error',
				text: 'Erro ao anexar o documento',
				open: true,
			})
		}
	} catch (error) {
		console.log(error)
	} finally {
		setIsLoadingData(false)
	}
}

export const deleteAttachment = async (item, sharedProps) => {
	const { setClosing, setFormData, setIsLoadingData, partnerId, setOpenModal, t } = sharedProps
	try {
		setIsLoadingData(true)

		const partesDaURL = item?.file?.split('/')
		const nomeDoArquivo = partesDaURL[partesDaURL.length - 1]

		const response =
			item?.status === 'not-saved'
				? await DeleteAttachmentFromStorageFile(nomeDoArquivo)
				: await DeleteAttachmentFile(partnerId, nomeDoArquivo, item?.fileName)

		if (response?.status === 200 && response?.data?.data) {
			setClosing({
				severity: 'success',
				text: 'Documento excluído com sucesso',
				open: true,
			})
			setFormData(prevState => prevState.filter(doc => doc.file !== item.file))
		} else {
			setClosing({
				severity: 'error',
				text: t(response?.data?.errors[0]?.message || t('ERROR_DELETE_DOCUMENT')),
				open: true,
			})
		}
	} catch (error) {
		setClosing({
			severity: 'error',
			text: t('Erro ao excluir o documento'),
			open: true,
		})
	} finally {
		setIsLoadingData(false)
		setOpenModal(prevState => {
			return {
				...prevState,
				text: '',
				open: false,
			}
		})
	}
}
