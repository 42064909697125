import Cookies from 'js-cookie'
import * as Yup from 'yup'
import { ValidateUpdateByMultipleDivision } from '../../../../../../../../../../../../redux/api/Grouper'

export const GetDivisionName = item => item?.segmentationType?.value || ''

export const Schema = Yup.object().shape({
	typeOfRedeem: Yup.object()
		.nullable()
		.required('Campo obrigatório'),

	redemptionPoints: Yup.string()
		.nullable()
		.when('typeOfRedeem', {
			is: value => value?.key === '3',
			then: Yup.string().required('Campo obrigatório'),
		}),
	selectedDivisionIdRed: Yup.array().nullable(),
	pametrizationName: Yup.string()
		.nullable()
		.when('selectedDivisionIdRed', {
			is: value => value?.length > 0,
			then: Yup.string().required('Campo obrigatório'),
		}),
})

export const ValidateStatusProductByDivisions = async ({ productId, partnerId, divisions, typeOfBenefit, page, pageSize }) => {
	try {
		const body = {
			partnerId: partnerId || '',
			productId: productId || '',
			divisions: divisions || [],
			typeOfBenefit: typeOfBenefit,
			pageSize: pageSize || 10,
			page: page || 1,
		}
		const res = await ValidateUpdateByMultipleDivision(body)
		return res
	} catch (error) {
		return error
	}
}

export const HasParameterization = async ({ productData, selectedDivisionIdRed, setHasParameterization, setLoading, setSnackStatus }) => {
	setLoading(true)

	try {
		const PartnerId = String(Cookies.get('partnerId'))
		const res = await ValidateStatusProductByDivisions({
			productId: productData?.id,
			partnerId: PartnerId,
			divisions: selectedDivisionIdRed,
			typeOfBenefit: 'Redeem',
		})

		if (res?.status == 200) {
			setHasParameterization(res?.data)
		}
	} catch (error) {
		setSnackStatus({
			oopen: false,
			severity: 'error',
			text: 'Ocorreu um erro ao validar a parametrização',
		})
	} finally {
		setLoading(false)
	}
}
