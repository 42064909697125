import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'

export const TitleStyled = styled.div`
	h6 {
		line-height: 100%;
		margin: 0;
		text-transform: unset;
	}

	h6:last-of-type {
		font-style: italic;
		font-size: 0.75rem;
		margin-top: 0.25rem;
	}
`

export const GridButton = styled(Button)`
	height: 100% !important;
	width: 52px !important;
	border-radius: 0px !important;
	margin: 0px 0px 0px 1px !important;

	& svg {
		width: 1.8rem !important;
		height: 1.8rem !important;
	}
`
export const Form = styled.form`
	width: 100%;

	.MuiButton-startIcon {
		margin-left: 0 !important;
		margin-right: 0.638rem !important;
	}
`

export const TableContainer = styled(Grid)`
	//padding-left: 8px;
	width: 100% !important;
	margin-top: 1rem !important;

	& > div {
		width: 100% !important;
	}

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				color: ${germiniDefaultColors[0]};
				font-size: 15px;
				font-weight: bold;
				:last-child {
					border-top-right-radius: 6px;
					padding: 0 !important;
					width: 20% !important;
					justify-content: center !important;
					text-align: right !important;
					padding-right: 1rem !important;
					> div {
						margin-right: 0px;
					}
					span {
						svg {
							display: none;
						}
					}
				}
			}
		}
	}

	.MuiTableBody-root {
		tr {
			color: #717171;
			background-color: #fff;
			td {
				font-size: 15px !important;
				height: 45px !important;
				padding: 0px 15px !important;

				:last-child {
					/* display: inline-flex !important; */
					padding: 0 !important;
					padding-right: auto !important;
					width: 10% !important;
				}
			}
		}
	}
`
