import React from 'react'
import PropTypes from 'prop-types'
import { DialogContent, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { DialogContainer } from './styles'
import Button from 'components/CustomButtons/Button.jsx'

function CustomModalError({ open, handleClose, icon = '', size = '700px', title = '', subtitle = '', buttonTitle = '', onConfirm }) {
	const { t } = useTranslation()
	return (
		<DialogContainer
			open={open}
			fullWidth
			size={size}
			onClose={handleClose}
			aria-labelledby="planning-dialog-title"
			aria-describedby="planning-dialog-description"
		>
			<DialogContent>
				<Grid container className="modal-box">
					<Grid item xs={12} className="modal-box-icon">
						<div className={icon} />
					</Grid>
					<Grid item xs={12} className="modal-box-title">
						<div>{title}</div>
					</Grid>

					<Grid item xs={12} className="modal-box-subtitle">
						<div>{subtitle}</div>
					</Grid>

					<Grid item xs={12} className="modal-box-button">
						<Button color="greenButtonColor" onClick={onConfirm}>
							{t(buttonTitle)}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</DialogContainer>
	)
}

CustomModalError.propTypes = {
	buttonTitle: PropTypes.string,
	handleClose: PropTypes.func,
	icon: PropTypes.string,
	onConfirm: PropTypes.func,
	open: PropTypes.bool,
	size: PropTypes.string,
	subtitle: PropTypes.string,
	title: PropTypes.string,
}

export default CustomModalError
