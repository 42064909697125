import styled from 'styled-components'

export const Close = styled.button`
	align-items: center;
	display: flex;
	justify-content: center;
	border: none;
	border-radius: 5px;
	background-color: #F05B4F;
	cursor: pointer;
	color: #fff;
	margin-left: 0.938rem;
	width: 90px;
	height: 35px;
	i {
		margin-right: 5px;
		margin-top: 3px;
	}
`
export const Icon = styled.div`
	color: #f9bb86;
	display: flex;
	font-size: 79px;
	justify-content: center;
	margin: 18px 0;
	top: -10px;
`
export const TextStyle = styled.div`
	text-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	font-size: 18px;
	justify-content: center;
	min-height: 211px;
	padding: 30px;
	width: 100%;
	i {
		font-size: 80px;
		color: #f8bb86;
	}
	p {
		font-weight: bold;
		margin-top: 30px;
	}
	span {
	}
`

export const Example = styled.div`
	text-align: left !important;
	background-color: #fff;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	img {
		width: 100%;
	}
`

export const Paragraph = styled.p`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 38px;
	margin-right: 38px;
	opacity: 0.9;
	color: #333333;
`

export const Model = styled.div`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 50px;
	padding-left: 32px;
	margin-right: 38px;
	opacity: 0.8;
	color: #333333;
`
export const Form = styled.form`
	.gfEDZo {
		width: 344px;
		height: 40px;
		background: #fff 0% 0% no-repeat padding-box;
		border: 1px solid #c4c4c4;
		opacity: 1;
	}
	.foVCqc {
		width: 344px;
	}
`
