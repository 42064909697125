// https://github.com/erikras/styled-components-theme
const customTheme = {
	primary: '#21409A',
	primaryDark: '#004A85',
	secondary: '#004A85',
	secondaryDark: '#004A85',
	background: '#F1F1F1',
	titleColor: '#4B4B4B',
	textPrimary: '#006CAC',

	main: '#2094D3',
	dark: '#004A85',
	light: '#6CADDF',

	white: '#fff',
	black: '#000000',
	grey: '#F5F5F5',
	darkGrey: '#E9E9E9',

	buttonDisabled: '#9c9c9c',
	buttonCancel: '#9f9f9f',
	buttonClear: '#eaeaea',

	tableCell: '#f9f9f9',

	switchDarkGrey: '#A9A9AA',

	fontFamily: "Roboto, 'Open Sans', sans-serif",

	red: '#F00',
	blue: '#00f',
	green: '#0f0',

	// MENU ACTIVE
	menuActivate: '#3DBAC7',
	// MENU Recebimento:
	menu1: '#647AB9',
	// MENU Crédito
	menu2: '#4D66AE',
	// MENU Relatorio
	menu3: '#3854A4',
	// SIDE MENU
	darkBlue: '#21409A',
	// DIVISOR
	divisor: '#D6D6D6',
	// INPUT BORDER
	border: '#C4C4C4',
	// TEXT COLORS
	textColor: '#333333',
	// TEXT DISABLED COLOR
	textColorDisabled: '#717171',
	// DISABLED
	disabled: '#D5D5D5',
	// GREEN BUTTON
	success: '#3EA850',
	// RED BUTTON
	error: '#F05B4F',
	// INFO
	info: '#3DBAC7',
	// INPUT COLOR
	inputText: '#21409A',
	// INPUT BG
	inputBG: '#f2f4f8',
	// INPUT DISABLED
	inputBGDisabled: '#F2F2F2',
	// INPUT DISABLED COLOR
	inputColorDisabled: '#717171',
	// INPUT BORDER
	inputBorderColor: '#C4C4C4',
	// LOADER
	warning: '#E3AB10',
}

export default customTheme
