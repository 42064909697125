import styled from 'styled-components'
import { TextField } from '@mui/material'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'

export const Container = styled.div`
	gap: 10px;
	display: flex;
	align-items: center;
	margin-right: 9px;
`

export const LabelCheckbox = styled.div`
	opacity: 1;
	color: #717171;
	text-align: left;
	letter-spacing: 0px;
	font: normal normal normal 15px Roboto;
`

export const CssTextField = styled(TextField)({
	backgroundColor: '#ffffff',
	width: '100%',
	marginTop: '3px',
	'& label.Mui-focused': {
		color: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
	},
	'& label': {
		lineHeight: 1.2,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		},
		'&.Mui-focused fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		},
	},
})
