import styled from 'styled-components';

export const Container = styled.div`
  .MuiOutlinedInput-notchedOutline {
    border: unset;
  }
`;

export const CustomInput = styled.div`
  align-items: flex-start;
  color: #be2c2c;
  display: flex;
  flex-direction: column;
  text-align: left;
  font: normal normal normal 16px Roboto;

  i {
    font-size: 20px;
  }

  label {
    text-align: left;
    font: normal normal normal 12px Roboto;
    letter-spacing: 0px;
    color: #989898;
    opacity: 1;
  }
`;
