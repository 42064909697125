/* eslint-disable */
import { Box, Grid } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import { Checkbox, Select, TagItem } from '../../../../../Components'
import { Stack } from '@mui/material'
import { getCheckListSelectedOptions, getLabelByElementId } from '../../../../../Functions'
import * as S from '../styles'
import Section from 'components/Section'
import { useTranslation } from 'react-i18next'
import { GetTypeGrouper } from './Functions'
import { getByElementKey } from '../../../../../Functions/getLabelByElementId'
import { CheckList } from '../../../../../../../../components/storybook'

export default function Configuration({ isEditData, partnerData, partnerInfos, customFieldsForm, updateCustomFieldsForm }) {
	const { control, errors } = useFormContext()

	const { t } = useTranslation()
	const [grouper, setGrouper] = useState([
		{
			key: '0',
			label: 'Desabilitado',
			value: 0,
		},
	])

	const catalogDuoDateOptions = [
		{
			key: '30',
			label: '30 dias',
			value: 'THIRTY',
		},
		{
			key: '60',
			label: '60 dias',
			value: 'SIXTY',
		},
		{
			key: '90',
			label: '90 dias',
			value: 'NINETY',
		},
	]

	useEffect(() => {
		GetTypeGrouper(setGrouper)
	}, [])

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={12} sm={12}>
					<Stack mt={10} sx={{ width: '100%' }}>
						<Section title="Configurações" />
					</Stack>
				</Grid>

				<Grid item lg={isEditData ? 4 : 12} sm={12}>
					<Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
						<Checkbox
							pt="7px"
							label="Utilizar a taxa padrão do programa"
							disabled={!isEditData}
							checked={customFieldsForm?.useDefaultConvertion}
							onChange={() => updateCustomFieldsForm('useDefaultConvertion', !customFieldsForm?.useDefaultConvertion)}
						/>
					</Stack>
				</Grid>
				{isEditData && (
					<Fragment>
						<Grid item lg={4} sm={12}>
							<Box sx={{ pt: 1 }}>
								{customFieldsForm?.useDefaultConvertion ? (
									<Box
										sx={{
											backgroundColor: '#F2F2F2',
											width: '100%',
											padding: '10px',
											borderRadius: '5px',
											color: '#858585',
											fontSize: '16px',
										}}
									>
										Taxa de conversão específica
									</Box>
								) : (
									<Controller
										control={control}
										name="businessConversionRate"
										render={({ onChange, value }) => (
											<S.CssTextField
												fullWidth
												label="Taxa de conversão específica"
												id="businessConversionRate"
												name="businessConversionRate"
												value={value}
												onChange={onChange}
												variant="outlined"
												margin="dense"
												style={{
													backgroundColor: customFieldsForm?.useDefaultConvertion ? '#F2F2F2' : '#fff',
												}}
											/>
										)}
										defaultValue={partnerData?.conversionRate}
									/>
								)}
							</Box>
						</Grid>
						<Grid item lg={4} sm={12}>
							<Box sx={{ pt: 0 }}>
								<Labelitem label="Prazo de fechamento contábil" margin={-1.4} />
								<Controller
									control={control}
									name="catalogBehavior"
									defaultValue={catalogDuoDateOptions.find(item => item.value === partnerData?.expirationDays?.value) || catalogDuoDateOptions[0]}
									render={({ onChange, value }) => <Select value={value} options={catalogDuoDateOptions} onChange={onChange} />}
								/>
							</Box>
						</Grid>
					</Fragment>
				)}
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem label="Benefício por agrupador" disabled value={t(getLabelByElementId(partnerData?.benefitGrouper, grouper))} />
					) : (
						<Fragment>
							<Labelitem label="Benefício por agrupador" isEditData />
							<Controller
								defaultValue={getByElementKey(partnerData?.benefitGrouper, grouper) || grouper[0]}
								control={control}
								name="benefitGroup"
								render={({ onChange, value }) => (
									<Select
										value={value}
										options={grouper}
										onChange={onChange}
										isEditData={isEditData}
										error={errors?.benefitGroup}
										helperText={errors?.benefitGroup && errors?.benefitGroup?.message}
									/>
								)}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={12} sm={12}>
					{!isEditData ? (
						<Fragment>
							<Box mt={3} sx={{ color: isEditData ? '#333333' : '#8D8D8D', fontSize: '13px' }}>
								Evento(s)
							</Box>
							<Grid container spacing={2}>
								{partnerData?.origins?.map((item, index) => (
									<Grid key={index} item lg={2} sm={3}>
										<TagItem label={item?.description} />
									</Grid>
								))}
							</Grid>
						</Fragment>
					) : (
						<Fragment>
							<Box direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
								<CheckList
									name="partnerEvents"
									selectorLabel="Evento(s)"
									fieldName="partnerEvents"
									removeTextLabel="Deletar todas"
									addTextLabel="Adicionar todas"
									options={partnerInfos?.partnerEvents}
									label="Selecione e adicione o(s) Evento(s)*"
									getSelectedItems={function noRefCheck(value) {
										updateCustomFieldsForm('partnerEvents', value)
									}}
									defaultOptions={getCheckListSelectedOptions(partnerData?.origins)}
								/>
							</Box>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}
