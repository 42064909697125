/* eslint-disable */

import React, { useState, useEffect } from 'react'
import style from './style.module.css'

const ProgressBar = ({ progressValue }) => {
	const [backgroundColor, set_bacoundColor] = useState(`white`)
	useEffect(() => {
		set_bacoundColor(`blue`)
	}, [])
	return (
		<div className={style.barContainer}>
			<div
				className={style.bar}
				style={{
					width: `${progressValue}%`,
					backgroundColor: backgroundColor,
				}}
			/>
		</div>
	)
}

export default ProgressBar
