import axios from "axios";

export const GetSponsors = (name, cnpj, companyName, pageSize, page) => {
  var query = `?page=${page && page !== "" ? page : "1"}`;
  if (name && name !== "") {
    query = query + `&name=${name}`;
  }
  if (cnpj && cnpj !== "") {
    query = query + `&cnpj=${cnpj}`;
  }
  if (companyName && companyName !== "") {
    query = query + `&companyName=${companyName}`;
  }
  if (pageSize && pageSize !== "") {
    query = query + `&pageSize=${pageSize}`;
  }
  return axios.get(`/Sponsor${query}`).then((response) => response.data);
};

export const GetSponsorById = (sponsorId) =>
  axios.get(`/Sponsor/${sponsorId}`).then((response) => response.data);

export const UpSertSponsors = (obj) =>
  axios.post(`/Sponsor/PostSponsor`, obj).then((response) => response.data);

export const UpdateSponsor = (sponsorId, obj) =>
  axios
    .put(`/Sponsor/UpdateSponsor/${sponsorId}`, obj)
    .then((response) => response.data);

export const DeleteSponsor = (sponsorId) =>
  axios.delete(`/Sponsor/${sponsorId}`).then((response) => response.data);

export const GetSponsorByCnpj = (sponsorId) =>
  axios
    .get(`/Sponsor/GetSponsorByCnpj/${sponsorId}`)
    .then((response) => response.data);

export const GetSponsorList = (companyName, pageSize) => {
  if (!pageSize) {
    return axios
      .get(`/Sponsor/ListSponsorByCompanyName?companyName=${companyName}`)
      .then((response) => response.data);
  }
  return axios
    .get(
      `/Sponsor/ListSponsorByCompanyName?companyName=${companyName}&pageSize=${pageSize}`
    )
    .then((response) => response.data);
};
