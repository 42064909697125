import React from 'react'
import * as S from './style'
import { useTranslation } from 'react-i18next'

// Components
import GridContainer from 'components/Grid/GridContainer.jsx'

export function Layout({ isOpen, ...props }) {
	const { t } = useTranslation()

	return (
		<S.ModalBox
			open={isOpen}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<S.Box>
				<S.ContainerChildren spacing={2}>
					{props.children}
				</S.ContainerChildren>
			</S.Box>
		</S.ModalBox>
	)
}
