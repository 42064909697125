import React, {useEffect} from 'react'
import { Grid, withStyles } from '@material-ui/core'

// Context
import { useAccounts } from '../hooks/useAccounts'

// Pages
import { AccountList } from '../AccountList'

export function PagesConfig() {
	const { switchComp } = useAccounts()

	return (
		<Grid container style={{ display: 'contents' }}>
			<AccountList />
		</Grid>
	)
}
