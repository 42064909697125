/* eslint-disable react/prop-types */
import { Box, Grid } from '@material-ui/core'
import React, { Fragment } from 'react'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as S from '../styles'
import { getLabelByElementId, validateValue } from '../../../../../Functions'
import { Stack } from '@mui/material'
import Checkbox from '../../../../Checkbox'
import { CellMaskCustom, TelephoneMaskCustom } from '../Address/Functions'
import Select from '../../../../Select'

export default function BusinessType({ isEditData, partnerData, customFieldsForm, updateCustomFieldsForm, partnerInfos }) {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={6} sm={12}>
					<Stack direction="row" justifyContent="flex-start" alignItems="center" alignContent="center" spacing={2}>
						<Box>
							<Fragment>
								<Checkbox
									label="Empresa se enquadra como MEI"
									disabled={!isEditData || partnerData?.branches?.length > 0 || !partnerData?.isMEI}
									checked={customFieldsForm?.isMEI}
									onChange={() => updateCustomFieldsForm('isMEI', !customFieldsForm?.isMEI)}
								/>
							</Fragment>
						</Box>
						{isEditData && (
							<Box>
								<Checkbox
									label="Isento IE"
									disabled={!isEditData || partnerData?.isIEFree}
									checked={customFieldsForm?.isIEFree}
									onChange={() => updateCustomFieldsForm('isIEFree', !customFieldsForm?.isIEFree)}
								/>
							</Box>
						)}
					</Stack>
				</Grid>
				<Grid item lg={3} sm={12}>
					{!partnerData?.isIEFree && !customFieldsForm?.isMEI && isEditData ? (
						<Fragment>
							<Labelitem label="UF da inscrição Estadual" />
							<Controller
								control={control}
								name="businessUF"
								defaultValue={partnerInfos.addressUFList?.find(uf => uf.value === partnerData?.ieStateId)}
								render={({ onChange, value }) => {
									return (
										<Select
											name="businessUF"
											isOptionEqualToValue={(option, value) => option.value === value}
											error={errors?.businessUF}
											helperText={t(errors?.businessUF?.message)}
											value={value}
											options={partnerInfos?.addressUFList ?? []}
											onChange={onChange}
										/>
									)
								}}
							/>
						</Fragment>
					) : (
						<Labelitem
							label="UF da inscrição Estadual"
							disabled
							value={validateValue(getLabelByElementId(partnerData?.ieStateId, partnerInfos.addressUFList))}
							isEditData={isEditData}
						/>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					<Labelitem label="Inscrição Estadual" disabled value={validateValue(partnerData?.ie)} isEditData={isEditData} />
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="Telefone" disabled value={validateValue(partnerData?.phoneNumber)} />
					) : (
						<Fragment>
							<Labelitem label="Telefone" isEditData />
							<Controller
								control={control}
								name="businessPhone"
								render={({ onChange, value }) => (
									<S.CssTextField
										error={errors?.businessPhone}
										helperText={t(errors?.businessPhone?.message)}
										fullWidth
										id="businessPhone"
										value={value}
										disabled={false}
										onChange={onChange}
										InputProps={{
											inputComponent: TelephoneMaskCustom,
										}}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
											marginTop: '8px',
										}}
									/>
								)}
								defaultValue={partnerData?.phoneNumber}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem label="Celular" disabled required value={validateValue(partnerData?.cellphoneNumber)} />
					) : (
						<Fragment>
							<Labelitem label="Celular" required isEditData />
							<Controller
								control={control}
								name="businessCellPhone"
								render={({ onChange, value }) => (
									<S.CssTextField
										error={errors?.businessCellPhone}
										helperText={t(errors?.businessCellPhone?.message)}
										fullWidth
										id="businessCellPhone"
										value={value}
										disabled={false}
										onChange={onChange}
										InputProps={{
											inputComponent: CellMaskCustom,
										}}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
											marginTop: '8px',
										}}
									/>
								)}
								defaultValue={partnerData?.cellphoneNumber}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={8} sm={12}>
					{!isEditData ? (
						<Labelitem label="Email" disabled required value={validateValue(partnerData?.email)} />
					) : (
						<Fragment>
							<Labelitem label="Email" required isEditData />
							<Controller
								control={control}
								name="businessEmail"
								render={({ onChange, value }) => (
									<S.CssTextField
										error={errors?.businessEmail}
										helperText={t(errors?.businessEmail?.message)}
										fullWidth
										id="businessEmail"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										style={{
											backgroundColor: '#fff',
											marginTop: '8px',
										}}
									/>
								)}
								defaultValue={partnerData?.email}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}
