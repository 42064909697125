import styled from 'styled-components'
import Card from 'components/Card/Card.jsx'

export const CustomCard = styled(Card)`
	margin: 0 0 0 15px;
	.MuiIcon-root {
		font-size: 30px;
		transform: rotateY(180deg);
	}
`

export const BodyCard = styled.div`
	padding: 0 25px;
`

export const Title = styled.p`
	font: normal normal normal 12px/60px Roboto;
	color: #333333;
	margin: 0;
	line-height: 100%;
`

