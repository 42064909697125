import axios from 'axios'
import { envs } from './windowServerData'
import Cookies from 'js-cookie'

export const setThemeColorToLocalStorage = _theme => {
	const string = JSON.stringify(_theme)
	localStorage.setItem('themeColor', string)
	Cookies.set('themeColor', string)
}

export const getThemeColorFromRequest = async () => {
	if (envs.REACT_APP_CVALE_IDENTITY === 'true') return

	return await axios
		.get(`${envs.REACT_APP_GERMINI_API_URL}/Themes/Partner`)
		.then(response => {
			return response
		})
		.catch(error => {
			console.log('error', error)
			return error
		})
}

const themeColor = {
	set(_val) {
		const theme = JSON.stringify(_val)
		Cookies.set('themeColor', theme)
		localStorage.setItem('themeColor', theme)
	},
	get() {
		try {
			const cookieTheme = Cookies.get('themeColor')
			const storageTheme = localStorage.getItem('themeColor')

			const re = JSON.parse(cookieTheme) || JSON.parse(storageTheme)
			return re
		} catch (err) {
			return [{}]
		}
	},
}

export const CVALE = {
	images: {
		loginPage: {
			logo: {
				filename: 'logo.png',
				url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/f9485d54-7717-4f2c-bfbd-d94dfb0bf212.png',
			},
			background: {
				filename: 'background.png',
				url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/2b51bff8-f731-415e-871b-ee09a71426cf.jpg',
			},
		},
		internalPages: {
			headerLogo: {
				filename: 'logo_header.png',
				url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/a1ea965e-5942-48ed-93d5-782e4729cfa3.png',
			},
			headerBackground: {
				filename: 'background_header.png',
				url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/a393f42d-e9cf-4317-b593-9abbd2564788.jpg',
			},
		},
		favicon: {
			filename: 'favicon.png',
			url: 'https://cvalefidelidadestorage.blob.core.windows.net/images/945bc099-46be-44c8-85a3-81e435838447.png',
		},
	},

	navigation: {
		buttons: {
			textColor: '#fff',
			backgroundColor: '#21409A',
			elementsColor: '#21409A',
		},
		menu: {
			activeColor: '#21409A',
		},
	},
	visual: {
		icons: {
			color: '#21409A',
			highlightColor: '#E58B00',
		},
	},
	titles: {
		main: {
			textColor: '#21409A',
		},
		secondary: {
			textColor: '#333333',
		},
		highlight: {
			textColor: '#21409A',
		},
	},
	structure: {
		backgroundColor: '#21409A',
	},
}

export const Germini = {
	images: {
		loginPage: {
			logo: {
				filename: 'logo.png',
				url: 'https://gerministore.blob.core.windows.net/images/9c40ab04-3457-4940-b066-ecc02b9482e8.png',
			},
			background: {
				filename: 'background.png',
				url: 'https://gerministore.blob.core.windows.net/images/de98c9e7-54ee-4bb5-a1ee-c35a85ad8819.jpg',
			},
		},
		internalPages: {
			headerLogo: {
				filename: 'logo_header.png',
				url: 'https://gerministore.blob.core.windows.net/images/60529145-9f02-4a0c-9440-850a9d0bfc8c.png',
			},
			headerBackground: {
				filename: 'background_header.png',
				url: 'https://gerministore.blob.core.windows.net/images/f042a99b-26a9-491f-81f5-b2e81b5d594b.jpg',
			},
		},
		favicon: {
			filename: 'favicon.png',
			url: 'https://gerministore.blob.core.windows.net/images/717c989a-d9c9-4c17-b9fa-a59fe9573cac.png',
		},
		defaultNotification: {
			filename: 'defaultNotification.png',
			url: 'https://gerministore.blob.core.windows.net/images/630b867f-493d-47c5-9323-09df2e83046d.png',
		},
	},
	navigation: {
		buttons: {
			textColor: '#FFFFFF',
			backgroundColor: '#F58B3C',
			elementsColor: '#F58B3C',
		},
		menu: { activeColor: '#F58B3C' },
	},
	visual: { icons: { color: '#F58B3C', highlightColor: '#1A9CAC' } },
	titles: {
		main: { textColor: '#1A9CAC' },
		secondary: { textColor: '#333333' },
		highlight: { textColor: '#1A9CAC' },
	},
	structure: { backgroundColor: '#F58B3C' },
}

const ThemeColorFromLocalStorage = () => {
	const themeColorStatic = {
		images: {
			loginPage: {
				logo: {
					filename: 'logo.png',
					url: 'https://gerministore.blob.core.windows.net/images/9c40ab04-3457-4940-b066-ecc02b9482e8.png',
				},
				background: {
					filename: 'background.png',
					url: 'https://gerministore.blob.core.windows.net/images/de98c9e7-54ee-4bb5-a1ee-c35a85ad8819.jpg',
				},
			},
			internalPages: {
				headerLogo: {
					filename: 'logo_header.png',
					url: 'https://gerministore.blob.core.windows.net/images/60529145-9f02-4a0c-9440-850a9d0bfc8c.png',
				},
				headerBackground: {
					filename: 'background_header.png',
					url: 'https://gerministore.blob.core.windows.net/images/f042a99b-26a9-491f-81f5-b2e81b5d594b.jpg',
				},
			},
			favicon: {
				filename: 'favicon.png',
				url: 'https://gerministore.blob.core.windows.net/images/717c989a-d9c9-4c17-b9fa-a59fe9573cac.png',
			},
			defaultNotification: {
				filename: 'defaultNotification.png',
				url: 'https://gerministore.blob.core.windows.net/images/630b867f-493d-47c5-9323-09df2e83046d.png',
			},
		},
		navigation: {
			buttons: {
				textColor: '#FFFFFF',
				backgroundColor: '#F58B3C',
				elementsColor: '#F58B3C',
			},
			menu: {
				activeColor: '#F58B3C',
			},
		},
		visual: {
			icons: {
				color: '#F58B3C',
				highlightColor: '#1A9CAC',
			},
		},
		titles: {
			main: {
				textColor: '#1A9CAC',
			},
			secondary: {
				textColor: '#333333',
			},
			highlight: {
				textColor: '#1A9CAC',
			},
		},
		structure: { backgroundColor: '#F58B3C' },
	}

	try {
		const local = Cookies.get('themeColor') || localStorage.getItem('themeColor')

		const themeColor = JSON.parse(local)
		return themeColor || themeColorStatic
	} catch (err) {
		return themeColorStatic
	}
}

export const ColorLuminance = (hex, lum) => {
	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '')
	if (hex.length < 6) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
	}
	lum = lum || 0
	// convert to decimal and change luminosity
	var rgb = '#',
		c,
		i
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i * 2, 2), 16)
		c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
		rgb += ('00' + c).substr(c.length)
	}
	return rgb
}

// export const getThemeColorFromLocalStorage = () => (`${envs.REACT_APP_CVALE_IDENTITY}` === 'true' ? CVALE : ThemeColorFromLocalStorage())
export const getThemeColorFromLocalStorage = () => (`${envs.REACT_APP_CVALE_IDENTITY}` === 'true' ? CVALE : ThemeColorFromLocalStorage())
export const GetThemeColorFromLocalStorage = () => (`${envs.REACT_APP_CVALE_IDENTITY}` === 'true' ? CVALE : themeColor.get())
