import { Box } from '@material-ui/core'
import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import { BusinessData, BusinessDivision, Documents, TabItem } from './Components'
import { Stack } from '@mui/material'
import Section from 'components/Section'
import Loader from 'components/Loader/Loader.jsx'

import { FormProvider, useForm } from 'react-hook-form'

import usePartnerProfile from './Hooks/usePartnerProfile'
import { getDefaultColor } from './Functions'
import { CustomTabItem } from './styles'
import ModalInfo from './Components/Modal/ModalInfo'
import { defaultBusinessMock } from './Mocks'
import { Button } from '../../../components/storybook'

export default function PartnerProfile() {
	const methods = useForm()

	const [editDivision, setEditDivision] = useState(false)
	const [selectedType, setSelectedType] = useState(defaultBusinessMock)
	const [onOpen, setOnOpen] = useState(false)
	const [openModalInfo, setOpenModalInfo] = useState({
		open: false,
		tabs: null,
	})

	const {
		getPartnerInfos,
		getPartnerData,
		getCityList,
		selectedItem,
		setSelectedItem,
		isEditData,
		setIsEditData,
		partnerData,
		partnerInfos,
		customFieldsForm,
		businessDivisionData,
		selectedState,
		setSelectedState,
		setZipCode,
		onTab,
		tabLabels,
		updateBusinessDivisionData,
		updateCustomFieldsForm,
		setBusinessDivisionData,
		partnerId,
		reloadFormsData,
		setIsLoadingData,
		isLoadingData,
		selectDivisionDetails,
	} = usePartnerProfile()

	const onCancelEdit = () => {
		setOnOpen(false)
		setIsEditData(false)
		setEditDivision(false)
		setSelectedType(defaultBusinessMock)
		setBusinessDivisionData(prev => {
			return {
				...prev,
				typeOfDivision: null,
				isEditBusinessDivision: false,
				isNewBusinessDivision: false,
				divisionDetails: null,
			}
		})
	}

	useEffect(() => {
		if (partnerId || !isEditData) {
			setIsLoadingData(true)
			getPartnerData()
			updateBusinessDivisionData('isEditBusinessDivision', false)
		}
	}, [partnerId, isEditData])

	useLayoutEffect(() => {
		getPartnerInfos()
	}, [])

	useEffect(() => {
		updateBusinessDivisionData('isEditBusinessDivision', false)
		reloadFormsData()
	}, [selectedItem])

	useEffect(() => {
		getCityList()
	}, [selectedState])

	const sharedProps = {
		isEditData,
		partnerData,
		partnerInfos,
		editDivision,
		onCancelEdit,
		setEditDivision,
		customFieldsForm,
		businessDivisionData,
		updateCustomFieldsForm,
		updateBusinessDivisionData,
		setSelectedState,
		setZipCode,
		partnerId,
		setIsEditData,
		selectedItem,
		segmentsList: partnerInfos?.segmentsList,
		originsList: partnerInfos?.originsList,
		parentsList: partnerInfos?.parentsList,
		reloadFormsData,
		setIsLoadingData,
		setBusinessDivisionData,
		getPartnerInfos,
		selectedType,
		setSelectedType,
		onOpen,
		setOnOpen,
		onTab,
		onSubmit: onTab[selectedItem],
	}

	return (
		<FormProvider {...methods}>
			<Fragment>
				{isLoadingData && <Loader />}
				<ModalInfo
					open={openModalInfo.open}
					onConfirm={() => {
						setIsEditData(false)
						setSelectedItem(openModalInfo.tabs)
						setOpenModalInfo({ open: false, tabs: null })
					}}
					onCancel={() => {
						setOpenModalInfo({ open: false, tabs: null })
					}}
				/>
				<Stack sx={{ display: editDivision && 'none' }}>
					<Section title="Perfil da empresa" style={{ marginBottom: '25px' }} />
				</Stack>
				<Stack
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					spacing={2}
					sx={{
						borderBottom: `4px solid ${getDefaultColor()}`,
						display: editDivision && 'none',
					}}
				>
					<Stack direction="row" justifyContent="flex-start" alignItems="center">
						{tabLabels?.map((item, index) => (
							<CustomTabItem
								onClick={() => {
									if (isEditData) {
										setOpenModalInfo({ open: true, tabs: index })
										return
									}
									setSelectedItem(index)
								}}
								key={index}
								index={index}
								selectedItem={selectedItem}
							>
								{item}
							</CustomTabItem>
						))}
					</Stack>
					<Box>
						{!isEditData ? (
							<Button typeVariation="CustomIconButton" onClick={() => setIsEditData(true)} />
						) : (
							<Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
								<Button
									type="submit"
									customType="saveSuccess"
									form={onTab[selectedItem]}
									disabled={selectedItem === 1}
									typeVariation="CustomIconButton"
								/>
								<Button customType="cancel" typeVariation="CustomIconButton" onClick={() => onCancelEdit()} />
							</Stack>
						)}
					</Box>
				</Stack>
				<TabItem value={selectedItem} index={0}>
					<BusinessData {...sharedProps} />
				</TabItem>
				<TabItem value={selectedItem} index={1}>
					<BusinessDivision selectDivisionDetails={selectDivisionDetails} {...sharedProps} />
				</TabItem>
				<TabItem value={selectedItem} index={2}>
					<Documents isEditData={isEditData} {...sharedProps} />
				</TabItem>
			</Fragment>
		</FormProvider>
	)
}
