import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Cards from './components/cards'
import { Controller } from 'react-hook-form'

// @ Functions
import { GetTypePoints, handleCalculateValue, handleProductDataUpdate } from './Functions'

// @ Styles and Utils
import { CssTextField } from '../../../../Filter/styles'
import { DefaultProductData } from './Functions/utils'

// @ Mui
import { Autocomplete, CircularProgress, FormLabel, Grid } from '@mui/material'

// @ Components
import Configuration from './components/Configuration'
import CustomModal from '../../../../../../Modal'
import { ParameterizationByDivision, MultipleParameterizationByDivision } from '../../../Functions'
import { ProductByDivision } from './Functions'
import SnackDefault from '../../../../../../../../../../../components/SnackDefault'
import Loader from 'components/Loader/Loader'
import CalcMemory from '../../../../CalculationMemory'

const ParameterizeDivisionTB = ({
	onReset,
	useData,
	division,
	viewMode,
	isMultiple,
	setViewMode,
	productData,
	pametrizationName,
	selectedDivisionIdGen,
	modalParameterization,
	setModalParameterization,
}) => {
	const { t } = useTranslation()
	const [GenerationType, setGenerationType] = useState([])
	const [parameterization, setParameterization] = useState([])
	const [divisionProductData, setDivisionProductData] = useState({})

	const [circularProgress, setCircularProgress] = useState(false)

	const [listDivision, setListDivision] = useState([])

	const [loading, setLoading] = useState(false)
	const [openCalcMemory, setOpenCalcMemory] = useState(false)

	useEffect(() => {
		ProductByDivision(division?.id, 'Points', productData?.id, setDivisionProductData)
	}, [division, setDivisionProductData, viewMode])

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const { control, watch, setValue } = useData

	const goToBack = () => {
		onReset()
		setViewMode(true)
	}

	const { typeOfPoints, pointsValue } = watch()

	const [parameterizationGenerationPoints, setParameterizationGenerationPoints] = useState(parameterization)

	const fetchCalculation = async points => {
		const generationPoints = await handleCalculateValue(
			productData?.id,
			divisionProductData?.price || productData?.price,
			typeOfPoints?.key || 0,
			points,
			divisionProductData?.typeOfRedeem?.key || productData?.typeOfRedeem?.key,
			divisionProductData?.redemptionPoints?.key || productData?.redemptionPoints?.key,
		)
		if (generationPoints) {
			setParameterizationGenerationPoints(generationPoints)
		}
	}

	const fetchGenerationType = () => {
		GetTypePoints({ setGenerationType, setCircularProgress })
	}

	const handleParameterization = () => {
		const commonParams = {
			setLoading,
			productData,
			divisionProductData,
			typeOfPoints,
			pointsValue,
			setSnackStatus,
		}

		if (isMultiple) {
			MultipleParameterizationByDivision({
				...commonParams,
				t,
				selectedDivisionIdGen,
				pametrizationName,
			})
		} else {
			ParameterizationByDivision({
				...commonParams,
				divisionId: division?.id,
				goToBack,
			})
		}

		setModalParameterization({ open: false })
	}

	useEffect(() => fetchGenerationType(), [])

	useEffect(() => {
		handleProductDataUpdate(divisionProductData, setParameterization, setValue)
	}, [divisionProductData])

	function handleValue(newValue) {
		if (newValue?.key === '0' || newValue?.key === '1') {
			setParameterization({
				parameterization: parameterizationGenerationPoints,
			})
		} else {
			setParameterization({
				parameterization: 0,
			})
		}
	}

	return (
		<S.CardsContent>
			{loading && <Loader />}
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => handleParameterization()}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			{isMultiple && <S.Line />}
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Configuração do benefício*')}</span>
						<Controller
							name="typeOfPoints"
							control={control}
							render={({ value, onChange }) => (
								<Autocomplete
									id="combo-box-demo"
									options={GenerationType}
									getOptionLabel={option => (option.value ? t(option.value) : '')}
									disableClearable={true}
									disablePortal
									disabled={viewMode}
									value={value}
									fullWidth
									onChange={(_event, newValue) => {
										handleValue(newValue)
										onChange(newValue)
										setValue('pointsValue', 0)
									}}
									renderInput={params => (
										<CssTextField
											{...params}
											variant="outlined"
											size="small"
											InputProps={{
												...params.InputProps,
												endAdornment: (
													<React.Fragment>
														{circularProgress && <CircularProgress color="inherit" size={20} />}
														{params.InputProps.endAdornment}
													</React.Fragment>
												),
											}}
										/>
									)}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				<Grid item xs={12} sm={9}>
					{typeOfPoints?.key !== '0' && (
						<Cards
							calculationMemory={parameterization}
							typeOfPoints={typeOfPoints?.key}
							setListDivision={setListDivision}
							onOpen={() => setOpenCalcMemory(!openCalcMemory)}
						/>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12}>
				{typeOfPoints?.key !== '1' && typeOfPoints?.key !== '0' && (
					<Configuration
						viewMode={viewMode}
						calculationMemory={parameterization}
						parameterizationGenerationPoints={parameterizationGenerationPoints}
						fetchCalculation={fetchCalculation}
						control={control}
						pointsValue={pointsValue}
						typeOfPoints={typeOfPoints?.key}
						divisionProductData={divisionProductData}
						listDivision={listDivision}
						onOpen={() => setOpenCalcMemory(!openCalcMemory)}
					/>
				)}
			</Grid>
			<CalcMemory
				open={openCalcMemory}
				calculationMemory={parameterization}
				productData={productData ?? []}
				parameterizationGenerationPoints={parameterizationGenerationPoints ?? []}
				onClose={() => setOpenCalcMemory(!openCalcMemory)}
			/>
		</S.CardsContent>
	)
}

ParameterizeDivisionTB.propTypes = {
	onReset: PropTypes.func,
	productData: PropTypes.object,
	divisionProductData: PropTypes.object,
	isMultiple: PropTypes.bool,
	useData: PropTypes.object,
	setViewMode: PropTypes.func,
	modalParameterization: PropTypes.object,
	setModalParameterization: PropTypes.func,
	division: PropTypes.object,
	viewMode: PropTypes.bool,
	selectedDivisionIdGen: PropTypes.string,
	pametrizationName: PropTypes.string,
}

ParameterizeDivisionTB.defaultProps = {
	divisionProductData: DefaultProductData,
	isMultiple: false,
}

export default ParameterizeDivisionTB
