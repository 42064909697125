import React, { useEffect, useState } from 'react'
import { Container } from './style'
import { EmptyTable } from './style'

import MaterialTable, {
	MTableCell,
	MTableHeader,
} from 'material-table'

import { Paper, TablePagination } from '@material-ui/core'
import { useStyles } from './utils'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const CustomTableMaterial = (props) => {
	const { data, paging, columns, tableRef } = props
	const [list, setList] = useState([])
	const classes = useStyles()
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		setList(data)
	}, [data])

	const btnBGCol = () => {
		if (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.backgroundColor
		) {
			return themeColor.navigation.buttons.backgroundColor + ' !important'
		}
		return ``
	}
	const titleColor = () => {
		if (
			themeColor &&
			themeColor.titles &&
			themeColor.titles.highlight &&
			themeColor.titles.highlight.textColor
		) {
			return themeColor.titles.highlight.textColor
		}
		return ``
	}

	const btnTextCol = () => {
		if (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor
		) {
			return themeColor.navigation.buttons.textColor + ' !important'
		}
		return ``
	}

	return (
		<Container colorTitle={titleColor()} btnBGCol={btnBGCol()}>
			<MaterialTable

				tableRef={tableRef}
				title=""
				columns={columns}

				components={{
					Container: (props) => (
						<Paper
							{...props}
							elevation={0}
							classes={{ root: classes.tableBody }}
						/>
					),

					Cell: (props) => (
						<MTableCell
							{...props}
							classes={{ root: classes.tableCell }}
						/>
					),
					Header: (props) => (
						<MTableHeader
							{...props}
							classes={{ header: classes.headerStyle }}
						/>
					),
					Pagination: (props) => (
						<TablePagination
							{...props}
							labelRowsPerPage=""
							classes={{
								root: classes.pagination,
								actions: classes.acttions,
							}}
						/>
					),
				}}
				icons={{
					Clear: () => (
						<i
							className="icon-lupa"
							style={{ color: `${btnTextCol()} !important` }}
						/>
					),
					ResetSearch: () => (
						<i
							className="icon-lupa"
							style={{
								fontSize: '20px',
								color: `${btnTextCol()} !important`,
							}}
						/>
					),
					Search: () => '',
				}}
				localization={{
					toolbar: {
						searchPlaceholder: 'Buscar',
					},
					header: {
						actions: 'Ações',
					},
					pagination: {
						labelRowsSelect: 'linhas',
						labelDisplayedRows: '{from}-{to} de {count}',
						firstAriaLabel: 'Primeira página',
						firstTooltip: '',
						previousAriaLabel: 'Anterior',
						previousTooltip: '',
						nextAriaLabel: 'Próxima',
						nextTooltip: '',
						lastAriaLabel: 'Última página',
						lastTooltip: '',
					},

					body: {
						emptyDataSourceMessage: (
							<EmptyTable>
								<i
									className="icon-informacao"
									style={{ color: '#B4B4B4' }}
								/>
								<span>Nenhum participante adicionado</span>
							</EmptyTable>
						),
					},
				}}
				cellStyle={(rowData) => ({
					backgroundColor: '#000',
					color: rowData.tableData.checked ? 'red !important' : '',
				})}
				options={{
					rowStyle: (rowData) => ({
						backgroundColor: 'rgb(255, 255, 255)',
						color: rowData.tableData.checked
							? 'red !important'
							: '',
					}),
					emptyRowsWhenPaging: false,
					paging: true,
					toolBar: false,
					selection: false,
					search: false,
					pageSizeOptions: [10, 20, 30, 40, 50],
					pageSize: 10,
					sorting: false,
					showTextRowsSelected: false,
					draggable: false,
				}}
				{...props}
			/>
		</Container>
	)
}
export default CustomTableMaterial
