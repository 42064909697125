import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@material-ui/core'

// @Components
import Loader from 'components/Loader/Loader.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import Section from 'components/Section'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { HideLoader } from 'redux/actions'
import { numberFormatText } from 'utils/utils'
// import { ApiGet } from '../Api'
import { useAward } from '../../../Providers/AwardContext'
import { GetAwardId, PostAwardRefund } from '../../../redux/api/award.js'
import ConfirmRefundModal from '../ConfirmRefundModal'
import ModalReversal from '../ModalReversal'
import TotalItemsDisplay from '../TotalItemsDisplay'
import * as S from './style'
import {envs}  from '../../../redux/api/windowServerData.js'



const Reversal = () => {
	const tableRef = React.useRef()
	const { register, handleSubmit, watch } = useForm()
	const watchAllFields = watch()
	const { token, validatedReversal, idDetailProcessed } = useAward()
	const history = useHistory()
	const { id } = useParams()
	const { t } = useTranslation()
	const dispatch = useDispatch()

	dispatch(HideLoader())

	const [isLoaded, setIsLoaded] = useState(false)
	const [dataLogAward, setDataLogAward] = useState([])
	const [openModal, setOpenModal] = useState(false)
	const [openPwdModal, setOpenPwdModal] = useState(false)
	const [errorModal, setErrorModal] = useState(false)
	const [selectList, setSelectList] = useState(false)
	const [selectedCpfList, setSelectedCpfList] = useState([])
	const [allCpfList, setAllCpfList] = useState([])
	const [reservedValue, setReservedValue] = useState(0)
	const [totalItems, setTotalItems] = useState(0)
	const [pageSizeState, setPageSizeState] = useState(10)
	const [recordPageState, setRecordPageState] = useState()
	const [refundType, setRefundType] = useState(1)
	const [dataResponse, setDataResponse] = useState([])
	const { setRadioValue } = useAward()

	useEffect(() => {
		setRadioValue('batch')
	}, [])

	useEffect(() => {
		if (watchAllFields.search === '') {
			tableRef.current.onQueryChange({ page: 0, search: '' })
		}
	}, [watchAllFields.search])

	useEffect(() => {
		setSelectedCpfList(allCpfList)
	}, [allCpfList])

	const handleChangeRadio = value => {
		switch (value) {
			case 'individual':
				setSelectList(true)
				setSelectedCpfList([])
				setRefundType(2)
				setRadioValue('individual')
				break
			case 'batch':
				setSelectList(false)
				setSelectedCpfList(allCpfList)
				setRefundType(1)
				setRadioValue('batch')
				break
			default:
				setSelectList(false)
		}
	}

	const handleGetRefundData = async (pageSize, page, search) => {
		const res = await GetAwardId(id, pageSize, page, search, false)
		if (res) {
			setDataResponse(res.data)
		}

		setReservedValue(res.data.bankAccountReserve && res.data.bankAccountReserve.reserveBalance)

		setTotalItems(res.data.consumers.recordCount)
		setAllCpfList(res.data.consumers.results)

		return res
	}

	const onSubmitFilter = data => {
		let value = data.search
			.replaceAll('.', '')
			.replaceAll('-', '')
			.replaceAll(' ', '')
			.replaceAll('	', '')
		tableRef.current.onQueryChange({ page: 0, search: value })
	}

	const handleConfirmPasswordModal = async data => {
		setIsLoaded(true)
		try {
			let consumerIds = []
			selectedCpfList && selectedCpfList.map(item => consumerIds.push(item.id))

			const obj = {
				awardId: id,
				password: data.password,
				refundType: refundType,
				consumerIds: consumerIds,
			}

			const res = await PostAwardRefund(obj)

			setOpenPwdModal(false)
			setOpenModal(false)
		} catch (error) {
			console.log(error)
		} finally {
			setIsLoaded(false)
			if (selectedCpfList) {
				history.push({
					pathname: '/financial/reversalConfirm',
					state: { data: selectedCpfList, reservedValue: reservedValue },
				})
			}
		}
	}

	const handleConfirmModal = () => {
		if (selectedCpfList.length > 0) {
			setOpenPwdModal(true)
		}
	}

	return (
		<>
			{isLoaded && <Loader />}
			{validatedReversal ? (
				<S.Container>
					<ConfirmRefundModal
						open={openPwdModal}
						onCancel={() => setOpenPwdModal(false)}
						onDelete={value => handleConfirmPasswordModal(value)}
						error={errorModal && 'Senha incorreta'}
					/>
					<ModalReversal
						setOpenModal={setOpenModal}
						openModal={openModal}
						handleConfirm={handleConfirmModal}
						cpfList={selectedCpfList}
						reversalType={selectList}
						pageSize={pageSizeState}
						recordPage={recordPageState}
					/>
					<div>
						<div
							style={{
								marginBottom: '15px',
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<Section title={t('REVERSAL_DATA')} style={{ flexGrow: 1 }} />
							<div style={{ marginLeft: '15px' }}>
								<Button color={'greenBorderButtonColor'} size="sm" onClick={() => history.push('/financial/award')}>
									Cancelar
								</Button>
								<Button
									disabled={dataResponse.allowRefund === false ? true : false}
									style={{
										cursor: `${dataResponse.allowRefund === false ? 'default' : 'pointer'}`,
										marginLeft: '8px',
										color: '#fff',
									}}
									size="sm"
									variant="contained"
									color="greenButtonColor"
									onClick={() => setOpenModal(true)}
								>
									Estornar
								</Button>
							</div>
						</div>
					</div>
					<S.GridContain>
						<S.FieldContain style={{ marginBottom: '15px' }}>
							<S.InputBox style={{ width: '220px' }}>
								<label>Data do evento</label>
								<S.Inputs
									value={
										dataResponse.processingDate
											? `${moment(dataResponse.processingDate).format('DD/MM/YYYY')} - ${moment(dataResponse.processingDate).format('HH:mm')}`
											: '-'
									}
								/>
							</S.InputBox>
							<S.InputBox style={{ width: '700px', marginRight: '0' }}>
								<label>Descrição da premiação</label>
								<S.Inputs value={dataResponse.description} />
							</S.InputBox>
						</S.FieldContain>
						<S.FieldContain style={{ marginBottom: '15px' }}>
							<S.InputBox style={{ width: '220px' }}>
								<label>Evento</label>
								<S.Inputs value={dataResponse.partnerCreditOrigin ? dataResponse.partnerCreditOrigin.value : '-'} />
							</S.InputBox>
							<S.InputBox style={{ width: '440px' }}>
								<label>Reserva da conta</label>
								<S.ShowValue>
									{numberFormatText(
										dataResponse.reserveBalance ? dataResponse.reserveBalance : '0',
										localStorage.getItem('currencySymbol')
											? `${localStorage.getItem('currencySymbol')}\n`
											: // : envs
											  // 	.REACT_APP_CURRENCY_SYMBOL,
											  envs.REACT_APP_CURRENCY_SYMBOL,
										2,
									)}
								</S.ShowValue>
								{/* <S.Inputs
									value={
										dataResponse.bankAccountReserve
											? dataResponse.bankAccountReserve
													.name
											: '-'
									}
								/> */}
							</S.InputBox>
							<S.InputBox style={{ marginRight: '0', width: '235px' }}>
								<label>Valor do saldo reservado</label>
								<S.ShowValue>
									{numberFormatText(
										dataResponse.bankAccountReserve ? dataResponse.bankAccountReserve.reserveBalance : '0',
										// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
										localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
										2,
									)}
								</S.ShowValue>
							</S.InputBox>
						</S.FieldContain>
						<S.ContainFields>
							<S.InputBox
								style={{
									marginRight: '0',
									width: '400px',
									minWidth: '220px',
								}}
							>
								<label>Observação</label>
								<S.Inputs value={dataResponse.observation ? dataResponse.observation : '-'} />
							</S.InputBox>

							{dataLogAward.isAnticipated && (
								<S.InputBox style={{ width: '220px' }}>
									<label style={{ marginLeft: '15px' }}>Taxa de antecipação</label>
									<S.Inputs value={dataLogAward.anticipationFee ? dataLogAward.anticipationFee + '%' : '-'} />
								</S.InputBox>
							)}
							{dataLogAward.isAnticipated && (
								<S.InputBox style={{ marginRight: '0', width: '235px' }}>
									<label style={{ marginLeft: '15px' }}>Carência</label>
									<S.Inputs value={dataLogAward.graceDescription ? dataLogAward.graceDescription : '-'} />
								</S.InputBox>
							)}
						</S.ContainFields>

						<FormControl component="fieldset" onChange={e => handleChangeRadio(e.target.value)}>
							<RadioGroup
								defaultValue="batch"
								style={{
									flexDirection: 'row',
									justifyContent: 'space-between',
									padding: '0',
									width: '500px',
									marginTop: '20px',
								}}
							>
								<S.MyTooltip
									arrow
									id="tooltip-top"
									title={'Ao selecionar está opção você irá estornar o valor integral do lote'}
									placement="top-start"
								>
									<FormControlLabel
										value="batch"
										control={<Radio color="primary" style={{ padding: '0' }} />}
										label="Estornar lote"
										style={{
											margin: '0',
											color: '#23429a',
										}}
									/>
								</S.MyTooltip>

								<S.MyTooltip arrow id="tooltip-top" title={'Você deve selecionar quais clientes serão estornados'} placement="top-start">
									<FormControlLabel
										value="individual"
										control={<Radio color="primary" style={{ padding: '0' }} />}
										label="Estornos individuais"
										style={{
											margin: '0',
											color: '#23429a',
										}}
									/>
								</S.MyTooltip>
							</RadioGroup>
						</FormControl>

						<S.FieldContain
							onSubmit={handleSubmit(onSubmitFilter)}
							autoComplete="off"
							style={{
								position: 'relative',
								justifyContent: 'flex-end',
							}}
						>
							<TextField inputRef={register} name="search" id="standard-basic" label="Buscar" variant="standard" />
							<button type="submit">
								<i className="icon-lupa" />
							</button>
						</S.FieldContain>
					</S.GridContain>
					{/* TABLE */}
					<MaterialTable
						tableRef={tableRef}
						style={{ width: '100%' }}
						options={{
							selectionProps: rowData => ({
								color: 'primary',
							}),
							showTextRowsSelected: false,
							showTitle: false,
							showSelectAllCheckbox: false,
							emptyRowsWhenPaging: false,
							toolBar: false,
							pageSizeOptions: [10, 20, 30, 40, 50],
							search: false,
							pageSize: recordPageState < 10 ? recordPageState : pageSizeState,
							sorting: false,
							selection: selectList,
							searchFieldStyle: {
								marginBottom: 20,
							},
						}}
						columns={[
							{
								title: 'CPF / Cód. participante',
								searchable: true,
								width: '190px',
								maxWidth: '190px',
								minWidth: '190px',
								cellStyle: {
									backgroundColor: '#fff',
									textAlign: 'left',
									width: '15%',
									maxWidth: '15%',
									minWidth: '15%',
								},
								headerStyle: {
									lineHeight: '1.2',
									height: '50px',
									fontSize: '15px',
									textAlign: 'left',
								},
								render: props => (
									<div
										style={{
											alignItems: 'flex-start',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											height: '34px',
										}}
									>
										{props.cpF_Code > 5 ? props.cpF_Code.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4') : props.cpF_Code}
									</div>
								),
							},
							{
								title: 'Valor',
								searchable: true,
								cellStyle: {
									textAlign: 'left',
									backgroundColor: '#fff',
									width: '30%',
									maxWidth: '30%',
									minWidth: '30%',
								},
								headerStyle: {
									textAlign: 'left',
									fontSize: '15px',
								},
								render: props => (
									<div
										style={{
											alignItems: 'flex-start',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'center',
											height: '34px',
										}}
									>
										{numberFormatText(
											props.value ? props.value : '0',
											// localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											localStorage.getItem('currencySymbol') ? `${localStorage.getItem('currencySymbol')}\n` : envs.REACT_APP_CURRENCY_SYMBOL,
											2,
										)}
									</div>
								),
							},
						]}
						data={query =>
							new Promise((resolve, reject) => {
								handleGetRefundData(query.pageSize, query.page + 1, query.search).then(result => {
									setDataLogAward(result.data)
									if (result.data.consumers) {
										setPageSizeState(result.data.consumers.pageSize)
										setRecordPageState(result.data.consumers.recordCount)
										resolve({
											data: result.data.consumers.results,
											page: result.data.consumers.currentPage - 1,
											totalCount: result.data.consumers.recordCount,
											pageSize: result.data.consumers.pageSize,
										})
									}
								})
							})
						}
						onSelectionChange={rows => setSelectedCpfList(rows)}
					/>
				</S.Container>
			) : (
				history.push('/financial/award')
			)}
			<div
				style={{
					display: 'flex',
					justifyContent: 'end',
					marginTop: 15,
				}}
			>
				<TotalItemsDisplay numItems={selectedCpfList.length} />
			</div>
		</>
	)
}
export default Reversal
