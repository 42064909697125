import _ from 'lodash'
export default function validateValue(value) {
	return value && value != [] ? value : '-'
}

export const getValuesObject = obj => {
	if (!obj) return { name: '', id: '' }

	return {
		name: obj?.name,
		id: obj?.id,
	}
}
