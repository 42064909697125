import React, { useEffect, useState } from 'react'
import compose from 'utils/compose'
import { withTranslation } from 'react-i18next'

// css
import * as S from './style.js'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const Section = ({ title, style }) => {
	const [theme, set_theme] = useState({})

	useEffect(() => {
		set_theme(getThemeColorFromLocalStorage())
	}, [])

	const getBackgroundColor = () => {
		if (theme && theme.structure && theme.structure.backgroundColor) {
			return theme.structure.backgroundColor
		}
		return '#fff'
	}
	const getTitleColor = () => {
		if (
			theme &&
			theme.titles &&
			theme.titles.main &&
			theme.titles.main.textColor
		) {
			return theme.titles.main.textColor
		}
		return '#fff'
	}
	return (
		<S.Container
			themeBorder={getBackgroundColor()}
			themeTitle={getTitleColor()}
			style={style}
		>
			{title}
		</S.Container>
	)
}

export default compose(withTranslation())(Section)
