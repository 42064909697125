import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import GridItem from 'components/Grid/GridItem.jsx'

export const ButtonItem = styled(Grid)`
	text-align: right;
	margin-top: 0px;
	margin-bottom: 15px;
	padding: 0px !important;
`

export const TitlesBtns = styled(Grid)`
	display: flex;
	margin-left: 15px;
`

export const TitlesContainer = styled(Grid)`
	text-align: right;
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
`

export const SearchBox = styled(GridContainer)`
	width: 100% !important;
	background: #f6f6f6;
	border-radius: 8px;
	margin-top: 10px !important;
	margin-left: 0px !important;
	padding: 20px 0px;

	@media only screen and (max-width: 959px) {
		gap: 20px;
	}

	@media only screen and (max-width: 599px) {
		padding: 20px 15px;
	}
`

export const InnerContainer = styled(Grid)`
	.header {
		display: flex;
		width: 100%;
		background-color: #fafafa;
		padding: 20px;
		flex-wrap: wrap;
		align-items: center;
		> div {
			margin-right: 20px;
		}
	}

	.filter-box {
		display: flex;
		max-height: 310px;

		.box-height {
			min-height: 180px;
		}

		background-color: #fafafa;
		margin: 0;
		/* padding: 16px; */
		padding-left: 24px;
		button {
			max-height: 38px;
		}
		.search-button {
			margin-right: 20px;
			color: #fff;
			&:hover {
				background-color: ${germiniDefaultColors[0]};
				opacity: 0.8;
			}
		}
	}

	@media screen and (max-width: 960px) {
		max-height: initial;
		.box-height {
			min-height: initial;
		}
		.grid-input {
			margin-top: 16px;
		}
	}

	@media screen and (max-width: 1280px) {
		.cards-box {
			justify-content: center !important;
		}
	}
`

export const BoxNotification = styled(Grid)`
	background-color: #fff;
	height: 100%;
	padding: 28px 28px 0;
	margin: 30px 0;
	width: 100%;

	.section {
		font-weight: 300;
		color: #5d5d5d;
		margin-top: 10px;
	}
	> div {
		&:first-child {
			padding: 0 !important;
		}
	}

	.MuiToolbar-regular {
		min-height: 0px;
	}

	.grid-container {
		margin: 0 30px;
		padding: 0 0 15px 0;
	}

	.button-container {
		max-height: 36px;
	}

	.filter-box {
		margin: 30px 0;
		background-color: #fafafa;
		padding: 16px;
		padding-left: 24px;
		.search-button {
			margin-right: 20px;
			color: #fff;
			&:hover {
				background-color: ${germiniDefaultColors[0]};
				opacity: 0.8;
			}
		}
	}

	.MuiPaper-elevation2 {
		box-shadow: unset;
	}

	.MuiTableCell-root {
		padding: 0;
		padding: 10px 0 10px 15px;
		> div {
			.MuiIconButton-root {
				background: transparent !important;
				box-shadow: none !important;
			}
			button {
				.MuiTouchRipple-root {
					display: none !important;
				}
				cursor: pointer;
				box-shadow: none !important;
			}
		}
	}

	.MuiTableHead-root {
		tr {
			th {
				:first-child {
					border-radius: 6px 0 0 0;
				}
				:last-child {
					border-radius: 0 6px 0 0;
					> div {
					}
					span {
						svg {
						}
					}
				}
			}
		}
	}

	.MuiTableBody-root {
		tr {
			color: #717171;
			background-color: #fff;
			td {
				font-size: 15px !important;
				/* background-color: black; */
				height: 45px !important;
				padding: 0px 15px !important;
				:first-child {
					width: 10% !important;
				}
				> div {
				}

				> div p {
					margin: 0px;
					font-size: 13px;
					font-style: italic;
				}
				:last-child {
					padding: 0 !important;
					width: 1% !important;
					justify-content: flex-end;
					text-align: right !important;
					> div {
					}
				}
			}
		}
	}

	.MuiIconButton-root {
		padding: 0px !important;
	}

	.submit-container {
		margin: 20px 0;
		padding-right: 16px;
		> :first-child {
			margin-right: 10px;
		}
	}
	@media screen and (max-width: 1200px) {
		.create-title {
			width: 100%;
			margin-top: 30px;
		}
	}
`

export const CustomFormLabel = styled.div`
	cursor: default !important;
	display: inline-flex !important;
	font-size: 15px !important;
	line-height: 1.428571429 !important;
	font-weight: 600 !important;
	//padding-top: 20px !important;
	color: #333333;
	margin-right: 0 !important;
	//margin-bottom: 15px;
`

export const GridBox = styled(Grid)`
	width: 100%;

	tbody {
		border-left: none;
		border-right: none;
	}

	.MuiPaper-elevation2 {
		box-shadow: unset;
	}

	.MuiToolbar-regular {
		min-height: 0px;
		padding: 15px;
	}

	.MuiTableBody-root {
		tr {
			color: #717171;
			font-size: 15px;
			height: 45px;

			p {
				font-size: 13px;
				font-style: italic;
				margin: 0px;
			}

			td {
				padding: 0px 15px !important;

				:last-child {
					button {
						span {
							svg {
								width: 22px;
								height: 22px;
							}
						}
					}
				}
				:nth-child(2) {
					text-align: left !important;
				}
			}
		}
	}
`

export const GridContent = styled(Grid)`
	padding: 0px !important;
`

export const GridButton = styled(Button)`
	height: 45px !important;
	width: 40px !important;
	border-radius: 0px !important;
	margin: 0px 0px 0px 1px !important;

	.op-icon {
		height: 24px !important;
		width: 24px !important;
		font-size: 20px;
	}
`

export const GroupButtons = styled(GridItem)`
	display: flex;
	justify-content: flex-end;
	gap: 15px;

	@media only screen and (max-width: 959px) {
		margin-top: 0px !important;
	}
`
