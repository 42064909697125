import React, { Fragment } from 'react';
import PasswordStrengthBars from 'react-password-strength-bar';
import axios from 'axios';
import { withTranslation } from 'react-i18next';

// @material-ui/core components
import { Grid, Tooltip } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

// style
import styles from '../resetPassword.module.scss';
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// UTILS
import compose from 'utils/compose';

const PwdTooltip = withStyles({
	tooltip: {
		textAlign: 'left!important',
		maxWidth: '500px!important',
		padding: '8px 15px',
		borderRadius: '10px!important',
		'@media (max-width: 780px)': {
			maxWidth: '300px!important',
			marginRight: '10px',
		},
	},
})(Tooltip)

class ResetPassword extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			corporationPartners: true,
		};
	}

	componentDidMount() {
		axios.get(`/ProgramRules/General/BasicData`)
			.then(response => { this.setState({ corporationPartners: response.data.hasCorporationPartners }) })
	}

	render() {
		const {
			t, classes,
			values,
			errors,
			touched,
			handleSubmit,
			handleCancel,
			handleChange,
			isValid,
			setFieldTouched, setFieldValue
		} = this.props;

		const change = (name, e) => {
			e.persist();
			handleChange(e);
			setFieldTouched(name, true, false);
		};

		return (
			<Fragment>
				<Grid>
					<label className={styles.font}>{t("MENU_USER_EDIT_PASSWORD")}</label>
				</Grid>
				<GridContainer>
					<GridItem xs={12} style={{ marginTop: "7px" }}>
						<CustomInput
							id="newPassword"
							name="newPassword"
							// labelText={this.state.corporationPartners ? 'CPF/CNPJ' : 'CPF'}
							labelText="Nova senha"
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: values.newPassword,
								type: "password",
								onChange: change.bind(null, "newPassword")
							}}
							error={
								touched.newPassword &&
								Boolean(errors.newPassword)
							}
							helpText={
								touched.newPassword
									? errors.newPassword
									: ''
							}
						/>
						<PasswordStrengthBars
							password={values.newPassword}
							minLength={2}
							shortScoreWord={"muito curto"}
							// onChangeScore={handleChangeScore}
							barColors={['#dddddd', '#ef4836', '#f6b44d', '#2b90ef', '#25c281']}
							scoreWords={['muito fraca', 'fraca', 'regular', 'boa', 'forte']} />

						{/* <TextField
                            fullWidth
                            label="Nova senha"
                            id="newPassword"
                            name="newPassword"
                            error={touched.newPassword && Boolean(errors.newPassword)}
                            value={values.newPassword}
                            onChange={change.bind(null, "newPassword")}
                            variant="outlined"
                            margin="dense"
                            type="password"
                            style={{ backgroundColor: '#fff' }}
                        /> */}
					</GridItem>
				</GridContainer>
				<GridContainer>
					<GridItem xs={12} style={{ marginTop: "7px" }}>
						<CustomInput
							id="confirmPassword"
							name="confirmPassword"
							labelText={t("Confirmar senha")}
							formControlProps={{
								fullWidth: true
							}}
							inputProps={{
								value: values.confirmPassword,
								type: "password",
								onChange: change.bind(null, "confirmPassword")
							}}
							error={
								touched.confirmPassword &&
								Boolean(errors.confirmPassword)
							}
							helpText={
								touched.confirmPassword
									? errors.confirmPassword
									: ''
							}
						/>
						{/* {errors.confirmPassword} */}
						<div style={{ textAlign: 'right' }}>
							<PwdTooltip
								arrow
								id="tooltip-top"
								title={
									<div>
										<b>Regras:</b>
										<ul
											style={{
												marginLeft:
													'-18px',
											}}
										>
											<li>No mínimo 8 caracteres</li>
											<li>No máximo 20 caracteres</li>
											<li>Conter letras, números e caracteres especiais</li>
											<li>Não pode ter mais de 3 números sequenciais</li>
											<li>Não pode ter números repetidos em ordem</li>
											<li>Não ser igual ao CPF, CNPJ, data de nascimento, telefone ou celular</li>
										</ul>
									</div>
								}
								placement="right-end"
							>
								<small
									style={{
										cursor: 'pointer',
										color: '#21409a',
										'text-decoration': 'underline',
										'text-decoration-style': 'dotted'
									}}
								>
									Política de senha
								</small>
							</PwdTooltip>
						</div>
						{/* <TextField
                            fullWidth
                            label="Confirmar senha"
                            id="confirmPassword"
                            name="confirmPassword"
                            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
                            value={values.confirmPassword}
                            onChange={change.bind(null, "confirmPassword")}
                            variant="outlined"
                            margin="dense"
                            type="password"
                            style={{ backgroundColor: '#fff' }}
                        /> */}
					</GridItem>
				</GridContainer>
				<GridContainer style={{ paddingTop: '45px', textAlign: "center" }}>
					<GridItem xs={12} style={{ display: 'flex' }}>
						<Grid style={{ marginRight: '8px' }}>
							<Button
								style={{
									width: "145px",
									fontSize: 15,
									height: 35,
									padding: "0 15px",
								}}

								size="sm"
								variant="contained"
								color="primary"
								//disabled={disabledForm}
								className={styles.Button}
								onClick={handleSubmit}
							>{t("LOGIN_SEND")}
							</Button>
						</Grid>
						<Grid>
							<Button
								color="greenBorderButtonColor"
								style={{
									width: "145px",
									fontSize: 15,
									height: 36,
									padding: "0 15px",
									borderRadius: "6px",
								}}
								onClick={handleCancel}
							>
								{t("BUTTON_CANCEL")}
							</Button>
						</Grid>
					</GridItem>
				</GridContainer>
			</Fragment>
		)
	}
}

export default compose(withTranslation(), withStyles(extendedFormsStyle))(ResetPassword);
