import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, MuiInput, MuiModal, MuiStack, MuiTypography } from '../../../../../components/storybook'
import { Modal } from '@mui/material'

export function CreateCampainModal({ open, handleClose, onConfirm, defaultColor }) {
	const { t } = useTranslation()
	const [inputValue, setInputValue] = useState('')

	return (
		<Modal
			open={open}
			onClose={handleClose}
			sx={{
				width: '70%',
				margin: '20% auto',
			}}
		>
			<MuiStack
				sx={{
					background: '#fff',
					padding: 3,
					borderRadius: 1.5,
				}}
			>
				<MuiStack direction="row" justifyContent="space-between">
					<MuiTypography variant="body1" fontWeight={600} color={defaultColor}>
						Origem da negociação
					</MuiTypography>
					<MuiStack direction="row" spacing={2}>
						<Button customType="cancel" typeVariation="CustomIconButton" onClick={handleClose} />
						<Button
							customType="save"
							typeVariation="CustomIconButton"
							disabled={inputValue?.length < 1}
							onClick={() => {
								onConfirm(inputValue)
								setInputValue(null)
							}}
						/>
					</MuiStack>
				</MuiStack>
				<MuiStack mt={5}>
					<MuiInput
						showLabel
						textLabel={inputValue?.length > 0 ? 'Crie uma origem para esta negociação*' : ' '}
						placeholder="Crie uma origem para esta negociação*"
						value={inputValue}
						onChange={e => setInputValue(e.target.value)}
					/>
				</MuiStack>
			</MuiStack>
		</Modal>
	)
}
