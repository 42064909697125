import React, { useState, useEffect /*, useRef*/, useContext } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

// @ Material UI
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import { Icon } from '@mui/material'

// @ Components
import { FormField } from 'components/FormField/styles'
import SimpleModal from 'components/CustomModal/Confirm'
import Button from 'components/CustomButtons/Button.jsx'

// @Context and Request
import GeneralContext from '../../../../../GeneralContext'

// @ Utils and Styles
import * as S from './styles'
import { PropsSnack, CssTextField } from '../utils'
import {
	createPartnerSettings,
	ChangeSelectedPurchaser,
	ValidatePurchaserCredentials,
} from '../../../../../redux/api/paymentGateway'
import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'
import Cookies from 'js-cookie'

const ModalsAuthorizer = ({ parentToChild, childToParent, setOpen }) => {
	const { t } = useTranslation()
	const { setIsSucess, purchase, settings, setSettings } = useContext(
		GeneralContext,
	)

	const [openCancelModal, setOpenCancelModal] = useState(false)
	const [disableValidate, setDisableValidate] = useState(true)
	const [validatedConnection, setValidatedConnection] = useState('')
	const [finish, setFinish] = useState(true)
	const [credentials, setCredentials] = useState({})

	// HOOKFORM
	const { control, handleSubmit, getValues, watch } = useForm({})

	// MODAL
	const handleCloseAuthorizer = () => {
		setOpenCancelModal(true)
		setValidatedConnection('')
	}

	const handleClickCancelModal = () => {
		setOpenCancelModal(false)
		setValidatedConnection('')
		childToParent({ click: false })
	}

	const handleCloseCancelModal = () => setOpenCancelModal(false)

	const getValue = (value, event) => {
		event(value)
		if (event) {
			setDisableValidate(false)
		}
	}

	const unstructureGetValues = () => {
		const { id, key, idOrToken } = getValues()
		return { id, key, idOrToken }
	}

	const compareCredentials = () => {
		const credentials = []
		credentials.push(unstructureGetValues())
		setCredentials(credentials[0])
	}

	console.log(getValues())

	const ValidatedConnection = async () => {
		const partnerId = String(Cookies.get('partnerId'))
		const validated = await ValidatePurchaserCredentials(
			purchase.id, partnerId,
			parentToChild.name === 'GetNet' || parentToChild.name === 'Juno'
				? {
					ClientId: getValues().id,
					ClientSecret: getValues().key,
					SellerId: getValues().idOrToken,
				}
				: parentToChild.name === 'Cielo' ||
					parentToChild.name === 'Paypal'
					? {
						MerchantId: getValues().id,
						MerchantKey: getValues().key,
					}
					: parentToChild.name === 'Rede'
						? {
							PV: getValues().id,
							Token: getValues().key,
						} : parentToChild.name === 'PagarMe' ||
							parentToChild.name === 'Yapay'
							? verifyAmbient(purchase, getValues())
							: '',
		)

		validated === undefined
			? setValidatedConnection(false)
			: setValidatedConnection(true)

		validated === undefined
			? setDisableValidate(false)
			: setDisableValidate(true)

		validated !== undefined ? setFinish(false) : setFinish(true)

		if (validated !== undefined) {
			compareCredentials()
		}
	}

	const Finish = () => {
		setIsSucess(PropsSnack)
		setValidatedConnection('')
		setFinish(true)
		childToParent({
			click: false,
			finish: true,
			icon: parentToChild.icon,
		})
	}

	const handleBack = () => {
		setValidatedConnection('')
		childToParent({ value: false })
		setOpen(true)
	}

	const updatePurchaser = async values => {

		const paymentMethods = []
		for (const key in purchase.paymentMethods) {
			paymentMethods.push({
				name: purchase.paymentMethods[key].name,
				enabled: purchase.paymentMethods[key].enabled,
			})
		}

		const brands = []
		for (const key in purchase.brands) {
			brands.push({
				name: purchase.brands[key].name,
				enabled: purchase.brands[key].enabled,
			})
		}

		const updateSelectedPurchaser = await ChangeSelectedPurchaser(
			{
				purchaserId: purchase.id,
				priority: purchase.priority,
				connection: verifyAmbient(purchase, values),
				paymentMethods: paymentMethods,
				brands: brands,
				isTestActive: values.enviroment === 'Teste' ? true : false,
				isProdActive: values.enviroment === 'Produção' ? true : false,
			},
		)

		if (updateSelectedPurchaser !== undefined) {
			Finish()
			setSettings(updateSelectedPurchaser.data)
		}

		return updateSelectedPurchaser
	}

	const handleSettings = async values => {
		const partnerId = String(Cookies.get('partnerId'))

		if (Object.keys(settings).length === 0) {
			try {
				const paymentMethods = []
				for (const key in purchase.paymentMethods) {
					paymentMethods.push({
						name: purchase.paymentMethods[key].name,
						enabled: purchase.paymentMethods[key].enabled,
					})
				}

				const brands = []
				for (const key in purchase.brands) {
					brands.push({
						name: purchase.brands[key].name,
						enabled: purchase.brands[key].enabled,
					})
				}
				const response = await createPartnerSettings({
					partnerId: partnerId,
					isGerminiPayActive: true,
					selectedPurchaser: {
						purchaserId: purchase.id,
						priority: 1,
						brands: brands,
						connection: verifyAmbient(purchase, values),
						paymentMethods: paymentMethods,
						isTestActive:
							values.enviroment === 'Teste' ? true : false,
						isProdActive:
							values.enviroment === 'Produção' ? true : false,
					},
				})

				setSettings(response?.data)
				Finish()
			} catch (error) {
				console.log(error)
			}
		}

		if (Object?.keys(settings ?? [])?.length !== 0) {
			updatePurchaser(values)
		}
	}

	const verifyAmbient = (purchase, values) => {
		if (!purchase) return

		if (!values) return

		const { name, connection } = purchase
		const { enviroment } = values

		if (name === 'GetNet' || name === 'Juno') {
			return {
				clientId: values.id,
				clientSecret: values.key,
				sellerId: values.idOrToken,
			}
		}

		if (name === 'Cielo' || name === 'Paypal') {
			return {
				MerchantId: values.id,
				MerchantKey: values.key,
			}
		}

		if (name === 'PagarMe' && enviroment === 'Teste') {
			return {
				sandboxPublicKey: values.id,
				sandboxSecretKey: values.key,
				prodPublicKey: 'key',
				prodSecretKey: 'key',
			}
		}

		if (name === 'Rede' && enviroment === 'Teste') {
			return {
				pv: values.id,
				token: values.key,
				prodPublicKey: 'key',
				prodSecretKey: 'key',
			}
		}

		if (enviroment === 'Prod') {
			return {
				sandboxPublicKey: 'key',
				sandboxSecretKey: 'key',
				prodPublicKey: values.id,
				prodSecretKey: values.key,
			}
		}

		return connection
	}

	useEffect(() => {
		if (parentToChild.click === false) {
			setDisableValidate(true)
		}

		if (Object.keys(credentials).length !== 0) {
			let isEqual =
				JSON.stringify(credentials) === JSON.stringify(validatedWatch())
			if (!isEqual) {
				setDisableValidate(false)
				setFinish(true)
				if (validatedConnection === true) {
					setValidatedConnection('')
				}
			}
			if (isEqual) {
				setDisableValidate(true)
				setFinish(false)
				setValidatedConnection(true)
			}
		}
	}, [credentials, watch()])

	const validatedWatch = () => {
		const { id, key, idOrToken } = watch()

		return { id, key, idOrToken }
	}

	return (
		<>
			<Modal open={openCancelModal ? false : parentToChild.value}>
				<S.CustomBoxModal onSubmit={handleSubmit(handleSettings)}>
					<S.BoxIcon>
						<Icon>
							<i className="icon-servicos_contratados_pagamentos" />
						</Icon>
						<S.Titles>
							<span> {t('BUTTON_CONNECT_WITH_AUTHORIZER')} </span>
							<p> {t('FIELD_INTEGRATION WITH AUTHORIZER')} </p>
						</S.Titles>
					</S.BoxIcon>
					<S.ValidatedConnection>
						<S.Authorizer>
							<span> {t('Autorizadora')} </span>
							<img src={parentToChild.icon} />
						</S.Authorizer>
						{validatedConnection === true ? (
							<>
								<S.Icon>
									<Icon>
										<i
											style={{
												color: '#7AC57E',
											}}
											className="icon-ico_API_conectada"
										/>
									</Icon>
								</S.Icon>
								<p
									style={{
										color: '#7AC57E',
										margin: '0 0 0 15px',
									}}
								>
									{t('FIELD_VALIDATED_CONNECTION')}{' '}
								</p>
							</>
						) : validatedConnection === false ? (
							<>
								<S.Icon>
									<Icon>
										<i
											style={{
												color: '#F05B4F',
												width: 'auto',
												height: 'auto',
											}}
											className="icon-ico_API_erro_conexao"
										/>
									</Icon>
								</S.Icon>
								<p
									style={{
										color: '#F05B4F',
										margin: '0 0 0 15px',
									}}
								>
									{t('FIELD_NOT_VALIDATED_CONNECTION')}
									<br />
									{t('FIELD_VERIFY_INFORMATION')}
								</p>
							</>
						) : (
							''
						)}
					</S.ValidatedConnection>
					<S.BodyModal style={{ marginTop: '25px' }}>
						<Grid container spacing={1}>
							<Grid item xs={12} sm={12} md={8} lg={8}>
								<Controller
									onChange={([, data]) => data}
									name="enviroment"
									control={control}
									render={({ onChange, value }) => (
										<Autocomplete
											disablePortal
											id="combo-box-demo"
											size="small"
											options={['Teste', 'Produção']}
											disableClearable={true}
											value={value}
											onChange={(e, data) =>
												getValue(data, onChange)
											}
											renderInput={params => (
												<CssTextField
													size="small"
													{...params}
													label={t(
														'FIELD_SELECT_ENVIRONMENT',
													)}
												/>
											)}
										/>
									)}
								></Controller>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={parentToChild.labelKey === '' ? 12 : 6}
								lg={parentToChild.labelKey === '' ? 12 : 6}
							>
								<Controller
									control={control}
									name="id"
									defaultValue={''}
									render={({ onChange, value }) => (
										<FormField
											variant="outlined"
											formControlProps={{
												fullWidth: true,
											}}
											onChange={e =>
												onChange(e.target.value)
											}
											value={value}
											size="small"
											label={parentToChild.labelId}
										/>
									)}
								></Controller>
							</Grid>
							{parentToChild.labelKey !== '' ? (
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Controller
										control={control}
										name="key"
										defaultValue={''}
										as={
											<FormField
												variant="outlined"
												formControlProps={{
													fullWidth: true,
												}}
												size="small"
												label={parentToChild.labelKey}
											/>
										}
									></Controller>
								</Grid>
							) : (
								''
							)}
							{parentToChild.name === 'GetNet' ||
								parentToChild.name === 'Juno' ? (
								<Grid item xs={12} sm={12} md={6} lg={6}>
									<Controller
										control={control}
										name="idOrToken"
										defaultValue={''}
										as={
											<FormField
												variant="outlined"
												formControlProps={{
													fullWidth: true,
												}}
												size="small"
												label={
													parentToChild.name ===
														'GetNet'
														? t('SellerId*')
														: t('PrivateToken*')
												}
											/>
										}
									></Controller>
								</Grid>
							) : (
								''
							)}
						</Grid>
					</S.BodyModal>
					<S.ModalButtons>
						<Button
							size="sm"
							variant="contained"
							color="greenBorderButtonColor"
							style={{
								margin: '10px 15px 0 0',
							}}
							onClick={handleBack}
						>
							{t('BUTTON_BACK')}
						</Button>
						<Button
							size="sm"
							variant="contained"
							color="greenBorderButtonColor"
							style={{
								margin: '10px 15px 0 0',
							}}
							onClick={handleCloseAuthorizer}
						>
							{t('BUTTON_CANCEL')}
						</Button>
						<Button
							size="sm"
							onClick={ValidatedConnection}
							disabled={disableValidate}
							style={{
								backgroundColor: disableValidate
									? '#BBBBBB'
									: getThemeColorFromLocalStorage()
										?.navigation?.buttons
										?.backgroundColor,
								margin: '10px 15px 0 0',
								color: '#fff',
							}}
						>
							{t('BUTTON_VALIDATE')}
						</Button>
						<Button
							size="sm"
							disabled={finish}
							type="submit"
							style={{
								backgroundColor: finish
									? '#BBBBBB'
									: getThemeColorFromLocalStorage()
										?.navigation?.buttons
										?.backgroundColor,
								margin: '10px 0 0 0',
								color: '#fff',
							}}
						>
							{t('BUTTON_FINISH')}
						</Button>
					</S.ModalButtons>
				</S.CustomBoxModal>
			</Modal>
			<SimpleModal
				open={openCancelModal}
				onClick={handleClickCancelModal}
				handleClose={handleCloseCancelModal}
				btnCloseTxt={t('BUTTON_NO')}
				btnConfirmTxt={t('BUTTON_YES')}
				text={t('FIELD_RESET_CONNECT_AUTHORIZER')}
			/>
		</>
	)
}

ModalsAuthorizer.propTypes = {
	parentToChild: PropTypes.object.isRequired,
	childToParent: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	setValues: PropTypes.func.isRequired,
	setOpen: PropTypes.func.isRequired,
}

export default ModalsAuthorizer
