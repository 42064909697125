import styled from 'styled-components'

export const PictureContainer = styled.div`
	position: relative;
	cursor: pointer;
	text-align: center;
	.image {
		background-color: #fff;
		border: 0.25rem solid ${({ border }) => (border ? border : '#fffff9')};
		color: #fff;
		border-radius: 5%;
		overflow: hidden;
		transition: all 0.2s;
		-webkit-transition: all 0.2s;
	}
	.pictureSrc {
		display: flex;
		width: 100%;
		height: max-content;
		object-fit: cover;
	}
`

export const Icon = styled.div`
	background-color: ${({ backgroundColor }) =>
		backgroundColor ? backgroundColor : '#fffff9'};
	position: absolute;
	left: 7px;
	top: 10px;
	border: 2px;
	padding: 8px;
	border-radius: 60px;
`
