import React from 'react'
import { NumericFormat } from 'react-number-format'

export const USDValue = props => {
	const { inputRef, onChange, ...other } = props

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				})
			}}
			thousandSeparator
			isNumericString
			prefix="SD$"
		/>
	)
}

export const PercentageValue = props => {
	const { inputRef, onChange, ...other } = props

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				})
			}}
			thousandSeparator
			isNumericString
			suffix="%"
		/>
	)
}

export const PTBRValue = props => {
	const { inputRef, onChange, ...other } = props

	return (
		<NumericFormat
			{...other}
			getInputRef={inputRef}
			onValueChange={values => {
				onChange({
					target: {
						name: props.name,
						value: values.value,
					},
				})
			}}
			thousandSeparator
			isNumericString
			prefix="%"
		/>
	)
}
