import React, { Fragment, useRef, useState, useEffect } from 'react'
import SearchForm from './SearchForm'
import TableComponent from './Table'
import { useForm } from 'react-hook-form'
import Snackbar from 'components/Snackbar'
import { DetailsModal } from '../DetailsModal'

const ExportedLogs = () => {
	const tableRef = useRef()
	const [modal, setModal] = useState({ open: false, id: '' })
	const [openSnack, setOpenSnack] = useState({
		open: false,
		color: '',
		message: '',
	})

	const { control, handleSubmit, reset, watch, setValue } = useForm({
		defaultValues: {
			period: [],
			dateFrom: '',
			dateTo: '',
			history: '',
		},
	})

	useEffect(() => {
		let timer
		if (openSnack) {
			timer = setTimeout(() => {
				setOpenSnack({ open: false })
			}, 3000)
		}
		return () => {
			clearTimeout(timer)
		}
	}, [openSnack])

	return (
		<Fragment>
			<Snackbar
				close={true}
				marginTop="130px"
				open={openSnack.open}
				color={openSnack.color}
				handleClose={() => setOpenSnack({ open: false })}
				message={openSnack.message}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			/>
			<DetailsModal
				setOpenSnack={setOpenSnack}
				open={modal.open}
				id={modal.id}
				tableRef={tableRef}
				handleClose={() => setModal({ open: false, id: '' })}
				type=""
			/>
			<SearchForm
				tableRef={tableRef}
				control={control}
				handleSubmit={handleSubmit}
				reset={reset}
				watch={watch}
				setValue={setValue}
			/>
			<TableComponent
				tableRef={tableRef}
				watch={watch}
				setModal={setModal}
			/>
		</Fragment>
	)
}

export default ExportedLogs
