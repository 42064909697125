import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import {
	germiniDefaultColors,
	grayColor,
} from 'assets/jss/material-dashboard-pro-react.jsx'


export const CardsSection = styled.section`
    margin-top: 25px;
`

export const NotificationContainer = styled(Grid)`
	.main-card,
	.button-box {
		/* max-width: 1300px; */
	}
	.button-box {
		padding: 0 20px;
	}
	.main-card {
		margin: 10px 0;
	}

	.card-container {
		cursor: pointer;
	}
	.card-container + .card-container {
		padding: 15;
	}

	.pagination-container {
		border: 1px solid #ccc;
		padding: 10px 0;
		border-right: none;
		border-left: none;
		margin-bottom: 20px;
		/* position: relative;
    bottom: -45px; */
	}

`

export const CardContainer = styled(Grid)`
	/* margin-right: 40px; */
	/* max-width: 320px !important;
  min-width: 320px !important; */

	.header-img {
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		border: 1px solid #b4b4b4;
		border-bottom: none;
		background-image: url(${({ img }) => img});
		width: 100%;
		height: 206px;
		background-repeat: no-repeat;
		background-position: center center;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
		background-size: cover;
	}

	.read-column {
		font-size: 12px;
		font-weight: 400;
		color: #fff;
		border: 1px solid #b4b4b4;
		border-bottom: none;
		border-top: none;
		padding: 3px 15px;
		justify-content: space-between;
		background-color: ${({ isread, colors }) =>
		isread ? '#b2b2b2' : colors};
		svg {
			margin-right: 10px;
		}
	}

	.read-line {
		font-size: 14px;
		color: ${({ isread, colors }) =>
		isread ? '#9d9d9d' : colors};
		height: 84px;
		max-height: 65%;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		font-weight: ${({ isread }) => (isread ? 400 : 700)};
	}

	.text-content {
		background-color: #fff;
		height: 110px;
		padding: 10px 15px;
		border-bottom-left-radius: 6px;
		border-bottom-right-radius: 6px;
		border: 1px solid #b4b4b4;
		border-top: none;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
	}

	.date-small {
		font-size: 12px;
		font-weight: 400;
		color: #333333;
	}

	.link {
		margin-top: 5px;
		font-size: 12px;
		color: #29479d;
		cursor: pointer;
		&:hover {
			font-weight: bold;
		}
	}

`
