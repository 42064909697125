import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`
export const Content = styled.div`
	display: flex;
	width: 100px;
	justify-content: flex-end;
	transform: translateY(-40px);
`

export const CardsContent = styled.div`
	margin-top: 25px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	.FormLabel {
		text-align: left;
		font: normal normal normal 13px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
	}
`

export const CustomSymbol = styled.div`
	color: #333333;
	opacity: 1;
`
