export const DefaultProductData = {
	standardAdministrationFeeApplied: '',
	standardGrossValue: '',
	standardAccumulatePoints: '',
	customAdministrationFeeApplied: '',
	customGrossValue: '',
	customAccumulatePoints: '',
	calculationMemory: {
		conversionRate: '',
		administrationFee: '',
		valueToConvert: '',
		multiplierFactor: '',
	},
}

export const StandardColors = {
	CostCenter: '#3EC450',
	BusinessUnit: '#FB9006',
	Branch: '#7E6BB9',
	Department: '#1BBED3',
}

export const newValue = (value, maxValue) => {
	if (value > maxValue) {
		return maxValue
	}
	return value
}
