import React from 'react'
import PropTypes from 'prop-types'
import { AntTab, AntTabs, useStyles } from './styles'
import { Box, Typography } from '@material-ui/core'

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box style={{ p: '30x 0px' }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
}

export default function CustomizedTabs({ tabs, active, handleTabChange, disabled }) {
	const classes = useStyles()

	return (
		<div>
			<div className={classes.root}>
				<div className={classes.demo1}>
					<AntTabs
						value={active}
						onChange={handleTabChange}
						aria-label="ant example"
					>
						{tabs.map(({ isShow = true, label, disabled }) => {
							return isShow && (
								<AntTab
									key={label}
									label={label}
									disabled={disabled}
								/>
							)
						})}
					</AntTabs>
				</div>

				{tabs.map((tab, index) => {
					const { isShow = true } = tab
					return isShow && (
						<TabPanel key={index} value={active} index={index}>
							{tab.tabContent}
						</TabPanel>
					)
				})}
			</div>
		</div>
	)
}

CustomizedTabs.defaultProps = {
	active: 0,
	color: 'primary',
	disabled: false,
}

CustomizedTabs.propTypes = {
	classes: PropTypes.object,
	active: PropTypes.number,
	tabs: PropTypes.arrayOf(
		PropTypes.shape({
			label: PropTypes.string,
			tabContent: PropTypes.node,
			disabled: PropTypes.bool,
			isShow: PropTypes.bool,
		}),
	),

	handleTabChange: PropTypes.func,
	disabled: PropTypes.bool,
}
