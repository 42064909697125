import { convertSegmentsToId } from '../Functions'
import _ from 'lodash'

export default function businessDivisionDataCreate({ data, segments, segmentsList, customFieldsForm, partnerId, selectedDivisionType }) {
	const formData = {
		name: data?.businessDivisionName,
		parentId: data?.businessDivisionParent?.value ?? null,
		defaultSegmentationType: selectedDivisionType,

		//identifier: data?.businessDivisionID,
		identifier: data?.businessDivisionID || data.businessDivisionId || null,
		description: data?.businessDivisionDescription,

		segments: segments && selectedDivisionType !== 'CostCenter' ? convertSegmentsToId(segments, segmentsList) : null,
		marketingArea: data?.businessDivisionMarketingArea?.value,
		address: !_.isEmpty(data?.businessDivisionCEP)
			? {
				location: data?.businessDivisionAddress || null,
				district: data?.businessDivisionDistrict || null,
				number: data?.businessDivisionAddressNumber || null,
				aditionalInfo: data?.businessDivisionAditionalInfo || null,
				zipCode: data?.businessDivisionCEP || null,
				stateId: data?.businessDivisionUF?.value || null,
				cityId: data?.businessDivisionCity?.value || null,
				countryId: data?.businessDivisionCountry?.value || null,
			}
			: null,
		contact: !_.isEmpty(data?.businessDivisionResponsibleCPF)
			? {
				name: data?.businessDivisionResponsibleName || null,
				cpf: data?.businessDivisionResponsibleCPF || null,
				cellphone: data?.businessDivisionResponsibleCellphone || null,
				email: data?.businessDivisionResponsibleEmail || null,
			}
			: null,
	}

	if (selectedDivisionType === 'Branch' || selectedDivisionType === 'Franchise') {
		return {
			...formData,
			parentId: partnerId,
			cnpj: data?.businessDivisionCNPJ ?? null,
			foundingDate: data?.businessDivisionFundationDate ?? null,
			isMEI: data?.isMEI || customFieldsForm?.isMEI || null,
			isIEFree: data?.isIEFree || customFieldsForm?.isIEFree || null,
			ieStateId: data?.ieStateId?.value || data?.ieStateId || '',
			ie: data?.ie ?? null,
		}
	}

	return formData
}
