import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'

// Material
import { Grid } from '@material-ui/core'

// Components
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import { AccountData } from './AccountData'
import { Balance } from './Balance'
import { Filter } from './List'

//Context
import { AccountsProvider } from '../contexts/AccountsContext'

import { useParams } from 'react-router-dom'
import { CustomGrid, title } from '../styles'


export function AccountManage() {
	const { t } = useTranslation()
	const history = useHistory()

	const { id } = useParams()

	function handleBack() {
		history.goBack()
	}

	return (
		<AccountsProvider>
			<Grid container>
				<Grid
					xs={12}
					style={title}
				>
					<Grid item xs={9} sm={11}>
						<Section
							title={t('ACCOUNT_MANAGE_MOVES')}
							style={{ flexGrow: 1 }}
						/>
					</Grid>
					<Button
						size="sm"
						style={{
							marginLeft: '0.938rem',
						}}
						variant="outlined"
						color="greenBorderButtonColor"
						//disableElevation
						onClick={handleBack}
					>
						{t('BUTTON_BACK')}
					</Button>
				</Grid>
				<Grid item xs={12} sm={6} style={{ marginTop: '-0.1rem' }}>
					<div style={{ marginRight: '0.938rem' }}>
						<AccountData />
					</div>
				</Grid>
				<Grid item xs={12} sm={6} style={{ marginTop: '-0.1rem' }}>
					<Balance />
				</Grid>

				<Grid item
					xs={12}
					sm={12}
					style={{ margin: '1.5rem 0 1.563rem 0' }}
				>
					<Section title={t('LAST_MOVES')} style={{ flexGrow: 1 }} />
				</Grid>

				<CustomGrid item
					xs={12}
					sm={12}
					style={{
						borderRadius: '10px',
					}}
				>
					<Filter bankAccountId={id} />
				</CustomGrid>
			</Grid>
		</AccountsProvider>
	)
}
