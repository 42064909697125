import React from 'react'
import * as S from './style'

const index = ({ numItems }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<S.text>Total de itens</S.text>
			<S.number style={{ marginLeft: 10 }}>
				{numItems < 10 ? `0${numItems}` : numItems}
			</S.number>
		</div>
	)
}

export default index
