import styled from 'styled-components/macro'

export const Container = styled.div`
	align-items: center;
	font-size: 16px;
	display: flex;
	//color: ${({ themeTitle }) => themeTitle};
	white-space: nowrap;
	font-weight: bold;
	overflow: hidden;
	&:after {
		border-top: 2px solid ${({ themeTitle }) => themeTitle};
		content: '';
		display: table-cell;
		position: relative;
		width: 100%;
		text-align: left;
		left: 15px;
		align-items: center;
		justify-content: center;
	}
`
