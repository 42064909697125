import React from 'react'
import { useTranslation } from 'react-i18next'
import { MuiModal } from '../../../../../components/storybook'

export function CancelNegotiationModal({ open, handleClose, onConfirm }) {
	const { t } = useTranslation()

	return (
		<MuiModal
			open={open}
			alertIcon
			description="Ao cancelar, as alterações serão perdidas"
			customDescription="Deseja realmente continuar?"
			onConfirm={onConfirm}
			onClose={handleClose}
		/>
	)
}
