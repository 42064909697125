import Axios from 'axios'

class NegotiationDetailsService {
	async getDetailsById(id) {
		try {
			const response = await Axios.get(`/Negotiation/GetById/${id}`)
			return response.data
		} catch (error) {
			console.error('Erro ao obter detalhes da negociação por ID', error)
			throw error
		}
	}
}

export default new NegotiationDetailsService()
