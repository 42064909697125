import styled from 'styled-components'
import Button from 'components/CustomButtons/Button.jsx'

export const Container = styled.div`
	padding: 15px;
	width: 100%;
`

export const TitleDiv = styled.div`
	display: flex;
	justify-content: space-between;
`

export const ButtonClose = styled(Button)`
	background-color: transparent !important;
	box-shadow: none !important;
	height: 25px;
	width: 25px !important;
	z-index: 9999;
	padding: 0 !important;
	.icon-modal_fechar {
		color: #333;
		font-size: 25px;
	}
`

export const Title = styled.div`
	font-size: 18px;
	font-weight: bold;
`

export const DetailContainer = styled.div`
	border: 0.3px solid #333333;
	margin-top: 15px;
	width: 100%;
`

export const DetailDiv = styled.div`
	display: flex;
	width: 100%;
`

export const DetailTitle = styled.div`
	border: 0.3px solid #333333;
	height: 40px;
	padding: 10px;
	font-weight: bold;
	width: 20%;
`

export const DataDetail = styled.div`
	border: 0.3px solid #333333;
	height: 40px;
	padding: 10px;
	width: 100%;
`
