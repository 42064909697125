import React, { Fragment } from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'
import MaskedInput from 'react-text-mask'
import Select from 'react-select'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { Add, Remove } from '@material-ui/icons'
import { TextField, IconButton, Grid } from '@material-ui/core'
import { Icon } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'
import { makeStyles, createStyles } from '@material-ui/core/styles'

// core components
import Button from 'components/CustomButtons/Button.jsx'
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import NoResults from 'components/NoResults/NoResults.jsx'
import Section from 'components/Section'

import GriTable from './Components/GriTable'

// Utils
import compose from 'utils/compose'
import { FormatCPF } from 'utils/utils'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import styles from './settings.module.scss'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'
import { germiniDefaultColors, grayColor } from 'assets/jss/material-dashboard-pro-react'

// REDIX INIT

import { connectRouterState } from 'utils/connectRouterState'

import { ListUsers, SweetAlert, GetCurrentPartner, HideAlert, ShowLoader, HideLoader } from 'redux/actions'

import styled from 'styled-components/macro'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { GroupButtons, MoreFilters, SearchBox } from './styles'
import { getProgramTitle } from '../../utils/utils'

const Container = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${props =>
		props && props.themeColor && props.themeColor.titles && props.themeColor.titles.highlight && props.themeColor.titles.highlight.textColor};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${props => props && props.themeColor && props.themeColor.structure && props.themeColor.structure.backgroundColor};
	}

	@media only screen and (max-width: 959px) {
		.grid-results {
			padding-left: 0px !important;
		}
	}
`

const CustomPopper = function (props) {
	const classesPoper = useStyles()
	return <Popper {...props} className={classesPoper.root} placement="bottom" />
}

function CPFMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}

const userStyle = {
	...extendedFormsStyle,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	inputHeight: {
		height: '9px',
	},
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
			'&.Mui-focused fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
		},
	},
})(TextField)

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				'& :hover': {
					color: '#ffffff',
					backgroundColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'& li': {
					backgroundColor: '#ffffff',
				},
			},
		},
	}),
)

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		boxShadow: 0,
		input: { height: '25px' },
		borderWidth: state.isFocused ? '2px' : base.borderWidth,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: grayColor[2],
		},
	}),
}

const statusOptions = [{ value: 'true', label: 'Ativos' }, { value: 'false', label: 'Inativos' }]

class Users extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			results: false,
			userDataFailed: false,
			searchName: '',
			searchDocument: '',
			searchEmail: '',
			selectStatus: '',
			searchActive: '',
			currentPage: 1,
			pageSize: 50,
			recordCount: 0,
			filtered: false,
			open: false,
			themeColor: getThemeColorFromLocalStorage(),
		}
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		this.props.ShowLoader(<Loader />)
		this.setState(
			{
				...this.props.urlState,
				selectStatus: _.find(statusOptions, x => x.value === _.get(this.props.urlState, 'searchActive', '')),
			},
			() => {
				this.props.GetCurrentPartner()
				//
				if (
					!_.isEmpty(this.state.searchName) ||
					!_.isEmpty(this.state.searchDocument) ||
					!_.isEmpty(this.state.searchEmail) ||
					!_.isEmpty(this.state.selectStatus)
				) {
					this.setState({ filtered: true })
				}
			},
		)
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')
		if (!_.isEqual(prevUrlState, urlState)) {
			this.setState(
				{
					...urlState,
					selectStatus: _.find(statusOptions, x => x.value === _.get(this.props.urlState, 'searchActive', '')),
				},
				() => {
					let list = this.props.userData.users.filter(this.filterResults)
					this.setState({ results: list, recordCount: list.length })
				},
			)
		}

		if (prevProps.userDataFailed !== this.props.userDataFailed) {
			this.setState({ userDataFailed: true })
		}

		if (prevProps.userData !== this.props.userData) {
			let list = this.props.userData.users.filter(this.filterResults)
			this.setState({ results: list, recordCount: list.length }, () => {
				this.props.HideLoader()
			})
		}

		if (this.props.userListDataFailed && prevProps.userListDataFailed !== this.props.userListDataFailed) {
			this.props.HideLoader()
			//console.log("Error on loading users")
		}
	}

	handleToggle = () => {
		this.setState({ open: !this.state.open })
	}

	handleChange = event => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ [id]: value })
	}

	handleSelectedChange = (event, value) => {
		this.setState({ selectStatus: value, searchActive: value.value })
	}

	handleTableEdit = i => {
		this.props.history.push(`/partnerusers/edit/${i.id}`)
	}

	handleSearchPartner = currentPage => {
		this.props.ShowLoader(<Loader />)
		this.props.setURLState({ currentPage })
	}

	handleFilter = clear => {
		const { searchName, searchEmail, selectStatus, searchDocument } = this.state
		let _cpf = searchDocument.replace(/[^\d]+/g, '')
		if (_cpf.length > 0 && _cpf.length < 11) {
			this.props.SweetAlert(
				<Alert
					warning
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => this.props.HideAlert()}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					cancelBtnCssClass={this.props.classes.button + ' ' + this.props.classes.danger}
					confirmBtnText={this.props.t('BUTTON_OK')}
				>
					{this.props.t('FIELD_CPF_MUST_BE_FILED_COMPLETE')}
				</Alert>,
			)
			return false
		}
		this.props.setURLState({
			searchName,
			searchEmail,
			searchActive: _.get(selectStatus, 'value', ''),
			searchDocument: searchDocument
				.replace('.', '')
				.replace('.', '')
				.replace('.', '')
				.replace('-', '')
				.trim(),
			currentPage: 1,
		})
		//
		if (clear) {
			this.setState({ filtered: false })
		} else {
			this.setState({ filtered: true })
		}
	}

	handleClearFilter = () => {
		this.setState(
			{
				filtered: false,
				searchName: '',
				searchEmail: '',
				searchDocument: '',
				searchActive: '',
				selectStatus: '',
			},
			() => this.handleFilter(true),
		)
	}

	filterResults = user => {
		const { searchName, searchActive, searchEmail, searchDocument } = this.state
		return Boolean(
			user.name.toLowerCase().includes(searchName.toLowerCase()) &&
			(searchActive === '' || user.isActive.toString() === searchActive) &&
			user.email.includes(searchEmail) &&
			(user.userName === null || user.userName.includes(searchDocument)),
		)
	}

	render() {
		const { classes, t } = this.props
		const {
			userDataFailed,
			open,
			filtered,
			searchDocument,
			searchName,
			searchEmail,
			selectStatus,
			results,
			currentPage,
			pageSize,
			recordCount,
		} = this.state

		return (
			<Container themeColor={this.state.themeColor}>
				<DocumentTitle title={getProgramTitle()} />
				<Grid
					xs={12}
					style={{
						alignItems: 'center',
						display: 'flex',
						height: '40px',
						marginBottom: '25px',
					}}
				>
					<Section title={t('MENU_PARTNER_USERS')} style={{ flexGrow: 1 }} />
					<Button style={{ marginLeft: '15px' }} size="sm" variant="contained" color="greenButtonColor" onClick={() => this.handleNew()}>
						{t('BUTTON_CREATE')}
					</Button>
				</Grid>
				<SearchBox>
					{/* FILTERS - LINE 1 */}
					<GridItem xs={12} sm={6} md={6}>
						<CssTextField
							fullWidth
							label="Nome"
							id="searchName"
							name="searchName"
							value={searchName}
							onChange={this.handleChange}
							variant="outlined"
							size="small"
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={6}>
						<CssTextField
							fullWidth
							label="CPF"
							id="searchDocument"
							name="searchDocument"
							value={searchDocument}
							onChange={this.handleChange}
							variant="outlined"
							size="small"
							InputProps={{
								inputComponent: CPFMaskCustom,
							}}
							style={{ backgroundColor: '#fff' }}
						/>
					</GridItem>

					{open && (
						<>
							{/* FILTERS LINE 2 */}
							<GridItem xs={12} sm={9} md={9} className="search-input">
								<CssTextField
									fullWidth
									label="E-mail"
									id="searchEmail"
									name="searchEmail"
									value={searchEmail}
									onChange={this.handleChange}
									variant="outlined"
									size="small"
									style={{ backgroundColor: '#fff' }}
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3} className="search-input">
								<Autocomplete
									styles={customSelectStyles}
									id="combo-box-demo"
									size="small"
									options={statusOptions}
									getOptionLabel={option => (option.label ? option.label : '')}
									getOptionSelected={(option, value) => value === undefined || value === '' || option.id === value.id}
									disableClearable={true}
									onChange={this.handleSelectedChange}
									value={selectStatus}
									PopperComponent={CustomPopper}
									renderInput={params => (
										<div ref={params.InputProps.ref}>
											<CssTextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
												label={t('FIELD_STATUS')}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
								/>
							</GridItem>
						</>
					)}

					<GridContainer style={{ margin: '20px 0' }} className="search-container-buttons">
						<MoreFilters
							onClick={this.handleToggle}
							title="Pesquisa avançada"
							color={
								this.state &&
								this.state.themeColor &&
								this.state.themeColor.navigation &&
								this.state.themeColor.navigation.buttons &&
								this.state.themeColor.navigation.buttons.elementsColor
							}
							xs={12}
							sm={6}
							md={6}
						>
							<IconButton classes={{ root: classes.filterPlusButton }} title="Pesquisa avançada">
								{!open ? <Add /> : <Remove />}
							</IconButton>
							<span>{!open ? t('SHOW_MORE_FILTERS') : t('HIDE_MORE_FILTERS')}</span>
						</MoreFilters>
						<GroupButtons xs={12} sm={6} md={6}>
							<Button size="sm" variant="contained" style={{ margin: 0 }} color="greenBorderButtonColor" onClick={() => this.handleClearFilter()}>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>
							<Button size="sm" style={{ margin: 0 }} variant="contained" color="greenButtonColor" onClick={() => this.handleFilter(false)}>
								{t('BUTTON_FIND')}
							</Button>
						</GroupButtons>
					</GridContainer>
				</SearchBox>

				{/*  */}
				{filtered ? <Section title={t('TITLE_SEARCH_RESULTS')} /> : ''}
				<GridContainer justifyContent="center" className="grid-results" style={{ paddingLeft: '15px' }}>
					{results && results.length > 0 && (
						<Fragment>
							{results.map((item, index) => [
								<GriTable
									key={index}
									id={item.id}
									history={this.props.history}
									labels={[
										t('TITLE_REGISTER_DATE'),
										t('TITLE_ACCESS'),
										t('FIELD_REGISTER_NAME'),
										t('FIELD_REGISTER_DOCUMENT'),
										t('FIELD_REGISTER_EMAIL'),
										t('FIELD_STATUS'),
									]}
									values={[
										moment(item.createdAt).isValid() ? moment(item.createdAt).format('L') : '-',
										' - ',
										_.truncate(item.name, {
											length: 30,
											separator: ' ',
										}),
										FormatCPF(item.cpf),
										item.email,
										<div
											style={{
												display: 'flex',
												lineHeight: 0.8,
												marginTop: '4px',
											}}
										>
											{item.isActive ? (
												<Icon
													className="icon-grid_ativo"
													style={{
														fontSize: '15px',
														color: '#9FB029',
													}}
												/>
											) : (
												<Icon
													className="icon-grid_inativo"
													style={{
														fontSize: '15px',
														color: '#38B9E9',
													}}
												/>
											)}
											<div
												style={{
													marginTop: '3px',
													marginLeft: '7px',
												}}
											>
												{item.isActive ? t('ACTIVE') : t('INACTIVE')}
											</div>
										</div>,
									]}
								/>,
							])}
						</Fragment>
					)}
					{(results && results.length === 0) || userDataFailed ? <NoResults value={t('RESULTS_NOT_FOUND')} /> : ''}
					{results && results.length > 0 && (
						<div
							style={{
								textAlign: 'center',
								marginTop: '20px',
								width: '100%',
							}}
						>
							<CustomPagination
								pageSize={pageSize}
								recordCount={recordCount}
								offset={pageSize * (currentPage - 1)}
								clickEvent={(e, offset) => this.handleSearch(Math.round(offset / pageSize) + 1)}
							/>
						</div>
					)}
				</GridContainer>
			</Container>
		)
	}

	//
	handleNew = () => {
		this.props.history.push(`/partnerusers/new`)
	}
	//

	//
	handleTableView = i => {
		this.props.history.push(`/partnerusers/${i.id}`)
	}
}

const mapStateToProps = state => {
	return {
		userData: state.users.userData,
		userDataFailed: state.users.userDataFailed,
		userListDataFailed: state.users.userListDataFailed,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			ListUsers,
			SweetAlert,
			GetCurrentPartner,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	withRouter,
	withTranslation(),
	connectRouterState({
		searchName: '',
		searchEmail: '',
		searchActive: '',
		searchDocument: '',
		currentPage: 1,
	}),
	withStyles(userStyle),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(Users)
