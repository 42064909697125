import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
	partnersData: [],
	partnersDataFailed: false,

	updatePartnerData: [],
	updatePartnerDataFailed: false,

	postPartnersBranchData: [],
	postPartnersBranchDataFailed: false,

	updatePartnersBranchData: [],
	updatePartnersBranchDataFailed: false,

	cashbackRulesData: [],
	cashbackRulesFailed: false,

	postPartnerCashbackRulesData: [],
	postPartnerCashbackRulesDataFailed: false,

	partnerConsumersData: [],
	partnerDataFailed: false,

	consumerCashbackRulesData: [],
	consumerCashbackRulesDataFailed: false,

	postPartnerConsumerCashbackRulesData: [],
	postPartnerConsumerCashbackRulesDataFailed: false,
}

const fetchPartnersData = (state, action) => {
	return updateObject(state, {
		partnersData: action.partnersData,
	})
}

const updatePartnerData = (state, action) => {
	return updateObject(state, {
		updatePartnerData: action.updatePartnerData,
	})
}

const postPartnersBranchData = (state, action) => {
	return updateObject(state, {
		postPartnersBranchData: action.postPartnersBranchData,
	})
}

const updatePartnersBranchData = (state, action) => {
	return updateObject(state, {
		updatePartnersBranchData: action.updatePartnersBranchData,
	})
}

// -

const fetchCashbackRulesData = (state, action) => {
	return updateObject(state, {
		cashbackRulesData: action.cashbackRulesData,
	})
}

const postPartnerCashbackRulesData = (state, action) => {
	return updateObject(state, {
		postPartnerCashbackRulesData: action.postPartnerCashbackRulesData,
	})
}

const fetchPartnerConsumersData = (state, action) => {
	return updateObject(state, {
		partnerConsumersData: action.partnerConsumersData,
	})
}

const fetchConsumerCashbackRulesData = (state, action) => {
	return updateObject(state, {
		consumerCashbackRulesData: action.consumerCashbackRulesData,
	})
}

const postPartnerConsumerCashbackRulesData = (state, action) => {
	return updateObject(state, {
		postPartnerConsumerCashbackRulesData:
			action.postPartnerConsumerCashbackRulesData,
	})
}

// DATA FAIL
const fetchPartnersFail = (state, action) => {
	return updateObject(state, {
		partnersDataFailed: true,
	})
}

const updatePartnerDataFail = (state, action) => {
	return updateObject(state, {
		updatePartnerDataFailed: true,
	})
}

const postPartnersBranchFail = (state, action) => {
	return updateObject(state, {
		postPartnersBranchDataFailed: true,
	})
}

const updatePartnersBranchFail = (state, action) => {
	return updateObject(state, {
		updatePartnersBranchDataFailed: true,
	})
}

// ----------

const cashbackRulesFailed = (state, action) => {
	return updateObject(state, {
		cashbackRulesFailed: true,
	})
}

const postPartnerCashbackRulesDataFailed = (state, action) => {
	return updateObject(state, {
		postPartnerCashbackRulesDataFailed: true,
	})
}

const partnerDataFailed = (state, action) => {
	return updateObject(state, {
		partnerDataFailed: true,
	})
}

const consumerCashbackRulesDataFailed = (state, action) => {
	return updateObject(state, {
		consumerCashbackRulesDataFailed: true,
	})
}

const postPartnerConsumerCashbackRulesDataFailed = (state, action) => {
	return updateObject(state, {
		postPartnerConsumerCashbackRulesDataFailed: true,
	})
}

// ------------------

const partnersReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_PARTNERS_DATA:
			return fetchPartnersData(state, action)

		case actionTypes.FETCH_PARTNERS_FAILED:
			return fetchPartnersFail(state, action)

		case actionTypes.UPDATE_PARTNER_DATA:
			return updatePartnerData(state, action)
		case actionTypes.UPDATE_PARTNER_FAILED:
			return updatePartnerDataFail(state, action)

		case actionTypes.POST_PARTNERS_BRANCH_DATA:
			return postPartnersBranchData(state, action)
		case actionTypes.POST_PARTNERS_BRANCH_FAILED:
			return postPartnersBranchFail(state, action)

		case actionTypes.UPDATE_PARTNERS_BRANCH_DATA:
			return updatePartnersBranchData(state, action)
		case actionTypes.UPDATE_PARTNERS_BRANCH_FAILED:
			return updatePartnersBranchFail(state, action)

		case actionTypes.FETCH_CASHBACK_RULES_DATA:
			return fetchCashbackRulesData(state, action)
		case actionTypes.FETCH_CASHBACK_RULES_FAILED:
			return cashbackRulesFailed(state, action)

		case actionTypes.POST_PARTNER_CASHBACK_RULES_DATA:
			return postPartnerCashbackRulesData(state, action)
		case actionTypes.POST_PARTNER_CASHBACK_RULES_FAILED:
			return postPartnerCashbackRulesDataFailed(state, action)

		case actionTypes.FETCH_PARTNER_CONSUMERS_DATA:
			return fetchPartnerConsumersData(state, action)
		case actionTypes.FETCH_PARTNER_CONSUMERS_FAILED:
			return partnerDataFailed(state, action)

		case actionTypes.FETCH_CONSUMER_CASHBACK_RULES_DATA:
			return fetchConsumerCashbackRulesData(state, action)
		case actionTypes.FETCH_CONSUMER_CASHBACK_RULES_FAILED:
			return consumerCashbackRulesDataFailed(state, action)

		case actionTypes.POST_PARTNER_CONSUMER_CASHBACK_RULES_DATA:
			return postPartnerConsumerCashbackRulesData(state, action)
		case actionTypes.POST_PARTNER_CONSUMER_CASHBACK_RULES_FAILED:
			return postPartnerConsumerCashbackRulesDataFailed(state, action)

		default:
			return state
	}
}

export default partnersReducer
