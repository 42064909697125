import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel } from '@mui/material'
import Info from '../Info'

const Cards = ({ parameterizationRedemption, typeOfRedeem, setOpen, productData, fetchCalculation }) => {
	const { t } = useTranslation()

	useEffect(() => {
		fetchCalculation('', typeOfRedeem)
	}, [typeOfRedeem])

	return (
		<S.Container typeOfPoints={typeOfRedeem}>
			<FormLabel sx={{ width: '100%' }}>
				<span className="FormLabel">{t('Valores baseados na conversão padrão')}</span>
				<Info parameterization={parameterizationRedemption} setOpen={setOpen} productData={productData} />
			</FormLabel>
		</S.Container>
	)
}

Cards.propTypes = {
	setOpen: PropTypes.func,
	productData: PropTypes.object,
	parameterizationRedemption: PropTypes.array,
	fetchCalculation: PropTypes.func,
	typeOfPoints: PropTypes.string,
	onOpen: PropTypes.func,
	typeOfRedeem: PropTypes.string,
}

Cards.defaultProps = {
	typeOfRedeem: '2',
}

export default Cards
