import styled from 'styled-components/macro'

// MUI
import TextField from '@mui/material/TextField'
import { withStyles } from '@mui/styles'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export const CssTextField = withStyles({
	root: {
		'& label': {
			fontSize: '14px',
			lineHeight: 1.5,
		},
		'& label.Mui-focused': {
			color: getThemeColorFromLocalStorage()?.structure?.backgroundColor || '#fff !important',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: getThemeColorFromLocalStorage()?.structure?.backgroundColor || '#fff !important',
			},
			'&.Mui-focused fieldset': {
				borderColor: getThemeColorFromLocalStorage()?.structure?.backgroundColor || '#fff !important',
			},
		},
	},
})(TextField)

export const ListProducts = styled.div`
	display: flex;
`

export const AddProduct = styled.form`
	align-items: center;
	display: flex;
	width: 100%;
`

export const FieldsStyled = {
	backgroundColor: '#fff',
	marginRight: '25px',
	marginTop: '5px',
}

export const Title = styled.h3`
	text-align: left;
	font: normal normal bold 15px Roboto;
	letter-spacing: 0px;
	color: ${() => getThemeColorFromLocalStorage()?.titles?.main?.textColor || '#000'};
	opacity: 1;
	margin-bottom: 30px;
`
