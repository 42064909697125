import { withStyles, TextField } from '@material-ui/core'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'

export const FormField = withStyles({
	root: {
		width: '100%',
		'& .Mui-focused fieldset': {
			borderColor: `${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor
				} !important`,
		},
		'& label.Mui-focused': {
			color: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
			},
			'&.Mui-focused fieldset': {
				borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
			},
		},
		'& .MuiInput-underline:hover:not:(.Mui-disabled):before': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
		'& .MuiInput-underline:after': {
			borderBottom: `2px solid  ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor
				}`,
		},
	},
})(TextField)
