import React from 'react'

import {
	Button,
	CreditIcon,
	MuiBox,
	MuiLegend,
	MuiStack,
	MuiTooltip,
	MuiTypography,
	formatBRCurrency,
	formatUSDCurrency,
} from '../../../../components/storybook'
import { useTranslation } from 'react-i18next'
import { formatDate, formatHour, validateBenefitMask, validateCurrencyMask, validateLimitedValues } from '../Functions'
import { CustomMask } from '../utils'
import { useHistory } from 'react-router-dom'

export function TableColumns({ onNotification, onCancel, handleDetails }) {
	const { t } = useTranslation()
	const history = useHistory()

	const statusColors = {
		Active: '#2BA2C8',
		Expired: '#EB964F',
		SaleMade: '#5FB55F',
		ActivePartial: '#D7E14D',
		Canceled: '#EB6C61',
	}

	const statusTooltip = {
		Active: t('NEGOTIATION.STATUS_NEGOTIATION_ACTIVE'),
		Expired: t('NEGOTIATION.STATUS_NEGOTIATION_EXPIRED'),
		SaleMade: 'Venda realizada',
		Canceled: t('NEGOTIATION.STATUS_NEGOTIATION_CANCELED'),
		ActivePartial: 'Crédito parcial ativo',
	}

	const typeGroup = {
		Category: 'Categoria',
		Brand: 'Marca',
		Family: 'Família',
		Manufacturer: 'Fabricante',
		Product: 'Produto',
	}

	return [
		{
			id: 'tag',
			label: '',
			align: 'right',
			maxWidth: '20px',
			render: rowData => (
				<MuiBox ml={-2.5}>
					<MuiTooltip title={statusTooltip[(rowData?.status?.value)] ?? ''}>
						<button style={{ backgroundColor: 'transparent', border: 'none' }}>
							<MuiLegend color={statusColors[(rowData?.status?.value)] ?? 'grey'} size="16px" borderRadius="5px" />
						</button>
					</MuiTooltip>
				</MuiBox>
			),
		},
		{
			id: 'name',
			label: t('NEGOTIATION.PARTICIPANT'),
			render: rowData => (
				<MuiBox sx={{ maxWidth: '230px', overflow: 'hidden' }}>
					<MuiTypography
						variant="body2"
						sx={{
							display: '-webkit-box',
							WebkitBoxOrient: 'vertical',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							WebkitLineClamp: 1,
							color: '#636363',
						}}
					>
						{rowData?.consumer?.name ?? '-'}
					</MuiTypography>
					<MuiTypography variant="body2" sx={{ color: '#636363', fontSize: 13 }}>
						{rowData?.consumer?.cpf ? CustomMask(rowData?.consumer?.cpf) : '-'}
					</MuiTypography>
				</MuiBox>
			),
		},
		{
			id: 'id',
			label: 'Produto/Agrupador',
			render: rowData => (
				<MuiBox sx={{ width: '200px', overflow: 'hidden' }}>
					<>
						<MuiTypography variant="body2" sx={{ display: 'flex', color: '#636363' }}>
							{typeGroup[(rowData?.typeOfGrouper?.value)] ?? '-'}
						</MuiTypography>
						<MuiTypography
							variant="body2"
							sx={{
								display: '-webkit-box',
								WebkitBoxOrient: 'vertical',
								overflow: 'hidden',
								textOverflow: 'ellipsis',
								WebkitLineClamp: 1,
								color: '#636363',
								fontSize: 12,
							}}
						>
							{rowData?.product?.value ? `${rowData?.product?.value} - ${rowData?.product?.key}` : rowData?.grouper?.value}
						</MuiTypography>
					</>
				</MuiBox>
			),
		},

		// {
		// 	id: 'benefit',
		// 	label: t('NEGOTIATION.BENEFIT'),
		// 	maxWidth: '100px',
		// 	paddingLeft: '0px!important',
		// 	render: rowData => {
		// 		return (
		// 			<>
		// 				{rowData?.limited && (
		// 					<MuiBox pl={4}>
		// 						{rowData?.sellQuantity?.totalSold || 0} {`| ${rowData?.sellQuantity?.totalAllowed || 0}`}
		// 					</MuiBox>
		// 				)}
		// 				<MuiStack direction="row" spacing={1} alignItems="center">
		// 					<CreditIcon size={20} color="#5FB55F" />

		// 					<MuiBox>
		// 						<MuiStack>
		// 							<MuiTypography variant="body2" sx={{ color: '#636363' }}>
		// 								{rowData?.limited ? validateLimitedValues(rowData) : validateCurrencyMask(rowData)}
		// 							</MuiTypography>
		// 						</MuiStack>
		// 						{rowData?.limited && (
		// 							<MuiTypography variant="body2" fontFamily={8} color="#636363">
		// 								Creditado
		// 							</MuiTypography>
		// 						)}
		// 					</MuiBox>

		// 					{rowData?.limited && (
		// 						<MuiStack
		// 							alignItems="center"
		// 							sx={{
		// 								borderLeft: '1px solid #989898',
		// 								paddingLeft: '10px',
		// 								display: 'flex',
		// 								alignItems: 'left',
		// 							}}
		// 						>
		// 							<MuiTypography variant="body2" sx={{ color: '#636363' }}>
		// 								{/* {rowData?.typeOfBenefit?.value === 'Cashback'
		// 									? formatBRCurrency(rowData?.value * rowData?.sellQuantity?.totalAllowed)
		// 									: `SD$ ${rowData?.value * rowData?.sellQuantity?.totalAllowed}`} */}
		// 								{validateBenefitMask(rowData)}
		// 							</MuiTypography>
		// 							<MuiTypography variant="body2" fontFamily={8} color="#636363">
		// 								Total
		// 							</MuiTypography>
		// 						</MuiStack>
		// 					)}
		// 				</MuiStack>
		// 			</>
		// 		)
		// 	},
		// },

		{
			id: 'validity',
			label: t('NEGOTIATION.VALIDITY'),
			render: rowData => (
				<MuiBox>
					<MuiTypography variant="body2" sx={{ color: '#636363' }}>
						{formatDate(rowData?.validUntil) ?? '-'}
					</MuiTypography>
					<MuiTypography sx={{ color: '#636363', fontSize: 13 }}>{formatHour(rowData?.validUntil) ?? '-'}</MuiTypography>
				</MuiBox>
			),
		},
		{
			id: 'actions',
			label: t('NEGOTIATION.ACTIONS'),
			// maxWidth: '80px',
			render: rowData => (
				<MuiStack direction="row" mr={-5}>
					<MuiTooltip title={t('NEGOTIATION.VIEW')}>
						<Button
							onClick={() => history.push(`/negotiationDetails/${rowData.id}`)}
							typeVariation="ViewButton"
							sx={{ width: '30px!important', height: '30px!important', '&:hover': { backgroundColor: '#FB9006!important' } }}
						/>
					</MuiTooltip>
					<MuiTooltip title={t('NEGOTIATION.CANCEL_NEGOTIATION')}>
						<Button
							onClick={() => {
								onCancel(rowData)
							}}
							disabled={rowData?.status?.value === 'Expired' || rowData?.status?.value === 'SaleMade' || rowData?.status?.value === 'Canceled'}
							customType="cancel"
							typeVariation="CustomIconButton"
							sx={{ width: '30px!important', height: '30px!important', '&:hover': { backgroundColor: '#EB6C61!important' } }}
						/>
					</MuiTooltip>
					<MuiTooltip title={t('NEGOTIATION.NOTIFY_NEGOTIATION')}>
						<Button
							onClick={() => {
								onNotification(rowData)
							}}
							disabled={rowData?.status?.value === 'Expired' || rowData?.status?.value === 'SaleMade' || rowData?.status?.value === 'Canceled'}
							typeVariation="CustomIconButton"
							customType="notification"
							sx={{
								width: '30px!important',
								height: '30px!important',
								backgroundColor: '#F2F2F2',
								borderRadius: '5px',
								'&:hover': { backgroundColor: '#1A9CAC!important', color: '#FFFFFF' },
							}}
						/>
					</MuiTooltip>
				</MuiStack>
			),
		},
	]
}
