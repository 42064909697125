import React from 'react'

import { useTranslation } from 'react-i18next'
import { MuiModal } from '../../../../../components/storybook'

export function ConfirmNegotiationModal({ open, handleClose, onConfirm }) {
	const { t } = useTranslation()

	return (
		<MuiModal
			open={open}
			alertIcon
			typeButtonSubmit="submit"
			description="Deseja realmente criar essa negociação?"
			// onConfirm={onConfirm}
			onClose={handleClose}
			form="negotiation-form"
		/>
	)
}
