import { Fade, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { withTranslation } from 'react-i18next'
import Section from 'components/Section'
import GridItem from 'components/Grid/GridItem'
import GridContainer from 'components/Grid/GridContainer'
import { ButtonClose } from 'components/NewButton/styles'
import * as _ from 'lodash'
import {
	GetPdv,
	GetActivePDVUser,
	DisconnectUser,
} from '../../../../../redux/api/pdv'
import Button from 'components/CustomButtons/Button.jsx'
import moment from 'moment'
import { Icon } from '@material-ui/core'

import { ModalContainer, PaperContainer, TitleText } from './styles'
import Loader from '../../../../../components/Loader/Loader'

function ViewModal({
	open,
	closeByClick,
	maxWidth,
	className,
	padding,
	shadow,
	t,
	buttonCloseIcon = 'icon-ico_cancelar',
	headerTitle = 'Operador logado no momento',
	values,
	itemId,
}) {
	const [pdv, setPdv] = useState({})
	const [data, setData] = useState({})

	useEffect(() => {
		if (itemId) {
			async function fetchAPI() {
				let response = await GetPdv(itemId)
				setPdv(response)
			}
			fetchAPI()
		}
	}, [itemId])

	useEffect(() => {
		if (pdv) {
			async function fetchAPI() {
				let response = await GetActivePDVUser(pdv.id, pdv.partnerId)
				setData(response.data)
			}
			fetchAPI()
		}
	}, [pdv])

	const handleDisconnect = () => {
		async function fetchAPI() {
			await DisconnectUser(pdv.id, pdv.partnerId)
		}
		fetchAPI()
		window.location.reload()
	}

	const formatDate = (date) => {
		return moment(date).format('HH:mm - DD/MM/YYYY')
	}

	const formatConnectionTime = (date) => {
		let now = moment(new Date())
		let end = moment(date)
		let duration = moment.duration(now.diff(end))
		let tempTime = moment.duration(duration)
		let minutes = tempTime.minutes()
		let hours = tempTime.hours()
		return `${hours ? hours : '00'}:${minutes ? minutes : '00'}`
	}

	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			disableEnforceFocus
		>
			{!data.name ? (
				open && <Loader />
			) : (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<Grid
							style={{
								alignItems: 'center',
								display: 'flex',
								marginBottom: '10px',
								padding: '20px 0px 0px 20px',
								marginRight: '20px',
							}}
						>
							<Section
								title={t(headerTitle)}
								style={{ flexGrow: 1 }}
							/>
							<div style={{ marginLeft: '23px' }}>
								<ButtonClose onClick={closeByClick}>
									<i
										className={buttonCloseIcon}
										style={{
											fontSize: '12px',
											marginRight: '10px',
										}}
									/>
									{t('BUTTON_CLOSE')}
								</ButtonClose>
							</div>
						</Grid>
						<GridContainer
							style={{
								padding: '20px 0px 0px 20px',
								marginRight: '20px',
								marginBottom: '20px',
							}}
						>
							<GridItem xs={3} sm={3} md={3}>
								<TitleText>Nome do operador</TitleText>
								<p>{data.name}</p>
							</GridItem>
							<GridItem xs={3} sm={3} md={3}>
								<TitleText>Horário de autenticação</TitleText>
								<p>{formatDate(data.date)}</p>
							</GridItem>
							<GridItem xs={3} sm={3} md={3}>
								<TitleText>Tempo de conexão</TitleText>
								<p>{formatConnectionTime(data.date)}</p>
							</GridItem>
							<GridItem xs={3} sm={3} md={3}>
								<Button
									justIcon
									size="sm"
									color="greenButtonColor"
									title={t('BUTTON_VIEW')}
									className="detail-button row-section-item"
									onClick={() => handleDisconnect()}
									style={{
										width: '98%',
										fontSize: '12px',
										padding: '15px',
										borderRadius: '8px',
										height: '35px',
										marginTop: '15px',
									}}
								>
									<i
										class="icon-desconectar"
										style={{
											fontSize: '17px',
											verticalAlign: 'middle',
											marginRight: '5px',
										}}
									></i>
									<span>Desconectar operador</span>
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			)}
		</ModalContainer>
	)
}

ViewModal.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	currency: '',
	programCurrency: '',
	headTitle: '',
	open: false,
	closeByClick: null,
	maxWidth: '800px',
	productValue: '',
	conversionRate: '',
	conversionRateApplied: '',
	administrationFee: '',
	administrationFeeApplied: '',
	valueToConvert: '',
	multiplicationFactor: '',
	accumulatedPoints: '',
	values: [],
	calculationMemory: [],
	itemId: '',
	typeOfPoints: '',
	typeOfRedeem: '',
}

export default withTranslation()(ViewModal)
