import axios from 'axios'
import {envs}  from '../windowServerData'



// GET api/
export const GetUserByGroup = id => {
	// return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getUsersByGroup?id=${id}`)
	return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getUsersByGroup?id=${id}`).then(response => response.data)
}

export const GetUsersOptions = text => {
	// return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/ListUsersByLogin/${text}`)
	return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/ListUsersByLogin/${text}`).then(response => response.data)
}
export const AssignUserToGroup = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/assignUserToGroup`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/assignUserToGroup`, obj).then(response => response.data)
}

export const GetClaimsGroupNewGroup = () => {
	// return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getClaimsGroup`).then(response => response.data)
	return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getClaimsGroup`).then(response => response.data)
}

export const RemoveUserFromgroup = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/removeUserFromGroup`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/removeUserFromGroup`, obj).then(response => response.data)
}

export const PostNewGroup = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/createGroup`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/createGroup`, obj).then(response => response.data)
}
export const EditNewGroup = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/editGroup`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/editGroup`, obj).then(response => response.data)
}
