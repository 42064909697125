import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'

// Form & validation
import InputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as Yup from 'yup'

// Redux
import { useDispatch } from 'react-redux'
import { HideLoader, ShowLoader } from 'redux/actions'
import {
  GetAccounts,
  GetBank,
  PostBankAccount,
} from '../../../redux/api/accounts'
import { FormSkeleton } from './FormSkeleton'
import { GetCurrentPartner } from '../../../redux/api/user'

// Material
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from "@material-ui/core/styles";


// Components
import Section from 'components/Section'
import Button from 'components/CustomButtons/Button.jsx'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import ConfirmModal from '../../../components/ConfirmModal'
import SnackDefault from '../../../components/SnackDefault'

import * as S from '../styles'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

const getThemeColorStructureBackground = () => {
  if (getThemeColorFromLocalStorage()) {
    if (getThemeColorFromLocalStorage().structure) {
      if (getThemeColorFromLocalStorage().structure.backgroundColor) {
        return getThemeColorFromLocalStorage().structure.backgroundColor
      }
    }
  }
  return '#08993A'
}

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: grayColor[2],
    },
    '& label': {
      fontSize: '14px',
      fontWeight: '400',
      color: 'hls(0, 0, 0, 0.55)',
      lineHeight: 1.2,
      zIndex: 0,
    },
    '& .MuiInputBase-root': {
      color: 'rgba(0, 0, 0, 0.6)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
      //
      '&:not(.Mui-disabled)': {
        marginTop: '-7px',
        marginLeft: '-5px',
      },
    },
    '& .MuiOutlinedInput-inputMarginDense': {
      paddingTop: '9.5px',
      paddingBottom: '10.5px',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: grayColor[2],
    },
    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.50)',
      fontSize: '16px',
    },
    '& .MuiInputBase-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.50)',
      cursor: 'default',
    },
    '& fieldset': {
      color: 'rgba(0, 0, 0, 0.7)',
      borderColor: 'hsl(0,0%,80%)',
      '& .hover': {
        borderColor: 'hsl(0,0%,80%)',
      },
      '& legend': {
        width: '0px',
      },
    },
    '& .MuiOutlinedInput-root': {
      '&:not(.Mui-disabled)': {
        '&:hover fieldset': {
          borderColor: getThemeColorStructureBackground(),
        },
        '&.Mui-focused fieldset': {
          borderColor: getThemeColorStructureBackground(),
        },
      },
    },
    '& .MuiOutlinedInput-root.Mui-disabled': {
      backgroundColor: 'hsl(3,3%,95%)',
      zIndex: -1,
      '& fieldset': {
        borderColor: 'transparent',
        '& legend': {
          width: '0px',
        },
      },
    },
    '& .PrivateNotchedOutline-legendLabelled-583': {
      width: '0px !important',
    },
  },
})(TextField)


const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      "& .MuiAutocomplete-listbox": {
        "& :hover": {
          color: "#ffffff",
          backgroundColor: getThemeColorFromLocalStorage() &&
            getThemeColorFromLocalStorage().structure &&
            getThemeColorFromLocalStorage().structure.backgroundColor
            ? getThemeColorFromLocalStorage().structure.backgroundColor
            : "#fff !important"
        },
        "& li": {
          backgroundColor: "#ffffff",
        }
      }
    },
  })
);

const CustomPopper = function (props) {
  const classesPoper = useStyles();
  return <Popper {...props} className={classesPoper.root} placement="bottom" />;
};


const ErrorSpan = styled.span`
	color: #f05b4f;
`

export function AccountForm() {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const { id } = useParams()

  const [snackStatus, setSnackStatus] = useState({
    open: false,
    severity: 'success',
    text: 'Operação realizada com sucesso !',
  })
  const [themeColor, setThemeColor] = useState()

  useEffect(() => {
    setThemeColor(getThemeColorFromLocalStorage())
  }, [])

  const validation = Yup.object().shape(
    {
      name: Yup.string()

        .test('nameExists', 'Same name exists', function (value) {
          return new Promise((resolve) => {
            GetAccounts(value).then((res) => {
              if (
                !!res &&
                !!res.results &&
                res.results.length > 0
              ) {
                const possibilities = res.results

                const entityWithSameName = possibilities.find(
                  (item) => item.name === value,
                )

                if (
                  !entityWithSameName ||
                  entityWithSameName.id === id
                )
                  resolve(true)
                else resolve(false)
              } else {
                resolve(true)
              }
            })
          })
        })
        .required(),
      description: Yup.string().required(),
      number: Yup.string()
        .nullable()
        .when(['branch', 'bank'], {
          is: (branch, bank) => !!branch || !!bank,
          then: Yup.string().required().min(8),
          otherwise: Yup.string().nullable(),
        }),
      branch: Yup.string()
        .nullable()
        .when(['number', 'bank'], {
          is: (number, bank) => !!number || !!bank,
          then: Yup.string().required().min(4),
          otherwise: Yup.string().nullable(),
        }),
      bank: Yup.object()
        .nullable()
        .when(['branch', 'number'], {
          is: (branch, number) => !!branch || !!number,
          then: Yup.object().required(),
          otherwise: Yup.object().nullable(),
        }),
    },
    [
      ['branch', 'bank'],
      ['number', 'bank'],
      ['branch', 'number'],
    ],
  )

  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState(null)

  const [openCancelModal, setOpenCancelModal] = useState(false)

  useEffect(() => {
    dispatch(HideLoader())
    RequestBank()
  }, [])

  useEffect(() => {
    if (isLoading) dispatch(ShowLoader())
    else dispatch(HideLoader())
  }, [isLoading])

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validation),
    mode: 'onSubmit',
  })

  const onSubmit = async (data) => {
    const formattedNumber = data.number ? data.number.split('-') : null
    const partnerId = await GetCurrentPartner().then((res) => {
      return res.id
    })

    const f = {
      name: data.name,
      description: data.description,
      type: 1,
      bankId: data.bank ? data.bank.value : null,
      branch: data.branch ? data.branch : null,
      branchDigit: null,
      number: formattedNumber ? formattedNumber[0] : null,
      numberDigit: formattedNumber ? formattedNumber[1] : null,
      isActive: true,
      partnerId: partnerId,
    }

    PostData(f)
      .then(() => {
        history.push({
          pathname: '/financial/accounts',
          state: { creationStatus: true },
        })
      })
      .catch((error) => {
        setSnackStatus((prevState) => ({
          ...prevState,
          severity: 'error',
          text: 'Falha ao realizar operação!',
          open: true,
        }))
        console.error(error)
      })
  }

  async function PostData(data) {
    try {
      setIsLoading(true)
      const res = await PostBankAccount(data)
      setIsLoading(false)
      return res
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }

  async function RequestBank() {
    try {
      const response = await GetBank()
      const formattedResponse = []

      response.forEach((item) => {
        const i = { value: item.key, label: item.value }
        formattedResponse.push(i)
      })

      setOptions(formattedResponse)
    } catch (error) { }
  }

  function handleBack() {
    if (isDirty) {
      setOpenCancelModal(true)
    } else {
      history.goBack()
    }
  }

  if (options) {
    return (
      <S.Container themeColor={themeColor}>
        <SnackDefault
          snackStatus={snackStatus}
          handleCloseSnack={() =>
            setSnackStatus((prevState) => ({
              ...prevState,
              open: false,
            }))
          }
        />
        <ConfirmModal
          type="cancel"
          open={openCancelModal}
          onConfirm={() => history.goBack()}
          onCancel={() => setOpenCancelModal(false)}
        />

        <S.AccountContainer onSubmit={handleSubmit(onSubmit)}>
          <S.HeaderBox>
            <S.SectionBox>
              <Section
                title={t('BUTTON_CREATE_ACCOUNT')}
                style={{ flexGrow: 1 }}
              />
            </S.SectionBox>

            <S.BtnHeaderBox>
              <Button
                color="greenBorderButtonColor"
                size="sm"
                startIcon={
                  <div>
                    <i
                      style={{
                        fontSize: 14,
                        padding: '2px 0',
                      }}
                    />
                  </div>
                }
                onClick={() => handleBack()}
              >
                {t('BUTTON_CANCEL')}
              </Button>
              <Button
                color="greenButtonColor"
                size="sm"
                type="submit"
              >
                {t('BUTTON_SAVE')}
              </Button>
            </S.BtnHeaderBox>
          </S.HeaderBox>
          <S.InputsBox>
            <S.AccountName>
              <Controller
                control={control}
                name="name"
                render={({ onChange, value }) => (
                  <CssTextField
                    value={value}
                    onChange={onChange}
                    label={`${t('ACCOUNT_NAME')} *`}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    error={errors.name}
                  />
                )}
              />

              {errors.name && errors.name.type === 'required' && (
                <ErrorSpan>Campo obrigatório</ErrorSpan>
              )}
              {errors.name &&
                errors.name.type === 'nameExists' && (
                  <ErrorSpan>O nome deve ser único</ErrorSpan>
                )}
            </S.AccountName>

            <S.AccountDescription>
              <Controller
                control={control}
                name="description"
                render={({ onChange, value }) => (
                  <CssTextField
                    value={value}
                    onChange={onChange}
                    label={`${t('ACCOUNT_DESCRIPTION')} *`}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    error={errors.description}
                  />
                )}
              />

              {errors.description &&
                errors.description.type === 'required' && (
                  <ErrorSpan>Campo obrigatório</ErrorSpan>
                )}
            </S.AccountDescription>

            <S.AccountBank>
              <Controller
                control={control}
                name="bank"
                render={({ onChange, value }) => (
                  <Autocomplete
                    size="small"
                    options={options}
                    getOptionLabel={(option) =>
                      option.label ? option.label : ''
                    }
                    getOptionSelected={(option, value) =>
                      value === undefined ||
                      value === '' ||
                      option.id === value.id
                    }
                    onChange={(event, item) => {
                      onChange(item)
                    }}
                    PopperComponent={CustomPopper}
                    value={value}
                    renderInput={(params) => (
                      <div ref={params.InputProps.ref}>
                        <CssTextField
                          themeColor={themeColor}
                          {...params.inputProps}
                          placeholder={t(
                            'FIELD_BANK',
                          )}
                          noOptionsMessage={() =>
                            t('RESULTS_NOT_FOUND')
                          }
                          style={{
                            height: '40px',
                            width: '100%',
                          }}
                          label={t('FIELD_BANK')}
                          variant="outlined"
                          size="small"
                        />
                      </div>
                    )}
                  />
                )}
              />
              {errors.bank && (
                <ErrorSpan>Campo obrigatório</ErrorSpan>
              )}
            </S.AccountBank>

            <S.AccountAgency>
              <Controller
                control={control}
                name="branch"
                render={({ onChange, value }) => (
                  <InputMask
                    value={value}
                    onChange={onChange}
                    mask="9999"
                    maskChar={null}
                    label={t('FIELD_AGENCY')}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    error={errors.branch}
                  >
                    {(inputProps) => (
                      <CssTextField {...inputProps} />
                    )}
                  </InputMask>
                )}
              />

              {errors.branch &&
                errors.branch.type === 'required' && (
                  <ErrorSpan>Campo obrigatório</ErrorSpan>
                )}
            </S.AccountAgency>

            <S.AccountNumber>
              <Controller
                control={control}
                name="number"
                render={({ onChange, value }) => (
                  <InputMask
                    value={value}
                    onChange={onChange}
                    mask="999999-9"
                    maskChar={null}
                    disableUnderline
                    label={t('FIELD_ACCOUNT_NUMBER')}
                    variant="outlined"
                    margin="dense"
                    fullWidth
                    error={errors.number}
                  >
                    {(inputProps) => (
                      <CssTextField {...inputProps} />
                    )}
                  </InputMask>
                )}
              />

              {errors.number &&
                errors.number.type === 'required' && (
                  <ErrorSpan>Campo obrigatório</ErrorSpan>
                )}
            </S.AccountNumber>
          </S.InputsBox>
        </S.AccountContainer>
      </S.Container>
    )
  } else return <FormSkeleton />
}
