import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

const useStyles = makeStyles((theme) => ({
	root: {
		width: '100%',
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
}))

export default function CustomizedSnackbars({
	openMsgSucess,
	setOpenMsgSucess,
	optionMenssage,
}) {
	const classes = useStyles()

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return
		}

		setOpenMsgSucess(false)
	}

	return (
		<div className={classes.root}>
			<Snackbar
				style={{ marginTop: '150px' }}
				open={openMsgSucess}
				autoHideDuration={5000}
				onClose={handleClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			>
				<Alert severity={optionMenssage}>
					{optionMenssage === 'success'
						? 'Premiação processada com sucesso'
						: 'Erro no processamento da premiação'}
				</Alert>
			</Snackbar>
		</div>
	)
}
