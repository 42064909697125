import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

// @ Components
import TabPanel from './TabPanel'
import Snackbar from 'components/Snackbar'
import * as Tab from 'components/NewTab/styles'

// @ Context and Styles
import * as S from './styles'
import GeneralContext from '../../../../../GeneralContext'

const CardLog = () => {
	const { t } = useTranslation()
	const { isSucess, setIsSucess } = useContext(GeneralContext)

	const [active, setActive] = React.useState(0)

	const handleChangeTab = (event, newValue) => {
		setActive(event)
	}

	return (
		<>
			<Snackbar
				close={true}
				marginTop="130px"
				open={isSucess?.open}
				color={isSucess?.color}
				handleClose={() => setIsSucess({ open: false })}
				message={t(isSucess?.message)}
				closeNotification={() => setIsSucess({ open: false })}
				icon={() => <i className={isSucess?.icon} />}
				anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			/>
			<S.CustomCard>
				<Tab.NewTabs selectedIndex={active} onSelect={handleChangeTab}>
					<Tab.NewTabList selectedIndex={active}>
						<Tab.NewTab>{t('PAYMENT')}</Tab.NewTab>
						<Tab.NewTab>{t('MENU_SETTINGS')}</Tab.NewTab>
						<Tab.NewTab>{t('EXPORTED_LOGS')}</Tab.NewTab>
					</Tab.NewTabList>
					<Tab.NewTabPanel>
						<TabPanel tab={active} setTab={setActive} />
					</Tab.NewTabPanel>
					<Tab.NewTabPanel>
						<TabPanel tab={active} setTab={setActive} />
					</Tab.NewTabPanel>
					<Tab.NewTabPanel>
						<TabPanel tab={active} setTab={setActive} />
					</Tab.NewTabPanel>
				</Tab.NewTabs>
			</S.CustomCard>
		</>
	)
}

export default CardLog
