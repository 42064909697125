import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { Box, Stack } from '@mui/material'
import ParameterizeDivisionTB from './ParameterizeDivisionTB'

// @ Styles
import { Title, SubTitle, Text, Section } from './styles'
import { CssTextField } from '../../../Filter/styles'
import { GetDivisionName } from './Functions'
import CustomSeparator from '../../../../../Breadcrumbs'
import Buttons from '../../../Buttons'
import { HasParameterization } from './ParameterizeDivisionTB/Functions'
import ParameterizeTable from './ParameterizeTable'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import { Schema } from './ParameterizeDivisionTB/Functions/utils'

// import { Container } from './styles';

function ParameterizeDivisionRes({
	onOpen,
	multiple,
	division,
	setMultiple,
	setEditMode,
	productData,
	setDivision,
	programRules,
	GenerationType,
	circularProgress,
	parameterization,
	setSelectedDivision,
	setParameterization,
	selectedDivisionIdRed,
	setSelectedDivisionIdRed,
}) {
	const { t } = useTranslation()
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})
	const [loading, setLoading] = useState(false)
	const [hasParameterization, setHasParameterization] = useState([])

	const [viewMode, setViewMode] = useState(true)

	const divisionName = GetDivisionName(division)

	const useData = useForm({
		defaultValues: {
			typeOfRedeem: '',
			redemptionPoints: '',
			pametrizationName: '',
		},
		resolver: yupResolver(Schema),
		mode: 'all',
	})

	const { formState, trigger, register, setValue, watch } = useData

	const onReset = () => {
		setDivision(null)
		setMultiple(false)
		setEditMode(false)
		setSelectedDivision([])
		setSelectedDivisionIdRed([])
		setParameterization(prev => ({
			...prev,
			show: false,
			isMultiple: false,
		}))
	}

	useEffect(() => {
		if (parameterization?.isMultiple) {
			setViewMode(false)
		}
	}, [parameterization])

	useEffect(() => {
		if (selectedDivisionIdRed?.length > 0) {
			setValue('selectedDivisionIdRed', selectedDivisionIdRed)
			HasParameterization({ productData, selectedDivisionIdRed, setHasParameterization, setLoading, setSnackStatus })
		}
	}, [selectedDivisionIdRed])

	const sharedProps = {
		onOpen,
		multiple,
		setMultiple,
		GenerationType,
		useData: useData,
		loading: loading,
		onReset: onReset,
		viewMode: viewMode,
		division: division,
		setLoading: setLoading,
		setViewMode: setViewMode,
		productData: productData,
		setDivision: setDivision,
		snackStatus: snackStatus,
		divisionName: divisionName,
		programRules: programRules,
		setSnackStatus: setSnackStatus,
		circularProgress: circularProgress,
		pametrizationName: watch('pametrizationName'),
		results: hasParameterization?.results,
		isMultiple: parameterization?.isMultiple,
		hasParameterization: hasParameterization,
		setParameterization: setParameterization,
		modalParameterization: modalParameterization,
		selectedDivisionIdRed: selectedDivisionIdRed,
		setModalParameterization: setModalParameterization,
		setHasParameterization: setHasParameterization,
	}

	return (
		<Stack display={!parameterization?.show ? 'none' : 'flex'} gap="30px">
			<CustomSeparator item={productData?.name} />

			<Box display="flex" justifyContent="space-between" alignItems="center" {...register('selectedDivisionIdRed')}>
				{_.isEmpty(hasParameterization?.results) ? (
					<>
						{!parameterization?.isMultiple ? (
							<Section gap={1}>
								<Title>Resgate</Title>
								<SubTitle typeColor={divisionName}>
									{t(divisionName)}: {division?.name}
								</SubTitle>
							</Section>
						) : (
							<Section
								sx={{
									gap: '30px',
									transform: 'translateY(10px)',
								}}
							>
								<SubTitle typeColor={divisionName}>Parametrização múltipla - Resgate</SubTitle>
								<Text>Para realizar a parametrização das divisões de negócio é necessário criar um nome.</Text>
								<Controller
									name="pametrizationName"
									control={useData.control}
									render={({ onChange, value }) => (
										<CssTextField
											label="Nome*"
											variant="outlined"
											size="small"
											value={value}
											onChange={e => {
												onChange(e.target.value)
											}}
											error={formState.errors?.pametrizationName}
											helperText={formState.errors?.pametrizationName?.message}
											InputProps={{
												maxLength: 50,
											}}
										/>
									)}
								/>
							</Section>
						)}
					</>
				) : (
					<SubTitle typeColor={divisionName}>Parametrização múltipla</SubTitle>
				)}

				<Box display="flex" gap={1}>
					{_.isEmpty(hasParameterization?.results) && (
						<>
							{!viewMode && (
								<>
									<Buttons className="cancel" Icon="icon-bot_fechar" onClick={() => setViewMode(true)} />
									<Buttons
										className="save"
										disabled={!_.isEmpty(formState.errors)}
										onClick={() => {
											trigger().then(isValid => {
												if (isValid) {
													setModalParameterization({
														open: true,
														title: 'Deseja realmente alterar a parametrização',
														subtitle: 'desse benefício?',
													})
												}
											})
										}}
										Icon="icon-bot_salvar"
									/>
								</>
							)}
							{viewMode && <Buttons className="softBlueAction" onClick={() => setViewMode(false)} Icon="icon-bot_editar" />}
						</>
					)}
					<Buttons className="blueAction" Icon="icon-bot_voltar" onClick={() => onReset()} />
				</Box>
			</Box>

			{_.isEmpty(hasParameterization?.results) ? <ParameterizeDivisionTB {...sharedProps} /> : <ParameterizeTable {...sharedProps} />}
		</Stack>
	)
}

ParameterizeDivisionRes.propTypes = {
	onOpen: PropTypes.func,
	multiple: PropTypes.bool,
	division: PropTypes.object,
	setDivision: PropTypes.func,
	setEditMode: PropTypes.func,
	setMultiple: PropTypes.func,
	productData: PropTypes.object,
	programRules: PropTypes.object,
	GenerationType: PropTypes.array,
	circularProgress: PropTypes.bool,
	setReloadProduct: PropTypes.func,
	parameterization: PropTypes.object,
	setSelectedDivision: PropTypes.func,
	setParameterization: PropTypes.func,
	selectedDivisionIdRed: PropTypes.number,
	setSelectedDivisionIdRed: PropTypes.func,
	multipleParameterization: PropTypes.array,
}

ParameterizeDivisionRes.defaultProps = {
	show: false,
	isMultiple: false,
}

export default ParameterizeDivisionRes
