import React, { useEffect } from 'react'

import {
	BtnContainer,
	Form,
	getModalStyle,
	Icon,
	IconContainer,
	TextModal,
	useStyles,
} from './styled'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'

import PropTypes from 'prop-types'
import { Box, FormControl } from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { useTranslation, withTranslation } from 'react-i18next'
import { FormField } from 'components/FormField/styles'
import Button from 'components/CustomButtons/Button.jsx'
import { Controller, useForm } from 'react-hook-form'
import { passwordResolver } from './utils'
import { yupResolver } from '@hookform/resolvers'

function SimpleModal({
	icon,
	text,
	type,
	open,
	label,
	onClick,
	handleClose,
	btnCloseTxt,
	btnConfirmTxt,
	disabled,
	SubText,
	errorProps,
}) {
	const classes = useStyles()
	const [modalStyle] = React.useState(getModalStyle)
	const { t } = useTranslation()

	const { handleSubmit, errors, control, setError } = useForm({
		mode: 'all',
		resolver: type === 'EXCLUSION' ? yupResolver(passwordResolver) : null,
	})

	const onSubmit = obj => {
		onClick(obj)
	}

	useEffect(() => {
		errorProps?.error &&
			setError('password', {
				type: 'custom',
				message: t(errorProps?.helperText),
			})
	}, [errorProps])

	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Box
				component={'form'}
				onSubmit={handleSubmit(onSubmit)}
				style={modalStyle}
				className={classes.paper}
			>
				<IconContainer>
					<Icon className={icon} />
				</IconContainer>

				<TextModal>
					{SubText}
					{text}
				</TextModal>
				<Form>
					{type === 'EXCLUSION' && (
						<FormControl fullWidth>
							<Controller
								as={
									<FormField
										variant="standard"
										size="small"
										fullWidth
										autoComplete="off"
										type="password"
										id="outlined-helperText"
										label={
											<CustomRequiredField
												value={t(label)}
											/>
										}
										disabled={disabled}
										error={errors?.password}
										helperText={
											errors?.password &&
											t(errors?.password?.message)
										}
										name="fileName"
									/>
								}
								name="password"
								control={control}
								rules={{
									required:
										type === 'EXCLUSION' ? true : false,
								}}
							/>
						</FormControl>
					)}
				</Form>
				<BtnContainer>
					<Button
						size="sm"
						variant="contained"
						onClick={handleClose}
						style={{ minWidth: '107px', height: 40 }}
						color={'greenBorderButtonColor'}
						startIcon={
							type && <i className="icon-ico_reprovar_botao" />
						}
					>
						{btnCloseTxt}
					</Button>
					<Button
						size="sm"
						type="submit"
						disabled={disabled}
						variant="contained"
						color={'greenButtonColor'}
						style={{ minWidth: '107px', height: 40 }}
					>
						{btnConfirmTxt}
					</Button>
				</BtnContainer>
			</Box>
		</Modal>
	)
}

SimpleModal.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	disabled: PropTypes.bool,
	SubText: PropTypes.object,
	errorProps: PropTypes.object,
	error: PropTypes.bool,
	handleClose: PropTypes.func,
	onClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	icon: PropTypes.string,
	text: PropTypes.string,
	helperText: PropTypes.string,
	headTitle: PropTypes.string,
	readAllImportantAlerts: PropTypes.func,
	showCheckBox: PropTypes.bool,
	btnCloseTxt: PropTypes.string,
	btnConfirmTxt: PropTypes.string,
	type: PropTypes.string,
	label: PropTypes.string,
}

SimpleModal.defaultProps = {
	text: '',
	type: '',
	label: '',
	shadow: '',
	padding: '',
	open: false,
	disabled: false,
	error: false,
	headTitle: '',
	className: '',
	children: null,
	helperText: 'Required',
	btnCloseTxt: '',
	btnConfirmTxt: '',
	icon: `icon-alerta_modal`,
}

export default withTranslation()(SimpleModal)
