import React from 'react'
import PropTypes from 'prop-types'

// @ Styles
import * as S from './styles'

const Checkbox = ({ checked, onChange, disabled }) => {
	return (
		<S.CheckboxContainer>
			<label className="control control-checkbox">
				<S.CustomCheckbox type="checkbox" checked={checked} onChange={onChange} disabled={disabled} />
				<div className="control_indicator"></div>
			</label>
		</S.CheckboxContainer>
	)
}

Checkbox.propTypes = {
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	disabled: PropTypes.bool,
}

export default Checkbox
