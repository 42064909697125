// ##############################
// // // javascript library for creating charts
// #############################
var Chartist = require('chartist')
var Legend = require('chartist-plugin-legend')
var tooltip = require('chartist-plugin-tooltips')

// ##############################
// // // variables used to create animation on charts
// #############################
var delays = 80,
	durations = 500
var delays2 = 80,
	durations2 = 500

// ##############################
// DASHBOARD GRAPH #1
// #############################
const dashGraphPoints = {
	options: {
		lineSmooth: Chartist.Interpolation.cardinal({
			tension: 10,
		}),
		axisY: {
			// type: Chartist.AutoScaleAxis,
			// onlyInteger: false,
			showGrid: true,
			offset: 80,
			labelInterpolationFnc: function (value) {
				return value
			},
		},
		axisX: {
			showGrid: false,
			labelInterpolationFnc: function (value) {
				// Will return Mon, Tue, Wed etc. on medium screens
				return MonthsTranslate(value)
			},
		},
		showPoint: true,
		height: '214px',
		// plugins: [
		// 	Chartist.plugins.tooltip(),
		// 	Chartist.plugins.legend({
		// 		position: 'bottom'
		// 	})
		// ]
	},

	animation: {
		draw: function (data) {
			if (data.type === 'line' || data.type === 'area') {
				data.element.animate({
					d: {
						begin: 600,
						dur: 700,
						from: data.path
							.clone()
							.scale(1, 0)
							.translate(0, data.chartRect.height())
							.stringify(),
						to: data.path.clone().stringify(),
						easing: Chartist.Svg.Easing.easeOutQuint,
					},
				})
			} else if (data.type === 'point') {
				data.element.animate({
					opacity: {
						begin: (data.index + 1) * delays,
						dur: durations,
						from: 0,
						to: 1,
						easing: 'ease',
					},
				})
			}
		},
	},
}

const dashSalesGraphPoints = {
	options: {
		lineSmooth: Chartist.Interpolation.cardinal({
			tension: 10,
		}),
		axisY: {
			// type: Chartist.AutoScaleAxis,
			// onlyInteger: false,
			showGrid: false,
			offset: 80,
			labelInterpolationFnc: function (value) {
				return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
			},
		},
		axisX: {
			showGrid: false,
			labelInterpolationFnc: function (value) {
				// Will return Mon, Tue, Wed etc. on medium screens
				return MonthsTranslate(value)
			},
		},
		showPoint: true,
		height: '214px',
		plugins: [
			Chartist.plugins.tooltip({
				transformTooltipTextFnc: function (tooltip) {
					let value = tooltip
					value = value.toString().replace('.', ';')
					value = value
						.toString()
						.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
					value = value.toString().replace(';', ',')
					return localStorage.getItem('currencySymbol') + ` ${value}`
				},
				anchorToPoint: true,
				// appendToBody: true
			}),
		],
	},

	animation: {
		draw: function (data) {
			if (data.type === 'line' || data.type === 'area') {
				data.element.animate({
					d: {
						begin: 600,
						dur: 700,
						from: data.path
							.clone()
							.scale(1, 0)
							.translate(0, data.chartRect.height())
							.stringify(),
						to: data.path.clone().stringify(),
						easing: Chartist.Svg.Easing.easeOutQuint,
					},
				})
			} else if (data.type === 'point') {
				data.element.animate({
					opacity: {
						begin: (data.index + 1) * delays,
						dur: durations,
						from: 0,
						to: 1,
						easing: 'ease',
					},
				})
			}
		},
	},
}
// ##############################
// DASHBOARD GRAPH #2
// #############################
const dashGraphClients = {
	options: {
		lineSmooth: Chartist.Interpolation.cardinal({
			tension: 10,
		}),
		axisY: {
			showGrid: true,
			offset: 40,
		},
		axisX: {
			showGrid: false,
		},
		low: 0,
		high: 1000,
		showPoint: true,
		height: '300px',
		plugins: [
			Chartist.plugins.tooltip(),
			Chartist.plugins.legend({
				position: 'bottom',
			}),
		],
	},
	animation: {
		draw: function (data) {
			if (data.type === 'line' || data.type === 'area') {
				data.element.animate({
					d: {
						begin: 600,
						dur: 700,
						from: data.path
							.clone()
							.scale(1, 0)
							.translate(0, data.chartRect.height())
							.stringify(),
						to: data.path.clone().stringify(),
						easing: Chartist.Svg.Easing.easeOutQuint,
					},
				})
			} else if (data.type === 'point') {
				data.element.animate({
					opacity: {
						begin: (data.index + 1) * delays,
						dur: durations,
						from: 0,
						to: 1,
						easing: 'ease',
					},
				})
			}
		},
	},
}

const MonthsTranslate = (m) => {
	switch (m) {
		case 'JAN':
			m = 'JAN'
			break
		case 'FEB':
			m = 'FEV'
			break
		case 'MAR':
			m = 'MAR'
			break
		case 'APR':
			m = 'ABR'
			break
		case 'MAY':
			m = 'MAI'
			break
		case 'JUN':
			m = 'JUN'
			break
		case 'JUL':
			m = 'JUL'
			break
		case 'AUG':
			m = 'AGO'
			break
		case 'SEP':
			m = 'SET'
			break
		case 'OCT':
			m = 'OUT'
			break
		case 'NOV':
			m = 'NOV'
			break
		case 'DEC':
			m = 'DEZ'
			break
		default:
			break
	}
	if (localStorage.getItem('i18nextLng') === 'en-US') {
		switch (m) {
			case 'JAN':
				m = 'JAN'
				break
			case 'FEB':
				m = 'FEB'
				break
			case 'MAR':
				m = 'MAR'
				break
			case 'APR':
				m = 'ABR'
				break
			case 'MAY':
				m = 'MAY'
				break
			case 'JUN':
				m = 'JUN'
				break
			case 'JUL':
				m = 'JUL'
				break
			case 'AUG':
				m = 'AGO'
				break
			case 'SEP':
				m = 'SEP'
				break
			case 'OCT':
				m = 'OUC'
				break
			case 'NOV':
				m = 'NOV'
				break
			case 'DEC':
				m = 'DIC'
				break
			default:
				break
		}
	}
	return m
}

module.exports = {
	dashGraphPoints,
	dashGraphClients,
	dashSalesGraphPoints,
	MonthsTranslate,
}
