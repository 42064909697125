import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'
import PropTypes from 'prop-types'
import { CustomizedSnack, useStyles } from './styles'

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />
}

const CustomizedSnackbars = props => {
	const {
		message,
		color,
		// close,
		// icon,
		marginTop,
		anchorOrigin,
		open,
		handleClose,
	} = props
	const classes = useStyles()

	return (
		<div className={classes.root}>
			<CustomizedSnack
				open={open}
				anchorOrigin={anchorOrigin}
				autoHideDuration={6000}
				onClose={handleClose}
				top={marginTop}
			>
				<Alert onClose={handleClose} severity={color}>
					{message}
				</Alert>
			</CustomizedSnack>
		</div>
	)
}

CustomizedSnackbars.propTypes = {
	classes: PropTypes.object.isRequired,
	message: PropTypes.node.isRequired,
	color: PropTypes.oneOf([
		'info',
		'success',
		'warning',
		'danger',
		'primary',
		'rose',
	]),
	close: PropTypes.bool,
	icon: PropTypes.object,
	anchorOrigin: PropTypes.object,
	place: PropTypes.oneOf(['tl', 'tr', 'tc', 'br', 'bl', 'bc']),
	open: PropTypes.bool,
	marginTop: PropTypes.string,
	closeNotification: PropTypes.func,
	handleClose: PropTypes.func,
}

export default CustomizedSnackbars
