import {
	getAllPurchasers,
	UpdatePaymentSettings,
} from '../../../../redux/api/paymentGateway'

// @Mui
import { styled } from '@mui/system'
import TextField from '@mui/material/TextField'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'
import Cookies from 'js-cookie'

export const GetPurchaserObjects = async setPurchaserObjects => {
	const response = await getAllPurchasers()

	if (response) {
		const { data } = response
		setPurchaserObjects(data)
	}
}

export const cardsStyle = [
	{
		name: 'MENU_PAYMENT_AUTHORIZING',
		icon: 'icon-ico_resumo_conta',
		color: 'success',
	},
]

export const disableOrEnablePaymentMethods = (payments, settings) => {
	const brands = settings?.selectedPurchasers[0]?.brands

	const paymentMethods = settings?.selectedPurchasers[0]?.paymentMethods

	const newPaymentMethods = paymentMethods.map(paymentMethod => {
		if (paymentMethod.name === payments) {
			paymentMethod.enabled = !paymentMethod.enabled
		}
		return paymentMethod
	})

	const newBrands = brands.map(brand => {
		if (brand.name === payments) {
			brand.enabled = !brand.enabled
		}
		return brand
	})

	updatePaymentMethods(newBrands, newPaymentMethods, settings)
}

export const updatePaymentMethods = async (brands, newPaymentMethods) => {
	const partnerId = String(Cookies.get('partnerId'))

	// eslint-disable-next-line no-unused-vars
	const updatePartnerSettings = await UpdatePaymentSettings(partnerId, {
		PaymentMethods: newPaymentMethods,
		brands: brands,
	})
}

export const verifyIcons = (purchaseOptions, setData, settings) => {
	for (const icons of purchaseOptions) {
		if (settings?.selectedPurchasers[0]?.purchaserName === icons.name) {
			if (typeof icons !== 'undefined' && icons !== null) {
				setData({
					name: icons.name,
					value: false,
					icon: icons.icon,
					finish: true,
				})
			}
		}
	}
}

export const PropsSnack = {
	open: true,
	color: 'success',
	icon: 'icon-ico_aprovar_botao',
	message: 'Autorizadora conectada com sucesso',
}

export const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: getThemeColorFromLocalStorage()?.headers?.backgroundColor,
	},
	'& label': {
		lineHeight: 1.2,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons
			?.backgroundColor,
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
		'&.Mui-focused fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
	},
})
