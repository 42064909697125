import React, { useEffect, useState } from 'react'
// nodejs library that concatenates classes
import classNames from 'classnames'
// nodejs library to set properties for components
import PropTypes from 'prop-types'

// material-ui components
import withStyles from '@material-ui/core/styles/withStyles'

import buttonStyle from 'assets/jss/material-dashboard-pro-react/components/buttonStyle.jsx'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { ButtonCustom } from './styles'

function RegularButton({ ...props }) {
	const { classes, color, round, children, fullWidth, disabled, simple, size, block, link, justIcon, className, muiClasses, ...rest } = props
	const btnClasses = classNames({
		[classes.button]: true,
		[classes[size]]: size,
		[classes[color]]: color,
		[classes.round]: round,
		[classes.fullWidth]: fullWidth,
		[classes.disabled]: disabled,
		[classes.simple]: simple,
		[classes.block]: block,
		[classes.link]: link,
		[classes.justIcon]: justIcon,
		[className]: className,
	})
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const getColor = () => {
		return (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor &&
			themeColor.navigation.buttons.textColor
		)
	}
	return (
		<ButtonCustom
			{...rest}
			color={getColor()}
			classes={muiClasses}
			className={btnClasses}
			disableElevation
			greenButtonColor={color}
			themeColor={themeColor}
			disabled={disabled}
		>
			{children}
		</ButtonCustom>
	)
}

RegularButton.propTypes = {
	classes: PropTypes.object.isRequired,
	color: PropTypes.oneOf([
		'primary',
		'info',
		'success',
		'warning',
		'danger',
		'rose',
		'white',
		'twitter',
		'facebook',
		'google',
		'linkedin',
		'pinterest',
		'youtube',
		'tumblr',
		'github',
		'behance',
		'dribbble',
		'reddit',
		'transparent',
		'waterButtonColor',
		'greenButtonColor',
		'greenBorderButtonColor',
	]),
	size: PropTypes.oneOf(['sm', 'lg']),
	simple: PropTypes.bool,
	round: PropTypes.bool,
	fullWidth: PropTypes.bool,
	disabled: PropTypes.bool,
	block: PropTypes.bool,
	link: PropTypes.bool,
	justIcon: PropTypes.bool,
	className: PropTypes.string,
	muiClasses: PropTypes.object,
	children: PropTypes.node,
}

export default withStyles(buttonStyle)(RegularButton)
