import styled from 'styled-components'

import { FormControlLabel } from '@mui/material'

export const LabelForm = styled(FormControlLabel)`
	.MuiSvgIcon-root {
		fill: ${props =>
			props?.themeColor?.navigation?.buttons?.backgroundColor};
		fill: ${({ error }) => error && '#f44336'};
	}

	span {
		color: #5d5d5d;
		color: ${({ error }) => error && '#f44336'};
	}
	svg {
		color: ${props =>
			props?.themeColor?.navigation?.buttons?.backgroundColor};
	}
`
