import * as Yup from 'yup'

export const branchSchema = Yup.object({
	businessDivisionName: Yup.string().required(' obrigatório'),
	businessDivisionCNPJ: Yup.string().required(' obrigatório'),
	businessDivisionMarketingArea: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionCEP: Yup.string().required(' obrigatório'),
	businessDivisionAddress: Yup.string().required(' obrigatório'),
	businessDivisionAddressNumber: Yup.string().required(' obrigatório'),
	businessDivisionDistrict: Yup.string().required(' obrigatório'),
	businessDivisionUF: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionCity: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionCountry: Yup.object({
		value: Yup.string().required(' obrigatório'),
	}),
	businessDivisionResponsibleCPF: Yup.string().required(' obrigatório'),
	businessDivisionResponsibleName: Yup.string().required(' obrigatório'),
	businessDivisionResponsibleCellphone: Yup.string().required(' obrigatório'),
	businessDivisionResponsibleEmail: Yup.string().required(' obrigatório'),
	businessDivisionParent: Yup.object()
		.nullable()
		.required(' obrigatório'),
	isMEI: Yup.boolean().nullable(),
	ie: Yup.string().when(['isIEFree', 'isMEI'], {
		is: (isIEFree, isMEI) => !isIEFree && !isMEI,
		then: Yup.string()
			.nullable()
			.required('IE obrigatório'),
		otherwise: Yup.string().nullable(),
	}),
	isIEFree: Yup.boolean().nullable(),
	ieStateId: Yup.object().when(['isIEFree', 'isMEI'], {
		is: (isIEFree, isMEI) => !isIEFree && !isMEI,
		then: Yup.object()
			.nullable()
			.required('Estado obrigatório'),
		otherwise: Yup.object().nullable(),
	}),
})
