import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import DocumentTitle from 'react-document-title'
import * as _ from 'lodash'
import Alert from 'react-bootstrap-sweetalert'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

//material-UI components
import { withStyles } from '@material-ui/styles'
import { Grid } from '@material-ui/core'

// layout components
import GridContainer from 'components/Grid/GridContainer.jsx'
import CustomTableMaterial from 'components/CustomTableMaterial'
import Section from 'components/Section'
import Loader from 'components/Loader/Loader.jsx'
import GriTablow from '../components/GriTablow'
import Button from 'components/CustomButtons/Button.jsx'
import { numberFormatText } from 'utils/utils'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

import { SweetAlert, HideAlert, GetInvoiceById, ShowLoader, HideLoader } from 'redux/actions'

//utils
import compose from 'utils/compose'
import { TitleContainer } from '../style'
import { envs } from '../../../redux/api/windowServerData'
import { getProgramTitle } from '../../../utils/utils'

class Details extends Component {
	constructor(props) {
		super(props)
		this.state = {
			invoiceData: {
				consumer: null,
				channelType: null,
				total: null,
				items: [],
			},
		}
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		const id = this.props.match && this.props.match.params.id
		this.props.GetInvoiceById(id)
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.invoiceData !== this.props.invoiceData) {
			this.setState({ invoiceData: this.props.invoiceData })
			this.props.HideLoader()
		}

		if (prevProps.invoiceDataFailed !== this.props.invoiceDataFailed) {
			//
			this.props.SweetAlert(
				<Alert
					error
					style={{ display: 'block' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}
	}

	render() {
		const { t } = this.props
		const { invoiceData } = this.state

		return (
			<>
				<DocumentTitle title={getProgramTitle()} />
				<GridContainer justifyContent="center">
					<Grid xs={12} sm={12} md={12} style={{ paddingLeft: '15px' }}>
						<TitleContainer>
							<Section title={t('MENU_SALES_ITEMS')} style={{ flexGrow: 1 }} />
							<Button
								style={{
									textAlign: 'right',
									marginLeft: '15px',
								}}
								size="sm"
								variant="contained"
								color="greenBorderButtonColor"
								onClick={() => this.props.history.goBack()}
							>
								{t('BUTTON_BACK')}
							</Button>
						</TitleContainer>
						<Grid
							container
							spacing={0}
							style={{
								backgroundColor: '#f0f0f0',
								padding: '5px 0',
								borderRadius: '4px',
								marginBottom: '20px',
							}}
						>
							<GriTablow
								labels={[t('FIELD_CHANNEL_TYPES'), t('FIELD_CONSUMER'), t('FIELD_PURCHASE_TOTAL')]}
								values={[
									t('FIELD_CHANNEL_' + (invoiceData.channelType ? invoiceData.channelType.value : '')),
									invoiceData.consumer ? invoiceData.consumer.name : '',
									numberFormatText(
										invoiceData.total ? invoiceData.total : '0',
										// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
										localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
										2,
									),
								]}
							/>
						</Grid>
						<CustomTableMaterial
							title={false}
							columns={[
								{ title: 'Código', field: 'code' },
								{
									title: 'Produto',
									field: 'description',
									headerStyle: { textAlign: 'left' },
									cellStyle: { textAlign: 'left' },
								},
								{ title: 'Qtd.', field: 'quantity' },
								//{ title: 'Un.', field: 'unit' },
								{ title: 'Unitário', field: 'unitPrice' },
								{ title: 'Total', field: 'totalPrice' },
							]}
							data={invoiceData.items.map(item => ({
								code: item.code,
								description: item.description,
								quantity: (item.quantity ? item.quantity : '0') + ' ' + (item.unit ? item.unit : ''),
								//unit: item.unit,
								unitPrice: numberFormatText(
									item.unitPrice ? item.unitPrice : '0',
									//localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
									localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
									2,
								),
								totalPrice: numberFormatText(
									item.totalPrice ? item.totalPrice : '0',
									//localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
									localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') : envs.REACT_APP_CURRENCY_SYMBOL,
									2,
								),
							}))}
							search={false}
						/>
					</Grid>
				</GridContainer>
			</>
		)
	}
}

Details.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
	return {
		invoiceData: state.invoice.invoiceData,
		invoiceDataFailed: state.invoice.invoiceDataFailed,
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({ SweetAlert, HideAlert, ShowLoader, HideLoader, GetInvoiceById }, dispatch)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(extendedFormsStyle),
	withTranslation(),
)(Details)
