import React, { useState } from 'react'

import { withStyles } from '@material-ui/core'

import SponsorRegister from './SponsorRegister'
import SponsorList from './SponsorList'

import { BoxNotification } from './styles'
import SponsorContext from './context'

function Sponsor() {
	const [switchComp, setSwitchComp] = useState('list')
	const [sponsor, setSponsor] = useState()
	const [editing, setEditing] = useState(false)
	const comp = {
		list: <SponsorList></SponsorList>,
		form: <SponsorRegister></SponsorRegister>,
	}

	return (
		<SponsorContext.Provider
			value={{
				setSwitchComp,
				sponsor,
				setSponsor,
				editing,
				setEditing,
			}}
		>
			<BoxNotification container style={{ display: 'contents' }}>
				{comp[switchComp]}
			</BoxNotification>
		</SponsorContext.Provider>
	)
}

export default withStyles()(withStyles()(Sponsor))
