import React, { useState, useEffect, useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import { useHistory, useParams } from 'react-router-dom'
import { FormSkeleton } from '../widgets/FormSkeleton'
import Button from 'components/CustomButtons/Button.jsx'

// Form & validation
import InputMask from 'react-input-mask'
import { useForm, Controller } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import * as Yup from 'yup'
// import Select from 'react-select'
import { Select, MenuItem } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

// Redux
import { useDispatch } from 'react-redux'
import { HideLoader } from 'redux/actions'
import {
	GetAccounts,
	GetAccountById,
	GetBank,
	UpdateBankAccount,
} from '../../../redux/api/accounts'
import { GetCurrentPartner } from '../../../redux/api/user'

// Material
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from "@material-ui/core/styles";

// Components
import Section from 'components/Section'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import { ModalConfirmPassword } from '../AccountManage/Modal/BalanceModal/ModalConfirmPassword'
import ModalFail from './ModalFail'
import ConfirmModal from '../../../components/ConfirmModal'
import * as S from './style'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'


const getThemeColorStructureBackground = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().structure) {
			if (getThemeColorFromLocalStorage().structure.backgroundColor) {
				return getThemeColorFromLocalStorage().structure.backgroundColor
			}
		}
	}
	return '#08993A'
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			fontWeight: '400',
			color: 'hls(0, 0, 0, 0.55)',
			lineHeight: 1.2,
			zIndex: 0,
		},
		'& .MuiInputBase-root': {
			color: 'rgba(0, 0, 0, 0.6)',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)',
			//
			'&:not(.Mui-disabled)': {
				marginTop: '-7px',
				marginLeft: '-5px',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: '9.5px',
			paddingBottom: '10.5px',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			fontSize: '16px',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			cursor: 'default',
		},
		'& fieldset': {
			color: 'rgba(0, 0, 0, 0.7)',
			borderColor: 'hsl(0,0%,80%)',
			'& .hover': {
				borderColor: 'hsl(0,0%,80%)',
			},
			'& legend': {
				width: '0px',
			},
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: getThemeColorStructureBackground(),
				},
				'&.Mui-focused fieldset': {
					borderColor: getThemeColorStructureBackground(),
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(3,3%,95%)',
			zIndex: -1,
			'& fieldset': {
				borderColor: 'transparent',
				'& legend': {
					width: '0px',
				},
			},
		},
		'& .PrivateNotchedOutline-legendLabelled-583': {
			width: '0px !important',
		},
	},
})(TextField)


const status = [
	{ value: false, label: 'Inativa' },
	{ value: true, label: 'Ativa' },
]

export function AccountDetails() {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const { id } = useParams()

	const [data, setData] = useState(null)
	const [isPwdModalOpen, setIsPwdModalOpen] = useState(false)
	const [isModalFail, setIsModalFail] = useState(false)
	const [openCancelModal, setOpenCancelModal] = useState(false)
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		dispatch(HideLoader())

		if (!!id) {
			promiseOptions()
			RequestApi()

			handleRequestBalanceData()
		}
	}, [id])

	const handleRequestBalanceData = useCallback(async () => {
	}, [id])

	const validation = Yup.object().shape(
		{
			name: Yup.string()
				.required()
				.test('nameExists', 'Same name exists', function (value) {
					return new Promise((resolve) => {
						GetAccounts(value).then((res) => {
							if (
								!!res &&
								res.results &&
								res.results.length > 0
							) {
								const possibilities = res.results

								const entityWithSameName = possibilities.find(
									(item) => item.name === value,
								)

								if (
									!entityWithSameName ||
									entityWithSameName.id === id
								)
									resolve(true)
								else resolve(false)
							} else {
								resolve(true)
							}
						})
					})
				}),
			description: Yup.string().required(),
			isActive: Yup.boolean().required(),
			number: Yup.string()
				.nullable()
				.when(['branch', 'bank'], {
					is: (branch, bank) => !!branch || !!bank,
					then: Yup.string().required().min(8),
					otherwise: Yup.string().nullable(),
				}),
			branch: Yup.string()
				.nullable()
				.when(['number', 'bank'], {
					is: (number, bank) => !!number || !!bank,
					then: Yup.string().required().min(4),
					otherwise: Yup.string().nullable(),
				}),
			bank: Yup.object()
				.nullable()
				.when(['branch', 'number'], {
					is: (branch, number) => !!branch || !!number,
					then: Yup.object().required(),
					otherwise: Yup.object().nullable(),
				}),
		},
		[
			['branch', 'bank'],
			['number', 'bank'],
			['branch', 'number'],
		],
	)

	const CustomPopper = function (props) {
		const classesPoper = useStyles();
		return <Popper {...props} className={classesPoper.root} placement="bottom" />;
	};

	const useStyles = makeStyles((theme) =>
		createStyles({
			root: {
				"& .MuiAutocomplete-listbox": {
					"& :hover": {
						color: "#ffffff",
						backgroundColor: themeColor &&
							themeColor.structure &&
							themeColor.structure.backgroundColor
							? themeColor.structure.backgroundColor + ' !important'
							: `#fff`
					},
					"& li": {
						backgroundColor: "#ffffff",
					}
				}
			},
		})
	);


	async function RequestApi() {
		try {
			await GetAccountById(id).then((response) => {
				const formattedData = {
					name: response.name,
					description: response.description,
					bank: response.bank
						? {
							value: response.bank.id,
							label: `${response.bank.code} - ${response.bank.name}`,
						}
						: null,
					branch: response.branch ? response.branch : null,
					number: response.number ? response.number : null,
					numberDigit: response.numberDigit
						? response.numberDigit
						: null,
					isActive: response.isActive,
				}
				setData(formattedData)
			})
		} catch (error) { }
	}

	const [isDisabled, setIsDisabled] = useState(true)
	const [options, setOptions] = useState(null)

	const {
		control,
		formState: { errors },
		register,
		handleSubmit,
	} = useForm({
		resolver: yupResolver(validation),
	})

	const onSubmit = async (data) => {
		if (data.isActive === false) {
			setIsPwdModalOpen(true)
		} else {
			handleSubmitForm(data)
		}
	}

	const handleSubmitForm = async (data) => {
		const partnerId = await GetCurrentPartner().then((res) => {
			return res.id
		})

		const numberAccArray = data.number ? data.number.split('-') : null

		const formattedValues = {
			name: data.name,
			description: data.description,
			bankId: !!data.bank ? data.bank.value : null,
			branch: !!data.branch ? data.branch : null,
			branchDigit: null,
			number: numberAccArray ? numberAccArray[0] : null,
			numberDigit: numberAccArray ? numberAccArray[1] : null,
			isActive: data.isActive,
			type: 1,
			partnerId: partnerId,
		}

		UpdateBankAccount(id, formattedValues)
			.then(() => {
				history.push('/accounts')
			})
			.catch((error) => {
				console.error(error)
			})
	}

	async function promiseOptions() {
		try {
			const response = await GetBank()
			const formattedResponse = []

			response.forEach((item) => {
				const i = { value: item.key, label: item.value }
				formattedResponse.push(i)
			})

			setOptions(formattedResponse)
			return formattedResponse
		} catch (error) { }
	}


	function handleEdit(e) {
		e.preventDefault()
		setIsDisabled(false)
	}

	function handleBack() {
		history.goBack()
	}

	async function handleConfirmPasswordModal() {
		setIsPwdModalOpen(false)

		// TESTE SEM API
		const reserved = 45
		if (reserved > 0) {
			setIsModalFail(true)
		} else {
			handleSubmitForm()
		}
	}

	function handleConfirmModal() {
		setIsDisabled(true)
		setOpenCancelModal(false)
	}

	if (data && options) {
		return (
			<S.Container themeColor={themeColor}>
				<ConfirmModal
					type="cancel"
					open={openCancelModal}
					onConfirm={() => handleConfirmModal()}
					onCancel={() => setOpenCancelModal(false)}
				/>
				<ModalFail
					open={isModalFail}
					onClose={() => {
						setIsPwdModalOpen(false)
						setIsModalFail(false)
					}}
				/>
				<ModalConfirmPassword
					open={isPwdModalOpen}
					onCancel={() => setIsPwdModalOpen(false)}
					onConfirm={() => handleConfirmPasswordModal()}
				/>
				<S.AccountContainer onSubmit={handleSubmit(onSubmit)}>
					<S.HeaderBox>
						<S.SectionBox>
							<Section
								title={t('EDIT_ACCOUNT')}
								style={{ flexGrow: 1 }}
							/>
						</S.SectionBox>

						<S.BtnHeaderBox>
							<Button
								size="sm"
								color="greenBorderButtonColor"
								startIcon={
									<div>
										<i
											style={{
												fontSize: 14,
												padding: '2px 0',
											}}
										/>
									</div>
								}
								onClick={
									isDisabled
										? () => handleBack()
										: () => setOpenCancelModal(true)
								}
							>
								{isDisabled
									? t('BUTTON_BACK')
									: t('BUTTON_CANCEL')}
							</Button>
							{isDisabled ? (
								<Button

									color="greenButtonColor"
									size="sm"
									type="button"
									onClick={(e) => handleEdit(e)}
								>
									{t('BUTTON_EDIT')}
								</Button>
							) : (
								<Button size="sm" type="submit">
									{t('BUTTON_SAVE')}
								</Button>
							)}
						</S.BtnHeaderBox>
					</S.HeaderBox>
					<S.InputsBox>
						<S.AccountName>
							<Controller
								control={control}
								name="name"
								render={({ onChange, value }) => (
									<CssTextField
										value={value}
										onChange={onChange}
										label={`${t('ACCOUNT_NAME')} *`}
										variant="outlined"
										margin="dense"
										fullWidth
										disabled={
											isDisabled ||
											(!!data.name &&
												data.name === 'Conta Padrão')
										}
										error={errors.name}
									/>
								)}
								defaultValue={data.name}
							/>

							{errors.name && errors.name.type === 'required' && (
								<S.ErrorSpan>Campo obrigatório</S.ErrorSpan>
							)}
							{errors.name &&
								errors.name.type === 'nameExists' && (
									<S.ErrorSpan>
										O nome deve ser único
									</S.ErrorSpan>
								)}
						</S.AccountName>

						<S.AccountDescription>
							<Controller
								control={control}
								name="description"
								render={({ onChange, value }) => (
									<CssTextField
										value={value}
										onChange={onChange}
										label={`${t('ACCOUNT_DESCRIPTION')} *`}
										variant="outlined"
										margin="dense"
										fullWidth
										disabled={
											isDisabled ||
											(!!data.name &&
												data.name === 'Conta Padrão')
										}
										defaultValue={data.description}
										error={errors.description}
									/>
								)}
								defaultValue={data.description}
							/>

							{errors.description &&
								errors.description.type === 'required' && (
									<S.ErrorSpan>Campo obrigatório</S.ErrorSpan>
								)}
						</S.AccountDescription>

						<S.AccountBank>
							<Controller
								control={control}
								name="bank"
								render={({ onChange, value }) => (
									<Autocomplete
										id="combo-box-demo"
										size="small"
										options={options}
										getOptionLabel={(option) =>
											option.label ? option.label : ''
										}
										getOptionSelected={(option, value) =>
											value === undefined ||
											value === '' ||
											option.id === value.id
										}
										onChange={(event, item) => {
											onChange(item)
										}}
										PopperComponent={CustomPopper}
										value={value}
										disabled={isDisabled}
										renderInput={(params) => (
											<div ref={params.InputProps.ref}>
												<S.BankStyledInput
													{...params}
													noOptionsMessage={() =>
														t('RESULTS_NOT_FOUND')
													}
													style={{
														width: '100%',
													}}
													label={t('FIELD_BANK')}
													variant="outlined"
													size="small"
												/>
											</div>
										)}
									/>
								)}
								defaultValue={data.bank}
							/>
							{errors.bank && (
								<S.ErrorSpan>Campo obrigatório</S.ErrorSpan>
							)}
						</S.AccountBank>

						<S.AccountAgency>
							<Controller
								control={control}
								name="branch"
								render={({ onChange, value }) => (
									<InputMask
										value={value}
										onChange={onChange}
										mask="9999"
										maskChar={null}
										disabled={isDisabled}
										label={t('FIELD_AGENCY')}
										variant="outlined"
										margin="dense"
										fullWidth
										error={errors.branch}
									>
										{(inputProps) => (
											<CssTextField
												{...inputProps}
												disabled={isDisabled}
											/>
										)}
									</InputMask>
								)}
								defaultValue={data.branch}
							/>

							{errors.branch &&
								errors.branch.type === 'required' && (
									<S.ErrorSpan>Campo obrigatório</S.ErrorSpan>
								)}
						</S.AccountAgency>

						<S.AccountNumber>
							<Controller
								control={control}
								name="number"
								render={({ onChange, value }) => (
									<InputMask
										value={value}
										onChange={onChange}
										mask="999999-9"
										maskChar={null}
										disableUnderline
										label={t('FIELD_ACCOUNT_NUMBER')}
										variant="outlined"
										margin="dense"
										fullWidth
										error={errors.number}
										disabled={isDisabled}
									>
										{(inputProps) => (
											<CssTextField
												{...inputProps}
												disabled={isDisabled}
											/>
										)}
									</InputMask>
								)}
								defaultValue={
									data.number && data.numberDigit
										? `${data.number}-${data.numberDigit}`
										: null
								}
							/>

							{errors.number &&
								errors.number.type === 'required' && (
									<S.ErrorSpan>Campo obrigatório</S.ErrorSpan>
								)}
						</S.AccountNumber>
					</S.InputsBox>
					<S.AccountActiva>
						<Controller
							name="isActive"
							control={control}
							as={
								<Select
									inputRef={register}
									placeholder={t('FIELD_STATUS')}
									noOptionsMessage={() =>
										t('RESULTS_NOT_FOUND')
									}
									variant="outlined"
									style={{
										height: '40px',
										width: '100%',
									}}
									disabled={
										isDisabled ||
										(!!data.name &&
											data.name === 'Conta Padrão')
									}
									input={<S.StyledInput />}
								>
									{status &&
										status.map((item) => (
											<MenuItem value={item.value}>
												{item.label}
											</MenuItem>
										))}
								</Select>
							}
							defaultValue={data.isActive}
						/>
						{errors.status && (
							<S.ErrorSpan>Campo obrigatório</S.ErrorSpan>
						)}
					</S.AccountActiva>
				</S.AccountContainer>
			</S.Container>
		)
	} else return <FormSkeleton />
}
