import React from 'react'
import PropTypes from 'prop-types'
import style from './style.module.css'
import ProgressBar from './subcomps/ProgressBar/index.jsx'
import { Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'

const WaitLoader = ({
	progressValue = 0, //passar um state com o progresso da requisição
	text,
	title,
	openModal = true,
}) => {
	const { t } = useTranslation()
	return (
		<Modal open={openModal}>
			<div className={style.container}>
				<div className={style.card}>
					<div>
						<i className="icon-ico_aguardar" />
					</div>
					<span>{t(title)}</span>
					<p>{t(text)}</p>
					<ProgressBar progressValue={progressValue} />
				</div>
			</div>
		</Modal>
	)
}

WaitLoader.propTypes = {
	progressValue: PropTypes.number,
	text: PropTypes.string,
	title: PropTypes.string,
	openModal: PropTypes.bool,
}

export default WaitLoader
