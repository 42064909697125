import { Grid } from '@material-ui/core'
import styled from 'styled-components/macro'

const getBGCol = (props) => {
	if (
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor
	) {
		return props.themeColor.structure.backgroundColor
	}
	return `#fff`
}
const getTextCol = (props) => {
	if (
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor
	) {
		return props.themeColor.titles.highlight.textColor
	}
	return `#333`
}
export const Container = styled.div`

	.MuiGrid-spacing-xs-4 > .MuiGrid-item {
		padding: 15px !important;
	}

	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) => getTextCol(props)};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) => getBGCol(props)} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => getBGCol(props)};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => getBGCol(props)};
	}


	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${(props) => getBGCol(props)} !important;
	 }

	.MuiSelect-outlined.MuiSelect-outlined {
		background-color: #fff;
	}
`

export const CustomGridDate = styled(Grid)`
	display: flex;
	gap: 30px;
`

export const CustomButtons = styled.div`
	display: flex;
	gap: 15px;
	justify-content: flex-end;
	margin-top: 21px;
	width: 100%;


`
