import styled from 'styled-components'

import { Modal } from '@material-ui/core'

export const ModalBox = styled(Modal)`
	align-items: center;
	display: flex;
	justify-content: center;
`

export const Box = styled.div`
	align-items: center;
	border-radius: 10px;
	box-shadow: 24;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	max-width: 800px;
	max-height: 670px;
	position: absolute;
	padding: 25px !important;
	transform: translate(-50%; -50%);
	width: 90%;
`

export const Close = styled.div`
	display: flex;
	justify-content: flex-end;
	button {
		border: none;
		border-radius: 5px;
		background-color: #F05B4F;
		cursor: pointer;
		color: #fff;
		padding: 8px;
		width: 90px;
		height: 0.938rem;
	}
`
export const Icon = styled.div`
	color: #f9bb86;
	display: flex;
	font-size: 79px;
	justify-content: center;
	margin: 18px 0;
	top: -10px;
`
export const TextStyle = styled.div`
	p {
		text-align: center;
		font: normal normal normal 16px/30px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
	}
`

export const Example = styled.div`
	text-align: left !important;
	background-color: #fff;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	img {
		width: 100%;
	}
`

export const Paragraph = styled.p`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 38px;
	margin-right: 38px;
	opacity: 0.9;
	color: #333333;
`

export const Model = styled.div`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 50px;
	padding-left: 32px;
	margin-right: 38px;
	opacity: 0.8;
	color: #333333;
`
export const Form = styled.form`
	.gfEDZo {
		width: 344px;
		height: 40px;
		background: #fff 0% 0% no-repeat padding-box;
		border: 1px solid #c4c4c4;
		opacity: 1;
	}
`
export const ContainerChildren = styled.div`
	height: 100%;
	width: 100%;
`
