import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    userByGroup: [],
    claimsGroupNewGroup: [],
    newGroupPostFailed: false,
    postNewGroup: false,
    editNewGroup: false,
    groupDataFailed: false
};
//POST ------------------------------------

const postNewGroupData = (state, action) => {
    return updateObject(state, {
        postNewGroup: action.postNewGroup,
    });
};
const EditNewGroupData = (state, action) => {
    return updateObject(state, {
        editNewGroup: action.editNewGroup,
    });
};

// -----------------------------------------

const fetcUserByGroup = (state, action) => {
    return updateObject(state, {
        userByGroup: action.userByGroup,
    });
};
const fetcClaimsGroupNewGroup = (state, action) => {
    return updateObject(state, {
        claimsGroupNewGroup: action.claimsGroupNewGroup,
    });
};

const fetcUsersOptions = (state, action) => {
    return updateObject(state, {
        userOptions: action.userOptions,
    });
};

export const fetcRemoveUserFromgroup = (state, action) => {
    return updateObject(state, {
        userByGroup: action.userByGroup,
    });
};

// DATA FAIL
const fetchFail = (state, action) => {
    return updateObject(state, {
        groupDataFailed: true
    });
};

const postNewGroupFail = (state, action) => {
    return updateObject(state, {
        newGroupPostFailed: action.newGroupPostFailed
    });
};

const groupsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.POST_NEW_GROUP_DATA:
            return postNewGroupData(state, action);
        case actionTypes.EDIT_NEW_GROUP_DATA:
            return EditNewGroupData(state, action);
        case actionTypes.POST_NEW_GROUP_DATA_FAILED:
            return postNewGroupFail(state, action);
        case actionTypes.FETCH_USER_GROUP_DATA:
            return fetcUserByGroup(state, action);
        case actionTypes.FETCH_REMOVE_USER_FROM_GROUP:
            return fetcRemoveUserFromgroup(state, action);
        case actionTypes.FETCH_USER_OPTIONS_DATA:
            return fetcUsersOptions(state, action);
        case actionTypes.FETCH_CLAIMS_GROUP_NEW_GROUP_DATA:
            return fetcClaimsGroupNewGroup(state, action);
        case actionTypes.FETCH_ACCESS_FAILED:
            return fetchFail(state, action);
        default:
            return state;
    }
};

export default groupsReducer;