import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	margin: 0 15px;
	width: 100%;

	@media screen and (max-width: 598px) {
		margin: 22px 15px 0px -13px;
	}
`