// @Mui
import { styled } from '@mui/system'
import TextField from '@mui/material/TextField'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

export const cardsStyle = [
	{
		name: 'NAVPILLS_LOG',
		icon: 'icon-ico_notificacoes',
		color: 'success',
	},
]

export const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
	},
	'& label': {
		lineHeight: 1.2,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		},
		'&.Mui-focused fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor,
		},
	},
})

export const PropsSnackExportLog = {
	open: true,
	color: 'success',
	icon: 'icon-ico_aprovar_botao',
	message: 'Log exportado com sucesso',
}

export const SnackProps = {
	open: false,
	color: '',
	icon: '',
	message: '',
}
