import React, { Fragment } from 'react'
// used for making the prop types of this component
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'

// core components

import defaultImage from 'assets/img/image_placeholder.jpg'
import defaultAvatar from 'assets/img/fazendeiro.png'
// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators, compose } from 'redux'
import { SetFileData } from 'redux/actions'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

class AvatarUpload extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			file: null,
			imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
			themeColor: getThemeColorFromLocalStorage(),
		}
		this.handleImageChange = this.handleImageChange.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this)
		this.handleClick = this.handleClick.bind(this)
		this.handleRemove = this.handleRemove.bind(this)
	}

	componentDidMount() {
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		if (this.props.value && this.props.value !== '') {
			this.setState({ imagePreviewUrl: this.props.value, file: '' })
		}
	}

	handleImageChange = async (e) => {
		e.persist()
		e.preventDefault()
		let reader = new FileReader()
		await this.props.SetFileData(e.target.files[0])
		reader.onloadend = () => {
			this.setState({
				file: this.props.imageFile,
				imagePreviewUrl: reader.result,
			})
		}
		reader.readAsDataURL(this.props.imageFile)
	}

	handleSubmit(e) {
		e.preventDefault()
	}
	handleClick() {
		this.refs.fileInput.click()
	}
	handleRemove() {
		this.setState({
			file: null,
			imagePreviewUrl: this.props.avatar ? defaultAvatar : defaultImage,
		})
		this.refs.fileInput.value = null
		if (this.props.onChange) {
			this.props.onChange()
		}
	}
	getStructBGCol() {
		return (
			this.state &&
			this.state.themeColor &&
			this.state.themeColor.structure &&
			this.state.themeColor.structure.backgroundColor
		)
	}
	render() {
		var { t } = this.props
		return (
			<Fragment>
				<div
					className="picture-container"
					style={{ textAlign: 'left', width: '124px' }}
				>
					<div
						className="picture"
						style={{
							margin: '5px 0 0 0',
							borderColor: this.getStructBGCol(),
						}}
					>
						<i
							className="material-icons"
							style={{
								backgroundColor: this.getStructBGCol(),
								position: 'absolute',
								left: '84px',
								top: '30px',
								border: '2px',
								padding: '8px',
								borderRadius: '60px',
							}}
						>
							camera_alt
						</i>
						<img
							style={{
								objectFit: 'cover',
								height: '100px',
								width: '100px',
							}}
							src={this.state.imagePreviewUrl}
							className="picture-src"
							alt="..."
						/>
						<input
							type="file"
							onChange={(e) => this.handleImageChange(e)}
							ref="fileInput"
						/>
					</div>
				</div>
				<button
					onClick={this.handleClick}
					style={{
						backgroundColor: 'transparent',
						border: 'none',
						cursor: 'pointer',
						display: 'inline',
						margin: '0',
						padding: '0',
					}}
				>
					<h6 className="description">{t('BUTTON_SELECT_IMAGE')}</h6>
				</button>
				{this.state.file != null && (
					<Fragment>
						{' '}
						|{' '}
						<button
							onClick={this.handleRemove}
							style={{
								backgroundColor: 'transparent',
								border: 'none',
								cursor: 'pointer',
								display: 'inline',
								margin: '0',
								padding: '0',
							}}
						>
							<h6 className="description">Remover Foto</h6>
						</button>
					</Fragment>
				)}
			</Fragment>
		)
	}
}

AvatarUpload.propTypes = {
	value: PropTypes.string,
}

const mapStateToProps = (state) => {
	return {
		imageFile: state.images.imageFile,
		tierDataFailed: state.tier.tierDataFailed,
	}
}

const mapDispatchToProps = (dispatch) =>
	bindActionCreators({ SetFileData }, dispatch)

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withTranslation(),
)(AvatarUpload)
