import React, { useState, useEffect } from 'react'

import { Controller, useForm } from 'react-hook-form'
import CustomDateNew from 'components/CustomDateNew'
import moment from 'moment'

import { IconButton } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import { Add, Remove } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Popper from "@material-ui/core/Popper";
import { makeStyles, createStyles } from "@material-ui/core/styles";


import * as S from './style'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { useAward } from '../../../Providers/AwardContext'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import { withStyles } from '@material-ui/styles'

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: grayColor[2],
			},
			'&.Mui-focused fieldset': {
				borderColor: grayColor[2],
			},
		},
	},
})(TextField)


const SearchForm = () => {
	const { t } = useTranslation()

	const [themeColor, setThemeColor] = useState()
	const [startDate, setStartDate] = useState('')
	const [selectedEvent, setSelectedEvent] = useState('')
	const [endDate, setEndDate] = useState('')
	const [open, setOpen] = useState(false)

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const { typesFilterAwardProc, setValueTypeFilter, } = useAward()

	const { register, handleSubmit, control, reset } = useForm({
		defaultValues: {
			dateFilter: null,
			dateFilterTo: null,
			description: '',
			typeFilter: '',
		},
	})

	const resetFilter = () => {
		setValueTypeFilter({
			dateFilter: null,
			dateFilterTo: null,
			description: '',
			typeFilter: '',
		})

		setSelectedEvent(null)
		setStartDate(null)
		setEndDate(null)
		reset({
			description: '',
			dateFilter: null,
			dateFilterTo: null,
			typeFilter: '',
		})
	}

	const onSubmitFilter = (filter) => {
		return setValueTypeFilter(filter)
	}

	const handleStartDate = (event) => {
		setStartDate(event)
	}

	const handleSelectedEvent = (value, event) => {
		event(value.key)
		setSelectedEvent(value)
	}

	const handleEndDate = (event) => {
		setEndDate(event)
	}

	const validation = (currentDate) => {
		const today = moment()

		if (moment(endDate, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(endDate)) {
				return false
			}
		}

		return currentDate.isBefore(today)
	}

	const valid = (current) => {
		const today = moment()
		return current.isAfter(startDate) && current.isBefore(today)
	}

	const CustomPopper = function (props) {
		const classesPoper = useStyles();
		return <Popper {...props} className={classesPoper.root} placement="bottom" />;
	};

	const handleToggle = () => {
		setOpen(!open)
	}

	const focousOut = () => {
		if (!moment(endDate, 'DD/MM/YYYY').isValid()) {
			setEndDate('')
		}
		if (!valid(moment(endDate), 'DD/MM/YYYY')) {
			setEndDate('')
		}
	}

	const getBtnElColor = () => {
		if (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.elementsColor
		) {
			return themeColor.navigation.buttons.elementsColor
		}
		return `#fff`
	}

	const customSelectStyles = {
		control: (base, state) => ({
			...base,
			input: { height: '25px' },
			boxShadow: state.isFocused ? 0 : 0,
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
			'&:hover': {
				borderColor: state.isFocused ? grayColor[2] : base.borderColor,
			},
		}),
	}

	const useStyles = makeStyles(() =>
		createStyles({
			root: {
				"& .MuiAutocomplete-listbox": {
					"& :hover": {
						color: "#ffffff",
						backgroundColor: themeColor &&
							themeColor.structure &&
							themeColor.structure.backgroundColor
							? themeColor.structure.backgroundColor + ' !important'
							: `#fff`
					},
					"& li": {
						backgroundColor: "#ffffff",
					}
				}
			},
		})
	);

	return (
		<S.FilterForm
			themeColor={getBtnElColor()}
			onSubmit={handleSubmit(onSubmitFilter)}
			autoComplete="off"
			getBGCol={
				themeColor &&
					themeColor.structure &&
					themeColor.structure.backgroundColor
					? themeColor.structure.backgroundColor + ' !important'
					: `#fff`
			}
			getTextCol={
				themeColor &&
					themeColor.titles &&
					themeColor.titles.highlight &&
					themeColor.titles.highlight.textColor
					? themeColor.titles.highlight.textColor + ' !important'
					: `#fff`
			}
		>
			<S.InpusContain>
				<S.DateTimeContain>
					<S.DateInput>
						<CustomDateNew
							value={startDate}
							label={t('DATETIME_FROM')}
							locale="pt-br"
							maxDate={new Date().getTime()}
							isValidDate={validation}
							onChange={(e) => handleStartDate(e._d)}
							timeFormat={false}
						/>
					</S.DateInput>

					<S.DateInput>
						<CustomDateNew
							value={endDate}
							locale={'pt-br'}
							label={t('DATETIME_TO')}
							maxDate={new Date().getTime()}
							timeFormat={false}
							isValidDate={valid}
							onChange={(e) => handleEndDate(e._d)}
							onBlur={focousOut}
						/>
					</S.DateInput>
				</S.DateTimeContain>
				<S.InputDescription>
					<CssTextField
						style={{
							backgroundColor: '#fff',
						}}
						name="description"
						inputRef={register}
						fullWidth
						label={t('FIELD_DESCRIPTION')}
						variant="outlined"
						margin="dense"
						className="text-field"
					/>
				</S.InputDescription>

				{open && (
					<S.InputEvent>
						<Controller
							onChange={([, data]) => data}
							name="typeFilter"
							control={control}
							render={({ onChange }) => (
								<Autocomplete
									styles={customSelectStyles}
									id="combo-box-demo"
									options={typesFilterAwardProc}
									getOptionLabel={(option) => option.label}
									PopperComponent={CustomPopper}
									disableClearable={true}
									value={selectedEvent}
									renderInput={(params) => (
										<div ref={params.InputProps.ref}>
											<TextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() =>
													t('RESULTS_NOT_FOUND')
												}
												label={t('EVENT')}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
									onChange={(e, data) => handleSelectedEvent(data, onChange)}
								/>
							)}
						/>
					</S.InputEvent>
				)}
			</S.InpusContain>
			<S.BntFilterContain>
				<S.BntMoreFilter
					onClick={handleToggle}
					getBtnElColor={getBtnElColor()}
				>
					<IconButton
						className="iconButton"
						title="Pesquisa avançada"
					>
						{!open ? <Add /> : <Remove />}
					</IconButton>
					<span
						style={{
							fontSize: '15px',
						}}
					>
						{!open
							? t('SHOW_MORE_FILTERS')
							: t('HIDE_MORE_FILTERS')}
					</span>
				</S.BntMoreFilter>
				<S.BntFilter>
					<Button
						size="sm"
						variant="contained"
						color="greenBorderButtonColor"
						{...{ reset }}
						onClick={resetFilter}
					>
						{t('BUTTON_CLEAN_FILTER')}
					</Button>
					<Button
						size="sm"
						variant="contained"
						color="greenButtonColor"
						type="submit"
					>
						{t('BUTTON_FIND')}
					</Button>
				</S.BntFilter>
			</S.BntFilterContain>
		</S.FilterForm>
	)
}
export default SearchForm
