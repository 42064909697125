import * as actionTypes from '../actions/actionsTypes'
import { updateObject } from '../utility'

const initialState = {
    lsInvoiceData: [],
    invoiceDataFailed: false,
    invoiceData: [],
    invoiceByIdDataFailed: false,
    lsInvoicesDetailPartner: [],
    invoicesDetailPartnerFailed: false,
    lsSalesHistory: [],
    salesHistoryFailed: false,
}

const fetchInvoicesData = (state, action) => {
    return updateObject(state, {
        lsInvoiceData: action.lsInvoiceData
    });
};
const fetchInvoiceById = (state, action) => {
    return updateObject(state, {
        invoiceData: action.invoiceData
    });
};
const fetchSalesHistory = (state, action) => {
    return updateObject(state, {
        lsSalesHistory: action.lsSalesHistory
    });
}
const fetchInvoicesDetailPartner = (state, action) => {
    return updateObject(state, {
        lsInvoicesDetailPartner: action.lsInvoicesDetailPartner
    });
}

// DATA FAIL
const fetchInvoicesDataError = (state, action) => {
    return updateObject(state, {
        invoiceDataFailed: true
    });
};
const fetchInvoiceByIdFailed = (state, action) => {
    return updateObject(state, {
        invoiceDataFailed: true
    });
};
const fetchSalesHistoryFailed = (state, action) => {
    return updateObject(state, {
        salesHistoryFailed: true
    });
}
const fetchInvoicesDetailPartnerFailed = (state, action) => {
    return updateObject(state, {
        invoicesDetailPartnerFailed: true
    });
}


export const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_INVOICES_DATA:
            return fetchInvoicesData(state, action);
        case actionTypes.FETCH_INVOICES_FAILED:
            return fetchInvoicesDataError(state, action);

        case actionTypes.FETCH_INVOICE_BY_ID:
            return fetchInvoiceById(state, action);
        case actionTypes.FETCH_INVOICE_BY_ID_FAILED:
            return fetchInvoiceByIdFailed(state, action);

        case actionTypes.FETCH_SALES_HISTORY:
            return fetchSalesHistory(state, action);
        case actionTypes.FETCH_SALES_HISTORY_FAILED:
            return fetchSalesHistoryFailed(state, action);

        case actionTypes.FETCH_INVOICES_DETAIL_PARTHER:
            return fetchInvoicesDetailPartner(state, action);
        case actionTypes.FETCH_INVOICES_DETAIL_PARTHER_FAILED:
            return fetchInvoicesDetailPartnerFailed(state, action);
        default:
            return state;
    }
}

export default invoiceReducer;