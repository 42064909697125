import { Box, Checkbox as MuiChecBbox } from '@material-ui/core'
import { Stack } from '@mui/material'

import React from 'react'
import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

export default function Checkbox({ label, pt, ...props }) {
	const getThemeColorStructureBackground = () => {
		if (getThemeColorFromLocalStorage()) {
			if (getThemeColorFromLocalStorage().structure) {
				if (getThemeColorFromLocalStorage().structure.backgroundColor) {
					return getThemeColorFromLocalStorage().structure.backgroundColor
				}
			}
		}
		return '#08993A'
	}

	return (
		<Stack
			direction="row"
			justifyContent="flex-start"
			alignItems="center"
			spacing={2}
			pt={pt ?? 5}
			sx={{
				'.MuiCheckbox-colorSecondary.Mui-checked': {
					color: `${getThemeColorStructureBackground()} !important`,
				},
			}}
		>
			<MuiChecBbox {...props} />
			{label && <Box style={{ marginLeft: 0 }}>{label}</Box>}
		</Stack>
	)
}
