import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel } from '@mui/material'
import GenerationPointsInfo from '../GenerationPointsInfo'

const GenerationPoints = ({ parameterization, typeOfPoints, onOpen, calculationMemory }) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<FormLabel sx={{ width: '100%' }}>
				<span className="FormLabel">
					{typeOfPoints === '2'
						? t('Valores baseados na taxa por pontos fixos')
						: t('Valores baseados na taxa percentual')}
				</span>
				<GenerationPointsInfo parameterization={parameterization} calculationMemory={calculationMemory?.parameterization} onOpen={onOpen} />
			</FormLabel>
		</S.Container>
	)
}

GenerationPoints.propTypes = {
	parameterization: PropTypes.array,
	typeOfPoints: PropTypes.string,
	onOpen: PropTypes.func,
}

export default GenerationPoints
