import Cookies from 'js-cookie'
import { UpdateGrouperParametrization } from '../../../../../../../../../../redux/api/Grouper'

export const onConfirm = async ({ productData, setSnackStatus, setLoading, grouper }) => {
	setLoading(true)
	const partnerId = Cookies.get('partnerId')

	const obj = {
		partnerId: partnerId || '',
		typeOfGrouper: grouper || '',
		pointsValue: productData?.pointsValue || 0,
		generatePoints: productData?.generatePoints,
		typeOfBenefitPoints: productData?.typeOfBenefitPoints?.key,
		typeOfPoints: productData?.typeOfPoints?.key,
		redemptionPoints: productData?.redemptionPoints || 0,
		typeOfBenefitRedeem: '0',
		typeOfRedeem: '0',
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: productData?.typeOfCashback?.key,
		cashbackPercentual: productData?.cashbackPercentual || 0,
	}

	try {
		const res = await UpdateGrouperParametrization({ id: productData?.id, obj })
		console.log('res', res)
		if (res?.status === 200 && res?.data?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})
			return
		}

		if (res?.status === 200 && !res?.data?.success) {
			setSnackStatus({
				open: true,
				text: res?.data?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		console.error('Erro ao realizar a operação:', error)
		setSnackStatus({
			open: true,
			text: 'Ocorreu um erro ao realizar a operação!',
			severity: 'error',
		})
	} finally {
		setLoading(false)
	}
}
