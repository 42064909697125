import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import { Tooltip } from '@mui/material'

const DivisionOptions = ({
	multiple,
	editMode,
	setMultiple,
	setEditMode,
	setSelectedDivision,
	setParameterization,
	selectedDivisionIdRed,
	setSelectedDivisionIdRed,
}) => {
	const { t } = useTranslation()

	return (
		<S.Container>
			<S.Content>
				{!editMode && (
					<Tooltip title={t('Editar')} placement="top" arrow>
						<Buttons className="softBlue" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
					</Tooltip>
				)}

				{editMode && (
					<>
						<Tooltip title={t('Parametrização Múltipla')} placement="top" arrow>
							<Buttons className="softBlue" Icon="icon-bot_parametrizar_multiplo" onClick={() => setMultiple(!multiple)} />
						</Tooltip>
						<Tooltip title={t('Configurar parametrização múltipla')} placement="top" arrow>
							<Buttons
								className="blueAction"
								Icon="icon-bot_parametrizar"
								disabled={!multiple || selectedDivisionIdRed?.length === 0}
								onClick={() =>
									setParameterization(prev => ({
										...prev,
										show: true,
										isMultiple: true,
									}))
								}
							/>
						</Tooltip>

						<Tooltip Tooltip title={t('Cancelar')} placement="top" arrow>
							<Buttons
								className="cancel"
								Icon="icon-bot_fechar"
								onClick={() => {
									setParameterization(prev => ({
										...prev,
										show: false,
										isMultiple: false,
									}))

									multiple && setMultiple(!multiple)
									setSelectedDivisionIdRed([])
									setSelectedDivision([])
									setEditMode(false)
								}}
							/>
						</Tooltip>
					</>
				)}
			</S.Content>
		</S.Container>
	)
}

DivisionOptions.propTypes = {
	multiple: PropTypes.bool,
	setMultiple: PropTypes.func,
	setSelectedDivision: PropTypes.func,
	setParameterization: PropTypes.func,
	setIsMultiple: PropTypes.func,
	editMode: PropTypes.bool,
	setSelectedDivisionIdRed: PropTypes.func,
	setEditMode: PropTypes.func,
	selectedDivisionIdRed: PropTypes.array,
}

export default DivisionOptions
