/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import { FormLabel, Grid, InputAdornment } from '@mui/material'
import { CssTextField } from '../../../Filter/styles'
import { Symbol } from '../../Functions'
import GenerationPoints from './GenerationPoints'
import { Controller } from 'react-hook-form'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom.jsx'
import { newValue } from '../../Functions/utils'

const Configuration = ({ typeOfPoints, productData, parameterizationGenerationPoints, fetchCalculation, control, editMode, onOpen }) => {
	const { t } = useTranslation()

	const adornmentPosition = typeOfPoints === '2' ? 'start' : 'end'

	return (
		<S.CardsContent>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Valor*')}</span>
						<Controller
							name="pointsValue"
							control={control}
							render={({ value, onChange }) => (
								<CssTextField
									variant="outlined"
									size="small"
									value={typeOfPoints === '3' ? newValue(value, 100) : value}
									disabled={!editMode}
									onChange={e => {
										fetchCalculation(e.target.value)
										onChange(e.target.value)
									}}
									InputProps={{
										[adornmentPosition + 'Adornment']: (
											<InputAdornment position={adornmentPosition}>
												<S.CustomSymbol>{Symbol(typeOfPoints)}</S.CustomSymbol>
											</InputAdornment>
										),
										inputComponent: DoubleFormatCustom,
										maxLength: 8,
										max_val: 100,
									}}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				{/* <Grid item xs={12} sm={9}>
					<GenerationPoints
						onOpen={onOpen}
						parameterization={parameterizationGenerationPoints}
						typeOfPoints={typeOfPoints}
						productData={productData}
					/>
				</Grid> */}
			</Grid>
		</S.CardsContent>
	)
}

Configuration.propTypes = {
	typeOfPoints: PropTypes.string,
	productData: PropTypes.object,
	parameterizationGenerationPoints: PropTypes.array,
	fetchCalculation: PropTypes.func,
	control: PropTypes.object,
	editMode: PropTypes.bool,
	points: PropTypes.array,
	onOpen: PropTypes.func,
}

Configuration.defaultProps = {
	typeOfPoints: '1',
}

export default Configuration
