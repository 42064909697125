import styled from 'styled-components'
import { getThemeColorFromLocalStorage, ColorLuminance } from 'redux/api/themeColors'
import { Button } from '@mui/material'

export const Container = styled.div``

export const SnackContainer = styled.div``

export const CustomCard = styled.form`
	.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
		border-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
	}
`

export const Titles = styled.div`
	color: ${getThemeColorFromLocalStorage()?.titles?.main?.textColor};
	font-size: 16px;
`

export const History = styled.div`
	margin-top: 15px;
	margin-bottom: 15px;
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	.icon-menu_relatorios {
	}
	a {
		color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
		font-size: 24px;
		:hover {
			color: ${ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5)};
		}
	}
`

export const Buttons = styled(Button)`
	height: 40px !important;
	width: 40px !important;
	min-width: 40px !important;
	border: 2px solid #fff !important;
	background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor} !important;
	font-size: 24px !important;
	display: flex !important;
	color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.textColor} !important;
	align-items: center !important;
	justify-content: center !important;
	border-radius: 6px !important;

	:hover {
		background-color: ${ColorLuminance(getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor, -0.5)} !important;
	}
`
