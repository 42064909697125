import _ from 'lodash'
import { GetTypeOfPoints } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import { UpdateGrouperParametrization } from '../../../../../../../../../../redux/api/Grouper'
import Cookies from 'js-cookie'
import { CalculateParametrizationValue } from '../../../../../../../../../../redux/api/product'
import { envs } from '../../../../../../../../../../redux/api/windowServerData'

export const GetTypePoints = async ({ setGenerationType, setCircularProgress }) => {
	setCircularProgress(true)

	try {
		const res = await GetTypeOfPoints()
		if (res && Array.isArray(res)) {
			const newRes =
				res
					?.map(item => {
						if (item.key === '2') {
							return null
						}
						if (item?.value === 'No') {
							return {
								...item,
								value: 'Não gerar pontos',
							}
						}
						return item
					})
					?.filter(item => item !== null) || []
			setGenerationType(newRes)
		}
	} catch (error) {
		console.error(error)
	} finally {
		setCircularProgress(false)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const FormatMoney = value => {
	// const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', symbol)
}

export const handleProductDataUpdate = ({ productData, setParameterization, reset }) => {
	if (!_.isEmpty(productData)) {
		const { typeOfPoints, parameterizationGenerationPoints, pointsValue } = productData

		reset({
			typeOfPoints: typeOfPoints || {
				key: '0',
				value: 'No',
			},
			pointsValue: pointsValue,
		})
		setParameterization(parameterizationGenerationPoints)
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async (productId, price, type, value, typeOfRedeem, redeemValue) => {
	const partnerId = Cookies.get('partnerId')
	try {
		const res = await CalculateParametrizationValue(partnerId, price, type, value, typeOfRedeem, redeemValue)
		if (res?.success && res?.data) {
			const { generationPoints } = res.data
			return generationPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationProduct = async ({
	grouper,
	productData,
	typeOfPoints,
	pointsValue,
	setSnackStatus,
	typeOfBenefitPoints,
	setEditMode,
}) => {
	const partnerId = Cookies.get('partnerId')

	const obj = {
		partnerId: partnerId || '',
		typeOfGrouper: grouper || '',
		typeOfBenefit: typeOfPoints?.key || '',
		typeOfBenefitPoints: typeOfBenefitPoints?.key || '',
		typeOfPoints: typeOfPoints?.key || '0',
		pointsValue: typeOfPoints?.key !== '0' && typeOfPoints?.key !== '1' ? pointsValue : '0',
		typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key || '',
		typeOfRedeem: productData?.typeOfRedeem?.key || '',
		redemptionPoints: productData?.redemptionPoints || '',
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key || '',
		typeOfCashback: productData?.typeOfCashback?.key || '',
		cashbackPercentual: productData?.cashbackPercentual || '0',
	}

	try {
		const res = await UpdateGrouperParametrization({ id: productData?.id, obj })

		if (res?.data?.success && res?.status === 200) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})
			// setEditMode(false)
			return
		}

		if (res?.data?.errors?.length > 0) {
			setSnackStatus({
				open: true,
				text: res?.data?.errors[0]?.message || 'Erro ao realizar operação!',
				severity: 'error',
			})
		}
	} catch (error) {
		setSnackStatus({
			open: true,
			text: 'Erro ao realizar operação!',
			severity: 'error',
		})
	}
}
