import React from 'react'
import Modal from '@material-ui/core/Modal'
import * as Styles from './style'
import Button from 'components/CustomButtons/Button.jsx'

export const ModalPending = ({
	openModal,
	setOpenModal,
	typeModal,
	handleRequest,
	valuesStatus,
}) => {
	const handleClose = () => {
		if (typeModal == false) setOpenModal(false)
	}

	const handleAction = (action) => {
		if (action === 'no') {
			setOpenModal(false)
		} else {
			handleRequest(valuesStatus.id, valuesStatus.status)
			setOpenModal(false)
		}
	}

	return (
		<Modal
			open={openModal}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<Styles.BoxPaused>
				<i className="icon-alerta_modal" />
				{typeModal ? (
					<p style={{ fontWeight: '400', fontSize: '20px' }}>
						Esse lote será cancelado. Deseja realmente continuar?
					</p>
				) : (
					<p>Premiação pausada</p>
				)}
				{typeModal && (
					<Styles.bnts>
						<Button
							color="greenButtonColor"
							size="sm"
							onClick={() => handleAction('no')}
							style={{ width: '90px' }}
						>
							NÃO
						</Button>
						<Button
							color="greenBorderButtonColor"
							style={{ width: '90px' }}
							size="sm"
							onClick={() => handleAction('yes')}
						>
							SIM
						</Button>
					</Styles.bnts>
				)}
			</Styles.BoxPaused>
		</Modal>
	)
}
