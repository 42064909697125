import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import GridItem from 'components/Grid/GridItem.jsx'
import { useForm, Controller } from 'react-hook-form'
import Autocomplete from '@mui/material/Autocomplete'
import { handleProductDataUpdate } from './Mocks'
import { CircularProgress } from '@mui/material'

const Filters = ({
	typeOfBenefit,
	setGeneralTab,
	setByDivisionTab,
	setNoDivisionTab,
	editMode,
	circularProgress,
	productData,
	setTypeOfBenefitCashback,
}) => {
	const { t } = useTranslation()
	const { control, watch, setValue, getValues } = useForm({
		defaultValues: {
			typeOfBenefitCashback: {
				key: '',
				value: '',
			},
		},
	})

	const [general, setGeneral] = useState(false)
	const [byDivision, setByDivision] = useState(false)
	const [noDivision, setNoDivision] = useState(false)

	const [typeOfBenefitPointsValue, setTypeOfBenefitPointsValue] = useState([
		{
			key: '',
			value: '',
		},
	])

	useEffect(() => {
		handleProductDataUpdate(productData, setValue)
	}, [productData])

	useEffect(() => {
		typeOfBenefit?.length > 0 && setTypeOfBenefitPointsValue(typeOfBenefit)
		setByDivisionTab(byDivision)
		setGeneralTab(general)
		setNoDivisionTab(noDivision)
		setTypeOfBenefitCashback(getValues().typeOfBenefitCashback)
	}, [typeOfBenefit, byDivision, general, noDivision])

	const selectedBenefit = watch()

	useEffect(() => {
		if (selectedBenefit?.typeOfBenefitCashback?.value === 'General') {
			setGeneral(true)
			setByDivision(false)
			setNoDivision(false)
		} else if (selectedBenefit?.typeOfBenefitCashback?.value === 'ByDivision') {
			setGeneral(false)
			setByDivision(true)
			setNoDivision(false)
		} else {
			setGeneral(false)
			setByDivision(false)
			setNoDivision(true)
		}
	}, [selectedBenefit])

	return (
		<S.Container>
			<S.Text>Comportamento do Benefício</S.Text>
			<GridItem xs={12} sm={12} md={12} className="GridItem">
				<Controller
					name="typeOfBenefitCashback"
					control={control}
					render={({ value, onChange }) => (
						<Autocomplete
							id="combo-box-demo"
							options={typeOfBenefitPointsValue}
							getOptionLabel={option => (option.value ? t(option.value) : '')}
							disabled={!editMode || circularProgress}
							value={value}
							onChange={(_event, newValue) => onChange(newValue)}
							renderInput={params => (
								<S.CssTextField
									{...params}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<React.Fragment>
												{circularProgress && <CircularProgress color="inherit" size={20} />}
												{params.InputProps.endAdornment}
											</React.Fragment>
										),
									}}
									variant="outlined"
									size="small"
									disabled={!editMode}
								/>
							)}
						/>
					)}
				/>
			</GridItem>
		</S.Container>
	)
}

Filters.propTypes = {
	typeOfBenefit: PropTypes.array,
	setGeneralTab: PropTypes.func,
	setByDivisionTab: PropTypes.func,
	setNoDivisionTab: PropTypes.func,
	editMode: PropTypes.bool,
	circularProgress: PropTypes.bool,
	productData: PropTypes.object,
	setTypeOfBenefitCashback: PropTypes.func,
}

export default Filters
