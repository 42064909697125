import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const FilialCount = styled.div`
	position: relative;
	bottom: 37px;
	left: 10px;
	font-size: 13px;
`

export const UnderLineTable = styled.div`
	position: relative;
	bottom: 20px;
	left: 1px;
	width: 100%;
	color: rgba(0, 0, 0, 0.54);
	border-bottom-style: solid;
	border-width: 1px;
`

export const PaperContainer = styled(Paper)`
	margin-bottom: 40px;
	position: relative;
	/* padding: 20px 20px 20px 35px; */
	max-width: ${({ maxwidth }) => maxwidth};
	width: 40vw;
	padding: ${({ padding }) => padding};

	&.MuiPaper-elevation1 {
		box-shadow: ${({ shadow }) => shadow};
	}
	.sub-info {
		color: #48484c;
		font-size: 0.9rem;
		font-weight: bold;
	}

	.MuiPaper-root {
		width: 99.5%;
		padding-bottom: 20px !important;
	}

	.info-geral {
		color: #31394d;
		font-weight: bold;
		font-size: 1.3rem;
		> div {
			margin-right: 5px;
		}
	}

	.blue {
		color: #3f51b5;
	}

	.close-button {
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: none;
		color: #ffffff;
		&:hover {
			opacity: 0.7;
		}
		svg {
			margin-right: 3px;
		}
	}

	.sub-total {
		color: #31394d;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
	}

	.sub-price {
		color: #49d489;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 18px;
	}
	.button-box {
		position: absolute;
		margin-top: -28px;
		top: 0;
		margin-right: -28px;
		right: 0;
		.MuiIconButton-label {
			background-color: #3f51b5;
			border-radius: 50%;
			.MuiIcon-colorPrimary {
				color: #fff;
				font-size: 2rem;
			}
		}
	}
`

export const Container = styled.div`
	width: 100%;
	.makeStyles-tableTitle-313 span,
	.makeStyles-tableTitle-351 span {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.backgroundColor} !important;
	}
	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}

	table tr:nth-child(even) {
		background-color: #ffffff;
	}
`
