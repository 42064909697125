import styled from 'styled-components'
import TextField from '@mui/material/TextField';
import { getThemeColorFromLocalStorage } from '../../../../../../../../redux/api/themeColors'

export const Container = styled.div`
    max-width: 310px;
	.GridItem{
        margin-left: -15px;
	}
`

export const Text = styled.div`
    margin-top: 25px;
    font-size: 14px;
`

export const CssTextField = styled(TextField)({
    backgroundColor: '#ffffff',
    width: '100%',
    marginTop: "3px",
    '& label.Mui-focused': {
        color: getThemeColorFromLocalStorage()?.headers?.backgroundColor,
    },
    '& label': {
        lineHeight: 1.2,
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons
            ?.backgroundColor,
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
                ?.backgroundColor,
        },
        '&.Mui-focused fieldset': {
            borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
                ?.backgroundColor,
        },
    },
})