import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import CustomModal from '../../../Modal'
import { UpdateProduct } from '../../../../../../../../redux/api/product'
import SnackDefault from '../../../../../../../../components/SnackDefault'
import Loader from 'components/Loader/Loader.jsx'

const NoDivisionTab = ({ setEditMode, editMode, productData, typeOfBenefitPoints }) => {
	const [loading, setLoading] = useState(false)
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	const ParameterizationProduct = async (productData, typeOfBenefitPoints) => {
		const obj = {
			id: productData?.id,
			price: productData?.price,
			name: productData?.name,
			generatePoints: false,
			typeOfBenefitPoints: typeOfBenefitPoints?.key,
			typeOfPoints: '0',
			pointsValue: '0',
			typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key,
			typeOfRedeem: productData?.typeOfRedeem?.key,
			redemptionPoints: productData?.redemptionPoints?.key ?? productData?.redemptionPoints,
			typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
			typeOfCashback: productData?.typeOfCashback?.key,
			cashbackPercentual: productData?.cashbackPercentual,
		}

		setLoading(true)

		try {
			const res = await UpdateProduct(obj?.id, obj)

			if (res?.success) {
				console.log('res', res)
				setSnackStatus({
					open: true,
					text: 'Operação realizada com sucesso!',
					type: 'success',
				})
				setEditMode(false)
				return
			}

			if (!res?.success) {
				setSnackStatus({
					open: true,
					message: res?.data?.errors[0]?.message,
					type: 'error',
				})
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<Fragment>
			{loading && <Loader />}
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					ParameterizationProduct(productData, typeOfBenefitPoints)
					setModalParameterization({ open: false })
				}}
			/>

			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<S.Container>
				{editMode === true ? (
					<S.Content>
						<Buttons className="cancel" Icon="icon-bot_fechar" onClick={() => setEditMode(false)} />
						<Buttons
							className="save"
							onClick={() =>
								setModalParameterization({
									open: true,
									title: 'Deseja realmente alterar a parametrização',
									subtitle: 'desse benefício?',
								})
							}
							Icon="icon-bot_salvar"
						/>
					</S.Content>
				) : (
					<S.Content>
						<Buttons className="softBlueAction" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
					</S.Content>
				)}
			</S.Container>
		</Fragment>
	)
}

NoDivisionTab.propTypes = {
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func,
	productData: PropTypes.object,
	setOpenModalInfo: PropTypes.func,
	typeOfBenefitPoints: PropTypes.array,
}

export default NoDivisionTab
