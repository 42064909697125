export default function getDivisionOptions(data) {
	const result = []

	if (data && data.length > 0) {
		data.forEach(item => {
			result.push({
				description: item?.name ?? item?.description,
				segType: item?.segmentationType?.value,
				value: item?.id,
			})
		})
		return result ?? []
	}

	return []
}

export function getProductOptions(data) {
	const result = []

	if (data && data.length > 0) {
		data.forEach(item => {
			result.push({
				key: item?.id,
				label: `${item?.sku} - ${item?.name ?? item?.description}`,
				value: item?.sku,
			})
		})
		return result ?? []
	}

	return []
}
