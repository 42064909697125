import styled from 'styled-components'

import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.successIcon {
		color: #8fc992 !important;
	}
`

export const TabPanel = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	.MuiAutocomplete-popperDisablePortal {
		li:hover {
			color: #ffffff !important;
			background-color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().headers &&
		getThemeColorFromLocalStorage().headers.backgroundColor
		? getThemeColorFromLocalStorage().headers.backgroundColor
		: '#fff !important'};
		}
	}
`

export const CustomCard = styled.div`
	background: transparent;
	padding: 0px;
	margin-top: 16.5px;
	border-radius: 10px;

	.MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-focused {
		color: #ffffff !important;
		background-color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor} !important;
	}

	.MuiCircularProgress-root {
		color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor} !important;
	}
`

export const Filter = styled.div`
	margin-top: 25px;
`

export const Buttons = styled.div`
	display: flex;
	align-items: center;
	min-height: 40px;
	justify-content: end;
	gap: 10px;
	padding: 20px 5px;
	padding-top: 0px;
	margin-top: 25px;
	flex-wrap: wrap;

	@media (max-width: 382px) {
		.button-wrapper {
			width: 100%;
		}

		padding: 0px;
	}
`

export const Table = styled.div`
	margin-top: 25px;

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				font-size: 15px;
				font-weight: bold;
				z-index: 0 !important;

				:first-child {
					border-top-left-radius: 6px;
					width: 100px !important;
					justify-content: flex-start;
					text-align: left !important;
					padding-left: 25px !important;
				}

				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
					justify-content: flex-end;
					padding-left: 45px;
					span {
						svg {
							display: none;
						}
					}
				}
				:nth-child(2) {
					text-align: left !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 45% !important;
				}
				:nth-child(4) {
					text-align: left !important;
					width: 15% !important;
				}
				:nth-child(5) {
					text-align: left !important;
					width: 20% !important;
				}
			}
		}
	}

	.MuiTableBody-root {
		.MuiTableCell-alignLeft {
			text-align: left;
			:last-child {
				text-align: right;
			}
		}
		tr {
			color: #717171;
			font-size: 15px;
			height: 45px;

			p {
				font-size: 13px;
				font-style: italic;
				margin: 0px;
				line-height: 25px !important;
			}

			td {
				padding: 0px 15px !important;
				:first-child {
					width: 1% !important;
					justify-content: flex-start;
					text-align: left !important;
				}
				:last-child {
					justify-content: flex-end;
				}
				:nth-child(2) {
					text-align: left !important;
					width: 10% !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 45% !important;
				}
				:nth-child(4) {
					text-align: left !important;
					width: 15% !important;
				}
				:nth-child(5) {
					text-align: left !important;
					width: 20% !important;
				}
				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
				}
			}
		}
	}
`

export const DateRow = styled.div`
	text-align: left;
	font: normal normal normal Segoe UI;
	font-size: 15px;
	line-height: 10px;
	letter-spacing: 0px;
	color: #717171;
	opacity: 1;
`

export const HourRow = styled.div`
	text-align: left;
	font: italic normal normal 12px Segoe UI;
	font-size: 12px;
	line-height: 8px;
	letter-spacing: 0px;
	color: #717171;
	opacity: 1;
`

export const CreatedAt = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`

export const EmptyTable = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: 3rem;
	color: #b4b4b4;
	text-align: center;

	i {
		font-size: 3em;
		cursor: pointer;
	}

	.icon-informacao:before {
		font-size: 1.5em;
	}

	span {
		font-weight: 900;
		margin: 25px 0 12px;
	}

	.MuiTablePagination-toolbar {
		padding-right: 42px;
	}
`

export const IconButtonCustom = styled.div`
	.MuiIconButton-root {
		color: ${getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().headers && getThemeColorFromLocalStorage().headers.backgroundColor
		? getThemeColorFromLocalStorage().headers.backgroundColor
		: '#fff !important'};
		border: 1px solid;
		padding: 4px;
		border-color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().headers &&
		getThemeColorFromLocalStorage().headers.backgroundColor
		? getThemeColorFromLocalStorage().headers.backgroundColor
		: '#fff !important'};
		margin-right: 5px;
		&:hover {
			background-color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().headers &&
		getThemeColorFromLocalStorage().headers.backgroundColor
		? getThemeColorFromLocalStorage().headers.backgroundColor
		: '#fff !important'};
		}
	}

	.MuiSvgIcon-root {
		&:hover {
			color: #ffff;
		}
	}

	span {
		color: ${getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().headers && getThemeColorFromLocalStorage().headers.backgroundColor
		? getThemeColorFromLocalStorage().headers.backgroundColor
		: '#fff !important'};
	}
`

export const IconStatus = styled.div`
	border-radius: 10px;
	height: 14px;
	width: 14px;
`

export const Selected = styled.span`
	color: #8fc992;
`

export const Icon = styled.span``

export const Description = styled.div`
	display: flex;
	justify-content: start !important;
	padding-left: 10px;
`

export const AlignIconStatus = styled.div`
	align-items: center;
	display: flex;
	padding-right: 15px;
`
