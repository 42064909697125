import React from 'react'
import DocumentTitle from 'react-document-title'
import pageInConstruction from 'assets/img/Ilustracao_Germinando.png'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'

// UTILS
import compose from 'utils/compose'

import { connect } from 'react-redux'

import { HideLoader } from 'redux/actions'
import { bindActionCreators } from 'redux'
import { getProgramTitle } from '../../utils/utils'

class Construction extends React.Component {
	componentDidMount() {
		this.props.HideLoader()
	}

	componentDidUpdate() {
		this.props.HideLoader()
	}

	render() {
		return (
			<Card style={{ marginTop: '0' }}>
				<DocumentTitle title={getProgramTitle()} />
				<CardBody>
					<GridContainer justifyContent="center">
						<img alt="Em construção" src={pageInConstruction} style={{ maxWidth: '100%' }} />
					</GridContainer>
					<GridContainer justifyContent="center">
						<label
							style={{
								fontSize: '28px',
								textAlign: 'center',
								width: '100%',
							}}
						>
							<strong>ESTAMOS GERMINANDO</strong>
							<br />
							<strong>ESTA PÁGINA PARA VOCÊ!</strong>
						</label>
					</GridContainer>
				</CardBody>
			</Card>
		)
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({ HideLoader }, dispatch)

export default compose(
	connect(
		null,
		mapDispatchToProps,
	),
)(Construction)
