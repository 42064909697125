import styled from 'styled-components'

const getHLCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor
	) {
		return props.themeColor.titles.highlight.textColor
	}
	return `#fff`
}

export const TitleStyled = styled.div`
	h6 {
		line-height: 100%;
		margin: 0;
		text-transform: unset;
	}

	h6:last-of-type {
		font-style: italic;
		font-size: 0.75rem;
		margin-top: 0.25rem;
	}
`

export const Title = styled.div`
	font-weight: bold;
	color: ${(props) => getHLCol(props)};
	span {
		color: red;
	}
`
export const Close = styled.button`
	align-items: center;
	display: flex;
	justify-content: center;
	border: none;
	border-radius: 5px;
	background-color: #F05B4F;
	cursor: pointer;
	color: #fff;
	margin-left: 0.938rem;
	width: 90px;
	height: 35px;
	i {
		margin-right: 5px;
		margin-top: 3px;
	}
`
export const Icon = styled.div`
	color: #f9bb86;
	display: flex;
	font-size: 79px;
	justify-content: center;
	margin: 18px 0;
	top: -10px;
`
export const TextStyle = styled.div`
	text-align: center;
	align-items: center;
	border-radius: 10px;
	background-color: #fff;
	display: flex;
	flex-direction: column;
	font-size: 80px;
	justify-content: center;
	min-height: 211px;
	padding: 30px;
	width: 600px;
	i {
		color: #f8bb86;
	}
	p {
		font-size: 22px;
		font-weight: bold;
		margin-top: 33px;
	}
`

export const Example = styled.div`
	text-align: left !important;
	background-color: #fff;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;
	img {
		width: 100%;
	}
`

export const Paragraph = styled.p`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 38px;
	margin-right: 38px;
	opacity: 0.9;
	color: #333333;
`

export const Model = styled.div`
	font: normal normal normal 16px/30px Roboto;
	letter-spacing: 0px;
	margin-left: 50px;
	padding-left: 32px;
	margin-right: 38px;
	opacity: 0.8;
	color: #333333;
`
export const Form = styled.form`
	.gfEDZo {
		width: 344px;
		height: 40px;
		background: #fff 0% 0% no-repeat padding-box;
		border: 1px solid #c4c4c4;
		opacity: 1;
	}
	.foVCqc {
		width: 344px;
	}
`
export const ContainForm = styled.form`
	width: 100%;
	.MuiGrid-spacing-xs-2 > .MuiGrid-item {
		padding: 0 !important;
	}
	.MuiGrid-spacing-xs-2 {
		margin: 0 !important;
	}
	.grid_liberar_sald {
		padding-left: 0px;
		padding-right: 0px;
	}
`
export const SectionBox = styled.div`
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin-bottom: 25px;
	width: 100%;
`
export const IconIcoDebitado = styled.i`
	color: #dc3f3f;
	font-size: 17px;
	margin-right: 5px;
	margin-top: 2px;
`
