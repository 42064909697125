import axios from 'axios'
import {envs}  from '../windowServerData'



export const GetActiveRoleClaims = () => {
	// return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/Account/getRoleClaims`)
	return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/Account/getRoleClaims`).then(response => response.data)
}

export const GetAccessGroups = (group, login, partnerId) => {
	let args = `partnerId=${partnerId}`
	if (group && group !== '') args = args + `&groupName=${group}`
	if (login && login !== '') args = args + `&userId=${login}`

	// const url = `${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getGroups?${args}`
	const url = `${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getGroups?${args}`
	return axios.get(url).then(response => response.data)
}

export const GetClaimsByGroupId = id => {
	// return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/GetClaimsPartnerByGroupId?id=${id}`).then(response => response.data)
	return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/GetClaimsPartnerByGroupId?id=${id}`).then(response => response.data)
}

export const GetGroupData = id => {
	// return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/GetGroupById?id=${id}`).then(response => response.data)
	return axios.get(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/GetGroupById?id=${id}`).then(response => response.data)
}

// POST api/

export const PostClaims = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/updateGroupClaims`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/updateGroupClaims`, obj).then(response => response.data)
}
export const PostAccess = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/Account/upSertRoleClaim`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/Account/upSertRoleClaim`, obj).then(response => response.data)
}

export const UpdateGroupData = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/UpdateGroupData`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/UpdateGroupData`, obj).then(response => response.data)
}
export const PostAssignToRole = obj => {
	// return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/Account/assignToRole`, obj).then(response => response.data)
	return axios.post(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/Account/assignToRole`, obj).then(response => response.data)
}

// PUT api/
export const UpdateAccess = (id, obj) => axios.put(`/---/${id}`, obj).then(response => response.data)
