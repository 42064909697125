/* eslint-disable import/first */
import { format, format as formatDateFNS, parseISO } from "date-fns";
export function formatCPF(cpf) {
    var _a;
    return (_a = cpf === null || cpf === void 0 ? void 0 : cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")) !== null && _a !== void 0 ? _a : "-";
}
export function formatCNPJ(cnpj) {
    var _a;
    return (_a = cnpj === null || cnpj === void 0 ? void 0 : cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")) !== null && _a !== void 0 ? _a : "-";
}
export function formatCellphone(phone) {
    if (!phone)
        return "-";
    var cleanedPhone = phone.replace(/\D/g, "");
    if (cleanedPhone.length === 10) {
        return cleanedPhone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    else if (cleanedPhone.length === 11) {
        return cleanedPhone.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    }
    else {
        return "-";
    }
}
export function formatDate(data) {
    var partesData = data === null || data === void 0 ? void 0 : data.split("-");
    if (partesData.length === 3) {
        return "".concat(partesData[2], "/").concat(partesData[1], "/").concat(partesData[0]);
    }
    else {
        return data !== null && data !== void 0 ? data : "-";
    }
}
export function formateDateBR(data) {
    var result = formatDateFNS(new Date(data), "dd/MM/yyyy");
    return result;
}
export function formatPhoneNumber(phone) {
    if (!phone)
        return "-";
    var cleanedPhone = phone.replace(/\D/g, "");
    if (cleanedPhone.length === 10) {
        return cleanedPhone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }
    else {
        return "-";
    }
}
export function formatCEP(cep) {
    if (!cep)
        return "-";
    var cleanedCEP = cep.replace(/\D/g, "");
    if (cleanedCEP.length === 8) {
        return cleanedCEP.replace(/(\d{5})(\d{3})/, "$1-$2");
    }
    else {
        return "-";
    }
}
export function formateCustomDate(data, format) {
    var result = formatDateFNS(new Date(data), format);
    return result;
}
export function formatUSDCurrency(value) {
    var currencyMask = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });
    if (Number(value) > 10) {
        return "SD$ ".concat(currencyMask.format(value).replace("$", ""));
    }
    else {
        return "SD$ ".concat(value);
    }
}
export function formatBRCurrency(value) {
    var currencyMask = new Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
    });
    return currencyMask.format(value);
}
export function clearMask(value) {
    if (value) {
        return value.replace(/[^\d]/g, "");
    }
    return null;
}
export function formatTime(date) {
    if (date) {
        var datePart = date === null || date === void 0 ? void 0 : date.split("+");
        if (datePart.length > 1) {
            var dataISO = datePart[0];
            var formatedDate = dataISO && format(parseISO(dataISO), "hh:mm");
            return formatedDate !== null && formatedDate !== void 0 ? formatedDate : "-";
        }
    }
    return "-";
}
export function formatRSPrice(value) {
    if (typeof value !== "number") {
        throw new TypeError("O valor deve ser um número.");
    }
    return value.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}
export function formatUSDPrice(value) {
    if (typeof value !== "number") {
        throw new TypeError("O valor deve ser um número.");
    }
    return value.toLocaleString("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
}
export function formatPoints(value, mask) {
    if (typeof value !== "number") {
        throw new TypeError("O valor deve ser um número.");
    }
    var isDecimal = !Number.isInteger(value);
    var options = isDecimal ? { minimumFractionDigits: 2, maximumFractionDigits: 2 } : { minimumFractionDigits: 0, maximumFractionDigits: 0 };
    var formattedValue = value.toLocaleString("en-US", options).replace(",", ".");
    return mask ? "".concat(mask, " ").concat(formattedValue) : formattedValue;
}
