import React, { useEffect, useState } from 'react'
import MaskedInput from 'react-text-mask'
import Datetime from 'react-datetime'
import * as S from './style'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { InputAdornment } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import moment from 'moment'

const DateMaskCustom = props => {
	const { inputRef, disabled, ...other } = props

	return (
		<MaskedInput
			{...other}
			disabled={disabled}
			readOnly={disabled}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
			placeholderChar={'\u2000'}
		/>
	)
}

function CustomDate({
	disabled = false,
	enableDates = false,
	label = '',
	helperText = '',
	marginLeft = '0px',
	error,
	register,
	className = '',
	value,
	...values
}) {
	const [themeColor, setThemeColor] = useState('')
	const [dateValid, setDateValid] = useState(true)

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const getColorIcon = () => {
		if (themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color) {
			return themeColor.visual.icons.color
		}

		return '#fff'
	}

	useEffect(() => {
		if (value) {
			setDateValid(moment(value, 'DD/MM/YYYY').isValid())
		}
	}, [value])

	return (
		<S.Container
			themeColor={
				themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color ? themeColor.visual.icons.color : `#fff`
			}
			getBGCol={themeColor && themeColor.structure && themeColor.structure.backgroundColor ? themeColor.structure.backgroundColor : `#fff`}
			id="date-picker-inline-container"
		>
			<Datetime
				{...values}
				value={value}
				locale="pt-br"
				disabled={disabled}
				timeFormat={false}
				className={className}
				renderInput={props => {
					return (
						<TextField
							{...props}
							label={label}
							variant="outlined"
							error={!dateValid}
							autoComplete="off"
							disabled={disabled}
							className={className}
							fullWidth
							name={values.name}
							helperText={!dateValid ? 'Data inválida' : ''}
							style={{
								backgroundColor: '#fff ',
							}}
							size="small"
							InputProps={{
								...props,
								inputComponent: DateMaskCustom,
								endAdornment: (
									<InputAdornment>
										<i
											className=" icon-calendario"
											style={{
												fontSize: '19px',
												color: disabled === true ? '#ccc' : getColorIcon(),
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
					)
				}}
			/>
		</S.Container>
	)
}

export default CustomDate
