import React, { useEffect, useState } from 'react'

// @Styles
import { Container } from './styles'

// @Assets & Config
import { history } from '../../../utils'
import { useTranslation } from 'react-i18next'
import logoVXP from '../../../assets/img/logo_EAWARE.png'
import { apiKernel, apiKernelPartner } from '../../../services/api'

// @ThemeColor
import { getThemeColorFromLocalStorage, getThemeColorFromRequest, setThemeColorToLocalStorage } from '../../../redux/api/themeColors'

// @Components
import Loader from 'components/Loader/Loader'
import TokenInput from '../../../components/TokenInput'
import Button from 'components/CustomButtons/Button.jsx'
import SnackDefault from '../../../components/SnackDefaultNew'
import CustomModalValidation from '../../../components/CustomModalValidation'

export default function ConfirmToken() {
	const { t } = useTranslation()
	const queryParams = new URLSearchParams(window.location.search)
	const token = queryParams.get('token')
	const login = queryParams.get('login')
	const partnerId = queryParams.get('partnerId')
	const identityId = queryParams.get('identityId')

	const [step, setStep] = useState(1)
	const [code, setCode] = useState('')
	const [phone, setPhone] = useState('')
	const [email, setEmail] = useState('')
	const [selected, setSelected] = useState()
	const [loading, setLoading] = useState(false)
	const [buttonDisabled, setButtonDisabled] = useState(false)
	const [themeColor, setThemeColor] = useState(getThemeColorFromLocalStorage())

	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: '',
		text: '',
	})

	const [openModal, setOpenModal] = useState({
		icon: 'icon-sessao_finalizada',
		open: false,
		subtitle: 'Seu acesso já foi validado.',
		title: '',
		buttonTitle: 'REALIZAR LOGIN',
	})

	const getTheme = async () => {
		setLoading(true)

		try {
			const response = await getThemeColorFromRequest()

			if (response?.data) {
				const { data } = response
				setThemeColor(data)
				setThemeColorToLocalStorage(data)
			}
		} catch (error) {
			console.error('Erro ao fazer parse do tema:', error)
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		if (!token || !login || !partnerId || !identityId) {
			history.push('/auth/login')
		} else {
			localStorage.clear()
			AcceptPDVEmail()
			getEmailPhone()
		}
	}, [])

	useEffect(() => {
		getTheme()
	}, [])

	const MaskEmail = () => {
		if (email) {
			return `${email.slice(0, 3)}****@***${email.slice(-6, -1)}${email.slice(-1)}`
		}
	}

	const MaskSMS = () => {
		if (phone) {
			return `(${phone.slice(0, 2)}) ${phone.slice(2, 5)}**-**${phone.slice(8, 10)}`
		}
	}

	const AcceptPDVEmail = async () => {
		const { data } = await apiKernel.post(`/Partner/Accept2FAEmail`, {
			token: token,
			login: login ? login.replaceAll('.', '').replaceAll('-', '') : '',
		})

		if (!data.success) {
			setSnackStatus({
				open: true,
				severity: 'error',
				text: 'Ocorreu um erro, entre em contato com o administrador.',
			})
		}
	}

	const getEmailPhone = async () => {
		const { data } = await apiKernelPartner.get(`/Partner/GetPartnerUserContactInfo?identityId=${identityId}&partnerId=${partnerId}`)

		if (data) {
			setPhone(data.data.phoneNumber)
			setEmail(data.data.email)
		}

		if (!data) {
			setSnackStatus({
				open: true,
				severity: 'error',
				text: 'Ocorreu um erro, entre em contato com o administrador.',
			})
		}
	}

	const handleChoseSelected = value => {
		if (value === 'email') {
			setSelected(1)
		}

		if (value === 'sms') {
			setSelected(2)
		}

		setStep(1)
	}

	const handleChangeComp = value => {
		handleChoseSelected(value)
		setStep(1)
	}

	const handleResent = () => {
		if (selected === 1) {
			handleSendEmail()
			setSelected()
		}

		if (selected === 2) {
			handleSendSMS()
			setSelected()
		}

		setStep(2)
	}

	const handleSendEmail = async () => {
		const { data } = await apiKernel.get(`/Partner/SendCodeToAuth?identityId=${identityId}&partnerId=${partnerId}&option=2`)

		if (data.success) {
			setSnackStatus({
				open: true,
				severity: 'success',
				text: 'E-mail enviado com sucesso',
			})
		}

		if (!data.success) {
			data &&
				data.errors &&
				data.errors.map(err => {
					setOpenModal({
						icon: 'icon-sessao_finalizada',
						open: true,
						subtitle: 'Seu acesso já foi validado.',
						title: '',
						buttonTitle: 'REALIZAR LOGIN',
					})
					if (err.message === 'identityId - PHONENUMBER_ALREADY_VALIDATED' || err.message === 'identityId - EMAIL_ALREADY_VALIDATED') {
						setTimeout(() => {
							history.replace('/')
						}, 10000)
					}
				})
		}
	}

	const handleSendSMS = async () => {
		const { data } = await apiKernel.get(`/Partner/SendCodeToAuth?identityId=${identityId}&partnerId=${partnerId}&option=1`)

		if (data.success) {
			setSnackStatus({
				open: true,
				severity: 'success',
				text: 'SMS enviado com sucesso',
			})
		}

		if (!data.success) {
			data.errors.map(err => {
				setOpenModal({
					icon: 'icon-sessao_finalizada',
					open: true,
					subtitle: 'Seu acesso já foi validado.',
					title: '',
					buttonTitle: 'REALIZAR LOGIN',
				})

				if (err.message === 'identityId - PHONENUMBER_ALREADY_VALIDATED' || err.message === 'identityId - EMAIL_ALREADY_VALIDATED') {
					setTimeout(() => {
						history.replace('/')
					}, 10000)
				}
			})
		}
	}

	const handleSave = async () => {
		if (code && code.length === 6) {
			const { data } = await apiKernel.get(`/Partner/ValidateCodeAuthentication?identityId=${identityId}&partnerId=${partnerId}&code=${code}`)

			if (data.success) {
				setSnackStatus({
					open: true,
					severity: 'success',
					text: 'Código enviado com sucesso',
				})
				setTimeout(() => {
					history.push(`/resetPassword`, {
						pathname: '/resetPassword',
						data: {
							email: email,
							token: data.data.token,
						},
					})
				}, 1000)
			}

			if (!data.success) {
				setSnackStatus({
					open: true,
					severity: 'error',
					text: 'Código invalido',
				})
			}
		}

		if (!code || code.length !== 6) {
			setSnackStatus({
				open: true,
				severity: 'error',
				text: 'Código invalido',
			})
		}
	}

	const handleSelect1 = () => {
		setSelected(1)
		setButtonDisabled(true)
	}

	const handleSelect2 = () => {
		setSelected(2)
		setButtonDisabled(true)
	}

	return loading ? (
		<Loader />
	) : (
		<Container themeColor={themeColor}>
			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>

			<form>
				<div className={'card cardShadow'}>
					<div className={`${'cardCenter'} ${'cardHeader'}`} onClick={() => setStep(1)}>
						<img src={themeColor?.images?.loginPage?.logo?.url} alt="logo" width={200} />
					</div>
					<div
						className={'cardHeaderTitle'}
						style={{
							backgroundColor: themeColor?.structure?.backgroundColor,
						}}
					>
						{'APLICAÇÃO DO PARCEIRO'}
					</div>
				</div>
				{step === 1 ? (
					<div>
						<div className="formLabels2">Selecione a forma que deseja receber o código de segurança:</div>
						<div className="formLabels3">
							<p className="text">Email: </p>
							<div className={selected === 1 && selected !== 2 ? 'textSelected' : 'textUnselected'} onClick={() => handleSelect1()}>
								{MaskEmail()}
							</div>
						</div>
						<div className="formLabels3">
							<p className="text">Celular (SMS): </p>
							<div className={selected === 2 && selected !== 1 ? 'textSelected' : 'textUnselected'} onClick={() => handleSelect2()}>
								{MaskSMS()}
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Button
								fullWidth
								style={{
									margin: '0 80px',
								}}
								disabled={!buttonDisabled}
								color="greenButtonColor"
								onClick={() => handleResent()}
							>
								{t('LOGIN_SEND')}
							</Button>
						</div>
					</div>
				) : (
					<div>
						<div className="formLabels">
							Utilize o código enviado para autenticação:
							<div style={{ margin: '30px 0 25px 0' }}>
								<TokenInput onChange={e => setCode(e)} />
							</div>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								marginBottom: '25px',
							}}
						>
							<p onClick={() => handleChangeComp('email')} className="textClick">
								Enviar por e-mail
							</p>
							<p onClick={() => handleChangeComp('sms')} className="textClick">
								Enviar por SMS
							</p>
						</div>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Button
								style={{
									margin: '0 80px',
								}}
								fullWidth
								color="greenButtonColor"
								onClick={() => handleSave()}
							>
								{t('LOGIN_SEND')}
							</Button>
						</div>
					</div>
				)}
			</form>

			<div className="logoVXP">
				<img src={logoVXP} alt="logoVXP" />
			</div>
			<CustomModalValidation
				buttonTitle={openModal.buttonTitle}
				icon={openModal.icon}
				onConfirm={() => {
					history.replace('/')
				}}
				open={openModal.open}
				title={openModal.title}
				subtitle={openModal.subtitle}
			/>
		</Container>
	)
}
