import React from 'react'
//import PerfectScrollbar from "perfect-scrollbar";
import 'perfect-scrollbar/css/perfect-scrollbar.css'
import * as _ from 'lodash'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { Switch, Route } from 'react-router-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
// import Skeleton from "@material-ui/lab/Skeleton";
import { Grid } from '@material-ui/core'

// core components
import AdminNavbar from 'components/Navbars/AdminNavbar.jsx'
//import Footer from "components/Footer/Footer.jsx";
import Sidebar from 'components/Sidebar/Sidebar.jsx'
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";
import Loader from 'components/Loader/Loader.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Banner from 'components/Banner'
import Footer from 'components/Footer'
import NotificationModal from 'components/NotificationModal/index.js'
import RenderDescription from 'components/NotificationModal/RenderDescription'
import { BodyRender, NotificationSkeleton } from 'components/NotificationModal/styles.js'

import { dashRoutes } from './routes.js'

// Utils
import compose from 'utils/compose'

import appStyle from 'assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx'
import logoF from 'assets/img/logo_Germini.png'
import logoP from 'assets/img/logo_Germini_Marca.png'

import { GetCurrentPartner, GetData, ShowLoader, HideLoader } from 'redux/actions'
import { GetPartnerImportantAlertsId, ReadAlert } from 'redux/api/alerts/alerts.js'
import { GetConnectionId } from 'redux/api/signalr.js'

import { RequireAuth } from '../../utils/RequireAuth.js'
import GeneralContext from '../../GeneralContext/index.js'
import { removeRoutes } from './Functions/index.jsx'

var ps
const AdminContext = React.createContext({
	mobileOpen: false,
	sidebarMini: false,
})

class Home extends React.Component {
	static contextType = GeneralContext
	constructor(props) {
		super(props)
		this.state = {
			mobileOpen: false,
			miniActive: false,
			image: null,
			color: 'green',
			bgColor: 'white',
			hasImage: false,
			logo: logoF,
			fixedClasses: 'dropdown',
			hasNotification: false,
			alerts: '',
			notifications: {
				total: 0,
				presentPage: 1,
				idList: [],
				data: {},
				loading: false,
			},
			readAllImportantAlerts: false,
			allowCashback: '',
			localDashRoutes: [],
		}
		this.resizeFunction = this.resizeFunction.bind(this)
	}

	async componentDidMount() {
		this.props.GetData()
		this.props.GetCurrentPartner()
		//this.props.ShowLoader(<Loader />)
		// VERIFY ROUTE PAGE TO HOME
		if (this.props.location.pathname === '/') {
			this.props.history.push(`/home`)
		}

		const getImportantAlerts = async () => {
			try {
				this.setState({
					notifications: {
						...this.state.notifications,
						loading: true,
					},
				})

				const response = await GetPartnerImportantAlertsId()
				if (response.length > 0) {
					const responseData = await ReadAlert(response[0])
					this.setState({
						hasNotification: true,
						notifications: {
							total: response.length,
							presentPage: 1,
							idList: response,
							data: responseData,
						},
					})
				}
				const connectId = await GetConnectionId()

				this.setState({
					alerts: connectId.filter(item => item.type.value === 'ALERT')[0],
				})
			} catch (error) {
				console.log('Dashboard -> getImportantAlerts -> error', error)
			} finally {
				this.setState({
					notifications: {
						...this.state.notifications,
						loading: false,
					},
				})
			}
		}

		await getImportantAlerts()
	}

	async changePage(page, readAllImportantAlerts) {
		try {
			this.setState({
				notifications: {
					...this.state.notifications,
					loading: true,
				},
			})
			const responseData = await ReadAlert(this.state.notifications.idList[page - 1], readAllImportantAlerts ? readAllImportantAlerts : '')
			this.setState({
				notifications: {
					...this.state.notifications,
					presentPage: page,
					data: responseData,
				},
			})
		} catch (error) {
			console.log('Dashboard -> changePage -> error', error)
		} finally {
			this.setState({
				notifications: {
					...this.state.notifications,
					loading: false,
				},
			})
		}
	}

	async componentDidUpdate(prevProps, prevState) {
		if (prevProps.userData !== this.props.userData) {
			if (this.props.userData === '' || !this.props.userData.id) {
				this.props.history.push('/auth/login')
			}
			this.setState({ userData: this.props.userData })
		}
		if (prevProps.generalRulesData !== this.props.generalRulesData) {
			this.setState({ generalRulesData: this.props.generalRulesData })
			localStorage.setItem('programName', this.props.generalRulesData.name)
			localStorage.setItem('currencySymbol', this.props.generalRulesData.currencySymbol)
			localStorage.setItem('programCurrencySymbol', this.props.generalRulesData.programCurrencySymbol)
		}

		if (prevProps.userDataFailed !== this.props.userDataFailed) {
			this.props.history.push('/auth/login')
			this.props.HideLoader()
		}
		if (prevProps.generalRulesDataFailed !== this.props.generalRulesDataFailed) {
			this.props.HideLoader()
		}
	}

	verifyRouteChange = (_path, _search) => {
		if (_path.indexOf('403') > -1 || _path.indexOf('404') > -1 || _path.indexOf('dashboard') > -1) {
			return false
		}
		//
		// NORMALIZING PATH
		let pathStr = _path.replace(/\//g, '.')
		pathStr = pathStr.substring(1, pathStr.length)
		const pathArr = pathStr.split('.')
		//
		// VERIFYING GUID EXISTENCE
		const isGuid = pathArr[pathArr.length - 1].split('-').length === 5 ? true : false
		if (isGuid) {
			// REMOVING GUID FROM UrlPath
			let pCopy = _.clone(pathArr)
			pCopy.splice(-1, 1)
			pathStr = pCopy.join('.')
		}
		//
		// VERIFY CLAIM
		let vClaim = pathStr.split('.')

		if (
			vClaim[vClaim.length - 1] !== 'put' &&
			vClaim[vClaim.length - 1] !== 'add' &&
			vClaim[vClaim.length - 1] !== 'new' &&
			vClaim[vClaim.length - 1] !== 'edit' &&
			vClaim[vClaim.length - 1] !== 'block'
		) {
			pathStr += '.get'
		}
		if (vClaim[vClaim.length - 1] === 'new') {
			pathStr = pathStr.replace('new', 'add')
		}
		//
		// VERIFYING PATH INTO ROUTES FILE
		let exist = false
		_.forEach(dashRoutes, (prop, key) => {
			if (prop.role) {
				if (prop.role === pathStr) {
					exist = true
				}
			}
			if (prop.roles) {
				if (_.includes(prop.roles, _.toLower(pathStr))) {
					exist = true
				}
			}
		})
		//
		if (!exist) {
			this.props.history.push('/404/')
			return false
		}
		//
		// VERIFY PATH INTO UserClaimGroups
		if (!_.includes(this.props.userClaimGroups, _.toLower(pathStr))) {
			this.props.history.push('/403/')
			return false
		} else {
			return false
		}
	}

	handleImageClick = image => {
		this.setState({ image: image })
	}

	handleColorClick = color => {
		this.setState({ color: color })
	}

	handleBgColorClick = bgColor => {
		this.setState({ bgColor: bgColor })
	}

	handleFixedClick = () => {
		if (this.state.fixedClasses === 'dropdown') {
			this.setState({ fixedClasses: 'dropdown show' })
		} else {
			this.setState({ fixedClasses: 'dropdown' })
		}
	}

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen })
	}

	getRoute() {
		return this.props.location.pathname !== '/full-screen-maps'
	}

	getActiveRoute = dashRoutes => {
		const { t } = this.props

		let activeRoute = ''
		for (let i = 0; i < dashRoutes.length; i++) {
			if (dashRoutes[i].collapse) {
				let collapseActiveRoute = this.getActiveRoute(dashRoutes[i].views)
				if (collapseActiveRoute !== activeRoute) {
					return collapseActiveRoute
				}
			} else {
				var path = dashRoutes[i].path.replace(':id', '')
				if (window.location.href.indexOf(dashRoutes[i].layout + path) !== -1) {
					return t(dashRoutes[i].name)
				}
			}
		}
		return activeRoute
	}

	getRoutes = dashRoutes => {
		for (let i = 0; i < dashRoutes?.length; i++) {
			const currentRoute = dashRoutes[i]
			const views = currentRoute?.views

			if (views?.length > 0) {
				for (let j = 0; j < views?.length; j++) {
					const currentView = views[j]

					if (currentView?.name === 'MENU_FINANCIAL_PAYMENT_OPTIONS') {
						currentView.sidebar = this.context?.activePay || false
					}
				}
			}
		}

		return (
			dashRoutes &&
			dashRoutes.map((prop, key) => {
				if (prop.collapse) {
					return this.getRoutes(prop.views)
				}
				if (prop.layout === '/admin') {
					const MyComponent = prop.component

					if (prop.requiresDigitalWallet) {
						return (
							<Route
								path={prop.path}
								key={key}
								render={props => (
									<RequireAuth>
										<MyComponent {...props} />
									</RequireAuth>
								)}
							/>
						)
					}

					return <Route path={prop.path} component={prop.component} key={key} />
				} else {
					return null
				}
			})
		)
	}

	sidebarMinimize() {
		this.setState({ miniActive: !this.state.miniActive })
		if (!this.state.miniActive) {
			this.setState({ logo: logoP })
		} else {
			this.setState({ logo: logoF })
		}
	}

	resizeFunction() {
		if (window.innerWidth >= 960) {
			this.setState({ mobileOpen: false })
		}
	}

	render() {
		const { classes, user, users, alert, loading, userData, generalRulesData, ...rest } = this.props
		const { hasNotification, notifications } = this.state
		const mainPanel =
			classes.mainPanel +
			' ' +
			cx({
				[classes.mainPanelSidebarMini]: this.state.miniActive,
				[classes.mainPanelWithPerfectScrollbar]: navigator.platform.indexOf('Win') > -1,
			})

		return (
			<AdminContext.Provider
				value={{
					mobileOpen: this.state.mobileOpen,
					sidebarMini: this.state.miniActive,
				}}
			>
				<AdminNavbar
					sidebarMinimize={this.sidebarMinimize.bind(this)}
					miniActive={this.state.miniActive}
					brandText={this.getActiveRoute(dashRoutes)}
					handleDrawerToggle={this.handleDrawerToggle}
					{...rest}
				/>
				<GridContainer>{this.props.userData && <Banner userData={this.props.user} />}</GridContainer>
				{alert}
				{loading}

				<div className={classes.wrapper}>
					{this.props.userData && (
						<Sidebar
							routes={removeRoutes(this.context.grouper, dashRoutes)}
							logo={this.state.logo}
							image={this.state.image}
							handleDrawerToggle={this.handleDrawerToggle}
							open={this.state.mobileOpen}
							color={this.state.color}
							bgColor={this.state.bgColor}
							miniActive={this.state.miniActive}
							user={user}
							userData={this.state.userData}
							generalRulesData={this.state.generalRulesData}
							{...rest}
						/>
					)}
					<div className={mainPanel} ref="mainPanel">
						{/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
						{this.getRoute() ? (
							<div className={classes.content}>
								<div className={classes.container}>
									<Switch>{this.getRoutes(dashRoutes)}</Switch>
								</div>
							</div>
						) : (
							<div className={classes && classes.map}>
								<Switch>{this.getRoutes(dashRoutes)}</Switch>
							</div>
						)}
					</div>
				</div>
				<GridContainer>
					<Footer />
				</GridContainer>

				<NotificationModal
					headerTitle="MENU_NOTIFICATION"
					width="900px"
					height="500px"
					headerIcon={false}
					closeByClick={() => this.setState({ hasNotification: false })}
					open={hasNotification}
					showCheckBox={true}
					buttonTitle="BUTTON_CLOSE"
					totalPage={notifications.total}
					sendPage={(page, readAllImportantAlerts) => this.changePage(page, readAllImportantAlerts)}
					presentPage={notifications.presentPage}
				>
					{notifications.loading ? (
						<div>
							<NotificationSkeleton variant="rect" width="840px" height="40px" />
							<NotificationSkeleton variant="rect" width="840px" height="40px" />
							<NotificationSkeleton variant="rect" width="840px" height="260px" />
						</div>
					) : (
						<Grid container>
							<Grid item xs={12} className="date-render">
								<small>{notifications.data.publishFormat}</small>
							</Grid>
							<Grid item xs={12} className="title-render">
								<b>{notifications.data.title}</b>
							</Grid>
							<BodyRender item xs={12} className="body-render">
								<RenderDescription renderComp={notifications.data.content} />
							</BodyRender>
						</Grid>
					)}
					<div></div>
				</NotificationModal>
			</AdminContext.Provider>
		)
	}
}

Home.propTypes = {
	classes: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
	const { users, authentication, sweetAlert, loader, generalRules } = state
	const { user } = authentication
	return {
		user,
		users,
		alert: sweetAlert.alert,
		loading: loader.loading,

		userData: users.userData,
		generalRulesData: generalRules.generalRulesData,
		userDataFailed: users.userDataFailed,
		generalRulesDataFailed: generalRules.generalRulesDataFailed,
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({ GetCurrentPartner, GetData, ShowLoader, HideLoader }, dispatch)

export { AdminContext }

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(appStyle),
	withTranslation(),
)(Home)
