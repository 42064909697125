import { GetTypeOfPoints } from 'redux/api/productPage'
import { numberFormatText } from 'utils/utils'
import { UpdateProduct } from 'redux/api/product'
import { GetGrouperByDivision } from 'redux/api/Grouper'
import { CalculateParametrizationValue, ValidateByDivisions } from 'redux/api/product'
import Cookies from 'js-cookie'
import {envs}  from '../../../../../../../../../../../../../redux/api/windowServerData'



export const GetTypePoints = async (setGenerationType, setLoading) => {
	try {
		const res = await GetTypeOfPoints()
		if (res && typeof res === 'object') {
			const newRes =
				res
					?.map(item => {
						if (item?.key === '0') {
							return {
								...item,
								value: 'Não gerar pontos',
							}
						}

						if (item?.key === '2') {
							return null
						}
						return item
					})
					?.filter(item => item !== null) || []

			setGenerationType(newRes.filter(item => item) || [])
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

export const ProductByDivision = async ({ id, divisionId, type, setDivisionProductData, setLoading }) => {
	setLoading(true)
	try {
		const res = await GetGrouperByDivision({ id, divisionId, type })

		if (res && typeof res === 'object') {
			setDivisionProductData(res?.data?.data)
		}
	} catch (error) {
		setDivisionProductData([])
	} finally {
		setLoading(false)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		0,
	)
}

export const FormatMoney = value => {
	return numberFormatText(
		value,
		// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const handleProductDataUpdate = (divisionProductData, setProductDataState, reset) => {
	if (divisionProductData) {
		const { typeOfPoints, parameterizationGenerationPoints, pointsValue } = divisionProductData

		reset({
			typeOfPoints: typeOfPoints || {
				key: '',
				value: 'Não parametrizado',
			},
			pointsValue: pointsValue,
		})

		setProductDataState({
			parameterization: parameterizationGenerationPoints,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async (productId, price, type, value, typeOfRedeem, redeemValue) => {
	const partnerId = Cookies.get('partnerId')
	try {
		const res = await CalculateParametrizationValue(partnerId, price, type, value, typeOfRedeem, redeemValue)
		if (res?.success && res?.data) {
			const { generationPoints } = res.data
			return generationPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationProduct = async (productData, typeOfPoints, cashbackPercentual, setSnackStatus, typeOfBenefitPoints) => {
	const obj = {
		id: productData?.id,
		price: 0,
		name: productData?.name,
		generatePoints: false,
		typeOfBenefitPoints: typeOfBenefitPoints.key,
		typeOfPoints: '0',
		typeOfBenefitRedeem: productData?.typeOfBenefitRedeem?.key,
		typeOfRedeem: '0',
		redemptionPoints: productData?.redemptionPoints?.key,
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: '0',
		cashbackPercentual: cashbackPercentual,
	}

	try {
		const res = await UpdateProduct(obj?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				type: 'success',
			})
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				message: res?.data?.errors[0]?.message,
				type: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	}
}

export const ValidateStatusProductByDivisions = async ({ productId, partnerId, divisions, typeOfBenefit, page, pageSize }) => {
	try {
		const body = {
			partnerId: partnerId || '',
			productId: productId || '',
			divisions: divisions || [],
			typeOfBenefit: typeOfBenefit,
			pageSize: pageSize || 10,
			page: page || 1,
		}
		const res = await ValidateByDivisions(body)
		return res
	} catch (error) {
		return error
	}
}

export const HasParameterization = async ({ productData, selectedDivisionIdRed, setHasParameterization, setLoading, setSnackStatus }) => {
	setLoading(true)

	try {
		const PartnerId = String(Cookies.get('partnerId'))
		const res = await ValidateStatusProductByDivisions({
			productId: productData?.id,
			partnerId: PartnerId,
			divisions: selectedDivisionIdRed,
			typeOfBenefit: 'Redeem',
		})
		if (res.status == 200) {
			setHasParameterization(res?.data)
		}
	} catch (error) {
		setSnackStatus({
			oopen: false,
			severity: 'error',
			text: 'Ocorreu um erro ao validar a parametrização',
		})
	} finally {
		setLoading(false)
	}
}
