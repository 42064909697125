import React, { useState, useEffect } from 'react'

import styles from './styles.module.scss'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import {envs}  from '../../redux/api/windowServerData'



export default function Footer() {
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])
	const getBGCol = () => {
		if (themeColor && themeColor.structure && themeColor.structure.backgroundColor) {
			return themeColor.structure.backgroundColor
		}
		return '#fff'
	}
	return (
		<footer
			className={styles.container}
			style={{
				backgroundColor: getBGCol(),
			}}
		>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					width: '100%',
				}}
			>
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						width: '100%',
						maxWidth: '1200px',
						zIndex: '5',
						color: '#666',
						marginTop: '-35px',
						fontSize: '0.8rem',
						opacity: '0.7',
					}}
				>
					<span>
						{'Release: ' +
							// `${envs.REACT_APP_GERMINI_BUILD_VERSION}`}
							`${envs.REACT_APP_GERMINI_BUILD_VERSION}`}
					</span>
				</div>
			</div>
		</footer>
	)
}
