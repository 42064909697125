import React, { useEffect, useState } from 'react'
import { Text } from './styles'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const TextInput = ({ ...props }) => {
	const [themeColor, setThemeColor] = useState(getThemeColorFromLocalStorage())

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	return <Text fullWidth variant="standard" {...props} themeColor={themeColor} />
}

export default TextInput
