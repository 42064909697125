import React from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

//@ components
import Details from './components/HistoryDetails';

import Section from 'components/Section';

const HistoryDetails = () => {
    const { t } = useTranslation()

    return (
        <S.Container>
            <Section title={t('MENU_PRODUCT_HISTORY')} />
            <Details/>
        </S.Container>
    )
}

export default HistoryDetails
