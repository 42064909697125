import Cookies from 'js-cookie'
import _ from 'lodash'
export const removeRoutes = (grouper, dashRoutes) => {
	if (!dashRoutes) return []

	for (let i = 0; i < dashRoutes?.length; i++) {
		if (dashRoutes[i]?.views?.length > 0) {
			for (let j = 0; j < dashRoutes[i]?.views?.length; j++) {
				if (dashRoutes[i]?.views[j]?.identifier === 'category') {
					dashRoutes[i].views[j].sidebar = !_?.isEmpty(GetGrouper(grouper)?.name)
					dashRoutes[i].views[j].name = GetGrouper(grouper)?.name || ''
				}
			}
		}
	}

	return dashRoutes
}

export const GetGrouper = item => {
	try {
		const grouper = item || String(Cookies.get('partnerId')) || localStorage.getItem('Grouper')

		switch (String(grouper)) {
			case '1':
				return {
					name: 'MENU_CATEGORY',
					Grouper: 1,
				}
			case '2':
				return {
					name: 'FAMILY',
					Grouper: 2,
				}
			case '3':
				return {
					name: 'MANUFACTURE',
					Grouper: 3,
				}
			case '4':
				return {
					name: 'FIELD_BRAND',
					Grouper: 4,
				}
			default:
				return {
					name: '',
					Grouper: 0,
				}
		}
	} catch (error) {
		return {
			name: '',
			Grouper: 0,
		}
	}
}
