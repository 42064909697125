import { Grid } from '@material-ui/core'
import React, { Fragment } from 'react'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { formatCPF, formatCellPhone, validateValue } from '../../../../../Functions'
import Section from 'components/Section'
import { Stack } from '@mui/material'
import { MuiInput } from '../../../../../../../../components/storybook'

export default function Responsible({ isEditData, divisionData }) {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()

	return (
		<Grid container spacing={2}>
			<Grid item lg={12} sm={12}>
				<Stack mt={10} sx={{ width: '100%' }}>
					<Section title="Responsável" />
				</Stack>
			</Grid>
			<Grid item lg={2} sm={12}>
				{!isEditData ? (
					<Labelitem label="CPF" disabled required value={validateValue(divisionData?.contactPartner?.cpf)} />
				) : (
					<Fragment>
						<Labelitem required label="CPF" />
						<Controller
							control={control}
							name="businessDivisionResponsibleCPF"
							render={({ onChange, value }) => (
								<MuiInput
									error={errors?.businessDivisionResponsibleCPF}
									helperText={t(errors?.businessDivisionResponsibleCPF?.message)}
									fullWidth
									id="businessDivisionResponsibleCPF"
									name="businessDivisionResponsibleCPF"
									value={value}
									disabled={false}
									onChange={e => {
										e.target.value = formatCPF(e.target.value)
										onChange(e)
									}}
									inputProps={{
										maxLength: 14,
									}}
									variant="outlined"
									margin="dense"
									style={{
										backgroundColor: '#fff',
									}}
								/>
							)}
							defaultValue={divisionData?.contactPartner?.cpf}
						/>
					</Fragment>
				)}
			</Grid>
			<Grid item lg={7} sm={12}>
				{!isEditData ? (
					<Labelitem label="Nome" disabled required value={validateValue(divisionData?.contactPartner?.name)} />
				) : (
					<Fragment>
						<Labelitem required label="Nome" />
						<Controller
							control={control}
							name="businessDivisionResponsibleName"
							render={({ onChange, value }) => (
								<MuiInput
									error={errors?.businessDivisionResponsibleName}
									helperText={t(errors?.businessDivisionResponsibleName?.message)}
									fullWidth
									id="businessDivisionResponsibleName"
									name="businessDivisionResponsibleName"
									value={value}
									disabled={false}
									onChange={onChange}
									variant="outlined"
									margin="dense"
									style={{
										backgroundColor: '#fff',
									}}
								/>
							)}
							defaultValue={divisionData?.contactPartner?.name}
						/>
					</Fragment>
				)}
			</Grid>

			<Grid item lg={3} sm={12}>
				{!isEditData ? (
					<Labelitem label="Celular" disabled required value={validateValue(divisionData?.contactPartner?.cellphone)} />
				) : (
					<Fragment>
						<Labelitem required label="Celular" />
						<Controller
							control={control}
							name="businessDivisionResponsibleCellphone"
							render={({ onChange, value }) => (
								<MuiInput
									error={errors?.businessDivisionResponsibleCellphone}
									helperText={t(errors?.businessDivisionResponsibleCellphone?.message)}
									fullWidth
									id="businessDivisionResponsibleCellphone"
									name="businessDivisionResponsibleCellphone"
									value={value}
									disabled={false}
									onChange={e => {
										e.target.value = formatCellPhone(e.target.value)
										onChange(e)
									}}
									variant="outlined"
									margin="dense"
									style={{
										backgroundColor: '#fff',
									}}
								/>
							)}
							defaultValue={divisionData?.contactPartner?.cellphone}
						/>
					</Fragment>
				)}
			</Grid>
			<Grid item lg={12} sm={12}>
				{!isEditData ? (
					<Labelitem label="Email" disabled required value={validateValue(divisionData?.contactPartner?.email)} />
				) : (
					<Fragment>
						<Labelitem required label="Email" />
						<Controller
							control={control}
							name="businessDivisionResponsibleEmail"
							render={({ onChange, value }) => (
								<MuiInput
									error={errors?.businessDivisionResponsibleEmail}
									helperText={t(errors?.businessDivisionResponsibleEmail?.message)}
									fullWidth
									id="businessDivisionResponsibleEmail"
									name="businessDivisionResponsibleEmail"
									value={value}
									disabled={false}
									onChange={onChange}
									variant="outlined"
									margin="dense"
									style={{
										backgroundColor: '#fff',
									}}
								/>
							)}
							defaultValue={divisionData?.contactPartner?.email}
						/>
					</Fragment>
				)}
			</Grid>
		</Grid>
	)
}
