import * as actionTypes from './actionsTypes'
import * as API from '../api/invoice'

//----------------------------------------------------
export const GetInvoices = (dateFrom, dateTo, ConsumerId, partnerId, pageIndex) => {
	return dispatch => (
		API.GetInvoices(dateFrom, dateTo, ConsumerId, partnerId, pageIndex).then(response => {
			dispatch(fetchInvoicesData(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetchInvoicesDataError(error));
		})
	);

	function fetchInvoicesData(data) {
		return {
			type: actionTypes.FETCH_INVOICES_DATA,
			lsInvoiceData: data
		};
	};

	function fetchInvoicesDataError(error) {
		return {
			type: actionTypes.FETCH_INVOICES_FAILED,
			invoiceDataFailed: error
		};
	};
};

export const GetInvoiceById = (id) => {
	return dispatch => (
		API.GetInvoiceById(id).then(response => {
			dispatch(fetchInvoicById(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetchInvoiceByIdFailed(error));
		})
	);

	function fetchInvoicById(data) {
		return {
			type: actionTypes.FETCH_INVOICE_BY_ID,
			invoiceData: data
		};
	};

	function fetchInvoiceByIdFailed(error) {
		return {
			type: actionTypes.FETCH_INVOICE_BY_ID_FAILED,
			invoiceByIdDataFailed: error
		}
	}
}

export const GetSalesHistory = (startDate, endData, consumerId, channelType, code, documentId, page) => {
	return dispatch => (
		API.GetSalesHistory(startDate, endData, consumerId, channelType, code, documentId, page).then(response => {
			dispatch(fetchSaveHistory(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetchSaveHistoryFailed(error));
		})
	);

	function fetchSaveHistory(data) {
		return {
			type: actionTypes.FETCH_SALES_HISTORY,
			lsSalesHistory: data
		};
	};

	function fetchSaveHistoryFailed(error) {
		return {
			type: actionTypes.FETCH_SALES_HISTORY_FAILED,
			salesHistoryFailed: error
		}
	}
}

export const GetInvoicesDetailsFromPartner = () => {
	return dispatch => (
		API.GetInvoicesDetailsFromPartner().then(response => {
			dispatch(fetchInvoiesDetailsPartner(response));
		}).catch(error => {
			console.error(error);
			dispatch(fetchInvoiesDetailsPartnerFailed(error));
		})
	);

	function fetchInvoiesDetailsPartner(data) {
		return {
			type: actionTypes.FETCH_INVOICES_DETAIL_PARTHER,
			lsInvoicesDetailPartner: data
		};
	};

	function fetchInvoiesDetailsPartnerFailed(error) {
		return {
			type: actionTypes.FETCH_INVOICES_DETAIL_PARTHER_FAILED,
			invoicesDetailPartnerFailed: error
		}
	}
}
