import styled from 'styled-components/macro'
import { InputBase, TextField } from '@material-ui/core'

export const Container = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor} !important;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiOutlinedInput-root:hover fieldset {
		border-color: ${({ themeColor }) =>
		themeColor &&
		themeColor.structure &&
		themeColor.structure.backgroundColor &&
		themeColor.structure.backgroundColor};
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}

	.MuiFormLabel-root.Mui-disabled {
		color: rgba(0, 0, 0, 0.5);
		font-size: 15px !important;
	}
`
export const ErrorSpan = styled.span`
	color: #f05b4f;
`
export const StyledInput = styled(InputBase)`
	border-radius: 4px;
	border: ${(props) => (props.disabled ? 'none' : '1px solid #ccc')};
	padding: 10px 14px;
	background: ${(props) => (props.disabled ? '#F3F2F2' : 'transparent')};
	color: rgba(0, 0, 0, 0.6);

	&:hover {
		border-color: black;
	}
`

export const BankStyledInput = styled(TextField)`
	border-radius: 4px;
	border: ${(props) => (props.disabled ? 'none' : '1px solid #ccc')};
	padding: 10px 14px;
	background: ${(props) => (props.disabled ? '#F3F2F2' : 'transparent')};
	color: rgba(0, 0, 0, 0.6);

	&:hover {
		border-color: black;
	}
`

// ================================================================
export const HeaderBox = styled.div`
	align-items: center;
	display: flex;
	width: 100%;
	height: 40px;
`
export const SectionBox = styled.div`
	width: 100%;
`
export const BtnHeaderBox = styled.div`
	display: flex;
	gap: 0.813rem;
	margin-left: 0.938rem;

	.RegularButton-sm-64 {
    	border-radius: 5px;
	}
`

export const AccountContainer = styled.form`
	width: 100%;
`
export const InputsBox = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding-top: 25px;
	width: 100%;
	.MuiFormControl-marginDense {
		margin: 0;
	}
`

export const AccountName = styled.div`
	margin-bottom: 15px;
	width: 100%;

	.MuiFormLabel-root.Mui-disabled {
		margin-top: -8px;
	}

	@media screen and (min-width: 500px) {
		padding-right: 7.5px;
		width: 50%;
	}
`
export const AccountDescription = styled.div`
	margin-bottom: 15px;
	width: 100%;

	.MuiFormLabel-root.Mui-disabled {
		margin-top: -8px;
	}

	@media screen and (min-width: 500px) {
		padding-left: 7.5px;
		width: 50%;
	}
`
export const AccountBank = styled.div`
	margin-bottom: 15px;
	width: 100%;
	@media screen and (min-width: 550px) {
		padding-right: 7.5px;
		width: 50%;
	}
`
export const AccountAgency = styled.div`
	margin-bottom: 15px;
	width: 100%;
	@media screen and (min-width: 470px) {
		padding-right: 7.5px;
		width: 50%;
	}
	@media screen and (min-width: 550px) {
		padding-left: 7.5px;
		padding-right: 0;
	}
	@media screen and (min-width: 900px) {
		padding-right: 7.5px;
		width: 25%;
	}
	@media screen and (min-width: 960px) {
		padding-left: 7.5px;
		padding-right: 0;
		width: 50%;
	}
	@media screen and (min-width: 1200px) {
		padding-right: 7.5px;
		width: 25%;
	}
`
export const AccountNumber = styled.div`
	margin-bottom: 15px;
	width: 100%;
	@media screen and (min-width: 470px) {
		padding-left: 7.5px;
		width: 50%;
	}
	@media screen and (min-width: 550px) {
		padding-left: 0;
		padding-right: 7.5px;
	}
	@media screen and (min-width: 900px) {
		padding-left: 7.5px;
		padding-right: 0;
		width: 25%;
	}
	@media screen and (min-width: 960px) {
		padding-left: 0;
		padding-right: 7.5px;
		width: 50%;
	}
	@media screen and (min-width: 1200px) {
		padding-left: 7.5px;
		padding-right: 0;
		width: 25%;
	}
`
export const AccountActiva = styled.div`
	width: 100%;

	@media screen and (min-width: 470px) {
		width: 25%;
	}
`
