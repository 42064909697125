import styled from 'styled-components/macro';
import { Dialog } from '@material-ui/core';

import theme from 'assets/theme/CustomTheme';

export const DialogContainer = styled(Dialog)`
	.MuiDialog-paperWidthSm {
		border-radius: 26px;
		padding: 36px;
	}

	.modal-box {
		display: flex;
		flex-direction: column;
		align-items: center;

		.modal-box-icon {
			text-align: center;
			color: ${theme.error};
			font-size: 65px;
			margin-bottom: 50px;
		}

		.modal-box-title {
			text-align: center;
			color: ${theme.error};
			font-size: 18px;
			font-weight: bold;
		}

		.modal-box-subtitle {
			font-size: 20px;
			color: ${theme.error};
			font-weight: bold;
			font-size: 18px;
		}

		.modal-box-button {
			margin-top: 55px;
		}
	}
`;
