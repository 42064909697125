import _ from 'lodash'
import { numberFormatText } from 'utils/utils'
import { CalculateValue, GetTypeORedeem, UpdateProduct } from '../../../../../../../../../redux/api/product'
import {envs}  from '../../../../../../../../../redux/api/windowServerData'



export const GetORedeem = async ({ setGenerationType, setCircularProgress }) => {
	setCircularProgress(true)

	try {
		const res = await GetTypeORedeem()

		if (res?.status === 200 && res?.data?.length > 0) {
			const newRes =
				res?.data
					?.map(item => {
						if (item?.value === 'No_') {
							return null
						}
						if (item?.value === 'No') {
							return {
								...item,
								value: 'Não resgatavel',
							}
						}
						return item
					})
					?.filter(item => item !== null) || []

			setGenerationType(newRes)
		}
	} catch (error) {
		setGenerationType([])
	} finally {
		setCircularProgress(false)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const FormatMoney = value => {
	// const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', symbol)
}

export const handleProductDataUpdate = ({ productData, setParameterization, reset }) => {
	if (!_.isEmpty(productData)) {
		const { typeOfRedeem, parameterizationRedemptionPoints, redemptionPoints } = productData

		reset({
			redemptionPoints: redemptionPoints || 0,
			rescue: typeOfRedeem,
		})

		setParameterization(parameterizationRedemptionPoints)
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async ({ productId, price, type, pointsValue, typeOfRedeem, value }) => {
	try {
		const res = await CalculateValue(productId, price, type, pointsValue, typeOfRedeem, value)

		if (res?.success && res?.data) {
			const { redemptionPoints } = res?.data
			return redemptionPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationProduct = async (productData, typeOfRedeem, redemptionPoints, setSnackStatus, typeOfBenefitRedeem, setLoading) => {
	const obj = {
		id: productData?.id,
		price: productData?.price,
		name: productData?.name,
		generatePoints: productData?.generatePoints,
		typeOfBenefitPoints: productData?.typeOfBenefitPoints?.key,
		typeOfPoints: productData?.typeOfPoints?.key,
		redemptionPoints: redemptionPoints || 0,
		typeOfBenefitRedeem: typeOfBenefitRedeem?.key || '1',
		typeOfRedeem: typeOfRedeem?.key,
		pointsValue: productData?.pointsValue || 0,
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: productData?.typeOfCashback?.key,
		cashbackPercentual: productData?.cashbackPercentual,
	}

	setLoading(true)

	try {
		const res = await UpdateProduct(obj?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				type: 'success',
			})
			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				message: res?.data?.errors[0]?.message,
				type: 'error',
			})
		}
	} catch (error) {
		setSnackStatus({
			open: true,
			message: 'Ocorreu um erro ao realizar a operação!',
			type: 'error',
		})
	} finally {
		setLoading(false)
	}
}
