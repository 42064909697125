import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Datetime from 'react-datetime'
import * as S from './style'

import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'
import { InputAdornment } from '@material-ui/core'
import { TextField } from '@material-ui/core'
import moment from 'moment'

function CustomDateLeft({ disabled = false, label = '', className = '', value, ...values }) {
	const [themeColor, setThemeColor] = useState('')
	const [dateValid, setDateValid] = useState(true)

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		if (value) {
			setDateValid(moment(value, 'DD/MM/YYYY').isValid())
		}
	}, [value])

	return (
		<S.Container
			themeColor={themeColor?.visual?.icons?.color || `#fff`}
			getBGCol={themeColor?.structure?.backgroundColor || `#fff`}
			id="date-picker-inline-container"
		>
			<Datetime
				{...values}
				value={value}
				locale="pt-br"
				disabled={disabled}
				timeFormat={false}
				className={className}
				renderInput={props => {
					return (
						<TextField
							{...props}
							label={label}
							variant="outlined"
							error={!dateValid}
							autoComplete="off"
							disabled={disabled}
							className={className}
							fullWidth
							name={values.name}
							helperText={!dateValid ? 'Data inválida' : ''}
							style={{
								backgroundColor: '#fff ',
							}}
							size="small"
							InputProps={{
								...props,
								inputComponent: S.DateMaskCustom,
								endAdornment: (
									<InputAdornment>
										<i
											className=" icon-calendario"
											style={{
												fontSize: '19px',
												color: disabled === true ? '#ccc' : themeColor?.visual?.icons?.color,
											}}
										/>
									</InputAdornment>
								),
							}}
						/>
					)
				}}
			/>
		</S.Container>
	)
}

CustomDateLeft.propTypes = {
	disabled: PropTypes.bool,
	label: PropTypes.string,
	className: PropTypes.string,
	value: PropTypes.string,
}

export default CustomDateLeft
