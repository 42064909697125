import axios from 'axios'
import Cookies from 'js-cookie'

export const GetFamilies = async (pageSize, page, orderBy, OrderByDesc, name) => {
	const partnerId = String(Cookies.get('partnerId'))
	let url = '/Family/GetFamilies'

	if (partnerId) {
		url += `&id=${partnerId}`
	}

	if (name) {
		url += `&name=${name}`
	}

	if (orderBy) {
		url += `&orderBy=${orderBy}`
	}

	if (OrderByDesc === true || OrderByDesc === false) {
		url += `&OrderByDesc=${OrderByDesc}`
	}
	if (pageSize) {
		url += `&pageSize=${pageSize}`
	}
	if (page) {
		url += `&page=${page}`
	}

	if (url.indexOf('&') !== -1) url = url.replace('&', '?')

	return axios.get(url).then(response => response.data)
}

export const DeleteFamily = async id => {
	const url = `/Family/DeleteFamily/${id}`
	return axios.delete(url).then(response => response)
}

export const CreateFamily = async obj => {
	const url = `/Family/CreateFamily`
	return axios.post(url, obj).then(response => response)
}
