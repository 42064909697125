import { numberFormatText } from 'utils/utils'
import {envs}  from '../../../../../../../../redux/api/windowServerData'



export const FormatMoney = value => {
	return numberFormatText(
		value,
		// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			  envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		0,
	)
}

export const formatDateTime = dateString => {
	if (!dateString) return '-'
	const date = new Date(dateString)
	const formattedDate = date.toLocaleDateString('pt-BR', {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	})
	const formattedTime = date.toLocaleTimeString('pt-BR', {
		hour: '2-digit',
		minute: '2-digit',
	})
	return `${formattedDate} - ${formattedTime}`
}
