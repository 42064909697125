import * as React from 'react'
import PropTypes from 'prop-types'

// @ Mui
import Modal from '@mui/material/Modal'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'

// @ Styles
import { Button, Container, Title } from './styles'
import { StandardColors } from '../DivisionTab/components/ParameterizeDivision/ParameterizeDivisionTB/Functions/utils'

const CalcMemory = ({ open, onClose, rows, divisionName }) => {
	return (
		<div>
			<Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Container>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Title color={StandardColors[divisionName]}>Memória de cálculo - Resgate</Title>
						<Button onClick={() => onClose()}>Fechar</Button>
					</Box>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
							<TableBody>
								{rows?.map(row => (
									<TableRow key={row.name}>
										<TableCell component="th" scope="row">
											{row.label}
										</TableCell>
										<TableCell style={{ width: 160 }} align="right">
											{row.value}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</Modal>
		</div>
	)
}

CalcMemory.propTypes = {
	open: PropTypes.bool,
	rows: PropTypes.array,
	onClose: PropTypes.func,
	divisionName: PropTypes.string,
	parameterization: PropTypes.object,
}

CalcMemory.defaultProps = {
	open: false,
}

export default CalcMemory

export function createData(label, value) {
	return { label, value }
}
