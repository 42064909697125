import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

// @ components
import DivisionOptions from '../Options'
import DivisionTable from './components/DivisionTable'

const DivisionTab = ({
	editMode,
	multiple,
	division,
	setMultiple,
	setEditMode,
	productData,
	setDivision,
	parameterization,
	selectedDivision,
	setSelectedDivision,
	setParameterization,
	selectedDivisionIdRed,
	setSelectedDivisionIdRed,
	setMultipleParameterization,
}) => {
	const sharedProps = {
		parameterization,
		division,
		editMode,
		multiple,
		setDivision,
		setMultiple,
		setEditMode,
		productData,
		selectedDivision,
		setParameterization,
		selectedDivisionIdRed,
		setSelectedDivisionIdRed,
		setMultipleParameterization,
		setSelectedDivision: setSelectedDivision,
	}

	return (
		<Fragment>
			<DivisionOptions {...sharedProps} />
			<DivisionTable {...sharedProps} />
		</Fragment>
	)
}

DivisionTab.propTypes = {
	division: PropTypes.array,
	editMode: PropTypes.bool,
	multiple: PropTypes.bool,
	setMultiple: PropTypes.func,
	setEditMode: PropTypes.func,
	setDivision: PropTypes.func,
	productData: PropTypes.object,
	parameterization: PropTypes.array,
	selectedDivision: PropTypes.array,
	setParameterization: PropTypes.func,
	setSelectedDivision: PropTypes.func,
	selectedDivisionIdRed: PropTypes.array,
	setSelectedDivisionIdRed: PropTypes.func,
	setMultipleParameterization: PropTypes.func,
}

DivisionTab.defaultProps = {
	selectedDivision: [],
}

export default DivisionTab
