import * as React from 'react'
import PropTypes from 'prop-types'
import Modal from '@mui/material/Modal'
import { Button, Container, Title } from './styles'
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { formatPTBRValue, formatPercentage, formatUSDValue } from '../../../../Functions'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'

const CalcMemory = ({ open = false, productData, onClose }) => {
	const { parameterizationGenerationPoints, price } = productData
	const defaultColor = getThemeColorFromLocalStorage()?.structure?.backgroundColor ?? '#F18932'
	function createData(label, value) {
		return { label, value }
	}

	const rows = [
		createData('Valor do produto', formatPTBRValue(price)),
		createData('Taxa de conversão', formatPercentage(parameterizationGenerationPoints?.calculationMemory?.conversionRate)),
		createData(
			'Taxa de conversão aplicada (valor bruto do desconto da transação)',
			formatPTBRValue(parameterizationGenerationPoints?.standardGrossValue ?? parameterizationGenerationPoints?.customGrossValue),
		),
		createData('Taxa de administração', formatPercentage(parameterizationGenerationPoints?.calculationMemory?.administrationFee)),
		createData(
			'Taxa de administração aplicada',
			formatPTBRValue(
				parameterizationGenerationPoints?.standardAdministrationFeeApplied ?? parameterizationGenerationPoints?.customAdministrationFeeApplied,
			),
		),
		createData('Valor a converter', formatPTBRValue(parameterizationGenerationPoints?.calculationMemory?.valueToConvert)),
		createData('Fator de multiplicação', `x ${parameterizationGenerationPoints?.calculationMemory?.multiplierFactor}`),
		createData('Pontos acumulados (Cliente)', formatUSDValue(parameterizationGenerationPoints?.standardAccumulativePoints)),
	]

	return (
		<div>
			<Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
				<Container>
					<Box display="flex" justifyContent="space-between" alignItems="center">
						<Title color={defaultColor}>Memória de cálculo - Geração</Title>
						<Button onClick={() => onClose()}>Fechar</Button>
					</Box>
					<TableContainer component={Paper}>
						<Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
							<TableBody>
								{rows?.map(row => (
									<TableRow key={row.name}>
										<TableCell component="th" scope="row">
											{row.label}
										</TableCell>
										<TableCell style={{ width: 160, fontWeight: 600, color: '#636363' }} align="right">
											{row.value}
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</Container>
			</Modal>
		</div>
	)
}

CalcMemory.propTypes = {
	open: PropTypes.bool,
	onClose: PropTypes.func,
	parameterization: PropTypes.object,
}

export default CalcMemory
