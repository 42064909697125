import { Grid, Icon, Tooltip } from '@material-ui/core'
// Icons
import { Search } from '@material-ui/icons'
// Components
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import { format } from 'date-fns'
import moment from 'moment'
import React, { createRef, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
// Redux
import { useDispatch } from 'react-redux'
// Utils
import { useHistory } from 'react-router-dom'
import { HideLoader } from 'redux/actions'
import CustomMaterialTable from '../../../components/CustomMaterialTable/CustomMaterialTable'
import { GetAccounts } from '../../../redux/api/accounts'
import { GetCurrentPartner } from '../../../redux/api/user'
import { useAccounts } from '../hooks/useAccounts'
// Styles
import * as Style from '../styles'
import SearchForm from './SearchForm'
import SnackDefault from '../../../components/SnackDefault'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

export function AccountList({ classes }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const tableRef = createRef()
	const [isLoading, setIsLoading] = useState(false)
	const { valueTypeFilter } = useAccounts()
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})
	const [themeColor, setThemeColor] = useState()

	const { handleSubmit, control, reset, watch, setValue, errors } = useForm({
		defaultValue: {
			currentPage: 0,
			pageCount: 1,
			pageSize: 10,
			recordCount: 1,
			filter: {
				name: '',
				status: '',
				endDate: null,
				startDate: null,
			},
		},
		mode: 'onChange',
	})

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		dispatch(HideLoader())

		if (history.location.state && history.location.state.creationStatus) {
			setSnackStatus((prevState) => ({
				...prevState,
				severity: 'success',
				open: true,
			}))
		}
	}, [])

	useEffect(() => {
		tableRef.current.onChangePage({}, 0)
		tableRef.current.onQueryChange({ page: 0, search: '' })
	}, [valueTypeFilter])

	const getColor = () => {
		return (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor &&
			themeColor.navigation.buttons.textColor
		)
	}

	const handleGetBankAccountData = useCallback(
		async (pageSize, page) => {
			let startDate = null
			let endDate = null
			if (valueTypeFilter.dateFilterFrom) {
				startDate = moment(
					valueTypeFilter.dateFilterFrom,
					'DD/MM/YYYY',
				).format('YYYY-MM-DD')
			}
			if (valueTypeFilter.dateFilterTo) {
				endDate = moment(valueTypeFilter.dateFilterTo, 'DD/MM/YYYY')
					.add(1, 'days')
					.format('YYYY-MM-DD')
			}

			const partnerId = await GetCurrentPartner().then((res) => {
				return res.id
			})

			const res = await GetAccounts(
				valueTypeFilter.accountName,
				startDate,
				endDate,
				valueTypeFilter.status,
				pageSize,
				page,
			)
			return res
		},
		[valueTypeFilter],
	)

	// Redirect to create new account page
	function handleNewAccount() {
		history.push('/financial/accounts/new')
	}

	// Redirect to manage account moves page
	function handleManage(props) {
		history.push(`/financial/accounts/manage/${props.id}`)
	}

	// Redirect to account details page
	function handleDetails(props) {
		history.push(`/financial/accounts/details/${props.id}`)
	}

	const getHLCol = () => {
		if (
			themeColor &&
			themeColor.titles &&
			themeColor.titles.highlight &&
			themeColor.titles.highlight.textColor
		) {
			return themeColor.titles.highlight.textColor
		}
		return `#444`
	}

	return (
		<Grid container xs={12} sm={12} md={12}>
			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus((prevState) => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<Style.HeaderBox>
				<Style.SectionBox>
					<Section
						title={t('MENU_ACCOUNTS')}
						style={{ flexGrow: 1 }}
					/>
				</Style.SectionBox>
				<Style.BtnHeaderBox>
					<Button
						color="greenButtonColor"
						variant="contained"
						size="sm"
						onClick={handleNewAccount}
					>
						<Icon
							className="icon-menu_minha_conta"
							style={{
								fontSize: '16px',
								color: `${getColor()}`,
							}}
						/>
						{t('BUTTON_CREATE_ACCOUNT')}
					</Button>
				</Style.BtnHeaderBox>
			</Style.HeaderBox>

			<Style.InnerContainer container>
				<Style.SearchBox>
					<Grid item xs={12}>
						<SearchForm />
					</Grid>
				</Style.SearchBox>

				<Style.ListGrid>
					<Style.GridContent
						style={{
							width: '100%',
						}}
					>
						<CustomMaterialTable
							tableRef={tableRef}
							style={{
								width: '100%',
								marginBottom: '70px',
								borderBottom: '2px solid #C8C8C8',
								borderRadius: '10px 10px 0 0',
							}}
							noToolbar
							title=""
							options={{
								emptyRowsWhenPaging: false,
								search: false,
								toolBar: false,
								pageSize: 10,
							}}
							columns={[
								{
									title: 'Status',
									field: 'status',
									width: '5%',
									headerStyle: {
										color: getHLCol(),
										fontSize: '15px',
									},
									render: (props) => (
										<Style.Status
											active={!!props.isActive}
										/>
									),
								},
								{
									title: 'Data de criação',
									field: 'date',
									width: '37.5%',
									headerStyle: {
										color: getHLCol(),
										fontSize: '15px',
									},
									render: (props) =>
										format(
											new Date(props.createdAt),
											'dd/MM/yyyy',
										),
								},
								{
									title: 'Nome da conta',
									field: 'name',
									width: '37.5%',
									headerStyle: {
										color: getHLCol(),
										fontSize: '15px',
									},
								},
								{
									title: 'Ações',
									field: 'actions',
									width: '20%',
									cellStyle: {
										textAlign: 'end',
									},
									headerStyle: {
										textAlign: 'end',
										color: getHLCol(),
										fontSize: '15px',
									},
									sorting: false,
									render: (props) => (
										<>
											<Tooltip
												title={t('BUTTON_SHOW_DETAILS')}
											>
												<Style.GridButton
													justIcon
													size="sm"
													title={t(
														'BUTTON_SHOW_DETAILS',
													)}
													color="greenButtonColor"
													// disabled={isUnsavedData}
													onClick={() =>
														handleDetails(props)
													}
												>
													<Search
														style={{
															width: 24,
															height: 24,
															fontSize: '20px',
														}}
													/>
												</Style.GridButton>
											</Tooltip>

											<Tooltip
												title={t(
													'ACCOUNT_MANAGE_MOVES',
												)}
											>
												<Style.GridButton
													justIcon
													size="sm"
													title={t(
														'ACCOUNT_MANAGE_MOVES',
													)}
													color="greenButtonColor"
													// disabled={isUnsavedData}
													onClick={() =>
														handleManage(props)
													}
												>
													<Icon
														className="icon-acao_extrato_bancario"
														style={{
															display:
																'inline-flex',
															width: '100%',
															height: '100%',
															fontSize: 20,
															alignItems:
																'center',
															justifyContent:
																'center',
														}}
													/>
												</Style.GridButton>
											</Tooltip>
										</>
									),
								},
							]}
							data={(query) =>
								new Promise((resolve, reject) => {
									handleGetBankAccountData(
										query.pageSize,
										query.page + 1,
										query.search,
										query.orderDirection,
									).then((result) => {
										if (result) {
											if (result.results) {
												resolve({
													data: result.results,
													page:
														result.currentPage - 1,
													totalCount:
														result.recordCount,
													pageSize: result.pageSize,
												})
											}
										}
									})
								})
							}
							isLoading={isLoading}
						/>
					</Style.GridContent>
				</Style.ListGrid>
			</Style.InnerContainer>
		</Grid>
	)
}
