import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { withTranslation } from 'react-i18next'
import MaskedInput from 'react-text-mask'

// @material-ui/core components
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import InputAdornment from '@material-ui/core/InputAdornment'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import Button from 'components/CustomButtons/Button.jsx'

// icons
import Face from '@material-ui/icons/Person'

// style
import styles from '../resetPassword.module.scss'
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'

// UTILS
import compose from 'utils/compose'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import ModalResendEmail from './ModalResendEmail'
import { history } from '../../../../utils'
import Axios from 'axios'

function CPFMaskCustom(props) {
	// eslint-disable-next-line react/prop-types
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={rawValue =>
				rawValue.replace(/[^\d]+/g, '').length <= 11
					? [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
					: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
			}
			placeholderChar={'\u2000'}
		/>
	)
}

class RequestPasswordReset extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			openModal: false,
		}
	}

	hideLoader = () => this.setState({ loading: !this.state.loading })
	hideModal = () => this.setState({ openModal: !this.state.openModal })

	componentDidUpdate(prevProps) {
		const { resetPasswordFailed } = this.props
		const { resetPasswordFailed: prevResetPasswordFailed } = prevProps

		if (resetPasswordFailed && resetPasswordFailed !== prevResetPasswordFailed) {
			const { errors } = resetPasswordFailed

			if (errors && errors.length > 0) {
				const { message } = errors[0]
				if (message === 'EMAIL_DOES_NOT_VALIDATED') {
					this.hideModal()
				}
			}
		}
	}

	Send2FAMail = async () => {
		this.hideLoader()

		try {
			await new Promise(resolve => setTimeout(resolve, 3000))
			const response = await Axios(`/Partner/Send2FAMail?username=${this.props.values.login?.replace(/\D/g, '') || ''}`)

			if (response?.status === 200 && response?.data?.success === true) {
				history.push('/auth/login')
			} else {
				this.hideModal()
			}
		} catch (error) {
			console.log('error', error)
		} finally {
			this.hideLoader()
		}
	}

	render() {
		const { t, classes, values, errors, touched, disabledForm, handleSubmit, handleCancel, handleChange, setFieldTouched } = this.props

		const { openModal, loading } = this.state

		const change = (name, e) => {
			e.persist()
			handleChange(e)
			setFieldTouched(name, true, false)
		}

		return (
			<Fragment>
				{openModal && (
					<ModalResendEmail
						open={openModal}
						loading={loading}
						handleClose={() => this.hideModal()}
						handleSendEmail={() => this.Send2FAMail()}
						closeByClick={() => this.hideModal()}
						maxWidth="sm"
						minWidth="sm"
						shadow={3}
						email=""
					/>
				)}
				<Grid>
					<label className={styles.font}>{t('TITLE_LOGIN_RESET_PASSWORD')}</label>
				</Grid>
				<GridContainer>
					<GridItem xs={12} style={{ marginTop: '7px' }}>
						<CustomInput
							error={touched.login && Boolean(errors.login)}
							id="login"
							name="login"
							labelText={t('FIELD_DOCUMENT_CPF')}
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								value: values.login,
								onChange: change.bind(null, 'login'),
								disabled: disabledForm,
								inputComponent: CPFMaskCustom,
								endAdornment: (
									<InputAdornment position="end">
										<Face className={classes.inputAdornmentIcon} />
									</InputAdornment>
								),
							}}
						/>
					</GridItem>
					<GridItem xs={12} style={{ marginTop: '7px' }}>
						{this.props.requestPasswordReset && this.props.requestPasswordReset.success && (
							<>
								<div style={{ color: '#3C4858', fontWeight: '900', fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif" }}>
									A senha será enviada para o e-mail cadastrado
									<br></br>
								</div>
								<div style={{ color: 'black' }}>{this.props.requestPasswordReset.data}</div>
							</>
						)}
					</GridItem>
				</GridContainer>
				<GridContainer style={{ paddingTop: '45px', textAlign: 'center' }}>
					<GridItem xs={12} style={{ display: 'flex' }}>
						<Grid style={{ marginRight: '20px' }}>
							<Button
								style={{
									width: '145px',
									fontSize: 15,
									height: 35,
									padding: '0 15px',
								}}
								size="sm"
								variant="contained"
								color="primary"
								//disabled={disabledForm}
								className={styles.Button}
								onClick={handleSubmit}
							>
								{t('LOGIN_SEND')}
							</Button>
						</Grid>
						<Grid>
							<Button
								color="greenBorderButtonColor"
								style={{
									width: '145px',
									fontSize: 15,
									height: 36,
									padding: '0 15px',
									borderRadius: '6px',
								}}
								onClick={handleCancel}
							>
								{t('BUTTON_CANCEL')}
							</Button>
						</Grid>
					</GridItem>
				</GridContainer>
			</Fragment>
		)
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)

const mapStateToProps = state => {
	return {
		requestPasswordReset: state.users.requestPasswordReset,
		resetPassword: state.users.resetPassword,
		resetPasswordFailed: state.users.resetPasswordFailed,
	}
}

RequestPasswordReset.propTypes = {
	classes: PropTypes.object,
	t: PropTypes.func,
	values: PropTypes.object,
	errors: PropTypes.object,
	touched: PropTypes.object,
	disabledForm: PropTypes.bool,
	handleSubmit: PropTypes.func,
	handleCancel: PropTypes.func,
	handleChange: PropTypes.func,
	setFieldTouched: PropTypes.func,
	resetPasswordFailed: PropTypes.object,
	requestPasswordReset: PropTypes.object,
}

export default compose(
	withTranslation(),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(extendedFormsStyle),
)(RequestPasswordReset)
