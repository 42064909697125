/* eslint-disable */
import { Grid } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Popper from "@material-ui/core/Popper";
import Button from 'components/CustomButtons/Button.jsx'
import CustomDateNew from 'components/CustomDateNew'
import { makeStyles, createStyles } from "@material-ui/core/styles";

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useAccounts } from '../../hooks/useAccounts'
import * as S from './styles'

import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'
import {
	germiniDefaultColors,
	grayColor,
} from 'assets/jss/material-dashboard-pro-react'


const statusOptions = [
	{ key: false, label: 'Inativos' },
	{ key: true, label: 'Ativos' },
]

const SearchForm = () => {
	const { setValueTypeFilter } = useAccounts()
	const [themeColor, setThemeColor] = useState()
	const [selectedStatus, setSelectedStatus] = useState('')

	const { control, handleSubmit, watch, reset, setValue } = useForm({
		defaultValues: {
			period: {},
		},
	})

	const watchAllFields = watch()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		if (moment(watchAllFields.dateFilterTo).isValid()) {
			focousOut()
		}
	}, [watchAllFields.dateFilterTo])

	const { t } = useTranslation()

	const handleClearFilter = () => {
		setValueTypeFilter({
			dateFilterFrom: null,
			dateFilterTo: null,
			accountName: '',
			status: '',
		})

		setSelectedStatus(null)
		reset({
			dateFilterFrom: null,
			dateFilterTo: null,
			accountName: '',
			status: '',
		})
	}


	const onSubmitFilter = (data) => {
		setValueTypeFilter({
			dateFilterFrom: data.dateFilterFrom
				? data.dateFilterFrom.format('DD/MM/YYYY')
				: null,
			dateFilterTo: data.dateFilterTo
				? data.dateFilterTo.format('DD/MM/YYYY')
				: null,
			accountName: data.accountName ? data.accountName : null,
			status: data.status ? data.status : null,
		})
	}

	const beforeValidation = (currentDate) => {
		const today = moment()

		const beforeDate = moment(watchAllFields.dateFilterFrom).format(
			'DD/MM/YYYY',
		)
		const endDate = moment(watchAllFields.dateFilterTo).format('DD/MM/YYYY')

		if (moment(beforeDate, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(moment(endDate, 'DD/MM/YYYY'))) {
				return false
			}
		}

		return currentDate.isBefore(today)
	}

	const afterValidation = (current) => {
		const today = moment()

		const beforeDate = moment(watchAllFields.dateFilterFrom).format(
			'DD/MM/YYYY',
		)

		return (
			current.isAfter(moment(beforeDate, 'DD/MM/YYYY')) &&
			current.isBefore(today)
		)
	}

	const focousOut = () => {
		const endDate = moment(watchAllFields.dateFilterTo).format('DD/MM/YYYY')

		if (!moment(endDate, 'DD/MM/YYYY').isValid()) {
			setValue('dateFilterTo', '')
		}
		if (!afterValidation(moment(endDate, 'DD/MM/YYYY'), 'DD/MM/YYYY')) {
			setValue('dateFilterTo', '')
		}
	}

	const CustomPopper = function (props) {
		const classesPoper = useStyles();
		return <Popper {...props} className={classesPoper.root} placement="bottom" />;
	};


	const customSelectStyles = {
		control: (base, state) => ({
			...base,
			input: { height: '25px' },
			boxShadow: state.isFocused ? 0 : 0,
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
			'&:hover': {
				borderColor: state.isFocused ? grayColor[2] : base.borderColor,
			},
		}),
	}

	const useStyles = makeStyles((theme) =>
		createStyles({
			root: {
				"& .MuiAutocomplete-listbox": {
					"& :hover": {
						color: "#ffffff",
						backgroundColor: themeColor &&
							themeColor.structure &&
							themeColor.structure.backgroundColor
							? themeColor.structure.backgroundColor + ' !important'
							: `#fff`
					},
					"& li": {
						backgroundColor: "#ffffff",
					}
				}
			},
		})
	);

	const handleSelectedStatus = (value, event) => {
		event(value.key.toString())
		setSelectedStatus(value)
	}

	return (
		<S.Container themeColor={themeColor}>
			<form
				onSubmit={handleSubmit(onSubmitFilter)}
				autoComplete="off"
				style={{
					padding: '20px 15px',
					paddingTop: '21px',
				}}
			>
				<Grid container spacing={4}>
					<S.CustomGridDate item xs={12} sm={5} md={5} lg={5}>
						<Controller
							as={
								<CustomDateNew
									locale="pt-br"
									maxDate={new Date().getTime()}
									isValidDate={beforeValidation}
									enableDates={true}
									label={t('DATETIME_FROM')}
								/>
							}
							name="dateFilterFrom"
							control={control}
							defaultValue={null}
						/>
						<Controller
							as={
								<CustomDateNew
									locale="pt-br"
									maxDate={new Date().getTime()}
									isValidDate={afterValidation}
									enableDates={true}
									label={t('DATETIME_TO')}
									marginLeft={'15px'}
								/>
							}
							name="dateFilterTo"
							control={control}
							defaultValue={null}
							onChange={(event) => {
								return event[0]
							}}
						/>
					</S.CustomGridDate>

					<Grid item xs={12} sm={4} md={3} lg={5}>
						<Controller
							name="accountName"
							control={control}
							render={({ value, onChange }) => (
								<TextField
									onChange={onChange}
									value={value}
									id="accountName"
									name="accountName"
									label={t('ACCOUNT_NAME')}
									variant="outlined"
									size="small"
									fullWidth
									disabled={false}
									style={{ backgroundColor: '#fff' }}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12} sm={4} md={3} lg={2}>
						<Controller
							onChange={([, data]) => data}
							name="status"
							control={control}
							render={({ onChange }) => (
								<Autocomplete
									styles={customSelectStyles}
									id="combo-box-demo"
									options={statusOptions}
									getOptionLabel={(option) =>
										option.label ? option.label : ''
									}
									PopperComponent={CustomPopper}
									disableClearable={true}
									value={selectedStatus}
									renderInput={(params) => (
										<div ref={params.InputProps.ref}>
											<TextField
												style={{
													backgroundColor: '#ffffff',
													width: '100%',
												}}
												{...params}
												noOptionsMessage={() =>
													t('RESULTS_NOT_FOUND')
												}
												label={t('FIELD_STATUS')}
												variant="outlined"
												size="small"
											/>
										</div>
									)}
									onChange={(e, data) => handleSelectedStatus(data, onChange)}
								/>
							)}
						/>
					</Grid>
				</Grid>

				<S.CustomButtons>
					<Button
						size="sm"
						variant="contained"
						color="greenBorderButtonColor"
						style={{ margin: 0 }}
						onClick={() => handleClearFilter()}
					>
						{t('BUTTON_CLEAN_FILTER')}
					</Button>
					<Button
						size="sm"
						variant="contained"
						color="greenButtonColor"
						type="submit"
						style={{ margin: 0 }}
					>
						{t('BUTTON_FIND')}
					</Button>
				</S.CustomButtons>
			</form>
		</S.Container>
	)
}

export default SearchForm
