import styled from 'styled-components/macro'
import { Grid } from '@material-ui/core'
export const MyMainTitle = styled.div`
	font-size: 18px;
	font-weight: bold;
`

export const MyTitle = styled.div`
	font-size: 15px;
	font-weight: bold;
`

export const MyBoldText = styled.span`
	color: #23429a;
	border-bottom: 2px dotted #23429a;
`
export const ContainFields = styled(Grid)`
	display: flex;
`

export const Container = styled.div`

	label {
		color: #717171;
		margin-left: 15px;
	}

	.MuiTypography-body1 {
		border-bottom: 2px dotted #23429a;
		margin-left: 5px;
	}
`
export const GridContain = styled.div``

export const FieldContain = styled.form`
	display: flex;
	button {
		border: none;
		background-color: transparent;
		cursor: pointer;
		color: #23429a;
		font-size: 100%;
		height: 100%;
		position: absolute;
		padding-top: 18px;
		width: 35px;
	}
`

export const InputBox = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 18px;
`
export const Inputs = styled.input`
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	color: #717171;
	height: 35px;
	padding: 0 15px;
	overflow: hidden;
    text-overflow: ellipsis;
`
export const ShowValue = styled.div`
	background-color: #f4f4f4;
	border: none;
	border-radius: 5px;
	color: #717171;
	height: 35px;
	padding: 0 15px;
	display: flex;
    align-items: center;
`
