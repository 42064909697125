import React from 'react'
import * as S from '../styles'
import { Link } from 'react-router-dom'
import { Tooltip } from '@mui/material'

export const Columns = t => {
	return [
		{
			title: t(getTitle()),
			field: 'name',
			width: '48%',
			cellStyle: {
				width: '48%',
				fontSize: '14px',
			},
			headerStyle: {
				width: '48%',
				paddingLeft: '25px',
			},
		},
		{
			title: t('Geração'),
			field: 'isConfigPoints',
			width: '15%',
			cellStyle: {
				width: '15%',
				textAlign: 'left !important',
			},
			headerStyle: {
				width: '15%',
				paddingLeft: '25px',
			},
			// eslint-disable-next-line react/display-name
			render: rowData => (
				<S.IconsDiv>
					{rowData?.typeOfBenefitPoints > 0 ? (
						<Tooltip arrow id="tooltip-top" title={t(rowData?.configPoints?.value)} placement="top">
							<i className="icon-gerar_pontos" />
						</Tooltip>
					) : (
						<b>-</b>
					)}
				</S.IconsDiv>
			),
		},
		{
			title: t('Resgate'),
			field: 'isConfigRedeem',
			width: '15%',
			cellStyle: {
				width: '15%',
				textAlign: 'left !important',
			},
			headerStyle: {
				width: '15%',
				paddingLeft: '25px',
			},
			// eslint-disable-next-line react/display-name
			render: rowData => (
				<S.IconsDiv>
					{rowData?.typeOfBenefitRedeem > 0 ? (
						<Tooltip arrow id="tooltip-top" title={t(rowData?.configRedeem?.value)} placement="top">
							<i className="icon-resgatar_pontos" />
						</Tooltip>
					) : (
						<b>-</b>
					)}
				</S.IconsDiv>
			),
		},
		{
			title: t('Cashback'),
			field: 'isConfigCashback',
			width: '15%',
			cellStyle: {
				width: '15%',
				textAlign: 'left !important',
			},
			headerStyle: {
				width: '15%',
				paddingLeft: '25px',
			},
			// eslint-disable-next-line react/display-name
			render: rowData => {
				return (
					<S.IconsDiv>
						{rowData?.typeOfBenefitCashback > 0 ? (
							<Tooltip arrow id="tooltip-top" title={t(TypeCashback(rowData?.configCashback?.value))} placement="top">
								<i className="icon-menu_cashback1" />
							</Tooltip>
						) : (
							<b>-</b>
						)}
					</S.IconsDiv>
				)
			},
		},
		{
			title: 'Ação',
			field: 'actions',
			cellStyle: {
				width: '50px',
				maxWidth: '50px',
				minWidth: '50px',
			},
			sorting: false,
			// eslint-disable-next-line react/display-name
			render: rowData => (
				<div
					style={{
						display: 'flex',
						justifyContent: 'start',
					}}
				>
					<Tooltip arrow id="tooltip-top" title={'Parametrizar'} placement="top">
						<Link to={`/category/parametrization/${rowData.id}`}>
							<S.ButtonAction>
								<i className="icon-i_configuracao" />
							</S.ButtonAction>
						</Link>
					</Tooltip>
				</div>
			),
		},
	]
}

function getTitle() {
	const grouper = localStorage.getItem('Grouper')

	if (grouper === '1') {
		return 'MENU_CATEGORY'
	} else if (grouper === '2') {
		return 'FAMILY'
	} else if (grouper === '3') {
		return 'MANUFACTURE'
	} else if (grouper === '4') {
		return 'FIELD_BRAND'
	} else {
		return '--'
	}
}

const TypeCashback = row => {
	if (row === 'Manual') {
		return 'FIELD_PERCENT'
	}

	if (row === 'Auto') {
		return 'Standard'
	}

	return ''
}
