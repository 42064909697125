/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers'
import * as moment from 'moment'
import { Grid, Fade, TextField } from '@material-ui/core'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import { ModalContainer, PaperContainer } from './styles'
import * as S from './styles'
import Button from 'components/CustomButtons/Button.jsx'
import Loader from 'components/Loader/Loader'
import { PartnerValidatePartner } from '../../../redux/api/partner'
const textType = [
	{
		text: 'Para prosseguir com o estorno é necessário informar a senha.',
		type: 'refund',
	},
]

function Confirm({
	className,
	closeByClick,
	error,
	maxWidth,
	minWidth,
	onCancel,
	open,
	parameter,
	shadow,
	t,
	text,
	title = 'Confirmar',
	type,
	enablePaymentMethods,
}) {
	const updateLockdownTime = () => {
		const time = moment(window.localStorage.getItem('managerPWDCheck'))
		const countDownTime = checkValidateCountDown(time)
		setCountDownTime(countDownTime)
	}

	const validationSchema = Yup.object().shape({
		password: Yup.string()
			.required('Senha obrigatória.')
			.nullable()
			.test(
				'checkPwd',
				'Senha inválida, tente novamente.',
				async value => {
					if (showWrongPassword) {
						let res = await validateManagerPwd(value)
						return res
					}
					return true
				},
			),
	})

	const [displayText, setDisplayText] = useState({})
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [countDownTime, setCountDownTime] = useState('')
	const [pwdCounter, setPwdCounter] = useState(0)
	const [showWrongPassword, setShowWrongPassword] = useState(false)

	async function removeForm(value) {
		setLoading(true)
		const { password } = value

		try {
			const response = await PartnerValidatePartner({
				password: password,
			})

			if (response?.data?.errors) {
				setErrorMessage(response?.data?.errors[0]?.message)
			}

			if (response === true) {
				enablePaymentMethods(response)
				onCancel()
			}
		} catch (error) {
			console.log(error)
		} finally {
			setLoading(false)
		}
	}

	function ModalType(type) {
		if (type) {
			if (!text) {
				return textType.forEach(item => {
					if (item.type == type) {
						setDisplayText(item.text)
					}
				})
			}
			return setDisplayText(text)
		}
		if (text) {
			return setDisplayText(text)
		}
		setDisplayText(
			'Para realizar a aprovação da transferência, insira a sua senha.',
		)
	}

	const { handleSubmit, control, errors, watch } = useForm({
		resolver: yupResolver(validationSchema),
	})

	const watchAllFields = watch()

	useEffect(() => {
		updateLockdownTime()
	}, [])

	useEffect(() => {
		setShowWrongPassword(false)
	}, [watchAllFields.password])

	useEffect(() => {
		ModalType(type)
	}, [type, text, parameter, error])

	function checkValidateCountDown(time) {
		let now = moment()
		let diff = time.diff(now)

		if (Object.is(NaN, diff) || diff < 0) return ''

		// if (diff < 0) return ''

		const tempTime = moment.duration(diff)
		const countDown = tempTime.minutes() + ':' + tempTime.seconds()
		return countDown
	}

	async function validateManagerPwd(password) {
		updateLockdownTime()

		const response = await PartnerValidatePartner({ password: password })
		if (response) return true

		if (pwdCounter === 5) {
			window.localStorage.setItem(
				'managerPWDCheck',
				moment().add('30', 'm'),
			)
		}
		setPwdCounter(pwdCounter + 1)
	}

	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			minwidth={minWidth}
			disableEnforceFocus
		>
			{open && (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={'0px 60px'} maxwidth={maxWidth}>
						{loading && <Loader />}
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '50px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<S.Text_Focused>{displayText}</S.Text_Focused>
						</GridItem>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(removeForm)}
						>
							<GridContainer style={{ marginTop: '30px' }}>
								<Grid
									item
									xs={8}
									style={{
										margin: '0 auto',
										paddingLeft: '10px',
									}}
								>
									<Controller
										as={
											<TextField
												fullWidth
												//style={{ marginRight: '50px' }}
												label="Senha *"
												type="password"
												error={
													!!errors.password ||
													t(errorMessage)
												}
												helperText={
													errors?.password?.message ||
													t(errorMessage)
												}
											/>
										}
										defaultValue=""
										name="password"
										control={control}
									></Controller>
								</Grid>
							</GridContainer>
							<S.Text_Error>{t(error ? error : '')}</S.Text_Error>
							<GridContainer
								style={{
									padding: '10px 0 0 20px',
									marginBottom: '50px',
									marginTop: '35px',
								}}
							>
								<GridItem
									xs={12}
									sm={12}
									style={{ textAlign: 'center' }}
								>
									<Button
										size="sm"
										variant="contained"
										color={'greenButtonColor'}
										style={{
											marginRight: 22,
											height: '42px',
										}}
										type="submit"
										onClick={() =>
											setShowWrongPassword(true)
										}
									>
										{title}
									</Button>

									<Button
										size="sm"
										variant="contained"
										color={'greenBorderButtonColor'}
										style={{
											width: '112px',
											height: '42px',
										}}
										onClick={() => {
											onCancel()
										}}
									>
										Cancelar
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</PaperContainer>
				</Fade>
			)}
		</ModalContainer>
	)
}

Confirm.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	standardFocus: PropTypes.string,
	text: PropTypes.string,
	password: PropTypes.string,
	parameter: PropTypes.string,
	type: PropTypes.string,
	error: PropTypes.string,
	onDelete: PropTypes.func,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
	enablePaymentMethods: PropTypes.func,
}

Confirm.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	standardFocus: 'no',
	children: null,
	open: false,
	closeByClick: null,
	onDelete: null,
	maxWidth: '',
	minWidth: '',
	text: '',
	parameter: '',
	password: '',
	error: '',
	type: '',
	onConfirm: null,
	onCancel: null,
}

export default withTranslation()(Confirm)
