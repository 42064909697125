export function formatPTBRValue(value) {
	const data = new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
		minimumFractionDigits: 2,
		maximumFractionDigits: 3,
	})

	return value ? data.format(value) : 'R$ 0'
}

export function formatUSDValue(value) {
	const data = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
		maximumFractionDigits: 3,
	})
	return value ? `SD${data.format(value)}` : 'SD 0'
}

export function formatPercentage(value) {
	const fractionValue = value / 100
	const data = new Intl.NumberFormat('pt-BR', {
		style: 'percent',
		maximumFractionDigits: 2,
	})
	return value ? data.format(fractionValue) : '0%'
}
