import { makeStyles } from '@material-ui/core/styles'
import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx'


export const useStyles = makeStyles((theme) => ({
	tableTitle: {
		padding: 0,
		'& h6': {
			color: '#3C4858',
			fontSize: '18px !important',
			fontWeight: 800 + ' !important',
		},
		'& span': {
			color: primaryColor[0],
		},
		minHeight: '0px',
	},

	toolbar: {
		padding: 0,
		'& h6': {
			color: '#3C4858',
			fontSize: '16px !important',
			fontWeight: 800 + ' !important',
		},
		'& span': {
			color: primaryColor[0],
		},
		minHeight: '0px',
		width: '90%',
		'& .MuiCheckbox-colorSecondary.Mui-checked': {
			color: '#21409a',
		},
		'& .MuiTablePagination-toolbar': {
			paddingRight: '42px',
		},

		'& .MuiInput-root': {
			height: '38px',
		}
	},

	headerStyle: {
		//color: primaryColor[0],
		fontWeight: '700',
		fontSize: '1em',
		background: '#E9E9E9 ',
		padding: '10px 16px',
		'&:first-child': {
			borderRadius: '10px 0 0 0',
		},
		'&:last-child': {
			borderRadius: '0 10px 0 0',
		},
		'.icon-lupa': {
			color: '#585858',
			fontSize: '21px'
		}
	},
	tableCell: {
		padding: '10px 16px',
		background: 'rgb(255, 255, 255)',
		'&:first-child': {
			background: 'rgb(255, 255, 255)',
		},
		'&:last-child': {
			background: 'rgb(255, 255, 255)',
		},
		// Alterar background da linha selecionada
		'&:nth-child(odd)': {
			background: 'rgb(255, 255, 255)',
		},
	},
	tableBody: {
		'& tbody': {
			border: 'none',
			background: 'rgb(255, 255, 255)',
		},
		'& tfoot': {
			'& tr': {
				'& td': {
					borderBottom: 'none',
				},
			},
		},
	},
	acttions: {
		color: 'blue',
	},
}))


