import React, { useEffect, useState } from 'react'
import { CellTooBar, Container, IconInfo } from './style'
import { EmptyTable } from './style'

import MaterialTable, {
	MTableCell,
	MTableToolbar,
	MTableHeader,
} from 'material-table'
import Button from 'components/CustomButtons/Button.jsx'

import { Paper, TablePagination, Tooltip } from '@material-ui/core'
import { useStyles } from './utils'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

const TableColumns = ({ data, paging, handleOnCancelDelete }) => {
	const [list, setList] = useState([])
	const [selected, setSelected] = useState({})
	const classes = useStyles()
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		setList(data)
	}, [data])

	useEffect(() => {
		list.sort(compare)
	}, [list])

	function compare(a) {
		if (a.status !== 1) {
			return -1
		}
		return 1
	}

	const handleSelect = (selected) => {
		setSelected(selected)
	}

	useEffect(() => {
		list.sort(compare)
		getTotalInvalidValues()
	}, [list])

	const getTextCol = () => {
		if (
			themeColor &&
			themeColor.titles &&
			themeColor.titles.highlight &&
			themeColor.titles.highlight.textColor
		) {
			return themeColor.titles.highlight.textColor + ' !important'
		}
		return `#fff`
	}
	const getStatusColor = (status) => ({
		height: '45px',
		font: 'normal normal normal 14 Roboto',
		color: [2, 3].includes(status) ? '#717171' : `unset`,
		display: 'flex',
		alignItems: 'center',
	})

	const columns = [
		{
			title: 'Linha',
			searchable: true,
			cellStyle: {
				background: 'rgb(255, 255, 255)',
				width: '5%',
				maxWidth: '6%',
				minWidth: '4%',
			},
			headerStyle: {
				color: getTextCol() + ' !important',
				fontSize: '15px',
			},
			render: (props) =>
				props.isRemoved ? null : (
					<div style={getStatusColor(props.status)}>
						{props.rowNumber}
					</div>
				),
		},
		{
			title: 'CPF / Cód. do participante',
			field: 'code_CPF',
			type: 'numeric',
			width: '4%',

			searchable: true,
			cellStyle: {
				background: 'rgb(255, 255, 255)',
				textAlign: 'left',
				paddingLeft: '25px',
			},
			headerStyle: {
				textAlign: 'left',
				paddingLeft: '25px',
				fontSize: '15px',
			},

			customFilterAndSearch: (term, props) =>
				props.code_CPF.includes(term.replace(/[^0-9]/g, '')),
			render: (props) =>
				props.isRemoved ? null : (
					<div
						style={
							props.isRemoved
								? { display: 'none' }
								: {
										display: 'flex',
										alignItems: 'center',
										fontSize: '15px',
										color: props.tableData.checked
											? '#DE2E2E'
											: props.status === 2 ||
											  props.status === 3 ||
											  props.status === 5 ||
											  props.status === 6
											? '#E58B00'
											: '#08993A',
								  }
						}
					>
						{props.code_CPF.replace(
							/(\d{3})(\d{3})(\d{3})(\d{2})/,
							'$1.$2.$3-$4',
						)}
						{props.status === 2 ||
						props.status === 3 ||
						props.status === 5 ||
						props.status === 6 ? (
							<IconInfo>
								<Tooltip
									title={
										props.status === 6
											? 'Registro duplicado'
											: 'Registro com erro'
									}
									color="#000000"
									arrow
									placement="top-start"
								>
									<IconInfo className="icon-alerta" />
								</Tooltip>
							</IconInfo>
						) : null}
					</div>
				),
		},
		{
			title: 'Valor',
			field: 'value',
			type: 'numeric',
			width: '4%',
			height: '45px',
			searchable: true,
			cellStyle: {
				background: 'rgb(255, 255, 255)',
				textAlign: 'left',
				width: '30%',
				maxWidth: '30%',
				minWidth: '25%',
			},
			headerStyle: {
				textAlign: 'left',
				color: getTextCol(),
				fontSize: '15px',
			},
			render: (props) =>
				props.isRemoved ? (
					''
				) : (
					<div
						style={
							props.isRemoved
								? { display: 'none' }
								: {
										display: 'flex',
										alignItems: 'center',
										fontSize: '15px',
										color: props.tableData.checked
											? '#DE2E2E'
											: props.status === 2 ||
											  props.status === 3 ||
											  props.status === 5 ||
											  props.status === 6
											? '#E58B00'
											: '#08993A',
								  }
						}
					>
						{props.status === 5 || props.value === 0
							? props.originalValue.toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
							  })
							: props.value.toLocaleString('pt-br', {
									style: 'currency',
									currency: 'BRL',
							  })}
					</div>
				),
		},
	]

	// Retornar total de valores invalidos
	const getTotalInvalidValues = () => {
		let total = 0
		list.forEach((item) => {
			if (item.status !== 1) {
				total += 1
			}
		})
		// setCount(total)
		return total
	}

	const btnBGCol = () => {
		if (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.backgroundColor
		) {
			return themeColor.navigation.buttons.backgroundColor + ' !important'
		}
		return ``
	}
	const titleColor = () => {
		if (
			themeColor &&
			themeColor.titles &&
			themeColor.titles.highlight &&
			themeColor.titles.highlight.textColor
		) {
			return themeColor.titles.highlight.textColor
		}
		return ``
	}

	const btnTextCol = () => {
		if (
			themeColor &&
			themeColor.navigation &&
			themeColor.navigation.buttons &&
			themeColor.navigation.buttons.textColor
		) {
			return themeColor.navigation.buttons.textColor + ' !important'
		}
		return ``
	}

	return (
		<Container colorTitle={titleColor()} btnBGCol={btnBGCol()}>
			<MaterialTable
				title="Participantes adicionados"
				columns={columns}
				data={list}
				onSelectionChange={(rows) => {
					handleSelect(rows)
				}}
				components={{
					Container: (props) => (
						<Paper
							{...props}
							elevation={0}
							classes={{ root: classes.tableBody }}
						/>
					),

					Toolbar: (props) => (
						<CellTooBar>
							<MTableToolbar
								{...props}
								classes={{ root: classes.toolbar }}
							/>
							<Button
								className={'btnTable'}
								size="sm"
								variant="contained"
								color="greenButtonColor"
								onClick={(props) => {
									if (selected.length > 0) {
										handleOnCancelDelete(selected)
									}
								}}
							>
								Remover registro
							</Button>
						</CellTooBar>
					),
					Cell: (props) => (
						<MTableCell
							{...props}
							classes={{ root: classes.tableCell }}
						/>
					),
					Header: (props) => (
						<MTableHeader
							{...props}
							classes={{ header: classes.headerStyle }}
						/>
					),
					Pagination: (props) => (
						<TablePagination
							{...props}
							labelRowsPerPage=""
							classes={{
								root: classes.pagination,
								actions: classes.acttions,
							}}
						/>
					),
				}}
				icons={{
					Clear: () => (
						<i
							className="icon-lupa"
							style={{ color: `${btnTextCol()} !important` }}
						/>
					),
					ResetSearch: () => (
						<i
							className="icon-lupa"
							style={{
								fontSize: '20px',
								color: `${btnTextCol()} !important`,
							}}
						/>
					),
					Search: () => '',
				}}
				localization={{
					toolbar: {
						searchPlaceholder: 'Buscar',
					},
					header: {
						actions: 'Ações',
					},
					pagination: {
						labelRowsSelect: 'linhas',
						labelDisplayedRows: '{from}-{to} de {count}',
						firstAriaLabel: 'Primeira página',
						firstTooltip: '',
						previousAriaLabel: 'Anterior',
						previousTooltip: '',
						nextAriaLabel: 'Próxima',
						nextTooltip: '',
						lastAriaLabel: 'Última página',
						lastTooltip: '',
					},

					body: {
						emptyDataSourceMessage: (
							<EmptyTable>
								<i
									className="icon-informacao"
									style={{ color: '#B4B4B4' }}
								/>
								<span>Nenhum participante adicionado</span>
							</EmptyTable>
						),
					},
				}}
				cellStyle={(rowData) => ({
					backgroundColor: '#000',
					color: rowData.tableData.checked ? 'red !important' : '',
				})}
				options={{
					rowStyle: (rowData) => ({
						backgroundColor: 'rgb(255, 255, 255)',
						color: rowData.tableData.checked
							? 'red !important'
							: '',
					}),
					emptyRowsWhenPaging: false,
					paging: paging,
					toolBar: true,
					selection: true,
					search: true,
					pageSizeOptions: [10, 20, 30, 40, 50],
					pageSize: 10,
					sorting: false,
					showTextRowsSelected: false,
					draggable: false,
				}}
			/>
		</Container>
	)
}
export default TableColumns
