
export const VerifyLth = (value) => {
	if (value === null) return;
	if (value === '') return 'Obrigatório';

	if (value <= 0) return 'Valor deve ser maior que 0';

	if (value > 100) return 'Valor deve ser menor que 100';

	return '';
}

