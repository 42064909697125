import React from 'react'
import * as S from './styles'

const Atention = () => {

    return (
        <S.Container>
            <div>
                <S.Icon><i className='icon-i_atencao' /></S.Icon>
                <S.Text>Não há divisões a serem exibidas</S.Text>
            </div>
        </S.Container>
    )
}

export default Atention
