/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import * as S from './styles'

//@ components
import Filters from './components/Filter'
import NoDivisionTab from './components/NoDivisionTab'
import GeneralTab from './components/generalTab'
import DivisionTab from './components/DivisionTab'

const Generation = ({
	typeOfBenefit,
	productData,
	grouper,
	circularProgress,
	parameterization,
	setDivision,
	selectedDivision,
	setSelectedDivision,
	setParameterization,
	setMultipleParameterization,
	typeOfBenefitPoints,
	setTypeOfBenefitPoints,
	selectedDivisionIdGen,
	setSelectedDivisionIdGen,
	openCalcMemory,
	setOpenCalcMemory,
	multiple,
	setModalCancel,
	setModalParameterization,
	setMultiple,
	editMode,
	setEditMode,
}) => {
	const [generalTab, setGeneralTab] = useState(false)
	const [byDivisionTab, setByDivisionTab] = useState(false)
	const [noDivisionTab, setNoDivisionTab] = useState(false)

	const sharedProps = {
		multiple,
		setMultiple,
		setModalCancel,
		selectedDivision,
		setSelectedDivision,
		setModalParameterization,
		typeOfBenefitPoints,
		setTypeOfBenefitPoints,
		typeOfBenefit,
		setGeneralTab,
		productData,
		editMode,
		circularProgress,
		setByDivisionTab,
		setNoDivisionTab,
		setEditMode,
		grouper,
		openCalcMemory,
		setOpenCalcMemory,
		selectedDivisionIdGen,
		setSelectedDivisionIdGen,
		parameterization,
		setDivision,
		setParameterization,
		setMultipleParameterization,
	}

	return (
		<S.Container>
			<Filters {...sharedProps} />
			<S.Components>
				{noDivisionTab && <NoDivisionTab {...sharedProps} />}
				{generalTab && <GeneralTab {...sharedProps} />}
				{byDivisionTab && <DivisionTab {...sharedProps} />}
			</S.Components>
		</S.Container>
	)
}

export default Generation
