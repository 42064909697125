import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import moment from 'moment'

// @ Components
import CustomDate from 'components/CustomDateNew'
import { FormField } from 'components/FormField/styles'
import Button from 'components/CustomButtons/Button.jsx'

// @ Material UI
import { Grid } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'

// @ Utils
import { CssTextField } from '../../../../../../utils'

// @ Utils and Styles
import * as S from '../styles'
import * as Styles from './styles'
import {
	periodOptions,
	defaultValues,
	Open,
	IsDisabled,
	handleChangePeriod,
} from './utils'

const SearchForm = ({
	tableRef,
	tab,
	control,
	handleSubmit,
	reset,
	watch,
	setValue,
}) => {
	const { t } = useTranslation()

	const { period } = watch()

	useEffect(() => {
		period && handleChangePeriod(period, setValue)
	}, [period])

	const handleSearch = e => {
		tableRef.current && tableRef.current.onQueryChange(e)
	}

	const isValid = current => {
		const startDate = watch('dateFrom')
		const today = moment()
		return current.isAfter(startDate) && current.isBefore(today)
	}

	const validation = currentDate => {
		const dateTo = watch('dateTo')
		const today = moment()
		if (moment(dateTo, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(dateTo)) {
				return false
			}
		}
		return currentDate.isBefore(today)
	}

	const focousOut = () => {
		const dateTo = watch('dateTo')
		if (!moment(dateTo, 'DD/MM/YYYY').isValid()) {
			setValue('dateTo', '')
		}
		if (!isValid(moment(dateTo), 'DD/MM/YYYY')) {
			setValue('dateTo', '')
		}
	}

	return (
		<S.CustomCard>
			<S.TabPanel>
				<Styles.Filter>
					<Grid item xs={12}>
						{t('SEARCH_OPTIONS')}
					</Grid>
				</Styles.Filter>
				<Grid container spacing={'20px'}>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<Controller
							name="period"
							control={control}
							defaultValue={[]}
							render={({ onChange, value }) => (
								<Autocomplete
									disablePortal
									id="combo-box-demo"
									size="small"
									getOptionLabel={option =>
										option.label ? option.label : ''
									}
									options={periodOptions}
									value={value}
									disableClearable
									onChange={(e, value) => onChange(value)}
									renderInput={params => (
										<CssTextField
											size="small"
											style={{
												backgroundColor: '#ffffff',
												width: '100%',
											}}
											{...params}
											label={t(
												'TITLE_PARTICIPANTS_PERIOD_FILTER',
											)}
										/>
									)}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Controller
							name="dateFrom"
							defaultValue=""
							control={control}
							as={
								<CustomDate
									size="small"
									variant="outlined"
									label={t('DATETIME_FROM')}
									maxDate={new Date().getTime()}
									timeFormat={false}
									open={Open(watch('period'))}
									disabled={IsDisabled(watch('period'))}
									isValidDate={e => validation(e)}
								/>
							}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={2} lg={2}>
						<Controller
							name="dateTo"
							defaultValue=""
							control={control}
							as={
								<CustomDate
									size="small"
									variant="outlined"
									label={t('DATETIME_TO')}
									timeFormat={false}
									onBlur={focousOut}
									isValidDate={isValid}
									open={Open(watch('period'))}
									disabled={IsDisabled(watch('period'))}
									maxDate={new Date().getTime()}
								/>
							}
						/>
					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={4}>
						<Grid
							item
							xs={12}
							sm={12}
							md={tab === 0 ? 8 : 12}
							lg={tab === 0 ? 8 : 12}
						>
							<Controller
								name="history"
								control={control}
								defaultValue=""
								as={
									<FormField
										variant="outlined"
										style={{
											backgroundColor: '#ffffff',
											width: '100%',
										}}
										formControlProps={{
											fullWidth: true,
										}}
										size="small"
										label={t('Nome do arquivo')}
									/>
								}
							/>
						</Grid>
					</Grid>
				</Grid>
				<S.Buttons>
					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							gap: '0.813rem',
						}}
					>
						<Button
							size="sm"
							variant="contained"
							color="greenButtonColor"
							className="button-wrapper"
							onClick={handleSubmit(handleSearch)}
						>
							{t('BUTTON_FIND')}
						</Button>
						<Button
							size="sm"
							onClick={() => {
								reset(defaultValues)
								tableRef.current &&
									tableRef.current.onQueryChange(
										defaultValues,
									)
							}}
							className="button-wrapper"
							color="greenBorderButtonColor"
						>
							{t('BUTTON_CLEAN_FILTER')}
						</Button>
					</div>
				</S.Buttons>
			</S.TabPanel>
		</S.CustomCard>
	)
}

SearchForm.propTypes = {
	tableRef: PropTypes.func,
	getLogs: PropTypes.func,
	tab: PropTypes.number,
	control: PropTypes.any,
	handleSubmit: PropTypes.func,
	reset: PropTypes.func,
	watch: PropTypes.func,
	setValue: PropTypes.func,
}

export default SearchForm
