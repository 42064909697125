import Axios from 'axios'

export default class NegotiationService {
	async getNegotiations() {
		const response = await Axios.post(`/Negotiation/GetAll`)
		return response?.data
	}

	async getProgramRules() {
		const response = await Axios.get(`/ProgramRules/General`)
		return response?.data
	}
	async getFilteredNegotiations({ pageSize, page }) {
		const response = await Axios.get(`/Negotiation/GetAll?pageSize=${pageSize}&page=${page}`)
		return response?.data
	}

	// async getBusinessDivisions(partnedId) {
	// 	const response = await Axios.get(`/PartnerBusinessDivision/GetAll/${partnedId}`)
	// 	return response?.data
	// }
	async getBusinessDivisions() {
		const response = await Axios.get(`/Negotiation/GetAllBusinessDivision?ShowHeadOffice=true`)
		return response?.data
	}

	async getGroupers() {
		const response = await Axios.get(`/Negotiation/GetNegotiationGrouper`)
		return response?.data
	}

	async getGroupData(partnerId, groupType) {
		const filter = {
			Category: `GetAllCategoriesByNameAndPartner`,
			Family: `GetAllFamiliesByNameAndPartner`,
			Manufacturer: `GetAllManufacturersByNameAndPartner`,
			Brand: `GetAllBrandsByNameAndPartner`,
		}

		const query = `/Product/${filter[groupType]}?partnerId=${partnerId}`

		if (filter[groupType] && partnerId) {
			const response = await Axios.get(query)
			return response?.data
		}
	}

	async getProductInfo(productFilter = '') {
		let filter = ''
		const isNumber = !isNaN(Number(productFilter)) && productFilter.trim() !== ''

		if (isNumber) {
			filter = `/Product/GetProductsCatalog?SKU=${productFilter}&page=1&pageSize=10`
		} else {
			filter = `/Product/GetProductsCatalog?Name=${productFilter}&page=1&pageSize=10`
		}

		const response = await Axios.get(filter)
		return response
	}

	async validateDocument(document) {
		const response = await Axios.get(`/Consumer/VerifyDocument?cpfCnpj=${document}&isRegister=false`)
		return response?.data
	}

	async validateSelectedGroup(data) {
		const response = await Axios.post(`/Negotiation/ValidateConfigBenefitType`, data)
		return response?.data
	}

	async createNegotiation(data) {
		const response = await Axios.post(`/Negotiation`, data)
		return response?.data
	}

	async getNegotiationCampains() {
		const response = await Axios.get(`/NegotiationCampaign/GetAll`)
		return response?.data
	}

	async createNegotiationCampains(data) {
		const response = await Axios.post(`/NegotiationCampaign`, data)
		return response?.data
	}
}
