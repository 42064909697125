// Material
import { Grid } from '@material-ui/core'
// Components
import Section from 'components/Section'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
// Context
import { useAccounts } from '../../../../hooks/useAccounts'
import { Layout } from '../../Layout'
import { Title } from './styles'
import moment from 'moment'
import { StatusIcon } from '../../../List/StatusIcon'
import { numberFormatText, convertUTCToLocal } from 'utils/utils'
import { useEffect } from 'react'
import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'
import Button from 'components/CustomButtons/Button.jsx'
import {envs}  from '../../../../../../redux/api/windowServerData'



export function ModalReserved({ isOpen, reservedData }) {
	const { closeReserved } = useAccounts()
	const { t } = useTranslation()
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	return (
		<Layout isOpen={isOpen}>
			<form
				style={{
					width: '100%',
					//padding: '15px',
				}}
			>
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '25px',
						width: '100%',
					}}
				>
					<Section title={t('PERFORMANCE_CAMPAIGN_CARD_MOVIMENTATION')} style={{ flexGrow: 1, marginRight: ' 0.938rem' }} />

					<Button onClick={() => closeReserved()} color="greenButtonClose" style={{ margin: 0 }} size="sm">
						<i className="icon-ico_cancelar" style={{ marginRight: '.688rem' }} />
						Fechar
					</Button>
				</div>

				<Grid container spacing={2} xs={12} sm={12}>
					<Grid item xs={3} sm={3}>
						<Title themeColor={themeColor}>Data</Title>
						<div>
							{/* {reservedData &&
								moment(
									reservedData.createdAt,
									'YYYY-MM-DD',
								).format('DD/MM/YYYY')} */}

							{reservedData && moment(convertUTCToLocal(reservedData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}
						</div>
						<div style={{ fontStyle: 'italic', fontSize: 'small' }}>
							{/* {reservedData &&
								moment(
									reservedData.createdAt,
									'YYYY-MM-DD HH:mm',
								).format('HH:mm')} */}

							{reservedData && moment(convertUTCToLocal(reservedData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('HH:mm')}
						</div>
					</Grid>
					<Grid item xs={3} sm={3}>
						<Title themeColor={themeColor}>Operação</Title>
						<div>{reservedData.operation && t(reservedData.operation.value)}</div>
						<div style={{ fontStyle: 'italic', fontSize: 'small' }}>{reservedData && reservedData.bankAccountReserve}</div>
					</Grid>
					<Grid item xs={3} sm={3}>
						<Title themeColor={themeColor}>Valor</Title>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							{reservedData.status && <StatusIcon slug={reservedData.status.value} />}

							{numberFormatText(
								reservedData.value ? reservedData.value : '0',
								localStorage.getItem('currencySymbol')
									? `${localStorage.getItem('currencySymbol')}\n`
									: // : envs.REACT_APP_CURRENCY_SYMBOL,
									  envs.REACT_APP_CURRENCY_SYMBOL,
								2,
							)}

							{/* {reservedData.value && reservedData.value} */}
						</div>
					</Grid>

					<Grid item xs={3}>
						<Title themeColor={themeColor}>Status</Title>
						<div>{reservedData.status && t(reservedData.status.value)}</div>
					</Grid>

					<Grid item xs={12}>
						<Title themeColor={themeColor}>Descrição</Title>
						<div>{reservedData.description}</div>
					</Grid>
				</Grid>
			</form>
		</Layout>
	)
}
