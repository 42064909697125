import * as actionTypes from "./actionsTypes";
import * as API from "../api/transaction";
import { GetPartnersData, GetConsumer } from "./index";

import * as Loader from "./loader";

export const fetchTransactionsData = (data) => {
  return {
    type: actionTypes.FETCH_TRANSACTIONS_DATA,
    lsTransactionsData: data,
  };
};

export const postTransactionData = (data) => {
  return {
    type: actionTypes.POST_TRANSACTION_DATA,
    transactionPost: data,
  };
};

export const fetchTransactionById = (data) => {
  return {
    type: actionTypes.FETCH_TRANSACTION_BY_ID,
    transactionData: data,
  };
};

export const fetchTransactionDetails = (data) => {
  return {
    type: actionTypes.FETCH_TRANSACTION_DETAILS,
    transactionDetails: data,
  };
};

export const fetchTransactionChannelTypes = (data) => {
  return {
    type: actionTypes.FETCH_TRANSACTION_CHANNEL_TYPES,
    lsChannelTypes: data,
  };
};

export const fetchTransactionType = (data) => {
  return {
    type: actionTypes.FETCH_TRANSACTION_TYPE,
    lstransactionTypeData: data,
  };
};

// FAIL FETCH -------------------------------------
export const fetchTransactionsFailed = (error) => {
  return {
    type: actionTypes.FETCH_TRANSACTIONS_FAILED,
    transactionDataFailed: error,
  };
};

export const fetchTransactionByIdFailed = (error) => {
  return {
    type: actionTypes.FETCH_TRANSACTIONS_BY_ID_FAILED,
    transactionByIdDataFailed: error,
  };
};

export const fetchTransactionChannelTypesFailed = (error) => {
  return {
    type: actionTypes.FETCH_TRANSACTIONS_CHANNE_TYPES_FAILED,
    channesTypesError: error,
  };
};

export const fetchTransactionsTypeFailed = (error) => {
  return {
    type: actionTypes.FETCH_TRANSACTIONS_TYPE_FAILED,
    transactionTypeDataFailed: error,
  };
};

//----------------------------------------------------
export const GetTransaction = (
  startDate,
  endDate,
  consumerId,
  partnerId,
  transactionCode,
  transactionType,
  page
) => {
  return (dispatch) =>
    API.GetTransactionsData(
      startDate,
      endDate,
      consumerId,
      partnerId,
      transactionCode,
      transactionType,
      page
    )
      .then((response) => {
        dispatch(fetchTransactionsData(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchTransactionsFailed(error));
        dispatch(Loader.hideLoader());
      });
};

export const PostTransaction = (data) => {
  return (dispatch) =>
    API.PostTransaction(data)
      .then((response) => {
        dispatch(postTransactionData(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchTransactionsFailed(error));
        dispatch(Loader.hideLoader());
      });
};

export const GetTransactionById = (id) => {
  return (dispatch) =>
    API.GetTransactionById(id)
      .then((response) => {
        dispatch(fetchTransactionById(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchTransactionByIdFailed(error));
        dispatch(Loader.hideLoader());
      });
};

export const GetTransactionDetails = (id) => {
  return (dispatch) =>
    API.GetTransactionDetails(id)
      .then((response) => {
        dispatch(fetchTransactionDetails(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchTransactionByIdFailed(error));
        dispatch(Loader.hideLoader());
      });
};

export const GetTransactionChannelTypes = () => {
  return (dispatch) =>
    API.GetTransactionChannelTypes()
      .then((response) => {
        dispatch(fetchTransactionChannelTypes(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchTransactionChannelTypesFailed(error));
        dispatch(Loader.hideLoader());
      });
};

export const GetPartnerAndConsumerFilter = (partner, consumer) => {
  return (dispatch) =>
    Promise.all([
      partner && dispatch(GetPartnersData("", partner, 1)),
      consumer && dispatch(GetConsumer("", consumer, 1)),
    ]);
};

export const GetTransactionType = () => {
  return (dispatch) =>
    API.GetTransactionType()
      .then((response) => {
        dispatch(fetchTransactionType(response));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchTransactionsTypeFailed(error));
      });
};
