import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// @ Utils
import { numberFormatText } from 'utils/utils'

// @ Axios
import axios from 'axios'

// @ Components
import Button from 'components/CustomButtons/Button.jsx'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'
import Section from 'components/Section'
import CustomDateNew from 'components/CustomDateNew'
import Loader from 'components/Loader/Loader.jsx'

// @ Moment
import moment from 'moment'

// @ HookForm
import { Controller, useForm } from 'react-hook-form'

// @ Redux and Requests
import { useDispatch } from 'react-redux'
import { HideLoader } from 'redux/actions'
import { GetAwardLogs } from '../../../redux/api/award.js'
import { useAward } from '../../../Providers/AwardContext'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

// @ i18next
import { useTranslation } from 'react-i18next'

// @ Styles
import * as S from './style'

// @ Material UI
import Autocomplete from '@material-ui/lab/Autocomplete'
import { FormControl, Grid, TextField } from '@material-ui/core'
import { Columns, OptionsTypeFilter, TypeFilter, customSelectStyles, defaultValues } from './utils.js'
import {envs}  from '../../../redux/api/windowServerData.js'



const LogAward = () => {
	const { t } = useTranslation()
	const tableRef = React.useRef()
	const { idDetailProcessed } = useAward()

	const dispatch = useDispatch()
	dispatch(HideLoader())

	const history = useHistory()
	const [dataResponse, setDataResponse] = useState([])
	const [dataLogAward, setDataLogAward] = useState([])
	const [endDate, setEndDate] = useState('')
	const [pageSizeState, setPageSizeState] = useState(10)
	const [recordPageState, setRecordPageState] = useState()
	const [valueTypeFilter, setValueTypeFilter] = useState(TypeFilter)
	const [startDate, setStartDate] = useState('')
	const [selectedOptionTypeFilter, setSelectedOptionTypeFilter] = useState('')
	const [loading, setLoading] = useState(false)

	const [themeColor, setThemeColor] = useState()
	const { register, handleSubmit, control, reset } = useForm({
		defaultValues: defaultValues,
	})

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const handleGetAwardData = useCallback(
		async (pageSize, page) => {
			let startDate = null
			let endDate = null
			if (valueTypeFilter.dateFilter) {
				startDate = moment(valueTypeFilter.dateFilter, 'DD/MM/YYYY').format('YYYY-MM-DD')
			}
			if (valueTypeFilter.dateFilterTo) {
				endDate = moment(valueTypeFilter.dateFilterTo, 'DD/MM/YYYY').format('YYYY-MM-DD')
			}

			const response = await GetAwardLogs(
				idDetailProcessed,
				startDate,
				endDate,
				valueTypeFilter.awardOperator,
				valueTypeFilter.logAction,
				pageSize,
				page,
			)

			let bankValue = null
			if (response.data.bankAccountReserve) {
				bankValue = Number.isInteger(response.data.bankAccountReserve.reserveBalance)
					? `R$ \n${response.data.bankAccountReserve.reserveBalance},00`
					: `R$ \n${response.data.bankAccountReserve.reserveBalance}`
			}

			setDataResponse({
				bankAccountReserveId: response.data.bankAccountReserve && response.data.bankAccountReserve.id,
				bankAccountReserveName: response.data.bankAccountReserve && response.data.bankAccountReserve.name,
				bankAccountReserveReserveBalance: bankValue,
				description: response.data.description,
				id: response.data.id,
				importValueTypeKey: response.data.importValueType.key,
				importValueTypeValue: response.data.importValueType.value,
				observation: response.data.observation,
				partnerKey: response.data.partner.key,
				partnerValue: response.data.partner.value,
				partnerCreditOriginKey: response.data.partnerCreditOrigin.key,
				partnerCreditOriginValue: response.data.partnerCreditOrigin.value,
				partnerCreditOriginId: response.data.partnerCreditOriginId,
				date: response.data.processingDate ? moment(response.data.processingDate).format('DD/MM/YYYY') : null,

				hour: response.data.processingDate ? moment(response.data.processingDate).format('HH:mm') : null,
				standartValue: response.data.standartValue,
				statusKey: response.data.status.key,
				statusValue: response.data.status.value,
			})

			return response
		},
		[idDetailProcessed, valueTypeFilter.awardOperator, valueTypeFilter.dateFilter, valueTypeFilter.dateFilterTo, valueTypeFilter.logAction],
	)

	const validation = currentDate => {
		const today = moment()

		if (moment(endDate, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(endDate)) return false
		}

		return currentDate.isBefore(today)
	}

	const GetExcludedEntriesFile = async () => {
		setLoading(true)
		const url1 = `/Award/GetExcludedEntriesFile/${dataLogAward.id}`
		try {
			const res = await axios
				.request({
					method: 'GET',
					url: `${url1}`,
					responseType: 'blob',
					headers: {
						'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8',
					},
				})
				.finally(() => setLoading(false))

			const desiredData = res.data

			if (desiredData) {
				const newData = desiredData

				const url = window.URL.createObjectURL(new Blob([newData]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', 'arquivo.xlsx')

				document.body.appendChild(link)
				link.click()
				document.body.removeChild(link)
			}
		} catch (error) {
			console.log(error)
		}
	}

	const resetFilter = () => {
		setValueTypeFilter(TypeFilter)

		setSelectedOptionTypeFilter(null)
		setStartDate(null)
		setEndDate(null)

		reset(TypeFilter)
	}

	const onSubmitFilter = filter => {
		return setValueTypeFilter(filter)
	}

	const handleStartDate = event => {
		setStartDate(event)
	}

	const valid = current => {
		const maxData = moment()
		return current.isAfter(startDate) && current.isBefore(maxData)
	}

	const handleEndDate = event => {
		setEndDate(event)
	}

	const handleSelectedLog = (value, event) => {
		event(value.value)
		setSelectedOptionTypeFilter(value)
	}

	const focousOut = () => {
		if (!moment(endDate, 'DD/MM/YYYY').isValid()) {
			setEndDate('')
		}
		if (!valid(moment(endDate), 'DD/MM/YYYY')) {
			setEndDate('')
		}
	}

	useEffect(() => {
		tableRef.current.onChangePage({}, 0)
		tableRef.current.onQueryChange({ page: 0, search: '' })
	}, [valueTypeFilter])

	return (
		<S.Container themeColor={themeColor}>
			{loading && <Loader />}
			<S.Section>
				<Section title={t('LOG_AWARD')} style={{ flexGrow: 1 }} />
				<div style={{ marginLeft: '15px' }}>
					<Button
						style={{
							backgroundColor: 'transparent',
							border: `1px solid ${themeColor &&
								themeColor.navigation &&
								themeColor.navigation.buttons &&
								themeColor.navigation.buttons.backgroundColor &&
								themeColor.navigation.buttons.backgroundColor}`,
							color:
								themeColor &&
								themeColor.navigation &&
								themeColor.navigation.buttons &&
								themeColor.navigation.buttons.backgroundColor &&
								themeColor.navigation.buttons.backgroundColor,
							borderRadius: '5px',
						}}
						size="sm"
						variant="contained"
						onClick={() => history.push('/financial/award')}
					>
						Voltar
					</Button>
				</div>
			</S.Section>
			<S.GridContain>
				<S.FieldContain themeColor={themeColor}>
					<S.InputBox style={{ width: '220px' }}>
						<label style={{ marginLeft: '15px' }}>Data do evento</label>
						<S.Inputs value={dataResponse.date ? `${dataResponse.date} - ${dataResponse.hour}` : '-'} />
					</S.InputBox>
					<S.InputBox style={{ width: '700px', marginRight: '0' }}>
						<label style={{ marginLeft: '15px' }}>Descrição da premiação</label>
						<S.Inputs value={dataResponse.description} />
					</S.InputBox>
				</S.FieldContain>
				<S.FieldContain themeColor={themeColor}>
					<S.InputBox style={{ width: '220px' }}>
						<label style={{ marginLeft: '15px' }}>Motivo do crédito</label>
						<S.Inputs value={dataResponse.partnerCreditOriginValue} />
					</S.InputBox>
					<S.InputBox style={{ width: '440px' }}>
						<label style={{ marginLeft: '15px' }}>Reserva da conta</label>
						<S.ShowValue>
							{numberFormatText(
								dataResponse.reserveBalance ? dataResponse.reserveBalance : '0',
								localStorage.getItem('currencySymbol')
									? `${localStorage.getItem('currencySymbol')}\n`
									: // : envs.REACT_APP_CURRENCY_SYMBOL,
									  envs.REACT_APP_CURRENCY_SYMBOL,
								2,
							)}
						</S.ShowValue>
					</S.InputBox>
					<S.InputBox style={{ marginRight: '0', width: '235px' }}>
						<label style={{ marginLeft: '15px' }}>Valor do saldo reservado</label>
						<S.Inputs value={dataResponse.bankAccountReserveReserveBalance} />
					</S.InputBox>
				</S.FieldContain>

				<S.ContainFields>
					<S.InputBox style={{ marginRight: '0', width: '410px' }}>
						<label style={{ marginLeft: '15px' }}>Observação</label>
						<S.Inputs value={dataResponse.observation ? dataResponse.observation : '-'} />
					</S.InputBox>
					{dataLogAward.isAnticipated && (
						<S.InputBox style={{ width: '220px' }}>
							<label style={{ marginLeft: '15px' }}>Taxa de antecipação</label>
							<S.Inputs value={dataLogAward.anticipationFee ? dataLogAward.anticipationFee + '%' : '-'} />
						</S.InputBox>
					)}
					{dataLogAward.isAnticipated && (
						<S.InputBox style={{ marginRight: '0', width: '235px' }}>
							<label style={{ marginLeft: '15px' }}>Carência</label>
							<S.Inputs value={dataLogAward.graceDescription ? dataLogAward.graceDescription : '-'} />
						</S.InputBox>
					)}
				</S.ContainFields>
			</S.GridContain>
			{/* FILTER */}
			<S.FilterForm onSubmit={handleSubmit(onSubmitFilter)} autoComplete="off">
				<Grid container spacing={4}>
					<Grid
						item
						xs={12}
						sm={6}
						md={5}
						className="filter__gridDATE"
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'space-between',
							gap: '30px',
						}}
					>
						<CustomDateNew
							value={startDate}
							locale="pt-br"
							label={t('DATETIME_FROM')}
							maxDate={new Date().getTime()}
							isValidDate={validation}
							onChange={e => handleStartDate(e._d)}
							timeFormat={false}
							fullWidth
						/>
						<CustomDateNew
							value={endDate}
							locale={'pt-br'}
							maxDate={new Date().getTime()}
							label={t('DATETIME_TO')}
							timeFormat={false}
							fullWidth
							isValidDate={valid}
							onChange={e => handleEndDate(e._d)}
							onBlur={focousOut}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={4}>
						<TextField
							name="awardOperator"
							inputRef={register}
							fullWidth
							label="Operador"
							variant="outlined"
							size="small"
							style={{
								backgroundColor: '#ffffff',
							}}
						/>
					</Grid>
					<Grid item xs={12} sm={6} md={3}>
						<FormControl size="small" variant="outlined" name="logAction" inputRef={register} fullWidth placeholder={t('FIELD_TYPE')}>
							<Controller
								onChange={([, data]) => data}
								name="logAction"
								control={control}
								render={({ onChange }) => (
									<Autocomplete
										styles={customSelectStyles}
										id="combo-box-demo"
										options={OptionsTypeFilter}
										getOptionLabel={option => (option.name ? option.name : '')}
										disableClearable={true}
										disablePortal
										value={selectedOptionTypeFilter}
										renderInput={params => (
											<div ref={params.InputProps.ref}>
												<TextField
													style={{
														backgroundColor: '#ffffff',
														width: '100%',
													}}
													{...params}
													noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
													label="Log"
													variant="outlined"
													size="small"
												/>
											</div>
										)}
										onChange={(e, data) => handleSelectedLog(data, onChange)}
									/>
								)}
							/>
						</FormControl>
					</Grid>

					<S.BntFilterContain>
						<Button size="sm" variant="contained" color="greenBorderButtonColor" {...{ reset }} style={{ margin: 0 }} onClick={resetFilter}>
							{t('BUTTON_CLEAN_FILTER')}
						</Button>
						<Button size="sm" style={{ margin: 0 }} variant="contained" color="greenButtonColor" type="submit">
							{t('BUTTON_FIND')}
						</Button>
					</S.BntFilterContain>
				</Grid>
			</S.FilterForm>
			{/* TABLE */}
			<MaterialTable
				tableRef={tableRef}
				style={{ width: '100%' }}
				options={{
					emptyRowsWhenPaging: false,
					toolBar: false,
					pageSizeOptions: [10, 20, 30, 40, 50],
					pageSize: recordPageState < 10 ? recordPageState : pageSizeState,
					search: false,
					sorting: false,
					showTitle: false,
				}}
				columns={Columns(GetExcludedEntriesFile, themeColor)}
				data={query =>
					new Promise(resolve => {
						handleGetAwardData(query.pageSize, query.page + 1).then(result => {
							if (result) {
								setDataLogAward(result.data)
								setPageSizeState(result.data.logs.pageSize)
								setRecordPageState(result.data.logs.recordCount)
								if (result.data) {
									resolve({
										data: result.data.logs.results,
										page: result.data.logs.currentPage - 1,
										totalCount: result.data.logs.recordCount,
										pageSize: result.data.logs.pageSize,
									})
								}
							}
						})
					})
				}
			/>
		</S.Container>
	)
}
export default LogAward
