import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'

//@Component
import SnackDefault from 'components/SnackDefault'
import ListDocument from './components'
import { updateData } from './components/functions'
import Cookies from 'js-cookie'
import { GetAttachmentTypes, GetAttachments } from '../../../../../../redux/api/PartnerDocuments'
import { defaultFormatDocument } from './components/List/utils'

export default function Documents({ isEditData, partnerData, setIsLoadingData, setIsEditData }) {
	const methods = useForm({})
	const [form, setForm] = useState([])
	const partnerId = String(Cookies.get('partnerId'))
	const [formData, setFormData] = useState([])

	const [attachmentTypes, setAttachmentTypes] = useState([])
	const [closing, setClosing] = useState({
		open: false,
		severity: '',
		text: '',
	})

	const documentData = async () => {
		try {
			setIsLoadingData(true)
			const types = await GetAttachmentTypes()
			setAttachmentTypes(types)

			GetAttachments(partnerId).then(response => {
				if (response?.data?.length > 0) {
					const newData = response.data.map(item => {
						return defaultFormatDocument(item, types)
					})
					setFormData(newData)
				}
			})
		} catch (error) {
			console.error('Error fetching data:', error)
		} finally {
			setIsLoadingData(false)
		}
	}

	const onSubmit = () => {
		setIsLoadingData(true)
		updateData(form, partnerId)
			.then(response => {
				if (response?.success) {
					setClosing({
						open: true,
						severity: 'success',
						text: 'Dados atualizados com sucesso!',
					})
					documentData()
					setIsEditData(false)
				}

				if (response?.errors?.length > 0) {
					setClosing({
						open: true,
						severity: 'error',
						text: response?.errors[0]?.message,
					})
				}
			})
			.catch(error => {
				setClosing({
					open: true,
					severity: 'error',
					text: 'Erro ao atualizar dados!',
				})
				console.error('Error fetching data:', error)
			})
			.finally(() => {
				setIsLoadingData(false)
			})
	}

	useEffect(() => {
		documentData()
	}, [])

	return (
		<FormProvider {...methods}>
			<SnackDefault
				snackStatus={closing}
				handleCloseSnack={() =>
					setClosing(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			<form id="document-data-form" onSubmit={methods.handleSubmit(onSubmit)}>
				<ListDocument
					setForm={setForm}
					formData={formData}
					partnerId={partnerId}
					setClosing={setClosing}
					isEditData={isEditData}
					partnerData={partnerData}
					setFormData={setFormData}
					attachmentTypes={attachmentTypes}
					setIsLoadingData={setIsLoadingData}
				/>
			</form>
		</FormProvider>
	)
}

Documents.propTypes = {
	isEditData: PropTypes.bool,
	partnerData: PropTypes.object,
	setIsLoadingData: PropTypes.func,
}
