import _ from 'lodash'
import { numberFormatText } from 'utils/utils'
import { GetTypeORedeem } from 'redux/api/product'
import { UpdateGrouperParametrization } from '../../../../../../../../../../redux/api/Grouper'
import Cookies from 'js-cookie'
import { CalculateParametrizationValue } from '../../../../../../../../../../redux/api/product'
import {envs}  from '../../../../../../../../../../redux/api/windowServerData'



export const GetORedeem = async ({ setGenerationType, setCircularProgress }) => {
	setCircularProgress(true)
	try {
		const res = await GetTypeORedeem()

		if (res?.status === 200 && res?.data?.length > 0) {
			const newRes =
				res?.data
					?.map(item => {
						if (item?.value === 'No_') {
							return null
						}
						if (item?.value === 'No') {
							return {
								...item,
								value: 'Não resgatavel',
							}
						}
						return item
					})
					?.filter(item => item !== null) || []

			setGenerationType(newRes)
		}
	} catch (error) {
		console.log(error)
	} finally {
		setCircularProgress(false)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const FormatMoney = value => {
	// const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	const symbol = localStorage.getItem('currencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
	return value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }).replace('R$', symbol)
}

export const handleProductDataUpdate = ({ productData, setParameterizationRedemptionPoints, reset }) => {
	if (!_.isEmpty(productData)) {
		const { typeOfRedeem, parameterizationRedemptionPoints, redemptionPoints } = productData

		reset({
			rescue: typeOfRedeem,
			redemptionPoints: redemptionPoints,
		})

		setParameterizationRedemptionPoints(parameterizationRedemptionPoints)

		return parameterizationRedemptionPoints
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '2':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '3':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async ({ price, pointsValue, typeOfRedeem }) => {
	const partnerId = Cookies.get('partnerId')
	try {
		const res = await CalculateParametrizationValue(partnerId, price, '', '', typeOfRedeem, pointsValue)

		if (res?.success && res?.data) {
			const { redemptionPoints } = res?.data
			return redemptionPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const ParameterizationProduct = async (
	productData,
	typeOfRedeem,
	redemptionPoints,
	setSnackStatus,
	typeOfBenefitRedeem,
	setLoading,
	grouper,
) => {
	const partnerId = Cookies.get('partnerId')
	const obj = {
		partnerId: partnerId || '',
		typeOfGrouper: grouper || '',
		generatePoints: productData?.generatePoints,
		typeOfBenefitPoints: productData?.typeOfBenefitPoints?.key,
		typeOfPoints: productData?.typeOfPoints?.key,
		redemptionPoints: redemptionPoints || 0,
		typeOfBenefitRedeem: typeOfBenefitRedeem?.key || '1',
		typeOfRedeem: typeOfRedeem?.key,
		pointsValue: productData?.pointsValue,
		typeOfBenefitCashback: productData?.typeOfBenefitCashback?.key,
		typeOfCashback: productData?.typeOfCashback?.key,
		cashbackPercentual: productData?.cashbackPercentual,
	}

	setLoading(true)

	try {
		const res = await UpdateGrouperParametrization({ id: productData?.id, obj })

		if (res?.data?.success && res?.status === 200) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})
			return
		}

		if (!res?.data?.success) {
			setSnackStatus({
				open: true,
				text: res?.data?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		setSnackStatus({
			open: true,
			text: 'Ocorreu um erro ao realizar a operação!',
			typseveritye: 'error',
		})
	} finally {
		setLoading(false)
	}
}
