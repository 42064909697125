import { PartnerService } from '../../Services'

export default async function getEventsList() {
	const partnerService = new PartnerService()
	const response = await partnerService.getPartnerEvents()

	const result = []

	response.forEach(item => {
		result.push(item.description)
	})

	return (
		{
			data: response,
			result: result,
		} ?? []
	)
}
