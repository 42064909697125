import React from 'react'
import { withTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Modal from '@mui/material/Modal'

//Styles and Components
import Button from 'components/CustomButtons/Button.jsx'
import {
	ModalBox,
	Title,
	PartnersIcon,
	HeaderModal,
	ButtonCloseIcon,
    BodyModal,
} from './styles'
import TableModal from './pageModal'

function PartnersModal({
	children,
	open,
	closeByClick,
	maxWidth,
	className,
	padding,
	shadow,
	t,
	headerIcon,
	headerTitle,
	buttonTitle,
	onClose,
}) {

	const handleDetail = event => {
		console.log(event)
	}
	return (
		<Modal
			open={open}
			className={className}
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			disableEnforceFocus
		>
			<ModalBox>
				<HeaderModal>
					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
						}}
					>
						<div style={{ display: 'flex' }}>
							<PartnersIcon className="icon-ico_parceiros" />
							<Title>
								Parceiro(s) com meios de pagamento habilitado(s)
							</Title>
						</div>
						<Button
							color="danger"
							size="sm"
							onClick={closeByClick}
							startIcon={
								<ButtonCloseIcon className="icon-ico_cancelar" />
							}
						>
							{t(buttonTitle)}
						</Button>
					</div>
				</HeaderModal>
                <BodyModal>
                    <TableModal></TableModal>
                </BodyModal>
			</ModalBox>
		</Modal>
	)
}

PartnersModal.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	headTitle: PropTypes.string,
	readAllImportantAlerts: PropTypes.func,
	showCheckBox: PropTypes.bool,
	t: PropTypes.func,
	headerIcon: PropTypes.string,
	headerTitle: PropTypes.string,
	buttonTitle: PropTypes.string,
	onClose: PropTypes.func,
	partnerId: PropTypes.string,
}

PartnersModal.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	headTitle: '',
	children: null,
	open: false,
	closeByClick: null,
	maxWidth: '630px',
	readAllImportantAlerts: null,
	showCheckBox: false,
	headerIcon: 'icon-ico_regra_plataforma',
	headerTitle: 'TITLE_REGULATION_REGULATION_MODEL',
	partnerId: '',
}

export default withTranslation()(PartnersModal)
