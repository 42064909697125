import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import PropTypes from 'prop-types'
import { Controller, useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'

import { Grid, Fade, Typography } from '@material-ui/core'
import imageCSV from 'assets/img/modelo_excel.jpg'
import imageTXT from 'assets/img/modelo_txt.jpg'
import Button from 'components/CustomButtons/Button.jsx'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import TextInput from 'components/TextInput'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'

import {
	ModalContainer,
	PaperContainer,
	Close,
	Icon,
	TypographyTXT,
	CloseButton,
	CloseFile,
	MinimumRecords,
} from './styles'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { yupResolver } from '@hookform/resolvers'

const textType = [
	{
		text: 'Deseja realmete excluir este item?',
		type: 'delete',
	},
	{
		text: 'As alterações realizadas serão perdidas. Deseja realmente continuar?',
		type: 'cancel',
	},
	{
		text: 'Para prosseguir com a exclusão é necessário informar a sua senha.',
		type: 'confirmDelete',
	},
	{
		type: 'cancelError',
		text: 'As alterações realizadas serão perdidas. Deseja realmente continuar?',
	},
	{
		type: 'ModalWarning',
		text: 'Deseja realmente excluir este item?',
	},
	{
		type: 'openModalWMutipleError',
		text: '',
	},
	{
		type: 'fileInvalid',
		text: '',
	},
	{
		type: 'fileValid',
		text: '',
	},
	{
		type: 'minimumRecords',
		text: 'Seu arquivo deve conter pelo menos 10 registros, abaixo dessa quantidade a distribuição deverá ser realizada de forma manual.',
	},
]

export const validationSchema = Yup.object().shape({
	password: Yup.string().required('Senha obrigatória.'),
})

function ConfirmModal({
	className,
	closeByClick,
	error,
	maxWidth,
	minWidth,
	onCancel,
	onConfirm,
	onDelete,
	open,
	padding,
	parameter,
	shadow,
	standardFocus,
	t,
	text,
	title = 'Confirmar',
	type,
}) {
	const { handleSubmit, control, errors } = useForm({
		resolver: yupResolver(validationSchema),
	})
	const [displayText, setDisplayText] = useState({})
	// const [displayError, setDisplayError] = useState()

	const [themeColor, setThemeColor] = useState({})

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		ModalType(type)
	}, [type, text, parameter, error])

	const getColor = () => {
		const color =
			themeColor &&
			themeColor.titles &&
			themeColor.titles.main &&
			themeColor.titles.main.textColor
		return color
	}

	function ModalType(type) {
		if (type) {
			if (!text) {
				return textType.forEach((item) => {
					if (item.type === type) {
						setDisplayText(item.text)
					}
				})
			}
			return setDisplayText(text)
		}
		if (text) {
			return setDisplayText(text)
		}
		setDisplayText('Deseja Continuar?')
	}

	async function removeForm(value) {
		onDelete(value)
	}

	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			minwidth={minWidth}
			disableEnforceFocus
			themeColor={themeColor}
		>
			{open && type === 'minimumRecords' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<CloseButton>
							<button onClick={onCancel}>
								<i className="icon-ico_cancelar" /> Fechard
							</button>
						</CloseButton>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								width: '860px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
								paddingTop: '13px',
							}}
						>
							<Grid item xs={12} sm={12}>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									textAlign: 'center',
								}}
							>
								<Typography
									className="infoMinimumRecords"
									variant="caption"
								>
									A quantidade de registros está abaixo do
									permitido
								</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									margin: '30px',
								}}
							>
								<MinimumRecords>{displayText}</MinimumRecords>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'fileValid' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								//margin: '30px 20px 30px 2px',
								width: '800px',
								height: 'fit-content',
							}}
						>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'flex-end',
									paddingRight: '20px',
									paddingTop: '16px',
								}}
							>
								<CloseFile
									onClick={onCancel}
									startIcon={
										<i
											className="icon-ico_cancelar"
											style={{
												paddingLeft: '5px',
												fontSize: '15px',
												fontWeight: '400',
											}}
										/>
									}
								>
									Fechar
								</CloseFile>
							</Grid>
							<Grid item xs={12} sm={12} className="paddPagText">
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
								<GridItem
									xs={12}
									sm={12}
									style={{
										textAlign: 'center',
										display: 'flex',
									}}
								>
									<Typography
										className="infoTextFileTitle"
										variant="caption"
										style={{
											fontWeight: 'bold',
										}}
									>
										O formato do arquivo é incompatível
									</Typography>
								</GridItem>
							</Grid>
							<Grid item xs={12} sm={12} className="paddPagText">
								<Typography className="infoTextFile">
									O padrão do arquivo a ser utilizado deverá
									seguir a formatação conforme indicado nas
									imagens abaixo.
									<br /> Uma coluna, indicando o CPF ou o
									Código do participante (CPF_Cod) e outra
									indicando o valor a ser creditado na conta
									do participante (Valor), sendo considerado
									um registro por linha.
									<br />
									<br />* A quantidade mínima a serem
									importados é de 10 registros, abaixo dessa
									quantidade a distribuição deverá ser
									realizada de forma manual
									<br /> * O arquivo no excel deverá ser salvo
									no formato CSV ou TXT. A Ferramenta não
									aceita as formatações .XLS e XLSX.
								</Typography>
							</Grid>
						</Grid>
						<Grid
							container
							xs={12}
							sm={12}
							spacing={2}
							className="paddPag"
						>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								spacing={2}
							>
								<Typography
									style={{
										color: getColor(),
										textTransform: 'none',
									}}
									variant="h6"
								>
									Formato Excel
								</Typography>
								<img
									src={imageCSV}
									alt="Arquivo Formato Excel exemplo"
									style={{
										width: '100%',
									}}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								md={6}
								lg={6}
								spacing={2}
							>
								<Typography
									style={{
										color: getColor(),
										textTransform: 'none',
									}}
									variant="h6"
								>
									Padrão CSV/TXT
								</Typography>
								<img
									src={imageTXT}
									alt="Arquivo Padrão CSV/TXT exemplo"
									style={{
										width: '100%',
									}}
								/>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'fileInvalid' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<CloseButton>
							<button onClick={onCancel}>
								<i className="icon-ico_cancelar" /> Fechar
							</button>
						</CloseButton>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								width: '600px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
							}}
						>
							<Grid item xs={12} sm={12}>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									margin: '30px',
								}}
							>
								<TypographyTXT>
									O formato do arquivo é inválido.
									<br /> Repita a operação utilizando uma das
									duas opções: csv, txt.
								</TypographyTXT>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'openModalWMutipleError' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<CloseButton>
							<button onClick={onCancel}>
								<i className="icon-ico_cancelar" /> Fechar
							</button>
						</CloseButton>
						<Grid
							container
							xs={12}
							sm={12}
							style={{
								width: '600px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
							}}
						>
							<Grid item xs={12} sm={12}>
								<Icon>
									<i className={'icon-alerta_modal'} />
								</Icon>
							</Grid>
							<Grid
								item
								xs={12}
								sm={12}
								style={{
									margin: '30px',
								}}
							>
								<TypographyTXT>
									Erro ao processar.
									<br /> Confira as informações e tente
									novamente.
								</TypographyTXT>
							</Grid>
						</Grid>
					</PaperContainer>
				</Fade>
			) : open && type === 'ModalWarning' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{
								//margin: '30px 20px 30px 2px',
								width: '860px',
								height: 'fit-content',
								padding: '28px',
								paddingBottom: '15px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									paddingRight: '0px !important',
								}}
							>
								<Typography
									className="infoText"
									style={{
										color: getColor(),
									}}
									variant="h6"
								>
									Informações para importação nos formatos
									<span>Excel</span> e padrão{' '}
									<span>CSV/TXT</span>
								</Typography>
								<Close>
									<button onClick={onCancel}>
										<i className="icon-ico_cancelar" />{' '}
										Fechar
									</button>
								</Close>
							</GridItem>
							<GridItem
								xs={12}
								sm={12}
								style={{
									marginTop: '20px',
								}}
							>
								<Typography className="infoTextFile">
									O padrão do arquivo a ser utilizado deverá
									seguir a formatação conforme indicado nas
									imagens abaixo.
									<br /> Uma coluna, indicando o CPF ou o
									Código do participante (CPF_Cod) e outra
									indicando o valor a ser creditado na conta
									do participante (Valor), sendo considerado
									um registro por linha.
									<br />
									<br />
									* A quantidade mínima a serem importados é
									de 10 registros, abaixo dessa quantidade a
									distribuição deverá ser realizada de forma
									manual
									<br /> * O arquivo no excel deverá ser salvo
									no formato CSV ou TXT. A Ferramenta não
									aceita as formatações .XLS e XLSX.
								</Typography>
							</GridItem>
						</GridContainer>
						<GridContainer
							xs={12}
							sm={12}
							style={{
								margin: 0,
								padding: '10px',
								marginBottom: '20px',
							}}
						>
							<GridItem xs={12} sm={12} md={6} lg={6} spacing={2}>
								<Typography
									style={{
										color: getColor(),
										textTransform: 'none',
										fontWeight: 'bold',
									}}
									variant="h6"
								>
									Formato Excel
								</Typography>
								<img
									src={imageCSV}
									alt="Arquivo Excel exemplo"
									style={{
										width: '100%',
									}}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={6} lg={6} spacing={2}>
								<Typography
									style={{
										color: getColor(),
										textTransform: 'none',
										fontWeight: 'bold',
									}}
									variant="h6"
								>
									Padrão CSV/TXT
								</Typography>
								<img
									src={imageTXT}
									alt="Arquivo CSV/TXT exemplo"
									style={{
										width: '100%',
									}}
								/>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : open && type === 'saveError' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '30px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '13px',
									fontWeight: 'bold',
								}}
							>
								{displayText}
							</div>
						</GridItem>
						<GridContainer
							style={{
								padding: '10px 0 0 20px',
								marginBottom: '30px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{ textAlign: 'center' }}
							>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'no'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginRight: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={() => onCancel()}
								>
									{t('BUTTON_OK')}
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : open && type === 'cancelError' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '30px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '13px',
									fontWeight: 'bold',
								}}
							>
								{displayText}
							</div>
						</GridItem>
						<GridContainer
							style={{
								padding: '10px 0 0 20px',
								marginBottom: '30px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{ textAlign: 'center' }}
							>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'no'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginRight: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onCancel}
								>
									{t('BUTTON_NO')}
								</Button>{' '}
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'yes'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginLeft: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onConfirm}
								>
									{t('BUTTON_YES')}
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : open && type !== 'confirmDelete' ? (
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={padding} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '30px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className={
										type === 'cancel'
											? 'icon-alerta_modal'
											: 'icon-grid_reprovado'
									}
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{
									hidden: true,
									fontSize: '13px',
									fontWeight: 'bold',
								}}
							>
								{displayText}
							</div>
						</GridItem>
						<GridContainer
							style={{
								padding: '10px 0 0 20px',
								marginBottom: '30px',
							}}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{ textAlign: 'center' }}
							>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'no'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginRight: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onCancel}
								>
									{t('BUTTON_NO')}
								</Button>
								<Button
									size="sm"
									variant="contained"
									color={
										standardFocus.toString() === 'yes'
											? 'greenButtonColor'
											: 'greenBorderButtonColor'
									}
									style={{
										marginLeft: '5px',
										padding: '8px 35px 8px 35px',
										borderRadius: '5px',
										fontSize: '16px',
									}}
									onClick={onConfirm}
								>
									{t('BUTTON_YES')}
								</Button>
							</GridItem>
						</GridContainer>
					</PaperContainer>
				</Fade>
			) : (
				//PASSOWRD MODAL
				<Fade in={open} unmountOnExit>
					<PaperContainer padding={'0px 60px'} maxwidth={maxWidth}>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '50px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{ fontSize: '15px', fontWeight: 'bold' }}
							>
								{displayText}
							</div>
						</GridItem>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(removeForm)}
						>
							<GridContainer style={{ marginTop: '30px' }}>
								<Grid
									item
									xs={10}
									style={{
										position: 'relative',
										left: '60px',
									}}
								>
									<Controller
										as={
											<TextInput
												style={{ marginRight: '50px' }}
												error={errors.password}
												helperText={
													errors.password
														? errors.password.message
														: ''
												}
												label="Senha *"
												type="password"
											/>
										}
										defaultValue=""
										name="password"
										control={control}
									></Controller>
								</Grid>
							</GridContainer>
							<div
								style={{
									position: 'relative',
									left: '45px',
									color: ' #F05B4F',
									fontSize: '0.80rem',
									fontWeight: '400',
									fontFamily:
										'Roboto, Helvetica, Arial, sans-serif',
								}}
							>
								{t(error ? error : '')}
							</div>
							<GridContainer
								style={{
									padding: '10px 0 0 20px',
									marginBottom: '50px',
									marginTop: '35px',
								}}
							>
								<GridItem
									xs={12}
									sm={12}
									style={{ textAlign: 'center' }}
								>
									<Button
										color="greenBorderButtonColor"
										size="sm"
										style={{ marginRight: 10 }}
										startIcon={
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													fontSize: '14px',
												}}
											>
												<i
													className={
														'icon-ico_cancelar'
													}
													style={{ fontSize: '18px' }}
												/>
											</div>
										}
										onClick={() => {
											onCancel()
										}}
									>
										Cancelar
									</Button>

									<Button
										variant="contained"
										color="greenButtonColor"
										size="sm"
										style={{
											width: 'fit-content',
										}}
										type="submit"
									>
										{title}
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</PaperContainer>
				</Fade>
			)}
		</ModalContainer>
	)
}

ConfirmModal.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	standardFocus: PropTypes.string,
	text: PropTypes.string,
	password: PropTypes.string,
	parameter: PropTypes.string,
	type: PropTypes.string,
	error: PropTypes.string,
	onDelete: PropTypes.func,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
}

ConfirmModal.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	standardFocus: 'no',
	children: null,
	open: false,
	closeByClick: null,
	onDelete: null,
	maxWidth: '',
	minWidth: '',
	text: '',
	parameter: '',
	password: '',
	error: '',
	type: '',
	onConfirm: null,
	onCancel: null,
}

export default withTranslation()(ConfirmModal)
