import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { TextField, InputAdornment } from '@mui/material'
import CustomMaterialTable from 'components/CustomMaterialTable/CustomMaterialTable'
import * as S from './styles'
import * as UT from './utils'
import { GetGrouperByPartner } from '../../../../redux/api/productPage'
import _ from 'lodash'

const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => {
			clearTimeout(handler)
		}
	}, [value, delay])

	return debouncedValue
}

const buildProductQueryString = params => {
	const queryParts = []

	if (params.page) queryParts.push(`page=${params.page}`)
	if (params.pageSize) queryParts.push(`pageSize=${params.pageSize}`)
	if (params.category) queryParts.push(`&Name=${encodeURIComponent(params.category)}`)

	return queryParts.join('&')
}

const ListComponent = ({ setLoading }) => {
	const [partnerId, setPartnerId] = useState(() => {
		const userData = localStorage.getItem('user')
		return userData ? JSON.parse(userData).id : null
	})
	const [category, setCategory] = useState('')
	const debouncedCategory = useDebounce(category, 500)
	const [pageSizeState, setPageSizeState] = useState(10)
	const [currentPage, setCurrentPage] = useState(0)
	const [tableData, setTableData] = useState({ data: [], page: 0, totalCount: 0 })

	const tableRef = useRef()
	const { t } = useTranslation()

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)

			const pagination = {
				page: currentPage + 1,
				pageSize: pageSizeState,
			}

			const params = buildProductQueryString({ category: debouncedCategory, ...pagination })

			try {
				const result = await GetGrouperByPartner(partnerId, params)
				if (!_.isEmpty(result)) {
					setTableData({
						data: result.results,
						page: result.currentPage - 1,
						totalCount: result.recordCount,
						pageSize: result.pageSize,
					})
				} else {
					setTableData({
						data: [],
						page: 0,
						totalCount: 0,
						pageSize: pageSizeState,
					})
				}
			} catch (error) {
				console.error('Erro ao buscar dados:', error)
				setTableData({
					data: [],
					page: 0,
					totalCount: 0,
					pageSize: pageSizeState,
				})
			} finally {
				setLoading(false)
			}
		}

		fetchData()
	}, [debouncedCategory, pageSizeState, currentPage, partnerId, setLoading])

	const handleSearchChange = e => {
		setCategory(e.target.value)
	}

	return (
		<S.Container>
			<TextField
				className="TextField"
				placeholder="Buscar"
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<i className="icon-lupa" />
						</InputAdornment>
					),
				}}
				value={category}
				onChange={handleSearchChange}
				type="search"
				variant="standard"
				sx={{
					maxWidth: '300px',
				}}
			/>
			<CustomMaterialTable
				tableRef={tableRef}
				title={false}
				onChangeRowsPerPage={e => setPageSizeState(e)}
				onChangePage={page => setCurrentPage(page)}
				options={{
					pageSize: pageSizeState,
					pageSizeOptions: [10, 20, 30, 50, 100],
					search: false,
					toolBar: false,
				}}
				localization={{
					body: {
						emptyDataSourceMessage: category ? 'Não há itens a serem listados' : 'Nenhum resultado encontrado',
					},
				}}
				columns={UT.Columns(t)}
				data={tableData.data}
				page={tableData.page}
				totalCount={tableData.totalCount}
			/>
		</S.Container>
	)
}

export default ListComponent
