/* eslint-disable import/first */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import { Divider, Icon, Stack } from "@mui/material";
import { BusinessItem, ContainerGroup } from "../../styles";
import { CostCenterIcon, BranchIcon, BusinessUnitIcon, DepartmentIcon, SimpleIcon, AlertIcon, MoreInformationIcon, SearchIcon, DNFranchiseIcon, } from "../../../Icons";
import { MdModeEditOutline } from "react-icons/md";
import { MuiTooltip } from "../../../Tooltip";
import { MuiTypography } from "../../../Typography";
export default function ComponentShape(_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var item = _a.item, name = _a.name, level = _a.level, category = _a.category, x = _a.x, y = _a.y, handleClick = _a.handleClick, divisionsData = _a.divisionsData, defaultColor = _a.defaultColor, divisionsErrors = _a.divisionsErrors, translateMessage = _a.translateMessage, titleTooltipEdit = _a.titleTooltipEdit, handleFullScreen = _a.handleFullScreen;
    var _l = useState(false), isHovered = _l[0], setIsHovered = _l[1];
    var validationDivisionsErrors = ((_b = divisionsErrors === null || divisionsErrors === void 0 ? void 0 : divisionsErrors.warnings) === null || _b === void 0 ? void 0 : _b.length) > 0 || ((_c = divisionsErrors === null || divisionsErrors === void 0 ? void 0 : divisionsErrors.criticalErrors) === null || _c === void 0 ? void 0 : _c.length) > 0;
    var validationDivisionsErrorsDefault = !divisionsErrors || (divisionsErrors.warnings.length === 0 && divisionsErrors.criticalErrors.length === 0);
    var categoryColors = {
        Branch: "#7E6BB9",
        CostCenter: "#62C957",
        BusinessUnit: "#FB9D24",
        Department: "#1BBED3",
        Franchise: "#DFAE93",
    };
    var categoryImage = {
        Branch: _jsx(BranchIcon, { size: 30 }),
        CostCenter: _jsx(CostCenterIcon, { size: 30 }),
        BusinessUnit: _jsx(BusinessUnitIcon, { size: 30 }),
        Department: _jsx(DepartmentIcon, { size: 30 }),
        Franchise: _jsx(DNFranchiseIcon, { size: 30 }),
        Simple: _jsx(SimpleIcon, { size: 30 }),
    };
    var categoryLabels = {
        Matriz: "Matriz",
        Branch: "Filial",
        CostCenter: "Centro de Custo",
        BusinessUnit: "Unidade de Negócio",
        Department: "Departamento",
        Franchise: "Franquia",
    };
    return (_jsxs(ContainerGroup, __assign({ className: "group", category: category, x: x, y: y }, { children: [level !== "levelOne" && (_jsxs("div", __assign({ className: "indicator-glue" }, { children: [_jsx("div", { className: "point" }), _jsx("div", { className: "dashed" })] }))), _jsxs("div", __assign({ className: "business-division" }, { children: [_jsx("div", { className: "indicator-color", style: { backgroundColor: (_d = categoryColors[category]) !== null && _d !== void 0 ? _d : "#73BDCE" } }), _jsxs(BusinessItem, __assign({ className: "business-division-item" }, { children: [category !== "Matriz" ? (_jsx("div", __assign({ className: "division-icon" }, { children: categoryImage[category] }))) : (_jsx(_Fragment, { children: _jsx("div", { className: "matrix-division", style: {
                                        backgroundImage: "url(".concat((_e = divisionsData[0]) === null || _e === void 0 ? void 0 : _e.pictureUrl),
                                    } }) })), _jsxs("div", __assign({ className: "business-division-item-branch" }, { children: [_jsxs("div", __assign({ className: "business-descriptions" }, { children: [_jsx("div", __assign({ className: "business-category" }, { children: categoryLabels[category] })), _jsx("div", __assign({ className: "business-name", style: { color: (_f = categoryColors[category]) !== null && _f !== void 0 ? _f : "#4E4E4E" } }, { children: name }))] })), category !== "Matriz" && (_jsxs(Stack, __assign({ direction: "row", spacing: 2 }, { children: [validationDivisionsErrors && (_jsx(Divider, { orientation: "vertical", sx: {
                                                    height: "auto",
                                                    borderColor: "#DFDFDF",
                                                } })), validationDivisionsErrors && (_jsx(MuiTooltip, __assign({ sx: {
                                                    zIndex: handleFullScreen && 11000,
                                                }, title: _jsxs(_Fragment, { children: [(_g = divisionsErrors === null || divisionsErrors === void 0 ? void 0 : divisionsErrors.warnings) === null || _g === void 0 ? void 0 : _g.map(function (item, index) { return (_jsx(MuiTypography, __assign({ variant: "caption", style: { marginLeft: "0px!important" } }, { children: item === null || item === void 0 ? void 0 : item.message }), index)); }), (_h = divisionsErrors === null || divisionsErrors === void 0 ? void 0 : divisionsErrors.criticalErrors) === null || _h === void 0 ? void 0 : _h.map(function (item, index) { return (_jsx(MuiTypography, __assign({ variant: "caption", style: { marginLeft: "0px!important" } }, { children: item === null || item === void 0 ? void 0 : item.message }), index)); })] }) }, { children: _jsxs(Icon, __assign({ className: "button-details", style: {
                                                        backgroundColor: "transparent",
                                                    } }, { children: [((_j = divisionsErrors === null || divisionsErrors === void 0 ? void 0 : divisionsErrors.warnings) === null || _j === void 0 ? void 0 : _j.length) > 0 && _jsx(MoreInformationIcon, { size: 20, color: "#005494" }), ((_k = divisionsErrors === null || divisionsErrors === void 0 ? void 0 : divisionsErrors.criticalErrors) === null || _k === void 0 ? void 0 : _k.length) > 0 && _jsx(AlertIcon, { size: 20, color: "#f05b4f" })] })) }))), validationDivisionsErrorsDefault ? (_jsx(Icon, __assign({ className: "button-details", onClick: function () { return handleClick(item, validationDivisionsErrorsDefault); } }, { children: _jsx(SearchIcon, { size: 15, color: "#fb9d24" }) }))) : null, validationDivisionsErrors && (_jsx(MuiTooltip, __assign({ sx: {
                                                    zIndex: handleFullScreen && 11000,
                                                }, title: titleTooltipEdit }, { children: _jsx(Icon, __assign({ className: "button-details", onClick: function () { return handleClick(item, validationDivisionsErrors); }, onMouseEnter: function () { return setIsHovered(true); }, onMouseLeave: function () { return setIsHovered(false); } }, { children: _jsx(MdModeEditOutline, { size: 15, color: isHovered ? "#FFFFFF" : defaultColor }) })) })))] })))] }))] }))] }))] })));
}
