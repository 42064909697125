import styled from 'styled-components'

export const HeaderBox = styled.div`
align-items:center
	display: flex;
	width: 100%;
`
export const SectionBox = styled.div`
	width: 100%;
`
export const BtnHeaderBox = styled.div`
	margin-left: 0.875rem;
`
