/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'
import { InputText } from './styles'

import useBrasilCoin, {
	ConvertToNumber,
	GetTextColor,
	handleChangeSplit,
} from './utils'

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

const MonetaryInput = props => {
	const {
		className = '',
		max,
		style = {},
		name,
		value,
		setValue,
		helperText,
		error,
		label,
		disabled,
		fullWidth = false,
		onChange,
	} = props

	const [originalValueArray, setOriginalValueArray] = useState([])
	const [colors] = useState(getThemeColorFromLocalStorage())

	useEffect(() => {
		const newValue = handleChangeSplit(`${value}`)
		setOriginalValueArray(newValue.split('') || [])
	}, [])

	useEffect(() => {
		const newValue = ConvertToNumber(originalValueArray, value, max)

		onChange(newValue)
	}, [name, originalValueArray])

	const handleKeyDown = useCallback(
		e => {
			const { key, keyCode } = e

			if (keyCode === 8) {
				let newOriginalValueArray = [...originalValueArray]
				newOriginalValueArray.pop()
				setOriginalValueArray(newOriginalValueArray)
			} else {
				let newKey = key.replace(/[^0-9\.]+/g, '')
				if (newKey != '')
					setOriginalValueArray([...originalValueArray, key])
			}

			if (
				(value === 0 && !VALID_FIRST.test(key)) ||
				(value !== 0 &&
					!VALID_NEXT.test(key) &&
					keyCode !== DELETE_KEY_CODE)
			) {
				return
			}
			const valueString = value && value.toString()
			let nextValue
			if (keyCode !== DELETE_KEY_CODE) {
				const nextValueString =
					value === 0 ? key : `${valueString}${key}`
				nextValue = Number.parseInt(nextValueString, 10)
			} else {
				const nextValueString = valueString && valueString.slice(0, -1)
				nextValue =
					nextValueString === ''
						? 0
						: Number.parseInt(nextValueString, 10)
			}
			if (nextValue > max) {
				return
			}
		},
		[max, originalValueArray, value],
	)

	return (
		<InputText
			label={label}
			borderText={GetTextColor(colors)}
			size="small"
			variant="outlined"
			className={className}
			fullWidth={fullWidth}
			inputMode="numeric"
			onKeyDown={handleKeyDown}
			style={style}
			value={useBrasilCoin(value)}
			error={error}
			helperText={error ? helperText : ''}
			disabled={disabled}
			InputProps={{
				style: style,
			}}
		/>
	)
}

export default MonetaryInput
