import React from 'react'
import PropTypes from 'prop-types'
import ReactFlow, { ReactFlowProvider } from 'reactflow'

import 'reactflow/dist/style.css'

import { Container } from './styles'

const ReactFlowWrapper = ({ nodes, edges }) => {
	return (
		<ReactFlowProvider>
			<Container>
				<ReactFlow defaultNodes={nodes} defaultEdges={edges} nodes={nodes} edges={edges}></ReactFlow>
			</Container>
		</ReactFlowProvider>
	)
}

ReactFlowWrapper.propTypes = {
	nodes: PropTypes.array,
	edges: PropTypes.array,
}

export default ReactFlowWrapper
