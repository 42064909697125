/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import * as S from './styles'

// @ components
import * as Tab from 'components/NewTab/styles'
import Generation from './components/Generation'
import Cashback from './components/Cashback'
import RescueTab from './components/Rescue'

// @ API
import { GetProductTypeOfBenefit } from 'redux/api/productPage'

//@Utils
import { defaultType, extractProductIdFromUrl, userId } from './mocks'
import CalcMemory from './components/CalculationMemory'
import Cookies from 'js-cookie'
import CustomModal from './components/Modal'

const Parametrization = ({
	rules,
	multiple,
	grouper,
	division,
	fetchData,
	productData,
	setDivision,
	setMultiple,
	loading,
	editMode,
	setEditMode,
	setLoading,
	parameterization,
	selectedDivision,
	setReloadProduct,
	setSelectedDivision,
	setParameterization,
	selectedDivisionIdGen,
	selectedDivisionIdRed,
	selectedDivisionIdCash,
	setSelectedDivisionIdGen,
	setSelectedDivisionIdRed,
	setSelectedDivisionIdCash,
	setMultipleParameterization,
}) => {
	const { t } = useTranslation()
	const Grouper = Cookies.get('Grouper')
	const [typeOfBenefit, setTypeOfBenefit] = useState({})
	const [openCalcMemory, setOpenCalcMemory] = useState(false)
	const [circularProgress, setCircularProgress] = useState(false)
	const IsBranch = Cookies.get('IsBranch')
	const [tabIndex, setTabIndex] = useState(0)

	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
		tabIndex: null,
	})

	const [modalCancel, setModalCancel] = useState({
		open: false,
		title: '',
		subtitle: '',
	})

	const [typeOfBenefitRedeem, setTypeOfBenefitRedeem] = useState([defaultType])

	const [typeOfBenefitPoints, setTypeOfBenefitPoints] = useState([defaultType])

	const [typeOfBenefitCashback, setTypeOfBenefitCashback] = useState([defaultType])

	const productId = extractProductIdFromUrl()
	const partnerId = userId

	const onSetTab = index => {
		if (editMode) {
			setModalParameterization({
				open: true,
				title: 'As alterações realizadas serão perdidas.',
				subtitle: 'Deseja continuar?',
				tabIndex: index,
			})
		} else {
			setTabIndex(index)
			fetchData()
		}
	}

	useEffect(() => {
		const fetchTypeOfBenefit = async () => {
			setCircularProgress(true)
			try {
				const typeOfBenefitData = await GetProductTypeOfBenefit()

				if (!_.isEmpty(typeOfBenefitData)) {
					setTypeOfBenefit(IsBranch ? typeOfBenefitData : typeOfBenefitData.filter(item => item.value !== 'ByDivision'))
				}
			} catch (error) {
				console.error('Erro ao buscar o tipo de benefício:', error)
			} finally {
				setCircularProgress(false)
			}
		}
		fetchTypeOfBenefit()
	}, [productId, partnerId])

	const sharedProps = {
		rules,
		multiple,
		setMultiple,
		division,
		grouper: grouper || Grouper,
		loading,
		editMode,
		setEditMode,
		setLoading,
		setSelectedDivision,
		selectedDivision,
		circularProgress,
		modalCancel,
		setModalCancel,
		typeOfBenefitPoints,
		setTypeOfBenefitPoints,
		setTypeOfBenefitRedeem,
		typeOfBenefitCashback,
		setModalParameterization,
		setTypeOfBenefitCashback,
		parameterization,
		typeOfBenefitRedeem,
		typeOfBenefit,
		productData,
		setMultipleParameterization,
		setParameterization,
		setDivision,
		setReloadProduct,
		selectedDivisionIdGen,
		setSelectedDivisionIdGen,
		selectedDivisionIdRed,
		setSelectedDivisionIdRed,
		selectedDivisionIdCash,
		setSelectedDivisionIdCash,
		openCalcMemory,
		setOpenCalcMemory,
	}

	return (
		<S.Container>
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					fetchData()
					setTabIndex(modalParameterization.tabIndex)
					setEditMode(false)
					setModalParameterization({ open: false })
				}}
			/>
			<CustomModal
				onClose={() => setModalCancel({ open: false })}
				open={modalCancel?.open}
				title={modalCancel?.title}
				subtitle={modalCancel?.subtitle}
				onConfirm={() => {
					setEditMode(false)
					fetchData()
					setModalCancel({ open: false })
				}}
			/>
			<S.SnackContainer>
				<S.CustomCard>
					<Tab.NewTabs defaultIndex={0} selectedIndex={tabIndex} onSelect={index => onSetTab(index)}>
						<Tab.NewTabList>
							<Tab.NewTab>{t('Geração')}</Tab.NewTab>
							<Tab.NewTab>{t('Resgate')}</Tab.NewTab>
							{rules?.cashbackRules?.allowCashback && rules?.digitalWalletRules?.allowDigitalWallet && <Tab.NewTab>{t('Cashback')}</Tab.NewTab>}
						</Tab.NewTabList>

						<Tab.NewTabPanel>
							<Generation {...sharedProps} />
						</Tab.NewTabPanel>
						<Tab.NewTabPanel>
							<RescueTab {...sharedProps} />
						</Tab.NewTabPanel>
						{rules?.cashbackRules?.allowCashback && rules?.digitalWalletRules?.allowDigitalWallet && (
							<Tab.NewTabPanel>
								<Cashback {...sharedProps} />
							</Tab.NewTabPanel>
						)}
					</Tab.NewTabs>
				</S.CustomCard>
				<CalcMemory open={openCalcMemory} productData={productData ?? []} onClose={() => setOpenCalcMemory(!openCalcMemory)} />
			</S.SnackContainer>
		</S.Container>
	)
}

export default Parametrization
