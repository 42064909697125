import styled from 'styled-components'

const ColorLuminance = (hex, lum) => {
	hex = String(hex).replace(/[^0-9a-f]/gi, '')
	if (hex.length < 6) {
		hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
	}
	lum = lum || 0
	var rgb = '#',
		c,
		i
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i * 2, 2), 16)
		c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
		rgb += ('00' + c).substr(c.length)
	}
	return rgb
}

export const Container = styled.div`
	.MuiToolbar-regular {
		min-height: 25px;
	}
	td:nth-child(2),
	th:nth-child(2) {
		text-align: left;
	}
`

export const ButtonAction = styled.div`
	width: 30px;
	height: 30px;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #f05b4f;
	background-color: #eeeeee;
	border-radius: 6px;

	cursor: pointer;
	:hover {
		color: #fff;
		background-color: #f05b4f;
	}
`
