import styled from 'styled-components'
import { Button } from '@material-ui/core';
import { getThemeColorFromLocalStorage, ColorLuminance } from '../../../../../redux/api/themeColors'

export const Container = styled.div`
`

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: 25px;
`

export const Titles = styled.div`
	margin-bottom: 1.563rem;
`

export const ButtonClose = styled.div`
	height: 40px;
    width: 100px;
    min-width: 40px;
    border: 1px solid #c3c3c3;
    background-color: #fff;
    font-size: 16px;
    display: flex;
    color: #333;
    align-items: center;
    justify-content: center;
	border-radius: 6px;

	:hover{
		background-color: #F05B4F;
        color: #fff;
        border: 1px solid #F05B4F;
	}
`