import styled from 'styled-components'

const getBGCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor
	) {
		return props.themeColor.structure.backgroundColor + ' !important'
	}
	return '#fff'
}
const getTextCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.textColor
	) {
		return props.themeColor.structure.textColor + ' !important'
	}
	return '#fff'
}
const getButtonColor = (props) => {
	if (
		props.themeColor &&
		props.themeColor.navigation &&
		props.themeColor.navigation.buttons &&
		props.themeColor.navigation.buttons.backgroundColor
	) {
		return (
			props.themeColor.navigation.buttons.backgroundColor + ' !important'
		)
	}
	return `#fff`
}
const getHighLighTextColor = (props) => {
	if (
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor
	) {
		return props.themeColor.titles.highlight.textColor + ' !important'
	}
	return `#fff`
}
export const TableContainer = styled.div`
	.MuiTableCell-root {
		
		> div {
			.MuiIconButton-root {
				background: transparent !important;
				box-shadow: none !important;
			}
			button {
				.MuiTouchRipple-root {
					display: none !important;
				}
				cursor: pointer;
				/* width: 40px; */
				box-shadow: none !important;
			}
		}
	}


	.MuiTableCell-body {
		> div {
			justify-content: center;
			align-items: center;
		}
	}

	table tr:nth-child(even) {
    	background-color: #ffffff;
	}

	.MuiTableBody-root {
		tr {
			color: #717171;

			td {
				font-size: 14px !important;
				

				> div p {
					margin: 0px;
					font-size: 13px;
					font-style: italic;
				}
			}
		}
	}

	.button-span {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 5px;
		border-radius: 5px;
		background-color: ${(props) => getBGCol(props)};
		color: ${(props) => getTextCol(props)};
	}

	.yellow {
		background-color: #e69a00;
	}

	.disabled {
		background-color: #d3d3d3;
	}

	.red {
		background-color: red;
	}

	.MuiIconButton-root {
		padding: 5px;
	}

	.makeStyles-tableTitle-323 span {
		color: ${(props) => getButtonColor(props)};
	}

	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) => getHighLighTextColor(props)};
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) => getBGCol(props)};
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => getBGCol(props)};
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) => getBGCol(props)};
	}
`
