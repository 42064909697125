import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

export default function getDefaultColor() {
	const getThemeColorStructureBackground = () => {
		if (getThemeColorFromLocalStorage()) {
			if (getThemeColorFromLocalStorage().structure) {
				if (getThemeColorFromLocalStorage().structure.backgroundColor) {
					return getThemeColorFromLocalStorage().structure.backgroundColor
				}
			}
		}
		return '#08993A'
	}

	return getThemeColorStructureBackground()
}
