export const DefaultProductData = {
	standardAdministrationFeeApplied: '',
	standardGrossValue: '',
	standardAccumulatePoints: '',
	customAdministrationFeeApplied: '',
	customGrossValue: '',
	customAccumulatePoints: '',
	calculationMemory: {
		conversionRate: '',
		administrationFee: '',
		valueToConvert: '',
		multiplierFactor: '',
	},
}

export const StandardColors = {
	Costcenter: '#3EC450',
	BusinessUnit: '#FB9006',
	Branch: '#7E6BB9',
	Department: '#1BBED3',
}
