import * as actionTypes from "../actions/actionsTypes";
import { updateObject } from "../utility";

const initialState = {
  transactionData: [],
  transactionDetails: [],
  transactionByIdDataFailed: false,
  transactionPost: false,
  lsTransactionsData: [],
  transactionDataFailed: false,
  lsChannelTypes: [],
  lstransactionTypeData: [],
  transactionTypeDataFailed: false,
  transactionChannelTypesDataFailed: false,
};

const fetchTransactionsData = (state, action) => {
  return updateObject(state, {
    lsTransactionsData: action.lsTransactionsData,
  });
};

const postTransactionData = (state, action) => {
  return updateObject(state, {
    transactionPost: action.transactionPost,
  });
};

const fetchTransactionById = (state, action) => {
  return updateObject(state, {
    transactionData: action.transactionData,
  });
};

const fetchTransactionDetails = (state, action) => {
  return updateObject(state, {
    transactionDetails: action.transactionDetails,
  });
};

const fetchTransactionType = (state, action) => {
  return updateObject(state, {
    lstransactionTypeData: action.lstransactionTypeData,
  });
};

const fetchTransactionChannelTypes = (state, action) => {
  return updateObject(state, {
    lsChannelTypes: action.lsChannelTypes,
  });
};

// DATA FAIL
const fetchTransactionsFailed = (state, action) => {
  return updateObject(state, {
    transactionDataFailed: true,
  });
};

const fetchTransactionsTypeFailed = (state, action) => {
  return updateObject(state, {
    transactionTypeDataFailed: true,
  });
};

const fetchTransactionChannelTypesFailed = (state, action) => {
  return updateObject(state, {
    transactionChannelTypesDataFailed: true,
  });
};
//
const transactionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSACTIONS_DATA:
      return fetchTransactionsData(state, action);
    case actionTypes.POST_TRANSACTION_DATA:
      return postTransactionData(state, action);
    case actionTypes.FETCH_TRANSACTION_BY_ID:
      return fetchTransactionById(state, action);
    case actionTypes.FETCH_TRANSACTION_DETAILS:
      return fetchTransactionDetails(state, action);
    case actionTypes.FETCH_TRANSACTION_TYPE:
      return fetchTransactionType(state, action);
    case actionTypes.FETCH_TRANSACTION_CHANNEL_TYPES:
      return fetchTransactionChannelTypes(state, action);

    case actionTypes.FETCH_TRANSACTIONS_FAILED:
      return fetchTransactionsFailed(state, action);

    case actionTypes.FETCH_TRANSACTIONS_TYPE_FAILED:
      return fetchTransactionsTypeFailed(state, action);

    case actionTypes.FETCH_TRANSACTIONS_CHANNE_TYPES_FAILED:
      return fetchTransactionChannelTypesFailed(state, action);

    default:
      return state;
  }
};

export default transactionReducer;
