import { Button, Modal, Paper, Typography } from '@material-ui/core'
import styled from 'styled-components'

const getColor = (themeColor) => {
	const color =
		themeColor &&
		themeColor.titles &&
		themeColor.titles.main &&
		themeColor.titles.main.textColor
	return color
}

export const ModalContainer = styled(Modal)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	height: 100%;

	.paddPag {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 50px;
		transform: translateX(10px);
	}
	.paddPagText {
		padding-left: 30px;
		padding-right: 30px;
		padding-bottom: 10px;
		transform: translateX(10px);
	}
`

export const Close = styled.div`
	display: flex;
	justify-content: flex-end;
	transform: translateX(17px);
	button {
		align-items: center;
		justify-content: center;
		display: flex;
		border: none;
		border-radius: 5px;
		background-color: #F05B4F;
		cursor: pointer;
		text-align: center;
		white-space: nowrap;
		font-size: 15px;
		color: #fff;
		padding: 0.40625rem 0.875rem;
		width: 100px;
		height: 40px;
	}

	i {
		margin-right: .625rem;
	}
`
export const CloseFile = styled(Button)`
	border: none;
	border-radius: 5px;
	background-color: #F05B4F !important;
	cursor: pointer;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	color: #fff !important;
	padding: 0.40625rem 0.875rem;
	width: 100px;
	height: 40px;
	font-size: 15px;

	span {
		font-weight: 400 !important;
	}
	i {
		margin-right: .625rem;
	}
`
export const CloseButton = styled.div`
	display: flex;
	justify-content: flex-end;
	margin-top: 16px;
	margin-right: 16px;
	button {
		display: flex;
		border: none;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		background-color: #F05B4F;
		cursor: pointer;
		color: #fff;
		font-size: 15px;
		padding: 0.40625rem 0.875rem;
		width: 100px;
		height: 40px;
	}
	i {
		margin-right: .625rem;
	}
`

export const PaperContainer = styled(Paper)`
	margin-bottom: 40px;
	position: relative;
	/* padding: 20px 20px 20px 35px; */
	max-width: ${({ maxwidth }) => maxwidth};
	min-width: 32vw;
	padding: ${({ padding }) => padding};

	span {
		font-weight: 1000;
	}
	&.MuiPaper-elevation1 {
		box-shadow: ${({ shadow }) => shadow};
	}
	&.MuiPaper-rounded {
		border-radius: 17px;
	}

	.sub-info {
		color: #48484c;
		font-size: 0.9rem;
		font-weight: bold;
	}

	.info-geral {
		color: #31394d;
		font-weight: bold;
		font-size: 1.3rem;
		> div {
			margin-right: 5px;
		}
	}

	.infoText {
		text-align: left;
		letter-spacing: 0px;
		opacity: 1;
		text-transform: none;
	}
	.infoTextFile {
		text-align: left;
		font: normal normal normal 14px/22px Roboto;
		letter-spacing: 0px;
		color: '#636363';
		opacity: 0.8;
		margin-bottom: 13px;
	}
	.infoTextReserv {
		text-align: center;
		font: normal normal normal 14px/24px Roboto;
		letter-spacing: 0px;
		color: #636363;
		opacity: 1;
	}

	.infoTextFileTitle {
		text-align: center;
		font: normal normal normal 16px/22px Roboto;
		letter-spacing: 0px;
		color: '#333333' !important;
		opacity: 1;
		width: 100%;
	}

	.buttonContainerModal {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		gap: 15px;

		span {
			font-weight: 400;
		}
	}

	.infoMinimumRecords {
		text-align: center;
		font: normal normal bold 18px/30px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
		width: 100%;
	}

	.blue {
		color: #3f51b5;
	}

	.close-button {
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: none;
		align-items: center;
		justify-content: center;
		color: #F05B4F;

		svg {
			margin-right: 3px;
		}
		i {
			margin-right: 5px;
			//transform: translateY(1px);
		}
	}

	.sub-total {
		color: #31394d;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
	}

	.sub-price {
		color: #49d489;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 18px;
	}
	.button-box {
		position: absolute;
		margin-top: -28px;
		top: 0;
		margin-right: -28px;
		right: 0;
		.MuiIconButton-label {
			background-color: #3f51b5;
			border-radius: 50%;
			.MuiIcon-colorPrimary {
				color: #fff;
				font-size: 2rem;
			}
		}
	}
`

export const Icon = styled.div`
	color: #f9bb86;
	display: flex;
	font-size: 79px;
	justify-content: center;
	margin: 18px 0;
	margin-top: 6px;
	top: -10px;
`
export const TypographyTXT = styled(Typography)`
	text-align: center;
	font: normal normal normal 16px/25px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`
export const MinimumRecords = styled(Typography)`
	text-align: center;
	font: normal normal normal 14px/24px Roboto;
	letter-spacing: 0px;
	color: #636363;
	opacity: 1;
`

export const IconFile = styled.i`
	font-size: 23px;
	cursor: pointer;
	margin: auto 5.34px;
	margin-left: 7px;
	color: ${({ color }) => getColor(color)};
`
