import React from 'react'

// Context
import { AccountsProvider } from '../contexts/AccountsContext'

// Components
import { PagesConfig } from './PagesConfig'

export function Accounts() {
	return (
		<AccountsProvider>
			<PagesConfig />
		</AccountsProvider>
	)
}
