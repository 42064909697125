import _ from 'lodash'
import { ValidateBusinessDivisionAllErrors } from '../../../../../../../../redux/api/partners/partners'
import { defaultError } from '../../../../../Mocks/divisionsErrors'
import { businessDivisionDataCreate, businessDivisionDataUdateFormat, defaultBusinessMock } from '../../../../../Mocks'

export const filterBusinessDivision = (parentsList, type) => {
	const optionsCostCenter = ['Matriz', 'Branch', 'BusinessUnit', 'Franchise']
	const optionsBusinessUnit = ['Matriz', 'Branch', 'Franchise']
	const optionsBranch = ['Matriz']
	const optionsDepartment = ['Matriz', 'Branch', 'BusinessUnit', 'CostCenter', 'Franchise']

	if (parentsList?.length === 0) return

	if (type === 'Department') {
		const res = parentsList.filter(item => optionsDepartment.includes(item.type))

		return res
	}

	if (type === 'CostCenter') {
		const res = parentsList.filter(item => optionsCostCenter.includes(item.type))

		return res
	}

	if (type === 'BusinessUnit') {
		const res = parentsList.filter(item => optionsBusinessUnit.includes(item.type))

		return res
	}

	if (type === 'Branch') {
		const res = parentsList.filter(item => optionsBranch.includes(item.type))

		return res
	}

	if (type === 'Franchise') {
		const res = parentsList.filter(item => optionsBranch.includes(item.type))

		return res
	}
}

export function incluirBranches(objeto, novoArray, parentId = null) {
	const newObjeto = { ...objeto, parentId: parentId }
	novoArray.push(newObjeto)

	if (objeto.branches && objeto.branches.length > 0) {
		for (let i = 0; i < objeto.branches.length; i++) {
			incluirBranches(objeto.branches[i], novoArray, objeto.id)
		}
	}
}

export const FilterChart = (organogramaData, businessMock) => {
	if (organogramaData?.length > 0) {
		if (!_.isEmpty(organogramaData[0]?.branches)) {
			const NewbusinessMock = businessMock.filter(item => item.type !== 'Simple')
			return NewbusinessMock || []
		}
	}
	return businessMock
}

export const translateErrors = (errorData, t) => {
	if (_.isEmpty(errorData)) return []
	const translatedErrors = errorData.map(error => {
		const translatedCriticalErrors = error.criticalErrors.map(criticalError => ({
			...criticalError,
			message: t(criticalError.message), // Traduz a mensagem de erro crítico
		}))

		const translatedWarnings = error.warnings.map(warning => ({
			...warning,
			message: t(warning.message), // Traduz a mensagem de aviso
		}))

		return {
			...error,
			criticalErrors: translatedCriticalErrors,
			warnings: translatedWarnings,
		}
	})

	return translatedErrors
}

export const validateBusinessDivisionAllErrors = async ({ partnerId, setLoading, setDivisionsErrors, t }) => {
	if (!partnerId) return
	setLoading(true)
	try {
		const res = await ValidateBusinessDivisionAllErrors(partnerId)
		if (res?.data?.errors?.length === 0) {
			setDivisionsErrors(translateErrors(res?.data?.data, t))
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

export const onCancelProps = ({
	setOnOpen,
	setSelectedType,
	setOModalCancel,
	setBusinessDivisionData,
	setIsEditData,
	setEditDivision,
	reloadFormsData,
	defaultBusinessMock,
	setDivisionData,
}) => {
	setOnOpen(false)
	setSelectedType(defaultBusinessMock)
	setOModalCancel(prev => ({ ...prev, open: false }))
	setBusinessDivisionData(prev => {
		return {
			...prev,
			typeOfDivision: null,
			isEditBusinessDivision: false,
			isNewBusinessDivision: false,
			divisionDetails: null,
		}
	})
	setIsEditData(false)
	setEditDivision(false)
	reloadFormsData()
	setDivisionData(null)
}

export const removeDivisionById = async ({ divisionId, partnerService, setLoading, setSnackStatus, setEditDivision, reloadFormsData }) => {
	setLoading(true)
	try {
		const res = await partnerService.deletePartnerData(divisionId)

		if (res?.status === 200 && res?.data?.success) {
			setSnackStatus({
				open: true,
				severity: 'success',
				text: 'Divisão de negócio excluída com sucesso!',
			})
			setEditDivision(false)
			reloadFormsData()
		} else {
			setSnackStatus({
				...defaultError,
				text: 'Não foi possível excluir a divisão de negócio!',
			})
		}
	} catch (error) {
		console.log(error)
		setSnackStatus(defaultError)
	} finally {
		setLoading(false)
	}
}

export const onSubmitProps = async ({
	data,
	businessDivisionData,
	selectedDivision,
	segmentsList,
	setSnackStatus,
	onCancel,
	updateBusinessDivisionData,
	partnerId,
	customFieldsForm,
	divisionData,
	setIsLoadingData,
	selectDivisionDetails,
	partnerService,
}) => {
	setIsLoadingData(true)
	try {
		if (businessDivisionData?.isNewBusinessDivision) {
			const formatedData = businessDivisionDataCreate({
				data,
				segments: businessDivisionData?.divisionSegment,
				segmentsList,
				partnerId,
				selectedDivisionType: selectedDivision,
				businessDivisionData,
				customFieldsForm,
			})
			const res = await partnerService.createBusinessDivisions({ data: formatedData })

			if (res?.success) {
				selectDivisionDetails(defaultBusinessMock)
				setSnackStatus({
					open: true,
					severity: 'success',
					text: 'Divisão de negócio criada com sucesso!',
				})
				onCancel()

				updateBusinessDivisionData('isEditBusinessDivision', false)
			}
			if (res?.errors?.length > 0) {
				setSnackStatus({
					open: true,
					severity: 'error',
					text: res?.errors[0]?.message,
				})
			}
		} else {
			const formatedData = businessDivisionDataUdateFormat({
				data,
				segments: businessDivisionData?.divisionSegment,
				segmentsList,
				partnerId,
				divisionData,
				selectedDivisionType: selectedDivision,
				businessDivisionData,
				customFieldsForm,
			})

			const res = await partnerService.updateBusinessDivisions({ data: formatedData })

			if (res?.success) {
				setSnackStatus({
					open: true,
					severity: 'success',
					text: 'Divisão de negócio atualizada com sucesso!',
				})
				onCancel()
				updateBusinessDivisionData('isEditBusinessDivision', false)
			}
			if (res?.errors?.length > 0) {
				setSnackStatus({
					open: true,
					severity: 'error',
					text: res?.errors[0]?.message,
				})
			}
		}
	} catch (error) {
		setSnackStatus(defaultError)
	} finally {
		setIsLoadingData(false)
	}
}
