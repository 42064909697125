import React from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import { TextField, Grid, IconButton } from '@material-ui/core'
import { Add, CodeSharp, Remove } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Popper from '@material-ui/core/Popper'

// core components
import Loader from 'components/Loader/Loader.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import Section from 'components/Section'
import CustomPagination from 'components/CustomPagination/CustomPagination.jsx'
import GriTable from './components/GriTable.jsx'
import NoResults from 'components/NoResults/NoResults.jsx'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import styles from './invoices.module.scss'
import { germiniDefaultColors, grayColor } from 'assets/jss/material-dashboard-pro-react'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { connectRouterState } from 'utils/connectRouterState'

import { numberFormatText } from 'utils/utils'
import CustomDateNew from 'components/CustomDateNew'

import {
	GetConsumer,
	GetConsumerById,
	GetSalesHistory,
	GetStatementData,
	GetTransactionChannelTypes,
	HideAlert,
	HideLoader,
	ShowLoader,
	SweetAlert,
} from 'redux/actions'

// Utils
import compose from 'utils/compose'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/styles'
import * as S from './style'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { envs } from '../../redux/api/windowServerData.js'
import { getProgramTitle } from '../../utils/utils.js'

const invoicesStyle = {
	...extendedFormsStyle,
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	inputHeight: {
		height: '9px',
	},
}

const customSelectStyles = {
	control: (base, state) => ({
		...base,
		input: { height: '25px' },
		boxShadow: state.isFocused ? 0 : 0,
		borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		'&:hover': {
			borderColor: state.isFocused ? grayColor[2] : base.borderColor,
		},
	}),
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor: grayColor[2],
			},
			'&.Mui-focused fieldset': {
				borderColor: grayColor[2],
			},
		},
	},
})(TextField)

const useStyles = makeStyles(theme =>
	createStyles({
		root: {
			'& .MuiAutocomplete-listbox': {
				'& :hover': {
					color: '#ffffff',
					backgroundColor:
						getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
							? getThemeColorFromLocalStorage().structure.backgroundColor
							: '#fff !important',
				},
				'& li': {
					backgroundColor: '#ffffff',
				},
			},
		},
	}),
)

const CustomPopper = function(props) {
	const classesPoper = useStyles()
	return <Popper {...props} className={classesPoper.root} placement="bottom" />
}

class Invoices extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			results: false,
			recordCount: 0,
			currentPage: 1,
			pageSize: 0,
			toggleDateFilter: 1,
			salesHistoryFailed: false,

			disabeldData: false,

			startDate: moment(new Date()),
			endDate: moment(new Date()),
			searchStartDate: moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
			searchEndDate: moment(new Date()).format('YYYY-MM-DD'),

			searchDocumentId: '',
			searchCode: '',
			searchConsumer: '',
			inputConsumer: '',
			selectedConsumer: '',
			consumerTypeOptions: [],
			channelTypeOptions: [],
			searchChannelType: '',
			selectedChannelType: '',
			open: false,
			isHidden: true,
			filtered: false,
			themeColor: getThemeColorFromLocalStorage(),
		}
		moment.locale(localStorage.getItem('i18nextLng'))
	}

	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		let urlState = _.get(this.props, 'urlState')
		this.setState({ themeColor: getThemeColorFromLocalStorage() })
		//
		this.setState(
			{
				searchStartDate: urlState.searchStartDate,
				startDate: moment(urlState.searchStartDate).isValid() && moment(urlState.searchStartDate).format('L'),
				searchEndDate: urlState.searchEndDate,
				endDate: moment(urlState.searchEndDate).isValid() && moment(urlState.searchEndDate).format('L'),
				searchConsumer: urlState.searchConsumer,
				searchChannelType: urlState.searchChannelType,
				searchCode: urlState.searchCode,
				searchDocumentId: urlState.searchDocumentId,
			},
			() => {
				this.props.GetTransactionChannelTypes()
				this.props.GetConsumerById(urlState.searchConsumer)
				this.props.GetSalesHistory(
					_.get(this.props.urlState, 'searchStartDate', ''),
					_.get(this.props.urlState, 'searchEndDate', ''),
					urlState.searchConsumer,
					urlState.searchChannelType.value,
					urlState.searchCode,
					urlState.searchDocumentId,
					this.state.currentPage,
				)
				//
				if (
					!_.isEmpty(this.state.searchStartDate) ||
					!_.isEmpty(this.state.searchEndDate) ||
					!_.isEmpty(this.state.searchConsumer) ||
					!_.isEmpty(this.state.searchChannelType) ||
					!_.isEmpty(this.state.searchDocumentId) ||
					!_.isEmpty(this.state.searchCode)
				) {
					this.setState({ filtered: true })
				}
			},
		)
		//
		// VERIFY WHEN ROUTE IS CHANGED
		if (this.props.salesHistoryFailed) {
			this.props.SweetAlert(
				<Alert
					error
					style={{ display: 'block' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}
	}

	componentDidUpdate(prevProps, prevState) {
		let prevUrlState = _.get(prevProps, 'urlState')
		let urlState = _.get(this.props, 'urlState')
		if (!_.isEqual(prevUrlState, urlState)) {
			this.props.ShowLoader(<Loader />)
			this.setState({ ...urlState })
			this.props.GetSalesHistory(
				urlState.searchStartDate,
				urlState.searchEndDate,
				urlState.searchConsumer,
				urlState.searchChannelType,
				urlState.searchCode,
				urlState.searchDocumentId,
				urlState.currentPage,
			)
		}

		if (prevProps.lsSalesHistory !== this.props.lsSalesHistory) {
			this.props.HideLoader()
			this.setState({ ...this.props.lsSalesHistory })
		}

		if (
			prevProps.invoiceDataFailed !== this.props.invoiceDataFailed ||
			prevProps.consumerDataFailed !== this.props.consumerDataFailed ||
			prevProps.partnerDataFailed !== this.props.partnerDataFailed
		) {
			this.props.HideLoader()
		}

		if (prevProps.lsChannelTypes !== this.props.lsChannelTypes) {
			this.setState({
				channelTypeOptions: this.setChannelTypeOptionState(),
			})
			if (!_.isEmpty(this.state.searchChannelType)) {
				let obj = _.find(this.props.lsChannelTypes, ['key', this.state.searchChannelType])
				let item = {
					value: obj.key,
					label: this.props.t('FIELD_CHANNEL_' + obj.value),
				}
				this.setState({ selectedChannelType: item })
			}
			this.props.HideLoader()
		}

		if (prevProps.consumerData !== this.props.consumerData) {
			this.setState({
				consumerTypeOptions: this.setConsumerTypeOptionState(),
			})
			if (!_.isEmpty(this.state.searchConsumer)) {
				let result = this.props.consumerData
				if (result) {
					const obj = {
						value: result.id,
						label: result.name,
					}
					this.setState({ selectedConsumer: obj })
				}
			}
			this.props.HideLoader()
		}

		if (prevProps.invoiceByIdDataFailed !== this.props.invoiceByIdDataFailed) {
			this.props.HideLoader()
		}
		if (prevProps.partnersData !== this.props.partnersData) {
			this.props.HideLoader()
		}
		if (prevProps.consumerDataFailed !== this.props.consumerDataFailed) {
			this.props.HideLoader()
		}

		if (prevProps.salesHistoryFailed !== this.props.salesHistoryFailed) {
			this.props.HideLoader()
			this.setState({ salesHistoryFailed: true })
		}

		if (prevState.salesHistoryFailed !== this.state.salesHistoryFailed) {
			this.props.SweetAlert(
				<Alert
					error
					style={{ display: 'block' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_GENERAL_ERROR')}
				</Alert>,
			)
		}
	}

	handleToggle = () => {
		this.setState({ open: !this.state.open })
	}

	setChannelTypeOptionState = () => {
		let list = []
		this.props.lsChannelTypes.map(item => {
			list.push({
				value: item.key,
				label: this.props.t('FIELD_CHANNEL_' + item.value),
			})
		})
		return list
	}

	setConsumerTypeOptionState = () => {
		const consumerList = this.props.consumerData.results
		let consumerOptions = []

		if (consumerList !== undefined) {
			consumerList.map(item => {
				consumerOptions.push({
					value: item.id,
					label: item.name + ' | ' + item.cpf,
				})
			})
		} else {
			this.props.GetConsumer('', '', '', '', 1).then(() => {
				this.props.lsConsumerData.results.map(consumer => {
					consumerOptions.push({
						value: consumer.id,
						label: consumer.name + ' | ' + consumer.cpf,
					})
				})
			})
		}

		return consumerOptions
	}

	handleConsumerChange = (event, value, reason) => {
		this.setState({
			searchConsumer: value.value && value.value,
			selectedConsumer: value,
		})
	}

	handleChange = event => {
		const id = event.target.id || event.target.name
		const value = event.target.value
		this.setState({ [id]: value })
	}

	handleDateChange = (state, name, event) => {
		var value = event
		if (this.state.disabeldData) {
			this.setState({
				searchstartDate: null,
				endDate: null,
				searchendDate: null,
				startDate: null,
			})
			return false
		}
		//
		if (this.state.startDate && name === 'endDate') {
			if (moment(this.state.searchstartDate).isAfter(moment(value))) {
				value = moment(this.state.searchstartDate)
			}
		}
		this.setState({
			[name]: value,
			[state]: moment(value).isValid() ? moment(value).format('YYYY-MM-DD') : '',
		})

		if (name === 'startDate' && this.state.endDate) {
			if (moment(this.state.searchendDate).isBefore(moment(value))) {
				this.handleDateChange('searchendDate', 'endDate', event)
			}
		}

		if (this.state.searchStartDate && this.state.searchEndDate) {
			let start = this.state.searchStartDate
			if (moment(this.state.searchStartDate).isAfter(moment(this.state.searchEndDate))) {
				this.setState({
					searchEndDate: start,
				})
			}
		}
	}

	handleFilter = clear => {
		const { searchCode, searchDocumentId, searchConsumer, searchStartDate, searchEndDate, searchChannelType } = this.state

		this.props.setURLState({
			searchChannelType: searchChannelType && searchChannelType,
			searchConsumer: searchConsumer && searchConsumer,
			searchStartDate,
			searchEndDate,

			searchCode,
			searchDocumentId,
			currentPage: 1,
		})
		//
		if (clear) {
			this.setState({ filtered: false })
		} else {
			this.setState({ filtered: true })
		}
	}

	handleSearch = currentPage => {
		this.props.ShowLoader(<Loader />)
		this.props.setURLState({ currentPage })
	}

	handleClearFilter = () => {
		this.setState(
			{
				filtered: false,
				searchCode: '',
				searchConsumer: '',
				selectedConsumer: '',
				searchChannelType: '',
				selectedChannelType: '',
				startDate: '',
				endDate: '',
				searchStartDate: '',
				searchEndDate: '',
			},
			() => {
				this.handleFilter(true)
			},
		)
	}

	handleSelectedChange = (event, value, reason) => {
		this.setState({
			searchChannelType: value.value && value.value,
			selectedChannelType: value,
		})
	}

	toggleShownRowIndex = index => {
		this.setState({
			showRowIndex: index,
		})
	}

	validation = currentDate => {
		const today = moment()

		if (moment(this.state.endDate, 'DD/MM/YYYY').isValid()) {
			if (currentDate.isAfter(this.state.endDate)) {
				return false
			}
		}
		return currentDate.isBefore(today)
	}

	valid = current => {
		const today = moment()
		return current.isAfter(this.state.startDate) && current.isBefore(today)
	}
	focousOut = () => {
		if (!moment(this.state.endDate, 'DD/MM/YYYY').isValid()) {
			this.setState({ endDate: '' })
		}
		if (!this.valid(moment(this.state.endDate), 'DD/MM/YYYY')) {
			this.setState({ endDate: '' })
		}
	}
	handleValueMask = e => {
		return e.target.value
	}
	render() {
		const { t, classes } = this.props
		const {
			filtered,
			salesHistoryFailed,
			selectedChannelType,
			searchCode,
			consumerTypeOptions,
			channelTypeOptions,
			selectedConsumer,
			open,
			results,
			recordCount,
			currentPage,
			pageSize,
			startDate,
			endDate,
		} = this.state
		//

		return (
			<div>
				<DocumentTitle title={getProgramTitle()} />
				<Grid>
					<Section title={t('MENU_SALES')} />
				</Grid>
				<S.SearchContain
					themeColor={
						this.state.themeColor && this.state.themeColor.structure && this.state.themeColor.structure.backgroundColor
							? this.state.themeColor.structure.backgroundColor
							: `#fff`
					}
					getBGCol={
						this.state.themeColor && this.state.themeColor.structure && this.state.themeColor.structure.backgroundColor
							? this.state.themeColor.structure.backgroundColor
							: `#fff`
					}
				>
					{/* PERÍODO */}

					<S.DateContain>
						<S.DateBox>
							<CustomDateNew
								value={startDate}
								isValidDate={this.validation}
								enableDates={true}
								label={t('DATETIME_FROM')}
								onChange={this.handleDateChange.bind(null, 'searchStartDate', 'startDate')}
							/>
						</S.DateBox>
						<S.DateBox>
							<CustomDateNew
								value={endDate}
								enableDates={true}
								isValidDate={this.valid}
								label={t('DATETIME_TO')}
								onChange={this.handleDateChange.bind(null, 'searchEndDate', 'EndDate')}
							/>
						</S.DateBox>
					</S.DateContain>

					{/* PARCEIRO */}
					<S.ConsumerBox>
						<Autocomplete
							styles={customSelectStyles}
							id="combo-box-demo"
							size="small"
							options={consumerTypeOptions}
							getOptionLabel={option => (option.label ? option.label : '')}
							getOptionSelected={(option, value) => value === undefined || value === '' || option.id === value.id}
							disableClearable={true}
							onChange={this.handleConsumerChange}
							value={selectedConsumer}
							PopperComponent={CustomPopper}
							renderInput={params => (
								(this.state.inputConsumer = params.inputProps.value),
								(
									<div ref={params.InputProps.ref}>
										<TextField
											style={{
												backgroundColor: '#ffffff',
												width: '100%',
											}}
											{...params}
											noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
											label={t('FIELD_CONSUMER')}
											variant="outlined"
											size="small"
										/>
									</div>
								)
							)}
						/>
					</S.ConsumerBox>

					{/* CODE */}
					<S.TransectionCodeBox>
						<CssTextField
							style={{
								backgroundColor: '#fff',
							}}
							className={styles.TextField}
							fullWidth
							label={t('FIELD_TRANSACTION_CODE')}
							id="searchCode"
							name="searchCode"
							value={searchCode}
							onChange={this.handleChange}
							variant="outlined"
							margin="dense"
						/>
					</S.TransectionCodeBox>

					{open && (
						/* CHANNEL TYPES */
						<S.BankBox>
							<Autocomplete
								styles={customSelectStyles}
								id="combo-box-demo"
								size="small"
								options={channelTypeOptions}
								getOptionLabel={option => (option.label ? option.label : '')}
								getOptionSelected={(option, value) => value === undefined || value === '' || option.id === value.id}
								disableClearable={true}
								onChange={this.handleSelectedChange}
								value={selectedChannelType}
								PopperComponent={CustomPopper}
								renderInput={params => (
									<div div ref={params.InputProps.ref}>
										<TextField
											style={{
												backgroundColor: '#fff',
												width: '100%',
											}}
											{...params}
											noOptionsMessage={() => t('RESULTS_NOT_FOUND')}
											label={t('FIELD_CHANNEL_TYPES')}
											variant="outlined"
											size="small"
										/>
									</div>
								)}
							/>
						</S.BankBox>
					)}

					<S.BtnBox>
						<S.MoreFilter
							onClick={this.handleToggle}
							title="Pesquisa avançada"
							style={{
								cursor: 'pointer',
								color: this.state.themeColor?.navigation?.buttons?.elementsColor,
								lineHeight: '36px',
							}}
						>
							<IconButton classes={{ root: classes.filterPlusButton }} title="Pesquisa avançada">
								{!open ? <Add /> : <Remove />}
							</IconButton>
							<span
								style={{
									color: this.state.themeColor?.navigation?.buttons?.elementsColor,
								}}
							>
								{!open ? t('SHOW_MORE_FILTERS') : t('HIDE_MORE_FILTERS')}
							</span>
						</S.MoreFilter>
						<S.BtnMoreFilter>
							<Button size="sm" variant="contained" style={{ height: '80-%' }} color="greenBorderButtonColor" onClick={this.handleClearFilter}>
								{t('BUTTON_CLEAN_FILTER')}
							</Button>
							<Button size="sm" variant="contained" color="greenButtonColor" onClick={() => this.handleFilter(false)}>
								{t('BUTTON_FIND')}
							</Button>
						</S.BtnMoreFilter>
					</S.BtnBox>
				</S.SearchContain>
				{/*  */}
				{filtered ? <Section title={t('TITLE_SEARCH_RESULTS')} /> : ''}
				<GridContainer justifyContent="center" style={{ paddingLeft: '15px' }}>
					{results &&
						results.map((item, index) => {
							return (
								<GriTable
									key={index}
									id={item.id}
									history={this.props.history}
									labels={[
										t('FIELD_DATE'), //Data
										t('FIELD_INVOICE_CODE'), // valor a pagar
										t('FIELD_TRANSACTION_CODE'), //Codigo de Transação
										t('FIELD_CONSUMER'), //consumidor
										t('FIELD_PURCHASE_TOTAL'), //total da compra
										t('FIELD_VALUE_PAID_INVOICES'), //valor pago em reais
										t('FIELD_VALUE_PAID_IN_POINTS_INVOICES'), //valor pago em pontos
										t('FIELD_AMOUNT_RECEIVABLE'), //a receber
										t('FIELD_CHANNEL_TYPES'), //canal de vendas
									]}
									values={[
										moment(item.date)
											.utc()
											.format('L'),
										item.code ? item.code : '',
										item.transactionCode && item.transactionCode ? item.transactionCode : ' - ',
										item.consumerName ? item.consumerName : ' - ',
										numberFormatText(
											item.total ? item.total : '0',
											localStorage.getItem('currencySymbol')
												? localStorage.getItem('currencySymbol') + ' '
												: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
												  envs.REACT_APP_CURRENCY_SYMBOL + ' ',
											2,
										),
										numberFormatText(
											item.totalCurrency,
											localStorage.getItem('currencySymbol')
												? localStorage.getItem('currencySymbol') + ' '
												: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
												  envs.REACT_APP_CURRENCY_SYMBOL + ' ',
											2,
										),
										numberFormatText(
											item.pointsRedeemed,
											localStorage.getItem('programCurrencySymbol')
												? localStorage.getItem('programCurrencySymbol') + ' '
												: envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
										),
										numberFormatText(
											item.partnerBalance > 0 ? item.partnerBalance : '0',
											localStorage.getItem('currencySymbol')
												? localStorage.getItem('currencySymbol') + ' '
												: //: envs.REACT_APP_CURRENCY_SYMBOL + ' ',
												  envs.REACT_APP_CURRENCY_SYMBOL + ' ',
											2,
										),
										// (item.partnerBalance > 0 ? item.partnerBalance : "0"),
										item?.partnerName ? item?.partnerName : t('FIELD_CHANNEL_' + item.channelType.value),
									]}
								/>
							)
						})}

					{(results && results.length === 0) || salesHistoryFailed ? <NoResults value={t('RESULTS_NOT_FOUND')} /> : ''}

					{results && results.length > 0 && (
						<div
							style={{
								textAlign: 'center',
								marginTop: '20px',
								width: '100%',
							}}
						>
							<CustomPagination
								pageSize={pageSize}
								recordCount={recordCount}
								offset={pageSize * (currentPage - 1)}
								clickEvent={(e, offset) => this.handleSearch(Math.round(offset / pageSize) + 1)}
							/>
						</div>
					)}
				</GridContainer>
			</div>
		)
	}
}

Invoices.propTypes = {
	classes: PropTypes.object,
}

const mapStateToProps = state => {
	return {
		lsConsumerData: state.consumer.lsConsumerData,
		partnersData: state.partners.partnersData,
		lsSalesHistory: state.invoice.lsSalesHistory,
		consumerData: state.consumer.consumerData,
		lsChannelTypes: state.transaction.lsChannelTypes,
		transactionChannelTypesDataFailed: state.transaction.transactionChannelTypesDataFailed,

		salesHistoryFailed: state.invoice.salesHistoryFailed,
		consumerDataFailed: state.consumer.consumerDataFailed,
		invoiceByIdDataFailed: state.invoice.invoiceByIdDataFailed,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetTransactionChannelTypes,
			GetConsumerById,
			GetConsumer,
			GetStatementData,
			GetSalesHistory,
			ShowLoader,
			HideLoader,
			SweetAlert,
			HideAlert,
		},
		dispatch,
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withRouter,
	withStyles(invoicesStyle),
	withTranslation(),
	connectRouterState({
		searchDocumentId: '',
		searchCode: '',
		searchConsumer: '',
		searchStartDate: '',
		searchEndDate: '',
		searchChannelType: '',
		currentPage: 1,
	}),
)(Invoices)
