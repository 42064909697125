/* eslint-disable  */
import React, { useState, useEffect, useContext } from 'react'
import * as Yup from 'yup'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import InputMask from 'react-input-mask'
import { HideLoader } from 'redux/actions'
import { useDispatch } from 'react-redux'
import { useForm, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { withStyles, MenuItem, Grid } from '@material-ui/core'
import { yupResolver } from '@hookform/resolvers'
import TextField from '@material-ui/core/TextField'

import ConfirmModal from 'components/ConfirmModal'
import CustomRequiredField from 'components/CustomRequiredField/CustomRequiredField.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader'
import Section from 'components/Section'
import Button from 'components/CustomButtons/Button.jsx'
import SponsorContext from '../context'
import * as Style from '../styles'
import { ShowLoader } from 'redux/actions'

import {
	UpSertSponsors,
	GetSponsorById,
	UpdateSponsor,
	GetSponsorByCnpj,
} from 'redux/api/sponsor'

import {
	GetCity,
	GetState,
	GetCountries,
	FindZipCode,
} from 'redux/api/location'

import GeneralContext from '../../../GeneralContext'
import { VerifyCNPJ, removeCNPJmask } from 'utils/utils'

import { grayColor } from 'assets/jss/material-dashboard-pro-react'

import styled from 'styled-components/macro'

import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'
import { cnpjValidation, getProgramTitle } from '../../../utils/utils'

const ContainerMain = styled.div`
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor} !important;
	}

	.SponsorRegister {
	}

	.WithStyles\(ForwardRef\(TextField\)\)-root-251
		.MuiOutlinedInput-root:not(.Mui-disabled).Mui-focused
		fieldset {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}

	.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.MuiInput-underline:after,
	.css-1pahdxg-control:hover,
	.css-1pahdxg-control {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
		border-width: 2px;
		box-shadow: none;
	}

	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}
	.MuiFormLabel-root.Mui-focused {
		color: ${(props) =>
		props &&
		props.themeColor &&
		props.themeColor.structure &&
		props.themeColor.structure.backgroundColor} !important;
	}
`

const registerValidation = Yup.object().shape({
	cnpj: Yup.string()
		.test('cnpjInvalido', null, (obj) => {
			if (obj && cnpjValidation(obj)) {
				return true
			} else {
				return new Yup.ValidationError('CNPJ inválido', null, 'cnpj')
			}
		})
		.required('CNPJ obrigatório'),
	name: Yup.string().required('Nome obrigatório'),
	companyName: Yup.string().required('Razão Social obrigatória'),
	location: Yup.string().required('Endereço obrigatório'),
	district: Yup.string().nullable().required('Bairro obrigatório'),
	number: Yup.string().required('Nº obrigatório'),
	aditionalInfo: Yup.string().nullable(),
	zipCode: Yup.string().required('CEP obrigatório'),
	country: Yup.string().required('País obrigatório'),
	state: Yup.string().required('Estado obrigatório'),
	city: Yup.string().required('Cidade obrigatória'),
	contactEmail: Yup.string()
		.email('E-mail invalido')
		.required('Email obrigatório'),
	contactName: Yup.string().required('Nome obrigatório'),
	contactPhoneNumber: Yup.string().required('Celular obrigatório'),
	phone: Yup.string(),
	cellphone: Yup.string(),
})

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			fontWeight: '400',
			color: 'hls(0, 0, 0, 0.55)',
			lineHeight: 1.2,
			zIndex: 1,
		},
		'& .MuiInputBase-root': {
			color: 'rgba(0, 0, 0, 0.6)',
		},
		'& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
			transform: 'translate(14px, -6px) scale(0.75)',
			//
			'&:not(.Mui-disabled)': {
				marginTop: '-7px',
				marginLeft: '-5px',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense': {
			paddingTop: '9.5px',
			paddingBottom: '10.5px',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiFormLabel-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			fontSize: '16px',
			top: '-9px',
		},
		'& .MuiInputBase-root.Mui-disabled': {
			color: 'rgba(0, 0, 0, 0.50)',
			cursor: 'default',
		},
		'& fieldset': {
			color: 'rgba(0, 0, 0, 0.7)',
			borderColor: 'hsl(0,0%,80%)',
			'& .hover': {
				borderColor: 'hsl(0,0%,80%)',
			},
			'& legend': {
				width: '0px',
			},
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: grayColor[2],
				},
				'&.Mui-focused fieldset': {
					borderColor: grayColor[2],
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(3,3%,95%)',
			'& fieldset': {
				'& legend': {
					width: '0px',
				},
			},
		},
		'& .PrivateNotchedOutline-legendLabelled-583': {
			width: '0px !important',
		},
		'& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline':
		{
			borderColor: 'transparent',
		},
	},
})(TextField)

function SponsorRegister({ classes }) {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const history = useHistory()
	const [editMode, setEditMode] = useState(false)
	const {
		handleSubmit,
		control,
		reset,
		watch,
		errors,
		setValue,
		formState,
		setError,
	} = useForm({
		resolver: yupResolver(registerValidation),
		defaultValues: {
			cnpj: '',
		},
		mode: 'all',
	})

	const [confirmModalProps, setConfirmModalProps] = useState({
		open: false,
		type: '',
		text: '',
	})

	const { setSnackStatus } = useContext(GeneralContext)
	const { sponsor, setSponsor, setEditing, editing, setSwitchComp } =
		useContext(SponsorContext)
	const warchAllFields = watch()
	const [newSponsor, setNewSponsor] = useState(false)
	const [cnpjRegistred, setCnpjRegistred] = useState(false)
	const [zipCodeInvalid, setZipCodeInvalid] = useState(false)
	const [disableZipCode, setDisableZipCode] = useState(false)
	const [disableDistrict, setDisableDistrict] = useState(false)
	const [disableLocation, setDisableLocation] = useState(false)
	const [cnpjInvalid, setCnpjInvalid] = useState('')
	const [country, setCountry] = useState([])
	const [region, setRegion] = useState([])
	const [city, setCity] = useState([])
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const getColor = () => {
		return (
			themeColor &&
			themeColor.titles &&
			themeColor.titles.secondary &&
			themeColor.titles.secondary.textColor &&
			themeColor.titles.secondary.textColor
		)
	}

	useEffect(() => {
		async function loadPage() {
			dispatch(ShowLoader(<Loader></Loader>))
			setEditMode(editing)
			const response = await GetSponsorById(sponsor)
			reset({
				cnpj: response.cnpj,
				name: response.name,
				companyName: response.companyName,
				phone: response.phone ? response.phone : '-',
				cellphone: response.cellphone ? response.cellphone : '-',
				location: response.address.location,
				district: response.address.district,
				number: response.address.number,
				aditionalInfo: response.address.aditionalInfo
					? response.address.aditionalInfo
					: '-',
				zipCode: response.address.zipCode,
				country: response.address.country.id,
				state: response.address.state.id,
				city: response.address.city.id,
				contactName: response.contact.contactName,
				contactEmail: response.contact.contactEmail,
				contactPhoneNumber: response.contact.contactPhoneNumber,
			})
			const responseCity = await GetCity(response.address.state.id)
			setCity(responseCity)
			const responseState = await GetState(response.address.country.id)
			setRegion(responseState)
			const responseCountry = await GetCountries()
			setCountry(responseCountry)
			dispatch(HideLoader())
		}
		if (sponsor) {
			loadPage()
		} else {
			getStates()
			setEditMode(true)
			setNewSponsor(true)
		}
	}, [])

	async function getStates() {
		try {
			dispatch(ShowLoader(<Loader></Loader>))
			const responseCountryList = await GetCountries()
			setCountry(responseCountryList)
			setValue(
				'country',
				_.find(responseCountryList, (x) => x.name === 'Brazil').id,
			)
			const responseStateList = await GetState(country)
			setRegion(responseStateList)
		} catch {
		} finally {
			dispatch(HideLoader())
		}
	}

	useEffect(() => {
		async function verifySponsor(doc) {
			try {
				const noMaskCnpj = removeCNPJmask(doc)
				const response = await GetSponsorByCnpj(noMaskCnpj)
				if (!response.success) {
					setNewSponsor(true)
					response.errors.forEach((element) => {
						if (element.messageCode === 9) {
							return (
								setCnpjInvalid('CNPJ Já cadastrado'),
								setCnpjRegistred(true)
							)
						}
					})
					return setNewSponsor(true)
				}
				if (response.success) {
					setCnpjRegistred(false)
					setCnpjInvalid('')
					if (response.data) {
						reset({
							cnpj: response.data.cnpj,
							companyName: response.data.companyName,
							name: response.data.name,
							phone: response.data.phone,
							cellphone: response.data.cellphone,
							zipCode: response.data.address.zipCode,
							location: response.data.address.location,
							number: response.data.address.number,
							aditionalInfo: response.data.address.aditionalInfo,
							district: response.data.address.district,
							city: response.data.address.city.id,
							state: response.data.address.state.id,
							country: response.data.address.country.id,
						})
						const responseCity = await GetCity(
							response.data.address.state.id,
						)
						setCity(responseCity)
						const responseState = await GetState(
							response.data.address.country.id,
						)
						setRegion(responseState)
						const responseCountry = await GetCountries()
						setCountry(responseCountry)
						return setNewSponsor(false)
					}
					setNewSponsor(true)
				}
			} catch (error) {
			} finally {
			}
		}
		const doc = watch('cnpj')
		if (VerifyCNPJ(doc) && newSponsor) {
			verifySponsor(doc)
		}
	}, [watch('cnpj')])

	useEffect(() => {
		async function verifyZipCode(cep) {
			if (formState.isDirty) {
				try {
					dispatch(ShowLoader(<Loader></Loader>))
					const responseZipCode = await FindZipCode(cep)
					if (responseZipCode.stateId) {
						setZipCodeInvalid(false)
						setValue('location', responseZipCode.location)
						responseZipCode.location
							? setDisableLocation(true)
							: setDisableLocation(false)
						setValue('number', '')
						setValue('aditionalInfo', '')
						setValue('district', responseZipCode.district)
						responseZipCode.district
							? setDisableDistrict(true)
							: setDisableDistrict(false)
						setDisableZipCode(true)
						setValue('city', responseZipCode.cityId)
						setValue('state', responseZipCode.stateId)
						setValue('country', responseZipCode.countryId)
						const responseCity = await GetCity(
							responseZipCode.stateId,
						)
						setCity(responseCity)
						const responseState = await GetState(
							responseZipCode.countryId,
						)
						setRegion(responseState)
						const responseCountry = await GetCountries()
						setCountry(responseCountry)
					} else {
						setZipCodeInvalid(true)
						setValue('location', '')
						setValue('number', '')
						setValue('aditionalInfo', '')
						setValue('district', '')
						setValue('city', null)
						setValue('state', null)
					}
				} catch (error) {
					console.log(error)
				} finally {
					dispatch(HideLoader())
				}
			}
		}
		const cep = watch('zipCode')
		if (cep.length === 9) {
			verifyZipCode(cep)
		}
	}, [watch('zipCode')])

	useEffect(() => {
		async function verifyState(cep, estado) {
			if (formState.isDirty) {
				try {
					const responseCity = await GetCity(estado)
					setCity(responseCity)
				} catch (error) {
				} finally {
				}
			}
		}

		const cep = watch('zipCode')
		const estado = watch('state')
		if (!cep.length || zipCodeInvalid) {
			verifyState(cep, estado)
		}
	}, [watch('zipCode'), watch('state')])

	function handleBack() {
		var currentUrl = window.location.pathname
		if (currentUrl === '/sponsors/new') {
			return history.goBack()
		}
		setEditing(false)
		setSwitchComp('list')
		setSponsor('')
	}

	function cancelConfirmed() {
		hideModal()
		setSwitchComp('list')
		setSponsor('')
	}

	function handleCancel() {
		if (formState.isDirty)
			return setConfirmModalProps((prevState) => ({
				...prevState,
				open: true,
				type: 'cancel',
			}))

		setSwitchComp('list')
		setSponsor('')
	}

	function hideModal() {
		setConfirmModalProps({ open: false, type: '', text: '' })
	}
	async function sendForm(formData) {
		try {
			dispatch(ShowLoader(<Loader></Loader>))
			let response
			if (sponsor) {
				response = await UpdateSponsor(sponsor, {
					name: formData.name,
					cnpj: formData.cnpj,
					companyName: formData.companyName,
					phone: formData.phone,
					cellphone: formData.cellphone,
					address: {
						addressType: 0,
						location: formData.location,
						district: formData.district,
						number: formData.number,
						aditionalInfo: formData.aditionalInfo,
						zipCode: formData.zipCode,
						stateId: formData.state,
						cityId: formData.city,
						countryId: formData.country,
					},
					contact: {
						contactName: formData.contactName,
						contactPhoneNumber: formData.contactPhoneNumber,
						contactEmail: formData.contactEmail,
					},
				})
			} else if (!cnpjRegistred) {
				response = await UpSertSponsors({
					name: formData.name,
					cnpj: formData.cnpj,
					companyName: formData.companyName,
					phone: formData.phone,
					cellphone: formData.cellphone,
					address: {
						addressType: 0,
						location: formData.location,
						district: formData.district,
						number: formData.number,
						aditionalInfo: formData.aditionalInfo,
						zipCode: formData.zipCode,
						stateId: formData.state,
						cityId: formData.city,
						countryId: formData.country,
					},
					contact: {
						contactName: formData.contactName,
						contactPhoneNumber: formData.contactPhoneNumber,
						contactEmail: formData.contactEmail,
					},
				})
			}
			if (!response.success) {
				response.errors.forEach((error) => {
					setError(error.field, { message: error.message })
				})
				return setSnackStatus({
					open: true,
					severity: 'error',
					text: 'Falha ao realizar operação!',
				})
			} else {
				setEditing(false)
				setEditMode(false)
				setSponsor('')
				return (
					setSwitchComp('list'),
					setSnackStatus({
						open: true,
						severity: 'success',
						text: 'Operação realizada com sucesso!',
					})
				)
			}
		} catch (error) {
		} finally {
			dispatch(HideLoader())
		}
	}

	return (
		<ContainerMain themeColor={themeColor}>
			<DocumentTitle title={getProgramTitle()} />
			<ConfirmModal
				open={confirmModalProps.open}
				type={confirmModalProps.type}
				text={confirmModalProps.text}
				onConfirm={() => cancelConfirmed()}
				onCancel={() => hideModal()}
			/>
			<Style.TitlesContainer xs={12} sm={12} md={12} style={{ height: '40px', alignItems: "center" }}>
				<Section title={t('MENU_SPONSOR')} style={{ flexGrow: '1' }} />
				<Style.TitlesBtns>
					<Button
						variant="contained"
						size="sm"
						color="greenBorderButtonColor"
						style={{ marginRight: '0.875rem' }}
						onClick={() =>
							formState.isDirty ? handleCancel() : handleBack()
						}
					>
						{formState.isDirty ? 'Cancelar' : 'Voltar'}
					</Button>
					{editMode ? (
						<Button
							color="greenButtonColor"
							size="sm"
							style={{ marginRight: '0px' }}
							onClick={handleSubmit(sendForm)}
						>
							{editMode ? t('BUTTON_SAVE') : t('BUTTON_EDIT')}
						</Button>
					) : (
						<Button
							style={{ marginRight: '0px' }}
							color="greenButtonColor"
							size="sm"
							onClick={() => setEditMode(true)}
						>
							{editMode ? t('BUTTON_SAVE') : t('BUTTON_EDIT')}
						</Button>
					)}
				</Style.TitlesBtns>
			</Style.TitlesContainer>
			<form
				onSubmit={handleSubmit(sendForm)}
				style={{ width: '100%' }}
				newSponsor="off"
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12}>
						<Style.CustomFormLabel
							style={{
								color: `${getColor()}`,
							}}
						>
							{t('FIELD_COMPANY_FORM')}
						</Style.CustomFormLabel>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={3}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<InputMask
									label={
										<CustomRequiredField
											disabled
											value="FIELD_CNPJ"
										/>
									}
									variant="outlined"
									margin="dense"
									mask="99.999.999/9999-99"
									fullWidth
									maskChar={null}
									disabled={!newSponsor}
									required={editMode}
									error={
										errors.cnpj
											? errors.cnpj
											: cnpjInvalid
												? cnpjInvalid
												: ''
									}
									helperText={
										errors.cnpj
											? errors.cnpj.message
											: cnpjInvalid
												? cnpjInvalid
												: ''
									}
								>
									{(props) => (
										<CssTextField
											{...props}
											disabled={!newSponsor}
										/>
									)}
								</InputMask>
							}
							name="cnpj"
							defaultValue=""
							control={control}
						/>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={9}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_COMPANY_NAME"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.companyName}
									helperText={
										errors.companyName
											? errors.companyName.message
											: ''
									}
									disabled={!newSponsor}
									required={editMode}
								/>
							}
							defaultValue=""
							name="companyName"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={9}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_REGISTER_NAME"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.name}
									helperText={
										errors.name ? errors.name.message : ''
									}
									disabled={!editMode}
									required={editMode}
								/>
							}
							defaultValue=""
							name="name"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={3}
						className={classes.rowMargin}
					>
						<Controller
							as={
								warchAllFields.phone === '-' ? (
									<CssTextField
										label={
											<CustomRequiredField
												disabled
												value="FIELD_REGISTER_PHONE"
											/>
										}
										variant="outlined"
										margin="dense"
										value="-"
										fullWidth
										disabled={!newSponsor && !editMode}
									/>
								) : (
									<InputMask
										label={
											<CustomRequiredField
												disabled
												value="FIELD_REGISTER_PHONE"
											/>
										}
										variant="outlined"
										margin="dense"
										mask="(99) 9999-9999"
										fullWidth
										maskChar={null}
										disabled={!newSponsor && !editMode}
										error={errors.phone}
										helperText={t(
											errors.phone
												? errors.phone.message
												: '',
										)}
									>
										{(props) => (
											<CssTextField
												{...props}
												disabled={
													!newSponsor && !editMode
												}
											/>
										)}
									</InputMask>
								)
							}
							defaultValue=""
							name="phone"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={3}
						className={classes.rowMargin}
					>
						<Controller
							as={
								warchAllFields.cellphone === '-' ? (
									<CssTextField
										label={
											<CustomRequiredField
												disabled
												value="Celular"
											/>
										}
										variant="outlined"
										margin="dense"
										value="-"
										fullWidth
										disabled={!newSponsor && !editMode}
									/>
								) : (
									<InputMask
										label={
											<CustomRequiredField
												disabled
												value="Celular"
											/>
										}
										variant="outlined"
										margin="dense"
										mask="(99) 99999-9999"
										fullWidth
										maskChar={null}
										disabled={!newSponsor && !editMode}
										error={errors.cellphone}
										helperText={t(
											errors.cellphone
												? errors.cellphone.message
												: '',
										)}
									>
										{(props) => (
											<CssTextField
												{...props}
												disabled={
													!newSponsor && !editMode
												}
											/>
										)}
									</InputMask>
								)
							}
							defaultValue=""
							name="cellphone"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={3}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<InputMask
									label={
										<CustomRequiredField
											disabled
											value="FIELD_CEP"
										/>
									}
									variant="outlined"
									margin="dense"
									mask="99999-999"
									maskChar={null}
									fullWidth
									error={errors.zipCode || zipCodeInvalid}
									helperText={
										errors.zipCode
											? errors.zipCode.message
											: '' || zipCodeInvalid
												? 'CEP Inválido'
												: ''
									}
									disabled={!newSponsor && !editMode}
									required={editMode}
								>
									{(props) => (
										<CssTextField
											{...props}
											disabled={!newSponsor && !editMode}
										/>
									)}
								</InputMask>
							}
							defaultValue=""
							name="zipCode"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={4}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="TITLE_REGISTER_ADDRESS"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.location}
									helperText={
										errors.location
											? errors.location.message
											: ''
									}
									disabled={
										!editMode ||
										(disableLocation && !zipCodeInvalid)
									}
									required={editMode}
								/>
							}
							defaultValue=""
							name="location"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={2}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_NUMBER"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.number}
									helperText={
										errors.number
											? errors.number.message
											: ''
									}
									disabled={!editMode}
									required={editMode}
								/>
							}
							defaultValue=""
							name="number"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={3}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_ADITIONAL_INFO"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.aditionalInfo}
									helperText={
										errors.aditionalInfo
											? errors.aditionalInfo.message
											: ''
									}
									disabled={!editMode}
								/>
							}
							defaultValue=""
							name="aditionalInfo"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={2}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_COUNTRY"
										/>
									}
									select
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.country}
									helperText={
										errors.country
											? errors.country.message
											: ''
									}
									disabled={true}
									required={editMode}
								/>
							}
							defaultValue=""
							name="country"
							control={control}
						>
							{country.map((item) => (
								<MenuItem value={item.id} key={item.name}>
									{t(item.name)}
								</MenuItem>
							))}
						</Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={2}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_STATE"
										/>
									}
									select
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.state}
									helperText={
										errors.state ? errors.state.message : ''
									}
									disabled={
										!editMode ||
										(disableZipCode && !zipCodeInvalid)
									}
									required={editMode}
								/>
							}
							defaultValue=""
							name="state"
							control={control}
						>
							{region.map((item) => (
								<MenuItem value={item.id} key={item.name}>
									{t(item.name)}
								</MenuItem>
							))}
						</Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={2}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_REGISTER_CITY"
										/>
									}
									select
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.city}
									helperText={
										errors.city ? errors.city.message : ''
									}
									disabled={
										!editMode ||
										(disableZipCode && !zipCodeInvalid)
									}
									required={editMode}
								>
									{!city.length > 0 ? (
										<MenuItem value={'Cidade'}>
											{t('Cidade')}
										</MenuItem>
									) : (
										city.map((item) => (
											<MenuItem
												value={item.id}
												key={item.name}
											>
												{t(item.name)}
											</MenuItem>
										))
									)}
									{ }
								</CssTextField>
							}
							defaultValue=""
							name="city"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={3}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_DISTRICT"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.district}
									helperText={
										errors.district
											? errors.district.message
											: ''
									}
									disabled={
										!editMode ||
										(disableDistrict && !zipCodeInvalid)
									}
									required={editMode}
								/>
							}
							defaultValue=""
							name="district"
							control={control}
						></Controller>
					</Grid>

					<Grid item
						xs={12}
						sm={12}
						md={12}
					>
						<Style.CustomFormLabel
							style={{
								color: `${getColor()}`,
							}}
						>
							{t('FIELD_CONTACT_FORM')}
						</Style.CustomFormLabel>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={9}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_REGISTER_NAME"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.contactName}
									helperText={
										errors.contactName
											? errors.contactName.message
											: ''
									}
									disabled={!editMode}
									required={editMode}
								/>
							}
							defaultValue=""
							name="contactName"
							control={control}
						></Controller>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={3}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<InputMask
									label={
										<CustomRequiredField
											disabled
											value="Celular"
										/>
									}
									variant="outlined"
									margin="dense"
									mask="(99) 99999-9999"
									fullWidth
									maskChar={null}
									disabled={!editMode}
									required={editMode}
									error={errors.contactPhoneNumber}
									helperText={t(
										errors.contactPhoneNumber
											? errors.contactPhoneNumber.message
											: '',
									)}
								>
									{(props) => (
										<CssTextField
											{...props}
											disabled={!editMode}
										/>
									)}
								</InputMask>
							}
							name="contactPhoneNumber"
							defaultValue=""
							control={control}
						/>
					</Grid>
					<Grid item
						xs={12}
						sm={12}
						md={9}
						className={classes.rowMargin}
					>
						<Controller
							as={
								<CssTextField
									label={
										<CustomRequiredField
											disabled
											value="FIELD_REGISTER_EMAIL"
										/>
									}
									variant="outlined"
									margin="dense"
									fullWidth
									error={errors.contactEmail}
									helperText={
										errors.contactEmail
											? errors.contactEmail.message
											: ''
									}
									disabled={!editMode}
									required={editMode}
								/>
							}
							defaultValue=""
							name="contactEmail"
							control={control}
						></Controller>
					</Grid>
				</Grid>
			</form>
		</ContainerMain>
	)
}

export default withStyles()(withStyles()(SponsorRegister))
