import React from 'react';

const SubtitleItem = ({ iconClassName, iconColor, text }) => {
    return (
        <div style={{ 
            display: "flex", 
            alignItems: "center", 
            marginRight: '10px' 
            }}
        >
            <i className={iconClassName} style={{ color: iconColor, fontSize: '12px', marginRight: '10px' }} />
            <div style={{ 
                fontSize: '12px', 
                marginTop: '3px', 
                }}
            >{text}</div>
        </div>
    );
};

export default SubtitleItem;
