import React from 'react'
import styled from 'styled-components'

// eslint-disable-next-line react/prop-types
const SubtitleItem = ({ iconClassName, iconColor, text }) => {
	return (
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
				marginRight: '10px',
			}}
		>
			<i className={iconClassName} style={{ color: iconColor, fontSize: '12px', marginRight: '10px' }} />
			<div
				style={{
					fontSize: '12px',
					marginTop: '3px',
				}}
			>
				{text}
			</div>
		</div>
	)
}

export default SubtitleItem

export const Indicator = styled.div`
	cursor: pointer;
	width: 6px;
	background-color: ${({ Color }) => Color};
	height: 47px;
	border-radius: 5px 0px 0px 5px;
`
