import React, { Fragment, useState, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import * as S from './styles'

// @Components
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section'
import ConfirmModal from 'components/ConfirmModal/Confirm'

// @ Material UI
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

// @ Context and Utils
import GeneralContext from '../../../GeneralContext'

// @ Request
import {
	DisablePartnerSettings,
	GetPartnerSettings,
} from '../../../redux/api/paymentGateway'
import PartnersModal from './partnersModal'
import CardParameterization from './CardParameterization'
import Cookies from 'js-cookie'

export const PaymentMethodsCard = () => {
	const { t } = useTranslation()
	const [isLoaded, setIsLoaded] = useState(false)
	const [enablePayment, setEnablePayment] = useState(false)
	const [openPartnersModal, setOpenPartnersModal] = useState(false)
	const { setSettings, settings } = useContext(GeneralContext)
	const [confirmModalProps, setConfirmModalProps] = useState({
		open: false,
		type: '',
		text: '',
		warningMessage: '',
		parameter: '',
		error: '',
	})

	const enablePaymentMethods = async event => {
		const partnerId = String(Cookies.get('partnerId'))
		setIsLoaded(true)

		if (Object.keys(settings).length === 0) {
			setIsLoaded(false)
			setEnablePayment(event?.target?.checked || event)
		} else {
			const enabledAndDisabledSettings = await DisablePartnerSettings(
				partnerId,
			)
			setEnablePayment(enabledAndDisabledSettings.data.isGerminiPayActive)
			setSettings(enabledAndDisabledSettings.data)
			setIsLoaded(false)
		}
	}

	const getPartnerSettings = async () => {
		setIsLoaded(true)
		const partnerId = String(Cookies.get('partnerId'))
		const getPartnerSettings = await GetPartnerSettings(partnerId).finally(
			() => setIsLoaded(false),
		)

		if (
			getPartnerSettings?.data !== undefined &&
			getPartnerSettings?.data?.isGerminiPayActive
		) {
			setSettings(getPartnerSettings?.data)
			setEnablePayment(getPartnerSettings?.data?.isGerminiPayActive)
		}

		if (
			getPartnerSettings?.data !== undefined &&
			!getPartnerSettings?.data?.isGerminiPayActive
		) {
			setSettings(getPartnerSettings?.data)
		}
	}

	useEffect(() => {
		getPartnerSettings()
	}, [])

	const onConfirmModal = () => {
		setConfirmModalProps({
			open: true,
			type: 'confirmSave',
			text: t('PARTICIPANTS_CONFIRM_ACTION_MESSAGE'),
			warningMessage: 'teste',
		})
	}

	const hideModal = () => {
		setConfirmModalProps({ open: false, type: '', text: '' })
	}

	const listEnabledPartners = event => {
		if (event) {
			setOpenPartnersModal(true)
		}
	}

	const handleCloseAdminModal = () => {
		setOpenPartnersModal(false)
	}

	return (
		<Fragment>
			<ConfirmModal
				open={confirmModalProps.open}
				type={confirmModalProps.type}
				text={confirmModalProps.text}
				parameter={confirmModalProps.parameter}
				warningMessage={''}
				onConfirm={() => confirmModalProps.onConfirm}
				onCancel={() => hideModal()}
				enablePaymentMethods={enablePaymentMethods}
			/>
			{isLoaded ? (
				<Loader />
			) : (
				<>
					<PartnersModal
						headerTitle=""
						maxWidth="400px"
						headerIcon={false}
						closeByClick={() => handleCloseAdminModal()}
						open={openPartnersModal}
						buttonTitle="BUTTON_CLOSE"
					/>
					<Section
						title={t('MENU_FINANCIAL_PAYMENT_OPTIONS')}
						style={{ flexGrow: 1 }}
					/>
					<div style={{ marginTop: '17px' }}>
						<div>
							<S.Title>
								Habilitar autorizadora e meios de pagamento para
								o programa ?
							</S.Title>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<S.Switch>
									<FormGroup>
										<FormControlLabel
											checked={enablePayment}
											control={
												<Switch
													onChange={event =>
														onConfirmModal(event)
													}
												/>
											}
											label={
												enablePayment
													? t('BUTTON_YES')
													: t('BUTTON_NO')
											}
										/>
									</FormGroup>
								</S.Switch>
							</div>
						</div>
						{enablePayment ? <CardParameterization /> : ''}
					</div>
				</>
			)}
		</Fragment>
	)
}
