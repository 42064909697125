import React, { useEffect, useState } from 'react'
import _ from 'lodash'

// @ Styles
import * as S from './styles'

//@ components
import Details from './Details'
import Parametrization from './Parametrization'
import ParameterizeDivisionGen from './Parametrization/components/Generation/components/DivisionTab/components/ParameterizeDivision'
import ParameterizeDivisionCash from './Parametrization/components/Cashback/components/DivisionTab/components/ParameterizeDivision'
import ParameterizeDivisionRes from './Parametrization/components/Rescue/components/DivisionTab/components/ParameterizeDivision'

import { Box } from '@mui/material'
import { GetDigitalWalletRules, GetGeneralRulesData } from '../../../redux/api/generalRules'
import { GetCashbackRules } from '../../../redux/api/partner'
import { GetORedeem } from './Parametrization/components/Rescue/components/DivisionTab/components/ParameterizeDivision/ParameterizeDivisionTB/Functions'

const ProductDetails = () => {
	const [division, setDivision] = useState(null)
	const [multiple, setMultiple] = useState(false)
	const [productData, setProductData] = useState(null)
	const [programRules, setProgramRules] = useState(null)
	const [GenerationType, setGenerationType] = useState([])
	const [reloadProduct, setReloadProduct] = useState(false)
	const [selectedDivision, setSelectedDivision] = useState([])
	const [selectedDivisionIdGen, setSelectedDivisionIdGen] = useState([])
	const [selectedDivisionIdRed, setSelectedDivisionIdRed] = useState([])
	const [selectedDivisionIdCash, setSelectedDivisionIdCash] = useState([])
	const [multipleParameterization, setMultipleParameterization] = useState([])
	const [circularProgress, setCircularProgress] = useState(false)
	const [editMode, setEditMode] = useState(false)
	const [rules, setRules] = useState({})

	const [tabOn, setTabOn] = useState([
		{
			cashback: false,
			redeem: false,
			points: false,
		},
	])

	const [parameterization, setParameterization] = useState({
		show: false,
		isMultiple: false,
	})

	const getDigitalWalletRules = async () => {
		const [digitalWalletRules, cashbackRules] = await Promise.all([GetDigitalWalletRules(), GetCashbackRules()])

		//
		if (!_.isEmpty(digitalWalletRules)) {
			setRules(prev => {
				return { ...prev, digitalWalletRules }
			})
		}

		if (!_.isEmpty(cashbackRules)) {
			setRules(prev => {
				return { ...prev, cashbackRules }
			})
		}
	}

	useEffect(() => {
		GetGeneralRulesData().then(res => {
			!_.isEmpty(res) && setProgramRules(res)
		})
		GetORedeem({ setGenerationType, setCircularProgress })
		getDigitalWalletRules()
	}, [])

	const sharedProps = {
		rules,
		multiple,
		editMode,
		setEditMode,
		setTabOn,
		division,
		productData,
		setMultiple,
		programRules,
		GenerationType,
		setProductData,
		setProgramRules,
		parameterization,
		circularProgress,
		setSelectedDivisionIdRed,
		setDivision: setDivision,
		selectedDivision: selectedDivision,
		setReloadProduct: setReloadProduct,
		setSelectedDivision: setSelectedDivision,
		setParameterization: setParameterization,
		selectedDivisionIdGen: selectedDivisionIdGen,
		selectedDivisionIdRed: selectedDivisionIdRed,
		selectedDivisionIdCash: selectedDivisionIdCash,
		setSelectedDivisionIdGen: setSelectedDivisionIdGen,
		multipleParameterization: multipleParameterization,
		setSelectedDivisionIdCash: setSelectedDivisionIdCash,
		setMultipleParameterization: setMultipleParameterization,
	}

	return (
		<S.Container>
			<Box display={!parameterization?.show ? 'block' : 'none'}>
				<Details productData={productData} setProductData={setProductData} reloadProduct={reloadProduct} setReloadProduct={setReloadProduct} />
				<Parametrization {...sharedProps} />
			</Box>

			{tabOn?.cashback && parameterization?.show && <ParameterizeDivisionCash {...sharedProps} />}

			{tabOn?.redeem && parameterization?.show && <ParameterizeDivisionRes {...sharedProps} />}

			{tabOn?.points && parameterization?.show && <ParameterizeDivisionGen {...sharedProps} />}
		</S.Container>
	)
}

export default ProductDetails
