import * as actionTypes from '../../actions/actionsTypes';
import {
    updateObject
} from '../../utility';

const initialState = {
    accessData: [],
    accessGroups: [],
    groupData: {},
    groupPermissionsData: [],
    createAccess: false,
    postAccess: false,
    groupPut: {},
    accessDataFailed: false,
    activeRoleClaims: false,
    postAssignToRole: false,
    groupPermissionsDataFailed: false
};

const fetchAccessData = (state, action) => {
    return updateObject(state, {
        accessData: action.accessData,
    });
};

const fetchAccessGroupsData = (state, action) => {
    return updateObject(state, {
        accessGroups: action.accessGroups,
    });
};

const fetcActiveRoleClaimsData = (state, action) => {
    return updateObject(state, {
        activeRoleClaims: action.activeRoleClaims,
    });
};

const fetcGroupPermissionsData = (state, action) => {
    return updateObject(state, {
        groupPermissionsData: action.groupPermissionsData,
    });
};
const fetcGroupData = (state, action) => {
    return updateObject(state, {
        groupData: action.groupData,
    });
};
const fetcUpdateGroupData = (state, action) => {
    return updateObject(state, {
        groupPut: action.groupData,
    });
};
// const createAccessData = (state, action) => {
//     return updateObject(state, {
//         createAccess: action.createAccess,
//     });
// };

const postAccessData = (state, action) => {
    return updateObject(state, {
        postAccess: action.postAccess,
    });
};

const postAssignToRole = (state, action) => {
    return updateObject(state, {
        postAssignToRole: action.postAssignToRole,
    });
};


// DATA FAIL
const fetchAccessFail = (state, action) => {
    return updateObject(state, {
        accessDataFailed: true
    });
};

const fetcGroupPermissionsFailed = (state, action) => {
    return updateObject(state, {
        groupPermissionsDataFailed: true
    });
};

const accessReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.FETCH_ACCESS_DATA:
            return fetchAccessData(state, action);
        case actionTypes.FETCH_ACCESS_GROUPS:
            return fetchAccessGroupsData(state, action);
        case actionTypes.FETCH_ACTIVE_ROLE_CLAIMS_DATA:
            return fetcActiveRoleClaimsData(state, action);
        case actionTypes.FETCH_GROUP_PERMISSIONS_DATA:
            return fetcGroupPermissionsData(state, action);
        case actionTypes.FETCH_UPDATE_GROUP_DATA:
            return fetcUpdateGroupData(state, action);
        case actionTypes.FETCH_GROUP_DATA:
            return fetcGroupData(state, action);
        case actionTypes.POST_ACCESS_DATA:
            return postAccessData(state, action);
        case actionTypes.POST_ASSIGN_TO_ROLE:
            return postAssignToRole(state, action);
        case actionTypes.FETCH_ACCESS_FAILED:
            return fetchAccessFail(state, action);
        case actionTypes.FETCH_GROUP_PERMISSIONS_FAILED:
            return fetcGroupPermissionsFailed(state, action);

        default:
            return state;
    }
};

export default accessReducer;