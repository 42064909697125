import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	width: 100%;
	justify-content: flex-end;
`
export const Content = styled.div`
	display: flex;
	width: 100px;
	justify-content: flex-end;
	transform: translateY(-40px);
`

export const CardsContent = styled.div`
	margin-top: 25px;
	width: 100%;
	display: flex;
	justify-content: space-between;

	.FormLabel {
		text-align: left;
		font: normal normal normal 13px Roboto;
		letter-spacing: 0px;
		color: #333333;
		opacity: 1;
	}
`

export const CustomSymbol = styled.div`
	color: #333333;
	opacity: 1;
`

export const CashbackContent = styled.div`
	display: flex;
	align-items: center;
	padding: 10px;
	gap: 10px;
	background: #f2f2f2 0% 0% no-repeat padding-box;
	border-radius: 4px;
	opacity: 1;
	margin-top: 9px;
`

export const CashbackCardIcon = styled.div`
	color: #989898;
	opacity: 1;
	font-size: 20px;
`

export const CashbackCardText = styled.div`
	text-align: left;
	font: normal normal normal 12px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`
