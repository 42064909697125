import React from 'react'
import { useTranslation, withTranslation } from 'react-i18next'

import PropTypes from 'prop-types'
import { Fade } from '@material-ui/core'

import { FormField } from 'components/FormField/styles'
import * as S from './styles'

import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { MenuItem } from '@mui/material'
import Button from 'components/CustomButtons/Button.jsx'
import { Controller, useForm } from 'react-hook-form'
import { SaveToExportTransactionLogs } from '../../../../../../../redux/api/paymentGateway'

// import Button from 'components/CustomButtons/Button.jsx'
import WaitLoader from 'components/WaitLoader'

function CustomModal({
	open,
	maxWidth,
	className,
	padding,
	shadow,
	onClose,
	setTab,
	tab,
	values,
	totalFiles,
}) {
	const { t } = useTranslation()
	const [progressValue, setProgressValue] = React.useState(0)
	const [loading, setLoading] = React.useState(false)

	const { control, handleSubmit, errors } = useForm({
		defaultValues: {
			FileName: '',
			FileType: '',
			SendEmail: false,
		},
		mode: 'all',
	})

	const onSubmit = async data => {
		const { FileName, FileType, SendEmail } = data
		setLoading(true)
		//const url = window.location.href
		try {
			const response = await SaveToExportTransactionLogs(
				FileName,
				FileType,
				SendEmail,
				tab,
				values,
				event => {
					let progress = Math.round(
						(event.loaded * 100) / event.total,
					)
					setProgressValue(progress)
				},
			)

			response.status === 200 && setTab(2)
		} catch (error) {
			console.log('error', error)
		} finally {
			setLoading(false)
		}
	}

	return (
		<S.ModalContainer
			open={open}
			className={className}
			onClose={onClose}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			disableEnforceFocus
		>
			{loading ? (
				<WaitLoader
					progressValue={progressValue}
					openModal={loading}
					text="YOUR_FILE_IS_BEING_GENERATED"
					title="WAIT"
				/>
			) : (
				<Fade in={open} unmountOnExit>
					<form onSubmit={handleSubmit(onSubmit)}>
						<S.PaperContainer padding={padding} maxwidth={maxWidth}>
							<S.ModalBody>
								<S.TitleContainer>
									<S.Title>
										<i className="icon-log" />
										Exportar logs
									</S.Title>

									<S.ButtonClose
										onClick={onClose}
										color="danger"
									>
										<i className="icon-Modal_erro" />
									</S.ButtonClose>
								</S.TitleContainer>
								<S.BodyContainer>
									<GridContainer>
										<GridItem
											item
											xs={12}
											sm={12}
											md={12}
											className="LabelStyle"
										>
											<label
												style={{
													textAlign: 'left',
													font:
														'italic normal normal 15px/21px Roboto',
													letterSpacing: '0px',
													color: '#333333',
												}}
											>
												Será gerado um arquivo com total
												de{' '}
												<strong>
													{totalFiles || 0}
												</strong>{' '}
												registros.
											</label>
										</GridItem>
										<GridItem
											item
											xs={12}
											sm={12}
											md={9}
											className="FileName"
										>
											<Controller
												name="FileName"
												control={control}
												rules={{ required: true }}
												render={({
													value,
													onChange,
												}) => (
													<FormField
														variant="outlined"
														style={{
															backgroundColor:
																'#ffffff',
														}}
														error={errors.FileName}
														helperText={
															errors.FileName &&
															t(
																'Campo obrigatório',
															)
														}
														value={value}
														onChange={onChange}
														formControlProps={{
															fullWidth: true,
														}}
														fullWidth
														size="small"
														label={t(
															'Nome do arquivo',
														)}
													/>
												)}
											/>
										</GridItem>

										<GridItem
											item
											xs={12}
											sm={12}
											md={3}
											className="FileType"
										>
											<Controller
												name="FileType"
												control={control}
												rules={{ required: true }}
												render={({
													value,
													onChange,
												}) => (
													<FormField
														className="FileTypeField"
														variant="outlined"
														style={{
															backgroundColor:
																'#ffffff',
														}}
														error={errors.FileType}
														helperText={
															errors.FileType &&
															t(
																'Campo obrigatório',
															)
														}
														formControlProps={{
															fullWidth: true,
														}}
														value={value}
														onChange={onChange}
														fullWidth
														size="small"
														label={t(
															'Formato do arquivo*',
														)}
														select
													>
														{S.SelectInput?.map(
															country => (
																<MenuItem
																	key={
																		country.id
																	}
																	value={
																		country.label
																	}
																>
																	{
																		country.label
																	}
																</MenuItem>
															),
														)}
													</FormField>
												)}
											/>
										</GridItem>

										<GridItem item xs={12} sm={12} md={12}>
											<S.CkeckBox>
												<Controller
													name="SendEmail"
													control={control}
													render={({
														value,
														onChange,
													}) => (
														<input
															type="checkbox"
															placeholder="Informar por e-mail"
															onChange={e => {
																onChange(
																	e.target
																		.checked,
																)
															}}
															value={value}
														/>
													)}
												/>
												<label>
													Informar por e-mail
												</label>
											</S.CkeckBox>
										</GridItem>

										<div
											style={{
												display: 'flex',
												justifyContent: 'flex-end',
												marginBottom: '20px',
												width: '100%',
											}}
										>
											<Button
												size="sm"
												color="greenButtonColor"
												type="submit"
												style={{
													backgroundColor: '#1BBED3',
													margin: '0px',
													boxShadow: 'none',
												}}
												startIcon={
													<i className="icon-exportar_relatorios" />
												}
											>
												{t('Gerar arquivo')}
											</Button>
										</div>
									</GridContainer>
								</S.BodyContainer>
							</S.ModalBody>
						</S.PaperContainer>
					</form>
				</Fade>
			)}
		</S.ModalContainer>
	)
}
CustomModal.propTypes = {
	open: PropTypes.bool,
	maxWidth: PropTypes.string,
	className: PropTypes.string,
	padding: PropTypes.string,
	shadow: PropTypes.bool,
	onClose: PropTypes.func,
	setTab: PropTypes.func,
	tab: PropTypes.number,
	values: PropTypes.object,
	totalFiles: PropTypes.number,
}

export default withTranslation()(CustomModal)
