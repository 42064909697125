import { Modal, Paper } from '@material-ui/core'
import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../../../../../../redux/api/themeColors'
import Button from 'components/CustomButtons/Button.jsx'

export const ModalContainer = styled(Modal)`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	header {
		width: 100%;
		align-items: center;
		display: flex;
		justify-content: space-between;
		padding: 10px 15px 0 20px;
		.section-part {
			.section-header {
				padding: 0;
				font-size: 15px;
				display: flex;
				white-space: nowrap;
				font-weight: 600;
				align-items: center;
				text-transform: initial;
				width: 100%;
				&::after {
					border-top: 3px solid #21409a;
					content: '';
					display: none;
					position: static;
					width: 100%;
					left: 0;
					margin-left: 10px;
				}
			}
		}
	}
`

export const ModalBody = styled.div`
	width: 100%;
	height: 300px;
	background-color: #fff;
	border-radius: 6px;
	padding: 20px;
	.LabelStyle {
		margin-bottom: 20px;
		margin-top: 20px;
	}
	.FileName {
		margin-bottom: 20px;
	}
	.FileType {
		margin-bottom: 20px;
	}
`

export const ButtonClose = styled(Button)`
	background-color: transparent !important;
	box-shadow: none !important;
	height: 25px;
	width: 25px !important;
	z-index: 9999;
	padding: 0 !important;
	.icon-Modal_erro {
		color: red;
		font-size: 25px;
	}
`

export const TitleContainer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
`

export const Title = styled.div`
	text-align: center;
	font: normal normal bold 18px Roboto;
	color: #333333;
	opacity: 1;
	display: flex;
	align-items: center;
	gap: 10px;

	i {
		font-size: 2rem;
		color: ${getThemeColorFromLocalStorage()?.visual?.icons?.color};
	}
`

export const BodyContainer = styled.div``

export const PaperContainer = styled(Paper)`
	position: relative;
	max-width: ${({ maxwidth }) => maxwidth};
	min-width: 880px;

	@media screen and (max-width: 880px) {
		min-width: 60vw;
	}

	padding: ${({ padding }) => padding};
	&.MuiPaper-elevation1 {
		box-shadow: ${({ shadow }) => shadow};
	}
	.sub-info {
		color: #48484c;
		font-size: 0.9rem;
		font-weight: bold;
	}

	.info-geral {
		color: #31394d;
		font-weight: bold;
		font-size: 1.3rem;
		> div {
			margin-right: 5px;
		}
	}

	.blue {
		color: #3f51b5;
	}

	.close-button {
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		background: transparent;
		border: none;
		font-size: 15px;
		color: #fff;
		background-color: #f44336;
		padding: 6px;
		border-radius: 6px;
		&:hover {
			opacity: 0.7;
		}
		svg {
			margin-right: 3px;
		}
	}

	.sub-total {
		color: #31394d;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 16px;
	}

	.sub-price {
		color: #49d489;
		font-family: Roboto;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: 0;
		line-height: 18px;
	}
	.button-box {
		position: absolute;
		margin-top: -28px;
		top: 0;
		margin-right: -28px;
		right: 0;
		.MuiIconButton-label {
			background-color: #3f51b5;
			border-radius: 50%;
			.MuiIcon-colorPrimary {
				color: #fff;
				font-size: 2rem;
			}
		}
	}
	main {
		margin: 0 0 40px 20px;

		.title-render,
		.body-render {
			margin: 10px 0;
			padding-left: 0px;
		}

		.img-render {
			width: 250px;
			height: 200px;
		}
	}
`

export const SelectInput = [
	{
		id: '0',
		label: 'csv',
	},
	{
		id: '1',
		label: 'txt',
	},
	{
		id: '2',
		label: 'xls',
	},
]

export const CkeckBox = styled.div`
	flex-direction: row;
	gap: 6px;
	align-items: flex-end;
	justify-content: flex-start;
	font-size: 1.2rem;

	input {
		width: unset;
	}

	label {
		border-bottom: 1px dotted
			${getThemeColorFromLocalStorage()?.titles?.highlight?.textColor};
		color: ${getThemeColorFromLocalStorage()?.titles?.highlight?.color};
		width: unset;
		text-decoration: none;
	}
`
