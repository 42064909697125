import React, { useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

// @ Styles
import * as S from './styles'

//@ components
import { FormLabel } from '@mui/material'
import Info from '../Info'

const Cards = ({ calculationMemory, typeOfPoints, setOpen, productData, fetchCalculation, divisionProductData, typeOfRedeem }) => {
	const { t } = useTranslation()

	useEffect(() => {
		!_.isEmpty(divisionProductData) && fetchCalculation('', typeOfRedeem)
	}, [divisionProductData])

	return (
		<S.Container typeOfPoints={typeOfPoints}>
			<FormLabel sx={{ width: '100%' }}>
				<span className="FormLabel">{t('Valores baseados na taxa padrão')} JJJJ</span>
				<Info calculationMemory={calculationMemory} setOpen={setOpen} productData={productData} />
			</FormLabel>
		</S.Container>
	)
}

Cards.propTypes = {
	setOpen: PropTypes.func,
	productData: PropTypes.object,
	typeOfPoints: PropTypes.string,
	typeOfRedeem: PropTypes.string,
	fetchCalculation: PropTypes.func,
	calculationMemory: PropTypes.array,
	divisionProductData: PropTypes.object,
}

Cards.defaultProps = {
	typeOfRedeem: '2',
}
export default Cards
