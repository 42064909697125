import styled from 'styled-components'
import { withStyles } from '@material-ui/core'
import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'
import TextField from '@mui/material/TextField'
export const Container = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	margin-top: 20px;

	.form-input {
		margin-bottom: 27px;
	}
`

export const Title = styled.div`
	text-align: left;
	font: normal normal bold 16px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	padding-right: 1px;
`

export const CustomButton = styled.div`
	align-items: center;
	background-color: #f2f2f2;
	color: #f05b4f;
	border: none;
	border-radius: 5px;
	display: flex;
	width: 40px;
	height: 40px;
	font-size: 20px;
	justify-content: center;
	cursor: pointer;
	transition: background-color 0.3s, color 0.3s, border 0.3s;

	&:hover {
		background-color: #f05b4f;
		color: #ffffff;
	}
`

export const Line = styled.div`
	border: 1px dashed #b2b2b2;
	opacity: 1;
	width: 100%;
`

export const CssTextField = withStyles({
	root: {
		margin: '0px',
		'& label.Mui-focused': {
			color:
				getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: '#fff !important',
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},

		'& .MuiInput-underline:after': {
			borderBottomColor: '#eeeeee',
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
			'&.Mui-focused fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() && getThemeColorFromLocalStorage().structure && getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure.backgroundColor
						: '#fff !important',
			},
		},
	},
})(TextField)
