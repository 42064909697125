import React from 'react'
import PropTypes from 'prop-types'

import { Grid } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
	label: {
		padding: '5px 18px 0 18px',
		fontWeight: '600',
		color: `#333333`,
	},
	textValue: {
		padding: '0 14px 6px 18px',
	},
}))

const GriTablow = ({ labels, values }) => {
	const { t } = useTranslation()
	const classes = useStyles()

	return (
		<React.Fragment>
			<Grid item xs={3}>
				<div className={classes.label}>{t(labels[0])}</div>
				<div className={classes.textValue}>{values[0]}</div>
			</Grid>
			<Grid item xs={5}>
				<div className={classes.label}>{labels[1]}</div>
				<div className={classes.textValue}>{values[1]}</div>
			</Grid>
			<Grid item xs={4}>
				<div className={classes.label}>{labels[2]}</div>
				<div className={classes.textValue}>{values[2]}</div>
			</Grid>
		</React.Fragment>
	)
}

GriTablow.propTypes = {
	labels: PropTypes.array,
	values: PropTypes.array,
}

export default GriTablow
