import React, { useState } from 'react'
import { IconButton } from '@mui/material'
import {
	AddBotIcon,
	Button,
	GridContainer,
	GridItem,
	MuiAutocomplete,
	MuiBox,
	MuiInput,
	MuiStack,
	MuiTypography,
	RemoveIcon,
} from '../../../../../components/storybook'
import { useTranslation } from 'react-i18next'
import { Controller, useForm } from 'react-hook-form'
import { benefitList, businessDivisionList, status } from '../../utils'
import moment from 'moment'
import { getDefaultColor } from '../../../../Partner/PartnerProfile/Functions'

export function FilterNegotiation({ getNegotiationFilter, convertOptions, selersList, grouperOptions, partnerBranches }) {
	const { t } = useTranslation()
	const { control, handleSubmit, setValue, watch } = useForm()

	const [showSecondContainer, setShowSecondContainer] = useState(false)

	const defaultColor = getDefaultColor()

	const toggleSecondContainer = () => {
		setShowSecondContainer(!showSecondContainer)
	}

	const watchAllFields = watch()

	const onSubmit = async data => {
		const filter = {
			TypeOfBenefit: data?.benefitName?.value,
			TypeOfGrouper: data?.grouper?.key,
			GrouperId: data?.typeGroup,
			sku_productName: data?.nameProduct,
			SellersName: data?.sellersName?.value,
			BusinessDivisionId: data?.businessDivision?.key,
			Status: data?.status?.value,
			Name_CpfCnpj: data?.participantInfo
				? data?.participantInfo
						.replace(/-/g, '')
						.replace(/\./g, '')
						.replace(/\//g, '')
				: null,
		}

		await getNegotiationFilter(filter)
	}

	const handleClear = () => {
		setValue('participantInfo', '')
		setValue('nameProduct', '')
		setValue('benefitName', null)
		setValue('businessDivision', null)
		setValue('status', null)
		setValue('pruductSKU', null)
		setValue('typeGroup', '')
		setValue('grouper', null)
		setValue('sellersName', '')
		getNegotiationFilter({})
	}

	return (
		<MuiBox
			sx={{
				backgroundColor: '#F8F8F8',
				padding: '10px 20px 20px 20px',
				borderRadius: '5px',
				width: '100%',
				'.MuiInputBase-input.Mui-disabled': {
					opacity: 1,
					background: '#F2F2F2 !important',
				},
			}}
			mt={3}
		>
			<form onSubmit={handleSubmit(onSubmit)}>
				<GridContainer spacing={1}>
					<GridItem lg={3} xs={12} md={3}>
						<Controller
							name="participantInfo"
							control={control}
							render={({ value, onChange }) => (
								<MuiInput
									showLabel
									textLabel={watchAllFields?.participantInfo ? t('NEGOTIATION.NAME_OR_CPF_CNPJ') : ' '}
									defaultColor={defaultColor}
									onChange={e => onChange(e)}
									value={value}
									placeholder={t('NEGOTIATION.NAME_OR_CPF_CNPJ')}
								/>
							)}
						/>
					</GridItem>

					<GridItem lg={3} xs={12} md={3}>
						<Controller
							name="status"
							control={control}
							render={({ onChange, value }) => (
								<MuiAutocomplete
									showLabel
									textLabel={watchAllFields?.status?.label ? 'Status' : ' '}
									defaultColor={defaultColor}
									value={value}
									onChange={(_e, newValue) => {
										onChange(newValue)
									}}
									options={convertOptions(status)}
									placeholder="Status"
								/>
							)}
							defaultValue={[]}
						/>
					</GridItem>
					<GridItem lg={3} xs={12} md={3}>
						<Controller
							name="grouper"
							control={control}
							render={({ onChange, value }) => (
								<MuiAutocomplete
									showLabel
									textLabel={watchAllFields?.grouper?.label ? 'Agrupador' : ' '}
									defaultColor={defaultColor}
									value={value}
									onChange={(_e, newValue) => {
										onChange(newValue)
									}}
									options={grouperOptions}
									placeholder="Agrupador"
								/>
							)}
							defaultValue={[]}
						/>
					</GridItem>
					<GridItem lg={3} xs={12} md={3}>
						{(!watchAllFields?.grouper?.label && (
							<Controller
								name=""
								control={control}
								render={({ value, onChange }) => (
									<MuiInput
										showLabel
										disabled
										defaultColor={defaultColor}
										textLabel={' '}
										onChange={e => onChange(e)}
										value={value}
										placeholder="Buscar"
									/>
								)}
							/>
						)) ||
							(watchAllFields?.grouper?.label === 'Produto' && (
								<Controller
									name="nameProduct"
									control={control}
									render={({ value, onChange }) => (
										<MuiInput
											showLabel
											textLabel={watchAllFields?.nameProduct ? t('NEGOTIATION.SKU_PRODUCT_NAME') : ' '}
											defaultColor={defaultColor}
											onChange={e => onChange(e)}
											value={value}
											placeholder={t('NEGOTIATION.SKU_PRODUCT_NAME')}
										/>
									)}
								/>
							)) || (
								<Controller
									name="typeGroup"
									control={control}
									render={({ value, onChange }) => (
										<MuiInput
											showLabel
											textLabel={watchAllFields?.typeGroup ? `Buscar ${watchAllFields?.grouper?.label}` : ' '}
											defaultColor={defaultColor}
											onChange={e => onChange(e)}
											value={value}
											placeholder={`Buscar ${watchAllFields?.grouper?.label}`}
										/>
									)}
								/>
							)}
					</GridItem>
				</GridContainer>
				{showSecondContainer && (
					<GridContainer spacing={1}>
						<GridItem lg={3} xs={12} md={3}>
							<Controller
								name="benefitName"
								control={control}
								render={({ onChange, value }) => (
									<MuiAutocomplete
										showLabel
										textLabel={watchAllFields?.benefitName?.label ? t('NEGOTIATION.BENEFIT') : ' '}
										defaultColor={defaultColor}
										value={value}
										onChange={(_e, newValue) => {
											onChange(newValue)
										}}
										options={convertOptions(benefitList)}
										placeholder={t('NEGOTIATION.BENEFIT')}
									/>
								)}
								defaultValue={[]}
							/>
						</GridItem>
						<GridItem lg={3} xs={12} md={3}>
							<Controller
								name="businessDivision"
								control={control}
								render={({ onChange, value }) => (
									<MuiAutocomplete
										showLabel
										textLabel={watchAllFields?.businessDivision?.label ? t('NEGOTIATION.BUSINESS_DIVISION') : ' '}
										defaultColor={defaultColor}
										value={value}
										onChange={(_e, newValue) => {
											onChange(newValue)
										}}
										options={partnerBranches}
										placeholder={t('NEGOTIATION.BUSINESS_DIVISION')}
									/>
								)}
								defaultValue={[]}
							/>
						</GridItem>
						<GridItem lg={3} xs={12} md={3}>
							<Controller
								name="sellersName"
								control={control}
								render={({ onChange, value }) => (
									<MuiAutocomplete
										showLabel
										textLabel={watchAllFields?.sellersName ? 'Nome do vendedor' : ' '}
										defaultColor={defaultColor}
										value={value}
										onChange={(_e, newValue) => {
											onChange(newValue)
										}}
										options={selersList}
										placeholder="Nome do vendedor"
									/>
								)}
								defaultValue={[]}
							/>
						</GridItem>
					</GridContainer>
				)}
				<MuiStack direction="row" spacing={2} display="flex" alignItems="center" justifyContent="space-between">
					<MuiStack direction="row" display="flex" alignItems="center" spacing={1}>
						<IconButton
							onClick={toggleSecondContainer}
							sx={{
								'&:hover': {
									backgroundColor: 'transparent',
								},
								padding: 0,
							}}
						>
							{showSecondContainer ? (
								<>
									<RemoveIcon size={35} color={defaultColor} />
								</>
							) : (
								<>
									<AddBotIcon size={30} color={defaultColor} />
								</>
							)}
						</IconButton>
						{showSecondContainer ? (
							<MuiTypography onClick={toggleSecondContainer} sx={{ color: defaultColor, cursor: 'pointer' }}>
								{t('NEGOTIATION.LESS_FILTERS')}
							</MuiTypography>
						) : (
							<MuiTypography onClick={toggleSecondContainer} sx={{ color: defaultColor, cursor: 'pointer' }}>
								{t('NEGOTIATION.MORE_FILTERS')}
							</MuiTypography>
						)}
					</MuiStack>
					<MuiStack direction="row" spacing={2} display="flex" alignItems="center" pr={1}>
						<Button
							text={t('BUTTON_FIND')}
							typeVariation="TextButton"
							type="submit"
							sx={{
								color: '#FFFFFF!important',
								textTransform: 'unset',
								width: '100%',
								height: '40px',
								padding: '5px 15px',
								backgroundColor: '#329EF1!important',
							}}
						/>
						<Button
							text={t('NEGOTIATION.CLEAR')}
							typeVariation="CleanButton"
							onClick={handleClear}
							sx={{
								border: '1px solid #C8C8C8',
								width: '100%',
								height: '40px',
								color: '#C81010!important',
								textTransform: 'unset',
							}}
						/>
					</MuiStack>
				</MuiStack>
			</form>
		</MuiBox>
	)
}
