import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../../../../redux/api/themeColors'

export const Search = styled.div`
	display: flex;
	justify-content: end;
	margin-top: 5px;
`
export const Table = styled.div`
	margin-top: 25px;

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				font-size: 15px;
				font-weight: bold;
				z-index: 0 !important;

				:first-child {
					border-top-left-radius: 6px;
					width: 100px !important;
					justify-content: flex-start;
					text-align: left !important;
				}

				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
					justify-content: flex-end;
					span {
						svg {
							display: none;
						}
					}
				}
				:nth-child(2) {
					text-align: left !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 45% !important;
				}
				:nth-child(4) {
					text-align: left !important;
					width: 15% !important;
				}
				:nth-child(5) {
					text-align: left !important;
					width: 20% !important;
				}
			}
		}
	}

	.MuiTableBody-root {
		.MuiTableCell-alignLeft {
			text-align: left;
			:last-child {
				text-align: right;
			}
		}
		tr {
			color: #717171;
			font-size: 15px;
			height: 45px;

			p {
				font-size: 13px;
				font-style: italic;
				margin: 0px;
			}

			td {
				padding: 0px 15px !important;
				:first-child {
					width: 1% !important;
					justify-content: flex-start;
					text-align: left !important;
				}
				:last-child {
					justify-content: flex-end;
				}
				:nth-child(2) {
					text-align: left !important;
					width: 10% !important;
				}
				:nth-child(3) {
					text-align: left !important;
					width: 45% !important;
				}
				:nth-child(4) {
					text-align: left !important;
					width: 15% !important;
				}
				:nth-child(5) {
					text-align: left !important;
					width: 20% !important;
				}
				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
				}
			}
		}
	}
`

export const EmptyTable = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding: 3rem;
	color: #b4b4b4;
	text-align: center;

	i {
		font-size: 3em;
		cursor: pointer;
	}

	.icon-informacao:before {
		font-size: 1.5em;
	}

	span {
		font-weight: 900;
		margin: 25px 0 12px;
	}

	.MuiTablePagination-toolbar {
		padding-right: 42px;
	}
`

export const SearchIcon = styled.i`
	color: ${getThemeColorFromLocalStorage() &&
	getThemeColorFromLocalStorage().visual &&
	getThemeColorFromLocalStorage().visual.icons &&
	getThemeColorFromLocalStorage().visual.icons.color
		? getThemeColorFromLocalStorage().visual.icons.color
		: '#fff !important'};
`

export const RowData = styled.p`
	font: normal normal normal 15px/41px Roboto !important;
	letter-spacing: 0px;
	color: #717171;
`
