import styled from 'styled-components'
import TextField from '@mui/material/TextField';
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'


const getThemeColorStructureBackground = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().structure) {
			if (getThemeColorFromLocalStorage().structure.backgroundColor) {
				return getThemeColorFromLocalStorage().structure.backgroundColor
			}
		}
	}
	return
}

export const Container = styled.div`
	.CardBody{
		padding: 15px 0px 15px 15px;
		background: rgb(246, 246, 246);
		border-radius: 5px;
		width: 100%;

	}

	.GridContainer{
		margin-top: 10px;
		align-items: center;
		height: 100%;
	}

	.buttonsContainer{
		width: 100%;
		padding: 15px 0;
		justify-content: end;
	}

	.buttonsContainerOut{
		width: 100%;
		padding: 15px 0px 15px 15px;
		display: flex;
		justify-content: end;
	}

	.Search{
		height: 40px;
		background-color: #329EF1;
		width: 100px;
		color: #ffffff;
		margin-right: 10px;
		font-size: 14px;
		font-weight: 400;
		text-transform:none;
	}

	.Clear{
		height: 40px;
		background-color: transparent;
		border: 1px solid #b6b6b6;
		color: red;
		width: 100px;
		font-size: 14px;
		font-weight: 400;
		text-transform: none;
	}

	.newButtons{
		height: 40px;
		width: 40px;
		min-width: 40px;
		margin-left: 10px;
		background-color: #f6f6f6;
		font-size: 24px;
		color: #989898;
	}

	.MuiInputLabel-root.Mui-focused {
		color: ${getThemeColorStructureBackground()};
	}
`

export const CssTextField = styled(TextField)({
	'& label.Mui-focused': {
		color: getThemeColorFromLocalStorage()?.headers?.backgroundColor,
	},
	'& label': {
		lineHeight: 1.2,
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: getThemeColorFromLocalStorage()?.navigation?.buttons
			?.backgroundColor,
	},
	'& .MuiOutlinedInput-root': {
		'&:hover fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
		'&.Mui-focused fieldset': {
			borderColor: getThemeColorFromLocalStorage()?.navigation?.buttons
				?.backgroundColor,
		},
	},
})
