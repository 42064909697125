import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// @ Components
import { Box } from '@mui/material'
import MaterialTable from 'components/CustomMaterialTable/CustomMaterialTable.jsx'

// @ Styles
import * as S from './styles'
import { Columns } from './mocks'

function ParameterizeTable({ results, setDivision, setParameterization, setHasParameterization }) {
	const { t } = useTranslation()
	const tableRef = React.useRef()

	const onCancel = () => {
		setDivision(null)
		setParameterization(prev => ({
			...prev,
			show: false,
			isMultiple: false,
		}))
	}

	const onConfirm = () => {
		setHasParameterization([])
	}

	return (
		<Fragment>
			<S.Container>
				<Box display="flex" gap="10px">
					<Box
						component="i"
						sx={{
							fontSize: '33px',
							color: '#989898',
						}}
						className="icon-i_atencao"
					/>
					<Box>
						As divisões de negócio abaixo já possuem parametrizações definidas.
						<br /> Deseja continuar com essa ação?
					</Box>
				</Box>
				<Box>
					<S.GroupButtons>
						<S.CustomButton size="sm" onClick={onCancel} bc="#B2B2B2" color="#F05B4F" bg="#ffffff" hoverBg="#F05B4F">
							{t('BUTTON_NO')}
						</S.CustomButton>{' '}
						<S.CustomButton size="sm" variant="contained" bc="#4caf50" color="greenButtonColor" bg="#4caf50" hoverBg="#00802C" onClick={onConfirm}>
							{t('BUTTON_YES')}
						</S.CustomButton>
					</S.GroupButtons>
				</Box>
			</S.Container>
			<Box>
				<MaterialTable
					tableRef={tableRef}
					style={{ width: '100%' }}
					options={{
						emptyRowsWhenPaging: false,
						toolBar: false,
						pageSizeOptions: [10, 20, 30, 40, 50],
						search: false,
						pageSize: 10,
						sorting: false,
						draggable: false,
					}}
					columns={Columns(t)}
					data={results}
				/>
			</Box>
		</Fragment>
	)
}

ParameterizeTable.propTypes = {
	results: PropTypes.array,
	setDivision: PropTypes.func,
	setParameterization: PropTypes.func,
	setHasParameterization: PropTypes.func,
}

export default ParameterizeTable
