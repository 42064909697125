const customPaginationStyle = {
	toolbar: {
		flex: 1,
		alignItems: 'center',
		justifyContent: 'center',
		background: '#fff',
		minHeight: '40px',
		padding: '0 !important',
	},
	totalCount: {
		color: 'black',
		right: '0',
		position: 'absolute',
	},
}

export default customPaginationStyle
