import axios from 'axios';

// GET api/Campaign?filters
export const SearchCampaigns = (name, sponsor, status, partnerId, campaignMode, page) =>
    axios.get(`/Campaign/GetCampaignsByPartner?name=${name}&sponsor=${sponsor}&status=${status}&partnerId=${partnerId}&campaignMode=${campaignMode}&page=${page}`)
        .then(response => response.data);

// GET api/Campaign/:id
export const GetCampaignById = (id) =>
    axios.get(`/Campaign/${id}`)
        .then(response => response.data);

// GET api/CampaignPerformance/:id
export const GetCampaignPerformance = (id) =>
    axios.get(`/Campaign/CampaignPerformance/${id}`)
        .then(response => response.data);

// GET api/Campaign/GetCampaignTypes
export const GetCampaignTypes = () =>
    axios.get(`/Campaign/GetCampaignTypes`)
        .then(response => response.data)

// GET api/Campaign/GetProductTypes
export const GetProductTypes = () =>
    axios.get(`/Campaign/GetProductTypes`)
        .then(response => response.data)

// GET api/Campaign/GetScoringTypes
export const GetScoringTypes = () =>
    axios.get(`/Campaign/GetScoringTypes`)
        .then(response => response.data)

// GET api/Campaign/GetCampaignStatus
export const GetCampaignStatus = () =>
    axios.get(`/Campaign/GetCampaignStatus`)
        .then(response => response.data)


// POST api/Campaign + Body
export const CreateCampaigns = (obj) =>
    axios.post(`/Campaign`, obj)
        .then(response => response.data);

// PUT api/Campaign/id + Body
export const UpdateCampaigns = (id, obj) =>
    axios.put(`/Campaign/${id}`, obj)
        .then(response => response.data);

// PUT api/Campaign/DeactivateCampaign/:id
export const DeactivateCampaign = (id) =>
    axios.put(`/Campaign/DeactivateCampaign/${id}`)
        .then(response => response.data);

export const GetBranchesByCampaignId = (id, name, pageSize, page) => {
    return axios.get(`/Campaign/GetBranchesByCampaignId/${id}?name=${name}&pageSize=${pageSize}&page=${page}`)
        .then(response => response.data)
}

export const GetBranchesByPartner = (name, pageSize, page) => {
    return axios.get(`/Campaign/GetBranchesByPartner?filter=${name}&pageSize=${pageSize}&page=${page}`)
        .then(response => response.data)
}

