export const ModalProps = {
	open: false,
	type: '',
	text: '',
	warningMessage: '',
	parameter: '',
	error: '',
}

export const PropsSnack = {
	open: false,
	severity: 'success',
	text: 'FIELD_ALERT_SUCCESS',
}
