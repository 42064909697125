/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'

import { Box, Stack } from '@mui/material'
import ParameterizeDivisionTB from './ParameterizeDivisionTB'

// @ Styles
import { Title, SubTitle, Text, Section } from './styles'
import { CssTextField } from '../../../Filter/styles'
import { GetDivisionName, HasParameterization, Schema } from './Functions'
import CustomSeparator from '../../../../../Breadcrumbs'
import Buttons from '../../../Buttons'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import ParameterizeTable from './ParameterizeTable'

function ParameterizeDivisionGen({
	productData,
	division,
	setDivision,
	parameterization,
	setParameterization,
	setSelectedDivisionIdGen,
	selectedDivisionIdGen,
	setSelectedDivision,
	setLoading,
	editMode,
	setEditMode,
	setMultiple,
	grouper,
}) {
	const { t } = useTranslation()
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})
	const [hasParameterization, setHasParameterization] = useState([])
	const [viewMode, setViewMode] = useState(true)

	const divisionName = GetDivisionName(division)

	const useData = useForm({
		defaultValues: {
			typeOfRedeem: '',
			redemptionPoints: '',
			pametrizationName: '',
		},
		resolver: yupResolver(Schema),
		mode: 'all',
	})

	const { formState, trigger, register, setValue, watch } = useData

	const onReset = () => {
		setDivision(null)
		setMultiple(false)
		setEditMode(false)
		setSelectedDivision([])
		setSelectedDivisionIdGen([])
		setParameterization(prev => ({
			...prev,
			show: false,
			isMultiple: false,
		}))
	}

	useEffect(() => {
		if (selectedDivisionIdGen?.length > 0) {
			setValue('selectedDivisionIdGen', selectedDivisionIdGen)
			HasParameterization({ productData, selectedDivisionIdGen, setHasParameterization, setLoading, setSnackStatus })
		}
	}, [selectedDivisionIdGen])

	useEffect(() => {
		if (parameterization?.isMultiple) {
			setViewMode(false)
		}
	}, [parameterization])

	const sharedProps = {
		setSnackStatus,
		setViewMode: setViewMode,
		viewMode: viewMode,
		editMode: editMode,
		setEditMode: setEditMode,
		onReset: onReset,
		setHasParameterization,
		setLoading: setLoading,
		hasParameterization: hasParameterization,
		snackStatus: snackStatus,
		results: hasParameterization?.results,
		setSelectedDivisionIdGen: setSelectedDivisionIdGen,
		selectedDivisionIdGen: selectedDivisionIdGen,
		modalParameterization: modalParameterization,
		setModalParameterization: setModalParameterization,
		isMultiple: parameterization?.isMultiple,
		productData: productData,
		division: division,
		pametrizationName: watch('pametrizationName'),
		grouper: grouper,
		setDivision: setDivision,
		setParameterization: setParameterization,
	}

	return (
		<Stack display={!parameterization?.show ? 'none' : 'flex'} gap="30px">
			<CustomSeparator item={productData?.name} />

			<Box display="flex" justifyContent="space-between" {...register('selectedDivisionIdGen')}>
				{_.isEmpty(hasParameterization?.results) ? (
					<>
						{!parameterization?.isMultiple ? (
							<Section gap={1}>
								<Title>Geração</Title>
								<SubTitle typeColor={divisionName}>
									{t(divisionName)}: {division?.name}
								</SubTitle>
							</Section>
						) : (
							<Section
								sx={{
									gap: '30px',
									transform: 'translateY(10px)',
								}}
							>
								<SubTitle typeColor={divisionName}>Parametrização múltipla - Geração</SubTitle>
								<Text>Para realizar a parametrização das divisões de negócio é necessário criar um nome.</Text>
								<Controller
									name="pametrizationName"
									control={useData.control}
									render={({ onChange, value }) => (
										<CssTextField
											label="Nome*"
											variant="outlined"
											size="small"
											value={value}
											onChange={e => {
												onChange(e.target.value)
											}}
											error={formState.errors?.pametrizationName}
											helperText={formState.errors?.pametrizationName?.message}
											InputProps={{
												maxLength: 50,
											}}
										/>
									)}
								/>
							</Section>
						)}
					</>
				) : (
					<SubTitle typeColor={divisionName}>Parametrização múltipla</SubTitle>
				)}

				<Box display="flex" gap={1}>
					{_.isEmpty(hasParameterization?.results) && (
						<>
							{!viewMode && (
								<>
									<Buttons className="cancel" Icon="icon-bot_fechar" onClick={() => setViewMode(true)} />
									<Buttons
										className="save"
										onClick={() => {
											trigger().then(isValid => {
												if (isValid) {
													setModalParameterization({
														open: true,
														title: 'Deseja realmente alterar a parametrização',
														subtitle: 'desse benefício?',
													})
												}
											})
										}}
										Icon="icon-bot_salvar"
									/>
								</>
							)}
							{viewMode && (
								<>
									<Buttons className="softBlueAction" onClick={() => setViewMode(false)} Icon="icon-bot_editar" />
								</>
							)}
						</>
					)}
					<Buttons className="blueAction" Icon="icon-bot_voltar" onClick={() => onReset()} />
				</Box>
			</Box>

			{_.isEmpty(hasParameterization?.results) ? <ParameterizeDivisionTB {...sharedProps} /> : <ParameterizeTable {...sharedProps} />}
		</Stack>
	)
}

ParameterizeDivisionGen.defaultProps = {
	show: false,
	isMultiple: false,
}

export default ParameterizeDivisionGen
