import styled from 'styled-components'

import { Grid, TextField, Tooltip, withStyles } from '@material-ui/core'

import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react.jsx'
import GridContainer from 'components/Grid/GridContainer.jsx'
import Button from 'components/CustomButtons/Button.jsx'
import { getThemeColorFromLocalStorage } from '../../../redux/api/themeColors'

import { grayColor } from 'assets/jss/material-dashboard-pro-react'

export const Container = styled.div`
	.button-container {
		background-color: ${({ disabledSaveButton }) =>
			disabledSaveButton && '#c8c8c8 !important'};
	}

	.cashBackInfo {
		background-color: #e9e7e7;
		padding: 4px;
		padding-left: 10px;
		border-radius: 4px;
		font-size: 15px;
		color: #625555;
		//opacity: 1;
	}

	.MuiIconButton-label {
		color: ${({ themeColors }) =>
			themeColors &&
			themeColors.navigation &&
			themeColors.navigation.buttons &&
			themeColors.navigation.buttons.backgroundColor &&
			themeColors.navigation.buttons.backgroundColor} !important;
	}

	#tabs-indicator {
		border-bottom: 4px solid
			${({ themeColors }) =>
				themeColors &&
				themeColors.navigation &&
				themeColors.navigation.buttons &&
				themeColors.navigation.buttons.backgroundColor &&
				themeColors.navigation.buttons.backgroundColor};
	}

	#tabs-indicator-btn {
		// alterar border radius somente da parte de baixo esquerda
		border-bottom-left-radius: 0px;
	}

	.op-icon {
		font-size: 20px !important;
		width: 24px;
		height: 24px !important;
	}

	.greyInfo2 {
		display: flex;
		align-items: center;
		font-weight: 400;
		background-color: #eee;
		padding: 0px;
	}
`

export const SearchBox = styled(GridContainer)`
	width: 100% !important;
	background: #f6f6f6;
	border-radius: 8px;
	margin-top: 10px !important;
	margin-left: 0px !important;
`

export const InnerContainer = styled(Grid)`
	.header {
		display: flex;
		width: 100%;
		background-color: #fafafa;
		padding: 20px;
		flex-wrap: wrap;
		align-items: center;
		> div {
			margin-right: 20px;
		}
	}

	.filter-box {
		display: flex;
		max-height: 310px;

		.box-height {
			min-height: 180px;
		}

		background-color: #fafafa;
		margin: 0;
		padding: 16px;
		padding-left: 24px;
		button {
			max-height: 38px;
		}
		.search-button {
			margin-right: 20px;
			color: #fff;
			&:hover {
				background-color: ${germiniDefaultColors[0]};
				opacity: 0.8;
			}
		}
	}

	@media screen and (max-width: 960px) {
		max-height: initial;
		.box-height {
			min-height: initial;
		}
		.grid-input {
			margin-top: 16px;
		}
	}

	@media screen and (max-width: 1280px) {
		.cards-box {
			justify-content: center !important;
		}
	}
`

export const GridBox = styled(Grid)`
	width: 100%;

	tbody {
		border-left: none;
		border-right: none;
	}

	.MuiPaper-elevation2 {
		box-shadow: unset;
	}

	.MuiToolbar-regular {
		min-height: 0px;
		padding: 15px;
	}

	.MuiTableHead-root {
		tr {
			th {
				background-color: #e9e9e9;
				color: ${germiniDefaultColors[0]};
				font-size: 15px;
				font-weight: bold;

				:first-child {
					border-top-left-radius: 6px;
					/* width: 27% !important; */
				}

				:nth-child(2) {
					/* text-align: left !important; */
					/* width: 44% !important; */
				}

				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
					justify-content: flex-end;
					text-align: right !important;
				}
			}
		}
	}

	.MuiTableBody-root {
		tr {
			color: #717171;
			font-size: 15px;
			height: 45px;

			p {
				font-size: 13px;
				font-style: italic;
				margin: 0px;
			}

			td {
				background-color: #fff;
				padding: 0px 15px !important;
				:first-child {
					width: 40% !important;
					justify-content: flex-start;
					text-align: left !important;
				}
				:last-child {
					border-top-right-radius: 6px;
					width: 2% !important;
					justify-content: flex-end;
					text-align: right !important;
					padding: 0px !important;
					button {
						span {
							svg {
								width: 22px;
								height: 22px;
							}
						}
					}
				}
				:nth-child(2) {
					text-align: left !important;
				}
			}
		}
	}
`

export const GridContent = styled(Grid)`
	padding: 0px !important;
	.MuiTableCell-head {
		color: ${({ colorTitle }) => colorTitle} !important;
	}
`

export const GridButton = styled(Button)`
	height: 45px !important;
	width: 45px !important;
	border-radius: 0px !important;
	margin: 0px 0px 0px 1px !important;
`
export const InputBox = styled.div`
	padding: 0 25px 9px 25px;
	display: flex;
	flex-wrap: wrap;
	.MuiFormControl-marginDense {
		margin: 0 !important;
	}
`
export const InputModalName = styled.div`
	width: 100%;
	margin-bottom: 35px;
	@media only screen and (min-width: 600px) {
		padding-right: 10px;
		width: 50%;
	}
`
export const InputModalCpf = styled.div`
	width: 100%;
	margin-bottom: 35px;
	@media only screen and (min-width: 600px) {
		padding-left: 10px;
		width: 50%;
	}
`
export const InputModalMinimum = styled.div`
	width: 100%;
	margin-bottom: 35px;
	@media only screen and (min-width: 600px) {
		padding-right: 10px;
		width: 50%;
	}
`
export const InputModalMaximum = styled.div`
	width: 100%;
	margin-bottom: 35px;
	@media only screen and (min-width: 600px) {
		padding-left: 10px;
		width: 50%;
	}
`

export const CssTooltip = withStyles({
	arrow: {
		left: '10px !important',
	},
	tooltip: {
		backgroundColor: '#171717',
		borderRadius: '6px',
		fontSize: '0.72rem',
		padding: '2px 8px',
		fontWeight: 'initial',
		'& span': {
			color: '#171717',
		},
	},
})(Tooltip)

export const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color:
				getThemeColorFromLocalStorage() &&
				getThemeColorFromLocalStorage().structure &&
				getThemeColorFromLocalStorage().structure.backgroundColor
					? getThemeColorFromLocalStorage().structure.backgroundColor
					: '#fff !important',
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},

		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:hover fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure
								.backgroundColor
						: '#fff !important',
			},
			'&.Mui-focused fieldset': {
				borderColor:
					getThemeColorFromLocalStorage() &&
					getThemeColorFromLocalStorage().structure &&
					getThemeColorFromLocalStorage().structure.backgroundColor
						? getThemeColorFromLocalStorage().structure
								.backgroundColor
						: '#fff !important',
			},
		},
	},
})(TextField)
