import styled from 'styled-components'
import { getThemeColorFromLocalStorage } from '../../../../redux/api/themeColors'

export const CustomCard = styled.div`
	.MuiCard-root {
		width: 161px;
		padding: 15px;
		background-color: #f5f5f5;
		box-shadow: none;
		&:hover {
			cursor: pointer;
			background-color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().structure &&
		getThemeColorFromLocalStorage().structure.backgroundColor
		? getThemeColorFromLocalStorage().structure.backgroundColor
		: '#fff !important'};
			.MuiIcon-root {
				color: #fff;
			}
			h1 {
				color: #fff;
			}
		}
	}

	.MuiIcon-root {
		height: 100%;
		width: 100%;
	}
`

export const HeaderIcons = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 5px;
`
export const CustomIcon = styled.div`
	.MuiIcon-root {
		color: ${getThemeColorFromLocalStorage() &&
		getThemeColorFromLocalStorage().visual &&
		getThemeColorFromLocalStorage().visual.icons &&
		getThemeColorFromLocalStorage().visual.icons.highlightColor
		? getThemeColorFromLocalStorage().visual.icons.highlightColor
		: '#fff !important'};
		font-size: 30px;
		transform: rotateY(180deg);
	}
`

export const CardTitle = styled.h1`
	font: normal normal normal 15px/20px Roboto;
	color: #333333;
	opacity: 1;
`

export const Observation = styled.p`
	margin-top: 60px;
	color: ${getThemeColorFromLocalStorage()?.navigation?.buttons?.backgroundColor};
	font: normal normal normal 15px/60px Roboto;
`

export const NoModules = styled.div`
	display: flex;
	flex-direction: column;

	i {
		color: #b4b4b4;
		display: flex;
		font-size: 30px;
		justify-content: center;
	}

	span {
		color: #b4b4b4;
		display: flex;
		font-weight: 900;
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		justify-content: center;
		margin: 25px 0 12px;
	}
`
