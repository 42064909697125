import React, { Component } from 'react'
import Pagination from 'material-ui-flat-pagination'

import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import {
	createTheme,
	MuiThemeProvider,
	withStyles,
	Toolbar,
} from '@material-ui/core'

import customPaginationStyle from 'assets/jss/material-dashboard-pro-react/views/customPaginationStyle'

import compose from 'utils/compose'

import { primaryColor } from 'assets/jss/material-dashboard-pro-react.jsx'

import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'

const getThemeColor = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().navigation) {
			if (getThemeColorFromLocalStorage().navigation.buttons) {
				if (
					getThemeColorFromLocalStorage().navigation.buttons
						.backgroundColor
				) {
					return getThemeColorFromLocalStorage().navigation.buttons
						.backgroundColor
				}
			}
		}
	} else {
		return '#08993A'
	}
}

const getThemeColorTextColor = () => {
	if (getThemeColorFromLocalStorage()) {
		if (getThemeColorFromLocalStorage().navigation) {
			if (getThemeColorFromLocalStorage().navigation.buttons) {
				if (
					getThemeColorFromLocalStorage().navigation.buttons.textColor
				) {
					return getThemeColorFromLocalStorage().navigation.buttons
						.textColor
				}
			}
		}
	} else {
		return '#08993A'
	}
}

const pagTheme = createTheme({
	overrides: {
		MuiFlatPagination: {
			root: {
				background: 'white',
			},
			colorInheritOther: {
				color: `${getThemeColor()}`,
				fontWeight: '400',
				backgroundColor: `${getThemeColorTextColor()}`,
				'&:hover': {
					color: primaryColor[0],
					fontWeight: '800',
				},
			},
		},
		MuiFlatPageButton: {
			root: {
				border: `1px solid ${getThemeColor()} !important `,
				borderRadius: '3px',
				padding: '0 12px 0 12px !important',
				maxWidth: '42px !important',
				fontSize: '11px',
				height: '33px',
				margin: '3px',
				backgroundColor: `${getThemeColor()}`,
				color: `${getThemeColorTextColor()}`,
				lineHeight: '1',
				'&:hover': {
					backgroundColor: `${getThemeColor()} !important`,
					color: `${getThemeColorTextColor()} !important`,
				},
			},
		},
	},
	palette: {
		primary: {
			main: '#000',
		},
	},
	typography: {
		useNextVariants: true,
	},
})

class CustomPagination extends Component {
	render() {
		const { pageSize, recordCount, classes, offset, clickEvent } =
			this.props
		return (
			<Toolbar className={classes.toolbar}>
				<MuiThemeProvider theme={pagTheme}>
					<Pagination
						otherPageColor="inherit"
						currentPageColor="default"
						size="small"
						limit={pageSize}
						offset={offset}
						total={recordCount}
						onClick={clickEvent}
						nextPageLabel={<ChevronRight />}
						previousPageLabel={<ChevronLeft />}
					/>
					<span className={classes.totalCount}>
						Total: <b>{recordCount}</b>
					</span>
				</MuiThemeProvider>
			</Toolbar>
		)
	}
}
export default compose(withStyles(customPaginationStyle))(CustomPagination)
