import styled from 'styled-components/macro'

export const Container = styled.div`
	margin-top: 37px;
	margin-bottom: 66px;
	border-bottom: 1px solid #c8c8c8;

	.MuiMenu-list {
		z-index: 1;
	}
	td:nth-child(2),
	th:nth-child(2) {
		text-align: left;
	}

	.icon-lupa {
		color: ${({ btnBGCol }) => btnBGCol};
	}

	.MuiTableCell-head {
		color: ${({ colorTitle }) => colorTitle} !important;
	}

	.MuiCheckbox-colorSecondary.Mui-checked {
	}

	.MTableToolbar-highlight-260 {
		color: #3c4858;
		background-color: #e9e9e9;
	}

	.wivQC .MuiTableBody-root tr td {
		padding: 0 !important;
	}
	.MuiTablePagination-caption {
		display: none;
	}

	

	.MuiTableCell-root {
		
	}

	.MuiTablePagination-root {
		overflow: visible !important;
	}
`

export const EmptyTable = styled.div`
	background-color: #fff;
	display: flex;
	flex-direction: column;
	padding-top: 1em;
	color: #b4b4b4;

	i {
		padding-top: 1em;
		font-size: 2em;
		cursor: pointer;
		margin: auto 18px;
	}

	.icon-informacao:before {
		font-size: 1.5em;
	}

	span {
		font-weight: 900;
		margin: 25px 0 12px;
	}

	.MuiTablePagination-toolbar {
		padding-right: 42px;
	}
`
export const ParticipantP = styled.p`
	font: normal normal bold 15px/21px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`
export const ErrorP = styled.p`
	text-align: left;
	font: normal normal bold 13px/15px Roboto;
	letter-spacing: 0px;
	color: #de2e2e;
	opacity: 1;
	margin-top: 5px;
	margin-bottom: 5px;
`

export const ActionBtn = styled.button`
	background-color: #F05B4F;
	border: none;
	cursor: pointer;
	color: #fff;
	font-size: 90%;
	height: 44px;
	/* margin-right: 2px; */
	width: 40px;
	font-size: 20px;
`

export const CellMaterialTable = styled.div`
	text-align: left;
	font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
	font-weight: 400;
	color: #878787;

	padding: 1.2em;
`
export const CellTooBar = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 11px;

	.btnTable {
		margin-left: 10px;
	}
`

export const IconInfo = styled.i`
	font-size: 20px;
	cursor: pointer;
	margin-left: 8px;
	color: #ff9800 !important;
	opacity: 1;
`
