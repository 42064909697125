/* eslint-disable react/prop-types */
import { Box, Grid } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import TagItem from '../../../../TagItem'
import * as S from '../styles'
import { useTranslation } from 'react-i18next'
import { validateValue, compareMarketingArea, getCheckListSelectedOptions } from '../../../../../Functions'
import Labelitem from '../../../../Labelitem'
import Select from '../../../../Select'
import { CheckList } from '../../../../../../../../components/storybook'

export default function WebsiteAndOthers({ isEditData, partnerData, partnerInfos, updateCustomFieldsForm }) {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()

	const [marketingArea, setMarketingArea] = useState(null)

	useEffect(() => {
		if (partnerInfos?.marketingArea && partnerData?.marketingArea) {
			setMarketingArea(compareMarketingArea(partnerInfos?.marketingArea, partnerData?.marketingArea))
		}
	}, [partnerInfos, partnerData])

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={8} sm={12}>
					{!isEditData ? (
						<Fragment>
							<Labelitem disabled label="Site" value={partnerData?.site} />
						</Fragment>
					) : (
						<Box mt={4}>
							<Fragment>
								<Labelitem label="Site" isEditData />
								<Controller
									control={control}
									name="businessWebsite"
									render={({ onChange, value }) => (
										<S.CssTextField
											error={errors?.businessWebsite}
											helperText={t(errors?.businessWebsite?.message)}
											fullWidth
											id="businessWebsite"
											value={value}
											disabled={false}
											onChange={onChange}
											variant="outlined"
											margin="dense"
											style={{
												backgroundColor: '#fff',
												marginTop: '8px',
											}}
										/>
									)}
									defaultValue={partnerData?.site}
								/>
							</Fragment>
						</Box>
					)}
				</Grid>
				<Grid item lg={12} sm={12}>
					{!isEditData ? (
						<Fragment>
							<Box mt={3} sx={{ color: isEditData ? '#333333' : '#8D8D8D', fontSize: '13px' }}>
								Segmento(s)
							</Box>
							<Grid container spacing={2}>
								{partnerData?.segmentations?.map((item, index) => (
									<Grid key={index} item lg={2} sm={3}>
										<TagItem label={item?.description} />
									</Grid>
								))}
							</Grid>
						</Fragment>
					) : (
						<Fragment>
							<Box direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
								<CheckList
									name="partnerSegments"
									selectorLabel="Segmento(s)"
									fieldName="partnerSegments"
									removeTextLabel="Deletar todas"
									addTextLabel="Adicionar todas"
									options={partnerInfos?.partnerSegmentations}
									label="Selecione e adicione o(s) Segmento(s)*"
									getSelectedItems={function noRefCheck(value) {
										updateCustomFieldsForm('partnerSegments', value)
									}}
									defaultOptions={getCheckListSelectedOptions(partnerData?.segmentations)}
								/>
							</Box>
						</Fragment>
					)}
				</Grid>

				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Área de marketing" value={validateValue(t(marketingArea?.label))} />
					) : (
						<Fragment>
							<Labelitem label="Área de marketing" required />
							<Controller
								control={control}
								name="businessMarketingArea"
								render={({ onChange, value }) => (
									<Select
										name="businessMarketingArea"
										error={errors?.businessMarketingArea}
										helperText={t(errors?.businessMarketingArea?.value?.message)}
										value={value}
										options={partnerInfos?.marketingArea ?? []}
										onChange={onChange}
									/>
								)}
								defaultValue={{
									label: marketingArea?.label,
									value: marketingArea?.label,
								}}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}
