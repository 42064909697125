import styled from "styled-components";

export const Container = styled.div`
  cursor: pointer;
  padding-left: 15px;

  @media screen and (max-width: 880px) {
    padding-left: 0px !important;
  }

  > div {
    > div:not(:nth-child(2)) {
      z-index: -2;
    }

    :hover div:not(:nth-child(2)) {
      z-index: 2;
    }
  }

  .slick-slide {
    visibility: hidden;
  }
  .slick-slide.slick-active {
    visibility: visible;
  }
`;

export const PrevArrow = styled.div`
  left: 5px !important;
  z-index: 1;
`;

export const NextArrow = styled.div`
  right: 5px !important;
`;
