import React, { useState } from 'react'
import { Icon, TextStyle, Form } from './style'
import { Modal, Box } from '@material-ui/core'
import Button from 'components/CustomButtons/Button.jsx'
import { useTranslation } from 'react-i18next'

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	maxWidth: 600,
	maxHeight: 600,
	bgcolor: 'background.paper',
	borderRadius: '10px',
	boxShadow: 24,
	p: 4,
	padding: '10px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}

const ModalInfo = ({
	open,
	onConfirm,
	onCancel,
	icon,
	text,
	type,
	GetValue,
	GetValidationNewFile,
	SelectValue,
	setImportNewFile,
	valueReserve,
	msgError,
}) => {
	const { t } = useTranslation()
	return (
		<Modal
			open={open}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
			<Box sx={style}>
				<Icon>
					<i className={'icon-alerta_modal'} />
				</Icon>

				<TextStyle>
					<Form
						style={{
							alignItems: 'center',
							display: 'flex',
							flexDirection: 'column',
						}}
					>
						<p
							style={{
								marginBottom: '0.8rem',
								marginLeft: '100px',
								marginRight: '100px',
							}}
						>
							As alterações realizadas serão perdidas. Deseja
							realmente continuar?
						</p>
						<div
							style={{
								marginTop: '20px',
								marginBottom: '37px ',
								display: 'flex',
								justifyContent: 'space-between',
							}}
						>
							<Button
								style={{
									width: '100px',
									marginRight: '30px',
								}}
								size="sm"
								variant="contained"
								color="greenBorderButtonColor"
								onClick={onCancel}
							>
								{t('BUTTON_NO')}
							</Button>
							<Button
								style={{
									width: '100px',
								}}
								size="sm"
								variant="contained"
								color="greenButtonColor"
								onClick={onConfirm}
							>
								{t('BUTTON_YES')}
							</Button>
						</div>
					</Form>
				</TextStyle>
			</Box>
		</Modal>
	)
}
export default ModalInfo
