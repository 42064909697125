import styled from 'styled-components'

export const Container = styled.div`
	display: flex;
	gap: 10px;
	width: 100%;
	justify-content: space-between;

	.stack-info {
		background: #f2f2f2 0% 0% no-repeat padding-box;
		border-radius: 4px;
		opacity: 1;
	}

	.box-info {
		display: flex;
		flex-direction: column;
	}

	.stack-info-icon {
		width: 40px;
		background: #f2f2f2 0% 0% no-repeat padding-box;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		i {
			font-size: 22px;
		}
	}
`

export const Title = styled.div`
	text-align: left;
	font: normal normal normal 13px Roboto;
	letter-spacing: 0px;
	color: #333333;
	margin-bottom: 3px;
	opacity: 1;
`

export const SubTitle = styled.div`
	text-align: left;
	font: normal normal normal 11px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
	margin-bottom: 10px;
`

export const Value = styled.div`
	text-align: left;
	font: normal normal bold 13px Roboto;
	letter-spacing: 0px;
	color: #333333;
	opacity: 1;
`
