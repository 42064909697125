import React, { Fragment } from 'react'
import * as _ from 'lodash'
import * as moment from 'moment'
import Skeleton from 'react-loading-skeleton'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { TextField, Icon, Grid } from '@material-ui/core'
import { withStyles, createTheme } from '@material-ui/core/styles'

// core components
import Button from 'components/CustomButtons/Button.jsx'
import Card from 'components/Card/Card.jsx'
import CardBody from 'components/Card/CardBody.jsx'
import CardHeader from 'components/Card/CardHeader.jsx'
import CardIcon from 'components/Card/CardIcon.jsx'
import DocumentTitle from 'react-document-title'
import DoubleFormatCustom from 'components/NumberFormatCustom/DoubleFormatCustom'
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import Loader from 'components/Loader/Loader.jsx'
import Section from 'components/Section'
import { Tooltip } from '@material-ui/core'
// Utils
import compose from 'utils/compose'
import { numberFormatText } from 'utils/utils'
import { grayColor } from 'assets/jss/material-dashboard-pro-react'
import performanceStyle from 'assets/jss/material-dashboard-pro-react/views/dashboardStyle'

import style from './performance.module.scss'

import { GetCampaignPerformanceData, ShowLoader, HideLoader, getClaimsGroups } from 'redux/actions'
import { envs } from '../../../../redux/api/windowServerData'
import { getProgramTitle } from '../../../../utils/utils'

const theme = createTheme({
	overrides: {
		MuiButtonBase: {
			// Name of the component ⚛️ / style sheet
			root: {
				// Name of the rule
				border: '1px solid green !important',
				borderRadius: '3px',
				padding: '0 12px 0 12px !important',
				maxWidth: '32px !important',
				height: '33px',
				margin: '3px',
				'&:hover': {
					background: 'green !important',
					color: 'white',
				},
			},
		},
	},
	palette: {
		primary: {
			main: grayColor[2],
		},
	},
	typography: {
		useNextVariants: true,
	},
})

const CampaignStyle = {
	inputDate: {
		height: '3px',
		fontSize: '13px',
		paddingLeft: '8px',
	},
	labelTexF: {
		lineHeight: '0 !important',
	},
	labelTexRF: {
		lineHeight: '0 !important',
		top: '-2px',
	},
	rowMargin: {
		marginBottom: '15px',
	},
}

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: grayColor[2],
		},
		'& label': {
			fontSize: '14px',
			lineHeight: 1.2,
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: grayColor[2],
		},
		'& .MuiOutlinedInput-root': {
			'&:not(.Mui-disabled)': {
				'&:hover fieldset': {
					borderColor: grayColor[2],
				},
				'&.Mui-focused fieldset': {
					borderColor: grayColor[2],
				},
			},
		},
		'& .MuiOutlinedInput-root.Mui-disabled': {
			backgroundColor: 'hsl(0,0%,95%)',
			'& fieldset': {
				borderColor: 'hsl(0,0%,90%)',
			},
		},
		'& .MuiOutlinedInput-inputMarginDense ': {
			paddingTop: '9.5px',
			paddingBottom: '9.5px',
		},
	},
})(TextField)

const CssTooltip = withStyles({
	tooltip: {
		backgroundColor: '#171717',
		borderRadius: '6px',
		fontSize: '0.72rem',
		padding: '2px 8px',
		fontWeight: 'initial',
		'& span': {
			color: '#171717',
		},
	},
})(Tooltip)

class Performance extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			id: '',
			partner: '',
			name: '',
			sponsor: '',
			investment: '',
			residualBalance: '',
			leftoverInvestment: '',
			volume: '',
			startTerm: '',
			endTerm: '',
			campaignMode: 1,
			dashboardCard: [],

			cardDataBody1: null,
			cardDataBody2: null,
			cardDataBody3: null,
			cardDataBody4: null,
		}

		moment.locale(localStorage.getItem('i18nextLng'))
	}
	componentDidMount() {
		this.props.ShowLoader(<Loader />)
		const id = this.props.match && this.props.match.params.id
		if (!id) {
			this.props.history.goBack()
		}
		this.props.GetCampaignPerformanceData(id)
		//this.props.GetAnnouncementData();
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.campaignPerformanceData !== this.props.campaignPerformanceData) {
			this.setState({ ...this.props.campaignPerformanceData }, () => {
				this.handleCardsData(this.state.dashboardCard[0], this.state.dashboardCard[1], this.state.dashboardCard[2], this.state.dashboardCard[3])
				this.props.HideLoader()
			})
		}

		if (prevProps.campaignFailed !== this.props.campaignFailed) {
			//console.log("performance - cards Data Failed: ", this.props.campaignFailed);
			alert('performance - cards Data Failed: ' + this.props.campaignFailed)
			this.props.HideLoader()
		}
	}

	handleBack = () => {
		this.props.history.goBack()
	}

	render() {
		const { t, classes } = this.props
		const {
			cardDataBody1,
			cardDataBody2,
			cardDataBody3,
			cardDataBody4,
			partner,
			name,
			sponsor,
			investment,
			residualBalance,
			leftoverInvestment,
			volume,
			startTerm,
			endTerm,
			campaignMode,
			dashboardCard,
		} = this.state

		var cardLoaderSkeleton = (
			<div>
				<p className={style.cardBodyTitle}>
					<Skeleton />
				</p>
				<span className={style.cardBodyValue}>
					<Skeleton width={140} />
				</span>

				<p className={style.cardBodyTitle}>
					<Skeleton />
				</p>
				<span className={style.cardBodyValue}>
					<Skeleton width={140} />
				</span>

				<p className={style.cardBodyTitle}>
					<Skeleton />
				</p>
				<span className={style.cardBodyValue}>
					<Skeleton width={140} />
				</span>
			</div>
		)

		return (
			<>
				<DocumentTitle title={getProgramTitle()} />
				<GridContainer>
					<GridItem
						xs={12}
						sm={12}
						md={12}
						style={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						<Section title={t('TITLE_CAMPAIGN_DATA')} style={{ flexGrow: 1 }} />

						<Button style={{ marginLeft: '15px' }} size="sm" variant="contained" color="greenBorderButtonColor" onClick={this.handleBack}>
							{t('BUTTON_BACK')}
						</Button>
					</GridItem>
				</GridContainer>
				{/* GRAPHS */}
				<GridContainer>
					{/* #2 - NOME */}
					<GridItem xs={12} sm={12} md={6} style={{ marginBottom: '10px' }}>
						<CssTextField
							disabled={true}
							className={style.TextField}
							fullWidth
							label={t('FIELD_CAMPAIGN_NAME')}
							id="name"
							name="name"
							value={name}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							InputLabelProps={{
								classes: {
									disabled: name && name !== '' ? classes.labelTexRF : '',
								},
							}}
						/>
					</GridItem>
					{/* #2 - CPF */}
					<GridItem xs={12} sm={12} md={6}>
						<CssTextField
							className={style.TextField}
							disabled={true}
							fullWidth
							label={t('FIELD_CAMPAIGN_SPONSOR')}
							id="sponsor"
							name="sponsor"
							value={sponsor}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							InputLabelProps={{
								classes: {
									disabled: sponsor && sponsor !== '' ? classes.labelTexRF : '',
								},
							}}
						/>
					</GridItem>
					{/* #3 - LOGIN */}
					<GridItem xs={12} sm={12} md={4} style={{ marginBottom: '10px' }}>
						<CssTextField
							disabled={true}
							className={style.TextField}
							fullWidth
							label={t('FIELD_CAMPAIGN_INVESTMENT')}
							id="investment"
							name="investment"
							value={_.toNumber(investment).toFixed(2)}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							InputProps={{
								startAdornment: (
									<span style={{ marginRight: '5px' }}>
										{localStorage.getItem('currencySymbol') !== null
											? localStorage.getItem('currencySymbol')
											: // : envs.REACT_APP_CURRENCY_SYMBOL + ' '}
											envs.REACT_APP_CURRENCY_SYMBOL + ' '}
									</span>
								),
								inputComponent: DoubleFormatCustom,
							}}
							InputLabelProps={{
								classes: {
									disabled: classes.labelTexRF,
								},
							}}
						/>
					</GridItem>
					<GridItem
						xs={12}
						sm={12}
						md={4}
						style={{
							display: 'flex',
							position: 'relative',
							justifyContent: 'flex-end',
						}}
					>
						<CssTextField
							disabled={true}
							className={style.TextField}
							fullWidth
							label={t('FIELD_CAMPAIGN_RESIDUAL_BALANCE')}
							id="residualBalance"
							name="residualBalance"
							value={residualBalance ? _.toNumber(residualBalance).toFixed(2) : '0'}
							variant="outlined"
							margin="dense"
							style={{
								backgroundColor: '#fff',
							}}
							InputProps={{
								startAdornment: (
									<span style={{ marginRight: '5px' }}>
										{localStorage.getItem('programCurrencySymbol') !== null
											? localStorage.getItem('programCurrencySymbol') + ' '
											: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
											envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
									</span>
								),
								inputComponent: DoubleFormatCustom,
							}}
							InputLabelProps={{
								classes: {
									disabled: classes.labelTexRF,
								},
							}}
						/>
						<CssTooltip
							id={`tooltip-linked`}
							title="O saldo residual é decorrente do arredondamento na quantidade de produtos subsidiados pela campanha"
							placement="top-start"
							arrow
						>
							<i
								className=" icon-ico_mais_informacao"
								style={{
									color: '#5d5d5d',
									fontSize: '22px',
									top: '4px',
									marginLeft: '5px',
									position: 'absolute',
									marginRight: '11px',
									marginTop: '12px',
								}}
							/>
						</CssTooltip>
					</GridItem>
					<GridItem xs={12} sm={12} md={4}>
						<CssTextField
							disabled={true}
							className={style.TextField}
							fullWidth
							label={t('FIELD_CAMPAIGN_LEFTOVER_CAMPAIGN')}
							id="leftoverInvestment"
							name="leftoverInvestment"
							value={leftoverInvestment ? _.toNumber(leftoverInvestment).toFixed(2) : ''}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							InputProps={{
								startAdornment: (
									<span style={{ marginRight: '5px' }}>
										{localStorage.getItem('programCurrencySymbol') !== null
											? localStorage.getItem('programCurrencySymbol') + ' '
											: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
											envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
									</span>
								),
								inputComponent: DoubleFormatCustom,
							}}
							InputLabelProps={{
								classes: {
									disabled: classes.labelTexRF,
								},
							}}
						/>
					</GridItem>
					{/* #4 - E-MAIL */}
					<GridItem xs={12} sm={12} md={4}>
						<CssTextField
							disabled={true}
							fullWidth
							id="startTerm"
							label={t('FIELD_START')}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							value={moment(startTerm).isValid() ? moment(startTerm).format('L') : startTerm}
							InputLabelProps={{
								classes: {
									disabled: startTerm && startTerm !== '' ? classes.labelTexRF : '',
								},
							}}
						/>
					</GridItem>
					{/* #2 - STATUS */}
					<GridItem xs={12} sm={6} md={4}>
						<CssTextField
							disabled={true}
							fullWidth
							label={t('FIELD_END')}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							value={moment(endTerm).isValid() ? moment(endTerm).format('L') : endTerm}
							InputLabelProps={{
								classes: {
									disabled: endTerm && endTerm !== '' ? classes.labelTexRF : '',
								},
							}}
						/>
					</GridItem>
					<GridItem xs={12} sm={6} md={4}>
						<CssTextField
							disabled={true}
							fullWidth
							label={t('FIELD_CAMPAIGN')}
							variant="outlined"
							margin="dense"
							style={{ backgroundColor: '#fff' }}
							value={campaignMode === 1 ? t('FIELD_GENERATION_POINTS') : t('FIELD_RESCUE_POINTS')}
							InputLabelProps={{
								classes: {
									disabled: classes.labelTexRF,
								},
							}}
						/>
					</GridItem>
				</GridContainer>
				<Grid style={{ margin: '20px 0 20px 0', paddingRight: '15px' }}>
					<Section title={t('MENU_CAMPAIGN_PERFORMANCE')} />
				</Grid>
				<GridContainer
					style={{
						paddingTop: '20px',
					}}
				>
					{/* card 1 */}
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card
							style={{
								minHeight: '235px',
							}}
						>
							<CardHeader color="warning" stats icon>
								<CardIcon color="warning" style={{ borderRadius: '8px' }}>
									<Icon>shopping_basket</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>{dashboardCard[0] && t(dashboardCard[0].name)}</div>
							</CardHeader>
							<CardBody>{cardDataBody1 ? cardDataBody1.map(x => [x]) : cardLoaderSkeleton}</CardBody>
						</Card>
					</GridItem>
					{/* card 2 */}
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card
							style={{
								minHeight: '235px',
							}}
						>
							<CardHeader color="success" stats icon>
								<CardIcon color="success" style={{ borderRadius: '8px' }}>
									<Icon>language</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>{dashboardCard[1] && t(dashboardCard[1].name)}</div>
							</CardHeader>
							<CardBody>{cardDataBody2 ? cardDataBody2.map(x => [x]) : cardLoaderSkeleton}</CardBody>
						</Card>
					</GridItem>
					{/* card 3 */}
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card
							style={{
								minHeight: '235px',
							}}
						>
							<CardHeader color="danger" stats icon>
								<CardIcon color="danger" style={{ borderRadius: '8px' }}>
									<Icon>trending_up</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>{dashboardCard[2] && t(dashboardCard[2].name)}</div>
							</CardHeader>
							<CardBody>{cardDataBody3 ? cardDataBody3.map(x => [x]) : cardLoaderSkeleton}</CardBody>
						</Card>
					</GridItem>
					{/* card 4 */}
					<GridItem xs={12} sm={6} md={6} lg={3}>
						<Card
							style={{
								minHeight: '235px',
							}}
						>
							<CardHeader color="info" stats icon>
								<CardIcon color="info" style={{ borderRadius: '8px' }}>
									<Icon>search</Icon>
								</CardIcon>
								<div className={style.cardHeadTitle}>
									{dashboardCard[3] && localStorage.getItem('programCurrencySymbol')
										? localStorage.getItem('programCurrencySymbol') + ' '
										: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
										envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' '}
								</div>
							</CardHeader>
							<CardBody>{cardDataBody4 ? cardDataBody4.map(x => [x]) : cardLoaderSkeleton}</CardBody>
						</Card>
					</GridItem>
				</GridContainer>
			</>
		)
	}
	//
	handleCardsData = (card1, card2, card3, card4) => {
		let _c1 = []
		let _c2 = []
		let _c3 = []
		let _c4 = []
		//
		if (card1 && card1.indicators.length > 0) {
			_.forEach(card1.indicators, (item, index) => {
				let labelBefore = this.props.t(item.labelBefore)
				labelBefore = labelBefore.length > 0
				let labelAfter = this.props.t(_.replace(item.labelAfter, /[^A-Z0-9]+/gi, ''))
				labelAfter = labelAfter.length > 0 ? '/' + labelAfter : ''

				_c1.push(
					<Fragment key={index}>
						<p className={style.cardBodyTitle}>{this.props.t(item.name)}</p>
						<span className={style.cardBodyValue}>
							{labelBefore}
							{item.type === 'CURRENCY' ? numberFormatText(item.value, '', 2) : numberFormatText(item.value, '', 0)}
							{labelAfter}
						</span>
					</Fragment>,
				)
			})
		}

		if (card2 && card2.indicators.length > 0) {
			_.forEach(card2.indicators, (item, index) => {
				let labelBefore = this.props.t(item.labelBefore)
				labelBefore =
					labelBefore.length > 0 && localStorage.getItem('currencyGeneralRulesData') !== null
						? JSON.parse(localStorage.getItem('currencyGeneralRulesData')).currencySymbol
						: ''
				let labelAfter = this.props.t(_.replace(item.labelAfter, /[^A-Z0-9]+/gi, ''))
				labelAfter = labelAfter.length > 0 ? '/' + labelAfter : ''

				_c2.push(
					<Fragment key={index}>
						<p className={style.cardBodyTitle}>{this.props.t(item.name)}</p>
						<span className={style.cardBodyValue}>
							{labelBefore}
							{item.type === 'CURRENCY' ? numberFormatText(item.value, '', 2) : numberFormatText(item.value, '', 0)}
							{labelAfter}
						</span>
					</Fragment>,
				)
			})
		}

		if (card3 && card3.indicators.length > 0) {
			_.forEach(card3.indicators, (item, index) => {
				let labelBefore = this.props.t(item.labelBefore)
				labelBefore =
					labelBefore.length > 0 && localStorage.getItem('currencyGeneralRulesData') !== null
						? JSON.parse(localStorage.getItem('currencyGeneralRulesData')).currencySymbol
						: ''
				let labelAfter = this.props.t(_.replace(item.labelAfter, /[^A-Z0-9]+/gi, ''))
				labelAfter = labelAfter.length > 0 ? '/' + labelAfter : ''

				_c3.push(
					<Fragment key={index}>
						<p className={style.cardBodyTitle}>{this.props.t(item.name)}</p>
						<span className={style.cardBodyValue}>
							{labelBefore}
							{item.type === 'CURRENCY' ? numberFormatText(item.value, '', 2) : numberFormatText(item.value, '', 0)}
							{labelAfter}
						</span>
					</Fragment>,
				)
			})
		}

		if (card4 && card4.indicators.length > 0) {
			_.forEach(card4.indicators, (item, index) => {
				let labelBefore = this.props.t(item.labelBefore)
				labelBefore =
					labelBefore.length > 0 && localStorage.getItem('currencyGeneralRulesData') !== null
						? JSON.parse(localStorage.getItem('currencyGeneralRulesData')).currencySymbol
						: ''
				let labelAfter = this.props.t(_.replace(item.labelAfter, /[^A-Z0-9]+/gi, ''))
				labelAfter = labelAfter.length > 0 ? '/' + labelAfter : ''

				_c4.push(
					<Fragment key={index}>
						<p className={style.cardBodyTitle}>{this.props.t(item.name)}</p>
						<span className={style.cardBodyValue}>
							{labelBefore}
							{item.type === 'CURRENCY' ? numberFormatText(item.value, '', 2) : numberFormatText(item.value, '', 0)}
							{labelAfter}
						</span>
					</Fragment>,
				)
			})
		}

		this.setState({ cardDataBody1: _c1 })
		this.setState({ cardDataBody2: _c2 })
		this.setState({ cardDataBody3: _c3 })
		this.setState({ cardDataBody4: _c4 })
	}
}

const mapStateToProps = state => {
	return {
		campaignPerformanceData: state.campaign.campaignPerformanceData,
		campaignFailed: state.campaign.campaignFailed,
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({ GetCampaignPerformanceData, ShowLoader, HideLoader, getClaimsGroups }, dispatch)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(performanceStyle),
	withStyles(CampaignStyle),
	withTranslation(),
)(Performance)
