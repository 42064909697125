import axios from 'axios'

export const GetAttachmentTypes = () => {
	return axios.get(`/PartnerAttachments/GetAttachmentTypes`).then(response => response.data)
}

export const AttachmentFile = async data => {
	const fileData = new Blob([data?.file], {
		type: 'application/octet-stream',
	})

	const fileName = data?.file?.name

	const formData = new FormData()
	formData.append('File', fileData, fileName)
	formData.append('FileName', data?.fileName || '')

	return await axios
		.post(`/PartnerAttachments/PostAttachmentFile`, formData, {
			headers: { 'Content-Type': 'multipart/form-data' },
		})
		.then(response => response)
}

export const PutAttachments = async (form, partnerId) => {
	const result = []

	form.forEach(item => {
		const { fileName, file, attachmentTypes, attachmentType } = item
		result.push({
			fileName,
			file: file.name,
			attachmentTypes: attachmentTypes.value,
			attachmentType,
		})
	})
	return await axios.put(`/PartnerAttachments/PutAttachments/${partnerId}`, result).then(response => response.data)
}

export const CreatePartnerAttachments = async (form, partnerId) => {
	const result = []

	form.forEach(item => {
		const { fileName, file, attachmentTypes, attachmentType } = item
		result.push({
			fileName,
			file: file,
			attachmentTypes: attachmentTypes.value,
			attachmentType,
		})
	})
	return await axios.post(`/PartnerAttachments/CreatePartnerAttachments/${partnerId}`, result).then(response => response.data)
}

export const GetAttachmentTypesList = partnerId => {
	return axios.get(`/PartnerAttachments/${partnerId}/GetAttachments`).then(response => response.data)
}

export const DeleteAttachmentFile = async (id, fileName, name) => {
	return await axios.delete(`/PartnerAttachments/DeleteAttachmentFile/${id}/${fileName}/${name}`)
}

export const GetAttachments = async id => {
	return await axios
		.get(`/PartnerAttachments/GetAttachments/${id}`)
		.then(response => response)
		.catch(response => response)
}
