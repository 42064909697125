// LOGIN USER
export const LOGIN_REQUEST = 'USERS_LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'USERS_LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'USERS_LOGIN_FAILURE'
export const LOGOUT = 'USERS_LOGOUT'
export const GETALL_REQUEST = 'USERS_GETALL_REQUEST'
export const GET_CLAIN_USERS = 'GET_CLAIN_USERS'
export const GET_CLAIN_USERS_SUCCESS = 'GET_CLAIN_USERS_SUCCESS'
export const GET_CLAIN_USERS_FAILURE = 'GET_CLAIN_USERS_FAILURE'
export const GETALL_SUCCESS = 'USERS_GETALL_SUCCESS'
export const GETALL_FAILURE = 'USERS_GETALL_FAILURE'
export const CHANGE_PASSWORD = 'USERS_CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAILURE = 'USERS_CHANGE_PASSWORD_FAILURE'
export const GET_SUCCESS = 'USERS_GET_SUCCESS'
export const GET_FAILURE = 'USERS_GET_FAILURE'
export const REQUEST_PASSWORD_RESET = 'REQUEST_PASSWORD_RESET'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED'

// HOME
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA'
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILED'
// IMAGES
export const POST_IMAGE_DATA = 'POST_IMAGE_DATA'
export const SET_FILE_DATA = 'SET_FILE_DATA'
export const FETCH_THUMBNAILS = 'FETCH_THUMBNAILS'
export const FETCH_IMAGE_FAILED = 'FETCH_IMAGE_FAILED'

// SWEET-ALERT
export const SWEET_ALERT = 'SWEET_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

// LOADER
export const SHOW_LOADER = 'SHOW_LOADER'
export const HIDE_LOADER = 'HIDE_LOADER'

// LOCATION
export const FETCH_CITY_DATA = 'FETCH_CITY_DATA'
export const FETCH_STATE_DATA = 'FETCH_STATE_DATA'
export const FETCH_COUNTRIES_DATA = 'FETCH_COUNTRIES_DATA'
export const FETCH_ZIPCODE_DATA = 'FETCH_ZIPCODE_DATA'
export const FETCH_CITY_FAILED = 'FETCH_CITY_DATA_FAILED'
export const FETCH_STATE_FAILED = 'FETCH_STATE_FAILED'
export const FETCH_COUNTRIES_FAILED = 'FETCH_COUNTRIES_FAILED'
export const FETCH_ZIPCODE_FAILED = 'FETCH_ZIPCODE_FAILED'

// MANAGEMENT - GENERAL RULES
export const FETCH_GENERAL_RULES_DATA = 'FETCH_GENERAL_RULES_DATA'
export const FETCH_GENERAL_RULES_FAILED = 'FETCH_GENERAL_RULES_FAILED'

export const FETCH_REGULATION_DATA = 'FETCH_REGULATION_DATA'
export const FETCH_REGULATION_FAILED = 'FETCH_REGULATION_FAILED'

// PLATFORM RULES
export const FETCH_PLATFORM_RULES_DATA = 'FETCH_PLATFORM_RULES_DATA'
export const FETCH_PLATFORM_RULES_FAILED = 'FETCH_PLATFORM_RULES_FAILED'

// USER - BY PARTNER ID
export const FETCH_PARTNER_USER_DATA = 'FETCH_PARTNER_USER_DATA'
export const FETCH_PARTNER_USER_PARTNER_DATA = 'FETCH_PARTNER_USER_PARTNER_DATA';
export const FETCH_PARTNER_USER_PARTNER_ERROR = 'FETCH_PARTNER_USER_PARTNER_ERROR';
export const FETCH_PARTNER_USER_BY_ID = 'FETCH_PARTNER_USER_BY_ID';
export const FETCH_PARTNER_USER_BY_ID_FAILED = 'FETCH_PARTNER_USER_BY_ID_FAILED';
export const POST_PARTNER_USER_DATA = 'POST_PARTNER_USER_DATA'
export const POST_PARTNER_USER_FAILED = 'POST_PARTNER_USER_FAILED'
export const PUT_PARTNER_USER_DATA = 'PUT_PARTNER_USER_DATA'
export const PUT_PARTNER_USER_DATA_FAILED = 'PUT_PARTNER_USER_DATA_FAILED'
export const FETCH_PARTNER_USER_FAILED = 'FETCH_PARTNER_USER_FAILED'
export const POST_CONSUMER_REGULATION_DATA = 'POST_CONSUMER_REGULATION_DATA'
export const POST_PARTNER_NEW_USER_DATA = 'POST_PARTNER_NEW_USER_DATA'
export const VERIFY_CPF = 'CONSUMER_VERIFY_CPF'
export const GENERAL_BASIC_DATA = 'GENERAL_BASIC_DATA'
export const GENERAL_BASIC_DATA_FAILED = 'GENERAL_BASIC_DATA_FAILED'

// PARTNERS
export const FETCH_PARTNERS_DATA = 'FETCH_PARTNERS_DATA'
export const FETCH_PARTNERS_FAILED = 'FETCH_PARTNERS_FAILED'

export const POST_PARTNERS_BRANCH_DATA = 'POST_PARTNERS_BRANCH_DATA'
export const POST_PARTNERS_BRANCH_FAILED = 'FETCH_PARTNERS_BRANCH_FAILED'

export const UPDATE_PARTNER_DATA = 'UPDATE_PARTNER_DATA'
export const UPDATE_PARTNER_FAILED = 'UPDATE_PARTNER_FAILED'

export const UPDATE_PARTNERS_BRANCH_DATA = 'UPDATE_PARTNERS_BRANCH_DATA'
export const UPDATE_PARTNERS_BRANCH_FAILED = 'UPDATE_PARTNERS_BRANCH_FAILED'

export const FETCH_CASHBACK_RULES_DATA = 'FETCH_CASHBACK_RULES_DATA'
export const FETCH_CASHBACK_RULES_FAILED = 'FETCH_CASHBACK_RULES_FAILED'

export const POST_PARTNER_CASHBACK_RULES_DATA =
	'POST_PARTNER_CASHBACK_RULES_DATA'
export const POST_PARTNER_CASHBACK_RULES_FAILED =
	'POST_PARTNER_CASHBACK_RULES_FAILED'

export const FETCH_PARTNER_CONSUMERS_DATA = 'FETCH_PARTNER_CONSUMERS_DATA'
export const FETCH_PARTNER_CONSUMERS_FAILED = 'FETCH_PARTNER_CONSUMERS_FAILED'

export const FETCH_CONSUMER_CASHBACK_RULES_DATA =
	'FETCH_CONSUMER_CASHBACK_RULES_DATA'
export const FETCH_CONSUMER_CASHBACK_RULES_FAILED =
	'FETCH_CONSUMER_CASHBACK_RULES_FAILED'

export const POST_PARTNER_CONSUMER_CASHBACK_RULES_DATA =
	'POST_PARTNER_CONSUMER_CASHBACK_RULES_DATA'
export const POST_PARTNER_CONSUMER_CASHBACK_RULES_FAILED =
	'POST_PARTNER_CONSUMER_CASHBACK_RULES_FAILED'

// STATEMENT
export const FETCH_STATEMENT_DATA = 'FETCH_STATEMENT_DATA'
export const FETCH_STATEMENT_DETAILS_DATA = 'FETCH_STATEMENT_DETAILS_DATA'
export const FETCH_STATEMENT_FAILED = 'FETCH_STATEMENT_FAILED'

// TIER
export const FETCH_TIER_DATA = 'FETCH_TIER_DATA'
export const FETCH_TIER_FAILED = 'FETCH_TIER_FAILED'

// CONSUMER WALLET
export const FETCH_CONSUMER_WALLET_DATA = 'FETCH_CONSUMER_WALLET_DATA'
export const FETCH_CONSUMER_WALLET_FAILED = 'FETCH_CONSUMER_WALLET_FAILED'

export const FETCH_CONSUMER_DATA = 'FETCH_CONSUMER_DATA'
export const POST_CONSUMER_DATA = 'POST_CONSUMER_DATA'
export const UPDATE_CONSUMER_DATA = 'UPDATE_CONSUMER_DATA'
export const FETCH_CONSUMER_DATA_BY_ID = 'FETCH_CONSUMER_DATA_BY_ID'
export const FETCH_CONSUMER_FAILED = 'FETCH_CONSUMER_FAILED'

export const FETCH_UNAUTH_USER = 'FETCH_UNAUTH_USER'

// PRODUCT
export const GET_PRODUCT = 'GET_PRODUCT'
export const GET_PRODUCT_FAILED = 'GET_PRODUCT_FAILED'
export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID'
export const GET_PRODUCT_BY_ID_FAILED = 'GET_PRODUCT_BY_ID_FAILED'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const UPDATE_PRODUCT_FAILED = 'UPDATE_PRODUCT_FAILED'
export const CALCULATED_PRODUCT_VALUE = 'CALCULATED_PRODUCT_VALUE'
export const CALCULATED_PRODUCT_VALUE_FAILED = 'CALCULATED_PRODUCT_VALUE_FAILED'
export const GET_CATEGORY = 'GET_CATEGORY'
export const GET_CATEGORY_FAILED = 'GET_CATEGORY_FAILED'
export const GET_BRAND = 'GET_BRAND'
export const GET_BRAND_FAILED = 'GET_BRAND_FAILED'
export const GET_MANUFACTURER = 'GET_MANUFACTUR'
export const GET_MANUFACTURER_FAILED = 'GET_MANUFACTUR_FAILED'
export const GET_FAMILY = 'GET_FAMILY'
export const GET_FAMILY_FAILED = 'GET_FAMILY_FAILED'

// INVOICES
export const FETCH_INVOICES_DATA = 'FETCH_INVOICES_DATA'
export const POST_INVOICES_DATA = 'POST_INVOICES_DATA'
export const FETCH_INVOICE_BY_ID = 'FETCH_INVOICE_BY_ID'
export const FETCH_INVOICE_BY_ID_FAILED = 'FETCH_INVOICE_BY_ID_FAILED'
export const FETCH_INVOICES_FAILED = 'FETCH_INVOICES_FAILED'

export const FETCH_SALES_HISTORY = 'FETCH_SALES_HISTORY'
export const FETCH_SALES_HISTORY_FAILED = 'FETCH_SALES_HISTORY_FAILED'

export const FETCH_INVOICES_DETAIL_PARTHER = 'FETCH_INVOICES_DETAIL_PARTHER'
export const FETCH_INVOICES_DETAIL_PARTHER_FAILED =
	'FETCH_INVOICES_DETAIL_PARTHER_FAILED'

// CAMPAIGN
export const FETCH_CAMPAIGN_LIST_DATA = 'FETCH_CAMPAIGN_LIST_DATA'
export const FETCH_CAMPAIGN_DATA = 'FETCH_CAMPAIGN_DATA'
export const FETCH_CAMPAIGN_PERFORMANCE_DATA = 'FETCH_CAMPAIGN_PERFORMANCE_DATA'
export const POST_CAMPAIGN_DATA = 'POST_CAMPAIGN_DATA'
export const PUT_CAMPAIGN_DATA = 'PUT_CAMPAIGN_DATA'
export const PUT_CAMPAIGN_DEACTIVATE = 'PUT_CAMPAIGN_DEACTIVATE'
export const FETCH_CAMPAIGN_FAILED = 'FETCH_CAMPAIGN_FAILED'
export const FETCH_CAMPAIGN_TYPE_DATA = 'FETCH_CAMPAIGN_TYPE_DATA'
export const FETCH_PRODUCT_TYPE_DATA = 'FETCH_PRODUCT_TYPE_DATA'
export const FETCH_SCORING_TYPE_DATA = 'FETCH_SCORING_TYPE_DATA'
export const FETCH_CAMPAIGN_STATUS_DATA = 'FETCH_CAMPAIGN_STATUS_DATA'

// TRANSACTIONS
export const FETCH_TRANSACTIONS_DATA = 'FETCH_TRANSACTIONS_DATA'
export const POST_TRANSACTION_DATA = 'POST_TRANSACTION_DATA'
export const FETCH_TRANSACTION_BY_ID = 'FETCH_TRANSACTION_BY_ID'
export const FETCH_TRANSACTION_TYPE = 'FETCH_TRANSACTION_TYPE'
export const FETCH_TRANSACTION_DETAILS = 'FETCH_TRANSACTION_DETAILS'
export const FETCH_TRANSACTION_CHANNEL_TYPES = 'FETCH_TRANSACTION_CHANNEL_TYPES'
export const FETCH_TRANSACTIONS_FAILED = 'FETCH_TIER_FAILED'
export const FETCH_TRANSACTIONS_BY_ID_FAILED = 'FETCH_TRANSACTIONS_BY_ID_FAILED'
export const FETCH_TRANSACTIONS_TYPE_FAILED = 'FETCH_TRANSACTIONS_TYPE_FAILED'
export const FETCH_TRANSACTIONS_CHANNE_TYPES_FAILED =
	'FETCH_TRANSACTIONS_CHANNE_TYPES_FAILED'

//MANAGEMENT - USER ACCESS GROUPS
export const FETCH_FAILED = 'FETCH_FAILED'
export const FETCH_USER_GROUP_DATA = 'FETCH_USER_GROUP_DATA'
export const FETCH_CLAIMS_GROUP_NEW_GROUP_DATA =
	'FETCH_CLAIMS_GROUP_NEW_GROUP_DATA'
export const FETCH_USER_OPTIONS_DATA = 'FETCH_USER_OPTIONS_DATA'
export const FETCH_REMOVE_USER_FROM_GROUP = 'FETCH_REMOVE_USER_FROM_GROUP'
export const POST_NEW_GROUP_DATA = 'POST_NEW_GROUP_DATA'
export const EDIT_NEW_GROUP_DATA = 'EDIT_NEW_GROUP_DATA'
export const POST_NEW_GROUP_DATA_FAILED = 'POST_NEW_GROUP_DATA_FAILED'

//MANAGEMENT - USER ACCESS
export const FETCH_ACCESS_DATA = 'FETCH_ACCESS_DATA'
export const FETCH_ACTIVE_ROLE_CLAIMS_DATA = 'FETCH_ACTIVE_ROLE_CLAIMS_DATA'
export const FETCH_GROUP_PERMISSIONS_DATA = 'FETCH_GROUP_PERMISSIONS_DATA'
export const FETCH_GROUP_PERMISSIONS_FAILED = 'FETCH_GROUP_PERMISSIONS_FAILED'
export const FETCH_GROUP_FAILED = 'FETCH_GROUP_FAILED'
export const FETCH_GROUP_DATA = 'FETCH_GROUP_DATA'
export const FETCH_UPDATE_GROUP_DATA = 'FETCH_UPDATE_GROUP_DATA'
export const POST_ACCESS_DATA = 'POST_ACCESS_DATA'
export const POST_ASSIGN_TO_ROLE = 'POST_ASSIGN_TO_ROLE'
export const POST_CLAIMS_DATA = 'POST_CLAIMS_DATA'
export const POST_ACCESS_TO_ROLE_DATA = 'POST_ACCESS_TO_ROLE_DATA'
export const UPDATE_ACCESS_DATA = 'UPDATE_ACCESS_DATA'
export const FETCH_ACCESS_FAILED = 'FETCH_ACCESS_FAILED'
export const FETCH_ACCESS_GROUPS = 'FETCH_ACCESS_GROUPS'

// SPONSOR
export const FETCH_SPONSOR_FAILED = 'FETCH_SPONSOR_FAILED'
export const FETCH_SPONSORS_DATA = 'FETCH_SPONSORS_DATA'
export const FETCH_SPONSOR_ID_DATA = 'FETCH_SPONSOR_ID_DATA'
export const POST_SPONSORS_DATA = 'POST_SPONSORS_DATA'
export const POST_SPONSORS_FAILED = 'POST_SPONSORS_FAILED'
export const FETCH_SPONSOR_LIST_DATA = 'FETCH_SPONSOR_LIST_DATA'
