import React, { useState } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import * as S from './styles'

//@ components
import Buttons from '../Buttons'
import CustomModal from '../../../Modal'
import SnackDefault from 'components/SnackDefault'
import Loader from 'components/Loader/Loader.jsx'
import { onConfirm } from './Functions'

const NoDivisionTab = ({ setEditMode, editMode, setOpenModalInfo, productData, grouper }) => {
	const [modalParameterization, setModalParameterization] = useState({
		open: false,
		title: '',
		subtitle: '',
	})
	const [loading, setLoading] = useState(false)
	const [snackStatus, setSnackStatus] = useState({
		open: false,
		severity: 'success',
		text: 'Operação realizada com sucesso !',
	})

	return (
		<S.Container>
			<CustomModal
				onClose={() => setModalParameterization({ open: false })}
				open={modalParameterization?.open}
				title={modalParameterization?.title}
				subtitle={modalParameterization?.subtitle}
				onConfirm={() => {
					onConfirm({ productData, setSnackStatus, setLoading, grouper })
					setEditMode(false)
					setModalParameterization({ open: false })
				}}
			/>
			<SnackDefault
				snackStatus={snackStatus}
				handleCloseSnack={() =>
					setSnackStatus(prevState => ({
						...prevState,
						open: false,
					}))
				}
			/>
			{loading && <Loader />}
			{editMode === true ? (
				<S.Content>
					<Buttons
						className="cancel"
						Icon="icon-bot_fechar"
						onClick={() => {
							setEditMode(false)
							setOpenModalInfo({
								open: false,
								tabs: null,
							})
						}}
					/>
					<Buttons
						className="save"
						Icon="icon-bot_salvar"
						disabled={!_.isEmpty(productData?.redemptionPoints)}
						onClick={() =>
							setModalParameterization({
								open: true,
								title: 'Deseja realmente alterar a parametrização',
								subtitle: 'desse benefício?',
							})
						}
					/>
				</S.Content>
			) : (
				<S.Content>
					<Buttons className="softBlueAction" Icon="icon-bot_editar" onClick={() => setEditMode(true)} />
				</S.Content>
			)}
		</S.Container>
	)
}

NoDivisionTab.propTypes = {
	grouper: PropTypes.string,
	editMode: PropTypes.bool,
	setEditMode: PropTypes.func,
	productData: PropTypes.object,
	setOpenModalInfo: PropTypes.func,
}

export default NoDivisionTab
