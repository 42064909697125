import React from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import ReCAPTCHA from 'react-google-recaptcha'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { CVALE, getThemeColorFromRequest, setThemeColorToLocalStorage } from '../../../redux/api/themeColors'

// @material-ui/core components
import { withStyles, createTheme, MuiThemeProvider } from '@material-ui/core/styles'
import InputAdornment from '@material-ui/core/InputAdornment'

// core components
import GridContainer from 'components/Grid/GridContainer.jsx'
import GridItem from 'components/Grid/GridItem.jsx'
import CustomInput from 'components/CustomInput/CustomInput.jsx'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'

import loginPageStyle from 'assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx' // icons
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react' // icons

// imgs
import Face from '@material-ui/icons/Face'
import axios from 'axios'
// CSS
import styles from '../style.module.scss'
import * as S from './styles.js'
import { login, logout } from 'redux/actions'
// Utils
import compose from 'utils/compose'
import { envs } from '../../../redux/api/windowServerData.js'
import Cookies from 'js-cookie'
import { getProgramTitle } from '../../../utils/utils.js'

function getFaviconEl() {
	return document.getElementById('favicon')
}

function CPFMaskCustom(props) {
	const { inputRef, ...other } = props

	return (
		<MaskedInput
			{...other}
			ref={ref => {
				inputRef(ref ? ref.inputElement : null)
			}}
			mask={rawValue =>
				rawValue.replace(/[^\d]+/g, '').length <= 11
					? [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
					: [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
			}
			placeholderChar={'\u2000'}
		/>
	)
}

const theme = createTheme({
	overrides: {
		MuiButton: {
			root: {
				color: 'rgba(0, 0, 0, 0.87)',
				padding: '6px 16px',
				fontSize: '0.875rem',
				minWidth: '34px',
				boxSizing: 'border-box',
				transition:
					'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
				lineHeight: `1.75`,
				fontWeight: 500,
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				borderRadius: '4px',
				textTransform: 'initial !important',
			},
		},
	},
	typography: {
		useNextVariants: true,
	},
})
class LoginPage extends React.Component {
	constructor(props) {
		super(props)
		// we use this to make the card to appear after the page has been rendered

		this.props.logout()

		this.state = {
			cardAnimaton: 'cardHidden',
			login: '',
			loginState: '',
			password: '',
			passwordState: '',
			submitted: false,
			corporationPartners: true,
			isVerified: false,
			visibilityPassword: false,
			userInactive: false,
			themeColor: '',
		}
	}

	async getTheme() {
		try {
			if (`${envs.REACT_APP_CVALE_IDENTITY}` === 'true') {
				this.setState({ themeColor: CVALE })
				return
			}

			const response = await getThemeColorFromRequest()
			const localTheme = await Cookies.get('themeColor')

			if (response?.data && !Object.is(JSON.stringify(response.data), localTheme)) {
				this.setState({ themeColor: response.data })
				setThemeColorToLocalStorage(response.data)
				this.handleFivicon(this.getUrl(response))
			} else if (localTheme) {
				const theme = JSON.parse(localTheme)
				this.setState({ themeColor: theme })
			}
		} catch (error) {
			console.error('Erro ao fazer parse do tema:', error)
		}
	}

	handleFivicon = _fivicon => {
		const favicon = getFaviconEl()
		console.log({ favicon: _fivicon })
		// favicon.href = `${_fivicon}`
		favicon && (favicon.href = `${_fivicon}`)
	}

	handleInactive = () => {
		this.setState({
			userInactive: false,
		})
	}

	change(event, stateName, type) {
		switch (type) {
			case 'login':
				if (this.verifyLength(event.target.value, 1)) {
					this.setState({
						[stateName + 'State']: 'success',
						[stateName]: event.target.value,
					})
				} else {
					this.setState({
						[stateName + 'State']: 'error',
						[stateName]: '',
					})
				}
				break
			case 'password':
				if (this.verifyLength(event.target.value, 1)) {
					this.setState({
						[stateName + 'State']: 'success',
						[stateName]: event.target.value,
					})
				} else {
					this.setState({
						[stateName + 'State']: 'error',
						[stateName]: '',
					})
				}
				break
			default:
				break
		}
	}

	validateFields = () => {
		if (!this.verifyLength(this.state.login, 1)) {
			this.setState({ loginState: 'error' })
		}
		if (!this.verifyLength(this.state.password, 1)) {
			this.setState({ passwordState: 'error' })
		}
	}

	submit = e => {
		e.preventDefault()
		this.setState({ submitted: true })
		const { login, password } = this.state
		let _login = login.replace(/[^\d]+/g, '')
		this.validateFields()
		if (_login && password && this.state.isVerified) {
			this.setState({
				userInactive: true,
			})
			this.props.login(_login, password)
			this.getTheme()
		}
	}
	// function that verifies if a string has a given length or not
	verifyLength(value, length) {
		return value.length >= length
	}

	verifyNumber(value) {
		const numberRex = new RegExp('^[0-9]+$')
		if (numberRex.test(value)) return true
		return false
	}

	getUrl(res) {
		if (res && res.data && res.data.favicon && res.data.favicon.url) {
			return res.data.favicon.url
		}
		return ''
	}

	componentDidMount() {
		if (envs.REACT_APP_CVALE_IDENTITY === 'true') {
			this.setState({ themeColor: CVALE })
		} else {
			this.getTheme()
		}
		// getThemeColorFromRequest().then(res => {
		// 	const theme = JSON.parse(Cookies.get('themeColor'))

		// 	console.log('themeColor', theme)
		// 	// console.log('res', res.data)

		// 	this.setState({ themeColor: theme })
		// 	// this.setState({ themeColor: envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : res.data })
		// 	// this.setState({ themeColor: envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : res.data })
		// 	// setThemeColorToLocalStorage(envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : res.data)
		// 	setThemeColorToLocalStorage(envs.REACT_APP_CVALE_IDENTITY === 'true' ? CVALE : res.data)
		// 	this.handleFivicon(this.getUrl(res))
		// })

		// we add a hidden class to the card and after 700 ms we delete it and the transition appears
		axios.get(`/ProgramRules/General/BasicData`).then(response => {
			this.setState({
				corporationPartners: response.data.hasCorporationPartners,
			})
		})
		this.timeOutFunction = setTimeout(
			function() {
				this.setState({ cardAnimaton: '' })
			}.bind(this),
			700,
		)
	}

	componentWillUnmount() {
		clearTimeout(this.timeOutFunction)
		this.timeOutFunction = null
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.logginFailed !== this.props.logginFailed) {
			if (this.props.logginFailed) {
				this.setState({
					login: '',
					password: '',
				})
			}
		}
	}

	handleVerify = e => {
		if (e) {
			this.setState({ isVerified: true })
		}
	}

	handleVisibilityPassword = () => {
		this.setState({ visibilityPassword: !this.state.visibilityPassword })
	}

	ColorLuminance = (hex, lum) => {
		// validate hex string
		hex = String(hex).replace(/[^0-9a-f]/gi, '')
		if (hex.length < 6) {
			hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
		}
		lum = lum || 0
		// convert to decimal and change luminosity
		var rgb = '#',
			c,
			i
		for (i = 0; i < 3; i++) {
			c = parseInt(hex.substr(i * 2, 2), 16)
			c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
			rgb += ('00' + c).substr(c.length)
		}
		return rgb
	}

	getButtonBackgrondColor() {
		if (
			this.state.themeColor &&
			this.state.themeColor.navigation &&
			this.state.themeColor.navigation.buttons &&
			this.state.themeColor.navigation.buttons.backgroundColor
		) {
			return this.state.themeColor.navigation.buttons.backgroundColor
		}
		return `#fff`
	}
	render() {
		const { t, classes, loggingIn, loggedIn, logginFailed } = this.props
		return (
			<div className={classes.container}>
				<DocumentTitle title={getProgramTitle(t('PAGE_TITLE_LOGIN'))} />
				<GridContainer justifyContent="center">
					<GridItem xs={12} sm={6} md={4}>
						<form name="form" onSubmit={this.submit}>
							<div login={this.state.login} className={classes[this.state.cardAnimaton] + ' ' + styles.card}>
								<div className={styles.cardShadow}>
									<div className={`${classes.textCenter} ${styles.cardHeader}`} color="success">
										<img
											src={(() => {
												if (
													this.state.themeColor &&
													this.state.themeColor.images &&
													this.state.themeColor.images.loginPage &&
													this.state.themeColor.images.loginPage.logo &&
													this.state.themeColor.images.loginPage.logo.url
												) {
													return this.state.themeColor.images.loginPage.logo.url
												}
												return ''
											})()}
											alt="logo"
											width={200}
											style={{
												width: '200px',
											}}
										/>
									</div>

									<div
										className={styles.cardHeaderTitle}
										style={{
											backgroundColor: (() => {
												if (this.state.themeColor && this.state.themeColor.structure && this.state.themeColor.structure.backgroundColor) {
													return this.state.themeColor.structure.backgroundColor
												}
												return '#fff'
											})(),
										}}
									>
										{t('TITLE_PARTNER_PORTAL')}
									</div>
								</div>
								{logginFailed && this.state.userInactive && logginFailed.code === 5 ? (
									<div className={styles.userInactive}>
										<p>
											O acesso está inativo.
											<br />
											Entre em contato com o administrador.
										</p>
										<div>
											<button onClick={this.handleInactive}>Voltar</button>
										</div>
									</div>
								) : (
									<>
										<div className={styles.cardBody}>
											<CustomInput
												id="login"
												name="login"
												labelText={t('FIELD_DOCUMENT_CPF')}
												error={this.state.loginState === 'error'}
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													inputComponent: CPFMaskCustom,
													value: this.state.login,
													onChange: event => this.change(event, 'login', 'login'),
													endAdornment: (
														<InputAdornment position="end">
															<Face className={classes.inputAdornmentIcon} />
														</InputAdornment>
													),
												}}
											/>
											<CustomInput
												id="password"
												name="password"
												labelText={t('LOGIN_PASSWORD')}
												error={this.state.passwordState === 'error'}
												formControlProps={{
													fullWidth: true,
												}}
												inputProps={{
													value: this.state.password,
													onChange: event => this.change(event, 'password', 'password'),

													type: `${this.state.visibilityPassword ? 'text' : 'password'}`,
													endAdornment: (
														<InputAdornment position="end">
															<button className={styles.visibilityBtn} onClick={this.handleVisibilityPassword}>
																{this.state.visibilityPassword ? (
																	<i className="icon-visualizar_password" />
																) : (
																	<i
																		className="icon-visualizar_password_fechado "
																		style={{
																			fontSize: '1.2em',
																		}}
																	/>
																)}
															</button>
														</InputAdornment>
													),
												}}
											/>
										</div>
										{logginFailed && logginFailed.code === 1 ? (
											<div
												style={{
													textAlign: 'center',
												}}
											>
												<Typography
													color="error"
													style={{
														fontWeight: 'bold',
													}}
												>
													{t('LOGIN_FAILED')}
												</Typography>
											</div>
										) : (
											<div
												style={{
													textAlign: 'center',
												}}
											>
												<Typography
													color="error"
													style={{
														fontWeight: 'bold',
													}}
												>
													{logginFailed && logginFailed.msg}
												</Typography>
											</div>
										)}
										{
											<ReCAPTCHA
												className={styles.recaptcha}
												// sitekey={envs.REACT_APP_RECAPTCHA_SITE_KEY}
												sitekey={envs.REACT_APP_RECAPTCHA_SITE_KEY}
												onChange={this.handleVerify.bind(this)}
											/>
										}
										<div className={styles.cardFooter}>
											{loggingIn && (
												<CircularProgress
													style={{
														color: germiniDefaultColors[0],
													}}
													color="inherit"
												/>
											)}
											<MuiThemeProvider theme={theme}>
												<S.BtnSubmit
													block
													backgroundColor={
														loggedIn || loggingIn || !this.state.isVerified ? this.getButtonBackgrondColor() + '80' : this.getButtonBackgrondColor()
													}
													backgroundColorOver={this.ColorLuminance(
														(() => {
															if (
																this.state.themeColor &&
																this.state.themeColor.navigation &&
																this.state.themeColor.navigation.buttons &&
																this.state.themeColor.navigation.buttons.backgroundColor
															) {
																return this.state.themeColor?.navigation?.buttons?.backgroundColor
															}
															return `#fff`
														})(),
														-0.5,
													)}
													color={(() => {
														if (
															this.state.themeColor &&
															this.state.themeColor.navigation &&
															this.state.themeColor.navigation.buttons &&
															this.state.themeColor?.navigation?.buttons?.textColor
														) {
															return this.state.themeColor?.navigation?.buttons?.textColor
														}
													})()}
													disabled={loggedIn || loggingIn || !this.state.isVerified}
													size="sm"
													type="submit"
													variant="contained"
												>
													{t('BUTTON_SEND')}
												</S.BtnSubmit>
											</MuiThemeProvider>
										</div>
										)
									</>
								)}
								<div className={classes[this.state.cardAnimaton]}>
									<a href="/auth/resetPassword" className={styles.buttonForget} disabled={loggingIn}>
										{t('LOGIN_FORGET_PASSWORD')}
									</a>
								</div>
								<div
									style={{
										display: 'block',
										zIndex: '5',
										float: 'left',
										color: '#fff',
										marginTop: '20px',
										fontSize: '0.8rem',
										opacity: '0.3',
									}}
								>
									{/* <span>{'Release: ' + `${envs.REACT_APP_GERMINI_BUILD_VERSION}`}</span> */}
									<span>{'Release: ' + `${envs.REACT_APP_GERMINI_BUILD_VERSION}`}</span>
								</div>
							</div>
						</form>
					</GridItem>
				</GridContainer>
			</div>
		)
	}
}

LoginPage.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
	const { loggingIn, loggedIn, logginFailed } = state.authentication

	return {
		loggingIn,
		loggedIn,
		logginFailed,
	}
}

const mapDispatchToProps = dispatch => bindActionCreators({ login, logout }, dispatch)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(loginPageStyle),
	withTranslation(),
)(LoginPage)
