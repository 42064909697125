import { Fade, Grid } from '@material-ui/core'
import { germiniDefaultColors } from 'assets/jss/material-dashboard-pro-react'
import GridContainer from 'components/Grid/GridContainer'
import GridItem from 'components/Grid/GridItem'
import { ButtonBack, ButtonConfirm } from 'components/NewButton/styles'
import TextInput from 'components/TextInput'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { withTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { ModalContainer, PaperContainer } from './styles'
import { PartnerValidatePartner } from '../../../../../../redux/api/partner'
import { yupResolver } from '@hookform/resolvers'
import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'
import Button from 'components/CustomButtons/Button.jsx'
const textType = [
	{
		text: 'Para prosseguir com a inativação é necessário informar a senha.',
		type: 'refund',
	},
]

export const validationSchema = Yup.object().shape({
	password: Yup.string().required('Senha obrigatória.'),
})

export function ModalConfirmPassword({
	className,
	closeByClick,
	error,
	maxWidth,
	minWidth,
	onCancel,
	onConfirm,
	open,
	parameter,
	password,
	shadow,
	t,
	text,
	subText,
	title = 'Confirmar',
	type,
}) {
	const { handleSubmit, control, errors, setError } = useForm({
		resolver: yupResolver(validationSchema),
	})
	const [displayText, setDisplayText] = useState({})
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	useEffect(() => {
		ModalType(type)
	}, [type, text, parameter, error])

	async function onSubmit(value) {
		let response = await PartnerValidatePartner({
			password: value.password,
		})

		if (response) {
			onConfirm()
		} else {
			setError('password', {
				type: 'manual',
				message: 'Senha inválida',
			})
		}
	}

	function ModalType(type) {
		if (type) {
			if (!text) {
				return textType.forEach((item) => {
					if (item.type === type) {
						setDisplayText(item.text)
					}
				})
			}
			return setDisplayText(text)
		}
		if (text) {
			return setDisplayText(text)
		}
		setDisplayText(
			text
				? text
				: 'Para prosseguir com a inativação é necessário informar a senha.',
		)
	}

	return (
		<ModalContainer
			open={open}
			className={className}
			onClose={closeByClick}
			aria-labelledby="alert-dialog-slide-title"
			aria-describedby="alert-dialog-slide-description"
			shadow={shadow}
			maxwidth={maxWidth}
			minwidth={minWidth}
			disableEnforceFocus
		>
			{open && (
				<Fade in={open} unmountOnExit>
					<PaperContainer
						padding={'0px 60px'}
						maxwidth={maxWidth}
						themeColor={themeColor}
					>
						<GridContainer
							xs={12}
							sm={12}
							style={{ margin: '50px 20px 30px 2px' }}
						>
							<GridItem
								xs={12}
								sm={12}
								style={{
									display: 'flex',
									justifyContent: 'center',
								}}
							>
								<i
									className="icon-alerta_modal"
									style={{
										color: germiniDefaultColors[11],
										fontSize: '80px',
									}}
								/>
							</GridItem>
						</GridContainer>
						<GridItem
							xs={12}
							sm={12}
							style={{ textAlign: 'center' }}
						>
							<div
								style={{ fontSize: '15px', fontWeight: 'bold' }}
							>
								{displayText}
							</div>
							<span>{subText}</span>
						</GridItem>
						<form
							style={{ width: '100%' }}
							onSubmit={handleSubmit(onSubmit)}
						>
							<GridContainer style={{ marginTop: '30px' }}>
								<Grid
									item
									xs={10}
									style={{
										position: 'relative',
										left: '45px',
									}}
								>
									<Controller
										as={
											<TextInput
												style={{ marginRight: '50px' }}
												label="Senha *"
												type="password"
												error={!!errors.password}
												helperText={
													errors.password &&
													errors.password.message
												}
											/>
										}
										defaultValue=""
										name="password"
										control={control}
									></Controller>
								</Grid>
							</GridContainer>
							<div
								style={{
									position: 'relative',
									left: '45px',
									color: '#F05B4F',
									fontSize: '0.80rem',
									fontWeight: '400',
									fontFamily:
										'Roboto, Helvetica, Arial, sans-serif',
								}}
							></div>
							<GridContainer
								style={{
									padding: '10px 0 0 20px',
									marginBottom: '50px',
									marginTop: '35px',
								}}
							>
								<GridItem
									xs={12}
									sm={12}
									style={{
										textAlign: 'center',
										display: 'flex',
										gap: '10px',
										alignItems: 'center',
										justifyContent: 'center',
									}}
								>
									<Button
										size="sm"
										color="greenBorderButtonColor"
										style={{ margin: 0 }}
										onClick={() => {
											onCancel()
										}}
									>
										Cancelar
									</Button>

									<Button
										size="sm"
										type="submit"
										color="greenButtonColor"
										style={{ margin: 0 }}
									>
										{title}
									</Button>
								</GridItem>
							</GridContainer>
						</form>
					</PaperContainer>
				</Fade>
			)}
		</ModalContainer>
	)
}

ModalConfirmPassword.propTypes = {
	children: PropTypes.element,
	open: PropTypes.bool,
	closeByClick: PropTypes.func,
	maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	minWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	className: PropTypes.string,
	padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	shadow: PropTypes.string,
	standardFocus: PropTypes.string,
	text: PropTypes.string,
	password: PropTypes.string,
	parameter: PropTypes.string,
	type: PropTypes.string,
	error: PropTypes.string,
	onConfirm: PropTypes.func,
	onCancel: PropTypes.func,
}

ModalConfirmPassword.defaultProps = {
	className: '',
	padding: '',
	shadow: '',
	standardFocus: 'no',
	children: null,
	open: false,
	closeByClick: null,
	onConfirm: null,
	maxWidth: '',
	minWidth: '',
	text: '',
	parameter: '',
	password: '',
	error: '',
	type: '',
	onCancel: null,
}

export default withTranslation()(ModalConfirmPassword)
