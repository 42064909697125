import React, { useState, useEffect } from 'react'
import * as _ from 'lodash'
import DocumentTitle from 'react-document-title'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import Pagination from '@material-ui/lab/Pagination'
import Skeleton from '@material-ui/lab/Skeleton'
import { Drafts, Mail } from '@material-ui/icons'
import { withStyles, Grid } from '@material-ui/core'

import Section from 'components/Section'
import NotificationModal from 'components/NotificationModal'
import RenderDescription from 'components/NotificationModal/RenderDescription'
import { BodyRender, NotificationSkeleton } from 'components/NotificationModal/styles'
import NoNotifications from 'components/NoNotifications/NoNotifications'

import defaultNotification from '../../assets/img/defaultNotification.jpg'

import * as Styled from './styles'

import { HideLoader, ShowLoader } from 'redux/actions'
import { GetPartnerAlerts, ReadAlert } from 'redux/api/alerts/alerts'
import { getThemeColorFromLocalStorage } from '../../redux/api/themeColors'
import { getProgramTitle } from '../../utils/utils'

function Notification() {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const [themeColor, setThemeColor] = useState()

	const [alerts, setAlerts] = useState([])
	const [loading, setLoading] = useState(false)
	const [showNotification, setShowNotification] = useState({
		loading: false,
		open: false,
	})
	const [pagination, setPagination] = useState({
		currentPage: 1,
		pageCount: 1,
		pageSize: 10,
		recordCount: 1,
	})

	const array = [1, 2, 3, 4, 5, 6]

	async function getAlerts(pagination) {
		try {
			dispatch(ShowLoader())
			setLoading(true)
			const { results, ...rest } = await GetPartnerAlerts(pagination)
			setPagination({ ...rest })
			setAlerts(
				results.slice(0, 6).map(item => ({
					...item,
					publishFormat: moment(item.publishDate)
						.locale('pt-br')
						.format('DD[|]MM[|]YYYY'),
				})),
			)
		} catch (error) {
		} finally {
			dispatch(HideLoader())
			setLoading(false)
		}
	}

	useEffect(() => {
		getAlerts()
	}, [])

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	const getColor = () => {
		return themeColor && themeColor.structure && themeColor.structure.backgroundColor && themeColor.structure.backgroundColor
	}

	async function readNotification(id) {
		try {
			setShowNotification(prevState => ({
				...prevState,
				loading: true,
				open: true,
			}))
			const response = await ReadAlert(id)
			setAlerts(prevState => prevState.map(item => (item.id === id ? { ...item, isRead: true } : item)))
			setShowNotification(prevState => ({
				...prevState,
				loading: false,
				...response,
				publishFormat: moment(response.publishDate)
					.locale('pt-br')
					.format('DD[ | ]MMM[ | ]YYYY'),
			}))
		} catch (error) {
			console.log('getNotification -> error', error)
		} finally {
			dispatch(HideLoader())
			setLoading(false)
		}
	}

	return (
		<>
			<DocumentTitle title={getProgramTitle()} />
			<Section title={t('MENU_NOTIFICATION')} />
			<Styled.CardsSection>
				<Styled.NotificationContainer container spacing={4}>
					{loading &&
						array.map(item => (
							<Styled.CardContainer colors={getColor()} key={item} item xs={12} sm={6} lg={4} container isread={false} img={defaultNotification}>
								<Skeleton width="100%" variant="rect" height={200}></Skeleton>
								<Skeleton width="100%" height={100}></Skeleton>
								<Skeleton width="100%" variant="rect" height={100}></Skeleton>
							</Styled.CardContainer>
						))}
					{!loading && alerts.length > 0 && (
						<>
							{!loading &&
								alerts.map(item => (
									<Styled.CardContainer
										key={item.id}
										colors={getColor()}
										item
										xs={12}
										sm={6}
										lg={4}
										container
										isread={item.isRead}
										img={item.imageUrl === 'string' || !item.imageUrl ? defaultNotification : item.imageUrl}
										onClick={() => readNotification(item.id)}
										className="card-container"
									>
										<Grid item xs={12} className="header-img"></Grid>
										<Grid item xs={12} className="read-column" container alignItems="center">
											{item.isRead ? (
												<>
													<Drafts></Drafts>
												</>
											) : (
												<>
													<Mail></Mail>
												</>
											)}
											<div>+ Leia mais</div>
										</Grid>
										<Grid item xs={12} className="text-content" container>
											<Grid item xs={12} container alignItems="center">
												<small className="date-small">{item.publishFormat}</small>
											</Grid>
											<Grid item xs={12} container alignItems="center" className="read-line" isread={item.isRead}>
												{item.title}
											</Grid>
										</Grid>
									</Styled.CardContainer>
								))}
							<Grid item xs={12} container className="pagination-container" justifyContent="flex-end">
								<Pagination
									count={pagination.pageCount}
									page={pagination.currentPage}
									onChange={(_, page) => {
										getAlerts(page)
									}}
									showFirstButton
									showLastButton
								/>
							</Grid>
						</>
					)}
				</Styled.NotificationContainer>
			</Styled.CardsSection>

			{!loading && !alerts.length && <NoNotifications />}

			<NotificationModal
				headerTitle="MENU_NOTIFICATION"
				width="900px"
				height="500px"
				headerIcon={false}
				closeByClick={() =>
					setShowNotification(prevState => ({
						...prevState,
						open: false,
					}))
				}
				onClose={() =>
					setShowNotification(prevState => ({
						...prevState,
						open: false,
					}))
				}
				open={showNotification.open}
				buttonTitle="BUTTON_CLOSE"
			>
				{showNotification.loading ? (
					<div>
						<NotificationSkeleton variant="rect" width="840px" height="40px"></NotificationSkeleton>
						<NotificationSkeleton variant="rect" width="840px" height="40px"></NotificationSkeleton>
						<NotificationSkeleton variant="rect" width="840px" height="305px"></NotificationSkeleton>
					</div>
				) : (
					<Grid container>
						<Grid item xs={12} className="date-render">
							<small>{showNotification.publishFormat}</small>
						</Grid>
						<Grid item xs={12} className="title-render">
							<b>{showNotification.title}</b>
						</Grid>
						<BodyRender item xs={12} className="body-render" minheight="30vh">
							<RenderDescription renderComp={showNotification.content}></RenderDescription>
						</BodyRender>
					</Grid>
				)}
			</NotificationModal>
		</>
	)
}

export default withStyles()(withStyles()(Notification))
