import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import GriTablow from './GriTablow.jsx'

import { Grid, Divider } from '@material-ui/core'

import { getThemeColorFromLocalStorage } from 'redux/api/themeColors'
import { Box, Stack, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { StandardColors } from '../../../generalTab/Functions/utils/index.jsx'
import { Container, IconTable } from './styles.js'
import Checkbox from '../../../../../Checkbox/index.jsx'

const Table = ({
	id,
	Item,
	labels,
	values,
	multiple,
	editMode,
	setDivision,
	selectedDivision,
	setSelectedDivision,
	setParameterization,
	selectedDivisionIdGen,
	setSelectedDivisionIdGen,
}) => {
	const { t } = useTranslation()
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	return (
		<Stack direction="row" width={'100%'}>
			<Tooltip title={t(labels[0])} placement="top" arrow>
				<Box borderRadius="8px 0px 0px 8px" width="6px" bgcolor={StandardColors[labels[0]]} />
			</Tooltip>
			<Container>
				<Grid container spacing={0} style={{ padding: '5px 0' }}>
					<Grid container item xs={12} spacing={0}>
						<GriTablow labels={[labels[0], labels[1], labels[2]]} values={[values[0], values[1], values[2]]} />
					</Grid>
					<Divider style={{ width: '96%', margin: '0 8px' }} />
					<Grid container item xs={12} spacing={0}>
						<GriTablow labels={[labels[3], labels[4], labels[5]]} values={[values[3], values[4], values[5]]} />
					</Grid>
				</Grid>
			</Container>
			<Tooltip title={t('Parametrizar')} placement="top" arrow>
				<IconTable
					themeColor={themeColor}
					bgColor={multiple ? '#8D8D8D' : '#f2f2f2'}
					disabled={!editMode}
					onClick={() => {
						if (!multiple && editMode) {
							setDivision(Item)
							setParameterization(prev => ({
								...prev,
								show: true,
								isMultiple: false,
								type: 'points',
							}))
						}
					}}
				>
					{multiple ? (
						<Checkbox
							type="checkbox"
							disabled={!editMode}
							checked={selectedDivision?.includes(values[0])}
							onChange={() => {
								if (selectedDivision?.includes(values[0])) {
									setSelectedDivision(selectedDivision?.filter(item => item !== values[0]))
									setSelectedDivisionIdGen(selectedDivisionIdGen?.filter(item => item !== id))
								} else {
									setSelectedDivision([...selectedDivision, values[0]])
									setSelectedDivisionIdGen([...selectedDivisionIdGen, id])
								}
							}}
						/>
					) : (
						<i className="icon-bot_parametrizar" />
					)}
				</IconTable>
			</Tooltip>
		</Stack>
	)
}

Table.propTypes = {
	id: PropTypes.string,
	Item: PropTypes.object,
	labels: PropTypes.array,
	values: PropTypes.array,
	multiple: PropTypes.bool,
	history: PropTypes.object,
	setDivision: PropTypes.func,
	selectedDivision: PropTypes.array,
	setSelectedDivision: PropTypes.func,
	setParameterization: PropTypes.func,
	editMode: PropTypes.bool,
	setSelectedDivisionIdGen: PropTypes.func,
	selectedDivisionIdGen: PropTypes.array,
}

export default Table
