import { useState } from 'react'
import {
	getAdditionalModules,
	getAddressByZipCode,
	getAddressCountryList,
	getAddressUFList,
	getAllBusinessDivision,
	getCityFromState,
	getEventsList,
	getMarketingAreaList,
	getParentList,
	getSegmentationsList,
} from '../Functions'
import { PartnerService } from '../../Services'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Cookies from 'js-cookie'

export default function usePartnerProfile() {
	const tabLabels = ['Dados da empresa', 'Divisão de negócio', 'Documentos']
	// const partnerId = String(localStorage.getItem('partnerId'))
	const partnerId = String(Cookies.get('partnerId'))

	const partnerService = new PartnerService()
	const { t } = useTranslation()
	const methods = useForm()

	const [selectedItem, setSelectedItem] = useState(0)
	const [isEditData, setIsEditData] = useState(false)
	const [partnerData, setPartnerData] = useState()
	const [isLoadingData, setIsLoadingData] = useState(false)
	const [partnerInfos, setPartnerInfos] = useState({
		partnerEvents: [],
		partnerSegmentations: [],
		marketingArea: [],
		countryList: [],
		addressUFList: [],
		// addressCountry: [],
		addressCityList: [],
		additionalModules: [],
		segmentsList: [],
		originsList: [],
		parentsList: [],
	})
	const [customFieldsForm, setCustomFieldsForm] = useState({
		partnerEvents: [],
		partnerSegments: [],
		isMEI: false,
		isIEFree: false,
		useDefaultConvertion: false,
		additionalModules: [],
	})

	const [businessDivisionData, setBusinessDivisionData] = useState({
		typeOfDivision: null,
		isEditBusinessDivision: false,
		isNewBusinessDivision: false,
		divisionsList: [],
		divisionDetails: null,
		divisionSegment: null,
		address: [],
	})

	const [selectedState, setSelectedState] = useState(null)
	const [zipCode, setZipCode] = useState(null)

	const onTab = {
		0: 'business-data-form',
		1: 'business-division-form',
		2: 'document-data-form',
	}

	async function getPartnerData() {
		const response = await partnerService.getPartnerData(partnerId)
		if (response) {
			setPartnerData(response)
			updateCustomFieldsForm('isMEI', response?.isMEI)
			updateCustomFieldsForm('isIEFree', response?.isIEFree)
			updateCustomFieldsForm('useDefaultConvertion', response?.useDefaultConvertionRate)
		}
		setIsLoadingData(false)
	}

	/////////////////////////   Get all infos   //////////////////////////////
	async function getPartnerInfos() {
		return new Promise(async resolve => {
			const countryList = await getAddressCountryList()

			const [
				segmentationsListData,
				eventsListData,
				marketingAreaData,
				addressCountryData,
				addressUFData,
				additionalModulesData,
				businessDivisionData,
				parentListData,
			] = await Promise.all([
				getSegmentationsList(),
				getEventsList(),
				getMarketingAreaList(),
				getAddressCountryList(),
				getAddressUFList(countryList),
				getAdditionalModules(),
				getAllBusinessDivision(updateBusinessDivisionData),
				getParentList(),
			])

			setPartnerInfos(prevState => ({
				...prevState,
				partnerSegmentations: segmentationsListData?.result,
				segmentsList: segmentationsListData?.data,
				partnerEvents: eventsListData?.result,
				originsList: eventsListData?.data,
				marketingArea: marketingAreaData,
				countryList: addressCountryData,
				addressUFList: addressUFData,
				additionalModules: additionalModulesData,
				parentsList: parentListData,
			}))

			updateBusinessDivisionData('divisionsList', businessDivisionData)
			resolve()
		})
	}

	function updateCustomFieldsForm(key, value) {
		setCustomFieldsForm(prev => {
			return {
				...prev,
				[key]: value,
			}
		})
	}

	function updateBusinessDivisionData(key, value) {
		setBusinessDivisionData(prev => {
			return {
				...prev,
				[key]: value,
			}
		})
	}

	async function getCityList() {
		if (!selectedState) return
		const data = await getCityFromState(selectedState)

		setPartnerInfos(prevState => ({
			...prevState,
			addressCityList: data,
		}))
	}

	async function getAddress() {
		const data = await getAddressByZipCode(zipCode)
		if (data) {
			setBusinessDivisionData(prevState => ({
				...prevState,
				divisionDetails: {
					...prevState.divisionDetails,
					address: data,
				},
			}))
		}
		return []
	}
	async function getAddressComponent(zipCode) {
		const data = await getAddressByZipCode(zipCode)

		return data ?? []
	}

	function reloadFormsData() {
		setIsEditData(false)
		updateBusinessDivisionData('typeOfDivision', null)
		getPartnerInfos()
		setIsLoadingData(false)
		setBusinessDivisionData(prevState => ({
			...prevState,
			isEditBusinessDivision: false,
			isNewBusinessDivision: false,
		}))
	}

	function selectDivisionDetails(item) {
		updateBusinessDivisionData('isEditBusinessDivision', true)
		updateBusinessDivisionData('divisionDetails', item)
		updateBusinessDivisionData('typeOfDivision', item?.segmentationType?.value)
	}

	return {
		getPartnerInfos,
		getPartnerData,
		getCityList,
		getAddress,
		selectedItem,
		setSelectedItem,
		isEditData,
		setIsEditData,
		partnerData,
		partnerInfos,
		customFieldsForm,
		businessDivisionData,
		selectedState,
		setSelectedState,
		zipCode,
		setZipCode,
		onTab,
		tabLabels,
		methods,
		t,
		updateBusinessDivisionData,
		updateCustomFieldsForm,
		setBusinessDivisionData,
		partnerId,
		reloadFormsData,
		isLoadingData,
		setIsLoadingData,
		getAddressComponent,
		selectDivisionDetails,
	}
}
