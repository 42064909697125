import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'

// @ Styles
import * as S from './styles'

// @ Mui
import { FormLabel, Grid, InputAdornment } from '@mui/material'

//@ components
import { CssTextField } from '../../../../../../Filter/styles'
import GenerationPoints from '../../../../../../generalTab/components/Configuration/GenerationPoints'

import { Symbol } from '../../Functions'
import NumberFormattedPoints from '../../../../../../../../../../../../../components/NumberFormatCustom/NumberFormattedPoints'

const Configuration = ({
	errors,
	control,
	setOpen,
	viewMode,
	productData,
	typeOfRedeem,
	programRules,
	redemptionPoints,
	fetchCalculation,
	calculationMemory,
	divisionProductData,
	parameterizationGenerationPoints,
}) => {
	const { t } = useTranslation()

	const adornmentPosition = typeOfRedeem === '3' ? 'start' : 'end'

	useEffect(() => {
		fetchCalculation('', typeOfRedeem)
	}, [divisionProductData])

	return (
		<S.CardsContent>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={3}>
					<FormLabel>
						<span className="FormLabel">{t('Valor*')}</span>
						<Controller
							name="redemptionPoints"
							control={control}
							defaultValue={redemptionPoints}
							render={({ onChange, value }) => (
								<CssTextField
									variant="outlined"
									size="small"
									onChange={e => {
										fetchCalculation(e.target.value)
										onChange(e.target.value)
									}}
									disabled={viewMode}
									value={value}
									error={errors?.redemptionPoints}
									helperText={errors?.redemptionPoints?.message}
									InputProps={{
										[adornmentPosition + 'Adornment']: (
											<InputAdornment position={adornmentPosition}>
												<S.CustomSymbol disabled={viewMode}>{Symbol(typeOfRedeem)}</S.CustomSymbol>
											</InputAdornment>
										),

										max_val: 999999,
										maxLength: 8,
										inputComponent: NumberFormattedPoints,
									}}
								/>
							)}
						/>
					</FormLabel>
				</Grid>
				<Grid item xs={12} sm={9}>
					<GenerationPoints
						setOpen={setOpen}
						viewMode={viewMode}
						productData={productData}
						programRules={programRules}
						typeOfRedeem={typeOfRedeem}
						calculationMemory={calculationMemory}
						parameterization={parameterizationGenerationPoints}
					/>
				</Grid>
			</Grid>
		</S.CardsContent>
	)
}

Configuration.propTypes = {
	setOpen: PropTypes.func,
	errors: PropTypes.object,
	viewMode: PropTypes.bool,
	control: PropTypes.object,
	productData: PropTypes.object,
	typeOfRedeem: PropTypes.string,
	programRules: PropTypes.object,
	fetchCalculation: PropTypes.func,
	redemptionPoints: PropTypes.number,
	calculationMemory: PropTypes.array,
	divisionProductData: PropTypes.object,
	parameterizationGenerationPoints: PropTypes.object,
}

Configuration.defaultProps = {
	typeOfRedeem: '3',
}

export default Configuration
