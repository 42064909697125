import { PartnerService } from '../../Services'

export default async function getAdditionalModules() {
	const partnerService = new PartnerService()
	const response = await partnerService.getAdditionalModules()

	const result = []

	response.results.forEach(item => {
		result.push({
			...item,
		})
	})

	return result ?? []
}
