import React from 'react'
import styled from 'styled-components'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import { styled as MUistyled } from '@mui/material/styles'

export const CustomCard = styled.div`
	.MuiCard-root {
		width: 161px;
		height: 100%;
		padding: 15px;
		background-color: #f5f5f5;
		box-shadow: none;

		opacity: ${({ disable }) => (disable ? '0.5' : '1')};
		&:hover {
			cursor: ${({ disable }) => (disable ? 'default' : 'pointer')};
			background-color: ${({ themeColor, disable }) => (disable ? '#f5f5f5' : themeColor?.navigation?.buttons?.backgroundColor)};
			.MuiIcon-root {
				color: ${({ disable }) => (disable ? '#6a7e80' : '#fff')};
			}
			h1 {
				color: ${({ disable }) => (disable ? '#6a7e80' : '#fff')};
			}
		}
	}

	.MuiIcon-root {
		height: 100%;
		width: 100%;
	}
`

export const IconInformation = styled.div`
	.MuiIcon-root {
		height: 100%;
	}
`

export const HeaderIcons = styled.div`
	display: flex;
	justify-content: space-between;
	padding-left: 5px;
`
export const CustomIcon = styled.div`
	.MuiIcon-root {
		color: ${({ themeColor, disable }) => (disable ? '#6a7e80' : themeColor?.visual?.icons?.color)};
		font-size: 30px;
		transform: rotateY(180deg);
	}
`
export const CardTitle = styled.h1`
	font: normal normal normal 15px/20px Roboto;
	color: #333333;
	opacity: 1;
`

export const BlackTooltip = MUistyled(({ className, ...props }) => <Tooltip {...props} arrow classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: theme.palette.common.black,
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: theme.palette.common.black,
	},
}))

export const NoModules = styled.div`
	display: flex;
	flex-direction: column;

	i {
		color: #b4b4b4;
		display: flex;
		font-size: 30px;
		justify-content: center;
	}

	span {
		color: #b4b4b4;
		display: flex;
		font-weight: 900;
		font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
		justify-content: center;
		margin: 25px 0 12px;
	}
`
