import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import Labelitem from '../../../../Labelitem'
import { Controller, useFormContext } from 'react-hook-form'
import Select from '../../../../Select'
import { useTranslation } from 'react-i18next'
import { compareMarketingArea, getLabelByElementId, validateValue } from '../../../../../Functions'
import TextInput from '../../../../TextInput'
import { getByElementId } from '../../../../../Functions/getLabelByElementId'

export default function Infos({ isEditData, partnerInfos, divisionData, parentDivision }) {
	const { t } = useTranslation()
	const { control, errors } = useFormContext()
	const [marketingArea, setMarketingArea] = useState(null)

	useEffect(() => {
		if (partnerInfos?.marketingArea && divisionData?.marketingArea) {
			setMarketingArea(compareMarketingArea(partnerInfos?.marketingArea, divisionData?.marketingArea))
		}
	}, [partnerInfos, divisionData])

	return (
		<Fragment>
			<Grid container spacing={2}>
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem
							disabled
							required
							label="Divisão de negócio Pai"
							value={validateValue(getLabelByElementId(divisionData?.parentId || divisionData?.partnerId, parentDivision))}
						/>
					) : (
						<Fragment>
							<Labelitem label="Divisão de negócio Pai" />
							<Controller
								control={control}
								name="businessDivisionParent"
								defaultValue={getByElementId(divisionData?.parentId || divisionData?.partnerId, parentDivision)}
								render={({ onChange, value }) => (
									<Select
										name="businessDivisionParent"
										error={errors?.businessDivisionParent}
										helperText={t(errors?.businessDivisionParent?.message)}
										value={value}
										options={parentDivision ?? []}
										onChange={onChange}
									/>
								)}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={3} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Identificador" value={validateValue(divisionData?.identifier)} />
					) : (
						<Fragment>
							<Labelitem label="Identificador" />
							<Controller
								control={control}
								name="businessDivisionID"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessDivisionID}
										helperText={t(errors?.businessDivisionID?.message)}
										fullWidth
										id="businessDivisionID"
										name="businessDivisionID"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={12}
										inputProps={{
											maxLength: 12,
										}}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={divisionData?.identifier}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Nome do centro de custo" value={validateValue(divisionData?.name)} />
					) : (
						<Fragment>
							<Labelitem label="Nome do centro de custo" />
							<Controller
								control={control}
								name="businessDivisionName"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessDivisionName}
										helperText={t(errors?.businessDivisionName?.message)}
										fullWidth
										id="businessDivisionName"
										name="businessDivisionName"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={55}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={divisionData?.name}
							/>
						</Fragment>
					)}
				</Grid>
				<Grid item lg={5} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Descrição" value={validateValue(divisionData?.description)} />
					) : (
						<Fragment>
							<Labelitem label="Descrição" />
							<Controller
								control={control}
								name="businessDivisionDescription"
								render={({ onChange, value }) => (
									<TextInput
										error={errors?.businessDivisionDescription}
										helperText={t(errors?.businessDivisionDescription?.message)}
										fullWidth
										id="businessDivisionDescription"
										name="businessDivisionDescription"
										value={value}
										disabled={false}
										onChange={onChange}
										variant="outlined"
										margin="dense"
										maxSize={60}
										inputProps={{
											maxLength: 60,
										}}
										style={{
											backgroundColor: '#fff',
										}}
									/>
								)}
								defaultValue={divisionData?.description}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item lg={4} sm={12}>
					{!isEditData ? (
						<Labelitem disabled required label="Área de marketing" value={validateValue(t(marketingArea?.label))} />
					) : (
						<Fragment>
							<Labelitem label="Área de marketing" />
							<Controller
								control={control}
								name="businessDivisionMarketingArea"
								render={({ onChange, value }) => (
									<Select
										name="businessDivisionMarketingArea"
										error={errors?.businessDivisionMarketingArea}
										helperText={t(errors?.businessDivisionMarketingArea?.message)}
										value={value}
										options={partnerInfos?.marketingArea ?? []}
										onChange={onChange}
									/>
								)}
								defaultValue={{
									label: marketingArea?.label,
									value: marketingArea?.label,
								}}
							/>
						</Fragment>
					)}
				</Grid>
			</Grid>
		</Fragment>
	)
}

Infos.propTypes = {
	isEditData: PropTypes.bool,
	partnerInfos: PropTypes.object,
	divisionData: PropTypes.object,
	updateBusinessDivisionData: PropTypes.func,
	typeDivision: PropTypes.string,
	parentsList: PropTypes.array,
	parentDivision: PropTypes.array,
}
