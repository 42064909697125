import { Search } from '@material-ui/icons'
import styled from 'styled-components/macro'

const getHLCol = (props) => {
	if (
		props.themeColor &&
		props.themeColor.titles &&
		props.themeColor.titles.highlight &&
		props.themeColor.titles.highlight.textColor
	) {
		return props.themeColor.titles.highlight.textColor + ' !important'
	}
	return `#fff`
}
export const Container = styled.div`
	.MuiTableCell-body {
		padding: 0px 15px !important;
	}
	.rdtPicker .dow,
	.rdtPicker th.rdtSwitch,
	.rdtPicker th.rdtNext,
	.rdtPicker th.rdtPrev,
	.rdtPicker .rdtTimeToggl {
		color: ${(props) => getHLCol(props)};
	}

	border-bottom: 1px solid #c8c8c8;
	.wivQC .MuiTableBody-root tr td {
		padding: 0 !important;
	}
	.MuiTablePagination-caption {
		display: none;
	}
`

export const IconContainer = styled.i`
	display: flex;
	align-items: center;
`

export const IconCOP = styled(Search)`
	width: 25;
	height: 25;
	font-size: 20px;
	transform: translateY(0.8px);
`

export const IconInfo = styled.i`
	font-size: 21px;
	cursor: pointer;
	color: #ff9800;
	opacity: 1;
`
