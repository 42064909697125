/* eslint-disable */
import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import propTypes from 'prop-types'
import compose from 'utils/compose'

class CustomRequiredField extends React.Component {
	constructor(props) {
		super(props)
	}
	render() {
		const { t, classes, disabled, message, value } = this.props

		return (
			<Fragment>
				{t(value)}&nbsp;
				{!disabled && (
					<label style={{ color: '#F05B4F' }}>
						{message ? message : '*'}
					</label>
				)}
			</Fragment>
		)
	}
}

CustomRequiredField.propTypes = {
	value: propTypes.string.isRequired,
}

export default compose(withTranslation())(CustomRequiredField)
