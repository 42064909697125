import * as Yup from 'yup'

export const DefaultProductData = {
	standardAdministrationFeeApplied: '',
	standardGrossValue: '',
	standardAccumulatePoints: '',
	customAdministrationFeeApplied: '',
	customGrossValue: '',
	customAccumulatePoints: '',
	calculationMemory: {
		conversionRate: '',
		administrationFee: '',
		valueToConvert: '',
		multiplierFactor: '',
	},
}

export const StandardColors = {
	Costcenter: '#3EC450',
	BusinessUnit: '#FB9006',
	Branch: '#7E6BB9',
	Department: '#1BBED3',
}

export const Schema = Yup.object().shape({
	typeOfRedeem: Yup.object()
		.nullable()
		.required('Campo obrigatório'),

	redemptionPoints: Yup.string()
		.nullable()
		.when('typeOfRedeem', {
			is: value => value?.key === '3',
			then: Yup.string().required('Campo obrigatório'),
		}),
	selectedDivisionIdRed: Yup.array().nullable(),
	pametrizationName: Yup.string()
		.nullable()
		.when('selectedDivisionIdRed', {
			is: value => value?.length > 0,
			then: Yup.string().required('Campo obrigatório'),
		}),
})
