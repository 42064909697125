import { GetTypeOfGrouper } from '../../../../../../../../../redux/api/partner'

export const GetTypeGrouper = async setGrouper => {
	await GetTypeOfGrouper()
		.then(response => {
			if (response?.status === 200) {
				const groupers = []

				response.data.forEach(item => {
					groupers.push({
						key: item?.key,
						label: item?.value,
						value: item?.key,
					})
				})

				setGrouper(prev => {
					return [...prev, ...groupers]
				})
			}
		})
		.catch(error => {
			console.log(error)
		})
}
