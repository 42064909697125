import _ from 'lodash'
import { GetTypeOfCashback } from 'redux/api/productPage'
import { numberFormatText, numberFormatTextPercentage } from 'utils/utils'
import { CalculateValue } from 'redux/api/product'
import Cookies from 'js-cookie'
import { GetDivisionByGrouper, UpdateGrouperByDivision } from '../../../../../../../../../../redux/api/Grouper'
import { envs } from '../../../../../../../../../../redux/api/windowServerData'

export const GetTypePoints = async setGenerationType => {
	try {
		const res = await GetTypeOfCashback()
		if (res && typeof res === 'object') {
			const newRes =
				res
					?.map(item => {
						if (item?.key === '0') {
							return null
						}
						if (item?.key === '1') {
							return {
								...item,
								value: 'Não gerar cashback',
							}
						}

						if (item?.key === '2') {
							return {
								...item,
								value: 'Padrão',
							}
						}

						if (item?.key === '3') {
							return {
								...item,
								value: 'Percentual',
							}
						}

						if (item?.key === '4') {
							return null
						}

						return item
					})
					?.filter(item => item !== null) || []

			setGenerationType(newRes.filter(item => item) || [])
		}
	} catch (error) {
		console.log(error)
	}
}

export const FormatPoints = value => {
	return numberFormatText(
		value,
		localStorage.getItem('programCurrencySymbol')
			? localStorage.getItem('programCurrencySymbol') + ' '
			: // : envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
			envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL + ' ',
		0,
	)
}

export const FormatMoney = value => {
	return numberFormatText(
		value,
		// localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		localStorage.getItem('currencySymbol') ? localStorage.getItem('currencySymbol') + ' ' : envs.REACT_APP_CURRENCY_SYMBOL + ' ',
		2,
	)
}

export const formatPercent = value => {
	return numberFormatTextPercentage(value, '', 2)
}

export const handleProductDataUpdate = (divisionProductData, setProductDataState, setValue) => {
	if (divisionProductData) {
		const { typeOfCashback, parameterizationGenerationPoints } = divisionProductData

		setValue('typeOfCashback', typeOfCashback)
		setProductDataState({
			parameterization: parameterizationGenerationPoints,
		})
	}
}

export const Symbol = type => {
	try {
		switch (type) {
			case '4':
				// return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
				return localStorage.getItem('programCurrencySymbol') || envs.REACT_APP_PROGRAM_CURRENCY_SYMBOL
			case '2':
				return '%'
			default:
				return 'SD$'
		}
	} catch (error) {
		return 'SD$'
	}
}

export const handleCalculateValue = async (productId, price, type, value, typeOfRedeem, redeemValue) => {
	try {
		const res = await CalculateValue(productId, price, type, value, typeOfRedeem, redeemValue)
		if (res?.success && res?.data) {
			const { generationPoints } = res.data
			return generationPoints
		}

		return null
	} catch (error) {
		console.log(error)
	}
}

export const GetBranchesPlatforms = async ({ Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, setDefaultValues, setLoading, id }) => {
	setLoading(true)
	try {
		const branchesPlatforms = await GetDivisionByGrouper(Name, TypeOfBenefit, ProductId, PartnerId, PageSize, Page, id)
		if (branchesPlatforms?.status === 200) {
			const { data } = branchesPlatforms
			setDefaultValues({
				...data,
				page: data?.currentPage,
			})

			return data?.results ?? []
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

export const ParameterizationByDivision = async ({
	productData,
	goToBack,
	grouper,
	setLoading,
	typeOfCashback,
	cashbackPercentual,
	setSnackStatus,
	divisionId,
}) => {
	setLoading(true)

	const partnerId = Cookies.get('partnerId')
	const obj = {
		partnerId: partnerId,
		typeOfBenefit: '2',
		typeOfGrouper: grouper || '',
		typeOfBenefitCashback: 'ByDivision',
		typeOfCashback: typeOfCashback?.key,
		cashbackPercentual: cashbackPercentual,
		divisionId: divisionId,
	}

	try {
		const res = await UpdateGrouperByDivision(productData?.id, obj)

		if (res?.success) {
			setSnackStatus({
				open: true,
				text: 'Operação realizada com sucesso!',
				severity: 'success',
			})

			setTimeout(() => {
				goToBack()
			}, 900)

			return
		}

		if (!res?.success) {
			setSnackStatus({
				open: true,
				text: res?.errors[0]?.message,
				severity: 'error',
			})
		}
	} catch (error) {
		console.log(error)
	} finally {
		setLoading(false)
	}
}

// fromatr por tipo de cashback
const formatByTypeOfCashback = item => {
	if (item.typeOfCashback?.key === '4') {
		return FormatMoney(item?.cashbackPercentual || '0')
	}

	if (item.typeOfCashback?.key === '3') {
		return formatPercent(item?.cashbackPercentual || '0')
	}
}

export function formatLabelsAndValuesCashback(item, t) {
	const labels = [
		t(item?.segmentationType?.value) || '',
		t('Forma de cashback'),
		item?.typeOfCashback?.key >= '1' ? t('Valor') : null,
		t('Cashback a acumular'),
	].filter(item => item !== null)

	const values = [
		item?.name || '-',
		item?.typeOfCashback ? t(formatLabelCashback(item?.typeOfCashback)) : '-',
		formatByTypeOfCashback(item),
		item?.typeOfCashback?.key >= '1' ? FormatMoney(item?.parametrizationCashback?.customCashbackValue || 0) : null,
	].filter(item => item !== null)

	return { labels, values }
}

const formatLabelCashback = typeOfCashback => {
	if (!_?.isEmpty(typeOfCashback)) {
		if (typeOfCashback?.key === '1') {
			return 'Não gera cashback'
		}

		if (typeOfCashback?.key === '2') {
			return 'Padrão'
		}

		if (typeOfCashback?.key === '3') {
			return 'Percentual'
		}
		return typeOfCashback?.value
	}
}
