import styled from 'styled-components/macro'
import Button from '@material-ui/core/Button'
import { ColorLuminance } from '../../redux/api/themeColors'

export const ButtonCustom = styled(Button)`
	font-size: 13px;
	color: ${({ color }) => color};

	&.MuiButton-root {
		background-color: ${({ greenButtonColor, themeColor, disabled }) =>
		greenButtonColor === 'greenButtonColor' && (disabled ? '#c8c8c8' : themeColor?.navigation?.buttons?.backgroundColor)} !important;
		color: ${({ greenButtonColor, themeColor }) =>
		greenButtonColor === 'greenButtonColor'
			? themeColor?.navigation?.buttons?.textColor
			: greenButtonColor === 'greenBorderButtonColor' && themeColor?.navigation?.buttons?.backgroundColor} !important;
		border: ${({ greenButtonColor, themeColor, disabled }) =>
		(greenButtonColor === 'greenBorderButtonColor' || greenButtonColor === 'greenButtonColor') &&
		`1px solid ${disabled ? '#c8c8c8' : themeColor?.navigation?.buttons?.backgroundColor}`} !important;
		box-shadow: none;

		:hover {
			background-color: ${({ greenButtonColor, themeColor }) =>
		greenButtonColor === 'greenButtonColor'
			? ColorLuminance(themeColor?.navigation?.buttons?.backgroundColor, -0.5)
			: greenButtonColor === 'greenBorderButtonColor' && themeColor?.navigation?.buttons?.backgroundColor} !important;
			color: white !important;
		}
	}
`
