import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
    germiniDefaultColors
} from "assets/jss/material-dashboard-pro-react.jsx";

const styles = theme => ({
    progress: {
        textAlign: 'center',
        position: 'fixed',
        left: '50%',
        top: '50%',
        overflow: 'hidden',
        zIndex: 5500,
        color: "#fff"
    }
});

function Loader(props) {
    const { classes } = props;
    return (
        <div style={{ backgroundColor: 'rgba(0, 0, 0, 0.4)', position: 'fixed', left: 0, right: 0, top: 0, bottom: 0, zIndex: 5000 }}>
            <CircularProgress className={classes.progress} size="100px" />
        </div>
    );
}

Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Loader);
