import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import Alert from 'react-bootstrap-sweetalert'
import DocumentTitle from 'react-document-title'

import * as _ from 'lodash'

// UTILS
import compose from 'utils/compose'

import withStyles from '@material-ui/core/styles/withStyles'

//COMPONENTS
import Loader from 'components/Loader/Loader.jsx'

// REDIX INIT
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { GetCurrentPartner, UpdateUserProfile, SetFileData, ImageUpload, SweetAlert, HideAlert, ShowLoader, HideLoader } from '../../../redux/actions'
import { userPreference, GetUserPreference } from 'redux/api/alerts/alerts'

import { Formik } from 'formik'
import { object, string } from 'yup'
import Form from './Component/Profile'
// MOMENT JS
import * as moment from 'moment'

import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx'
import { getProgramTitle } from '../../../utils/utils'

const validationSchema = object().shape({
	given_name: string(), //.required(),
	email: string()
		.email('E-mail inválido')
		.required('obrigatório')
		.typeError(' obrigatório'),
	name: string().required(),
	id: string(),
})

class Profile extends React.Component {
	constructor(props) {
		super(props)
		this.formik = React.createRef()
		this.state = {
			id: '',
			sub: '',
			given_name: '',
			name: '',
			email: '',
			picture: '',
			viewImportantAlerts: true,
			disabledForm: true,
			newImage: null,
			username: '',
			image: null,
			loading: false,
		}
		if (localStorage.getItem('i18nextLng') !== null) {
			moment.locale(localStorage.getItem('i18nextLng'))
		} else {
			moment.locale('pt-BR')
		}
	}

	componentDidMount() {
		if (!this.props.user || _.isEmpty(this.props.user)) {
			this.props.history.push(`/auth/login`)
		} else {
			GetUserPreference().then(response => {
				if (response) {
					this.setState({ ...response })
				}
			})

			this.props.HideLoader()
			this.setState({ ...this.props.user })
		}
	}

	componentDidUpdate = (prevProps, prevState) => {
		this.props.HideLoader()
		if (prevProps.user !== this.props.user) {
			if (!this.props.user || _.isEmpty(this.props.user)) {
				this.props.history.push(`/auth/login`)
			}

			this.setState({ ...this.props.user })
			this.props.HideLoader()
		}

		if (prevProps.userUpdate !== this.props.userUpdate) {
			this.setState({ ...this.props.userUpdate.data })
			this.props.SweetAlert(
				<Alert
					success
					style={{ display: 'block', marginTop: '-100px' }}
					title={''}
					onConfirm={() => {
						this.props.HideAlert()
						this.props.HideLoader()
						window.location.reload(true)
					}}
					confirmBtnCssClass={this.props.classes.button + ' ' + this.props.classes.greenButtonColor}
					confirmBtnText={'OK'}
				>
					{this.props.t('FIELD_ALERT_SUCCESS')}
				</Alert>,
			)
		}
	}

	handleSave = values => {
		this.setState({ loading: true })
		this.props.ShowLoader(<Loader />)
		let img = this.props.imageFile

		if (this.props.imageFile !== null) {
			this.props.ImageUpload(img).then(rest => {
				//this.props.ImageUpload(this.state.newImage).then(rest => {
				if (img) {
					if (rest) {
						values.picture = rest.data
					} else {
						values.picture = ''
					}
				}
				userPreference(values.viewImportantAlerts).then(() => {
					this.props
						.UpdateUserProfile(values.sub, values)
						.then(() => this.setState({ disabledForm: true }))
						.finally(() => {
							this.setState({ loading: false })
						})
				})
				this.props.SetFileData(null)
			})
		} else {
			values.picture = ''
			userPreference(values.viewImportantAlerts).then(() => {
				this.props
					.UpdateUserProfile(values.sub, values)
					.then(() => this.setState({ disabledForm: true }))
					.finally(() => {
						this.setState({ loading: false })
					})
			})
		}
	}

	handleEdit() {
		this.setState({ disabledForm: false })
	}

	handleCancel() {
		if (this.formik.current) {
			this.formik.current.resetForm()
			this.setState({ disabledForm: true })
			this.props.SetFileData(null)
		}
	}

	callbackFunction = e => {
		this.setState({ newImage: e })
	}

	render() {
		const { t } = this.props
		const { sub, given_name, name, email, username, picture, viewImportantAlerts, disabledForm, image, id } = this.state
		const values = {
			id,
			sub,
			given_name,
			username,
			name,
			email,
			picture,
			viewImportantAlerts,
			image,
		}
		return (
			<Fragment>
				<DocumentTitle title={getProgramTitle()} />
				{this.state.loading && <Loader />}
				<Formik
					ref={this.formik}
					render={props => (
						<Form {...props} handleCancel={this.handleCancel.bind(this)} handleEdit={this.handleEdit.bind(this)} disabledForm={disabledForm} />
					)}
					validationSchema={validationSchema}
					initialValues={values}
					onSubmit={this.handleSave.bind(this)}
					enableReinitialize={true}
				/>
			</Fragment>
		)
	}
}
const mapStateToProps = state => {
	return {
		userData: state.users.userData,
		userUpdate: state.users.userUpdate,
		user: state.authentication.user,
		userDataFail: state.users.userDataFail,
		imageUrl: state.images.imageUrl,
		imageFile: state.images.imageFile,
	}
}

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			GetCurrentPartner,
			UpdateUserProfile,
			SetFileData,
			ImageUpload,
			SweetAlert,
			HideAlert,
			ShowLoader,
			HideLoader,
		},
		dispatch,
	)

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(extendedFormsStyle),
	withTranslation(),
)(Profile)
