import { Box } from '@mui/material'
import styled from 'styled-components'

export const Container = styled(Box)`
	height: 600px;

	.react-flow__node.highlight {
		background-color: #ff0072;
		color: white;
	}

	path.react-flow__edge-path {
		stroke-width: 3;

		//dotted line
		stroke-dasharray: 5, 5;
	}

	.intersection-flow .react-flow__node {
		display: flex;
		justify-content: center;
		align-items: center;
		font-weight: 700;
		border-radius: 1px;
		border-width: 2px;
		box-shadow: none;
	}

	.intersection-flow .react-flow__node.selected,
	.intersection-flow .react-flow__node:hover,
	.intersection-flow .react-flow__node:focus {
		box-shadow: none !important;
	}

	.react-flow__node-default.selectable.selected {
		box-shadow: none !important;
	}

	.react-flow__node-default {
		border: none;
		background: transparent;
	}

	.intersection-flow .react-flow__handle {
		display: none;
	}
`
