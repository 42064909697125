/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import * as S from './styles'

//@ components
import Filters from './components/Filter'
import GeneralTab from './components/generalTab'
import DivisionTab from './components/DivisionTab'
import NoDivisionTab from './components/NoDivisionTab'
import ModalInfo from './components/Modal/ModalInfo'

const RescueTab = ({
	multiple,
	productData,
	setDivision,
	setMultiple,
	typeOfBenefit,
	division,
	grouper,
	circularProgress,
	parameterization,
	selectedDivision,
	typeOfBenefitRedeem,
	setParameterization,
	setSelectedDivision,
	selectedDivisionIdRed,
	setTypeOfBenefitRedeem,
	setSelectedDivisionIdRed,
	setMultipleParameterization,
	editMode,
	setModalCancel,
	setEditMode,
}) => {
	const [generalTab, setGeneralTab] = useState(false)
	const [openModalInfo, setOpenModalInfo] = useState({
		open: false,
		tabs: null,
	})
	const [byDivisionTab, setByDivisionTab] = useState(false)
	const [noDivisionTab, setNoDivisionTab] = useState(false)

	const sharedProps = {
		grouper,
		editMode,
		multiple,
		division,
		setMultiple,
		setDivision,
		setEditMode,
		openModalInfo,
		setModalCancel,
		setOpenModalInfo,
		circularProgress,
		selectedDivision,
		setSelectedDivision,
		productData: productData,
		setGeneralTab: setGeneralTab,
		typeOfBenefit: typeOfBenefit,
		setByDivisionTab: setByDivisionTab,
		parameterization: parameterization,
		setNoDivisionTab: setNoDivisionTab,
		typeOfBenefitPoints: typeOfBenefitRedeem,
		typeOfBenefitRedeem: typeOfBenefitRedeem,
		setParameterization: setParameterization,
		selectedDivisionIdRed: selectedDivisionIdRed,
		setTypeOfBenefitRedeem: setTypeOfBenefitRedeem,
		setSelectedDivisionIdRed: setSelectedDivisionIdRed,
		setMultipleParameterization: setMultipleParameterization,
	}

	return (
		<S.Container>
			<ModalInfo
				open={openModalInfo.open}
				onConfirm={() => {
					setOpenModalInfo({ open: false, tabs: null })
					setEditMode(false)
				}}
				onCancel={() => {
					setOpenModalInfo({ open: false, tabs: null })
				}}
			/>
			<Filters {...sharedProps} />
			<S.Components>
				{noDivisionTab && <NoDivisionTab {...sharedProps} />}
				{generalTab && <GeneralTab {...sharedProps} />}
				{byDivisionTab && <DivisionTab {...sharedProps} />}
			</S.Components>
		</S.Container>
	)
}

export default RescueTab
