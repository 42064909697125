import axios from 'axios'

export const GetUsersByPartner = () => axios.get('/Partner/GetUsersByPartner').then(response => response.data)

export const GetPartnerUserById = id => axios.get(`/Partner/GetPartnerUser?identityId=${id}`).then(response => response.data)

export const GetCurrentPartner = () => axios.get(`/Partner/GetCurrentPartner`).then(response => response.data)

export const GetCurrentPartnerBase = () =>
	axios.get(`/Partner/GetCurrentPartnerBase`).then(response => {
		localStorage.setItem('typeSimpleUser', response?.data?.isSimple)
		return response.data
	})

export const VerifyDocument = cpf => {
	return axios.get(`/Consumer/VerifyDocument/${cpf}`).then(response => response.data)
}

// POST
export const PostPartnerRegulation = obj => axios.post(`/Consumer/SetConsumerRegulationRules`, obj).then(response => response.data)

// UPDATE
export const UpdatePartner = (id, obj) => axios.post(`/Partner/UpdatePartner/${id}`, obj).then(response => response.data)

// POST
export const PostUser = obj => axios.post(`/Consumer/Register`, obj).then(response => response.data)

// POST
export const CreateNewUser = (id, obj) => axios.post(`/Partner/registernewuser/${id}`, obj).then(response => response.data)

// GET
export const SendResetPasswordRequest = login => axios.get(`/Partner/SendResetPasswordRequest/${login}`).then(response => response.data)

// POST
export const ResetPassword = obj => axios.put(`/Partner/ResetPassword`, obj).then(response => response.data)

export const GetGeneralBasicData = () => axios.get(`/ProgramRules/General/BasicData`).then(response => response.data)

export const GetPermissionPartnerUser = () => axios.get(`/Partner/GetPermissionPartnerUser`).then(response => response.data)
