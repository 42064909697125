// import config from 'config';
import axios from 'axios'
import * as _ from 'lodash'

import { authHeader } from '../../utils'
import { envs } from '../api/windowServerData'

export const userService = {
	login,
	logout,
	getAll,
	changePassword,
	getClaimsGroups,
	GetUserById,
	ListUsers,
	CreateNewUser,
	UpdateUser,
	UpdateUserProfile,
}

function getClaimsGroups() {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json-patch+json',
			appId: 'PRN1802',
			// companyInternalName: 'eprodutor',
		},
		Accept: 'application/json',
	}

	// return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getPermissionsClaimsGroup`, requestOptions).then(response => {
	return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Group/getPermissionsClaimsGroup`, requestOptions).then(response => {
		return response.text().then(text => {
			let data = text && JSON.parse(text)
			if (!response.ok) {
				const error = (data && data.message) || response.statusText
				return Promise.reject(error)
			}

			// REMOVE ITEMS - 403 Forbidden
			_.remove(data, function(n) {
				return n.indexOf('403') > -1 ? n : null
			})

			data = _.mapValues(data, function(val) {
				if (typeof val === 'string') {
					return val.toLowerCase()
				}
				if (_.isArray(val)) {
					return _.map(val, _.method('toLowerCase'))
				}
			})
			// axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
			return data
		})
	})
}

function login(username, password) {
	const authData = {
		username,
		password,
	}

	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json-patch+json',
			appId: 'PRN1802',
			// companyInternalName: 'eprodutor',
		},
		body: JSON.stringify(authData),
	}

	// return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Login`, requestOptions)
	return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Login`, requestOptions)
		.then(handleResponse)
		.then(user => {
			//
			//console.log("user -> ", user)
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			localStorage.setItem('token', user.token)
			localStorage.setItem('user', JSON.stringify(user.user))

			axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token
			return user.user
		})
}

function logout() {
	// remove user from local storage to log user out
	localStorage.clear()
}

function changePassword(id, obj) {
	let token = localStorage.getItem('token')
	const requestOptions = {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json-patch+json',
			appId: 'PRN1802',
			// companyInternalName: 'eprodutor',

			Authorization: 'Bearer ' + token,
		},
		Accept: 'application/json',
		body: `{"id": "${id}", "oldPassword": "${obj.oldPassword}", "newPassword": "${obj.newPassword}"}`,
	}

	// return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Password/Change`, requestOptions).then(response => {
	return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}api/v2/Account/Password/Change`, requestOptions).then(response => {
		if (response.status === 200)
			return response.text().then(text => {
				const data = text && JSON.parse(text)
				return data
			})
		else
			return response.text().then(text => {
				const error = text && JSON.parse(text)
				return Promise.reject(error)
			})
	})
}

function getAll() {
	const requestOptions = {
		method: 'GET',
		headers: authHeader(),
	}

	return fetch(`http://localhost:5000/users`, requestOptions).then(handleResponse)
}

function handleResponse(response) {
	return response.text().then(text => {
		const data = text && JSON.parse(text)
		if (data.code === 429) {
			// ERRO 429
			const error = 'Limite de acessos excedido. Aguarde uns minutos e tente novamente'
			return Promise.reject(error)
		}
		//
		var myHeaders = response.headers
		myHeaders.forEach(function(val, key) {
			// console.log('=>>> Header = ' + key + ' -> ' + val)
		})
		//
		if (!response.ok) {
			// if (response.status === 401) {
			// 	// auto logout if 401 response returned from api
			// 	 logout()
			// 	// location.reload(true);
			// }

			const error = data || response.statusText
			return Promise.reject(error)
		}

		const user = {
			user: data.data.user,
			token: data.data.token,
		}

		return user
	})
}

function GetUserById(id) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json-patch+json',
			appId: 'PRN1802',
			// companyInternalName: 'eprodutor',
		},
		Accept: 'application/json',
	}

	// return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/GetUserById/${id}`, requestOptions).then(response => {
	return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/GetUserById/${id}`, requestOptions).then(response => {
		return response.text().then(text => {
			const data = text && JSON.parse(text)

			if (!response.ok) {
				const error = (data && data.message) || response.statusText
				return Promise.reject(error)
			}

			// axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
			return data
		})
	})
}

function ListUsers(name, email, isActive, cpf, page) {
	const requestOptions = {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json-patch+json',
			appId: 'PRN1802',
			// companyInternalName: 'eprodutor',
		},
		Accept: 'application/json',
	}

	return fetch(
		// `${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/ListUsers?name=${name}&email=${email}&isActive=${isActive}&CPF=${cpf}&page=${page}`,
		`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/ListUsers?name=${name}&email=${email}&isActive=${isActive}&CPF=${cpf}&page=${page}`,
		requestOptions,
	).then(response => {
		return response.text().then(text => {
			const data = text && JSON.parse(text)

			if (!response.ok) {
				const error = (data && data.message) || response.statusText
				return Promise.reject(error)
			}

			// axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
			return data
		})
	})
}

function CreateNewUser(id, obj) {
	var strGroups = ''

	if (obj.userGroups) {
		let strArray = []
		obj.userGroups.map(item => {
			strArray.push(`{ userId: '', groupId: '${item}' }`)
		})
		strGroups = 'userGroups: [' + strArray.join(', ') + '],'
	}

	const requestOptions = {
		method: 'POST',
		headers: { 'Content-Type': 'application/json-patch+json' },
		Accept: 'application/json',
		body: `{ userName: '${obj.userName}', ${strGroups} name: '${obj.name}', email: '${obj.email}',
            isActive: '${obj.isActive}'}`,
	}

	// return fetch(`${envs.REACT_APP_GERMINI_API_URL}/Partner/registernewuser/${id}`, requestOptions).then(response => {
	return fetch(`${envs.REACT_APP_GERMINI_API_URL}/Partner/registernewuser/${id}`, requestOptions).then(response => {
		return response.text().then(text => {
			const data = text && JSON.parse(text)

			if (!response.ok) {
				const error = data
				return Promise.reject(error)
			}

			return data
		})
	})
}

function UpdateUser(id, obj) {
	var strGroups = ''

	if (obj.userGroups) {
		let strArray = []
		strGroups = 'userGroups: ['
		obj.userGroups.map(item => {
			strArray.push(`{ userId: '', groupId: '${item}' }`)
		})
		strGroups = strGroups + strArray.join(', ') + '],'
	}

	const requestOptions = {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json-patch+json',
			appId: 'PRN1802',
			// companyInternalName: 'eprodutor',
		},
		Accept: 'application/json',
		body: `{ userName: '${obj.userName}', cpf: '${obj.cpf}', ${strGroups} name: '${obj.name}', email: '${obj.email}',
            isActive: '${obj.isActive}'}`,
	}

	// return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/UpdateUser/${id}`, requestOptions).then(response => {
	return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/UpdateUser/${id}`, requestOptions).then(response => {
		return response.text().then(text => {
			const data = text && JSON.parse(text)

			if (!response.ok) {
				const error = data
				return Promise.reject(error)
			}

			return data
		})
	})
}

function UpdateUserProfile(id, obj) {
	// se não for passado a imagem, não atualiza a imagem
	const body =
		obj.picture || obj.image
			? {
					name: obj.name,
					email: obj.email,
					image: obj.picture || obj.image || '',
			  }
			: {
					name: obj.name,
					email: obj.email,
			  }

	// return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/${obj.id}`, {
	return fetch(`${envs.REACT_APP_GERMINI_IDENTITY_URL}/api/v2/Account/${obj.id}`, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json-patch+json',
			appId: 'PRN1802',
			// companyInternalName: 'eprodutor',

			Authorization: 'Bearer ' + localStorage.getItem('token'),
		},
		Accept: 'application/json',
		body: JSON.stringify(body),
	}).then(response => {
		return response.text().then(text => {
			const data = text && JSON.parse(text)
			//
			const user = localStorage.getItem('user') && JSON.parse(localStorage.getItem('user'))

			if (!response.ok) {
				if (response.status === 401 || !user) {
					// auto logout if 401 response returned from api
					//logout()
					// this.location.reload(true);
				}

				const error = (data && data.message) || response.statusText
				return Promise.reject(error)
			}
			// store user details and jwt token in local storage to keep user logged in between page refreshes
			// localStorage.setItem('token', user.token);
			if (data.image) {
				user.picture = data.image
				user.image = data.image
			}
			user.given_Name = data.name
			user.name = data.name
			user.email = data.email

			localStorage.setItem('user', JSON.stringify(user))

			// axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
			return data
		})
	})
}
