import React from 'react'
import * as _ from 'lodash'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'

const NumberFormattedPoints = ({ inputRef, onChange, ...other }) => {
	const MAX_VAL = other.rows || 9999999999
	const withValueLimit = ({ value }) => {
		if (_.isEmpty(value)) {
			return true
		}
		return value <= MAX_VAL
	}

	return (
		<NumericFormat
			allowNegative={false}
			getInputRef={inputRef}
			isAllowed={withValueLimit}
			isNumericString={true}
			style={{ marginLeft: '5px' }}
			thousandSeparator={''}
			format={value => {
				if (value === '') return ''
				return Number(value).toFixed(0) // Formata para inteiro
			}}
			{...other}
			onValueChange={values => {
				onChange({
					target: {
						id: other.id,
						value: values.value,
					},
				})
			}}
		/>
	)
}

NumberFormattedPoints.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
}

export default NumberFormattedPoints
