import React from 'react'
import * as _ from 'lodash'
import { NumericFormat } from 'react-number-format'
import PropTypes from 'prop-types'

const DoubleFormatCustom = ({ inputRef, onChange, ...other }) => {
	const DECIMAL_SCALE = other.decimal_scale || 2
	const MAX_VAL = other.rows || 9999999999
	const withValueLimit = ({ value }) => {
		if (_.isEmpty(value)) {
			return true
		}
		return value <= MAX_VAL
	}
	//
	return (
		<NumericFormat
			allowNegative={false}
			decimalScale={DECIMAL_SCALE}
			decimalSeparator={','}
			getInputRef={inputRef}
			isAllowed={withValueLimit}
			isNumericString={true}
			style={{ marginLeft: '5px' }}
			thousandSeparator={'.'}
			{...other}
			onValueChange={(values) => {
				onChange({
					target: {
						id: other.id,
						value: values.value,
					},
				})
			}}
		/>
	)
}
//
DoubleFormatCustom.propTypes = {
	inputRef: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
}
//
export default DoubleFormatCustom
