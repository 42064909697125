// Material
import { Grid } from '@material-ui/core'
// Components
import Section from 'components/Section'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// Context
import { useAccounts } from '../../../../hooks/useAccounts'
import { Layout } from '../../Layout'
import { Title, MyButton } from './styles'
import moment from 'moment'
import { numberFormatText, convertUTCToLocal } from 'utils/utils'
import { getThemeColorFromLocalStorage } from '../../../../../../redux/api/themeColors'
import Button from 'components/CustomButtons/Button.jsx'

import {envs}  from '../../../../../../redux/api/windowServerData'


export function ModalCredit({ isOpen, creditData }) {
	const { closeCredit } = useAccounts()
	const { t } = useTranslation()
	const [themeColor, setThemeColor] = useState()

	useEffect(() => {
		setThemeColor(getThemeColorFromLocalStorage())
	}, [])

	return (
		<Layout isOpen={isOpen}>
			<form
				style={{
					width: '100%',
					//padding: '15px',
				}}
			>
				<div
					style={{
						alignItems: 'center',
						display: 'flex',
						justifyContent: 'space-between',
						marginBottom: '25px',
						width: '100%',
					}}
				>
					<Section title={t('PERFORMANCE_CAMPAIGN_CARD_MOVIMENTATION')} style={{ flexGrow: 1, marginRight: ' 0.938rem' }} />

					<Button onClick={() => closeCredit()} color="greenButtonClose" style={{ margin: 0 }} size="sm">
						<i className="icon-ico_cancelar" style={{ marginRight: '.688rem' }} />
						Fechar
					</Button>
				</div>

				<Grid container spacing={2} xs={12} sm={12}>
					<Grid item xs={3} sm={3}>
						<Title themeColor={themeColor}>Data</Title>
						<div>
							{creditData && moment(convertUTCToLocal(creditData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('DD/MM/YYYY')}
							<br />
						</div>
						<div>{creditData && moment(convertUTCToLocal(creditData.createdAt), 'YYYY-MM-DD hh:mm:ss').format('HH:mm')}</div>
					</Grid>
					<Grid item xs={3} sm={3}>
						<Title themeColor={themeColor}>Operação</Title>
						<div>{creditData.operation && t(creditData.operation.value)}</div>
					</Grid>
					<Grid item xs={3} sm={3}>
						<Title themeColor={themeColor}>Valor</Title>
						<div>
							{numberFormatText(
								creditData.value ? creditData.value : '0',
								localStorage.getItem('currencySymbol')
									? `${localStorage.getItem('currencySymbol')}\n`
									: // : envs.REACT_APP_CURRENCY_SYMBOL,
									  envs.REACT_APP_CURRENCY_SYMBOL,
								2,
							)}

							{/* {creditData.value && creditData.value} */}
						</div>
					</Grid>

					<Grid item xs={3}>
						<Title themeColor={themeColor}>Status</Title>
						<div>{creditData.status && t(creditData.status.value)}</div>
					</Grid>

					<Grid item xs={12}>
						<Title themeColor={themeColor}>Descrição</Title>
						<div>{creditData.description}</div>
					</Grid>
					<Grid item xs={12}>
						<Title themeColor={themeColor}>Comprovantes</Title>

						<div
							style={{
								display: 'flex',
								alignItems: 'center',
								marginTop: '10px',
							}}
						>
							<i
								class="icon-ico_saldo_incluido_visualizar1"
								style={{
									fontSize: 30,
									color: themeColor && themeColor.visual && themeColor.visual.icons && themeColor.visual.icons.color,
								}}
							/>

							{creditData &&
								creditData.attachments &&
								creditData.attachments.map(item => (
									<MyButton
										href={item.attachmentLink}
										themeColor={themeColor}
										style={{
											marginLeft: 10,
											padding: '5px',
											display: 'flex',
											alignItems: 'center',
											justifyContent: 'center',
											cursor: 'pointer',
										}}
									>
										{item.attachmentName}
									</MyButton>
								))}
						</div>
					</Grid>
				</Grid>
			</form>
		</Layout>
	)
}
